import React from 'react';
import { GRAPH_TYPE, TARGET_TYPE } from '../../../config/appDefaults';

const disclaimer = (
  <>
    <br />
    <br />
    <i>
      Note: This graph is based on the data in your accounting software, so work
      with your bookkeeper to make sure your books are up to date for a given
      period before relying on this graph.
    </i>
  </>
);

export const HOURS_GRAPH = {
  BILLABLE_HOURS: 'billableHours',
  NON_BILLABLE_HOURS: 'nonBillableHours',
};

export const hoursGraphs = [
  {
    name: HOURS_GRAPH.BILLABLE_HOURS,
    label: 'Billable Hours',
    targetType: TARGET_TYPE.NUMBER,
    graphType: GRAPH_TYPE.BAR,
    description: (
      <>
        See how your team is spending their time.
        <br />
        <br />
        Billable hours are based on the time entries associated with projects,
        and sub-projects marked as billable. Change the &quot;Is this a billable
        project?&quot; setting within any project or sub-project to change how
        your time tracking data is reported in this scoreboard.
        <br />
        <br />
        <b>How to use this graph</b>
        <br />
        <br />
        Every hour that your billable team, i.e. your revenue-generating team,
        like your Field/Production employees, spends on non-billable activities
        is time that they could have spent generating more revenue for your
        business. So, the more time your billable team spends on non-billable
        activities the less revenue your company generates, which can have a
        drastic impact on your profits.
        <br />
        <br />
        Avoid drastic changes in your profits by routinely reviewing how your
        billable team is spending their time. Use the Scoreboard filters to
        customize your crew members, customers, projects and timelines in your
        analysis. Save the filters for quick reference in the future.
        <br />
        <br />
        To learn how you can use billable hours to calculate your overhead
        allocation rate, and charge out rate, i.e. price, for both hourly and/or
        salaried employees on jobs, checkout out our{' '}
        <a
          href="https://share.hsforms.com/1SZJVU8JbQP6Gtw9GxK-HAQ3m7k0"
          target="_blank"
          rel="noopener noreferrer"
        >
          free charge out calculator tool
        </a>
        .
      </>
    ),
  },
  {
    name: HOURS_GRAPH.NON_BILLABLE_HOURS,
    label: 'Non-Billable Hours',
    targetType: TARGET_TYPE.NUMBER,
    graphType: GRAPH_TYPE.BAR,
    description: (
      <>
        Non-billable hours
        {disclaimer}
      </>
    ),
  },
];
