import gql from 'graphql-tag';
import * as Fragments from '../fragments';

export default gql`
  ${Fragments.BookkeepingRule}
  query GetBookkeepingRules($companyId: String!) {
    getBookkeepingRules(input: { companyId: $companyId }) {
      items {
        ...BookkeepingRuleFields
      }
    }
  }
`;
