import React, { useState } from 'react';
import { Grid, ButtonBase } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import {
  leadStatuses,
  defaultLeadStatus,
  LEAD_STATUS_INITIAL,
  LEAD_STATUS_IN_PROGRESS_PENDING,
  LEAD_STATUS_REJECTED,
  LEAD_STATUS_IN_PROGRESS_ACCEPTED,
} from '../../config/appDefaults';

import LeadChip from '../lead-chip/lead-chip';
import palette from '../../theme/palette';

const ChangeLeadStatusRadioButtons = ({
  onChange,
  initialStatus,
  buttonPadding = 16,
  hideIcons = false,
}) => {
  const [selectedChip, setSelectedChip] = useState(
    initialStatus || defaultLeadStatus
  );

  const handleChipClick = value => {
    setSelectedChip(value);
    onChange(value);
  };

  const decideRadioColor = contentStatus => {
    let chipColor;
    switch (contentStatus) {
      case LEAD_STATUS_INITIAL:
        chipColor = palette.brandColorPrimary;
        break;
      case LEAD_STATUS_IN_PROGRESS_PENDING:
        chipColor = palette.brandColorOrange;
        break;
      case LEAD_STATUS_REJECTED:
        chipColor = palette.brandColorError;
        break;
      case LEAD_STATUS_IN_PROGRESS_ACCEPTED:
        chipColor = palette.brandColorGreen;
        break;
      default:
        break;
    }
    return chipColor;
  };

  return (
    <>
      {leadStatuses.map(status => (
        <ButtonBase
          key={status.value}
          onClick={() => handleChipClick(status.value)}
          style={{ padding: buttonPadding }}
        >
          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item style={{ marginBottom: 8 }}>
              <LeadChip contentStatus={status.value} hideIcons={hideIcons} />
            </Grid>
            <Grid item>
              {selectedChip === status.value ? (
                <RadioButtonCheckedIcon
                  htmlColor={decideRadioColor(status.value)}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  htmlColor={decideRadioColor(status.value)}
                />
              )}
            </Grid>
          </Grid>
        </ButtonBase>
      ))}
    </>
  );
};

export default ChangeLeadStatusRadioButtons;
