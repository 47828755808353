import axios from 'axios';
import { Auth } from 'aws-amplify';
import _ from 'lodash';
import store from '../store/index';

const cloudinaryUploadUrl =
  'https://api.cloudinary.com/v1_1/check-the-level/auto/upload'; // need to add "/<resource_type>/upload"

// figure out the url
function typeToUrl(type) {
  // TODO: set the apiUrl based on the environment
  const apiUrl = 'https://gysvrh4mq1.execute-api.us-east-1.amazonaws.com/Prod';
  // const feedUrl = `${apiUrl}/feed`;
  // const myJrnsUrl = `${apiUrl}/jrns/mine`;
  // const getPublicJrnsUrl = `${apiUrl}/jrns/public`;
  // const addJrnUrl = `${apiUrl}/jrns`;
  // const updateAJrnUrl = `${apiUrl}/jrns/update`;
  // const removeAJrnUrl = `${apiUrl}/jrns/remove`;
  // const getJrnContent = `${apiUrl}/jrns/content/`;// POST
  // const addContent = `${apiUrl}/jrns/content/add`;
  // const updateContent = `${apiUrl}/jrns/content/update`;
  // const removeContent = `${apiUrl}/jrns/content/remove`;
  // const usersUrl = `${apiUrl}/users`;
  // const updateUserInfoUrl = `${apiUrl}/users/info`;// POST
  // const myInfoUrl = `${apiUrl}/me`;
  // const profilePicUrl = `${apiUrl}/me/profile-pic`;
  // const friendRequestUrl = `${apiUrl}/friends/request`;
  // const myFriendRequestsUrl = `${apiUrl}/friends/request/mine`;
  // const friendListFetchUrl = `${apiUrl}/friends`;

  const getSignedUrlForMedia = `${apiUrl}/request-signed-url`;

  // console.log('typeToUrl type = ', type);
  switch (type) {
    // case ('add_a_jrn'):
    //   return addJrnUrl;

    // case ('update_a_jrn'):
    //   return updateAJrnUrl;

    // case ('remove_a_jrn'):
    //   return removeAJrnUrl;

    // case ('get_jrn_content'):
    //   return getJrnContent;

    // case ('add_content'):
    //   return addContent;

    // case ('update_content'):
    //   return updateContent;

    // case ('remove_content'):
    //   return removeContent;

    // case ('get_my_jrns'):
    //   return myJrnsUrl;

    // case ('get_public_jrns'):
    //   return getPublicJrnsUrl;

    // case ('feed_get_new'):
    //   return feedUrl;

    // case ('feed_get_old'):
    //   return feedUrl;

    // case ('my_info'):
    //   return myInfoUrl;

    // case ('update_user_info'):
    //   return updateUserInfoUrl;

    // case ('get_users'):
    //   return usersUrl;

    // case ('friend_request'):
    //   return friendRequestUrl;

    // case ('my_friend_requests'):
    //   return myFriendRequestsUrl;

    // case ('friend_list_fetch'):
    //   return friendListFetchUrl;

    // case ('get_signedURL_for_profile_pic'):
    //   return profilePicUrl;

    // case ('update_profile_pic'):
    //   // doesnt need a URL since it includes it's own presigned url in the request
    //   return '';

    case 'get_signedURL_for_upload_media':
      return getSignedUrlForMedia;

    case 'upload_media':
      return cloudinaryUploadUrl;

    default:
      return apiUrl;
  }
}

export default {
  /**
   * Executes a REST request.
   *
   * @param {obj} requestParams
   */
  restRequest: async (requestParams, type) => {
    const url = typeToUrl(type);
    const pathArray = url.split('/');
    const host = pathArray[2];
    let path = pathArray.slice(3).join('/');
    path = `/${path}`;

    const newRequestParams = { ...requestParams };
    newRequestParams.host = host;
    newRequestParams.path = path;
    newRequestParams.url = typeToUrl(type);
    newRequestParams.headers = {
      'content-type': 'application/json; charset=utf-8',
    };

    if (newRequestParams.method !== 'GET') {
      // TODO: we need to figure out if the token has expired
      //  (or expires in the next 5 minutes to give us some time for latency)
      //  if it hasn't then just proceed with the call, on token error, run
      //  the reAuth then try the call again
      //  if it has, do a reAuth to get another token and then try the call again

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async function restRequest(resolve, reject) {
        // eslint-disable-line
        // console.log('inside promise');
        // gets current user from local storage
        // const cognitoUser = await Auth.getCurrentUser();
        // const cognitoUser = await Auth.currentSession();
        // console.log('trying to get session');
        // get current session from storage or by getting a new refresh token from the server
        const session = await Auth.currentSession();
        // console.log('session: ', session);

        if (!session) {
          // console.log('no session available');
          // throw (err); // have your route handler toss user back to login route
        }
        // console.log('cognitoUser.getSession session = ', session);
        // now you'll have your session here
        newRequestParams.headers.Authorization = session
          .getIdToken()
          .getJwtToken();
        newRequestParams.data = newRequestParams.body;
        // console.log('about to try axios with: ', newRequestParams);
        axios(newRequestParams)
          .then(response => {
            // console.log('signedRequest response = ', response);
            resolve(response.data);
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.log('api error = ', error);
            // throw error;
            reject(error);
          });
      });
    }
    //  TODO: make this a POST as well so
    //  the requestor can be authenticated
    // console.log('LocalStorage.getItem('awsCredentials') must have been null');
    const unsignedRequest = newRequestParams.url;
    // console.log('get called');
    const unsignedRequestCall = await axios.get(unsignedRequest);
    return unsignedRequestCall;
    // throw new Error('Invalid request params');
  },

  s3Request: async requestParams => {
    // console.log('s3Request requestParams = ', requestParams);
    // const url = requestParams.url;

    const filenameParts = requestParams.localLocation.split('/');
    // const filename = filenameParts[filenameParts.length - 1];
    const uriParts = requestParams.localLocation.split('.');
    const presignedUrl = requestParams.url;
    const fileType = uriParts[uriParts.length - 1];
    const filePath = requestParams.body;
    const fileName = filenameParts[filenameParts.length - 1];

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function xhrForS3Request() {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          // Successfully uploaded the file.
          // console.log('success xhr = ', xhr);
          return { status: 'success' };
        }
        // The file could not be uploaded.
        // console.log('failed xhr = ', xhr);
        return { status: 'error' };
      }
      return false;
    };
    xhr.open('PUT', presignedUrl);
    xhr.setRequestHeader('X-Amz-ACL', 'public-read');
    xhr.setRequestHeader('Content-Type', fileType);
    xhr.send({ uri: filePath, type: fileType, name: fileName });
  },

  cloudinaryUpload: async requestParams => {
    // console.log('cloudinaryUpload requestParams: ', requestParams);
    const { file } = requestParams;
    const { signature, timestamp } = requestParams;
    const type = requestParams.typeOfFile ? requestParams.typeOfFile : 'image';
    const deviceWidth = window.outerWidth;
    const deviceHeight = window.outerHeight;
    const maxHeight = deviceHeight - 100;

    const profileImage = requestParams.isProfileImage || false;
    const customFilename = requestParams.customFilename || false;
    const customFolder = requestParams.customFolder || false;

    // let imageEager = 'w_56,h_56,c_fit,q_100';
    // if (profileImage) {
    //   imageEager += `|w_${parseInt(deviceHeight / 4, 10)},h_${parseInt(deviceHeight / 4, 10)},q_100`;
    // } else {
    //   imageEager += `|w_${deviceWidth},h_${maxHeight},q_auto,c_fit`;
    // }
    // const videoEager = 'streaming_profile=lean_hd,format=m3u8';

    let eagerToUse;
    const eagerAsync = true;
    if (type === 'video') {
      eagerToUse = 'sp_hd';
      // eagerToUse = 'sp_hd,f_m3u8';
      // eagerToUse = 'eager_async=true,sp=lean_hd,f=m3u8';
    } else {
      // make image the fallback
      eagerToUse = 'w_56,h_56,c_fit,q_100';
      // only add the big version if it's not a profile image
      if (profileImage === true) {
        eagerToUse += `|w_${parseInt(deviceHeight / 4, 10)},h_${parseInt(
          deviceHeight / 4,
          10
        )},q_100`;
      } else {
        eagerToUse += `|w_${deviceWidth},h_${maxHeight},q_auto,c_fit`;
      }
    }
    // console.log('eagerToUse: ', eagerToUse);
    const formData = new FormData();
    formData.append('file', file);
    // console.log('formData file: ', formData);
    if (profileImage) {
      formData.append('folder', 'profileImages');
    } else if (customFolder) {
      formData.append('folder', customFolder);
    }
    formData.append('timestamp', timestamp);
    formData.append('api_key', process.env.REACT_APP_CLOUDINARY_API_KEY);
    formData.append('signature', signature);
    if (customFilename) {
      formData.append('use_filename', true);
      formData.append('unique_filename', true);
    }
    formData.append('eager', eagerToUse);

    if (type === 'video') {
      formData.append('eager_async', eagerAsync);
    }

    // xhr.send(formData);
    // console.log('formData: ', formData);

    return new Promise(function xhrForCloudinaryRequest(resolve, reject) {
      // eslint-disable-line
      // const xhr = new XMLHttpRequest();

      // if (type === 'video') {
      //   const dbprogress = event => {
      //     const progressPercent = Math.floor(
      //       (event.loaded / event.total) * 100
      //     );
      //     // console.log(`progress: ${progressPercent}%`);

      //     store.dispatch({
      //       type: 'SET_UPLOADING_STATUS',
      //       payload: {
      //         progressPercent,
      //       },
      //     });
      //     // if (event.loaded === event.total) {
      //     //   resolve({
      //     //     status: 'success',
      //     //     // url: response.secure_url,
      //     //   });
      //     // }
      //   };
      //   xhr.upload.onprogress = _.throttle(dbprogress, 500);
      // }

      // xhr.onload = function xhrOnload() {
      //   if (this.status >= 200 && this.status < 300) {
      //     const response = JSON.parse(xhr.responseText);
      //     resolve({
      //       status: 'success',
      //       url: response.secure_url,
      //     });
      //   } else {
      //     console.log('this.status1 = ', this.status);
      //     console.log('xhr.statusText1 = ', xhr.statusText);
      //     reject(
      //       new Error({
      //         status: this.status,
      //         statusText: xhr.statusText,
      //       })
      //     );
      //   }
      // };
      // xhr.onerror = function xhrOnError() {
      //   console.log('this.status2 = ', this.status);
      //   // console.log('xhr.statusText2 = ', xhr.statusText);
      //   reject(
      //     new Error({
      //       status: this.status,
      //       statusText: xhr.statusText,
      //     })
      //   );
      // };

      const dbprogress = event => {
        const progressPercent = Math.floor((event.loaded / event.total) * 100);
        // console.log(`progress: ${progressPercent}%`);
        store.dispatch({
          type: 'SET_UPLOADING_STATUS',
          payload: { progressPercent },
        });
      };

      const axiosConfig = {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        onUploadProgress: _.throttle(dbprogress, 500),
      };
      return axios
        .post(cloudinaryUploadUrl, formData, axiosConfig)
        .then(response => {
          store.dispatch({
            type: 'SET_UPLOADING_STATUS',
            payload: { progressPercent: null },
          });

          resolve({
            status: 'success',
            response: response.data,
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(JSON.stringify(error, null, 2));
          reject(error.response);
        });
    });
  },
};
