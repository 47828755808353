import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import _ from 'lodash';

import { LEVEL_ROLE } from '../../config/appDefaults';
import { getFullUserString } from '../../helpers';

const RevokeRoleConfirmationDialog = ({
  role,
  userToRevoke,
  forceTransfer = false,
  provisionalCandidates,
  onConfirm,
  onCancel,
  userInfo,
}) => {
  const [transferToUserId, setTransferToUserId] = useState('');

  const wording = useMemo(() => {
    if (role === LEVEL_ROLE.COMPANY_OWNER) {
      return 'owners';
    }
    if (role === LEVEL_ROLE.BOOKKEEPER) {
      return 'bookkeepers';
    }

    return 'admins';
  }, [role]);

  const sortedProvisionalCandidates = useMemo(() => {
    return _.orderBy(provisionalCandidates, ['username'], ['asc']);
  }, [provisionalCandidates]);

  if (!userToRevoke) {
    return null;
  }

  const who =
    userToRevoke.userId === userInfo.userId
      ? 'You'
      : getFullUserString(userToRevoke);

  const handleCrewMemberSelect = event => {
    setTransferToUserId(event.target.value);
  };

  const renderCrewMemberOptions = () => {
    return _.map(sortedProvisionalCandidates, candidate => (
      <MenuItem key={candidate.userId} value={candidate.userId}>
        {getFullUserString(candidate)}
      </MenuItem>
    ));
  };

  return (
    <Dialog maxWidth="sm" aria-labelledby="confirmation-dialog" open>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent dividers style={{ paddingTop: 20, paddingBottom: 20 }}>
        <Grid container spacing={2}>
          <Grid item>
            <Typography>
              <b>{who}</b> will be removed from the company {wording}&nbsp;
              {role === LEVEL_ROLE.ADMIN || forceTransfer
                ? 'and no longer be able to manage the company on Level.'
                : 'but will continue as a company administrator.'}
            </Typography>
          </Grid>
          {forceTransfer && !_.isEmpty(provisionalCandidates) && (
            <Grid container direction="column" item spacing={2}>
              <Grid item>
                <Typography>
                  Please select a crew member to transfer the role to.
                </Typography>
              </Grid>
              <Grid container item justifyContent="center">
                <FormControl style={{ width: '100%' }}>
                  <Select
                    value={transferToUserId}
                    onChange={handleCrewMemberSelect}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="" disabled>
                      Select a crew member
                    </MenuItem>
                    {renderCrewMemberOptions()}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button
          disabled={forceTransfer && !transferToUserId}
          onClick={() => {
            onConfirm({ userToRevoke, transferToUserId, role });
          }}
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({ userInfo: state.userInfo });

export default connect(mapStateToProps)(RevokeRoleConfirmationDialog);
