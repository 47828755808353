import React from 'react';
import { Grid } from '@material-ui/core';

import { useStyles } from './onboarding.styles';
import LevelLogo from '../../components/level-logo/level-logo';
import OnboardingStepButtons from './onboarding-step-buttons';

const OnboardingWelcomeStep = () => {
  const classes = useStyles();

  return (
    <>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        style={{ flex: 1 }}
      >
        <Grid item>
          <Grid item xs={12}>
            <LevelLogo logoVariation="new" className={classes.levelLogo} />
          </Grid>
          <Grid item xs={12} className={classes.title}>
            Welcome to
            <br />
            Level&apos;s Bookkeeping Copilot!
          </Grid>
          <Grid item xs={12} className={classes.description}>
            We just need some basic information to get you started.
          </Grid>
        </Grid>
      </Grid>
      <OnboardingStepButtons allowGoToNext showLoading={false} />
    </>
  );
};

export default OnboardingWelcomeStep;
