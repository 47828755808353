import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Check as CheckIcon,
  VisibilityOff as HideIcon,
  Link as LinkIcon,
  Visibility as ShowIcon,
} from '@material-ui/icons';
import copy from 'copy-to-clipboard';
import _ from 'lodash';
import moment from 'moment';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import LoadingCover from '../../../components/LoadingCover/loadingCover';
import BackToTopButton from '../../../components/back-to-top/back-to-top';
import { BOOKKEEPING_REPORT_ALERT_DICTIONARY } from '../../../components/bookkeeping-alerts/bookkeeping-alert-dictionary';
import BookkeepingAlertsBackButton from '../../../components/bookkeeping-alerts/bookkeeping-alerts-back-button';
import BookkeepingAlertsReport from '../../../components/bookkeeping-alerts/bookkeeping-alerts-report';
import BookkeepingAlertsReportContext from '../../../components/bookkeeping-alerts/bookkeeping-alerts-report/bookkeeping-alerts-report.context';
import {
  BOOKKEEPING_REPORT_EVENT_META_TYPE,
  IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER,
} from '../../../components/bookkeeping-alerts/bookkeeping-alerts.constants';
import styles from '../../../components/bookkeeping-alerts/bookkeeping-alerts.styles';
import {
  DashboardControlBarSelect,
  SELECT_ALL_VALUE,
} from '../../../components/dashboard';
import { UpdateBookkeepingReportAction } from '../../../graphql/graphql';
import GetBookkeepingReport from '../../../graphql/queries/get-bookkeeping-report';
import GetBookkeepingRules from '../../../graphql/queries/get-bookkeeping-rules';
import ListBookkeepingSnapshotEventMeta from '../../../graphql/queries/list-bookkeeping-snapshot-event-meta';
import palette from '../../../theme/palette';

const useStyles = makeStyles(styles);

const BookkeepingAlertsReportView = ({
  managingCompanyId,
  onUpdateBookkeepingReport,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const [reportDate, setReportDate] = useState(null);
  const [selectedAlertTypeId, setSelectedAlertTypeId] = useState(
    SELECT_ALL_VALUE
  );
  const [snapshotId, setSnapshotId] = useState(null);
  const [loading, setLoading] = useState(null);
  const [isReportLinkCopied, setIsReportLinkCopied] = useState(false);
  const [backUrl, setBackUrl] = useState('/alerts');

  const containerRef = useRef(null);

  useEffect(() => {
    let timeout;
    if (isReportLinkCopied) {
      timeout = setTimeout(() => {
        setIsReportLinkCopied(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isReportLinkCopied]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const snapshotIdParam = searchParams.get('snapshotId');
    const dateParam = searchParams.get('date');
    const typeParam = searchParams.get('type');

    const backType = searchParams.get('backType');
    const backPeriod = searchParams.get('backPeriod');

    if (snapshotIdParam) {
      setSnapshotId(snapshotIdParam);
    }

    if (dateParam) {
      const reportTime = parseInt(dateParam, 10);
      if (!Number.isNaN(reportTime)) {
        setReportDate(new Date(reportTime));
      } else {
        const dateToTry = new Date(dateParam);
        if (moment(dateToTry).isValid()) {
          // If not a number, must be an ISO or date string
          setReportDate(dateToTry);
        }
      }
    }

    if (typeParam && BOOKKEEPING_REPORT_ALERT_DICTIONARY[typeParam]) {
      setSelectedAlertTypeId(typeParam);
    }

    // Set the back URL to make sure the dashboard is restored to the
    // previous state before the user navigated to this report
    if (backType || backPeriod) {
      const backSearchParams = new URLSearchParams();
      if (backType) {
        backSearchParams.set('type', backType);
      }

      if (backPeriod) {
        backSearchParams.set('period', backPeriod);
      }

      setBackUrl(`/alerts?${backSearchParams.toString()}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!reportDate) {
      return;
    }

    // Update the URL with the selected report date and alert type
    // it also removes the snapshotId, backType, and backPeriod from the URL if they exist

    const searchParams = new URLSearchParams();
    searchParams.set('date', reportDate.getTime());
    if (selectedAlertTypeId && selectedAlertTypeId !== SELECT_ALL_VALUE) {
      searchParams.set('type', selectedAlertTypeId);
    }

    const url = `${window.location.pathname}?${searchParams.toString()}`;

    window.history.replaceState({}, document.title, url);
  }, [reportDate, selectedAlertTypeId]);

  const bookkeepingRulesQuery = useQuery(GetBookkeepingRules, {
    variables: {
      companyId: managingCompanyId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !managingCompanyId,
  });

  const bookkeepingRules = _.get(
    bookkeepingRulesQuery,
    'data.getBookkeepingRules.items',
    null
  );

  const alertTypeOptions = useMemo(() => {
    if (!bookkeepingRules) {
      return null; // means still loading
    }

    const typeOptions = [];
    const typeOptionsMap = {};
    _.forEach(bookkeepingRules, rule => {
      if (
        BOOKKEEPING_REPORT_ALERT_DICTIONARY[rule.alertIdentifier] &&
        rule.alertIdentifierSub !== IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER && // not the ignore undeposited funds rule
        !typeOptionsMap[rule.alertIdentifier]
      ) {
        typeOptions.push({
          key: rule.alertIdentifier,
          displayValue:
            BOOKKEEPING_REPORT_ALERT_DICTIONARY[rule.alertIdentifier].name,
        });
        typeOptionsMap[rule.alertIdentifier] = true;
      }
    });

    return typeOptions;
  }, [bookkeepingRules]);

  const {
    data: bookkeepingReportData,
    loading: bookkeepingReportLoading,
  } = useQuery(GetBookkeepingReport, {
    variables: {
      companyId: managingCompanyId,
      reportDate: reportDate?.toISOString() || null,
      snapshotId,
    },
    skip: !(managingCompanyId && (reportDate || snapshotId)),
  });

  const reportResponse = _.get(
    bookkeepingReportData,
    'getBookkeepingReport',
    null
  );

  const snapshotIdToUse = snapshotId || reportResponse?.snapshotId;

  const {
    data: bookkeepingAddressedStateEventMetaData,
    loading: bookkeepingAddressedStateEventMetaLoading,
  } = useQuery(ListBookkeepingSnapshotEventMeta, {
    variables: {
      companyId: managingCompanyId,
      snapshotId: snapshotIdToUse,
      metaType: BOOKKEEPING_REPORT_EVENT_META_TYPE.ADDRESSED_STATE,
    },
    skip: !managingCompanyId || !snapshotIdToUse,
  });

  const addressedStateEventMetaMap = useMemo(() => {
    const addressedStateList = _.get(
      bookkeepingAddressedStateEventMetaData,
      'listBookkeepingSnapshotEventMeta.items',
      []
    );

    return _.keyBy(addressedStateList, 'alertInstanceId');
  }, [bookkeepingAddressedStateEventMetaData]);

  const handleCopyLinkClick = () => {
    const searchParams = new URLSearchParams();
    searchParams.set('date', reportDate.getTime());
    if (selectedAlertTypeId && selectedAlertTypeId !== SELECT_ALL_VALUE) {
      searchParams.set('type', selectedAlertTypeId);
    }

    const queryString = searchParams.toString();

    const url = `${window.location.origin}${location.pathname}?${queryString}`;

    copy(url);
    setIsReportLinkCopied(true);
  };

  const noReportDataAndNotFetching =
    !bookkeepingReportLoading &&
    !bookkeepingAddressedStateEventMetaLoading &&
    ((bookkeepingReportData !== undefined && !reportResponse) ||
      !reportResponse?.dataJson);

  const [datePlaceholder, setDatePlaceholder] = useState('');
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const dateParam = searchParams.get('date');
    if (dateParam) {
      const dateForFormatting = new Date(parseInt(dateParam, 10));
      const momentDate = moment(dateForFormatting).format(
        'MMMM D, YYYY @ h:mm A'
      );
      setDatePlaceholder(momentDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only want to run this once on load

  const dateToShow = reportResponse?.dataJson
    ? moment(reportResponse?.date)
    : null;
  const prettyDateToShow = dateToShow?.isValid()
    ? dateToShow.format('MMMM D, YYYY @ h:mm A')
    : '';

  const reportData = useMemo(() => {
    if (reportResponse?.dataJson) {
      return JSON.parse(reportResponse.dataJson);
    }

    return null;
  }, [reportResponse]);

  const showLoader =
    bookkeepingReportLoading ||
    bookkeepingAddressedStateEventMetaLoading ||
    !bookkeepingReportData;

  const hidden = reportResponse?.hidden || false;

  const handleVisiblityChange = async newValue => {
    setLoading(true);

    await onUpdateBookkeepingReport({
      snapshotId: snapshotIdToUse,
      hidden: newValue,
    });

    setLoading(false);
  };

  return (
    <BookkeepingAlertsReportContext.Provider
      value={{
        companyId: managingCompanyId,
        snapshotId: snapshotIdToUse,
        reportDate,
      }}
    >
      <Grid
        container
        direction="column"
        style={{
          width: '100%',
          minHeight: '100%',
          background: '#fff',
        }}
      >
        <Grid container item className={classes.header}>
          <Grid container item alignItems="stretch" xs={12} sm={9}>
            <Grid item>
              <BookkeepingAlertsBackButton to={backUrl} />
            </Grid>

            <Grid item>
              <Grid container direction="column" item>
                <Grid item>
                  <Typography
                    variant="caption"
                    style={{ fontSize: 12, paddingLeft: 1 }}
                  >
                    Bookkeeping Report
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" style={{ fontSize: '135%' }}>
                    {prettyDateToShow || datePlaceholder || ''}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {prettyDateToShow && (
              <Grid item style={{ alignSelf: 'flex-end' }}>
                <Grid item container>
                  <Tooltip
                    title={isReportLinkCopied ? 'Copied' : 'Copy Report Link'}
                    PopperProps={{ style: { zIndex: 9999 } }}
                  >
                    <IconButton
                      onClick={handleCopyLinkClick}
                      style={{ marginLeft: 4 }}
                    >
                      {isReportLinkCopied ? <CheckIcon /> : <LinkIcon />}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            item
            justifyContent="flex-end"
            alignItems="center"
            xs={12}
            sm={3}
          >
            <Grid item style={{ minWidth: 150, marginRight: 16 }}>
              <DashboardControlBarSelect
                label="Filter By Alert Type"
                showOptionAll
                options={alertTypeOptions}
                selected={selectedAlertTypeId}
                onChange={setSelectedAlertTypeId}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          style={{
            paddingTop: 16,
            height: 'calc(100vh - 64px - 80px)',
            position: 'relative',
            overflowY: 'auto',
          }}
          justifyContent="center"
          ref={containerRef}
        >
          {noReportDataAndNotFetching && (
            <Typography variant="h5">{`No report available for ${
              moment(reportDate).isValid()
                ? moment(reportDate).format('YYYY-MM-DD')
                : 'the selected date'
            }.`}</Typography>
          )}

          {showLoader && <LoadingCover />}

          {reportData && (
            <>
              <BookkeepingAlertsReport
                reportDate={reportDate}
                reportData={reportData}
                selectedAlertTypeId={selectedAlertTypeId}
                addressedStateEventMetaMap={addressedStateEventMetaMap}
              />
              <Grid
                container
                item
                direction="column"
                style={{ width: '100%', padding: 16 }}
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{
                      color: palette.brandColorMidGrey,
                      fontStyle: 'italic',
                    }}
                  >
                    Is this report a duplicate or contain alerts that you would
                    like to ignore? Go ahead and hide the report.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color={!hidden ? 'default' : 'primary'}
                    onClick={() => handleVisiblityChange(!hidden)}
                    disabled={loading}
                  >
                    {!hidden && (
                      <>
                        Hide report from graph
                        {loading && (
                          <CircularProgress
                            style={{ marginLeft: 8 }}
                            size={16}
                          />
                        )}
                        {!loading && <HideIcon style={{ marginLeft: 8 }} />}
                      </>
                    )}
                    {hidden && (
                      <>
                        Show report in graph
                        {loading && (
                          <CircularProgress
                            style={{ marginLeft: 8 }}
                            size={16}
                          />
                        )}
                        {!loading && <ShowIcon style={{ marginLeft: 8 }} />}
                      </>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
          <BackToTopButton scrollElementRef={containerRef} />
        </Grid>
      </Grid>
    </BookkeepingAlertsReportContext.Provider>
  );
};

const mapStateToProps = state => ({
  managingCompanyId:
    (state.appState.managingCompanyInfo &&
      state.appState.managingCompanyInfo.managingCompanyId) ||
    null,
});

export default compose(
  UpdateBookkeepingReportAction,
  connect(mapStateToProps)
)(BookkeepingAlertsReportView);
