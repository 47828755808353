import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import ContentLoader from 'react-content-loader';
import FadeIn from 'react-fade-in/lib/FadeIn';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  companyLogo: {
    width: 40,
    margin: '0 auto',
  },
  fadeWrapper: {
    width: '100%',
  },
}));

const ClientDisplay = ({ managingCompanyInfo, firmInfo, errorOccurred }) => {
  const classes = useStyles();
  const displayNameRef = useRef(null);

  useEffect(() => {
    // decrease font size by 1px until it fits
    if (displayNameRef.current?.offsetWidth) {
      if (displayNameRef.current.offsetWidth > 200) {
        const currentFontSize = _.replace(
          window.getComputedStyle(displayNameRef.current).fontSize,
          'px',
          ''
        );

        displayNameRef.current.style.fontSize = `${currentFontSize - 1}px`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayNameRef.current?.offsetWidth]);

  return (
    <Grid container>
      {errorOccurred && `An error occurred.`}
      {!errorOccurred && !managingCompanyInfo && (
        <ContentLoader
          height={95}
          width={208}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="57" y="75" rx="3" ry="3" width="94" height="18" />
          <circle cx="104" cy="30" r="30" />
        </ContentLoader>
      )}
      {!errorOccurred && managingCompanyInfo && (
        <FadeIn className={classes.fadeWrapper}>
          <Grid
            container
            item
            xs={12}
            style={{
              background: '#fafafa',
              padding: 8,
              border: '1px solid #f4f4f4',
            }}
          >
            <Grid container item alignItems="center" justifyContent="center">
              {managingCompanyInfo.companyLogo && (
                <Grid item>
                  <img
                    alt="Company Logo"
                    className={classes.companyLogo}
                    src={managingCompanyInfo.companyLogo}
                    style={{ marginRight: 8 }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography
                  ref={displayNameRef}
                  className={classes.name}
                  variant="body1"
                  align="center"
                >
                  {managingCompanyInfo.companyName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" align="center">
                  via {firmInfo.companyName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </FadeIn>
      )}
    </Grid>
  );
};

const mapStateToProps = state => ({
  firmInfo: state.appState.firmInfo || null,
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(ClientDisplay);
