import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  ${Fragment.Stat}
  query GetContentById($contentId: ID!) {
    getContentById(contentId: $contentId) {
      managingCompanyId
      customerId
      customerInfo {
        customerId
        managingCompanyId
        firstName
        lastName
        address
        email
        latitude
        longitude
        phoneNumber
        notes
        companyName
        qboCustomerId
      }
      contentId
      jrnId
      creatorId
      type
      subtype
      permissionsFrom
      requireAdmin
      completionType
      completionInfo {
        instructions
        completedById
        completedByDate
        textResponse
        mediaInfo {
          uri
          aspectRatio
        }
      }
      contentUrl
      date
      description
      aspectRatio
      labels
      address
      latitude
      longitude
      isPublic
      synced
      comments {
        items {
          content
          commentId
          dateCreated
          userId
        }
      }
      loves {
        items {
          loveId
          userId
          contentId
        }
      }
      title
      endDate
      qboCustomerId
      privacyLevel
      inviteCode
      allowedToEdit
      allowedToAdd
      allowedToView
      usersEverOnJrn
      startDate
      addedBy
      assignedTo
      contentStatus
      useTimetracking
      useTimetrackingQuestions
      timetrackingQuestions
      timetrackingQuestionsCheckout
      billable
      priority
      amount {
        value
      }
      recurrence {
        rangeId
        rangeStartDate
        rangeEndDate
        daysOfTheWeek
      }
      timetrackingAnswers {
        questionId
        answer
      }
      fromTemplate
      timetrackingType
      workflowStage
      workflowStageReason
      # REMOVEME: remove this when "bugets" field is deprecated
      budgets {
        ...BudgetFields
      }
      stats {
        ...StatFields
      }
      includeSubsInStats
      excludeFromStats
      rfiIds
      globalExpenseId
      billable
    }
  }
`;
