import React, { useState } from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  Tooltip,
  IconButton,
  Paper,
  Button,
} from '@material-ui/core';
import moment from 'moment';
import { Cancel as CancelIcon, Home as CustomerIcon } from '@material-ui/icons';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import themePalette from '../../theme/palette';

const CrewMemberInvitations = ({ invitations, deleteInvitation }) => {
  const [invitationCopied, setInvitationCopied] = useState({});

  return (
    <Grid
      container
      item
      direction="column"
      alignItems="center"
      style={{ padding: 20 }}
    >
      <Grid container item direction="column" alignItems="center" spacing={2}>
        {invitations && invitations.length === 0 && (
          <Typography variant="body1">No outstanding invitations.</Typography>
        )}
        {invitations &&
          invitations.length !== 0 &&
          invitations.map(
            ({ invitationId, email, sentOn, role, isProcessing = null }) => {
              const baseInvite = `${window.location.origin}/invitation?key=`;
              const encodingInvite = window.btoa(invitationId);
              const inviteLink = baseInvite.concat(encodingInvite);

              return (
                <Grid item style={{ width: '100%' }} key={`${email}-${sentOn}`}>
                  <Paper
                    key={invitationId}
                    style={{ width: '100%', padding: 20 }}
                  >
                    <Grid container justifyContent="center">
                      <Grid
                        container
                        item
                        alignItems="center"
                        style={{ flex: 1 }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Typography variant="h5" style={{ flex: 0 }}>
                            {email}
                          </Typography>
                          {role === 'customer' && (
                            <Tooltip title="Customer">
                              <CustomerIcon
                                style={{
                                  marginLeft: 8,
                                  marginBottom: 4,
                                  color: themePalette.brandColorDarkGrey,
                                }}
                              />
                            </Tooltip>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            {sentOn ? moment(sentOn).fromNow() : 'Sending...'}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          onClick={() => {
                            navigator.clipboard.writeText(inviteLink);
                            setInvitationCopied({
                              ...invitationCopied,
                              [invitationId]: true,
                            });
                            setTimeout(() => {
                              setInvitationCopied({
                                ...invitationCopied,
                                [invitationId]: false,
                              });
                            }, 3000);
                          }}
                        >
                          {invitationCopied[invitationId] ? (
                            <CheckCircleOutlineIcon
                              style={{ color: themePalette.brandColorGreen }}
                            />
                          ) : (
                            'Copy Link'
                          )}
                        </Button>
                        {!isProcessing ? (
                          <Tooltip title="Delete invitation">
                            <IconButton
                              onClick={() => deleteInvitation(invitationId)}
                            >
                              <CancelIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <div style={{ padding: 12 }}>
                            <CircularProgress size="22px" />
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            }
          )}
      </Grid>
    </Grid>
  );
};

export default CrewMemberInvitations;
