import palette from '../../theme/palette';

const heightOfFooter = 68;

export default theme => ({
  mainContainer: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  mainContainerWithFooter: {
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: heightOfFooter,
  },
  rightWrapper: {
    padding: theme.spacing(2),
  },
  infoSection: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid #ccc',
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    fontWeight: 'bold',
    flex: 0,
    minWidth: 140,
    paddingRight: 16,
  },
  descriptionWrapper: {
    display: 'block',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    background: palette.background.alt,
  },
  footer: {
    left: 0,
    right: 0,
    bottom: 0,
    background: '#ececec',
    padding: 16,
    height: heightOfFooter,
    position: 'absolute',
  },
  billSplitContainer: {
    margin: '8px 0px',
    border: '1px solid #eeeeee',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  selectableBillSplitContainer: {
    margin: '8px 0px',
    border: '1px solid #eeeeee',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#eeeeee',
      boxShadow: '0px 0px 5px 0px #ccc',
      borderColor: '#ececec',
    },
    cursor: 'pointer',
  },
  projectSplitContainer: {
    margin: '8px 0px',
    border: '1px solid #eeeeee',
    padding: theme.spacing(1),
  },
  selectableProjectSplitContainer: {
    margin: '8px 0px',
    border: '1px solid #eeeeee',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#eeeeee',
      boxShadow: '0px 0px 5px 0px #ccc',
      borderColor: '#ececec',
    },
    cursor: 'pointer',
  },
  selectableButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  paymentSplitContainer: {
    padding: theme.spacing(1),
  },
  selectablePaymentSplitContainer: {
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#eeeeee',
      boxShadow: '0px 0px 5px 0px #ccc',
      borderColor: '#ececec',
    },
    cursor: 'pointer',
  },
});
