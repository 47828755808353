import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ListOfConvos from '../../components/ListOfConvos/ListOfConvos.data';
import MessagesListData from './components/messagesList.data';
import ContentDetailsModal from '../add-to-project/content-details-modal';

const useStyles = makeStyles(() => ({
  scrollableColumn: {
    overflowY: 'scroll',
    height: 'calc(100vh - 64px)',
    overflowX: 'hidden',
  },
}));

const Messages = props => {
  const messagesRoute = '/messages/:projectId';

  const { history } = props;
  const routeMatch = useRouteMatch(messagesRoute);
  const classes = useStyles();
  const [projectInfo, setProjectInfo] = useState({});

  useEffect(() => {
    const currentId = projectInfo && projectInfo.contentId;
    if (
      routeMatch &&
      routeMatch.params &&
      routeMatch.params.projectId &&
      currentId !== routeMatch.params.projectId
    ) {
      setProjectInfo({ contentId: routeMatch.params.projectId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeMatch]);

  const onClickOfProject = passedProjectInfo => {
    setProjectInfo(passedProjectInfo);
    history.push(`/messages/${passedProjectInfo.contentId}`);
  };

  const [showManualAddDialog, setShowManualAddDialog] = useState({
    open: false,
    canEdit: false,
    canAdd: false,
    parentId: null,
  });

  return (
    <>
      <Grid container>
        <Grid item xs={4} lg={4} className={classes.scrollableColumn}>
          <ListOfConvos
            onClickOfProject={onClickOfProject}
            chosenConvoId={projectInfo.contentId}
            setShowManualAddDialog={setShowManualAddDialog}
          />
        </Grid>
        <Grid
          item
          xs={8}
          lg={8}
          className={classes.scrollableColumn}
          style={{ overflowY: 'auto' }}
        >
          <Switch>
            <Route path={messagesRoute}>
              <MessagesListData
                key={projectInfo.contentId}
                convoInfo={projectInfo}
                setShowManualAddDialog={setShowManualAddDialog}
              />
            </Route>
          </Switch>
        </Grid>
      </Grid>
      {showManualAddDialog.open && (
        <ContentDetailsModal
          mode={showManualAddDialog.mode}
          parentInfo={showManualAddDialog.projectInfo}
          existingInfo={showManualAddDialog.existingInfo}
          onClose={() => {
            setShowManualAddDialog({
              ...showManualAddDialog,
              open: false,
            });
          }}
        />
      )}
    </>
  );
};

export default Messages;
