import React, { useMemo } from 'react';
import _ from 'lodash';
import { InputLabel } from '@material-ui/core';
import moment from 'moment';
import FormikSelect from '../../../components/formik-custom-components/formik-select';

import {
  PAYMENT_DUE_IN_DAYS,
  PAYMENT_TERMS,
  PAYMENT_TERMS_LABELS,
} from './add-content-form.constants';

const PaymentTermsField = ({
  classes,
  disabled,
  values,
  error,
  touched,
  setFieldValue,
  onClose,
}) => {
  const paymentTermOptions = useMemo(() => {
    return _.map(_.keys(PAYMENT_TERMS), key => {
      const valueToUse = PAYMENT_TERMS[key];
      return { value: valueToUse, label: PAYMENT_TERMS_LABELS[valueToUse] };
    });
  }, []);

  const handleChange = event => {
    setFieldValue('paymentTerms', event.target.value);

    if (values.startDate && event.target.value !== PAYMENT_TERMS.OTHER) {
      const daysToAdd = PAYMENT_DUE_IN_DAYS[event.target.value];
      const newEndDate = moment(values.startDate).add(daysToAdd, 'days');
      setFieldValue('endDate', newEndDate, false);
    }
  };

  const showError = !!error && !!touched;

  return (
    <>
      <InputLabel
        className={classes.staticLabel}
        htmlFor="paymentTerms"
        disabled={disabled}
        error={showError}
      >
        Payment Terms
      </InputLabel>
      <FormikSelect
        name="paymentTerms"
        value={values.paymentTerms || ''}
        error={showError ? error : null}
        onClose={onClose}
        options={paymentTermOptions}
        handleChange={handleChange}
        disabled={disabled}
      />
    </>
  );
};

export default PaymentTermsField;
