/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose, withApollo } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import uuid from 'uuid';
import cloudinary from 'cloudinary-core';
import { animateScroll as scroller } from 'react-scroll';
import ReactHtmlParser from 'react-html-parser';

import {
  Grid,
  Button,
  IconButton,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';

import {
  ArrowForward as ArrowForwardIcon,
  AttachMoney as PayIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  GetApp as GetAppIcon,
  MoreHoriz as MoreHorizIcon,
  SwapHorizOutlined as SwapHorizOutlinedIcon,
  ViewQuilt as ViewQuiltIcon,
  InfoOutlined as InfoOutlinedIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';

import ContentImage from './ContentTypes/content-image';
import ContentGallery from './ContentTypes/contentGallery';
import ContentVideo from './ContentTypes/contentVideo';
import ContentTextnote from './ContentTypes/contentTextnote';
import ContentTimetracking from './ContentTypes/contentTimetracking';
import ContentTask from './ContentTypes/contentTask';
import ContentProjectOrLead from './ContentTypes/contentProjectOrLead';

import {
  UpdateContentStatusMutationAction,
  DeleteContentAction,
  DeleteJrnAction,
  CreateCommentMutationAction,
  MoveContentToOtherProjectAction,
  ListCompanyProjectsAction,
} from '../../graphql/graphql';

import {
  cloudinaryifyProfilePic,
  cloudinaryDownloadUrl,
  downloadAFileFromAUrl,
  publicIdFromUrl,
} from '../../helpers/cloudinary';

import { runAnalytics, daysAfterEpoch } from '../../helpers';

import {
  cloudindaryCloudName,
  FINANCIAL_TYPES,
  ALLOWED_TYPES_TO_MOVE,
  RFI_SOURCES,
  GLOBAL_EXPENSE_STATUS,
  CONTENT_DEFINITION,
  CONTENT_TYPE,
  PRODUCT,
} from '../../config/appDefaults';

import { renderDateString } from '../../helpers/renderDateString';
import Comments from './Comments/comments';
import CommentInput from './Comments/CommentInput';
import OkCancelDialog from '../OkCancelDialog/okCancelDialog';

import { TopParentContext } from '../../views/Projects/components/ProjectDetails/projectDetails.data';
import GetAllContentByGlobalExpenseId from '../../graphql/queries/get-all-content-by-global-expense-id';
import GetAllContentByJrnId from '../../graphql/queries/GetAllContentByJrnId';

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2),
  },
  noContent: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  projectDetailsWrapper: {
    paddingRight: theme.spacing(2),
    // marginRight: theme.spacing(2),
    paddingTop: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    borderBottom: `2px solid ${theme.palette.brandColorPrimary}`,
  },
  filterWrapper: {
    backgroundColor: '#f4f4f4',
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderLeft: `5px solid ${theme.palette.brandColorPrimary}`,
    borderRight: `5px solid ${theme.palette.brandColorPrimary}`,
  },
  filterButton: {
    color: theme.palette.brandColorPrimary,
    backgroundColor: '#fff',
  },
  filterButtonActive: {
    color: '#fff',
    backgroundColor: theme.palette.brandColorPrimary,
  },
  headerWrapper: {
    padding: theme.spacing(1),
  },
  headerMoreIcon: {
    alignSelf: 'flex-end',
  },
  footerWrapper: {
    width: '100%',
    padding: theme.spacing(1),
  },
  descriptionWrapper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    display: 'block',
  },
  commentsWrapper: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderTop: '1px solid #f6f6f6',
  },
  closeModal: {
    position: 'absolute',
    top: -60,
    right: -80,
    fontSize: 60,
    color: '#fff',
  },
  moreButton: {
    fontSize: 30,
  },
  modeButtonListView: {
    marginRight: 0,
    fontSize: 30,
  },
  actionButtons: {
    color: theme.palette.brandColorDarkGrey,
    alignItems: 'center',
    justifyContent: 'flex-start',
    display: 'flex',
  },
  drawerPaper: {
    width: '90%',
  },
  rfiIndicator: {
    color: '#fff',
    backgroundColor: theme.palette.brandColorOrange,
    padding: 8,
    borderRadius: 4,
  },
  rfiIcon: {
    color: '#fff',
    marginRight: 4,
  },
}));

// //////////////////////////////////////////////

const ContentItem = props => {
  const {
    contentItem,
    contentType,
    allUsers,
    projectCanEdit,
    projectCanAdd,
    onDeleteContent,
    onDeleteJrn,
    onAddComment,
    handleEdit,
    handleSplit,
    onMarkBillAsPaid,
    onViewGlobalItem,
    isThisExtTask,
    fromDeepLink,
    projectInfo: parentInfo,
    // location,
    history,
    handleMove,

    // mapStateToProps
    userId,
    userInfo,

    // graphql
    companyProjects,

    // client,
    handleViewOrEditRfi,

    client,
    managingCompanyInfo,
  } = props;
  const isBookkeeper = _.get(managingCompanyInfo, 'isCompanyBookkeeper', false);
  const hasRfiProduct = managingCompanyInfo?.products?.includes(PRODUCT.RFI);

  // const handleShare = passedContent => {
  //   let sharePath;
  //   if (passedContent.subtype === 'extended' && passedContent.type === 'task') {
  //     sharePath = `${location.pathname}/${passedContent.contentId}`;
  //   } else {
  //     sharePath = `${location.pathname}?cid=${passedContent.contentId}`;
  //   }
  //   const shareUrl = `${window.location.protocol}//${window.location.host}${sharePath}`;
  //   navigator.clipboard.writeText(shareUrl);
  // };
  const hasGlobalExpense =
    contentItem.globalExpenseId && !!contentItem.globalExpense;
  const isFinancial = FINANCIAL_TYPES.includes(contentItem.type);
  const topParentInfo = React.useContext(TopParentContext);
  const isInTemplate = topParentInfo && topParentInfo.type === 'template';
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showMessageDialog, setShowMessageDialog] = useState({
    open: false,
    title: '',
    message: '',
  });
  const isCreator = userId === contentItem.creatorId;
  const isTask =
    contentItem.type === 'task' || contentItem.type === 'punchlistItem';
  const cl = new cloudinary.Cloudinary({
    cloud_name: cloudindaryCloudName,
    secure: true,
  });
  const imageTranformWidth = parseInt(window.innerWidth, 10) || 500;

  const pdfUrl = imageInfo => {
    if (_.includes(imageInfo, 'cloudinary')) {
      return cl.url(publicIdFromUrl(imageInfo), {
        quality: 'auto',
        width: imageTranformWidth,
        crop: 'scale',
        fetchFormat: 'pdf',
      });
    }
    return imageInfo;
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleShowCommentInput = () => {
    setShowCommentInput(!showCommentInput);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const downloadAllFromItem = () => {
    closeMenu();
    // get all the url's and prompt for downloads/saving
    const resourceUris = [];

    if (contentItem.type === CONTENT_TYPE.PDF) {
      const resourceUrl = contentItem.contentUrl || null;
      if (resourceUrl) {
        resourceUris.push(pdfUrl(resourceUrl));
      }
    } else if (contentItem.type === CONTENT_TYPE.GALLERY || isTask) {
      try {
        resourceUris.push(
          ...(JSON.parse(contentItem.contentUrl)?.map(({ uri }) => uri) || [])
        );
      } catch (e) {
        // Ignore error
        // eslint-disable-next-line no-console
        console.error('downloadAllFromItem ~ e:', e);
      }
    } else if (hasGlobalExpense) {
      resourceUris.push(contentItem.globalExpense.contentUrl);
    } else {
      resourceUris.push(contentItem.contentUrl);
    }

    resourceUris.forEach((uri, index) => {
      // Create a download link
      const downloadUrl = cloudinaryDownloadUrl(uri);
      // Trigger download of it
      setTimeout(() => downloadAFileFromAUrl(downloadUrl), index * 1000);
    });
  };

  const deleteContent = content => {
    const contentIdToDelete = content.contentId;
    const parentProjectId = content.jrnId;
    onDeleteContent(contentIdToDelete, parentProjectId);
    const options = {
      contentAction: 'Delete Content',
      projectId: parentProjectId,
      userId: content.creatorId,
      username: userInfo.username,
      type: content.type,
      contentId: contentIdToDelete,
      email: userInfo.email,
    };
    runAnalytics('Contents', options);
  };

  const deleteGlobalExpense = globalExpenseItem => {
    onDeleteJrn(globalExpenseItem.contentId, globalExpenseItem.type);

    const options = {
      contentAction: `Delete ${
        CONTENT_DEFINITION[globalExpenseItem.type].name
      }`,
      projectId: globalExpenseItem.jrnId,
      userId: userInfo.userId,
      username: userInfo.username,
      type: globalExpenseItem.type,
      contentId: globalExpenseItem.contentId,
      email: userInfo.email,
    };
    runAnalytics('Contents', options);
  };

  const handleDeleteConfirm = async () => {
    if (hasGlobalExpense) {
      // get all contents for this global expense
      let allContents = [];
      try {
        const contentQuery = await client.query({
          query: GetAllContentByGlobalExpenseId,
          variables: { globalExpenseId: contentItem.globalExpenseId },
          fetchPolicy: 'network-only',
        });
        allContents = _.get(
          contentQuery,
          'data.getAllContentByGlobalExpenseId.items',
          []
        );
      } catch (e) {
        // ignore error
      }

      const expenses = [];
      const billPayments = [];
      _.forEach(allContents, content => {
        if (content.type === CONTENT_TYPE.BILL_PAYMENT) {
          billPayments.push(content);
        } else {
          expenses.push(content);
        }
      });

      // global expense item has only one project
      if (expenses.length <= 1) {
        let allowToDelete = true;
        if (
          contentItem.globalExpense.type === CONTENT_TYPE.GLOBAL_BILL &&
          !_.isEmpty(billPayments)
        ) {
          // global bill has bill payments attached to it
          allowToDelete = false;
          setShowMessageDialog({
            open: true,
            message:
              'Cannot delete this bill as it has payments. Please delete all the payments first!',
          });
        }

        if (allowToDelete) {
          // delete content item
          deleteContent(contentItem);

          // delete global expense
          deleteGlobalExpense(contentItem.globalExpense);
        }
      } else {
        // global expense item has more than one project
        // open editing modal to edit the global expense item
        // and remove this content item by setting its deletionPending flag to true.

        setShowMessageDialog({
          title: 'Info',
          open: true,
          message: `This ${contentItem.type} is shared with other projects. You will be redirected to the editing form to allocate the remaining amount.`,
          onConfirm: () => {
            setShowMessageDialog({ open: false });
            handleEdit(contentItem.globalExpense, {
              contentToDelete: contentItem,
            });
          },
        });
      }
    } else {
      let allowToDelete = true;
      // content item is a subproject
      if (contentItem.type === CONTENT_TYPE.PROJECT) {
        // check if the subproject has any payments attached to it
        const contentQuery = await client.query({
          query: GetAllContentByJrnId,
          variables: { jrnId: contentItem.contentId },
          fetchPolicy: 'no-cache',
        });

        if (!_.isEmpty(contentQuery.data.getAllContentByJrnId.items)) {
          // subproject has content attached to it
          allowToDelete = false;
          setShowMessageDialog({
            open: true,
            message:
              'Cannot delete this subproject as it has content attached to it.',
          });
        }
      }

      if (allowToDelete) {
        deleteContent(contentItem);
      }
    }
  };

  const handleConvert = globalExpense => {
    const globalExpenseToConvert = { ...globalExpense };
    if (globalExpenseToConvert.type === CONTENT_TYPE.GLOBAL_BILL) {
      // convert bill to receipt
      globalExpenseToConvert.type = CONTENT_TYPE.GLOBAL_RECEIPT;
      globalExpenseToConvert.contentStatus = null;
      globalExpenseToConvert.documentNumber = null;
      globalExpenseToConvert.paymentTerms = null;
    } else {
      // convert receipt to bill
      globalExpenseToConvert.type = CONTENT_TYPE.GLOBAL_BILL;
      globalExpenseToConvert.contentStatus = GLOBAL_EXPENSE_STATUS.UNPAID;
      // documentNumber and paymentTerms will be asked in the addContentForm
    }
    handleEdit(globalExpenseToConvert);
  };

  const onClickofSubProject = () => {
    scroller.scrollTo('topOfProjects', {
      duration: 1500,
      delay: 0,
      smooth: true,
      containerId: 'ContainerElementID',
    });
  };

  const onClickofLead = () => {
    history.push(
      `/customers/${contentItem.customerId}/lead/${contentItem.contentId}`
    );
  };

  const setTaskStatus = async (
    taskItem,
    contentStatus,
    completionInfo,
    originalFiles
  ) => {
    const { onUpdateContentStatus } = props;
    const updateContents = {
      jrnId: taskItem.jrnId,
      contentId: taskItem.contentId,
      contentStatus,
    };
    if (completionInfo) {
      updateContents.completionInfo = completionInfo;
    }
    // need to update with the completion info based on completionType here so the upload filetype gets set properly
    const options = { customFolder: taskItem.jrnId };
    if (['images', 'video', 'pdf'].includes(taskItem.completionType)) {
      options.type = taskItem.completionType;
    }

    try {
      return onUpdateContentStatus(updateContents, options, originalFiles);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('err: ', err);
    }
    return null;
  };

  const handleCreateRfi = () => {
    let baseInfo;

    if (!hasGlobalExpense) {
      // figure out of it's money in our money out
      // parentInfo
      const parentHasParent =
        parentInfo && parentInfo.jrnId && parentInfo.jrnId !== '00000';

      const parentPath = [parentInfo.title];

      // if parentHasParent then we need to get the title of the parent
      if (parentHasParent) {
        const grandParentInfo = _.find(companyProjects, {
          contentId: parentInfo.jrnId,
        });
        if (grandParentInfo) {
          parentPath.unshift(grandParentInfo.title);
        }
      }

      baseInfo = {
        requestId: uuid(),
        txnDate: moment(),
        dateCreated: moment().toISOString(),
        local: true,
        requestSource: RFI_SOURCES.LEVEL.value,
        idOfRelatedItem: contentItem.contentId,
        parentId: parentInfo.contentId,
        parentPath,
      };
    } else {
      const { globalExpense: globalItem } = contentItem;

      baseInfo = {
        requestId: uuid(),
        txnDate: moment(),
        dateCreated: moment().toISOString(),
        local: true,
        requestSource: RFI_SOURCES.LEVEL.value,
        idOfRelatedItem: globalItem.contentId,
        projectId: null,
        parentPath: null,
      };
    }

    handleViewOrEditRfi({
      newRfiInfo: {
        baseInfo,
      },
      fromWhichJrnContent: {
        jrnId: contentItem.jrnId,
        contentId: contentItem.contentId,
      },
    });
  };

  const renderMenu = () => {
    let contentUrlAsArray;
    if (isTask && typeof contentItem.contentUrl === 'string') {
      try {
        contentUrlAsArray = JSON.parse(contentItem.contentUrl);
      } catch (error) {
        contentUrlAsArray = [];
      }
    }

    // Can edit, either full edit or admin field edit
    let canEdit = false;
    if (contentItem.type === 'project') {
      // If subproject, force user to go to the subproject page to edit, delete, etc
    } else if (projectCanEdit) {
      canEdit = true;
    } else if (isCreator && projectCanAdd) {
      canEdit = true;
    }

    // Can delete
    let canDelete = false;
    if (contentItem.type === 'project') {
      // If subproject, force user to go to the subproject page to edit, delete, etc
    } else if (contentItem.type === 'timetracking') {
      canDelete = projectCanEdit;
    } else {
      canDelete =
        projectCanEdit || (userId === contentItem.creatorId && projectCanAdd);
    }

    // Can download
    let financialDownload = false;
    if (isFinancial) {
      const arrayAndLength = contentUrlAsArray && contentUrlAsArray.length > 0;
      const contentUrlIsString = typeof contentItem.contentUrl === 'string';
      if (arrayAndLength || contentUrlIsString) {
        financialDownload = true;
      }
    }
    const canDownload =
      financialDownload ||
      (isTask && contentUrlAsArray && contentUrlAsArray.length > 0) ||
      (isFinancial && contentUrlAsArray && contentUrlAsArray.length > 0) ||
      contentItem.type === 'gallery' ||
      contentItem.type === 'image' ||
      contentItem.type === 'video' ||
      contentItem.type === 'pdf';

    const renderMenuItems = () => {
      const menuItemsToReturn = [];

      if (canEdit && hasGlobalExpense) {
        if (
          contentItem.globalExpense.type === CONTENT_TYPE.GLOBAL_BILL &&
          (contentItem.globalExpense.contentStatus ===
            GLOBAL_EXPENSE_STATUS.UNPAID ||
            contentItem.globalExpense.contentStatus ===
              GLOBAL_EXPENSE_STATUS.PARTIALLY_PAID)
        ) {
          menuItemsToReturn.push(
            <MenuItem
              key="markAsPaid"
              onClick={() => {
                closeMenu();
                onMarkBillAsPaid(contentItem.globalExpense);
              }}
              className={classes.actionButtons}
            >
              <PayIcon />
              &nbsp;&nbsp;Make a Payment
            </MenuItem>
          );
        }

        menuItemsToReturn.push(
          <MenuItem
            key="view"
            onClick={() => {
              closeMenu();
              onViewGlobalItem(contentItem.globalExpense);
            }}
            className={classes.actionButtons}
          >
            <VisibilityIcon />
            &nbsp;&nbsp;View{' '}
            {CONTENT_DEFINITION[contentItem.globalExpense.type].name}
          </MenuItem>
        );

        if (
          managingCompanyInfo.isCompanyAdmin &&
          (contentItem.globalExpense.type === CONTENT_TYPE.GLOBAL_RECEIPT ||
            (contentItem.globalExpense.type === CONTENT_TYPE.GLOBAL_BILL &&
              contentItem.globalExpense.contentStatus ===
                GLOBAL_EXPENSE_STATUS.UNPAID))
        ) {
          menuItemsToReturn.push(
            <MenuItem
              key="convert"
              onClick={() => {
                closeMenu();
                handleConvert(contentItem.globalExpense);
              }}
              className={classes.actionButtons}
            >
              <SwapHorizOutlinedIcon />
              &nbsp;&nbsp;Convert To{' '}
              {contentItem.globalExpense.type === CONTENT_TYPE.GLOBAL_BILL
                ? 'Receipt'
                : 'Bill'}
            </MenuItem>
          );
        }
      }
      if (canEdit) {
        let whatToEdit = contentItem;
        if (contentItem.globalExpense) {
          whatToEdit = contentItem.globalExpense;
        }

        menuItemsToReturn.push(
          <MenuItem
            key="edit"
            onClick={() => {
              closeMenu();
              handleEdit(whatToEdit);
            }}
            className={classes.actionButtons}
          >
            <EditIcon />
            &nbsp;&nbsp;Edit This
          </MenuItem>
        );
      }
      if (canEdit && isFinancial && !hasGlobalExpense) {
        menuItemsToReturn.push(
          <MenuItem
            key="split"
            onClick={() => {
              closeMenu();
              handleSplit(contentItem);
            }}
            className={classes.actionButtons}
          >
            <ViewQuiltIcon />
            &nbsp;&nbsp;Split This
          </MenuItem>
        );
      }
      if (
        canEdit &&
        ALLOWED_TYPES_TO_MOVE.includes(contentItem.type) &&
        !hasGlobalExpense
      ) {
        menuItemsToReturn.push(
          <MenuItem
            key="move"
            onClick={() => {
              closeMenu();
              handleMove(contentItem);
            }}
            className={classes.actionButtons}
          >
            <ArrowForwardIcon />
            &nbsp;&nbsp;Move This
          </MenuItem>
        );
      }

      if (!isInTemplate && FINANCIAL_TYPES.includes(contentItem.type)) {
        const rfiIdsToUse = hasGlobalExpense
          ? contentItem.globalExpense.rfiIds
          : contentItem.rfiIds;

        const existingRfiId =
          rfiIdsToUse && rfiIdsToUse.length && rfiIdsToUse[0];

        if (existingRfiId) {
          // make sure there's something in the list and that it's not falsey (i.e. null wasn't passed in accidentally)
          menuItemsToReturn.push(
            <MenuItem
              key="viewRfi"
              onClick={() => {
                closeMenu();
                handleViewOrEditRfi({ requestId: existingRfiId });
              }}
              className={classes.actionButtons}
            >
              <InfoOutlinedIcon />
              &nbsp;&nbsp;View RFI
            </MenuItem>
          );
        } else if (isBookkeeper && hasRfiProduct) {
          menuItemsToReturn.push(
            <MenuItem
              key="createRfi"
              onClick={() => {
                closeMenu();
                handleCreateRfi();
              }}
              className={classes.actionButtons}
            >
              <InfoOutlinedIcon />
              &nbsp;&nbsp;Create RFI
            </MenuItem>
          );
        }
      }
      if (canDownload) {
        menuItemsToReturn.push(
          <MenuItem
            key="download"
            onClick={downloadAllFromItem}
            className={classes.actionButtons}
          >
            <GetAppIcon />
            &nbsp;&nbsp;Download
            {contentItem.type === 'gallery' || isTask ? ' All' : null}
          </MenuItem>
        );
      }
      if (canDelete) {
        menuItemsToReturn.push(
          <MenuItem
            key="delete"
            onClick={() => {
              closeMenu();
              // dialog asking if they're sure
              setDeleteModalOpen(true);
            }}
            className={classes.actionButtons}
          >
            <DeleteIcon />
            &nbsp;&nbsp;Delete This
          </MenuItem>
        );
      }

      if (!menuItemsToReturn.length) {
        menuItemsToReturn.push(
          <MenuItem
            key="noOptions"
            className={classes.actionButtons}
            onClick={closeMenu}
          >
            <CloseIcon />
            &nbsp;&nbsp;No Options
          </MenuItem>
        );
      }
      return menuItemsToReturn;
    };
    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        {renderMenuItems()}
      </Menu>
    );
  };

  const renderMore = notEdge => {
    return (
      <IconButton
        className={classes.moreButton}
        edge={notEdge ? null : 'end'}
        aria-label="delete"
        onClick={handleClick}
      >
        <MoreHorizIcon fontSize="inherit" />
      </IconButton>
    );
  };

  const renderContentHeader = contentPiece => {
    let user;
    if (isInTemplate) {
      user = userInfo;
    } else {
      user = _.find(allUsers, { userId: contentPiece.creatorId });
    }
    if (!user) {
      return null;
    }

    let dateString;
    if (isInTemplate) {
      const diffStartFromStart = daysAfterEpoch(contentPiece.date) + 1;
      dateString = `Day ${diffStartFromStart} @ ${moment(contentPiece.date)
        .utc()
        .format('h:mma')}`;
    } else {
      const formatString =
        contentPiece.type === CONTENT_TYPE.BILL && hasGlobalExpense
          ? 'MMM D, YYYY'
          : 'MMM D, YYYY @ h:mma';
      dateString = renderDateString(contentPiece.date, null, formatString);
    }

    return (
      <>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.headerWrapper}
        >
          <Grid item>
            <Grid container alignItems="center" direction="row">
              <Grid item>
                <ListItemAvatar>
                  <Avatar
                    alt={user.username}
                    src={
                      user.profilePic
                        ? cloudinaryifyProfilePic(user.profilePic)
                        : null
                    }
                    className={classes.headerProfilePic}
                  />
                </ListItemAvatar>
              </Grid>
              <Grid item>
                <ListItemText primary={user.username} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {dateString}
            {renderMenu()}
            {renderMore()}
          </Grid>
        </Grid>
      </>
    );
  };

  const renderContentFooter = contentPiece => (
    <Grid container direction="column" className={classes.footerWrapper}>
      <Grid item>
        {!!contentPiece.description &&
          contentPiece.type !== 'textnote' &&
          contentPiece.type !== 'task' &&
          contentPiece.type !== 'punchlistItem' && (
            <Grid
              container
              direction="row"
              className={classes.descriptionWrapper}
            >
              <div className="levelParsedHtml">
                {ReactHtmlParser(contentPiece.description)}
              </div>
            </Grid>
          )}
      </Grid>
    </Grid>
  );

  const renderContentComments = contentPiece => {
    // no commented if viewing a template
    if (isInTemplate) return null;
    return (
      <Grid container direction="column" className={classes.commentsWrapper}>
        <Comments comments={contentPiece.comments.items} allUsers={allUsers} />
        {showCommentInput ? (
          <CommentInput
            addCommentAction={onAddComment}
            contentItem={contentItem}
          />
        ) : (
          <Button onClick={handleShowCommentInput}>+ Add comment</Button>
        )}
      </Grid>
    );
  };

  const renderContentContent = contentPiece => {
    switch (contentPiece.type) {
      case 'image': {
        return <ContentImage contentPiece={contentPiece} />;
      }
      case 'pdf': {
        return <ContentImage contentPiece={contentPiece} />;
      }
      case 'receipt':
      case 'bill':
      case 'invoice':
      case 'payment': {
        return (
          <ContentImage
            contentPiece={contentPiece}
            projectCanEdit={projectCanEdit}
            projectCanAdd={projectCanAdd}
            isCreator={isCreator}
            allUsers={allUsers}
          />
        );
      }
      case 'gallery': {
        return <ContentGallery noMaxHeight contentPiece={contentPiece} />;
      }
      case 'video': {
        return (
          <ContentVideo
            key={contentPiece.contentUrl} // video wasnt visually updating without this on edit
            contentPiece={contentPiece}
          />
        );
      }
      case 'textnote': {
        return <ContentTextnote contentPiece={contentPiece} />;
      }
      case 'punchlistItem':
      case 'task': {
        return (
          <ContentTask
            contentPiece={contentPiece}
            allUsers={allUsers}
            projectCanEdit={projectCanEdit}
            projectCanAdd={projectCanAdd}
            setTaskStatus={setTaskStatus}
            renderMenu={renderMenu}
            renderMore={renderMore}
            isThisExtTask={isThisExtTask}
            parentInfo={parentInfo}
            templateInfo={topParentInfo}
          />
        );
      }
      case 'timetracking': {
        return (
          <ContentTimetracking
            contentPiece={contentPiece}
            allUsers={allUsers}
            projectCanEdit={projectCanEdit}
            renderMenu={renderMenu}
            renderMore={renderMore}
          />
        );
      }
      case 'project': {
        return (
          <ContentProjectOrLead
            contentPiece={contentPiece}
            onClick={onClickofSubProject}
            projectCanEdit={projectCanEdit}
            templateInfo={topParentInfo}
          />
        );
      }
      case 'lead': {
        return (
          <ContentProjectOrLead
            contentPiece={contentPiece}
            onClick={onClickofLead}
          />
        );
      }
      default: {
        break;
      }
    }
    return null;
  };

  const renderFeedContent = contentPiece => {
    const showHeaderFor = [
      'image',
      'gallery',
      'video',
      'textnote',
      'pdf',
      ...FINANCIAL_TYPES,
    ];
    const showFooterFor = [
      'image',
      'gallery',
      'video',
      'textnote',
      'pdf',
      ...FINANCIAL_TYPES,
    ];
    const showCommentsFor = [
      'image',
      'gallery',
      'video',
      'textnote',
      'pdf',
      ...FINANCIAL_TYPES,
    ];

    const showHeader = showHeaderFor.indexOf(contentPiece.type) >= 0;
    const showFooter = showFooterFor.indexOf(contentPiece.type) >= 0;
    const showComments = showCommentsFor.indexOf(contentPiece.type) >= 0;

    return (
      <Grid container className={classes.contentWrapper} direction="column">
        {showHeader && (
          <Grid item style={{ width: '100%' }}>
            {renderContentHeader(contentPiece)}
          </Grid>
        )}
        <Grid item style={{ width: '100%' }}>
          {renderContentContent(contentPiece)}
        </Grid>
        {showFooter && <Grid item>{renderContentFooter(contentPiece)}</Grid>}
        {showComments && !fromDeepLink && (
          <Grid item>{renderContentComments(contentPiece)}</Grid>
        )}
      </Grid>
    );
  };

  return (
    <>
      {contentType === 'task' && !isThisExtTask ? (
        <Grid container className={classes.contentWrapper}>
          <Grid item style={{ width: '100%' }}>
            {renderContentContent(contentItem)}
          </Grid>
          <Grid item style={{ width: '100%' }}>
            {renderContentComments(contentItem)}
          </Grid>
        </Grid>
      ) : (
        renderFeedContent(contentItem)
      )}
      {deleteModalOpen && (
        <OkCancelDialog
          open={deleteModalOpen}
          title="Just making sure..."
          okButtonText="Yes"
          okButtonVariant="text"
          autoFocusButton="cancelButton"
          onClose={() => {
            setDeleteModalOpen(false);
          }}
          onConfirm={handleDeleteConfirm}
        >
          <Typography>
            Are you sure you want to erase this piece of content? This cannot be
            undone.
          </Typography>
        </OkCancelDialog>
      )}
      {showMessageDialog.open && (
        <OkCancelDialog
          open={showMessageDialog.open}
          title={showMessageDialog.title || 'Oops...'}
          okButtonVariant="text"
          hideCancel
          onConfirm={
            showMessageDialog.onConfirm
              ? showMessageDialog.onConfirm
              : () => {
                  setShowMessageDialog({ open: false });
                }
          }
        >
          <Typography>
            {showMessageDialog.message || 'Something went wrong.'}
          </Typography>
        </OkCancelDialog>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    userId: state.userInfo.userId,
    userInfo: state.userInfo,
    managingCompanyInfo: state.appState.managingCompanyInfo,
  };
}

export default compose(
  // Mutations
  UpdateContentStatusMutationAction,
  DeleteContentAction,
  DeleteJrnAction,
  CreateCommentMutationAction,
  MoveContentToOtherProjectAction,
  ListCompanyProjectsAction,

  withApollo
)(connect(mapStateToProps)(withRouter(ContentItem)));
