import React from 'react';
import { useQuery } from 'react-apollo-hooks';

import {
  CONTENT_TYPE,
  CONTENT_DETAILS_MODAL_MODE,
  GLOBAL_FINANCIAL_TYPES,
  FINANCIAL_TYPES,
} from '../../config/appDefaults';
import GetJrn from '../../graphql/queries/GetJrn';
// eslint-disable-next-line import/no-cycle
import ContentDetailsModal from '../add-to-project/content-details-modal';

const RfiViewRelatedContent = ({ contentToShow, handleClose }) => {
  // If content has jrnId, load the parent info
  const parentInfoQuery = useQuery(GetJrn, {
    variables: {
      jrnId: contentToShow.jrnId,
    },
    skip: !(contentToShow.jrnId && contentToShow.jrnId !== '00000'),
  });

  const parentInfo = parentInfoQuery.data ? parentInfoQuery.data.getJrn : null;

  let isReady = false;

  let contentDetailsMode;
  let contentParentInfo;
  if (GLOBAL_FINANCIAL_TYPES.includes(contentToShow.type)) {
    contentDetailsMode =
      contentToShow.type === CONTENT_TYPE.GLOBAL_RECEIPT
        ? CONTENT_DETAILS_MODAL_MODE.ADMIN_RECEIPT
        : CONTENT_DETAILS_MODAL_MODE.ADMIN_BILL;
    contentParentInfo = null;
    isReady = true;
  } else if (FINANCIAL_TYPES.includes(contentToShow.type) && parentInfo) {
    contentDetailsMode =
      contentToShow.type === CONTENT_TYPE.GLOBAL_RECEIPT
        ? CONTENT_DETAILS_MODAL_MODE.ADMIN_RECEIPT
        : CONTENT_DETAILS_MODAL_MODE.ADMIN_BILL;
    contentParentInfo = parentInfo;
    isReady = true;
  } else {
    contentDetailsMode = CONTENT_DETAILS_MODAL_MODE.EDIT;
    contentParentInfo = parentInfo;
    isReady = true;
  }

  return (
    <ContentDetailsModal
      loading={!isReady}
      mode={contentDetailsMode}
      parentInfo={contentParentInfo}
      existingInfo={contentToShow}
      onClose={handleClose}
      readonly
      hideFooter
      rfiViewMode
    />
  );
};

export default RfiViewRelatedContent;
