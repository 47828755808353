import { getAPresignedUrl, uploadToPresignedUrl } from '../graphql/graphql';

export const cloudinaryifyProfilePic = (src, isListPic) => {
  // const windowDimensions = Dimensions.get('window');
  const whatToLookFor = '/upload/';
  const whatToLookForIndex = src.lastIndexOf(whatToLookFor);
  const urlLeft = src.substring(0, whatToLookForIndex + whatToLookFor.length);
  const splitSrc = [
    urlLeft,
    `/${src.substring(whatToLookForIndex + whatToLookFor.length)}`,
  ];
  // const width = parseInt(windowDimensions.height / 4, 10); // get the pre-decimal value
  const width = 100;
  let transformationsString = '';
  if (!isListPic || typeof isListPic !== 'boolean') {
    const transformations = [];
    transformations.push(`w_${width}`);
    transformations.push(`h_${width}`);
    transformations.push('q_100');
    transformations.push('c_fill');
    transformationsString = transformations.join(',');
  } else {
    transformationsString = 'w_56,h_56,c_fit,q_100';
  }
  return `${splitSrc[0]}${transformationsString}${splitSrc[1]}`;
};

export const publicIdFromUrl = url => {
  if (!url) return null;
  const splitUrl = url.split('/');
  let cloudindaryPublicId;
  const lookFor = 'upload';
  const indexOfUpload = splitUrl.indexOf(lookFor);
  if (indexOfUpload) {
    const piece1 = splitUrl
      .slice(indexOfUpload + 1, splitUrl.length - 1)
      .join('/');
    const piece2 = splitUrl[splitUrl.length - 1].split('.')[0];
    cloudindaryPublicId = `${piece1}/${piece2}`;
  }
  return cloudindaryPublicId;
};

export const cloudinaryDownloadUrl = url => {
  if (url.indexOf('/upload/') > -1) {
    return url.replace('/upload/', '/upload/fl_attachment/');
  }
  return url;
};

export const downloadAFileFromAUrl = url => {
  const element = document.createElement('a');
  element.href = url;
  element.style.display = 'none';
  // element.download = url.split('/').pop();
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const cloudinaryToStreamUrl = srcUrl => {
  const lastIndexOfDot = srcUrl.lastIndexOf('.');
  const streamingUrl = `${srcUrl.substring(0, lastIndexOfDot)}.m3u8`;
  return streamingUrl;
};

export const cloudinaryVideoThumbnail = uri => {
  // drop the filename off the video uri
  const dotIndex = uri.lastIndexOf('.');
  // add on "jpg"
  return `${uri.substring(0, dotIndex)}.jpg`;
};

export const onUploadFile = async (
  passedFile,
  passedFolderId,
  passedTypeOfFile
) => {
  // add content mutation herrrrrrrrre

  const typeOfFile = passedTypeOfFile || 'image';

  const contentFolder = passedFolderId;

  const presignUrlParams = {
    typeOfFile,
    customFolder: contentFolder,
  };
  const presignedUrl = await getAPresignedUrl(presignUrlParams);

  const uploadedResponse = await uploadToPresignedUrl(
    passedFile,
    presignedUrl.signature,
    presignedUrl.timestamp,
    typeOfFile,
    false, // since this will never be called for profile photos
    false, // pass the custome filename if it's there
    contentFolder // pass parent project id for custom folder
  );

  const toReturn = {
    uri: uploadedResponse.response.secure_url,
  };

  if (typeOfFile === 'image') {
    const aspectRatio =
      uploadedResponse.response.width / uploadedResponse.response.height;
    toReturn.aspectRatio = aspectRatio;
  }

  return toReturn;
};
