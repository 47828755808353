import React from 'react';
import ContentLoader from 'react-content-loader';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import _ from 'lodash';
import moment from 'moment';
import cloudinary from 'cloudinary-core';

import {
  publicIdFromUrl,
  cloudinaryifyProfilePic,
  cloudinaryDownloadUrl,
} from '../../../helpers/cloudinary';
import { cloudindaryCloudName } from '../../../config/appDefaults';

const cl = new cloudinary.Cloudinary({
  cloud_name: cloudindaryCloudName,
  secure: true,
});
const imageTranformWidth = parseInt(window.innerWidth, 10) || 500;
const imageTranformHeight = Math.round(window.innerHeight * 0.75);

const useStyles = makeStyles(theme => ({
  textNoteWrapper: {
    padding: theme.spacing(2),
  },
  bigAvatarWrapper: {
    margin: 10,
    width: 60,
    height: 60,
    backgroundColor: '#f4f4f4',
    borderRadius: 30,
  },
  bigAvatarImage: {
    // margin: 10,
    width: 60,
    height: 60,
    backgroundColor: '#f4f4f4',
    borderRadius: 30,
  },
  image: {
    maxHeight: '50vh',
    minHeight: '30vh',
    maxWidth: '100%',
    height: 'auto',
  },
  viewPhotoButton: {
    padding: 0,
    margin: 0,
  },
  moreButtonStyle: {
    paddingRight: theme.spacing(1),
  },
}));

const ContentTimetracking = props => {
  const {
    contentPiece,
    allUsers,
    projectCanEdit,
    hideDownload,
    renderMenu,
    renderMore,
  } = props;
  const [showModal, setShowModal] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(true);
  const classes = useStyles();

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleImageLoaded = () => {
    setShowLoader(false);
  };

  const thisUrl = imageInfo => {
    if (_.includes(imageInfo, 'cloudinary')) {
      return cl.url(publicIdFromUrl(imageInfo), {
        quality: 'auto',
        width: imageTranformWidth,
        height: imageTranformHeight,
        crop: 'limit',
        fetchFormat: 'jpg',
      });
    }
    return imageInfo;
  };

  const user = _.find(allUsers, { userId: contentPiece.assignedTo });
  if (!user) return null;

  let stringColor = contentPiece.contentStatus === 'checkin' ? 'green' : 'red';

  if (contentPiece.isInvalid) {
    stringColor = '#cccccc';
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.bigAvatarWrapper}
        >
          <ListItemAvatar>
            <Avatar
              alt={user.username}
              className={classes.bigAvatarImage}
              src={
                user.profilePic
                  ? cloudinaryifyProfilePic(user.profilePic)
                  : null
              }
            />
          </ListItemAvatar>
        </Grid>
      </Grid>
      <Grid item style={{ flex: 1 }}>
        <Typography
          style={{
            fontSize: 14,
            color: stringColor,
          }}
        >
          {user.username} clocked{' '}
          {contentPiece.contentStatus === 'checkin' ? 'in' : 'out'}
        </Typography>
        <Typography variant="caption">
          {moment(contentPiece.date).format('MMM D, YYYY @ h:mma')}
        </Typography>
      </Grid>
      <Grid item>
        {contentPiece.contentUrl && projectCanEdit && (
          <Button onClick={openModal}>View clock-in photo</Button>
        )}
      </Grid>
      {projectCanEdit && (
        <Grid item>
          {renderMenu()}
          <div className={classes.moreButtonStyle}>{renderMore()}</div>
        </Grid>
      )}
      {showModal && (
        <Dialog
          maxWidth="md"
          aria-labelledby="confirmation-dialog"
          open={showModal}
          onClose={closeModal}
        >
          <DialogContent dividers>
            <div
              style={{
                position: 'relative',
                display: 'flex',
                minHeight: 400,
                minWidth: 350,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {showLoader && (
                <ContentLoader
                  height={400}
                  width={350}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                  style={{ position: 'absolute', top: 0, left: 0 }}
                >
                  <rect x="0" y="0" rx="5" ry="5" width="350" height="400" />
                </ContentLoader>
              )}
              <img
                alt=""
                src={thisUrl(contentPiece.contentUrl)}
                className={classes.image}
                onLoad={handleImageLoaded}
              />
            </div>
          </DialogContent>
          <DialogActions>
            {!hideDownload && (
              <Button
                color="primary"
                startIcon={<GetAppIcon />}
                href={
                  _.includes(contentPiece.contentUrl, 'cloudinary')
                    ? cloudinaryDownloadUrl(contentPiece.contentUrl)
                    : contentPiece.contentUrl
                }
              >
                Download
              </Button>
            )}
            <Button onClick={closeModal} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Grid>
  );
};

export default ContentTimetracking;
