import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Customer}
  query FindQboCustomers($companyId: String!, $searchTerm: String) {
    findQboCustomers(
      input: { companyId: $companyId, searchTerm: $searchTerm }
    ) {
      items {
        ...CustomerFields
      }
    }
  }
`;
