import gql from 'graphql-tag';

export default gql`
  query GetCompanyTargets($companyId: String!) {
    getCompanyTargets(companyId: $companyId) {
      items {
        name
        value
        dataType
      }
    }
  }
`;
