import React from 'react';

import { connect } from 'react-redux';
import { Switch, Redirect, Route } from 'react-router-dom';
import _ from 'lodash';
import { Main as MainLayout, Minimal as MinimalLayout } from '../layouts';
import {
  Auth as AuthView,
  Crew as CrewView,
  Firm as FirmView,
  Projects as ProjectsView,
  Messages as MessagesView,
  Settings as SettingsView,
  Onboarding as OnboardingView,
  Calendar as CalendarView,
  Customers as CustomersView,
  RfiDashboard as RfiDashboardView,
  Shoebox as ShoeboxView,
  Scoreboard as ScoreboardView,
  AdminHome as AdminHomeView,
  AdminManageTime as AdminManageTimeView,
  AdminManageProjects as AdminManageProjectsView,
  AdminManageLeads as AdminManageLeadsView,
  AdminManageReceipts as AdminManageReceiptsView,
  AdminManageInvoicesPayments as AdminManageInvoicesPaymentsView,
  AdminManageBillPayments as AdminManageBillPaymentsView,
  AdminManageDocs as AdminManageDocsView,
  AdminManageRequestsForInfo as AdminManageRequestsForInfoView,
  AdminManageQuestions as AdminManageQuestionsView,
  NotFound as NotFoundView,
  NoAccess as NoAccessView,
  NoCompanyAccess as NoCompanyAccessView,
  Unsubscribe as UnsubscribeView,
  Disconnect as DisconnectView,
  BookkeepingAlerts as BookkeepingAlertsView,
  BookkeepingAlertsSettings as BookkeepingAlertsSettingsView,
  BookkeepingAlertsReport as BookkeepingAlertsReportView,
  CustomScan as CustomScanView,
  CompanyLocked as CompanyLockedView,
  Bills as BillsView,
  Vendors as VendorsView,
  Empty as EmptyView,
} from '../views/index';
import { AuthRoute, UnauthRoute } from '../components/route-hocs';
import JoinRedirect from '../components/join-redirect';
import InvitationRedirect from '../components/invitation-redirect';
import { BASE_ROUTE, SCOPE } from './routes.config';

const Routes = ({ userInfo, managingCompanyInfo }) => {
  // Note: the current protection scheme requires the user to navigate to an unauthorized page to set the managingCompanyInfo
  const { isSubscriptionBased } = managingCompanyInfo || {};

  const userBelongsToFirm = !!userInfo?.managingFirmId;

  // GET DEFAULT PATH BASED ON PRODUCTS
  let defaultPath = null;
  // NOTE: isSubscriptionBased can be undefined in the very first load
  if (userBelongsToFirm && !managingCompanyInfo) {
    defaultPath = '/firm';
  } else if (_.isBoolean(isSubscriptionBased)) {
    defaultPath = isSubscriptionBased ? BASE_ROUTE.ALERTS : BASE_ROUTE.PROJECTS;
  }

  // FUTURE: generate the switch dynamically base on route config
  // FUTURE: add permission checks to route config

  return (
    <Switch>
      {defaultPath && <Redirect exact from="/" to={defaultPath} />}
      <UnauthRoute
        path="/auth/invitation/:invitationKey"
        component={AuthView}
      />
      <UnauthRoute path="/auth" component={AuthView} />
      <UnauthRoute path="/signin" component={AuthView} />
      <UnauthRoute path="/email-sign-up/:signUpToken" component={AuthView} />
      <Route path="/join" component={JoinRedirect} />
      <Route path="/invitation" component={InvitationRedirect} />
      <Route path="/unsubscribe" component={UnsubscribeView} />
      <Route path="/disconnect" component={DisconnectView} />
      {!defaultPath && (
        <AuthRoute path="/" exact component={EmptyView} layout={MainLayout} />
      )}
      <AuthRoute
        path="/firm"
        component={FirmView}
        layout={MainLayout}
        scope={SCOPE.FIRM}
      />
      <AuthRoute
        path="/projects"
        component={ProjectsView}
        layout={MainLayout}
        scope={SCOPE.COMPANY_OR_CLIENT}
        blockIfNoProjectManagement
      />
      <AuthRoute
        path="/messages"
        component={MessagesView}
        layout={MainLayout}
        scope={SCOPE.COMPANY_OR_CLIENT}
        blockIfNoProjectManagement
      />
      <AuthRoute
        path="/crew"
        component={CrewView}
        layout={MainLayout}
        blockIfNoProjectManagement
      />
      <AuthRoute path="/onboarding" component={OnboardingView} />
      <AuthRoute
        path="/calendar"
        component={CalendarView}
        layout={MainLayout}
        scope={SCOPE.COMPANY_OR_CLIENT}
        blockIfNoProjectManagement
      />
      <AuthRoute
        path="/alerts/report"
        component={BookkeepingAlertsReportView}
        layout={MainLayout}
        blockIfNoAccessToAlerts
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/alerts/settings"
        component={BookkeepingAlertsSettingsView}
        layout={MainLayout}
        blockIfNoAccessToAlerts
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/alerts"
        component={BookkeepingAlertsView}
        layout={MainLayout}
        blockIfNoAccessToAlerts
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/custom-scan"
        component={CustomScanView}
        layout={MainLayout}
        blockIfNoAccessToAlerts
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/settings"
        component={SettingsView}
        layout={MainLayout}
      />
      <AuthRoute
        path="/admin/manage-time"
        component={AdminManageTimeView}
        layout={MainLayout}
        blockIfNotCompanyAdmin
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/admin/manage-receipts"
        component={AdminManageReceiptsView}
        layout={MainLayout}
        blockIfNotCompanyAdmin
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/admin/manage-invoice-payments"
        component={AdminManageInvoicesPaymentsView}
        layout={MainLayout}
        blockIfNotCompanyAdmin
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/admin/manage-bill-payments"
        component={AdminManageBillPaymentsView}
        layout={MainLayout}
        blockIfNotCompanyAdmin
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/admin/manage-docs"
        component={AdminManageDocsView}
        layout={MainLayout}
        blockIfNotCompanyAdmin
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/requests-for-information"
        component={RfiDashboardView}
        layout={MainLayout}
        blockIfNoAccessToRfi
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/admin/manage-requests-for-info"
        component={AdminManageRequestsForInfoView}
        layout={MainLayout}
        blockIfNoBookkeeperAccessToRfi
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/admin/manage-questions"
        component={AdminManageQuestionsView}
        layout={MainLayout}
        blockIfNotCompanyAdmin
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/admin/manage-projects"
        component={AdminManageProjectsView}
        layout={MainLayout}
        blockIfNotCompanyAdmin
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/leads"
        component={AdminManageLeadsView}
        layout={MainLayout}
        blockIfNotCompanyAdmin
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/admin/manage-leads"
        component={AdminManageLeadsView}
        layout={MainLayout}
        blockIfNotCompanyAdmin
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/customers/:customerId?"
        component={CustomersView}
        layout={MainLayout}
        blockIfNotCompanyAdmin
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/admin"
        component={AdminHomeView}
        layout={MainLayout}
        blockIfNotCompanyAdmin
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/shoebox"
        component={ShoeboxView}
        layout={MainLayout}
        blockIfNotCompanyAdminOrBookkeeper
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/bills"
        component={BillsView}
        layout={MainLayout}
        blockIfNotCompanyAdminOrBookkeeper
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/vendors"
        component={VendorsView}
        layout={MainLayout}
        blockIfNotCompanyAdminOrBookkeeper
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/scoreboard"
        component={ScoreboardView}
        layout={MainLayout}
        blockIfNoAccessToScoreboards
        scope={SCOPE.COMPANY_OR_CLIENT}
      />
      <AuthRoute
        path="/not-found"
        exact
        component={NotFoundView}
        layout={MinimalLayout}
      />
      <AuthRoute
        path="/no-access"
        component={NoAccessView}
        layout={MinimalLayout}
      />
      <AuthRoute
        path="/no-company-access"
        component={NoCompanyAccessView}
        layout={MinimalLayout}
      />
      <AuthRoute
        path="/company-locked"
        component={CompanyLockedView}
        layout={MinimalLayout}
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    managingCompanyInfo: state.appState.managingCompanyInfo,
  };
}

export default connect(mapStateToProps)(Routes);
