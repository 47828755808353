/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useContext } from 'react';
import { compose } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import _ from 'lodash';

import {
  Grid,
  Dialog,
  DialogContent,
  Tabs,
  Tab,
  Typography,
  Button,
  Fab,
  ButtonGroup,
} from '@material-ui/core';

import {
  DoneAll as DoneAllIcon,
  Search as SearchIcon,
  Person as PersonIcon,
  Group as GroupIcon,
  Sort as SortIcon,
} from '@material-ui/icons';

import { ShiftTools } from 'level-foundation';

import ContentItem from './contentItem';
import ContentDetailsModal from '../../views/add-to-project/content-details-modal';

import MessagesListData from '../../views/Messages/components/messagesList.data';
import SearchAndFilter from '../SearchAndFilter/searchAndFilter';

import Scheduler from '../../views/Projects/components/ProjectDetails/scheduler';

import { TopParentContext } from '../../views/Projects/components/ProjectDetails/projectDetails.data';

import {
  DeleteContentAction,
  DeleteJrnAction,
  MoveContentToOtherProjectAction,
} from '../../graphql/graphql';

import {
  ALLOWED_TYPES_TO_MOVE,
  CONTENT_DETAILS_MODAL_MODE,
  TOP_PROJECT_ID,
} from '../../config/appDefaults';

import DialogTitle from '../dialog-title/dialog-title';
import ChooseProjectWithSubs from '../choose-project-dialog/choose-project-with-subs';
import SplitFinancialItem from '../split-financial-item';
import ProjectSearchAndSort from '../project-search-and-sort/project-search-and-sort';
import OkCancelDialog from '../OkCancelDialog/okCancelDialog';

import ViewOrEditRfi from './view-or-edit-rfi';

const useStyles = makeStyles(theme => ({
  noContent: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  filterWrapper: {
    backgroundColor: theme.palette.brandColorPrimary10,
    margin: '0 auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  filterButton: {
    color: theme.palette.brandColorPrimary,
    backgroundColor: '#fff',
  },
  filterButtonActive: {
    color: '#fff',
    backgroundColor: theme.palette.brandColorPrimary,
    '&:hover': {
      backgroundColor: theme.palette.brandColorPrimary,
    },
  },
  fab: {
    boxShadow: 'none',
  },
  fabIcon: {
    marginLeft: theme.spacing(1),
  },
  iconFlipped: {
    transform: 'scaleY(-1)',
  },
}));

const initialSetShowContentDetailsModal = {
  open: false,
  mode: null,
  projectInfo: null,
  existingInfo: null,
  contentToDelete: null,
};

const ProjectContentListing = props => {
  const {
    content,
    allUsers,
    userId,
    projectInfo,
    projectCanEdit,
    projectCanAdd,
    scrollPosition,
    lastTabIndex,
    timeManager,
    onDeleteContent,
    onDeleteJrn,
    onMoveContentToOtherProjectAction,
    setShowLoaderCover,
  } = props;
  const classes = useStyles();

  const belongsToExtended =
    projectInfo.type === 'task' && projectInfo.subtype === 'extended';

  const topParentInfo = useContext(TopParentContext);
  const isTemplate = projectInfo.type === 'template';
  const parentIsTemplate = topParentInfo && topParentInfo.type === 'template';
  const useTemplateLayout = isTemplate || parentIsTemplate;
  const isSubproject =
    projectInfo.type === 'project' &&
    projectInfo.jrnId &&
    projectInfo.jrnId !== TOP_PROJECT_ID;
  const [showSearchFilter, setShowSearchFilter] = useState(false);
  const [subprojectsToUse, setSubprojectsToUse] = useState(null);
  const [feedType] = useState('full');
  const [pdfType] = useState('full');

  const [tabIndex, setTabIndex] = useState(lastTabIndex || 0);

  const [taskType, setTaskType] = useState('all');
  const [incompleteTasksOnly, setIncompleteTasksOnly] = useState(false);

  const [showContentDetailsModal, setShowContentDetailsModal] = useState(
    initialSetShowContentDetailsModal
  );

  const [
    showDeleteConfirmationDialog,
    setShowDeleteConfirmationDialog,
  ] = useState({ open: false, contentToDelete: null });
  const [showMessageDialog, setShowMessageDialog] = useState({
    open: false,
    title: '',
    message: '',
  });
  const [viewOrEditRfiDialog, setViewOrEditRfiDialog] = useState({
    requestId: null,
  });

  const [sortTask, setSortTask] = useState(true);

  const [contentByType, setContentByType] = useState({
    feed: [],
    subprojects: [],
    tasks: [],
    pdf: [],
    shifts: [],
  });
  const [myShifts, setMyShifts] = useState(false);

  const [showMoveDialog, setShowMoveDialog] = useState({
    open: false,
    item: null,
  });

  const [
    showSplitFinancialItemDialog,
    setShowSplitFinancialItemDialog,
  ] = useState({ open: false, contentInfo: null });

  const handleMove = item => {
    if (!ALLOWED_TYPES_TO_MOVE.includes(item.type)) {
      return;
    }
    setShowMoveDialog({
      open: true,
      item,
    });
  };

  const handleNewParentChoice = async newParent => {
    setShowLoaderCover({ open: true, message: 'Moving...' });
    const newProjectId = newParent.project.contentId;
    setShowMoveDialog({ open: false });
    await onMoveContentToOtherProjectAction({
      contentToMoveId: showMoveDialog.item.contentId,
      oldProjectId: showMoveDialog.item.jrnId,
      newProjectId,
      existingContent: showMoveDialog.item,
    });
    setShowLoaderCover({ open: false, message: '' });
  };

  const processContent = jrnContent => {
    let feedContent = jrnContent ? [...jrnContent] : [];

    // now sort the rest of the content by date
    feedContent = _.orderBy(feedContent, ['date'], ['desc']);

    const tasksContent = _.remove(
      feedContent,
      item => item.type === 'task' || item.type === 'punchlistItem'
    );
    const pdfContent = _.remove(feedContent, item => item.type === 'pdf');
    const shiftContent = _.remove(feedContent, item => item.type === 'shift');
    const subprojectContent = _.remove(
      feedContent,
      item => item.type === 'project'
    );

    const shiftsRangesSpread = ShiftTools.spreadShiftRanges(shiftContent);

    const myShiftsSpread = _.filter(
      shiftsRangesSpread,
      item => item.assignedTo === userId
    );

    setContentByType({
      feed: feedContent,
      subprojects: subprojectContent,
      tasks: tasksContent,
      pdf: pdfContent,
      shifts: shiftsRangesSpread,
      myShifts: myShiftsSpread,
    });
  };

  useEffect(() => {
    // This effect is to regenerate the content list if the provided content changes
    processContent(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const a11yProps = index => ({
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  });

  const filterTasks = (tasks, taskTypeFilter = 'all') => {
    let filteredTasks = tasks;

    if (taskTypeFilter !== 'all') {
      filteredTasks = _.filter(tasks, {
        assignedTo: userId,
      });

      if (incompleteTasksOnly === true) {
        filteredTasks = _.filter(
          filteredTasks,
          task => task.contentStatus !== 'completed'
        );
      }
    } else if (incompleteTasksOnly === true) {
      filteredTasks = _.filter(
        tasks,
        task => task.contentStatus !== 'completed'
      );
    }

    if (sortTask === false) {
      filteredTasks = _.orderBy(
        filteredTasks,
        ['date', 'title'],
        ['desc', 'asc']
      );
    } else {
      filteredTasks = _.orderBy(
        filteredTasks,
        ['date', 'title'],
        ['asc', 'asc']
      );
    }
    return filteredTasks;
  };

  const handleEdit = (item, optionalData) => {
    let itemToUse = item;
    if (item.type === 'shift' && item.recurrence) {
      itemToUse = _.find(content, { contentId: item.recurrence.rangeId });
    }

    setShowContentDetailsModal({
      open: true,
      mode: CONTENT_DETAILS_MODAL_MODE.EDIT,
      projectInfo,
      existingInfo: itemToUse,
      contentToDelete: optionalData?.contentToDelete,
    });
  };

  const handleSplit = item => {
    setShowSplitFinancialItemDialog({
      open: true,
      contentInfo: item,
    });
  };

  const handleViewGlobalItem = globalItem => {
    setShowContentDetailsModal({
      open: true,
      mode: CONTENT_DETAILS_MODAL_MODE.GLOBAL_ITEM_VIEW,
      projectInfo: null,
      existingInfo: globalItem,
    });
  };

  const handleDeleteBillPayment = billPayment => {
    setShowDeleteConfirmationDialog({
      open: true,
      contentToDelete: billPayment,
    });
  };

  const handleDeleteBillPaymentConfirm = async () => {
    const { contentToDelete: billPayment } = showDeleteConfirmationDialog;
    const { jrn: globalPayment } = billPayment;
    const billPaymentAmount = Number(billPayment.amount?.value);
    const globalPaymentAmount = Number(globalPayment.amount?.value);
    if (billPaymentAmount === globalPaymentAmount) {
      // global payment item has only one bill payment
      // delete the bill payment item
      await onDeleteContent(billPayment.contentId, billPayment.jrnId, {
        content: billPayment,
      });

      // delete global payement
      onDeleteJrn(globalPayment.contentId, globalPayment.type);
    } else {
      // global payment item has more than one bill payment
      // open editing modal to edit the global payment item
      // and remove this bill payment item by setting its deletionPending flag to true.

      setShowMessageDialog({
        title: 'Info',
        open: true,
        message: `This bill payment is shared with other bills. You will be redirected to the editing form to allocate the remaining amount.`,
        onConfirm: () => {
          setShowMessageDialog({ open: false });
          setShowContentDetailsModal({
            open: true,
            mode: CONTENT_DETAILS_MODAL_MODE.EDIT,
            projectInfo: null,
            existingInfo: globalPayment,
            contentToDelete: billPayment,
          });
        },
      });
    }
  };

  const handleMarkBillAsPaid = globalExpenseItem => {
    setShowContentDetailsModal({
      open: true,
      mode: CONTENT_DETAILS_MODAL_MODE.MARK_BILL_AS_PAID,
      modeInfo: { bills: [globalExpenseItem] },
      projectInfo: null,
      existingInfo: globalExpenseItem,
    });
  };

  const handleViewOrEditRfi = ({
    requestId,
    newRfiInfo,
    fromWhichJrnContent,
  }) => {
    setViewOrEditRfiDialog({
      requestId,
      newRfiInfo: { ...newRfiInfo, idOfParentProject: projectInfo.contentId },
      fromWhichJrnContent,
    });
  };

  const renderFeedItem = contentItem => (
    <div key={contentItem.contentId} style={{ flex: 1, width: '100%' }}>
      <LazyLoadComponent scrollPosition={scrollPosition}>
        <ContentItem
          contentItem={contentItem}
          contentType="feed"
          feedType={feedType}
          allUsers={allUsers}
          handleEdit={handleEdit}
          handleSplit={handleSplit}
          onViewGlobalItem={handleViewGlobalItem}
          onMarkBillAsPaid={handleMarkBillAsPaid}
          handleMove={handleMove}
          userId={userId}
          projectCanEdit={projectCanEdit}
          projectCanAdd={projectCanAdd}
          projectInfo={projectInfo}
          handleViewOrEditRfi={handleViewOrEditRfi}
        />
      </LazyLoadComponent>
    </div>
  );
  const renderPdfItem = contentItem => (
    <ContentItem
      key={contentItem.contentId}
      contentItem={contentItem}
      contentType="pdf"
      feedType={pdfType}
      allUsers={allUsers}
      handleEdit={handleEdit}
      handleSplit={handleSplit}
      handleMove={handleMove}
      userId={userId}
      projectCanEdit={projectCanEdit}
      projectCanAdd={projectCanAdd}
      projectInfo={projectInfo}
    />
  );

  const renderTaskItem = task => (
    <ContentItem
      key={task.contentId}
      contentItem={task}
      contentType="task"
      allUsers={allUsers}
      handleEdit={handleEdit}
      userId={userId}
      projectCanEdit={projectCanEdit}
      projectCanAdd={projectCanAdd}
      isThisExtTask={belongsToExtended}
      projectInfo={projectInfo}
    />
  );

  const calcTaskMeta = allTasks => {
    const taskCount = allTasks.length;
    const completedTaskCount = _.filter(allTasks, {
      contentStatus: 'completed',
    }).length;
    const progressBarPercentage =
      completedTaskCount === 0 ? 2 : (completedTaskCount / taskCount) * 100;
    return {
      taskCount,
      completedTaskCount,
      progressBarPercentage,
    };
  };

  // calc how many tasks and how many complete
  const { taskCount, completedTaskCount, progressBarPercentage } = calcTaskMeta(
    contentByType.tasks
  );

  // set which tasks should be shown
  const tasksToShow = filterTasks(contentByType.tasks, taskType);

  // handle the tabs
  let tabsToShow;

  if (belongsToExtended) {
    // Extended task case
    tabsToShow = ['feed', 'tasks', 'docs'];
  } else if (useTemplateLayout) {
    // Template case
    tabsToShow = ['feed', 'tasks', 'docs'];
    if (!isSubproject || contentByType.subprojects.length) {
      // push 'subprojects' into the array in the second spot if there are subprojects
      tabsToShow.splice(1, 0, 'subprojects');
    }
  } else {
    // Project case
    tabsToShow = ['feed', 'tasks', 'docs', 'shifts', 'chat'];
    if (!isSubproject || contentByType.subprojects.length) {
      // push 'subprojects' into the array in the second spot if there are subprojects
      tabsToShow.splice(1, 0, 'subprojects');
    }
  }

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const renderTabContents = () => {
    const renderFeed = index => {
      return (
        <Grid
          container
          direction="column"
          style={{ display: tabIndex === index ? 'flex' : 'none' }}
        >
          {contentByType.feed.length > 0 ? (
            contentByType.feed.map(renderFeedItem)
          ) : (
            <div className={classes.noContent}>No content here yet.</div>
          )}
        </Grid>
      );
    };
    const renderSubprojects = index => {
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          style={{ display: tabIndex === index ? 'flex' : 'none' }}
        >
          <Grid item style={{ width: '100%' }}>
            <ProjectSearchAndSort
              allProjects={contentByType.subprojects}
              passBack={setSubprojectsToUse}
            />
            <Grid container spacing={1} direction="column">
              {subprojectsToUse && subprojectsToUse.length > 0 ? (
                subprojectsToUse.map(renderFeedItem)
              ) : (
                <div className={classes.noContent}>No subprojects found.</div>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    };
    const renderTasks = index => {
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          style={{ display: tabIndex === index ? 'flex' : 'none' }}
        >
          <Grid item style={{ width: '100%' }}>
            {taskCount > 0 && (
              <Grid
                container
                direction="column"
                justifyContent="center"
                spacing={3}
                className={classes.filterWrapper}
              >
                <Grid
                  container
                  style={{ justifyContent: 'center', paddingTop: '15px' }}
                >
                  {!isTemplate && (
                    <>
                      <Button
                        className={
                          taskType === 'myTasks'
                            ? classes.filterButtonActive
                            : classes.filterButton
                        }
                        onClick={() => {
                          if (taskType === 'all') {
                            setTaskType('myTasks');
                          }

                          if (taskType === 'myTasks') {
                            setTaskType('all');
                          }
                        }}
                        style={{ margin: '5px' }}
                      >
                        my tasks
                      </Button>

                      <Button
                        className={
                          incompleteTasksOnly === true
                            ? classes.filterButtonActive
                            : classes.filterButton
                        }
                        onClick={() =>
                          setIncompleteTasksOnly(!incompleteTasksOnly)
                        }
                        style={{ margin: '5px' }}
                      >
                        Incomplete
                      </Button>
                    </>
                  )}
                  <Button
                    className={
                      sortTask
                        ? classes.filterButton
                        : classes.filterButtonActive
                    }
                    onClick={() => {
                      setSortTask(!sortTask);
                    }}
                    style={{ margin: '5px' }}
                  >
                    Sort&nbsp;
                    <SortIcon
                      className={sortTask ? classes.iconFlipped : null}
                    />
                  </Button>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h5">
                        {`${completedTaskCount} of ${taskCount} items complete`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {completedTaskCount === taskCount && (
                        <DoneAllIcon
                          style={{ marginLeft: 7, color: 'green' }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    style={{ flexDirection: 'row', width: '100%' }}
                  >
                    <Grid
                      item
                      style={{
                        backgroundColor: 'green',
                        height: 2,
                        width: `${progressBarPercentage}%`,
                        alignSelf: 'flex-start',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid container spacing={1} direction="column">
              {tasksToShow.length > 0 ? (
                tasksToShow.map(renderTaskItem)
              ) : (
                <Grid item>
                  <div className={classes.noContent}>
                    {taskType === 'all' && 'There are no tasks yet.'}
                    {taskType === 'incomplete' &&
                      'There are no incomplete items.'}
                    {taskType === 'myTasks' && 'No tasks are assigned to you.'}
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    };
    const renderPdfs = index => {
      return (
        <Grid
          container
          direction="column"
          style={{ display: tabIndex === index ? 'flex' : 'none' }}
        >
          {contentByType.pdf.length > 0 ? (
            contentByType.pdf.map(renderPdfItem)
          ) : (
            <div className={classes.noContent}>No content here yet.</div>
          )}
        </Grid>
      );
    };
    const renderChat = index => {
      if (tabIndex !== index) return null;
      return (
        <Grid
          container
          direction="column"
          style={{ display: tabIndex === index ? 'flex' : 'none' }}
        >
          <MessagesListData convoInfo={projectInfo} hideHeader styleForFeed />
        </Grid>
      );
    };
    const renderShifts = index => {
      if (tabIndex !== index) return null;
      return (
        <Grid
          container
          style={{
            display: tabIndex === index ? 'flex' : 'none',
            marginTop: '20px',
            width: '100%',
            flex: 1,
          }}
        >
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              spacing={3}
              className={classes.filterWrapper}
            >
              <Grid item>
                <ButtonGroup
                  variant="text"
                  color="primary"
                  arialabel="feed view"
                >
                  <Button
                    className={
                      myShifts === false
                        ? classes.filterButtonActive
                        : classes.filterButton
                    }
                    onClick={() => {
                      setMyShifts(false);
                    }}
                  >
                    <GroupIcon />
                    &nbsp;&nbsp;All Shifts
                  </Button>
                  <Button
                    className={
                      myShifts === true
                        ? classes.filterButtonActive
                        : classes.filterButton
                    }
                    onClick={() => {
                      setMyShifts(true);
                    }}
                  >
                    <PersonIcon />
                    &nbsp;&nbsp;My Shifts
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
          <Scheduler
            project={projectInfo}
            isAdmin={projectCanEdit}
            handleEdit={handleEdit}
            // projectCanAdd={projectCanAdd}
            allUsers={allUsers}
            shifts={myShifts ? contentByType.myShifts : contentByType.shifts}
          />
        </Grid>
      );
    };
    return (
      <Grid container direction="column" style={{ maxWidth: '100%' }}>
        {tabsToShow.map((tabLabel, index) => {
          return (
            <React.Fragment key={tabLabel}>
              {tabLabel === 'feed' && renderFeed(index)}
              {tabLabel === 'subprojects' && renderSubprojects(index)}
              {tabLabel === 'tasks' && renderTasks(index)}
              {tabLabel === 'docs' && renderPdfs(index)}
              {tabLabel === 'chat' && renderChat(index)}
              {tabLabel === 'shifts' && renderShifts(index)}
            </React.Fragment>
          );
        })}
      </Grid>
    );
  };

  return (
    <>
      {content.length > 0 && (
        <>
          {showSearchFilter ? (
            <SearchAndFilter
              data={content}
              onClose={() => setShowSearchFilter(false)}
              returnData={processContent}
              project={projectInfo}
              hasAdminRights={projectCanEdit}
              timeManager={timeManager}
            />
          ) : (
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Fab
                  onClick={() => {
                    setShowSearchFilter(true);
                  }}
                  variant="extended"
                  size="medium"
                  aria-label="add"
                  className={classes.fab}
                >
                  Search Project
                  <SearchIcon className={classes.fabIcon} />
                </Fab>
              </Grid>
            </Grid>
          )}
        </>
      )}

      <Grid container direction="column" style={{ maxWidth: '100%' }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
        >
          {tabsToShow.map((label, index) => {
            let numToShow = 0;
            switch (label) {
              case 'feed': {
                numToShow = contentByType.feed && contentByType.feed.length;
                break;
              }
              case 'subprojects': {
                numToShow =
                  contentByType.subprojects && contentByType.subprojects.length;
                break;
              }
              case 'tasks': {
                numToShow = tasksToShow && tasksToShow.length;
                break;
              }
              case 'docs': {
                numToShow = contentByType.pdf && contentByType.pdf.length;
                break;
              }
              default:
                break;
            }

            return (
              <Tab
                style={{ minWidth: label === 'subprojects' ? 170 : 70 }}
                key={label}
                label={numToShow ? `${label} (${numToShow})` : label}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>

        {renderTabContents()}

        {showContentDetailsModal.open && (
          <ContentDetailsModal
            mode={showContentDetailsModal.mode}
            modeInfo={showContentDetailsModal.modeInfo}
            parentInfo={showContentDetailsModal.projectInfo}
            existingInfo={showContentDetailsModal.existingInfo}
            contentToDelete={showContentDetailsModal.contentToDelete}
            onClose={() => {
              setShowContentDetailsModal(initialSetShowContentDetailsModal);
            }}
            onViewGlobalItem={handleViewGlobalItem}
            onDeleteBillPayment={handleDeleteBillPayment}
            hideDeleteButton
          />
        )}
      </Grid>
      {showMoveDialog.open && (
        <Dialog
          maxWidth="lg"
          fullWidth
          open
          PaperProps={{ style: { minHeight: '50%' } }}
        >
          <DialogTitle
            onClose={() => {
              setShowMoveDialog({ open: false });
            }}
          />
          <DialogContent>
            <>
              <Typography
                variant="h2"
                gutterBottom
                align="center"
                color="primary"
              >
                Choose which project to move this to:
              </Typography>
              <ChooseProjectWithSubs
                setSelectedProject={handleNewParentChoice}
              />
            </>
          </DialogContent>
        </Dialog>
      )}
      {showDeleteConfirmationDialog.open && (
        <OkCancelDialog
          open={showDeleteConfirmationDialog.open}
          title="Just making sure..."
          okButtonText="Yes"
          okButtonVariant="text"
          autoFocusButton="cancelButton"
          onClose={() => {
            setShowDeleteConfirmationDialog(currentState => ({
              ...currentState,
              open: false,
            }));
          }}
          onConfirm={handleDeleteBillPaymentConfirm}
        >
          <Typography>
            Are you sure you want to erase this payment? This cannot be undone.
          </Typography>
        </OkCancelDialog>
      )}
      {showMessageDialog.open && (
        <OkCancelDialog
          open={showMessageDialog.open}
          title={showMessageDialog.title || 'Oops...'}
          okButtonVariant="text"
          hideCancel
          onConfirm={
            showMessageDialog.onConfirm
              ? showMessageDialog.onConfirm
              : () => {
                  setShowMessageDialog({ open: false });
                }
          }
        >
          <Typography>
            {showMessageDialog.message || 'Something went wrong.'}
          </Typography>
        </OkCancelDialog>
      )}
      <SplitFinancialItem
        open={showSplitFinancialItemDialog.open}
        onClose={() => {
          setShowSplitFinancialItemDialog({ open: false });
        }}
        contentInfo={showSplitFinancialItemDialog.contentInfo}
        projectInfo={projectInfo}
        dialog
      />

      <ViewOrEditRfi
        open={
          !!viewOrEditRfiDialog.requestId || !!viewOrEditRfiDialog.newRfiInfo
        }
        newRfiInfo={viewOrEditRfiDialog.newRfiInfo}
        requestId={viewOrEditRfiDialog.requestId}
        onClose={() => {
          setViewOrEditRfiDialog({ requestId: null });
        }}
        fromWhichJrnContent={viewOrEditRfiDialog.fromWhichJrnContent}
      />
    </>
  );
};

export default compose(
  DeleteContentAction,
  DeleteJrnAction,
  MoveContentToOtherProjectAction
)(ProjectContentListing);

export const TAB_INDEX = {
  FEED: 0,
  SUBPROJECTS: 1,
  TASKS: 2,
  DOCS: 3,
  CHAT: 4,
  SHIFTS: 5,
};
