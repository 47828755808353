import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import DashboardSelect from './scoreboard-choose';
import ConnectQboWrapper from '../../components/connect-qbo-wrapper/connect-qbo-wrapper';

const Scoreboard = ({ managingCompanyInfo, dashboards, children }) => {
  if (managingCompanyInfo && !managingCompanyInfo.isConnectedToQuickBooks) {
    return (
      <ConnectQboWrapper title="Access your Scoreboard">
        <Typography variant="body1">
          Like in any sports game, your business has a scoreboard, and its
          scoreboard is its financials.{' '}
          <b>Connect your QuickBooks Online account</b> to see how your business
          is performing, and identify trends in profits, cash flow, and more, so
          that you can make better business decisions (just like in a sports
          game!).
        </Typography>
      </ConnectQboWrapper>
    );
  }

  if (managingCompanyInfo && managingCompanyInfo.isConnectedToQuickBooks) {
    return (
      <div>
        <div style={{ width: '100%' }}>
          <DashboardSelect dashboards={dashboards} />
        </div>
        {children}
      </div>
    );
  }

  return null;
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(Scoreboard);
