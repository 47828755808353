import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { makeStyles } from '@material-ui/styles';
import { Button, ButtonGroup } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  active: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.white} !important`,
  },
  inactive: {
    backgroundColor: `${theme.palette.white} !important`,
    color: `${theme.palette.primary.main} !important`,
  },
  diabledActive: {
    backgroundColor: theme.palette.brandColorDarkGrey25,
  },
  disabledInactive: {},
}));

const LevelSegmentButton = ({
  options = [],
  passbackChosenValue,
  size = 'medium',
  initiallySelected,
  disabled,
  buttonStyle,
  levelSegmentButtonRef,
}) => {
  const classes = useStyles();
  const [chosenValue, setChosenValue] = useState(initiallySelected || null);

  const handleChoiceChange = newChoice => () => {
    setChosenValue(newChoice);
    passbackChosenValue(newChoice);
  };

  // expose setChosenValue function to parent component
  useImperativeHandle(levelSegmentButtonRef, () => ({
    setChosenValue,
  }));

  const renderSegments = () => {
    return options.map(buttonInfo => {
      const isActive = chosenValue === buttonInfo.value;

      let classToUse = classes.inactive;
      if (disabled) {
        if (isActive) {
          classToUse = classes.diabledActive;
        } else {
          classToUse = classes.disabledInactive;
        }
      } else if (isActive) {
        classToUse = classes.active;
      }

      return (
        <Button
          key={buttonInfo.value}
          value={buttonInfo.value}
          disabled={disabled}
          onClick={handleChoiceChange(buttonInfo.value)}
          className={classToUse}
          style={{
            ...buttonStyle,
          }}
        >
          {buttonInfo.label || buttonInfo.value}
        </Button>
      );
    });
  };

  return (
    <ButtonGroup size={size} variant="outlined" color="primary">
      {renderSegments()}
    </ButtonGroup>
  );
};

export default forwardRef((props, ref) => (
  <LevelSegmentButton {...props} levelSegmentButtonRef={ref} />
));
