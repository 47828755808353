import gql from 'graphql-tag';

export default gql`
  mutation UpdateBookkeepingAlertPreferences(
    $id: String!
    $companyId: String!
    $enabled: Boolean!
    $timeZone: String!
    $timeOfDay: String!
    $daysAfterPeriodBookCompleted: Int
    $frequencyOfBookkeepingTasks: String
  ) {
    updateBookkeepingAlertPreferences(
      input: {
        id: $id
        companyId: $companyId
        enabled: $enabled
        timeZone: $timeZone
        timeOfDay: $timeOfDay
        daysAfterPeriodBookCompleted: $daysAfterPeriodBookCompleted
        frequencyOfBookkeepingTasks: $frequencyOfBookkeepingTasks
      }
    ) {
      id
      companyId
      enabled
      timeZone
      timeOfDay
      daysAfterPeriodBookCompleted
      frequencyOfBookkeepingTasks
      qbFiscalYearStartMonth
    }
  }
`;
