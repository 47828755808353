import React from 'react';
import { Query, compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import uuid from 'uuid';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import GetMessagesByJrnId from '../../../graphql/queries/GetMessagesByJrnId';
import GetUsersByJrnId from '../../../graphql/queries/GetUsersByJrnId';
import GetJrnBasicInfo from '../../../graphql/queries/GetJrn';

import SubscribeToNewMessages from '../../../graphql/subscriptions/subscribe_createMessage';
import MessagesListView from './messagesList.view';
import {
  CreateMessageMutationAction,
  DeleteJrnAction,
  LeaveJrnMutationAction,
} from '../../../graphql/graphql';

const useStyles = makeStyles(theme => ({
  convo: {
    height: '100%',
  },
  root: {
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  messageDetailsWrapper: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingTop: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const MessagesListData = props => {
  const {
    hideHeader,
    onDeleteJrn,
    onLeaveJrn,
    setShowManualAddDialog,
    userInfo,
    styleForFeed,
  } = props;
  const classes = useStyles();
  const { projectId } = useParams();

  const [initialLoadDone, setInitialLoadDone] = React.useState(false);

  // get users and their info (includes your own)
  const GetUsersByJrnIdQuery = useQuery(GetUsersByJrnId, {
    variables: { jrnId: projectId },
  });
  const { loading: GetUsersByJrnIdLoading } = GetUsersByJrnIdQuery;
  const allConvoUsers = _.get(
    GetUsersByJrnIdQuery,
    'data.getUsersByJrnId.items'
  );

  // get the convo's info
  const GetJrnBasicInfoQuery = useQuery(GetJrnBasicInfo, {
    variables: { jrnId: projectId },
  });
  const { loading: GetJrnBasicInfoLoading } = GetJrnBasicInfoQuery;
  const basicConvoInfo = _.get(GetJrnBasicInfoQuery, 'data.getJrn');
  let titleToUse;
  let onlyUser;
  let thumbnailToUse;
  if (basicConvoInfo && allConvoUsers) {
    const ate = basicConvoInfo.allowedToEdit;
    const ata = basicConvoInfo.allowedToAdd || [];
    const atv = basicConvoInfo.allowedToView || [];
    const combinedLists = ate.concat(ata).concat(atv);

    if (
      basicConvoInfo.type === 'project' ||
      (basicConvoInfo.type === 'conversation' &&
        basicConvoInfo.title !== 'hold' &&
        basicConvoInfo.title !== null)
    ) {
      titleToUse = basicConvoInfo.title;
      thumbnailToUse = basicConvoInfo.contentUrl;
    } else {
      const otherUsersInfo = _.find(
        allConvoUsers,
        user => user.userId !== userInfo.userId
      );
      titleToUse = otherUsersInfo.username;
      thumbnailToUse = otherUsersInfo.profilePic;
      if (combinedLists.length === 1) {
        onlyUser = true;
        titleToUse += ' left the chat.';
      }
    }
    // this handles the case the user being the only one left in a group chat
    if (basicConvoInfo.type === 'conversation' && combinedLists.length === 1) {
      onlyUser = true;
    }
  }

  return (
    <>
      <Query
        query={GetMessagesByJrnId}
        variables={{ jrnId: projectId }}
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data, subscribeToMore, fetchMore }) => {
          if (loading && !initialLoadDone) {
            return (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.convo}
              >
                <CircularProgress />
              </Grid>
            );
          }
          if (!initialLoadDone) {
            setInitialLoadDone(true);
          }

          if (error) {
            return (
              <Typography
                variant="body2"
                align="center"
                style={{ marginTop: 30 }}
              >
                Sorry, you can&apos;t access this conversation right now.
                <br />
                <br />
                This may be a connection issue or you may not have access to the
                conversation.
                <br />
                Please try again later.
              </Typography>
            );
          }

          if (!data.getMessagesByJrnId) {
            return (
              <div className={classes.messageDetailsWrapper}>
                <Paper className={clsx(classes.root)}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                      No conversation found. Please check your URL.
                      <br />
                      <br />
                      This may be a temporary issue. Please check back later if
                      you believe you received this message in error.
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            );
          }

          const sortedData =
            _.orderBy(data.getMessagesByJrnId.items, ['date'], ['desc']) || [];

          return (
            <MessagesListView
              messages={sortedData.reverse()}
              userId={userInfo.userId}
              userInfo={userInfo}
              onlyUser={onlyUser}
              convoInfo={basicConvoInfo}
              titleToUse={titleToUse}
              thumbnailToUse={thumbnailToUse}
              allConvoUsers={allConvoUsers}
              loading={
                GetUsersByJrnIdLoading || GetJrnBasicInfoLoading || loading
              }
              onDeleteJrn={onDeleteJrn}
              onLeaveJrn={onLeaveJrn}
              setShowManualAddDialog={setShowManualAddDialog}
              hideHeader={hideHeader}
              nextToken={data.getMessagesByJrnId.nextToken}
              onLoadMore={nextToken => {
                fetchMore({
                  variables: {
                    jrnId: projectId,
                    after: data.getMessagesByJrnId.nextToken,
                  },
                  updateQuery: (previousResult, newResult) => {
                    const newMessageConnection =
                      newResult.fetchMoreResult.getMessagesByJrnId;
                    if (
                      newMessageConnection.items.length &&
                      nextToken !== newMessageConnection.nextToken
                    ) {
                      const giveBack = {
                        ...previousResult,
                        ...{
                          getMessagesByJrnId: {
                            ...previousResult.getMessagesByJrnId,
                            __typename: 'MessageConnection',
                            items: [
                              ...previousResult.getMessagesByJrnId.items,
                              ...newMessageConnection.items,
                            ],
                            nextToken: newMessageConnection.nextToken,
                          },
                        },
                      };
                      return giveBack;
                    }
                    return previousResult;
                  },
                });
              }}
              subscribeToNewMessages={() => {
                subscribeToMore({
                  document: SubscribeToNewMessages,
                  variables: { jrnId: projectId },
                  updateQuery: (
                    prev,
                    {
                      subscriptionData: {
                        data: { subscribeToNewMessages },
                      },
                    }
                  ) => {
                    if (!subscribeToNewMessages) return prev;
                    const giveBack = {
                      ...prev,
                      ...{
                        getMessagesByJrnId: {
                          ...prev.getMessagesByJrnId,
                          __typename: 'MessageConnection',
                          items: [
                            subscribeToNewMessages,
                            ...prev.getMessagesByJrnId.items.filter(message => {
                              return (
                                message.messageId !==
                                subscribeToNewMessages.messageId
                              );
                            }),
                          ],
                        },
                      },
                    };
                    return giveBack;
                  },
                });
              }}
              createNewMessage={passedMessage => {
                const { onAddMessage } = props;
                if (!passedMessage || passedMessage.trim().length === 0) {
                  return;
                }
                const date = Date.now();
                const mId = uuid();
                const messageToAdd = {
                  authorId: userInfo.userId,
                  content: passedMessage,
                  jrnId: projectId,
                  createdAt: `${date}`,
                  messageId: `${date}-${mId}`,
                  synced: false,
                };
                onAddMessage(messageToAdd);
              }}
              styleForFeed={styleForFeed}
            />
          );
        }}
      </Query>
    </>
  );
};
function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
  };
}

export default compose(
  // Mutations
  CreateMessageMutationAction,
  DeleteJrnAction,
  LeaveJrnMutationAction
)(connect(mapStateToProps)(MessagesListData));
