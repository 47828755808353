import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  query ListCompanyTemplates($companyId: String!, $first: Int, $after: String) {
    listCompanyTemplates(
      input: { companyId: $companyId, first: $first, after: $after }
    ) {
      items {
        __typename
        address
        contentId
        jrnId
        title
        creatorId
        contentUrl
        description
        endDate
        qboCustomerId
        privacyLevel
        inviteCode
        allowedToEdit
        allowedToAdd
        allowedToView
        usersEverOnJrn
        startDate
        synced
        labels
        latitude
        longitude
        phoneNumber
        phoneNumberActive
        type
        lastMessageSent
        useTimetracking
        useTimetrackingQuestions
        timetrackingQuestions
        timetrackingQuestionsCheckout
        billable
        includeSubsInStats
        # REMOVEME: remove this when "bugets" field is deprecated
        budgets {
          ...BudgetFields
        }
        fromTemplate
        timetrackingType
      }
    }
  }
`;
