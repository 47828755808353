import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import {
  Badge,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';

import {
  People as CrewIcon,
  VerifiedUser as AdminIcon,
  VerifiedUserOutlined as AddAdminIcon,
  RemoveCircleOutlineOutlined as RemoveIcon,
  PersonAdd as AddUserIcon,
  DeleteOutlineOutlined as DeleteUserIcon,
  Home as CustomerIcon,
  Star as OwnerIcon,
  StarOutline as AddOwnerIcon,
  MenuBook as BookkeeperIcon,
  ImportContacts as AddBookkeeperIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  CreditCardOutlined as CreditCardOutlinedIcon,
} from '@material-ui/icons';
import ReactDataGrid from '@inovua/reactdatagrid-community';

// UTILITIES
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import Papa from 'papaparse';
import moment from 'moment';
import numeral from 'numeral';

import {
  GetCompanyInfoAction,
  SendCrewMemberInvitationAction,
  GetCompanyCrewMemberInvitationsAction,
  DeleteCrewMemberInvitationAction,
  RemoveCompanyCrewMemberAction,
  AddCompanyCrewMemberAction,
  MakeCompanyAdminAction,
  TransferCompanyAdminAction,
  UpdateUserAdminMutationAction,
} from '../../graphql/graphql';
import GetCompanyCrewAdmin from '../../graphql/queries/get-company-crew-admin';
import GetCompanyCrew from '../../graphql/queries/get-company-crew';

import themePalette from '../../theme/palette';
import {
  runAnalytics,
  getCompanyCrewBreakdown,
  downloadBlob,
  determineUserRole,
} from '../../helpers';
import InviteCrewMember from './invite-crew-member';
import CrewMemberInvitations from './crew-member-invitations';
import ConfirmUserRemoval from './remove-crew-member-confirmation';
import ConfirmUserAddition from './add-crew-member-confirmation';
import AssignRoleConfirmationDialog from './assign-role-confirmation-dialog';
import RevokeRoleConfirmationDialog from './revoke-role-confirmation-dialog';
import LevelAccountingLogo from '../level-accounting-logo/level-accounting-logo';

import EditCrewMemberDialog from './edit-crew-member-dialog';
import ManageCreditCardsDialog from './manage-credit-cards-dialog';
import LoadingCover from '../LoadingCover/loadingCover';

import {
  CREW_ROLES_OPTIONS,
  CREW_DEPARTMENT_OPTIONS,
  CREW_PAY_TYPES_OPTIONS,
  CREW_PAY_TYPES,
  CREW_VACA_ACCRUAL_TYPES_OPTIONS,
  LEVEL_ROLE,
} from '../../config/appDefaults';
import AdminToolsTitle from '../admin-tools-title/admin-tools-title';

const useStyles = makeStyles(theme => ({
  crewDataGrid: {
    '& .InovuaReactDataGrid__row': {
      cursor: 'pointer',
    },
  },
  actionButtonsWrapper: {
    padding: theme.spacing(3),
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },
  scrollableColumn: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'auto',
    height: 'calc(100vh - 64px)',
  },
  editButton: {
    padding: theme.spacing(0.5),
    margin: 0,
    minWidth: 0,
    color: '#aaa',
    '&:hover': {
      background: 'transparent',
      color: '#333',
    },
  },
  badge: {
    '& span': {
      height: 14,
      minWidth: 14,
      fontSize: 9,
      padding: '0px 2px',
    },
  },
  tooltip: {
    fontSize: 'inherit',
    color: 'inherit',
    lineHeight: 'inherit',
  },
}));

// Border style
const largeWhiteBorder = '10px solid #ffffff';

// Grid style
const gridStyle = { height: '100%', minHeight: '100%' };

// Grid scroll stype
const scrollProps = {
  ...ReactDataGrid.defaultProps.scrollProps,
  autoHide: false,
  scrollThumbWidth: 12,
  scrollThumbStyle: {
    background: themePalette.brandColorPrimary,
  },
};

const ManageCompanyCrew = ({
  userInfo,
  companies,
  onSendCrewMemberInvitation,
  getCompanyCrewMemberInvitations,
  getCompanyCrewMemberInvitationsRefetch,
  onDeleteCrewMemberInvitation,
  onRemoveCompanyCrewMember,
  onAddCompanyCrewMember,
  onMakeCompanyAdmin,
  onTransferCompanyAdmin,
  managingCompanyInfo,
  onUpdateUserAdmin,
}) => {
  const classes = useStyles();
  const [gridRef, setGridRef] = useState(null);

  // set query based on user role
  let queryToUse = GetCompanyCrew;
  let dataPath = 'getCompanyCrew';
  if (managingCompanyInfo) {
    // if bookkeeper or admin, use GetCompanyCrewAdminAction, otherwise use GetCompanyCrewAction
    if (managingCompanyInfo.isCompanyAdmin) {
      queryToUse = GetCompanyCrewAdmin;
      dataPath = 'getCompanyCrewAdmin';
    }
  }
  const GetCrewQuery = useQuery(queryToUse, {
    fetchPolicy: 'cache-and-network',
    variables: { companyId: managingCompanyInfo.managingCompanyId },
  });

  const companyCrew = _.get(GetCrewQuery, `data[${dataPath}].items`, null);
  const companyCrewLoading = _.get(GetCrewQuery, 'loading', null);

  const [manageCardsDialog, setManageCardsDialog] = useState({
    open: false,
    user: null,
  });
  const handleManageCardsDialogClose = () => {
    setManageCardsDialog({ open: false, user: null });
  };

  const [manageInvitesDialog, setManageInvitesDialog] = useState({
    open: false,
  });
  const handleInvitesDialogClose = () => {
    setManageInvitesDialog({ open: false });
  };

  const [editUserDialog, setEditUserDialog] = useState({
    open: false,
  });
  const handleEditDialogClose = () => {
    setEditUserDialog({ open: false });
  };

  const editThisUser = userId => {
    const user = _.find(companyCrew, { userId });
    setEditUserDialog({ open: true, user });
  };

  const manageCreditCards = userId => {
    const user = _.find(companyCrew, { userId });
    setManageCardsDialog({ open: true, user });
  };

  const [showInviteCrewMemberForm, setShowInviteCrewMemberForm] = useState(
    false
  );
  const [assignRoleConfirmDialog, setAssignRoleConfirmDialog] = useState({
    open: false,
    user: null,
    role: null,
  });
  const [revokeRoleConfirmDialog, setRevokeRoleConfirmDialog] = useState({
    open: false,
    user: null,
    role: null,
    forceTransfer: false,
    provisionalCandidates: null,
  });
  const [confirmUserForRemoval, setConfirmUserForRemoval] = useState(null);
  const [confirmUserForAddition, setConfirmUserForAddition] = useState(null);
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const [pendingUserActions, setPendingUserActions] = useState({});
  const [invitationsForDeletion, setInvitationsForDeletion] = useState([]);
  const [showActiveUsers, setShowActiveUsers] = useState(true);

  const addCrewMember = async ({ userToAdd }) => {
    setPendingUserActions(currentState => ({
      ...currentState,
      [userToAdd.userId]: true,
    }));

    try {
      setConfirmUserForAddition(null);
      await onAddCompanyCrewMember({ userId: userToAdd.userId });

      const options = {
        userId: userInfo.userId,
        friendId: userToAdd.userId,
        friendUsername: userToAdd.username,
        type: 'Add Crew To Company',
        email: userInfo.email,
        companyId: managingCompanyInfo.managingCompanyId,
      };

      runAnalytics('Company Crew', options);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }

    setPendingUserActions(currentState => ({
      ...currentState,
      [userToAdd.userId]: false,
    }));
  };

  const removeCrewMember = async ({ userToRemove }) => {
    setPendingUserActions(currentState => ({
      ...currentState,
      [userToRemove.userId]: true,
    }));

    try {
      setConfirmUserForRemoval(null);
      await onRemoveCompanyCrewMember({ userId: userToRemove.userId });
      const options = {
        userId: userInfo.userId,
        friendId: userToRemove.userId,
        friendUsername: userToRemove.username,
        type: 'Remove Crew From Company',
        email: userInfo.email,
        companyId: managingCompanyInfo.managingCompanyId,
      };

      runAnalytics('Company Crew', options);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }

    setPendingUserActions(currentState => ({
      ...currentState,
      [userToRemove.userId]: false,
    }));
  };

  const company = useMemo(() => {
    const foundCompany = companies
      ? _.find(companies, {
          companyId: managingCompanyInfo.managingCompanyId,
        })
      : null;
    return foundCompany;
  }, [companies, managingCompanyInfo]);

  const companyCrewBreakdown = useMemo(() => {
    return getCompanyCrewBreakdown(company, companyCrew);
  }, [company, companyCrew]);

  const showAssignRoleConfirmDialog = ({ userId, role }) => {
    const userToAssign = _.find(
      companyCrew,
      ({ userId: userIdToAssign }) => userIdToAssign === userId
    );

    setAssignRoleConfirmDialog({
      open: true,
      user: userToAssign,
      role,
    });
  };

  const hideAssignRoleConfirmDialog = () => {
    setAssignRoleConfirmDialog({
      open: false,
      user: null,
      role: null,
    });
  };

  const determineCanRevokeRole = ({ userId, role }) => {
    const result = {
      canRevoke: false,
      fromUser: null,
      forceTransfer: false,
      provisionalCandidates: null,
    };

    result.fromUser = _.find(
      companyCrew,
      ({ userId: fromUserId }) => fromUserId === userId
    );

    if (role === LEVEL_ROLE.ADMIN) {
      // Force transfer if the user revokes their own admin status
      // and there is only one admin in the company
      result.forceTransfer =
        userId === userInfo.userId && company?.admins?.length <= 1;

      if (result.forceTransfer) {
        // only normal users can be provisional candidates
        result.provisionalCandidates = [...companyCrewBreakdown.users];
      }

      if (managingCompanyInfo?.isCompanyAdmin && result.fromUser) {
        result.canRevoke = true;
      }
    } else if (role === LEVEL_ROLE.BOOKKEEPER) {
      // Force transfer if the user revokes their own bookkeeper status
      // and there is no company owner and only one bookkeeper in the company
      result.forceTransfer =
        userId === userInfo.userId &&
        companyCrewBreakdown.owners.length <= 0 &&
        companyCrewBreakdown.levelAccounting.length +
          companyCrewBreakdown.nonLevelBookkeepers.length <=
          1;

      if (result.forceTransfer) {
        // only admins (not bookkeepers/owners) and normal users can be provisional candidates
        result.provisionalCandidates = [
          ...companyCrewBreakdown.adminsWithoutOtherRoles,
          ...companyCrewBreakdown.users,
        ];
      }

      if (
        (managingCompanyInfo?.isCompanyOwner ||
          managingCompanyInfo?.isCompanyBookkeeper) &&
        result.fromUser
      ) {
        result.canRevoke = true;
      }
    } else if (role === LEVEL_ROLE.COMPANY_OWNER) {
      // Force transfer if there is only one owner in the company
      result.forceTransfer = companyCrewBreakdown.owners.length <= 1;

      if (result.forceTransfer) {
        // only admins (not bookkeepers/owners) and normal users can be provisional candidates
        result.provisionalCandidates = [
          ...companyCrewBreakdown.adminsWithoutOtherRoles,
          ...companyCrewBreakdown.users,
        ];
      }

      if (
        (managingCompanyInfo?.isCompanyOwner ||
          managingCompanyInfo?.isCompanyBookkeeper) &&
        result.fromUser
      ) {
        result.canRevoke = true;
      }
    }

    // in case transferring is needed,
    // if there is no one to transfer to, then we can't revoke
    if (result.forceTransfer && _.isEmpty(result.provisionalCandidates)) {
      result.canRevoke = false;
    }

    return result;
  };

  const showRevokeRoleConfirmDialog = ({ userId, role }) => {
    const {
      canRevoke,
      fromUser,
      forceTransfer,
      provisionalCandidates,
    } = determineCanRevokeRole({ userId, role });

    if (!canRevoke) {
      return;
    }

    setRevokeRoleConfirmDialog({
      open: true,
      user: fromUser,
      role,
      forceTransfer,
      provisionalCandidates,
    });
  };

  const hideRevokeRoleConfirmDialog = () => {
    setRevokeRoleConfirmDialog({
      open: false,
      user: null,
      role: null,
      forceTransfer: false,
      provisionalCandidates: null,
    });
  };

  const assignCompanyRole = async ({
    userToAssign,
    role = LEVEL_ROLE.ADMIN,
  }) => {
    setPendingUserActions(currentState => ({
      ...currentState,
      [userToAssign.userId]: true,
    }));

    const roleToUse = role === LEVEL_ROLE.ADMIN ? null : role;

    try {
      hideAssignRoleConfirmDialog();
      await onMakeCompanyAdmin({
        userId: userToAssign.userId,
        role: roleToUse,
      });

      const wording =
        role === LEVEL_ROLE.COMPANY_OWNER ? 'Owner' : _.capitalize(role);

      const options = {
        userId: userInfo.userId,
        friendId: userToAssign.userId,
        friendUsername: userToAssign.username,
        type: `Make Company ${wording}`,
        email: userInfo.email,
        companyId: managingCompanyInfo.managingCompanyId,
      };

      runAnalytics(`Company ${wording}`, options);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }

    setPendingUserActions(currentState => ({
      ...currentState,
      [userToAssign.userId]: false,
    }));
  };

  const transferRole = async ({
    fromUser,
    toUserId,
    role = LEVEL_ROLE.ADMIN,
  }) => {
    const roleToUse = role === LEVEL_ROLE.ADMIN ? null : role;
    setPendingUserActions(currentState => ({
      ...currentState,
      [fromUser.userId]: true,
      ...(!!toUserId && { [toUserId]: true }),
    }));

    try {
      await onTransferCompanyAdmin({
        fromUserId: fromUser.userId,
        toUserId,
        role: roleToUse,
      });

      const wording =
        role === LEVEL_ROLE.COMPANY_OWNER ? 'Owner' : _.capitalize(role);

      const options = {
        userId: userInfo.userId,
        friendId: fromUser.userId,
        friendUsername: fromUser.username,
        type: `${toUserId ? 'Transfer' : 'Revoke'} Company ${wording}`,
        email: userInfo.email,
        companyId: managingCompanyInfo.managingCompanyId,
      };

      runAnalytics(`Company ${wording}`, options);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }

    setPendingUserActions(currentState => ({
      ...currentState,
      [fromUser.userId]: false,
      ...(!!toUserId && { [toUserId]: false }),
    }));
  };

  const handleRevokeRoleConfirm = ({
    userToRevoke: fromUser,
    transferToUserId = null,
    role,
  }) => {
    hideRevokeRoleConfirmDialog();
    // if transferToUserId is provided, transfer role
    if (transferToUserId) {
      return transferRole({ fromUser, toUserId: transferToUserId, role });
    }

    // otherwise, revoking role
    if (role === LEVEL_ROLE.ADMIN) {
      return transferRole({ fromUser, role });
    }

    // if bookkeeper or owner, remove role by assigning admin role
    return assignCompanyRole({
      userToAssign: fromUser,
      role: LEVEL_ROLE.ADMIN,
    });
  };

  const showRemoveCrewMemberConfirmDialog = userId => {
    const userForRemoval = _.find(
      companyCrew,
      ({ userId: userIdForRemoval }) => userIdForRemoval === userId
    );

    setConfirmUserForRemoval(userForRemoval);
  };

  const showAddCrewMemberConfirmDialog = userId => {
    const userForAddition = _.find(
      companyCrew,
      ({ userId: userIdForAddition }) => userIdForAddition === userId
    );

    setConfirmUserForAddition(userForAddition);
  };

  const canManageCreditCards =
    managingCompanyInfo.isConnectedToMazumaGo &&
    (managingCompanyInfo.isCompanyOwner ||
      managingCompanyInfo.isLevelAccounting ||
      managingCompanyInfo.isCompanyBookkeeper);

  const canManageOwnersAndBookkeepers =
    managingCompanyInfo.isCompanyOwner ||
    managingCompanyInfo.isLevelAccounting ||
    managingCompanyInfo.isCompanyBookkeeper;

  const getActionButtons = ({
    userId,
    type,
    userType,
    isActive,
    availableSeats,
    assignedCards,
  }) => {
    const isMe = userInfo.userId === userId;
    const {
      isOwner,
      isAdmin,
      isBookkeeper,
      isLevelAccounting,
    } = determineUserRole({
      companyInfo: company,
      userInfo: {
        userId,
        type,
        userType,
      },
    });
    const noOfCards = assignedCards ? assignedCards.length : 0;

    let editAction = null;
    let creditCardAction = null;
    let adminAction = null;
    let deleteAction = null;
    let ownerAction = null;
    let bookkeeperAction = null;

    if (
      managingCompanyInfo.isCompanyOwner ||
      managingCompanyInfo.isLevelAccounting ||
      managingCompanyInfo.isCompanyBookkeeper
    ) {
      editAction = (
        <Tooltip title="Edit Crew Details">
          <Button
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              editThisUser(userId);
            }}
            className={classes.editButton}
          >
            <EditIcon />
          </Button>
        </Tooltip>
      );
    }

    if (canManageCreditCards && isActive) {
      creditCardAction = (
        <Tooltip
          title={
            <>
              <Typography className={classes.tooltip}>
                Manage credit cards
              </Typography>
              {noOfCards > 0 && (
                <Typography
                  className={classes.tooltip}
                >{`${noOfCards} assigned cards`}</Typography>
              )}
            </>
          }
        >
          <Button
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              manageCreditCards(userId);
            }}
            className={classes.editButton}
          >
            {noOfCards > 0 ? (
              <Badge
                badgeContent={noOfCards}
                color="primary"
                overlap="circular"
                className={classes.badge}
              >
                <CreditCardOutlinedIcon />
              </Badge>
            ) : (
              <CreditCardOutlinedIcon />
            )}
          </Button>
        </Tooltip>
      );
    }

    if (canManageOwnersAndBookkeepers && isActive) {
      if (!isOwner && !isBookkeeper && isAdmin) {
        ownerAction = (
          <Tooltip title="Make company owner">
            <Button
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                showAssignRoleConfirmDialog({
                  userId,
                  role: LEVEL_ROLE.COMPANY_OWNER,
                });
              }}
              className={classes.editButton}
            >
              <AddOwnerIcon />
            </Button>
          </Tooltip>
        );
      }

      if (isOwner) {
        const { canRevoke } = determineCanRevokeRole({
          userId,
          role: LEVEL_ROLE.COMPANY_OWNER,
        });

        if (canRevoke) {
          ownerAction = (
            <Tooltip title="Revoke owner status">
              <Button
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  showRevokeRoleConfirmDialog({
                    userId,
                    role: LEVEL_ROLE.COMPANY_OWNER,
                  });
                }}
                className={classes.editButton}
              >
                <RemoveIcon />
              </Button>
            </Tooltip>
          );
        }
      }

      if (!isOwner && !isBookkeeper && isAdmin) {
        bookkeeperAction = (
          <Tooltip title="Make company bookkeeper">
            <Button
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                showAssignRoleConfirmDialog({
                  userId,
                  role: LEVEL_ROLE.BOOKKEEPER,
                });
              }}
              className={classes.editButton}
            >
              <AddBookkeeperIcon />
            </Button>
          </Tooltip>
        );
      }

      if (isBookkeeper && !isLevelAccounting) {
        const { canRevoke } = determineCanRevokeRole({
          userId,
          role: LEVEL_ROLE.BOOKKEEPER,
        });

        if (canRevoke) {
          bookkeeperAction = (
            <Tooltip title="Revoke bookkeeper status">
              <Button
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  showRevokeRoleConfirmDialog({
                    userId,
                    role: LEVEL_ROLE.BOOKKEEPER,
                  });
                }}
                className={classes.editButton}
              >
                <RemoveIcon />
              </Button>
            </Tooltip>
          );
        }
      }
    }

    if (isActive) {
      if (availableSeats > 0 && !isAdmin) {
        adminAction = (
          <Tooltip title="Make company admin">
            <Button
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                showAssignRoleConfirmDialog({
                  userId,
                  role: LEVEL_ROLE.ADMIN,
                });
              }}
              className={classes.editButton}
            >
              <AddAdminIcon />
            </Button>
          </Tooltip>
        );
      }

      if (isAdmin && !isBookkeeper && !isOwner) {
        const { canRevoke } = determineCanRevokeRole({
          userId,
          role: LEVEL_ROLE.ADMIN,
        });

        if (canRevoke) {
          adminAction = (
            <Tooltip title="Revoke admin status">
              <Button
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  showRevokeRoleConfirmDialog({
                    userId,
                    role: LEVEL_ROLE.ADMIN,
                  });
                }}
                className={classes.editButton}
              >
                <RemoveIcon />
              </Button>
            </Tooltip>
          );
        }
      }

      if (!isMe && !isOwner && !isBookkeeper) {
        deleteAction = (
          <Tooltip title="Remove from company">
            <Button
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                showRemoveCrewMemberConfirmDialog(userId);
              }}
              className={classes.editButton}
            >
              <DeleteUserIcon />
            </Button>
          </Tooltip>
        );
      }
    } else {
      adminAction = (
        <Tooltip title="Add to company">
          <Button
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              showAddCrewMemberConfirmDialog(userId);
            }}
            className={classes.editButton}
          >
            <AddUserIcon />
          </Button>
        </Tooltip>
      );
    }

    return (
      <>
        <div style={{ flex: 1 }}>{editAction}</div>
        {canManageCreditCards && (
          <div style={{ flex: 1 }}>{creditCardAction}</div>
        )}
        <div style={{ flex: 1 }}>{adminAction}</div>
        {canManageOwnersAndBookkeepers && (
          <>
            <div style={{ flex: 1 }}>{bookkeeperAction}</div>
            <div style={{ flex: 1 }}>{ownerAction}</div>
          </>
        )}
        <div style={{ flex: 1 }}>{deleteAction}</div>
      </>
    );
  };

  const dataSource = useMemo(() => {
    if (company && companyCrew) {
      const {
        admins: companyAdmins,
        users: companyUsers,
        // owners: companyOwners,
      } = company;

      const filteredUsers = companyCrew.filter(({ userId }) => {
        const isActive =
          companyAdmins.includes(userId) || companyUsers.includes(userId);
        if (showActiveUsers) {
          return isActive;
        }
        return !isActive;
      });

      const orderedUsers = _.orderBy(filteredUsers, ['username', 'asc']);

      const { availableAdminSeats: availableSeats } = companyCrewBreakdown;

      return orderedUsers.map(
        ({
          userId,
          username,
          firstName,
          lastName,
          email,
          phoneNumber,
          type,
          userType,
          role,
          department,
          payType,
          payRate,
          payrollId,
          laborBurdenPercentage,
          overtimeRate,
          vacaAccrualRate,
          vacaAccrualType,
          cards: assignedCards,
        }) => {
          // Check if they are an owner
          let actions = null;

          const pendingUserAction = !!pendingUserActions[userId];

          actions = (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {pendingUserAction ? (
                <CircularProgress size={20} />
              ) : (
                getActionButtons({
                  userId,
                  type,
                  userType,
                  isActive: showActiveUsers,
                  availableSeats,
                  assignedCards,
                })
              )}
            </div>
          );

          return {
            actions,
            userId,
            username,
            firstName,
            lastName,
            type,
            userType,
            ...(managingCompanyInfo.isCompanyAdmin && {
              email: managingCompanyInfo.isCompanyAdmin ? email : null,
              phoneNumber: managingCompanyInfo.isCompanyAdmin
                ? phoneNumber
                : null,
              role,
              department,
              payType,
              payRate,
              payrollId,
              laborBurdenPercentage,
              overtimeRate,
              vacaAccrualRate,
              vacaAccrualType,
            }),
          };
        }
      );
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showActiveUsers,
    company,
    companyCrew,
    pendingUserActions,
    companyCrewBreakdown,
  ]);

  const invitations = useMemo(() => {
    let invitationsToSet = null;
    if (
      getCompanyCrewMemberInvitations &&
      getCompanyCrewMemberInvitations.items
    ) {
      invitationsToSet = _.orderBy(
        [...getCompanyCrewMemberInvitations.items],
        ['sentOn'],
        ['desc']
      );
    }

    _.each(invitationsToSet, invitation => {
      const passedInvitation = invitation;
      if (invitationsForDeletion.includes(invitation.invitationId)) {
        passedInvitation.isProcessing = true;
      }
    });

    if (invitationsToSet && pendingInvitations.length !== 0) {
      invitationsToSet = [
        ..._.reverse(pendingInvitations),
        ...invitationsToSet,
      ];
    }

    return invitationsToSet;
  }, [
    getCompanyCrewMemberInvitations,
    pendingInvitations,
    invitationsForDeletion,
  ]);

  const sendInvitation = async (email, role) => {
    const thisPendingId = uuid();

    // Add request to pending invitations
    setPendingInvitations([
      ...pendingInvitations,
      { email, role, pendingId: thisPendingId, isProcessing: true },
    ]);

    await onSendCrewMemberInvitation({ email, role });
    const options = {
      userId: userInfo.userId,
      email: userInfo.email,
      type: 'Sent Invitation',
      invitedEmail: email,
      invitedRole: role,
      companyId: managingCompanyInfo.managingCompanyId,
    };
    runAnalytics('Invitation', options);

    // Remove request from pending invitations
    const pendingInvitationsCopy = [...pendingInvitations];
    _.remove(
      pendingInvitationsCopy,
      ({ pendingId = null }) => pendingId === thisPendingId
    );
    setPendingInvitations([...pendingInvitations]);
  };

  const deleteInvitation = async invitationId => {
    // Add invitationId to list
    setInvitationsForDeletion([...invitationsForDeletion, invitationId]);

    await onDeleteCrewMemberInvitation({ invitationId });
    const options = {
      userId: userInfo.userId,
      invitationId,
      type: 'Delete Invitation',
      email: userInfo.email,
      companyId: managingCompanyInfo.managingCompanyId,
    };
    runAnalytics('Invitation', options);
    await getCompanyCrewMemberInvitationsRefetch();
  };

  // Column definitions
  const minColumnWidth = 200;
  const maxColumnWidth = 300;
  const columns = [
    {
      name: 'username',
      header: 'Username',
      minWidth: minColumnWidth,
      maxWidth: maxColumnWidth,
      defaultFlex: 1,
      render: ({ data, value: username }) => {
        const { userId, type, userType } = data;
        const {
          isOwner,
          isAdmin,
          isBookkeeper,
          isLevelAccounting,
          isCustomer,
        } = determineUserRole({
          companyInfo: company,
          userInfo: {
            userId,
            userType,
            type,
          },
        });

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {username}
            {isAdmin && !isLevelAccounting && (
              <Tooltip title="Company Admin">
                <AdminIcon
                  style={{
                    marginLeft: 8,
                    color: themePalette.brandColorPrimary,
                  }}
                />
              </Tooltip>
            )}
            {isBookkeeper && !isLevelAccounting && (
              <Tooltip title="Bookkeeper">
                <BookkeeperIcon
                  style={{
                    marginLeft: 8,
                    color: themePalette.brandColorDarkGrey,
                  }}
                />
              </Tooltip>
            )}
            {isOwner && (
              <Tooltip title="Company Owner">
                <OwnerIcon
                  style={{
                    marginLeft: 8,
                    color: themePalette.brandColorPrimary,
                  }}
                />
              </Tooltip>
            )}
            {isLevelAccounting && (
              <Tooltip title="Level Accounting">
                <LevelAccountingLogo style={{ marginLeft: 8 }} />
              </Tooltip>
            )}
            {isCustomer && (
              <Tooltip title="Customer">
                <CustomerIcon
                  style={{
                    marginLeft: 8,
                    color: themePalette.brandColorDarkGrey,
                  }}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      name: 'firstName',
      header: 'First Name',
      minWidth: minColumnWidth,
      maxWidth: maxColumnWidth,
      defaultFlex: 1,
    },
    {
      name: 'lastName',
      header: 'Last Name',
      minWidth: minColumnWidth,
      maxWidth: maxColumnWidth,
      defaultFlex: 1,
    },
  ];

  const valToLabel = (value, options) => {
    const info = _.find(options, { value });
    if (info) {
      return info.label;
    }
    return null;
  };

  const renderPercentage = value => {
    return value && value !== '0.00' ? `${value}%` : null;
  };

  if (managingCompanyInfo.isCompanyAdmin) {
    let actionsColumnWidth = 120;
    if (canManageCreditCards) {
      actionsColumnWidth += 40;
    }
    if (canManageOwnersAndBookkeepers) {
      actionsColumnWidth += 80;
    }

    columns.unshift(
      {
        name: 'actions',
        header: null,
        minWidth: actionsColumnWidth,
        maxWidth: actionsColumnWidth,
        defaultFlex: 0,
      },
      {
        name: 'userId',
        header: 'UserId',
        minWidth: minColumnWidth,
        maxWidth: maxColumnWidth,
        defaultFlex: 1,
        defaultVisible: false,
      }
    );
    columns.push(
      {
        name: 'email',
        header: 'Email',
        minWidth: minColumnWidth,
        maxWidth: maxColumnWidth,
        defaultFlex: 1,
      },
      {
        name: 'phoneNumber',
        header: 'Phone Number',
        minWidth: minColumnWidth,
        maxWidth: maxColumnWidth,
        defaultFlex: 1,
      },
      {
        name: 'role',
        header: 'Role',
        minWidth: minColumnWidth,
        maxWidth: maxColumnWidth,
        defaultFlex: 1,
        render: ({ value }) => {
          return valToLabel(value, CREW_ROLES_OPTIONS);
        },
      },
      {
        name: 'department',
        header: 'Department',
        minWidth: minColumnWidth,
        maxWidth: maxColumnWidth,
        defaultFlex: 1,
        render: ({ value }) => {
          return valToLabel(value, CREW_DEPARTMENT_OPTIONS);
        },
      },
      {
        name: 'payrollId',
        header: 'Payroll ID',
        minWidth: minColumnWidth,
        maxWidth: maxColumnWidth,
        defaultFlex: 1,
      },
      {
        name: 'payType',
        header: 'Pay Type',
        minWidth: minColumnWidth,
        maxWidth: maxColumnWidth,
        defaultFlex: 1,
        render: ({ value }) => {
          return valToLabel(value, CREW_PAY_TYPES_OPTIONS);
        },
      },
      {
        name: 'payRate',
        header: 'Pay Rate',
        minWidth: minColumnWidth,
        maxWidth: maxColumnWidth,
        defaultFlex: 1,
        render: ({ value, data: { payType } }) => {
          return value && value !== '0.00'
            ? `${numeral(value).format('$0,0.00')} per ${
                payType === CREW_PAY_TYPES.FIXED_SALARY ? 'year' : 'hour'
              }`
            : null;
        },
      },
      {
        name: 'overtimeRate',
        header: 'Overtime Rate',
        minWidth: minColumnWidth,
        maxWidth: maxColumnWidth,
        defaultFlex: 1,
        render: ({ value }) => {
          return value && value !== '0.00'
            ? `${numeral(value).format('$0,0.00')} per hour`
            : null;
        },
      },
      {
        name: 'vacaAccrualType',
        header: 'Vaca Accrual Type',
        minWidth: minColumnWidth,
        maxWidth: maxColumnWidth,
        defaultFlex: 1,
        render: ({ value }) => {
          return valToLabel(value, CREW_VACA_ACCRUAL_TYPES_OPTIONS);
        },
      },
      {
        name: 'vacaAccrualRate',
        header: 'Vacation Accrual %',
        minWidth: minColumnWidth,
        maxWidth: maxColumnWidth,
        defaultFlex: 1,
        render: ({ value }) => {
          return renderPercentage(value);
        },
      },
      {
        name: 'laborBurdenPercentage',
        header: 'Labor Burden % (excl. Vacation Accrual)',
        minWidth: minColumnWidth,
        maxWidth: maxColumnWidth,
        defaultFlex: 1,
        render: ({ value }) => {
          return renderPercentage(value);
        },
      },
      {
        name: 'totalLaborBurdenPercentage',
        header: 'Total Labor Burden %',
        minWidth: minColumnWidth,
        maxWidth: maxColumnWidth,
        defaultFlex: 1,
        render: ({ data: { laborBurdenPercentage, vacaAccrualRate } }) => {
          const total = Number(laborBurdenPercentage) + Number(vacaAccrualRate);
          return renderPercentage(numeral(total).format('0.00'));
        },
      }
    );
  }

  const onSelectionChange = ({ selected }) => {
    editThisUser(selected);
  };

  const exportCSV = (allOrVisibleColumns = 'allColumns') => {
    const percentageRenders = ['laborBurdenPercentage', 'vacaAccrualRate'];
    const labelRenders = ['role', 'department', 'payType', 'vacaAccrualType'];
    const currentColumns = gridRef.current[allOrVisibleColumns];

    // remove the delete/edit column
    const columnsToRemove = ['actions'];
    _.remove(currentColumns, column => columnsToRemove.includes(column.name));

    const rows = gridRef.current.data.map(data => {
      return currentColumns.map(column => {
        if (labelRenders.includes(column.id)) {
          const mappings = {
            role: CREW_ROLES_OPTIONS,
            department: CREW_DEPARTMENT_OPTIONS,
            payType: CREW_PAY_TYPES_OPTIONS,
            vacaAccrualType: CREW_VACA_ACCRUAL_TYPES_OPTIONS,
          };
          return valToLabel(data[column.id], mappings[column.id]);
        }
        if (percentageRenders.includes(column.id)) {
          const val = data[column.id];
          if (val) {
            return numeral(val)
              .divide(100)
              .format('0.0000');
          }
          return null;
        }
        if (column.id === 'totalLaborBurdenPercentage') {
          const { laborBurdenPercentage, vacaAccrualRate } = data;
          const total = Number(laborBurdenPercentage) + Number(vacaAccrualRate);
          return numeral(total)
            .divide(100)
            .format('0.0000');
        }
        // default case
        return data[column.id];
      });
    });

    const csvOutput = Papa.unparse({
      fields: currentColumns.map(column => column.header),
      data: rows,
    });

    const blob = new Blob([csvOutput], {
      type: 'text/csv;charset=utf-8;',
    });

    const filename = `crew-export-${moment().format(
      'YYYY-MM-DD-HH-mm-ss'
    )}.csv`;

    downloadBlob(blob, filename);
    runAnalytics('Contents', {
      contentAction: 'Export Content',
      userId: userInfo.userId,
      username: userInfo.username,
      type: 'Admin Leads',
    });
  };

  return (
    <>
      <div className={classes.scrollableColumn}>
        <Grid container direction="column" style={{ flex: 1 }}>
          <Grid
            item
            container
            justifyContent="flex-end"
            className={classes.actionButtonsWrapper}
          >
            <Grid
              item
              xs={managingCompanyInfo.isCompanyAdmin ? 4 : 12}
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <AdminToolsTitle
                titleText={
                  managingCompanyInfo.isCompanyAdmin
                    ? 'Manage Crew'
                    : 'View Crew'
                }
                Icon={CrewIcon}
              />
            </Grid>
            {managingCompanyInfo.isCompanyAdmin && (
              <Grid
                item
                xs={8}
                style={{ justifyContent: 'flex-end', display: 'flex' }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => exportCSV()}
                >
                  Export
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setShowActiveUsers(!showActiveUsers)}
                >
                  {showActiveUsers ? 'Show Removed Users' : 'Show Active Users'}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setManageInvitesDialog({ open: true })}
                >
                  Manage Invites
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid container item style={{ flex: 1 }}>
            <Grid
              container
              item
              xs={12}
              style={{
                borderTop: largeWhiteBorder,
                borderLeft: largeWhiteBorder,
                borderRight: largeWhiteBorder,
              }}
            >
              {companyCrewLoading ? (
                <LoadingCover loader="linear">
                  <Typography variant="h3" align="center">
                    Loading company crew...
                  </Typography>
                </LoadingCover>
              ) : (
                <>
                  {company && companyCrew ? (
                    <ReactDataGrid
                      className={classes.crewDataGrid}
                      onReady={setGridRef}
                      idProperty="userId"
                      columns={columns}
                      dataSource={dataSource}
                      style={gridStyle}
                      scrollProps={scrollProps}
                      enableSelection
                      onSelectionChange={onSelectionChange}
                    />
                  ) : (
                    <Grid container style={{ marginTop: 32 }}>
                      <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography
                          variant="h4"
                          align="center"
                          style={{ marginBottom: 16 }}
                        >
                          There was a temporary issue loading your crew.
                          <br />
                          Please reload the page and try again.
                        </Typography>
                        <Button
                          variant="contained"
                          onClick={() => window.location.reload()}
                        >
                          Reload Page
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
      {showInviteCrewMemberForm && (
        <InviteCrewMember
          handleClose={() => setShowInviteCrewMemberForm(false)}
          sendInvitation={sendInvitation}
        />
      )}
      {confirmUserForRemoval && (
        <ConfirmUserRemoval
          userToRemove={confirmUserForRemoval}
          onConfirm={removeCrewMember}
          onCancel={() => setConfirmUserForRemoval(null)}
        />
      )}
      {confirmUserForAddition && (
        <ConfirmUserAddition
          userToAdd={confirmUserForAddition}
          onConfirm={addCrewMember}
          onCancel={() => setConfirmUserForAddition(null)}
        />
      )}
      {assignRoleConfirmDialog?.open && (
        <AssignRoleConfirmationDialog
          role={assignRoleConfirmDialog.role}
          userToAssign={assignRoleConfirmDialog.user}
          onConfirm={assignCompanyRole}
          onCancel={hideAssignRoleConfirmDialog}
        />
      )}
      {revokeRoleConfirmDialog?.open && (
        <RevokeRoleConfirmationDialog
          role={revokeRoleConfirmDialog.role}
          userToRevoke={revokeRoleConfirmDialog.user}
          forceTransfer={revokeRoleConfirmDialog.forceTransfer}
          provisionalCandidates={revokeRoleConfirmDialog.provisionalCandidates}
          onConfirm={handleRevokeRoleConfirm}
          onCancel={hideRevokeRoleConfirmDialog}
        />
      )}
      {managingCompanyInfo.isCompanyAdmin && manageInvitesDialog.open && (
        <Dialog
          open
          onClose={handleInvitesDialogClose}
          aria-labelledby="simple-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            style={{ paddingTop: 8, paddingBottom: 8, paddingRight: 8 }}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="h5">Manage Crew Invites </Typography>
              <IconButton onClick={handleInvitesDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container style={{ justifyContent: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowInviteCrewMemberForm(true)}
              >
                Create New Invite +
              </Button>
            </Grid>

            <Typography variant="h4" style={{ marginTop: 24 }}>
              Existing Invites
            </Typography>
            <CrewMemberInvitations
              invitations={invitations}
              deleteInvitation={deleteInvitation}
            />
          </DialogContent>
        </Dialog>
      )}
      {managingCompanyInfo.isCompanyAdmin && editUserDialog.open && (
        <EditCrewMemberDialog
          user={editUserDialog.user}
          onClose={handleEditDialogClose}
          toUpdate={onUpdateUserAdmin}
        />
      )}
      {canManageCreditCards && manageCardsDialog.open && (
        <ManageCreditCardsDialog
          open={manageCardsDialog.open}
          onClose={handleManageCardsDialogClose}
          user={manageCardsDialog.user}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default compose(
  GetCompanyInfoAction,
  SendCrewMemberInvitationAction,
  GetCompanyCrewMemberInvitationsAction,
  DeleteCrewMemberInvitationAction,
  RemoveCompanyCrewMemberAction,
  AddCompanyCrewMemberAction,
  MakeCompanyAdminAction,
  TransferCompanyAdminAction,
  UpdateUserAdminMutationAction
)(connect(mapStateToProps)(ManageCompanyCrew));
