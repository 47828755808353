import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => {
  return {
    sectionHeader: {
      marginTop: 24,
      borderBottom: `1px solid ${theme.palette.brandColorPrimary40}`,
      width: '100%',
    },
  };
});

const CustomerSectionHeader = props => {
  const { text } = props;
  const classes = useStyles(props);

  return (
    <Typography
      variant={text ? 'h3' : 'h4'}
      className={classes.sectionHeader}
      style={{}}
      color="primary"
    >
      {text || 'Unsorted'}
    </Typography>
  );
};

export default CustomerSectionHeader;
