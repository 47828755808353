import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import LockableButton from '../lockable-button/lockable-button';
import TrussButtonTallDefaultImage from '../../assets/images/truss/RWT_teal_btn_tall_default.svg';
import TrussButtonTallHoverImage from '../../assets/images/truss/RWT_teal_btn_tall_hover.svg';

const useStyles = makeStyles(() => ({
  registerWithMazumaGoWrapper: {
    padding: 10,
    '& .connect-hover': {
      display: 'none',
    },
    '&:hover .connect-hover': {
      display: 'initial',
    },
    '&:hover .connect-default': {
      display: 'none',
    },
  },
}));

const RegisterWithMazumaGoButton = ({
  locked,
  onConnectToMazumaGo,
  loading,
}) => {
  const classes = useStyles();

  const handleRegisterWithMazumaGo = () => {
    onConnectToMazumaGo();
  };

  return (
    <>
      <div className={classes.registerWithMazumaGoWrapper}>
        <LockableButton
          loading={loading}
          locked={locked}
          onClick={handleRegisterWithMazumaGo}
          style={{ padding: 0, margin: 0 }}
        >
          <img
            className="connect-default"
            src={TrussButtonTallDefaultImage}
            alt="Sign Up with Truss"
          />
          <img
            className="connect-hover"
            src={TrussButtonTallHoverImage}
            alt="Sign Up with Truss"
          />
        </LockableButton>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || {},
});

export default connect(mapStateToProps)(RegisterWithMazumaGoButton);
