import gql from 'graphql-tag';
import * as Fragments from '../fragments';

export default gql`
  ${Fragments.BookkeepingEventMeta}
  mutation UpsertBookkeepingEventMeta(
    $id: ID!
    $alertInstanceId: String!
    $snapshotId: String!
    $companyId: String!
    $reportDate: String!
    $metaType: String!
    $value: String!
    $alertIdentifier: String!
  ) {
    upsertBookkeepingEventMeta(
      input: {
        id: $id
        alertInstanceId: $alertInstanceId
        snapshotId: $snapshotId
        companyId: $companyId
        reportDate: $reportDate
        metaType: $metaType
        value: $value
        alertIdentifier: $alertIdentifier
      }
    ) {
      ...BookkeepingEventMetaFields
    }
  }
`;
