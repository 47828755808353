import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Company}
  query GetCompanyById($companyId: ID!) {
    getCompanyById(companyId: $companyId) {
      ...CompanyFields
    }
  }
`;
