import basicSchemaGenerator from './basic-schema-generator';
import { descMaxValidation } from './schema-validation';

export default [
  //   run this             fieldname    type      edit   req  customValidations
  { ...basicSchemaGenerator('contentId', 'string', false, true, []) },
  { ...basicSchemaGenerator('jrnId', 'string', false, false, []) },
  { ...basicSchemaGenerator('creatorId', 'string', false, true, []) },
  { ...basicSchemaGenerator('type', 'string', true, true, []) },
  { ...basicSchemaGenerator('date', 'date', true, true, []) },
  { ...basicSchemaGenerator('startDate', 'date', false, true, []) },
  { ...basicSchemaGenerator('endDate', 'date', false, true, []) },
  {
    ...basicSchemaGenerator('description', 'string', true, false, [
      descMaxValidation,
    ]),
  },
  {
    ...basicSchemaGenerator('contentUrl', 'array', true, false, []),
  },
  { ...basicSchemaGenerator('aspectRatio', 'string', false, false, []) },
  { ...basicSchemaGenerator('labels', 'array', true, false, []) },
  { ...basicSchemaGenerator('addedBy', 'string', false, false, []) },
  { ...basicSchemaGenerator('assignedTo', 'string', false, false, []) },
  { ...basicSchemaGenerator('amount', 'string', true, true, []) },
  { ...basicSchemaGenerator('vendorId', 'string', true, false, []) },
  { ...basicSchemaGenerator('poNumber', 'string', true, false, []) },
  // for knowing if the content in the cache is from us or the server
  { ...basicSchemaGenerator('synced', 'string', false, true, []) },
];
