import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Timer } from '@material-ui/icons';

import LoadingCover from '../../LoadingCover/loadingCover';

const ReportGenerationLoadingCover = () => {
  const [showAdditionalMessage, setShowAdditionalMessage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAdditionalMessage(true);
    }, 8000); // 8000 milliseconds = 8 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <LoadingCover loader="linear" customStyles={{ opacity: 1 }}>
      <Grid container direction="column" alignItems="center">
        <Typography variant="h3" style={{ marginTop: 16 }}>
          We&apos;re now generating your reports! 📈
        </Typography>
        <Typography variant="h4" style={{ marginTop: 16 }}>
          It may take a couple minutes to do this, so we&apos;ll load them here
          when they&apos;re ready.
        </Typography>
        {showAdditionalMessage && (
          <Alert
            severity="info"
            icon={<Timer fontSize="inherit" />}
            style={{ marginTop: 32 }}
          >
            <Typography variant="h4" gutterBottom>
              Thanks for your patience!
            </Typography>
            <Typography variant="h4">
              We promise it&apos;s worth the wait. Just a little bit longer...
            </Typography>
          </Alert>
        )}
      </Grid>
    </LoadingCover>
  );
};

export default ReportGenerationLoadingCover;
