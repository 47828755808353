import React, { useEffect, useRef, useState } from 'react';

import { Dialog, Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import { compose } from 'react-apollo';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { USER_ACTIVITY_STATUS } from '../../config/appDefaults';
import StartCustomScan from '../../graphql/mutations/mutation_start-custom-scan';
import GetBookkeepingRules from '../../graphql/queries/get-bookkeeping-rules';
import GetCustomScanReport from '../../graphql/queries/get-custom-scan-report';
import GetUserActivity from '../../graphql/queries/get-user-activity';
import {
  sessionStorageGetItem,
  sessionStorageRemoveItem,
  sessionStorageSetItem,
} from '../../helpers';
import { useCompanyActivityStatus } from '../../hooks';
import BackToTopButton from '../back-to-top/back-to-top';
import BookkeepingAlertsReport from '../bookkeeping-alerts/bookkeeping-alerts-report';
import {
  ALERT_IDENTIFIERS_NOT_SUPPORTED_BY_CREATION_DATE_BASED_CUSTOM_SCAN,
  ALERT_IDENTIFIERS_NOT_SUPPORTED_BY_TRANSACTION_DATE_BASED_CUSTOM_SCAN,
  CUSTOM_SCAN_TYPE,
  IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER,
} from '../bookkeeping-alerts/bookkeeping-alerts.constants';
import { Dashboard, DASHBOARD_LAYOUT } from '../dashboard';
import LoadingCover from '../LoadingCover/loadingCover';
import OkCancelDialog from '../OkCancelDialog/okCancelDialog';
import CustomScanDashboardControlBar from './custom-scan-dashboard-control-bar';
import CustomScanSettings from './custom-scan-settings';

const SESSION_STORAGE_KEY = {
  ACTIVITY_ID_TO_SCAN: 'activityIdToScan',
  ACTIVITY_ID_TO_GET_REPORT: 'activityIdToGetReport',
  REPORT_META_DATA: 'reportMetaData',
};

const CustomScanDashboard = ({ managingCompanyInfo }) => {
  const isCopilot = managingCompanyInfo?.isCopilot;
  const containerRef = useRef(null);
  const isSessionStorageCheckDone = useRef(false);

  const [reportData, setReportData] = useState(null);
  const [loaderMessage, setLoaderMessage] = useState(null);
  const [showScanSettingsDialog, setShowScanSettingsDialog] = useState(false);
  const [settingsError, setSettingsError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [activityIdToScan, setActivityIdToScan] = useState(null);
  const [activityIdToGetReport, setActivityIdToGetReport] = useState(null);
  const [reportMetaData, setReportMetaData] = useState({});

  useEffect(() => {
    if (isSessionStorageCheckDone.current || !managingCompanyInfo) {
      return;
    }

    const foundActivityIdToScan = sessionStorageGetItem({
      key: SESSION_STORAGE_KEY.ACTIVITY_ID_TO_SCAN,
      companyId: managingCompanyInfo.companyId,
    });

    if (foundActivityIdToScan) {
      setActivityIdToScan(foundActivityIdToScan);
    }

    const foundReportMetaDataStr = sessionStorageGetItem({
      key: SESSION_STORAGE_KEY.REPORT_META_DATA,
      companyId: managingCompanyInfo.companyId,
    });

    if (foundReportMetaDataStr) {
      setReportMetaData(JSON.parse(foundReportMetaDataStr));
    }

    const foundActivityIdToGetReport = sessionStorageGetItem({
      key: SESSION_STORAGE_KEY.ACTIVITY_ID_TO_GET_REPORT,
      companyId: managingCompanyInfo.companyId,
    });

    if (foundActivityIdToGetReport) {
      setActivityIdToGetReport(foundActivityIdToGetReport);
    }

    isSessionStorageCheckDone.current = true;
  }, [managingCompanyInfo]);

  const [startCustomScan] = useMutation(StartCustomScan);

  const bookkeepingRulesQuery = useQuery(GetBookkeepingRules, {
    variables: {
      companyId: managingCompanyInfo?.managingCompanyId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !managingCompanyInfo,
  });

  const bookkeepingRules = _.get(
    bookkeepingRulesQuery,
    'data.getBookkeepingRules.items',
    null
  );

  const { isSettingUpBookkeepingAlerts } = useCompanyActivityStatus({
    companyId: managingCompanyInfo?.companyId,
    trackBackfillReports: false,
    onSetupBookkeepingAlertsSuccess: bookkeepingRulesQuery.refetch,
  });

  const userActivityQuery = useQuery(GetUserActivity, {
    skip: !activityIdToScan || !managingCompanyInfo?.managingCompanyId,
    fetchPolicy: 'cache-and-network',
    variables: {
      companyId: managingCompanyInfo?.managingCompanyId,
      activityId: activityIdToScan,
    },
  });

  useEffect(() => {
    if (!activityIdToScan) {
      return;
    }

    setLoaderMessage('Scanning...');
    userActivityQuery.startPolling(2500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityIdToScan]);

  const userActivity = _.get(userActivityQuery, 'data.getUserActivity', null);

  const { refetch: getCustomScanReport } = useQuery(GetCustomScanReport, {
    skip: true,
    fetchPolicy: 'network-only',
    variables: {
      companyId: managingCompanyInfo?.managingCompanyId,
    },
  });

  const dateToString = date => moment(date).format('YYYY-MM-DD');

  const stringToDate = dateStr => {
    const date = new Date(dateStr);
    date.setFullYear(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    );
    return date;
  };

  useEffect(() => {
    if (!userActivity || !managingCompanyInfo) {
      return;
    }
    const { status } = userActivity;

    if (status === USER_ACTIVITY_STATUS.SUCCESS) {
      userActivityQuery.stopPolling();

      setActivityIdToGetReport(userActivity.id);

      // store the activityIdToGetReport in the local session
      sessionStorageSetItem({
        key: SESSION_STORAGE_KEY.ACTIVITY_ID_TO_GET_REPORT,
        companyId: managingCompanyInfo.companyId,
        value: userActivity.id,
      });

      // remove the activityIdToScan from the local session
      sessionStorageRemoveItem({
        key: SESSION_STORAGE_KEY.ACTIVITY_ID_TO_SCAN,
        companyId: managingCompanyInfo.companyId,
      });
      sessionStorageRemoveItem({
        key: SESSION_STORAGE_KEY.REPORT_META_DATA,
        companyId: managingCompanyInfo.companyId,
      });
    } else if (status === USER_ACTIVITY_STATUS.ERROR) {
      userActivityQuery.stopPolling();
      setLoaderMessage(null);

      // remove the activityIdToScan from the local session
      sessionStorageRemoveItem({
        key: SESSION_STORAGE_KEY.ACTIVITY_ID_TO_SCAN,
        companyId: managingCompanyInfo.companyId,
      });
      sessionStorageRemoveItem({
        key: SESSION_STORAGE_KEY.REPORT_META_DATA,
        companyId: managingCompanyInfo.companyId,
      });

      setErrorMessage(
        'An error occurred while scanning. Please try again later!'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActivity, managingCompanyInfo]);

  const areOnlyCompanyRulesSelected = ({
    selectedRules,
    companyRules,
    type,
  }) => {
    const createRuleKey = rule => {
      return `${rule.alertIdentifier}-${rule.alertIdentifierSub || 'null'}`;
    };

    const notSupportedAlertIdentifiers =
      type === CUSTOM_SCAN_TYPE.CREATION_DATE
        ? ALERT_IDENTIFIERS_NOT_SUPPORTED_BY_CREATION_DATE_BASED_CUSTOM_SCAN
        : ALERT_IDENTIFIERS_NOT_SUPPORTED_BY_TRANSACTION_DATE_BASED_CUSTOM_SCAN;

    const notSupportedAlertIdentifiersMap = {};
    _.forEach(notSupportedAlertIdentifiers, alertIdentifier => {
      notSupportedAlertIdentifiersMap[alertIdentifier] = true;
    });

    const enabledCompanyRulesMap = {};
    _.forEach(companyRules, rule => {
      if (
        rule.enabled &&
        !notSupportedAlertIdentifiersMap[rule.alertIdentifier] // remove unsupported rules
      ) {
        const key = createRuleKey(rule);
        enabledCompanyRulesMap[key] = true;
      }
    });

    const selectedRulesMap = {};
    _.forEach(selectedRules, rule => {
      const key = createRuleKey(rule);
      selectedRulesMap[key] = true;
    });

    if (
      _.keys(enabledCompanyRulesMap).length !== _.keys(selectedRulesMap).length
    ) {
      return false;
    }

    return _.keys(selectedRulesMap).every(key => {
      return !!enabledCompanyRulesMap[key];
    });
  };

  const determineNumberOfRulesText = ({
    selectedRules,
    companyRules,
    type,
  }) => {
    const onlyCompanyRulesSelected = areOnlyCompanyRulesSelected({
      selectedRules,
      companyRules,
      type,
    });

    if (onlyCompanyRulesSelected) {
      return 'Company Rules selected';
    }

    let count = 0;
    _.forEach(selectedRules, ({ alertIdentifierSub }) => {
      if (alertIdentifierSub !== IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER) {
        count += 1;
      }
    });

    return `${count} ${count === 1 ? 'Rule selected' : 'Rules selected'}`;
  };

  useEffect(() => {
    if (
      !activityIdToGetReport ||
      !bookkeepingRules ||
      !managingCompanyInfo?.managingCompanyId
    ) {
      return;
    }

    const getReportData = async () => {
      setLoaderMessage('Loading report...');

      try {
        const response = await getCustomScanReport({
          companyId: managingCompanyInfo?.managingCompanyId,
          activityId: activityIdToGetReport,
        });

        const report = _.get(response, 'data.getCustomScanReport', {});

        if (report.dataJson) {
          setReportData(JSON.parse(report.dataJson));

          if (report.startDate && report.endDate && report.bookkeepingRules) {
            const numberOfRulesText = determineNumberOfRulesText({
              selectedRules: report.bookkeepingRules,
              companyRules: bookkeepingRules,
              type: report.type,
            });

            const metaData = {
              companyName: managingCompanyInfo?.companyName,
              startDate: stringToDate(report.startDate),
              endDate: stringToDate(report.endDate),
              selectedRules: report.bookkeepingRules,
              dateCreated: new Date(report.dateCreated),
              type: report.type,
              daysOfWeek: report.daysOfWeek,
              numberOfRulesText,
            };

            setReportMetaData(metaData);
          }
        } else {
          // remove the activityIdToGetReport from the local session
          sessionStorageRemoveItem({
            key: SESSION_STORAGE_KEY.ACTIVITY_ID_TO_GET_REPORT,
            companyId: managingCompanyInfo.companyId,
          });
        }

        setLoaderMessage(null);
      } catch (err) {
        setLoaderMessage(null);
        // eslint-disable-next-line no-console
        console.error('error:', err, 'error.message', err?.message);

        // remove the activityIdToGetReport from the local session
        sessionStorageRemoveItem({
          key: SESSION_STORAGE_KEY.ACTIVITY_ID_TO_GET_REPORT,
          companyId: managingCompanyInfo.companyId,
        });

        if (
          _.includes(err?.message, 'size exceeded maximum allowed payload size')
        ) {
          setErrorMessage(
            'The report is too large to load. Please reduce the reporting period or the number of rules and try again.'
          );
        } else {
          setErrorMessage(
            'An error occurred while loading the report. Please try again later!'
          );
        }
      }
    };

    getReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityIdToGetReport, managingCompanyInfo, bookkeepingRules]);

  const handleOnScanClick = async ({
    startDate,
    endDate,
    selectedRules,
    type: scanType,
    daysOfWeek,
  }) => {
    const startDateString = dateToString(startDate);
    const endDateString = dateToString(endDate);

    if (scanType === CUSTOM_SCAN_TYPE.CREATION_DATE) {
      if (_.isEmpty(daysOfWeek)) {
        setSettingsError({
          daysOfWeek: true,
          form: 'Please select at least one day of the week',
        });
        return;
      }

      const todayString = dateToString(new Date());

      if (startDateString > todayString) {
        setSettingsError({
          startDate: true,
          form: 'The start date cannot be in the future',
        });
        return;
      }

      if (endDateString > todayString) {
        setSettingsError({
          endDate: true,
          form: 'The end date cannot be in the future',
        });
        return;
      }
    }

    if (startDateString > endDateString) {
      setSettingsError({
        startDate: true,
        endDate: true,
        form: 'The start date cannot be after the end date',
      });
      return;
    }

    // // Ensure at least one rule is selected, excluding 'Ignore Undeposited Funds'
    const haveAtLeastOneRule = _.some(selectedRules, rule => {
      return (
        rule.alertIdentifierSub !== IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER
      );
    });

    if (!haveAtLeastOneRule) {
      setSettingsError({
        selectedRules: true,
        form: 'Please select at least one rule',
      });
      return;
    }

    setShowScanSettingsDialog(false);

    setLoaderMessage('Scanning...');
    setReportData(null);

    const newActivityId = uuid();

    sessionStorageSetItem({
      key: SESSION_STORAGE_KEY.ACTIVITY_ID_TO_SCAN,
      companyId: managingCompanyInfo?.companyId,
      value: newActivityId,
    });

    const numberOfRulesText = determineNumberOfRulesText({
      selectedRules,
      companyRules: bookkeepingRules,
      type: scanType,
    });

    const metaData = {
      companyName: managingCompanyInfo?.companyName,
      startDate,
      endDate,
      selectedRules,
      type: scanType,
      daysOfWeek,
      numberOfRulesText,
    };

    sessionStorageSetItem({
      key: SESSION_STORAGE_KEY.REPORT_META_DATA,
      companyId: managingCompanyInfo?.companyId,
      value: JSON.stringify(metaData),
    });

    // this will be used to populate the settings when the user clicks on 'New Scan'
    setReportMetaData(metaData);

    sessionStorageRemoveItem({
      key: SESSION_STORAGE_KEY.ACTIVITY_ID_TO_GET_REPORT,
      companyId: managingCompanyInfo?.companyId,
    });

    try {
      // send the request to start the retroactive report generation
      const response = await startCustomScan({
        variables: {
          activityId: newActivityId,
          companyId: managingCompanyInfo?.companyId,
          startDate: startDateString,
          endDate: endDateString,
          type: scanType,
          daysOfWeek,
          bookkeepingRules: _.map(
            selectedRules,
            ({ __typename, type, ...rest }) => ({
              ...rest,
            })
          ),
        },
      });

      const responseStatus = _.get(response, 'data.startCustomScan.status');

      if (responseStatus !== 'success') {
        setErrorMessage(
          'An unexpected error occurred. Please try again later!'
        );
        setLoaderMessage(null);
        return;
      }

      setActivityIdToScan(newActivityId);
    } catch (err) {
      setErrorMessage('An error occurred while starting scanning');
    }
  };

  const hideControlBar = !reportData && !loaderMessage;

  const loadingRules =
    isSettingUpBookkeepingAlerts || bookkeepingRulesQuery.loading;

  return (
    <Dashboard
      layout={DASHBOARD_LAYOUT.SIMPLE}
      controlBar={
        <CustomScanDashboardControlBar
          title="Custom Scan"
          loading={loadingRules}
          disabled={!!loaderMessage}
          hideAccessExplainer={isCopilot}
          onNewScanClick={() => {
            setShowScanSettingsDialog(true);
          }}
        />
      }
      hideControlBar={hideControlBar}
      dashboardFullStyle={
        hideControlBar
          ? { height: 'calc(100vh - 64px)', backgroundColor: '#e8e8e8' }
          : {}
      }
    >
      <Grid
        container
        justifyContent="center"
        style={{ overflowY: 'auto', paddingTop: isCopilot ? 0 : 8 }}
        ref={containerRef}
      >
        {!!hideControlBar && (
          <CustomScanSettings
            defaultBookkeepingRules={bookkeepingRules}
            loading={loadingRules}
            disabled={!!loaderMessage}
            containerStyle={{ alignSelf: 'center' }}
            initialSettings={reportMetaData}
            error={settingsError}
            setError={setSettingsError}
            onScanClick={handleOnScanClick}
          />
        )}

        {!!reportData && (
          <BookkeepingAlertsReport
            reportData={reportData}
            isCustomScan
            showPrintButton
            reportMetaData={reportMetaData}
          />
        )}

        {!!showScanSettingsDialog && (
          <Dialog open>
            <CustomScanSettings
              defaultBookkeepingRules={bookkeepingRules}
              loading={loadingRules}
              disabled={!!loaderMessage}
              containerStyle={{ alignSelf: 'center' }}
              initialSettings={reportMetaData}
              error={settingsError}
              setError={setSettingsError}
              showCancelButton
              onCancelClick={() => {
                setShowScanSettingsDialog(false);
                setSettingsError(null);
              }}
              onScanClick={handleOnScanClick}
            />
          </Dialog>
        )}

        {!!errorMessage && (
          <OkCancelDialog
            open
            okButtonText="Ok"
            hideCancel
            title="Oops!"
            dividers={false}
            loaderStyle={{ opacity: 1 }}
            onConfirm={() => {
              setErrorMessage(null);
            }}
          >
            <Typography>{errorMessage}</Typography>
          </OkCancelDialog>
        )}

        <BackToTopButton scrollElementRef={containerRef} />
        {(!!loadingRules || !!loaderMessage) && (
          <LoadingCover withCam customStyles={{ opacity: 1 }}>
            <Typography variant="h2">
              {loaderMessage || 'Loading your company rules...'}
            </Typography>
          </LoadingCover>
        )}
      </Grid>
    </Dashboard>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default compose(connect(mapStateToProps))(CustomScanDashboard);
