import gql from 'graphql-tag';
import * as Fragments from '../fragments';

export default gql`
  ${Fragments.BookkeepingRule}
  query GetCustomScanReport($activityId: ID!, $companyId: ID!) {
    getCustomScanReport(
      input: { activityId: $activityId, companyId: $companyId }
    ) {
      activityId
      startDate
      endDate
      dataJson
      dateCreated
      type
      daysOfWeek
      bookkeepingRules {
        ...BookkeepingRuleFields
      }
    }
  }
`;
