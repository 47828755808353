import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: '#eee',
    color: '#666',
  },
});

const SimpleDialog = props => {
  const classes = useStyles();
  const { onClose, open, options } = props;

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = value => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Set item status:</DialogTitle>
      <List>
        {options.map(option => (
          <ListItem
            button
            onClick={() => handleListItemClick(option.value)}
            key={option.value}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>{option.icon}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={option.label} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default SimpleDialog;
