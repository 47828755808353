// Height and width driving elements
const dashboardTotalMargin = `8px`;
const dashboardTotalPadding = `16px`;
const modalTotalOuterMargin = `64px`;
const modalTotalPadding = `64px`;

// Height driving elements
const topBarHeight = '80px';
const tabSectionAndMargin = '52px';
const dashboardTopPadding = '16px';
const dashboardHeadingHeightWithPadding = '81px';
const modalBorderThicknesses = `2px`;
const heightToPreventUnnecessaryScroll = `2px`;

// Width driving elements
const sideBarWidth = `239px`;
const modalTotalInnerMargin = `8px`;

export default (theme, mode = 'default', layout = 'default') => {
  let targetHeight;
  let targetWidth;
  let minHeight;
  if (mode === 'modal') {
    targetHeight = `(100vh - ${dashboardHeadingHeightWithPadding} - ${modalTotalOuterMargin} - ${modalTotalPadding} - ${heightToPreventUnnecessaryScroll})`;
    targetWidth = `(100vw - ${modalTotalOuterMargin} - ${modalTotalInnerMargin} - ${modalTotalPadding} - ${modalBorderThicknesses})`;
    minHeight = 360;
  } else {
    targetHeight = `(100vh - ${tabSectionAndMargin} - ${topBarHeight} - ${dashboardTopPadding} - ${dashboardHeadingHeightWithPadding} - ${dashboardTotalMargin})`;
    targetWidth = `(100vw - ${sideBarWidth} - ${dashboardTotalMargin} - ${dashboardTotalPadding})`;
    minHeight = 400;

    if (layout === 'simple') {
      targetHeight = `(100vh - ${topBarHeight} - ${dashboardHeadingHeightWithPadding})`;
      targetWidth = `(100vw - ${sideBarWidth})`;
    }
  }

  return {
    simpleDashboard: {
      background: '#fff',
      minHeight: '100vh',
    },
    dashboard: {
      background: theme.palette.white,
      margin: 4,
      borderRadius: 4,
      border: 1,
      borderColor: 'transparent',
      padding: 16,
    },
    dashboardGraphArea: {
      minHeight,
      height: `calc(${targetHeight})`,
      width: `calc(${targetWidth})`,
      display: 'flex',
      flexWrap: 'wrap',
      overflowX: 'hidden',
      overflowY: 'hidden',
      position: 'relative',
      alignSelf: 'center',
    },
    dashboardFull: {
      minHeight,
      height: `calc(${targetHeight})`,
      width: `calc(${targetWidth})`,
      display: 'flex',
      position: 'relative',
    },
    dashboardHalf: {
      minHeight,
      height: `calc(${targetHeight})`,
      width: `calc(${targetWidth}/2)`,
      display: 'flex',
      position: 'relative',
    },
    dashboardThird: {
      minHeight,
      height: `calc(${targetHeight})`,
      width: `calc(${targetWidth}/3))`,
      display: 'flex',
      position: 'relative',
    },
    dashboardQuadrant: {
      minHeight: minHeight / 2,
      height: `calc(${targetHeight}/2)`,
      width: `calc(${targetWidth}/2)`,
      display: 'flex',
      position: 'relative',
    },
    controlBar: {
      height: topBarHeight,
      overflowX: 'auto',
      overflowY: 'hidden',
    },
    controlBarInner: {
      padding: '12px 12px 12px 16px',
      flexWrap: 'nowrap',
      borderBottom: `1px solid ${theme.palette.brandColorLightGrey}`,
    },
    roleIconPrimary: {
      marginBottom: -6,
      color: theme.palette.brandColorPrimary,
    },
    roleIconSecondary: {
      marginLeft: 4,
      marginBottom: -6,
      color: theme.palette.brandColorDarkGrey,
    },
    tableWrapper: {
      width: '100%',
      overflowY: 'auto',
      marginTop: 16,
    },
    progressReportTitle: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: theme.spacing(1),
    },
    progressReportValue: {
      flex: 1,
      marginLeft: theme.spacing(1),
    },
    reportWrapper: {
      display: 'flex',
      width: '100%',
      height: '100%',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    report: {
      height: '100%',
      width: '100%',
      padding: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    reportSection: {
      paddingTop: theme.spacing(2),
    },
  };
};
