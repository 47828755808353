import React, { useMemo, useState } from 'react';
import { compose } from 'redux';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import { LIST_TYPE } from './list-of-vendors-header';
import ListOfVendorsView from './list-of-vendors.view';

import { GetVendorsAction } from '../../../graphql/graphql';

const ListOfVendors = ({
  vendors,
  vendorsLoading,
  vendorsRefetch,
  onVendorClick,
  idOfVendorBeingViewed,
}) => {
  const { state: locationState } = useLocation();

  const [selectedTab, setSelectedTab] = useState(
    locationState?.tab || LIST_TYPE.ACTIVE_VENDORS
  );

  const { activeVendors, archivedVendors } = useMemo(() => {
    const activeList = [];
    const archivedList = [];
    _.forEach(vendors, vendor => {
      if (vendor.isArchived) {
        archivedList.push(vendor);
      } else {
        activeList.push(vendor);
      }
    });
    return { activeVendors: activeList, archivedVendors: archivedList };
  }, [vendors]);

  const vendorsToShow = useMemo(() => {
    if (selectedTab === LIST_TYPE.ACTIVE_VENDORS) {
      return activeVendors;
    }

    if (selectedTab === LIST_TYPE.ARCHIVED_VENDORS) {
      return archivedVendors;
    }

    return vendors;
  }, [selectedTab, vendors, activeVendors, archivedVendors]);

  return (
    <ListOfVendorsView
      allVendors={vendors}
      vendors={vendorsToShow}
      onVendorClick={onVendorClick}
      refetchVendorList={vendorsRefetch}
      idOfVendorBeingViewed={idOfVendorBeingViewed}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      loading={vendorsLoading}
    />
  );
};

export default compose(GetVendorsAction)(ListOfVendors);
