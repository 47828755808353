import React, { useMemo } from 'react';
import numeral from 'numeral';
import _ from 'lodash';

import {
  Grid,
  Typography,
  InputAdornment,
  Tooltip,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';

import LaborBudgetTextField from './labor-budget-text-field';
import {
  CREW_DEPARTMENT_OPTIONS,
  CREW_PAY_TYPES,
  CREW_PAY_TYPES_OPTIONS,
} from '../../../config/appDefaults';
import { getFullUserString } from '../../../helpers';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: 'inherit',
    color: 'inherit',
    lineHeight: 'inherit',
  },
}));

const LaborBudgetItem = ({
  companyCrewMap,
  userBudget,
  hoursWorked,
  isDeactiveUser,
  onHoursChange,
  onHoursBlur,
}) => {
  const {
    userId,
    department,
    payType,
    payRate,
    laborBurdenPercentage,
    vacaAccrualRate,
    hours,
    cost,
    hourlyRate,
  } = userBudget;

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const classes = useStyles();

  const showHoursWorked = !_.isUndefined(hoursWorked) && !mdDown;

  const hourlyRateTooltip = useMemo(() => {
    let tooltip = null;
    if (payType === CREW_PAY_TYPES.VARIABLE_HOURLY) {
      const laborBurden =
        (Number(payRate) * Number(laborBurdenPercentage)) / 100;
      const vacaAccrual = (Number(payRate) * Number(vacaAccrualRate)) / 100;
      tooltip = (
        <>
          <Typography className={classes.tooltip}>
            Pay Rate: {numeral(payRate).format('$0.00')} / hour
          </Typography>
          {!!laborBurdenPercentage && (
            <Typography className={classes.tooltip}>
              {`Labor Burden Percentage: ${laborBurdenPercentage}% (${numeral(
                laborBurden
              ).format('$0.00')} / hour)`}
            </Typography>
          )}
          {!!vacaAccrualRate && (
            <Typography className={classes.tooltip}>
              {`Vaccation Accrual Percentage: ${vacaAccrualRate}% (${numeral(
                vacaAccrual
              ).format('$0.00')} / hour)`}
            </Typography>
          )}
        </>
      );
    }
    return tooltip;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laborBurdenPercentage, payRate, payType, vacaAccrualRate]);

  const departmentLabel = useMemo(() => {
    const option = _.find(CREW_DEPARTMENT_OPTIONS, { value: department });
    return option ? option.label : ' ';
  }, [department]);

  const payTypeLabel = useMemo(() => {
    const option = _.find(CREW_PAY_TYPES_OPTIONS, { value: payType });
    return option ? option.label : ' ';
  }, [payType]);

  const userFullString = useMemo(() => {
    return getFullUserString(companyCrewMap[userId]);
  }, [companyCrewMap, userId]);

  const handleHoursChange = event => {
    let { value } = event.target;
    value = value.replace(/[^0-9.]+/g, '');
    onHoursChange({
      userId,
      hours: value,
    });
  };

  const handleHoursBlur = event => {
    onHoursBlur({
      userId,
      hours: event.target.value,
    });
  };

  return (
    <Grid
      container
      key={userId}
      item
      xs={12}
      justifyContent="space-between"
      alignItems="center"
      spacing={mdDown ? 1 : 0}
    >
      <Grid item lg={showHoursWorked ? 2 : 3} xs={2}>
        <Tooltip
          title={isDeactiveUser ? 'Deactive user (ever on project)' : ''}
        >
          <span>
            <LaborBudgetTextField
              label="Username"
              value={userFullString}
              multiline
              disabled
              InputProps={
                isDeactiveUser
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <InfoOutlinedIcon style={{ fontSize: 16 }} />
                        </InputAdornment>
                      ),
                    }
                  : {}
              }
            />
          </span>
        </Tooltip>
      </Grid>
      <Grid item xs={2}>
        <LaborBudgetTextField
          label="Department"
          value={departmentLabel}
          disabled
        />
      </Grid>
      <Grid item xs={2}>
        <Tooltip
          title={
            payType && payType !== CREW_PAY_TYPES.VARIABLE_HOURLY
              ? 'Only users with a Pay Type of Variable/Hourly can have their rate applied to projects'
              : ''
          }
        >
          <span>
            <LaborBudgetTextField
              label="Pay Type"
              value={payTypeLabel}
              disabled
              InputProps={
                payType && payType !== CREW_PAY_TYPES.VARIABLE_HOURLY
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <InfoOutlinedIcon style={{ fontSize: 16 }} />
                        </InputAdornment>
                      ),
                    }
                  : {}
              }
            />
          </span>
        </Tooltip>
      </Grid>
      <Grid item xs={2}>
        {hourlyRateTooltip && (
          <Tooltip title={hourlyRateTooltip}>
            <span>
              <LaborBudgetTextField
                label="Hourly Rate + Labor Burden"
                value={numeral(hourlyRate).format('0.00')}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <InfoOutlinedIcon style={{ fontSize: 16 }} />
                    </InputAdornment>
                  ),
                }}
              />
            </span>
          </Tooltip>
        )}
      </Grid>
      {showHoursWorked && (
        <Grid item xs={1}>
          <LaborBudgetTextField
            label="Hours Worked"
            value={hoursWorked}
            adornmentChar="#"
            showZeroAsEmpty={false}
            disabled
          />
        </Grid>
      )}
      <Grid item lg={1} xs={2}>
        <LaborBudgetTextField
          label="Budget Hours"
          name="hours"
          type="number"
          value={hours}
          adornmentChar="#"
          onChange={handleHoursChange}
          onBlur={handleHoursBlur}
        />
      </Grid>
      <Grid item lg={1} xs={2}>
        {payType === CREW_PAY_TYPES.VARIABLE_HOURLY && (
          <LaborBudgetTextField
            label="Budget"
            value={numeral(cost).format('0.00')}
            adornmentChar="$"
            disabled
          />
        )}
      </Grid>
    </Grid>
  );
};

export default LaborBudgetItem;
