import React, { useState } from 'react';
import numeral from 'numeral';
import moment from 'moment';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import RowCircleSelect from '../../../components/row-circle-select/row-circle-select';
import LoadingCover from '../../../components/LoadingCover/loadingCover';

const HandleExistingRfiTxns = ({ txns, handleDialogClose, handleSave }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [reopening, setReopening] = useState(false);

  const onSave = async () => {
    const passback = [];
    selectedItems.forEach(id => {
      const temp = txns.find(txn => txn.txnId === id);
      passback.push(temp);
    });
    setReopening(true);
    await handleSave({ reopenThese: passback });
    setReopening(false);
    handleDialogClose();
  };

  const renderTxns = () => {
    const onSelect = txn => {
      if (selectedItems.includes(txn.txnId)) {
        setSelectedItems(selectedItems.filter(id => id !== txn.txnId));
      } else {
        setSelectedItems([...selectedItems, txn.txnId]);
      }
    };

    return txns.map(txn => (
      <Grid
        key={txn.txnId}
        container
        justifyContent="space-between"
        alignItems="center"
        style={{
          borderBottom: '1px solid #ccc',
          paddingBottom: 10,
          marginBottom: 10,
        }}
      >
        <Grid item>
          <Typography variant="body1">
            Account Name: {txn.accountName}
          </Typography>
          <Typography variant="body1">
            Amount: {numeral(txn.amount).format('$0,0.00')}
          </Typography>
          <Typography variant="body1">
            Date: {moment(txn.txnDate).format('MMM D, YYYY')}
          </Typography>
          <Typography variant="body1">Type: {txn.txnType}</Typography>
        </Grid>
        <Grid item>
          <RowCircleSelect
            target={txn.txnId}
            isActive={selectedItems.includes(txn.txnId)}
            onSelect={() => onSelect(txn)}
          />
        </Grid>
      </Grid>
    ));
  };

  return (
    <Dialog
      open
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
    >
      {reopening && <LoadingCover />}
      <DialogTitle disableTypography style={{ padding: 8 }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h4" style={{ paddingLeft: 8, paddingTop: 12 }}>
              Existing RFIs
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleDialogClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                borderBottom: '1px solid #ccc',
                paddingBottom: 10,
                marginBottom: 10,
              }}
            >
              Some of the transactions that are about to be imported already
              have RFIs associated with them. Select which RFIs below you would
              like to re-open.
            </Typography>
          </Grid>
          {renderTxns()}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleDialogClose} color="primary">
          No, thanks
        </Button>
        <Button
          onClick={onSave}
          color="primary"
          variant="contained"
          disabled={!selectedItems?.length}
        >
          Reopen as Draft
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HandleExistingRfiTxns;
