import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.License}
  query ListFirmLicenses {
    listFirmLicenses {
      items {
        ...LicenseFields
      }
    }
  }
`;
