import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useMutation } from 'react-apollo-hooks';
import {
  Checkbox,
  Chip,
  Grid,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { VerifiedUser as VerifiedUserIcon } from '@material-ui/icons';
import { Auth } from 'aws-amplify';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import RecordSmsConsentMutation from '../../graphql/mutations/mutation_record-sms-consent';
import palette from '../../theme/palette';
import BasicDialog from '../basic-dialog/basic-dialog';
import LoadingCover from '../LoadingCover/loadingCover';
import PhoneNumberInput from '../phone-number-input';
import SettingsSection from '../settings-section';
import VerificationCode from '../verification-code';

const ALLOWED_COUNTRIES = ['US', 'CA'];

const MfaSettings = ({ hasAddedAClient }) => {
  const [loading, setLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isDisabling, setIsDisabling] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [mfaPhoneNumber, setMfaPhoneNumber] = useState('');
  const [choosePhoneNumber, setChoosePhoneNumber] = useState('');
  const [choosePhoneNumberError, setChoosePhoneNumberError] = useState('');
  const [mfaStatus, setMfaStatus] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState('');
  const [explicitlyAgreedToSms, setExplicitlyAgreedToSms] = useState(false);

  const [showBasicDialog, setShowBasicDialog] = useState({
    open: false,
    title: '',
    message: '',
  });
  const [showCodeDialog, setShowCodeDialog] = useState(false);
  const [showDisableMfaDialog, setShowDisableMfaDialog] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);

  const resetCognitoUserInfo = async () => {
    const currentCognitoUser = await Auth.currentAuthenticatedUser();
    setCognitoUser(currentCognitoUser);
  };

  useEffect(() => {
    resetCognitoUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  useEffect(() => {
    if (cognitoUser) {
      const phoneNumber = _.get(cognitoUser, 'attributes.phone_number');
      const phoneNumberVerified = _.get(
        cognitoUser,
        'attributes.phone_number_verified'
      );
      const preferredMFA = _.get(cognitoUser, 'preferredMFA');

      const phoneIsVerified = phoneNumber && phoneNumberVerified;
      const mfaIsSet = preferredMFA === 'SMS' || preferredMFA === 'SMS_MFA';

      if (!editMode) {
        // Don't overwrite the phone number if in edit mode
        setMfaPhoneNumber(phoneNumber || '');

        if (phoneIsVerified) {
          setChoosePhoneNumber(phoneNumber);
          setMfaStatus(!!mfaIsSet);
        } else {
          setChoosePhoneNumber('');
          setMfaStatus(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoUser]);

  const [recordSmsConsent] = useMutation(RecordSmsConsentMutation);

  const handleVerificationCodeChange = passedCode => {
    setVerificationCode(passedCode);
  };

  const handleChoosePhoneNumberSubmit = async () => {
    setLoading(true);

    try {
      // Set the cognito user's phone number
      await Auth.updateUserAttributes(cognitoUser, {
        phone_number: choosePhoneNumber,
      });

      // Reset code if not empty
      // Assume successful following steps for user experience
      setVerificationCode('');
      setShowCodeDialog(true);

      // Send an opt-in message
      await recordSmsConsent({
        variables: {
          phoneNumber: choosePhoneNumber,
          messageType: '2FA',
        },
      });

      // Issue the verification code
      await Auth.verifyUserAttribute(cognitoUser, 'phone_number');
    } catch (e) {
      // Hide code dialog if open
      setShowCodeDialog(false);

      // eslint-disable-next-line no-console
      console.log('e: ', e);
      // show user a message that the username is not available
      setShowBasicDialog({
        open: true,
        title: 'Oops...',
        message: 'Something went wrong. Please try again later!',
      });
      setLoading(false);
    }
  };

  const handleEditMode = () => {
    setChoosePhoneNumber(mfaPhoneNumber);
    setEditMode(true);
  };

  const handleCancel = () => {
    setChoosePhoneNumberError('');
    setEditMode(false);
  };

  const handleSubmitVerificationCode = async () => {
    setIsVerifying(true);
    setVerificationCodeError('');

    try {
      await Auth.verifyCurrentUserAttributeSubmit(
        'phone_number',
        verificationCode
      );

      // Set the MFA preferences
      await Auth.setPreferredMFA(cognitoUser, 'SMS');

      setShowCodeDialog(false);
      setEditMode(false);
      setLoading(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('err: ', err);

      if (err.code === 'CodeMismatchException') {
        setVerificationCodeError('Invalid verification code');
        setIsVerifying(false);
        return;
      }

      setLoading(false);
      setShowCodeDialog(false);
      setShowBasicDialog({
        open: true,
        title: 'Oops...',
        message: 'Something went wrong. Please try again!',
      });
    }

    setIsVerifying(false);
  };

  const cancelVerificationCodeSubmit = () => {
    setVerificationCode('');
    setVerificationCodeError('');
    setShowCodeDialog(false);
    setLoading(false);
  };

  let handleToggleMfa = null;
  if (mfaPhoneNumber) {
    if (mfaStatus) {
      handleToggleMfa = async () => {
        setShowDisableMfaDialog(true);
      };
    }
  }

  const handleDisableMfaConfirmed = async () => {
    setIsDisabling(true);

    try {
      const currentCognitoUser = await Auth.currentAuthenticatedUser();
      await Auth.setPreferredMFA(currentCognitoUser, 'NOMFA');
      await resetCognitoUserInfo();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('err: ', err);

      setShowBasicDialog({
        open: true,
        title: 'Oops...',
        message: 'Something went wrong. Please try again!',
      });
    }

    setShowDisableMfaDialog(false);
    setIsDisabling(false);
  };

  const handleChoosePhoneNumberChange = passedPhoneNumber => {
    setChoosePhoneNumber(passedPhoneNumber);
  };

  let isPhoneNumberSubmitable = false;
  if (choosePhoneNumber) {
    const editablePhoneNumber = parsePhoneNumberFromString(
      choosePhoneNumber || '',
      'US'
    );

    if (
      editablePhoneNumber.isValid() &&
      _.includes(ALLOWED_COUNTRIES, editablePhoneNumber.country)
    ) {
      isPhoneNumberSubmitable = true;
    }
  }

  let displayPhoneNumber = '';
  if (mfaPhoneNumber) {
    const chosenPhoneNumber = parsePhoneNumberFromString(
      mfaPhoneNumber || '',
      'US'
    );
    displayPhoneNumber = chosenPhoneNumber.formatNational();
  }

  const handleExplicitlyAgreedToSmsClick = event => {
    setExplicitlyAgreedToSms(event.target.checked);
  };

  return (
    <SettingsSection
      icon={<VerifiedUserIcon />}
      title="Two-Factor Authentication"
      description="Manage your two-factor authentication (2FA) settings."
      onCancel={editMode ? handleCancel : handleToggleMfa}
      onCancelText={editMode ? 'Cancel' : 'Disable'}
      onDone={editMode ? handleChoosePhoneNumberSubmit : handleEditMode}
      onDoneText={editMode ? 'Activate 2FA' : 'Update'}
      onDoneDisabled={
        loading ||
        (editMode && (!isPhoneNumberSubmitable || !explicitlyAgreedToSms))
      }
      hashKey="2fa"
    >
      {/* Hidden attribute for userflow */}
      {hasAddedAClient && <div id="userflow-has-firm-clients" />}
      <Grid container direction="column" spacing={4}>
        <Grid item style={{ width: '100%' }}>
          <Typography variant="body2">
            Two-Factor Authentication (2FA) adds an extra layer of security to
            your account by requiring a second form of verification. By enabling
            2FA, you agree to receive SMS messages to the provided phone number
            for verification purposes. You will receive one message per login.
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          direction="column"
          spacing={1}
          style={{ maxWidth: 500 }}
        >
          <Grid
            container
            item
            xs={12}
            alignItems="flex-start"
            style={{ minHeight: 50 }}
          >
            <Grid item xs={4}>
              <InputLabel style={{ paddingTop: 4 }}>
                <Typography color="textSecondary">Status</Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={8}>
              <Chip
                label={mfaStatus ? 'Active' : 'Inactive'}
                style={{
                  backgroundColor: mfaStatus
                    ? palette.brandColorGreen
                    : palette.brandColorDarkGrey,
                  color: '#ffffff',
                }}
                id={mfaStatus ? 'mfa-active-chip' : 'mfa-inactive-chip'} // do not remove - for Userflow targeting
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            alignItems="flex-start"
            style={{ minHeight: 50 }}
          >
            <Grid item xs={4}>
              <InputLabel style={{ paddingTop: 4 }}>
                <Typography color="textSecondary">Phone Number</Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={8}>
              {!editMode ? (
                <Typography
                  variant="h5"
                  style={{ paddingTop: 5, paddingBottom: 5 }}
                >
                  {displayPhoneNumber || '-'}
                </Typography>
              ) : (
                <PhoneNumberInput
                  name="phoneNumber"
                  value={choosePhoneNumber}
                  onChange={handleChoosePhoneNumberChange}
                  inputProps={{
                    readOnly: !editMode,
                    disabled: !editMode,
                  }}
                  label={false}
                  helperText="Only US & Canada phone numbers allowed"
                  error={choosePhoneNumberError}
                  setError={message => setChoosePhoneNumberError(message)}
                  allowedCountries={ALLOWED_COUNTRIES}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        {editMode && (
          <Grid container item style={{ width: '100%' }} spacing={2}>
            <Grid item container xs={12} alignItems="center">
              <Grid item style={{ flex: 0, width: 48 }}>
                <Checkbox
                  color="primary"
                  checked={explicitlyAgreedToSms}
                  onClick={handleExplicitlyAgreedToSmsClick}
                />
              </Grid>
              <Grid item style={{ flex: 1 }}>
                <Typography variant="body2">
                  I agree to receive SMS messages from Level Software Inc. for
                  verification purposes. I understand that standard messaging
                  rates may apply, and that I can opt-out at any time with STOP.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 16 }}>
              <Typography variant="body2">
                <a
                  href="https://www.checkthelevel.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a
                  href="https://www.checkthelevel.com/terms-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <BasicDialog
        open={!!showBasicDialog.open}
        title={showBasicDialog.title}
        customChildren
        handleClose={() =>
          setShowBasicDialog({ open: false, message: '', title: '' })
        }
      >
        <Typography>{showBasicDialog.message}</Typography>
      </BasicDialog>
      <BasicDialog
        open={!!showCodeDialog}
        title="Verify Your Phone Number"
        buttonText="Cancel"
        handleClose={cancelVerificationCodeSubmit}
        handleSecondaryAction={handleSubmitVerificationCode}
        secondaryButtonDisabled={verificationCode?.length !== 6}
        secondaryButtonText="Verify"
        customChildren
      >
        <Typography variant="body1">
          A verification code has been sent to your phone number. Please enter
          it below.
        </Typography>
        <Grid
          container
          style={{ width: '100%', padding: 16 }}
          justifyContent="center"
        >
          <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
            <VerificationCode
              value={verificationCode}
              onChange={handleVerificationCodeChange}
            />
            {verificationCodeError && (
              <Typography
                variant="caption"
                color="error"
                style={{ marginTop: 4 }}
              >
                {verificationCodeError}
              </Typography>
            )}
          </Grid>
        </Grid>
        {isVerifying && <LoadingCover />}
      </BasicDialog>
      <BasicDialog
        open={!!showDisableMfaDialog}
        title="Disable Two-Factor Authentication"
        buttonText="Cancel"
        handleClose={() => setShowDisableMfaDialog(false)}
        handleSecondaryAction={handleDisableMfaConfirmed}
        secondaryButtonText="Disable"
        customChildren
      >
        <Typography variant="body1">
          Are you sure you want to disable two-factor authentication? You will
          no longer receive a verification code when signing in.
        </Typography>
        {isDisabling && <LoadingCover />}
      </BasicDialog>
    </SettingsSection>
  );
};

const mapStateToProps = state => ({
  hasAddedAClient: state.appState.hasAddedAClient || null,
});

export default compose(connect(mapStateToProps), withRouter)(MfaSettings);
