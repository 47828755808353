import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';
import { Grid, Collapse, Tooltip, IconButton } from '@material-ui/core';
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  HelpOutline as HelpIcon,
} from '@material-ui/icons';
import palette from '../../theme/palette';

const contentLoader = (
  <div style={{ height: 16, width: 72, float: 'right' }}>
    <ContentLoader
      height={16}
      width={72}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="0" rx="4" ry="4" height="16" width="72" />
    </ContentLoader>
  </div>
);

const DashboardReportTableRow = ({
  name,
  value,
  description,
  isVisible = true,
  expandableBody = null,
  setInfoToShow,
  isLoading,
  defaultExpanded = false,
  color,
  dividerBelowExpandableBody = false,
}) => {
  const [open, setOpen] = useState(defaultExpanded);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Grid
        container
        style={{
          height: dividerBelowExpandableBody ? 'initial' : 32,
          fontSize: 14,
          borderBottom: '1px solid',
          borderColor: palette.brandColorLightGrey,
          alignItems: 'center',
        }}
      >
        <Grid item style={{ paddingLeft: 8, width: 36 }}>
          {expandableBody && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          )}
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            color: color || 'inherit',
          }}
        >
          {name}
          {description ? (
            <Tooltip title="Click for more info">
              <HelpIcon
                fontSize="small"
                color="primary"
                style={{
                  marginLeft: 4,
                  cursor: 'pointer',
                }}
                onClick={() =>
                  setInfoToShow({
                    title: name,
                    description,
                  })
                }
              />
            </Tooltip>
          ) : (
            ''
          )}
        </Grid>
        <Grid item style={{ color: color || 'inherit' }}>
          {!isLoading ? value : contentLoader}
        </Grid>
        {dividerBelowExpandableBody && expandableBody && (
          <Grid item xs={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Grid container direction="column">
                <div style={{ paddingLeft: 8 }}>{expandableBody}</div>
              </Grid>
            </Collapse>
          </Grid>
        )}
      </Grid>
      {!dividerBelowExpandableBody && expandableBody && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div style={{ paddingLeft: 8 }}>{expandableBody}</div>
        </Collapse>
      )}
    </>
  );
};

export default DashboardReportTableRow;
