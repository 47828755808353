import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import numeral from 'numeral';
import _ from 'lodash';

import {
  Avatar,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Hidden,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import {
  AccountBalance as AccountBalanceIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

import globalStyles from '../../../theme/palette';

import {
  GetCompanyCrewAdminAction,
  UpdateUserAdminMutationAction,
} from '../../../graphql/graphql';

import AccessLimitedToInfo from '../../../components/access-limited-to-info/access-limited-to-info';
import LaborBudgetUserPayInfoItem from './labor-budget-user-pay-info-item';
import LoadingCover from '../../../components/LoadingCover/loadingCover';

import { calculateHourlyRate, getFullUserString } from '../../../helpers';
import {
  CREW_DEPARTMENT_OPTIONS,
  CREW_PAY_TYPES,
  CREW_PAY_TYPES_OPTIONS,
} from '../../../config/appDefaults';

const useStyles = makeStyles({
  divider: {
    backgroundColor: '#eee',
    height: 32,
  },
  actionContainer: {
    padding: '8px 24px 8px 24px',
  },
  headerRow: {
    backgroundColor: '#f0f0f0',
    marginBottom: 8,
    '&.MuiGrid-item': {
      padding: '12px 12px 8px 12px',
    },
  },
  headerCell: {
    paddingLeft: 14,
  },
  heading: {
    color: '#999',
    fontSize: 12,
    lineHeight: '14px',
  },
  activeLink: {
    color: globalStyles.brandColorPrimary,
  },
  infoIcon: {
    color: '#999',
    fontSize: 16,
  },
});

const LaborBudgetUserPayInfoDialog = props => {
  const {
    open,
    handleClose,
    handleDone,
    allUserIdsOnProject,
    activeUserMap,
    budgetsByUserIdMap: currentBudgetByUserIdMap,
    companyCrewMap,
    managingCompanyInfo,
    onUpdateUserAdmin,
  } = props;

  const classes = useStyles();

  const [budgetsByUserIdMap, setBudgetsByUserIdMap] = useState(
    currentBudgetByUserIdMap
  );

  const [
    showSetDefaultPayInfoDialog,
    setShowSetDefaultPayInfoDialog,
  ] = useState({
    open: false,
    usersToUpdate: [],
  });

  const [updatingUserPayInfo, setUpdatingUserPayInfo] = useState(false);

  const crewDepartmentLabelMap = useMemo(() => {
    const departmentMap = {};
    _.forEach(CREW_DEPARTMENT_OPTIONS, ({ value, label }) => {
      departmentMap[value] = label;
    });
    return departmentMap;
  }, []);

  const crewPayTypeLabelMap = useMemo(() => {
    const payTypeMap = {};
    _.forEach(CREW_PAY_TYPES_OPTIONS, ({ value, label }) => {
      payTypeMap[value] = label;
    });
    return payTypeMap;
  }, []);

  const onDoneClick = () => {
    const usersToUpdate = [];
    _.forEach(allUserIdsOnProject, userId => {
      const currentPayInfo = budgetsByUserIdMap[userId];
      const crewPayInfo = companyCrewMap[userId];
      if (
        currentPayInfo &&
        crewPayInfo &&
        currentPayInfo.payType &&
        (!crewPayInfo.payType ||
          !crewPayInfo.payRate ||
          Number(crewPayInfo.payRate) === 0)
      ) {
        usersToUpdate.push({
          userId,
          department: currentPayInfo.department || null,
          payType: currentPayInfo.payType,
          payRate: currentPayInfo.payRate,
          laborBurdenPercentage: currentPayInfo.laborBurdenPercentage,
          vacaAccrualRate: currentPayInfo.vacaAccrualRate,
        });
      }
    });

    if (!_.isEmpty(usersToUpdate)) {
      setShowSetDefaultPayInfoDialog({
        open: true,
        usersToUpdate,
      });
      return;
    }

    handleDone(budgetsByUserIdMap);
  };

  const onNoUpdateClick = () => {
    setShowSetDefaultPayInfoDialog({
      open: false,
      usersToUpdate: [],
    });
    handleDone(budgetsByUserIdMap);
  };

  const onYesUpdateClick = async () => {
    setUpdatingUserPayInfo(true);
    // Sequentially update user pay info
    await _.reduce(
      showSetDefaultPayInfoDialog.usersToUpdate,
      async (sequence, userToUpdate) => {
        return sequence.then(async () => {
          await onUpdateUserAdmin(userToUpdate);
        });
      },
      Promise.resolve()
    );
    setShowSetDefaultPayInfoDialog({
      open: false,
      usersToUpdate: [],
    });
    setUpdatingUserPayInfo(false);
  };

  const handleInfoChange = ({ userId, fieldName, value }) => {
    setBudgetsByUserIdMap(prevState => {
      const newState = _.cloneDeep(prevState);
      newState[userId][fieldName] = value;
      const hourlyRate = calculateHourlyRate(newState[userId]);
      newState[userId].hourlyRate = hourlyRate;
      newState[userId].cost = newState[userId].hours * hourlyRate;
      return newState;
    });
  };

  const handleInfoBlur = ({ userId, fieldName, value }) => {
    setBudgetsByUserIdMap(prevState => {
      const newState = _.cloneDeep(prevState);
      newState[userId][fieldName] = numeral(value).format('0.00');
      const hourlyRate = calculateHourlyRate(newState[userId]);
      newState[userId].hourlyRate = hourlyRate;
      const cost = newState[userId].hours * hourlyRate;
      newState[userId].cost = numeral(cost).format('0.00');
      return newState;
    });
  };

  const renderListOfUserBudgets = () => {
    return allUserIdsOnProject.map(userId => {
      return (
        <LaborBudgetUserPayInfoItem
          key={userId}
          userBudget={budgetsByUserIdMap[userId]}
          companyCrewMap={companyCrewMap}
          isDeactiveUser={!activeUserMap[userId]}
          onInfoChange={handleInfoChange}
          onInfoBlur={handleInfoBlur}
        />
      );
    });
  };

  const renderListOfUsersToUpdate = () => {
    return showSetDefaultPayInfoDialog.usersToUpdate.map(userToUpdate => {
      const userInfo = companyCrewMap[userToUpdate.userId];
      const payInfo = [];

      _.forEach(userToUpdate, (value, key) => {
        if (value) {
          switch (key) {
            case 'department':
              payInfo.push(`Department: ${crewDepartmentLabelMap[value]}`);
              break;
            case 'payType':
              payInfo.push(`Pay Type: ${crewPayTypeLabelMap[value]}`);
              break;
            case 'payRate':
              if (userToUpdate.payType !== CREW_PAY_TYPES.NO_PAY) {
                payInfo.push(
                  `Pay Rate: $${value}/${
                    userToUpdate.payType === CREW_PAY_TYPES.FIXED_SALARY
                      ? 'year'
                      : 'hour'
                  }`
                );
              }
              break;
            case 'laborBurdenPercentage':
              payInfo.push(`Labor Burden: ${value}%`);
              break;
            case 'vacaAccrualRate':
              payInfo.push(`Vacation Accrual Rate: ${value}%`);
              break;
            default:
              break;
          }
        }
      });
      const payInfoString = payInfo.join(', ');
      return (
        <ListItem key={userInfo.userId}>
          <ListItemAvatar>
            <Avatar src={userInfo.profilePic} />
          </ListItemAvatar>
          <ListItemText
            primary={getFullUserString(userInfo)}
            secondary={payInfoString}
          />
        </ListItem>
      );
    });
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="xl"
        fullWidth
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose(event, reason);
          }
        }}
        disableEscapeKeyDown
      >
        <DialogTitle disableTypography>
          <Grid container alignItems="center">
            <AccountBalanceIcon />
            &nbsp;
            <Typography variant="h6">User Pay Info</Typography>
          </Grid>
          <AccessLimitedToInfo companyOwner companyBookkeeper companyAdmin />
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            spacing={3}
            style={{ minHeight: 300, marginTop: 0, marginBottom: 2 }}
            alignContent="flex-start"
          >
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              className={classes.headerRow}
            >
              <Grid item lg={3} xs={2} className={classes.headerCell}>
                <Typography className={classes.heading}>Username</Typography>
              </Grid>
              <Grid item xs={2} className={classes.headerCell}>
                <Typography className={classes.heading}>Department</Typography>
              </Grid>
              <Grid item xs={2} className={classes.headerCell}>
                <Typography className={classes.heading}>Pay Type</Typography>
              </Grid>
              <Grid item lg={1} xs={2} className={classes.headerCell}>
                <Typography className={classes.heading}>Pay Rate</Typography>
              </Grid>
              <Grid item lg={1} xs={2} className={classes.headerCell}>
                <Typography className={classes.heading}>
                  Labor Burden (excl. Vacation Accrual)
                </Typography>
              </Grid>
              <Grid
                container
                item
                lg={1}
                xs={2}
                className={classes.headerCell}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography className={classes.heading}>
                  Vacation Accrual
                </Typography>
                <Tooltip title="1 day of leave equates to a ~0.4% Vacation Accrual. For example, 10 leave days = 4% Vacation Accrual (0.4% x 10)">
                  <InfoOutlinedIcon className={classes.infoIcon} />
                </Tooltip>
              </Grid>
              <Hidden mdDown>
                <Grid item xs={1} className={classes.headerCell}>
                  <Typography className={classes.heading}>
                    Hourly Rate + Labor Burden
                  </Typography>
                </Grid>
              </Hidden>
            </Grid>
            {renderListOfUserBudgets()}
          </Grid>
        </DialogContent>

        <DialogActions className={classes.divider} />
        <DialogActions className={classes.actionContainer}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            flex={1}
          >
            <Grid container item xs={7}>
              {(managingCompanyInfo.isCompanyOwner ||
                managingCompanyInfo.isCompanyBookkeeper ||
                managingCompanyInfo.isCompanyAdmin) && (
                <Typography color="textSecondary">
                  Note: Pay rates are project specific. Update the default
                  values in the&nbsp;
                  <RouterLink to="/crew" className={classes.activeLink}>
                    Manage Crew
                  </RouterLink>
                  &nbsp;table.
                </Typography>
              )}
            </Grid>
            <Grid container item xs={5} justifyContent="flex-end">
              <Button onClick={handleClose} color="primary" autoFocus>
                Cancel
              </Button>
              <Button
                onClick={onDoneClick}
                variant="contained"
                color="primary"
                autoFocus
              >
                Done
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      {showSetDefaultPayInfoDialog.open && (
        <Dialog
          open={showSetDefaultPayInfoDialog.open}
          maxWidth="md"
          fullWidth
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setShowSetDefaultPayInfoDialog({
                open: false,
                usersToUpdate: [],
              });
            }
          }}
          disableEscapeKeyDown
        >
          <DialogTitle disableTypography>
            <Grid container alignItems="center">
              <AccountBalanceIcon />
              &nbsp;
              <Typography variant="h6">User Pay Info</Typography>
            </Grid>
            <AccessLimitedToInfo companyOwner companyBookkeeper companyAdmin />
          </DialogTitle>
          <DialogContent>
            <Grid style={{ marginTop: 0, marginBottom: 8 }}>
              <Typography>
                The default pay info for the user(s) listed below has not been
                set in the Manage Crew table. Would you like to set their
                default pay info using the information listed below?
              </Typography>
              <List>{renderListOfUsersToUpdate()}</List>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.divider} />
          <DialogActions className={classes.actionContainer}>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              flex={1}
            >
              <Button onClick={onNoUpdateClick} color="primary" autoFocus>
                No
              </Button>
              <Button
                onClick={onYesUpdateClick}
                variant="contained"
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            </Grid>
          </DialogActions>
          {updatingUserPayInfo && (
            <LoadingCover loader="linear">
              Setting default pay info...
            </LoadingCover>
          )}
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || {},
});

export default compose(
  GetCompanyCrewAdminAction,
  UpdateUserAdminMutationAction,
  connect(mapStateToProps)
)(LaborBudgetUserPayInfoDialog);
