import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

const CenteredSpinner = ({ styles, customPadding, customSize }) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ padding: customPadding || 32, ...styles }}
    >
      <CircularProgress size={customSize || 32} />
    </Grid>
  );
};

export default CenteredSpinner;
