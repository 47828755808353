import React, { useEffect, useRef, useState } from 'react';

import _ from 'lodash';
import { useQuery } from 'react-apollo-hooks';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import {
  EXPIRED_SUBSCRIPTION_STATUSES,
  FIRM_ROLE,
  PRODUCT,
} from '../../config/appDefaults';
import GetUserInfo from '../../graphql/queries/GetUserInfo';
import { determineManagingCompanyInfo } from '../../helpers';
import { SCOPE } from '../../route-builder/routes.config';
import GetCompanyById from '../../graphql/queries/get-company-by-id';

const AuthRoute = ({
  component: Component,
  layout: Layout = null,
  currentAuth,
  dispatch,
  location,
  userInfo,
  managingCompanyInfo,
  firmInfo,
  scope,
  redirectLink,
  // Blocker flags
  blockIfNoProjectManagement,
  blockIfNotCompanyAdmin,
  blockIfNotCompanyAdminOrBookkeeper,
  blockIfNoAccessToAlerts,
  blockIfNoAccessToRfi,
  blockIfNoBookkeeperAccessToRfi,
  blockIfNoAccessToScoreboards,
  ...rest
}) => {
  const [wasLoggedIn, setWasLoggedIn] = useState(currentAuth.isLoggedIn);
  const [unauthRedirectTo, setUnauthRedirectTo] = useState(null);

  // use useRef to check if managingCompanyInfo has been checked before
  const hasManagingCompanyInfoCheckedRef = useRef(false);
  const isCheckingManagingCompanyInfoRef = useRef(false);

  const userBelongsToFirm = !!userInfo?.managingFirmId;

  const { refetch: getCompanyById } = useQuery(GetCompanyById, {
    skip: true, // skip the query until we need it
    fetchPolicy: 'network-only',
  });

  const { refetch: getUserInfo } = useQuery(GetUserInfo, {
    skip: true, // skip the query until we need it
    fetchPolicy: 'network-only',
    variables: {
      userId: 'willBePulledFromCognitoSubContentInResolver',
    },
  });

  useEffect(() => {
    const recheckManagingCompanyInfo = async () => {
      isCheckingManagingCompanyInfoRef.current = true;
      const getUserInfoResult = await getUserInfo();
      const currentUserInfo = _.get(
        getUserInfoResult,
        'data.getMyUserInfo',
        null
      );

      const [companyId] = currentUserInfo?.companies || [];

      if (companyId) {
        const getCompanyByIdResult = await getCompanyById({ companyId });
        const companyInfo = _.get(
          getCompanyByIdResult,
          'data.getCompanyById',
          null
        );

        if (companyInfo) {
          const freshManagingCompanyInfo = determineManagingCompanyInfo({
            companyInfo,
            userInfo: currentUserInfo,
          });

          if (!_.isEqual(freshManagingCompanyInfo, managingCompanyInfo)) {
            dispatch({
              type: 'SET_MANAGING_COMPANY_INFO',
              payload: { ...freshManagingCompanyInfo },
            });
          }
        }
      }

      hasManagingCompanyInfoCheckedRef.current = true;
      isCheckingManagingCompanyInfoRef.current = false;
    };

    // TOWATCH: This could also be the case for firmInfo.
    // NOTE: For unknown reasons, the managingCompanyInfo is sometimes empty after returning
    // from a successful connection to QBO. This is a workaround to recheck the managingCompanyInfo
    // if it's empty to prevent the user from being redirected to the no-company-access page.
    if (
      _.isEmpty(managingCompanyInfo) && // only recheck if managingCompanyInfo isn't loaded
      currentAuth.isLoggedIn && // only recheck if the user is logged in
      userInfo.type && // only recheck if user isn't currently still onboarding
      !userBelongsToFirm && // only recheck if the user is not a firm member
      !hasManagingCompanyInfoCheckedRef.current && // we haven't run this re-check yet
      !isCheckingManagingCompanyInfoRef.current // no other re-check is in progress
    ) {
      recheckManagingCompanyInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managingCompanyInfo, currentAuth]);

  useEffect(() => {
    if (scope === SCOPE.FIRM && managingCompanyInfo) {
      // This will clean up the managingCompanyInfo state if the user is navigating back to the firm scope
      dispatch({
        type: 'SET_MANAGING_COMPANY_INFO',
        payload: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managingCompanyInfo, scope]);

  useEffect(() => {
    // If trying to access an authenticated endpoint while not authenticated...
    if (!currentAuth.isLoggedIn) {
      const linkRedirect = location.pathname + location.search;
      // ... and they didn't just log out, set the redirect for the resource they were trying to access
      if (!wasLoggedIn && linkRedirect !== '/') {
        dispatch({
          type: 'LINK_REDIRECT',
          payload: linkRedirect,
        });
      } else if (wasLoggedIn) {
        // If they just logged out, update the state
        setWasLoggedIn(currentAuth.isLoggedIn);
      }

      let redirectTo = '/auth';
      if (currentAuth.redirectToOnSignOut) {
        redirectTo = currentAuth.redirectToOnSignOut;
      }

      setUnauthRedirectTo(redirectTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAuth]);

  // If the user is not authenticated, redirect them to the login page
  if (unauthRedirectTo) {
    return <Redirect to={unauthRedirectTo} replace />;
  }

  if (redirectLink) {
    dispatch({
      type: 'LINK_REDIRECT',
      payload: null,
    });
    return <Redirect to={redirectLink} />;
  }

  // #region Scope checking
  let wrongScope = false;
  if (!managingCompanyInfo && scope === SCOPE.COMPANY_OR_CLIENT) {
    // If scope is company or client, we need managingCompanyInfo
    wrongScope = true;
  }

  if (wrongScope) {
    // If user has the wrong scope set
    // let the default route handler determine where it should go
    return <Redirect to="/" replace />;
  }
  // #endregion Scope checking

  // Check if user has any access
  const hasCompanyAccess =
    (userBelongsToFirm && userInfo.managingFirmRole !== FIRM_ROLE.INACTIVE) ||
    (!userBelongsToFirm && managingCompanyInfo?.managingCompanyId);

  // If the user is authenticated, but doesn't belong to a company,
  // redirect them to the no company access page
  if (
    !hasCompanyAccess &&
    location.pathname !== '/no-company-access' && // and we're not already on the path
    hasManagingCompanyInfoCheckedRef.current // and we've already checked for the company info
  ) {
    const linkRedirect = location.pathname + location.search;
    return (
      <Redirect
        to={{
          pathname: '/no-company-access',
          state: { linkRedirect },
        }}
      />
    );
  }

  const notOnLockedScreenAlready = location.pathname !== '/company-locked';
  const companyToCheckLockOn = userInfo?.managingFirmId
    ? firmInfo
    : managingCompanyInfo;

  const companyLockedState = {};
  if (notOnLockedScreenAlready) {
    const isLockedReasonBased = companyToCheckLockOn?.isLockedReason;
    const subscriptionEnded = EXPIRED_SUBSCRIPTION_STATUSES.includes(
      companyToCheckLockOn?.subscriptionStatus
    );

    // If the user is authenticated, but the company is locked,
    // redirect them to the company locked page
    if (notOnLockedScreenAlready) {
      if (isLockedReasonBased) {
        return <Redirect to="/company-locked" />;
      }
      if (subscriptionEnded && !userBelongsToFirm) {
        companyLockedState.checkForProducts = [PRODUCT.BOOKKEEPING_ALERTS];
        const currentPath = location.pathname + location.search;
        companyLockedState.checkForProductsSuccessRedirect = currentPath;
        return (
          <Redirect
            to={{ pathname: '/company-locked', state: companyLockedState }}
          />
        );
      }
    }
  }

  // If the user is authenticated, but doesn't have access to the restricted resource,
  // redirect them to the no access (no authorization) page
  // #region Route specific blockers
  let showNoAccess = false;

  const {
    isCompanyAdmin,
    isCompanyOwner,
    isCompanyBookkeeper,
    hasBookkeepingAlerts,
    hasProjectManagementProduct,
    hasRfiFeature,
    isBookkeepingCustomer,
    isAdvisoryCustomer,
  } = managingCompanyInfo || {};

  if (blockIfNotCompanyAdmin) {
    if (!isCompanyAdmin) {
      showNoAccess = true;
    }
  }

  if (blockIfNotCompanyAdminOrBookkeeper) {
    if (!(isCompanyAdmin || isCompanyBookkeeper)) {
      showNoAccess = true;
    }
  }

  if (blockIfNoAccessToAlerts) {
    if (
      (!userBelongsToFirm && !hasBookkeepingAlerts) ||
      !(isCompanyOwner || isCompanyBookkeeper)
    ) {
      showNoAccess = true;
    }
  }

  if (blockIfNoAccessToRfi) {
    if (!hasRfiFeature || !(isCompanyAdmin || isCompanyBookkeeper)) {
      showNoAccess = true;
    }
  }

  if (blockIfNoBookkeeperAccessToRfi) {
    if (!hasRfiFeature || !isCompanyBookkeeper) {
      showNoAccess = true;
    }
  }

  if (blockIfNoAccessToScoreboards) {
    if (
      !(isCompanyOwner || isCompanyBookkeeper) ||
      !(isBookkeepingCustomer || isAdvisoryCustomer)
    ) {
      showNoAccess = true;
    }
  }

  if (blockIfNoProjectManagement) {
    if (!hasProjectManagementProduct) {
      showNoAccess = true;
    }
  }
  // #endregion

  if (showNoAccess) {
    return <Redirect to="/no-access" />;
  }

  return (
    <Route
      {...rest}
      render={routeProps =>
        Layout ? (
          <Layout>
            <Component {...routeProps} />
          </Layout>
        ) : (
          <Component {...routeProps} />
        )
      }
    />
  );
};
const mapStateToProps = state => {
  return {
    currentAuth: state.currentAuth,
    userInfo: state.userInfo,
    redirectLink: state.appState.redirectLink,
    managingCompanyInfo: state.appState.managingCompanyInfo,
    firmInfo: state.appState.firmInfo,
  };
};

export default connect(mapStateToProps)(AuthRoute);
