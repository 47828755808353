import React, { useEffect } from 'react';
import { InputLabel, TextField, Typography } from '@material-ui/core';
import numeral from 'numeral';
import { CONTENT_TYPE } from '../../../config/appDefaults';

const AmountField = ({
  classes,
  error,
  values,
  touched,
  setFieldValue,
  invalidAmount,
  onResetInvalidAmount = () => {},
  onBlur,
}) => {
  let amountValue = '';
  if (
    values.amount &&
    values.amount.value !== null &&
    values.amount.value !== undefined
  ) {
    amountValue = values.amount.value;
    if (typeof amountValue === 'number') {
      amountValue = values.amount.value.toFixed(2);
    }
  }

  const showError = !!error && !!touched;

  useEffect(() => {
    onResetInvalidAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.amount]);

  return (
    <>
      <InputLabel
        className={classes.staticLabel}
        htmlFor="amount"
        error={showError || invalidAmount}
      >
        {values.type === CONTENT_TYPE.GLOBAL_PAYMENT
          ? 'Payment amount including tax ($)'
          : 'Amount including tax ($)'}
      </InputLabel>

      <TextField
        inputProps={{
          style: { textAlign: 'right', height: 18 },
        }}
        type="number"
        name="amount"
        error={showError || invalidAmount}
        fullWidth
        value={amountValue}
        margin="none"
        onBlur={e => {
          if (values.amount) {
            const valueToSet = numeral(values.amount.value).format('0.00');
            setFieldValue('amount', {
              ...values.amount,
              value: valueToSet,
            });
          }
          if (onBlur) {
            onBlur(e);
          }
        }}
        onChange={event => {
          setFieldValue('amount', {
            ...values.amount,
            value: event.target.value,
          });
        }}
      />
      {showError && !invalidAmount && (
        <Typography variant="caption" color="error" style={{ marginTop: 4 }}>
          This field is required
        </Typography>
      )}
      {invalidAmount && (
        <Typography variant="caption" color="error" style={{ marginTop: 4 }}>
          Amount must be greater than 0
        </Typography>
      )}
    </>
  );
};

export default AmountField;
