import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from '../../components';
import Scoreboard from './scoreboard';
import dashboards from './scoreboard-dashboards';

const ScoreboardRouter = () => {
  return (
    <Switch>
      <Redirect exact from="/scoreboard/" to="/scoreboard/breakeven" />
      {dashboards.map(({ path, component }) => (
        <RouteWithLayout
          key={path}
          path={`/scoreboard/${path}`}
          layout={Scoreboard}
          component={component}
          dashboards={dashboards}
        />
      ))}
      {/* <RouteWithLayout path="/scoreboard/jobcosts" component={Scoreboard} /> */}
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default ScoreboardRouter;
