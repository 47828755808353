import { Badge } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import palette from '../../theme/palette';

const BookkeepingAlertsBadge = withStyles(theme => ({
  badge: {
    top: 12,
    minWidth: 60,
    backgroundColor: ({ color }) => {
      switch (color) {
        case 'primary':
          return theme.palette.error.main;
        case 'secondary':
          return palette.brandColorOrange;
        case 'error':
          return '#dddddd';
        case 'default':
        default:
          return palette.brandColorGreen;
      }
    },
    color: theme.palette.common.white,
  },
}))(Badge);

export default BookkeepingAlertsBadge;
