/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { geocodeByAddress } from 'react-places-autocomplete';
import { compose } from 'react-apollo';
import { useSnackbar } from 'notistack';
import CreatableSelect from 'react-select/creatable';

import {
  Grid,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
  TextField,
  InputLabel,
} from '@material-ui/core';
import { ImageOutlined as ImageIcon } from '@material-ui/icons';
import Dropzone from 'react-dropzone';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import {
  MAX_CHARACTERS_IN_COMPANYNAME,
  COMPANY_INDUSTRY_OPTIONS,
} from '../../config/appDefaults';
import LevelLogo from '../../components/level-logo/level-logo';
import AuthTextField from '../../components/auth/auth-text-field/auth-text-field';
import Label from '../../components/auth/auth-text-field/label';
import LocationSearchInput from '../../components/location-search-input/location-search-input';
import { AddOrUpdateCompanyAction } from '../../graphql/graphql';
import { onUploadFile } from '../../helpers/cloudinary';

import { useStyles } from './onboarding.styles';
import { isDefaultCompanyName, extractAddressDetails } from '../../helpers';
import OnboardingStepButtons from './onboarding-step-buttons';
import LoadingCover from '../../components/LoadingCover/loadingCover';
import { COMPANY_TYPE } from './onboarding.constants';

const startingAddressObj = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
};

const customStyles = {
  control: base => ({
    ...base,
    borderTop: 'none',
    borderRight: 'none',
    borderBottom: '1px solid rgba(0,0,0, 0.42)',
    borderLeft: 'none',
    borderRadius: 0,
    fontSize: 14,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  }),
  valueContainer: base => ({
    ...base,
    paddingLeft: 0,
  }),
  placeholder: base => ({
    ...base,
    color: '#aaa',
  }),
};

const OnboardingCompanyInfoStep = ({
  // passed props
  isCopilot,
  companyInfo,
  setLoading,
  onAddOrUpdateCompany,
  requireBillingInfo,
  passBackCompanyBillingAddress,
  checkoutWasCancelled,
  hideCompanyAddress = false,
  companyType = COMPANY_TYPE.CLIENT,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: startingAddressObj,
    validationSchema: Yup.object({
      address1: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
      zip: Yup.string().required('Required'),
      // phoneNumber: Yup.string().required('Required'),
      country: Yup.string().required('Required'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
      }, 400);
    },
  });

  useEffect(() => {
    if (checkoutWasCancelled) {
      // Enqueue your notification
      const key = enqueueSnackbar(
        'Checkout was cancelled. Please re-enter your billing info and try again.',
        { variant: 'error', persist: true }
      );

      // Cleanup function runs when the component unmounts
      return () => {
        // Dismiss the specific notification
        closeSnackbar(key);
      };
    }
    return undefined;
  }, [checkoutWasCancelled, closeSnackbar, enqueueSnackbar]);

  const [companyValues, setCompanyValues] = useState({
    companyId: uuid(),
    companyLogo: null,
    companyName: '',
    invitationId: null,
    companyIndustry: '',
  });

  const [localCompanyAddressObj, setLocalCompanyAddressObj] = useState(null);
  const [optionsToUse, setOptionsToUse] = useState(COMPANY_INDUSTRY_OPTIONS);
  const [companyIndustry, setCompanyIndustry] = useState('');
  const handleIndustryChange = selectedOption => {
    setCompanyIndustry(selectedOption);
  };
  const handleCreateIndustry = inputValue => {
    setOptionsToUse([
      ...COMPANY_INDUSTRY_OPTIONS,
      { label: inputValue, value: inputValue },
    ]);
    setCompanyIndustry({ label: inputValue, value: inputValue });
  };
  const [billingAddress, setBillingAddress] = useState(startingAddressObj);

  const [sameBillingAddress, setSameBillingAddress] = useState(false);
  const [showALoader, setShowALoader] = useState(false);
  const handleCompanyNameChange = e => {
    const passedCompanyName = e.target.value;
    setCompanyValues(currentValues => ({
      ...currentValues,
      companyName: passedCompanyName,
    }));
  };

  useEffect(() => {
    if (!_.isEmpty(companyInfo)) {
      const updatedCompanyValues = {
        ...companyValues,
        companyLogo: companyInfo.companyLogo || null,
      };

      if (
        companyInfo.companyId &&
        companyInfo.companyId !== companyValues.companyId
      ) {
        updatedCompanyValues.companyId = companyInfo.companyId;
      }

      if (
        !companyValues.companyName &&
        !isDefaultCompanyName(companyInfo.companyName) // Do not show default company name to user
      ) {
        updatedCompanyValues.companyName = companyInfo.companyName || '';
      }

      if (!companyValues.address) {
        updatedCompanyValues.address = companyInfo.address || '';
      }

      setCompanyValues(updatedCompanyValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo]);

  const addressValid = hideCompanyAddress || _.trim(companyValues.address);
  const companyInfoEntered = _.trim(companyValues.companyName) && addressValid;

  let allowGoToNext = companyInfoEntered;

  if (isCopilot) {
    if (!companyIndustry?.value) {
      allowGoToNext = false;
    }
  }

  if (requireBillingInfo) {
    const billingAddressValid = formik.dirty && formik.isValid;
    if (!billingAddressValid) {
      allowGoToNext = false;
    }
  }

  const onNextAction = async () => {
    setLoading(true);
    if (
      companyValues.companyLogo &&
      !_.includes(companyValues.companyLogo.uri, 'cloudinary')
    ) {
      const { uri: uploadedUri } = await onUploadFile(
        companyValues.companyLogo,
        companyValues.companyId
      );
      companyValues.companyLogo = uploadedUri;
    }

    const valuesToPass = {
      ...companyValues,
    };
    if (companyIndustry?.value) {
      valuesToPass.industry = companyIndustry.value;
    }

    await onAddOrUpdateCompany({
      ...valuesToPass,
      companyName: _.trim(companyValues.companyName),
      companyType,
    });

    dispatch({
      type: 'COMPANY_REGISTRATION_KEY',
      payload: null,
    });

    // if the billing address has been updated, pass it back to the parent
    if (!_.isEqual(billingAddress, startingAddressObj)) {
      passBackCompanyBillingAddress(billingAddress);
    }
    setLoading(false);
  };

  const handleSameBillingAddress = async e => {
    setSameBillingAddress(e.target.checked);
    if (e.target.checked) {
      let addressToUse = localCompanyAddressObj;
      if (!addressToUse && companyValues.address) {
        setShowALoader(true);
        const cleanAddressObj = await geocodeByAddress(companyValues.address);
        addressToUse = extractAddressDetails(cleanAddressObj[0]);
        setShowALoader(false);
      }
      if (addressToUse) {
        setBillingAddress(addressToUse);
        formik.setValues({ ...startingAddressObj, ...addressToUse });
      }
    }
  };

  const customFilterSecondaryValues = (option, inputValue) => {
    // search both the label and all the strings in the secondaryValues array, if available, for a string match
    const allValues = [option.label];
    if (option.data.secondaryValues) {
      allValues.push(...option.data.secondaryValues);
    }
    return allValues.some(value =>
      value.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const isFirm = companyType === COMPANY_TYPE.FIRM;

  return (
    <>
      <Grid item xs={12}>
        <LevelLogo
          logoVariation={isCopilot ? 'new' : 'base'}
          className={classes.smallLevelLogo}
        />
      </Grid>
      <Grid item xs={12} className={classes.title} style={{ marginTop: 32 }}>
        {isFirm ? 'Firm Info' : 'Company Info'}
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.subtitle}
        style={{ marginBottom: 16 }}
      >
        {isFirm
          ? 'The bookkeeping/accounting firm with access to client accounts.'
          : 'The company whose books will be connected to Level.'}
      </Grid>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        className={classes.fieldsContainer}
      >
        <Grid item xs={12}>
          <AuthTextField
            label={isFirm ? 'Firm Name' : 'Company Name'}
            required
            value={companyValues.companyName}
            maxLength={MAX_CHARACTERS_IN_COMPANYNAME}
            onChange={handleCompanyNameChange}
            placeholder={
              isFirm ? 'Enter your firm name...' : 'Enter your company name...'
            }
          />
        </Grid>

        {!hideCompanyAddress && (
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            style={{ marginBottom: 24 }}
          >
            <Grid
              item
              xs={12}
              className={classes.textField}
              style={{ textAlign: 'left' }}
            >
              <LocationSearchInput
                label={
                  <Label
                    text={isFirm ? 'Firm Address' : 'Company Address'}
                    required
                  />
                }
                InputLabelProps={{ shrink: true }}
                multiline
                pushedAddress={companyValues.address}
                passBack={address => {
                  setCompanyValues(currentValues => ({
                    ...currentValues,
                    address,
                  }));
                }}
                passbackAddressObject={addressObject => {
                  const cleanAddressObj = extractAddressDetails(addressObject);
                  setLocalCompanyAddressObj(cleanAddressObj);
                  if (sameBillingAddress) {
                    formik.setValues({
                      ...startingAddressObj,
                      ...cleanAddressObj,
                    });
                  }
                }}
              />
            </Grid>
          </Grid>
        )}

        {isCopilot && (
          <Grid item container xs={12} style={{ marginBottom: 24 }}>
            <Grid item xs={12}>
              <InputLabel shrink required className={classes.basicInputLabel}>
                Choose your industry
              </InputLabel>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'left' }}>
              <CreatableSelect
                styles={customStyles}
                isMulti={false}
                value={companyIndustry}
                options={optionsToUse}
                onChange={handleIndustryChange}
                placeholder="Type and choose..."
                isClearable
                onCreateOption={handleCreateIndustry}
                filterOption={customFilterSecondaryValues}
              />
            </Grid>
          </Grid>
        )}

        <Grid item container xs={12}>
          <Grid item xs={12} style={{ marginBottom: 8 }}>
            <InputLabel shrink className={classes.basicInputLabel}>
              {isFirm ? 'Firm Logo' : 'Company Logo'}
            </InputLabel>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 8 }}>
            <Dropzone
              accept="image/*"
              onDrop={acceptedFiles => {
                const filesToPass = [...acceptedFiles];
                filesToPass[0].uri = URL.createObjectURL(filesToPass[0]);
                setCompanyValues(currentValues => ({
                  ...currentValues,
                  companyLogo: acceptedFiles[0],
                }));
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  {...getRootProps()}
                  style={{
                    cursor: 'pointer',
                    padding: companyValues.companyLogo ? 0 : 8,
                  }}
                  component={companyValues.companyLogo ? 'div' : Paper}
                >
                  <input {...getInputProps()} />
                  {!companyValues.companyLogo ? (
                    <>
                      <ImageIcon className={classes.companyLogoIcon} />
                      <Typography
                        color="primary"
                        variant="body1"
                        display="inline"
                      >
                        Click here to upload
                        <br />
                        your company logo
                      </Typography>
                    </>
                  ) : (
                    <>
                      <img
                        alt={isFirm ? 'Firm Logo' : 'Company Logo'}
                        src={
                          _.includes(companyValues.companyLogo, 'cloudinary')
                            ? companyValues.companyLogo
                            : companyValues.companyLogo.uri
                        }
                        className={classes.companyLogo}
                      />
                      <Typography
                        color="primary"
                        style={{ textAlign: 'right', marginLeft: 16 }}
                        display="inline"
                      >
                        Change...
                      </Typography>
                    </>
                  )}
                </Grid>
              )}
            </Dropzone>
          </Grid>
        </Grid>

        {requireBillingInfo && (
          <Grid
            item
            container
            xs={12}
            style={{ marginTop: 32, backgroundColor: '#f5f5f5' }}
          >
            <Grid
              item
              xs={12}
              container
              className={classes.textField}
              style={{ textAlign: 'left', padding: 16, position: 'relative' }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-start"
                item
                xs={12}
                style={{ textAlign: 'left' }}
              >
                <Typography style={{ fontSize: 16 }} display="inline">
                  Billing Address *
                </Typography>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sameBillingAddress}
                      onChange={handleSameBillingAddress}
                      name="sameBillingAddress"
                      color="primary"
                    />
                  }
                  label="Copy from above"
                  labelPlacement="start"
                />
              </Grid>

              <form onSubmit={formik.handleSubmit}>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <TextField
                      name="address1"
                      label="Address 1 *"
                      error={
                        formik?.touched?.address1 &&
                        Boolean(formik?.errors?.address1)
                      }
                      helperText={
                        formik?.touched?.address1 && formik?.errors?.address1
                      }
                      InputLabelProps={{ shrink: true }}
                      className={classes.billingAddressField}
                      onChange={formik.handleChange}
                      value={formik.values.address1}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="address2"
                      label="Address 2 (Unit, Suite, etc.)"
                      error={
                        formik?.touched?.address2 &&
                        Boolean(formik?.errors?.address2)
                      }
                      helperText={
                        formik?.touched?.address2 && formik?.errors?.address2
                      }
                      InputLabelProps={{ shrink: true }}
                      className={classes.billingAddressField}
                      onChange={formik.handleChange}
                      value={formik.values.address2}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="city"
                      label="City *"
                      error={
                        formik?.touched?.city && Boolean(formik?.errors?.city)
                      }
                      helperText={formik?.touched?.city && formik?.errors?.city}
                      InputLabelProps={{ shrink: true }}
                      className={classes.billingAddressField}
                      onChange={formik.handleChange}
                      value={formik.values.city}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="state"
                      label="State *"
                      error={
                        formik?.touched?.state && Boolean(formik?.errors?.state)
                      }
                      helperText={
                        formik?.touched?.state && formik?.errors?.state
                      }
                      InputLabelProps={{ shrink: true }}
                      className={classes.billingAddressField}
                      onChange={formik.handleChange}
                      value={formik.values.state}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="zip"
                      label="Zip *"
                      error={
                        formik?.touched?.zip && Boolean(formik?.errors?.zip)
                      }
                      helperText={formik?.touched?.zip && formik?.errors?.zip}
                      InputLabelProps={{ shrink: true }}
                      className={classes.billingAddressField}
                      onChange={formik.handleChange}
                      value={formik.values.zip}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="country"
                      label="Country *"
                      error={
                        formik?.touched?.country &&
                        Boolean(formik?.errors?.country)
                      }
                      helperText={
                        formik?.touched?.country && formik?.errors?.country
                      }
                      InputLabelProps={{ shrink: true }}
                      className={classes.billingAddressField}
                      onChange={formik.handleChange}
                      value={formik.values.country}
                    />
                  </Grid>
                </Grid>
              </form>
              {showALoader && <LoadingCover />}
            </Grid>
          </Grid>
        )}
      </Grid>
      <OnboardingStepButtons
        allowGoToNext={allowGoToNext}
        nextAction={onNextAction}
      />
    </>
  );
};

export default compose(AddOrUpdateCompanyAction)(OnboardingCompanyInfoStep);
