import React from 'react';

import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import InputContentLoader from '../input-content-loader/input-content-loader';

const CustomScanSettingsDatePicker = ({
  label,
  loading,
  value,
  maxDate,
  maxDateMessage,
  minDate,
  onChange,
  disabled,
  ...props
}) => {
  return (
    <InputContentLoader loading={loading} label={label}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          fullWidth
          label={label}
          value={value}
          variant="inline"
          maxDate={maxDate}
          minDate={minDate}
          maxDateMessage={maxDateMessage}
          format="MMMM D, YYYY"
          disabled={disabled}
          autoOk
          onChange={date => {
            onChange(date);
          }}
          {...props}
        />
      </MuiPickersUtilsProvider>
    </InputContentLoader>
  );
};

export default CustomScanSettingsDatePicker;
