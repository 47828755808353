import React, { useState, useMemo } from 'react';

import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Tooltip, Typography } from '@material-ui/core';

import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';

import _ from 'lodash';
import numeral from 'numeral';
import moment from 'moment';

import EditableBudget from './editable-budget';
import TabPanel from './tab-panel';

import {
  calculateHourlyRate,
  getFullUserString,
} from '../../../../../../helpers';
import { calculateCompletePercentage } from './helpers';
import {
  BUDGET_TYPE_RECEIPTS_INVOICES,
  BUDGET_TYPE_UNASSIGNED_LABOR_HOURS,
  BUDGET_TYPE_USER_LABOR_HOURS,
  CREW_PAY_TYPES,
} from '../../../../../../config/appDefaults';

const DEFAULT_VISIBLE_ROWS = 10;
const UNASSIGNED_USER_ID = 'unassigned';
const UNASSIGNED_USER_NAME = 'Unassigned';

const useStyles = makeStyles(theme => ({
  tableHeading: {
    fontSize: 14,
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: '#999',
    textAlign: 'center',
  },
  headerRow: {
    background: '#f0f0f0',
  },
  row: {
    padding: '8px 4px 8px 8px',
    borderBottom: '1px solid #e6e6e6',
    '&:hover': {
      border: `1px solid ${theme.palette.brandColorPrimary}`,
    },
    justifyContent: 'center',
    alignItems: 'center',
  },
  cellTextCenter: {
    fontSize: 14,
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
  cellTextLeft: {
    fontSize: 14,
    textOverflow: 'ellipsis',
    textAlign: 'left',
  },
  noData: {
    marginTop: 16,
    marginBottom: 16,
  },
}));

const ByUserTabPanel = ({
  editBudgetByUser,
  data,
  overageIsGood,
  parentProjectDetails,
  projectUsers,
  tabIndex,
  tabValue, // current showing tab value
  timeManager,
  hideZeroRows,
  budgetInfo,
  dialogTitle,
}) => {
  const {
    tabName,
    budgetLabels,
    dataUnit,
    numberFormat,
    pluralWording,
    budgetInfo: tabDataBudgetInfo, // total = labor cost, and budget = labor budget
    contentSumByUserId, // total = content sum, budget = 0
    // NOTE: If tabDataBudgetInfo and contentSumByUserId are provided,
    // then the total = labor cost + content sum, and budget = labor budget + content.budget
  } = data;

  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);

  const projectUsersMap = useMemo(() => {
    if (projectUsers) {
      return _.keyBy(projectUsers, 'userId');
    }
    return {};
  }, [projectUsers]);

  const contentByUserId = useMemo(() => {
    const byUserId = {};
    if (contentSumByUserId && dataUnit === '$') {
      let unassignedBudget = 0;
      if (budgetInfo) {
        const receiptsInvoicesBudget = budgetInfo.budgets.find(
          budget => budget.label === BUDGET_TYPE_RECEIPTS_INVOICES
        );
        if (receiptsInvoicesBudget) {
          unassignedBudget = receiptsInvoicesBudget.value;
        }
      }

      byUserId[UNASSIGNED_USER_ID] = {
        total: 0,
        budget: unassignedBudget,
      };

      _.forEach(contentSumByUserId, (contentSum, userId) => {
        byUserId[userId] = {
          total: contentSum,
          budget: 0,
        };
      });
    }
    return byUserId;
  }, [budgetInfo, contentSumByUserId, dataUnit]);

  const hoursBudgetByUser = useMemo(() => {
    const byUser = {};
    if (
      tabDataBudgetInfo &&
      tabDataBudgetInfo.budgets &&
      tabDataBudgetInfo.userPayInfo
    ) {
      const userPayInfoMap = _.keyBy(tabDataBudgetInfo.userPayInfo, 'userId');
      const budgetByUserId = {};
      _.forEach(tabDataBudgetInfo.budgets, budget => {
        if (budget.label === BUDGET_TYPE_USER_LABOR_HOURS) {
          const userId = budget.context;
          budgetByUserId[userId] = budget;
        }
      });

      _.forEach(projectUsersMap, userInfo => {
        const payInfo = userPayInfoMap[userInfo.userId];
        const budget = budgetByUserId[userInfo.userId];
        if (payInfo) {
          byUser[userInfo.userId] = {
            ...payInfo,
            hours: budget ? budget.value : 0,
          };
        }
      });
    }
    return byUser;
  }, [projectUsersMap, tabDataBudgetInfo]);

  const unassignedLabor = useMemo(() => {
    let unassignedBudget = 0;
    if (tabDataBudgetInfo && tabDataBudgetInfo.budgets) {
      const unassignedLaborHours = tabDataBudgetInfo.budgets.find(
        budget => budget.label === BUDGET_TYPE_UNASSIGNED_LABOR_HOURS
      );
      if (unassignedLaborHours) {
        if (dataUnit === 'h') {
          unassignedBudget = unassignedLaborHours.value;
        } else if (dataUnit === '$') {
          const hourlyRate = Number(unassignedLaborHours.context);
          unassignedBudget = unassignedLaborHours.value * hourlyRate;
        }
      }
    }
    return {
      userId: UNASSIGNED_USER_ID,
      name: UNASSIGNED_USER_NAME,
      total: 0,
      budget: unassignedBudget,
    };
  }, [tabDataBudgetInfo, dataUnit]);

  const activeUserIdsMap = useMemo(() => {
    const userIdsMap = {};
    if (parentProjectDetails) {
      _.forEach(
        [
          ...parentProjectDetails.allowedToAdd,
          ...parentProjectDetails.allowedToEdit,
          ...parentProjectDetails.allowedToView,
        ],
        userId => {
          userIdsMap[userId] = true;
        }
      );
    }
    return userIdsMap;
  }, [parentProjectDetails]);

  const allUserIds = useMemo(() => {
    const userIds = [UNASSIGNED_USER_ID];
    if (projectUsers) {
      const sortedUsers = _.orderBy(
        projectUsers,
        [
          user => (activeUserIdsMap[user.userId] ? 1 : 0),
          user => user.username.toLowerCase(),
        ],
        ['desc', 'asc']
      );

      _.forEach(sortedUsers, user => {
        userIds.push(user.userId);
      });
    }
    return userIds;
  }, [activeUserIdsMap, projectUsers]);

  const laborByUserId = useMemo(() => {
    const byUser = {};
    if (unassignedLabor) {
      byUser[unassignedLabor.userId] = unassignedLabor;
    }

    if (dataUnit === 'h') {
      _.forEach(projectUsersMap, userInfo => {
        const userBudget = hoursBudgetByUser[userInfo.userId] || {};

        const name = getFullUserString(userInfo);

        const hoursWorked = timeManager.getHours({
          userIds: [userInfo.userId],
        });

        byUser[userInfo.userId] = {
          userId: userInfo.userId,
          name,
          total: hoursWorked,
          budget: userBudget.hours || 0,
        };
      });
    } else if (dataUnit === '$') {
      _.forEach(projectUsersMap, userInfo => {
        const userBudget = hoursBudgetByUser[userInfo.userId] || {};
        if (userBudget.payType === CREW_PAY_TYPES.VARIABLE_HOURLY) {
          const name = getFullUserString(userInfo);

          const hoursWorked = timeManager.getHours({
            userIds: [userInfo.userId],
          });

          const hourlyRate = calculateHourlyRate(userBudget);

          byUser[userInfo.userId] = {
            userId: userInfo.userId,
            name,
            total: hoursWorked * hourlyRate,
            budget: userBudget.hours * hourlyRate,
          };
        }
      });
    }

    return byUser;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUnit, hoursBudgetByUser, projectUsersMap, unassignedLabor]);

  const rows = useMemo(() => {
    const dataRows = [];
    _.forEach(allUserIds, userId => {
      const labor = laborByUserId[userId] || {};
      const content = contentByUserId[userId] || {};
      let total = labor.total || 0;
      let budget = labor.budget || 0;

      if (dataUnit === '$') {
        total += content.total || 0;
        budget += content.budget || 0;
      }

      const dataRow = calculateCompletePercentage({
        total,
        budget,
        numberFormat,
        overageIsGood,
        useColor: tabName !== 'Receipts/Bills',
      });

      if (!hideZeroRows || (hideZeroRows && (!!total || !!budget))) {
        dataRows.push({
          ...dataRow,
          userId,
          name: labor.name || getFullUserString(projectUsersMap[userId]),
        });
      }
    });

    return dataRows;
  }, [
    allUserIds,
    contentByUserId,
    dataUnit,
    hideZeroRows,
    laborByUserId,
    numberFormat,
    overageIsGood,
    projectUsersMap,
    tabName,
  ]);
  const onExportDataClick = () => {
    const dataForExport = [
      ['Project', parentProjectDetails.title],
      ['Date', `${new Date().toDateString()} ${new Date().toTimeString()}`],
      ['View', `${dialogTitle}${(tabName && ` > ${tabName}`) || ''}`],
      [],
      [
        `Username (Name)`,
        `Total`,
        `Budget`,
        `Variance (${dataUnit})`,
        `% Complete`,
      ],
    ];

    // Build data for export
    _.forEach(
      rows,
      ({ name, total, budget, varianceByUnit, percentCompleteValue }) => {
        dataForExport.push([
          name,
          total,
          budget,
          varianceByUnit,
          percentCompleteValue,
        ]);
      }
    );

    // Convert to CSV
    const csv = dataForExport
      .map(row => row.map(cell => `"${cell}"`).join(','))
      .join('\n');

    // Create doc and download
    const expsumdl = document.createElement('a');
    const file = new Blob([csv], { type: 'text/csv' });
    expsumdl.href = URL.createObjectURL(file);
    expsumdl.download = `${dialogTitle
      .toLowerCase()
      .replace(' ', '-')}_${moment().format('YYYYMMDDHHmmss')}.csv`;
    expsumdl.click();
  };

  const renderRow = (row, rowIndex, array) => {
    if (rowIndex >= DEFAULT_VISIBLE_ROWS && !showAll) {
      return null;
    }
    return (
      <React.Fragment key={row.userId}>
        <Grid
          item
          container
          justifyContent="space-between"
          className={classes.row}
        >
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body2"
              className={classes.cellTextLeft}
              style={{
                width: 'auto',
                paddingLeft: 4,
                display: 'inline-flex',
              }}
            >
              {row.name}
            </Typography>
            {row.userId !== UNASSIGNED_USER_ID &&
              !activeUserIdsMap[row.userId] && (
                <Tooltip title="Deactive user (ever on project)">
                  <InfoOutlinedIcon
                    style={{ fontSize: 16, color: '#999', marginLeft: 4 }}
                  />
                </Tooltip>
              )}
          </Grid>
          <Grid item xs={6} container direction="row" justifyContent="flex-end">
            <Grid item xs={3}>
              <Typography
                variant="body2"
                className={classes.cellTextCenter}
                style={{ color: row.fontColor }}
              >
                {row.total}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: row.fontColor,
              }}
            >
              {contentSumByUserId && row.userId !== UNASSIGNED_USER_ID ? (
                <Typography
                  variant="body2"
                  className={classes.cellTextCenter}
                  style={{ color: row.fontColor }}
                >
                  {row.budget}
                </Typography>
              ) : (
                <EditableBudget
                  editBudgetByUser={editBudgetByUser}
                  project={parentProjectDetails}
                  budget={numeral(row.budget).value()}
                  numberFormat={numberFormat}
                  nonEditableTextClass={classes.cellTextCenter}
                  budgetLabels={budgetLabels}
                  color={row.fontColor}
                  budgetInfo={budgetInfo}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                className={classes.cellTextCenter}
                style={{ color: row.fontColor }}
              >
                {row.varianceByUnit || 'See below'}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                className={classes.cellTextCenter}
                style={{ color: row.fontColor }}
              >
                {row.percentCompleteValue || 'See below'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {array.length > DEFAULT_VISIBLE_ROWS &&
          rowIndex === DEFAULT_VISIBLE_ROWS - 1 &&
          !showAll && (
            <Grid container justifyContent="center">
              <Button
                onClick={() => {
                  setShowAll(true);
                }}
                style={{
                  marginTop: 8,
                  marginBottom: 8,
                }}
                color="primary"
              >
                Show All {array.length} items...
              </Button>
            </Grid>
          )}
      </React.Fragment>
    );
  };

  return (
    <TabPanel value={tabValue} index={tabIndex}>
      <Grid container direction="column">
        <Grid item container className={`${classes.row} ${classes.headerRow}`}>
          <Grid item xs={6} container justifyContent="space-between">
            <Typography variant="body2" className={classes.tableHeading}>
              Username (Name)
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs={3}>
              <Typography variant="body2" className={classes.tableHeading}>
                Total
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                className={classes.tableHeading}
                style={{ minWidth: 80, textAlign: 'center' }}
              >
                Budget
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                className={classes.tableHeading}
                style={{ minWidth: 80, textAlign: 'center' }}
              >
                Variance ({dataUnit})
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                className={classes.tableHeading}
                style={{ minWidth: 80, textAlign: 'center' }}
              >
                % Complete
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          style={{
            marginBottom: 12,
          }}
        >
          {rows && rows.length > 0 ? (
            rows.map(renderRow)
          ) : (
            <Grid container justifyContent="center">
              <Typography className={classes.noData}>
                {_.capitalize(`No ${pluralWording} to show.`)}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button variant="text" color="primary" onClick={onExportDataClick}>
            Export data (.CSV)
          </Button>
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default ByUserTabPanel;
