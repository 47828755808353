import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  query GetCompanyLeads($companyId: String!, $nextToken: String) {
    getCompanyLeads(input: { companyId: $companyId, nextToken: $nextToken }) {
      items {
        contentStatus
        customerId
        address
        contentId
        jrnId
        title
        creatorId
        contentUrl
        description
        date
        endDate
        allowedToEdit
        allowedToAdd
        allowedToView
        usersEverOnJrn
        startDate
        labels
        latitude
        longitude
        type
        qboCustomerId
        budgets {
          ...BudgetFields
        }
      }
      nextToken
    }
  }
`;
