import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Grid, Typography, makeStyles } from '@material-ui/core';

import styles from './auth.styles';

import {
  MAX_CHARACTERS_IN_EMAIL,
  MAX_CHARACTERS_IN_PASSWORD,
} from '../../config/appDefaults';

import AuthTextField from './auth-text-field/auth-text-field';
import BigButton from './big-button';
import palette from '../../theme/palette';

const useStyles = makeStyles(styles);

const SignInForm = ({
  isProcessing,
  authError,
  username,
  password,
  onUsernameChange,
  onPasswordChange,
  onSignIn,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const [signinOnly, setSigninOnly] = useState(false);

  useEffect(() => {
    if (
      location.pathname === '/signin' ||
      location.pathname === '/signin/reset'
    ) {
      setSigninOnly(true);
    }
  }, [location]);

  const handleUsernameChange = event => {
    const passedUsername = event.target.value.replace(/\s/g, '');

    onUsernameChange(passedUsername, { requireValidation: true });
  };

  const handlePasswordChange = event => {
    const passedPassword = event.target.value;

    onPasswordChange(passedPassword, { requireValidation: true });
  };

  return (
    <>
      <Grid container item xs={12} className={classes.formBody}>
        <Grid item xs={12}>
          <AuthTextField
            name="username"
            label="Email or Username"
            value={username}
            maxLength={MAX_CHARACTERS_IN_EMAIL}
            errorText={authError?.username}
            onChange={handleUsernameChange}
          />
        </Grid>
        <Grid item xs={12}>
          <AuthTextField
            name="password"
            label="Password"
            value={password}
            type="password"
            maxLength={MAX_CHARACTERS_IN_PASSWORD}
            errorText={authError?.password}
            onChange={handlePasswordChange}
            onEnterKeyPress={onSignIn}
          />
        </Grid>
        {!!authError?.form && (
          <Grid item xs={12} className={classes.formErrorContainer}>
            <Typography className={classes.errorText}>
              {authError.form}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        className={classes.formActions}
      >
        <BigButton
          buttonText="Submit"
          fullWidth
          isProcessing={isProcessing}
          onClick={onSignIn}
        />
      </Grid>
      <Grid
        container
        item
        justifyContent="center"
        xs={12}
        style={{ marginTop: 24, color: palette.brandColorDarkGrey }}
      >
        Forget your password?&nbsp;
        <Link
          to={!signinOnly ? '/auth/reset' : '/signin/reset'}
          style={{ color: palette.brandColorPrimary, cursor: 'pointer' }}
        >
          Reset password
        </Link>
      </Grid>
    </>
  );
};

export default SignInForm;
