import gql from 'graphql-tag';

export default gql`
  query GetCompanyQuestions($companyId: String!) {
    getCompanyQuestions(companyId: $companyId) {
      items {
        __typename
        questionId
        questionType
        questionText
        answers
        isRequired
        managingCompanyId
        creatorId
      }
    }
  }
`;
