import gql from 'graphql-tag';

export default gql`
  query listMyProjectsForCalendarContext($contentType: String!) {
    listMyProjectsForCalendarContext(input: { contentType: $contentType }) {
      items {
        __typename
        contentId
        jrnId
        address
        contentUrl
        endDate
        startDate
        title
        type
      }
    }
  }
`;
