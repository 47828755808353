import React from 'react';
import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  Typography,
  Paper,
} from '@material-ui/core';
import ContentLoader from 'react-content-loader';

const InvitationCard = ({ invitationData }) => {
  // If error occurred
  if (invitationData === false) {
    return null;
  }

  let companyLogo = null;
  let companyName = null;

  if (invitationData) {
    companyLogo = invitationData.company && invitationData.company.companyLogo;
    companyName = invitationData.company && invitationData.company.companyName;
  }

  return (
    <Grid container item xs={12} style={{ marginBottom: 24 }}>
      {invitationData && (
        <Card style={{ width: '100%' }}>
          <CardHeader
            avatar={
              <Avatar
                alt="Company Logo"
                aria-label="company-logo"
                src={companyLogo}
                style={{ borderRadius: 4, height: 72, width: 72 }}
              >
                {!companyLogo && companyName.substr(0, 1)}
              </Avatar>
            }
            title={
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {companyName}
              </Typography>
            }
            subheader="has invited you to Level"
          />
        </Card>
      )}
      {!invitationData && (
        <Paper style={{ width: '100%', height: '100px' }}>
          <ContentLoader
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
          >
            <rect x="20" y="20" rx="0" ry="0" width="72" height="72" />
            <rect x="120" y="30" rx="0" ry="0" width="200" height="20" />
            <rect x="120" y="60" rx="0" ry="0" width="200" height="20" />
          </ContentLoader>
        </Paper>
      )}
    </Grid>
  );
};

export default InvitationCard;
