import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  query GetBudgetInfo($jrnId: String!) {
    getBudgetInfo(input: { jrnId: $jrnId }) {
      projectId
      budgets {
        ...BudgetFields
      }
      userPayInfo {
        userId
        department
        payType
        payRate
        laborBurdenPercentage
        vacaAccrualRate
      }
      subprojects {
        projectId
        budgets {
          ...BudgetFields
        }
        userPayInfo {
          userId
          department
          payType
          payRate
          laborBurdenPercentage
          vacaAccrualRate
        }
      }
    }
  }
`;
