import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Customer}
  query getCompanyCustomers($companyId: String!) {
    getCompanyCustomers(companyId: $companyId) {
      items {
        ...CustomerFields
      }
    }
  }
`;
