import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  DialogActions,
} from '@material-ui/core';
import { CONTENT_TYPE } from '../../../config/appDefaults';

const FinancialTypeExplanationDialog = ({ contentType, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        {(contentType === CONTENT_TYPE.RECEIPT ||
          contentType === CONTENT_TYPE.BILL) && (
          <>
            <Typography style={{ marginTop: 8, marginBottom: 8 }}>
              Select “Receipt” if the upload is proof of payment to a supplier
            </Typography>
            <Typography style={{ marginTop: 8, marginBottom: 8 }}>
              Select “Bill” if the upload is an unpaid invoice from a supplier
            </Typography>
          </>
        )}
        {(contentType === CONTENT_TYPE.INVOICE ||
          contentType === CONTENT_TYPE.PAYMENT) && (
          <>
            <Typography style={{ marginTop: 8, marginBottom: 24 }}>
              Select “Invoice” if the upload is a customer invoice.
            </Typography>
            <Typography style={{ marginBottom: 24 }}>
              Select “Payment” if the upload is proof of payment from a customer
              (e.g. sales receipt, check, or email).
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FinancialTypeExplanationDialog;
