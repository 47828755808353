import gql from 'graphql-tag';

export default gql`
  mutation UpdateUserAdmin(
    $companyId: String!
    $userId: ID!
    $username: String
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
    $role: String
    $department: String
    $payType: String
    $payRate: String
    $payrollId: String
    $overtimeRate: String
    $laborBurdenPercentage: String
    $vacaAccrualRate: String
    $vacaAccrualType: String
  ) {
    updateUserAdmin(
      input: {
        companyId: $companyId
        userId: $userId
        username: $username
        firstName: $firstName
        lastName: $lastName
        email: $email
        phoneNumber: $phoneNumber
        role: $role
        department: $department
        payType: $payType
        payRate: $payRate
        payrollId: $payrollId
        overtimeRate: $overtimeRate
        laborBurdenPercentage: $laborBurdenPercentage
        vacaAccrualRate: $vacaAccrualRate
        vacaAccrualType: $vacaAccrualType
      }
    ) {
      userId
      username
      firstName
      lastName
      email
      phoneNumber
      profilePic
      accessMode
      userType
      type
      role
      department
      payType
      payRate
      payrollId
      overtimeRate
      laborBurdenPercentage
      vacaAccrualRate
      vacaAccrualType
    }
  }
`;
