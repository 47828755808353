import React from 'react';
import {
  Collapse,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  OpenInNew as OpenInNewIcon,
} from '@material-ui/icons';
import _ from 'lodash';

import palette from '../../../theme/palette';

const BookkeepingAlertsReportAlertListItemTableCellTxnArray = ({
  transactions,
  expanded,
  onExpandedChange,
}) => {
  if (_.isEmpty(transactions)) {
    return null;
  }

  return (
    <Grid container direction="column">
      <Grid
        item
        container
        justifyContent="flex-start"
        alignItems="center"
        style={{
          paddingTop: 6,
          paddingLeft: 4,
          paddingBottom: 6,
        }}
      >
        <Typography
          variant="body1"
          style={{ color: 'inherit', fontWeight: 'inherit' }}
        >
          {transactions.length}
        </Typography>
        <IconButton
          size="small"
          onClick={onExpandedChange}
          style={{ marginLeft: 8, backgroundColor: palette.background.alt }}
        >
          {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {_.map(transactions, (item, index) => (
          <Grid
            item
            container
            alignItems="center"
            key={item.txnId}
            style={{
              paddingTop: 6,
              paddingLeft: 18,
              paddingBottom: 6,
              height: 38,
              ...(index % 2 === 0 && {
                backgroundColor: palette.brandColorLightGrey,
              }),
            }}
          >
            {!!item.qboTxnUrl && (
              <Tooltip
                title="View in QuickBooks Online"
                style={{ marginRight: 8, marginLeft: 4 }}
                PopperProps={{ style: { zIndex: 9999 } }}
              >
                <Link
                  href={item.qboTxnUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton size="small">
                    <OpenInNewIcon fontSize="small" />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
            <Typography
              variant="body1"
              style={{ color: 'inherit', fontWeight: 'inherit' }}
            >{`Transaction ID: ${item.txnId}`}</Typography>
          </Grid>
        ))}
      </Collapse>
    </Grid>
  );
};

export default BookkeepingAlertsReportAlertListItemTableCellTxnArray;
