import gql from 'graphql-tag';

export default gql`
  query GetUsersByIds($ids: [String!]!) {
    getUsersByIds(ids: $ids) {
      __typename
      items {
        __typename
        userId
        username
        firstName
        lastName
        profilePic
        accessMode
        userType
        type
      }
    }
  }
`;
