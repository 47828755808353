import axios from 'axios';
import uuid from 'uuid/v4';

const getStreetViewLocationAsFile = async ({ location }) => {
  // use fetch or axios to get the image and set it as the cover image
  // aspect ratio is 1:1 since we're getting a square
  const locationToUse = location.replace(' ', '+');
  const streetViewCallParams = {
    location: locationToUse,
    size: '500x500',
    key: process.env.REACT_APP_GOOGLE_STREETVIEW_API_KEY,
    return_error_code: true,
  };

  const getUrl = 'https://maps.googleapis.com/maps/api/streetview';
  const params = streetViewCallParams;

  try {
    const response = await axios.get(getUrl, {
      params,
      responseType: 'blob',
    });
    const mimeType = response.headers['content-type'];
    return new File([response.data], `${uuid()}.jpeg`, {
      type: mimeType,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('streetview call err: ', err);
    return null;
  }
};

export { getStreetViewLocationAsFile };
