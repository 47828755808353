import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ButtonBase,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { Description as BillIcon } from '@material-ui/icons';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import _ from 'lodash';

import { asCurrency } from '../dashboard/dashboard.utils';
import { monetaryRender } from '../../helpers';
import {
  GLOBAL_EXPENSE_STATUS,
  GLOBAL_EXPENSE_STATUS_LABEL,
} from '../../config/appDefaults';

const GlobalPaymentBillSplit = ({
  classes,
  content,
  selectable,
  userId,
  managingCompanyInfo,
  onViewGlobalItem,
}) => {
  const { amount, description, globalExpense: globalBill } = content;

  const isAllowedToViewGlobalBill = useMemo(() => {
    if (managingCompanyInfo.isCompanyAdmin) {
      return true;
    }

    if (globalBill && globalBill.creatorId === userId) {
      return true;
    }

    return false;
  }, [globalBill, managingCompanyInfo, userId]);

  const contentUrl = _.replace(globalBill.contentUrl, '.pdf', '.jpg');
  const globalBillAmount = globalBill?.amount?.value || 0;
  const globalBillBalance = globalBill?.balance
    ? globalBill.balance.value
    : globalBillAmount;

  return (
    <Grid
      container
      direction="column"
      className={
        selectable && onViewGlobalItem && isAllowedToViewGlobalBill
          ? classes.selectableBillSplitContainer
          : classes.billSplitContainer
      }
      style={{ position: 'relative' }}
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ minHeight: 88 }}
      >
        <Grid item xs={9}>
          <ListItem>
            <ListItemAvatar>
              {isAllowedToViewGlobalBill && contentUrl ? (
                <Avatar alt="Bill Cover Image" src={contentUrl} />
              ) : (
                <Avatar>
                  <BillIcon />
                </Avatar>
              )}
            </ListItemAvatar>
            {isAllowedToViewGlobalBill && (
              <ListItemText
                primary={`Bill No.: ${globalBill.documentNumber}`}
                secondary={
                  <>
                    {globalBill.contentStatus === GLOBAL_EXPENSE_STATUS.PAID ||
                    globalBill.contentStatus ===
                      GLOBAL_EXPENSE_STATUS.OVER_PAID ? (
                      <Typography variant="caption">
                        Status:{' '}
                        {GLOBAL_EXPENSE_STATUS_LABEL[globalBill.contentStatus]}
                      </Typography>
                    ) : (
                      <Typography variant="caption">
                        Remaining balance:{' '}
                        {monetaryRender({
                          value: globalBillBalance,
                          withDecimals: true,
                        })}
                      </Typography>
                    )}
                    <br />
                    <Typography variant="caption">
                      Due on:{' '}
                      {moment(globalBill.endDate).format('MMM DD, YYYY')}
                    </Typography>
                  </>
                }
              />
            )}
            {!isAllowedToViewGlobalBill && (
              <ListItemText primary="Other Bill" />
            )}
          </ListItem>
        </Grid>
        <Grid
          container
          item
          xs={3}
          justifyContent="flex-end"
          style={{ paddingRight: 16 }}
        >
          {asCurrency((amount && amount.value) || 0)}
        </Grid>
        {!!description && (
          <Grid item xs={12}>
            <div className={classes.descriptionWrapper}>
              {ReactHtmlParser(description)}
            </div>
          </Grid>
        )}
      </Grid>
      {selectable && onViewGlobalItem && (
        <Tooltip
          title={
            isAllowedToViewGlobalBill
              ? 'View this bill'
              : 'You do not have permission to view this bill'
          }
        >
          {isAllowedToViewGlobalBill ? (
            <ButtonBase
              className={classes.selectableButton}
              onClick={() => {
                onViewGlobalItem(globalBill);
              }}
            />
          ) : (
            <div className={classes.selectableButton} />
          )}
        </Tooltip>
      )}
    </Grid>
  );
};

const mapStateToProps = state => ({
  userId: state.userInfo && state.userInfo.userId,
  managingCompanyInfo: state.appState.managingCompanyInfo,
});

export default connect(mapStateToProps)(GlobalPaymentBillSplit);
