import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Grid, TextField, IconButton } from '@material-ui/core';
import { Settings as SettingsIcon } from '@material-ui/icons';
import {
  REPORT_PERIOD,
  ACCOUNTING_METHOD,
  SCOREBOARD_NAME,
} from '../../../config/appDefaults';
import {
  DashboardSettings,
  DashboardControlBar,
  DashboardControlBarSelect,
  DashboardSettingsTargets,
  DashboardSettingsReportData,
} from '../../dashboard';

function ProfitabilityControlBar({
  title,
  targetData,
  reportPeriod,
  accountingMethod,
  setReportPeriod,
  managingCompanyInfo,
}) {
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const handleSettingsClick = () => {
    setShowSettingsModal(true);
  };

  const mappedAccountingMethod = _.find(
    ACCOUNTING_METHOD,
    ({ key }) => key === accountingMethod
  );

  const accountingMethodDisplay = mappedAccountingMethod
    ? mappedAccountingMethod.displayValue
    : ' ';

  const settingsSections = [
    {
      label: 'Report Data',
      component: DashboardSettingsReportData,
      sectionProps: {
        scoreboardName: SCOREBOARD_NAME.PROFITABILITY,
        accountingMethod,
      },
    },
  ];

  if (managingCompanyInfo && managingCompanyInfo.isCompanyBookkeeper) {
    settingsSections.push({
      label: 'Targets',
      component: DashboardSettingsTargets,
      sectionProps: {
        targetsToEdit: targetData,
      },
    });
  }

  return (
    <>
      <DashboardControlBar title={title}>
        <Grid item style={{ minWidth: 150 }}>
          <TextField
            label="Accounting Method"
            value={accountingMethodDisplay}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item style={{ minWidth: 150 }}>
          <DashboardControlBarSelect
            label="Report Period"
            options={REPORT_PERIOD}
            selected={reportPeriod}
            onChange={setReportPeriod}
          />
        </Grid>
        <Grid item>
          <IconButton
            color="primary"
            variant="contained"
            onClick={handleSettingsClick}
          >
            <SettingsIcon />
          </IconButton>
        </Grid>
      </DashboardControlBar>
      {showSettingsModal && (
        <DashboardSettings
          title={`${title} Scoreboard Settings`}
          sections={settingsSections}
          handleClose={() => setShowSettingsModal(false)}
        />
      )}
    </>
  );
}

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(ProfitabilityControlBar);
