import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles(theme => ({
  errorMessageWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    color: theme.palette.brandColorError,
  },
  errorMessageIcon: {
    marginRight: 2,
  },
}));

const ErrorMessage = props => {
  const { errorMessage } = props;
  const classes = useStyles();

  if (errorMessage) {
    return (
      <div className={classes.errorMessageWrapper}>
        <ErrorOutlineIcon
          fontSize="inherit"
          className={classes.errorMessageIcon}
        />
        {errorMessage}
      </div>
    );
  }
  // if there's no error then dont show anything
  return null;
};

export default ErrorMessage;
