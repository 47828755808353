import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import moment from 'moment';

import ReactHtmlParser from 'react-html-parser';
import { Link, useRouteMatch } from 'react-router-dom';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import PersonIcon from '@material-ui/icons/Person';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import PlayCircleFilledOutlinedIcon from '@material-ui/icons/PlayCircleFilledOutlined';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import TaskStatusChangeDialog from './taskStatusChangeDialog';
import ContentGallery from './contentGallery';
import TaskCompletionDialog from '../../TaskCompletionDialog/taskCompletionDialog';
import TaskDisplayDialog from '../../TaskCompletionDialog/taskDisplayDialog';
import { renderDateString } from '../../../helpers/renderDateString';
import { daysAfterEpoch, utcADate } from '../../../helpers/index';
import {
  adminTaskStatusesForAdmins,
  completionTypesAsArray,
} from '../../../config/appDefaults';
import palette from '../../../theme/palette';

const useStyles = makeStyles(theme => ({
  taskWrapper: {
    position: 'relative',
    padding: theme.spacing(1),
  },
  taskDescriptionWrapper: {
    paddingRight: '50px',
  },
  taskDescription: {
    color: theme.palette.brandColorPrimary,
    display: 'flex',
    marginRight: '5px',
    alignItems: 'center',
  },
  taskInfoWrapper: {
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  moreButtonWrapper: {
    position: 'absolute',
    top: 0,
    right: theme.spacing(1),
  },
  viewMoreButton: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  taskLabelInfo: {
    color: theme.palette.brandColorDarkGrey,
  },
  taskCheckboxIconWrapper: {
    color: '#aaa',
    display: 'flex',
    fontSize: 34,
  },
  incompleteTaskStatus: {
    color: theme.palette.taskStatusIncomplete,
  },
  inprogressTaskStatus: {
    color: theme.palette.taskStatusInProgress,
  },
  pendingapprovalTaskStatus: {
    color: theme.palette.taskStatusPendingApproval,
  },
  completedTaskStatus: {
    color: theme.palette.taskStatusCompleted,
  },
  completionTypeIcon: {
    color: '#aaa',
  },
  completionInfoResponse: {
    background: '#acd6ac',
    padding: '5px',
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: theme.spacing(2),
    borderRadius: '10px',
  },
  pendingCompletionInfoResponse: {
    background: 'orange',
    padding: '5px',
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: theme.spacing(2),
    borderRadius: '10px',
  },
  levelParsedHtmlDescription: {
    padding: theme.spacing(2),
  },
  toggleTaskDetails: {
    backgroundColor: palette.background.alt,
    marginBottom: theme.spacing(1),
  },
}));

const ContentTask = props => {
  const {
    allUsers,
    contentPiece,
    renderMenu,
    renderMore,
    setTaskStatus,
    projectCanEdit,
    isThisExtTask,
    projectCanAdd,
    parentInfo,
    templateInfo,
  } = props;

  const { url } = useRouteMatch();

  const classes = useStyles();
  const [openTaskStatusDialog, setOpenTaskStatusDialog] = useState(false);
  const [
    taskCompletionDialogOptions,
    setTaskCompletionDialogOptions,
  ] = useState({
    open: false,
  });
  const [openTaskDisplayDialog, setOpenTaskDisplayDialog] = useState(false);

  const [showDesription, setShowDesription] = useState(false);
  const [showFullExtendedTask, setShowFullExtendedTask] = useState(false);
  const [hoverCheckbox, setHoverCheckbox] = useState(false);

  const setDescriptionState = () => {
    setShowDesription(!showDesription);
  };

  const assignedTo = contentPiece.assignedTo
    ? _.find(allUsers, { userId: contentPiece.assignedTo })
    : null;
  const statusClass = contentPiece.contentStatus
    ? contentPiece.contentStatus.replace('-', '')
    : '';

  const openChangeTaskStatusDialog = () => {
    if (projectCanAdd || projectCanEdit) {
      setOpenTaskStatusDialog(true);
    }
  };

  const handleFullExtendedTask = () => {
    setShowFullExtendedTask(!showFullExtendedTask);
    setShowDesription(!showDesription);
  };

  const openConfirmTaskCompletionDialog = wantToSetItTo => {
    if (projectCanAdd || projectCanEdit) {
      setTaskCompletionDialogOptions({
        statusToSet: wantToSetItTo,
        open: true,
      });
    }
  };

  const openConfirmTaskDisplayDialog = () => {
    setOpenTaskDisplayDialog(true);
  };

  const figureOutTaskCompleteAction = requestedStatus => {
    // things to take into account:
    //  - if it's a requireAdmin task
    //  - if it has a completionType requirement

    // if view-only, do nothing
    if (!(projectCanAdd || projectCanEdit)) return;
    const userIsAdmin = projectCanEdit;
    // INFO vars
    const { requireAdmin, contentStatus } = contentPiece;
    const hasCompletionType =
      contentPiece.completionType && contentPiece.completionType !== 'none';
    // FLAGS
    let wantToSetItTo;

    // if they passed a status make sure it's allowed
    if (requestedStatus) {
      wantToSetItTo = requestedStatus;
      if (requireAdmin && !userIsAdmin && wantToSetItTo === 'completed') {
        // make sure non-admin user didn't somehow try to complete
        wantToSetItTo = 'pending-approval';
      }
    } else {
      // if there was no requestedStatus passed, that means it came from a tap of the iconButton so figure it out for them
      wantToSetItTo = 'completed';
      // allows for normal task to be set back to incomplete
      if (!hasCompletionType && !requireAdmin) {
        wantToSetItTo =
          contentStatus === 'completed' ? 'incomplete' : 'completed';
      }

      if (requireAdmin && !userIsAdmin) {
        wantToSetItTo = 'pending-approval';
      }
    }

    if (
      hasCompletionType &&
      (wantToSetItTo === 'completed' || wantToSetItTo === 'pending-approval')
    ) {
      openConfirmTaskCompletionDialog(wantToSetItTo);
    } else {
      setTaskStatus(contentPiece, wantToSetItTo);
    }
  };

  const handleTaskStatusDialogClosing = value => {
    setOpenTaskStatusDialog(false);
    // if nothing is passed it means they closed the dialog without making a choice so do nothing
    if (value) {
      // need to figure our what we want to do with this new requested status
      figureOutTaskCompleteAction(value);
    }
  };

  const handleConfirmTaskCompletionDialogClosing = () => {
    setTaskCompletionDialogOptions({
      ...taskCompletionDialogOptions,
      open: false,
    });
  };

  const handleConfirmTaskDisplayDialogClosing = () => {
    setOpenTaskDisplayDialog(false);
  };

  const handleSubmitOfTaskCompletion = (
    contentStatus,
    passedCompletionInfo,
    originalFilesToUpload
  ) => {
    if (projectCanAdd || projectCanEdit) {
      return setTaskStatus(
        contentPiece,
        contentStatus,
        passedCompletionInfo,
        originalFilesToUpload
      );
    }
    return null;
  };

  const thereIsTextResponse =
    contentPiece.completionInfo && contentPiece.completionInfo.textResponse;
  const thereIsMediaInfo =
    contentPiece.completionInfo &&
    contentPiece.completionInfo.mediaInfo &&
    contentPiece.completionInfo.mediaInfo.length > 0;
  const numOfMediaInfo = thereIsMediaInfo
    ? contentPiece.completionInfo.mediaInfo.length
    : 0;

  let contentUrlAsArray = [];
  if (typeof contentPiece.contentUrl === 'string') {
    try {
      contentUrlAsArray = JSON.parse(contentPiece.contentUrl);
    } catch (error) {
      contentUrlAsArray = [];
    }
  }

  let completionInfoMsgWording = '';
  if (contentPiece.contentStatus === 'pending-approval') {
    completionInfoMsgWording = 'Pending ';
  } else if (contentPiece.contentStatus !== 'completed') {
    completionInfoMsgWording = 'Previous ';
  }

  const renderDate = () => {
    let dateString;
    if (!contentPiece.endDate || contentPiece.endDate < contentPiece.date) {
      if (templateInfo) {
        const diffStartFromStart = daysAfterEpoch(contentPiece.date) + 1;
        dateString = `Day ${diffStartFromStart} @ ${moment(contentPiece.date)
          .utc()
          .format('h:mma')}`;
      } else {
        dateString = renderDateString(
          contentPiece.date,
          null,
          'MMM D, YYYY @ h:mma'
        );
      }
      return (
        <>
          <span className={classes.taskLabel}>Due Date:</span>
          <span className={classes.taskLabelInfo}>{` ${dateString}`}</span>
        </>
      );
    }

    if (templateInfo) {
      const diffStartFromStart = daysAfterEpoch(contentPiece.startDate) + 1;
      const diffEndFromStart = daysAfterEpoch(contentPiece.endDate) + 1;

      if (diffStartFromStart === diffEndFromStart) {
        if (contentPiece.startDate === contentPiece.endDate) {
          dateString = `Due: Day ${diffStartFromStart} @ ${utcADate(
            contentPiece.startDate
          )}`;
        } else {
          dateString = `Day ${diffStartFromStart} from ${utcADate(
            contentPiece.startDate
          )} - ${utcADate(contentPiece.endDate)}`;
        }
      } else {
        dateString = `Day ${diffStartFromStart} @ ${utcADate(
          contentPiece.startDate
        )} - Day ${diffEndFromStart} @ ${utcADate(contentPiece.endDate)}`;
      }
    } else {
      dateString = ` ${renderDateString(
        contentPiece.startDate,
        null,
        'MMM D, YYYY'
      )} - ${renderDateString(contentPiece.endDate, null, 'MMM D, YYYY')}`;
    }

    return (
      <>
        <span className={classes.taskLabelInfo}>{dateString}</span>
      </>
    );
  };

  const renderButtonForTaskAction = (options = {}) => {
    const renderCompletionTypeIcon = () => {
      switch (contentPiece.completionType) {
        case 'pdf':
          return <PictureAsPdfIcon className={classes.completionTypeIcon} />;
        case 'image':
          return <PhotoLibraryIcon className={classes.completionTypeIcon} />;
        case 'video':
          return (
            <VideocamOutlinedIcon className={classes.completionTypeIcon} />
          );
        case 'admin':
          return <PersonIcon className={classes.completionTypeIcon} />;
        case 'text':
          return (
            <BorderColorOutlinedIcon className={classes.completionTypeIcon} />
          );
        default:
          return <span>?</span>;
      }
    };

    const renderTaskCheckbox = () => {
      if (contentPiece.contentStatus === 'completed') {
        return (
          <span className={classes.taskCheckboxIconWrapper}>
            <CheckCircleOutlineIcon
              htmlColor={palette.taskStatusCompleted}
              fontSize="inherit"
            />
          </span>
        );
      }
      if (hoverCheckbox) {
        return (
          <span
            className={clsx(
              classes.taskCheckboxIconWrapper,
              options.format === 'list' && classes[`${statusClass}TaskStatus`]
            )}
          >
            <CheckCircleOutlineIcon htmlColor="inherit" fontSize="inherit" />
          </span>
        );
      }
      if (contentPiece.contentStatus === 'pending-approval') {
        return (
          <span
            className={clsx(
              classes.taskCheckboxIconWrapper,
              classes[`${statusClass}TaskStatus`]
            )}
          >
            <RadioButtonUncheckedIcon htmlColor="inherit" fontSize="inherit" />
          </span>
        );
      }
      return (
        <span
          className={clsx(
            classes.taskCheckboxIconWrapper,
            options.format === 'list' && classes[`${statusClass}TaskStatus`]
          )}
        >
          <RadioButtonUncheckedIcon htmlColor="inherit" fontSize="inherit" />
        </span>
      );
    };

    let completionTypeTooltipWording;
    switch (contentPiece.completionType) {
      case 'pdf':
        completionTypeTooltipWording = 'Requires a PDF to complete';
        break;
      case 'image':
        completionTypeTooltipWording = 'Requires 1 or more images to complete';
        break;
      case 'video':
        completionTypeTooltipWording = 'Requires a video to complete';
        break;
      case 'admin':
        completionTypeTooltipWording = `Requires an admin's approval after submission`;
        break;
      case 'text':
        completionTypeTooltipWording =
          'Requires entering some notes to complete';
        break;
      default:
        completionTypeTooltipWording = 'Unknown requirement. Refresh page.';
        break;
    }
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {contentPiece.completionType &&
          contentPiece.completionType !== 'none' &&
          contentPiece.contentStatus !== 'completed' &&
          contentPiece.contentStatus !== 'pending-approval' && (
            <Grid item>
              <Tooltip title={completionTypeTooltipWording}>
                {renderCompletionTypeIcon()}
              </Tooltip>
            </Grid>
          )}
        <Grid
          item
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {templateInfo ? null : (
            <IconButton
              onMouseEnter={() => setHoverCheckbox(true)}
              onMouseLeave={() => setHoverCheckbox(false)}
              onClick={() => figureOutTaskCompleteAction()}
            >
              {renderTaskCheckbox()}
            </IconButton>
          )}
        </Grid>
      </Grid>
    );
  };

  let taskStatusWording = '';
  const taskStatusWordingInfo = _.find(adminTaskStatusesForAdmins, {
    value: contentPiece.contentStatus,
  });
  if (taskStatusWordingInfo && taskStatusWordingInfo.label) {
    taskStatusWording = taskStatusWordingInfo.label;
  }

  let useThisBg;
  let useThisFontColor;
  let useThisLineColor;
  let useThisBorderWidth;
  switch (contentPiece.contentStatus) {
    case 'incomplete':
    case 'in-progress':
    case 'pending-approval':
      useThisBg = '#fff';
      useThisFontColor = '#666';
      useThisLineColor = '#ccc';
      useThisBorderWidth = 1;
      break;
    case 'completed':
    default:
      useThisBg = palette.brandColorGreenBg;
      useThisFontColor = '#333';
      useThisLineColor = palette.brandColorGreen;
      useThisBorderWidth = 0;
  }

  if (!_.includes(completionTypesAsArray, contentPiece.completionType)) {
    // TODO: when we implement ServiceWorkers this will need to refresh and update it
    return (
      <Grid container direction="column" className={classes.taskWrapper}>
        <Grid item>
          <Typography variant="h4" gutterBottom>
            Task Not Available
          </Typography>
          <p>
            This completion type is not yet supported by your version of the
            app. Please refresh your browser and try viewing it again.
            <Button onClick={() => window.location.reload()}>
              Click here to Refresh
            </Button>
          </p>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container direction="column" className={classes.taskWrapper}>
        <Grid
          item
          style={{ overflow: 'hidden', maxWidth: '100%', hyphens: 'auto' }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {isThisExtTask && !showFullExtendedTask && (
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{
                  flex: 1,
                  flexWrap: 'nowrap',
                  maxWidth: '100%',
                }}
              >
                <Grid item style={{ flex: 0 }}>
                  <IconButton onClick={handleFullExtendedTask}>
                    <ChevronRightIcon />
                  </IconButton>
                </Grid>
                <Grid item style={{ flex: 1, minWidth: 0 }}>
                  <Typography
                    variant="h4"
                    onClick={handleFullExtendedTask}
                    style={{ cursor: 'pointer' }}
                  >
                    {contentPiece.title}
                  </Typography>
                </Grid>
                <Grid item>
                  {renderButtonForTaskAction({ format: 'list' })}
                </Grid>
                <Grid item style={{ flex: 0 }}>
                  {renderMenu()}
                  {renderMore(true)}
                </Grid>
              </Grid>
            )}

            {(!isThisExtTask || showFullExtendedTask) && (
              <>
                {contentPiece.subtype === 'extended' ? (
                  <Link
                    to={{
                      pathname: `${url}/${contentPiece.contentId}`,
                      state: { lastTabIndex: 1 },
                    }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      item
                      className={classes.taskDescriptionWrapper}
                    >
                      <Tooltip title="Go to sub-tasks...">
                        <Typography variant="h3">
                          <span className={classes.taskDescription}>
                            {contentPiece.subtype === 'extended' && (
                              <AddCircleOutlineIcon
                                style={{ marginRight: 5 }}
                              />
                            )}
                            {contentPiece.title}
                            {!contentPiece.title &&
                              contentPiece.description && (
                                <div className="levelParsedHtml">
                                  {ReactHtmlParser(contentPiece.description)}
                                </div>
                              )}
                          </span>
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Link>
                ) : (
                  <Grid item className={classes.taskDescriptionWrapper}>
                    {isThisExtTask && showFullExtendedTask && (
                      <Grid item>
                        <IconButton onClick={handleFullExtendedTask}>
                          <ChevronRightIcon
                            style={{ transform: 'rotate(90deg)' }}
                          />
                        </IconButton>
                      </Grid>
                    )}
                    <Typography variant="h3">
                      <span className={classes.taskDescription}>
                        {contentPiece.subtype === 'extended' && (
                          <AddCircleOutlineIcon />
                        )}
                        {contentPiece.title}
                        {!contentPiece.title && contentPiece.description && (
                          <div className="levelParsedHtml">
                            {ReactHtmlParser(contentPiece.description)}
                          </div>
                        )}
                      </span>
                    </Typography>
                  </Grid>
                )}

                <Grid item className={classes.moreButtonWrapper}>
                  {renderMenu()}
                  {renderMore()}
                </Grid>
              </>
            )}
          </Grid>
          {(!isThisExtTask || showFullExtendedTask) && (
            <>
              <Grid item style={{ flex: 1 }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Grid
                      container
                      className={classes.taskInfoWrapper}
                      direction="column"
                    >
                      <Grid item>{renderDate()}</Grid>
                      <Grid item>
                        <div style={{ marginTop: 4 }}>
                          <span className={classes.taskLabel}>
                            Assigned To:
                          </span>
                          <span className={classes.taskLabelInfo}>
                            {assignedTo
                              ? ` ${assignedTo.username}`
                              : ' unassigned'}
                          </span>
                        </div>
                      </Grid>
                      <Grid item>
                        <span className={classes.taskLabel}>Status:</span>
                        <Button
                          size="small"
                          onClick={openChangeTaskStatusDialog}
                          disabled={!!templateInfo}
                        >
                          <span className={classes[`${statusClass}TaskStatus`]}>
                            {taskStatusWording}
                          </span>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid item style={{ flex: 0 }}>
                      {renderButtonForTaskAction()}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          {contentPiece.completionType !== 'none' &&
            (thereIsTextResponse || thereIsMediaInfo) && (
              <Grid
                item
                className={classes.completionInfoResponse}
                style={{
                  background: useThisBg,
                  border: `${useThisBorderWidth}px solid ${useThisLineColor}`,
                  color: useThisFontColor,
                }}
              >
                {contentPiece.completionInfo.textResponse && (
                  <Grid style={{ padding: '5px', color: useThisFontColor }}>
                    {completionInfoMsgWording}Completion Info:{' '}
                    {contentPiece.completionInfo.textResponse}
                  </Grid>
                )}
                {(contentPiece.completionType === 'image' ||
                  contentPiece.completionType === 'video' ||
                  contentPiece.completionType === 'pdf') && (
                  <>
                    {numOfMediaInfo ? (
                      <Grid style={{ textAlign: 'center' }}>
                        <Button
                          onClick={openConfirmTaskDisplayDialog}
                          style={{
                            color: useThisFontColor,
                          }}
                        >
                          View{' '}
                          {contentPiece.completionType === 'image' && (
                            <>
                              {numOfMediaInfo > 1 ? (
                                <>
                                  {numOfMediaInfo} Images
                                  <PhotoLibraryIcon
                                    style={{
                                      marginLeft: 10,
                                      color: useThisFontColor || null,
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  Image
                                  <ImageOutlinedIcon
                                    style={{
                                      marginLeft: 10,
                                      color: useThisFontColor || null,
                                    }}
                                  />
                                </>
                              )}
                            </>
                          )}
                          {contentPiece.completionType === 'video' && (
                            <>
                              Video
                              <PlayCircleFilledOutlinedIcon
                                style={{
                                  marginLeft: 10,
                                  color: useThisFontColor || null,
                                }}
                              />
                            </>
                          )}
                          {contentPiece.completionType === 'pdf' && (
                            <>
                              PDF
                              <PictureAsPdfIcon
                                style={{
                                  marginLeft: 10,
                                  color: useThisFontColor || null,
                                }}
                              />
                            </>
                          )}
                        </Button>
                      </Grid>
                    ) : (
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          textAlign: 'center',
                          padding: 10,
                        }}
                      >
                        <Grid item>
                          {contentPiece.completionType === 'image' && (
                            <ImageOutlinedIcon
                              style={{
                                marginRight: 5,
                                color: useThisFontColor || null,
                              }}
                            />
                          )}
                          {contentPiece.completionType === 'video' && (
                            <PlayCircleFilledOutlinedIcon
                              style={{
                                marginRight: 5,
                                color: useThisFontColor || null,
                              }}
                            />
                          )}
                          {contentPiece.completionType === 'pdf' && (
                            <PictureAsPdfIcon
                              style={{
                                marginRight: 5,
                                color: useThisFontColor || null,
                              }}
                            />
                          )}
                        </Grid>
                        <Grid item>No confirmation content available.</Grid>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            )}
        </Grid>
      </Grid>
      <Grid
        container
        style={{ width: '100%', paddingLeft: 5, paddingRight: 5 }}
      >
        <Grid item style={{ width: '100%' }}>
          {!isThisExtTask && (
            <>
              {contentPiece.title &&
                (contentPiece.description || contentUrlAsArray.length > 0) && (
                  <Grid item>
                    <Button
                      className={classes.toggleTaskDetails}
                      onClick={setDescriptionState}
                    >
                      {showDesription ? 'Hide ' : 'View '}
                      Task Details
                    </Button>
                  </Grid>
                )}
            </>
          )}
          {showDesription &&
            (!!contentPiece.description || contentUrlAsArray.length > 0) && (
              <Grid container>
                <Grid item className={classes.levelParsedHtmlDescription}>
                  <div className="levelParsedHtml">
                    {ReactHtmlParser(contentPiece.description)}
                  </div>
                </Grid>
                <Grid item style={{ width: '100%' }}>
                  {contentUrlAsArray.length > 0 && (
                    <ContentGallery fromTask contentPiece={contentPiece} />
                  )}
                </Grid>
              </Grid>
            )}
        </Grid>
      </Grid>

      {openTaskStatusDialog && (
        <TaskStatusChangeDialog
          isAdmin={projectCanEdit}
          contentPiece={contentPiece}
          onClose={handleTaskStatusDialogClosing}
        />
      )}

      {taskCompletionDialogOptions && taskCompletionDialogOptions.open && (
        <TaskCompletionDialog
          statusToSet={taskCompletionDialogOptions.statusToSet}
          contentPiece={contentPiece}
          isProjectAdmin={projectCanEdit}
          onSubmit={handleSubmitOfTaskCompletion}
          onCancel={handleConfirmTaskCompletionDialogClosing}
          parentInfo={parentInfo}
        />
      )}
      {openTaskDisplayDialog && (
        <TaskDisplayDialog
          contentPiece={contentPiece}
          open={openTaskDisplayDialog}
          onCancel={handleConfirmTaskDisplayDialogClosing}
        />
      )}
    </>
  );
};

export default ContentTask;
