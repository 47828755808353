import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import ContentLoader from 'react-content-loader';
import Paper from '@material-ui/core/Paper';

import ListOfVendorsHeader, {
  LIST_TYPE,
  SORT_TYPE,
} from './list-of-vendors-header';
import VendorItem from './vendor-item';
import AddVenderDialog from './add-vendor-dialog';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: `${theme.spacing(1)}px auto 0 auto`,
    width: '100%',
  },
}));

const ListOfVendorsView = props => {
  const {
    vendors,
    allVendors,
    onVendorClick,
    refetchVendorList,
    idOfVendorBeingViewed,
    selectedTab,
    setSelectedTab,
    loading,
    vendorSort,
  } = props;

  const classes = useStyles();
  const [useThisData, setUseThisData] = useState([]);
  const [selectedSortOption, setSelectedSortOption] = useState(
    vendorSort || SORT_TYPE.NAME_ASC
  );
  const [isFiltered, setIsFiltered] = useState(false);
  const [showAddVendorDialog, setShowAddVendorDialog] = useState({
    open: false,
    vendor: null,
  });

  const sortedVendors = useMemo(() => {
    const vendorNameAsLowercase = vendor => vendor.name.toLowerCase();
    if (vendors) {
      let sortWhat;
      let sortHow;

      switch (selectedSortOption) {
        case SORT_TYPE.NAME_ASC:
          sortWhat = [vendorNameAsLowercase];
          sortHow = ['asc'];
          break;
        case SORT_TYPE.NAME_DECS:
          sortWhat = [vendorNameAsLowercase];
          sortHow = ['desc'];
          break;
        default:
          sortWhat = [vendorNameAsLowercase];
          sortHow = ['asc'];
      }

      return _.orderBy(vendors, sortWhat, sortHow);
    }

    return [];
  }, [selectedSortOption, vendors]);

  const handleAddButtonClick = () => {
    setShowAddVendorDialog({ open: true, vendor: null });
  };

  const handleEditButtonClick = vendor => {
    setShowAddVendorDialog({ open: true, vendor });
  };

  const renderLoading = () => {
    const howMany = [1, 2, 3, 4, 5];
    return howMany.map(key => (
      <Grid item key={key} style={{ width: '100%' }}>
        <Paper className={classes.paper}>
          <ContentLoader
            height={112}
            width={300}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            style={{ maxHeight: 112, maxWidth: 300 }}
          >
            <circle cx="63" cy="56" r="40" />
            <rect x="120" y="40" rx="0" ry="0" width="168" height="18" />
            <rect x="120" y="65" rx="0" ry="0" width="120" height="12" />
          </ContentLoader>
        </Paper>
      </Grid>
    ));
  };

  let wording;
  switch (selectedTab) {
    case LIST_TYPE.ACTIVE_VENDORS:
      wording = 'vendors';
      break;
    case LIST_TYPE.ARCHIVED_VENDORS:
      wording = 'archived vendors';
      break;
    default:
      wording = 'items';
  }

  return (
    <Grid
      item
      container
      alignItems="center"
      direction="column"
      spacing={2}
      style={{ padding: 16 }}
    >
      <ListOfVendorsHeader
        selectedTab={selectedTab}
        selectedSortOption={selectedSortOption}
        vendors={sortedVendors}
        refetchVendorList={refetchVendorList}
        onAddButtonClick={handleAddButtonClick}
        onFilteredVendorsChange={setUseThisData}
        onIsFilteredChange={setIsFiltered}
        onSortOptionChange={setSelectedSortOption}
        onTabChange={setSelectedTab}
      />
      {loading ? (
        renderLoading()
      ) : (
        <Grid item style={{ width: '100%' }}>
          {_.isEmpty(useThisData) && isFiltered && (
            <Typography variant="h5" align="center" style={{ padding: 24 }}>
              No search results.
            </Typography>
          )}
          {_.isEmpty(useThisData) && !isFiltered && (
            <Typography variant="h5" align="center" style={{ padding: 24 }}>
              You have no {wording}.
            </Typography>
          )}

          {!_.isEmpty(useThisData) &&
            useThisData.map(vendor => (
              <VendorItem
                key={vendor.relationId}
                vendor={vendor}
                highlightSelected={vendor.relationId === idOfVendorBeingViewed}
                onEditButtonClick={handleEditButtonClick}
                onVendorClick={onVendorClick}
              />
            ))}
          {showAddVendorDialog.open && (
            <AddVenderDialog
              allVendors={allVendors}
              open={showAddVendorDialog.open}
              editingVendor={showAddVendorDialog.vendor}
              onClose={() =>
                setShowAddVendorDialog({ open: false, vendor: null })
              }
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    vendorSort: state.appState.vendorSort || null,
  };
}

export default connect(mapStateToProps)(ListOfVendorsView);
