import React, { useCallback, useRef, useMemo, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  Add as AddIcon,
  AttachMoney as PayIcon,
  Block as BlockIcon,
  Cached as CachedIcon,
  Equalizer as EqualizerIcon,
  MoreHoriz as MoreHorizIcon,
  Save as SaveIcon,
} from '@material-ui/icons';

import _ from 'lodash';
import moment from 'moment';

import BillsTable from './bills-table';
import AdminToolsIconButton from '../../components/admin-tools-icon-button/admin-tools-icon-button';
import AdminToolsTitle from '../../components/admin-tools-title/admin-tools-title';
import ContentDetailsModal from '../add-to-project/content-details-modal';
import BillsViewStatsDialog from './bills-view-stats-dialog';
import LoadingCover from '../../components/LoadingCover/loadingCover';
import ContentImageInfoBlock from '../../components/ProjectContentListing/ContentTypes/content-image/content-image-info-block';

import {
  CONTENT_DEFINITION,
  CONTENT_DETAILS_MODAL_MODE,
  CONTENT_TYPE,
  GLOBAL_EXPENSE_STATUS,
  GLOBAL_EXPENSE_STATUS_LABEL,
} from '../../config/appDefaults';
import { BILLS_TABLE_COLUMN } from './bills-table-column-defs';
import store from '../../store';

import { GetCompanyCrewAction, GetVendorsAction } from '../../graphql/graphql';
import ListCompanyGlobalFinancialItems from '../../graphql/queries/list-company-global-financial-items';
import { monetaryRender } from '../../helpers';
import { useRepetitiveQuery } from '../../hooks';
import palette from '../../theme/palette';

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    padding: theme.spacing(3),
  },
  actionButtonsContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    minWidth: 460,
    '& button': {
      marginLeft: theme.spacing(2),
    },
    '& div': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const BillsDashboard = ({
  companyCrew,
  getCompanyCrewLoading,
  getCompanyCrewRefetch,
  managingCompanyInfo,
  vendors,
  vendorsLoading,
  vendorsRefetch,
}) => {
  const classes = useStyles();

  const billsTableRef = useRef(null);
  const [gridRef, setGridRef] = useState(null);

  const [selectedItemsMap, setSelectedItemsMap] = useState({});
  const [showContentDetailsModal, setShowContentDetailsModal] = useState({
    open: false,
    mode: null,
    modeInfo: null,
    existingInfo: null,
  });
  const [showStatsDialog, setShowStatsDialog] = useState({
    open: false,
    billStats: null,
  });

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const showPreferencesMenu = event => {
    setMenuAnchorEl(event.currentTarget);
  };
  const closePreferencesMenu = () => {
    setMenuAnchorEl(null);
  };

  const listCompanyGlobalFinancialItemsAccessor =
    'listCompanyGlobalFinancialItems';
  const listCompanyGlobalBillsQuery = useRepetitiveQuery(
    ListCompanyGlobalFinancialItems,
    {
      skip: !managingCompanyInfo.managingCompanyId,
      variables: {
        companyId: managingCompanyInfo.managingCompanyId,
        typesToInclude: ['BILL'],
      },
      fetchPolicy: 'cache-and-network',
      accessor: listCompanyGlobalFinancialItemsAccessor,
    }
  );
  const globalBills = _.get(
    listCompanyGlobalBillsQuery,
    `data.${listCompanyGlobalFinancialItemsAccessor}.items`,
    null
  );

  const listCompanyGlobalBillsLoading = _.get(
    listCompanyGlobalBillsQuery,
    'loading'
  );
  const listCompanyGlobalBillsRefetch = _.get(
    listCompanyGlobalBillsQuery,
    'refetch'
  );

  const somethingLoading =
    getCompanyCrewLoading || listCompanyGlobalBillsLoading || vendorsLoading;

  const refetchData = () => {
    if (somethingLoading) {
      return;
    }

    getCompanyCrewRefetch();
    listCompanyGlobalBillsRefetch();
    vendorsRefetch();
  };

  const enabledMarkAsPaid = useMemo(() => {
    if (_.isEmpty(selectedItemsMap)) {
      return false;
    }

    const selectedItems = _.values(selectedItemsMap);
    const selectedVendorId = selectedItems[0].globalBill.vendorId;

    // Only allow mark as paid if all selected items have the same vendor
    // and are unpaid or partially paid
    return _.every(selectedItems, ({ globalBill }) => {
      return (
        globalBill.vendorId === selectedVendorId &&
        (globalBill.contentStatus === GLOBAL_EXPENSE_STATUS.UNPAID ||
          globalBill.contentStatus === GLOBAL_EXPENSE_STATUS.PARTIALLY_PAID)
      );
    });
  }, [selectedItemsMap]);

  const handleSelectionChange = useCallback(({ selected }) => {
    setSelectedItemsMap(selected);
  }, []);

  const handleMarkBillAsPaid = () => {
    const selectedItems = _.values(selectedItemsMap);
    const selectedBills = _.map(selectedItems, 'globalBill');
    if (!_.isEmpty(selectedItems)) {
      setShowContentDetailsModal({
        open: true,
        mode: CONTENT_DETAILS_MODAL_MODE.MARK_BILL_AS_PAID,
        modeInfo: { bills: selectedBills },
        existingInfo: selectedBills[0],
      });
    }
  };

  const calcStatsByStatus = () => {
    const statsByStatus = {};
    const billsByStatus = {
      [GLOBAL_EXPENSE_STATUS.UNPAID]: [],
      [GLOBAL_EXPENSE_STATUS.PARTIALLY_PAID]: [],
      [GLOBAL_EXPENSE_STATUS.PAID]: [],
      [GLOBAL_EXPENSE_STATUS.OVER_PAID]: [],
    };

    _.forEach(gridRef?.current.data, item => {
      billsByStatus[item.globalBill.contentStatus].push(item);
    });

    _.keys(billsByStatus).forEach(status => {
      const bills = billsByStatus[status];
      const entriesCount = bills.length;
      let total = 0;
      let balanceDue = 0;
      let todayBalanceDue = 0;
      let overDueBalance = 0;
      const startOfToday = moment().startOf('day');
      const endOfToday = moment().endOf('day');

      bills.forEach(bill => {
        total += bill[BILLS_TABLE_COLUMN.AMOUNT] || 0;
        balanceDue += bill[BILLS_TABLE_COLUMN.BALANCE_DUE] || 0;
        if (bill[BILLS_TABLE_COLUMN.DUE_DATE] < startOfToday) {
          overDueBalance += bill[BILLS_TABLE_COLUMN.BALANCE_DUE] || 0;
        } else if (bill[BILLS_TABLE_COLUMN.DUE_DATE] <= endOfToday) {
          todayBalanceDue += bill[BILLS_TABLE_COLUMN.BALANCE_DUE] || 0;
        }
      });
      const average = entriesCount
        ? Number.parseFloat(total / entriesCount)
        : 0;

      statsByStatus[status] = {
        entriesCount,
        total,
        balanceDue,
        todayBalanceDue,
        overDueBalance,
        average,
      };
    });

    return statsByStatus;
  };

  const monetizeAmount = moneyValue => {
    return monetaryRender({ value: moneyValue, withDecimals: true });
  };

  const { billStats, overallDueStats } = useMemo(() => {
    const statsByStatus = calcStatsByStatus();
    const color = {
      balanceDue: palette.brandColorBlackish,
      overDueBalance: palette.brandColorError,
      todayBalanceDue: palette.brandColorOrange75,
      upcomingBalanceDue: palette.brandColorPrimary,
    };
    const statsToUse = [];
    const overallStat = {
      entriesCount: 0,
      total: 0,
      overPaid: 0,
      balanceDue: 0,
      overDueBalance: 0,
      todayBalanceDue: 0,
      upcomingBalanceDue: 0,
    };
    _.keys(statsByStatus)
      .sort()
      .forEach(status => {
        const data = statsByStatus[status];
        if (data.entriesCount > 0) {
          overallStat.entriesCount += data.entriesCount;
          overallStat.total += data.total;

          const stat = {
            sectionHeader: GLOBAL_EXPENSE_STATUS_LABEL[status],
            statusLabel: `${GLOBAL_EXPENSE_STATUS_LABEL[status]} Bills`,
            entriesCount: data.entriesCount,
            total: monetizeAmount(data.total),
          };

          if (status === GLOBAL_EXPENSE_STATUS.OVER_PAID) {
            overallStat.overPaid += data.balanceDue;
            stat.overPaid = monetizeAmount(data.balanceDue);
          }

          if (
            status === GLOBAL_EXPENSE_STATUS.UNPAID ||
            status === GLOBAL_EXPENSE_STATUS.PARTIALLY_PAID
          ) {
            overallStat.balanceDue += data.balanceDue;
            let upcomingBalanceDue = data.balanceDue;
            stat.balanceDue = monetizeAmount(data.balanceDue);

            if (data.todayBalanceDue) {
              overallStat.todayBalanceDue += data.todayBalanceDue;
              upcomingBalanceDue -= data.todayBalanceDue;
              stat.todayBalanceDue = monetizeAmount(data.todayBalanceDue);
            }

            if (data.overDueBalance) {
              overallStat.overDueBalance += data.overDueBalance;
              upcomingBalanceDue -= data.overDueBalance;
              stat.overDueBalance = monetizeAmount(data.overDueBalance);
            }

            overallStat.upcomingBalanceDue += upcomingBalanceDue;
            stat.upcomingBalanceDue = monetizeAmount(upcomingBalanceDue);
          }

          statsToUse.push({ ...stat, color });
        }
      });

    const overallStatsToPush = {
      sectionHeader: 'Overall',
      statusLabel: 'Bills',
      entriesCount: overallStat.entriesCount,
      total: monetizeAmount(overallStat.total),
      overPaid: overallStat.overPaid
        ? monetizeAmount(overallStat.overPaid)
        : null,
      balanceDue: overallStat.balanceDue
        ? monetizeAmount(overallStat.balanceDue)
        : null,
      overDueBalance: overallStat.overDueBalance
        ? monetizeAmount(overallStat.overDueBalance)
        : null,
      todayBalanceDue: overallStat.todayBalanceDue
        ? monetizeAmount(overallStat.todayBalanceDue)
        : null,
      upcomingBalanceDue: overallStat.upcomingBalanceDue
        ? monetizeAmount(overallStat.upcomingBalanceDue)
        : 0,
      color,
    };

    statsToUse.unshift(overallStatsToPush);

    const dueStats = [
      {
        label: 'Total Due',
        value: overallStatsToPush.balanceDue,
        color: color.balanceDue,
      },
      {
        label: 'Overdue',
        value: overallStatsToPush.overDueBalance,
        color: color.overDueBalance,
      },
      {
        label: 'Due Today',
        value: overallStatsToPush.todayBalanceDue,
        color: color.todayBalanceDue,
      },
      {
        label: 'Upcoming Due',
        value: overallStatsToPush.upcomingBalanceDue,
        color: color.upcomingBalanceDue,
      },
    ];

    return { billStats: statsToUse, overallDueStats: dueStats };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRef?.current.data]);

  const handleViewStats = () => {
    setShowStatsDialog({ open: true, billStats });
  };

  const handleAddBill = () => {
    setShowContentDetailsModal({
      open: true,
      mode: CONTENT_DETAILS_MODAL_MODE.ADMIN_GLOBAL_BILL_ADD,
    });
  };

  const saveColumnPrefs = () => {
    const allColumnInfo = gridRef.current.columnsMap;
    store.dispatch({
      type: 'SET_ADMIN_TOOLS_SETTINGS',
      payload: { globalBills: allColumnInfo },
    });
    closePreferencesMenu();
  };

  const clearColumnPrefs = () => {
    store.dispatch({
      type: 'SET_ADMIN_TOOLS_SETTINGS',
      payload: { globalBills: null },
    });
    // rebuild the table now
    billsTableRef.current?.buildTableDefs({ withoutPreferences: true });
    closePreferencesMenu();
  };

  return (
    <>
      <Grid
        container
        direction="column"
        style={{ height: 'calc(100vh - 64px)', position: 'relative' }}
      >
        <Grid container item>
          <Grid
            container
            justifyContent="space-between"
            className={classes.headerWrapper}
            spacing={1}
          >
            <Grid container item xs={12} justifyContent="space-between">
              <Grid item>
                <AdminToolsTitle
                  Icon={CONTENT_DEFINITION[CONTENT_TYPE.GLOBAL_BILL].Icon}
                  titleText="Manage Bills"
                />
              </Grid>
              <Grid item className={classes.actionButtonsContainer}>
                <AdminToolsIconButton
                  tooltipText="Reload Data"
                  transparentBackground
                  onClick={refetchData}
                  disabled={somethingLoading}
                  isLoading={somethingLoading}
                >
                  <CachedIcon />
                </AdminToolsIconButton>
                <AdminToolsIconButton
                  tooltipText="Select unpaid or partially-paid items that have the same vendor then click to make a payment"
                  onClick={handleMarkBillAsPaid}
                  disabled={!enabledMarkAsPaid}
                >
                  <PayIcon />
                </AdminToolsIconButton>
                <AdminToolsIconButton
                  tooltipText="View stats for current data"
                  onClick={handleViewStats}
                  disabled={!gridRef?.current}
                >
                  <EqualizerIcon />
                </AdminToolsIconButton>
                <AdminToolsIconButton
                  tooltipText="Add a bill"
                  onClick={handleAddBill}
                >
                  <AddIcon />
                </AdminToolsIconButton>
                <AdminToolsIconButton
                  tooltipText="View other options"
                  onClick={showPreferencesMenu}
                >
                  <MoreHorizIcon />
                </AdminToolsIconButton>
                <Menu
                  anchorEl={menuAnchorEl}
                  keepMounted
                  open={Boolean(menuAnchorEl)}
                  onClose={closePreferencesMenu}
                >
                  <MenuItem onClick={saveColumnPrefs}>
                    <SaveIcon />
                    &nbsp;&nbsp;Save Column Preferences
                  </MenuItem>
                  <MenuItem onClick={clearColumnPrefs}>
                    <BlockIcon />
                    &nbsp;&nbsp;Clear Column Preferences
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-start">
              {_.map(overallDueStats, stat => {
                return stat.value ? (
                  <ContentImageInfoBlock
                    key={stat.label}
                    primaryText={stat.value}
                    secondaryText={stat.label}
                    color={stat.color}
                    containerStyle={{ marginRight: 16 }}
                  />
                ) : null;
              })}
            </Grid>
          </Grid>
        </Grid>
        {_.isNil(companyCrew) || _.isNil(globalBills) || _.isNil(vendors) ? (
          <LoadingCover loader="linear">
            <Typography variant="h3" align="center">
              Loading all company bills...
            </Typography>
          </LoadingCover>
        ) : (
          <Grid item style={{ flex: 1 }}>
            <BillsTable
              ref={billsTableRef}
              companyCrew={companyCrew}
              globalBills={globalBills}
              vendors={vendors}
              gridRef={gridRef}
              selectedItemsMap={selectedItemsMap}
              onDataGridReady={setGridRef}
              onSelectionChange={handleSelectionChange}
              refetchData={refetchData}
            />
          </Grid>
        )}
      </Grid>
      {showContentDetailsModal.open && (
        <ContentDetailsModal
          mode={showContentDetailsModal.mode}
          modeInfo={showContentDetailsModal.modeInfo}
          existingInfo={showContentDetailsModal.existingInfo}
          onClose={data => {
            setShowContentDetailsModal({
              open: false,
              mode: null,
              modeInfo: null,
              existingInfo: null,
            });
            if (data && !data.cancelled) {
              setSelectedItemsMap({});
            }
          }}
        />
      )}
      {showStatsDialog.open && (
        <BillsViewStatsDialog
          open={showStatsDialog.open}
          billStats={showStatsDialog.billStats}
          onClose={() => {
            setShowStatsDialog({ open: false });
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || {},
});

export default compose(
  GetCompanyCrewAction,
  GetVendorsAction,
  connect(mapStateToProps)
)(BillsDashboard);
