import { standardRequiredMsg } from './schema-validation';

export default (
  fieldName,
  validationType = 'string',
  userEditable = false,
  required = false,
  moreValidations = [] // array of validation objects with type as string and params as array
) => {
  const baseDetails = {
    fieldName,
    validationType,
    userEditable,
    validations: [],
  };

  if (validationType === 'date') {
    baseDetails.validations.push({
      type: 'typeError',
      params: ['Invalid date or time'],
    });
  }

  if (required) {
    baseDetails.validations.push({
      type: 'required',
      params: [standardRequiredMsg()],
    });
  }

  if (moreValidations.length > 0) {
    moreValidations.forEach(validation => {
      baseDetails.validations.push({
        type: validation.type,
        params: [...validation.params],
      });
    });
  }
  return baseDetails;
};
