import React, { useState } from 'react';
import { withRouter, useLocation } from 'react-router-dom';

import {
  Paper,
  Grid,
  Tabs,
  Tab,
  CircularProgress,
  Typography,
} from '@material-ui/core';

import SingleProject from '../../../../components/ListOfProjects/singleProject';
import ProjectSearchAndSort from '../../../../components/project-search-and-sort/project-search-and-sort';

import { CONTENT_DEFINITION } from '../../../../config/appDefaults';

const CustomerContent = ({
  leadsLoading,
  leads,
  projectsLoading,
  projects,
  history,
}) => {
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(0);
  const [projectsToUse, setProjectsToUse] = useState([]);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const goToProject = project => {
    history.push(`/projects/${project.contentId}`);
  };

  const openLead = lead => {
    history.push(`${location.pathname}/lead/${lead.contentId}`);
  };

  const renderEmptyMessage = type => {
    return (
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        style={{ padding: 32 }}
      >
        <Typography variant="body1">No {type} yet.</Typography>
      </Grid>
    );
  };

  let leadsTabWording = CONTENT_DEFINITION.lead.pluralName;
  if (leads && leads.length) {
    leadsTabWording += ` (${leads.length})`;
  }
  let projectsTabWording = 'Projects';
  if (projects && projects.length) {
    projectsTabWording += ` (${projects.length})`;
  }

  return (
    <Paper style={{ padding: 16 }}>
      <Grid container direction="column" style={{ maxWidth: '100%' }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
        >
          <Tab label={leadsTabWording} />
          <Tab label={projectsTabWording} />
        </Tabs>
        {tabIndex === 0 && (
          <Grid container item>
            {leadsLoading && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ padding: 32 }}
              >
                <CircularProgress />
              </Grid>
            )}
            {!leadsLoading && (
              <>
                {leads && !!leads.length ? (
                  <>
                    {leads.map(lead => {
                      return (
                        <SingleProject
                          key={lead.contentId}
                          project={lead}
                          onClickOfProject={() => {
                            openLead(lead);
                          }}
                          showStatus
                          showAddress
                          softHighlight
                        />
                      );
                    })}
                  </>
                ) : (
                  renderEmptyMessage('leads')
                )}
              </>
            )}
          </Grid>
        )}
        {tabIndex === 1 && (
          <Grid container item>
            <ProjectSearchAndSort
              allProjects={projects}
              passBack={setProjectsToUse}
            />
            {projectsLoading && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ padding: 32 }}
              >
                <CircularProgress />
              </Grid>
            )}
            {!projectsLoading && (
              <>
                {projectsToUse && !!projectsToUse.length ? (
                  <>
                    {projectsToUse.map(project => {
                      return (
                        <SingleProject
                          key={project.contentId}
                          project={project}
                          onClickOfProject={() => {
                            goToProject(project);
                          }}
                        />
                      );
                    })}
                  </>
                ) : (
                  renderEmptyMessage('projects')
                )}
              </>
            )}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default withRouter(CustomerContent);
