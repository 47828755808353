import React, { useState, useEffect, useCallback } from 'react';
import numeral from 'numeral';
import { fromString } from 'html-to-text';

// UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  LinearProgress,
  Button,
  Tooltip,
  Chip,
} from '@material-ui/core';

import { Visibility as VisibilityIcon } from '@material-ui/icons';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';

// utilities
import _ from 'lodash';
import moment from 'moment';

// UI components

// helpers
import { monetaryRender, capitalizeSentence } from '../../helpers';
import {
  amountFilter,
  simpleSortForAmount,
} from '../../helpers/react-datagrid-helpers';

import {
  RFI_STATUSES,
  RFI_SOURCES_ARRAY,
  RFI_MODES,
  RFI_STATUSES_EXTERNAL_ACTIVE,
  RFI_STATUSES_EXTERNAL_ALL,
  RFI_TXN_ANSWER_TYPES,
} from '../../config/appDefaults';

import themePalette from '../../theme/palette';

// for ReactDataGrid
window.moment = moment;
const filterTypes = {
  ...ReactDataGrid.defaultProps.filterTypes,
  ...amountFilter,
};

const defaultSortInfo = [
  {
    name: 'dateCreated',
    dir: 1,
    type: 'date',
  },
];

const buildColumnObj = options => {
  if (!options.name) return null;
  const basicColumn = {
    ...options,
    header:
      options.header !== undefined
        ? options.header
        : capitalizeSentence(options.name),
  };
  return basicColumn;
};

const useStyles = makeStyles(theme => ({
  editButton: {
    padding: theme.spacing(0.5),
    margin: 0,
    minWidth: 0,
    color: '#aaa',
    '&:hover': {
      background: 'transparent',
      color: '#333',
    },
  },
}));

const RfiDatagrid = ({ rfis, editThisRfi, rfiMode }) => {
  const classes = useStyles();
  const [setGridRef] = useState(null);
  const rowHeight = 50;
  const gridStyle = { height: '100%', minHeight: '100%', marginBottom: 32 };

  const [dataSource, setDataSource] = useState([]);
  const [selected] = useState({});

  const monetizeAmount = moneyValue => {
    return monetaryRender({ value: moneyValue, withDecimals: true });
  };

  useEffect(() => {
    if (!rfis) {
      return;
    }

    const processTransactions = passedData => {
      const transactions = passedData.map(rfi => {
        let requestTypeInfo = null;
        if (rfi.requestType) {
          requestTypeInfo = _.find(RFI_TXN_ANSWER_TYPES, {
            value: rfi.requestType,
          });
        }

        return {
          ...rfi,
          txnReceived: rfi.txnReceived
            ? numeral(rfi.txnReceived.value).value()
            : null,
          txnSpent: rfi.txnSpent ? numeral(rfi.txnSpent.value).value() : null,
          requestTypeForDisplay: requestTypeInfo?.label || null,
        };
      });

      // add to any existing info in the datasource
      setDataSource(transactions);
    };

    processTransactions(rfis);
  }, [rfis]);

  const COLUMN_MIN_WIDTH_EXTRA_SMALL = 52;
  const COLUMN_MIN_WIDTH_SMALL = 100;
  const COLUMN_MIN_WIDTH_MEDIUM = 180;
  const COLUMN_MIN_WIDTH_LARGE = 250;

  const showingComplete = rfiMode === RFI_MODES.COMPLETE.value;
  const RFI_STATUSES_BASED_ON_MODE = showingComplete
    ? RFI_STATUSES_EXTERNAL_ALL
    : RFI_STATUSES_EXTERNAL_ACTIVE;

  const showInTable = [
    {
      name: 'listNumber',
      header: null,
      minWidth: COLUMN_MIN_WIDTH_EXTRA_SMALL,
      maxWidth: COLUMN_MIN_WIDTH_EXTRA_SMALL,
    },
    {
      name: 'edit',
      header: null,
      minWidth: COLUMN_MIN_WIDTH_EXTRA_SMALL,
      maxWidth: COLUMN_MIN_WIDTH_EXTRA_SMALL,
    },
    {
      name: 'requestStatus',
      header: 'Status',
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: true,
        dataSource: _.map(RFI_STATUSES_BASED_ON_MODE, item => ({
          id: item.value,
          label: item.label,
        })),
      },
      defaultFlex: 30,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
    },
    {
      name: 'requestSource',
      header: 'Source',
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: true,
        dataSource: RFI_SOURCES_ARRAY.map(item => ({
          id: item.value,
          label: item.label,
        })),
      },
      defaultFlex: 30,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
    },
    {
      name: 'requestTypeForDisplay',
      header: 'Type',
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: true,
        dataSource: _.map(RFI_TXN_ANSWER_TYPES, item => ({
          id: item.label,
          label: item.label,
        })),
      },
      defaultFlex: 30,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
    },
    {
      name: 'dateCreated',
      header: 'Request Date',
      dateFormat: 'YYYY-MM-DD',
      filterEditor: DateFilter,
      filterEditorProps: () => {
        // for range and notinrange operators, the index is 1 for the after field
        return {
          dateFormat: 'MMM D, YYYY',
          cancelButton: false,
          highlightWeekends: false,
        };
      },
      defaultFlex: 30,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
    },
    {
      name: 'txnDate',
      header: 'Transaction Date',
      dateFormat: 'YYYY-MM-DD',
      filterEditor: DateFilter,
      filterEditorProps: () => {
        // for range and notinrange operators, the index is 1 for the after field
        return {
          dateFormat: 'MMM D, YYYY',
          cancelButton: false,
          highlightWeekends: false,
        };
      },
      defaultFlex: 30,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
    },
    {
      name: 'txnAccount',
      header: 'Account',
      defaultFlex: 30,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: true,
        dataSource: _.uniq(
          _.map(rfis, ({ txnAccount }) => txnAccount || '').filter(
            txnAccount => !!txnAccount
          )
        )
          .sort((a, b) => {
            // sort by lowercase
            const aLower = a.toLowerCase();
            const bLower = b.toLowerCase();
            if (aLower < bLower) {
              return -1;
            }
            if (aLower > bLower) {
              return 1;
            }
            return 0;
          })
          .map(txnAccount => ({ id: txnAccount, label: txnAccount })),
      },
    },
    {
      name: 'bankAccount',
      header: 'Bank Account',
      defaultFlex: 30,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: true,
        dataSource: _.uniq(
          _.map(rfis, ({ bankAccount }) => bankAccount || '').filter(
            bankAccount => !!bankAccount
          )
        )
          .sort((a, b) => {
            // sort by lowercase
            const aLower = a.toLowerCase();
            const bLower = b.toLowerCase();
            if (aLower < bLower) {
              return -1;
            }
            if (aLower > bLower) {
              return 1;
            }
            return 0;
          })
          .map(bankAccount => ({ id: bankAccount, label: bankAccount })),
      },
    },
    {
      name: 'txnDescription',
      header: 'Description',
      defaultFlex: 30,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
    },
    {
      name: 'txnPayee',
      header: 'Payee',
      defaultFlex: 30,
      minWidth: COLUMN_MIN_WIDTH_MEDIUM,
    },
    {
      name: 'txnSpent',
      header: 'Spent',
      type: 'amount',
      textAlign: 'center',
      sort: simpleSortForAmount,
      defaultFlex: 10,
      minWidth: COLUMN_MIN_WIDTH_SMALL,
    },
    {
      name: 'txnReceived',
      header: 'Received',
      type: 'amount',
      textAlign: 'center',
      sort: simpleSortForAmount,
      defaultFlex: 10,
      minWidth: COLUMN_MIN_WIDTH_SMALL,
    },
    {
      name: 'initialNotes',
      header: 'Accountant Notes',
      defaultFlex: 50,
      minWidth: COLUMN_MIN_WIDTH_LARGE,
    },
  ];

  // build the table columns off the datasource
  const columns = showInTable.map(attribute => {
    const attributePlus = { ...attribute, editable: false };
    if (attribute.name === 'edit') {
      attributePlus.render = ({ data }) => {
        return (
          <Grid container justifyContent="space-around">
            <Tooltip title="View RFI">
              <Button
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  editThisRfi(data);
                }}
                className={classes.editButton}
              >
                <VisibilityIcon />
              </Button>
            </Tooltip>
          </Grid>
        );
      };
    } else if (attribute.name === 'listNumber') {
      attributePlus.render = params => {
        return <div style={{ textAlign: 'center' }}>{params.rowIndex + 1}</div>;
      };
    } else if (attribute.name === 'requestStatus') {
      attributePlus.render = ({ data }) => {
        const color = {
          [RFI_STATUSES.EXT_A_REJECTED.value]: themePalette.brandColorError,
          [RFI_STATUSES.EXT_A_PENDING_APPROVAL.value]:
            themePalette.brandColorOrange,
          [RFI_STATUSES.EXT_A_ACTIVE.value]: themePalette.brandColorGreen,
        };
        return (
          <div style={{ textAlign: 'center' }}>
            <Chip
              label={
                _.find(RFI_STATUSES_BASED_ON_MODE, {
                  value: data.requestStatus,
                })?.label || 'N/A'
              }
              style={{
                color: color[data.requestStatus] ? '#fff' : 'inherit',
                backgroundColor: color[data.requestStatus] || 'transparent',
                textTransform: 'uppercase',
              }}
            />
          </div>
        );
      };
    } else if (attribute.name === 'requestSource') {
      attributePlus.render = ({ data }) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {_.find(RFI_SOURCES_ARRAY, { value: data.requestSource })?.label ||
              'N/A'}
          </div>
        );
      };
    } else if (attribute.name === 'initialNotes') {
      attributePlus.render = ({ value }) => {
        if (!value) return '';
        return fromString(value);
      };
    } else if (attribute.name === 'parentName') {
      attributePlus.render = ({ data }) => {
        return <>{data.parentPath && data.parentPath.join(' > ')}</>;
      };
    } else if (
      attribute.name === 'txnReceived' ||
      attribute.name === 'txnSpent'
    ) {
      attributePlus.render = ({ value }) => {
        return (
          <Grid container justifyContent="center" alignItems="center">
            {monetizeAmount(value)}
          </Grid>
        );
      };
    } else if (
      attribute.name === 'txnDate' ||
      attribute.name === 'dateCreated'
    ) {
      attributePlus.render = ({ value }) => {
        if (!value) return '-';
        return moment(value).format('MMM D, YYYY');
      };
    } else if (attribute.name === 'receiptImage') {
      attributePlus.render = ({ value, data }) => {
        if (!value) return 'n/a';
        return (
          <a
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            className="basicStyledLink"
            style={{ textTransform: 'capitalize' }}
          >
            {data.type || 'Receipt'}
          </a>
        );
      };
    }
    return buildColumnObj(attributePlus);
  });

  const onSelectionChange = useCallback(
    ({ selected: selectedRow }) => {
      const selectedRowId = Object.keys(selectedRow)[0];
      const rfiToEdit = _.find(rfis, { requestId: selectedRowId });
      editThisRfi(rfiToEdit);
    },
    [rfis, editThisRfi]
  );

  const filterValue = [
    {
      name: 'requestSource',
      operator: 'inlist',
      type: 'select',
      value: null,
    },
    {
      name: 'requestStatus',
      operator: 'inlist',
      type: 'select',
      value: null,
    },
    {
      name: 'requestTypeForDisplay',
      operator: 'inlist',
      type: 'select',
      value: null,
    },
    {
      name: 'txnAccount',
      operator: 'inlist',
      type: 'select',
      value: null,
    },
    {
      name: 'bankAccount',
      operator: 'inlist',
      type: 'select',
      value: null,
    },
    {
      name: 'txnSpent',
      operator: 'Starts With',
      type: 'amount',
      value: '',
    },
    {
      name: 'txnReceived',
      operator: 'Starts With',
      type: 'amount',
      value: '',
    },
    {
      name: 'txnDate',
      operator: 'eq',
      type: 'date',
      value: '',
    },
    {
      name: 'dateCreated',
      operator: 'eq',
      type: 'date',
      value: '',
    },
    {
      name: 'txnDescription',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'initialNotes',
      operator: 'contains',
      type: 'string',
      value: '',
    },
  ];

  const scrollProps = {
    ...ReactDataGrid.defaultProps.scrollProps,
    autoHide: false,
    scrollThumbWidth: 12,
    scrollThumbStyle: {
      background: themePalette.brandColorPrimary,
    },
  };

  return (
    <>
      {rfis ? (
        <ReactDataGrid
          rowHeight={rowHeight}
          rowStyle={{ cursor: 'pointer' }}
          // grab the red
          onReady={setGridRef}
          // set which property is used as the ID
          idProperty="requestId"
          // set which columns show
          columns={columns}
          // set the data to build from
          dataSource={dataSource}
          // set basic styling for the overall table
          style={gridStyle}
          // filtering
          enableFiltering
          defaultFilterValue={filterValue}
          filterTypes={filterTypes}
          // sorting
          defaultSortInfo={defaultSortInfo}
          allowUnsort={false}
          // scrollbar
          scrollProps={scrollProps}
          // checkbox column
          selected={selected}
          // editing options
          editable
          enableKeyboardNavigation={false}
          onSelectionChange={onSelectionChange}
        />
      ) : (
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', height: '100%' }}
        >
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              Loading RFIs...
            </Typography>
            <LinearProgress
              style={{ width: '50%', margin: '0 auto', marginTop: 16 }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default RfiDatagrid;
