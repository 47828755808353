import React from 'react';
import { GRAPH_TYPE, TARGET_TYPE } from '../../../config/appDefaults';

const disclaimer = (
  <>
    <br />
    <br />
    <i>
      Note: This graph is based on the data in your accounting software, so work
      with your bookkeeper to make sure your books are up to date for a given
      period before relying on this graph.
    </i>
  </>
);

export const BREAKEVEN_GRAPH = {
  MONTHLY_SALES: 'monthlySales',
  ANNUAL_BREAKEVEN_SALES_FORECAST: 'annualBreakevenSalesForecast',
  SALES_TO_DATE: 'salesToDate',
};

export const breakevenGraphs = [
  {
    name: BREAKEVEN_GRAPH.MONTHLY_SALES,
    label: 'Monthly Sales ($)',
    targetType: TARGET_TYPE.NUMBER,
    graphType: GRAPH_TYPE.BAR,
    description: (
      <>
        Monthly Sales ($) shows your monthly project sales. This chart will help
        you easily see how your project sells are trending month-over-month, and
        help you determine if you are track to break-even.
        <br />
        <br />
        If the report period selected is currently on-going, and if your
        progress report has determined that there are remaining sales to
        break-even, you will see a <b>break-even</b> goal line. This goal line
        displays the average sales per remaining month required to break-even as
        calculated in the progress report. If you can maintain month sales above
        this number while maintaining your current operational efficiency, then
        you should be in the clear!
        {disclaimer}
      </>
    ),
  },
  {
    name: BREAKEVEN_GRAPH.SALES_TO_DATE,
    label: 'Sales to Date ($)',
    targetType: TARGET_TYPE.NUMBER,
    graphType: GRAPH_TYPE.AREA,
    description: (
      <>
        Sales to Date ($) shows your cumulative project sales to date for the
        period shown.
        {disclaimer}
      </>
    ),
  },
  {
    name: BREAKEVEN_GRAPH.ANNUAL_BREAKEVEN_SALES_FORECAST,
    label: 'Annual Break-even Sales ($)',
    targetType: TARGET_TYPE.NUMBER,
    graphType: GRAPH_TYPE.LINE,
    description: (
      <>
        See where your business’s profitability is trending toward by tracking
        its cumulative sales against its forecasted break-even point.
        <br />
        <br />
        Your Break-even Sales changes based on your monthly project sales,
        variable costs and fixed costs. Level uses this data in your accounting
        software to calculate your break-even point.
        <br />
        <br />
        <b>How to use this graph</b>
        <br />
        Your Break-even Sales changes based on 3 types of transactions in your
        business: sales, variable costs, and fixed costs.
        <br />
        <br />
        Variable costs are costs that change based on your production levels.
        Whereas fixed costs stay constant whatever the production level in your
        business.
        <br />
        <br />
        Increasing your sales without increasing your costs, like increasing
        your prices, can decrease your Break-even Sales. Whereas increases in
        your variable costs or fixed costs can increase your Break-even Sales.
        <br />
        <br />
        Want to see what’s contributing to changes in your Break-even Sales?
        Navigate to settings and export your data to get a monthly breakdown of
        your sales, variable costs, and fixed costs.
        {disclaimer}
      </>
    ),
  },
];
