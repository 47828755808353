import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';

import styles from './auth.styles';

import AuthTextField from './auth-text-field/auth-text-field';
import BigButton from './big-button';
import PasswordRequirementsTooltip from './auth-text-field/password-requirements-tooltip';

const useStyles = makeStyles(styles);

const ChangePasswordForm = ({
  password,
  confirmPassword,
  isProcessing,
  authError,
  onPasswordChange,
  onConfirmPasswordChange,
  onPasswordSet,
}) => {
  const classes = useStyles();

  const handleChange = handler => event => {
    const newValue = event.target.value;

    handler(newValue);
  };

  return (
    <>
      <Typography className={classes.formTitle}>Set a new password</Typography>

      <Grid container item xs={12} className={classes.formBody}>
        <Grid item xs={12}>
          <AuthTextField
            name="password"
            label="New Password"
            lableTooltip={<PasswordRequirementsTooltip />}
            value={password}
            required
            type="password"
            errorText={authError?.password}
            onChange={handleChange(onPasswordChange)}
          />
        </Grid>
        <Grid item xs={12}>
          <AuthTextField
            name="confirm-password"
            label="Confirm Password"
            value={confirmPassword}
            required
            type="password"
            errorText={authError?.confirmPassword}
            onChange={handleChange(onConfirmPasswordChange)}
            onEnterKeyPress={onPasswordSet}
          />
        </Grid>
        {!!authError?.form && (
          <Grid item xs={12} className={classes.formErrorContainer}>
            <Typography className={classes.errorText}>
              {authError.form}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item container xs={12} justifyContent="center">
        <BigButton
          buttonText="Update"
          fullWidth
          isProcessing={isProcessing}
          onClick={onPasswordSet}
        />
      </Grid>
    </>
  );
};

export default ChangePasswordForm;
