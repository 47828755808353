import gql from 'graphql-tag';

export default gql`
  query ListMyShifts($userId: String!) {
    listMyShifts(input: { userId: $userId }) {
      items {
        __typename
        contentId
        jrnId
        creatorId
        type
        startDate
        endDate
        description
        labels
        recurrence {
          __typename
          rangeId
          rangeStartDate
          rangeEndDate
          daysOfTheWeek
        }
      }
    }
  }
`;
