import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select as MuiSelect,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import _ from 'lodash';
import moment from 'moment';

import {
  CUSTOMER_TXN_OPTION,
  CUSTOMER_TXN_REPORT_PERIOD_OPTIONS,
  REPORTING_PERIOD,
} from '../../../config/appDefaults';

import BasicDialog from '../../basic-dialog/basic-dialog';

const useStyles = makeStyles(theme => {
  return {
    filtersWrapper: {
      display: 'flex',
      width: '100%',
      height: '100%',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    paper: {
      height: '100%',
      width: '100%',
      padding: 24,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    formControl: {
      minWidth: 120,
    },
    updateButton: {
      marginTop: theme.spacing(2),
      minWidth: 150,
    },
  };
});

const CustomerTransactionsReportSettings = ({
  hideSettingsChangedWarning,
  loading,
  onSettingsUpdate,
  onReportPeriodChange = () => {},
}) => {
  const classes = useStyles();

  const [showParentCustomers, setShowParentCustomers] = useState(true);
  const [showSubCustomers, setShowSubCustomers] = useState(true);
  const [showUnassignedCogsTxns, setShowUnassignedCogsTxns] = useState(true);
  const [excludeUndepositedFundTxns, setExcludeUndepositedFundTxns] = useState(
    true
  );

  const [selectedReportPeriod, setSelectedReportPeriod] = useState(
    REPORTING_PERIOD.THIS_WEEK.value
  );
  const [customPeriodStartDate, setCustomPeriodStartDate] = useState(null);
  const [customPeriodEndDate, setCustomPeriodEndDate] = useState(null);
  const [showBasicDialog, setShowBasicDialog] = useState({ open: false });

  const [isSettingsChanged, setIsSettingsChanged] = useState(false);

  const [error, setError] = useState({
    customPeriodStartDate: '',
    customPeriodEndDate: '',
  });

  useEffect(() => {
    onReportPeriodChange(selectedReportPeriod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customPeriodStartDate && customPeriodEndDate) {
      // if duration is more than 16 weeks, show warning message
      if (
        moment(customPeriodEndDate).diff(customPeriodStartDate, 'week', true) >
        16
      ) {
        setShowBasicDialog({
          open: true,
          title: "Head's up!",
          message:
            'Including too many transactions in the report may cause your browser to slow down and behave unexpectedly. If experiencing performance issues, please choose a shorter reporting window.',
        });
      }

      // if the end date is before the start date, set it to the start date
      if (moment(customPeriodEndDate).isBefore(customPeriodStartDate)) {
        setCustomPeriodEndDate(customPeriodStartDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customPeriodStartDate, customPeriodEndDate]);

  const dateToString = date => {
    return moment(date).format('YYYY-MM-DD');
  };

  const getFilterSettings = () => {
    const settings = {
      reportPeriod: selectedReportPeriod,
      options: [],
    };

    if (selectedReportPeriod === REPORTING_PERIOD.CUSTOM.value) {
      if (!customPeriodStartDate) {
        setError(currentState => ({
          ...currentState,
          customPeriodStartDate: 'Start date is required',
        }));
        return null;
      }
      if (!customPeriodEndDate) {
        setError(currentState => ({
          ...currentState,
          customPeriodEndDate: 'End date is required',
        }));
        return null;
      }
      settings.customPeriodStartDate = dateToString(customPeriodStartDate);
      settings.customPeriodEndDate = dateToString(customPeriodEndDate);
    }

    if (!showParentCustomers) {
      settings.options.push(CUSTOMER_TXN_OPTION.EXCLUDE_PARENT_CUSTOMERS);
    }

    if (!showSubCustomers) {
      settings.options.push(CUSTOMER_TXN_OPTION.EXCLUDE_SUB_CUSTOMERS);
    }

    if (showUnassignedCogsTxns) {
      settings.options.push(CUSTOMER_TXN_OPTION.INCLUDE_UNASSIGNED_COGS_TXNS);
    }

    if (excludeUndepositedFundTxns) {
      settings.options.push(CUSTOMER_TXN_OPTION.EXCLUDE_UNDEPOSITED_FUNDS);
    }

    return settings;
  };

  const handleReportPeriodChange = event => {
    setSelectedReportPeriod(event.target.value);
    setIsSettingsChanged(true);
    onReportPeriodChange(event.target.value);
  };

  const handleCustomStartDateChange = date => {
    setError(currentState => ({
      ...currentState,
      customPeriodStartDate: '',
    }));
    setCustomPeriodStartDate(date);
    setIsSettingsChanged(true);
  };

  const handleCustomEndDateChange = date => {
    setError(currentState => ({
      ...currentState,
      customPeriodEndDate: '',
    }));
    setCustomPeriodEndDate(date);
    setIsSettingsChanged(true);
  };

  const handleUpdateButtonClick = () => {
    const payload = getFilterSettings();

    if (!payload) {
      return;
    }

    setIsSettingsChanged(false);

    onSettingsUpdate(payload);
  };

  return (
    <>
      <div className={classes.filtersWrapper}>
        <Paper elevation={4} className={classes.paper}>
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="h5" style={{ marginBottom: 16 }}>
                <b>Report Settings</b>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            spacing={1}
            justifyContent="space-between"
          >
            <Grid item xs={12}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel
                  id="billable-hours-select-report-period-label"
                  disabled={loading}
                >
                  Report Period
                </InputLabel>
                <MuiSelect
                  value={selectedReportPeriod}
                  onChange={handleReportPeriodChange}
                  disabled={loading}
                >
                  {_.map(
                    CUSTOMER_TXN_REPORT_PERIOD_OPTIONS,
                    ({ key, displayValue, value }) => (
                      <MenuItem key={key} value={value}>
                        {displayValue}
                      </MenuItem>
                    )
                  )}
                </MuiSelect>
              </FormControl>
            </Grid>
            {selectedReportPeriod === 'Custom' && (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid item container justifyContent="space-between" spacing={1}>
                  <Grid item xs={6}>
                    <DatePicker
                      label="Start Date"
                      variant="inline"
                      value={customPeriodStartDate}
                      format="MMM D, YYYY"
                      onChange={handleCustomStartDateChange}
                      autoOk
                      maxDate={new Date()}
                      fullWidth
                      error={!!error.customPeriodStartDate}
                      helperText={error.customPeriodStartDate}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      label="End Date"
                      variant="inline"
                      value={customPeriodEndDate}
                      format="MMM D, YYYY"
                      onChange={handleCustomEndDateChange}
                      autoOk
                      minDate={customPeriodStartDate}
                      maxDate={new Date()}
                      fullWidth
                      error={!!error.customPeriodEndDate}
                      helperText={error.customPeriodEndDate}
                      disabled={!customPeriodStartDate || loading}
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            )}
            <Grid item xs={12} style={{ marginTop: 8 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={showParentCustomers}
                    disabled={loading}
                    onChange={event => {
                      setShowParentCustomers(event.target.checked);
                      setIsSettingsChanged(true);
                    }}
                  />
                }
                label="Show parent customers"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={showSubCustomers}
                    disabled={loading}
                    onChange={event => {
                      setShowSubCustomers(event.target.checked);
                      setIsSettingsChanged(true);
                    }}
                  />
                }
                label="Show sub-customers / projects"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={showUnassignedCogsTxns}
                    disabled={loading}
                    onChange={event => {
                      setShowUnassignedCogsTxns(event.target.checked);
                      setIsSettingsChanged(true);
                    }}
                  />
                }
                label="Show unassigned transactions"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={excludeUndepositedFundTxns}
                    disabled={loading}
                    onChange={event => {
                      setExcludeUndepositedFundTxns(event.target.checked);
                      setIsSettingsChanged(true);
                    }}
                  />
                }
                label="Exclude undeposited fund transactions"
              />
            </Grid>
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {isSettingsChanged && !hideSettingsChangedWarning && (
                <Typography variant="body2" color="error">
                  The report settings have been modified since the last update.
                </Typography>
              )}
              <Button
                variant="contained"
                color="primary"
                className={classes.updateButton}
                disabled={loading}
                onClick={handleUpdateButtonClick}
              >
                Run Report
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
      {showBasicDialog.open && (
        <BasicDialog
          open={!!showBasicDialog.open}
          title={showBasicDialog.title || 'Hmmm...'}
          handleClose={() =>
            setShowBasicDialog(currentState => ({
              ...currentState,
              open: false,
            }))
          }
        >
          <span>{showBasicDialog.message}</span>
        </BasicDialog>
      )}
    </>
  );
};

export default CustomerTransactionsReportSettings;
