import gql from 'graphql-tag';

export default gql`
  query GetCompanyCrewAdmin($companyId: String!) {
    getCompanyCrewAdmin(companyId: $companyId) {
      items {
        userId
        username
        firstName
        lastName
        email
        phoneNumber
        profilePic
        accessMode
        userType
        type
        role
        department
        payType
        payRate
        payrollId
        overtimeRate
        laborBurdenPercentage
        vacaAccrualRate
        vacaAccrualType
        cards {
          cardAccessId
          userId
          cardId
        }
      }
    }
  }
`;
