import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Company}
  mutation DisconnectFromQuickBooks($companyId: String!) {
    disconnectFromQuickBooks(input: { companyId: $companyId }) {
      ...CompanyFields
    }
  }
`;
