import React from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { withSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  // ListItemText,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import _ from 'lodash';
import omitDeep from 'omit-deep';

import ContentGallery from '../ProjectContentListing/ContentTypes/contentGallery';
import ContentVideo from '../ProjectContentListing/ContentTypes/contentVideo';
import LoadingCover from '../LoadingCover/loadingCover';
import {
  MAX_IMAGES_IN_GALLERY,
  MAX_UPLOAD_TOTAL_FILESIZE_BYTES,
  MAX_PDF_FILE_SIZE,
} from '../../config/appDefaults';

import { uploadToPresignedUrl, getAPresignedUrl } from '../../graphql/graphql';

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: '#eee',
    color: '#666',
  },
  title: {
    color: '#397ca5',
    textAlign: 'center',
  },
  subTitle: {
    font: 'bold',
    textAlign: 'center',
  },
  cancel: {
    backgroundColor: '#E0E0E0',
    width: '100%',
  },
  submit: {
    width: '100%',
    color: '#FFFFFF',
  },
  dropzoneStyle: {
    border: '3px dashed #ccc',
    minHeight: '100px',
  },
  uploadWrapper: {
    height: '100%',
    maxHeight: '300px',
    padding: theme.spacing(2),
  },
  uploadAreaWrapper: {
    textAlign: 'center',
    borderRadius: 5,
    color: theme.palette.brandColorPrimary,
    backgroundColor: theme.palette.brandColorPrimary10,
    fontSize: 50,
    minHeight: 200,
    width: '100%',
    maxWidth: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eee',
    },
    transition: 'all 0.25s ease',
  },
  taskDialog: {
    justifyContent: 'center',
  },
  taskButtonContainer: {
    justifyContent: 'center',
    margin: '10px',
    textAlign: 'center',
  },
  taskButton: {
    margin: '5px',
  },
  pdfIcon: {
    color: theme.palette.brandColorPrimary,
    fontSize: 200,
  },
}));

const TaskCompletionDialog = props => {
  const classes = useStyles();
  const {
    onCancel,
    contentPiece,
    enqueueSnackbar,
    onSubmit,
    uploadProgressPercent,
    userId,
    statusToSet,
    parentInfo,
  } = props;

  const { completionType, completionInfo = {} } = contentPiece;
  const [mediaInfoChanged, setMediaInfoChanged] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [infoWeNeed, setInfoWeNeed] = React.useState({
    instructions: completionInfo.instructions || null,
    textResponse: completionInfo.textResponse || '',
    completedById: completionInfo.completedById || userId,
    completedByDate: completionInfo.completedByDate || new Date().toISOString(),
    mediaInfo: [],
  });
  const [pdfName, setPdfName] = React.useState('');
  const [dropPDF, setdropPDF] = React.useState(false);
  React.useEffect(() => {
    // on mount only, check if there's existing mediaInfo and if so, clean it and set it locally
    const existingMediaInfo = _.get(contentPiece, 'completionInfo.mediaInfo');
    let cleanMediaInfo;
    if (existingMediaInfo) {
      cleanMediaInfo = omitDeep(existingMediaInfo, ['__typename']);
      setInfoWeNeed({ ...infoWeNeed, mediaInfo: cleanMediaInfo });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // }, [contentPiece, infoWeNeed]);

  const dropzoneLevelRef = React.createRef();

  const handleCancel = () => {
    onCancel();
  };

  const uploadMedia = async (mediaItem, typeOfFile) => {
    const _mediaItem = mediaItem;
    const parentId =
      _.get(parentInfo, 'permissionsFrom') ||
      _.get(parentInfo, 'contentId') ||
      null;
    // For each non-cloudinary url, create a presigned url
    let presignedUrl;
    try {
      presignedUrl = await getAPresignedUrl({
        typeOfFile,
        customFolder: parentId,
      });
    } catch (e) {
      return {
        status: 'error',
        msg: 'could not get presignedUrl',
      };
    }

    // Upload cloudinary media to presigned url
    let uploadResponse;
    try {
      uploadResponse = await uploadToPresignedUrl(
        _mediaItem,
        presignedUrl.signature,
        presignedUrl.timestamp,
        typeOfFile,
        false, // since this will never be called for profile photos
        null, // pass the custome filename if it's there
        parentId // pass the custom folder name or null
      );
    } catch (e) {
      return {
        status: 'error',
        msg: 'could not uploadToPresignedUrl',
      };
    }

    const aspectRatio =
      uploadResponse.response.width / uploadResponse.response.height;

    // Update media info object and return
    _mediaItem.uri = uploadResponse.response.secure_url;
    _mediaItem.aspectRatio = aspectRatio;

    return _mediaItem;
  };

  const handleNewMedia = async (mediaInfo, type) => {
    const typeOfFile = type === 'video' ? 'video' : 'image'; // pdfs are handled as images

    const mediaUploadPromises = [];
    _.forEach(mediaInfo, mediaItem => {
      // Check if media info includes non-cloudinary urls
      if (mediaItem.uri && !_.includes(mediaItem.uri, 'cloudinary')) {
        mediaUploadPromises.push(uploadMedia(mediaItem, typeOfFile));
      }
    });

    await Promise.all(mediaUploadPromises);
  };

  const handleSubmit = async () => {
    setSaving(true);
    let shouldProceed = false;
    let mediaInfoForSaving;

    if (completionType === 'text') {
      if (
        infoWeNeed &&
        infoWeNeed.textResponse &&
        infoWeNeed.textResponse.length > 0
      ) {
        shouldProceed = true;
      }
    } else if (
      completionType === 'image' ||
      completionType === 'video' ||
      completionType === 'pdf'
    ) {
      if (infoWeNeed.mediaInfo && infoWeNeed.mediaInfo.length) {
        shouldProceed = true;
        if (mediaInfoChanged) {
          await handleNewMedia(infoWeNeed.mediaInfo, completionType);
          mediaInfoForSaving = infoWeNeed.mediaInfo.map(mediaItem => {
            return {
              uri: mediaItem.uri,
              aspectRatio: 1,
            };
          });
        }
      }
    } else if (contentPiece.requireAdmin) {
      shouldProceed = true;
    }
    if (shouldProceed) {
      try {
        const status = statusToSet;
        await onSubmit(
          status,
          {
            ...infoWeNeed,
            mediaInfo: mediaInfoForSaving || infoWeNeed.mediaInfo,
          },
          infoWeNeed.mediaInfo // original mediaInfo containing actual "Files"
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('onSubmit setContentStatus error : ', e);
      }
      setSaving(false);
      onCancel();
    } else {
      setSaving(false);
    }
  };

  const renderButtonWording = () => {
    if (saving) {
      return 'Saving...';
    }
    if (statusToSet === 'completed') {
      return 'Complete';
    }
    if (statusToSet === 'pending-approval') {
      return 'Request approval';
    }
    return 'Submit';
  };

  let disableSubmit = false;
  if (
    completionType === 'text' &&
    (!infoWeNeed.textResponse || !infoWeNeed.textResponse.length)
  ) {
    disableSubmit = true;
  } else if (
    (completionType === 'image' ||
      completionType === 'video' ||
      completionType === 'pdf') &&
    (!infoWeNeed.mediaInfo || !infoWeNeed.mediaInfo.length)
  ) {
    disableSubmit = true;
  }

  let acceptedType = 'image/*';
  if (completionType === 'pdf') {
    acceptedType = 'application/pdf';
  } else if (completionType === 'video') {
    acceptedType = 'video/*';
  }
  const revokeUrls = files => {
    files.forEach(file => {
      if (file && file.uri) {
        URL.revokeObjectURL(file.uri);
      }
    });
  };

  const isThereMediaInfo =
    infoWeNeed && infoWeNeed.mediaInfo && infoWeNeed.mediaInfo.length > 0;

  // if no statusToSet is passed in then we won't try to assume anything and return null
  if (!statusToSet) return null;

  return (
    <Dialog open onClose={handleCancel} aria-labelledby="simple-dialog-title">
      <Grid container className={classes.taskDialog}>
        <Grid item xs={12}>
          <DialogTitle id="simple-dialog-title" className={classes.title}>
            Task Completion Requirement
          </DialogTitle>
          {!completionInfo || !completionInfo.instructions ? (
            <DialogContentText className={classes.subTitle}>
              This task requires {completionType === 'image' ? 'an ' : 'a '}
              {completionType} based response to complete
            </DialogContentText>
          ) : (
            <DialogContentText className={classes.subTitle}>
              {completionInfo.instructions}
            </DialogContentText>
          )}
        </Grid>
        <Grid item xs={10}>
          {(completionType === 'image' ||
            completionType === 'video' ||
            completionType === 'pdf') && (
            <Grid container>
              <Grid item xs={12} className={classes.uploadAreaWrapper}>
                <Dropzone
                  ref={dropzoneLevelRef}
                  accept={acceptedType}
                  multiple={completionType === 'image'}
                  maxSize={
                    completionType === 'pdf'
                      ? MAX_PDF_FILE_SIZE
                      : MAX_UPLOAD_TOTAL_FILESIZE_BYTES
                  }
                  onDropRejected={() => {
                    enqueueSnackbar(
                      `Sorry that file is too large or the wrong file type. Max size is ${MAX_UPLOAD_TOTAL_FILESIZE_BYTES /
                        1000 /
                        1000}Mb.`,
                      {
                        variant: 'error',
                        persist: false,
                      }
                    );
                  }}
                  onDrop={acceptedFiles => {
                    let acceptedFilesParam = acceptedFiles;
                    if (isThereMediaInfo) {
                      // revoke existing files if any
                      revokeUrls(infoWeNeed.mediaInfo);
                    }

                    if (
                      completionType === 'pdf' &&
                      acceptedFilesParam.length > 0
                    ) {
                      setdropPDF(true);
                      setPdfName(acceptedFilesParam[0].name);
                    } else if (completionType === 'video') {
                      if (acceptedFilesParam.length === 0) {
                        return;
                      }
                      if (acceptedFilesParam.length > 1) {
                        enqueueSnackbar('Only 1 file allowed', {
                          variant: 'warning',
                        });
                        acceptedFilesParam = acceptedFilesParam.slice(0, 1);
                      }
                    } else if (completionType === 'image') {
                      if (acceptedFilesParam.length > MAX_IMAGES_IN_GALLERY) {
                        enqueueSnackbar(
                          `Maximum of ${MAX_IMAGES_IN_GALLERY} image.`,
                          {
                            variant: 'warning',
                          }
                        );
                        acceptedFilesParam = acceptedFilesParam.slice(
                          0,
                          MAX_IMAGES_IN_GALLERY
                        );
                      }
                    }

                    acceptedFilesParam.forEach((file, index) => {
                      acceptedFilesParam[index].uri = URL.createObjectURL(file);
                    });
                    setInfoWeNeed({
                      ...infoWeNeed,
                      mediaInfo: acceptedFilesParam,
                    });
                    setMediaInfoChanged(true);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      style={{ height: isThereMediaInfo ? null : '100%' }}
                      {...getRootProps()}
                    >
                      <Grid item>
                        <input {...getInputProps()} />
                        {!isThereMediaInfo && (
                          <>
                            <CloudUploadOutlinedIcon
                              fontSize="inherit"
                              color="inherit"
                            />
                            <Typography variant="body1">
                              Drag &apos;n&apos; drop the file(s) here, or click
                              here to select them
                            </Typography>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Dropzone>
                {/* if type is image and infoWeNeed.mediaInfo exist display */}
                {completionType === 'video' &&
                  infoWeNeed.mediaInfo &&
                  infoWeNeed.mediaInfo.length > 0 && (
                    <>
                      {_.get(infoWeNeed, 'mediaInfo[0].uri') &&
                      infoWeNeed.mediaInfo[0].uri.indexOf('cloudinary') > -1 ? (
                        // Handle if it's cloudinary
                        <ContentVideo
                          contentPiece={{
                            contentUrl: JSON.stringify(
                              infoWeNeed.mediaInfo,
                              null,
                              2
                            ),
                          }}
                        />
                      ) : (
                        // eslint-disable-next-line jsx-a11y/media-has-caption
                        <video
                          id="previewwww"
                          controls
                          style={{
                            maxWidth: '100%',
                            height: 'auto',
                            maxHeight: 300,
                          }}
                          // autoplay
                          src={
                            infoWeNeed.mediaInfo && infoWeNeed.mediaInfo[0]
                              ? infoWeNeed.mediaInfo[0].uri
                              : null
                          }
                        />
                      )}
                    </>
                  )}
                {completionType === 'pdf' && dropPDF && (
                  <Grid item xs={12}>
                    <PictureAsPdfIcon className={classes.pdfIcon} />
                    <Typography style={{ paddingBottom: '10px' }}>
                      {pdfName}
                    </Typography>
                  </Grid>
                )}
                {completionType === 'image' && isThereMediaInfo && (
                  <ContentGallery
                    hideDownload
                    contentPiece={{
                      contentUrl: JSON.stringify(infoWeNeed.mediaInfo, null, 2),
                    }}
                  />
                )}
                {completionType === 'pdf' && isThereMediaInfo && !dropPDF && (
                  <ContentGallery
                    hideDownload
                    contentPiece={{
                      contentUrl: JSON.stringify(infoWeNeed.mediaInfo, null, 2),
                    }}
                  />
                )}
              </Grid>
              {isThereMediaInfo && (
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <Button
                    color="primary"
                    onClick={e => {
                      e.preventDefault();
                      if (dropzoneLevelRef && dropzoneLevelRef.current) {
                        dropzoneLevelRef.current.open();
                      }
                    }}
                  >
                    Change...
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
          <TextField
            style={{ width: '100%', marginTop: 16 }}
            placeholder="Enter response here..."
            value={infoWeNeed.textResponse}
            onChange={event => {
              setInfoWeNeed({
                ...infoWeNeed,
                textResponse: event.target.value,
              });
            }}
          />
        </Grid>
        <Grid container className={classes.taskButtonContainer}>
          <Grid item xs={5} className={classes.taskButton}>
            <Button className={classes.cancel} onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={5} className={classes.taskButton}>
            <Button
              disabled={disableSubmit}
              className={classes.submit}
              style={{
                backgroundColor: disableSubmit ? '#ccc' : '#397ca5',
              }}
              onClick={handleSubmit}
            >
              {renderButtonWording()}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {saving && (
        <LoadingCover>
          {completionType === 'video' ? (
            <>
              <Typography variant="h3" align="center">
                Saving...
                {uploadProgressPercent ? `${uploadProgressPercent}%` : ''}
              </Typography>
              <Typography variant="h3" align="center">
                For long or high quality videos this can take a bit.
              </Typography>
            </>
          ) : (
            <Typography variant="h3" align="center">
              Saving...
            </Typography>
          )}
        </LoadingCover>
      )}
    </Dialog>
  );
};

function mapStateToProps(state) {
  return {
    userId: state.userInfo.userId,
    uploadProgressPercent: state.appState.uploadProgressPercent || null,
  };
}

export default connect(mapStateToProps)(withSnackbar(TaskCompletionDialog));
