import React from 'react';
import { Grid } from '@material-ui/core';

const ActionButtonHolder = ({
  children,
  extraContainerStyles = {},
  extraButtonsStyles = {},
}) => {
  return (
    <Grid
      container
      spacing={1}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 16,
        ...extraContainerStyles,
      }}
    >
      <Grid
        item
        style={{
          borderRadius: 30,
          border: '1px solid #ccc',
          paddingLeft: 16,
          paddingRight: 16,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...extraButtonsStyles,
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default ActionButtonHolder;
