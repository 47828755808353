import React from 'react';
import _ from 'lodash';

import { Grid, Typography } from '@material-ui/core';
import { CONTENT_TYPE } from '../../../config/appDefaults';
import ChooseFriends from '../choose-friends';

const NewConversationPermissions = ({
  userId,
  myCrew,
  myCrewLoading,
  values,
  setValues,
  existingConversations,
  handleSubmit,
  setConvoIsGroup,
  setPermissionsPicked,
  goToConversation,
}) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Grid item>
        <Typography variant="h3" gutterBottom align="center" color="primary">
          Please choose who you&apos;d like to include:
        </Typography>
      </Grid>
      <Grid item style={{ minWidth: '30%' }}>
        {myCrewLoading ? (
          'Loading Crew...'
        ) : (
          <ChooseFriends
            data={myCrew}
            onFinished={list => {
              setValues(
                {
                  ...values,
                  allowedToEdit: [...list, userId],
                  allowedToView: [],
                  title: list.length > 1 ? '' : 'hold',
                  description: list.length > 1 ? '' : 'hold',
                },
                false
              );

              // Should be treated as group chat instead of 1-o-1
              const isOneOnOneChat = list.length === 1;

              // Check if user already has 1-o-1 conversation with other user
              let existingConvoId = null;
              if (isOneOnOneChat) {
                for (let j = 0; j < existingConversations.length; j += 1) {
                  const conversation = existingConversations[j];
                  if (conversation.type === CONTENT_TYPE.CONVERSATION) {
                    if (
                      _.isEqual(conversation.allowedToEdit, [
                        ...list,
                        userId,
                      ]) &&
                      (conversation.title === 'hold' ||
                        conversation.title === null)
                    ) {
                      existingConvoId = conversation.contentId;
                      break;
                    }
                  }
                }
              }

              if (!existingConvoId) {
                // Create conversation
                if (list.length === 1) {
                  // Need to delay to execute after setValues state updates
                  setTimeout(() => {
                    handleSubmit();
                  }, 50);
                } else if (list.length > 1) {
                  // If group conversation
                  setConvoIsGroup(true);
                  setPermissionsPicked(true);
                }
              } else {
                // Go to conversation
                goToConversation(existingConvoId);
              }
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default NewConversationPermissions;
