import gql from 'graphql-tag';

export default gql`
  fragment BookkeepingAlertFields on BookkeepingAlert {
    id
    companyId
    alertIdentifier
    alertIdentifierSub
    enabled
    recipientType
    recipient
    notificationMethod
  }
`;
