import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  InputLabel,
} from '@material-ui/core';
import { Style as LabelIcon } from '@material-ui/icons';
import { useMutation } from 'react-apollo-hooks';

import FormikCreatableSelect from '../../components/formik-custom-components/formik-creatable-select';
import LoadingCover from '../../components/LoadingCover/loadingCover';

import UpdateContentMutaion from '../../graphql/mutations/mutation_updateContent';
import UpdateJrnMutation from '../../graphql/mutations/mutation_updateJrn';
import { batchRequest, monetaryRender } from '../../helpers';

const useStyles = makeStyles({
  actionContainer: {
    padding: '8px 24px 8px 24px',
  },
});

const BulkAddLabelsDialog = props => {
  const {
    open,
    handleClose,
    handleDone,
    contentLabelSuggestions,
    contentMap,
  } = props;

  const classes = useStyles();

  const [labelsToAdd, setLabelsToAdd] = useState([]);
  const [updatingContent, setUpdatingContent] = useState(false);
  const [showFailedDialog, setShowFailedDialog] = useState({
    open: false,
    failedContents: [],
  });
  const [noOfUpdatedContents, setNoOfUpdatedContents] = useState(0);
  const [progressValue, setProgressValue] = useState(0);

  const [updateContent] = useMutation(UpdateContentMutaion);
  const [updateJrn] = useMutation(UpdateJrnMutation);

  const allLabelOptions = useMemo(() => {
    return _.map(contentLabelSuggestions, label => ({ label, value: label }));
  }, [contentLabelSuggestions]);

  const runUpdateContent = async contents => {
    if (_.isEmpty(labelsToAdd) || _.isEmpty(contents)) {
      return;
    }

    setUpdatingContent(true);

    const contentsToUpdate = [];
    _.forEach(contents, content => {
      const existingLabels = content.labels ? content.labels.split(', ') : [];
      const addedLabels = _.map(labelsToAdd, label => label.value);
      const newLabels = _.uniq([...existingLabels, ...addedLabels]);

      // only update if there are new labels added
      if (existingLabels.length !== newLabels.length) {
        newLabels.sort();
        contentsToUpdate.push({
          contentId: content.contentId,
          jrnId: content.projectId,
          labels: newLabels,
          globalExpenseId: content.globalExpenseId,
        });
      }
    });

    const { rejectedItems, fulfilledItems } = await batchRequest({
      items: contentsToUpdate,
      actionFunction: ({ globalExpenseId, ...contentPayload }) => {
        if (globalExpenseId) {
          updateJrn({
            variables: {
              contentId: globalExpenseId,
              labels: contentPayload.labels,
            },
          });
        }
        return updateContent({ variables: contentPayload });
      },
      noOfItemsPerBatch: 5,
      progressUpdate: setProgressValue,
    });

    const failedContents = _.map(
      rejectedItems,
      ({ item }) => contentMap[item.contentId]
    );

    setUpdatingContent(false);

    setNoOfUpdatedContents(
      currentState => currentState + fulfilledItems.length
    );

    if (_.isEmpty(failedContents)) {
      handleDone({ noOfUpdatedContents, shouldRefetch: false });
    } else {
      setShowFailedDialog({ open: true, failedContents });
    }
  };

  const onDoneClick = async () => {
    const contents = [];
    _.forEach(contentMap, content => {
      contents.push(content);
    });

    await runUpdateContent(contents);
  };

  const onCloseClick = () => {
    setShowFailedDialog({ open: false, failedContents: [] });
    handleDone({ noOfUpdatedContents, shouldRefetch: true });
  };

  const onTryAgainClick = () => {
    const contentsToTry = [...showFailedDialog.failedContents];
    setShowFailedDialog({ open: false, failedContents: [] });
    runUpdateContent(contentsToTry);
  };

  const monetizeAmount = moneyValue => {
    return monetaryRender({ value: moneyValue, withDecimals: true });
  };

  const noOfContents = _.keys(contentMap).length;
  const dialogTitle = `Bulk Edit ${noOfContents} ${
    noOfContents > 1 ? 'Records' : 'Record'
  }`;

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose(event, reason);
          }
        }}
        disableEscapeKeyDown
      >
        <DialogTitle disableTypography>
          <Grid container alignItems="center">
            <LabelIcon />
            &nbsp;
            <Typography variant="h6">{dialogTitle}</Typography>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            spacing={3}
            style={{ minHeight: 100, marginTop: 0, marginBottom: 2 }}
            alignContent="flex-start"
          >
            <Grid item xs={12} style={{ zIndex: 1 }}>
              <InputLabel htmlFor="labels">Add Labels</InputLabel>
              <FormikCreatableSelect
                options={allLabelOptions}
                value={labelsToAdd}
                onChange={(name, value) => {
                  setLabelsToAdd(value || []);
                }}
                placeholder="Select or type to create new ..."
                name="labels"
                isMulti
                menuPlacement="bottom"
                menuPortalTarget={document.body}
              />
              <Typography variant="caption">
                Useful when filtering later
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className={classes.actionContainer}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            flex={1}
          >
            <Button onClick={handleClose} color="primary" autoFocus>
              Cancel
            </Button>
            <Button
              onClick={onDoneClick}
              variant="contained"
              color="primary"
              autoFocus
              disabled={_.isEmpty(labelsToAdd)}
            >
              Done
            </Button>
          </Grid>
        </DialogActions>
        {updatingContent && (
          <LoadingCover
            loader="linear"
            variant={noOfContents > 10 ? 'determinate' : 'indeterminate'}
            value={progressValue}
          >
            <Typography variant="h3" align="center">
              Bulk updating...
            </Typography>
          </LoadingCover>
        )}
      </Dialog>
      {showFailedDialog.open && (
        <Dialog
          open={open}
          maxWidth="xl"
          fullWidth
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setShowFailedDialog({ open: false, failedContents: [] });
            }
          }}
          disableEscapeKeyDown
        >
          <DialogTitle disableTypography>
            <Grid container alignItems="center">
              <LabelIcon />
              &nbsp;
              <Typography variant="h6">Bulk Edit Info</Typography>
            </Grid>
          </DialogTitle>

          <DialogContent>
            <Grid style={{ marginTop: 0, marginBottom: 16 }}>
              <Typography>Unable to update the following record(s):</Typography>
            </Grid>
            <TableContainer component={Paper} style={{ marginBottom: 16 }}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Username</TableCell>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Labels</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {showFailedDialog.failedContents.map(content => (
                    <TableRow key={content.contentId}>
                      <TableCell>{content.username}</TableCell>
                      <TableCell>{content.projectName}</TableCell>
                      <TableCell>{content.labels}</TableCell>
                      <TableCell>
                        {moment(content.date).format('MMM D, YYYY @ h:mma')}
                      </TableCell>
                      <TableCell>{content.type}</TableCell>
                      <TableCell align="right">
                        {monetizeAmount(content.amount)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>

          <DialogActions className={classes.actionContainer}>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              flex={1}
            >
              <Button onClick={onCloseClick} color="primary" autoFocus>
                Ignore &amp; Close
              </Button>
              <Button
                onClick={onTryAgainClick}
                variant="contained"
                color="primary"
                autoFocus
                disabled={_.isEmpty(labelsToAdd)}
              >
                Try Again
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  contentLabelSuggestions: state.appState.contentLabelSuggestions || [],
});

export default connect(mapStateToProps)(BulkAddLabelsDialog);
