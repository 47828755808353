import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose, withApollo } from 'react-apollo';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Checkbox,
  CircularProgress,
  Button,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  AttachMoney as PayIcon,
  HelpOutline as HelpIcon,
} from '@material-ui/icons';
import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';
import moment from 'moment';
import uuid from 'uuid';

import GetJrn from '../../graphql/queries/GetJrn';
import GetAllContentByGlobalExpenseId from '../../graphql/queries/get-all-content-by-global-expense-id';
import GetAllContentByJrnId from '../../graphql/queries/GetAllContentByJrnId';
import UpdateJrnMutation from '../../graphql/mutations/mutation_updateJrn';
import {
  CreateCommentMutationAction,
  DeleteContentAction,
  DeleteJrnAction,
  GetCompanyCrewAction,
} from '../../graphql/graphql';
import Comments from '../ProjectContentListing/Comments/comments';
import CommentInput from '../ProjectContentListing/Comments/CommentInput';
import LoadingCover from '../LoadingCover/loadingCover';
import ContentGallery from '../ProjectContentListing/ContentTypes/contentGallery';
import ContentImageInfoBlock from '../ProjectContentListing/ContentTypes/content-image/content-image-info-block';
import OkCancelDialog from '../OkCancelDialog/okCancelDialog';

import styles from './view-global-item.styles';
import { asCurrency } from '../dashboard/dashboard.utils';

import GlobalExpenseSplits from './global-expense-splits';
import GlobalPaymentSplits from './global-payment-splits';
import { getStatusDisplayInfo } from '../../helpers/bill-payments';
import {
  CONTENT_DEFINITION,
  CONTENT_TYPE,
  GLOBAL_EXPENSE_STATUS,
  PAYMENT_METHOD,
  PAYMENT_METHOD_LABEL,
  WORKFLOW_STAGE,
  RFI_SOURCES,
  PRODUCT,
  WORKFLOW_STAGE_REASON,
} from '../../config/appDefaults';
import { PAYMENT_TERMS_LABELS } from '../../views/add-to-project/add-content-form/add-content-form.constants';
import palette from '../../theme/palette';
import ViewOrEditRfi from '../ProjectContentListing/view-or-edit-rfi';
import { RelatedRfisFinancialItems } from '../related-rfis-financial-items/related-rfis-financial-tems';

const useStyles = makeStyles(styles);

const ViewGlobalItem = ({
  // passed in props
  targetContent,
  onMarkAsPaid,
  showFooter,
  onEditButtonClick,
  onViewGlobalItem,
  onDeleteBillPayment,
  getNextItem,
  showAccountingStatus,
  fromWhichAdminTool,
  includeArchive,
  hideDeleteButton,
  onClose,
  readonly,
  rfiViewMode,

  // HOC props
  managingCompanyInfo,
  client,
  companyCrew,
  onAddComment,
  onDeleteContent,
  onDeleteJrn,
}) => {
  const classes = useStyles();

  const [
    showDeleteConfirmationDialog,
    setShowDeleteConfirmationDialog,
  ] = useState({ open: false });
  const [showMessageDialog, setShowMessageDialog] = useState({ open: false });
  const [viewOrEditRfiDialog, setViewOrEditRfiDialog] = useState({
    requestId: null,
  });
  // const [createOrEditPanel, setCreateOrEditPanel] = useState({ open: false });

  const [updateWorkflowStage, { loading: workflowStageSaving }] = useMutation(
    UpdateJrnMutation
  );
  const [
    updateWorkflowStageReason,
    { loading: workflowStageReasonSaving },
  ] = useMutation(UpdateJrnMutation);

  const globalItemQuery = useQuery(GetJrn, {
    variables: { jrnId: targetContent.contentId },
    fetchPolicy: 'cache-and-network',
    skip: !targetContent?.contentId,
  });

  const globalItem = _.get(globalItemQuery, 'data.getJrn', null);

  const {
    loading: globalItemLoading,
    startPolling: startPollingGlobalItem,
    stopPolling: stopPollingGlobalItem,
  } = globalItemQuery;

  const isCompanyAdmin =
    managingCompanyInfo && managingCompanyInfo.isCompanyAdmin;
  const isBookkeepingCustomer =
    managingCompanyInfo && managingCompanyInfo.isBookkeepingCustomer;
  const isCompanyBookkeeper =
    managingCompanyInfo && managingCompanyInfo.isCompanyBookkeeper;
  // disabled if they are a bookkeeping customer and not a bookkeeper
  // or if they are not a company admin
  const allowFor =
    (isBookkeepingCustomer && isCompanyBookkeeper) ||
    (!isBookkeepingCustomer && isCompanyAdmin);
  const disableAccessToIsRecorded = !allowFor;

  const hasRfiProduct = managingCompanyInfo?.products?.includes(PRODUCT.RFI);

  const [showCommentInput, setShowCommentInput] = useState(false);

  const isGlobalBill = globalItem?.type === CONTENT_TYPE.GLOBAL_BILL;
  const isGlobalReceipt = globalItem?.type === CONTENT_TYPE.GLOBAL_RECEIPT;
  const isGlobalPayment = globalItem?.type === CONTENT_TYPE.GLOBAL_PAYMENT;
  const isGlobalExpense = isGlobalBill || isGlobalReceipt;

  const amountAsCurrency = asCurrency(
    (globalItem?.amount && globalItem.amount.value) || 0
  );

  const balanceDueAsCurrency = globalItem?.balance
    ? asCurrency(globalItem.balance.value)
    : amountAsCurrency;

  const addingUser = useMemo(() => {
    if (companyCrew && globalItem) {
      return _.find(companyCrew, {
        userId: globalItem && globalItem.creatorId,
      });
    }

    return null;
  }, [companyCrew, globalItem]);

  let billInfoDetails = null;
  if (isGlobalBill) {
    billInfoDetails = getStatusDisplayInfo({ bill: globalItem });
  }

  const labels = useMemo(() => {
    return _.join(globalItem?.labels, ', ');
  }, [globalItem]);

  const handleMarkAsPaid = () => {
    onMarkAsPaid({ bills: [globalItem] });
  };

  const handleDeleteGlobalItemConfirm = async () => {
    let contentItemsToDelete = [];
    if (isGlobalExpense) {
      let allContents = [];
      try {
        const contentQuery = await client.query({
          query: GetAllContentByGlobalExpenseId,
          variables: { globalExpenseId: globalItem.contentId },
          fetchPolicy: 'network-only',
        });
        allContents = _.get(
          contentQuery,
          'data.getAllContentByGlobalExpenseId.items',
          []
        );
      } catch (e) {
        // ignore error
      }

      if (isGlobalBill) {
        const expenses = [];
        const billPayments = [];
        _.forEach(allContents, content => {
          if (content.type === CONTENT_TYPE.BILL_PAYMENT) {
            billPayments.push(content);
          } else {
            expenses.push(content);
          }
        });
        if (!_.isEmpty(billPayments)) {
          setShowMessageDialog({
            open: true,
            message:
              'Cannot delete this bill as it has payments. Please delete all the payments first!',
          });
          return;
        }

        contentItemsToDelete = expenses;
      } else if (isGlobalReceipt) {
        contentItemsToDelete = allContents;
      }
    } else if (isGlobalPayment) {
      try {
        const contentQuery = await client.query({
          query: GetAllContentByJrnId,
          variables: { jrnId: globalItem.contentId },
          fetchPolicy: 'network-only',
        });
        contentItemsToDelete = _.get(
          contentQuery,
          'data.getAllContentByJrnId.items',
          []
        );
      } catch (e) {
        // ignore error
      }
    }

    await Promise.all(
      _.map(contentItemsToDelete, contentItem => {
        return onDeleteContent(contentItem.contentId, contentItem.jrnId, {
          fromWhichAdminTool,
          includeArchive,
          content: contentItem,
        });
      })
    );

    await onDeleteJrn(globalItem.contentId, globalItem.type);
    setShowDeleteConfirmationDialog({ open: false });
    if (getNextItem) {
      getNextItem('next');
    } else {
      // if there is no next item, close the content-details dialog
      onClose();
    }
  };

  const loadExistingRfi = async ({ rfiId }) => {
    setViewOrEditRfiDialog({ requestId: rfiId });
  };

  const handleViewOrEditRfi = ({ requestId, newRfiInfo }) => {
    setViewOrEditRfiDialog({
      requestId,
      newRfiInfo,
    });
  };

  const handleCreateRfi = () => {
    if (globalItem.type) {
      const newRfiInfo = {
        relatedItemInfo: globalItem,
        baseInfo: {
          requestId: uuid(),
          txnDate: moment(),
          dateCreated: moment().toISOString(),
          local: true,
          requestSource: RFI_SOURCES.LEVEL.value,
          idOfRelatedItem: globalItem.contentId,
          projectId: globalItem.jrnId,
          parentPath: null,
        },
      };
      handleViewOrEditRfi({ newRfiInfo });
    }
  };

  const renderOnlyBookkeeperExplanation = () => (
    <Tooltip title="This field can only be changed by bookkeepers">
      <HelpIcon
        fontSize="small"
        color="primary"
        style={{ marginLeft: 4, marginTop: -2 }}
      />
    </Tooltip>
  );

  return (
    <Grid
      container
      className={
        showFooter ? classes.mainContainerWithFooter : classes.mainContainer
      }
    >
      <Grid item xs={6}>
        <ContentGallery
          contentPiece={globalItem}
          glassMagnifier
          showCopyUrl
          compactView
        />
      </Grid>
      <Grid item xs={6} className={classes.rightWrapper}>
        <Grid container>
          {billInfoDetails && (
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-end"
              spacing={1}
              style={{ marginBottom: '8px' }}
            >
              <Grid item>
                <ContentImageInfoBlock
                  color={billInfoDetails.color}
                  primaryText={billInfoDetails.primaryText}
                  secondaryText={billInfoDetails.secondaryText}
                  small
                  fullHeight
                  label
                />
              </Grid>
              {!readonly &&
                (globalItem.contentStatus === GLOBAL_EXPENSE_STATUS.UNPAID ||
                  globalItem.contentStatus ===
                    GLOBAL_EXPENSE_STATUS.PARTIALLY_PAID) && (
                  <Grid item>
                    <Button
                      variant="contained"
                      style={{
                        height: '100%',
                        backgroundColor: palette.brandColorGreen,
                        color: '#FFFFFF',
                      }}
                      onClick={handleMarkAsPaid}
                    >
                      <PayIcon />
                      Make a Payment
                    </Button>
                  </Grid>
                )}
            </Grid>
          )}
          <Grid item xs={12} className={classes.infoSection}>
            <div className={classes.label}>Type</div>{' '}
            {globalItem &&
              globalItem.type &&
              CONTENT_DEFINITION[globalItem.type].name}
          </Grid>
          <Grid item xs={12} className={classes.infoSection}>
            <div className={classes.label}>Vendor</div>{' '}
            {globalItem && globalItem.vendor && globalItem.vendor.name}
          </Grid>
          {isGlobalBill && (
            <Grid item xs={6} className={classes.infoSection}>
              <div className={classes.label}>Bill Number</div>{' '}
              {globalItem && globalItem.documentNumber}
            </Grid>
          )}
          {isGlobalExpense && (
            <Grid item xs={6} className={classes.infoSection}>
              <div className={classes.label}>PO Number</div>{' '}
              {globalItem && globalItem.poNumber}
            </Grid>
          )}
          <Grid item xs={6} className={classes.infoSection}>
            <div className={classes.label}>Amount</div>{' '}
            {globalItem && globalItem.contentStatus === 'refund' && '-'}
            {amountAsCurrency}
          </Grid>
          {isGlobalBill && (
            <Grid item xs={6} className={classes.infoSection}>
              <div className={classes.label}>Balance Due</div>{' '}
              {balanceDueAsCurrency}
            </Grid>
          )}
          {isGlobalPayment && (
            <>
              <Grid item xs={6} className={classes.infoSection}>
                <div className={classes.label}>Method</div>{' '}
                {globalItem &&
                  globalItem.subtype &&
                  PAYMENT_METHOD_LABEL[globalItem.subtype]}
              </Grid>
              {globalItem.subtype === PAYMENT_METHOD.CHECK && (
                <Grid item xs={6} className={classes.infoSection}>
                  <div className={classes.label}>Check Number</div>{' '}
                  {globalItem && globalItem.documentNumber}
                </Grid>
              )}
            </>
          )}
          {isGlobalReceipt && (
            <Grid item xs={12} className={classes.infoSection}>
              <div className={classes.label}>Is this a refund?</div>{' '}
              {globalItem && globalItem.contentStatus === 'refund'
                ? 'Yes'
                : 'No'}
            </Grid>
          )}
          <Grid item xs={6} className={classes.infoSection}>
            <div className={classes.label}>Date</div>{' '}
            {moment(globalItem?.startDate).format('MMM DD, YYYY')}
          </Grid>
          {isGlobalBill && (
            <>
              <Grid item xs={6} className={classes.infoSection}>
                <div className={classes.label}>Due Date</div>{' '}
                {moment(globalItem && globalItem.endDate).format(
                  'MMM DD, YYYY'
                )}
              </Grid>
              <Grid item xs={6} className={classes.infoSection}>
                <div className={classes.label}>Payment Terms </div>{' '}
                {PAYMENT_TERMS_LABELS[globalItem?.paymentTerms]}
              </Grid>
            </>
          )}
          <Grid item xs={6} className={classes.infoSection}>
            <div className={classes.label}>Added By</div>{' '}
            {addingUser && addingUser.username}
          </Grid>
          {!!(globalItem && globalItem.description) && (
            <Grid
              item
              xs={12}
              className={classes.infoSection}
              style={{ flexDirection: 'column' }}
            >
              <div style={{ marginBottom: 8 }}>
                <div className={classes.label}>Notes / Description</div>
              </div>
              <Grid
                container
                direction="row"
                className={classes.descriptionWrapper}
              >
                <div className="levelParsedHtml">
                  {ReactHtmlParser(globalItem.description)}
                </div>
              </Grid>
            </Grid>
          )}
          {!!labels && (
            <Grid item xs={12} className={classes.infoSection}>
              <div className={classes.label}>Labels</div> {labels}
            </Grid>
          )}
          {showAccountingStatus &&
            (isGlobalExpense || isGlobalPayment) &&
            globalItem && (
              <Grid
                item
                xs={12}
                className={classes.infoSection}
                style={{ flexDirection: 'column' }}
              >
                <div className={classes.label}>Accounting Status:</div>{' '}
                <Grid
                  container
                  style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 8 }}
                  direction="column"
                >
                  <Grid
                    container
                    item
                    alignItems="center"
                    style={{ height: 42 }}
                  >
                    <Grid
                      item
                      style={{ flex: 1, display: 'flex', alignItems: 'center' }}
                    >
                      Recorded in QuickBooks?
                      {isBookkeepingCustomer &&
                        !managingCompanyInfo?.isCompanyBookkeeper &&
                        renderOnlyBookkeeperExplanation()}
                    </Grid>
                    <Grid item style={{ flex: 0 }}>
                      {workflowStageSaving ? (
                        <CircularProgress size={19} color="primary" />
                      ) : (
                        <Checkbox
                          color="primary"
                          edge="end"
                          onChange={() =>
                            updateWorkflowStage({
                              variables: {
                                contentId: globalItem && globalItem.contentId,
                                workflowStage:
                                  globalItem &&
                                  globalItem.workflowStage ===
                                    WORKFLOW_STAGE.RECORDED
                                    ? WORKFLOW_STAGE.UNRECORDED
                                    : WORKFLOW_STAGE.RECORDED,
                              },
                            })
                          }
                          checked={
                            globalItem.workflowStage === WORKFLOW_STAGE.RECORDED
                          }
                          disabled={readonly || disableAccessToIsRecorded}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    alignItems="center"
                    style={{ height: 42 }}
                  >
                    <Grid
                      item
                      style={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      Requires more information (RFI)?
                      {!managingCompanyInfo?.isCompanyBookkeeper &&
                        renderOnlyBookkeeperExplanation()}
                    </Grid>
                    <Grid item style={{ flex: 0 }}>
                      {hasRfiProduct && (
                        <Checkbox
                          color="primary"
                          edge="end"
                          onChange={() => {
                            handleCreateRfi(globalItem);
                          }}
                          checked={!!globalItem.rfiIds?.length}
                          disabled={
                            readonly ||
                            !managingCompanyInfo?.isCompanyBookkeeper ||
                            !!globalItem.rfiIds?.length
                          }
                        />
                      )}
                      {!hasRfiProduct && workflowStageReasonSaving && (
                        <CircularProgress size={19} color="primary" />
                      )}
                      {!hasRfiProduct && !workflowStageReasonSaving && (
                        <Checkbox
                          color="primary"
                          edge="end"
                          onChange={() => {
                            updateWorkflowStageReason({
                              variables: {
                                contentId: globalItem?.contentId,
                                workflowStageReason:
                                  globalItem?.workflowStageReason ===
                                  WORKFLOW_STAGE_REASON.RFI_REQUIRED
                                    ? null
                                    : WORKFLOW_STAGE_REASON.RFI_REQUIRED,
                              },
                            });
                          }}
                          checked={
                            globalItem.workflowStageReason ===
                            WORKFLOW_STAGE_REASON.RFI_REQUIRED
                          }
                          disabled={
                            !(
                              managingCompanyInfo &&
                              managingCompanyInfo.isCompanyBookkeeper
                            )
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                  {hasRfiProduct && !rfiViewMode && (
                    <RelatedRfisFinancialItems
                      contentInfo={globalItem}
                      loadExistingRfi={loadExistingRfi}
                    />
                  )}
                </Grid>
              </Grid>
            )}
          {isGlobalExpense && (
            <GlobalExpenseSplits
              parentClasses={classes}
              globalExpense={globalItem}
              startPollingGlobalExpense={startPollingGlobalItem}
              stopPollingGlobalExpense={stopPollingGlobalItem}
              globalExpenseId={globalItem.contentId}
              selectable
              companyCrew={companyCrew}
              onViewGlobalItem={onViewGlobalItem}
              onDeleteBillPayment={onDeleteBillPayment}
              onClose={onClose}
            />
          )}
          {isGlobalPayment && (
            <GlobalPaymentSplits
              parentClasses={classes}
              globalPaymentId={globalItem.contentId}
              selectable
              onViewGlobalItem={onViewGlobalItem}
            />
          )}
          {!readonly && (
            <Grid
              container
              direction="column"
              className={classes.commentsWrapper}
            >
              <Comments
                comments={globalItem?.comments?.items || []}
                allUsers={companyCrew}
              />
              {showCommentInput ? (
                <CommentInput
                  addCommentAction={onAddComment}
                  contentItem={globalItem}
                />
              ) : (
                <Button onClick={() => setShowCommentInput(true)}>
                  + Add comment
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      {globalItemLoading && <LoadingCover />}
      {showFooter && (
        <div className={classes.footer}>
          <Grid container>
            <Grid container item style={{ flex: 1 }} />
            {getNextItem && (
              <Grid item container justifyContent="center" style={{ flex: 3 }}>
                <Button
                  onClick={() => {
                    getNextItem('previous');
                  }}
                  variant="contained"
                  startIcon={<ArrowBackIcon />}
                  style={{ marginRight: 8 }}
                >
                  Previous Item
                </Button>
                <Button
                  onClick={() => {
                    getNextItem('next');
                  }}
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                >
                  Next Item
                </Button>
              </Grid>
            )}
            {(onEditButtonClick || !hideDeleteButton) && (
              <Grid
                container
                item
                justifyContent="flex-end"
                style={{ flex: 1 }}
              >
                {!hideDeleteButton && (
                  <Button
                    onClick={() => {
                      setShowDeleteConfirmationDialog({ open: true });
                    }}
                    variant="contained"
                    style={{
                      color: 'white',
                      backgroundColor: palette.brandColorError,
                    }}
                  >
                    Delete Item
                  </Button>
                )}
                {onEditButtonClick && (
                  <Button
                    onClick={onEditButtonClick}
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 16 }}
                  >
                    Edit Item
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </div>
      )}
      {showDeleteConfirmationDialog.open && (
        <OkCancelDialog
          open={showDeleteConfirmationDialog.open}
          title="Just making sure..."
          okButtonText="Yes"
          okButtonVariant="text"
          autoFocusButton="cancelButton"
          onClose={message => {
            if (message !== 'ok') {
              // if completed successfully, the dialog will be closed by the onConfirm handler
              setShowDeleteConfirmationDialog({ open: false });
            }
          }}
          onConfirm={handleDeleteGlobalItemConfirm}
        >
          <Typography>
            Are you sure you want to delete this{' '}
            {globalItem?.type
              ? _.toLower(CONTENT_DEFINITION[globalItem.type].name)
              : 'item'}
            ? This cannot be undone.
          </Typography>
        </OkCancelDialog>
      )}
      {showMessageDialog.open && (
        <OkCancelDialog
          open={showMessageDialog.open}
          title={showMessageDialog.title || 'Oops...'}
          okButtonVariant="text"
          hideCancel
          onConfirm={
            showMessageDialog.onConfirm
              ? showMessageDialog.onConfirm
              : () => {
                  setShowMessageDialog({ open: false });
                }
          }
        >
          <Typography>
            {showMessageDialog.message || 'Something went wrong.'}
          </Typography>
        </OkCancelDialog>
      )}
      <ViewOrEditRfi
        open={
          !!viewOrEditRfiDialog.requestId || !!viewOrEditRfiDialog.newRfiInfo
        }
        newRfiInfo={viewOrEditRfiDialog.newRfiInfo}
        requestId={viewOrEditRfiDialog.requestId}
        onClose={() => {
          setViewOrEditRfiDialog({ requestId: null });
        }}
        skipGetCompanyRfisUpdate // FUTURE: If we add the ability to add a new RFI related to content from the admin module, this will need to be removed
      />
    </Grid>
  );
};

const mapStateToProps = state => ({
  userId: state.userInfo && state.userInfo.userId,
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default compose(
  connect(mapStateToProps),
  DeleteContentAction,
  DeleteJrnAction,
  CreateCommentMutationAction,
  GetCompanyCrewAction,
  withApollo
)(ViewGlobalItem);
