import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo-hooks';
import _ from 'lodash';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { SearchInput } from '..';
import FindQboCustomers from '../../graphql/queries/find-qbo-customers';
import FindQboSubcustomers from '../../graphql/queries/find-qbo-subcustomers';
import QboExistingCustomerTable from './qbo-existing-customer-table';

function QboCustomerSearch({
  managingCompanyInfo,
  initialValue,
  handleSelection,
  parentCustomerInfo = null,
  allowEmpty = false,
  searchOnMount = false,
  searchByParentQboId = false,
}) {
  const [searchInputString, setSearchInputString] = useState(initialValue);
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchResults, setSearchResults] = useState(null);

  const handleChange = event => {
    const newValue = _.get(event, 'currentTarget.value', '');
    setSearchInputString(newValue);
  };

  const searchForQboCustomer = () => {
    setSearchTerm(searchInputString);
  };

  const companyId =
    managingCompanyInfo && managingCompanyInfo.managingCompanyId;

  const {
    loading: findQboCustomersLoading,
    data: findQboCustomersResult,
  } = useQuery(FindQboCustomers, {
    skip: !companyId || searchTerm === null || parentCustomerInfo,
    variables: {
      companyId,
      searchTerm: searchTerm || null,
      parentCustomerId: parentCustomerInfo
        ? parentCustomerInfo.customerId
        : null,
    },
    fetchPolicy: 'network-only',
  });

  const {
    loading: findQboSubcustomersLoading,
    data: findQboSubcustomersResult,
  } = useQuery(FindQboSubcustomers, {
    skip: !companyId || searchTerm === null || !parentCustomerInfo,
    variables: {
      companyId,
      searchTerm: searchTerm || null,
      parentCustomerId: parentCustomerInfo && parentCustomerInfo.customerId,
      useQboId: searchByParentQboId,
    },
    fetchPolicy: 'network-only',
  });

  const isSearching = findQboCustomersLoading || findQboSubcustomersLoading;

  const preventSubmit =
    (!isSearching && searchTerm === searchInputString) ||
    (!allowEmpty && !searchInputString);

  useEffect(() => {
    if (searchOnMount && !preventSubmit) {
      searchForQboCustomer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (findQboCustomersResult) {
      const foundCustomers = _.get(
        findQboCustomersResult,
        'findQboCustomers.items'
      );
      if (foundCustomers) {
        setSearchResults(foundCustomers);
      }
    }

    if (findQboSubcustomersResult) {
      const foundSubcustomers = _.get(
        findQboSubcustomersResult,
        'findQboSubcustomers.items'
      );

      if (foundSubcustomers) {
        setSearchResults(foundSubcustomers);
      }
    }
  }, [findQboCustomersResult, findQboSubcustomersResult]);

  let noDataMessage;
  if (isSearching) {
    noDataMessage = 'Searching...';
  } else if (searchResults === null) {
    noDataMessage = 'Search to find existing QuickBooks Online customers';
  } else {
    noDataMessage = 'No QuickBooks Online customers found in search';
  }

  return (
    <Grid container>
      <Grid container item style={{ paddingBottom: 16 }}>
        <SearchInput
          onChange={handleChange}
          onSubmit={() => {
            if (!preventSubmit) {
              searchForQboCustomer();
            }
          }}
          initialValue={searchInputString}
          disabled={!!findQboCustomersLoading}
          style={{ flex: '1' }}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 4, width: 100 }}
          onClick={searchForQboCustomer}
          disabled={preventSubmit}
        >
          {!isSearching ? (
            'Search'
          ) : (
            <CircularProgress size={20} style={{ color: '#ffffff' }} />
          )}
        </Button>
      </Grid>
      <Grid container item style={{ minHeight: 350 }}>
        <QboExistingCustomerTable
          noDataMessage={noDataMessage}
          searchResults={searchResults}
          setSelectedRemoteCustomer={handleSelection}
          isSubcustomerList={!!parentCustomerInfo}
        />
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
  };
}

export default connect(mapStateToProps)(QboCustomerSearch);
