import gql from 'graphql-tag';

export default gql`
  query TriggerRfiClientEmail($companyId: String!, $requestIds: [String!]!) {
    triggerRfiClientEmail(
      input: { companyId: $companyId, requestIds: $requestIds }
    ) {
      status
      msg
    }
  }
`;
