import React from 'react';

import { CircularProgress, Grid, LinearProgress } from '@material-ui/core';

import camWave from '../../assets/images/cam/copilot_mascot_003.png';

const LoadingCover = ({
  children,
  loader,
  customStyles = {},
  variant = 'indeterminate',
  value,
  position = 'absolute',
  withCam = false,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        position,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: '#fff',
        opacity: 0.85,
        zIndex: 9998,
        ...customStyles,
      }}
    >
      {withCam && (
        <div style={{ marginBottom: 4 }}>
          <Grid container alignItems="center">
            <img
              src={camWave}
              alt="robot waving"
              style={{ height: 80, marginRight: 16 }}
            />
            {children}
          </Grid>
        </div>
      )}
      {loader === 'linear' ? (
        <LinearProgress
          variant={variant}
          style={{ width: '50%' }}
          value={value}
        />
      ) : (
        <CircularProgress variant={variant} value={value} />
      )}
      {!withCam && <div style={{ marginTop: 16 }}>{children}</div>}
    </div>
  );
};

export default LoadingCover;
