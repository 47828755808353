import React, { useRef, useEffect } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { Lock as LockIcon } from '@material-ui/icons';
import palette from '../../theme/palette';

const LockableButton = ({ loading, locked, onClick, children, ...props }) => {
  const innerRef = useRef(null);

  useEffect(() => {
    const thisDiv = innerRef.current;

    if (onClick) {
      thisDiv.addEventListener('click', onClick);
    }

    return () => {
      if (onClick) {
        thisDiv.removeEventListener('click', onClick);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={innerRef}
      style={{ position: 'relative', cursor: 'pointer', width: '100%' }}
    >
      <Button disabled={loading} {...props}>
        {children}
      </Button>
      {(locked || loading) && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: palette.brandColorDarkGrey,
              opacity: 0.5,
              borderRadius: 4,
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {locked && (
              <LockIcon
                style={{
                  color: palette.brandColorLightGrey,
                }}
              />
            )}
            {loading && <CircularProgress style={{ color: '#FFFFFF' }} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default LockableButton;
