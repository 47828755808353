import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import { Switch, Route, useParams, withRouter } from 'react-router-dom';
import ListOfCustomers from './list-of-customers/list-of-customers-data';
import CustomerDetails from './customer-details/customer-details';
import GraphQL from '../../graphql';

const styles = {
  scrollableColumn: {
    overflowY: 'scroll',
    height: 'calc(100vh - 64px)',
    padding: 16,
  },
};

const Customers = ({
  history,
  managingCompanyInfo,
  companyCustomers,
  getCompanyCustomersLoading,
  getCompanyCustomersRefetch,
}) => {
  const { customerId } = useParams();

  let customerInfo = null;
  if (customerId && companyCustomers) {
    customerInfo = _.find(
      companyCustomers,
      ({ customerId: customerIdInList }) => customerIdInList === customerId
    );
  }

  const goToCustomer = goToCustomerInfo => {
    history.push(`/customers/${goToCustomerInfo.customerId}`);
  };

  return (
    <Grid container>
      <Grid item xs={4} lg={4} style={styles.scrollableColumn}>
        <ListOfCustomers
          companyCustomers={companyCustomers}
          getCompanyCustomersLoading={getCompanyCustomersLoading}
          getCompanyCustomersRefetch={getCompanyCustomersRefetch}
          goToCustomer={goToCustomer}
          idOfCustomerBeingViewed={customerId}
        />
      </Grid>
      <Grid
        container
        item
        xs={8}
        lg={8}
        style={styles.scrollableColumn}
        id="ContainerElementID"
      >
        <Switch>
          <Route path="/customers/:customerId">
            <CustomerDetails
              customerInfo={customerInfo}
              managingCompanyInfo={managingCompanyInfo}
            />
          </Route>
        </Switch>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default withRouter(
  connect(mapStateToProps)(
    compose(GraphQL.GetCompanyCustomersAction)(Customers)
  )
);
