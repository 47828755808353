import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import Customer from './customer';
import CustomerSectionHeader from './customer-section-header';

const Customers = ({
  data: listOfCustomers,
  onClickOfCustomer,
  idOfCustomerBeingViewed,
  isFiltered,
}) => {
  const groupedData = useMemo(() => {
    if (!listOfCustomers) {
      return [];
    }
    return _.groupBy(listOfCustomers, customer => {
      const whereToCheck =
        customer.lastName || customer.firstName || customer.companyName;
      if (!whereToCheck) return '';
      return whereToCheck.charAt(0).toUpperCase();
    });
  }, [listOfCustomers]);

  if (!listOfCustomers || !listOfCustomers.length) {
    if (isFiltered) {
      return 'No search results.';
    }
    return 'No customers yet.';
  }

  return (
    <>
      {_.keys(groupedData)
        .sort()
        .map(key => {
          return (
            <React.Fragment key={key}>
              <CustomerSectionHeader text={key} />
              {groupedData[key].map(customer => {
                return (
                  <Customer
                    key={customer.customerId}
                    data={customer}
                    onClick={onClickOfCustomer}
                    highlightSelected={
                      customer.customerId === idOfCustomerBeingViewed
                    }
                  />
                );
              })}
            </React.Fragment>
          );
        })}
    </>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default withRouter(connect(mapStateToProps)(Customers));
