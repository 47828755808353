import React, { useState, useRef } from 'react';
import { compose } from 'react-apollo';
import { useSnackbar } from 'notistack';
import { Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import QboSyncMenu from './qbo-sync-menu';
import GraphQL from '../../graphql';

import { QBO_SYNCABLE_TYPE, TOP_PROJECT_ID } from '../../config/appDefaults';
import { getQboSyncState, QBO_SYNC_STATE } from '../../helpers/qbo-sync';
import palette from '../../theme/palette';
import { useQboStatusUpdateChecker } from '../../hooks';

import QboLogoIconImage from '../../assets/images/quickbooks/qb-logo-icon-button.svg';
import QboLogoIconDisabledImage from '../../assets/images/quickbooks/qb-logo-icon-button-disabled.svg';

const useStyles = makeStyles(() => ({
  headerIconButton: {
    color: '#666',
  },
}));

const QboSyncActionButton = ({
  recordInfo,
  parentCustomerInfo,
  recordType,
  onSyncCustomerToNew,
  onSyncCustomerToExisting,
  onDisconnectSyncedCustomer,
  onDisconnectSyncedJrn,
  onSyncJrnToNew,
  onSyncJrnToExisting,
  onCheckForCustomerUpdates,
  onCheckForJrnUpdates,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showQboSyncMenu, setShowQboSyncMenu] = useState(false);
  const qboSyncToggleRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  useQboStatusUpdateChecker({ recordInfo, recordType });

  // Determine sync state
  const syncState = getQboSyncState(recordInfo);

  const classes = useStyles();

  const syncToNewCustomer = async () => {
    setIsLoading(true);
    if (recordType === QBO_SYNCABLE_TYPE.CUSTOMER && recordInfo) {
      try {
        await onSyncCustomerToNew(recordInfo.customerId);
      } catch (err) {
        let errorMessage;
        if (err.message.includes('Duplicate Name')) {
          errorMessage =
            'Looks like that customer already exists! Try syncing to an existing customer.';
        } else {
          errorMessage =
            "Sorry! We weren't able to sync this. Please try again later.";
        }

        enqueueSnackbar(errorMessage, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } else if (
      (recordType === QBO_SYNCABLE_TYPE.PROJECT ||
        recordType === QBO_SYNCABLE_TYPE.LEAD) &&
      recordInfo
    ) {
      try {
        await onSyncJrnToNew(recordInfo.contentId);
      } catch (err) {
        let errorMessage;
        if (err.message.includes('Duplicate Name')) {
          errorMessage =
            'Looks like a sub-customer with that name already exists! Try syncing to an existing sub-customer.';
        } else {
          errorMessage =
            "Sorry! We weren't able to sync this. Please try again later.";
        }

        enqueueSnackbar(errorMessage, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }

    // Give the customer object a chance to update before turning off the loader
    setTimeout(() => setIsLoading(false), 50);
  };

  const syncToExistingCustomer = async remoteCustomer => {
    setIsLoading(true);
    if (recordType === QBO_SYNCABLE_TYPE.CUSTOMER && recordInfo) {
      try {
        await onSyncCustomerToExisting({
          customerId: recordInfo.customerId,
          qboCustomerId: remoteCustomer.customerId,
        });
      } catch (err) {
        let errorMessage;
        if (err.message.includes('ALREADY_SYNCED')) {
          errorMessage =
            'Sorry! It looks like that customer is already synced to Level!';
        } else {
          errorMessage =
            "Sorry! We weren't able to sync this. Please try again later.";
        }

        enqueueSnackbar(errorMessage, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } else if (
      (recordType === QBO_SYNCABLE_TYPE.PROJECT ||
        recordType === QBO_SYNCABLE_TYPE.LEAD) &&
      recordInfo
    ) {
      // Update the Jrn
      try {
        await onSyncJrnToExisting({
          contentId: recordInfo.contentId,
          qboCustomerId: remoteCustomer.contentId,
        });
      } catch (err) {
        let errorMessage;
        if (err.message.includes('ALREADY_SYNCED')) {
          errorMessage =
            'Sorry! It looks like that customer is already synced to Level!';
        } else {
          errorMessage =
            "Sorry! We weren't able to sync this. Please try again later.";
        }

        enqueueSnackbar(errorMessage, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
    // Give the customer object a chance to update before turning off the loader
    setTimeout(() => setIsLoading(false), 50);
  };

  const disconnectSyncedCustomer = async () => {
    setIsLoading(true);
    if (recordType === QBO_SYNCABLE_TYPE.CUSTOMER && recordInfo) {
      await onDisconnectSyncedCustomer(recordInfo.customerId);
    } else if (
      recordType === QBO_SYNCABLE_TYPE.LEAD ||
      recordType === QBO_SYNCABLE_TYPE.PROJECT
    ) {
      await onDisconnectSyncedJrn(recordInfo.contentId);
    }
    // Give the customer object a chance to update before turning off the loader
    setTimeout(() => setIsLoading(false), 50);
  };

  const checkForUpdates = async () => {
    try {
      setIsLoading(true);
      if (recordType === QBO_SYNCABLE_TYPE.CUSTOMER && recordInfo) {
        const result = await onCheckForCustomerUpdates(recordInfo.customerId);
        if (result?.data?.checkForQboUpdatesToCustomer) {
          enqueueSnackbar('This customer was updated successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar('This customer is already up-to-date', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
      } else if (
        (recordType === QBO_SYNCABLE_TYPE.LEAD ||
          recordType === QBO_SYNCABLE_TYPE.PROJECT) &&
        recordInfo
      ) {
        const result = await onCheckForJrnUpdates(recordInfo.contentId);
        if (result?.data?.checkForQboUpdatesToJrn) {
          enqueueSnackbar(`This ${recordType} was updated successfully`, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar(`This ${recordType} is already up-to-date`, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('checkForUpdates ~ err', err);
      enqueueSnackbar(`Sorry! This ${recordType} cannot be updated`, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
    setIsLoading(false);
  };

  let searchDefault;
  let disabledReason = null;
  if (recordType === QBO_SYNCABLE_TYPE.CUSTOMER) {
    const components = [];
    if (recordInfo.firstName) {
      components.push(recordInfo.firstName);
    }
    if (recordInfo.lastName) {
      components.push(recordInfo.lastName);
    }
    if (components.length === 0 && recordInfo.companyName) {
      components.push(recordInfo.companyName);
    }
    searchDefault = components.join(' ');
  } else if (
    recordType === QBO_SYNCABLE_TYPE.PROJECT ||
    recordType === QBO_SYNCABLE_TYPE.LEAD
  ) {
    searchDefault = ''; // No search default since all sub-customers will load

    const parentCustomerState = getQboSyncState(parentCustomerInfo);
    if (recordInfo && !recordInfo.qboCustomerId) {
      if (parentCustomerInfo && parentCustomerState !== QBO_SYNC_STATE.ACTIVE) {
        disabledReason = 'Parent customer is not synced.';
      } else if (
        recordType === QBO_SYNCABLE_TYPE.LEAD &&
        !recordInfo.qboCustomerId &&
        recordInfo.jrnId &&
        recordInfo.jrnId !== TOP_PROJECT_ID
      ) {
        disabledReason = 'Lead with project cannot be synced.';
      } else if (!recordInfo.customerInfo) {
        disabledReason = `Only ${recordType}s with customers can be synced.`;
      }
    }
  }

  let iconToUse;
  if (isLoading || syncState === QBO_SYNC_STATE.SYNCING) {
    iconToUse = <CircularProgress size={20} />;
  } else if (syncState === QBO_SYNC_STATE.ACTIVE) {
    iconToUse = (
      <img alt="QuickBooks Online Sync" src={QboLogoIconImage} height="24" />
    );
  } else if (syncState === QBO_SYNC_STATE.ERROR) {
    iconToUse = (
      <ErrorIcon style={{ fontSize: 24, color: palette.brandColorOrange }} />
    );
  } else {
    iconToUse = (
      <img
        alt="QuickBooks Online Sync"
        src={QboLogoIconDisabledImage}
        height="24"
      />
    );
  }

  let tooltipText = `${
    syncState === QBO_SYNC_STATE.ACTIVE ? 'Synced' : 'Not synced'
  } with QuickBooks Online`;

  if (disabledReason) {
    tooltipText = disabledReason;
  }

  if (syncState === QBO_SYNC_STATE.ERROR) {
    // If known error, describe what needs to change for fix
    let specificReason = null;
    if (recordInfo.qboCustomerId === 'ERROR:DUPLICATE_NAME') {
      specificReason =
        ' A vendor, customer or employee in QuickBooks already has that name.';
    } else if (recordInfo.qboCustomerId === 'ERROR:ALREADY_SYNCED') {
      specificReason =
        ' The selected QuickBooks Online customer was already synced to Level.';
    } else if (recordInfo.qboCustomerId === 'ERROR:INVALID_CHARACTERS') {
      if (
        recordType === QBO_SYNCABLE_TYPE.LEAD ||
        recordType === QBO_SYNCABLE_TYPE.PROJECT
      ) {
        specificReason =
          ' The title includes invalid characters. Please remove any special characters, like a colon (:).';
      } else if (recordType === QBO_SYNCABLE_TYPE.CUSTOMER) {
        specificReason =
          ' The customer has invalid characters in its name. Please remove any special characters, like a colon (:).';
      }
    }
    // Otherwise, advise disconnecting and trying again
    tooltipText = `Sync to QuickBooks Online was not successful.${specificReason ||
      ''} Please select "Disconnect from Synced Customer", rename it and try again!`;
  }

  return (
    <>
      <Tooltip title={tooltipText}>
        <span>
          <IconButton
            onClick={() => setShowQboSyncMenu(true)}
            color="secondary"
            variant="contained"
            className={classes.headerIconButton}
            ref={qboSyncToggleRef}
            disabled={isLoading || !!disabledReason}
          >
            {iconToUse}
          </IconButton>
        </span>
      </Tooltip>
      <QboSyncMenu
        showQboSyncMenu={showQboSyncMenu}
        setShowQboSyncMenu={setShowQboSyncMenu}
        toggleElement={qboSyncToggleRef.current}
        recordType={recordType}
        parentCustomerInfo={parentCustomerInfo}
        syncToNewCustomer={syncToNewCustomer}
        syncToExistingCustomer={syncToExistingCustomer}
        disconnectSyncedCustomer={disconnectSyncedCustomer}
        syncState={syncState}
        searchDefault={searchDefault}
        checkForUpdates={checkForUpdates}
      />
    </>
  );
};

export default compose(
  GraphQL.SyncCustomerToNewAction,
  GraphQL.SyncCustomerToExistingAction,
  GraphQL.DisconnectSyncedCustomerAction,
  GraphQL.CheckForQboUpdatesToCustomerAction,
  GraphQL.SyncJrnToNewAction,
  GraphQL.SyncJrnToExistingAction,
  GraphQL.DisconnectSyncedJrnAction,
  GraphQL.CheckForQboUpdatesToJrnAction
)(QboSyncActionButton);
