import _ from 'lodash';

const determineUserRole = ({ companyInfo, userInfo }) => {
  const role = {
    isOwner: false,
    isAdmin: false,
    isBookkeeper: false,
    isLevelAccounting: false,
    isCustomer: false, // legacy
  };

  if (!companyInfo || !userInfo) {
    return role;
  }

  const { userId, type, userType } = userInfo;

  role.isAdmin = _.includes(companyInfo.admins, userId);

  role.isOwner =
    role.isAdmin && _.includes(companyInfo.owners, userInfo.userId);

  role.isBookkeeper = role.isAdmin && type === 'bookkeeper';

  role.isLevelAccounting = role.isBookkeeper && userType === 'level';

  role.isCustomer = type === 'customer';

  return role;
};

export default determineUserRole;
