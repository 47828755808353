import gql from 'graphql-tag';

export default gql`
  mutation RemoveCompanyShoeboxItem($contentId: String!, $companyId: String!) {
    removeCompanyShoeboxItem(
      input: { contentId: $contentId, companyId: $companyId }
    ) {
      companyId
      contentId
      dateCreated
      status
      subject
      notes
      amount {
        value
      }
      media {
        aspectRatio
        type
        uri
        ocrData {
          status
          error
          uri
          dataJson
        }
      }
    }
  }
`;
