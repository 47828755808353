import React, { useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { compose, withApollo } from 'react-apollo';
import { Redirect } from 'react-router-dom';

import _ from 'lodash';

import AUTH_ERRORS from './auth-errors.json';

import styles from './auth.styles';

import AuthTextField from './auth-text-field/auth-text-field';
import BigButton from './big-button';
import PasswordRequirementsTooltip from './auth-text-field/password-requirements-tooltip';

const useStyles = makeStyles(styles);

const CODE_HELPER_TEXT =
  'Please check your email for a 6 digit code we just sent you. Enter it above and then click "Confirm" below to complete your password reset.';

const ResetPasswordView = () => {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [invalidUsernameMessage, setInvalidUsernameMessage] = useState('');
  const [password, setPassword] = useState('');
  const [invalidPasswordMessage, setInvalidPasswordMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [sendCode, setSendCode] = useState(false);
  const [code, setCode] = useState('');
  const [invalidCodeMessage, setInvalidCodeMessage] = useState('');
  const [shouldGoToSignIn, setShouldGoToSignIn] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const handleUsernameChange = e => {
    const passedUsername = e.target.value;
    // clear error message
    setInvalidUsernameMessage('');
    setUsername(passedUsername);
  };

  const handleUsernameBlur = e => {
    const passedUsername = e.target.value;
    if (!passedUsername) {
      setInvalidUsernameMessage(AUTH_ERRORS.EMAIL_OR_USERNAME_IS_REQUIRED);
    }
  };

  const isPasswordValid = passwordToCheck => {
    const matchCognitoRequirementsRegex = new RegExp(
      '^(((?=.*[a-z]))((?=.*[a-z])(?=.*[0-9]))((?=.*[0-9])))(?=.{6,})'
    );

    return matchCognitoRequirementsRegex.test(passwordToCheck);
  };

  const handlePasswordChange = e => {
    const passedPassword = e.target.value;
    // clear error message
    setInvalidPasswordMessage('');
    setPassword(passedPassword);
  };

  const handlePasswordBlur = e => {
    const passedPassword = e.target.value;
    if (!!passedPassword && !isPasswordValid(passedPassword)) {
      setInvalidPasswordMessage(AUTH_ERRORS.PASSWORD_REQUIREMENTS);
    }
  };

  const handleCodeChange = e => {
    let passedCode = e.target.value;
    // clear error message
    setInvalidCodeMessage('');
    passedCode = passedCode.replace(/[^0-9]+/g, '');
    setCode(passedCode);
  };

  const onSubmitForm = () => {
    setErrorMessage('');
    const usernameToSubmit = _.trim(username);

    setUsername(usernameToSubmit);

    if (!usernameToSubmit) {
      setInvalidUsernameMessage(AUTH_ERRORS.EMAIL_OR_USERNAME_IS_REQUIRED);
      return;
    }

    if (!isPasswordValid(password)) {
      setInvalidPasswordMessage(AUTH_ERRORS.PASSWORD_REQUIREMENTS);
      return;
    }

    setIsProcessing(true);

    Auth.forgotPassword(usernameToSubmit)
      .then(() => {
        setSendCode(true);
      })
      .catch(({ code: errorCode }) => {
        if (errorCode === 'InvalidParameterException') {
          setInvalidUsernameMessage(AUTH_ERRORS.INVALID_USERNAME);
          return;
        }

        if (errorCode === 'UserNotFoundException') {
          setInvalidUsernameMessage(AUTH_ERRORS.USER_NOT_FOUND_EXCEPTION);
          return;
        }

        // Give generic error message for all other errors
        setErrorMessage(AUTH_ERRORS.UNEXPECTED_ERROR);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const onConfirmSubmitted = () => {
    setErrorMessage('');
    const token = _.trim(code);

    if (!token) {
      setInvalidCodeMessage(CODE_HELPER_TEXT);
      return;
    }

    setIsProcessing(true);
    Auth.forgotPasswordSubmit(username, token, password)
      .then(() => {
        setShouldGoToSignIn(true);
      })
      .catch(({ code: errorCode }) => {
        if (errorCode === 'CodeMismatchException') {
          setInvalidCodeMessage(AUTH_ERRORS.CODE_MISMATCH_EXCEPTION);
          return;
        }

        // Give generic error message for all other errors
        setErrorMessage(AUTH_ERRORS.UNEXPECTED_ERROR);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };
  if (shouldGoToSignIn) {
    return <Redirect to="/auth" />;
  }
  return (
    <Grid container item xs={12} className={classes.formContainer}>
      <Typography className={classes.formTitle}>Reset your password</Typography>

      {!sendCode ? (
        <>
          <Typography className={classes.formMessage}>
            Enter your username and your new preferred password.
          </Typography>

          <Typography className={classes.formMessage}>
            A code will then be sent to the email address associated with your
            Level account to confirm your new password request.
          </Typography>

          <Grid container item xs={12} className={classes.formBody}>
            <Grid item xs={12}>
              <AuthTextField
                name="username"
                label="Email or Username"
                required
                value={username}
                disabled={isProcessing}
                errorText={invalidUsernameMessage}
                onBlur={handleUsernameBlur}
                onChange={handleUsernameChange}
              />
            </Grid>
            <Grid item xs={12}>
              <AuthTextField
                name="password"
                label="New Password"
                type="password"
                lableTooltip={<PasswordRequirementsTooltip />}
                required
                value={password}
                disabled={isProcessing}
                errorText={invalidPasswordMessage}
                onChange={handlePasswordChange}
                onBlur={handlePasswordBlur}
                onEnterKeyPress={onSubmitForm}
              />
            </Grid>
            {!!errorMessage && (
              <Grid item xs={12} className={classes.formErrorContainer}>
                <Typography className={classes.errorText}>
                  {errorMessage}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            className={classes.formActions}
          >
            <BigButton
              buttonText="Send Code"
              fullWidth
              isProcessing={isProcessing}
              onClick={onSubmitForm}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid container item xs={12} className={classes.formBody}>
            <Grid item xs={12}>
              <AuthTextField
                name="code"
                label="6 Digit Code"
                required
                value={code}
                disabled={isProcessing}
                helperText={CODE_HELPER_TEXT}
                errorText={invalidCodeMessage}
                onChange={handleCodeChange}
                onEnterKeyPress={onConfirmSubmitted}
              />
            </Grid>

            {!!errorMessage && (
              <Grid item xs={12} className={classes.formErrorContainer}>
                <Typography className={classes.errorText}>
                  {errorMessage}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            className={classes.formActions}
          >
            <BigButton
              buttonText="Confirm"
              fullWidth
              isProcessing={isProcessing}
              onClick={onConfirmSubmitted}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default compose(withApollo)(ResetPasswordView);
