import _ from 'lodash';
import determineUserRole from './determine-user-role.helper';
import {
  PRODUCT,
  PRODUCTS_SUBSCRIPTION_BASED,
  MAZUMAGO_SUPPORTED_COUNTRY_CODES,
  MAZUMAGO_STATUS,
} from '../config/appDefaults';

const determineManagingCompanyInfo = ({ userInfo, companyInfo }) => {
  let managingCompanyInfo = null;

  if (companyInfo) {
    const {
      isOwner,
      isAdmin,
      isBookkeeper: isCompanyBookkeeper,
      isLevelAccounting,
    } = determineUserRole({ companyInfo, userInfo });

    const isBookkeepingCustomer = _.includes(
      companyInfo.products,
      PRODUCT.BOOKKEEPING
    );

    const isAdvisoryCustomer = _.includes(
      companyInfo.products,
      PRODUCT.ADVISORY
    );

    const hasBookkeepingAlerts = _.includes(
      companyInfo.products,
      PRODUCT.BOOKKEEPING_ALERTS
    );

    const hasProjectManagementProduct = _.includes(
      companyInfo.products,
      PRODUCT.PROJECT_MANAGEMENT
    );

    const isAClient = !!userInfo.managingFirmId;

    let isSubscriptionBased;
    let isCopilot;
    if (isAClient) {
      isSubscriptionBased = true;
      isCopilot = true;
    } else {
      isSubscriptionBased = _.every(companyInfo.products, product => {
        return _.includes(PRODUCTS_SUBSCRIPTION_BASED, product);
      });
      isCopilot = !!companyInfo.subscriptionStatus;
    }

    const hasRfiFeature = _.includes(companyInfo.products, PRODUCT.RFI);

    const isConnectedToQuickBooks = !!companyInfo.connectedToQuickBooks;

    const isCustomerSyncEnabled =
      isConnectedToQuickBooks && isAdmin && !!companyInfo.customerSyncEnabled;

    const canConnectWithMazumaGo = !!(
      companyInfo.countryCode &&
      MAZUMAGO_SUPPORTED_COUNTRY_CODES[companyInfo.countryCode]
    );

    const isConnectedToMazumaGo =
      companyInfo.mazumaGoStatus === MAZUMAGO_STATUS.CONNECTED;

    const timetrackingSettings = companyInfo.timetrackingSettings || {
      allowCrewEditing: false,
    };

    managingCompanyInfo = {
      ...companyInfo,
      managingCompanyId: companyInfo.companyId, // DEPRECATED: phase out in favour of companyId
      managingCompanyName: companyInfo.companyName, // DEPRECATED: phase out in favour of companyName
      isCompanyAdmin: isAdmin,
      isCompanyOwner: isOwner,
      isCompanyBookkeeper,
      isLevelAccounting,
      isAdvisoryCustomer,
      isBookkeepingCustomer,
      isConnectedToQuickBooks, // DEPRECATED: use checkQboConnectionStatus query result instead where possible
      isCustomerSyncEnabled,
      isSubscriptionBased, // DEPRECATED: use isCopilot instead
      hasBookkeepingAlerts,
      hasProjectManagementProduct,
      hasRfiFeature,
      canConnectWithMazumaGo,
      isConnectedToMazumaGo,
      timetrackingSettings,
      isCopilot,
    };
  }

  return managingCompanyInfo;
};

export default determineManagingCompanyInfo;
