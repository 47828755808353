import React from 'react';
import { compose } from 'redux';
import { Grid, Avatar, Paper, IconButton, Tooltip } from '@material-ui/core';
import {
  ArchiveOutlined as ArchiveIcon,
  Edit as EditIcon,
  Store as VendorIcon,
  Unarchive as UnarchiveIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { UpdateVendorAction } from '../../../graphql/graphql';
import palette from '../../../theme/palette';

const useStyles = makeStyles(theme => {
  return {
    paper: props => {
      // let bgColor = '#fff';
      let bgColorHover = theme.palette.brandColorPrimary;
      let textColor = null;
      let textColorHover = '#fff';
      if (props.highlightSelected) {
        textColor = '#fff';
      }
      if (props.softHighlight) {
        bgColorHover = palette.background.alt;
        textColorHover = null;
      }

      let paperStyle = {
        margin: `${theme.spacing(1)}px auto 0 auto`,
        padding: theme.spacing(2),
        backgroundColor: props.highlightSelected
          ? theme.palette.brandColorPrimary
          : null,
        color: textColor,

        transition: 'all 0.3s ease',
        width: '100%',
        '&:hover': {
          backgroundColor: palette.background.alt,
          color: null,
        },
      };

      if (props.onVendorClick) {
        paperStyle = {
          ...paperStyle,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: bgColorHover,
            color: textColorHover,
          },
        };
      }
      return paperStyle;
    },
    bigAvatar: {
      height: 80,
      width: 80,
    },
    vendorName: {
      marginBottom: 5,
      wordBreak: 'break-word',
    },
    archivedStatus: {
      fontSize: '80%',
    },
    iconButton: {
      marginLeft: 8,
    },
  };
});

const VendorItem = props => {
  const { vendor, onEditButtonClick, onVendorClick, onUpdateVendor } = props;
  const classes = useStyles(props);

  const handleEditButtonClick = e => {
    e.stopPropagation();
    onEditButtonClick(vendor);
  };

  const handleArchiveToggleButtonClick = e => {
    e.stopPropagation();
    onUpdateVendor({
      ...vendor,
      isArchived: !vendor.isArchived,
    });
  };

  return (
    <Paper
      className={classes.paper}
      onClick={() => {
        if (onVendorClick) {
          onVendorClick(vendor);
        }
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item xs={12}>
          <Grid container alignItems="center" wrap="nowrap" spacing={3}>
            <Grid item style={{ position: 'relative' }}>
              <Avatar alt="vendor profile image" className={classes.bigAvatar}>
                <VendorIcon />
              </Avatar>
            </Grid>
            <Grid item container>
              <Grid container direction="column">
                <Grid item className={classes.vendorName}>
                  {vendor.name}
                </Grid>
                {vendor.isArchived && (
                  <Grid item className={classes.archivedStatus}>
                    Archived
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                flex: 0,
                flexDirection: 'row',
                padding: 0,
              }}
            >
              <Grid item>
                <Tooltip title="Edit this vendor">
                  <IconButton onClick={handleEditButtonClick}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    vendor.isArchived
                      ? 'Unarchive this vendor'
                      : 'Archive this vendor'
                  }
                >
                  <span>
                    <IconButton onClick={handleArchiveToggleButtonClick}>
                      {vendor.isArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default compose(UpdateVendorAction)(VendorItem);
