import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Report}
  query GetProjectProfitabilityData($companyId: String, $projectId: String) {
    getProjectProfitabilityData(
      input: { companyId: $companyId, projectId: $projectId }
    ) {
      items {
        ...ReportFields
      }
    }
  }
`;
