import React, { useMemo } from 'react';
import {
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';
import { QboTools } from 'level-foundation';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.brandColorLightGrey,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles(() => ({
  root: {
    fontSize: 12,
  },
}))(TableCell);

export const ORDER_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

export const COLUMN_DEF = {
  TXN_ID: {
    key: 'txnId',
    order: 1,
    label: '',
    isSortable: false,
    width: 10,
    renderValue: txn => {
      const qboTxnUrl = QboTools.qboUrlFromTxn(txn);

      if (!qboTxnUrl) {
        return null;
      }

      return (
        <Tooltip title="View in QuickBooks Online">
          <Link href={qboTxnUrl} target="_blank" rel="noopener noreferrer">
            <IconButton size="small" onClick={() => {}}>
              <OpenInNewIcon fontSize="small" />
            </IconButton>
          </Link>
        </Tooltip>
      );
    },
  },
  CUSTOMER_NAME: {
    key: 'customerName',
    order: 2,
    label: 'Customer Name',
    isSortable: true,
    width: 300,
  },
  TXN_DATE: {
    key: 'txnDate',
    order: 3,
    label: 'Reporting Date',
    isSortable: true,
    width: 130,
  },
  TXN_TYPE: {
    key: 'txnType',
    order: 4,
    label: 'Transaction Type',
    isSortable: true,
    width: 140,
  },
  DOC_NUM: {
    key: 'docNum',
    order: 5,
    label: 'Transaction #',
    isSortable: true,
    width: 125,
  },
  VENDOR_NAME: {
    key: 'vendorName',
    order: 6,
    label: 'Vendor Name',
    isSortable: true,
    width: 200,
  },
  MEMO: {
    key: 'memo',
    order: 7,
    label: 'Memo / Description',
    isSortable: true,
  },
  ACCOUNT_NAME: {
    key: 'accountName',
    order: 8,
    label: 'Account',
    isSortable: true,
    width: 300,
  },
  AMOUNT: {
    key: 'amount',
    order: 9,
    label: 'Amount',
    isSortable: true,
    align: 'right',
    width: 100,
  },
};

const TransactionTable = ({
  order,
  orderBy,
  transactions,
  hideCustomerName,
}) => {
  const columnsToUse = useMemo(() => {
    const columns = _.values(COLUMN_DEF);
    const sortedColumns = _.orderBy(columns, 'order', ORDER_DIRECTION.ASC);

    if (hideCustomerName) {
      return _.filter(
        sortedColumns,
        column => column.key !== COLUMN_DEF.CUSTOMER_NAME.key
      );
    }

    return sortedColumns;
  }, [hideCustomerName]);

  const transactionsToUse = useMemo(() => {
    const txns = _.map(transactions, txn => {
      return {
        ...txn,
        amount: txn.debitAmount || txn.creditAmount,
      };
    });

    const sortedTxns = _.orderBy(txns, [orderBy], [order]);

    return _.map(sortedTxns, txn => {
      const { amount, txnDate, ...rest } = txn;
      return {
        ...rest,
        amount: numeral(amount).format('$0,0.00'),
        txnDate: moment(txnDate)
          .utc()
          .format('MMM DD, YYYY'),
      };
    });
  }, [transactions, order, orderBy]);

  return (
    <div
      style={{ width: '100%', marginTop: 8, marginBottom: 8, paddingLeft: 28 }}
    >
      <Paper
        variant="outlined"
        style={{
          padding: 8,
        }}
      >
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columnsToUse.map(column => {
                  return (
                    <StyledTableCell
                      padding="none"
                      key={column.key}
                      align={column.align || 'left'}
                      sortDirection={orderBy === column.key ? order : false}
                      width={column.width}
                      style={{
                        fontWeight: 'bold',
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingLeft: column.align === 'right' ? 0 : 16,
                        paddingRight: column.align === 'right' ? 16 : 0,
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === column.key}
                        direction={
                          orderBy === column.key ? order : ORDER_DIRECTION.ASC
                        }
                        hideSortIcon={orderBy !== column.key}
                        disabled
                      >
                        {column.label}
                      </TableSortLabel>
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(transactionsToUse, (txn, index) => (
                <StyledTableRow key={`${txn.txnId}_${index}`}>
                  {_.map(columnsToUse, column => {
                    return (
                      <StyledTableCell
                        key={column.key}
                        align={column.align || 'left'}
                      >
                        {column.renderValue
                          ? column.renderValue(txn)
                          : txn[column.key]}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  );
};

export default TransactionTable;
