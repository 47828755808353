/** @typedef {import('./routes').RouteConfig} RouteConfig */

import {
  BarChart as BarChartIcon,
  CalendarToday as CalendarTodayIcon,
  ContactMail as ContactMailIcon,
  People as CrewIcon,
  Forum as ForumIcon,
  Inbox as InboxIcon,
  Info as InfoIcon,
  NotificationsRounded as NotificationsIcon,
  Policy as PolicyIcon,
  Settings as SettingsIcon,
  Store as VendorIcon,
  VerifiedUser as VerifiedUserIcon,
  Group as GroupIcon,
} from '@material-ui/icons';
import React from 'react';

import {
  CONTENT_DEFINITION,
  CONTENT_TYPE,
  PRODUCT,
} from '../config/appDefaults';

// WARNING!!! This component drives the order of the sidebar nav
/** @enum {string} */
export const BASE_ROUTE = {
  RFI: '/requests-for-information',
  SHOEBOX: '/shoebox',
  CUSTOMERS: '/customers',
  LEADS: '/leads',
  CALENDAR: '/calendar',
  PROJECTS: '/projects',
  SCOREBOARD: '/scoreboard',
  BILLS: '/bills',
  VENDORS: '/vendors',
  MESSAGES: '/messages',
  CREW: '/crew',
  ALERTS: '/alerts',
  CUSTOM_SCAN: '/custom-scan',
  ADMIN: '/admin',
  FIRM: '/firm',
  SETTINGS: '/settings',
};

const LeadIcon = CONTENT_DEFINITION[CONTENT_TYPE.LEAD].Icon;
const ProjectIcon = CONTENT_DEFINITION[CONTENT_TYPE.PROJECT].Icon;
const BillIcon = CONTENT_DEFINITION[CONTENT_TYPE.GLOBAL_BILL].Icon;

/** @type {Object<string, RouteConfig>} */
export const BASE_ROUTE_CONFIG = {
  [BASE_ROUTE.RFI]: {
    href: BASE_ROUTE.RFI,
    title: 'RFIs',
    Icon: <InfoIcon />,
    adminOnly: true,
  },
  [BASE_ROUTE.SHOEBOX]: {
    href: BASE_ROUTE.SHOEBOX,
    title: 'Shoebox',
    Icon: <InboxIcon />,
    adminOnly: true,
  },
  [BASE_ROUTE.CUSTOMERS]: {
    href: BASE_ROUTE.CUSTOMERS,
    title: 'Customers',
    Icon: <ContactMailIcon />,
    adminOnly: true,
  },
  [BASE_ROUTE.LEADS]: {
    href: BASE_ROUTE.LEADS,
    title: CONTENT_DEFINITION.lead.pluralName,
    Icon: <LeadIcon />,
    adminOnly: true,
  },
  [BASE_ROUTE.CALENDAR]: {
    href: BASE_ROUTE.CALENDAR,
    title: 'Calendar',
    Icon: <CalendarTodayIcon />,
  },
  [BASE_ROUTE.PROJECTS]: {
    href: BASE_ROUTE.PROJECTS,
    title: 'Projects',
    Icon: <ProjectIcon />,
  },
  [BASE_ROUTE.SCOREBOARD]: {
    href: BASE_ROUTE.SCOREBOARD,
    title: 'Scoreboard',
    Icon: <BarChartIcon />,
    ownerOrBookkeeperOnly: true,
  },
  [BASE_ROUTE.VENDORS]: {
    href: BASE_ROUTE.VENDORS,
    title: 'Vendors',
    Icon: <VendorIcon />,
    adminOnly: true,
  },
  [BASE_ROUTE.BILLS]: {
    href: BASE_ROUTE.BILLS,
    title: 'Bills',
    Icon: <BillIcon />,
    adminOnly: true,
  },
  [BASE_ROUTE.MESSAGES]: {
    href: BASE_ROUTE.MESSAGES,
    title: 'Messages',
    Icon: <ForumIcon />,
  },
  [BASE_ROUTE.CREW]: {
    href: BASE_ROUTE.CREW,
    title: 'Crew',
    Icon: <CrewIcon />,
  },
  [BASE_ROUTE.ALERTS]: {
    href: BASE_ROUTE.ALERTS,
    title: 'Alerts',
    Icon: <NotificationsIcon />,
    ownerOrBookkeeperOnly: true,
  },
  [BASE_ROUTE.CUSTOM_SCAN]: {
    href: BASE_ROUTE.CUSTOM_SCAN,
    title: 'Custom Scan',
    Icon: <PolicyIcon />,
    ownerOrBookkeeperOnly: true,
  },
  [BASE_ROUTE.ADMIN]: {
    href: BASE_ROUTE.ADMIN,
    title: 'Admin',
    Icon: <VerifiedUserIcon />,
    adminOnly: true,
  },
  [BASE_ROUTE.FIRM]: {
    href: BASE_ROUTE.FIRM,
    title: 'Clients',
    Icon: <GroupIcon />,
  },
  [BASE_ROUTE.SETTINGS]: {
    href: BASE_ROUTE.SETTINGS,
    title: 'Account',
    Icon: <SettingsIcon />,
  },
};

const projectManagementCore = [
  BASE_ROUTE.SHOEBOX,
  BASE_ROUTE.CUSTOMERS,
  BASE_ROUTE.LEADS,
  BASE_ROUTE.CALENDAR,
  BASE_ROUTE.PROJECTS,
  BASE_ROUTE.SCOREBOARD,
  BASE_ROUTE.BILLS,
  BASE_ROUTE.VENDORS,
  BASE_ROUTE.MESSAGES,
  BASE_ROUTE.CREW,
  BASE_ROUTE.ADMIN,
  BASE_ROUTE.SETTINGS,
];

// Note: for each project, add ALL top level routes available to the user here
//    they will be merged and deduped into a final list of allowed routes
/** @type {Object<PRODUCT, BASE_ROUTE[]>} */
export const PRODUCT_ALLOWED_ROUTES = {
  [PRODUCT.ADVISORY]: [...projectManagementCore],
  [PRODUCT.BOOKKEEPING]: [...projectManagementCore],
  [PRODUCT.BOOKKEEPING_ALERTS]: [
    BASE_ROUTE.ALERTS,
    BASE_ROUTE.CUSTOM_SCAN,
    BASE_ROUTE.SETTINGS,
  ],

  [PRODUCT.PROJECT_MANAGEMENT]: [...projectManagementCore],
  [PRODUCT.RFI]: [BASE_ROUTE.RFI, BASE_ROUTE.SETTINGS, BASE_ROUTE.ADMIN],
};

/** @type {BASE_ROUTE[]} */
export const FIRM_ALLOWED_ROUTES = [BASE_ROUTE.FIRM, BASE_ROUTE.SETTINGS];

/** @enum {string} */
export const SCOPE = {
  FIRM: 'firm',
  COMPANY_OR_CLIENT: 'companyOrClient',
};
