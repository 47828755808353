import React from 'react';
import CreatableSelect from 'react-select/creatable';

import palette from '../../theme/palette';

class FormikCreatableSelect extends React.Component {
  handleChange = value => {
    const { onChange, name } = this.props;
    // this is going to call setFieldValue and manually update values[name]
    if (onChange) {
      onChange(name, value);
    }
  };

  handleBlur = () => {
    const { onBlur, name } = this.props;
    // this is going to call setFieldTouched and manually update touched[name]
    if (onBlur) {
      onBlur(name, true);
    }
  };

  render() {
    const {
      name,
      value,
      options,
      isMulti,
      placeholder,
      menuPlacement,
      isClearable,
      onCreateOption,
      error,
      menuPortalTarget,
    } = this.props;

    const customStyles = {
      control: provided => ({
        ...provided,
        border: 0,
        outline: 0,
        borderRadius: 0,
        borderBottom: error
          ? `2px solid ${palette.brandColorError}`
          : '1px solid rgba(0, 0, 0, 0.42)',
        fontSize: 14,
        minHeight: 31,
        height: 31,
      }),
      clearIndicator: provided => ({
        ...provided,
        padding: '0px',
        paddingLeft: '0px',
        paddingTop: '0px',
        paddingRight: '0px',
        paddingDown: '0px',
      }),
      dropdownIndicator: provided => ({
        ...provided,
        padding: '0px',
        paddingLeft: '0px',
        paddingTop: '0px',
        paddingRight: '0px',
        paddingDown: '0px',
      }),
      indicatorsContainer: provided => ({
        ...provided,
        height: 31,
      }),
      valueContainer: provided => ({
        ...provided,
        height: 31,
      }),
      menuPortal: provided => ({
        ...provided,
        zIndex: 9999,
      }),
    };

    return (
      <CreatableSelect
        styles={customStyles}
        isMulti={isMulti}
        name={name}
        value={value}
        options={options}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        placeholder={placeholder}
        menuPlacement={menuPlacement || 'auto'}
        isClearable={isClearable}
        {...(onCreateOption && { onCreateOption })}
        menuPortalTarget={menuPortalTarget}
      />
    );
  }
}

export default FormikCreatableSelect;
