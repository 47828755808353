import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import numeral from 'numeral';
import { makeStyles } from '@material-ui/styles';
import styles from '../../dashboard/dashboard.styles';
import DashboardReportTableRow from '../../dashboard/dashboard-report-table-row';
import { BREAKEVEN_GRAPH } from './breakeven-graphs';
import palette from '../../../theme/palette';

const useStyles = makeStyles(theme => styles(theme));

const asCurrency = value => {
  if (!value) {
    return '-';
  }
  return numeral(value).format('$0,0.00');
};

const BREAKEVEN_STATUS = {
  ACCOMPLISHED: 'Accomplished',
  ON_TRACK: 'On track',
  NEEDS_ATTENTION: 'Needs attention',
  BEHIND: 'Behind',
  CANNOT_BREAKEVEN: 'Cannot break-even',
};

const BREAKEVEN_STATUS_COLOR = {
  [BREAKEVEN_STATUS.ACCOMPLISHED]: palette.brandColorGreen,
  [BREAKEVEN_STATUS.ON_TRACK]: palette.brandColorPrimary,
  [BREAKEVEN_STATUS.NEEDS_ATTENTION]: palette.brandColorOrange,
  [BREAKEVEN_STATUS.BEHIND]: palette.brandColorError,
  [BREAKEVEN_STATUS.CANNOT_BREAKEVEN]: palette.brandColorError,
};

const BreakevenProgressReport = ({
  reportData,
  monthlySalesTarget,
  setMonthlySalesTarget,
}) => {
  const classes = useStyles();

  const [infoToShow, setInfoToShow] = useState(null);

  const BREAKEVEN_STATUS_COMPONENTS = {
    [BREAKEVEN_STATUS.ACCOMPLISHED]: (
      <Typography
        data-testid="breakeven-status"
        noWrap
        style={{
          fontWeight: 'bold',
          color: BREAKEVEN_STATUS_COLOR[BREAKEVEN_STATUS.ACCOMPLISHED],
        }}
      >
        {BREAKEVEN_STATUS.ACCOMPLISHED}
      </Typography>
    ),
    [BREAKEVEN_STATUS.ON_TRACK]: (
      <Typography
        data-testid="breakeven-status"
        noWrap
        style={{
          fontWeight: 'bold',
          color: BREAKEVEN_STATUS_COLOR[BREAKEVEN_STATUS.ON_TRACK],
        }}
      >
        {BREAKEVEN_STATUS.ON_TRACK}
      </Typography>
    ),
    [BREAKEVEN_STATUS.NEEDS_ATTENTION]: (
      <Typography
        data-testid="breakeven-status"
        noWrap
        style={{
          fontWeight: 'bold',
          color: BREAKEVEN_STATUS_COLOR[BREAKEVEN_STATUS.NEEDS_ATTENTION],
        }}
      >
        {BREAKEVEN_STATUS.NEEDS_ATTENTION}
      </Typography>
    ),
    [BREAKEVEN_STATUS.BEHIND]: (
      <Typography
        data-testid="breakeven-status"
        noWrap
        style={{
          fontWeight: 'bold',
          color: BREAKEVEN_STATUS_COLOR[BREAKEVEN_STATUS.BEHIND],
        }}
      >
        {BREAKEVEN_STATUS.BEHIND}
      </Typography>
    ),
    [BREAKEVEN_STATUS.CANNOT_BREAKEVEN]: (
      <Typography
        data-testid="breakeven-status"
        noWrap
        style={{
          fontWeight: 'bold',
          color: BREAKEVEN_STATUS_COLOR[BREAKEVEN_STATUS.CANNOT_BREAKEVEN],
        }}
      >
        {BREAKEVEN_STATUS.CANNOT_BREAKEVEN}
      </Typography>
    ),
  };

  let totalSalesToDate = null;
  let breakevenSales = null;
  let remainingSalesToBreakeven = null;
  let monthsLeftInThePeriod = null;
  let averageSalesPerMonthForBreakeven = null;
  let averageSalesPerMonth = null;
  let breakevenStatus = null; // On track, In trouble

  const breakevenSalesData =
    reportData &&
    reportData[BREAKEVEN_GRAPH.ANNUAL_BREAKEVEN_SALES_FORECAST] &&
    reportData[BREAKEVEN_GRAPH.ANNUAL_BREAKEVEN_SALES_FORECAST].values;

  const salesToDateData =
    reportData &&
    reportData[BREAKEVEN_GRAPH.SALES_TO_DATE] &&
    reportData[BREAKEVEN_GRAPH.SALES_TO_DATE].values;

  if (breakevenSalesData && salesToDateData) {
    monthsLeftInThePeriod = 0;

    for (let i = breakevenSalesData.length - 1; i >= 0; i -= 1) {
      if (breakevenSalesData[i].y !== null) {
        breakevenSales = breakevenSalesData[i].y;
        break;
      }
    }

    for (let i = salesToDateData.length - 1; i >= 0; i -= 1) {
      if (salesToDateData[i].y === null) {
        monthsLeftInThePeriod += 1;
      } else {
        totalSalesToDate = salesToDateData[i].y;
        averageSalesPerMonth = salesToDateData[i].y / (i + 1);
        break;
      }
    }

    if (breakevenSales !== null && totalSalesToDate !== null) {
      if (breakevenSales > 0) {
        remainingSalesToBreakeven =
          breakevenSales - totalSalesToDate > 0
            ? breakevenSales - totalSalesToDate
            : 0;

        if (monthsLeftInThePeriod) {
          averageSalesPerMonthForBreakeven =
            remainingSalesToBreakeven / monthsLeftInThePeriod;

          if (remainingSalesToBreakeven === 0) {
            breakevenStatus = BREAKEVEN_STATUS.ACCOMPLISHED;
          } else {
            const projectedNetProfit =
              (averageSalesPerMonth * 12) / breakevenSales;

            if (projectedNetProfit > 1.1) {
              breakevenStatus = BREAKEVEN_STATUS.ON_TRACK;
            } else if (projectedNetProfit > 1) {
              breakevenStatus = BREAKEVEN_STATUS.NEEDS_ATTENTION;
            } else {
              breakevenStatus = BREAKEVEN_STATUS.BEHIND;
            }
          }
        } else {
          breakevenStatus =
            remainingSalesToBreakeven === 0
              ? BREAKEVEN_STATUS.ACCOMPLISHED
              : BREAKEVEN_STATUS.CANNOT_BREAKEVEN;
        }
      } else {
        breakevenStatus = BREAKEVEN_STATUS.CANNOT_BREAKEVEN;
      }
    }
  }

  if (
    breakevenStatus &&
    averageSalesPerMonthForBreakeven !==
      (monthlySalesTarget && monthlySalesTarget.value)
  ) {
    if (!averageSalesPerMonthForBreakeven) {
      setMonthlySalesTarget(null);
    } else {
      setMonthlySalesTarget({
        value: averageSalesPerMonthForBreakeven,
        color: BREAKEVEN_STATUS_COLOR[breakevenStatus],
      });
    }
  } else if (
    !breakevenStatus &&
    monthlySalesTarget &&
    monthlySalesTarget.value
  ) {
    setMonthlySalesTarget(null);
  }

  const breakevenStatusDescription = (
    <>
      Based on the above calculations, the break-even status distills your
      financial data throughout the report period into one of the following
      categories:
      <br />
      <ul>
        <li>
          <span
            style={{
              fontWeight: 'bold',
              color: BREAKEVEN_STATUS_COLOR[BREAKEVEN_STATUS.ACCOMPLISHED],
            }}
          >
            {BREAKEVEN_STATUS.ACCOMPLISHED}
          </span>{' '}
          - Your cumulative annual sales have surpassed your forecasted annual
          Break-even Sales. As long as you do not increase your fixed costs and
          continue to deliver on projects the way you have been, everything from
          here on out will increase your profit.
        </li>
        <li>
          <span
            style={{
              fontWeight: 'bold',
              color: BREAKEVEN_STATUS_COLOR[BREAKEVEN_STATUS.ON_TRACK],
            }}
          >
            {BREAKEVEN_STATUS.ON_TRACK}
          </span>{' '}
          - Your monthly sales rate is on track to surpass your forecasted
          annual Break-even Sales by at least 10%. As long as you do not
          increase your fixed costs, and continue to deliver the same margins on
          your projects you should be in the clear!
        </li>
        <li>
          <span
            style={{
              fontWeight: 'bold',
              color: BREAKEVEN_STATUS_COLOR[BREAKEVEN_STATUS.NEEDS_ATTENTION],
            }}
          >
            {BREAKEVEN_STATUS.NEEDS_ATTENTION}
          </span>{' '}
          - Your monthly sales rate is on track to surpass your forecasted
          annual Break-even Sales - but not by much! Focus on keeping your costs
          low, and increasing your project margins.
        </li>
        <li>
          <span
            style={{
              fontWeight: 'bold',
              color: BREAKEVEN_STATUS_COLOR[BREAKEVEN_STATUS.BEHIND],
            }}
          >
            {BREAKEVEN_STATUS.BEHIND}
          </span>{' '}
          - You’re not on track to break-even. Focus on increasing your sales
          volume, reducing your fixed costs, and increasing your project margins
          (e.g. increase your selling price).
        </li>
        <li>
          <span
            style={{
              fontWeight: 'bold',
              color: BREAKEVEN_STATUS_COLOR[BREAKEVEN_STATUS.CANNOT_BREAKEVEN],
            }}
          >
            {BREAKEVEN_STATUS.CANNOT_BREAKEVEN}
          </span>{' '}
          - Either you were unable to break-even during the selected period, or
          the calculated Break-even Sales were negative. A negative Break-even
          Sales indicates that your projects are running at a loss. To correct
          this and get on the path of profitability, you will need to increase
          your Gross Margin (%) by reducing your variable costs, and/or increase
          your selling prices.
        </li>
      </ul>
    </>
  );

  const rows = [
    {
      name: 'Total sales to date',
      value: asCurrency(totalSalesToDate),
      description:
        'The cumulative project sales throughout the selected report period based on the current accounting method.',
    },
    {
      name: 'Break-even sales',
      value: asCurrency(breakevenSales >= 0 ? breakevenSales : null),
      description:
        'The most recent forecasted annual Break-even Sales for the selected report period as shown in the Annual Break-even Sales ($) chart. See the chart info for more information about the Break-even Sales.',
    },
    {
      name: 'Remaining sales required to break-even',
      value: asCurrency(remainingSalesToBreakeven),
      description:
        'The remaining sales required to break-even is calculated as the remainder when subtracting the total sales to date from the Break-even Sales.',
    },
    {
      name: 'Months left in the period',
      value: monthsLeftInThePeriod || '-',
      description:
        'The months remaining (including the current month) between now and the end of the selected report period.',
    },
    {
      name: 'Average sales per remaining month required to break-even',
      value: asCurrency(averageSalesPerMonthForBreakeven),
      description:
        'The remaining sales required to break-even distributed evenly over the remaining months (including this month) in the selected report period.',
    },
    {
      name: 'Average monthly sales to date',
      value: asCurrency(averageSalesPerMonth),
      description:
        'The average monthly project sales to date to compare with the required average sales per remaining month required to break-even.',
    },
    {
      name: 'Break-even status',
      value: breakevenStatus
        ? BREAKEVEN_STATUS_COMPONENTS[breakevenStatus]
        : '-',
      description: breakevenStatusDescription,
    },
  ];

  const toTableRow = ({ row, rowId }) => (
    <DashboardReportTableRow
      key={rowId}
      {...row}
      setInfoToShow={setInfoToShow}
      isLoading={
        !(
          reportData &&
          reportData[BREAKEVEN_GRAPH.ANNUAL_BREAKEVEN_SALES_FORECAST]
        )
      }
    />
  );

  return (
    <>
      <div className={classes.reportWrapper}>
        <Paper elevation={4} className={classes.report}>
          <Grid item>
            <Typography variant="h5">Progress Report</Typography>
          </Grid>
          <Grid item container className={classes.tableWrapper}>
            {rows.map((row, i) => toTableRow({ row, rowId: i }))}
          </Grid>
        </Paper>
      </div>
      {infoToShow && (
        <Dialog
          open
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{infoToShow.title}</DialogTitle>
          <DialogContent dividers style={{ paddingTop: 20, paddingBottom: 20 }}>
            <DialogContentText style={{ color: palette.text.primary }}>
              {infoToShow.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setInfoToShow(null)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default BreakevenProgressReport;
