import React, { useEffect, useState, useRef } from 'react';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import {
  Button,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Dialog,
  DialogContent,
  IconButton,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import {
  Warning as WarningIcon,
  Dashboard as DashboardIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';
import uuid from 'uuid';
import _ from 'lodash';
import numeral from 'numeral';

import {
  RFI_STATUSES,
  RFI_TXN_ANSWER_TYPES_BY_GROUP,
  RFI_SOURCES,
  RFI_FIELDS_TO_SHOW_PER_SOURCE,
  MAX_CHARACTERS_IN_DESCRIPTION_FIELD,
  CONTENT_TYPE,
  RFI_TXN_ANSWER_TYPES,
} from '../../../config/appDefaults';

import GetInternalComments from '../../../graphql/queries/get-internal-comments';
import GetContentById from '../../../graphql/queries/GetContentById';
import { CreateInternalCommentMutationAction } from '../../../graphql/graphql';

import Select from '../../../components/formik-custom-components/formik-select';
import DatePickerField from '../../../components/formik-custom-components/formik-date-picker';
import LevelPellEditor from '../../add-to-project/level-pell-editor/level-pell-editor';
import ChooseProjectDialog from './choose-project-dialog';
import Comments from '../../../components/ProjectContentListing/Comments/comments';
import CommentInput from '../../../components/ProjectContentListing/Comments/CommentInput';
import FullScreenGallery from '../../../components/full-screen-gallery/full-screen-gallery';
import DialogTitle from '../../../components/dialog-title/dialog-title';
import LoadingCover from '../../../components/LoadingCover/loadingCover';
import ExpenseProjectSplitter from '../../../components/expense-project-splitter/expense-project-splitter';
import BillPaymentBillSplitter from '../../../components/billpayment-bill-splitter/billpayment-bill-splitter';

import {
  contentUrlToMedia,
  formatNumberAsMoney,
  isItSpent,
  makeMoney,
} from '../../../helpers';
import RenderSupportingItem from '../../rfi-dashboard/rfi-supporting-media';
// eslint-disable-next-line import/no-cycle
import RfiViewRelatedContent from '../../rfi-dashboard/rfi-view-related-content';
import RfiAdminModePrompt from './rfi-admin-mode-prompt';
import GetCompanyRfi from '../../../graphql/queries/get-company-rfi';
import { standardRequiredMsg } from '../../add-to-project/info-about-content/schema-validation';
import palette from '../../../theme/palette';

const useStyles = makeStyles(theme => ({
  formWrapper: {
    padding: theme.spacing(3),
    width: '100%',
  },
  rfiDataLabel: {
    fontSize: 11,
    lineHeight: '16px',
  },
  formGroupWrapper: {
    marginBottom: theme.spacing(2),
  },
  commentsWrapper: {
    width: '100%',
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    borderTop: '1px solid #f6f6f6',
    background: palette.background.alt,
    alignContent: 'flex-start',
    height: 'calc(100vh - 78px)',
    overflowY: 'auto',
  },
}));

const RFI_SOURCES_OPTIONS = Object.values(RFI_SOURCES);

const parseAmountAsString = amount => {
  let amountAsString = '';
  if (amount) {
    const amountAsValue = amount.value || 0;
    amountAsString = numeral(amountAsValue).format('0.00');
  }
  return amountAsString;
};

const AdminCreateEditRfiForm = ({
  adminMode,
  rfiToEdit,
  companyCrew,
  onSave,
  onClose,
  lockFields = {},
  onCancel,
  managingCompanyId,
  userInfo,
  managementMode,
  baseRfiInfo,
  onCreateInternalComment,
}) => {
  const classes = useStyles();
  const [chooseProjectDialogOptions, setChooseProjectDialogOptions] = useState({
    open: false,
  });
  const [visualParentPath, setVisualParentPath] = useState(null);
  const [fullScreenGalleryInfo, setFullScreenGalleryInfo] = useState({
    open: false,
    images: [],
  });
  const [isAdminMode, setIsAdminMode] = useState(adminMode);
  const [showSaving, setShowSaving] = useState(false);

  const [showAdminDialog, setShowAdminDialog] = useState({ open: false });
  const [proceedToNext, setProceedToNext] = useState(false);
  const [adminFormKey, setAdminFormKey] = useState(uuid());
  const [showRelatedContentModal, setShowRelatedContentModal] = useState({
    open: false,
  });
  const [projectList, setProjectList] = useState([]);
  const [billList, setBillList] = useState([]);

  useEffect(() => {
    if (rfiToEdit?.parentPath) {
      setVisualParentPath(rfiToEdit.parentPath.join(' > '));
    } else if (baseRfiInfo?.parentPath) {
      setVisualParentPath(baseRfiInfo.parentPath.join(' > '));
    }
  }, [rfiToEdit, baseRfiInfo]);

  const resetForm = () => {
    // Reset form
    setAdminFormKey(uuid());

    // Reset content driven states
    setProjectList([]);
    setBillList([]);
    setVisualParentPath(null);
  };

  useEffect(() => {
    if (proceedToNext) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfiToEdit]);

  // If rfiToEdit has projectList and billList undefined, query the full request id info and update the lists if present
  const {
    loading: getRfiLoading,
    // refetch: getRfiRefetch,
    data: getRfiData,
  } = useQuery(GetCompanyRfi, {
    variables: {
      companyId: managingCompanyId,
      requestId: rfiToEdit?.requestId,
    },
    skip: !rfiToEdit || rfiToEdit.local,
    fetchPolicy: 'network-only',
  });

  const latestRfi = _.get(getRfiData, 'getCompanyRfi', null);

  useEffect(() => {
    // Default the projectList and billList
    if (latestRfi?.projectList && latestRfi.relatedProjects) {
      const projectListToUse = [];
      _.forEach(latestRfi.projectList, ({ contentId, amount }) => {
        // Get project
        const project = _.find(latestRfi.relatedProjects, { contentId }, null);

        if (!project) {
          return;
        }

        // Get parent project
        const parentProject = project.jrn;

        // Build path
        const projectPath = [project.title];
        if (parentProject) {
          projectPath.unshift(parentProject.title);
        }

        projectListToUse.push({
          project,
          projectPath,
          amount: parseAmountAsString(amount),
        });
      });

      setProjectList(projectListToUse);
    }

    if (latestRfi?.billList && latestRfi.relatedBills) {
      const billListToUse = [];

      _.forEach(latestRfi.billList, ({ contentId, amount }) => {
        const bill = _.find(latestRfi.relatedBills, { contentId }, null);

        if (!bill) {
          return;
        }
        // Review and safeguard project not found situation
        billListToUse.push({
          bill,
          amount: parseAmountAsString(amount),
        });
      });

      setBillList(billListToUse);
    }
  }, [latestRfi]);

  const FormikRef = useRef();

  const GetInternalCommentsQuery = useQuery(GetInternalComments, {
    fetchPolicy: 'cache-and-network',
    variables: {
      companyId: managingCompanyId,
      itemType: 'RFI',
      itemId: rfiToEdit?.requestId,
    },
    skip: !!baseRfiInfo, // if there's base info, then we're creating from an existing piece of content and comments aren't allowed
  });
  const internalCommentsLoading = _.get(GetInternalCommentsQuery, 'loading');
  const internalCommentsRfis = _.get(
    GetInternalCommentsQuery,
    `data.getInternalComments.items`,
    null
  );

  const GetRelatedItemQuery = useQuery(GetContentById, {
    fetchPolicy: 'cache-and-network',
    variables: {
      contentId: rfiToEdit?.idOfRelatedItem || baseRfiInfo?.idOfRelatedItem,
    },
    skip: !rfiToEdit?.idOfRelatedItem && !baseRfiInfo?.idOfRelatedItem,
  });
  const relatedItemInfo = _.get(
    GetRelatedItemQuery,
    `data.getContentById`,
    null
  );

  const editMode = rfiToEdit && !rfiToEdit?.local;

  const handleSetProject = () => {
    setChooseProjectDialogOptions({ open: true });
  };

  const handleChosenProject = ({ project, path }) => {
    setVisualParentPath(path.join(' > '));
    FormikRef.current.setFieldValue('projectId', project.contentId);
    FormikRef.current.setFieldValue('parentPath', path);
    setChooseProjectDialogOptions({ open: false });
  };

  const handleOnlickThumbnail = passedIndex => {
    let showThumbs = true;
    _.each(rfiToEdit.media, mediaItem => {
      if (showThumbs !== false) {
        if (mediaItem.type !== 'image') {
          showThumbs = false;
        }
      }
    });

    setFullScreenGalleryInfo({
      open: true,
      images: rfiToEdit.media,
      selectedItem: passedIndex,
      showThumbs,
    });
  };

  const doFormikSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    const toSave = {
      ...values,
      requestId: rfiToEdit?.requestId || baseRfiInfo.requestId,
      billList: null,
      projectList: null,
    };

    // Update toSave to include billList and projectList
    if (RFI_TXN_ANSWER_TYPES[values.requestType]?.hasProjectMultiSelect) {
      // Write projectList and nullify others
      toSave.projectId = null;
      toSave.projectList = projectList.map(
        ({ project, amount: amountAsString }) => ({
          contentId: project.contentId,
          amount: makeMoney(amountAsString ? parseFloat(amountAsString) : 0),
        })
      );
      toSave.billList = null;
    } else if (RFI_TXN_ANSWER_TYPES[values.requestType]?.hasBillMultiSelect) {
      // Write billList and nullify others
      toSave.projectId = null;
      toSave.projectList = null;
      toSave.billList = billList.map(({ bill, amount: amountAsString }) => ({
        contentId: bill.contentId,
        amount: makeMoney(amountAsString ? parseFloat(amountAsString) : 0),
      }));
    }

    _.each(toSave.media, (item, index) => {
      delete toSave.media[index].__typename;
    });

    setShowSaving(true);

    await onSave({
      listOfRfis: [toSave],
      addOrUpdate: editMode ? 'update' : 'add',
    });

    actions.setSubmitting(false);
    setShowSaving(false);

    onClose({ askIfSure: false, proceedToNext });
  };

  const renderContentComments = () => {
    const wrapper = children => (
      <Grid container item xs={5} className={classes.commentsWrapper}>
        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <Typography variant="h3" gutterBottom>
            Internal Comments
          </Typography>
        </Grid>
        <Grid container item xs={12} style={{ marginBottom: 24 }}>
          {children}
        </Grid>
      </Grid>
    );
    if (rfiToEdit.local) {
      return wrapper(<div>Cannot add comments to an unsaved RFI.</div>);
    }
    const thereAreComments =
      internalCommentsRfis && internalCommentsRfis.length > 0;
    if (internalCommentsLoading) {
      return wrapper(<div>Loading Comments...</div>);
    }

    const addInternalComment = comment => {
      onCreateInternalComment({
        comment: { ...comment, contentType: 'RFI' },
        itemType: 'RFI',
        itemId: rfiToEdit.requestId,
      });
    };

    return wrapper(
      <>
        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <CommentInput
            addCommentAction={addInternalComment}
            contentItem={{ ...rfiToEdit, contentId: rfiToEdit.requestId }}
            userId={userInfo.userId}
            autoFocus={false}
          />
        </Grid>
        <div style={{ height: 16 }} />
        {thereAreComments ? (
          <Comments
            comments={internalCommentsRfis}
            allUsers={companyCrew}
            dateAligment="right"
            bubbleColor="#e0e0e0"
          />
        ) : (
          <Grid item xs={12} style={{ padding: 24 }}>
            No comments yet.
          </Grid>
        )}
      </>
    );
  };

  const toggleInternalAdminMode = () => {
    if (isAdminMode) {
      setIsAdminMode(false);
    } else {
      // prompt for a password and if correct, turn on admin mode
      // if not, let them know it was wrong
      setShowAdminDialog({ open: true });
    }
  };

  const toggleProceedToNext = () => {
    if (proceedToNext) {
      setProceedToNext(false);
    } else {
      setProceedToNext(true);
    }
  };

  // INITIAL VALUES
  const initialValues = {
    txnAccount: rfiToEdit?.txnAccount || '',
    txnAccountType: rfiToEdit?.txnAccountType || '',
    bankAccount: rfiToEdit?.bankAccount || '',
    txnType: rfiToEdit?.txnType || '',
    txnPayee: rfiToEdit?.txnPayee || '',
    txnDescription: rfiToEdit?.txnDescription || '',
    txnDate: rfiToEdit?.txnDate ? moment(rfiToEdit.txnDate) : moment(),
    txnReceived: rfiToEdit?.txnReceived?.value || '',
    txnSpent: rfiToEdit?.txnSpent?.value || '',
    initialNotes: rfiToEdit?.initialNotes || '',
    projectId: rfiToEdit?.projectId || '',
    requestSource: rfiToEdit?.requestSource || '',
    requestStatus: rfiToEdit?.requestStatus || '',
    requestType: rfiToEdit?.requestType || '',
    media: rfiToEdit?.media || [],
    idOfRelatedItem: rfiToEdit?.idOfRelatedItem || null,
    clientNotes: rfiToEdit?.clientNotes || null,
  };

  if (rfiToEdit?.txnId) {
    initialValues.txnId = rfiToEdit?.txnId;
  }
  if (baseRfiInfo) {
    initialValues.requestSource = RFI_SOURCES.LEVEL.value;
    initialValues.requestStatus = RFI_STATUSES.IN_DRAFT.value;

    if (baseRfiInfo?.txnId) {
      initialValues.txnId = rfiToEdit?.txnId;
    }

    if (baseRfiInfo.idOfRelatedItem) {
      initialValues.idOfRelatedItem = baseRfiInfo.idOfRelatedItem;
    }

    if (baseRfiInfo.bankAccount) {
      initialValues.bankAccount = baseRfiInfo.bankAccount;
    }

    if (baseRfiInfo.description) {
      initialValues.initialNotes = baseRfiInfo.description;
    }
    if (baseRfiInfo.projectId) {
      initialValues.projectId = baseRfiInfo.projectId;
    }
    if (relatedItemInfo) {
      // deal with the date
      if (relatedItemInfo.date) {
        initialValues.txnDate = moment(relatedItemInfo.date);
      }
      // deal with amounts in out or
      const receiptBillTypes = [
        CONTENT_TYPE.RECEIPT,
        CONTENT_TYPE.BILL,
        CONTENT_TYPE.GLOBAL_BILL,
        CONTENT_TYPE.GLOBAL_RECEIPT,
      ];
      const isReceiptBill = receiptBillTypes.includes(relatedItemInfo.type);
      const isRefund = relatedItemInfo.contentStatus === 'refund';
      const isInvoicePayment = [
        CONTENT_TYPE.INVOICE,
        CONTENT_TYPE.PAYMENT,
      ].includes(relatedItemInfo.type);
      const moneyReceived =
        (isReceiptBill && isRefund) || (isInvoicePayment && !isRefund);
      if (moneyReceived) {
        initialValues.txnReceived = relatedItemInfo.amount.value;
      } else {
        initialValues.txnSpent = relatedItemInfo.amount.value;
      }
    }
    if (baseRfiInfo.contentUrl) {
      const contentUrlAsMedia = contentUrlToMedia(baseRfiInfo.contentUrl);
      initialValues.media = contentUrlAsMedia;
    }
  }
  if (initialValues.txnSpent) {
    initialValues.txnSpent = formatNumberAsMoney(initialValues.txnSpent);
  }
  if (initialValues.txnReceived) {
    initialValues.txnReceived = formatNumberAsMoney(initialValues.txnReceived);
  }
  if (!initialValues.requestSource) {
    initialValues.requestSource = RFI_SOURCES.INFORMATIONAL.value;
  }

  const thereIsMedia = initialValues.media && initialValues.media.length > 0;

  const validationDefinition = {
    initialNotes: yup
      .string()
      .max(
        MAX_CHARACTERS_IN_DESCRIPTION_FIELD,
        `Notes should be of maximum ${MAX_CHARACTERS_IN_DESCRIPTION_FIELD} characters length`
      ),
  };

  if (initialValues.requestSource === RFI_SOURCES.UNBOOKED.value) {
    validationDefinition.requestType = yup
      .string()
      .required(standardRequiredMsg());
  }

  const validationSchema = yup.object(validationDefinition);

  return (
    <Grid
      container
      style={{ height: '100vh', flexWrap: 'nowrap' }}
      direction="column"
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        item
        xs={12}
        style={{
          padding: 16,
          background: '#eee',
          flex: 0,
          zIndex: 999,
          height: 78,
        }}
      >
        <Typography variant="h3">+ Create RFI</Typography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 8,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={isAdminMode}
                onChange={toggleInternalAdminMode}
              />
            }
            label="Admin Mode"
            labelPlacement="start"
          />
          <RfiAdminModePrompt
            shouldShow={showAdminDialog.open}
            onSuccess={() => {
              setIsAdminMode(true);
              setShowAdminDialog({ open: false });
            }}
            onCancel={() => {
              setShowAdminDialog({ open: false });
            }}
          />
        </div>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        item
        xs={12}
        style={{ flexGrow: 1 }}
      >
        {renderContentComments()}
        <Grid
          container
          item
          xs={7}
          className={classes.formWrapper}
          style={{
            flexGrow: 1,
            height: 'calc(100vh - 78px)',
            overflowY: 'auto',
          }}
        >
          <Formik
            key={adminFormKey}
            innerRef={FormikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={doFormikSubmit}
            style={{ width: '100%' }}
            enableReinitialize
          >
            {formikProps => {
              const {
                values,
                touched,
                errors,
                handleChange,
                // handleBlur,
                handleSubmit,
                setValues,
                setFieldValue,
                // setFieldTouched,
              } = formikProps;

              const showRelatedContent = !!initialValues.idOfRelatedItem;

              const fieldsToShowForThisSource = _.get(
                RFI_FIELDS_TO_SHOW_PER_SOURCE,
                [values.requestSource],
                []
              );

              // figure out which options to show for the txnType based on the RFI
              const amountToUse = numeral(
                values.txnSpent || values.txnReceived
              ).value();
              const isPositive = amountToUse > 0;

              const txnIsSpent = isItSpent({
                accountType: values.txnAccountType,
                isPositive,
                fromContentType: relatedItemInfo?.type,
              });

              const answersForTxnTypes = txnIsSpent
                ? RFI_TXN_ANSWER_TYPES_BY_GROUP.UNBOOKED.SPENT
                : RFI_TXN_ANSWER_TYPES_BY_GROUP.UNBOOKED.RECEIVED;

              const viewThisContent = () => {
                if (!relatedItemInfo) {
                  return;
                }

                setShowRelatedContentModal({
                  open: true,
                  contentToShow: relatedItemInfo,
                });
              };

              const showProjectMultiSelect =
                fieldsToShowForThisSource.includes('projectList') &&
                RFI_TXN_ANSWER_TYPES[values.requestType]?.hasProjectMultiSelect;

              const showBillMultiSelect =
                fieldsToShowForThisSource.includes('billList') &&
                RFI_TXN_ANSWER_TYPES[values.requestType]?.hasBillMultiSelect;

              return (
                <Form style={{ width: '100%' }}>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    className={classes.formGroupWrapper}
                  >
                    <Grid item xs={6}>
                      <Typography
                        className={classes.rfiDataLabel}
                        style={{ opacity: 0.5 }} // Show as disabled
                      >
                        Request Source
                      </Typography>
                      <Select
                        name="requestSource"
                        value={values.requestSource}
                        onChange={handleChange}
                        style={{ width: '100%' }}
                        disabled
                        options={RFI_SOURCES_OPTIONS.map(requestSource => ({
                          value: requestSource.value,
                          label: requestSource.label,
                        }))}
                      />
                    </Grid>
                    {values.txnType && (
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="txnType"
                          name="txnType"
                          label="Source Type"
                          value={values.txnType}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    className={classes.formGroupWrapper}
                  >
                    {fieldsToShowForThisSource.includes('txnDate') && (
                      <Grid item xs={12} sm={6}>
                        <Field
                          label="Transaction Date"
                          name="txnDate"
                          value={values.txnDate}
                          component={DatePickerField}
                          noFuture
                          variant="inline"
                          fullWidth
                          autoOk
                          disabled={lockFields.txnDate}
                        />
                      </Grid>
                    )}
                    {fieldsToShowForThisSource.includes('requestType') && (
                      <Grid item xs={6}>
                        <Typography className={classes.rfiDataLabel}>
                          Type
                        </Typography>
                        <Select
                          name="requestType"
                          value={values.requestType || ''}
                          onChange={handleChange}
                          style={{ width: '100%' }}
                          error={errors.requestType}
                          options={_.map(answersForTxnTypes, requestType => ({
                            value: requestType.value,
                            label: requestType.label,
                          }))}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    className={classes.formGroupWrapper}
                  >
                    {fieldsToShowForThisSource.includes('txnAccount') && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          id="txnAccount"
                          name="txnAccount"
                          label="Transaction Account"
                          value={values.txnAccount}
                          onChange={handleChange}
                          error={
                            touched.txnAccount && Boolean(errors.txnAccount)
                          }
                          helperText={touched.txnAccount && errors.txnAccount}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    )}
                    {fieldsToShowForThisSource.includes('bankAccount') && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          id="bankAccount"
                          name="bankAccount"
                          label="Bank Account"
                          value={values.bankAccount}
                          onChange={handleChange}
                          error={
                            touched.bankAccount && Boolean(errors.bankAccount)
                          }
                          helperText={touched.bankAccount && errors.bankAccount}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {fieldsToShowForThisSource.includes('txnDescription') && (
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={2}
                      className={classes.formGroupWrapper}
                    >
                      <Grid item xs={12} sm={12}>
                        <TextField
                          fullWidth
                          id="txnDescription"
                          name="txnDescription"
                          label="Transaction Description"
                          value={values.txnDescription}
                          onChange={handleChange}
                          error={
                            touched.txnDescription &&
                            Boolean(errors.txnDescription)
                          }
                          helperText={
                            touched.txnDescription && errors.txnDescription
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {fieldsToShowForThisSource.includes('txnPayee') && (
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={2}
                      className={classes.formGroupWrapper}
                    >
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id="txnPayee"
                          name="txnPayee"
                          label="Transaction Payee"
                          value={values.txnPayee}
                          onChange={handleChange}
                          error={touched.txnPayee && Boolean(errors.txnPayee)}
                          helperText={touched.txnPayee && errors.txnPayee}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    className={classes.formGroupWrapper}
                  >
                    {fieldsToShowForThisSource.includes('txnReceived') && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          type="number"
                          id="txnReceived"
                          name="txnReceived"
                          label="Amount Received"
                          value={values.txnReceived}
                          disabled={!!values.txnSpent}
                          onChange={handleChange}
                          onBlur={event => {
                            setFieldValue(
                              'txnReceived',
                              formatNumberAsMoney(event.target.value)
                            );
                          }}
                          error={
                            touched.txnReceived && Boolean(errors.txnReceived)
                          }
                          helperText={touched.txnReceived && errors.txnReceived}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    )}

                    {fieldsToShowForThisSource.includes('txnSpent') && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          type="number"
                          id="txnSpent"
                          name="txnSpent"
                          label="Amount Spent"
                          value={values.txnSpent}
                          disabled={!!values.txnReceived}
                          onChange={handleChange}
                          onBlur={event => {
                            setFieldValue(
                              'txnSpent',
                              formatNumberAsMoney(event.target.value)
                            );
                          }}
                          error={touched.txnSpent && Boolean(errors.txnSpent)}
                          helperText={touched.txnSpent && errors.txnSpent}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {fieldsToShowForThisSource.includes('projectId') &&
                    (RFI_TXN_ANSWER_TYPES[values.requestType]
                      ?.hasProjectSelect ||
                      !values.requestType) && (
                      <Grid
                        container
                        item
                        xs={12}
                        spacing={2}
                        className={classes.formGroupWrapper}
                      >
                        <Typography className={classes.rfiDataLabel}>
                          Project
                        </Typography>
                        {visualParentPath ? (
                          <Grid item>
                            <Typography
                              variant="body1"
                              style={{ paddingTop: 8 }}
                            >
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`/projects/${values.projectId}`}
                                className="basicStyledLink"
                              >
                                {visualParentPath || 'View Project'}
                              </a>
                              {values.requestSource !==
                                RFI_SOURCES.LEVEL.value && (
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    handleSetProject({
                                      rfiDetails: values,
                                    });
                                  }}
                                  style={{ marginLeft: 8, fontSize: 16 }}
                                >
                                  <EditIcon />
                                </IconButton>
                              )}
                            </Typography>
                          </Grid>
                        ) : (
                          <Grid container item alignItems="center">
                            <Button
                              variant="outlined"
                              endIcon={<DashboardIcon />}
                              onClick={() => {
                                handleSetProject({
                                  rfiDetails: values,
                                });
                              }}
                            >
                              Set Project
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  {showProjectMultiSelect && (
                    <Grid item xs={12} style={{ marginBottom: 24 }}>
                      <ExpenseProjectSplitter
                        projectList={projectList}
                        setProjectList={setProjectList}
                        chillMode
                        showLoader={getRfiLoading}
                      />
                    </Grid>
                  )}
                  {showBillMultiSelect && (
                    <Grid item xs={12} style={{ marginBottom: 16 }}>
                      <BillPaymentBillSplitter
                        billList={billList}
                        setBillList={setBillList}
                        chillMode
                        showVendor
                        showLoader={getRfiLoading}
                      />
                    </Grid>
                  )}
                  {fieldsToShowForThisSource.includes('initialNotes') && (
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={2}
                      className={classes.formGroupWrapper}
                    >
                      <Grid item xs={12} sm={12}>
                        <Typography className={classes.rfiDataLabel}>
                          Notes
                          {errors.initialNotes && (
                            <span
                              style={{
                                marginLeft: 10,
                                color: 'red',
                              }}
                            >
                              <WarningIcon
                                color="inherit"
                                style={{ fontSize: 16 }}
                              />{' '}
                              Notes are too long ({values.initialNotes.length}{' '}
                              of {MAX_CHARACTERS_IN_DESCRIPTION_FIELD})
                            </span>
                          )}
                        </Typography>

                        <div
                          style={{
                            marginTop: 5,
                            border: `1px solid ${
                              errors.initialNotes ? '#e00' : '#ccc'
                            }`,
                          }}
                        >
                          <LevelPellEditor
                            defaultContent={
                              values && values.initialNotes
                                ? values.initialNotes
                                : ''
                            }
                            onChange={htmlText => {
                              setFieldValue('initialNotes', htmlText);
                            }}
                            containerClass="level-pell-content-addContentForm"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  )}

                  {thereIsMedia && (
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={2}
                      className={classes.formGroupWrapper}
                    >
                      <Grid item xs={12} sm={12}>
                        <Typography className={classes.rfiDataLabel}>
                          Supporting Media
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ marginTop: 4 }}>
                        {initialValues.media.map((item, index) => (
                          <RenderSupportingItem
                            key={item.uri || index}
                            supportingItem={item}
                            index={index}
                            handleOnlickThumbnail={handleOnlickThumbnail}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  )}

                  {showRelatedContent && (
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={2}
                      className={classes.formGroupWrapper}
                      direction="column"
                    >
                      <Grid item>
                        <Typography className={classes.rfiDataLabel}>
                          Regarding This Content
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={viewThisContent}
                          startIcon={<VisibilityIcon />}
                          variant="contained"
                          color="primary"
                          disabled={!relatedItemInfo}
                        >
                          View Content Details
                        </Button>
                      </Grid>
                    </Grid>
                  )}

                  <Grid container item xs={12} style={{ marginTop: 24 }}>
                    <Typography variant="body1">
                      <span style={{ fontStyle: 'italic' }}>
                        FYI: Clients will have the ability to upload supporting
                        images/documents once the RFI is approved.
                      </span>
                    </Typography>
                  </Grid>

                  {managementMode && (
                    <Grid
                      container
                      justifyContent="flex-end"
                      item
                      xs={12}
                      style={{ marginTop: 16 }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={proceedToNext}
                            onChange={toggleProceedToNext}
                          />
                        }
                        label="Go to next RFI after submission"
                        labelPlacement="start"
                      />
                    </Grid>
                  )}

                  <Grid
                    container
                    justifyContent="flex-end"
                    item
                    xs={12}
                    style={{ marginTop: 16 }}
                  >
                    <Button
                      onClick={() => {
                        onCancel();
                      }}
                      style={{ color: 'red' }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setValues({
                          ...values,
                          requestStatus: RFI_STATUSES.IN_DRAFT.value,
                        });
                        handleSubmit();
                      }}
                      style={{ marginLeft: 16 }}
                    >
                      Save Draft
                    </Button>
                    {isAdminMode &&
                      [
                        RFI_STATUSES.IN_PENDING_APPROVAL.value,
                        RFI_STATUSES.IN_APPROVED.value,
                      ].includes(initialValues.requestStatus) && (
                        <Button
                          color="primary"
                          variant="contained"
                          style={{
                            marginLeft: 16,
                            backgroundColor: '#ee0000',
                          }}
                          onClick={() => {
                            setValues({
                              ...values,
                              requestStatus: RFI_STATUSES.IN_REJECTED.value,
                            });
                            handleSubmit();
                          }}
                        >
                          Reject
                        </Button>
                      )}
                    {isAdminMode &&
                      initialValues.requestStatus !==
                        RFI_STATUSES.IN_APPROVED.value && (
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ marginLeft: 16, backgroundColor: 'green' }}
                          onClick={() => {
                            setValues({
                              ...values,
                              requestStatus: RFI_STATUSES.IN_APPROVED.value,
                            });
                            handleSubmit();
                          }}
                        >
                          Approve
                        </Button>
                      )}
                    {!isAdminMode && (
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ marginLeft: 16 }}
                        onClick={() => {
                          setValues({
                            ...values,
                            requestStatus:
                              RFI_STATUSES.IN_PENDING_APPROVAL.value,
                          });
                          handleSubmit();
                        }}
                      >
                        Submit For Approval
                      </Button>
                    )}
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>

      {chooseProjectDialogOptions.open && (
        <ChooseProjectDialog
          setProjectAndPath={handleChosenProject}
          paperProps={{ style: { height: '75%' } }}
          toClose={() => setChooseProjectDialogOptions({ open: false })}
        />
      )}
      {fullScreenGalleryInfo.open && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open
          onClose={() => setFullScreenGalleryInfo({ open: false })}
        >
          <DialogTitle
            onClose={() => setFullScreenGalleryInfo({ open: false })}
          >
            {fullScreenGalleryInfo.title || 'Supporting Paperwork'}
          </DialogTitle>
          <DialogContent>
            <FullScreenGallery
              images={fullScreenGalleryInfo.images}
              selectedItem={fullScreenGalleryInfo.selectedItem}
              showThumbs={fullScreenGalleryInfo.showThumbs}
            />
          </DialogContent>
        </Dialog>
      )}
      {showRelatedContentModal.open && (
        <RfiViewRelatedContent
          contentToShow={showRelatedContentModal.contentToShow}
          handleClose={() => setShowRelatedContentModal({ open: false })}
        />
      )}
      {showSaving && (
        <LoadingCover loader="linear">
          <Typography variant="h3" align="center">
            Saving...
          </Typography>
        </LoadingCover>
      )}
    </Grid>
  );
};

export default compose(
  // Mutations
  CreateInternalCommentMutationAction
)(AdminCreateEditRfiForm);
