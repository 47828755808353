import React, { useState } from 'react';
import _ from 'lodash';
import {
  Typography,
  Grid,
  Paper,
  Button,
  InputAdornment,
  InputLabel,
  Select,
  FormHelperText,
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import { Formik, Form, Field } from 'formik';
import {
  EmailOutlined as EmailOutlinedIcon,
  WorkOutline as WorkOutlineIcon,
  GroupOutlined as GroupOutlinedIcon,
  ShoppingCartOutlined as ShoppingCartOutlinedIcon,
} from '@material-ui/icons';
import * as Yup from 'yup';
import { compose } from 'react-apollo';

import LoadingCover from '../../../components/LoadingCover/loadingCover';
import {
  GetStripeProductInfoAction,
  GenerateNewCustomerOrderAction,
} from '../../../graphql/graphql';

const useStyles = makeStyles(theme => ({
  contentSection: {
    minHeight: 522,
  },
  explainerText: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    background: '#e0f3ff',
    borderRadius: 10,
  },
  previewText: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    background: theme.palette.brandColorGreenBg,
    borderRadius: 10,
    width: '100%',
    wordWrap: 'break-word',
  },
  inputField: {
    marginBottom: 20,
  },
  doneIcon: {
    color: theme.palette.taskStatusCompleted,
    fontSize: 150,
  },
}));

const initialValues = {
  companyName: '',
  email: '',
  price: '',
  count: '',
  coupon: '',
  taxRate: '',
};

const generateOrderSchema = Yup.object().shape({
  companyName: Yup.string().required('Required'),
  count: Yup.number()
    .min(1, 'Minimum of 1 is required')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  price: Yup.string().required('Required'),
});

const GenerateOrder = props => {
  const { stripeProductInfo, generateNewCustomerOrder } = props;

  const [checkoutSessionId, setCheckoutSessionId] = useState(null);

  const createCheckoutSession = async ({
    companyName,
    email,
    price,
    count,
    taxRate = null,
    coupon = null,
  }) => {
    const {
      data: {
        generateNewCustomerOrder: { sessionId },
      },
    } = await generateNewCustomerOrder({
      companyName,
      email,
      price,
      count,
      taxRate,
      coupon,
    });

    return sessionId;
  };

  const resetComponent = resetForm => {
    setCheckoutSessionId(null);
    resetForm();
  };

  const isPending = !stripeProductInfo;

  const handleFormSubmit = async formData => {
    const { companyName, email, price, count, taxRate, coupon } = formData;
    const generatedCheckoutSessionId = await createCheckoutSession({
      companyName,
      email,
      price,
      count,
      coupon,
      taxRate,
    });

    setCheckoutSessionId(generatedCheckoutSessionId);

    return true;
  };

  const classes = useStyles();

  let checkoutUrl = null;
  if (checkoutSessionId) {
    const port = window.location.port ? `:${window.location.port}` : '';
    checkoutUrl = `${window.location.protocol}//${window.location.hostname}${port}/checkout/${checkoutSessionId}`;
  }

  return (
    <Grid container style={{ padding: 20 }}>
      <Grid item xs={6}>
        <Paper style={{ minHeight: 400, padding: 50, display: 'flex' }}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={generateOrderSchema}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {formikProps => {
              const {
                values,
                errors,
                setFieldValue,
                handleSubmit,
                handleReset,
                isSubmitting,
              } = formikProps;

              const readonly = isSubmitting || checkoutSessionId;

              const products = _.get(stripeProductInfo, 'products.items');
              const prices = _.get(stripeProductInfo, 'prices.items');
              const coupons = _.get(stripeProductInfo, 'coupons.items');
              const taxRates = _.get(stripeProductInfo, 'taxRates.items');

              let productPriceList = null;
              if (products && prices) {
                productPriceList = [];
                _.forEach(products, product => {
                  _.forEach(prices, price => {
                    if (product.id === price.product) {
                      productPriceList.push({
                        value: price.id,
                        label: `${product.name}${
                          price.nickname ? ` - ${price.nickname}` : ''
                        }`,
                      });
                    }
                  });
                });
              }

              return (
                <Form style={{ position: 'relative', width: '100%' }}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid
                      item
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography variant="h3" align="center" color="primary">
                          Generate Order
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={12}
                        className={classes.contentSection}
                      >
                        <Grid container justifyContent="center">
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle1"
                              className={classes.explainerText}
                            >
                              Generate a link to a Stripe checkout for a new
                              Level customer.
                            </Typography>
                            <Field
                              name="companyName"
                              label="Company Name"
                              placeholder="e.g. Acme Inc."
                              value={values.companyName}
                              type="text"
                              component={TextField}
                              disabled={readonly}
                              fullWidth
                              className={classes.inputField}
                              InputProps={{
                                style: { height: 50 },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <WorkOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Field
                              name="email"
                              label="Billing User Email"
                              placeholder="e.g. j.smith@acme.com"
                              value={values.email}
                              type="text"
                              component={TextField}
                              disabled={readonly}
                              fullWidth
                              className={classes.inputField}
                              InputProps={{
                                style: { height: 50 },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <EmailOutlinedIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Field
                              name="count"
                              label="Admin Seats"
                              placeholder="e.g. 3"
                              value={values.count}
                              type="number"
                              component={TextField}
                              disabled={readonly}
                              fullWidth
                              className={classes.inputField}
                              InputProps={{
                                style: { height: 50 },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <GroupOutlinedIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <div className={classes.inputField}>
                              <InputLabel
                                htmlFor="product"
                                style={{
                                  transform: 'translate(0, 1.5px) scale(0.75)',
                                  transformOrigin: 'top left',
                                }}
                                error={errors.product}
                              >
                                Product
                              </InputLabel>
                              <Select
                                placeholder="Select a product"
                                startAdornment={
                                  <InputAdornment position="start">
                                    <ShoppingCartOutlinedIcon />
                                  </InputAdornment>
                                }
                                native
                                value={values.price}
                                onChange={event =>
                                  setFieldValue(
                                    'price',
                                    event.target.value,
                                    false
                                  )
                                }
                                inputProps={{
                                  name: 'price',
                                  id: 'price',
                                  style: { height: 40 },
                                }}
                                fullWidth
                                error={errors.product}
                                disabled={readonly}
                              >
                                <option aria-label="None" value="" disabled>
                                  Select a product
                                </option>
                                {productPriceList &&
                                  productPriceList.map(({ label, value }) => (
                                    <option key={value} value={value}>
                                      {label}
                                    </option>
                                  ))}
                              </Select>
                              {errors.product && (
                                <FormHelperText error>
                                  {errors.product}
                                </FormHelperText>
                              )}
                            </div>
                            <div className={classes.inputField}>
                              <InputLabel
                                htmlFor="coupon"
                                style={{
                                  transform: 'translate(0, 1.5px) scale(0.75)',
                                  transformOrigin: 'top left',
                                }}
                                error={errors.coupon}
                              >
                                Discount
                              </InputLabel>
                              <Select
                                startAdornment={
                                  <InputAdornment position="start">
                                    <ShoppingCartOutlinedIcon />
                                  </InputAdornment>
                                }
                                native
                                value={values.coupon}
                                onChange={event =>
                                  setFieldValue(
                                    'coupon',
                                    event.target.value,
                                    false
                                  )
                                }
                                inputProps={{
                                  name: 'coupon',
                                  id: 'coupon',
                                  style: { height: 40 },
                                }}
                                fullWidth
                                error={errors.discount}
                                disabled={readonly}
                              >
                                <option aria-label="None" value="none">
                                  None
                                </option>
                                {coupons &&
                                  coupons.map(({ name: label, id: value }) => (
                                    <option key={value} value={value}>
                                      {label}
                                    </option>
                                  ))}
                              </Select>
                              {errors.coupon && (
                                <FormHelperText error>
                                  {errors.coupon}
                                </FormHelperText>
                              )}
                            </div>
                            <div className={classes.inputField}>
                              <InputLabel
                                htmlFor="taxRate"
                                style={{
                                  transform: 'translate(0, 1.5px) scale(0.75)',
                                  transformOrigin: 'top left',
                                }}
                                error={errors.taxRate}
                              >
                                Tax Rate
                              </InputLabel>
                              <Select
                                startAdornment={
                                  <InputAdornment position="start">
                                    <ShoppingCartOutlinedIcon />
                                  </InputAdornment>
                                }
                                native
                                value={values.taxRate}
                                onChange={event =>
                                  setFieldValue(
                                    'taxRate',
                                    event.target.value,
                                    false
                                  )
                                }
                                inputProps={{
                                  name: 'taxRate',
                                  id: 'taxRate',
                                  style: { height: 40 },
                                }}
                                fullWidth
                                error={errors.taxRate}
                                disabled={readonly}
                              >
                                <option aria-label="None" value="none">
                                  None
                                </option>
                                {taxRates &&
                                  taxRates.map(
                                    ({
                                      state,
                                      country,
                                      percentage,
                                      displayName,
                                      id: value,
                                    }) => (
                                      <option key={value} value={value}>
                                        {`${state}, ${country} - ${displayName} @ ${percentage}%`}
                                      </option>
                                    )
                                  )}
                              </Select>
                              {errors.taxRate && (
                                <FormHelperText error>
                                  {errors.taxRate}
                                </FormHelperText>
                              )}
                            </div>
                          </Grid>
                          {checkoutUrl && (
                            <Typography
                              variant="subtitle1"
                              className={classes.previewText}
                            >
                              A checkout session has been created. Verify the
                              checkout session link and issue to customer.
                              <div style={{ marginTop: 10 }}>
                                <a
                                  href={checkoutUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {checkoutUrl}
                                </a>
                              </div>
                            </Typography>
                          )}
                        </Grid>
                        <Grid container direction="column" alignItems="center">
                          <Grid item>
                            {!checkoutSessionId && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                              >
                                Generate
                              </Button>
                            )}
                            {checkoutSessionId && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => resetComponent(handleReset)}
                                disabled={isSubmitting}
                              >
                                Done
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {(isSubmitting || isPending) && (
                    <LoadingCover>
                      {isSubmitting && (
                        <Typography variant="h3" align="center">
                          Saving...
                        </Typography>
                      )}
                    </LoadingCover>
                  )}
                </Form>
              );
            }}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default compose(
  GetStripeProductInfoAction,
  GenerateNewCustomerOrderAction
)(GenerateOrder);
