import gql from 'graphql-tag';

export default gql`
  mutation UpdateSlideshowOnJrn(
    $requestorId: String!
    $jrnId: String!
    $link: String!
    $active: Boolean!
  ) {
    updateSlideshowOnJrn(
      input: {
        requestorId: $requestorId
        jrnId: $jrnId
        link: $link
        active: $active
      }
    ) {
      __typename
      id
      jrnId
      link
      active
      status
      msg
    }
  }
`;
