import gql from 'graphql-tag';

export default gql`
  mutation UpdateContentStatus(
    $contentId: ID!
    $jrnId: String!
    $contentStatus: String!
    $completionInfo: CompletionInfoInput
  ) {
    updateContentStatus(
      input: {
        contentId: $contentId
        jrnId: $jrnId
        contentStatus: $contentStatus
        completionInfo: $completionInfo
      }
    ) {
      __typename
      contentId
      jrnId
      contentStatus
      completionInfo {
        __typename
        instructions
        completedById
        completedByDate
        textResponse
        mediaInfo {
          __typename
          uri
          aspectRatio
        }
      }
      synced
    }
  }
`;
