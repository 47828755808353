import gql from 'graphql-tag';

export default gql`
  mutation DeleteCrewMemberInvitation($invitationId: ID!) {
    deleteCrewMemberInvitation(input: { invitationId: $invitationId }) {
      __typename
      status
      msg
    }
  }
`;
