import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { IconButton, Typography } from '@material-ui/core';
import { CancelOutlined as CancelOutlinedIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import LevelModal from '../../components/Modal/level-modal';
import AddWhatToProject from './add-what-to-project';
import AddContentForm from './add-content-form';

// eslint-disable-next-line import/no-cycle
import FinancialItemAdminView from '../Admin/financial-item-admin-view';
import ViewGlobalItem from '../../components/view-global-item';

import NewProjectDecider from './new-project-decider';
import NewProjectResult from './new-project-result';

import ChooseProjectWithSubs from '../../components/choose-project-dialog/choose-project-with-subs';

import {
  ADMIN_CONTENT_QUERIES,
  CONTENT_DETAILS_MODAL_MODE as MODE,
  CONTENT_TYPE,
  GLOBAL_EXPENSE_TYPES,
} from '../../config/appDefaults';
import LoadingCover from '../../components/LoadingCover/loadingCover';

const FINANCIAL_ADMIN_VIEW_MODES = [
  MODE.ADMIN_RECEIPT,
  MODE.ADMIN_BILL,
  MODE.ADMIN_INVOICE,
  MODE.ADMIN_PAYMENT,
];

const INCOME_ADMIN_MODES = [
  MODE.ADMIN_INVOICE,
  MODE.ADMIN_PAYMENT,
  MODE.ADMIN_INVOICE_PAYMENT_ADD,
];

const EXPENSE_ADMIN_MODES = [
  MODE.ADMIN_RECEIPT,
  MODE.ADMIN_BILL,
  MODE.ADMIN_RECEIPT_BILL_ADD,
  MODE.ADMIN_RECEIPT_BILL_DELETE,
];

const FINANCIAL_ADMIN_ADD_MODES = [
  MODE.ADMIN_RECEIPT_BILL_ADD,
  MODE.ADMIN_INVOICE_PAYMENT_ADD,
  MODE.ADMIN_GLOBAL_BILL_ADD,
];

const PROJECT_ADMIN_MODES = [
  MODE.ADD_FROM_PROJECT_ADMIN,
  MODE.EDIT_FROM_PROJECT_ADMIN,
];

const useStyles = makeStyles(() => ({
  optionWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  optionButton: {
    fontSize: 40,
  },
}));

const ContentDetailsModal = ({
  loading,
  mode,
  modeInfo,
  actionType,
  canEdit,
  parentInfo,
  existingInfo,
  onClose,
  onViewGlobalItem,
  onDeleteBillPayment,
  onDeleteButtonClick,
  fromThisTemplate,
  getNextItem,
  leadDetails,
  hideCustomerOption,
  includeArchive, // FUTURE: Can we use modeInfo for this to cover more variables from admin tools?
  contentToDelete,
  hideDeleteButton,
  readonly,
  hideFooter,
  rfiViewMode,
}) => {
  const [currentMode, setCurrentMode] = useState(mode);
  const [currentModeInfo, setCurrentModeInfo] = useState(modeInfo);

  const parentId = parentInfo ? parentInfo.contentId : null;
  const isCreateProjectFromLead =
    currentMode === MODE.CREATE_PROJECT_FROM_CUSTOMER_LEADS ||
    currentMode === MODE.CREATE_PROJECT_FROM_MANAGE_LEADS;
  const isParentExtended =
    parentInfo &&
    parentInfo.type === 'task' &&
    parentInfo.subtype === 'extended';

  const classes = useStyles();
  const [infoWeNeedToAddContent, setInfoWeNeedToAddContent] = useState(null);
  const [chosenParentInfo, setChosenParentInfo] = useState(parentInfo);

  const fromProjectAdminTool = PROJECT_ADMIN_MODES.includes(currentMode);

  // use this to switch between view and edit/add mode for financial content driven from admin modules
  const [isAdminAddEditFinancial, setIsAdminAddEditFinancial] = useState(false);

  const permissionOfParent =
    parentInfo && parentInfo.permissionsFrom
      ? parentInfo.permissionsFrom
      : null;

  const [projectCreationInfoWeNeed, setProjectCreationInfoWeNeed] = useState({
    type: actionType || null, // createFromTemplate or createFromScratch
    whichTemplate: fromThisTemplate || null, // full template content object
  });

  const [projectCreationResult, setProjectCreationResult] = useState(null);

  useEffect(() => {
    setChosenParentInfo(parentInfo);
  }, [parentInfo]);

  useEffect(() => {
    setCurrentMode(mode);
  }, [mode]);

  const handleMarkAsPaid = (bills, fromWhichAdminTool) => {
    setCurrentMode(MODE.MARK_BILL_AS_PAID);
    setCurrentModeInfo({
      bills,
      showViewModeOnComplete: true,
      fromWhichAdminTool,
    });
  };

  const handleProjectCreation = response => {
    if (isCreateProjectFromLead && response) {
      onClose(response);
    } else if (
      !response ||
      (Object.keys(response).includes('cancelled') && !response.cancelled)
    ) {
      setProjectCreationResult({ err: true, project: null });
    } else if (response && response.cancelled) {
      setProjectCreationResult({ err: null, project: null });
    } else if (response.contentId) {
      setProjectCreationResult({ err: null, project: response });
    }
  };

  const renderWhichContent = () => {
    let fromWhichAdminTool = null;
    if (EXPENSE_ADMIN_MODES.includes(currentMode)) {
      fromWhichAdminTool = ADMIN_CONTENT_QUERIES.RECEIPTS_AND_BILLS;
    } else if (INCOME_ADMIN_MODES.includes(currentMode)) {
      fromWhichAdminTool = ADMIN_CONTENT_QUERIES.INVOICES_AND_PAYMENTS;
    } else if (currentMode === MODE.ADMIN_GLOBAL_BILL_ADD) {
      fromWhichAdminTool = CONTENT_TYPE.GLOBAL_BILL;
    }

    const inAdminFinancialViewMode =
      !isAdminAddEditFinancial &&
      FINANCIAL_ADMIN_VIEW_MODES.includes(currentMode);

    const inAdminFinancialEditMode =
      isAdminAddEditFinancial &&
      FINANCIAL_ADMIN_VIEW_MODES.includes(currentMode);

    const inAdminFinancialDeleteMode =
      currentMode === MODE.ADMIN_RECEIPT_BILL_DELETE;

    const inAdminFinancialAddMode = FINANCIAL_ADMIN_ADD_MODES.includes(
      currentMode
    );

    if (
      inAdminFinancialAddMode ||
      inAdminFinancialDeleteMode ||
      inAdminFinancialEditMode ||
      inAdminFinancialViewMode
    ) {
      // If financial content is being driven from the admin modules

      // console.log('inAdminFinancialViewMode: ', inAdminFinancialViewMode);
      // console.log('inAdminFinancialAddMode: ', inAdminFinancialAddMode);
      // console.log('inAdminFinancialEditMode: ', inAdminFinancialEditMode);
      // console.log('inAdminFinancialDeleteMode: ', inAdminFinancialDeleteMode);
      if (inAdminFinancialViewMode) {
        switch (existingInfo.type) {
          case CONTENT_TYPE.GLOBAL_BILL:
          case CONTENT_TYPE.GLOBAL_RECEIPT:
          case CONTENT_TYPE.GLOBAL_PAYMENT:
            return (
              <ViewGlobalItem
                targetContent={existingInfo}
                onMarkAsPaid={({ bills }) => {
                  handleMarkAsPaid(bills, fromWhichAdminTool);
                }}
                showFooter={!hideFooter}
                onEditButtonClick={() => {
                  setIsAdminAddEditFinancial(true);
                  setCurrentModeInfo({ showViewModeOnComplete: true });
                }}
                onViewGlobalItem={onViewGlobalItem}
                onDeleteBillPayment={onDeleteBillPayment}
                getNextItem={getNextItem}
                showAccountingStatus
                fromWhichAdminTool={fromWhichAdminTool}
                includeArchive={includeArchive}
                hideDeleteButton={hideDeleteButton}
                onClose={onClose}
                readonly={readonly}
                rfiViewMode={rfiViewMode}
              />
            );
          default:
            return (
              <FinancialItemAdminView
                targetContent={existingInfo} // this is used by the HOC GraphQL query
                showFooter={!hideFooter}
                getNextItem={getNextItem}
                onEditButtonClick={() => setIsAdminAddEditFinancial(true)}
                hideDeleteButton={hideDeleteButton}
                onDeleteButtonClick={onDeleteButtonClick}
                parentInfo={parentInfo}
                includeArchive={includeArchive}
                fromWhichAdminTool={fromWhichAdminTool}
                readonly={readonly}
                rfiViewMode={rfiViewMode}
              />
            );
        }
      } else if (inAdminFinancialAddMode) {
        if (!chosenParentInfo) {
          // If we don't have a parent, we need to choose one
          return (
            <>
              <Typography variant="h5" gutterBottom align="center">
                Which project would you like to add to?
              </Typography>
              <ChooseProjectWithSubs
                setSelectedProject={selectedProjectInfo => {
                  setChosenParentInfo(selectedProjectInfo.project);
                  setIsAdminAddEditFinancial(true);
                }}
              />
            </>
          );
        }

        if (currentMode === MODE.ADMIN_INVOICE_PAYMENT_ADD) {
          // add new income
          return (
            <AddContentForm
              parentId={chosenParentInfo.contentId}
              parentInfo={chosenParentInfo}
              passedType="invoice"
              fromWhichAdminTool={fromWhichAdminTool}
              includeArchive={includeArchive}
              onComplete={() => {
                onClose();
              }}
            />
          );
        }

        // if currentMode === MODE.ADMIN_RECEIPT_BILL_ADD
        // force user to choose type of expense
        if (
          currentMode === MODE.ADMIN_RECEIPT_BILL_ADD &&
          !infoWeNeedToAddContent
        ) {
          return (
            <AddWhatToProject
              mode={MODE.ADMIN_RECEIPT_BILL_ADD}
              parentId={chosenParentInfo.contentId}
              parentInfo={chosenParentInfo}
              canEdit={canEdit}
              passOptionsBack={passedOptions =>
                setInfoWeNeedToAddContent(passedOptions)
              }
            />
          );
        }

        let passedType = infoWeNeedToAddContent?.type;

        if (currentMode === MODE.ADMIN_GLOBAL_BILL_ADD) {
          passedType = CONTENT_TYPE.GLOBAL_BILL;
        }

        // add new expense item (admin module)
        return (
          <AddContentForm
            parentId={chosenParentInfo.contentId}
            parentInfo={chosenParentInfo}
            passedType={passedType}
            fromWhichAdminTool={fromWhichAdminTool}
            includeArchive={includeArchive}
            onAddAnother={() => {
              // reset the infoWeNeedToAddContent so user can choose another type of expense
              setInfoWeNeedToAddContent(null);
            }}
            onComplete={() => {
              onClose();
            }}
          />
        );
      } else if (inAdminFinancialEditMode || inAdminFinancialDeleteMode) {
        const passedType = existingInfo.type;
        switch (existingInfo.type) {
          case CONTENT_TYPE.GLOBAL_BILL:
          case CONTENT_TYPE.GLOBAL_RECEIPT:
          case CONTENT_TYPE.GLOBAL_PAYMENT:
            return (
              <AddContentForm
                parentId={existingInfo.jrnId}
                passedContentInfo={existingInfo}
                passedType={passedType}
                fromWhichAdminTool={fromWhichAdminTool}
                includeArchive={includeArchive}
                contentToDelete={contentToDelete}
                onComplete={() => {
                  if (inAdminFinancialEditMode) {
                    // show view mode
                    setIsAdminAddEditFinancial(false);
                  } else {
                    onClose();
                  }
                }}
              />
            );
          default:
            return (
              <AddContentForm
                parentId={chosenParentInfo.contentId}
                parentInfo={chosenParentInfo}
                passedContentInfo={existingInfo}
                passedType={passedType}
                permissionsFromParent={permissionOfParent}
                fromWhichAdminTool={fromWhichAdminTool}
                includeArchive={includeArchive}
                onComplete={() => {
                  // show view mode
                  setIsAdminAddEditFinancial(false);
                }}
              />
            );
        }
      }
    }

    if (currentMode === MODE.ADMIN_DOC) {
      return (
        <FinancialItemAdminView
          targetContent={existingInfo} // this is used by the HOC GraphQL query
          showFooter={false}
          onEditButtonClick={() => setCurrentMode(MODE.EDIT)}
          hideDeleteButton
          parentInfo={parentInfo}
          includeArchive={includeArchive}
          fromWhichAdminTool={fromWhichAdminTool}
          readonly
          hideAccountingAndRfi
        />
      );
    }

    if (
      (currentMode === MODE.GLOBAL_ITEM_VIEW ||
        currentMode === MODE.ADMIN_GLOBAL_ITEM_VIEW) &&
      existingInfo
    ) {
      switch (existingInfo.type) {
        case CONTENT_TYPE.GLOBAL_BILL:
        case CONTENT_TYPE.GLOBAL_RECEIPT:
        case CONTENT_TYPE.GLOBAL_PAYMENT:
          return (
            <ViewGlobalItem
              targetContent={existingInfo}
              onMarkAsPaid={({ bills }) => {
                handleMarkAsPaid(bills, null);
              }}
              showFooter={!hideFooter}
              onEditButtonClick={() => {
                setCurrentMode(MODE.EDIT);
                setCurrentModeInfo({
                  showViewModeOnComplete: true,
                  isFromAdminModule:
                    currentMode === MODE.ADMIN_GLOBAL_ITEM_VIEW,
                });
              }}
              onViewGlobalItem={onViewGlobalItem}
              onDeleteBillPayment={onDeleteBillPayment}
              getNextItem={getNextItem}
              showAccountingStatus={currentMode === MODE.ADMIN_GLOBAL_ITEM_VIEW}
              fromWhichAdminTool={fromWhichAdminTool}
              includeArchive={includeArchive}
              hideDeleteButton={hideDeleteButton}
              onClose={onClose}
              readonly={readonly}
              rfiViewMode={rfiViewMode}
            />
          );
        default:
          break;
      }
    }

    if (currentMode === MODE.MARK_BILL_AS_PAID) {
      const paymentInfo = {
        vendorId: existingInfo?.vendorId,
        billsToPay: currentModeInfo?.bills || [],
      };

      return (
        <AddContentForm
          passedType={CONTENT_TYPE.GLOBAL_PAYMENT}
          baseContentInfo={paymentInfo}
          billsToPay={currentModeInfo?.bills || []}
          fromWhichAdminTool={currentModeInfo?.fromWhichAdminTool}
          onComplete={data => {
            if (currentModeInfo?.showViewModeOnComplete) {
              setCurrentModeInfo(null);
              if (currentModeInfo?.fromWhichAdminTool) {
                // as we are coming from the admin tool and just finish marking bill as paid,
                // we need to open view admin bill
                setCurrentMode(MODE.ADMIN_BILL);
              } else {
                setCurrentMode(MODE.GLOBAL_ITEM_VIEW);
              }
            } else {
              onClose(data);
            }
          }}
        />
      );
    }

    // ------- General project and content creation below -------
    if (projectCreationResult) {
      // If the workflow is complete, show the result
      if (!projectCreationResult.err && !projectCreationResult.project) {
        onClose();
        return null;
      }
      return (
        <NewProjectResult
          project={projectCreationResult.project}
          errorOccurred={projectCreationResult.err}
          handleClose={onClose}
        />
      );
    }

    const { type, whichTemplate } = projectCreationInfoWeNeed;
    if (type === 'createFromTemplate' && whichTemplate) {
      // To create a project from a template
      fromWhichAdminTool = fromProjectAdminTool ? 'project' : undefined;

      if (isCreateProjectFromLead) {
        fromWhichAdminTool = currentMode;
      }

      return (
        <AddContentForm
          passedType="project"
          createFromTemplate={whichTemplate}
          onComplete={handleProjectCreation}
          fromWhichAdminTool={fromWhichAdminTool} // used to know if we should add it to another query like getCompanyProjects
          includeArchive={includeArchive}
          creatingProjectFromLead={isCreateProjectFromLead} // for project from lead
          baseContentInfo={isCreateProjectFromLead ? existingInfo : null}
          leadDetails={isCreateProjectFromLead ? leadDetails : null}
          hideCustomerOption={hideCustomerOption}
        />
      );
    }

    // This is triggered when creating a project
    if (
      currentMode === MODE.ADD_TOP_LEVEL ||
      currentMode === MODE.ADD_FROM_PROJECT_ADMIN ||
      isCreateProjectFromLead
    ) {
      // give option of new project from template or just blank
      if (!projectCreationInfoWeNeed.type && existingInfo.type === 'project') {
        return (
          <NewProjectDecider
            handleClose={onClose}
            passBack={data => {
              setProjectCreationInfoWeNeed({
                ...projectCreationInfoWeNeed,
                ...data,
              });
            }}
          />
        );
      }

      // if new is chosen, show existing form
      fromWhichAdminTool = fromProjectAdminTool ? 'project' : undefined;
      if (isCreateProjectFromLead) {
        fromWhichAdminTool = currentMode;
      }

      return (
        <AddContentForm
          passedType={existingInfo.type}
          permissionsFromParent={
            parentInfo && parentInfo.permissionsFrom
              ? parentInfo.permissionsFrom
              : null
          }
          onComplete={isCreateProjectFromLead ? handleProjectCreation : onClose}
          fromWhichAdminTool={fromWhichAdminTool}
          includeArchive={includeArchive}
          creatingProjectFromLead={isCreateProjectFromLead} // for project from lead
          baseContentInfo={isCreateProjectFromLead ? existingInfo : null}
          leadDetails={isCreateProjectFromLead ? leadDetails : null}
          hideCustomerOption={hideCustomerOption}
        />
      );
    }

    // this is triggered when editing content including project excluding convos
    if (
      currentMode === MODE.EDIT ||
      currentMode === MODE.EDIT_FROM_PROJECT_ADMIN
    ) {
      return (
        <AddContentForm
          parentId={existingInfo.jrnId}
          isParentExtended={isParentExtended}
          parentInfo={parentInfo}
          passedType={existingInfo.type}
          passedContentInfo={existingInfo}
          onComplete={() => {
            if (currentModeInfo?.showViewModeOnComplete) {
              setCurrentModeInfo(null);
              if (currentModeInfo?.isFromAdminModule) {
                setCurrentMode(MODE.ADMIN_GLOBAL_ITEM_VIEW);
              } else {
                setCurrentMode(MODE.GLOBAL_ITEM_VIEW);
              }
            } else {
              onClose();
            }
          }}
          permissionsFromParent={permissionOfParent}
          fromWhichAdminTool={fromProjectAdminTool ? 'project' : undefined}
          contentToDelete={contentToDelete}
        />
      );
    }

    if (!infoWeNeedToAddContent) {
      return (
        <AddWhatToProject
          parentId={parentId}
          parentInfo={parentInfo}
          isParentExtended={isParentExtended}
          canEdit={canEdit}
          passOptionsBack={passedOptions =>
            setInfoWeNeedToAddContent(passedOptions)
          }
        />
      );
    }

    // This is triggered when adding content
    return (
      <AddContentForm
        parentId={parentId}
        parentInfo={parentInfo}
        isParentExtended={isParentExtended}
        passedType={infoWeNeedToAddContent.type}
        nextTimetrackingStatus={infoWeNeedToAddContent.nextTimetrackingStatus} // will be undefined if not timetrackings
        onComplete={onClose}
        permissionsFromParent={permissionOfParent}
        onAddAnother={() => {
          // if we are adding another gloal expense, we need to reset the infoWeNeedToAddContent
          // so that the user can choose a new type of expense
          if (GLOBAL_EXPENSE_TYPES.includes(infoWeNeedToAddContent?.type)) {
            setInfoWeNeedToAddContent(null);
          }
        }}
      />
    );
  };

  return (
    <LevelModal open handleClose={onClose}>
      <div
        style={{ minHeight: '50vh', display: 'flex', flexDirection: 'column' }}
      >
        <div className={classes.optionWrapper}>
          <IconButton
            onClick={() => onClose(null)}
            className={classes.optionButton}
          >
            <CancelOutlinedIcon fontSize="inherit" color="primary" />
          </IconButton>
        </div>
        {loading ? <LoadingCover /> : renderWhichContent()}
      </div>
    </LevelModal>
  );
};

export default withRouter(ContentDetailsModal);
