import gql from 'graphql-tag';

export default gql`
  query GetUsersByJrnId($jrnId: String!) {
    getUsersByJrnId(jrnId: $jrnId) {
      items {
        userId
        username
        firstName
        lastName
        profilePic
        accessMode
        userType
        type
      }
    }
  }
`;
