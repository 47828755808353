import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo-hooks';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash';
import moment from 'moment';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import GetUsersByJrnId from '../../graphql/queries/GetUsersByJrnId';

import {
  dateBetween,
  dateAfter,
  dateBefore,
  searchTextFieldInObject,
} from '../../helpers/searchHelpers';

import { runAnalytics } from '../../helpers';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#eaeaea',
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: '4px',
  },
  cancelIconButton: {
    top: '-5px',
    right: '-7px',
    padding: '3px',
  },
  button: {
    textAlign: 'right',
  },
  apply: {
    color: 'white',
    background: theme.palette.brandColorPrimary,
    '&.Mui-disabled': {
      color: '#eeeeee',
      background: theme.palette.brandColorPrimary75,
    },
  },
  start: {
    paddingRight: '10px',
  },
  end: {
    paddingLeft: '10px',
  },
  results: {
    textAlign: 'center',
    color: 'red',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginRight: 2,
    backgroundColor: '#ccc',
    height: 20,
  },
  fieldWithClearButton: {
    display: 'flex',
    flex: 1,
  },
  clearIconButton: {
    display: 'flex',
    marginLeft: 8,
    padding: 0,
  },
}));

const SearchAndFilter = props => {
  const {
    data,
    returnData,
    project,
    onClose,
    hasAdminRights,
    timeManager,
    userInfo,
  } = props;
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = React.useState('');
  const [chosenTypes, setChosenTypes] = React.useState([]);
  const [createdBy, setCreatedBy] = React.useState([]);
  const [assigned, setAssigned] = React.useState([]);
  const [labels, setLabels] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  let disabled = true;

  const [noResults, setNoResults] = React.useState(false);

  const [uniqueTypeList, setUniqueTypeList] = React.useState([]);
  const [uniqueCreatorIdList, setUniqueCreatorIdList] = React.useState([]);
  const [uniqueAssignedToList, setUniqueAssignedToList] = React.useState([]);
  const [uniqueLabelList, setUniqueLabelList] = React.useState([]);

  useEffect(() => {
    if (data && data.length) {
      const uniqueTypeListTemp = [];
      const uniqueCreatorIdListTemp = [];
      const uniqueAssignedToListTemp = [];
      const uniqueLabelListTemp = [];
      data.forEach(contentPiece => {
        // have to check for "punchlistItem" until that type finally doesn't exist anymore
        const updatedType =
          contentPiece.type === 'punchlistItem' ? 'task' : contentPiece.type;
        if (!uniqueTypeListTemp.includes(updatedType)) {
          uniqueTypeListTemp.push(updatedType);
        }
        if (!uniqueCreatorIdListTemp.includes(contentPiece.creatorId)) {
          uniqueCreatorIdListTemp.push(contentPiece.creatorId);
        }
        if (!uniqueAssignedToListTemp.includes(contentPiece.assignedTo)) {
          if (
            contentPiece.assignedTo !== 'unassigned' &&
            contentPiece.assignedTo !== null
          ) {
            uniqueAssignedToListTemp.push(contentPiece.assignedTo);
          }
        }
        // for each label check if it already exists in the uniqueLabelsList
        if (contentPiece.labels && contentPiece.labels.length) {
          contentPiece.labels.forEach(label => {
            if (!uniqueLabelListTemp.includes(label)) {
              uniqueLabelListTemp.push(label);
            }
          });
        }
      });
      setUniqueTypeList(uniqueTypeListTemp.sort());
      setUniqueCreatorIdList(uniqueCreatorIdListTemp); // sort doest matter here since it's ID based
      setUniqueAssignedToList(uniqueAssignedToListTemp); // sort doest matter here since it's ID based
      setUniqueLabelList(uniqueLabelListTemp.sort());
    }
  }, [data]);

  // gets all users associated with the project
  const GetUsersByJrnIdQuery = useQuery(GetUsersByJrnId, {
    variables: { jrnId: data[0].jrnId },
    fetchPolicy: 'cache-and-network',
  });

  const getUserName = id => {
    const users = GetUsersByJrnIdQuery.data.getUsersByJrnId.items;
    const user = _.find(users, { userId: id });
    if (user) return user.username;
    return null;
  };

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  const handleType = event => {
    setChosenTypes(event.target.value);
  };

  const handleCreatedBy = event => {
    setCreatedBy(event.target.value);
  };

  const handleAssigned = event => {
    setAssigned(event.target.value);
  };

  const handleLabels = event => {
    setLabels(event.target.value);
  };

  const handleStartDate = passedDate => {
    setStartDate(passedDate);
  };

  const handleEndDate = passedDate => {
    // const date = moment(passedDate).format('MMM D, YYYY');
    setEndDate(passedDate);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setChosenTypes([]);
    setCreatedBy([]);
    setAssigned([]);
    setLabels([]);
    setStartDate(null);
    setEndDate(null);
    disabled = true;
    setNoResults(false);

    // return all data
    returnData(data);
  };

  const handleClose = () => {
    clearSearch();
    onClose();
  };

  const exportTimesheets = () => {
    let exportUserIds = [...assigned];

    // If no assignee, export all
    if (exportUserIds.length === 0) {
      exportUserIds = null;
    }

    runAnalytics('Contents', {
      contentAction: 'Export Content',
      userId: userInfo.userId,
      username: userInfo.username,
      type: 'Project Timetracking',
      //   type:
      // chosenTypes === 'timetracking'
      //   ? 'Project Timetracking'
      //   : 'Project Receipts',
    });

    const csvData = timeManager.generateExport({
      userIds: exportUserIds,
      startDate: startDate ? startDate.startOf('day').toDate() : null,
      endDate: endDate ? endDate.endOf('day').toDate() : null,
      includeCheckinPhotos: !!hasAdminRights,
    });

    const a = document.createElement('a');
    const file = new Blob([csvData], { type: 'text/plain' });
    a.href = URL.createObjectURL(file);
    a.download = `time-export-${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
    a.click();
  };

  const emailList = results => {
    const emailLineBreak = '%0D%0A';
    const emailSpace = '%20';
    const subject = `Level: "${project.title}" content list`;
    let body = `Here's the list of content requested from "${project.title}":`;
    body += emailLineBreak;
    body += emailSpace;
    body += emailLineBreak;
    results.forEach(contentItem => {
      if (
        contentItem.type === 'image' ||
        contentItem.type === 'receipt' ||
        contentItem.type === 'gallery' ||
        contentItem.type === 'video'
      ) {
        let listOfUrls = [];
        if (contentItem.type === 'gallery') {
          const galleryObject = JSON.parse(contentItem.contentUrl);
          const galleryUrls = galleryObject
            ? galleryObject.map(item => item.uri)
            : [];
          listOfUrls = [...galleryUrls];
        } else {
          listOfUrls.push(contentItem.contentUrl);
        }
        // console.log('listOfUrls: ', listOfUrls);
        // TODO: could add a snippet of the description before
        //  the content so people know the context of the photo
        listOfUrls.forEach(url => {
          // could do a check for cloudinary here
          // if (contentItem.contentUrl.contains('cloudinary')) {}

          body += `- ${url}${emailLineBreak}`;
        });
      } else if (
        contentItem.type === 'task' ||
        contentItem.type === 'punchlistItem'
      ) {
        body += `Task: ${contentItem.title}`;
        body += emailLineBreak;
      } else if (contentItem.type === 'timetracking') {
        body += `Clock in/out: ${contentItem.creatorId} clocked ${
          contentItem.contentStatus === 'checkin' ? 'in' : 'out'
        }`;
        body += emailLineBreak;
      }
      // else if (contentItem.type === 'shift') {
      //   body += `Shift: ${contentItem.title}`;
      //   body += emailLineBreak;
      // } else if (contentItem.type === 'textnote') {
      //   body += `Description: ${description}`;
      //   body += emailLineBreak;
      // }
    });

    body += emailLineBreak;
    body += emailSpace;
    body += emailLineBreak;
    body += emailSpace;
    body += emailLineBreak;
    body += 'Sent via the Level app: https://checkthelevel.com';

    const newTab = `mailto:?subject=${subject}&body=${body}`;
    window.open(newTab, '_blank');
  };

  const executeSearch = async (share = '') => {
    let searchResults = [...data];
    // if the user is looking for tasks, include the old "punchlistItem" type too
    if (chosenTypes.includes('task')) {
      chosenTypes.push('punchlistItem');
    }
    if (searchTerm) {
      const cleanSearchTerm = searchTerm.trim();
      // START - SEARCH - apply the search term
      if (cleanSearchTerm.length > 0) {
        // indexof the title and description of the jrnContent to find the ones that match
        searchResults = _.filter(searchResults, jrnToCheck => {
          const inDescription = searchTextFieldInObject(
            jrnToCheck,
            'description',
            cleanSearchTerm
          );
          const inTitle = searchTextFieldInObject(
            jrnToCheck,
            'title',
            cleanSearchTerm
          );
          const inAmount = searchTextFieldInObject(
            jrnToCheck.amount,
            'value',
            cleanSearchTerm,
            true
          );
          const inAnyComments = jrnToCheck.comments?.items?.some(comment => {
            return searchTextFieldInObject(comment, 'content', cleanSearchTerm);
          });
          const inGlobalExpenseDocumentNumber = searchTextFieldInObject(
            jrnToCheck.globalExpense,
            'documentNumber',
            cleanSearchTerm
          );
          const inGlobalExpenseValue = searchTextFieldInObject(
            jrnToCheck.globalExpense?.amount,
            'value',
            cleanSearchTerm,
            true
          );
          return (
            inDescription ||
            inTitle ||
            inAmount ||
            inAnyComments ||
            inGlobalExpenseDocumentNumber ||
            inGlobalExpenseValue
          );
        });
      }
    }

    // START - type filter
    // punchlistItem
    if (chosenTypes.length) {
      searchResults = _.filter(searchResults, result => {
        return chosenTypes.includes(result.type);
      });
    }

    // START - createdBy filter
    if (createdBy.length) {
      searchResults = _.filter(searchResults, result =>
        createdBy.includes(result.creatorId)
      );
    }
    // START - assignedTo filter
    if (assigned.length) {
      searchResults = _.filter(searchResults, result => {
        // take null to also mean unassigned (e.g. an image would have assignedTo=null but for tasks assignedTo="unassigned")
        if (assigned.includes(null) && result === 'unassigned') return true;
        return assigned.includes(result.assignedTo);
      });
    }

    // START - now apply the labels if there are any chosen
    if (labels.length) {
      searchResults = _.filter(searchResults, result => {
        if (labels.includes(null) && !result.labels.length) return true; // take into account if they've chosen none
        const intersect = _.intersection(labels, result.labels);
        return intersect.length > 0;
      });
    }

    // START - DATE RANGE
    // have a calendar pick where they can pick a single date or date range
    // then check the dates here using moment()

    if (startDate && endDate) {
      searchResults = _.filter(searchResults, result => {
        return dateBetween(result.date, startDate, endDate);
      });
    } else if (startDate) {
      searchResults = _.filter(searchResults, result => {
        return dateAfter(result.date, startDate);
      });
    } else if (endDate) {
      searchResults = _.filter(searchResults, result => {
        return dateBefore(result.date, endDate);
      });
    }

    // END - DATE;
    if (share === 'send') {
      emailList(searchResults);
    }
    setNoResults(false);
    if (searchResults.length < 1) {
      setNoResults(true);
    }

    // END - TAGS - now apply the tags if th
    returnData(searchResults);
  };

  const renderChips = ({
    selected = [],
    wordingForNone = 'None',
    listType,
  }) => {
    // if type is assignedTo or createdBy, use getUserName(ID-HERE)
    return (
      <div className={classes.chips}>
        {selected.map(value => {
          let labelToUse;
          if (
            listType &&
            value &&
            (listType === 'createdBy' || listType === 'assignedTo')
          ) {
            labelToUse = getUserName(value);
          } else {
            labelToUse = value || wordingForNone;
          }
          return (
            <Chip key={value} label={labelToUse} className={classes.chip} />
          );
        })}
      </div>
    );
  };

  // disable share button when filters are blank or when start and end date dont both equal current date
  if (
    searchTerm !== '' ||
    chosenTypes.length ||
    createdBy.length ||
    assigned.length ||
    labels.length ||
    startDate !== null ||
    endDate !== null
  ) {
    disabled = false;
  } else {
    disabled = true;
  }

  if (chosenTypes === 'shift' || chosenTypes === 'textnote') {
    disabled = true;
  }

  let allowTimetrackingExport = false;

  const onlyTimetrackingSelected =
    chosenTypes &&
    chosenTypes.length === 1 &&
    chosenTypes[0] === 'timetracking';

  if (
    onlyTimetrackingSelected &&
    !searchTerm &&
    labels.length === 0 &&
    createdBy.length === 0 &&
    hasAdminRights &&
    timeManager
  ) {
    allowTimetrackingExport = true;
  }

  const shareButtonAction = allowTimetrackingExport ? 'export' : 'send';
  const shareButtonLabel = allowTimetrackingExport ? 'Export' : 'Send...';

  // only show the send button if only (images/galleries/videos) are chosen or if (timetracking) only is chosen
  const shareIfTypes = ['image', 'receipt', 'gallery', 'video'];
  let showShareButton = false;
  let theresABadType = false;
  // check to see if any of the chosen chosenTypess are NOT in the shareIfTypes and if so, dont show the share button
  //  also don't share it when the only thing chosen is NOT timetracking
  chosenTypes.forEach(typeChosen => {
    if (!shareIfTypes.includes(typeChosen)) {
      theresABadType = true;
    }
  });
  if (
    !theresABadType ||
    (chosenTypes.length === 1 &&
      chosenTypes[0] === 'timetracking' &&
      allowTimetrackingExport)
  ) {
    if (chosenTypes.length) {
      showShareButton = true;
    }
  }
  return (
    <Grid item className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={11}>
          <Typography variant="h2">Search &amp; Filter</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={handleClose}
            className={classes.cancelIconButton}
          >
            <CancelIcon fontSize="large" className={classes.cancelIcon} />
          </IconButton>
        </Grid>
        <Grid item container xs={12} md={6}>
          <InputLabel>Search</InputLabel>
          <Grid item container xs={12}>
            <TextField
              className={classes.fieldWithClearButton}
              placeholder="search terms here..."
              value={searchTerm}
              onChange={handleSearch}
              fullWidth
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  executeSearch();
                }
              }}
            />
            {searchTerm.length > 0 && (
              <IconButton
                className={classes.clearIconButton}
                variant="outlined"
                onClick={() => {
                  setSearchTerm('');
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6}>
          <InputLabel>Type</InputLabel>
          <Grid item container xs={12}>
            <Select
              className={classes.fieldWithClearButton}
              inputProps={{ style: { paddingTop: 0, paddingBottom: 0 } }}
              multiple
              value={chosenTypes}
              onChange={handleType}
              fullWidth
              renderValue={selected => {
                return renderChips({ selected });
              }}
            >
              {uniqueTypeList.map(passedType => (
                <MenuItem key={passedType} value={passedType}>
                  <Checkbox
                    color="primary"
                    checked={chosenTypes.includes(passedType)}
                  />
                  <ListItemText primary={passedType} />
                </MenuItem>
              ))}
            </Select>
            {chosenTypes.length > 0 && (
              <IconButton
                className={classes.clearIconButton}
                variant="outlined"
                onClick={() => {
                  setChosenTypes([]);
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6}>
          <InputLabel>Added By</InputLabel>
          <Grid item container xs={12}>
            <Select
              className={classes.fieldWithClearButton}
              multiple
              value={createdBy}
              onChange={handleCreatedBy}
              fullWidth
              disabled={
                !uniqueCreatorIdList || uniqueCreatorIdList.length === 0
              }
              renderValue={selected => {
                return renderChips({
                  selected,
                  wordingForNone: 'No one',
                  listType: 'createdBy',
                });
              }}
            >
              {uniqueCreatorIdList.map(creatorId => (
                <MenuItem key={creatorId} value={creatorId}>
                  <Checkbox
                    color="primary"
                    checked={createdBy.includes(creatorId)}
                  />
                  <ListItemText primary={getUserName(creatorId)} />
                </MenuItem>
              ))}
            </Select>
            {createdBy.length > 0 && (
              <IconButton
                className={classes.clearIconButton}
                variant="outlined"
                onClick={() => {
                  setCreatedBy([]);
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6}>
          <InputLabel>Assigned To</InputLabel>
          <Grid item container xs={12}>
            <Select
              className={classes.fieldWithClearButton}
              multiple
              value={assigned}
              onChange={handleAssigned}
              fullWidth
              disabled={
                !uniqueAssignedToList || uniqueAssignedToList.length === 0
              }
              renderValue={selected => {
                return renderChips({
                  selected,
                  wordingForNone: 'No one',
                  listType: 'assignedTo',
                });
              }}
            >
              <MenuItem value={null}>
                <Checkbox color="primary" checked={assigned.includes(null)} />
                <ListItemText primary="No one" />
              </MenuItem>
              {uniqueAssignedToList.map(assignedTo => (
                <MenuItem key={assignedTo} value={assignedTo}>
                  <Checkbox
                    color="primary"
                    checked={assigned.includes(assignedTo)}
                  />
                  <ListItemText primary={getUserName(assignedTo)} />
                </MenuItem>
              ))}
            </Select>

            {assigned.length > 0 && (
              <IconButton
                className={classes.clearIconButton}
                variant="outlined"
                onClick={() => {
                  setAssigned([]);
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6}>
          <InputLabel id="labels">Labels</InputLabel>
          <Grid item container xs={12}>
            <Select
              className={classes.fieldWithClearButton}
              multiple
              value={labels}
              onChange={handleLabels}
              renderValue={selected => {
                return renderChips({ selected, wordingForNone: 'No labels' });
              }}
            >
              {uniqueLabelList.map(label => (
                <MenuItem key={label} value={label}>
                  <Checkbox color="primary" checked={labels.includes(label)} />
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
            {labels.length > 0 && (
              <IconButton
                className={classes.clearIconButton}
                variant="outlined"
                onClick={() => {
                  setLabels([]);
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6}>
          <Grid item container xs={6} className={classes.start}>
            <InputLabel>After</InputLabel>
            <Grid item container xs={12}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  className={clsx(
                    classes.TextField,
                    classes.fieldWithClearButton
                  )}
                  variant="inline"
                  disableToolbar
                  value={startDate}
                  format="MMM D, YYYY"
                  onChange={handleStartDate}
                  autoOk
                  initialFocusedDate={project.startDate}
                  maxDate={endDate || undefined}
                />
              </MuiPickersUtilsProvider>
              {startDate && (
                <IconButton
                  className={classes.clearIconButton}
                  variant="outlined"
                  onClick={() => {
                    setStartDate(null);
                  }}
                >
                  <HighlightOffIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={6} className={classes.end}>
            <InputLabel>Before</InputLabel>
            <Grid item container xs={12}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  className={clsx(
                    classes.TextField,
                    classes.fieldWithClearButton
                  )}
                  variant="inline"
                  disableToolbar
                  value={endDate}
                  format="MMM D, YYYY"
                  onChange={handleEndDate}
                  autoOk
                  initialFocusedDate={project.endDate}
                  minDate={startDate || undefined}
                />
              </MuiPickersUtilsProvider>
              {endDate && (
                <IconButton
                  className={classes.clearIconButton}
                  variant="outlined"
                  onClick={() => {
                    setEndDate(null);
                  }}
                >
                  <HighlightOffIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.button}>
          {hasAdminRights && showShareButton && (
            <Button
              disabled={disabled}
              onClick={() =>
                shareButtonAction === 'export'
                  ? exportTimesheets()
                  : executeSearch(shareButtonAction)
              }
            >
              {shareButtonLabel}
            </Button>
          )}
          <Button
            onClick={() => {
              clearSearch();
            }}
          >
            Clear
          </Button>
          <Button
            disabled={disabled}
            onClick={() => {
              executeSearch();
            }}
            className={classes.apply}
          >
            Apply
          </Button>
        </Grid>
        {noResults && (
          <Grid item xs={12} className={classes.results}>
            Sorry no search results were found.
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
  };
}

export default connect(mapStateToProps)(SearchAndFilter);
