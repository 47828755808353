import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  Add as AddIcon,
  Sync as SyncIcon,
  SyncDisabled as SyncDisabledIcon,
} from '@material-ui/icons';

import { QBO_SYNCABLE_TYPE } from '../../config/appDefaults';
import QboSyncNewCustomerDialog from './qbo-sync-to-new-customer-dialog';
import QboSyncExistingCustomerDialog from './qbo-sync-to-existing-customer-dialog';
import QboDisconnectSyncedDialog from './qbo-disconnect-synced-customer-dialog';
import { QBO_SYNC_STATE } from '../../helpers/qbo-sync';

const useStyles = makeStyles(theme => ({
  actionButtons: {
    color: theme.palette.brandColorDarkGrey,
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
}));

const QboSyncMenu = ({
  showQboSyncMenu,
  setShowQboSyncMenu,
  toggleElement,
  recordType,
  parentCustomerInfo,
  searchDefault,
  syncToNewCustomer,
  syncToExistingCustomer,
  disconnectSyncedCustomer,
  syncState,
  checkForUpdates,
}) => {
  const classes = useStyles();

  const [showNewCustomerDialog, setShowNewCustomerDialog] = useState(false);
  const [showExistingCustomerDialog, setShowExistingCustomerDialog] = useState(
    false
  );
  const [
    showDisconnectSyncedCustomerDialog,
    setShowDisconnectSyncedCustomerDialog,
  ] = useState(false);

  const closeMenu = () => setShowQboSyncMenu(false);

  const menuItems = [];
  switch (syncState) {
    case QBO_SYNC_STATE.ACTIVE:
      menuItems.push(
        <MenuItem
          key="disconnect"
          onClick={() => {
            setShowDisconnectSyncedCustomerDialog(true);
            closeMenu();
          }}
          className={classes.actionButtons}
        >
          <SyncDisabledIcon />
          &nbsp;&nbsp;Disconnect from synced customer
        </MenuItem>
      );
      menuItems.push(
        <MenuItem
          key="checkforupdates"
          onClick={() => {
            checkForUpdates();
            closeMenu();
          }}
          className={classes.actionButtons}
        >
          <SyncIcon />
          &nbsp;&nbsp;Check for updates
        </MenuItem>
      );
      break;
    case QBO_SYNC_STATE.NOT_SYNCED:
      menuItems.push(
        <MenuItem
          key="sync-with-new"
          onClick={() => {
            setShowNewCustomerDialog(true);
            closeMenu();
          }}
          className={classes.actionButtons}
        >
          <AddIcon />
          &nbsp;&nbsp;Sync with new{' '}
          {recordType !== QBO_SYNCABLE_TYPE.CUSTOMER
            ? 'sub-customer'
            : 'customer'}
        </MenuItem>
      );
      menuItems.push(
        <MenuItem
          key="sync-with-existing"
          onClick={() => {
            setShowExistingCustomerDialog(true);
            closeMenu();
          }}
          className={classes.actionButtons}
        >
          <SyncIcon />
          &nbsp;&nbsp;Sync with existing{' '}
          {recordType !== QBO_SYNCABLE_TYPE.CUSTOMER
            ? 'sub-customer'
            : 'customer'}
        </MenuItem>
      );
      break;
    case QBO_SYNC_STATE.SYNCING:
    case QBO_SYNC_STATE.ERROR:
      menuItems.push(
        <MenuItem
          key="disconnect"
          onClick={() => {
            setShowDisconnectSyncedCustomerDialog(true);
            closeMenu();
          }}
          className={classes.actionButtons}
        >
          <SyncDisabledIcon />
          &nbsp;&nbsp;Disconnect from synced customer
        </MenuItem>
      );

      break;
    default:
      break;
  }

  return (
    <>
      <Menu
        id="qbo-sync-menu"
        anchorEl={toggleElement}
        keepMounted
        open={showQboSyncMenu}
        onClose={closeMenu}
      >
        {menuItems}
      </Menu>
      {showNewCustomerDialog && (
        <QboSyncNewCustomerDialog
          syncToNewCustomer={syncToNewCustomer}
          handleClose={() => setShowNewCustomerDialog(false)}
          recordType={recordType}
        />
      )}
      {showExistingCustomerDialog && (
        <QboSyncExistingCustomerDialog
          syncToExistingCustomer={syncToExistingCustomer}
          handleClose={() => setShowExistingCustomerDialog(false)}
          recordType={recordType}
          parentCustomerInfo={parentCustomerInfo}
          searchDefault={searchDefault}
        />
      )}
      {showDisconnectSyncedCustomerDialog && (
        <QboDisconnectSyncedDialog
          disconnectSyncedCustomer={disconnectSyncedCustomer}
          handleClose={() => setShowDisconnectSyncedCustomerDialog(false)}
          recordType={recordType}
        />
      )}
    </>
  );
};

export default QboSyncMenu;
