import React from 'react';
import _ from 'lodash';
import { InputLabel } from '@material-ui/core';
import FormikSelect from '../../../components/formik-custom-components/formik-select';

import {
  PAYMENT_METHOD,
  PAYMENT_METHOD_LABEL,
} from '../../../config/appDefaults';

const PaymentMethodField = ({
  classes,
  disabled,
  values,
  error,
  touched,
  setFieldValue,
  onClose,
}) => {
  const paymentMethodOptions = _.map(_.values(PAYMENT_METHOD), value => {
    return { value, label: PAYMENT_METHOD_LABEL[value] };
  });

  const handleChange = event => {
    setFieldValue('subtype', event.target.value, true);
  };

  const showError = !!error && !!touched;

  return (
    <>
      <InputLabel
        className={classes.staticLabel}
        htmlFor="subtype"
        disabled={disabled}
        error={showError}
      >
        Payment Method
      </InputLabel>
      <FormikSelect
        name="subtype"
        value={values.subtype || ''}
        error={showError ? error : null}
        options={paymentMethodOptions}
        handleChange={handleChange}
        onClose={onClose}
        disabled={disabled}
      />
    </>
  );
};

export default PaymentMethodField;
