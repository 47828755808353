import gql from 'graphql-tag';

export default gql`
  fragment BookkeepingEventMetaFields on BookkeepingEventMeta {
    id
    alertInstanceId
    snapshotId
    companyId
    reportDate
    metaType
    value
    alertIdentifier
    creatorId
    dateCreated
    modifierId
    dateModified
  }
`;
