import numeral from 'numeral';
import _ from 'lodash';

export const asCurrency = (value, showZeroValue) => {
  if (_.isNil(value) || (value === 0 && !showZeroValue)) {
    return '-';
  }
  return numeral(value).format('$0,0.00');
};

export const asPercentage = value => {
  if (!value) {
    return '-';
  }
  return numeral(value).format('0.00%');
};

export const asHours = value => {
  if (!value) {
    return '0';
  }
  return numeral(value).format('0,0.00');
};
