import React from 'react';
import { Close as CloseIcon } from '@material-ui/icons';
import { Grid, IconButton, Typography } from '@material-ui/core';

import productIcon from '../../assets/images/cam/copilot_mascot_004.jpg';
import ConnectToQuickBooks from '../connect-to-quickbooks/connect-to-quickbooks';
import LevelModal from '../Modal/level-modal';

const FirmAddClientDialog = ({ onClose }) => {
  return (
    <LevelModal
      open
      fullWidth={false}
      maxWidth="sm"
      dialogStyle={{ zIndex: '9999' }}
    >
      <Grid container alignItems="center" style={{ position: 'relative' }}>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: 16 }}
        >
          <Typography
            variant="h4"
            style={{ textAlign: 'center', paddingLeft: 8, flex: 1 }}
          >
            Add a Client from QuickBooks Online
          </Typography>
          <IconButton onClick={onClose} style={{ flex: 0 }}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ marginBottom: 16 }}
          alignItems="center"
        >
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <img
              src={productIcon}
              style={{ width: 'auto', height: 200 }}
              alt="group of abstract objects representing bookkeeping alerts"
            />
          </Grid>
          <Grid container item justifyContent="center" xs={8}>
            <Typography variant="body1" style={{ fontSize: 16 }}>
              Add clients to your firm by clicking the button below and
              connecting Level to their QuickBooks Online account!
            </Typography>

            <Grid container justifyContent="center" style={{ marginTop: 32 }}>
              <ConnectToQuickBooks redirectTo="/firm" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LevelModal>
  );
};

export default FirmAddClientDialog;
