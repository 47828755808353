import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Company}
  mutation MakeCompanyAdmin(
    $companyId: String!
    $userId: String!
    $role: String
  ) {
    makeCompanyAdmin(
      input: { companyId: $companyId, userId: $userId, role: $role }
    ) {
      ...CompanyFields
    }
  }
`;
