import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Button, Grid, Typography } from '@material-ui/core';
import LevelModal from '../Modal/level-modal';
import { runAnalytics } from '../../helpers';

const InvitedWhileLoggedInDialog = ({
  dispatch,
  userInfo,
  companyRegistrationKey,
}) => {
  const handleSignOut = async () => {
    await Auth.signOut();

    const options = {
      userId: userInfo.userId,
      username: userInfo.username,
      type: 'Log Out',
      email: userInfo.email,
    };
    runAnalytics('Log', options);

    const inviteLink = `/invitation?key=${companyRegistrationKey}`;
    dispatch({
      type: 'SHOW_ERROR_INVITE_MODAL',
      payload: false,
    });
    dispatch({
      type: 'SIGNOUT_SUCCEEDED',
      payload: { redirectToOnSignOut: inviteLink },
    });
  };

  const handleClose = () => {
    dispatch({
      type: 'SHOW_ERROR_INVITE_MODAL',
      payload: false,
    });
  };
  return (
    <LevelModal open handleClose={handleClose} fullWidth maxWidth="sm">
      <Grid container style={{ minHeight: 200 }} spacing={2} direction="column">
        <Grid item>
          <Typography variant="h4">Already with a Company</Typography>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Typography variant="body1" style={{ marginBottom: 24 }}>
            This user account is already part of a company. To accept this
            invitation, click the &quot;Log Out&quot; button below and sign up
            as a new user.
          </Typography>
          <Typography variant="body1" style={{ fontStyle: 'italic' }}>
            Note: A user cannot be part of multiple companies.
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: 'right' }}>
          <Button
            onClick={() => {
              dispatch({
                type: 'SHOW_ERROR_INVITE_MODAL',
                payload: false,
              });
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSignOut();
              // now redirect
            }}
            color="primary"
            variant="contained"
          >
            Log Out
          </Button>
        </Grid>
      </Grid>
    </LevelModal>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  companyRegistrationKey: state.appState.companyRegistrationKey || null,
});

export default withRouter(connect(mapStateToProps)(InvitedWhileLoggedInDialog));
