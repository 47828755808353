import React from 'react';
import { Dialog, DialogContent, Typography } from '@material-ui/core';

import DialogTitle from '../../../components/dialog-title/dialog-title';
import ChooseProjectWithSubs from '../../../components/choose-project-dialog/choose-project-with-subs';

const AssignFlowInfoDialog = ({
  assignFlowInfo,
  setAssignFlowInfo,
  handleParentChoice,
}) => {
  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={assignFlowInfo.open}
      PaperProps={{ style: { minHeight: '75%' } }}
    >
      <DialogTitle
        onClose={() => {
          setAssignFlowInfo({ ...assignFlowInfo, open: false });
        }}
      />
      <DialogContent>
        <>
          <Typography variant="h2" gutterBottom align="center" color="primary">
            Choose another project to add this to:
          </Typography>
          <ChooseProjectWithSubs setSelectedProject={handleParentChoice} />
        </>
      </DialogContent>
    </Dialog>
  );
};

export default AssignFlowInfoDialog;
