import React, { useEffect, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import { connect } from 'react-redux';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Button,
  Link,
} from '@material-ui/core';
import { ChevronRight as ChevronRightIcon } from '@material-ui/icons';

import _ from 'lodash';

import LevelModal from '../Modal/level-modal';
import CreditCardItem from './credit-card-item';
import LoadingCover from '../LoadingCover/loadingCover';
import {
  CREDIT_CARD_TYPE,
  MAZUMAGO_CREDIT_CARD_STATUS,
  MAZUMAGO_MANAGE_CARDS_URL,
} from '../../config/appDefaults';

import ListMazumagoCards from '../../graphql/queries/list-mazumago-cards';
import ListMazumagoCardsForUser from '../../graphql/queries/list-mazumago-cards-for-user';
import {
  AddCardAccessForUserAction,
  RemoveCardAccessAction,
} from '../../graphql/graphql';

const useStyles = makeStyles(theme => {
  const heightOfFooter = 68;
  return {
    mainContainer: {
      minHeight: '70vh',
      paddingTop: 0,
      paddingBottom: heightOfFooter,
      width: '100%',
    },
    accordion: {
      width: '100%',
    },
    accordionSummaryIcon: {
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
    },
    cardsContainer: {
      minHeight: 190,
    },
    footer: {
      height: heightOfFooter,
      background: '#ececec',
      padding: theme.spacing(2),
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 3,
    },
  };
});

const ManageCreditCardsDialog = ({
  managingCompanyInfo,
  open,
  onClose,
  user,
  onAddCardAccessForUser,
  onRemoveCardAccess,
}) => {
  const classes = useStyles();
  const [allCardsMap, setAllCardsMap] = useState({});
  const [availableCardIds, setAvailableCardIds] = useState([]);
  const [assignedCardIds, setAssignedCardIds] = useState([]);
  const [closedNotAssignCardIds, setClosedNotAssignedCardIds] = useState([]);
  const [inactiveCardIds, setInactiveCardIds] = useState([]);

  const [loadingMessage, setLoadingMessage] = useState('');

  // Get all cards for the company {{{
  const allCardsQuery = useQuery(ListMazumagoCards, {
    skip: !managingCompanyInfo.managingCompanyId,
    variables: { companyId: managingCompanyInfo.managingCompanyId },
    fetchPolicy: 'cache-and-network',
  });

  const allCards = _.get(allCardsQuery, 'data.listMazumaGoCards.items');
  const listMazumaGoCardsLoading = allCardsQuery.loading;
  // }}}

  // Get all assigned cards for the user {{{
  const assignedCardsQuery = useQuery(ListMazumagoCardsForUser, {
    skip: !managingCompanyInfo.managingCompanyId || !user.userId,
    variables: {
      companyId: managingCompanyInfo.managingCompanyId,
      userId: user.userId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const assignedCards = _.get(
    assignedCardsQuery,
    'data.listMazumaGoCardsForUser.items'
  );
  const listMazumaGoCardsForUserLoading = assignedCardsQuery.loading;
  // }}}

  const sortCards = cards => {
    return _.orderBy(
      cards,
      ['status', 'nickname', 'lastFourDigits'],
      ['asc', 'asc', 'asc']
    );
  };

  useEffect(() => {
    if (allCards && assignedCards) {
      const allCreditCardsMap = {};
      const availableCreditCardIds = [];
      const assignedCreditCardIds = [];
      const closedNotAssignedCreditCardIds = [];
      const inactiveCreditCardIds = [];
      const assignedCreditCardIdsMap = {};

      // sort cards
      const sortedAllCards = sortCards(allCards);
      const sortedAssignedCards = sortCards(assignedCards);

      sortedAssignedCards.forEach(card => {
        assignedCreditCardIds.push(card.cardId);
        assignedCreditCardIdsMap[card.cardId] = card;
      });

      sortedAllCards.forEach(card => {
        // add card to allCardsMap
        allCreditCardsMap[card.cardId] = card;

        if (!assignedCreditCardIdsMap[card.cardId]) {
          if (
            card.status === MAZUMAGO_CREDIT_CARD_STATUS.CLOSED_BY_CUSTOMER.value
          ) {
            // add closed and not-assigned cardIds to closedNotAssignedCreditCardIds
            closedNotAssignedCreditCardIds.push(card.cardId);
          } else if (
            card.status === MAZUMAGO_CREDIT_CARD_STATUS.INACTIVE.value
          ) {
            // add inactive cardIds to inactiveCreditCardIds
            inactiveCreditCardIds.push(card.cardId);
          } else {
            // add available (active or frozen) and not-assigned cardIds to availableCreditCardIds
            availableCreditCardIds.push(card.cardId);
          }
        }
      });

      setAllCardsMap(allCreditCardsMap);
      setAvailableCardIds(availableCreditCardIds);
      setAssignedCardIds(assignedCreditCardIds);
      setClosedNotAssignedCardIds(closedNotAssignedCreditCardIds);
      setInactiveCardIds(inactiveCreditCardIds);
    }
  }, [allCards, assignedCards]);

  const userFullName = useMemo(() => {
    if (user) {
      const names = [];
      if (user.firstName) {
        names.push(user.firstName);
      }
      if (user.lastName) {
        names.push(user.lastName);
      }
      return names.join(' ');
    }
    return '';
  }, [user]);

  const onAssignCard = async ({ userId, cardId }) => {
    setLoadingMessage('Assigning card...');
    await onAddCardAccessForUser({
      userId,
      cardId,
      cardType: CREDIT_CARD_TYPE.MAZUMAGO,
      options: { card: allCardsMap[cardId] },
    });
    setLoadingMessage('');
  };

  const onRevokeCard = async ({ userId, cardId }) => {
    setLoadingMessage('Revoking card...');
    await onRemoveCardAccess({ userId, cardId });
    setLoadingMessage('');
  };

  const handleActionButtonClick = (userId, cardId) => async isAssigning => {
    if (isAssigning) {
      onAssignCard({ userId, cardId });
    } else {
      onRevokeCard({ userId, cardId });
    }
  };

  const renderCreditCards = ({
    cardIds = [],
    isAssigned,
    noCardsMessage,
    hideActionButton = false,
    disabled = false,
  }) => {
    if (cardIds.length === 0) {
      return <Typography variant="body2">{noCardsMessage}</Typography>;
    }
    return cardIds.map(cardId => {
      return (
        <Grid item key={cardId}>
          <CreditCardItem
            card={allCardsMap[cardId]}
            user={user}
            isAssigned={isAssigned}
            onActionButtonClick={handleActionButtonClick(user.userId, cardId)}
            hideActionButton={hideActionButton}
            disabled={disabled}
          />
        </Grid>
      );
    });
  };

  return (
    <LevelModal open={open}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 8,
        }}
      >
        <div className={classes.mainContainer}>
          <Accordion defaultExpanded className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ChevronRightIcon />}
              className={classes.accordionSummaryIcon}
            >
              <Typography variant="h5">
                Credit Cards Assigned to{' '}
                <b>
                  {userFullName} ({user.username})
                </b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                className={classes.cardsContainer}
                justifyContent="space-evenly"
                spacing={2}
              >
                {renderCreditCards({
                  cardIds: assignedCardIds,
                  isAssigned: true,
                  noCardsMessage: 'No credit cards assigned.',
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ChevronRightIcon />}
              className={classes.accordionSummaryIcon}
            >
              <Typography variant="h5">{`Available Credit Cards (${availableCardIds.length})`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                className={classes.cardsContainer}
                justifyContent="space-evenly"
                spacing={2}
              >
                {renderCreditCards({
                  cardIds: availableCardIds,
                  isAssigned: false,
                  noCardsMessage: 'No credit cards available.',
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ChevronRightIcon />}
              className={classes.accordionSummaryIcon}
            >
              <Typography variant="h5">{`Inactive Credit Cards (${inactiveCardIds.length})`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                className={classes.cardsContainer}
                justifyContent="space-evenly"
                spacing={2}
              >
                {renderCreditCards({
                  cardIds: inactiveCardIds,
                  isAssigned: false,
                  noCardsMessage: 'No inactive credit cards.',
                  hideActionButton: true,
                  disabled: true,
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ChevronRightIcon />}
              className={classes.accordionSummaryIcon}
            >
              <Typography variant="h5">{`Closed Credit Cards (${closedNotAssignCardIds.length})`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                className={classes.cardsContainer}
                justifyContent="space-evenly"
                spacing={2}
              >
                {renderCreditCards({
                  cardIds: closedNotAssignCardIds,
                  isAssigned: false,
                  noCardsMessage: 'No closed credit cards.',
                  hideActionButton: true,
                  disabled: true,
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
        <Grid container className={classes.footer}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ flex: 1, paddingLeft: 8, paddingRight: 8 }}
          >
            {/* NOTE: This link here for user convenience.
            We will remove it when we have our own card management functionality */}
            <Typography>
              Want to manage credit cards in the Truss wallet?&nbsp;
              <Link
                href={MAZUMAGO_MANAGE_CARDS_URL}
                target="_blank"
                rel="noopener"
                onClick={() => {
                  // we close the modal, so when the users go back to this page, they have to open the modal again.
                  // That makes sure they will get the lastest card info from Truss server.
                  onClose();
                }}
              >
                Click here
              </Link>
            </Typography>
            <Button onClick={onClose} variant="contained">
              Close
            </Button>
          </Grid>
        </Grid>
      </div>
      {(listMazumaGoCardsLoading ||
        listMazumaGoCardsForUserLoading ||
        !!loadingMessage) && (
        <LoadingCover loader="linear">
          <Typography>{loadingMessage || 'Loading card info...'}</Typography>
        </LoadingCover>
      )}
    </LevelModal>
  );
};

function mapStateToProps(state) {
  return {
    managingCompanyInfo: state.appState.managingCompanyInfo || {},
  };
}

export default compose(
  AddCardAccessForUserAction,
  RemoveCardAccessAction
)(connect(mapStateToProps)(ManageCreditCardsDialog));
