import React, { useState, useEffect } from 'react';
import { compose } from 'react-apollo';
import _ from 'lodash';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from '@material-ui/core';
import palette from '../../../theme/palette';
import GraphQL from '../../../graphql';
import ChooseQuickbooksAccounts from '../../../components/choose-quickbooks-accounts';
import LoadingCover from '../../../components/LoadingCover/loadingCover';

import { AUTOSELECT_UNCATEGORIZED_ACCOUNT_NAMES } from '../../../config/appDefaults';

const ChooseUncategorizedAccounts = ({
  currentAccounts,
  handleDialogClose,
  handleSave,
  getAllCompanyAccountsLoading,
  allCompanyAccounts,
}) => {
  const [selectedAccounts, setSelectedAccounts] = useState(currentAccounts);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (
      allCompanyAccounts &&
      (!currentAccounts || currentAccounts.length === 0)
    ) {
      // iterate through the accounts and look for any named "Uncategorized Income", "Uncategorized Expense", or "Uncategorized Asset"
      // if found, add it to the selectedAccounts
      const copySelectedAccounts = [...selectedAccounts];
      allCompanyAccounts.forEach(account => {
        if (
          AUTOSELECT_UNCATEGORIZED_ACCOUNT_NAMES.includes(
            account.accountName
          ) &&
          !copySelectedAccounts.includes(account.sourceId)
        ) {
          copySelectedAccounts.push(account.sourceId);
        }
      });
      setSelectedAccounts(copySelectedAccounts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCompanyAccounts]);

  const hasChange = !_.isEqual(selectedAccounts, currentAccounts);

  const handleAccountChosen = sourceId => {
    const copySelectedAccounts = [...selectedAccounts];
    if (selectedAccounts.includes(sourceId)) {
      _.remove(copySelectedAccounts, id => id === sourceId);
    } else {
      copySelectedAccounts.push(sourceId);
    }
    setSelectedAccounts(copySelectedAccounts);
  };

  const handleDone = async () => {
    // Start loader
    setIsSubmitting(true);
    // Do update
    await handleSave(selectedAccounts);
    // Stop loader
    setIsSubmitting(false);
    // Close dialog
    handleDialogClose();
  };

  return (
    <Dialog
      open
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
    >
      {(getAllCompanyAccountsLoading || isSubmitting) && <LoadingCover />}
      <DialogTitle disableTypography>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Choose Uncategorized Accounts</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" style={{ color: 'green' }}>
              ({selectedAccounts.length} selected)
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        dividers
        style={{
          paddingTop: 20,
          paddingBottom: 20,
          minWidth: 550,
          minHeight: 250,
          position: 'relative',
        }}
      >
        <DialogContentText style={{ color: palette.text.primary }}>
          Select an account to use for your Uncategorized transactions. These
          will be pulled into Level to be used for creating RFI&apos;s.
        </DialogContentText>
        <ChooseQuickbooksAccounts
          accounts={allCompanyAccounts}
          currentAccounts={currentAccounts}
          selectedAccounts={selectedAccounts}
          isLoading={getAllCompanyAccountsLoading}
          onChooseAccount={handleAccountChosen}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleDialogClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleDone}
          color="primary"
          variant="contained"
          disabled={!hasChange || isSubmitting || getAllCompanyAccountsLoading}
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default compose(
  GraphQL.GetAllCompanyAccountsAction
  // GraphQL.GetCompanyExpenseAccountsAction
  // GraphQL.UpdateScoreboardSettingsAction
)(ChooseUncategorizedAccounts);
