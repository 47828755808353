import React from 'react';
import _ from 'lodash';
import {
  Grid,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import styles from './job-cost.style';

const useStyles = makeStyles(styles);

const JobCostSettings = ({
  graph: { name: jobCostType, label },
  settings,
  jobCostAccounts,
  onJobCostAccountChanges,
}) => {
  const classes = useStyles();

  const handleSelectedAccountChange = event => {
    onJobCostAccountChanges({
      jobCostType,
      ...settings,
      sourceAccount: event.target.value,
    });
  };

  const handleIsHiddenChange = () => {
    onJobCostAccountChanges({
      jobCostType,
      ...settings,
      isHidden: !settings.isHidden,
    });
  };

  return (
    <Grid
      container
      item
      className={classes.settings}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item>
        <Typography variant="h6" style={{ marginBottom: 16 }}>
          <b>{label} Settings</b>
        </Typography>
      </Grid>
      <Grid
        container
        item
        direction="column"
        justifyContent="center"
        spacing={2}
        className={classes.settingsContent}
      >
        <Grid item>
          <Typography variant="body1">
            Choose a QuickBooks Online Cost of Goods Sold (COGS) parent account
            to use for this chart.
          </Typography>
        </Grid>
        <Grid item>
          <Select
            value={
              jobCostAccounts && jobCostAccounts.length
                ? settings.sourceAccount
                : ''
            }
            onChange={handleSelectedAccountChange}
            style={{ width: '100%' }}
            displayEmpty
          >
            <MenuItem key="default" value="" disabled>
              Select an account
            </MenuItem>
            {_.map(jobCostAccounts || [], ({ displayName, accountName }) => (
              <MenuItem key={accountName} value={accountName}>
                {displayName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <FormControlLabel
            checked={settings.isHidden}
            onChange={handleIsHiddenChange}
            control={<Switch color="primary" />}
            label={
              <Typography variant="h5">Hide chart from dashboard?</Typography>
            }
            labelPlacement="start"
            style={{
              width: '100%',
              marginLeft: 0,
              marginTop: 0,
              justifyContent: 'space-between',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JobCostSettings;
