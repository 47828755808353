import React, { useState } from 'react';

import { Button, Grid, Typography } from '@material-ui/core';
import { Security as SecurityIcon } from '@material-ui/icons';
import _ from 'lodash';
import { useMutation } from 'react-apollo-hooks';
import { connect } from 'react-redux';

import SignOutAllDevices from '../../graphql/mutations/mutation_sign-out-all-devices';
import { performSignOut } from '../../helpers';
import palette from '../../theme/palette';
import OkCancelDialog from '../OkCancelDialog/okCancelDialog';
import SettingsSection from '../settings-section';

const SecuritySettings = ({ userInfo }) => {
  const [showConfirmationDialog, setShowDisableMfaDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [signOutAllDevices] = useMutation(SignOutAllDevices);

  const handleSignOutAllDevices = async () => {
    try {
      const response = await signOutAllDevices();
      const responseStatus = _.get(response, 'data.signOutAllDevices.status');

      if (responseStatus !== 'success') {
        setErrorMessage(
          'An unexpected error occurred. Please try again later!'
        );
        return;
      }

      // don't await this call, just fire and forget
      // so the confirmation dialog can close properly
      performSignOut({ userInfo });
    } catch (err) {
      setErrorMessage('An unexpected error occurred. Please try again later!');
    }
  };

  return (
    <SettingsSection
      icon={<SecurityIcon />}
      title="Security"
      description="Manage more security options"
      hashKey="security"
    >
      <Grid container direction="column" spacing={4}>
        <Grid item style={{ width: '100%' }}>
          <Typography variant="body2">
            Clicking the button below will log you out of this account on all
            devices, including this one. You will need to log back in to
            continue using the application.
          </Typography>
        </Grid>
        <Grid container item style={{ width: '100%', marginBottom: 16 }}>
          <Button
            variant="contained"
            style={{
              color: palette.white,
              backgroundColor: palette.brandColorError,
            }}
            onClick={() => setShowDisableMfaDialog(true)}
          >
            Log out of this account on all devices
          </Button>
        </Grid>
      </Grid>
      {!!errorMessage && (
        <OkCancelDialog
          open
          okButtonText="Ok"
          hideCancel
          title="Oops!"
          dividers={false}
          loaderStyle={{ opacity: 1 }}
          onConfirm={() => {
            setErrorMessage(null);
          }}
        >
          <Typography>{errorMessage}</Typography>
        </OkCancelDialog>
      )}
      {!!showConfirmationDialog && (
        <OkCancelDialog
          open
          title="Log Out of All Devices"
          dividers={false}
          autoFocusButton="cancelButton"
          okButtonText="Yes, Log Out"
          okButtonStyle={{ backgroundColor: palette.brandColorError }}
          onClose={() => setShowDisableMfaDialog(false)}
          onConfirm={handleSignOutAllDevices}
        >
          <Typography variant="body1">
            Are you sure you want to want to log out of this account on all
            devices, including this one? You will need to log back in to
            continue using the application.
          </Typography>
        </OkCancelDialog>
      )}
    </SettingsSection>
  );
};

const mapStateToProps = state => {
  return {
    userInfo: state.userInfo,
  };
};

export default connect(mapStateToProps)(SecuritySettings);
