import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import numeral from 'numeral';
import _ from 'lodash';

import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Typography,
  makeStyles,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
  Equalizer as EqualizerIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@material-ui/icons';

import { GetCompanyCrewAdminAction } from '../../../graphql/graphql';

import GetBudgetInfo from '../../../graphql/queries/get-budget-info';

import AccessLimitedToInfo from '../../../components/access-limited-to-info/access-limited-to-info';
import LoadingCover from '../../../components/LoadingCover/loadingCover';
import LaborBudgetItem from './labor-budget-item';
import LaborBudgetTextField from './labor-budget-text-field';
import LaborBudgetUserPayInfoDialog from './labor-budget-user-pay-info-dialog';
import {
  CREW_PAY_TYPES,
  BUDGET_ENUMS_HOURS,
  BUDGET_TYPE_USER_LABOR_HOURS,
  BUDGET_TYPE_UNASSIGNED_LABOR_HOURS,
} from '../../../config/appDefaults';
import { calculateHourlyRate } from '../../../helpers';

const useStyles = makeStyles({
  totalContainer: {
    backgroundColor: '#eee',
    height: 66,
  },
  totalInfo: {
    margin: '8px 16px 4px 16px',
  },
  actionContainer: {
    padding: '8px 24px 8px 24px',
  },
  noCrewMembers: {
    marginTop: 16,
    fontStyle: 'italic',
  },
  headerRow: {
    backgroundColor: '#f0f0f0',
    marginBottom: 8,
    '&.MuiGrid-item': {
      padding: '12px 12px 8px 12px',
    },
  },
  headerCell: {
    paddingLeft: 14,
  },
  heading: {
    color: '#999',
    fontSize: 12,
    lineHeight: '14px',
  },
});

const LaborBudgetDialog = props => {
  const {
    open,
    handleClose,
    handleDone,
    companyCrew,
    getCompanyCrewAdminLoading,
    userIdsOnProject,
    activeUserIds,
    hoursWorkedByUserId = {},
    projectId,
    budgetInfo,
    managingCompanyInfo,
  } = props;

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const classes = useStyles();

  const [budgetsByUserIdMap, setBudgetsByUserIdMap] = useState({});
  const [totalLabor, setTotalLabor] = useState({ hours: 0, cost: 0 });
  const [otherBudgets, setOtherBudgets] = useState([]); // other budgets besides UserLaborHours budget
  const [showUserPayInfoDialog, setShowUserPayInfoDialog] = useState(false);
  const [unassignedLabor, setUnassignedLabor] = useState({
    unassignedHours: 0,
    averageHourlyRate: 0,
  });

  const showHoursWorked = !_.isEmpty(hoursWorkedByUserId) && !mdDown;

  const getBudgetInfoQuery = useQuery(GetBudgetInfo, {
    skip: !projectId || !!budgetInfo,
    variables: { jrnId: projectId },
    fetchPolicy: 'cache-and-network',
  });

  const getBudgetInfoLoading = getBudgetInfoQuery.loading;

  const currentBudgetInfo =
    budgetInfo || getBudgetInfoQuery.data?.getBudgetInfo;

  const { companyCrewMap, allUserIdsOnProject, activeUserMap } = useMemo(() => {
    const crewMap = {};
    let sortedUserIds = [];

    const activeUserIdsMap = {};
    _.forEach(activeUserIds, userId => {
      activeUserIdsMap[userId] = true;
    });

    if (companyCrew && userIdsOnProject) {
      companyCrew.forEach(crew => {
        crewMap[crew.userId] = crew;
      });

      // Remove members who are not on the company crew
      const userIdsOnProjectAndCompanyCrew = _.filter(
        userIdsOnProject,
        userId => !!crewMap[userId]
      );

      sortedUserIds = _.orderBy(
        userIdsOnProjectAndCompanyCrew,
        [
          userId => (activeUserIdsMap[userId] ? 1 : 0),
          userId => crewMap[userId].payType,
          userId => crewMap[userId].username.toLowerCase(),
        ],
        ['desc', 'desc', 'asc']
      );
    }
    return {
      companyCrewMap: crewMap,
      allUserIdsOnProject: sortedUserIds,
      activeUserMap: activeUserIdsMap,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCrew]);

  useEffect(() => {
    if (currentBudgetInfo) {
      const { budgets } = currentBudgetInfo;
      const unassginedBudget = _.find(
        budgets,
        budget => budget.label === BUDGET_TYPE_UNASSIGNED_LABOR_HOURS
      );
      if (unassginedBudget) {
        const unassignedHours = numeral(unassginedBudget.value).format('0.0');
        let averageHourlyRate = 0;
        if (unassginedBudget.context) {
          averageHourlyRate = numeral(Number(unassginedBudget.context)).format(
            '0.00'
          );
        }
        setUnassignedLabor({ unassignedHours, averageHourlyRate });
      }
    }
  }, [currentBudgetInfo]);

  useEffect(() => {
    const budgetsByUserId = {};
    if (!_.isEmpty(allUserIdsOnProject) && !_.isEmpty(companyCrewMap)) {
      const { userPayInfo, budgets } = currentBudgetInfo || {};

      _.forEach(allUserIdsOnProject, userId => {
        // Use the userPayInfo set on the project if it exists
        let userPayInfoFound = false;
        if (userPayInfo) {
          const existingUserPayInfo = _.find(userPayInfo, { userId });
          if (existingUserPayInfo) {
            userPayInfoFound = true;

            budgetsByUserId[userId] = {
              ...existingUserPayInfo,
              hours: '',
            };
          }
        }
        if (!userPayInfoFound) {
          // Default to company crew info if no userpayinfo found - this should only be in the case of a new project or user added to project once LBE-508 is deployed
          const {
            department,
            payType,
            payRate,
            laborBurdenPercentage,
            vacaAccrualRate,
          } = companyCrewMap[userId];

          budgetsByUserId[userId] = {
            userId,
            department,
            payType,
            payRate,
            laborBurdenPercentage,
            vacaAccrualRate,
            hours: '',
          };
        }
      });

      if (budgets) {
        const others = [];
        _.forEach(budgets, budget => {
          if (budget.label === BUDGET_TYPE_USER_LABOR_HOURS) {
            budgetsByUserId[budget.context] = {
              ...budgetsByUserId[budget.context],
              hours: numeral(budget.value).format('0.0'),
            };
          } else if (budget.label === BUDGET_TYPE_UNASSIGNED_LABOR_HOURS) {
            // Do nothing
          } else {
            others.push(budget);
          }
        });

        // save other budget types
        setOtherBudgets(others);
      }

      // Calculate labor cost for each user
      _.forEach(budgetsByUserId, (userBudget, userId) => {
        if (userBudget.payType === CREW_PAY_TYPES.VARIABLE_HOURLY) {
          // calculate hourly rate
          const hourlyRate = calculateHourlyRate(userBudget);

          // calculate budget cost
          const cost = Number(userBudget.hours) * hourlyRate;

          // update user labor budget
          budgetsByUserId[userId].hourlyRate = hourlyRate;
          budgetsByUserId[userId].cost = cost;
        }
      });

      setBudgetsByUserIdMap(budgetsByUserId);
    }
  }, [allUserIdsOnProject, companyCrewMap, currentBudgetInfo]);

  // calculate total labor hours and total labor cost
  useEffect(() => {
    let totalHours = Number(unassignedLabor.unassignedHours);
    let totalBudget =
      Number(unassignedLabor.unassignedHours) *
      Number(unassignedLabor.averageHourlyRate);

    if (!_.isEmpty(budgetsByUserIdMap)) {
      _.forEach(allUserIdsOnProject, userId => {
        const { hours, cost, payType } = budgetsByUserIdMap[userId];
        totalHours += Number(hours);
        if (payType === CREW_PAY_TYPES.VARIABLE_HOURLY) {
          totalBudget += Number(cost);
        }
      });
    }

    setTotalLabor({
      hours: numeral(totalHours).format('0.0'),
      cost: numeral(totalBudget).format('0.00'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetsByUserIdMap, unassignedLabor]);

  const onDoneClick = () => {
    const budgets = [...otherBudgets];

    budgets.push({
      budgetType: BUDGET_ENUMS_HOURS,
      label: BUDGET_TYPE_UNASSIGNED_LABOR_HOURS,
      value: Number(unassignedLabor.unassignedHours),
      context: numeral(unassignedLabor.averageHourlyRate).format('0.00'),
    });

    const userPayInfo = [];
    _.forEach(allUserIdsOnProject, userId => {
      const { hourlyRate, cost, hours, ...rest } = budgetsByUserIdMap[userId];
      budgets.push({
        budgetType: BUDGET_ENUMS_HOURS,
        label: BUDGET_TYPE_USER_LABOR_HOURS,
        value: Number(hours),
        context: userId,
      });
      userPayInfo.push({
        userId,
        ...rest,
      });
    });

    handleDone({ budgets, userPayInfo });
  };

  const handleUnassignedLaborChange = event => {
    let { value } = event.target;
    const { name: fieldName } = event.target;
    value = value.replace(/[^0-9.]+/g, '');
    setUnassignedLabor(currentState => {
      return { ...currentState, [fieldName]: value };
    });
  };

  const handleUnassignedLaborBlur = event => {
    const { name: fieldName, value } = event.target;
    setUnassignedLabor(currentState => {
      return {
        ...currentState,
        [fieldName]: numeral(value).format(
          fieldName === 'averageHourlyRate' ? '0.00' : '0.0'
        ),
      };
    });
  };

  const handleHoursChange = ({ userId, hours }) => {
    setBudgetsByUserIdMap(prevState => {
      const newState = _.cloneDeep(prevState);
      newState[userId].hours = hours;
      newState[userId].cost = hours * newState[userId].hourlyRate;
      return newState;
    });
  };

  const handleHoursBlur = ({ userId, hours }) => {
    setBudgetsByUserIdMap(prevState => {
      const newState = _.cloneDeep(prevState);
      newState[userId].hours = numeral(hours).format('0.0');
      newState[userId].cost = numeral(
        hours * newState[userId].hourlyRate
      ).format('0.00');
      return newState;
    });
  };

  const handleBudgetUserPayInfoDialogClose = () => {
    setShowUserPayInfoDialog(false);
  };

  const handleBudgetUserPayInfoDialogDone = updatedBudgetsByUserIdMap => {
    setBudgetsByUserIdMap(updatedBudgetsByUserIdMap);
    setShowUserPayInfoDialog(false);
  };

  const renderUnassignedLaborBudget = () => {
    return (
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        spacing={mdDown ? 1 : 0}
      >
        <Grid item lg={showHoursWorked ? 2 : 3} xs={2}>
          <LaborBudgetTextField label="" value="UNASSIGNED" disabled />
        </Grid>
        <Grid item xs={2} /> {/* Department */}
        <Grid item xs={2} /> {/* Pay Type */}
        <Grid item xs={2}>
          <LaborBudgetTextField
            label="Hourly Rate + Labor Burden"
            name="averageHourlyRate"
            type="number"
            value={unassignedLabor.averageHourlyRate}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Estimated hourly rate + labor burden">
                    <InfoOutlinedIcon style={{ fontSize: 16 }} />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            onChange={handleUnassignedLaborChange}
            onBlur={handleUnassignedLaborBlur}
          />
        </Grid>
        {showHoursWorked && <Grid item xs={1} />}
        <Grid item lg={1} xs={2}>
          <LaborBudgetTextField
            label="Budget Hours"
            name="unassignedHours"
            type="number"
            value={unassignedLabor.unassignedHours}
            adornmentChar="#"
            onChange={handleUnassignedLaborChange}
            onBlur={handleUnassignedLaborBlur}
          />
        </Grid>
        <Grid item lg={1} xs={2}>
          {!!unassignedLabor.unassignedHours &&
            !!unassignedLabor.averageHourlyRate && (
              <LaborBudgetTextField
                label="Budget"
                value={numeral(
                  Number(unassignedLabor.unassignedHours) *
                    Number(unassignedLabor.averageHourlyRate)
                ).format('0.00')}
                adornmentChar="$"
                disabled
              />
            )}
        </Grid>
      </Grid>
    );
  };

  const renderListOfUserBudgets = () => {
    if (_.isEmpty(allUserIdsOnProject) || _.isEmpty(budgetsByUserIdMap)) {
      return null;
    }

    return allUserIdsOnProject.map(userId => {
      return (
        <LaborBudgetItem
          key={userId}
          userBudget={budgetsByUserIdMap[userId]}
          hoursWorked={hoursWorkedByUserId[userId]}
          companyCrewMap={companyCrewMap}
          isDeactiveUser={!activeUserMap[userId]}
          onHoursChange={handleHoursChange}
          onHoursBlur={handleHoursBlur}
        />
      );
    });
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="xl"
        fullWidth
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose(event, reason);
          }
        }}
        disableEscapeKeyDown
      >
        <DialogTitle disableTypography>
          <Grid container alignItems="center">
            <EqualizerIcon />
            &nbsp;
            <Typography variant="h6">Labor Budget</Typography>
          </Grid>
          <AccessLimitedToInfo companyOwner companyBookkeeper companyAdmin />
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            spacing={3}
            style={{ minHeight: 300, marginTop: 0, marginBottom: 2 }}
            alignContent="flex-start"
          >
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              className={classes.headerRow}
            >
              <Grid
                item
                lg={showHoursWorked ? 2 : 3}
                xs={2}
                className={classes.headerCell}
              >
                <Typography className={classes.heading}>Username</Typography>
              </Grid>
              <Grid item xs={2} className={classes.headerCell}>
                <Typography className={classes.heading}>Department</Typography>
              </Grid>
              <Grid item xs={2} className={classes.headerCell}>
                <Typography className={classes.heading}>Pay Type</Typography>
              </Grid>
              <Grid item xs={2} className={classes.headerCell}>
                <Typography className={classes.heading}>
                  Hourly Rate + Labor Burden
                </Typography>
              </Grid>
              {showHoursWorked && (
                <Grid item xs={1} className={classes.headerCell}>
                  <Typography className={classes.heading}>
                    Hours Worked
                  </Typography>
                </Grid>
              )}
              <Grid item lg={1} xs={2} className={classes.headerCell}>
                <Typography className={classes.heading}>
                  Budget Hours
                </Typography>
              </Grid>
              <Grid item lg={1} xs={2} className={classes.headerCell}>
                <Typography className={classes.heading}>Budget</Typography>
              </Grid>
            </Grid>
            {renderUnassignedLaborBudget()}
            {renderListOfUserBudgets()}
          </Grid>
        </DialogContent>

        <DialogActions className={classes.totalContainer}>
          <Grid
            container
            justifyContent="space-between"
            className={classes.totalInfo}
            spacing={2}
          >
            <Grid item lg={8} xs={6} />
            <Grid item lg={2} xs={3}>
              <LaborBudgetTextField
                label="Total Budget Hours"
                value={totalLabor.hours}
                adornmentChar="#"
                disabled
              />
            </Grid>
            <Grid item lg={2} xs={3}>
              <LaborBudgetTextField
                label="Total Budget"
                value={totalLabor.cost}
                adornmentChar="$"
                disabled
              />
            </Grid>
          </Grid>
        </DialogActions>
        <DialogActions className={classes.actionContainer}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            flex={1}
          >
            <Grid container item xs={4}>
              {allUserIdsOnProject &&
                allUserIdsOnProject.length > 0 &&
                (managingCompanyInfo.isCompanyOwner ||
                  managingCompanyInfo.isCompanyBookkeeper ||
                  managingCompanyInfo.isCompanyAdmin) && (
                  <Button
                    onClick={() => {
                      setShowUserPayInfoDialog(true);
                    }}
                    variant="outlined"
                    color="primary"
                    autoFocus
                  >
                    Manage Pay Rate
                  </Button>
                )}
            </Grid>
            <Grid container item xs={8} justifyContent="flex-end">
              <Button onClick={handleClose} color="primary" autoFocus>
                Cancel
              </Button>
              <Button
                onClick={onDoneClick}
                variant="contained"
                color="primary"
                autoFocus
              >
                Done
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        {(getCompanyCrewAdminLoading || getBudgetInfoLoading) && (
          <LoadingCover />
        )}
      </Dialog>
      {showUserPayInfoDialog && (
        <LaborBudgetUserPayInfoDialog
          open={showUserPayInfoDialog}
          companyCrewMap={companyCrewMap}
          budgetsByUserIdMap={budgetsByUserIdMap}
          allUserIdsOnProject={allUserIdsOnProject}
          activeUserMap={activeUserMap}
          handleClose={handleBudgetUserPayInfoDialogClose}
          handleDone={handleBudgetUserPayInfoDialogDone}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || {},
});

export default compose(
  GetCompanyCrewAdminAction,
  connect(mapStateToProps)
)(LaborBudgetDialog);
