import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import _ from 'lodash';
import numeral from 'numeral';
import {
  ACCOUNTING_METHOD,
  REPORT_PERIOD,
  SCOREBOARD_NAME,
} from '../../../config/appDefaults';
import { Dashboard } from '../../dashboard';
import Graph, { processXYTypes } from '../../graph';
import GetProfitLossData from '../../../graphql/queries/get-profit-loss-data';
import {
  GetCompanyTargetsAction,
  ListScoreboardSettingsAction,
} from '../../../graphql/graphql';
import { profitAndLossGraphs } from './profitability-graphs';
import ProfitabilityControlBar from './profitability-control-bar';

const title = 'Profitability';

const ProfitabilityScoreboard = ({
  companyTargets,
  scoreboardSettings,
  managingCompanyInfo,
}) => {
  const [accountingMethod, setAccountingMethod] = useState(null);

  useEffect(() => {
    if (scoreboardSettings) {
      // Set defaults
      let updatedAccountingMethod = ACCOUNTING_METHOD.CASH.key;

      // Find settings for scoreboard
      const profitabilityScoreboardSettings = _.find(
        scoreboardSettings,
        ({ scoreboardName }) => scoreboardName === SCOREBOARD_NAME.PROFITABILITY
      );

      // Override accounting method default with user settings
      if (
        profitabilityScoreboardSettings &&
        profitabilityScoreboardSettings.accountingMethod
      ) {
        const companyAccountingMethodSetting = _.find(
          ACCOUNTING_METHOD,
          ({ value }) =>
            value === profitabilityScoreboardSettings.accountingMethod
        );
        if (companyAccountingMethodSetting) {
          updatedAccountingMethod = companyAccountingMethodSetting.key;
        }
      }

      setAccountingMethod(updatedAccountingMethod);
    }
  }, [scoreboardSettings]);

  const [reportPeriod, setReportPeriod] = useState(
    REPORT_PERIOD.LAST_12_MONTHS.key
  );

  const targetData = _.map(
    profitAndLossGraphs,
    ({ name: reportName, label, targetType }) => ({
      name: reportName,
      label,
      dataType: targetType,
    })
  );

  const targetsByKey = {};
  if (companyTargets) {
    _.each(profitAndLossGraphs, ({ name: reportName }) => {
      targetsByKey[reportName] = _.find(
        companyTargets,
        ({ name }) => name === reportName
      );
    });
  }

  const ProfitAndLossDataQuery = useQuery(GetProfitLossData, {
    skip:
      !(managingCompanyInfo && managingCompanyInfo.managingCompanyId) ||
      accountingMethod === null,
    variables: {
      companyId: managingCompanyInfo && managingCompanyInfo.managingCompanyId,
      reportPeriod: _.find(REPORT_PERIOD, ({ key }) => key === reportPeriod)
        .value,
      accountingMethod:
        accountingMethod !== null
          ? _.find(ACCOUNTING_METHOD, ({ key }) => key === accountingMethod)
              .value
          : null,
    },
  });

  const profitAndLossData =
    ProfitAndLossDataQuery.data &&
    ProfitAndLossDataQuery.data.getProfitAndLossData &&
    ProfitAndLossDataQuery.data.getProfitAndLossData.items;

  const reportData = useMemo(() => {
    const compiledReportData = {};

    if (profitAndLossData) {
      _.forEach(profitAndLossGraphs, ({ name: sourceName }) => {
        const data = _.cloneDeep(
          _.find(profitAndLossData, ({ name }) => name === sourceName)
        );

        if (data) {
          data.values = processXYTypes(
            data.values,
            data.labels.x,
            data.labels.y
          );

          if (data.labels.y === 'percentage' && data.metadata) {
            const percentageForPeriodKvPair = _.find(
              data.metadata,
              metadatum => metadatum.key === 'percentageForPeriod'
            );
            if (percentageForPeriodKvPair && percentageForPeriodKvPair.value) {
              data.percentageForPeriod = parseFloat(
                percentageForPeriodKvPair.value
              );
            }
          }
        }

        compiledReportData[sourceName] = data;
      });
    }

    return compiledReportData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profitAndLossData]);

  const controlBar = (
    <ProfitabilityControlBar
      title={title}
      targetData={targetData}
      reportPeriod={reportPeriod}
      accountingMethod={accountingMethod}
      setReportPeriod={setReportPeriod}
    />
  );

  return (
    <Dashboard controlBar={controlBar}>
      {profitAndLossGraphs.map(({ name, label, description, graphType }) => {
        const thisReportData = reportData[name];

        const showTotal =
          thisReportData &&
          ((thisReportData.labels && thisReportData.labels.y === 'money') ||
            thisReportData.percentageForPeriod);

        let totalOverride = null;
        if (
          thisReportData &&
          thisReportData.percentageForPeriod !== null &&
          thisReportData.percentageForPeriod !== undefined
        ) {
          totalOverride = numeral(thisReportData.percentageForPeriod).format(
            '0.00%'
          );
        }

        return (
          <Graph
            key={name}
            description={description}
            title={label}
            dataSeries={[
              {
                type: graphType,
                data: thisReportData && thisReportData.values,
                dataType: thisReportData && thisReportData.labels,
                name: label,
              },
            ]}
            target={targetsByKey[name] && targetsByKey[name].value}
            loading={!(thisReportData && thisReportData.values)}
            showTotal={showTotal}
            totalOverride={totalOverride}
          />
        );
      })}
    </Dashboard>
  );
};

ProfitabilityScoreboard.title = title;

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(
  compose(
    GetCompanyTargetsAction,
    ListScoreboardSettingsAction
  )(ProfitabilityScoreboard)
);
