import React, { useMemo } from 'react';
import { TextField } from '@material-ui/core';
import { compose } from 'redux';

import GraphQL from '../../../graphql';

const ReadonlyVendorField = ({ vendor }) => {
  const vendorName = useMemo(() => {
    if (vendor) {
      return vendor.name;
    }
    return '';
  }, [vendor]);

  return (
    <TextField
      id="vendor-display-name"
      label="Vendor"
      value={vendorName}
      InputProps={{
        readOnly: true,
      }}
      fullWidth
    />
  );
};

export default compose(GraphQL.GetVendorAction)(ReadonlyVendorField);
