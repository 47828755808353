export default theme => {
  return {
    projectList: {
      flex: 1,
      padding: theme.spacing(1),
    },
    totalAmount: {
      textAlign: 'center',
      padding: theme.spacing(1),
      margin: '8px 0px 0px 16px',
      backgroundColor: '#eee',
    },
  };
};
