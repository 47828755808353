import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Relation}
  mutation UpdateRelation(
    $relationId: ID!
    $name: String!
    $isArchived: Boolean
  ) {
    updateRelation(
      input: { relationId: $relationId, name: $name, isArchived: $isArchived }
    ) {
      ...RelationFields
    }
  }
`;
