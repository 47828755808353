export const WHO = {
  MINE: 'mine',
  COMPANY: 'company',
  MANAGED_BY_ME: 'managedByMe',
};

export const DATA_TYPE = {
  PROJECTS: 'projects',
  SHIFTS: 'shifts',
  TASKS: 'tasks',
};

export const CALENDAR_MODE = {
  MY_PROJECTS: 'projects',
  MY_TASKS: 'tasks',
  MY_SHIFTS: 'shifts',
  ADMIN_TASKS: 'adminTasks',
  ADMIN_SHIFTS: 'adminShifts',
  COMPANY_PROJECTS: 'companyProjects',
  COMPANY_TASKS: 'companyTasks',
  COMPANY_SHIFTS: 'companyShifts',
};
