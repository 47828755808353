import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Refresh as ResetIcon } from '@material-ui/icons';
import _ from 'lodash';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

import palette from '../../theme/palette';
import { BOOKKEEPING_ALERT_SETTINGS_LAYOUT } from '../bookkeeping-alerts/bookkeeping-alerts-settings/bookkeeping-alerts-settings-layout';
import BookkeepingAlertsSettingsListItem from '../bookkeeping-alerts/bookkeeping-alerts-settings/bookkeeping-alerts-settings-list-item';
import { BOOKKEEPING_ALERT_SETTINGS_INFO } from '../bookkeeping-alerts/bookkeeping-alerts-settings/bookkeeping-report-settings-info';
import { IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER } from '../bookkeeping-alerts/bookkeeping-alerts.constants';
import ButtonWithTooltip from '../button-with-tooltip/button-with-tooltip';
import InputContentLoader from '../input-content-loader/input-content-loader';

export const LAYOUT = {
  TOOLBAR: 'toolbar',
  BOX: 'box',
};

const styles = theme => {
  return {
    listSubheader: {
      width: '100%',
      paddingTop: 16,
      paddingRight: 16,
      paddingBottom: 16,
      paddingLeft: 16,
      borderBottom: '1px solid #e0e0e0',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      fontSize: '110%',
      background: '#fff',
    },
  };
};

const useStyles = makeStyles(styles);

const CustomScanSettingsRuleMultiSelect = ({
  label,
  excludedAlertIdentifiersMap,
  loading,
  initialSelectedRules,
  defaultBookkeepingRules,
  allRules,
  setAllRules,
  managingCompanyInfo,
  showChangeAdornment,
  disabled,
  error,
  clearError,
  ruleMultiSelectRef,
}) => {
  const classes = useStyles();
  const [layoutToUse, setLayoutToUse] = useState([]);
  const [showMenuDialog, setShowMenuDialog] = useState(false);
  const isAllRulesInitialized = useRef(false);
  const isInitialSelectedRuleApplied = useRef(false);
  const [defaultBookkeepingRulesMap, setDefaultBookkeepingRulesMap] = useState(
    {}
  );
  const [
    rulesToShowByAlertIdentifier,
    setRulesToShowByAlertIdentifier,
  ] = useState({});

  const createRuleKey = rule => {
    return `${rule.alertIdentifier}-${rule.alertIdentifierSub || 'null'}`;
  };

  const generateAllRules = ({ supportedAlertIdentifiers, defaultRulesMap }) => {
    const determineRuleToUse = ({
      alertIdentifier,
      alertIdentifierSub = null,
    }) => {
      const existingRule =
        defaultRulesMap[createRuleKey({ alertIdentifier, alertIdentifierSub })];
      if (existingRule) {
        return existingRule;
      }

      return {
        id: uuid(),
        alertIdentifier,
        alertIdentifierSub,
        companyId: managingCompanyInfo.managingCompanyId,
        enabled: false,
      };
    };

    const rulesToReturn = [];
    _.forEach(supportedAlertIdentifiers, alertIdentifier => {
      const settingsInfo = BOOKKEEPING_ALERT_SETTINGS_INFO[alertIdentifier];
      const { subs, generalSettings } = settingsInfo;
      const hasSubs = !_.isEmpty(subs);

      if (hasSubs) {
        _.forEach(subs, ({ value: alertIdentifierSub }) => {
          const rule = determineRuleToUse({
            alertIdentifier,
            alertIdentifierSub,
          });
          rulesToReturn.push(rule);
        });
      } else {
        const rule = determineRuleToUse({ alertIdentifier });
        rulesToReturn.push(rule);
      }

      _.forEach(generalSettings, ({ value: alertIdentifierSub }) => {
        const rule = determineRuleToUse({
          alertIdentifier,
          alertIdentifierSub,
        });
        rulesToReturn.push(rule);
      });
    });

    return rulesToReturn;
  };

  useEffect(() => {
    const defaultRulesMap = {};

    if (!isAllRulesInitialized.current && defaultBookkeepingRules) {
      _.forEach(defaultBookkeepingRules, rule => {
        const key = createRuleKey(rule);
        defaultRulesMap[key] = rule;
      });

      const supportedAlertIdentifiers = [];
      _.forEach(BOOKKEEPING_ALERT_SETTINGS_LAYOUT, ({ alerts }) => {
        // filter out alerts that do not have settings info
        const supportedAlerts = _.filter(alerts, alertIdentifier => {
          const hasSettingsInfo = !!BOOKKEEPING_ALERT_SETTINGS_INFO[
            alertIdentifier
          ];
          if (!hasSettingsInfo) {
            // eslint-disable-next-line no-console
            console.warn(`WARN: No settings info entry for ${alertIdentifier}`);
          }

          return hasSettingsInfo;
        });

        if (!_.isEmpty(supportedAlerts)) {
          supportedAlertIdentifiers.push(...supportedAlerts);
        }
      });

      // generate all types of rules
      const rulesToSet = generateAllRules({
        supportedAlertIdentifiers,
        defaultRulesMap,
      });

      setDefaultBookkeepingRulesMap(defaultRulesMap);
      setAllRules(rulesToSet);
      isAllRulesInitialized.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultBookkeepingRules]);

  useEffect(() => {
    if (clearError) {
      clearError();
    }

    const groupedRulesMap = {};
    _.forEach(allRules, rule => {
      if (excludedAlertIdentifiersMap[rule.alertIdentifier]) {
        return;
      }

      if (!groupedRulesMap[rule.alertIdentifier]) {
        groupedRulesMap[rule.alertIdentifier] = [];
      }

      groupedRulesMap[rule.alertIdentifier].push(rule);
    });

    const layout = [];
    _.forEach(BOOKKEEPING_ALERT_SETTINGS_LAYOUT, ({ alerts, ...others }) => {
      // filter out alert alertIdentifiers that are excluded
      const supportedAlerts = _.filter(alerts, alertIdentifier => {
        return !excludedAlertIdentifiersMap[alertIdentifier];
      });

      if (!_.isEmpty(supportedAlerts)) {
        layout.push({
          alerts: supportedAlerts,
          ...others,
        });
      }
    });

    setRulesToShowByAlertIdentifier(groupedRulesMap);
    setLayoutToUse(layout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRules, excludedAlertIdentifiersMap]);

  const setSelectedRules = rulesMapToSet => {
    setAllRules(currentState => {
      return _.map(currentState, rule => {
        const key = createRuleKey(rule);
        let enabled = false;

        if (rulesMapToSet[key]) {
          enabled = rulesMapToSet[key].enabled;
        }

        return {
          ...rule,
          enabled,
        };
      });
    });
  };

  useEffect(() => {
    // apply initial selected rules if provided
    if (
      !isInitialSelectedRuleApplied.current &&
      !_.isEmpty(allRules) &&
      initialSelectedRules
    ) {
      const initialSelectedRulesMap = {};
      _.forEach(initialSelectedRules, rule => {
        initialSelectedRulesMap[createRuleKey(rule)] = rule;
      });

      setSelectedRules(initialSelectedRulesMap);

      isInitialSelectedRuleApplied.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRules, initialSelectedRules]);

  const areOnlyDefaultRulesSelected = useMemo(() => {
    if (
      !isAllRulesInitialized.current ||
      _.isEmpty(allRules) ||
      (initialSelectedRules && !isInitialSelectedRuleApplied.current)
    ) {
      return false;
    }

    for (let i = 0; i < allRules.length; i += 1) {
      const rule = allRules[i];
      const key = createRuleKey(rule);
      const defaultRule = defaultBookkeepingRulesMap[key];

      if (!excludedAlertIdentifiersMap[rule.alertIdentifier]) {
        if (!defaultRule && rule.enabled) {
          return false;
        }

        if (defaultRule && defaultRule.enabled !== rule.enabled) {
          return false;
        }
      }
    }

    return true;
  }, [
    allRules,
    defaultBookkeepingRulesMap,
    excludedAlertIdentifiersMap,
    initialSelectedRules,
  ]);

  useEffect(() => {
    if (areOnlyDefaultRulesSelected) {
      // make sure all the company rules are selected
      setSelectedRules(defaultBookkeepingRulesMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areOnlyDefaultRulesSelected]);

  const selectedRulesCount = useMemo(() => {
    let totalRules = 0;
    _.forEach(allRules, rule => {
      if (
        !excludedAlertIdentifiersMap[rule.alertIdentifier] &&
        rule.enabled &&
        rule.alertIdentifierSub !== IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER
      ) {
        totalRules += 1;
      }
    });

    return totalRules;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRules, excludedAlertIdentifiersMap]);

  const handleClearAllClick = () => {
    setAllRules(currentState => {
      return currentState.map(rule => {
        if (
          rule.alertIdentifierSub !== IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER
        ) {
          return {
            ...rule,
            enabled: false,
          };
        }
        return rule;
      });
    });
  };

  const handleRuleChange = updatedRule => {
    setAllRules(currentState => {
      const updatedRuleKey = createRuleKey(updatedRule);

      return _.map(currentState, rule => {
        const ruleKey = createRuleKey(rule);
        if (ruleKey === updatedRuleKey) {
          return updatedRule;
        }

        return rule;
      });
    });
  };

  const resetToCompanyRules = () => {
    setSelectedRules(defaultBookkeepingRulesMap);
  };

  // expose resetToCompanyRules method to parent component
  useImperativeHandle(ruleMultiSelectRef, () => ({
    resetToCompanyRules,
  }));

  let textToDisplay = `${selectedRulesCount} ${
    selectedRulesCount === 1 ? 'Rule' : 'Rules'
  } Selected`;

  if (areOnlyDefaultRulesSelected && selectedRulesCount > 0) {
    textToDisplay = 'Company Rules Selected';
  }

  let color = palette.brandColorPrimary;
  if (error) {
    color = palette.brandColorError;
  } else if (disabled) {
    color = 'inherit';
  }

  return (
    <>
      <InputContentLoader label={label} loading={loading}>
        <TextField
          fullWidth
          label={label}
          value={textToDisplay}
          disabled={disabled}
          error={error}
          InputProps={{
            readOnly: true,
            endAdornment: showChangeAdornment ? (
              <InputAdornment position="end" style={{ marginRight: 16 }}>
                <span style={{ color }}>change</span>
              </InputAdornment>
            ) : null,
          }}
          onClick={() => {
            if (!disabled) {
              setShowMenuDialog(true);
            }
          }}
        />
      </InputContentLoader>
      {!_.isEmpty(layoutToUse) && showMenuDialog && (
        <Dialog
          open={showMenuDialog}
          maxWidth="md"
          fullWidth
          onClose={(_event, reason) => {
            if (reason !== 'backdropClick') {
              setShowMenuDialog(false);
            }
          }}
          disableEscapeKeyDown
        >
          <DialogTitle disableTypography>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Typography variant="h5">Rules to Scan</Typography>
            </Grid>
          </DialogTitle>

          <DialogContent style={{ minHeight: '70vh' }}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={handleClearAllClick}
                style={{ marginRight: 8 }}
              >
                Clear
              </Button>
              <Tooltip title="Reset To Company Rules">
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  endIcon={<ResetIcon />}
                  onClick={resetToCompanyRules}
                >
                  Reset
                </Button>
              </Tooltip>
            </Grid>
            {layoutToUse.map(({ group: groupName, alerts: alertsInGroup }) => {
              return (
                <React.Fragment key={groupName}>
                  <Grid container direction="column">
                    <Grid item className={classes.listSubheader}>
                      {groupName}
                    </Grid>
                    {alertsInGroup.map(alertIdentifier => {
                      return (
                        <BookkeepingAlertsSettingsListItem
                          key={alertIdentifier}
                          alertIdentifier={alertIdentifier}
                          filteredRules={
                            rulesToShowByAlertIdentifier[alertIdentifier]
                          }
                          subs={
                            BOOKKEEPING_ALERT_SETTINGS_INFO[alertIdentifier]
                              .subs
                          }
                          generalSettings={
                            BOOKKEEPING_ALERT_SETTINGS_INFO[alertIdentifier]
                              .generalSettings
                          }
                          useCheckboxForRules
                          onChange={handleRuleChange}
                          toggleRowContainerStyle={{ marginRight: 52 }}
                        />
                      );
                    })}
                  </Grid>
                </React.Fragment>
              );
            })}
          </DialogContent>
          <DialogActions>
            <ButtonWithTooltip
              color="primary"
              variant="contained"
              disabled={selectedRulesCount === 0}
              tooltipText={
                selectedRulesCount === 0
                  ? 'Please select at least one rule'
                  : ''
              }
              onClick={() => {
                setShowMenuDialog(false);
              }}
            >
              Done
            </ButtonWithTooltip>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default compose(connect(mapStateToProps))(
  CustomScanSettingsRuleMultiSelect
);
