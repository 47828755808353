// @ts-check
/** @typedef {import('../config/appDefaults').PRODUCT} PRODUCT */
/** @typedef {import('./routes').Page} Page */

import _ from 'lodash';

import {
  PRODUCT_ALLOWED_ROUTES,
  FIRM_ALLOWED_ROUTES,
  BASE_ROUTE,
  BASE_ROUTE_CONFIG,
} from './routes.config';

/**
 * Get a list of the allowed routes for a given product configuration
 * @param {{ userInfo: Object, managingCompanyInfo: Object }} params
 * @returns {Page[]}
 */
export const getAllowedPages = ({ userInfo, managingCompanyInfo }) => {
  // Using the userInfo and managingCompanyInfo state information, remove unpermission roles
  const {
    isCompanyAdmin,
    isCompanyOwner,
    isBookkeepingCustomer,
    isAdvisoryCustomer,
    isCompanyBookkeeper,
    products,
  } = managingCompanyInfo || {};

  const isFirmUser = !!userInfo?.managingFirmId;

  const hasAccessToFinancials =
    (isCompanyOwner || isCompanyBookkeeper) &&
    (isBookkeepingCustomer || isAdvisoryCustomer);

  let allowedRoutes = [];

  // Build allowed routes based on product
  _.forEach(products, product => {
    const productRoutes = PRODUCT_ALLOWED_ROUTES[product] || [];
    allowedRoutes.push(...productRoutes);
  });

  if (isFirmUser && !managingCompanyInfo) {
    allowedRoutes.push(...FIRM_ALLOWED_ROUTES);
  }

  // Remove duplicate routes
  allowedRoutes = _.uniq(allowedRoutes);

  // Permissions routes
  allowedRoutes = _.filter(allowedRoutes, route => {
    const routeConfig = BASE_ROUTE_CONFIG[route];

    // Check if company owner or bookkeeper restricted
    if (
      routeConfig.ownerOrBookkeeperOnly &&
      !isCompanyOwner &&
      !isCompanyBookkeeper
    ) {
      return false;
    }

    // Check if company admin restricted
    if (routeConfig.adminOnly && !isCompanyAdmin) {
      return false;
    }

    return true;
  });

  // Sort the routes based on their configured order
  const routeOrderWeight = {};
  _.values(BASE_ROUTE).forEach((href, i) => {
    routeOrderWeight[href] = i;
  });
  allowedRoutes.sort((a, b) => routeOrderWeight[a] - routeOrderWeight[b]);

  // Finally map to page object
  return _.map(allowedRoutes, route => {
    const pageInfo = /** @type {Page} */ ({ ...BASE_ROUTE_CONFIG[route] });

    if (route === BASE_ROUTE.SCOREBOARD) {
      // Special case for scoreboard
      pageInfo.locked = !hasAccessToFinancials;
    }

    if (route === BASE_ROUTE.SETTINGS) {
      // Special case for settings
      if (isFirmUser && !!managingCompanyInfo) {
        pageInfo.title = 'Client Settings';
      }
    }

    return pageInfo;
  });
};
