import React, { useEffect, useState } from 'react';

import { Grid, IconButton, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import _ from 'lodash';
import { useQuery } from 'react-apollo-hooks';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import camSad from '../../assets/images/cam/copilot_mascot_frown_no-arm.jpg';
import GetCompanyById from '../../graphql/queries/get-company-by-id';
import { determineManagingCompanyInfo, modifyQueryParams } from '../../helpers';
import { useQueryStringParams } from '../../hooks';
import LevelModal from '../Modal/level-modal';
import LevelSupportEmailAddressLink from '../level-support-email-address-link/level-support-email-address-link';
import GoToClientDialog from './go-to-client-dialog';

const QboRedirectHandlerWrapper = ({
  children,
  showGoToClientDialog,
  userInfo,
  dispatch,
  managingCompanyInfo,
}) => {
  const history = useHistory();

  const [errorLinkingDialog, setErrorLinkingDialog] = useState({
    open: false,
    message: '',
    details: '',
  });
  const [goToClientDialog, setGoToClientDialog] = useState({
    open: false,
  });

  const queryStringParams = useQueryStringParams();
  const queryErrorParam = queryStringParams.get('error');
  const queryLinkedParam = queryStringParams.get('linked');
  const queryCompanyIdParam = queryStringParams.get('companyId');

  const getCompanyByIdQuery = useQuery(GetCompanyById, {
    skip: !queryCompanyIdParam,
    variables: {
      companyId: queryCompanyIdParam,
    },
    fetchPolicy: 'cache-and-network',
  });

  const connectedCompanyInfo = _.get(
    getCompanyByIdQuery,
    'data.getCompanyById',
    null
  );

  useEffect(() => {
    if (queryErrorParam === 'qb') {
      setErrorLinkingDialog({
        open: true,
        message: 'Something went wrong while linking your books to Level 😢',
        details: null,
      });
    } else if (queryErrorParam === 'realm-id-mismatch') {
      setErrorLinkingDialog({
        open: true,
        message:
          'Oops! The chosen QuickBooks Online account could not be connected.',
        details: `It looks like this ${
          userInfo?.managingFirmId ? 'client' : 'company'
        } has already been connected to a different QuickBooks Online account.`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryErrorParam]);

  useEffect(() => {
    if (
      queryLinkedParam === 'success' &&
      queryCompanyIdParam &&
      showGoToClientDialog
    ) {
      setGoToClientDialog({
        open: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLinkedParam, queryCompanyIdParam, showGoToClientDialog]);

  useEffect(() => {
    // keep managingCompanyInfo up-to-date
    if (
      queryLinkedParam === 'success' &&
      !showGoToClientDialog &&
      managingCompanyInfo &&
      connectedCompanyInfo &&
      managingCompanyInfo.companyId === connectedCompanyInfo.companyId
    ) {
      const latestManagingCompanyInfo = determineManagingCompanyInfo({
        companyInfo: connectedCompanyInfo,
        userInfo,
      });

      if (
        latestManagingCompanyInfo &&
        !_.isEqual(managingCompanyInfo, latestManagingCompanyInfo)
      ) {
        dispatch({
          type: 'SET_MANAGING_COMPANY_INFO',
          payload: { ...latestManagingCompanyInfo },
        });

        // clear query params
        modifyQueryParams({
          history,
          paramsToModify: [
            { name: 'linked', value: null },
            { name: 'companyId', value: null },
          ],
          pushOrReplace: 'replace',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryLinkedParam,
    connectedCompanyInfo,
    managingCompanyInfo,
    showGoToClientDialog,
    userInfo,
  ]);

  const closeErrorDialog = () => {
    setErrorLinkingDialog({
      open: false,
      message: '',
      details: '',
    });
  };

  const handleGoToClientDialogClose = () => {
    setGoToClientDialog(false);
    modifyQueryParams({
      history,
      paramsToModify: [
        { name: 'linked', value: null },
        { name: 'companyId', value: null },
      ],
      pushOrReplace: 'replace',
    });
  };

  const renderErrorLinkingDialog = () => {
    return (
      <LevelModal
        open
        fullWidth={false}
        maxWidth="sm"
        dialogStyle={{ zIndex: '9999' }}
      >
        <Grid container alignItems="center" style={{ position: 'relative' }}>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: 16 }}
          >
            <Grid item style={{ maxWidth: 500, flex: 1 }} flex={1}>
              <Typography
                variant="h3"
                style={{ textAlign: 'center', paddingLeft: 16 }}
              >
                {errorLinkingDialog?.message}
              </Typography>
            </Grid>
            <IconButton
              onClick={() => {
                closeErrorDialog();

                modifyQueryParams({
                  history,
                  clearAllParams: true,
                  pushOrReplace: 'replace',
                });
              }}
              style={{ flex: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{ marginBottom: 16 }}
            alignItems="center"
          >
            <Grid item xs={4} style={{ textAlign: 'center' }}>
              <img
                src={camSad}
                style={{ width: 'auto', height: 200 }}
                alt="robot missing an arm with a sad look on its face"
              />
            </Grid>
            <Grid container item xs={8} direction="column" spacing={3}>
              {errorLinkingDialog?.details && (
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{ fontSize: 18, paddingLeft: 16 }}
                  >
                    {errorLinkingDialog?.details}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Typography
                  variant="body1"
                  style={{ fontSize: 18, paddingLeft: 16 }}
                >
                  Please try again or{' '}
                  <LevelSupportEmailAddressLink underline>
                    contact support
                  </LevelSupportEmailAddressLink>{' '}
                  if you continue to have issues.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </LevelModal>
    );
  };

  return (
    <>
      {children}

      {!!errorLinkingDialog?.open && renderErrorLinkingDialog()}

      {!!goToClientDialog?.open && !!connectedCompanyInfo && (
        <GoToClientDialog
          companyInfo={connectedCompanyInfo}
          onClose={handleGoToClientDialogClose}
        />
      )}
    </>
  );
};
const mapStateToProps = state => ({
  userInfo: state.userInfo,
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(QboRedirectHandlerWrapper);
