import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Grid, IconButton, TextField } from '@material-ui/core';
import { Settings as SettingsIcon } from '@material-ui/icons';
import {
  DashboardControlBar,
  DashboardControlBarSelect,
} from '../../dashboard';
import { REPORT_PERIOD, ACCOUNTING_METHOD } from '../../../config/appDefaults';

const JobCostControlBar = ({
  title,
  reportPeriod,
  accountingMethod,
  managingCompanyInfo,
  setReportPeriod,
  setShowSettings,
}) => {
  const handleSettingsClick = () => {
    setShowSettings(true);
  };

  const accountingMethodDisplay =
    accountingMethod !== null
      ? _.find(ACCOUNTING_METHOD, ({ key }) => key === accountingMethod)
          .displayValue
      : ' ';

  return (
    <DashboardControlBar title={title}>
      <Grid item style={{ minWidth: 150 }}>
        <TextField
          label="Accounting Method"
          value={accountingMethodDisplay}
          disabled
          fullWidth
        />
      </Grid>
      <Grid item style={{ minWidth: 150 }}>
        <DashboardControlBarSelect
          label="Report Period"
          options={REPORT_PERIOD}
          selected={reportPeriod}
          onChange={setReportPeriod}
        />
      </Grid>
      {managingCompanyInfo &&
        (managingCompanyInfo.isCompanyBookkeeper ||
          managingCompanyInfo.isCompanyOwner) && (
          <Grid item>
            <IconButton
              color="primary"
              variant="outlined"
              onClick={handleSettingsClick}
            >
              <SettingsIcon />
            </IconButton>
          </Grid>
        )}
    </DashboardControlBar>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo,
});

export default connect(mapStateToProps)(JobCostControlBar);
