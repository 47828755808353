import gql from 'graphql-tag';

export default gql`
  fragment JrnBaseFields on Jrn {
    managingCompanyId
    customerId
    contentId
    jrnId
    title
    creatorId
    contentUrl
    contentStatus
    description
    endDate
    qboCustomerId
    privacyLevel
    inviteCode
    allowedToEdit
    allowedToAdd
    allowedToView
    usersEverOnJrn
    startDate
    synced
    labels
    latitude
    longitude
    type
    subtype
    address
    useTimetracking
    useTimetrackingQuestions
    timetrackingQuestions
    timetrackingQuestionsCheckout
    fromTemplate
    timetrackingType
    documentNumber
    poNumber
    vendorId
    amount {
      value
    }
    balance {
      value
    }
    paymentTerms
    workflowStage
    workflowStageReason
    rfiIds
  }
`;
