import React from 'react';
import { connect } from 'react-redux';
import { compose, withApollo } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import { Drawer, Grid, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import _ from 'lodash';

import LoadingCover from '../LoadingCover/loadingCover';
import { prepRfisForSaving } from '../../helpers';

import {
  GetCompanyRfiAction,
  GetCompanyCrewAction,
  AddCompanyRfisAction,
  UpdateCompanyRfisAction,
  ListCompanyProjectsAction,
} from '../../graphql/graphql';
// eslint-disable-next-line import/no-cycle
import AdminCreateEditRfiForm from '../../views/Admin/manage-requests-for-info/admin-create-edit-rfi-form';
// eslint-disable-next-line import/no-cycle
import RfiEditForm from '../../views/rfi-dashboard/rfi-edit-form';
import GetCompanyRfiSingle from '../../graphql/queries/get-company-rfi';
import { RFI_STATUSES_INTERNALLY_EDITABLE } from '../../config/appDefaults';

const VIEW_OR_EDIT_RFI_MODE = {
  BOOKKEEPER_INTERNAL: 'bookkeeper-internal',
  BOOKKEEPER_EXTERNAL: 'bookkeeper-external',
  ADMIN_EXTERNAL: 'admin-external',
  SORRY: 'sorry',
};

const ViewOrEditRfi = ({
  // passed props
  open,
  requestId, // this is used to query for an existing RFI
  newRfiInfo = {}, // this is used to create a new RFI from scratch
  onClose,
  fromWhichAdminTool,
  includeArchive,
  fromWhichJrnContent,
  skipGetCompanyRfisUpdate,

  // HOC props
  userInfo,
  managingCompanyInfo,
  companyCrew,
  onAddCompanyRfis,
  onUpdateCompanyRfis,
}) => {
  const isBookkeeper = _.get(managingCompanyInfo, 'isCompanyBookkeeper', false);

  const handleSaveRfis = async ({ listOfRfis: saveThese, addOrUpdate }) => {
    const allRfis = prepRfisForSaving({
      rfisToSave: saveThese,
      companyId: managingCompanyInfo.managingCompanyId,
    });

    if (addOrUpdate === 'add') {
      try {
        await onAddCompanyRfis({
          listOfRfis: allRfis,
          relatedContentProjectId: newRfiInfo.idOfParentProject,
          fromWhichAdminTool,
          includeArchive,
          fromWhichJrnContent,
          skipGetCompanyRfisUpdate,
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('contentItem onAddCompanyRfis err: ', err);
      }
    } else {
      try {
        await onUpdateCompanyRfis(allRfis, null, true);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('VOERFI -> onUpdateCompanyRfis err: ', err);
      }
    }
  };

  // query for the single RFI
  const GetRfiQuery = useQuery(GetCompanyRfiSingle, {
    fetchPolicy: 'network-only',
    variables: {
      companyId: managingCompanyInfo?.managingCompanyId,
      requestId,
    },
    skip: !requestId,
  });

  const rfiLoading = _.get(GetRfiQuery, 'loading');
  const rfiToUse =
    newRfiInfo?.baseInfo || _.get(GetRfiQuery, `data.getCompanyRfi`, null);

  let mode;
  let isInternal;
  if (!rfiLoading) {
    if (rfiToUse) {
      isInternal =
        _.find(RFI_STATUSES_INTERNALLY_EDITABLE, {
          value: rfiToUse.requestStatus,
        }) || rfiToUse.local;
      if (isInternal) {
        // 1. if the rfi is still internal
        //  show it in edit mode to bookkeepers but
        //  tell admins it's not ready for viewing yet
        mode = isBookkeeper
          ? VIEW_OR_EDIT_RFI_MODE.BOOKKEEPER_INTERNAL
          : VIEW_OR_EDIT_RFI_MODE.SORRY;
      } else {
        // 2. if the rfi is external, show it in edit more for both of them
        mode = isBookkeeper
          ? VIEW_OR_EDIT_RFI_MODE.BOOKKEEPER_EXTERNAL
          : VIEW_OR_EDIT_RFI_MODE.ADMIN_EXTERNAL;
      }
    } else if (requestId) {
      // If not loading, no rfi found and requestId was passed, show the sorry message
      mode = VIEW_OR_EDIT_RFI_MODE.SORRY;
    }
  }

  const isInExternalStatus =
    mode === VIEW_OR_EDIT_RFI_MODE.BOOKKEEPER_EXTERNAL ||
    mode === VIEW_OR_EDIT_RFI_MODE.ADMIN_EXTERNAL;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ style: { width: '90%', maxWidth: 1200 } }}
    >
      {!mode && <LoadingCover />}
      {mode === VIEW_OR_EDIT_RFI_MODE.SORRY && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: '100%' }}
        >
          <Grid item style={{ textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom>
              Sorry, this RFI is not ready for viewing yet. Once it is approved,
              you will be notified and it will be viewable.
            </Typography>
            <IconButton
              onClick={onClose}
              style={{ border: '1px solid #333', marginTop: 16 }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      )}
      {mode === VIEW_OR_EDIT_RFI_MODE.BOOKKEEPER_INTERNAL && (
        <AdminCreateEditRfiForm
          companyCrew={companyCrew}
          onSave={handleSaveRfis}
          onClose={onClose}
          onCancel={onClose}
          adminMode={false}
          managingCompanyId={managingCompanyInfo.managingCompanyId}
          userInfo={userInfo}
          rfiToEdit={rfiToUse}
          relatedItemInfo={
            newRfiInfo.relatedItemInfo || rfiToUse.relatedItemInfo
          }
          baseRfiInfo={newRfiInfo.baseInfo}
        />
      )}
      {isInExternalStatus && (
        <RfiEditForm
          requestId={rfiToUse.requestId}
          companyCrew={companyCrew}
          toClose={onClose}
        />
      )}
    </Drawer>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default compose(
  GetCompanyRfiAction,
  GetCompanyCrewAction,
  AddCompanyRfisAction,
  UpdateCompanyRfisAction,
  ListCompanyProjectsAction,
  withApollo
)(connect(mapStateToProps)(ViewOrEditRfi));
