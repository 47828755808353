import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import _ from 'lodash';
import { Tabs, Paper, Tab } from '@material-ui/core';
import dashboards from './scoreboard-dashboards';

const DashboardSelect = ({ location }) => {
  const selectedDashboard = location.pathname.split('/').pop();

  return (
    <Paper style={{ margin: 4, marginBottom: 0 }}>
      <Tabs
        value={selectedDashboard}
        indicatorColor="primary"
        textColor="primary"
      >
        {_.map(dashboards, ({ path, component }) => (
          <Tab
            component={NavLink}
            key={path}
            label={component.title}
            value={path}
            to={`/scoreboard/${path}`}
          />
        ))}
      </Tabs>
    </Paper>
  );
};

export default withRouter(DashboardSelect);
