import React, { useEffect, useState } from 'react';

import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Business as BusinessIcon,
  OpenInNew,
  PersonAdd as PersonAddIcon,
  Wallpaper as WallpaperIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import _ from 'lodash';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';

import {
  MANAGEABLE_SUBSCRIPTION_STATUSES,
  MAX_CHARACTERS_IN_USERNAME,
  FIRM_ROLE,
} from '../../config/appDefaults';
import {
  AddOrUpdateCompanyAction,
  GetCompanyCrewAction,
} from '../../graphql/graphql';
import GetCompanyById from '../../graphql/queries/get-company-by-id';
import {
  determineManagingCompanyInfo,
  getCompanyCrewBreakdown,
} from '../../helpers';
import { onUploadFile } from '../../helpers/cloudinary';
import LoadingCover from '../LoadingCover/loadingCover';
import LocationSearchInput from '../location-search-input/location-search-input';
import SettingsSection from '../settings-section';

const windowWidth = window.innerWidth;
const imageTranformWidth =
  windowWidth / 3 < 612 ? 612 : Math.round(windowWidth / 3);

const companyLogoHeight = 150;

const useStyles = makeStyles(() => ({
  formRow: {
    alignItems: 'center',
    width: '100%',
    minHeight: 38,
  },
  topAlign: {
    alignItems: 'flex-start',
  },
  inlineIconButton: {
    marginTop: -10,
    marginBottom: -8,
  },
}));

const ManageCompanyInfo = ({
  companyId,
  companyCrew,
  dispatch,
  firmInfo,
  isCopilot,
  managingCompanyInfo,
  userInfo,
  onAddOrUpdateCompany,
}) => {
  const isClient =
    !!userInfo?.managingFirmId && companyId !== userInfo?.managingFirmId;

  const isFirm =
    !!userInfo?.managingFirmId && companyId === userInfo?.managingFirmId;

  const { isCompanyOwner, isCompanyBookkeeper } = managingCompanyInfo || {};
  const isOwnerOrBookkeeper = isCompanyOwner || isCompanyBookkeeper;

  const { subscriptionStatus } = {
    ...(isFirm ? { ...firmInfo } : { ...managingCompanyInfo }),
  };

  const showManageSubscription =
    !isClient &&
    subscriptionStatus &&
    MANAGEABLE_SUBSCRIPTION_STATUSES.includes(subscriptionStatus);

  const hasManageSubscriptionPermission =
    (isFirm && userInfo?.managingFirmRole === FIRM_ROLE.ADMIN) ||
    (!isClient && !isFirm && isOwnerOrBookkeeper);

  const classes = useStyles();

  const getCompanyByIdQuery = useQuery(GetCompanyById, {
    skip: !companyId,
    variables: { companyId },
    fetchPolicy: 'cache-and-network',
  });

  const latestCompanyInfo = _.get(getCompanyByIdQuery, 'data.getCompanyById');
  const refetchCompanyById = getCompanyByIdQuery.refetch;

  useEffect(() => {
    if (latestCompanyInfo && !isClient && companyId) {
      if (
        firmInfo?.companyId === companyId &&
        !_.isEqual(latestCompanyInfo, firmInfo)
      ) {
        // Keep firmInfo state up-to-date
        dispatch({
          type: 'SET_FIRM_INFO',
          payload: { ...latestCompanyInfo },
        });
      } else if (managingCompanyInfo?.companyId === companyId) {
        // Keep managingCompanyInfo state up-to-date
        const latestManagingCompanyInfo = determineManagingCompanyInfo({
          companyInfo: latestCompanyInfo,
          userInfo,
        });

        if (!_.isEqual(latestManagingCompanyInfo, managingCompanyInfo)) {
          dispatch({
            type: 'SET_MANAGING_COMPANY_INFO',
            payload: { ...latestManagingCompanyInfo },
          });
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, firmInfo, isClient, latestCompanyInfo, managingCompanyInfo]);

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const onFormSubmit = async values => {
    if (!latestCompanyInfo) {
      // it should not reach here
      return;
    }

    const companyInfo = {
      companyId,
      companyName: values.companyName?.trim() || latestCompanyInfo.companyName,
      companyLogo: values.companyLogo
        ? values.companyLogo
        : latestCompanyInfo.companyLogo || null,
      address: values.address?.trim() || latestCompanyInfo.address,
      invitationId: null,
    };
    setLoading(true);

    if (values.companyLogo && !_.includes(values.companyLogo, 'cloudinary')) {
      const { uri: uploadedUri } = await onUploadFile(
        values.companyLogo,
        companyId
      );
      companyInfo.companyLogo = uploadedUri;
    }

    try {
      await onAddOrUpdateCompany({
        ...companyInfo,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('onAddOrUpdateCompany ~ err: ', err);
    }
    setLoading(false);
    setEditMode(false);
    refetchCompanyById();
  };

  const getSeatCountText = () => {
    if (
      (!latestCompanyInfo.adminSeats && latestCompanyInfo.adminSeats !== 0) ||
      !companyCrew
    ) {
      return null;
    }

    const { availableAdminSeats } = getCompanyCrewBreakdown(
      latestCompanyInfo,
      companyCrew
    );

    return `${(latestCompanyInfo.adminSeats || 0) - availableAdminSeats} / ${
      latestCompanyInfo.adminSeats
    }`;
  };

  const initialValues = {
    companyName: latestCompanyInfo?.companyName || '',
    companyLogo: latestCompanyInfo?.companyLogo || '',
    address: latestCompanyInfo?.address || '',
  };

  const handleRequestAdmins = () => {
    window.open(
      'https://share.hsforms.com/1-uuoDMdzRvenTSAsh-flZg3m7k0',
      '_blank'
    );
  };

  const handleEditMode = () => {
    setEditMode(true);
  };

  const typeWording = isFirm ? 'Firm' : 'Company';

  return (
    <Formik
      onSubmit={onFormSubmit}
      initialValues={initialValues}
      enableReinitialize
    >
      {formikProps => {
        const { values, setFieldValue, submitForm, resetForm } = formikProps;

        const cancelEdit = () => {
          resetForm();
          setEditMode(false);
        };

        // Check if any of the fields changed
        let changeOccurred = false;
        if (latestCompanyInfo) {
          changeOccurred =
            latestCompanyInfo.companyName !== values.companyName ||
            latestCompanyInfo.companyLogo !== values.companyLogo ||
            latestCompanyInfo.address !== values.address;
        }

        // Company name and address cannot be empty
        const valuesAreValid =
          values.companyName?.trim() && values.address?.trim();

        const readyToSubmit = changeOccurred && valuesAreValid;

        const renderCompanyLogo = () => {
          let imageUri = null;

          if (values.companyLogo && _.isObject(values.companyLogo)) {
            imageUri = values.companyLogo.uri;
          } else if (values.companyLogo) {
            imageUri = values.companyLogo;
          }

          if (imageUri) {
            return (
              <Grid
                container
                item
                direction="column"
                style={{ minHeight: companyLogoHeight }}
              >
                <Grid item>
                  <img
                    src={imageUri}
                    style={{
                      maxHeight: companyLogoHeight,
                      maxWidth: '100%',
                    }}
                    alt="Company Logo"
                    transformation={{
                      quality: 'auto',
                      width: imageTranformWidth,
                      crop: 'scale',
                      fetchFormat: 'jpg',
                    }}
                  />
                </Grid>
              </Grid>
            );
          }

          return (
            <Grid
              container
              item
              direction="column"
              style={{
                backgroundColor: '#f4f4f4',
                height: companyLogoHeight,
                width: companyLogoHeight,
              }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <WallpaperIcon style={{ fontSize: 60 }} />
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  No {_.toLower(typeWording)} logo
                </Typography>
              </Grid>
            </Grid>
          );
        };

        return (
          <SettingsSection
            title={`${typeWording} Info`}
            description={`Manage your ${_.toLower(typeWording)} details`}
            icon={<BusinessIcon />}
            {...(!isClient && {
              onDone: editMode ? submitForm : handleEditMode,
              onDoneText: editMode ? 'Save' : 'Edit',
              onDoneDisabled: editMode && !readyToSubmit,
              onCancel: editMode ? cancelEdit : null,
            })}
          >
            <Form
              style={{
                width: '100%',
                maxWidth: 650,
                flex: 1,
              }}
            >
              <Grid
                container
                item
                xs={12}
                direction="column"
                spacing={1}
                style={{ paddingTop: 20, paddingBottom: 20 }}
              >
                <Grid container item className={classes.formRow}>
                  <Grid item xs={4}>
                    <InputLabel>
                      <Typography color="textSecondary">
                        {typeWording} Name
                      </Typography>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    {!editMode ? (
                      <Typography
                        variant="h5"
                        style={{ paddingTop: 5, paddingBottom: 5 }}
                      >
                        {values.companyName}
                      </Typography>
                    ) : (
                      <Field
                        name="companyName"
                        value={values.companyName}
                        maxLength={MAX_CHARACTERS_IN_USERNAME}
                        type="text"
                        component={TextField}
                        inputProps={{
                          readOnly: !editMode,
                        }}
                        style={{ width: '100%' }}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container item className={classes.formRow}>
                  <Grid item xs={4}>
                    <InputLabel>
                      <Typography color="textSecondary">
                        {typeWording} Address
                      </Typography>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    {!editMode ? (
                      <Typography
                        variant="h5"
                        style={{ paddingTop: 5, paddingBottom: 5 }}
                      >
                        {values.address}
                      </Typography>
                    ) : (
                      <LocationSearchInput
                        multiline
                        passBack={address => {
                          setFieldValue('address', address);
                        }}
                        defaultValue={initialValues.address || ''}
                        pushedAddress={values.address}
                      />
                    )}
                  </Grid>
                </Grid>
                {isOwnerOrBookkeeper && !isCopilot && (
                  <Grid container item className={classes.formRow}>
                    <Grid item xs={4}>
                      <InputLabel>
                        <Typography color="textSecondary">
                          Admins Assigned
                        </Typography>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="h5"
                        style={{ paddingTop: 5, paddingBottom: 5 }}
                      >
                        {latestCompanyInfo && getSeatCountText()}
                        {!editMode && managingCompanyInfo.isCompanyOwner && (
                          <Tooltip title="Request Admin Seats">
                            <IconButton
                              className={classes.inlineIconButton}
                              onClick={handleRequestAdmins}
                            >
                              <PersonAddIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {!isClient && (
                  <Grid
                    container
                    item
                    className={clsx(classes.formRow, classes.topAlign)}
                  >
                    <Grid item xs={4}>
                      <InputLabel>
                        <Typography color="textSecondary">
                          {typeWording} Logo
                        </Typography>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                      {editMode ? (
                        <Dropzone
                          accept="image/*"
                          onDrop={acceptedFiles => {
                            const filesToPass = [...acceptedFiles];
                            filesToPass[0].uri = URL.createObjectURL(
                              filesToPass[0]
                            );
                            setFieldValue('companyLogo', filesToPass[0]);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <Grid container {...getRootProps()}>
                              <Grid
                                item
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <Grid item style={{ cursor: 'pointer' }}>
                                  <input {...getInputProps()} />
                                  {renderCompanyLogo()}
                                </Grid>
                                <Grid item style={{ cursor: 'pointer' }}>
                                  <input {...getInputProps()} />
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    Click to update
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Dropzone>
                      ) : (
                        renderCompanyLogo()
                      )}
                    </Grid>
                  </Grid>
                )}

                {showManageSubscription && (
                  <Grid
                    container
                    item
                    className={clsx(classes.formRow, classes.topAlign)}
                    style={{ marginTop: 6 }}
                  >
                    <Grid item xs={4}>
                      <InputLabel>
                        <Typography color="textSecondary">
                          Manage Subscription
                        </Typography>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                      {hasManageSubscriptionPermission ? (
                        <>
                          <Typography
                            variant="body1"
                            style={{ paddingBottom: 16 }}
                          >
                            We use Stripe to help you manage your subscription
                            in a safe, reliable way. If you don&apos;t recall
                            which email address you use for billing, check the
                            email address that you&apos;re receiving current
                            invoices to.
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            href={
                              process.env
                                .REACT_APP_STRIPE_SELF_SERVE_PORTAL_LINK
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            endIcon={<OpenInNew />}
                          >
                            Manage billing and subscription
                          </Button>
                        </>
                      ) : (
                        <Typography
                          variant="body1"
                          style={{ paddingTop: 5, paddingBottom: 5 }}
                        >
                          You do not have permission to change billing and
                          subscription details. Please ask the user who created
                          your company account to manage billing and
                          subscription.
                        </Typography>
                      )}
                      <Typography
                        variant="body1"
                        style={{ paddingTop: 5, paddingBottom: 5 }}
                      >
                        If you need help, please contact support at{' '}
                        <Link
                          href="mailto:support@checkthelevel.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          support@checkthelevel.com
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {(loading ||
                (managingCompanyInfo &&
                  (managingCompanyInfo.isCompanyOwner ||
                    managingCompanyInfo.isBookkeepingCustomer) &&
                  !companyCrew)) && (
                <LoadingCover>
                  {loading && (
                    <Typography variant="h3" align="center">
                      Saving...
                    </Typography>
                  )}
                </LoadingCover>
              )}
            </Form>
          </SettingsSection>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
  firmInfo: state.appState.firmInfo || null,
});

export default compose(
  GetCompanyCrewAction,
  AddOrUpdateCompanyAction,
  connect(mapStateToProps)
)(ManageCompanyInfo);
