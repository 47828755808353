import gql from 'graphql-tag';

export default gql`
  query GetOcrDataForMedia(
    $mediaSourceType: OcrSourceType!
    $mediaSource: String
  ) {
    getOcrDataForMedia(
      input: { mediaSourceType: $mediaSourceType, mediaSource: $mediaSource }
    ) {
      status
      error
      uri
      dataJson
      duplicateInfo {
        contentId
        type
        jrnId
        title
      }
    }
  }
`;
