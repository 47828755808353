import gql from 'graphql-tag';

export default gql`
  mutation StartCustomScan(
    $activityId: ID!
    $companyId: ID!
    $startDate: String!
    $endDate: String!
    $bookkeepingRules: [BookkeepingRuleInput]!
    $type: String
    $daysOfWeek: [String]
  ) {
    startCustomScan(
      input: {
        activityId: $activityId
        companyId: $companyId
        startDate: $startDate
        endDate: $endDate
        bookkeepingRules: $bookkeepingRules
        type: $type
        daysOfWeek: $daysOfWeek
      }
    ) {
      status
      msg
    }
  }
`;
