import gql from 'graphql-tag';

export default gql`
  query FindQboSubcustomers(
    $companyId: String!
    $searchTerm: String
    $parentCustomerId: String
    $useQboId: Boolean
  ) {
    findQboSubcustomers(
      input: {
        companyId: $companyId
        searchTerm: $searchTerm
        parentCustomerId: $parentCustomerId
        useQboId: $useQboId
      }
    ) {
      items {
        managingCompanyId
        customerId
        contentId
        jrnId
        title
        creatorId
        contentUrl
        description
        endDate
        qboCustomerId
        privacyLevel
        inviteCode
        allowedToEdit
        allowedToAdd
        allowedToView
        usersEverOnJrn
        startDate
        synced
        labels
        latitude
        longitude
        phoneNumber
        phoneNumberActive
        type
        address
        useTimetracking
        useTimetrackingQuestions
        timetrackingQuestions
        timetrackingQuestionsCheckout
        fromTemplate
        timetrackingType
      }
    }
  }
`;
