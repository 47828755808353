import _ from 'lodash';

import { BOOKKEEPING_ALERT as BOOKKEEPING_REPORT_ALERT } from './bookkeeping-alerts.constants';

const BOOKKEEPING_ALERT_DICTIONARY = {
  [BOOKKEEPING_REPORT_ALERT.JOURNAL_ENTRY_CREATED_OR_MODIFIED]: {
    name: 'Journal Entries',
    description: `<p>Journal entries are a high risk transaction as they are typically used to hide fraud or if used incorrectly can lead to larger problems in your accounting software, for example journal entries should almost never be posted to Accounts Receivable (&quot;A/R&quot) or Accounts Payable (&quot;A/R&quot), instead the features within the A/R or A/P modules should be used to ensure the accuracy of your A/R and A/P reports are maintained.</p>`,
    labelsAndLocation: [
      { label: 'Reporting Date', key: 'txnDate' },
      { label: 'Account', key: 'accountName' },
      { label: 'Debits', key: 'debitAmount' },
      { label: 'Credits', key: 'creditAmount' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
  },
  [BOOKKEEPING_REPORT_ALERT.EXPENSE_TRANSACTIONS_WITH_CUSTOMERS_NOT_CODED_TO_COST_OF_SALES]: {
    name:
      'Expense Transactions Assigned to Customers Not Coded to Cost of Sales',
    description: `<p>Your gross profit is calculated as sales minus any direct costs spent on delivering your good or service, i.e. Cost of Goods Sold. An accurate gross profit is crucial for your business because it directly indicates the efficiency of its core operations by showing how well it generates profit from the sale of its goods or services meaning this metric is essential for making informed financial decisions, such as pricing strategies, budget allocations, and identifying areas where cost reductions could increase profitability.<br/><br/>Expenses assigned to a customer meet the definition of a direct cost or Cost of Goods Sold. Below are transactions assigned to customers not coded to Cost of Good Sold. Please review these and if required, reassign them to Cost of Goods accounts.</p>`,
    labelsAndLocation: [
      { label: 'Reporting Date', key: 'txnDate' },
      { label: 'Transaction Type', key: 'txnType' },
      { label: 'Transaction #', key: 'docNum' },
      { label: 'Customer Name', key: 'customerName' },
      { label: 'Account', key: 'accountName' },
      { label: 'Amount', key: 'amount' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
  },
  [BOOKKEEPING_REPORT_ALERT.COST_OF_SALES_TRANSACTIONS_MISSING_CUSTOMER_INFORMATION]: {
    name: 'Cost of Sales Transactions Missing Customer Information',
    description: `<p>Cost of Sales (&quot;COS&quot) is defined as any direct costs spent on delivering a good or service. Direct costs are defined as costs that can be easily and conveniently traced to a project or job. With this in mind, please review the following cost of sales transactions which have not been assigned to customers within your accounting software.</p>`,
    labelsAndLocation: [
      { label: 'Reporting Date', key: 'txnDate' },
      { label: 'Transaction Type', key: 'txnType' },
      { label: 'Transaction #', key: 'docNum' },
      { label: 'Account', key: 'accountName' },
      { label: 'Amount', key: 'amount' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
  },
  [BOOKKEEPING_REPORT_ALERT.BILLABLE_EXPENSE_TRANSACTIONS_WITHOUT_ATTACHMENT]: {
    name: 'Billable Expense Transactions without an Attachment',
    description: `<p>The following transactions marked as billable in your accounting software do not have an attachment. So, any sales invoices generated from these transactions will not have supporting documentation attached to the sales invoice. If you&apos;d like to include the supporting documents with the linked sales invoice, please upload the billable expense supporting documentation to these transactions.</p>`,
    labelsAndLocation: [
      { label: 'Reporting Date', key: 'txnDate' },
      { label: 'Transaction Type', key: 'txnType' },
      { label: 'Transaction #', key: 'docNum' },
      { label: 'Customer Name', key: 'customerName' },
      { label: 'Account', key: 'accountName' },
      { label: 'Amount', key: 'amount' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
  },
  [BOOKKEEPING_REPORT_ALERT.MISSING_BILL_NUMBERS]: {
    name: 'Missing Bill Numbers',
    description: `<p>Inputting bill numbers when you enter data into your accounting software helps prevent duplicate expense entries, and makes finding the documentation a lot easier should you ever get audited. With this in mind, please review the following bills that do not have bill numbers.</p>`,
    labelsAndLocation: [
      { label: 'Reporting Date', key: 'txnDate' },
      { label: 'Vendor', key: 'vendorName' },
      { label: 'Account', key: 'accountName' },
      { label: 'Amount', key: 'amount' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
  },
  [BOOKKEEPING_REPORT_ALERT.DUPLICATE_EXPENSE_TRANSACTIONS]: {
    name: 'Duplicate Expense Transactions',
    description: `<p>Expense transactions that may have been double counted in your books have been identified.</p>
      <p>&quot;C/R&quot; represents the cleared status of the transaction. A transaction can have 3 types of cleared statuses:</p>
      <ul>
        <li>&quot;Blank&quot; (aka Uncleared) - The Uncleared status appears when a transaction is brought into your accounting system by not using your accounting software&apos;s bank feed</li>
        <li>&quot;C&quot; (aka Cleared) - The Cleared status appears when a bank/credit card transaction has come directly from your bank. This could be via a bank feed or from importing a bank statement.</li>
        <li>&quot;R&quot; (aka Reconciled) - The Reconciled status appears when a bank/credit card transaction in your accounting software has been reconciled to a bank statement. You can reconcile bank transactions through <a href="https://quickbooks.intuit.com/learn-support/articles/getting-the-most-out-of-quickbooks/how-to-reconcile-in-quickbooks-online/05/897168" target="_blank">the bank reconciliation feature</a> within QuickBooks Online.</li>
      </ul>
      <p>The <span style="color:#aaa;">grey expense rows</span> indicate existing expenses that were identified as potential duplicates, while the dark expense rows are the potential duplicate expenses more recently added or modified by the identified user.</p>`,
    labelsAndLocation: [
      { label: 'Reporting Date', key: 'txnDate' },
      { label: 'Transaction Type', key: 'txnType' },
      { label: 'Transaction #', key: 'docNum' },
      { label: 'Vendor', key: 'vendorName' },
      { label: 'Account', key: 'accountName' },
      { label: 'Amount', key: 'amount' },
      { label: 'C/R', key: 'isCleared' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
    customSortFunc: items => {
      return _.orderBy(items, ['txn.txnDate', 'txn.txnType'], ['asc', 'asc']);
    },
  },
  [BOOKKEEPING_REPORT_ALERT.TRANSACTIONS_POSTED_TO_PARENT_ACCOUNTS]: {
    name: 'Transactions Posted to Parent Accounts',
    description: `<p>Transactions posted to parent accounts within your accounting software have been identified. Please repost these transactions to sub-accounts to avoid errors in your numbers.</p>`,
    labelsAndLocation: [
      { label: 'Reporting Date', key: 'txnDate' },
      { label: 'Transaction Type', key: 'txnType' },
      { label: 'Transaction #', key: 'docNum' },
      { label: 'Account Type', key: 'accountType' },
      { label: 'Account', key: 'accountName' },
      { label: 'Amount', key: 'amount' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
  },
  [BOOKKEEPING_REPORT_ALERT.TRANSACTIONS_POSTED_TO_PARENT_CUSTOMERS]: {
    name: 'Transactions Posted to Parent Customers',
    description: `<p>To ensure transactions are being correctly recorded against sub-customers in your accounting software for project-based bookkeeping purposes, please review the following transactions posted to parent customers.</p>`,
    labelsAndLocation: [
      { label: 'Reporting Date', key: 'txnDate' },
      { label: 'Transaction Type', key: 'txnType' },
      { label: 'Transaction #', key: 'docNum' },
      { label: 'Customer Name', key: 'customerName' },
      { label: 'Account', key: 'accountName' },
      { label: 'Amount', key: 'amount' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
  },
  [BOOKKEEPING_REPORT_ALERT.CLASS_ALERTS]: {
    name: 'Transactions Missing Classes',
    description: `<p>The following transactions were not assigned a class. Where applicable, please assign classes to the following transactions.</p>`,
    labelsAndLocation: [
      { label: 'Reporting Date', key: 'txnDate' },
      { label: 'Transaction Type', key: 'txnType' },
      { label: 'Transaction #', key: 'docNum' },
      { label: 'Account Type', key: 'accountType' },
      { label: 'Account', key: 'accountName' },
      { label: 'Amount', key: 'amount' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
  },
  [BOOKKEEPING_REPORT_ALERT.LOCATION_ALERTS]: {
    name: 'Transactions Missing Location',
    description: `<p>The following transactions were not assigned a location. Where applicable, please assign a location to the following transactions.</p>`,
    labelsAndLocation: [
      { label: 'Reporting Date', key: 'txnDate' },
      { label: 'Transaction Type', key: 'txnType' },
      { label: 'Transaction #', key: 'docNum' },
      { label: 'Account Type', key: 'accountType' },
      { label: 'Account', key: 'accountName' },
      { label: 'Amount', key: 'amount' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
  },
  [BOOKKEEPING_REPORT_ALERT.VOIDED_TRANSACTIONS]: {
    name: 'Transactions Voided',
    description: `<p>The following voided transaction counts were detected in your accounting software.</p>
    <p>A transaction can have 3 types of cleared statuses:</p>
    <ul>
      <li>&quot;Blank&quot; (aka Uncleared) - The Uncleared status appears when a transaction is brought into your accounting system by not using your accounting software&apos;s bank feed</li>
      <li>&quot;C&quot; (aka Cleared) - The Cleared status appears when a bank/credit card transaction has come directly from your bank. This could be via a bank feed or from importing a bank statement.</li>
      <li>&quot;R&quot; (aka Reconciled) - The Reconciled status appears when a bank/credit card transaction in your accounting software has been reconciled to a bank statement. You can reconcile bank transactions through <a href="https://quickbooks.intuit.com/learn-support/articles/getting-the-most-out-of-quickbooks/how-to-reconcile-in-quickbooks-online/05/897168" target="_blank">the bank reconciliation feature</a> within QuickBooks Online.</li>
    </ul>
    <p>Warning: When a reconciled transaction is voided it will create a reconciliation difference, and compromise the accuracy of your accounting data.</p>`,
    labelsAndLocation: [
      { label: 'Transaction Category', key: 'entityType' },
      { label: 'Voided Count', key: 'count' },
    ],
    footer: `<p>For a detailed audit history of voided transactions, review the <a href="{{QBO_AUDIT_LOG_LINK}}" target="_blank">QuickBooks Online Audit Log</a>. <b>Please sign into QuickBooks Online in another tab before clicking the link.</b></p>`,
  },
  [BOOKKEEPING_REPORT_ALERT.DELETED_TRANSACTIONS]: {
    name: 'Transactions Deleted',
    description: `<p>The following deleted transaction counts were detected in your accounting software.</p>
    <p>A transaction can have 3 types of cleared statuses:</p>
    <ul>
      <li>&quot;Blank&quot; (aka Uncleared) - The Uncleared status appears when a transaction is brought into your accounting system by not using your accounting software&apos;s bank feed</li>
      <li>&quot;C&quot; (aka Cleared) - The Cleared status appears when a bank/credit card transaction has come directly from your bank. This could be via a bank feed or from importing a bank statement.</li>
      <li>&quot;R&quot; (aka Reconciled) - The Reconciled status appears when a bank/credit card transaction in your accounting software has been reconciled to a bank statement. You can reconcile bank transactions through <a href="https://quickbooks.intuit.com/learn-support/articles/getting-the-most-out-of-quickbooks/how-to-reconcile-in-quickbooks-online/05/897168" target="_blank">the bank reconciliation feature</a> within QuickBooks Online.</li>
    </ul>
    <p>Warning: When a reconciled transaction is deleted it will create a reconciliation difference, and compromise the accuracy of your accounting data.</p>`,
    labelsAndLocation: [
      { label: 'Transaction Category', key: 'entityType' },
      { label: 'Deleted Count', key: 'count' },
    ],
    footer: `<p>For a detailed audit history of deleted transactions, review the <a href="{{QBO_AUDIT_LOG_LINK}}" target="_blank">QuickBooks Online Audit Log</a>. <b>Please sign into QuickBooks Online in another tab before clicking the link.</b></p>`,
  },
  [BOOKKEEPING_REPORT_ALERT.TRANSACTIONS_MISSING_VENDOR_CUSTOMER_INFORMATION]: {
    name: 'Transactions Missing Vendor/Customer Information',
    description: `<p>Assigning vendor/customer information to your business&apos;s transactions has a number of benefits, like reducing the risk of fraud or duplicate entries, and providing extra security should you ever get audited. With this in mind, please review the following transactions which have not been assigned to a vendor/customer within your accounting software:</p>`,
    labelsAndLocation: [
      { label: 'Reporting Date', key: 'txnDate' },
      { label: 'Transaction Type', key: 'txnType' },
      { label: 'Transaction #', key: 'docNum' },
      { label: 'Account', key: 'accountName' },
      { label: 'Amount', key: 'amount' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
  },
  [BOOKKEEPING_REPORT_ALERT.NEW_VENDOR_IN_ACCOUNT]: {
    name: 'Transactions with a New Vendor in Any Account',
    description:
      '<p>The following accounts have new vendors assigned to them. Please double check these transactions to see if they were supposed to be posted to an account that already has these vendors assigned to them. You can find a list of vendors and transactions/accounts associated with them by signing into your QuickBooks Online account and navigating to Expenses > Vendors or by <a href="https://app.qbo.intuit.com/app/vendors" target="_blank">clicking this shortcut</a>.</p>',
    labelsAndLocation: [
      { label: 'Reporting Date', key: 'txnDate' },
      { label: 'Transaction Type', key: 'txnType' },
      { label: 'Transaction #', key: 'docNum' },
      { label: 'Vendor', key: 'vendorName' },
      { label: 'Account', key: 'accountName' },
      { label: 'Amount', key: 'amount' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
  },
  [BOOKKEEPING_REPORT_ALERT.NEW_VENDOR]: {
    name: 'New Vendors Added',
    description: `<p>The following vendors have been added to your accounting software.</p><br />
    <p><strong style="color: red">ACTION REQUIRED:</strong> If you&apos;d like the new vendors tracked in your accounting software for 1099 (USA) or T5018 (Canada) purposes, please email your bookkeeping team and let them know to update the new vendor in your accounting software accordingly.</p><br />
    <p><strong>IMPORTANT:</strong> Where the vendor is an employee, subcontractor or 1099, to avoid errors in your year-end tax filing please ensure the correct &quot;Track Payments&quot; setting has been selected and the vendor&apos;s Business / Social Security Number has been saved in your accounting software. If you are uncertain whether a vendor requires its payments tracked, please contact your tax accountant or see the IRS or CRA website for more information.</p><br />`,
    labelsAndLocation: [
      { label: 'Vendor Name', key: 'name' },
      { label: 'Currency', key: 'currency' },
      { label: 'Track Payments', key: 'trackType' },
      { label: 'Business / Social Security Number', key: 'taxId' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
    customSortFunc: items => {
      return _.orderBy(items, ['name', 'currency'], ['asc', 'asc']);
    },
  },
  [BOOKKEEPING_REPORT_ALERT.NEW_PRODUCT]: {
    name: 'New Product/Service Items',
    description: `<p>New products/services can affect your accounting reports, and some integrations with accounting software require transactions to be posted to products/services in order to sync properly. Please review the following new product/services.</p>`,
    labelsAndLocation: [
      { label: 'Product/Service Type', key: 'itemType' },
      { label: 'Name', key: 'name' },
      { label: 'Class', key: 'class' },
      { label: 'Income Account', key: 'incomeAccountName' },
      { label: 'Expense Account', key: 'expenseAccountName' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
    customSortFunc: items => {
      return _.orderBy(items, ['itemType', 'name'], ['asc', 'asc']);
    },
  },
  [BOOKKEEPING_REPORT_ALERT.TRANSACTIONS_MISSING_PRODUCT_OR_SERVICE]: {
    name: 'Transactions Missing Product/Service',
    description: `<p>The following transactions were not assigned a product/service. Where applicable, please assign a product/service to the following transactions.</p>
    <p><strong>IMPORTANT:</strong> Some integrations with QuickBooks Online, like BuilderTrend, may require transactions assigned to products/services in order to sync. If a transaction is posted without a product/service that could mean information won&apos;t be synced correctly. If you are creating new products/services in either QuickBooks Online or your integrated software, it is recommended to notify your accountant/bookkeeper so that they can confirm the new product/service has been correctly mapped in both systems.</p>`,
    labelsAndLocation: [
      { label: 'Reporting Date', key: 'txnDate' },
      { label: 'Transaction Type', key: 'txnType' },
      { label: 'Transaction #', key: 'docNum' },
      { label: 'Account Type', key: 'accountType' },
      { label: 'Account', key: 'accountName' },
      { label: 'Amount', key: 'amount' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
  },
  [BOOKKEEPING_REPORT_ALERT.NONBILLABLE_TRANSACTIONS_WITH_CUSTOMERS]: {
    name: 'Expense Transactions Assigned to Customers Not Marked as Billable',
    description: `<p>You can mark Expenses, Bills, and Time Charges as &quot;Billable&quot; in QuickBooks Online to quickly create a sales invoice from these transactions. This feature is helpful if you are charging your customers using a &quot;Time and Material&quot; or &quot;Cost-Plus&quot; pricing method.</p> <p>Please review the following created or updated transactions that have been assigned to customers and not marked as &quot;Billable&quot.</p>`,
    labelsAndLocation: [
      { label: 'Reporting Date', key: 'txnDate' },
      { label: 'Transaction Type', key: 'txnType' },
      { label: 'Transaction #', key: 'docNum' },
      { label: 'Customer Name', key: 'customerName' },
      { label: 'Account', key: 'accountName' },
      { label: 'Amount', key: 'amount' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
  },
  [BOOKKEEPING_REPORT_ALERT.NONBILLABLE_TIME_ACTIVITY]: {
    name: 'Time Charges Assigned to Customers Not Marked as Billable',
    description: `<p>You can mark Time Charges as &quot;Billable&quot; in QuickBooks Online to quickly create a sales invoice from these transactions. This feature is helpful if you are charging your customers using a &quot;Time and Material&quot; or &quot;Cost-Plus&quot; pricing method.</p> <p>Please review the following created or updated time charges that have been assigned to customers and not marked as &quot;Billable&quot.</p>`,
    labelsAndLocation: [
      { label: 'Customer Name', key: 'customerName' },
      { label: 'Activity Date', key: 'activityDate' },
      { label: 'Employee Name', key: 'employeeName' },
      { label: 'Service', key: 'service' },
      { label: 'Description', key: 'description' },
      { label: 'Duration', key: 'duration' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
    customSortFunc: timeActivities => {
      return _.orderBy(
        timeActivities,
        [
          'customerName',
          'activityDate',
          timeActivity => Number(timeActivity.id),
        ],
        ['asc', 'asc', 'asc']
      );
    },
  },
  [BOOKKEEPING_REPORT_ALERT.TRANSACTIONS_POSTED_TO_CLOSED_PERIOD]: {
    name: 'Transactions Posted to a Closed Bookkeeping Period',
    description: `<p>The following transactions were posted in a closed bookkeeping period, and may affect your previously filed tax returns or distort your historical financial data. Please review these transactions to determine if they were posted to the correct bookkeeping period.<br/><br/>If you'd like to update when your books are closed, please visit your <a href="https://webapp.checkthelevel.com/alerts/settings" target="_blank">settings</a>.</p>`,
    labelsAndLocation: [
      { label: 'Reporting Date', key: 'txnDate' },
      { label: 'Transaction Type', key: 'txnType' },
      { label: 'Transaction #', key: 'docNum' },
      { label: 'Name', key: 'name' },
      { label: 'Memo/Description', key: 'memo' },
      { label: 'Account', key: 'accountName' },
      { label: 'Amount', key: 'amount' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
    customSortFunc: transactions => {
      return _.orderBy(
        transactions,
        ['txnDate', 'txnType', txn => Number(txn.txnId)],
        ['desc', 'asc', 'asc']
      );
    },
  },
  [BOOKKEEPING_REPORT_ALERT.VENDORS_WITH_NEGATIVE_BALANCES]: {
    // Use cases:
    //  - pay vendor with no bill to pay, then vendor would owe money from an accounting perspective (VENDOR)
    //  - understated expenses from a tax perspective
    name: 'Vendors with Negative Balances',
    groupTargetOverride: 'Vendor',
    description: `<p>The following transactions have resulted in negative balances with these vendors. If you use the &quot;Cash&quot; accounting method for your reports, it&apos;s likely that these transactions have also resulted in an <a href="https://quickbooks.intuit.com/learn-support/en-us/help-article/pay-bills/see-unapplied-cash-bill-payment-expense-profit/L5xz0uRuz_US_en_US#:~:text=When%20you%20run%20a%20Profit,without%20matching%20them%20to%20bills" target="_blank">Unapplied Cash Bill Payment Expense</a> in your profit and loss statement.</p>`,
    labelsAndLocation: [
      { label: 'Created/Modified By', key: 'lastModifiedBy' },
      { label: 'Transaction Type', key: 'txnType' },
      { label: 'Reporting Date', key: 'txnDate' },
      { label: 'Transaction # ', key: 'docNum' },
      { label: 'Account', key: 'accountName' },
      { label: 'Amount', key: 'taxInAmount' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
    summary: BOOKKEEPING_REPORT_ALERT.VENDORS_WITH_NEGATIVE_BALANCES_SUMMARY,
  },
  [BOOKKEEPING_REPORT_ALERT.VENDORS_WITH_NEGATIVE_BALANCES_SUMMARY]: {
    description: `<p>The following vendors have an outstanding negative A/P balance.</p>`,
    labelsAndLocation: [
      { label: 'Vendor', key: 'key' },
      { label: 'Outstanding Balance', key: 'value' },
    ],
    isSummarySection: true,
    customSortFunc: items => {
      return _.orderBy(items, ['key', 'value'], ['asc', 'asc']);
    },
  },
  [BOOKKEEPING_REPORT_ALERT.CUSTOMERS_WITH_NEGATIVE_BALANCES]: {
    // Use cases:
    //  - payment received with no invoice, then we would owe customer money from an accounting perspective (CUSTOMER)
    //  - understated sales from a tax perspective
    name: 'Customers with Negative Balances',
    groupTargetOverride: 'Customer',
    description: `<p>The following transactions have resulted in negative balances with these customers. If you use the &quot;Cash&quot; accounting method for your reports, it&apos;s likely that these transactions have also resulted in an <a href="https://quickbooks.intuit.com/learn-support/en-us/help-article/pay-bills/see-unapplied-cash-bill-payment-expense-profit/L5xz0uRuz_US_en_US#:~:text=When%20you%20run%20a%20Profit,without%20matching%20them%20to%20bills" target="_blank">Unapplied Cash Bill Payment Expense</a> in your profit and loss statement.</p>`,
    labelsAndLocation: [
      { label: 'Created/Modified By', key: 'lastModifiedBy' },
      { label: 'Transaction Type', key: 'txnType' },
      { label: 'Reporting Date', key: 'txnDate' },
      { label: 'Transaction # ', key: 'docNum' },
      { label: 'Account', key: 'accountName' },
      { label: 'Amount', key: 'taxInAmount' },
      { label: 'Addressed', key: 'alertInstanceId' },
    ],
    summary: BOOKKEEPING_REPORT_ALERT.CUSTOMERS_WITH_NEGATIVE_BALANCES_SUMMARY,
  },
  [BOOKKEEPING_REPORT_ALERT.CUSTOMERS_WITH_NEGATIVE_BALANCES_SUMMARY]: {
    description: `<p>The following customers have an outstanding negative A/R balance.</p>`,
    labelsAndLocation: [
      { label: 'Customer', key: 'key' },
      { label: 'Outstanding Balance', key: 'value' },
    ],
    isSummarySection: true,
    customSortFunc: items => {
      return _.orderBy(items, ['key', 'value'], ['asc', 'asc']);
    },
  },
};

export { BOOKKEEPING_ALERT_DICTIONARY as BOOKKEEPING_REPORT_ALERT_DICTIONARY };
