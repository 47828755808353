import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  mutation DisconnectSyncedJrn($companyId: String!, $contentId: String!) {
    disconnectSyncedJrn(
      input: { companyId: $companyId, contentId: $contentId }
    ) {
      __typename
      managingCompanyId
      customerId
      contentId
      jrnId
      title
      creatorId
      contentUrl
      description
      endDate
      qboCustomerId
      privacyLevel
      inviteCode
      allowedToEdit
      allowedToAdd
      allowedToView
      usersEverOnJrn
      startDate
      synced
      labels
      latitude
      longitude
      phoneNumber
      phoneNumberActive
      type
      address
      useTimetracking
      useTimetrackingQuestions
      timetrackingQuestions
      timetrackingQuestionsCheckout
      # REMOVEME: remove this when "bugets" field is deprecated
      budgets {
        ...BudgetFields
      }
      fromTemplate
      timetrackingType
    }
  }
`;
