import gql from 'graphql-tag';

export default gql`
  mutation GenerateNewCustomerOrder(
    $companyName: String!
    $email: String!
    $price: String!
    $count: Int
    $taxRate: String
    $coupon: String
  ) {
    generateNewCustomerOrder(
      input: {
        companyName: $companyName
        email: $email
        price: $price
        count: $count
        taxRate: $taxRate
        coupon: $coupon
      }
    ) {
      sessionId
    }
  }
`;
