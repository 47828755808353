import React, { useEffect, useState, useRef } from 'react';
import { useQuery } from 'react-apollo-hooks';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';

import {
  Button,
  ButtonBase,
  Typography,
  InputLabel,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Tooltip,
} from '@material-ui/core';
import {
  ContactMail as ContactMailIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  PersonAddDisabled as PersonAddDisabledIcon,
  PersonAdd as PersonAddIcon,
  HelpOutline as HelpOutlineIcon,
} from '@material-ui/icons';
import GetCompanyCustomers from '../../../graphql/queries/get-company-customers';
import FilterList from '../../../components/FilterSearch/filterSearch';
import CenteredSpinner from '../../../components/centered-spinner/centered-spinner';
import OkCancelDialog from '../../../components/OkCancelDialog/okCancelDialog';

import AddCustomerDialog from '../../../components/add-customer-dialog';

import { orderCustomers } from '../../../helpers';
import QboSyncStatusIcon from '../../../components/qbo-sync-status-icon';
import { QBO_SYNCABLE_TYPE } from '../../../config/appDefaults';
import { getQboSyncState, QBO_SYNC_STATE } from '../../../helpers/qbo-sync';
import ButtonWithTooltip from '../../../components/button-with-tooltip';

const useStyles = makeStyles(theme => ({
  staticLabel: {
    transform: 'scale(0.75)',
  },
  advancedButton: { marginTop: 3, minWidth: '80%' },
  customerOption: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  customerAddress: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0,
  },
  instructionWrapper: {
    marginBottom: theme.spacing(1),
  },
  customerItemWrapper: {
    borderBottom: '1px solid #ccc',
    color: '#333',
  },
  customerName: {
    marginRight: theme.spacing(2),
  },
  radioIcon: {
    marginRight: theme.spacing(0.5),
  },
  unassignButton: {
    margin: `${theme.spacing(2)}px auto 0 auto`,
  },
}));

const ChooseCustomer = ({
  open,
  mode,
  managingCompanyInfo,
  restrictedMode,
  onChoose,
  initialId,
  errorMessage,
  onClose,
  disabledMessage,
}) => {
  const customerContainerRef = useRef(null);
  const customerItemRefs = useRef([]);

  const classes = useStyles();
  const [showAddCustomerDialog, setShowAddCustomerDialog] = useState({
    open: false,
  });
  const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
  const [selection, setSelection] = useState({
    customerId: initialId,
    scrollToCustomer: false,
  });

  const [useThisData, setUseThisData] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    title: '',
    message: '',
    onClose: () => {}, // Set based on context
    hideCancel: false,
    onConfirm: () => {}, // Set based on context
  });

  useEffect(() => {
    if (!openCustomerDialog && open) {
      setOpenCustomerDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (selection && selection.scrollToCustomer && selection.customerId) {
      const foundElement = _.find(customerItemRefs.current, customerElement => {
        if (customerElement) {
          return customerElement.id === `item-${selection.customerId}`;
        }
        return false;
      });

      if (foundElement) {
        if (customerContainerRef && customerContainerRef.current) {
          customerContainerRef.current.scroll({
            top: foundElement.offsetTop - 200,
            behavior: 'smooth',
          });
        }
      }
    }
  }, [selection]);

  const {
    loading: getCustomersLoading,
    error: getCustomersError,
    data: getCustomersData,
  } = useQuery(GetCompanyCustomers, {
    skip: !openCustomerDialog,
    variables: {
      companyId: managingCompanyInfo.managingCompanyId,
    },
  });

  let selectionIsSyncing = false;

  let customerData = null;
  if (getCustomersData) {
    const foundCustomers = _.get(
      getCustomersData,
      'getCompanyCustomers.items',
      null
    );

    if (foundCustomers) {
      customerData = orderCustomers(foundCustomers);
    }
  }

  if (selection && selection.customerId && customerData) {
    const selectedCustomer = _.find(
      customerData,
      ({ customerId }) => customerId === selection.customerId
    );

    const syncState = getQboSyncState(selectedCustomer);

    selectionIsSyncing = syncState === QBO_SYNC_STATE.SYNCING;
  }

  const closeDialog = () => {
    setOpenCustomerDialog(false);
    if (onClose) {
      onClose();
    }
  };

  const handleCancel = () => {
    closeDialog();
  };
  const handleDone = () => {
    let customerInfo = null;

    if (selection && selection.customerId) {
      customerInfo = _.find(
        customerData,
        ({ customerId }) => customerId === selection.customerId
      );
    }

    onChoose(customerInfo);
    closeDialog();
  };
  const handleUnassign = () => {
    setSelection(null);
  };

  const handleCustomerJustCreated = customer => {
    setDialogInfo({
      title: 'New Customer Created',
      message: 'Would you like to set this using the new customer?',
      open: true,
      onClose: () => setDialogInfo({ ...dialogInfo, open: false }),
      hideCancel: false,
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      onConfirm: () => setSelection({ ...customer, scrollToCustomer: true }),
    });
  };

  const openAddCustomerDialog = () => {
    setShowAddCustomerDialog({
      open: true,
    });
  };

  return (
    <>
      <InputLabel
        className={classes.staticLabel}
        htmlFor="visualPermissions"
        disabled={restrictedMode}
      >
        Customer
      </InputLabel>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          variant="outlined"
          onClick={() => setOpenCustomerDialog(true)}
          className={classes.advancedButton}
          startIcon={<ContactMailIcon />}
          disabled={!!disabledMessage}
        >
          Choose Customer...
        </Button>
        {disabledMessage && (
          <Tooltip title={disabledMessage}>
            <HelpOutlineIcon
              fontSize="small"
              color="inherit"
              style={{ marginLeft: 5 }}
            />
          </Tooltip>
        )}
      </div>
      {!!errorMessage && (
        <div>
          <Typography variant="caption" style={{ color: 'red' }}>
            {errorMessage}
          </Typography>
        </div>
      )}

      {openCustomerDialog && (
        <Dialog open fullWidth maxWidth="xs" scroll="paper">
          <DialogContent
            dividers
            style={{ maxHeight: 500, minHeight: 300 }}
            ref={customerContainerRef}
          >
            <Grid container>
              <Grid item className={classes.instructionWrapper}>
                <Typography variant="h5" color="primary">
                  Which customer would you like to assign this to:
                </Typography>
              </Grid>
              {getCustomersError && 'Sorry, there was an error. Please retry.'}
              {!getCustomersError && getCustomersLoading && <CenteredSpinner />}
              {!getCustomersError && !getCustomersLoading && customerData && (
                <>
                  <FilterList
                    data={customerData}
                    isFiltered={filterStatus => setIsFiltered(filterStatus)}
                    passBack={filteredData => setUseThisData(filteredData)}
                    searchBy={['firstName', 'lastName', 'companyName']}
                  />

                  <div style={{ height: 16, width: '100%' }} />

                  {useThisData &&
                    useThisData.length > 0 &&
                    useThisData.map(customer => {
                      const hasFirstOrLast = !!(
                        customer.firstName || customer.lastName
                      );
                      const hasFirstAndLast =
                        customer.firstName && customer.lastName;

                      return (
                        <Grid
                          id={`item-${customer.customerId}`}
                          item
                          xs={12}
                          key={customer.customerId}
                          className={classes.customerItemWrapper}
                          ref={element =>
                            customerItemRefs.current.push(element)
                          }
                        >
                          <ButtonBase
                            onClick={() => {
                              setSelection({
                                ...customer,
                                scrollToCustomer: false,
                              });
                            }}
                            className={classes.customerOption}
                          >
                            {selection &&
                            selection.customerId === customer.customerId ? (
                              <RadioButtonCheckedIcon
                                className={classes.radioIcon}
                                color="inherit"
                              />
                            ) : (
                              <RadioButtonUncheckedIcon
                                className={classes.radioIcon}
                                color="inherit"
                              />
                            )}
                            <div
                              className={classes.customerName}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <Typography variant="body1" color="inherit">
                                {hasFirstOrLast ? (
                                  <>
                                    {customer.firstName}
                                    {hasFirstAndLast && ' '}
                                    {customer.lastName}
                                  </>
                                ) : (
                                  customer.companyName || ''
                                )}
                              </Typography>
                              <QboSyncStatusIcon
                                recordInfo={customer}
                                recordType={QBO_SYNCABLE_TYPE.CUSTOMER}
                              />
                            </div>
                          </ButtonBase>
                        </Grid>
                      );
                    })}
                  {(!customerData || customerData.length === 0) && (
                    <Grid item xs={12}>
                      {isFiltered ? (
                        <div>Search returned no results.</div>
                      ) : (
                        <div>No customers added yet.</div>
                      )}
                    </Grid>
                  )}
                  {mode !== 'lead' && !selection.customerId && (
                    <Grid container item xs={12} justifyContent="center">
                      <Button
                        onClick={handleUnassign}
                        startIcon={<PersonAddDisabledIcon />}
                        className={classes.unassignButton}
                        color="default"
                        variant="contained"
                      >
                        Unassign Customer
                      </Button>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button
                  color="primary"
                  onClick={openAddCustomerDialog}
                  startIcon={<PersonAddIcon />}
                >
                  New Customer
                </Button>
              </Grid>
              <Grid item>
                <Button autoFocus onClick={handleCancel} color="primary">
                  Cancel
                </Button>
                <ButtonWithTooltip
                  disabled={selectionIsSyncing}
                  tooltipText={
                    selectionIsSyncing && 'Customer sync in progress...'
                  }
                  variant="text"
                  onClick={handleDone}
                >
                  Done
                </ButtonWithTooltip>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
      {showAddCustomerDialog.open && (
        <AddCustomerDialog
          toClose={passedCustomerInfo => {
            if (passedCustomerInfo && passedCustomerInfo.customerId) {
              handleCustomerJustCreated(passedCustomerInfo);
            }
            setShowAddCustomerDialog({
              open: false,
            });
          }}
          optimisticSync
        />
      )}
      {dialogInfo.open && (
        <OkCancelDialog {...dialogInfo}>
          <Typography>{dialogInfo.message}</Typography>
        </OkCancelDialog>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
  };
}

export default connect(mapStateToProps)(ChooseCustomer);
