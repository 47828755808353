import gql from 'graphql-tag';

import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Rfi}
  mutation UpdateCompanyRfis(
    $rfis: [UpdateCompanyRfiInput!]!
    $companyId: String!
  ) {
    updateCompanyRfis(input: { rfis: $rfis, companyId: $companyId }) {
      items {
        ...RfiBaseFields
      }
      nextToken
    }
  }
`;
