import gql from 'graphql-tag';

export default gql`
  query GetNotificationPrefs($requestorId: ID!) {
    getNotificationPrefs(requestorId: $requestorId) {
      __typename
      msg
      readyToReceive
      token
      dontSendFor {
        jrnId
        chat
        newContent
        newComment
      }
      userId
    }
  }
`;
