import React, { useContext } from 'react';
import { Grid, IconButton, Typography, Avatar } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

import ProjectDetailsHeader from '../Projects/components/ProjectDetails/projectDetailsHeader';
import LevelModal from '../../components/Modal/level-modal';
import { CalendarDataContext } from './calendar-data-provider';
import { cloudinaryifyProfilePic } from '../../helpers/cloudinary';
import {
  renderDateString,
  renderShiftDate,
} from '../../helpers/renderDateString';
import { capitalizeSentence } from '../../helpers';

import GoToProjectButton from './calendar-event-details-modal-gotoproject';
import { CONTENT_TYPE, CONTENT_DEFINITION } from '../../config/appDefaults';
import useStyles from './calendar.styles';

const CalendarEventDetailsModal = ({ contentItem, handleClose }) => {
  const classes = useStyles();

  const calendarDataContext = useContext(CalendarDataContext);

  if (!contentItem) {
    // Should be closed if no content item is passed in
    return null;
  }

  const isAProject = contentItem.type === CONTENT_TYPE.PROJECT;
  const isATask = contentItem.type === CONTENT_TYPE.TASK;
  const isAShift = contentItem.type === CONTENT_TYPE.SHIFT;

  let goToProjectId;
  if (isAProject) {
    goToProjectId = contentItem.contentId;
  } else {
    goToProjectId = contentItem.jrnId;
  }

  let assigneeInfo;
  if (contentItem.assignedTo && contentItem.assignedTo !== 'unassigned') {
    if (calendarDataContext.userMap[contentItem.assignedTo]) {
      assigneeInfo = calendarDataContext.userMap[contentItem.assignedTo];
    }
  }

  let parentInfo;
  if (contentItem.jrnId) {
    if (calendarDataContext.projectMap[contentItem.jrnId]) {
      parentInfo = calendarDataContext.projectMap[contentItem.jrnId];
    }
  }

  const renderTaskDate = () => {
    let dateString;
    if (!contentItem.endDate || contentItem.endDate < contentItem.date) {
      dateString = renderDateString(
        contentItem.date,
        null,
        'MMM D, YYYY @ h:mma'
      );
      return (
        <>
          <span className={classes.taskLabel}>Due Date:</span>
          <span className={classes.taskLabelInfo}>{` ${dateString}`}</span>
        </>
      );
    }

    dateString = ` ${renderDateString(
      contentItem.startDate,
      null,
      'MMM D, YYYY'
    )} - ${renderDateString(contentItem.endDate, null, 'MMM D, YYYY')}`;

    return (
      <>
        <span className={classes.taskLabelInfo}>{dateString}</span>
      </>
    );
  };

  const TaskIcon = CONTENT_DEFINITION[CONTENT_TYPE.TASK].Icon;

  return (
    <LevelModal
      open
      handleClose={handleClose}
      contentStyle={{ padding: 8 }}
      maxWidth={isAProject ? null : 'sm'}
    >
      <Grid container justifyContent="flex-end">
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid
        container
        className={classes.paper}
        style={{ padding: 16, paddingTop: 4 }}
      >
        {isATask && (
          <Grid item container direction="column">
            <Grid container direction="row" alignItems="center">
              <Grid item style={{ flex: 0 }}>
                <Avatar
                  alt="Task image"
                  src={
                    contentItem?.contentUrl
                      ? cloudinaryifyProfilePic(contentItem.contentUrl)
                      : null
                  }
                  className={classes.bigAvatar}
                >
                  <TaskIcon />
                </Avatar>
              </Grid>
              <Grid
                item
                style={{ alignItems: 'center', display: 'flex', flex: 1 }}
              >
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      {parentInfo?.title || 'No project found'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h4"
                      className={classes.modalProjectTitle}
                    >
                      {contentItem.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container item style={{ width: '100%' }}>
                      <Grid item style={{ width: 80 }}>
                        <Typography style={{ fontWeight: 'bold' }}>
                          Due
                        </Typography>
                      </Grid>
                      <Grid item style={{ flex: 1 }}>
                        <Typography variant="h6">
                          {renderTaskDate(contentItem)}
                        </Typography>
                      </Grid>
                    </Grid>
                    {contentItem.contentStatus && (
                      <Grid container item style={{ width: '100%' }}>
                        <Grid item style={{ width: 80 }}>
                          <Typography style={{ fontWeight: 'bold' }}>
                            Status
                          </Typography>
                        </Grid>
                        <Grid item style={{ flex: 1 }}>
                          <Typography>
                            {contentItem.contentStatus
                              ? capitalizeSentence(contentItem.contentStatus)
                              : 'N/A'}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {assigneeInfo?.username && (
                      <Grid container item style={{ width: '100%' }}>
                        <Grid item style={{ width: 80 }}>
                          <Typography style={{ fontWeight: 'bold' }}>
                            Assignee
                          </Typography>
                        </Grid>
                        <Grid container item style={{ flex: 1 }}>
                          {assigneeInfo.username}
                          <Avatar
                            alt="user"
                            src={
                              assigneeInfo.profilePic
                                ? cloudinaryifyProfilePic(
                                    assigneeInfo.profilePic
                                  )
                                : null
                            }
                            className={classes.smallAvatar}
                            style={{ marginLeft: 3 }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {!!contentItem.description && (
              <Grid
                item
                container
                direction="column"
                className={classes.descriptionWrapper}
              >
                <Typography variant="h5" className={classes.descriptionLabel}>
                  Task Details:
                </Typography>
                {ReactHtmlParser(contentItem.description)}
              </Grid>
            )}

            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="flex-end"
              style={{ marginTop: 16 }}
            >
              <GoToProjectButton projectId={goToProjectId} />
            </Grid>
          </Grid>
        )}
        {isAShift && (
          <Grid item container direction="column">
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Avatar
                  alt="project cover image"
                  src={
                    parentInfo?.contentUrl
                      ? cloudinaryifyProfilePic(parentInfo.contentUrl)
                      : null
                  }
                  className={classes.bigAvatar}
                />
              </Grid>
              <Grid item style={{ alignItems: 'center', display: 'flex' }}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography
                      variant="h5"
                      className={classes.modalProjectTitle}
                    >
                      {parentInfo?.title || 'No project found'}
                    </Typography>
                  </Grid>
                  {assigneeInfo?.username && (
                    <Grid item container alignItems="center">
                      <Grid item>
                        <b>{assigneeInfo.username}</b>
                      </Grid>
                      <Grid item style={{ padding: 3, paddingRight: 0 }}>
                        <Avatar
                          alt="user"
                          src={
                            assigneeInfo.profilePic
                              ? cloudinaryifyProfilePic(assigneeInfo.profilePic)
                              : null
                          }
                          className={classes.mediumAvatar}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid item>
                    <Typography variant="h6">
                      {renderShiftDate(
                        moment(contentItem.startDate),
                        moment(contentItem.endDate)
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!!contentItem.description && (
              <Grid
                item
                container
                direction="column"
                className={classes.descriptionWrapper}
              >
                <Typography variant="h5" className={classes.descriptionLabel}>
                  Shift Notes:
                </Typography>
                {ReactHtmlParser(contentItem.description)}
              </Grid>
            )}

            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <GoToProjectButton projectId={goToProjectId} />
            </Grid>
          </Grid>
        )}

        {isAProject && (
          <ProjectDetailsHeader
            projectInfo={contentItem}
            showActionButtons={false}
            hideStatTiles
          >
            <Grid
              item
              xs={12}
              container
              style={{
                position: 'absolute',
                bottom: 16,
                right: 16,
              }}
              alignContent="flex-end"
              justifyContent="flex-end"
            >
              <GoToProjectButton projectId={goToProjectId} />
            </Grid>
          </ProjectDetailsHeader>
        )}
      </Grid>
    </LevelModal>
  );
};

export default CalendarEventDetailsModal;
