import React, { useState, useEffect, useMemo } from 'react';
import { compose } from 'react-apollo';
import numeral from 'numeral';
import _ from 'lodash';

import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import EqualizerIcon from '@material-ui/icons/Equalizer';

import {
  BASIC_PROJECT_BUDGETS,
  BUDGET_INFO,
  BUDGET_TYPE_TOTAL_LABOR_COST,
  BUDGET_TYPE_TOTAL_LABOR_HOURS,
  BUDGET_TYPE_PAYMENTS,
  BUDGET_TYPE_RECEIPTS_INVOICES,
  BUDGET_TYPE_USER_LABOR_HOURS,
  CREW_PAY_TYPES,
  BUDGET_TYPE_UNASSIGNED_LABOR_HOURS,
} from '../../../config/appDefaults';
import LaborBudgetDialog from './labor-budget-dialog';
import LoadingCover from '../../../components/LoadingCover/loadingCover';

import { calculateHourlyRate } from '../../../helpers';
import { GetBudgetInfoAction } from '../../../graphql/graphql';

const BudgetsDialog = props => {
  const {
    open,
    handleClose,
    handleDone,
    projectId,
    budgetInfo: existingBudgetInfo,
    localBudgetInfo, // passed from parent component
    getBudgetInfoLoading,
    allUsers,
    activeUserIds,
    useTimetracking,
  } = props;

  const [budgetInfo, setBudgetInfo] = useState(null);
  const [budgetsByLabel, setBudgetsByLabel] = useState(null); // Mapping
  const [budgetsToShow, setBudgetsToShow] = useState(null);
  const [showLaborBudgetDialog, setShowLaborBudgetDialog] = useState({
    open: false,
  });

  const userIdsOnProject = useMemo(() => {
    if (allUsers) {
      return _.map(allUsers, user => user.userId);
    }
    return [];
  }, [allUsers]);

  useEffect(() => {
    const byLabelBudgets = {};
    let budgetDefaults = _.cloneDeep(BASIC_PROJECT_BUDGETS);

    if (!useTimetracking) {
      // remove total labor hours and total labor cost from budget defaults
      budgetDefaults = _.filter(budgetDefaults, budget => {
        return (
          budget.label !== BUDGET_TYPE_TOTAL_LABOR_HOURS &&
          budget.label !== BUDGET_TYPE_TOTAL_LABOR_COST
        );
      });
    }

    budgetDefaults.forEach(budget => {
      byLabelBudgets[budget.label] = budget;
    });

    const currentBudgetInfo = { ...existingBudgetInfo };
    if (localBudgetInfo) {
      if (!_.isEmpty(localBudgetInfo.budgets)) {
        currentBudgetInfo.budgets = localBudgetInfo.budgets;
      }
      if (!_.isEmpty(localBudgetInfo.userPayInfo)) {
        currentBudgetInfo.userPayInfo = localBudgetInfo.userPayInfo;
      }
    }

    const { budgets } = currentBudgetInfo;
    _.forEach(budgets, budget => {
      // set payment budget and receipts invoices budget
      if (
        budget.label === BUDGET_TYPE_PAYMENTS ||
        budget.label === BUDGET_TYPE_RECEIPTS_INVOICES
      ) {
        const thisBudget = _.cloneDeep(budget);
        if (thisBudget.value) {
          thisBudget.value = numeral(thisBudget.value).format(
            BUDGET_INFO[thisBudget.label].numberFormat
          );
        }
        byLabelBudgets[thisBudget.label] = thisBudget;
      }
    });

    setBudgetInfo(currentBudgetInfo);

    setBudgetsByLabel(byLabelBudgets);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingBudgetInfo, useTimetracking]);

  useEffect(() => {
    if (useTimetracking && budgetInfo) {
      const { budgets, userPayInfo } = budgetInfo;
      let totalLaborBudget = 0;
      let totalHoursBudget = 0;

      const userPayInfoMap = _.keyBy(userPayInfo, 'userId');

      _.forEach(budgets, budget => {
        if (budget.label === BUDGET_TYPE_UNASSIGNED_LABOR_HOURS) {
          const unassignedHours = budget.value;
          totalHoursBudget += unassignedHours;
          if (budget.context) {
            const averageHourlyRate = Number(budget.context);
            totalLaborBudget += unassignedHours * averageHourlyRate;
          }
        }

        // calculate total hours and total labor budget
        if (budget.label === BUDGET_TYPE_USER_LABOR_HOURS) {
          totalHoursBudget += budget.value;

          const payInfo = userPayInfoMap[budget.context];
          if (payInfo && payInfo.payType === CREW_PAY_TYPES.VARIABLE_HOURLY) {
            const hourlyRate = calculateHourlyRate(payInfo);
            totalLaborBudget += hourlyRate * budget.value;
          }
        }
      });

      setBudgetsByLabel(prevState => {
        const newState = _.cloneDeep(prevState);
        // Update the total hour budget
        newState[BUDGET_TYPE_TOTAL_LABOR_HOURS] = {
          ...newState[BUDGET_TYPE_TOTAL_LABOR_HOURS],
          value: numeral(totalHoursBudget).format(
            BUDGET_INFO[BUDGET_TYPE_TOTAL_LABOR_HOURS].numberFormat
          ),
        };

        // Update the total labor budget
        newState[BUDGET_TYPE_TOTAL_LABOR_COST] = {
          ...newState[BUDGET_TYPE_TOTAL_LABOR_COST],
          value: numeral(totalLaborBudget).format(
            BUDGET_INFO[BUDGET_TYPE_TOTAL_LABOR_COST].numberFormat
          ),
        };

        return newState;
      });
    }
  }, [budgetInfo, useTimetracking]);

  useEffect(() => {
    const initialBudgets = [];
    _.forEach(budgetsByLabel, budget => {
      initialBudgets.push(budget);
    });

    setBudgetsToShow(initialBudgets);
  }, [budgetsByLabel]);

  const onDoneClick = () => {
    const toReturn = _.cloneDeep(budgetInfo) || {};

    toReturn.budgets = _.filter(
      toReturn.budgets,
      budget =>
        budget.label === BUDGET_TYPE_UNASSIGNED_LABOR_HOURS ||
        budget.label === BUDGET_TYPE_USER_LABOR_HOURS
    );

    // make sure payment budget and receipt budget are always in the first and second positions
    toReturn.budgets = [
      { ...budgetsByLabel[BUDGET_TYPE_PAYMENTS] },
      { ...budgetsByLabel[BUDGET_TYPE_RECEIPTS_INVOICES] },
      ...toReturn.budgets,
    ];

    handleDone(toReturn);
  };

  const handleValueChange = ({ label, value }) => {
    if (
      label === BUDGET_TYPE_TOTAL_LABOR_COST ||
      label === BUDGET_TYPE_TOTAL_LABOR_HOURS
    ) {
      return;
    }

    setBudgetsByLabel(prevState => {
      const budgetsCopy = { ...(prevState || {}) };
      budgetsCopy[label] = { ...budgetsCopy[label], value };
      return budgetsCopy;
    });
  };

  const handleValueComplete = ({ label, value }) => {
    if (
      label === BUDGET_TYPE_TOTAL_LABOR_COST ||
      label === BUDGET_TYPE_TOTAL_LABOR_HOURS
    ) {
      return;
    }

    setBudgetsByLabel(prevState => {
      const budgetsCopy = { ...(prevState || {}) };
      let updatedValue = value;

      // Format the value based on the number format of the budget
      if (updatedValue) {
        if (BUDGET_INFO[label] && BUDGET_INFO[label].numberFormat) {
          updatedValue = numeral(updatedValue).format(
            BUDGET_INFO[label].numberFormat
          );
        }
      }
      budgetsCopy[label] = { ...budgetsCopy[label], value: updatedValue };
      return budgetsCopy;
    });
  };

  const openLaborBudgetDialog = budget => {
    if (
      budget.label === BUDGET_TYPE_TOTAL_LABOR_COST ||
      budget.label === BUDGET_TYPE_TOTAL_LABOR_HOURS
    ) {
      setShowLaborBudgetDialog({
        open: true,
      });
    }
  };

  const handleBudgetFieldClick = budget => {
    openLaborBudgetDialog(budget);
  };

  const handleBudgetFieldFocus = budget => {
    openLaborBudgetDialog(budget);
  };

  const handleLaborBudgetDialogClose = () => {
    setShowLaborBudgetDialog({
      open: false,
    });
  };

  const handleLaborBudgetDialogDone = updatedBudgetInfo => {
    setBudgetInfo(updatedBudgetInfo);
    setShowLaborBudgetDialog({ open: false });
  };

  const renderListOfBudgets = () => {
    if (!budgetsToShow || !budgetsToShow.length) {
      return null;
    }

    return budgetsToShow.map(budget => {
      let budgetValue = '';
      if (budget.value) {
        budgetValue = budget.value;
      }

      return (
        <Grid
          key={budget.label}
          item
          xs={12}
          sm={6}
          style={{ marginBottom: 16 }}
        >
          <TextField
            label={BUDGET_INFO[budget.label].labelText}
            InputProps={
              BUDGET_INFO[budget.label].adornment
                ? {
                    startAdornment: (
                      <InputAdornment position="start">
                        {BUDGET_INFO[budget.label].adornment}
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
            variant="outlined"
            type="number"
            name="amount"
            fullWidth
            value={budgetValue}
            margin="none"
            onChange={event =>
              handleValueChange({
                label: budget.label,
                value: event.target.value,
              })
            }
            onBlur={event =>
              handleValueComplete({
                label: budget.label,
                value: event.target.value,
              })
            }
            onClick={() => {
              handleBudgetFieldClick(budget);
            }}
            onFocus={() => {
              handleBudgetFieldFocus(budget);
            }}
          />
          {!!BUDGET_INFO[budget.label].helperText && (
            <div style={{ marginLeft: 8, marginRight: 8 }}>
              <Typography variant="caption">
                {BUDGET_INFO[budget.label].helperText}
              </Typography>
            </div>
          )}
        </Grid>
      );
    });
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose(event, reason);
          }
        }}
        disableEscapeKeyDown
      >
        <DialogTitle disableTypography>
          <Grid container alignItems="center">
            <EqualizerIcon />
            <Typography variant="h6">Budgets</Typography>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            {renderListOfBudgets()}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button
            onClick={onDoneClick}
            variant="contained"
            color="primary"
            autoFocus
          >
            Done
          </Button>
        </DialogActions>
        {getBudgetInfoLoading && <LoadingCover />}
      </Dialog>
      {showLaborBudgetDialog.open && (
        <LaborBudgetDialog
          open
          projectId={projectId}
          userIdsOnProject={userIdsOnProject}
          activeUserIds={activeUserIds}
          budgetInfo={budgetInfo}
          handleClose={handleLaborBudgetDialogClose}
          handleDone={handleLaborBudgetDialogDone}
        />
      )}
    </>
  );
};

export default compose(GetBudgetInfoAction)(BudgetsDialog);
