import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/styles';
import BackgroundImage from '../../assets/images/background.jpg';
import palette from '../../theme/palette';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
    minWidth: 100,
  },
  title: {
    color: palette.brandColorDarkGrey,
    marginBottom: 16,
    fontSize: 32,
  },
  description: {
    color: palette.brandColorDarkGrey,
    margin: '0 auto 24px auto',
    maxWidth: 420,
    marginTop: 24,
  },
  container: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    zIndex: 1,
  },
  onboardingContainer: {
    zIndex: 2,
    paddingTop: isMobile ? 24 : 32,
    paddingBottom: isMobile ? 24 : 32,
    paddingLeft: 24,
    paddingRight: 24,
    maxWidth: 560,
    minHeight: isMobile ? '100dvh' : 'auto',
  },
  stepContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 auto',
    minHeight: isMobile ? 0 : 650,
  },
  levelLogo: {
    width: 400,
    marginBottom: 40,
    paddingLeft: 30,
  },
  smallLevelLogo: {
    width: 180,
    marginBottom: 12,
  },
  profileIcon: {
    height: 80,
    width: 80,
    color: palette.brandColorPrimary,
  },
  profilePic: {
    height: 130,
    width: 130,
  },
  companyLogoIcon: {
    height: 80,
    width: 80,
    color: palette.brandColorPrimary,
  },
  companyLogo: {
    height: 140,
  },
  fieldsContainer: {
    paddingTop: 16,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    maxWidth: 350,
    flexGrow: 3,
  },
  usernameStatus: {
    padding: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    color: '#fff',
    background: '#555555',
    opacity: 0.5,
    zIndex: 9999,
  },
  billingAddressField: {
    width: '100%',
    marginBottom: 8,
    paddingRight: 8,
  },
  basicInputLabel: {
    textAlign: 'left',
    fontSize: 18,
    color: '#666',
  },
}));

export { useStyles };
