import gql from 'graphql-tag';

export default gql`
  fragment BookkeepingRuleFields on BookkeepingRule {
    id
    companyId
    alertIdentifier
    alertIdentifierSub
    enabled
  }
`;
