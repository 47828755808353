import React from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ContentGallery from '../ProjectContentListing/ContentTypes/contentGallery';
import LoadingCover from '../LoadingCover/loadingCover';
import SplitFinancialItemList from '../split-financial-item-list';
import styles from './split-financial-item.styles';

const useStyles = makeStyles(styles);

const SplitFinancialForm = ({
  contentInfo,
  projectList,
  handleFieldChange,
  handleProjectDelete,
  handleSave,
  onClose,
  handleAddAnotherProject,
  totalAmount,
  isLoading,
  invalidAmountMap,
  setInvalidAmountMap,
}) => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 8,
      }}
    >
      <Grid container justifyContent="center">
        <Grid item>
          <Typography variant="h3" gutterBottom align="center" color="primary">
            Split across projects
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.mainContainer}>
        <Grid item xs={6} style={{ zIndex: 2 }}>
          {contentInfo && (
            <ContentGallery
              contentPiece={contentInfo}
              glassMagnifier
              showCopyUrl
              compactView
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.rightWrapper}>
          <SplitFinancialItemList
            itemList={projectList}
            onAddButtonClick={handleAddAnotherProject}
            onDeleteButtonClick={handleProjectDelete}
            onFieldChange={handleFieldChange}
            totalAmount={totalAmount}
            autoFocus
            invalidAmountMap={invalidAmountMap}
            setInvalidAmountMap={newInvalidAmountMap => {
              setInvalidAmountMap(newInvalidAmountMap);
            }}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.footer}>
        <Grid container justifyContent="flex-end" style={{ flex: 1 }}>
          <Button
            onClick={onClose}
            variant="contained"
            style={{ marginRight: 16 }}
          >
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </Grid>
      </Grid>
      {isLoading && (
        <LoadingCover>
          <Typography variant="h3" align="center">
            Splitting...
          </Typography>
        </LoadingCover>
      )}
    </div>
  );
};

export default SplitFinancialForm;
