import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo-hooks';
import { makeStyles } from '@material-ui/styles';
import LockableButton from '../lockable-button/lockable-button';
import ServicesRequiredDialog from '../services-required';
import GetConnectToQbUri from '../../graphql/queries/get-connect-to-qb-uri';

import QboGreenButtonTallDefaultImage from '../../assets/images/quickbooks/C2QB_green_btn_tall_default.svg';
import QboGreenButtonTallHoverImage from '../../assets/images/quickbooks/C2QB_green_btn_tall_hover.svg';

const useStyles = makeStyles(() => ({
  connectToQuickbooksWrapper: {
    padding: 10,
    '& .connect-hover': {
      display: 'none',
    },
    '&:hover .connect-hover': {
      display: 'initial',
    },
    '&:hover .connect-default': {
      display: 'none',
    },
  },
}));

const ConnectToQuickBooks = ({
  managingCompanyInfo,
  locked,
  redirectTo,
  customCssId,
  customButtonStyles = {},
}) => {
  const classes = useStyles();

  const [getConnectToQbUri, setGetConnectToQbUri] = useState(false);
  const [showServicesRequired, setShowServicesRequired] = useState(false);

  const { data: connectToQbData, loading: connectToQbUriLoading } = useQuery(
    GetConnectToQbUri,
    {
      skip: !getConnectToQbUri,
      variables: {
        companyId: managingCompanyInfo && managingCompanyInfo.managingCompanyId,
        redirectTo: redirectTo
          ? `${window.location.origin}${redirectTo}`
          : window.location.href,
      },
      fetchPolicy: 'network-only',
    }
  );

  if (connectToQbData) {
    const { getConnectToQbUri: qbAuthUri } = connectToQbData;
    window.location.href = qbAuthUri;
  }

  const handleQuickBooksConnection = () => {
    if (locked) {
      setShowServicesRequired(true);
    } else {
      setGetConnectToQbUri(true);
    }
  };

  return (
    <>
      <div className={classes.connectToQuickbooksWrapper} id={customCssId}>
        <LockableButton
          loading={connectToQbUriLoading}
          locked={locked}
          onClick={handleQuickBooksConnection}
          style={{ padding: 0, margin: 0 }}
        >
          <img
            className="connect-default"
            src={QboGreenButtonTallDefaultImage}
            alt="Connect to QuickBooks"
            style={customButtonStyles}
          />
          <img
            className="connect-hover"
            src={QboGreenButtonTallHoverImage}
            alt="Connect to QuickBooks"
            style={customButtonStyles}
          />
        </LockableButton>
      </div>
      {showServicesRequired && (
        <ServicesRequiredDialog
          handleClose={() => setShowServicesRequired(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(ConnectToQuickBooks);
