import React, { useMemo } from 'react';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import copyToClipboard from 'copy-to-clipboard';

import { monetaryRender } from '../../../helpers';

import {
  RFI_STATUSES,
  RFI_STATUSES_INTERNAL_FILTERS,
  RFI_STATUS_COLORS,
} from '../../../config/appDefaults';
import palette from '../../../theme/palette';

const useStyles = makeStyles(theme => ({
  statModalLabel: {
    textTransform: 'uppercase',
  },
  statSectionWrapper: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  statSectionHeader: {
    color: theme.palette.brandColorPrimary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    paddingBottom: 5,
  },
  statText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    paddingTop: 5,
    paddingBottom: 5,
  },
}));

const RequestsForInfoStatsDialog = ({ open, currentData, onClose }) => {
  const classes = useStyles();

  const stats = useMemo(() => {
    const statsToShow = {};
    RFI_STATUSES_INTERNAL_FILTERS.forEach(status => {
      statsToShow[status.value] = { count: 0, inflow: 0, outflow: 0 };
    });

    if (currentData) {
      _.forEach(currentData, rfiData => {
        if (rfiData.requestStatus && statsToShow[rfiData.requestStatus]) {
          statsToShow[rfiData.requestStatus].count += 1;
          if (rfiData.txnReceived) {
            statsToShow[rfiData.requestStatus].inflow +=
              rfiData.txnReceived.value || 0;
          } else if (rfiData.txnSpent) {
            statsToShow[rfiData.requestStatus].outflow +=
              rfiData.txnSpent.value || 0;
          }
        }
      });
    }

    return statsToShow;
  }, [currentData]);

  if (!currentData) {
    // Weird case, ignore
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="data stats"
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <div className={classes.statSectionWrapper}>
          {_.map(stats, ({ count, inflow, outflow }, statKey) => {
            const rfiStatus = _.find(RFI_STATUSES, { value: statKey });

            const inflowAsMoney = monetaryRender({
              value: inflow,
              withDecimals: true,
            });
            const outflowAsMoney = monetaryRender({
              value: outflow,
              withDecimals: true,
            });

            return (
              <div className={classes.statSectionWrapper} key={statKey}>
                <Typography
                  variant="h4"
                  className={classes.statSectionHeader}
                  style={{
                    color:
                      statKey !== RFI_STATUSES.IN_DRAFT.value
                        ? RFI_STATUS_COLORS[statKey].background
                        : palette.brandColorDarkGrey,
                  }}
                >
                  {rfiStatus.label}
                </Typography>
                <Typography variant="h6" className={classes.statText}>
                  <span className={classes.statModalLabel}>Number of RFIs</span>
                  <Tooltip title="Click to copy">
                    <Button
                      onClick={() => {
                        copyToClipboard(count);
                      }}
                    >
                      {count}
                    </Button>
                  </Tooltip>
                </Typography>
                <Typography variant="h6" className={classes.statText}>
                  <span className={classes.statModalLabel}>
                    Cash Outflow Value of RFIs
                  </span>
                  <Tooltip title="Click to copy">
                    <Button
                      onClick={() => {
                        copyToClipboard(outflowAsMoney);
                      }}
                    >
                      {outflowAsMoney}
                    </Button>
                  </Tooltip>
                </Typography>
                <Typography variant="h6" className={classes.statText}>
                  <span className={classes.statModalLabel}>
                    Cash Inflow Value of RFIs
                  </span>
                  <Tooltip title="Click to copy">
                    <Button
                      onClick={() => {
                        copyToClipboard(inflowAsMoney);
                      }}
                    >
                      {inflowAsMoney}
                    </Button>
                  </Tooltip>
                </Typography>
              </div>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RequestsForInfoStatsDialog;
