export default theme => {
  const heightOfFooter = 68;
  return {
    mainContainer: {
      minHeight: '70vh',
      padding: theme.spacing(2),
      paddingTop: 0,
      paddingBottom: heightOfFooter,
    },
    rightWrapper: {
      padding: 0,
      paddingLeft: theme.spacing(2),
    },
    footer: {
      height: heightOfFooter,
      background: '#ececec',
      padding: theme.spacing(2),
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 3,
    },
  };
};
