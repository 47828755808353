import './level-number-input.scss';

import React, { useState, useEffect } from 'react';
import { ButtonBase, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  AddCircleOutline as AddCircleOutlineIcon,
} from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';

import palette from '../../../theme/palette';

const useStyles = makeStyles({
  containedButton: {
    width: 28,
    backgroundColor: palette.brandColorPrimary,
    '&:hover': {
      backgroundColor: 'rgba(8, 65, 92, 1)',
    },
  },
  containedButtonText: {
    color: palette.white,
    fontSize: 18,
    marginTop: -2,
  },
});

const CONTAINED_HEIGHT = 30;

const LevelNumberInput = ({
  value,
  onChange,
  inlineLabel,
  disabled,
  min,
  contained = false,
}) => {
  const classes = useStyles();

  const [counterValue, setCounterValue] = useState(value || 0);

  useEffect(() => {
    setCounterValue(value);
  }, [value]);

  const handleChange = event => {
    let newVal = parseInt(event.target.value, 10);

    if (Number.isNaN(newVal)) {
      newVal = 0;
    }

    const valToSet = typeof min === 'number' && newVal < min ? min : newVal;
    onChange(valToSet);
    setCounterValue(valToSet);
  };

  const handleIncrement = () => {
    const newVal = parseInt(counterValue, 10) + 1;
    handleChange({ target: { value: newVal } });
  };

  const handleDecrement = () => {
    const newVal = parseInt(counterValue, 10) - 1;
    handleChange({ target: { value: newVal } });
  };

  if (contained) {
    return (
      <Grid
        container
        style={{
          width: 96,
          backgroundColor: palette.brandColorPrimary,
          height: CONTAINED_HEIGHT,
          borderRadius: 4,
          border: '1px solid',
          borderColor: palette.brandColorPrimary,
        }}
      >
        <ButtonBase
          onClick={handleDecrement}
          className={classes.containedButton}
          disabled={disabled}
        >
          <Grid container item alignItems="center" justifyContent="center">
            <Typography variant="body1" className={classes.containedButtonText}>
              -
            </Typography>
          </Grid>
        </ButtonBase>
        <Grid
          container
          item
          style={{ flex: 1, backgroundColor: palette.white }}
          justifyContent="center"
          alignItems="center"
        >
          <TextField
            className="level-number-input-contained"
            value={counterValue}
            onChange={handleChange}
            type="number"
            inputProps={{
              style: {
                textAlign: 'center',
                height: CONTAINED_HEIGHT - 2,
                padding: 0,
                color: palette.brandColorPrimary,
                fontSize: 16,
              },
            }}
            disabled={disabled}
          />
        </Grid>
        <ButtonBase
          onClick={handleIncrement}
          className={classes.containedButton}
          disabled={disabled}
        >
          <Grid container item alignItems="center" justifyContent="center">
            <Typography variant="body1" className={classes.containedButtonText}>
              +
            </Typography>
          </Grid>
        </ButtonBase>
      </Grid>
    );
  }

  return (
    <Grid container alignItems="center" style={{ width: '100%' }}>
      {!!inlineLabel && (
        <Grid item style={{ flex: 0, marginRight: 8 }}>
          {inlineLabel.replace(/ /g, '\u00a0')}
        </Grid>
      )}
      <Grid item style={{ flex: 1, position: 'relative' }}>
        <TextField
          value={counterValue}
          onChange={handleChange}
          type="number"
          style={{ width: '100%' }}
          inputProps={{
            style: {
              textAlign: 'center',
              flex: 1,
              paddingLeft: 36,
              paddingRight: 36,
              paddingTop: 8,
            },
          }}
          disabled={disabled}
        />
        <IconButton
          onClick={handleDecrement}
          style={{
            padding: 6,
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
        <IconButton
          onClick={handleIncrement}
          style={{
            padding: 6,
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
          }}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default LevelNumberInput;
