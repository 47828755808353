import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  ButtonBase,
  IconButton,
  Grid,
  Typography,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';

import moment from 'moment';
import numeral from 'numeral';

import DialogTitle from '../../components/dialog-title/dialog-title';
import FullScreenGallery from '../../components/full-screen-gallery/full-screen-gallery';
import LevelPellEditor from '../add-to-project/level-pell-editor/level-pell-editor';
import {
  MAX_CHARACTERS_IN_JRN_TITLE_FIELD,
  MAX_CHARACTERS_IN_DESCRIPTION_FIELD,
} from '../../config/appDefaults';

import { getToJpg } from '../../helpers';
import palette from '../../theme/palette';

const useStyles = makeStyles(theme => ({
  formInputWrapper: {
    marginTop: 80,
    padding: theme.spacing(2),
  },
  formFieldWrapper: {
    marginBottom: theme.spacing(2),
  },
  titleButtonWrapper: {
    position: 'absolute',
    top: 0,
    padding: theme.spacing(2),
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#eee',
  },
  saveButton: {
    marginLeft: theme.spacing(2),
  },
  itemDataLabel: {
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  supportingItemThumbWrapper: {
    position: 'relative',
    height: 320,
    width: 480,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
    overflow: 'hidden',
  },
  supportingItemThumb: {
    height: '100%',
    maxHeight: 500,
    width: 'auto',
  },
}));

const ViewShoeboxItem = ({
  paperworkItem,
  onClose,
  handleCreateContent,
  handleUpdate,
}) => {
  let initialAmount = '';
  if (paperworkItem?.amount?.value) {
    if (typeof paperworkItem.amount.value === 'number') {
      initialAmount = numeral(paperworkItem.amount.value).format('0.00');
    } else {
      initialAmount = paperworkItem.amount.value;
    }
  }

  const [subject, setSubject] = useState(paperworkItem?.subject || '');
  const [amount, setAmount] = useState(initialAmount);

  const [notes, setNotes] = useState(
    paperworkItem && paperworkItem.notes ? paperworkItem.notes : ''
  );
  const [notesError, setNotesError] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const classes = useStyles({ descriptionError: notesError, subjectError });
  const onCreateContent = () => {
    handleCreateContent(paperworkItem);
  };

  const onUpdate = () => {
    const updatedItem = {
      ...paperworkItem,
      subject,
      notes,
    };

    if (amount !== initialAmount) {
      if (amount === '') {
        updatedItem.amount = null;
      } else {
        updatedItem.amount = { value: parseFloat(amount, 10) };
      }
    }

    if (notesError || subjectError) {
      return;
    }

    handleUpdate(updatedItem);
  };

  const [fullScreenGalleryInfo, setFullScreenGalleryInfo] = useState({
    open: false,
  });

  const handleOnlickThumbnail = () => {
    setFullScreenGalleryInfo({
      images: paperworkItem.media,
      open: true,
    });
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubjectChange = event => {
    const updatedSubject = event.target.value;
    if (updatedSubject.length > MAX_CHARACTERS_IN_JRN_TITLE_FIELD) {
      setSubjectError(true);
    } else {
      setSubjectError(false);
    }
    setSubject(updatedSubject);
  };

  const handleNotesChange = updatedNotes => {
    if (updatedNotes.length > MAX_CHARACTERS_IN_DESCRIPTION_FIELD) {
      setNotesError(true);
    } else {
      setNotesError(false);
    }
    setNotes(updatedNotes);
  };

  return (
    <>
      <div className={classes.titleButtonWrapper}>
        <Typography variant="h4">Shoebox Item Details</Typography>
        <div>
          <Button variant="contained" color="default" onClick={onCreateContent}>
            Assign to Project or Bill
          </Button>
          <Button
            className={classes.saveButton}
            variant="contained"
            color="primary"
            onClick={onUpdate}
            disabled={subjectError || notesError}
          >
            Save
          </Button>
          <IconButton onClick={handleClose} style={{ marginLeft: 24 }}>
            <CloseIcon style={{ fontSize: 28 }} />
          </IconButton>
        </div>
      </div>
      <Grid container>
        <Grid item xs={12} container className={classes.formInputWrapper}>
          <Grid
            item
            xs={12}
            container
            spacing={2}
            className={classes.formFieldWrapper}
          >
            <Grid item xs={12}>
              <Typography className={classes.itemDataLabel}>
                Date Received
              </Typography>
              <Typography variant="body1">
                {moment(paperworkItem.dateCreated).format(
                  'MMM D, YYYY @ h:mma'
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                className={classes.itemDataLabel}
                style={{ color: subjectError ? '#e00' : 'inherit' }}
              >
                Subject
              </Typography>
              <TextField
                placeholder="Subject..."
                value={subject || ''}
                onChange={handleSubjectChange}
                fullWidth
                error={subjectError}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                className={classes.itemDataLabel}
                style={{ color: subjectError ? '#e00' : 'inherit' }}
              >
                Total including tax ($)
              </Typography>
              <TextField
                placeholder=""
                type="number"
                value={amount}
                onBlur={() => {
                  if (amount !== '') {
                    const valueToSet = numeral(amount).format('0.00');
                    setAmount(valueToSet);
                  }
                }}
                onChange={event => {
                  setAmount(event.target.value);
                }}
                fullWidth
                error={subjectError}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.formFieldWrapper}>
            <Grid item xs={12}>
              <Typography
                className={classes.itemDataLabel}
                style={{ color: notesError ? '#e00' : 'inherit' }}
              >
                Notes
              </Typography>
              <div
                style={{
                  marginTop: 5,
                  border: `1px solid ${notesError ? '#e00' : '#ccc'}`,
                }}
              >
                <LevelPellEditor
                  defaultContent={notes}
                  onChange={htmlText => {
                    handleNotesChange(htmlText);
                  }}
                  containerClass="level-pell-content-addContentForm"
                />
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.formFieldWrapper}>
            <Typography className={classes.itemDataLabel}>
              Attachment
            </Typography>

            {paperworkItem.media ? (
              <div className={classes.supportingItemThumbWrapper}>
                <ButtonBase
                  className={classes.supportingItemThumbWrapper}
                  onClick={handleOnlickThumbnail}
                  style={{ backgroundColor: palette.background.alt }}
                >
                  <img
                    src={getToJpg(paperworkItem.media[0].uri)}
                    alt="thumb"
                    className={classes.supportingItemThumb}
                  />
                </ButtonBase>
              </div>
            ) : (
              <Typography variant="body1">No media attached</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      {fullScreenGalleryInfo.open && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open
          onClose={() => setFullScreenGalleryInfo({ open: false })}
        >
          <DialogTitle
            onClose={() => setFullScreenGalleryInfo({ open: false })}
          >
            Supporting Paperwork
          </DialogTitle>
          <DialogContent>
            <FullScreenGallery
              images={fullScreenGalleryInfo.images}
              selectedItem={fullScreenGalleryInfo.selectedItem}
              showThumbs={false}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ViewShoeboxItem;
