import gql from 'graphql-tag';

export default gql`
  query GetBookkeepingAlertPreferences($companyId: String!) {
    getBookkeepingAlertPreferences(input: { companyId: $companyId }) {
      id
      companyId
      enabled
      timeZone
      timeOfDay
      daysAfterPeriodBookCompleted
      frequencyOfBookkeepingTasks
      qbFiscalYearStartMonth
    }
  }
`;
