import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

import ButtonWithTooltip from '../button-with-tooltip/button-with-tooltip';

const useStyles = makeStyles(() => ({
  iconButton: {
    backgroundColor: 'rgba(0,0,0,0.05)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.09)',
    },
  },
}));

const AdminToolsIconButton = ({
  tooltipText,
  disabled,
  onClick,
  style,
  children,
  transparentBackground = false,
  isLoading,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <ButtonWithTooltip
      iconButton
      color="default"
      tooltipText={tooltipText}
      onClick={onClick}
      disabled={disabled}
      className={!transparentBackground && !disabled ? classes.iconButton : ''}
      style={
        transparentBackground
          ? { ...style }
          : {
              border: disabled ? '1px solid #ddd' : '1px solid transparent',
              ...style,
            }
      }
      {...otherProps}
    >
      {isLoading ? (
        <CircularProgress color="inherit" size={22} style={{ marginLeft: 0 }} />
      ) : (
        children
      )}
    </ButtonWithTooltip>
  );
};

export default AdminToolsIconButton;
