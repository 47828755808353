import React from 'react';
import { connect } from 'react-redux';
import { Grid, Dialog, Button } from '@material-ui/core';
import ContentItem from '../../components/ProjectContentListing/contentItem';

const DeepLinkModal = props => {
  const {
    modalContentInfo,
    showContentModal,
    setShowContentModal,
    userInfo,
    allUsers,
  } = props;
  const projectCanEdit = false;
  const projectCanAdd = false;
  return (
    <Dialog fullWidth maxWidth="lg" open={showContentModal}>
      <Grid container>
        <Grid item xs={12} style={{ padding: '15px' }}>
          <ContentItem
            contentItem={modalContentInfo}
            projectCanEdit={projectCanEdit}
            projectCanAdd={projectCanAdd}
            userId={userInfo.userId}
            allUsers={allUsers}
            fromDeepLink
          />
          <Button
            onClick={() => {
              setShowContentModal(false);
            }}
            style={{ margin: '5px', float: 'right' }}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
  };
}

export default connect(mapStateToProps)(DeepLinkModal);
