import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import cloudinary from 'cloudinary-core';
import { Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import ContentGallery from '../ProjectContentListing/ContentTypes/contentGallery';
import ContentVideo from '../ProjectContentListing/ContentTypes/contentVideo';
import { publicIdFromUrl } from '../../helpers/cloudinary';
import { cloudindaryCloudName, brokenImageUrl } from '../../config/appDefaults';

const useStyles = makeStyles({
  cancelButton: {
    fontSize: '40px',
    justifyContent: 'flex-end',
  },
  video: {
    height: '100%',
    maxHeight: '70vh',
    minHeight: '30vh',
    outline: 0,
    maxWidth: '100%',
  },
});

function TaskDisplayDialog(props) {
  const classes = useStyles();
  const { onCancel, contentPiece, open } = props;

  let mediaInfo = _.get(contentPiece, 'completionInfo.mediaInfo', []);

  const cleanImageObjects = [];
  if (mediaInfo && mediaInfo.length) {
    mediaInfo = mediaInfo.forEach(image => {
      if (image && image.uri) {
        cleanImageObjects.push(image);
      }
    });
  }

  const cl = new cloudinary.Cloudinary({
    cloud_name: cloudindaryCloudName,
    secure: true,
  });
  const imageTranformWidth = parseInt(window.innerWidth, 10) || 500;
  const imageTranformHeight = Math.round(window.innerHeight * 0.75);

  const getPreviewImageUrl = imageInfo => {
    if (imageInfo) {
      if (_.includes(imageInfo, 'cloudinary')) {
        return cl.url(publicIdFromUrl(imageInfo), {
          quality: 'auto',
          width: imageTranformWidth,
          height: imageTranformHeight,
          crop: 'limit',
          fetchFormat: 'jpg',
        });
      }
      return imageInfo;
    }
    return { uri: brokenImageUrl };
  };
  const pdfUrl = imageInfo => {
    if (_.includes(imageInfo, 'cloudinary')) {
      return cl.url(publicIdFromUrl(imageInfo), {
        quality: 'auto',
        width: imageTranformWidth,
        crop: 'scale',
        fetchFormat: 'pdf',
      });
    }
    return imageInfo;
  };

  return (
    <Dialog
      onClose={onCancel}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="md"

      // fullScreen
    >
      <Button onClick={onCancel} className={classes.cancelButton}>
        <CancelOutlinedIcon fontSize="inherit" color="primary" />
      </Button>
      <Grid
        container
        style={{ padding: '20px', maxheight: '600px', textAlign: 'center' }}
      >
        <Grid item xs={12}>
          {contentPiece.completionType === 'video' && (
            <ContentVideo
              contentPiece={{
                contentUrl: JSON.stringify(
                  contentPiece.completionInfo.mediaInfo,
                  null,
                  2
                ),
              }}
            />
          )}
          {contentPiece.completionType === 'image' && (
            <>
              <ContentGallery
                noMaxHeight
                // hideDownload
                contentPiece={{
                  contentUrl: JSON.stringify(
                    contentPiece.completionInfo.mediaInfo,
                    null,
                    2
                  ),
                }}
              />
              <div>{contentPiece.completionInfo.textResponse}</div>
            </>
          )}
          {contentPiece.completionType === 'pdf' && (
            <>
              <a
                href={pdfUrl(contentPiece.completionInfo.mediaInfo[0].uri)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt=""
                  src={getPreviewImageUrl(
                    contentPiece.completionInfo.mediaInfo[0].uri
                  )}
                  className={classes.image}
                />
              </a>

              <div>{contentPiece.completionInfo.textResponse}</div>
            </>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default TaskDisplayDialog;
