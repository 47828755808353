import React, { useState, useReducer } from 'react';
import { compose, withApollo } from 'react-apollo';
import uuid from 'uuid';
import _ from 'lodash';

// UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { ButtonBase, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import moment from 'moment';

import LoadingCover from '../../../components/LoadingCover/loadingCover';
import AnswersDialog from '../../add-to-project/answers-dialog/answers-dialog';

// GraphQL
import GetQuestionsByProjectId from '../../../graphql/queries/GetQuestionsByProjectId';
import {
  CreateContentMutationAction,
  UpdateContentMutationAction,
} from '../../../graphql/graphql';

import { twoDatesWithinInterval } from '../../../helpers/index';
import palette from '../../../theme/palette';

const useStyles = makeStyles(theme => ({
  formLine: {
    marginBottom: theme.spacing(4),
    minHeight: 70,
  },
  labelBasics: {
    display: 'flex',
    marginBottom: theme.spacing(0.5),
  },
  actionsButtonsWrapper: {
    padding: theme.spacing(1),
  },
  baseInfoWrapper: {
    border: '1px solid #eee',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: palette.background.alt,
    borderRadius: 5,
  },
  discrepancyText: {
    color: theme.palette.brandColorOrange,
  },
}));

const AddEditTimetrackingForm = ({
  checkInContent,
  checkOutContent,
  projectToAddTo,
  assigneeInfo,
  onClose,
  onUpdateContent,
  onAddContent,
  userInfo,
  client,
  includeArchive,
}) => {
  const classes = useStyles();
  const figureOutDates = inOrOut => {
    const checkInDate = _.get(checkInContent, 'date');
    const checkOutDate = _.get(checkOutContent, 'date');

    // both dates
    if (checkInDate && checkOutDate) {
      return inOrOut === 'in' ? moment(checkInDate) : moment(checkOutDate);
    }

    // just one date
    if (checkInDate) {
      return inOrOut === 'in' ? moment(checkInDate) : null;
    }
    if (checkOutDate) {
      return inOrOut === 'out' ? moment(checkOutDate) : null;
    }

    // no dates
    return null;
  };
  const [checkInDate, setCheckInDate] = useState(figureOutDates('in'));
  const [checkOutDate, setCheckOutDate] = useState(figureOutDates('out'));
  const [errorMessages, setErrorMessages] = useState({});
  const [savingOverlay, setSavingOverlay] = useState(false);

  const [loadingQuestions, setLoadingQuestions] = useState({
    loading: false,
    inOrOut: null,
  });
  const [timetrackingAnswers, setTimetrackingAnswers] = useState(
    checkInContent && checkInContent.timetrackingAnswers
      ? checkInContent.timetrackingAnswers
      : null
  );
  const [
    timetrackingAnswersCheckout,
    setTimetrackingAnswersCheckout,
  ] = useState(
    checkOutContent && checkOutContent.timetrackingAnswers
      ? checkOutContent.timetrackingAnswers
      : null
  );
  const [editAnswersDialog, setEditAnswersDialog] = useState({
    open: false,
  });

  const [, forceUpdate] = useReducer(x => x + 1, 0); // used to force a re-render
  const handleADateChange = (value, forInOrOut) => {
    if (forInOrOut === 'in') {
      const thereWasNoCheckInDate = !checkInDate;
      setCheckInDate(value);
      // if there is currently no check in date, set the checkin to 9am and the clock out to 5pm
      if (thereWasNoCheckInDate) {
        const newCheckInDate = moment(value)
          .hour(9)
          .minute(0);
        setCheckInDate(newCheckInDate);
        setCheckOutDate(
          moment(value)
            .hour(17)
            .minute(0)
        );
      }
    } else {
      // setCheckOutDate(value);
      // /////////////////
      const thereWasNoCheckOutDate = !checkOutDate;
      setCheckOutDate(value);
      // if there is currently no check out date, set the checkin to 9am and the clock out to 5pm
      if (thereWasNoCheckOutDate) {
        const newCheckOutDate = moment(value)
          .hour(17)
          .minute(0);
        setCheckOutDate(newCheckOutDate);
        setCheckInDate(
          moment(value)
            .hour(9)
            .minute(0)
        );
      }
    }
    forceUpdate(); // even after updating the state, the date picker wasn't updating, so this forces it to update
  };

  const getProjectQuestions = async () => {
    let questionsResponse;
    const projectId = projectToAddTo.contentId;

    try {
      questionsResponse = await client.query({
        query: GetQuestionsByProjectId,
        variables: { projectId },
        fetchPolicy: 'no-cache',
      });
      const returnedQuestions = _.get(
        questionsResponse,
        'data.getQuestionsByProjectId.items'
      );
      const whatToSetReturn = returnedQuestions || [];
      return whatToSetReturn;
      // then find the piece of content needing to be edited
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('getProjectQuestions query e: ', e);
      return null;
    }
  };

  const openAnswersDialog = async inOrOut => {
    setLoadingQuestions({ loading: true, inOrOut });
    const allQuestions = await getProjectQuestions();
    const allQuestionsMap = _.keyBy(allQuestions, 'questionId');
    setLoadingQuestions({ loading: false, inOrOut: null });

    const questionIds =
      (inOrOut === 'in'
        ? projectToAddTo.timetrackingQuestions
        : projectToAddTo.timetrackingQuestionsCheckout) || [];

    const questions = [];
    _.forEach(questionIds, questionId => {
      questions.push(allQuestionsMap[questionId]);
    });

    setEditAnswersDialog({
      open: true,
      title: inOrOut === 'in' ? 'Clock-in Questions' : 'Clock-out Questions',
      questions,
      existingAnswers:
        inOrOut === 'in' ? timetrackingAnswers : timetrackingAnswersCheckout,
      inOrOut,
    });
  };

  const renderEditTimetrackingPiece = (item, inOrOut) => {
    const isCheckIn = inOrOut === 'in';

    let discrepancyInDate = false;
    let discrepancyInTime = false;
    if (item) {
      discrepancyInDate = !moment(item.date).isSame(item.dateCreated, 'day');

      discrepancyInTime = !twoDatesWithinInterval({
        date1: item.date,
        date2: item.dateCreated,
        intervalAmount: 60,
        intervalType: 'seconds',
      });
    }

    const datepickerAttr = {
      name: isCheckIn ? 'checkInDate' : 'checkOutDate',
      value: isCheckIn ? checkInDate : checkOutDate,
      helperText: isCheckIn
        ? errorMessages.checkInDate
        : errorMessages.checkOutDate,
      error: isCheckIn
        ? !!errorMessages.checkInDate
        : !!errorMessages.checkOutDate,
      onChange: value => {
        handleADateChange(value, isCheckIn ? 'in' : 'out');
      },
    };
    return (
      <>
        <Grid item xs={12} className={classes.formLine}>
          <InputLabel className={classes.labelBasics}>
            Clock-{isCheckIn ? 'in' : 'out'} Date:
          </InputLabel>
          <DatePicker
            name={datepickerAttr.name}
            value={datepickerAttr.value}
            helperText={datepickerAttr.helperText}
            error={datepickerAttr.error}
            onChange={datepickerAttr.onChange}
            variant="inline"
            format="dddd MMMM D, YYYY"
            fullWidth
            autoOk
            minutesStep={5}
          />
          {discrepancyInDate && (
            <Typography variant="caption" className={classes.discrepancyText}>
              Clock-{isCheckIn ? 'in' : 'out'} created on:{' '}
              {moment(item.dateCreated).format('dddd, MMMM D, YYYY')}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} className={classes.formLine}>
          <InputLabel className={classes.labelBasics}>
            Clock-{isCheckIn ? 'in' : 'out'} Time:
          </InputLabel>
          <TimePicker
            name={datepickerAttr.name}
            value={datepickerAttr.value}
            helperText={datepickerAttr.helperText}
            error={datepickerAttr.error}
            onChange={datepickerAttr.onChange}
            variant="inline"
            fullWidth
            format="h:mma"
            minutesStep={5}
          />
          {((!isCheckIn && checkOutDate) || (isCheckIn && checkInDate)) && (
            <Grid container alignItems="center" style={{ marginTop: 4 }}>
              <Grid
                item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: '2px dashed #ccc',
                  borderRadius: 10,
                  marginRight: 8,
                }}
              >
                <ButtonBase
                  onClick={() => {
                    if (isCheckIn) {
                      handleADateChange(checkInDate.subtract(1, 'hours'), 'in');
                    } else {
                      handleADateChange(
                        checkOutDate.subtract(1, 'hours'),
                        'out'
                      );
                    }
                  }}
                  style={{ padding: '8px 12px' }}
                >
                  -
                </ButtonBase>
                <Typography>1h</Typography>
                <ButtonBase
                  onClick={() => {
                    if (isCheckIn) {
                      handleADateChange(checkInDate.add(1, 'hours'), 'in');
                    } else {
                      handleADateChange(checkOutDate.add(1, 'hours'), 'out');
                    }
                  }}
                  style={{ padding: '8px 12px' }}
                >
                  +
                </ButtonBase>
              </Grid>

              <Grid
                item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: '2px dashed #ccc',
                  borderRadius: 10,
                }}
              >
                <ButtonBase
                  onClick={() => {
                    if (isCheckIn) {
                      handleADateChange(
                        checkInDate.subtract(5, 'minutes'),
                        'in'
                      );
                    } else {
                      handleADateChange(
                        checkOutDate.subtract(5, 'minutes'),
                        'out'
                      );
                    }
                  }}
                  style={{ padding: '8px 12px' }}
                >
                  -
                </ButtonBase>
                <Typography>5m</Typography>
                <ButtonBase
                  onClick={() => {
                    if (isCheckIn) {
                      handleADateChange(checkInDate.add(5, 'minutes'), 'in');
                    } else {
                      handleADateChange(checkOutDate.add(5, 'minutes'), 'out');
                    }
                  }}
                  style={{ padding: '8px 12px' }}
                >
                  +
                </ButtonBase>
              </Grid>
            </Grid>
          )}
          {discrepancyInTime && (
            <Typography variant="caption" className={classes.discrepancyText}>
              Clock-{isCheckIn ? 'in' : 'out'} created at:{' '}
              {moment(item.dateCreated).format('h:mma')}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            disabled={loadingQuestions.loading}
            onClick={() => {
              openAnswersDialog(isCheckIn ? 'in' : 'out');
            }}
            style={{ marginTop: 3 }}
          >
            {loadingQuestions.inOrOut === inOrOut &&
              loadingQuestions.loading && (
                <CircularProgress size={16} style={{ marginRight: 8 }} />
              )}
            {isCheckIn ? 'Edit Clock-in Answers' : 'Edit Clock-out Answers'}
          </Button>
        </Grid>
      </>
    );
  };

  const handleCancel = () => {
    onClose();
  };

  const doValidation = () => {
    const errors = {};
    if (!checkInDate && !checkOutDate) {
      errors.checkInDate = 'Clock-in or clock-out must be filled in';
      errors.checkOutDate = 'Clock-in or clock-out must be filled in';
    }
    if (checkInDate && checkOutDate) {
      if (checkInDate.isAfter(checkOutDate)) {
        errors.checkOutDate = 'Clock-out has to be after the clock-in';
      }
    }
    setErrorMessages(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    }
    return false;
  };

  const handleSave = async () => {
    if (!doValidation()) {
      return;
    }
    setSavingOverlay(true);

    const fillOutObject = inOrOut => {
      const toReturn = {
        type: 'timetracking',
        contentId: uuid(),
        jrnId: projectToAddTo.contentId,
        creatorId: userInfo.userId,
        assignedTo: assigneeInfo.userId,
        contentStatus: inOrOut === 'in' ? 'checkin' : 'checkout',
        addedBy: 'webapp',
        timetrackingAnswers:
          inOrOut === 'in' ? timetrackingAnswers : timetrackingAnswersCheckout,
        date: null, // set below
        isPublic: false,
        synced: false,
        requireAdmin: false,
        useTimetracking: false,
        subtype: null,
        permissionsFrom: null,
        recurrence: null,

        // necessary since we ask for these fields in the GetAdminContentByType query
        completionType: null,
        completionInfo: null,
        contentUrl: null,
        description: null,
        aspectRatio: null,
        labels: null,
        latitude: null,
        longitude: null,
        title: null,
        endDate: null,
        startDate: null,
        priority: null,
      };

      return toReturn;
    };

    let checkInToUse;
    let checkOutToUse;
    let checkInWasMissing = false;
    let checkOutWasMissing = false;
    const doSaveFor = [];

    // if there is a clock-in date specified
    if (checkInDate) {
      // checkInContent is what was passed in as a prop - so a pre-existing piece of content
      if (checkInContent) {
        checkInToUse = { ...checkInContent, timetrackingAnswers };
      } else {
        checkInToUse = fillOutObject('in');
        checkInWasMissing = true;
      }

      checkInToUse.date = moment(checkInDate)
        .startOf('minute')
        .toISOString();
      doSaveFor.push({
        data: checkInToUse,
        addOrEdit: checkInWasMissing === true ? 'add' : 'edit',
      });
    }

    // if there is a check out date specified then they want to save it (even if it's just there from being loaded and nothing changed)
    if (checkOutDate) {
      // checkOutContent is what was passed in as a prop - so a pre-existing piece of content
      if (checkOutContent) {
        checkOutToUse = {
          ...checkOutContent,
          timetrackingAnswers: timetrackingAnswersCheckout,
        };
      } else {
        checkOutToUse = fillOutObject('out');
        checkOutWasMissing = true;
      }

      checkOutToUse.date = moment(checkOutDate)
        .startOf('minute')
        .toISOString();
      doSaveFor.push({
        data: checkOutToUse,
        addOrEdit: checkOutWasMissing === true ? 'add' : 'edit',
      });
    }

    const doTheSave = async saveThis => {
      const content = { ...saveThis.data };
      content.completionInfo = null; // null this so e dont get a graphql error when it's empty
      delete content.__typename;
      if (content.timetrackingAnswers) {
        content.timetrackingAnswers.forEach((answer, answerIndex) => {
          delete content.timetrackingAnswers[answerIndex].__typename;
        });
      }
      try {
        if (saveThis.addOrEdit === 'add') {
          await onAddContent(content, {
            fromWhichAdminTool: 'timetracking',
            user: assigneeInfo,
            includeArchive,
          });
        } else {
          await onUpdateContent(content, {
            user: assigneeInfo,
            includeArchive,
            skipOptimisticResponse: true,
          });
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(
          `err when trying to ${saveThis.addOrEdit} ${saveThis.data.contentStatus}: `,
          err
        );
      }
    };
    // ///////////////////////
    // do these in sequence, not parallel, since we need to make sure the add goes first or
    //  else it could end up in an activeSession that shouldn't be there if
    //  the check-out beats the check-in
    const starterPromise = Promise.resolve(null); // intentionally empty to kick things off
    await doSaveFor.reduce(
      (p, spec) => p.then(() => doTheSave(spec)),
      starterPromise
    );
    setSavingOverlay(false);
    onClose();
  };

  return (
    <Grid container spacing={6} className={classes.mainContainer}>
      <Grid
        item
        xs={12}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.baseInfoWrapper}
      >
        <Grid item>
          <Typography variant="h4" style={{ marginBottom: 8 }}>
            {projectToAddTo.title}
          </Typography>
        </Grid>

        <Grid item>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Tooltip title="User is not editable" placement="bottom">
                <Grid item container direction="row" alignItems="center">
                  <Avatar
                    alt={assigneeInfo.username}
                    src={assigneeInfo.profilePic}
                    style={{ marginRight: 8 }}
                  />
                  <Typography variant="body1">
                    {assigneeInfo.username}
                  </Typography>
                </Grid>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container item xs={6}>
          {renderEditTimetrackingPiece(checkInContent, 'in')}
        </Grid>
        <Grid container item xs={6}>
          {renderEditTimetrackingPiece(checkOutContent, 'out')}
        </Grid>
      </MuiPickersUtilsProvider>
      <Grid
        container
        justifyContent="flex-end"
        className={classes.actionsButtonsWrapper}
      >
        <Button
          onClick={handleCancel}
          color="primary"
          autoFocus
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          autoFocus
        >
          Save
        </Button>
      </Grid>
      {savingOverlay && (
        <LoadingCover loader="linear">
          <Typography variant="h3" align="center">
            Updating...
          </Typography>
        </LoadingCover>
      )}
      {editAnswersDialog.open && (
        <AnswersDialog
          open
          questions={editAnswersDialog.questions}
          existingAnswers={editAnswersDialog.existingAnswers}
          handleClose={() =>
            // just close the answer dialog since it was cancelled
            setEditAnswersDialog({
              ...editAnswersDialog,
              open: false,
            })
          }
          handleDone={updatedAnswers => {
            if (editAnswersDialog.inOrOut === 'in') {
              setTimetrackingAnswers(updatedAnswers);
            } else {
              setTimetrackingAnswersCheckout(updatedAnswers);
            }
            // then close the answer dialog
            setEditAnswersDialog({
              ...editAnswersDialog,
              open: false,
            });
          }}
        />
      )}
    </Grid>
  );
};

export default compose(
  CreateContentMutationAction,
  UpdateContentMutationAction,
  withApollo
)(AddEditTimetrackingForm);
