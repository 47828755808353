import gql from 'graphql-tag';

export default gql`
  query GetBookkeepingReport(
    $companyId: String!
    $reportDate: String
    $snapshotId: String
  ) {
    getBookkeepingReport(
      input: {
        companyId: $companyId
        reportDate: $reportDate
        snapshotId: $snapshotId
      }
    ) {
      snapshotId
      date
      dataJson
      hidden
    }
  }
`;
