import * as Yup from 'yup';
import { typesAndSchema } from '../info-about-content';

const buildYupSchema = ({ contentType }) => {
  const createYupSchema = (schema, config) => {
    const mutatedSchema = { ...schema };

    const {
      fieldName,
      validationType,
      validations = [],
      userEditable,
    } = config;

    if (!userEditable || !Yup[validationType]) {
      return schema;
    }

    let validator = Yup[validationType]();

    validations.forEach(validation => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });

    mutatedSchema[fieldName] = validator;

    return mutatedSchema;
  };

  const contentTypeSchema = typesAndSchema[contentType];

  const yupSchema = contentTypeSchema.reduce(createYupSchema, {});

  return Yup.object().shape(yupSchema);
};

export default buildYupSchema;
