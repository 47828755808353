import React, { useState, useEffect } from 'react';
import { Button, Grid, Link, Typography } from '@material-ui/core';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { useSnackbar } from 'notistack';
import ContentLoader from 'react-content-loader';

import _ from 'lodash';
import numeral from 'numeral';

import { useStyles } from '../../views/Onboarding/onboarding.styles';
import PricingOption from '../../views/Onboarding/pricing-option';

import GenerateCompanyCheckoutSession from '../../graphql/mutations/mutation_generate-company-checkout-session';
import GetStripeProductInfo from '../../graphql/queries/get-product-prices-by-type';
import LevelNumberInput from '../../views/add-to-project/level-number-input/level-number-input';
import LevelLogo from '../level-logo/level-logo';
import palette from '../../theme/palette';
import { getStripePricingDetails } from '../../helpers';

const SelectBookkeepingCopilotPrice = ({
  companyInfo,
  setLoading,
  companyBillingAddress,
  stepBack,
  hideLogo,
  customEndpoints,
  isFirm = false,
  defaultQuantity = 0,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [pricingOptions, setPricingOptions] = useState(null);
  const [yearlySavingsPercentage, setYearlySavingsPercentage] = useState(null);
  const [quarterlySavingsPercentage, setQuarterlySavingsPercentage] = useState(
    null
  );
  const [priceSelected, setPriceSelected] = useState(null);
  const [quantity, setQuantity] = useState(defaultQuantity);

  useEffect(() => {
    setQuantity(defaultQuantity);
  }, [defaultQuantity]);

  // run a query to get the pricing options, use - getProductPricesByType
  const {
    data: pricingQueryResponse,
    loading: pricingOptionsLoading,
  } = useQuery(GetStripeProductInfo, {
    variables: {
      productType: 'bookkeepingAlerts',
    },
  });
  // use lodash to get the items off the response data
  const pricingOptionsData = _.get(
    pricingQueryResponse,
    'getProductPricesByType.items',
    []
  );

  useEffect(() => {
    if (pricingOptionsData?.length > 0) {
      // figure out the yearly savings between the two pricing options
      const monthlyPlan = _.find(pricingOptionsData, {
        recurring: { interval: 'month', intervalCount: 1 },
      });
      const quarterlyPlan = _.find(pricingOptionsData, {
        recurring: { interval: 'month', intervalCount: 3 },
      });
      const annualPlan = _.find(pricingOptionsData, {
        recurring: { interval: 'year', intervalCount: 1 },
      });
      const yearlySavingsPercentageCalc =
        annualPlan && monthlyPlan
          ? numeral(
              ((monthlyPlan.unitAmount * 12 - annualPlan.unitAmount) /
                (monthlyPlan.unitAmount * 12)) *
                100
            ).format('0')
          : null;
      const quarterlySavingsPercentageCalc =
        quarterlyPlan && monthlyPlan
          ? numeral(
              ((monthlyPlan.unitAmount * 3 - quarterlyPlan.unitAmount) /
                (monthlyPlan.unitAmount * 3)) *
                100
            ).format('0')
          : null;

      const pricingOptionsInOrder = [
        // add each plan if they exist
        annualPlan || null,
        quarterlyPlan || null,
        monthlyPlan || null,
      ].filter(plan => plan !== null); // remove any nulls
      // set the monthly plan as the default selected price
      setPricingOptions(pricingOptionsInOrder);
      setYearlySavingsPercentage(yearlySavingsPercentageCalc);
      setQuarterlySavingsPercentage(quarterlySavingsPercentageCalc);
      setPriceSelected(annualPlan.id);
    }
  }, [pricingOptionsData]);

  const [generateCompanyCheckoutSession] = useMutation(
    GenerateCompanyCheckoutSession
  );

  const goToBilling = async () => {
    setLoading(true);

    // need to request a Stripe checkout session id from the server with the selected price
    // then redirect to the Stripe checkout page with the session id
    let checkoutSessionId;
    const { companyId } = companyInfo;
    try {
      const params = {
        companyId,
        price: priceSelected,
        billingAddress: companyBillingAddress,
        customEndpoints: {
          success: customEndpoints?.success || window.location.href,
          cancel: customEndpoints?.cancel || window.location.href,
        },
        count: quantity,
      };
      const checkoutSessionIdResponse = await generateCompanyCheckoutSession({
        variables: params,
      });
      if (checkoutSessionIdResponse && checkoutSessionIdResponse.data) {
        checkoutSessionId =
          checkoutSessionIdResponse.data.generateCompanyCheckoutSession
            .sessionId;
      }
    } catch (err) {
      enqueueSnackbar(
        'There was an error getting you to the checkout page. Please try again.',
        { variant: 'warning' }
      );
      // eslint-disable-next-line no-console
      console.error('Error while getting checkout session - abort', err);
      setLoading(false);
      return;
    }
    // once we have the checkout session id, redirect to the Stripe checkout page using that id
    // eslint-disable-next-line no-undef
    const stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY); // Stripe is on the window, loaded from index.html
    try {
      await stripe.redirectToCheckout({
        sessionId: checkoutSessionId,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error while redirecting to checkout page - abort', err);
      enqueueSnackbar(
        'There was an error getting you to the checkout page. Please try again.',
        { variant: 'warning' }
      );

      setLoading(false);
      return;
    }

    setLoading(false);
  };

  const selectedPricingInfo = _.find(pricingOptions, { id: priceSelected });
  let readableTermPrice = null;
  let term = null;
  if (selectedPricingInfo) {
    ({ readableTermPrice, term } = getStripePricingDetails({
      unitAmount: selectedPricingInfo?.unitAmount,
      recurring: selectedPricingInfo?.recurring,
      quantity,
    }));
  }

  return (
    <>
      {!hideLogo && (
        <Grid item xs={12}>
          <LevelLogo logoVariation="new" className={classes.smallLevelLogo} />
        </Grid>
      )}
      <Grid
        container
        alignItems="center"
        item
        xs={12}
        style={{ marginBottom: 26 }}
      >
        <Grid
          item
          xs={12}
          style={{
            ...(hideLogo ? { marginTop: 16 } : {}),
            textAlign: 'center',
          }}
        >
          <Typography
            variant="body1"
            style={{ color: palette.brandColorDarkGrey }}
          >
            {isFirm && (
              <>
                Please choose which type of license you would like. Once
                purchased, you will be able to manage your licenses and apply
                them to your clients.
              </>
            )}
            {!isFirm && (
              <>
                Please choose which Level Bookkeeping Copilot pricing plan you
                would like to sign up for.
              </>
            )}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent="center">
        {pricingOptionsLoading ? (
          <Grid container item>
            {_.times(2, i => {
              return (
                <Grid
                  key={i}
                  item
                  xs={6}
                  style={{ paddingLeft: 16, paddingRight: 16 }}
                >
                  <ContentLoader height={280} />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <>
            {pricingOptions ? (
              pricingOptions.map(pricingInfo => {
                let savingsPercentage = null;
                if (pricingInfo.recurring.interval === 'year') {
                  savingsPercentage = yearlySavingsPercentage;
                } else if (
                  pricingInfo.recurring.interval === 'month' &&
                  pricingInfo.recurring.intervalCount === 3
                ) {
                  savingsPercentage = quarterlySavingsPercentage;
                }

                return (
                  <Grid
                    key={pricingInfo.id}
                    item
                    xs={6}
                    style={{
                      paddingLeft: 16,
                      paddingRight: 16,
                      position: 'relative',
                    }}
                  >
                    <PricingOption
                      key={pricingInfo.id}
                      pricingInfo={pricingInfo}
                      savingsPercentage={savingsPercentage}
                      selected={priceSelected === pricingInfo.id}
                      onSelect={() => setPriceSelected(pricingInfo.id)}
                      isFirm={isFirm}
                    />
                  </Grid>
                );
              })
            ) : (
              <>
                Sorry no pricing options are currently available.
                <br />
                <br />
                Please contact Level Support at{' '}
                <Link href="mailto:support@checkthelevel.com">
                  support@checkthelevel.com
                </Link>
                for assistance.
              </>
            )}
          </>
        )}
      </Grid>

      {isFirm && (
        <Grid container item xs={12} justifyContent="center">
          <Grid
            container
            item
            style={{
              marginTop: 32,
              padding: 12,
              width: 400,
              backgroundColor: palette.brandColorLightGrey,
              borderRadius: 4,
            }}
          >
            <Grid container item xs={12} alignItems="center">
              <Grid
                container
                item
                direction="column"
                alignItems="center"
                xs={4}
              >
                <Grid item style={{ marginBottom: 4 }}>
                  <Typography
                    variant="h5"
                    style={{ color: palette.brandColorDarkGrey }}
                  >
                    Quantity
                  </Typography>
                </Grid>
                <Grid item>
                  <LevelNumberInput
                    min={0}
                    value={quantity}
                    onChange={value => setQuantity(value)}
                    contained
                  />
                </Grid>
              </Grid>
              <Grid item xs={8} style={{ paddingLeft: 8, paddingRight: 8 }}>
                <Typography
                  variant="body1"
                  style={{ color: palette.brandColorDarkGrey }}
                >
                  Note: you will need a license for{' '}
                  <b>
                    <i>each client</i>
                  </b>{' '}
                  you would like to add.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid
                item
                style={{
                  width: 200,
                  borderRadius: 8,
                  marginTop: 16,
                  backgroundColor: palette.white,
                  height: 40,
                  paddingTop: 4,
                  paddingBottom: 8,
                }}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  {readableTermPrice && term && (
                    <>
                      <Grid item>
                        <Typography
                          variant="body1"
                          style={{ color: palette.brandColorDarkGrey }}
                        >
                          Subtotal: {readableTermPrice} / {term}
                        </Typography>
                      </Grid>
                      <Grid item style={{ lineHeight: 0.5 }}>
                        <Typography
                          variant="caption"
                          style={{ color: palette.brandColorDarkGrey }}
                        >
                          Plus applicable taxes.
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid container item justifyContent="center" style={{ marginTop: 24 }}>
        <Button onClick={stepBack} className={classes.button}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!priceSelected || quantity === 0}
          onClick={priceSelected ? goToBilling : null}
          className={classes.button}
        >
          Go to Billing
        </Button>
      </Grid>
    </>
  );
};

export default SelectBookkeepingCopilotPrice;
