import React, { useState, useEffect } from 'react';

import { compose } from 'react-apollo';
import { connect } from 'react-redux';

// UI
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, Button } from '@material-ui/core';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

// utilities
import _ from 'lodash';
import uuid from 'uuid';

import ContentLoader from 'react-content-loader';
import {
  AddOrUpdateCompanyQuestionsAction,
  ArchiveOrRestoreCompanyQuestionAction,
  GetCompanyQuestionsAction,
} from '../../../graphql/graphql';

import Question from '../../add-to-project/questions-dialog/question';
// helpers

import FilterList from '../../../components/FilterSearch/filterSearch';
import OkCancelDialog from '../../../components/OkCancelDialog/okCancelDialog';
import AdminToolsTitle from '../../../components/admin-tools-title/admin-tools-title';

const useStyles = makeStyles(theme => ({
  scrollableColumn: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'scroll',
    height: 'calc(100vh - 64px)',
  },
  actionButtonsWrapper: {
    padding: theme.spacing(3),
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },
  editingWarning: {
    padding: 16,
    marginBottom: 16,
  },
}));

const emptyQuestion = () => ({
  questionId: uuid(),
  questionText: '',
  questionType: 'text',
  isRequired: false,
});

const ManageQuestions = ({
  managingCompanyInfo,
  onAddOrUpdateCompanyQuestions,
  archiveOrRestoreCompanyQuestion,
  companyQuestions: unsortedCompanyQuestions,
  getCompanyQuestionsLoading,
}) => {
  const classes = useStyles();

  const [companyQuestions, setCompanyQuestions] = useState([]);
  const [questionsToSet, setQuestionsToSet] = useState();

  const [archiveDialog, setArchiveDialog] = useState({ open: false });

  const QUESTION_TYPES = {
    ALL: 'all',
    TEXT: 'text',
    CHECKBOX: 'checkbox',
    DROPDOWN: 'dropdown',
  };

  const [questionSaving, setQuestionSaving] = useState(null);

  useEffect(() => {
    const sortedData =
      _.orderBy(unsortedCompanyQuestions, ['questionText'], ['asc', 'desc']) ||
      [];
    setCompanyQuestions(sortedData);
    setQuestionsToSet(sortedData);
  }, [unsortedCompanyQuestions]);

  const submitAddOrUpdateQuestion = async questionToSave => {
    if (!questionToSave) return;
    // now if questionsToAdd or questionsToUpdate have anything in them and the user has a company , run and await their updates
    const copyQuestion = { ...questionToSave };
    delete copyQuestion.__typename;
    if (copyQuestion.change) {
      if (copyQuestion.change === 'new') {
        delete copyQuestion.change; // dont need this on the object, was just for tracking changes
      } else if (copyQuestion.change === 'edit') {
        delete copyQuestion.change; // dont need this on the object, was just for tracking changes
        delete copyQuestion.managingCompanyId; // not in the input schema - not allowed to change the managing company
        delete copyQuestion.questionType; // not in the input schema - not allowed to change the type of question
        delete copyQuestion.creatorId; // not in the input schema - not allowed to change creator
      }
    }
    let upsertResponse;
    try {
      setQuestionSaving(copyQuestion.questionId);
      upsertResponse = await onAddOrUpdateCompanyQuestions({
        questions: [copyQuestion],
        companyId: managingCompanyInfo.managingCompanyId,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('onAddOrUpdateCompanyQuestions err: ', err);
    }
    if (
      _.get(upsertResponse, 'data.addOrUpdateCompanyQuestions.status') ===
      'success'
    ) {
      const currentQuestionIndex = _.findIndex(questionsToSet, {
        questionId: copyQuestion.questionId,
      });
      const newQuestionsList = [...questionsToSet];
      newQuestionsList[currentQuestionIndex] = copyQuestion;
      if (!copyQuestion.change) {
        copyQuestion.change = 'edit';
      }
      setQuestionsToSet(newQuestionsList);
    }
    setQuestionSaving(null);
  };

  const addNewQuestion = () => {
    const newQuestion = emptyQuestion();
    setQuestionsToSet([{ ...newQuestion, change: 'new' }, ...questionsToSet]);
  };

  const archiveQuestion = async question => {
    try {
      archiveOrRestoreCompanyQuestion({
        action: 'archive',
        companyId: managingCompanyInfo.managingCompanyId,
        questionId: question.questionId,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('archiveResponse err: ', err);
    }
    const newQuestionsList = [...questionsToSet];
    _.remove(newQuestionsList, {
      questionId: question.questionId,
    });
    setQuestionsToSet(newQuestionsList);
  };

  const setupArchiveDialog = question => {
    setArchiveDialog({ open: true, question });
  };

  const renderListOfQuestions = () => {
    return questionsToSet.map((question, index) => {
      return (
        <Question
          question={question}
          index={index}
          key={question.questionId}
          onUpdate={updatedQuestionInfo => {
            const questionToSave = { ...updatedQuestionInfo };
            const currentQuestionIndex = _.findIndex(questionsToSet, {
              questionId: questionToSave.questionId,
            });
            const newQuestionsList = [...questionsToSet];
            newQuestionsList[currentQuestionIndex] = questionToSave;
            if (!questionToSave.change) {
              questionToSave.change = 'edit';
            }
            setQuestionsToSet(newQuestionsList);
          }}
          onSubmit={submitAddOrUpdateQuestion}
          onArchive={setupArchiveDialog}
          fromAdminView
          questionSaving={questionSaving}
        />
      );
    });
  };

  return (
    <div className={classes.scrollableColumn}>
      <div style={{ flex: 0, background: '#eee' }}>
        <Grid item xs={12} container className={classes.actionButtonsWrapper}>
          <AdminToolsTitle
            Icon={HelpOutlineIcon}
            titleText="Manage Questions"
          />
        </Grid>
      </div>
      <Grid container item style={{ padding: 16 }}>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          className={classes.editingWarning}
        >
          <Grid item>
            Please note that editing a question will change it for all projects
            it is used on. Changing the text will also change it for past
            answers.
            <br />
            <br /> If this is not what you wanted, please create a new question
            instead.
          </Grid>
        </Grid>
        <Paper style={{ flex: 1, height: '100%' }}>
          <Grid container item xs={12} style={{ padding: 16 }}>
            <Grid item style={{ flex: 1 }}>
              <FilterList
                data={companyQuestions}
                passBack={filteredData => setQuestionsToSet(filteredData)}
                searchBy="questionText"
                selectOptions={QUESTION_TYPES}
                initialSelectValue={QUESTION_TYPES.ALL}
                fromAdminView
              />
            </Grid>
            <Grid item container justifyContent="center" style={{ flex: 0 }}>
              <Button style={{ minWidth: 250 }} onClick={addNewQuestion}>
                + Add a new question
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{
              flex: 1,
              marginTop: 10,
              paddingLeft: 16,
              paddingRight: 16,
            }}
          >
            {getCompanyQuestionsLoading ? (
              <ContentLoader viewBox="0 0 400 150" height={130} width={400}>
                <rect x="5" y="15" rx="5" ry="5" width="335" height="10" />
                <circle cx="355" cy="20" r="8" />
                <circle cx="380" cy="20" r="8" />

                <rect x="5" y="45" rx="5" ry="5" width="335" height="10" />
                <circle cx="355" cy="50" r="8" />
                <circle cx="380" cy="50" r="8" />

                <rect x="5" y="75" rx="5" ry="5" width="335" height="10" />
                <circle cx="355" cy="80" r="8" />
                <circle cx="380" cy="80" r="8" />

                <rect x="5" y="105" rx="5" ry="5" width="335" height="10" />
                <circle cx="355" cy="110" r="8" />
                <circle cx="380" cy="110" r="8" />
              </ContentLoader>
            ) : (
              <>
                {questionsToSet && !!questionsToSet.length ? (
                  renderListOfQuestions()
                ) : (
                  <Typography>There are no questions.</Typography>
                )}
              </>
            )}
          </Grid>
        </Paper>
      </Grid>
      {archiveDialog.open && (
        <OkCancelDialog
          title="Are you sure?"
          open
          onClose={() => setArchiveDialog({ ...archiveDialog, open: false })}
          onConfirm={() => {
            archiveQuestion(archiveDialog.question);
            setArchiveDialog({ ...archiveDialog, open: false });
          }}
          okButtonText="Yes, archive it"
        >
          <Typography>
            Archiving this question removes it as an option for projects.
            Projects already using this question are unaffected.
          </Typography>
        </OkCancelDialog>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    managingCompanyInfo: state.appState.managingCompanyInfo || {},
  };
}

export default compose(
  AddOrUpdateCompanyQuestionsAction,
  ArchiveOrRestoreCompanyQuestionAction,
  GetCompanyQuestionsAction
)(connect(mapStateToProps)(ManageQuestions));
