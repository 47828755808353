import _ from 'lodash';
import omitDeep from 'omit-deep';
import uuid from 'uuid/v4';
import { CONTENT_TYPE, GLOBAL_EXPENSE_TYPES } from '../../config/appDefaults';

export const ACTION = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

const splitItem = ({ itemToSplit, projectList, billList }) => {
  // Clean up the contentInfo object
  const cleanContentInfo = omitDeep(_.cloneDeep(itemToSplit), ['__typename']);

  const isGlobalExpense = GLOBAL_EXPENSE_TYPES.includes(itemToSplit.type);
  const isGlobalPayment = itemToSplit.type === CONTENT_TYPE.GLOBAL_PAYMENT;

  if (isGlobalExpense || isGlobalPayment) {
    // Clean jrn fields from record
    delete cleanContentInfo.fromTemplate;
    delete cleanContentInfo.recurrence;
    delete cleanContentInfo.allowedToEdit;
    delete cleanContentInfo.allowedToAdd;
    delete cleanContentInfo.allowedToView;
    delete cleanContentInfo.usersEverOnJrn;
    delete cleanContentInfo.useTimetracking;
    delete cleanContentInfo.workflowStage;
    delete cleanContentInfo.workflowStageReason;

    // keep the refund status for global receipts
    // so we can pass it to the project receipts
    if (itemToSplit.type !== CONTENT_TYPE.GLOBAL_RECEIPT) {
      delete cleanContentInfo.contentStatus;
    }
  }

  delete cleanContentInfo.comments;
  delete cleanContentInfo.loves;

  let splitList = projectList;
  if (isGlobalPayment) {
    splitList = billList;
  }

  return _.map(
    splitList,
    ({
      project = null,
      bill = null,
      amount,
      description = null,
      existingContentInfo,
      billable = null,
      deletionPending,
    }) => {
      const projectOrBill = project || bill;
      if (!projectOrBill) {
        throw new Error('Unexpected error. Could not find project or bill.');
      }

      let action = null;
      let contentInfo;

      if (existingContentInfo && deletionPending) {
        contentInfo = existingContentInfo;
        action = ACTION.DELETE;
      } else if (existingContentInfo) {
        let changeOccurred = false;

        // Copy the existing content item
        const cleanExistingInfo = omitDeep(_.cloneDeep(existingContentInfo), [
          '__typename',
        ]);
        delete cleanExistingInfo.comments;
        delete cleanExistingInfo.loves;
        delete cleanExistingInfo.globalExpense;

        // Create a new content item for update
        const updatedContentInfo = _.cloneDeep(cleanExistingInfo);

        // If amount is provided, make sure amount is initialized and update
        if (amount !== null && amount !== undefined) {
          if (!updatedContentInfo.amount) {
            // Initialize if not already initialized
            updatedContentInfo.amount = { value: Number(amount) };
            changeOccurred = true;
          } else if (
            updatedContentInfo.amount &&
            updatedContentInfo.amount.value !== Number(amount)
          ) {
            // Update if changed
            updatedContentInfo.amount.value = Number(amount);
            changeOccurred = true;
          }
        }

        // If description is provided, update it
        if (
          description !== undefined &&
          updatedContentInfo.description !== description &&
          !(!description && !updatedContentInfo.description)
        ) {
          updatedContentInfo.description = description;
          changeOccurred = true;
        }

        // If billable is provided and different, then update it
        if (
          typeof billable === 'boolean' &&
          billable !== updatedContentInfo.billable
        ) {
          updatedContentInfo.billable = billable;
          changeOccurred = true;
        }

        if (cleanContentInfo.contentUrl !== updatedContentInfo.contentUrl) {
          updatedContentInfo.contentUrl = cleanContentInfo.contentUrl;
          changeOccurred = true;
        }

        if (itemToSplit.type === CONTENT_TYPE.GLOBAL_BILL) {
          // convert global receipt to global bill
          if (updatedContentInfo.type !== CONTENT_TYPE.BILL) {
            updatedContentInfo.type = CONTENT_TYPE.BILL;
            changeOccurred = true;
          }
          if (updatedContentInfo.date !== itemToSplit.startDate) {
            updatedContentInfo.date = itemToSplit.startDate;
            changeOccurred = true;
          }
          if (updatedContentInfo.contentStatus) {
            updatedContentInfo.contentStatus = null;
            changeOccurred = true;
          }
        } else if (itemToSplit.type === CONTENT_TYPE.GLOBAL_RECEIPT) {
          // convert global bill to global receipt
          if (updatedContentInfo.type !== CONTENT_TYPE.RECEIPT) {
            updatedContentInfo.type = CONTENT_TYPE.RECEIPT;
            changeOccurred = true;
          }
          if (updatedContentInfo.contentStatus !== itemToSplit.contentStatus) {
            updatedContentInfo.contentStatus = itemToSplit.contentStatus;
            changeOccurred = true;
          }
          if (updatedContentInfo.date !== itemToSplit.date) {
            updatedContentInfo.date = itemToSplit.date;
            changeOccurred = true;
          }
        } else if (itemToSplit.type === CONTENT_TYPE.GLOBAL_PAYMENT) {
          if (updatedContentInfo.date !== itemToSplit.date) {
            updatedContentInfo.date = itemToSplit.date;
            changeOccurred = true;
          }
          if (updatedContentInfo.subtype !== itemToSplit.subtype) {
            updatedContentInfo.subtype = itemToSplit.subtype;
            changeOccurred = true;
          }
        }

        if (!_.isEqual(cleanContentInfo.labels, updatedContentInfo.labels)) {
          updatedContentInfo.labels = cleanContentInfo.labels;
          changeOccurred = true;
        }

        contentInfo = updatedContentInfo;

        if (changeOccurred) {
          action = ACTION.UPDATE;
        }
      } else {
        // create a new content
        const newContentInfo = _.cloneDeep(cleanContentInfo);

        if (cleanContentInfo.type === CONTENT_TYPE.GLOBAL_BILL) {
          newContentInfo.type = CONTENT_TYPE.BILL;
          newContentInfo.globalExpenseId = cleanContentInfo.contentId;
        } else if (cleanContentInfo.type === CONTENT_TYPE.GLOBAL_RECEIPT) {
          newContentInfo.type = CONTENT_TYPE.RECEIPT;
          newContentInfo.globalExpenseId = cleanContentInfo.contentId;
        } else if (cleanContentInfo.type === CONTENT_TYPE.GLOBAL_PAYMENT) {
          newContentInfo.type = CONTENT_TYPE.BILL_PAYMENT;
          newContentInfo.globalExpenseId = projectOrBill.contentId; // Point to bill
        }

        newContentInfo.contentId = uuid();
        newContentInfo.jrnId = isGlobalPayment
          ? itemToSplit.contentId // Point to global payment for payments
          : projectOrBill.contentId; // Point to projects for everything else
        newContentInfo.amount.value = Number(amount);
        newContentInfo.description = description;
        if (typeof billable === 'boolean') {
          newContentInfo.billable = billable;
        }

        contentInfo = newContentInfo;
        action = ACTION.CREATE;
      }

      return { contentInfo, action };
    }
  );
};

export const calculateUnallocatedAmount = ({ totalAmount, contentList }) => {
  let result = totalAmount;
  if (contentList) {
    result = contentList.reduce(
      (acc, { deletionPending, amount: contentAmount }) => {
        if (deletionPending) {
          return acc;
        }

        return acc - contentAmount;
      },
      totalAmount
    );
  }

  // Edge case: if the totalAmount is 124.95 and the projectList is [100, 24.95],
  // then the unallocated amount will be 3.552713678800501e-15, instead of 0.
  // So to fix this, we round the result to 2 decimal places.
  return Number(result.toFixed(2));
};

export default splitItem;
