import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { getFullUserString } from '../../helpers';

const ConfirmUserRemoval = ({ onCancel, onConfirm, userToRemove }) => {
  if (!userToRemove) {
    return null;
  }

  const fullUserString = getFullUserString(userToRemove);

  return (
    <Dialog maxWidth="sm" aria-labelledby="confirmation-dialog" open>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent dividers style={{ paddingTop: 20, paddingBottom: 20 }}>
        <Grid container>
          <Grid item>
            <Typography>
              <b>{fullUserString}</b> will be removed from the company crew
              members and no longer have access to the company&apos;s data on
              Level.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onConfirm({ userToRemove });
          }}
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmUserRemoval;
