import React from 'react';
import { Typography, Grid, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import LevelModal from '../Modal/level-modal';
import camSad from '../../assets/images/cam/copilot_mascot_frown_no-arm.jpg';
import LevelSupportEmailAddressLink from '../level-support-email-address-link/level-support-email-address-link';

const StripeErrorOnCheckout = ({ onClose }) => {
  return (
    <LevelModal
      open
      fullWidth={false}
      maxWidth="sm"
      dialogStyle={{ zIndex: '9999' }}
    >
      <Grid container alignItems="center" style={{ position: 'relative' }}>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: 16 }}
        >
          <Typography
            variant="h3"
            style={{ textAlign: 'center', paddingLeft: 8, flex: 1 }}
          >
            Something went wrong while processing your payment 😢
          </Typography>
          <IconButton
            onClick={() => {
              onClose();
            }}
            style={{ flex: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ marginBottom: 16 }}
          alignItems="center"
        >
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <img
              src={camSad}
              style={{ width: 'auto', height: 200 }}
              alt="robot missing an arm with a sad look on its face"
            />
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="body1"
              style={{ fontSize: 18, paddingLeft: 16 }}
            >
              Please try again or{' '}
              <LevelSupportEmailAddressLink underline>
                contact support
              </LevelSupportEmailAddressLink>{' '}
              if you continue to have issues.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </LevelModal>
  );
};

export default StripeErrorOnCheckout;
