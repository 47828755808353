import gql from 'graphql-tag';

export default gql`
  mutation RemoveCardAccess(
    $cardId: String!
    $companyId: String!
    $userId: String!
  ) {
    removeCardAccess(
      input: { cardId: $cardId, companyId: $companyId, userId: $userId }
    ) {
      status
      msg
    }
  }
`;
