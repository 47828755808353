import React, { useState } from 'react';
import { Grid, ButtonBase, Typography, makeStyles } from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';

import RowCircleSelect from '../row-circle-select';

const useStyles = makeStyles(() => ({
  treeItemWrapper: {
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
}));

const QuickbooksAccountOption = ({
  account,
  nestingLevel = 0,
  onChooseAccount,
  selectedAccounts,
}) => {
  const [isExpanded, setIsExpanded] = useState(account.isExpanded || false);

  const classes = useStyles();

  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="space-between"
      alignItems="center"
      style={{
        borderBottom: nestingLevel === 0 ? '1px solid #ccc' : 'none',
      }}
    >
      <Grid
        item
        xs={12}
        container
        className={classes.treeItemWrapper}
        alignItems="center"
        style={{
          paddingRight: 8,
          paddingLeft: nestingLevel * 16 + 8,
        }}
      >
        <Grid item style={{ flex: 1 }}>
          <ButtonBase
            onClick={() => setIsExpanded(!isExpanded)}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              padding: '12px 0px',
            }}
          >
            {isExpanded ? (
              <ExpandMoreIcon style={{ marginRight: 8 }} />
            ) : (
              <ChevronRightIcon style={{ marginRight: 8 }} />
            )}
            {account.displayName}
          </ButtonBase>
        </Grid>
        <Grid item style={{ alignItems: 'center', flex: 0 }}>
          <RowCircleSelect
            target={account.sourceId}
            isActive={selectedAccounts.includes(account.sourceId)}
            onSelect={({ target: sourceId }) => onChooseAccount(sourceId)}
          />
        </Grid>
      </Grid>
      {isExpanded &&
        (account.children && account.children.length > 0 ? (
          account.children.map(childAccount => (
            <QuickbooksAccountOption
              key={childAccount.sourceId}
              account={childAccount}
              nestingLevel={nestingLevel + 1}
              onChooseAccount={onChooseAccount}
              selectedAccounts={selectedAccounts}
            />
          ))
        ) : (
          <Grid item xs={12}>
            <Typography
              style={{
                color: '#999',
                fontStyle: 'italic',
                paddingLeft: 72,
                paddingBottom: 16,
              }}
            >
              No sub-accounts
            </Typography>
          </Grid>
        ))}
    </Grid>
  );
};

export default QuickbooksAccountOption;
