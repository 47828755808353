import React, { useState, useEffect, useMemo } from 'react';
import { compose } from 'react-apollo';

import { makeStyles } from '@material-ui/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
} from '@material-ui/core';

import {
  AttachMoney as AttachMoneyIcon,
  CheckBoxOutlined as CheckBoxOutlinedIcon,
  ChevronRight as ChevronRightIcon,
  ExpandMore as ExpandMoreIcon,
  Receipt as ReceiptIcon,
  PeopleOutlined as PeopleOutlinedIcon,
  ReceiptOutlined as ReceiptOutlinedIcon,
  TimerOutlined as TimerOutlinedIcon,
} from '@material-ui/icons';

import _ from 'lodash';

import LevelTabs from '../../../../../components/level-tabs/level-tabs';
import { GetBudgetInfoAction } from '../../../../../graphql/graphql';

import {
  TYPE_TO_STAT_LABEL,
  CONTENT_TYPE,
  TASK_STATUSES,
  BUDGET_TYPE_PAYMENTS,
  BUDGET_TYPE_RECEIPTS_INVOICES,
  BUDGET_TYPE_TOTAL_LABOR_COST,
  BUDGET_TYPE_TOTAL_LABOR_HOURS,
  CREW_PAY_TYPES,
  TOP_PROJECT_ID,
} from '../../../../../config/appDefaults';

import {
  addUpBudgetItems,
  calculateHourlyRate,
  ensureNum,
} from '../../../../../helpers/index';

import globalStyles from '../../../../../theme/palette';
import StatDialog from './stat-dialog/stat-dialog';
import {
  calculateSubprojectBudgetTotals,
  calculateTotalLaborBudget,
} from './stat-dialog/helpers';
import StatTile from './stat-tile';

const useStyles = makeStyles(() => ({
  verticalStatTileFirst: {
    marginRight: 8,
  },
  verticalStatTileLast: {
    marginLeft: 8,
  },
  accordion: {
    width: '100%',
    marginTop: 16,
    padding: 0,
    '&.MuiPaper-elevation1': {
      boxShadow: 'none',
    },
    '&.MuiAccordion-root:before': {
      opacity: 0,
    },
    '&.Mui-expanded': {
      boxShadow:
        '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    },
  },
  accordionSummary: {
    width: '100%',
    padding: 0,
    '&.MuiAccordionSummary-root:hover:not(.Mui-disabled)': {
      cursor: 'auto',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  accordionDetails: {
    padding: '16px 16px 32px 32px',
  },
  totalExpensesStatTile: {
    marginTop: 0,
  },
}));

const StatTiles = ({
  projectInfo,
  statView,
  projectCanEdit,
  setStatView,
  userId,
  timeManager,
  contentLists,
  projectUsers,
  budgetInfo,
  getBudgetInfoLoading,
}) => {
  const classes = useStyles();

  const handleStatViewModeChange = newViewMode => {
    if (newViewMode) {
      // nullish check stops the button from toggling to nothing
      setStatView(newViewMode);
    }
  };

  const [expandTotalExpenses, setExpandTotalExpenses] = useState(false);

  const [paymentsModalInfo, setPaymentsModalInfo] = useState({
    open: false,
  });
  const [totalExpensesModalInfo, setTotalExpensesModalInfo] = useState({
    open: false,
    initialTabValue: 0,
  });
  const [laborHoursModalInfo, setLaborHoursModalInfo] = useState({
    open: false,
  });
  const openPaymentModal = () => {
    setPaymentsModalInfo({ open: true });
  };
  const closePaymentModal = () => {
    setPaymentsModalInfo({ open: false });
  };
  const openHoursModal = () => {
    setLaborHoursModalInfo({ open: true });
  };
  const closeHoursModal = () => {
    setLaborHoursModalInfo({ open: false });
  };
  const openTotalExpensesModal = initialTabValue => () => {
    setTotalExpensesModalInfo({ open: true, initialTabValue });
  };
  const closeTotalExpensesModal = () => {
    setTotalExpensesModalInfo({ open: false, initialTabValue: 0 });
  };

  const [taskStats, setTaskStats] = useState({
    numOfCompleteTasks: 0,
    numOfTotalTasks: 0,
  });
  const overviewMode = statView === 'overview'; // use this when we want to turn this on or off based on some project/company setting
  const adminMode = statView === 'admin'; // use this when we want to turn this on or off based on some project/company setting
  const userMode = statView === 'user';
  const overviewOrAdmin = overviewMode || adminMode;

  // START - Seperate content
  const receiptsToUse = useMemo(() => {
    let receipts = [];
    if (contentLists.receipt && contentLists.receipt.length) {
      receipts = overviewOrAdmin
        ? [...(contentLists.receipt || []), ...(contentLists.bill || [])]
        : contentLists.receipt.filter(
            item =>
              item.type === CONTENT_TYPE.RECEIPT && item.creatorId === userId
          );
    }
    return receipts;
  }, [contentLists, userId, overviewOrAdmin]);

  const invoicesToUse = useMemo(() => {
    return contentLists.invoice && contentLists.invoice.length
      ? contentLists.invoice.filter(item => item.type === CONTENT_TYPE.INVOICE)
      : [];
  }, [contentLists]);

  const paymentsToUse = useMemo(() => {
    return contentLists.invoice && contentLists.invoice.length
      ? contentLists.invoice.filter(item => item.type === CONTENT_TYPE.PAYMENT)
      : [];
  }, [contentLists]);
  // END - Seperate content

  // //////////// START - Calculate subproject stats //////////////////
  //
  const sortedProjects = useMemo(() => {
    return contentLists.project
      ? _.orderBy(contentLists.project, ['title'], ['asc'])
      : [];
  }, [contentLists]);

  const { subStatTotals, anySubHasTimetrackingOn } = useMemo(() => {
    const subStats = {};
    let hasTimetrackingOn = false;
    if (sortedProjects) {
      sortedProjects.forEach(item => {
        // tally up stat totals
        const stats = { ...(item.stats || {}) };
        delete stats.__typename;
        _.keys(stats).forEach(stat => {
          if (!subStats[stat]) {
            subStats[stat] = 0;
          }
          subStats[stat] += stats[stat];
        });

        if (!hasTimetrackingOn) {
          if (item.useTimetracking && !item.excludeFromStats) {
            hasTimetrackingOn = true;
          }
        }
      });
    }
    return {
      subStatTotals: subStats,
      anySubHasTimetrackingOn: hasTimetrackingOn,
    };
  }, [sortedProjects]);
  //
  // /////////////// END - Calculate subproject stats ////////////////

  // Calculate subproject budget totals
  const subBudgetTotals = useMemo(() => {
    return calculateSubprojectBudgetTotals(budgetInfo);
  }, [budgetInfo]);

  // /////////////// START - Get parent budget totals ////////////////////
  //
  const parentReceiptBudgetValue = _.get(
    _.find(budgetInfo?.budgets, { label: BUDGET_TYPE_RECEIPTS_INVOICES }) || {},
    'value',
    0
  );

  const totalParentLaborBudget = useMemo(() => {
    if (projectInfo.useTimetracking && budgetInfo) {
      return calculateTotalLaborBudget(budgetInfo);
    }
    return { cost: 0, hours: 0 };
  }, [budgetInfo, projectInfo.useTimetracking]);

  const parentLaborBudgetValue = totalParentLaborBudget.cost;

  const parentHoursBudgetValue = totalParentLaborBudget.hours;

  const parentPaymentsBudgetValue = _.get(
    _.find(budgetInfo?.budgets, { label: BUDGET_TYPE_PAYMENTS } || {}),
    'value',
    0
  );
  //
  // ////////////// END - Get budget totals ////////////////////

  // /////////////////////////////////////////////////////////////////////
  // Based on whether we're combining subs with parent, choose the values to use
  const subsSumReceipts =
    subStatTotals[TYPE_TO_STAT_LABEL[CONTENT_TYPE.RECEIPT]] || 0;
  const subsSumBills =
    subStatTotals[TYPE_TO_STAT_LABEL[CONTENT_TYPE.BILL]] || 0;
  const subsSumInvoices =
    subStatTotals[TYPE_TO_STAT_LABEL[CONTENT_TYPE.INVOICE]] || 0;
  const subsSumPayments =
    subStatTotals[TYPE_TO_STAT_LABEL[CONTENT_TYPE.PAYMENT]] || 0;
  const subsSumHours =
    subStatTotals[TYPE_TO_STAT_LABEL[CONTENT_TYPE.TIMETRACKING]] || 0;
  const subsSumLaborCost =
    subStatTotals[TYPE_TO_STAT_LABEL[`${CONTENT_TYPE.TIMETRACKING}Cost`]] || 0;

  const parentReceiptsSum = addUpBudgetItems(receiptsToUse, 2);
  const additionalReceipts = overviewMode
    ? [subsSumReceipts, subsSumBills]
    : undefined;
  const overallReceiptsSum = addUpBudgetItems(
    receiptsToUse,
    2,
    additionalReceipts
  );

  // figure out invoices and payments totals
  const parentPaymentsSum = addUpBudgetItems(paymentsToUse, 2);
  const additionalPayments = overviewMode ? [subsSumPayments] : undefined;
  const overallPaymentsSum = addUpBudgetItems(
    paymentsToUse,
    2,
    additionalPayments
  );

  const parentInvoicesSum = addUpBudgetItems(invoicesToUse, 2);
  const additionalInvoices = overviewMode ? [subsSumInvoices] : undefined;
  const overallInvoicesSum = addUpBudgetItems(
    invoicesToUse,
    2,
    additionalInvoices
  );

  const parentHoursWorked =
    projectInfo.useTimetracking && timeManager
      ? timeManager.getHours({
          userIds: userMode ? [userId] : null,
        })
      : 0;
  const additionalHoursWorked = overviewMode ? subsSumHours : 0;
  const overallHoursWorked = parentHoursWorked + additionalHoursWorked;

  const parentLaborCost = useMemo(() => {
    let laborCost = 0;
    if (budgetInfo && budgetInfo.userPayInfo) {
      if (projectInfo.useTimetracking && timeManager) {
        let { userPayInfo } = budgetInfo;
        if (userMode) {
          userPayInfo = userPayInfo.filter(item => item.userId === userId);
        }
        _.forEach(userPayInfo, payInfo => {
          const { userId: onProjectUserId, payType } = payInfo;
          if (payType === CREW_PAY_TYPES.VARIABLE_HOURLY) {
            const hourlyRate = calculateHourlyRate(payInfo);
            const hoursWorked = timeManager.getHours({
              userIds: [onProjectUserId],
            });
            laborCost += hoursWorked * hourlyRate;
          }
        });
      }
    }
    return laborCost;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetInfo, projectInfo.useTimetracking, parentHoursWorked]);

  const additionalLaborCost = overviewMode ? subsSumLaborCost : 0;
  const overallLaborCost = parentLaborCost + additionalLaborCost;

  const subsReceiptBudgetValue =
    subBudgetTotals[BUDGET_TYPE_RECEIPTS_INVOICES] || 0;
  const subsPaymentsBudgetValue = subBudgetTotals[BUDGET_TYPE_PAYMENTS] || 0;
  const subsLaborHoursBudgetValue =
    subBudgetTotals[BUDGET_TYPE_TOTAL_LABOR_HOURS] || 0;
  const subsLaborBudgetValue =
    subBudgetTotals[BUDGET_TYPE_TOTAL_LABOR_COST] || 0;

  let hoursWorkedToShow = parentHoursWorked;
  let hoursBudgetValueToShow = parentHoursBudgetValue;
  let laborCostToShow = parentLaborCost;
  let laborBudgetValueToShow = parentLaborBudgetValue;
  let receiptsSumToShow = parentReceiptsSum;
  let receiptBudgetValueToShow = parentReceiptBudgetValue;
  let paymentsSumToShow = parentPaymentsSum;
  let paymentsBudgetValueToShow = parentPaymentsBudgetValue;
  let invoicesSumToShow = parentInvoicesSum;
  // /////////////////////////////////////////////////////////////////////

  const receiptsSumByUserId = useMemo(() => {
    const sumByUserId = {};
    const receiptsByUserId = _.groupBy(receiptsToUse, 'creatorId');
    _.forEach(projectUsers, user => {
      sumByUserId[user.userId] = addUpBudgetItems(
        receiptsByUserId[user.userId]
      );
    });
    return sumByUserId;
  }, [projectUsers, receiptsToUse]);

  if (overviewMode) {
    hoursWorkedToShow = overallHoursWorked;
    hoursBudgetValueToShow += subsLaborHoursBudgetValue;

    laborCostToShow = overallLaborCost;
    laborBudgetValueToShow += subsLaborBudgetValue;

    receiptsSumToShow = overallReceiptsSum;
    receiptBudgetValueToShow += subsReceiptBudgetValue;

    paymentsSumToShow = overallPaymentsSum;
    paymentsBudgetValueToShow += subsPaymentsBudgetValue;

    invoicesSumToShow = overallInvoicesSum;
  }

  const parentTotalExpenses = parentLaborCost + parentReceiptsSum;
  const parentTotalBudgetValue =
    parentLaborBudgetValue + parentReceiptBudgetValue;
  const overallTotalExpenses = overallLaborCost + overallReceiptsSum;

  const totalExpensesToShow = laborCostToShow + receiptsSumToShow;
  const totalBudgetValueToShow =
    laborBudgetValueToShow + receiptBudgetValueToShow;

  // START - Decide what tiles to show
  const shouldShowTimetracking =
    projectInfo.useTimetracking || (overviewMode && anySubHasTimetrackingOn);

  const shouldShowLaborCostTile =
    overviewOrAdmin && (laborCostToShow || laborBudgetValueToShow);

  const shouldShowReceiptTile = receiptsSumToShow || receiptBudgetValueToShow;
  const shouldShowPaymentsTile =
    overviewOrAdmin &&
    (paymentsSumToShow || invoicesSumToShow || paymentsBudgetValueToShow);
  // END - Decide what tiles to show

  useEffect(() => {
    let numOfCompleteTasks = 0;
    let numOfTotalTasks = 0;
    const thereAreTasks =
      contentLists && contentLists.task && contentLists.task.length;

    if (thereAreTasks) {
      if (overviewOrAdmin) {
        numOfTotalTasks = contentLists.task.length;
        contentLists.task.forEach(task => {
          if (task.contentStatus === 'completed') {
            numOfCompleteTasks += 1;
          }
        });
      } else {
        contentLists.task.forEach(task => {
          if (task.assignedTo === userId) {
            numOfTotalTasks += 1;
            if (task.contentStatus === 'completed') {
              numOfCompleteTasks += 1;
            }
          }
        });
      }
    }
    if (subStatTotals && overviewMode) {
      numOfCompleteTasks += ensureNum(subStatTotals.taskCompleted);
      numOfTotalTasks +=
        ensureNum(
          subStatTotals[
            TYPE_TO_STAT_LABEL[
              `${CONTENT_TYPE.TASK}&&${TASK_STATUSES.COMPLETED}`
            ]
          ]
        ) +
        ensureNum(
          subStatTotals[
            TYPE_TO_STAT_LABEL[
              `${CONTENT_TYPE.TASK}&&${TASK_STATUSES.IN_PROGRESS}`
            ]
          ]
        ) +
        ensureNum(
          subStatTotals[
            TYPE_TO_STAT_LABEL[
              `${CONTENT_TYPE.TASK}&&${TASK_STATUSES.INCOMPLETE}`
            ]
          ]
        ) +
        ensureNum(
          subStatTotals[
            TYPE_TO_STAT_LABEL[
              `${CONTENT_TYPE.TASK}&&${TASK_STATUSES.PENDING_APPROVAL}`
            ]
          ]
        );
    }
    setTaskStats({ numOfCompleteTasks, numOfTotalTasks });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentLists, overviewOrAdmin, userId, overviewMode]);

  const determineProgressBarDataForPayments = () => {
    const thickness = 8;
    const barColor = globalStyles.progressGreen;
    const percentageGreen = { value: 0, width: '0%' };
    const percentageOrange = { value: 0, width: '0%' };
    const percentageWhite = { value: 0, width: '0%' };

    if (!invoicesSumToShow && !paymentsBudgetValueToShow) {
      return null;
    }

    let totalValue = 0;
    if (paymentsBudgetValueToShow) {
      totalValue = paymentsBudgetValueToShow;
    } else if (invoicesSumToShow > paymentsSumToShow) {
      totalValue = invoicesSumToShow;
    } else {
      totalValue = paymentsSumToShow;
    }

    percentageGreen.value = (paymentsSumToShow / totalValue) * 100;
    let greenWidthNum = Math.min(percentageGreen.value, 100);
    percentageGreen.width = `${greenWidthNum}%`;

    percentageOrange.value = (invoicesSumToShow / totalValue) * 100;
    let orangeWidthNum = Math.min(percentageOrange.value, 100);

    if (greenWidthNum < 100 && orangeWidthNum > greenWidthNum) {
      orangeWidthNum -= greenWidthNum;
      percentageOrange.width = `${orangeWidthNum}%`;
    } else if (paymentsBudgetValueToShow) {
      if (orangeWidthNum < 100 && orangeWidthNum < greenWidthNum) {
        percentageOrange.width = `${orangeWidthNum}%`;
        greenWidthNum -= orangeWidthNum;
        percentageGreen.width = `${greenWidthNum}%`;
      }
    }

    percentageWhite.value = 100 - greenWidthNum - orangeWidthNum;
    percentageWhite.width = `${percentageWhite.value}%`;

    return {
      barColor,
      percentageGreen,
      percentageOrange,
      percentageWhite,
      thickness,
    };
  };

  const paymentProgressBarData = determineProgressBarDataForPayments();

  return (
    <Grid
      container
      style={{
        paddingTop: 0,
        boxShadow: '0 0 5px 0 #eee',
        marginBottom: 16,
        border: '1px solid #e6e6e6',
        borderRadius: '10px 10px 0 0',
        overflow: 'hidden',
      }}
    >
      <Grid item container justifyContent="flex-end" alignItems="center">
        {projectCanEdit && (
          <LevelTabs
            tabs={[
              {
                isActive: overviewMode,
                title: 'OVERALL',
                subtitle: '(incl. subs)',
                onClick: () => handleStatViewModeChange('overview'),
                visible:
                  projectInfo.jrnId === TOP_PROJECT_ID &&
                  projectInfo.includeSubsInStats,
              },
              {
                isActive: adminMode,
                title: 'PROJECT',
                onClick: () => handleStatViewModeChange('admin'),
                visible: true,
              },
              {
                isActive: userMode,
                title: 'MY VIEW',
                onClick: () => handleStatViewModeChange('user'),
                visible: true,
              },
            ]}
          />
        )}
      </Grid>
      {getBudgetInfoLoading ? (
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: 120 }}
        >
          <CircularProgress size={24} />
        </Grid>
      ) : (
        <Grid item container style={{ padding: `0 30px 36px` }}>
          <Grid item container>
            {!!shouldShowTimetracking && (
              <Grid container item xs={6}>
                <StatTile
                  budget={overviewOrAdmin ? hoursBudgetValueToShow : undefined}
                  budgetNumberFormat="0,0"
                  containerClassName={classes.verticalStatTileFirst}
                  disabled={!overviewOrAdmin}
                  hideProgressBar={!overviewOrAdmin}
                  label="Hours Worked"
                  onStatTileClick={openHoursModal}
                  renderIcon={props => <TimerOutlinedIcon {...props} />}
                  total={hoursWorkedToShow}
                  totalNumberFormat="0,0.00"
                  verticalStatTile
                />
              </Grid>
            )}

            <Grid container item xs={shouldShowTimetracking ? 6 : 12}>
              <StatTile
                budget={taskStats.numOfTotalTasks}
                budgetNumberFormat="0"
                containerClassName={
                  shouldShowTimetracking ? classes.verticalStatTileLast : ''
                }
                completionAsGreen
                disabled
                label="Tasks Completed"
                renderIcon={props => <CheckBoxOutlinedIcon {...props} />}
                total={taskStats.numOfCompleteTasks}
                totalNumberFormat="0"
                verticalStatTile
              />
            </Grid>
          </Grid>
          {(!!shouldShowLaborCostTile || !!shouldShowReceiptTile) && (
            <Accordion
              className={classes.accordion}
              expanded={expandTotalExpenses}
            >
              <AccordionSummary className={classes.accordionSummary}>
                <StatTile
                  budget={overviewOrAdmin ? totalBudgetValueToShow : undefined}
                  budgetNumberFormat="$0,0"
                  containerClassName={classes.totalExpensesStatTile}
                  disabled={!overviewOrAdmin}
                  hideProgressBar={!overviewOrAdmin}
                  label={`${'Total' || 'Project'} Expenses`}
                  onStatTileClick={openTotalExpensesModal(0)}
                  renderIcon={props => <ReceiptOutlinedIcon {...props} />}
                  total={totalExpensesToShow}
                  totalNumberFormat="$0,0"
                  onLabelButtonClick={() => {
                    setExpandTotalExpenses(prevState => !prevState);
                  }}
                  renderLabelButtonIcon={props =>
                    expandTotalExpenses ? (
                      <ExpandMoreIcon {...props} />
                    ) : (
                      <ChevronRightIcon {...props} />
                    )
                  }
                />
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Grid container>
                  {!!shouldShowLaborCostTile && (
                    <StatTile
                      budget={
                        overviewOrAdmin ? laborBudgetValueToShow : undefined
                      }
                      budgetNumberFormat="$0,0"
                      disabled={!overviewOrAdmin}
                      hideProgressBar={!overviewOrAdmin}
                      label="Labor Cost"
                      onStatTileClick={openTotalExpensesModal(1)}
                      renderIcon={props => <PeopleOutlinedIcon {...props} />}
                      total={laborCostToShow}
                      totalNumberFormat="$0,0"
                    />
                  )}
                  {!!shouldShowReceiptTile && (
                    <StatTile
                      budget={
                        overviewOrAdmin ? receiptBudgetValueToShow : undefined
                      }
                      budgetNumberFormat="$0,0"
                      disabled={!overviewOrAdmin}
                      hideProgressBar={!overviewOrAdmin}
                      label="Receipt/Bill Total"
                      onStatTileClick={openTotalExpensesModal(
                        shouldShowLaborCostTile ? 2 : 1
                      )}
                      renderIcon={props => <ReceiptIcon {...props} />}
                      total={receiptsSumToShow}
                      totalNumberFormat="$0,0"
                    />
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
          {!!shouldShowPaymentsTile && (
            <StatTile
              budget={overviewOrAdmin ? paymentsBudgetValueToShow : undefined}
              budgetNumberFormat="$0,0"
              disabled={!overviewOrAdmin}
              hideProgressBar={!overviewOrAdmin}
              label="Total Received"
              onStatTileClick={openPaymentModal}
              progressBarData={paymentProgressBarData}
              renderIcon={props => <AttachMoneyIcon {...props} />}
              total={paymentsSumToShow}
              totalNumberFormat="$0,0"
            />
          )}
        </Grid>
      )}
      {laborHoursModalInfo.open && (
        <StatDialog
          maxWidth="lg"
          fullWidth
          onClose={closeHoursModal}
          title={`${
            statView === 'overview' ? 'Overall' : 'Project'
          } Labor Hours`}
          overviewMode={overviewMode}
          projects={sortedProjects}
          projectUsers={projectUsers}
          parentProjectDetails={projectInfo}
          overageIsGood={false}
          timeManager={timeManager}
          budgetInfo={budgetInfo}
          data={[
            {
              parentX: parentHoursWorked,
              parentOfY: parentHoursBudgetValue,
              statTotal: overallHoursWorked,
              totalBudgetValue: hoursBudgetValueToShow,
              pluralWording: 'Worked Hours',
              itemTypes: [CONTENT_TYPE.TIMETRACKING],
              budgetLabels: [BUDGET_TYPE_TOTAL_LABOR_HOURS],
              numberFormat: '0.00',
              dataUnit: 'h',
              breakdownByUser: !overviewMode,
              editBudgetByUser: true,
              budgetInfo,
            },
          ]}
        />
      )}
      {totalExpensesModalInfo.open && (
        <StatDialog
          maxWidth="lg"
          fullWidth
          onClose={closeTotalExpensesModal}
          title={`${statView === 'overview' ? 'Overall' : 'Project'} Expenses`}
          overviewMode={overviewMode}
          projects={sortedProjects}
          projectUsers={projectUsers}
          parentProjectDetails={projectInfo}
          overageIsGood={false}
          timeManager={timeManager}
          initialTabValue={totalExpensesModalInfo.initialTabValue}
          budgetInfo={budgetInfo}
          data={[
            {
              parentX: parentTotalExpenses,
              parentOfY: parentTotalBudgetValue,
              statTotal: overallTotalExpenses,
              totalBudgetValue: totalBudgetValueToShow,
              tabName: 'Overview',
              pluralWording: 'Expenses',
              itemTypes: [
                `${CONTENT_TYPE.TIMETRACKING}Cost`,
                CONTENT_TYPE.RECEIPT,
              ],
              budgetLabels: [
                BUDGET_TYPE_TOTAL_LABOR_COST,
                BUDGET_TYPE_RECEIPTS_INVOICES,
              ],
              numberFormat: '$0,0.00',
              dataUnit: '$',
              breakdownByUser: !overviewMode,
              budgetInfo,
              contentSumByUserId: receiptsSumByUserId,
            },
            shouldShowLaborCostTile
              ? {
                  parentX: parentLaborCost,
                  parentOfY: parentLaborBudgetValue,
                  statTotal: overallLaborCost,
                  totalBudgetValue: laborBudgetValueToShow,
                  tabName: 'Labor',
                  pluralWording: 'Labor',
                  itemTypes: [`${CONTENT_TYPE.TIMETRACKING}Cost`],
                  budgetLabels: [BUDGET_TYPE_TOTAL_LABOR_COST],
                  numberFormat: '$0,0.00',
                  dataUnit: '$',
                  breakdownByUser: !overviewMode,
                  editBudgetByUser: true,
                  budgetInfo,
                }
              : null,
            {
              parentX: parentReceiptsSum,
              parentOfY: parentReceiptBudgetValue,
              statTotal: overallReceiptsSum,
              totalBudgetValue: receiptBudgetValueToShow,
              tabName: 'Receipts/Bills',
              pluralWording: 'Receipts/Bills',
              itemTypes: [CONTENT_TYPE.RECEIPT],
              budgetLabels: [BUDGET_TYPE_RECEIPTS_INVOICES],
              numberFormat: '$0,0.00',
              dataUnit: '$',
              breakdownByUser: !overviewMode,
              contentSumByUserId: receiptsSumByUserId,
            },
          ]}
        />
      )}
      {paymentsModalInfo.open && (
        <StatDialog
          maxWidth="lg"
          fullWidth
          onClose={closePaymentModal}
          title="Invoice/Payments Details"
          overviewMode={overviewMode}
          projects={sortedProjects}
          parentProjectDetails={projectInfo}
          overageIsGood
          listAllTotalStatsInOverviewSection
          budgetInfo={budgetInfo}
          data={[
            {
              parentX: parentPaymentsSum,
              parentOfY: parentPaymentsBudgetValue,
              statTotal: overallPaymentsSum,
              totalBudgetValue: paymentsBudgetValueToShow,
              tabName: 'Payments',
              pluralWording: 'Payments',
              itemTypes: [CONTENT_TYPE.PAYMENT],
              budgetLabels: [BUDGET_TYPE_PAYMENTS],
              numberFormat: '$0,0.00',
              totalColor: globalStyles.progressGreen,
              dataUnit: '$',
            },
            {
              parentX: parentInvoicesSum,
              parentOfY: parentPaymentsBudgetValue,
              statTotal: overallInvoicesSum,
              totalBudgetValue: paymentsBudgetValueToShow,
              tabName: 'Invoices',
              pluralWording: 'Invoices',
              itemTypes: [CONTENT_TYPE.INVOICE],
              budgetLabels: [BUDGET_TYPE_PAYMENTS],
              numberFormat: '$0,0.00',
              totalColor: globalStyles.progressOrange,
              dataUnit: '$',
            },
          ]}
        />
      )}
    </Grid>
  );
};

export default compose(GetBudgetInfoAction)(StatTiles);
