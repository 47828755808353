import gql from 'graphql-tag';

export default gql`
  fragment AccountingTxnFields on AccountingTxn {
    accountName
    accountType
    accountId
    amount
    balance
    class
    createdBy
    createdDate
    creditAmount
    customerName
    customerId
    debitAmount
    docNum
    employeeId
    employeeName
    existingRfiIds
    isCleared
    lastModifiedBy
    lastModifiedDate
    location
    locationId
    memo
    name
    splitAccount
    taxInAmount
    txnDate
    txnId
    txnType
    vendorId
    vendorName
  }
`;
