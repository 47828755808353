import basicSchemaGenerator from './basic-schema-generator';
import { titleMaxValidation } from './schema-validation';

export default [
  {
    ...basicSchemaGenerator('title', 'string', true, true, [
      titleMaxValidation,
    ]),
  },
];
