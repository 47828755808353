import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
  Button,
} from '@material-ui/core';

import ChooseProjectWithSubs from '../../../components/choose-project-dialog/choose-project-with-subs';

const ChooseProjectDialog = ({
  setProjectAndPath,
  toClose,
  paperProps = {},
}) => {
  return (
    <Dialog maxWidth="md" fullWidth open PaperProps={paperProps}>
      <DialogTitle disableTypography>
        <Typography variant="h4" align="center" color="primary">
          Choose which project this belongs to:
        </Typography>
      </DialogTitle>
      <DialogContent style={{ margin: '32px 0' }}>
        <ChooseProjectWithSubs setSelectedProject={setProjectAndPath} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={toClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChooseProjectDialog;
