import gql from 'graphql-tag';

// returns a User object so you can update the user's GetMyInfo cached data
export default gql`
  query RefreshMyActiveSessions {
    refreshMyActiveSessions {
      __typename
      userId
      username
      email
      profilePic
      coverImage
      hasLoggedIn
      unreadCount
      userType
      type
      activeSessions {
        uniqueId
        dateCreated
        date
        creatorId
        assignedToId
        projectId
        projectTitle
        checkinContentId
      }
      companies
    }
  }
`;
