// #region Imports
import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { compose, withApollo } from 'react-apollo';
import { connect } from 'react-redux';

// UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch as MuiSwitch,
  Tooltip,
  Menu,
  MenuItem,
} from '@material-ui/core';

import {
  Add as AddIcon,
  Block as BlockIcon,
  CloudDownload as CloudDownloadIcon,
  DeleteOutline as DeleteOutlineIcon,
  Equalizer as EqualizerIcon,
  Image as ImageIcon,
  LocalOffer as LocalOfferIcon,
  MoreHoriz as MoreHorizIcon,
  Publish as PublishIcon,
  Save as SaveIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';

import ReactDataGrid from '@inovua/reactdatagrid-community';

import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import Papa from 'papaparse';
import { fromString } from 'html-to-text';

// Utilities
import _, { uniqueId } from 'lodash';
import moment from 'moment';
import copyToClipboard from 'copy-to-clipboard';

import store from '../../../store';

// UI components
import AdminToolsIconButton from '../../../components/admin-tools-icon-button/admin-tools-icon-button';
import AdminToolsTitle from '../../../components/admin-tools-title/admin-tools-title';
import ContentDetailsModal from '../../add-to-project/content-details-modal';
import OkCancelDialog from '../../../components/OkCancelDialog/okCancelDialog';

// GraphQL imports
import GetSignedUrlForZipDownload from '../../../graphql/queries/GetSignedUrlForZipDownload';
import UpdateContentMutation from '../../../graphql/mutations/mutation_updateContent';
import UpdateJrnMutation from '../../../graphql/mutations/mutation_updateJrn';
import ListContentByTypeQuery from '../../../graphql/queries/list-content-by-type';
import ListProjectsAndSubprojectsQuery from '../../../graphql/queries/list-projects-and-subprojects';
import GetRelationsByType from '../../../graphql/queries/get-relations-by-type';
import ListCompanyGlobalFinancialItems from '../../../graphql/queries/list-company-global-financial-items';
import GetCompanyCrew from '../../../graphql/queries/get-company-crew';
import { DeleteContentAction, DeleteJrnAction } from '../../../graphql/graphql';

// Helper imports
import {
  locationify,
  runAnalytics,
  monetaryRender,
  buildLabels,
  applyPreferences,
  capitalizeSentence,
} from '../../../helpers/index';

import {
  amountFilter,
  simpleSortForAmount,
} from '../../../helpers/react-datagrid-helpers';

import {
  ADMIN_CONTENT_QUERIES,
  CONTENT_DEFINITION,
  CONTENT_DETAILS_MODAL_MODE,
  CONTENT_TYPE,
  DEFAULT_DATE_FORMAT_WITH_TIME,
  GLOBAL_EXPENSE_STATUS,
  PRODUCT,
  TOP_PROJECT_ID,
  WORKFLOW_STAGE,
  WORKFLOW_STAGE_REASON,
} from '../../../config/appDefaults';

import themePalette from '../../../theme/palette';
import BulkAddLabelsDialog from '../bulk-add-labels-dialog';
import LoadingCover from '../../../components/LoadingCover/loadingCover';
import { useChunkedQuery, useRepetitiveQuery } from '../../../hooks';
// #endregion

// #region Constants
const levelFilterValues = [
  {
    name: 'projectName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'username',
    operator: 'inlist',
    type: 'select',
    value: null,
  },
  {
    name: 'amount',
    type: 'amount',
    operator: 'Starts With',
    value: '',
  },
  {
    name: 'date',
    operator: 'afterOrOn',
    type: 'date',
    value: '',
  },
  {
    name: 'poNumber',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'vendor',
    operator: 'inlist',
    type: 'select',
    value: null,
  },
  {
    name: 'documentNumber',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'billable',
    operator: 'inlist',
    type: 'select',
    value: null,
  },
  {
    name: 'description',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'labels',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'type',
    operator: 'inlist',
    type: 'select',
    value: null,
  },
  {
    name: 'isRecorded',
    operator: ['eq'],
    type: 'boolean',
    value: null,
  },
  {
    name: 'isRfiRequired',
    operator: ['eq'],
    type: 'boolean',
    value: null,
  },
  {
    name: 'dateCreated',
    operator: 'afterOrOn',
    type: 'date',
    value: '',
  },
];
// #endregion

// #region Global Scope
// for ReactDataGrid
window.moment = moment;
const filterTypes = {
  ...ReactDataGrid.defaultProps.filterTypes,
  ...amountFilter,
};

const useStyles = makeStyles(theme => ({
  scrollableColumn: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'scroll',
    height: 'calc(100vh - 64px)',
  },
  editButton: {
    padding: theme.spacing(0.5),
    margin: 0,
    minWidth: 0,
    color: '#aaa',
    '&:hover': {
      background: 'transparent',
      color: '#333',
    },
  },
  headerWrapper: {
    padding: theme.spacing(3),
  },
  actionButtonsContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    minWidth: 460,
    '& button': {
      marginLeft: theme.spacing(2),
    },
    '& div': {
      marginLeft: theme.spacing(2),
    },
  },
  headerIcon: {
    color: '#999',
    fontSize: 30,
    position: 'absolute',
    top: 5,
  },
  statModalLabel: {
    textTransform: 'uppercase',
  },
  statSectionWrapper: {
    paddingTop: 25,
  },
  statSectionHeader: {
    color: theme.palette.brandColorPrimary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    paddingBottom: 5,
  },
  statText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    paddingTop: 5,
    paddingBottom: 5,
  },
  helperText: {
    color: theme.palette.brandColorPrimary,
    alignItems: 'center',
  },
}));
// #endregion

const checkInOutFormat = 'MMM D, YYYY @ h:mma';
const downloadBlob = (blob, fileName = 'grid-data.csv') => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.position = 'absolute';
  link.style.visibility = 'hidden';

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

const ManageReceipts = ({
  // HOC props
  client,
  onDeleteContent,
  onDeleteJrn,
  managingCompanyInfo,
  userInfo,

  // MSTP props
  contentLabelSuggestions,
  columnSettings,
}) => {
  // #region Style Hooks
  const classes = useStyles();
  // #endregion

  // #region State Hooks
  const [includeArchive, setIncludeArchive] = useState(false);
  const [gridRef, setGridRef] = useState(null);
  const [entriesStats, setEntriesStats] = useState({ total: 0, avg: 0 });
  const [statsDialogInfo, setStatsDialogInfo] = useState({ open: false });
  const [filterValue, setFilterValue] = useState(null);
  const [columnOrder, setColumnOrder] = useState(null);
  const [tableKey, setTableKey] = useState(uniqueId());
  const resetTableKey = () => setTableKey(uniqueId());
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    title: '',
  });
  const [showMessageDialog, setShowMessageDialog] = useState({
    title: '',
    open: false,
    message: '',
  });
  const [currentlyBeingViewed, setCurrentlyBeingViewed] = useState(null);
  const [showContentDetailsModal, setShowContentDetailsModal] = useState({
    open: false,
    existingInfo: null,
  });
  const [showBulkAddLabelsDialog, setShowBulkAddLabelsDialog] = useState({
    open: false,
    selectedContentMap: {},
  });
  const [dataSource, setDataSource] = useState(null);
  const [columns, setColumns] = useState(null);
  const [tableReady, setTableReady] = useState(false);
  const [defaultSortInfo, setDefaultSortInfo] = useState({
    name: 'date',
    dir: -1,
    type: 'date',
  });
  const [selected, setSelected] = useState({});
  const [downloadLinkLoading, setDownloadLinkLoading] = useState(false);
  // #endregion

  // #region Query Hooks
  // Get active projects list
  const listProjectsAndSubprojectsAccessor = 'listProjectsAndSubprojects';
  const activeProjectsAndSubprojectsQuery = useChunkedQuery(
    ListProjectsAndSubprojectsQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        companyId: managingCompanyInfo.managingCompanyId,
      },
      skip: !managingCompanyInfo.managingCompanyId,
      accessor: listProjectsAndSubprojectsAccessor,
    }
  );

  // Get active projects and subproject query items
  const { items: activeProjectsAndSubprojects = null } = _.get(
    activeProjectsAndSubprojectsQuery.data,
    listProjectsAndSubprojectsAccessor,
    {}
  );

  // Get archived projects list if required
  const archivedProjectsAndSubprojectsQuery = useChunkedQuery(
    ListProjectsAndSubprojectsQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        companyId: managingCompanyInfo.managingCompanyId,
        archived: true,
      },
      skip: !managingCompanyInfo.managingCompanyId || !includeArchive,
      accessor: listProjectsAndSubprojectsAccessor,
    }
  );

  // Get archived projects and subproject query items
  const { items: archivedProjectsAndSubprojects = null } = _.get(
    archivedProjectsAndSubprojectsQuery.data,
    listProjectsAndSubprojectsAccessor,
    {}
  );

  // Get active receipts and bills
  const activeBillsAndReceiptsQuery = useQuery(ListContentByTypeQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      companyId: managingCompanyInfo.managingCompanyId,
      contentTypes: [CONTENT_TYPE.BILL, CONTENT_TYPE.RECEIPT],
    },
    skip: !managingCompanyInfo.managingCompanyId,
  });
  const activeBillsAndReceipts = _.get(
    activeBillsAndReceiptsQuery,
    'data.listContentByType.items',
    null
  );

  // Get archived bills and receipts if required
  const archivedBillsAndReceiptsQuery = useQuery(ListContentByTypeQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      companyId: managingCompanyInfo.managingCompanyId,
      contentTypes: [CONTENT_TYPE.BILL, CONTENT_TYPE.RECEIPT],
      archived: true,
    },
    skip: !managingCompanyInfo.managingCompanyId || !includeArchive,
  });
  const archivedBillsAndReceipts = _.get(
    archivedBillsAndReceiptsQuery,
    'data.listContentByType.items',
    null
  );

  // Get vendors
  const getVendorsQuery = useQuery(GetRelationsByType, {
    fetchPolicy: 'cache-and-network',
    skip: !managingCompanyInfo.managingCompanyId,
    variables: {
      companyId: managingCompanyInfo.managingCompanyId,
      type: 'VENDOR',
    },
  });
  const vendors = _.get(getVendorsQuery, 'data.getRelationsByType.items', null);

  // Get company crew
  const getUsersQuery = useQuery(GetCompanyCrew, {
    skip: !managingCompanyInfo.managingCompanyId,
    variables: {
      companyId: managingCompanyInfo.managingCompanyId,
    },
    fetchPolicy: 'cache-and-network',
  });
  const companyCrew = _.get(getUsersQuery, 'data.getCompanyCrew.items', null);

  // Get global expense items
  const listCompanyGlobalFinancialItemsAccessor =
    'listCompanyGlobalFinancialItems';
  const globalExpenseItemQuery = useRepetitiveQuery(
    ListCompanyGlobalFinancialItems,
    {
      skip: !managingCompanyInfo.managingCompanyId,
      variables: {
        companyId: managingCompanyInfo.managingCompanyId,
        typesToInclude: ['RECEIPT', 'BILL'],
      },
      fetchPolicy: 'cache-and-network',
      accessor: listCompanyGlobalFinancialItemsAccessor,
    }
  );
  const globalExpenses = _.get(
    globalExpenseItemQuery,
    `data.${listCompanyGlobalFinancialItemsAccessor}.items`,
    null
  );
  // #endregion

  // #region Mutation Hooks
  const [updateContent] = useMutation(UpdateContentMutation);
  const [updateJrn] = useMutation(UpdateJrnMutation);
  // #endregion

  // Generate report data object
  const reportData = useMemo(() => {
    let reportDataToReturn;
    if (
      activeProjectsAndSubprojects &&
      activeBillsAndReceipts &&
      vendors &&
      companyCrew &&
      globalExpenses
    ) {
      reportDataToReturn = {
        projects: [...activeProjectsAndSubprojects],
        expenses: [...activeBillsAndReceipts],
        vendors: [...vendors],
        companyCrew: [...companyCrew],
        globalExpenses: [...globalExpenses],
      };
    } else {
      // Missing key info, not yet ready
      return null;
    }

    if (includeArchive) {
      if (archivedProjectsAndSubprojects && archivedBillsAndReceipts) {
        reportDataToReturn.projects.push(...archivedProjectsAndSubprojects);
        reportDataToReturn.expenses.push(...archivedBillsAndReceipts);
      } else {
        return null;
      }
    }

    return reportDataToReturn;
  }, [
    includeArchive,
    activeBillsAndReceipts,
    activeProjectsAndSubprojects,
    archivedBillsAndReceipts,
    archivedProjectsAndSubprojects,
    companyCrew,
    globalExpenses,
    vendors,
  ]);

  // Check if company has rfi product, if so use new approach otherwise use old approach
  const hasRfiProduct = managingCompanyInfo?.products?.includes(PRODUCT.RFI);

  // View the selected content in a modal
  const viewThisContent = (itemInfo, index) => {
    if (!itemInfo) {
      setShowContentDetailsModal({ open: false });
      return;
    }

    const contentToView = _.find(reportData?.expenses || [], {
      contentId: itemInfo.contentId,
    });

    const globalExpense =
      itemInfo.globalExpense ||
      (contentToView?.globalExpenseId &&
        _.find(globalExpenses, { contentId: contentToView.globalExpenseId }));

    const itemIndex = !_.isNil(index)
      ? index
      : _.findIndex(gridRef.current.data, { contentId: itemInfo.contentId });

    if (globalExpense) {
      setCurrentlyBeingViewed({ item: contentToView, index: itemIndex });
      setShowContentDetailsModal({
        open: true,
        mode:
          globalExpense.type === CONTENT_TYPE.GLOBAL_RECEIPT
            ? CONTENT_DETAILS_MODAL_MODE.ADMIN_RECEIPT
            : CONTENT_DETAILS_MODAL_MODE.ADMIN_BILL,
        parentInfo: null,
        existingInfo: globalExpense,
        itemInfo,
        showNextPreviousButtons: true,
      });
    } else {
      // old receipt/bill which does not have globalExpenseId
      const parentProjectDetails = _.find(reportData?.projects || [], {
        contentId: itemInfo.projectId,
      });

      if (contentToView && parentProjectDetails) {
        // use addContentForm modal here instead
        setCurrentlyBeingViewed({ item: contentToView, index: itemIndex });
        setShowContentDetailsModal({
          open: true,
          mode:
            contentToView.type === CONTENT_TYPE.RECEIPT
              ? CONTENT_DETAILS_MODAL_MODE.ADMIN_RECEIPT
              : CONTENT_DETAILS_MODAL_MODE.ADMIN_BILL,
          parentInfo: parentProjectDetails,
          existingInfo: contentToView,
          itemInfo,
          showNextPreviousButtons: true,
        });
      } else {
        // throw up a modal to let them know something went wrong
        // console.log('something went wrong getting the content');
      }
    }
  };

  // Get adjacent item in modal view
  const getNextItem = nextOrPrevious => {
    const getNextItemHelper = (currentItem, currentIndex) => {
      const currentData = gridRef.current.data; // with filters applied
      let indexOfCurrentItem = _.findIndex(currentData, {
        contentId: currentItem?.contentId || 'DOES_NOT_EXIST', // Handle case where currentItem is deleted
      });

      if (indexOfCurrentItem < 0) {
        // currentItem has been deleted
        indexOfCurrentItem = currentIndex - 1;
      }

      const indexOfRequested =
        nextOrPrevious === 'next'
          ? indexOfCurrentItem + 1
          : indexOfCurrentItem - 1;

      let nextItemIndex;
      if (nextOrPrevious === 'next' && indexOfRequested >= currentData.length) {
        // if next and index is more than the length of currentData, go to start of currentData?
        nextItemIndex = 0;
      } else if (nextOrPrevious === 'previous' && indexOfRequested < 0) {
        // if previous and index is less than 0, go to end of currentData?
        nextItemIndex = currentData.length - 1;
      } else {
        nextItemIndex = indexOfRequested;
      }
      const nextItem = currentData[nextItemIndex];

      return { item: nextItem, index: nextItemIndex };
    };

    if (!currentlyBeingViewed) {
      return;
    }

    let nextItemData = getNextItemHelper(
      currentlyBeingViewed.item,
      currentlyBeingViewed.index
    );

    // skip item which has the same globalExpenseId as currentlyBeingViewed.globalExpenseId
    while (
      nextItemData.item?.globalExpenseId &&
      nextItemData.item.globalExpenseId ===
        currentlyBeingViewed.item?.globalExpenseId
    ) {
      nextItemData = getNextItemHelper(nextItemData.item, nextItemData.index);
    }

    viewThisContent(nextItemData.item, nextItemData.index);
  };

  // Delete this content
  const deleteThisContent = async (data, goToNextItem = false) => {
    const doDelete = async () => {
      const { contentId, projectId, globalExpenseId } = data;

      if (!globalExpenseId) {
        await onDeleteContent(contentId, projectId, {
          fromWhichAdminTool: ADMIN_CONTENT_QUERIES.RECEIPTS_AND_BILLS,
          includeArchive,
        });
      } else {
        const expenseToDelete = _.find(reportData.expenses, { contentId });
        const globalExpense = _.find(reportData.globalExpenses, {
          contentId: globalExpenseId,
        });

        const expenseAmount = Number(expenseToDelete.amount?.value);
        const globalExpenseAmount = Number(globalExpense.amount?.value);
        // global expense item has only one expense item
        if (expenseAmount === globalExpenseAmount) {
          let allowToDelete = true;
          if (
            globalExpense.type === CONTENT_TYPE.GLOBAL_BILL &&
            globalExpense.contentStatus !== GLOBAL_EXPENSE_STATUS.UNPAID
          ) {
            // global bill has bill payments attached to it
            allowToDelete = false;
            setShowMessageDialog({
              open: true,
              message:
                'Cannot delete this bill as it has payments. Please delete all the payments first!',
            });
          }

          if (allowToDelete) {
            // delete the expense item
            await onDeleteContent(
              expenseToDelete.contentId,
              expenseToDelete.jrnId,
              {
                fromWhichAdminTool: ADMIN_CONTENT_QUERIES.RECEIPTS_AND_BILLS,
                includeArchive,
              }
            );

            // delete global expense
            onDeleteJrn(globalExpense.contentId, globalExpense.type);
          }
        } else {
          // global expense item has more than one expense
          // open editing modal to edit the global expense item
          // and remove this expense item by setting its deletionPending flag to true.

          setShowMessageDialog({
            title: 'Info',
            open: true,
            message: `This ${expenseToDelete.type} is shared with other projects. You will be redirected to the editing form to allocate the remaining amount.`,
            onConfirm: () => {
              setShowMessageDialog({ open: false });
              setShowContentDetailsModal({
                open: true,
                mode: CONTENT_DETAILS_MODAL_MODE.ADMIN_RECEIPT_BILL_DELETE,
                projectInfo: null,
                existingInfo: globalExpense,
                contentToDelete: expenseToDelete,
              });
            },
          });
        }
      }
      if (goToNextItem) {
        getNextItem('next');
      }
    };

    setDialogInfo({
      title: 'Just making sure...',
      message: `Are you sure you want to delete this ${_.toLower(
        CONTENT_DEFINITION[data.type].name
      )}? This cannot be undone.`,
      open: true,
      onClose: () => {
        setDialogInfo(currentState => ({ ...currentState, open: false }));
      },
      hideCancel: false,
      onConfirm: async () => {
        await doDelete();
      },
    });
  };

  // Build data source and table info
  const buildDataGrid = ({ withoutPreferences = false } = {}) => {
    if (!reportData) {
      // If we don't have everything we need, then wait
      return;
    }

    // seperate the content by project
    const splitContentOnAttribute = (content, attribute) => {
      const contentByAttribute = new Map();
      content.forEach(item => {
        if (!contentByAttribute.get(item[attribute])) {
          contentByAttribute.set(item[attribute], []);
        }
        contentByAttribute.get(item[attribute]).push(item);
      });
      return contentByAttribute;
    };
    const adminContentByProject = splitContentOnAttribute(
      reportData.expenses,
      'jrnId'
    );

    const projectsMap = _.keyBy(reportData.projects, 'contentId');
    const globalExpensesMap = _.keyBy(globalExpenses, 'contentId');

    const uniqueProjectNames = [];
    const uniqueTypes = [];
    const allExpenses = [];
    adminContentByProject.forEach((arrayOfContent, projectId) => {
      if (!arrayOfContent || !arrayOfContent.length) {
        return;
      }
      const projectInfo = projectsMap[projectId];

      if (!projectInfo) return;

      if (projectInfo && arrayOfContent && reportData.companyCrew) {
        _.forEach(arrayOfContent, expense => {
          const user = _.find(
            reportData.companyCrew,
            { userId: expense.creatorId },
            'not found'
          );
          const username = user ? user.username : 'n/a';
          let amountToUse = _.get(expense, 'amount.value', -Number.EPSILON);
          if (
            typeof amountToUse === 'number' &&
            expense.contentStatus === 'refund'
          ) {
            amountToUse *= -1;
          }

          const projectDescription = projectInfo.description
            ? fromString(projectInfo.description)
            : null;
          const description = expense.description
            ? fromString(expense.description)
            : null;

          let projectLatLon;
          if (
            projectInfo.address &&
            projectInfo.latitude &&
            projectInfo.longitude
          ) {
            projectLatLon = {
              lat: projectInfo.latitude,
              lon: projectInfo.longitude,
            };
          }

          let projectName = projectInfo.title;
          if (projectInfo.jrnId && projectInfo.jrnId !== TOP_PROJECT_ID) {
            const parentProjectInfo = _.find(reportData.projects, {
              contentId: projectInfo.jrnId,
            });
            if (parentProjectInfo) {
              projectName = `${parentProjectInfo.title} > ${projectInfo.title}`;
            }
          }

          if (!uniqueProjectNames.includes(projectName)) {
            uniqueProjectNames.push(projectName);
          }

          if (!uniqueTypes.includes(expense.type)) {
            uniqueTypes.push(expense.type);
          }

          const globalExpense = globalExpensesMap[expense.globalExpenseId];
          const vendor = _.get(globalExpense, 'vendor.name', null);
          const labels = _.join(globalExpense?.labels || expense.labels, ', ');

          const workflowStage = globalExpense
            ? globalExpense.workflowStage
            : expense.workflowStage;

          let isRfiRequired;
          if (hasRfiProduct) {
            // If they have the RFI product, check to see if focused item has rfiIds
            isRfiRequired = globalExpense
              ? globalExpense.rfiIds?.length > 0
              : expense.rfiIds?.length > 0;
          } else {
            // If they don't have the RFI product, check to see if the focused item has the workflow stage reason
            const workflowStageReason = globalExpense
              ? globalExpense.workflowStageReason
              : expense.workflowStageReason;

            isRfiRequired =
              workflowStageReason === WORKFLOW_STAGE_REASON.RFI_REQUIRED;
          }

          const receiptImage = globalExpense
            ? globalExpense.contentUrl
            : expense.contentUrl;

          const whatToAdd = {
            projectId: projectInfo.contentId,
            projectName,
            projectAddress: projectInfo.address || null,
            projectLatLon,
            projectDescription,
            userId: expense.creatorId,
            username,

            globalExpenseId: expense.globalExpenseId,
            documentNumber: globalExpense?.documentNumber || null,
            poNumber: globalExpense?.poNumber || null,
            billable: expense.billable ? 'YES' : '',
            vendor,

            parentId: expense.jrnId,
            contentId: expense.contentId,
            date: moment(expense.date).format('YYYY-MM-DDTHH:mm:ssZ'),
            amount: amountToUse, // amount - negative if refund
            description,
            type: expense.type,
            labels,
            receiptImage,
            isRecorded: workflowStage === WORKFLOW_STAGE.RECORDED,
            isRfiRequired,
            dateCreated: moment(expense.dateCreated).format(
              'YYYY-MM-DDTHH:mm:ssZ'
            ),
            rfiIds: expense.rfiIds,
            globalExpense,
          };

          allExpenses.push(whatToAdd);
        });
      }
    });

    uniqueProjectNames.sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    // set the datasource
    setDataSource(allExpenses);

    // prep data by allowlisting wanted attributes
    const uniqueUsernames = companyCrew.map(user => user.username);
    if (uniqueUsernames) {
      uniqueUsernames.sort((a, b) => {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
    }
    const columnMinWidthExtraSmall = 82;
    const columnMinWidthSmall = 100;
    const columnMinWidthMedium = 180;
    const showInTable = [
      {
        name: 'edit',
        header: null,
        minWidth: columnMinWidthExtraSmall,
        maxWidth: columnMinWidthExtraSmall,
      },
      {
        name: 'userId',
        header: 'User Id',
        defaultVisible: false,
      },
      {
        name: 'username',
        header: 'Username',
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: 'All',
          multiple: true,
          wrapMultiple: true,
          dataSource: uniqueUsernames.map(value => ({
            id: value,
            label: value,
          })),
        },
        defaultFlex: 50,
        minWidth: columnMinWidthSmall,
      },
      {
        name: 'projectId',
        header: 'Project Id',
        defaultVisible: false,
      },
      {
        name: 'projectName',
        header: 'Project Name',
        defaultFlex: 50,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'projectAddress',
        header: 'Project Address',
        defaultFlex: 30,
        defaultVisible: false,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'projectDescription',
        header: 'Project Description',
        defaultVisible: false,
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'vendor',
        header: 'Vendor',
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: '',
          multiple: true,
          wrapMultiple: true,
          dataSource: _.orderBy(
            vendors.map(({ name }) => ({
              id: name,
              label: name,
            })),
            ['label'],
            ['asc']
          ),
        },
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'type',
        header: 'Type',
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: 'All',
          multiple: true,
          wrapMultiple: true,
          dataSource: uniqueTypes.map(value => ({
            id: value,
            label: value,
          })),
        },
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'documentNumber',
        header: 'Bill Number',
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'billable',
        header: 'Billable?',
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: 'All',
          multiple: true,
          wrapMultiple: true,
          dataSource: [
            { id: 'YES', label: 'YES' },
            { id: '', label: 'NO' },
          ],
        },
        defaultFlex: 20,
        minWidth: 110,
        textAlign: 'center',
      },
      {
        name: 'amount',
        header: 'Total',
        type: 'amount',
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
        sort: simpleSortForAmount,
      },
      {
        name: 'description',
        header: 'Notes',
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'labels',
        header: 'Labels',
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'date',
        header: 'Date',
        dateFormat: 'YYYY-MM-DD',
        filterEditor: DateFilter,
        filterEditorProps: () => {
          // for range and notinrange operators, the index is 1 for the after field
          return {
            dateFormat: checkInOutFormat, // causing rtl error in console
            cancelButton: false,
            highlightWeekends: false,
          };
        },
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'poNumber',
        header: 'PO Number',
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'receiptImage',
        header: <ImageIcon className={classes.headerIcon} />,
        // header: 'Check-In Photo',
        headerAlign: 'center',
        headerVerticalAlign: 'bottom',
        textAlign: 'center',
        defaultFlex: 10,
        minWidth: columnMinWidthSmall,
      },
      {
        name: 'isRecorded',
        header: 'Is Recorded?',
        textAlign: 'center',
        defaultFlex: 10,
        minWidth: columnMinWidthSmall,
        filterEditor: BoolFilter,
      },
      {
        name: 'isRfiRequired',
        header: "RFI Req'd",
        textAlign: 'center',
        defaultFlex: 10,
        minWidth: columnMinWidthSmall,
        filterEditor: BoolFilter,
      },
      {
        name: 'dateCreated',
        header: 'Date Added',
        dateFormat: 'YYYY-MM-DD',
        filterEditor: DateFilter,
        filterEditorProps: () => {
          // for range and notinrange operators, the index is 1 for the after field
          return {
            dateFormat: checkInOutFormat, // causing rtl error in console
            cancelButton: false,
            highlightWeekends: false,
          };
        },
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
      },
    ];

    // build the table columns off the datasource
    const columnsInfo = showInTable.map(attribute => {
      const attributePlus = { ...attribute };
      if (attribute.name === 'edit') {
        attributePlus.render = ({ data, rowIndex }) => {
          return (
            <>
              <Tooltip title="Delete Entry">
                <Button
                  onClick={() => {
                    deleteThisContent(data);
                  }}
                  className={classes.editButton}
                >
                  <DeleteOutlineIcon />
                </Button>
              </Tooltip>
              <Tooltip title="View Entry">
                <Button
                  onClick={() => {
                    viewThisContent(data, rowIndex);
                  }}
                  className={classes.editButton}
                >
                  <VisibilityIcon />
                </Button>
              </Tooltip>
            </>
          );
        };
      } else if (attribute.name === 'projectName') {
        attributePlus.render = ({ value, data }) => {
          return (
            <a
              href={`/projects/${data.projectId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="basicStyledLink"
            >
              {value}
            </a>
          );
        };
      } else if (attribute.name === 'projectAddress') {
        attributePlus.render = ({ value, data }) => {
          if (!value) return null;
          const locationText = value;
          let latLonAsLink;
          if (data.projectLatLon) {
            latLonAsLink = locationify(
              data.projectLatLon.lat,
              data.projectLatLon.lon
            );
          }
          return (
            <a
              href={latLonAsLink}
              target="_blank"
              rel="noopener noreferrer"
              className="basicStyledLink"
            >
              {locationText}
            </a>
          );
        };
      } else if (attribute.name === 'amount') {
        attributePlus.render = ({ value }) => {
          if (typeof value === 'string') return value;
          if (value === -Number.EPSILON) {
            return 'n/a';
          }
          return monetaryRender({ value, withDecimals: true });
        };
      } else if (attribute.name === 'description') {
        attributePlus.render = ({ value }) => {
          if (!value) return null;
          return value;
        };
      } else if (
        attribute.name === 'date' ||
        attribute.name === 'dateCreated'
      ) {
        attributePlus.render = ({ value }) => {
          if (!value) return 'n/a';
          return moment(value).format('MMM D, YYYY @ h:mma');
        };
      } else if (attribute.name === 'receiptImage') {
        attributePlus.render = ({ value, data }) => {
          if (!value) return 'n/a';
          return (
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              className="basicStyledLink"
              style={{ textTransform: 'capitalize' }}
            >
              {data.type || 'Receipt'}
            </a>
          );
        };
      } else if (attribute.name === 'isRecorded') {
        attributePlus.render = ({ value, data }) => {
          const isBookkeepingCustomer = !!managingCompanyInfo.isBookkeepingCustomer;
          const isCompanyBookkeeper = !!managingCompanyInfo.isCompanyBookkeeper;
          // disabled if they are a bookkeeping customer and not a bookkeeper
          //    or if they are not a company admin
          const allowFor =
            (isBookkeepingCustomer && isCompanyBookkeeper) ||
            (!isBookkeepingCustomer && managingCompanyInfo.isCompanyAdmin);
          const disabled = !allowFor;
          return (
            <Checkbox
              color="primary"
              onChange={() => {
                if (data.globalExpenseId) {
                  updateJrn({
                    variables: {
                      contentId: data.globalExpenseId,
                      workflowStage: value
                        ? WORKFLOW_STAGE.UNRECORDED
                        : WORKFLOW_STAGE.RECORDED,
                    },
                  });
                } else {
                  updateContent({
                    variables: {
                      contentId: data.contentId,
                      jrnId: data.projectId,
                      workflowStage: value
                        ? WORKFLOW_STAGE.UNRECORDED
                        : WORKFLOW_STAGE.RECORDED,
                    },
                  });
                }
              }}
              checked={value}
              disabled={disabled}
            />
          );
        };
      } else if (attribute.name === 'isRfiRequired') {
        attributePlus.render = ({ value, data }) => {
          return (
            <Checkbox
              color="primary"
              checked={value}
              onChange={() => {
                if (!hasRfiProduct && managingCompanyInfo.isCompanyBookkeeper) {
                  if (data.globalExpenseId) {
                    updateJrn({
                      variables: {
                        contentId: data.globalExpenseId,
                        workflowStageReason: value
                          ? null
                          : WORKFLOW_STAGE_REASON.RFI_REQUIRED,
                      },
                    });
                  } else {
                    updateContent({
                      variables: {
                        contentId: data.contentId,
                        jrnId: data.projectId,
                        workflowStageReason: value
                          ? null
                          : WORKFLOW_STAGE_REASON.RFI_REQUIRED,
                      },
                    });
                  }
                }
              }}
              disabled={
                hasRfiProduct || !managingCompanyInfo.isCompanyBookkeeper
              }
            />
          );
        };
      }

      // now that we have all the info we need, build the full column object
      if (!attributePlus.name) {
        return null;
      }

      return {
        ...attributePlus,
        header:
          attributePlus.header !== undefined
            ? attributePlus.header
            : capitalizeSentence(attributePlus.name),
      };
    });

    const {
      newColumnsInfo: newColumns,
      newSortInfo,
      newFilterInfo,
      newColumnOrder,
    } = applyPreferences({
      preferences: withoutPreferences ? null : columnSettings,
      defaultColumnsInfo: columnsInfo,
      defaultFilterInfo: levelFilterValues,
      defaultSortInfo,
      defaultColumnOrder: null,
    });

    if (!tableReady || withoutPreferences) {
      setColumns(newColumns);
      setDefaultSortInfo(newSortInfo);
      setFilterValue(newFilterInfo);
      setColumnOrder(newColumnOrder);

      resetTableKey(); // this is to force the table to re-render
    }

    if (!tableReady) {
      setTableReady(true);
    }
  };

  // #region Effect Hooks
  useEffect(() => {
    if (reportData) {
      // If expense content changes, update the labels
      if (reportData.expenses) {
        const labels = buildLabels(reportData.expenses);
        const allContentLabels = _.uniq([
          ...labels,
          ...contentLabelSuggestions,
        ]);
        store.dispatch({
          type: 'UPDATE_CONTENT_LABEL_SUGGESTIONS',
          payload: { contentLabelSuggestions: allContentLabels },
        });
      }

      // if a modal is open and content changes, update the content in the modal
      if (currentlyBeingViewed) {
        const updateReceipt = _.find(gridRef.current.dataSource, {
          contentId: currentlyBeingViewed.item?.contentId,
        });
        if (updateReceipt) {
          viewThisContent(updateReceipt);
        }
      }

      buildDataGrid();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);
  // #endregion

  // #region Handler functions
  const onSelectionChange = useCallback(({ selected: isSelected }) => {
    setSelected(isSelected);
  }, []);

  const handleIncludeArchiveChange = event => {
    setIncludeArchive(event.target.checked);
  };

  // Get Zip URL
  const getZipUrl = async listOfAssets => {
    let zipUrlResponse;
    try {
      zipUrlResponse = await client.query({
        query: GetSignedUrlForZipDownload,
        variables: { listOfAssets },
        fetchPolicy: 'no-cache',
      });
      // then find the piece of content needing to be edited
    } catch (e) {
      // console.log('whatShouldStatusBe query e: ', e);
    }
    const urlToReturn = _.get(
      zipUrlResponse,
      'data.getSignedUrlForZipDownload'
    );
    return urlToReturn;
  };

  // Download Zip of selected items
  const downloadZipOfSelected = async () => {
    setDownloadLinkLoading(true);
    // build list of assets to download
    const listOfAssets = [];
    _.each(selected, entry => {
      if (entry.receiptImage) {
        listOfAssets.push(entry.receiptImage);
      }
    });
    if (!listOfAssets || !listOfAssets.length) {
      setDownloadLinkLoading(false);
      setShowMessageDialog({
        title: 'Nothing to download',
        open: true,
        message: 'None of the items you selected have an image.',
      });
      return;
    }

    // run the query to get the URL
    const downloadUrl = await getZipUrl(listOfAssets);
    if (!downloadUrl) return;
    // trigger download from url
    window.open(downloadUrl);
    setDownloadLinkLoading(false);
    // clear selected
    setSelected({});
  };

  const handleDeleteBillPaymentConfirm = async billPayment => {
    const { jrn: globalPayment } = billPayment;
    const billPaymentAmount = Number(billPayment.amount?.value);
    const globalPaymentAmount = Number(globalPayment.amount?.value);
    if (billPaymentAmount === globalPaymentAmount) {
      // global payment item has only one bill payment
      // delete the bill payment item
      await onDeleteContent(billPayment.contentId, billPayment.jrnId, {
        content: billPayment,
      });

      // delete global payement
      onDeleteJrn(globalPayment.contentId, globalPayment.type);
    } else {
      // global payment item has more than one bill payment
      // open editing modal to edit the global payment item
      // and remove this bill payment item by setting its deletionPending flag to true.

      setShowMessageDialog({
        title: 'Info',
        open: true,
        message: `This bill payment is shared with other bills. You will be redirected to the editing form to allocate the remaining amount.`,
        onConfirm: () => {
          setShowMessageDialog({ open: false });
          setShowContentDetailsModal({
            open: true,
            mode: CONTENT_DETAILS_MODAL_MODE.EDIT,
            projectInfo: null,
            existingInfo: globalPayment,
            contentToDelete: billPayment,
          });
        },
      });
    }
  };

  const handleDeleteBillPayment = billPayment => {
    setDialogInfo({
      title: 'Just making sure...',
      message:
        'Are you sure you want to delete this payment? This cannot be undone.',
      open: true,
      onClose: () => {
        setDialogInfo(currentState => ({ ...currentState, open: false }));
      },
      hideCancel: false,
      contentToDelete: billPayment,
      onConfirm: () => {
        handleDeleteBillPaymentConfirm(billPayment);
      },
    });
  };
  // #endregion

  const exportCSV = (allOrVisibleColumns = 'allColumns') => {
    runAnalytics('Contents', {
      contentAction: 'Export Content',
      userId: userInfo.userId,
      username: userInfo.username,
      type: 'Admin Receipts',
    });
    const currentColumns = gridRef.current[allOrVisibleColumns];
    const columnsToUpdate = {
      receiptImage: { header: 'Receipt Image' },
    };
    currentColumns.forEach((columnInfo, index) => {
      if (columnsToUpdate[columnInfo.name]) {
        currentColumns[index].header = columnsToUpdate[columnInfo.name].header;
      }
    });

    // remove the delete/edit column
    _.remove(currentColumns, column => column.name === 'edit');
    _.remove(currentColumns, column => column.id === '__checkbox-column');

    // Note: to export all data use gridRef.current.dataSource
    const rows = gridRef.current.data.map(data => {
      return currentColumns.map(column => {
        if (column.id === 'date') {
          return moment(data[column.id]).format(DEFAULT_DATE_FORMAT_WITH_TIME);
        }
        // default case
        return data[column.id];
      });
    });

    const csvOutput = Papa.unparse({
      fields: currentColumns.map(column => column.header),
      data: rows,
    });

    const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8;' });

    const filename = `receipt-export-${moment().format(
      'YYYY-MM-DD-HH-mm-ss'
    )}.csv`;

    downloadBlob(blob, filename);
  };

  const calcInfoStats = () => {
    const receipts = [];
    const bills = [];
    gridRef.current.data.forEach(item => {
      if (item.type === 'receipt') {
        receipts.push(item);
      } else {
        bills.push(item);
      }
    });

    // build numbers for receipts
    const receiptEntriesCount = receipts.length;
    const receiptTotal = receipts.reduce((total, data) => {
      return total + (data.amount || 0);
    }, 0);
    const receiptAvg = receiptEntriesCount
      ? Number.parseFloat(receiptTotal / receiptEntriesCount)
      : 0;

    // build numbers for bills
    const billEntriesCount = bills.length;
    const billTotal = bills.reduce((total, data) => {
      return total + (data.amount || 0);
    }, 0);
    const billAvg = billEntriesCount
      ? Number.parseFloat(billTotal / billEntriesCount)
      : 0;

    const combinedTotal = Number.parseFloat(receiptTotal + billTotal);

    setEntriesStats({
      receiptEntriesCount,
      receiptTotalText: monetaryRender({
        value: receiptTotal,
        withDecimals: true,
      }),
      receiptAvg: monetaryRender({ value: receiptAvg, withDecimals: true }),
      billEntriesCount,
      billTotalText: monetaryRender({ value: billTotal, withDecimals: true }),
      billAvg: monetaryRender({ value: billAvg, withDecimals: true }),
      combinedTotal: monetaryRender({
        value: combinedTotal,
        withDecimals: true,
      }),
    });
    setStatsDialogInfo({ open: true });
  };

  const openAddModal = () => {
    setShowContentDetailsModal({
      mode: CONTENT_DETAILS_MODAL_MODE.ADMIN_RECEIPT_BILL_ADD,
      open: true,
    });
  };

  const handleViewGlobalItem = globalItem => {
    setShowContentDetailsModal({
      open: true,
      mode: CONTENT_DETAILS_MODAL_MODE.ADMIN_GLOBAL_ITEM_VIEW,
      existingInfo: globalItem,
      parentInfo: null,
      showNextPreviousButtons: false,
    });
  };

  const handleStatDialogClose = () => {
    setStatsDialogInfo({
      ...statsDialogInfo,
      open: false,
    });
  };

  const copyThis = textToCopy => {
    copyToClipboard(textToCopy);
  };

  const scrollProps = {
    ...ReactDataGrid.defaultProps.scrollProps,
    autoHide: false,
    scrollThumbWidth: 12,
    scrollThumbStyle: {
      background: themePalette.brandColorPrimary,
    },
  };

  const downloadbuttonDisable = _.isEmpty(selected) || downloadLinkLoading;
  const labelsButtonDisable = _.isEmpty(selected);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const showPreferencesMenu = event => {
    setMenuAnchorEl(event.currentTarget);
  };
  const closePreferencesMenu = () => {
    setMenuAnchorEl(null);
  };

  const saveColumnPrefs = () => {
    const allColumnInfo = gridRef.current.columnsMap;
    store.dispatch({
      type: 'SET_ADMIN_TOOLS_SETTINGS',
      payload: { receiptsAndBills: allColumnInfo },
    });
    closePreferencesMenu();
  };

  const clearColumnPrefs = () => {
    store.dispatch({
      type: 'SET_ADMIN_TOOLS_SETTINGS',
      payload: { receiptsAndBills: null },
    });
    // rebuild the table now
    buildDataGrid({ withoutPreferences: true });
    closePreferencesMenu();
  };

  return (
    <div className={classes.scrollableColumn}>
      {tableReady && reportData ? (
        <>
          <div style={{ flex: 0, background: '#eee' }}>
            <Grid
              container
              justifyContent="space-between"
              className={classes.headerWrapper}
              spacing={1}
            >
              <Grid container item xs={12} justifyContent="space-between">
                <Grid item>
                  <AdminToolsTitle
                    Icon={CONTENT_DEFINITION[CONTENT_TYPE.RECEIPT].Icon}
                    titleText="Manage Receipts &amp; Bills"
                  />
                </Grid>
                <Grid item className={classes.actionButtonsContainer}>
                  <AdminToolsIconButton
                    tooltipText="Select items then click to add labels"
                    onClick={() => {
                      setShowBulkAddLabelsDialog({
                        open: true,
                        selectedContentMap: selected,
                      });
                    }}
                    disabled={labelsButtonDisable}
                  >
                    <LocalOfferIcon />
                  </AdminToolsIconButton>

                  <AdminToolsIconButton
                    tooltipText="Select items then click to download"
                    onClick={downloadZipOfSelected}
                    disabled={downloadbuttonDisable}
                    isLoading={downloadLinkLoading}
                  >
                    <CloudDownloadIcon />
                  </AdminToolsIconButton>

                  <AdminToolsIconButton
                    tooltipText="Export to Excel/CSV"
                    onClick={() => {
                      exportCSV();
                    }}
                  >
                    <PublishIcon />
                  </AdminToolsIconButton>

                  <AdminToolsIconButton
                    tooltipText="View stats for current data"
                    onClick={calcInfoStats}
                  >
                    <EqualizerIcon />
                  </AdminToolsIconButton>

                  <AdminToolsIconButton
                    tooltipText="Add an item"
                    onClick={openAddModal}
                  >
                    <AddIcon />
                  </AdminToolsIconButton>

                  <AdminToolsIconButton
                    tooltipText="View other options"
                    onClick={showPreferencesMenu}
                  >
                    <MoreHorizIcon />
                  </AdminToolsIconButton>
                  <Menu
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={Boolean(menuAnchorEl)}
                    onClose={closePreferencesMenu}
                  >
                    <MenuItem onClick={saveColumnPrefs}>
                      <SaveIcon />
                      &nbsp;&nbsp;Save Column Preferences
                    </MenuItem>
                    <MenuItem onClick={clearColumnPrefs}>
                      <BlockIcon />
                      &nbsp;&nbsp;Clear Column Preferences
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
              <Grid item xs={6} />
              <Grid
                item
                xs={6}
                style={{ justifyContent: 'flex-end', display: 'flex' }}
              >
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={includeArchive}
                      onChange={handleIncludeArchiveChange}
                    />
                  }
                  label="Include archived projects"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
          </div>
          <div style={{ flex: 1, height: 200 }}>
            <ReactDataGrid
              key={tableKey}
              // grab the ref
              onReady={setGridRef}
              // set which property is used as the ID
              idProperty="contentId"
              // set which columns show
              columns={columns}
              // column order
              defaultColumnOrder={columnOrder}
              // set the data to build from
              dataSource={dataSource}
              // set basic styling for the overall table
              style={{ height: '100%', minHeight: '100%' }}
              className="reactDataGridFixLastItemOverlap"
              // filtering
              enableFiltering
              defaultFilterValue={filterValue}
              filterTypes={filterTypes}
              // sorting
              defaultSortInfo={defaultSortInfo}
              allowUnsort={false}
              // scrollbar
              scrollProps={scrollProps}
              headerHeight={0}
              // checkbox column
              checkboxColumn
              selected={selected}
              onSelectionChange={onSelectionChange}
            />
          </div>
        </>
      ) : (
        <LoadingCover loader="linear">
          <Typography variant="h3" align="center">
            Loading data from across your projects...
          </Typography>
        </LoadingCover>
      )}

      {dialogInfo.open && (
        <OkCancelDialog
          okButtonText="Yes"
          okButtonVariant="text"
          autoFocusButton="cancelButton"
          title={dialogInfo.title}
          open={dialogInfo.open}
          onClose={dialogInfo.onClose}
          hideCancel={dialogInfo.hideCancel}
          onConfirm={dialogInfo.onConfirm}
        >
          <Typography>{dialogInfo.message}</Typography>
        </OkCancelDialog>
      )}
      {showMessageDialog.open && (
        <OkCancelDialog
          open={showMessageDialog.open}
          title={showMessageDialog.title || 'Oops...'}
          okButtonVariant="text"
          hideCancel
          onConfirm={
            showMessageDialog.onConfirm
              ? showMessageDialog.onConfirm
              : () => {
                  setShowMessageDialog({ open: false });
                }
          }
        >
          <Typography>
            {showMessageDialog.message || 'Something went wrong.'}
          </Typography>
        </OkCancelDialog>
      )}
      {statsDialogInfo.open && (
        <Dialog
          open
          onClose={handleStatDialogClose}
          aria-labelledby="data stats"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Typography variant="h4" className={classes.statSectionHeader}>
              Receipts
            </Typography>
            <Typography variant="h6" className={classes.statText}>
              <span className={classes.statModalLabel}># of Receipts</span>
              <Tooltip title="Click to copy">
                <Button
                  onClick={() => {
                    copyThis(entriesStats.receiptEntriesCount);
                  }}
                >
                  {entriesStats.receiptEntriesCount}
                </Button>
              </Tooltip>
            </Typography>
            <Typography variant="h6" className={classes.statText}>
              <span className={classes.statModalLabel}>Receipts Total</span>
              <Tooltip title="Click to copy">
                <Button
                  onClick={() => {
                    copyThis(entriesStats.receiptTotalText);
                  }}
                >
                  {entriesStats.receiptTotalText}
                </Button>
              </Tooltip>
            </Typography>
            <Typography variant="h6" className={classes.statText}>
              <span className={classes.statModalLabel}>Avg Receipt Amount</span>
              <Tooltip title="Click to copy">
                <Button
                  onClick={() => {
                    copyThis(entriesStats.receiptAvg);
                  }}
                >
                  {entriesStats.receiptAvg}
                </Button>
              </Tooltip>
            </Typography>

            <div className={classes.statSectionWrapper}>
              <Typography variant="h4" className={classes.statSectionHeader}>
                Bills
              </Typography>
              <Typography variant="h6" className={classes.statText}>
                <span className={classes.statModalLabel}># of Bills</span>
                <Tooltip title="Click to copy">
                  <Button
                    onClick={() => {
                      copyThis(entriesStats.billEntriesCount);
                    }}
                  >
                    {entriesStats.billEntriesCount}
                  </Button>
                </Tooltip>
              </Typography>
              <Typography variant="h6" className={classes.statText}>
                <span className={classes.statModalLabel}>Bills Total</span>
                <Tooltip title="Click to copy">
                  <Button
                    onClick={() => {
                      copyThis(entriesStats.billTotalText);
                    }}
                  >
                    {entriesStats.billTotalText}
                  </Button>
                </Tooltip>
              </Typography>
              <Typography variant="h6" className={classes.statText}>
                <span className={classes.statModalLabel}>Avg Bill Amount</span>
                <Tooltip title="Click to copy">
                  <Button
                    onClick={() => {
                      copyThis(entriesStats.billAvg);
                    }}
                  >
                    {entriesStats.billAvg}
                  </Button>
                </Tooltip>
              </Typography>
            </div>
            <div className={classes.statSectionWrapper}>
              <Typography variant="h4" className={classes.statSectionHeader}>
                Overall
              </Typography>
              <Typography variant="h6" className={classes.statText}>
                <span className={classes.statModalLabel}>Combined Total</span>
                <Tooltip title="Click to copy">
                  <Button
                    onClick={() => {
                      copyThis(entriesStats.combinedTotal);
                    }}
                  >
                    {entriesStats.combinedTotal}
                  </Button>
                </Tooltip>
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleStatDialogClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {showContentDetailsModal.open && (
        <ContentDetailsModal
          mode={showContentDetailsModal.mode}
          canEdit={showContentDetailsModal.canEdit}
          parentInfo={showContentDetailsModal.parentInfo}
          existingInfo={showContentDetailsModal.existingInfo}
          includeArchive={includeArchive}
          contentToDelete={showContentDetailsModal.contentToDelete}
          onViewGlobalItem={handleViewGlobalItem}
          onDeleteBillPayment={handleDeleteBillPayment}
          onClose={() => {
            setShowContentDetailsModal({ open: false });
            setCurrentlyBeingViewed(null);
          }}
          getNextItem={
            showContentDetailsModal.showNextPreviousButtons ? getNextItem : null
          }
          onDeleteButtonClick={() => {
            deleteThisContent(showContentDetailsModal.itemInfo, true);
          }}
          fromWhichAdminTool={ADMIN_CONTENT_QUERIES.RECEIPTS_AND_BILLS}
        />
      )}

      {showBulkAddLabelsDialog.open && (
        <BulkAddLabelsDialog
          open={showBulkAddLabelsDialog.open}
          contentMap={showBulkAddLabelsDialog.selectedContentMap}
          handleClose={() => {
            setShowBulkAddLabelsDialog({ open: false, selectedContentMap: {} });
          }}
          handleDone={({ shouldRefetch }) => {
            setShowBulkAddLabelsDialog({ open: false, selectedContentMap: {} });
            if (shouldRefetch) {
              activeBillsAndReceiptsQuery.refetch();
              globalExpenseItemQuery.refetch();
              if (includeArchive) {
                archivedBillsAndReceiptsQuery.refetch();
              }
            }
            setSelected({});
          }}
        />
      )}
    </div>
  );
};
function mapStateToProps(state) {
  const columnSettings = _.get(
    state,
    'appState.adminToolsSettings.receiptsAndBills',
    null
  );
  return {
    userInfo: state.userInfo,
    managingCompanyInfo: state.appState.managingCompanyInfo || {},
    contentLabelSuggestions: state.appState.contentLabelSuggestions || [],
    columnSettings,
  };
}

export default compose(
  DeleteContentAction,
  DeleteJrnAction,
  withApollo
)(connect(mapStateToProps)(ManageReceipts));
