import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Grid,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ButtonBase,
  Tooltip,
} from '@material-ui/core';
import { Dashboard as DashboardIcon } from '@material-ui/icons';

import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';

import { asCurrency } from '../dashboard/dashboard.utils';

const GlobalExpenseProjectSplit = ({
  classes,
  content,
  selectable,
  userId,
  managingCompanyInfo,
  onClose,
}) => {
  const history = useHistory();
  const location = useLocation();

  const isAllowedToViewProjectInfo = useMemo(() => {
    if (managingCompanyInfo.isCompanyAdmin) {
      return true;
    }

    if (content?.jrn) {
      const { allowedToEdit, allowedToAdd } = content.jrn;

      if (
        _.includes(allowedToEdit, userId) ||
        (_.includes(allowedToAdd, userId) && content.creatorId === userId)
      ) {
        return true;
      }
    }

    return false;
  }, [content, managingCompanyInfo, userId]);

  const {
    billableStatus,
    description,
    projectCoverUrl,
    projectTitle,
  } = useMemo(() => {
    const { jrn: parentProject } = content;
    let coverUrl = null;
    let title = 'Other Project';
    let status = null;
    let notes = null;

    if (isAllowedToViewProjectInfo) {
      status = content.billable ? 'Billable' : 'Non-Billable';
      notes = content.description || null;

      if (parentProject) {
        coverUrl = parentProject.contentUrl || null;

        title = parentProject.title;
        if (parentProject.jrn?.title) {
          title = `${parentProject.jrn.title} > ${title}`;
        }
      }
    }

    return {
      billableStatus: status,
      description: notes,
      projectCoverUrl: coverUrl,
      projectTitle: title,
    };
  }, [content, isAllowedToViewProjectInfo]);

  const handleSelectableButtonClick = () => {
    if (_.endsWith(location.pathname, `/projects/${content.jrnId}`)) {
      onClose();
    } else {
      history.push(`/projects/${content.jrnId}`);
    }
  };

  return (
    <Grid
      container
      direction="column"
      className={
        selectable && isAllowedToViewProjectInfo
          ? classes.selectableProjectSplitContainer
          : classes.projectSplitContainer
      }
      style={{ position: 'relative' }}
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ minHeight: 70 }}
      >
        <Grid item xs={9}>
          <ListItem>
            <ListItemAvatar>
              {projectCoverUrl ? (
                <Avatar alt="Project Cover Image" src={projectCoverUrl} />
              ) : (
                <Avatar>
                  <DashboardIcon />
                </Avatar>
              )}
            </ListItemAvatar>
            <ListItemText primary={projectTitle} secondary={billableStatus} />
          </ListItem>
        </Grid>
        <Grid
          container
          item
          xs={3}
          justifyContent="flex-end"
          style={{ paddingRight: 16 }}
        >
          {content.contentStatus === 'refund' && '-'}
          {asCurrency((content.amount && content.amount.value) || 0, true)}
        </Grid>
        {!!description && (
          <Grid item xs={12}>
            <div className={classes.descriptionWrapper}>
              {ReactHtmlParser(description)}
            </div>
          </Grid>
        )}
      </Grid>
      {selectable && (
        <Tooltip
          title={
            isAllowedToViewProjectInfo
              ? 'Go to this project'
              : 'You do not have permission to access this project'
          }
        >
          {isAllowedToViewProjectInfo ? (
            <ButtonBase
              className={classes.selectableButton}
              onClick={handleSelectableButtonClick}
            />
          ) : (
            <div className={classes.selectableButton} />
          )}
        </Tooltip>
      )}
    </Grid>
  );
};

const mapStateToProps = state => ({
  userId: state.userInfo && state.userInfo.userId,
  managingCompanyInfo: state.appState.managingCompanyInfo,
});

export default connect(mapStateToProps)(GlobalExpenseProjectSplit);
