import React from 'react';

import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/styles';
import Linkify from 'react-linkify';

const useStyles = makeStyles(theme => ({
  textNoteWrapper: {
    overflow: 'hidden',
    overflowWrap: 'break-word',
    padding: theme.spacing(1),
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
    backgroundColor: '#f4f4f4',
    borderRadius: 30,
  },
}));

const ContentTextNote = props => {
  const { contentPiece } = props;

  const classes = useStyles();

  return (
    <div className={classes.textNoteWrapper}>
      <Linkify>
        <div className="levelParsedHtml">
          {ReactHtmlParser(contentPiece.description)}
        </div>
      </Linkify>
    </div>
  );
};

export default ContentTextNote;
