import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-apollo-hooks';
import { compose, withApollo } from 'react-apollo';
import { connect } from 'react-redux';

import {
  Avatar,
  Button,
  ButtonBase,
  CircularProgress,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Checkbox,
} from '@material-ui/core';
import {
  ChevronRight as ChevronRightIcon,
  Dashboard as DashboardIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';

import _ from 'lodash';

import FilterList from '../FilterSearch/filterSearch';
import CenteredSpinner from '../centered-spinner/centered-spinner';
import { renderDateString } from '../../helpers/renderDateString';

// GrapQL
import ListCompanyProjects from '../../graphql/queries/list-company-projects';
import ListMyJrns from '../../graphql/queries/ListMyJrns';
import GetAllContentByJrnId from '../../graphql/queries/GetAllContentByJrnId';
import { TOP_PROJECT_ID } from '../../config/appDefaults';

const useStyles = makeStyles(() => ({
  treeItemWrapper: {
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  buttonBase: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '8px 0',
  },
  listItem: {
    padding: '0 16px 0 8px',
  },
}));

const ChooseProjectWithSubs = ({
  setSelectedProject,
  managingCompanyInfo,
  userInfo,
  client,
  includeViewOnlyProjects,
  multipleSelection = false,
}) => {
  const classes = useStyles();

  let isCompanyAdmin = false;

  let queryParams;
  if (
    managingCompanyInfo &&
    managingCompanyInfo.isCompanyAdmin &&
    managingCompanyInfo.managingCompanyId
  ) {
    isCompanyAdmin = true;
    queryParams = {
      query: ListCompanyProjects,
      path: 'listCompanyProjects',
      variables: {
        companyId: managingCompanyInfo.managingCompanyId,
        first: null,
        after: null,
      },
    };
  } else {
    queryParams = {
      query: ListMyJrns,
      path: 'listMyJrns',
      variables: {
        userId: 'willBePulledFromCognitoSubContentInResolver',
      },
    };
  }

  const projectsQuery = useQuery(queryParams.query, {
    variables: queryParams.variables,
    fetchPolicy: 'cache-and-network',
  });
  const projects = _.get(projectsQuery, `data[${queryParams.path}].items`);
  const loading = _.get(projectsQuery, 'loading');

  const [sortedProjects, setSortedProjects] = useState(null);
  const [topProjectIds, setTopProjectIds] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [mapAllProjects, setMapAllProjects] = useState({});
  const [expanded, setExpanded] = useState([]);
  const [chosenProjectMap, setChosenProjectMap] = useState(null);

  const toggleProjectChosenState = ({ project, projectPath }) => {
    setChosenProjectMap(currentState => {
      const newState = { ...(currentState || {}) };
      if (newState[project.contentId]) {
        delete newState[project.contentId];
      } else {
        newState[project.contentId] = { project, projectPath };
      }

      return newState;
    });
  };

  const sortProjects = projs => {
    return _.orderBy(
      projs,
      [project => project.title.toLowerCase(), 'startDate'],
      ['asc', 'desc']
    );
  };

  const projectToShow = projs => {
    if (isCompanyAdmin || includeViewOnlyProjects) {
      return projs;
    }

    const { userId } = userInfo;

    return projs.filter(project => {
      return (
        project.allowedToAdd.includes(userId) ||
        project.allowedToEdit.includes(userId)
      );
    });
  };

  useEffect(() => {
    if (projects) {
      let projs = projectToShow(projects);
      projs = sortProjects(projs);

      setSortedProjects(projs); // date sorted set of projects to filter off of

      const allProjects = {};
      projs.forEach(project => {
        allProjects[project.contentId] = project;
      });
      setMapAllProjects(allProjects); // all projects including their subprojects, added to each time a project is chosen
      setTopProjectIds(projs.map(p => p.contentId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  useEffect(() => {
    if (chosenProjectMap) {
      setSelectedProject(_.values(chosenProjectMap));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenProjectMap]);

  const handleSelectedProject = project => {
    // gather parent info and pass it back
    const titleArray = [];
    const buildPathInfo = checkThisProject => {
      // put this item in the path
      titleArray.push(checkThisProject.title);
      // if it has a parent, find the parent and run it through the function again
      const parentId = checkThisProject.jrnId;
      if (parentId && parentId !== TOP_PROJECT_ID) {
        if (mapAllProjects[parentId]) {
          buildPathInfo(mapAllProjects[parentId]);
        }
      }
    };
    buildPathInfo(project);
    const toReturn = {
      project,
      path: titleArray.reverse(),
    };
    setSelectedProject(toReturn);
  };

  const getSubprojects = async projectId => {
    // TODO: make a new query that allows getting of content from a project by type (make sure we take admin types into account)
    //  limit it entirely to company admins since non-admins should never be running it
    // for now, use getAllContentByJrnId to get the subprojects of this project or is there a better query?
    try {
      const projectContentResponse = await client.query({
        query: GetAllContentByJrnId,
        variables: { jrnId: projectId },
        fetchPolicy: 'no-cache',
      });
      let projectSubs = projectContentResponse.data.getAllContentByJrnId.items;
      projectSubs = _.filter(
        projectSubs,
        content => content.type === 'project'
      );
      return projectSubs;
      //   setProjectContentCopy(projectSubs);
      // then find the piece of content needing to be edited
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('getSubprojects query e: ', e);
      return [];
    }
  };

  const setSubs = ({ project, subs }) => {
    const subsToShow = projectToShow(subs);

    setMapAllProjects(prevState => {
      const allProjects = { ...prevState };

      // add each sub to the all projects
      subsToShow.forEach(sub => {
        allProjects[sub.contentId] = sub;
      });

      // also add the subprojectIds to the project itself
      if (allProjects[project.contentId]) {
        const sortedSubs = sortProjects(subsToShow);
        allProjects[project.contentId].subprojectIds = sortedSubs.map(
          sub => sub.contentId
        );
      }

      return allProjects;
    });
  };

  const showSubsFor = async project => {
    const subs = await getSubprojects(project.contentId);
    setSubs({ project, subs });
  };

  const recursiveProjects = () => {
    const handleToggle = project => {
      if (expanded.includes(project.contentId)) {
        setExpanded(expanded.filter(id => id !== project.contentId));
      } else {
        setExpanded([...expanded, project.contentId]);
        showSubsFor(project);
      }
    };

    const handleSelect = project => {
      handleSelectedProject(project);
    };

    const renderOneItem = ({
      project,
      topOnly = true,
      nestingLevel = 1,
      parentProject = null,
    }) => {
      const isTopLevel = nestingLevel === 1;
      if ((topOnly && !isTopLevel) || !project) {
        return null;
      }
      const isExpanded = expanded.includes(project.contentId);
      return (
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
          key={project.contentId}
          style={{
            borderBottom: isTopLevel ? '1px solid #ccc' : 'none',
          }}
        >
          <Grid
            item
            xs={12}
            container
            className={classes.treeItemWrapper}
            alignItems="center"
            style={{
              paddingRight: 8,
              paddingLeft: isTopLevel ? 8 : nestingLevel * 16,
            }}
          >
            <Grid item style={{ flex: 1 }}>
              <ButtonBase
                onClick={() => handleToggle(project)}
                className={classes.buttonBase}
                disabled={!isCompanyAdmin}
              >
                {isCompanyAdmin && (
                  <>
                    {isExpanded ? (
                      <ExpandMoreIcon style={{ marginRight: 8 }} />
                    ) : (
                      <ChevronRightIcon style={{ marginRight: 8 }} />
                    )}
                  </>
                )}
                <ListItem className={classes.listItem}>
                  <ListItemAvatar>
                    {project.contentUrl ? (
                      <Avatar
                        alt="Project Cover Image"
                        src={project.contentUrl}
                      />
                    ) : (
                      <Avatar>
                        <DashboardIcon />
                      </Avatar>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={project.title}
                    secondary={renderDateString(
                      project.startDate,
                      project.endDate
                    )}
                  />
                </ListItem>
              </ButtonBase>
            </Grid>

            <Grid item style={{ alignItems: 'center', flex: 0 }}>
              {!multipleSelection && (
                <Button
                  color="primary"
                  size="small"
                  onClick={event => {
                    event.stopPropagation();
                    event.preventDefault();
                    handleSelect(project);
                  }}
                >
                  Select
                </Button>
              )}
              {multipleSelection && (
                <Checkbox
                  checked={
                    !!(chosenProjectMap && chosenProjectMap[project.contentId])
                  }
                  onChange={() => {
                    const projectPathComponents = [project.title];
                    if (parentProject) {
                      projectPathComponents.unshift(parentProject.title);
                    }
                    toggleProjectChosenState({
                      project,
                      projectPath: projectPathComponents,
                    });
                  }}
                  color="primary"
                />
              )}
            </Grid>
          </Grid>

          {isCompanyAdmin && isExpanded && (
            <>
              {project.subprojectIds ? (
                <>
                  {project.subprojectIds.length > 0 ? (
                    project.subprojectIds.map(id => {
                      return renderOneItem({
                        project: mapAllProjects[id],
                        topOnly: false,
                        nestingLevel: nestingLevel + 1,
                        parentProject: project,
                      });
                    })
                  ) : (
                    <Grid item xs={12}>
                      <Typography
                        style={{
                          color: '#999',
                          fontStyle: 'italic',
                          paddingLeft: 72,
                          paddingBottom: 16,
                        }}
                      >
                        No subprojects
                      </Typography>
                    </Grid>
                  )}
                </>
              ) : (
                <CenteredSpinner customPadding={12} customSize={18} />
              )}
            </>
          )}
        </Grid>
      );
    };

    return topProjectIds.map(id =>
      renderOneItem({ project: mapAllProjects[id] })
    );
  };

  return (
    <Grid container direction="column" className={classes.chooseSections}>
      <Grid
        item
        xs={8}
        style={{
          margin: '0 auto',
          width: '100%',
          maxHeight: '40vh',
        }}
      >
        <FilterList
          data={sortedProjects}
          isFiltered={filterStatus => setIsFiltered(filterStatus)}
          passBack={filteredData => {
            setTopProjectIds(filteredData.map(project => project.contentId));
          }}
        />
        <div style={{ height: 32 }} />
        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              {topProjectIds && !topProjectIds.length && (
                <>
                  <Typography variant="body1" align="center">
                    {isFiltered ? (
                      <>No search results.</>
                    ) : (
                      <>No projects yet.</>
                    )}
                  </Typography>
                </>
              )}

              {topProjectIds && !!topProjectIds.length && recursiveProjects()}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
    userInfo: state.userInfo || {},
  };
}

export default connect(mapStateToProps)(
  compose(withApollo)(ChooseProjectWithSubs)
);
