import React, { useState, useMemo } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Typography } from '@material-ui/core';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';

import _ from 'lodash';
import moment from 'moment';

import EditableBudget from './editable-budget';
import TabPanel from './tab-panel';

import {
  calculateCompletePercentage,
  calculateTotalLaborBudget,
} from './helpers';
import {
  BUDGET_TYPE_PAYMENTS,
  BUDGET_TYPE_RECEIPTS_INVOICES,
  BUDGET_TYPE_TOTAL_LABOR_COST,
  BUDGET_TYPE_TOTAL_LABOR_HOURS,
  CONTENT_TYPE,
  TYPE_TO_STAT_LABEL,
} from '../../../../../../config/appDefaults';

const DEFAULT_VISIBLE_ROWS = 10;

const useStyles = makeStyles(theme => ({
  tableHeading: {
    fontSize: 14,
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: '#999',
    textAlign: 'center',
  },
  headerRow: {
    background: '#f0f0f0',
  },
  row: {
    padding: '8px 4px 8px 8px',
    borderBottom: '1px solid #e6e6e6',
    '&:hover': {
      border: `1px solid ${theme.palette.brandColorPrimary}`,
    },
    justifyContent: 'center',
    alignItems: 'center',
  },
  cellTextCenter: {
    fontSize: 14,
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
  cellTextLeft: {
    fontSize: 14,
    textOverflow: 'ellipsis',
    textAlign: 'left',
  },
}));

const ByProjectTabPanel = ({
  editBudgetByUser,
  data,
  hideZeroRows,
  location,
  overageIsGood,
  parentProjectDetails,
  projects,
  tabIndex,
  tabValue, // current showing tab value
  budgetInfo: parentBudgetInfo,
  dialogTitle,
}) => {
  const {
    parentX,
    parentOfY,
    numberFormat = '$0,0',
    itemTypes,
    budgetLabels,
    dataUnit,
    tabName,
  } = data;

  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);

  // Calculate the total budgets for all subprojects
  const budgetByProjectId = useMemo(() => {
    const budgetMap = {};
    if (parentBudgetInfo && parentBudgetInfo.subprojects && budgetLabels) {
      _.forEach(parentBudgetInfo.subprojects, subBudgetInfo => {
        const { projectId } = subBudgetInfo;
        budgetMap[projectId] = {};

        // calculate labor budget (cost and hours)
        const totalLaborBudget = calculateTotalLaborBudget(subBudgetInfo);
        budgetMap[projectId] = {
          ...budgetMap[projectId],
          [BUDGET_TYPE_TOTAL_LABOR_COST]: totalLaborBudget.cost,
          [BUDGET_TYPE_TOTAL_LABOR_HOURS]: totalLaborBudget.hours,
        };

        _.forEach(budgetLabels, budgetLabel => {
          if (
            budgetLabel === BUDGET_TYPE_PAYMENTS ||
            budgetLabel === BUDGET_TYPE_RECEIPTS_INVOICES
          ) {
            const budget =
              _.find(subBudgetInfo.budgets, { label: budgetLabel }) || {};
            budgetMap[projectId] = {
              ...budgetMap[projectId],
              [budgetLabel]: budget.value,
            };
          }
        });
      });
    }
    return budgetMap;
  }, [parentBudgetInfo, budgetLabels]);

  const subprojectBudgetInfoMap = useMemo(() => {
    if (parentBudgetInfo && parentBudgetInfo.subprojects) {
      return _.keyBy(parentBudgetInfo.subprojects, 'projectId');
    }
    return {};
  }, [parentBudgetInfo]);

  const subprojectRows = useMemo(() => {
    const rows = [];
    _.forEach(projects, project => {
      let total = 0;
      if (project.stats) {
        _.forEach(itemTypes, itemType => {
          total += project.stats[TYPE_TO_STAT_LABEL[itemType]] || 0;
          if (itemType === CONTENT_TYPE.RECEIPT) {
            total += project.stats[TYPE_TO_STAT_LABEL.bill] || 0;
          }
        });
      }

      const budget = budgetByProjectId[project.contentId] || {};

      let budgetValue = 0;
      _.forEach(budgetLabels, budgetLabel => {
        budgetValue += budget[budgetLabel] || 0;
      });

      if (!hideZeroRows || (hideZeroRows && (!!total || !!budgetValue))) {
        rows.push({ project, total, budget: budgetValue });
      }
    });
    return rows;
  }, [budgetByProjectId, budgetLabels, hideZeroRows, itemTypes, projects]);

  const onExportDataClick = () => {
    const dataForExport = [
      ['Project', parentProjectDetails.title],
      ['Date', `${new Date().toDateString()} ${new Date().toTimeString()}`],
      ['View', `${dialogTitle}${(tabName && ` > ${tabName}`) || ''}`],
      [],
      ['Project Name', 'Total', 'Budget', 'Variance', '% Complete'],
    ];

    // Add parent project row
    const {
      total: parentFormattedTotal,
      budget: parentFormattedBudget,
      varianceByUnit: parentFormattedVariance,
      percentCompleteValue: parentFormattedCompletePercentage,
    } = calculateCompletePercentage({
      total: parentX,
      budget: parentOfY,
      numberFormat,
      overageIsGood,
    });

    dataForExport.push([
      parentProjectDetails.title,
      parentFormattedTotal,
      parentFormattedBudget,
      parentFormattedVariance,
      parentFormattedCompletePercentage,
    ]);

    // Add each subproject
    subprojectRows.forEach(
      ({ project, total: subTotal, budget: subBudget }) => {
        const {
          total: formattedTotal,
          budget: formattedBudget,
          varianceByUnit: formattedVariance,
          percentCompleteValue: formattedCompletePercentage,
        } = calculateCompletePercentage({
          total: subTotal,
          budget: subBudget,
          numberFormat,
          overageIsGood,
        });

        dataForExport.push([
          project.title,
          formattedTotal,
          formattedBudget,
          formattedVariance,
          formattedCompletePercentage,
        ]);
      }
    );

    // Convert to CSV
    const csv = dataForExport
      .map(row => row.map(cell => `"${cell}"`).join(','))
      .join('\n');

    // Create doc and download
    const expsumdl = document.createElement('a');
    const file = new Blob([csv], { type: 'text/csv' });
    expsumdl.href = URL.createObjectURL(file);
    expsumdl.download = `${dialogTitle
      .toLowerCase()
      .replace(' ', '-')}_${moment().format('YYYYMMDDHHmmss')}.csv`;
    expsumdl.click();
  };

  const renderProjectData = ({
    total = 0,
    budget = 0,
    project,
    budgetInfo,
  }) => {
    const {
      total: formattedTotal,
      varianceByUnit,
      percentCompleteValue,
      fontColor,
    } = calculateCompletePercentage({
      total,
      budget,
      numberFormat,
      overageIsGood,
    });

    return (
      <>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            className={classes.cellTextCenter}
            style={{ color: fontColor }}
          >
            {formattedTotal}
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: fontColor,
          }}
        >
          <EditableBudget
            editBudgetByUser={editBudgetByUser}
            project={project}
            budget={budget}
            numberFormat={numberFormat}
            nonEditableTextClass={classes.cellTextCenter}
            budgetLabels={budgetLabels}
            color={fontColor}
            budgetInfo={budgetInfo}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            className={classes.cellTextCenter}
            style={{ color: fontColor }}
          >
            {varianceByUnit}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            className={classes.cellTextCenter}
            style={{ color: fontColor }}
          >
            {percentCompleteValue}
          </Typography>
        </Grid>
      </>
    );
  };

  const renderParentProjectRow = () => {
    return (
      <Grid item container className={classes.row}>
        <Grid item xs={6} container justifyContent="space-between">
          <Typography variant="body2" className={classes.cellTextLeft}>
            {parentProjectDetails.title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          {renderProjectData({
            total: parentX,
            budget: parentOfY,
            project: parentProjectDetails,
            budgetInfo: parentBudgetInfo,
          })}
        </Grid>
      </Grid>
    );
  };

  const renderProjectRow = (
    { project, budget, total },
    projectIndex,
    array
  ) => {
    if (projectIndex >= DEFAULT_VISIBLE_ROWS && !showAll) {
      return null;
    }

    const goToLocation = {
      pathname: `${location.pathname}/${project.contentId}`,
      state: { lastTabIndex: 0 },
    };

    return (
      <React.Fragment key={project.contentId}>
        <Grid
          item
          container
          justifyContent="space-between"
          className={classes.row}
        >
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <SubdirectoryArrowRightIcon
              style={{
                color: '#666',
                marginLeft: 12,
                marginTop: -2,
                fontSize: 16,
              }}
            />
            <Link to={goToLocation}>
              <Typography
                variant="body2"
                className={classes.cellTextLeft}
                style={{
                  textDecoration: 'underline',
                  width: 'auto',
                  paddingLeft: 4,
                  display: 'inline-flex',
                }}
              >
                {project.title}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={6} container direction="row" justifyContent="flex-end">
            {renderProjectData({
              total,
              budget,
              project,
              budgetInfo: subprojectBudgetInfoMap[project.contentId],
            })}
          </Grid>
        </Grid>
        {array.length > DEFAULT_VISIBLE_ROWS &&
          projectIndex === DEFAULT_VISIBLE_ROWS - 1 &&
          !showAll && (
            <Grid container justifyContent="center">
              <Button
                onClick={() => {
                  setShowAll(true);
                }}
                style={{
                  marginTop: 8,
                  marginBottom: 8,
                }}
                color="primary"
              >
                Show All {array.length} items...
              </Button>
            </Grid>
          )}
      </React.Fragment>
    );
  };

  return (
    <TabPanel value={tabValue} index={tabIndex}>
      <Grid container direction="column">
        <Grid item container className={`${classes.row} ${classes.headerRow}`}>
          <Grid item xs={6} container justifyContent="space-between">
            <Typography variant="body2" className={classes.tableHeading}>
              Project Name
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs={3}>
              <Typography variant="body2" className={classes.tableHeading}>
                Total
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                className={classes.tableHeading}
                style={{ minWidth: 80, textAlign: 'center' }}
              >
                Budget
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                className={classes.tableHeading}
                style={{ minWidth: 80, textAlign: 'center' }}
              >
                Variance ({dataUnit})
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                className={classes.tableHeading}
                style={{ minWidth: 80, textAlign: 'center' }}
              >
                % Complete
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {renderParentProjectRow()}
        {subprojectRows.length > 0 && (
          <Grid
            item
            container
            style={{
              marginBottom: 12,
            }}
          >
            {subprojectRows.map(renderProjectRow)}
          </Grid>
        )}
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button variant="text" color="primary" onClick={onExportDataClick}>
            Export data (.CSV)
          </Button>
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default withRouter(ByProjectTabPanel);
