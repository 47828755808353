import React from 'react';

import palette from '../../../theme/palette';

/**
 *
 * NOTE: error and success are mutually exclusive. If both are true, error will be used.
 */
const HelperText = ({ text, error, success }) => {
  let color = 'inherit';
  let fontStyle = 'italic';

  if (error) {
    color = palette.brandColorError;
  } else if (success) {
    color = palette.success.main;
  } else {
    fontStyle = 'inherit';
  }

  return <span style={{ color, fontStyle }}>{text}</span>;
};

export default HelperText;
