import React from 'react';
import { IconButton, Link, Tooltip, Typography } from '@material-ui/core';
import { OpenInNew as OpenInNewIcon } from '@material-ui/icons';

const BookkeepingAlertsReportAlertListItemTableCellText = ({
  value,
  qboTxnUrl,
}) => {
  return (
    <>
      {!!qboTxnUrl && (
        <Tooltip
          title="View in QuickBooks Online"
          style={{ marginRight: 8, marginLeft: 4 }}
          PopperProps={{ style: { zIndex: 9999 } }}
        >
          <Link href={qboTxnUrl} target="_blank" rel="noopener noreferrer">
            <IconButton size="small">
              <OpenInNewIcon fontSize="small" />
            </IconButton>
          </Link>
        </Tooltip>
      )}
      <Typography
        variant="body1"
        style={{ color: 'inherit', fontWeight: 'inherit' }}
      >
        {value}
      </Typography>
    </>
  );
};

export default BookkeepingAlertsReportAlertListItemTableCellText;
