import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from '@material-ui/core';

import LoadingCover from '../LoadingCover/loadingCover';
import LicenseRequiredActionButton from '../license-required-action-button/license-required-action-button';

const NeedALicenseModal = ({ open, onClose, companyInfo }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Dialog maxWidth="xs" fullWidth open={open}>
      {loading && <LoadingCover />}
      <DialogTitle>License Required</DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          style={{ padding: 12 }}
        >
          <Typography variant="body1">
            Sorry! You will need to add a license to{' '}
            <b>{companyInfo?.companyName || 'this client'}</b> before you can
            set up their account and start generating bookkeeping reports.
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Cancel
        </Button>
        <LicenseRequiredActionButton
          companyInfo={companyInfo}
          onComplete={onClose}
          setLoading={setLoading}
        />
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  firmInfo: state.appState.firmInfo,
});

export default connect(mapStateToProps)(NeedALicenseModal);
