import basicSchemaGenerator from './basic-schema-generator';
import { descMaxValidation, titleMaxValidation } from './schema-validation';

export default [
  //   run this             fieldname    type      edit   req  customValidations
  { ...basicSchemaGenerator('contentId', 'string', false, true, []) },
  { ...basicSchemaGenerator('jrnId', 'string', false, false, []) },
  { ...basicSchemaGenerator('creatorId', 'string', false, true, []) },
  { ...basicSchemaGenerator('type', 'string', false, true, []) },
  { ...basicSchemaGenerator('date', 'date', true, true, []) },
  { ...basicSchemaGenerator('startDate', 'date', false, false, []) },
  { ...basicSchemaGenerator('endDate', 'date', false, false, []) },
  { ...basicSchemaGenerator('timeForDate', 'date', true, true, []) },
  { ...basicSchemaGenerator('timeForStartDate', 'date', false, false, []) },
  { ...basicSchemaGenerator('timeForEndDate', 'date', false, false, []) },
  {
    ...basicSchemaGenerator('description', 'string', true, false, [
      descMaxValidation,
    ]),
  },
  {
    ...basicSchemaGenerator('contentUrl', 'array', true, false, [
      {
        type: 'required',
        params: ['You need to provide at least one pdf.'],
      },
      {
        type: 'min',
        params: [1, 'You need to provide at least one pdf'],
      },
    ]),
  },
  { ...basicSchemaGenerator('aspectRatio', 'string', false, false, []) },
  // toggle for setting content to show up the slideshow
  { ...basicSchemaGenerator('isPublic', 'boolean', true, true, []) },
  // for knowing if the content in the cache is from us or the server
  { ...basicSchemaGenerator('synced', 'string', false, true, []) },
  // array of strings to be used for filtering
  { ...basicSchemaGenerator('labels', 'array', true, false, []) },
  { ...basicSchemaGenerator('latitude', 'string', false, false, []) },
  { ...basicSchemaGenerator('longitude', 'string', false, false, []) },
  {
    ...basicSchemaGenerator('title', 'string', false, false, [
      titleMaxValidation,
    ]),
  },
  { ...basicSchemaGenerator('coverImage', 'string', false, false, []) },
  { ...basicSchemaGenerator('privacyLevel', 'string', false, false, []) },
  // not currently in use, used to give ability to join a group by an invite code
  { ...basicSchemaGenerator('inviteCode', 'string', false, false, []) },
  { ...basicSchemaGenerator('allowedToEdit', 'array', false, false, []) },
  { ...basicSchemaGenerator('allowedToAdd', 'string', false, false, []) },
  { ...basicSchemaGenerator('allowedToView', 'string', false, false, []) },
  { ...basicSchemaGenerator('addedBy', 'string', false, false, []) },
  // if task, this is the userId of who it's assigned to
  { ...basicSchemaGenerator('assignedTo', 'string', false, false, []) },
  // if task, this is the status of it (incomplete, in-progress, complete)
  { ...basicSchemaGenerator('contentStatus', 'string', false, false, []) },
  // if task, this sets the priority (low, normal, high)
  { ...basicSchemaGenerator('priority', 'string', false, false, []) },
];
