import React, { useState } from 'react';
import {
  Grid,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from '@material-ui/core';
import { defaultLeadStatus } from '../../config/appDefaults';

import CenteredSpinner from '../centered-spinner/centered-spinner';
import ChangeLeadStatusRadioButtons from './change-lead-status-radio-buttons';

const ChangeLeadStatusDialog = ({
  toClose,
  onConfirm,
  initialStatus,
  buttonPadding,
}) => {
  const [saving, setSaving] = useState(false);
  const [selectedChip, setSelectedChip] = useState(
    initialStatus || defaultLeadStatus
  );
  const handleCancel = () => {
    toClose('cancel');
  };

  const handleOk = async () => {
    setSaving(true);
    try {
      await onConfirm(selectedChip);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('handleOk err: ', err);
    }
    setSaving(false);
    toClose();
  };

  const handleChipClick = value => {
    setSelectedChip(value);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open>
      <DialogTitle>Change Lead Status</DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          {saving && <CenteredSpinner />}
          {!saving && (
            <ChangeLeadStatusRadioButtons
              onChange={value => handleChipClick(value)}
              initialStatus={selectedChip}
              buttonPadding={buttonPadding}
            />
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeLeadStatusDialog;
