import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import SplitFinancialItemListProject from './split-financial-item-list-project';
import splitFinancialItemStyles from './split-financial-item-list.styles';
import SplitFinancialItemListBill from './split-financial-item-list-bill';
import ButtonWithTooltip from '../button-with-tooltip/button-with-tooltip';

const useStyles = makeStyles(splitFinancialItemStyles);

const SplitFinancialItemList = ({
  itemList,
  onFieldChange,
  onAddButtonClick,
  onDeleteButtonClick,
  totalAmount,
  hideDetails,
  hideTotal,
  listStyle,
  autoFocus,
  allowDeleteExisting,
  billMode = false,
  showVendor = false,
  managingCompanyInfo,
  invalidAmountMap = {},
  setInvalidAmountMap = () => {},
  disabled,
  disabledMessage,
}) => {
  const classes = useStyles();

  const hasEmptyList = !(itemList?.length > 0);

  return (
    <Grid container direction="column">
      <Grid
        container
        item
        direction="column"
        wrap="nowrap"
        className={classes.projectList}
        {...(listStyle && { style: listStyle })}
      >
        {itemList.map((item, index) => {
          let keyToUse;
          if (billMode) {
            keyToUse = item.bill.contentId;
          } else {
            keyToUse = item.project.contentId;
          }

          if (billMode) {
            return (
              <SplitFinancialItemListBill
                key={keyToUse}
                entryInfo={item}
                entryIndex={index}
                autoFocusOnAmount={
                  autoFocus ? index === itemList.length - 1 : null
                }
                invalidAmount={!!invalidAmountMap[item.bill.contentId]}
                onResetInvalidAmount={() => {
                  setInvalidAmountMap({
                    ...invalidAmountMap,
                    [item.bill.contentId]: false,
                  });
                }}
                onFieldChange={onFieldChange}
                onDeleteButtonClick={onDeleteButtonClick}
                allowDeleteExisting={allowDeleteExisting}
                showVendor={showVendor}
                disabled={disabled}
              />
            );
          }

          return (
            <SplitFinancialItemListProject
              key={keyToUse}
              entryInfo={item}
              entryIndex={index}
              autoFocusOnAmount={
                autoFocus ? index === itemList.length - 1 : null
              }
              invalidAmount={!!invalidAmountMap[item.project.contentId]}
              onResetInvalidAmount={() => {
                setInvalidAmountMap({
                  ...invalidAmountMap,
                  [item.project.contentId]: false,
                });
              }}
              onFieldChange={onFieldChange}
              onDeleteButtonClick={onDeleteButtonClick}
              allowDeleteExisting={allowDeleteExisting}
              hideDetails={hideDetails}
              disabled={disabled}
            />
          );
        })}
      </Grid>
      <Grid
        container
        direction="column"
        style={{ paddingLeft: 16, paddingRight: 36 }}
      >
        {/* Note: We don't have get-company-bills query for non-admins on the backend,
            so we hide the button for non-admins. */}
        {(!billMode || managingCompanyInfo.isCompanyAdmin) && (
          <ButtonWithTooltip
            onClick={onAddButtonClick}
            startIcon={<AddIcon />}
            variant={!hasEmptyList ? 'text' : 'contained'}
            color="primary"
            style={{ margin: '8px 0px 0px 16px' }}
            disabled={disabled}
            tooltipText={disabledMessage}
          >
            {hasEmptyList &&
              `Select the ${billMode ? 'bill' : 'project'} to apply against`}
            {!hasEmptyList &&
              `Add another ${billMode ? 'bill' : 'project'} to split across`}
          </ButtonWithTooltip>
        )}
        {!hideTotal && (
          <Grid item className={classes.totalAmount}>
            <Typography>Total: {totalAmount}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo,
});

export default connect(mapStateToProps)(SplitFinancialItemList);
