import React, { useEffect } from 'react';

import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import { useQuery } from 'react-apollo-hooks';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import constructionSign from '../../assets/images/construction-sign@4x.png';
import LevelSupportEmailAddressLink from '../../components/level-support-email-address-link/level-support-email-address-link';
import GetUserInfo from '../../graphql/queries/GetUserInfo';
import GetCompanyById from '../../graphql/queries/get-company-by-id';
import { determineManagingCompanyInfo } from '../../helpers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: 100,
    textAlign: 'center',
  },
}));

const NoAccess = ({ currentAuth, dispatch, firmInfo, managingCompanyInfo }) => {
  const classes = useStyles();

  const history = useHistory();

  const { data: userInfoData } = useQuery(GetUserInfo, {
    variables: {
      userId: 'willBePulledFromCognitoSubContentInResolver',
    },
    pollInterval: 5000,
  });
  const userInfo = _.get(userInfoData, 'getMyUserInfo', null);

  const { data: getCompanyByIdData } = useQuery(GetCompanyById, {
    skip: !currentAuth?.isLoggedIn || !managingCompanyInfo?.companyId,
    variables: {
      companyId: managingCompanyInfo?.companyId,
    },
    pollInterval: 5000,
  });

  const companyInfo = _.get(getCompanyByIdData, 'getCompanyById', null);

  useEffect(() => {
    if (currentAuth?.isLoggedIn && userInfo && companyInfo) {
      const newManagingCompanyInfo = determineManagingCompanyInfo({
        companyInfo,
        userInfo,
      });

      if (
        !!newManagingCompanyInfo &&
        !_.isEqual(newManagingCompanyInfo, managingCompanyInfo)
      ) {
        dispatch({
          type: 'SET_MANAGING_COMPANY_INFO',
          payload: newManagingCompanyInfo,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo, currentAuth, userInfo]);

  const goBackToMainScreen = () => {
    if ((managingCompanyInfo && !companyInfo) || !userInfo) {
      return;
    }

    if (_.isEmpty(managingCompanyInfo?.products)) {
      if (firmInfo) {
        // go back to firm
        history.replace('/firm');
      } else {
        // non-firm based user
        // let the default route handler determine where it should go
        history.replace('/');
      }
    } else {
      // has products
      // let the default route handler determine where it should go
      history.replace('/');
    }
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h1">You do not have access</Typography>
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={goBackToMainScreen}
            >
              Go back to the main screen
            </Button>
            <Typography variant="h4" style={{ marginTop: 64 }}>
              If you have any issues, please contact Level Support at&nbsp;
              <LevelSupportEmailAddressLink />.
            </Typography>
            <br />
            <br />
            <img alt="construction sign" src={constructionSign} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  currentAuth: state.currentAuth,
  managingCompanyInfo: state.appState.managingCompanyInfo,
  firmInfo: state.appState.firmInfo,
});

export default connect(mapStateToProps)(NoAccess);
