import gql from 'graphql-tag';

import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Rfi}
  mutation RemoveCompanyRfi($requestId: ID!, $managingCompanyId: String) {
    removeCompanyRfi(
      input: { requestId: $requestId, managingCompanyId: $managingCompanyId }
    ) {
      ...RfiBaseFields
    }
  }
`;
