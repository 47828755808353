import React, { useState } from 'react';
import { compose, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import _ from 'lodash';
import copy from 'copy-to-clipboard';
import uuid from 'uuid';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Drawer,
  Tooltip,
} from '@material-ui/core';
import {
  Inbox as InboxIcon,
  Done as DoneIcon,
  Refresh as RefreshIcon,
  Warning,
} from '@material-ui/icons';
// GraphQL
import {
  GetCompanyCrewAction,
  GetCompanyInfoAction,
  ListCompanyProjectsAction,
  AddCompanyShoeboxItemsAction,
  UpdateCompanyShoeboxItemAction,
  RemoveCompanyShoeboxItemAction,
} from '../../graphql/graphql';
import GetOcrDataForMedia from '../../graphql/queries/get-ocr-data-for-media';

import ShoeboxItemsDatagrid from './shoebox-items-datagrid';
import AddWhatToProject from '../add-to-project/add-what-to-project';
import AddContentForm from '../add-to-project/add-content-form';

import FileUploadArea from '../../components/file-upload-area/file-upload-area';
import { onUploadFile } from '../../helpers/cloudinary';
import { removeAttribute } from '../../helpers';
import LoadingCover from '../../components/LoadingCover/loadingCover';
import DialogTitle from '../../components/dialog-title/dialog-title';
import ChooseProjectWithSubs from '../../components/choose-project-dialog/choose-project-with-subs';
import OkCancelDialog from '../../components/OkCancelDialog/okCancelDialog';

import {
  CONTENT_DEFINITION,
  CONTENT_TYPE,
  SHOEBOX_MAX_UPLOAD_NUM_OF_ITEMS,
} from '../../config/appDefaults';
import ViewShoeboxItem from './view-shoebox-item';
import ShoeboxDupesHandler from './shoebox-dupes-handler';
import { useRepetitiveQuery } from '../../hooks';
import getCompanyShoeboxItems from '../../graphql/queries/get-company-shoebox-items';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: '90%',
    maxWidth: 1200,
  },
  paddedContentWrapper: {
    padding: theme.spacing(2),
  },
  pageTitleWrapper: {
    padding: theme.spacing(2),
  },
}));

const ShoeboxDashboard = ({
  companyCrew,
  companyProjects,
  companies,
  managingCompanyId,
  onAddCompanyShoeboxItems,
  onRemoveCompanyShoeboxItem,
  onUpdateCompanyShoeboxItem,
  client,
}) => {
  const classes = useStyles();
  const [viewItemPanel, setViewItemPanel] = useState({ open: false });
  const [copyButtonState, setCopyButtonState] = useState('initial');
  const [shoeboxItemBeingUsed, setShoeboxItemBeingUsed] = useState();
  const [dialogInfo, setDialogInfo] = React.useState({
    open: false,
    title: '',
  });
  const [handleDupesDialogInfo, setHandleDupesDialogInfo] = React.useState({
    open: false,
  });
  const [createContentFlowInfo, setCreateContentFlowInfo] = useState({
    open: false,
    // item: Object containing the shoeboxItem
    // parentInfo: Object containing the parentInfo - do we need anything more than tha parent's contentId?
  });
  const [screenBlockingOverlay, setScreenBlockingOverlay] = useState({
    open: false,
    wording: '',
  });
  const [multipleSelection, setMultipleSelection] = useState(null);

  // START - GET SHOEBOX ITEMS - QUERY //////////////////////////////
  const companyShoeboxItemsAccessor = 'getCompanyShoeboxItems';
  const companyShoeboxItemsResponse = useRepetitiveQuery(
    getCompanyShoeboxItems,
    {
      fetchPolicy: 'cache-and-network',
      skip: !managingCompanyId,
      variables: { companyId: managingCompanyId, mode: 'ACTIVE' },
      accessor: companyShoeboxItemsAccessor,
    }
  );
  const getCompanyShoeboxItemsLoading = _.get(
    companyShoeboxItemsResponse,
    'loading'
  );
  const getCompanyShoeboxItemsRefetch = _.get(
    companyShoeboxItemsResponse,
    'refetch'
  );
  const {
    items: companyShoeboxItems,
    totalCount: companyShoeboxItemsTotalCount,
  } = _.get(
    companyShoeboxItemsResponse,
    `data.${companyShoeboxItemsAccessor}`,
    {}
  );
  // END - GET SHOEBOX ITEMS - QUERY /////////////////////////////
  const viewThisItem = item => {
    setViewItemPanel({ open: true, paperworkItem: item });
  };

  const handleDrawerClose = () => {
    setViewItemPanel({ open: false });
  };

  const mediaToContentUrl = media => {
    const mediaItemToContentUrlItem = mediaItem => {
      return {
        uri: mediaItem.uri,
        aspectRatio: mediaItem.aspectRatio,
        type: mediaItem.type,
      };
    };
    const contentUrlEntries = [];
    media.forEach(mediaItem => {
      const temp = mediaItemToContentUrlItem(mediaItem);
      contentUrlEntries.push(temp);
    });
    return contentUrlEntries;
  };
  const prepItemToBeContent = item => {
    let description = '';
    if (item.subject) {
      description += `${item.subject}<br />`;
    }
    if (item.notes) {
      description += `${item.notes}`;
    }
    const contentUrl = item.media
      ? JSON.stringify(mediaToContentUrl(item.media))
      : null;

    return {
      ...item,
      contentId: uuid(), // since this will have the contentId of the paperwork item and we may want to put it onto two different projects
      contentUrl,
      date: item.dateCreated,
      description,
    };
  };

  const createContentFromShoeboxItem = item => {
    setShoeboxItemBeingUsed(item);
    const itemAsContent = prepItemToBeContent(item);
    setCreateContentFlowInfo({ open: true, item: itemAsContent });
  };

  const handleParentChoice = parentInfo => {
    setCreateContentFlowInfo({
      ...createContentFlowInfo,
      parentInfo: parentInfo.project,
    });
  };

  const handleWhatToAddChoice = ({ type }) => {
    setCreateContentFlowInfo({
      ...createContentFlowInfo,
      type,
    });
  };

  const getOcrDataForMediaItem = async ({ mediaSource, mediaSourceType }) => {
    // mediaSourceType = URI, BASE64
    let ocrData;
    try {
      ocrData = await client.query({
        query: GetOcrDataForMedia,
        variables: {
          mediaSource,
          mediaSourceType,
        },
        fetchPolicy: 'no-cache',
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('getOcrDataForMediaItem -> GetOcrDataForMedia err: ', err);
    }
    const toReturn = _.get(ocrData, ['data', 'getOcrDataForMedia'], null);
    if (toReturn) {
      // only remove the __typename if data exists
      delete toReturn.__typename;
    }
    return toReturn;
  };

  const addListOfShoeboxItems = async ({ listOfItems }) => {
    // now that we have all the uploaded files back, create a new shoebox item for each one
    const dateCreated = moment();
    const newShoeboxItems = listOfItems.map((mediaInfo, index) => {
      // const newShoeboxItems = uploadedFiles.map((mediaInfo, index) => {
      const dateToUse = moment(dateCreated)
        .add(index, 'milliseconds')
        .toISOString();
      let amountToUse = null;
      if (mediaInfo.ocrData?.dataJson) {
        const ocrData = JSON.parse(mediaInfo.ocrData.dataJson);
        if (typeof ocrData?.total === 'number') {
          // only set if there is a value or else we're setting "value" to null which is not allowed by the schema since it's type is float
          amountToUse = { value: ocrData.total };
        }
      }
      return {
        contentId: uuid(),
        companyId: managingCompanyId,
        dateCreated: dateToUse,
        media: [mediaInfo],
        amount: amountToUse,
        subject: null,
        notes: null,
      };
    });
    try {
      await onAddCompanyShoeboxItems({ listOfShoeboxItems: newShoeboxItems });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('onAddCompanyShoeboxItems err: ', err);
    }
  };

  const handleDuplicates = ({ filesWithDupes = [], uploadedFiles = [] }) => {
    setHandleDupesDialogInfo({
      title: 'Duplicate Shoebox Items',
      open: true,
      dupes: filesWithDupes,
      allFiles: uploadedFiles,
      whatToSave: async whatToSave => {
        setHandleDupesDialogInfo({ open: false });
        if (whatToSave.length !== 0) {
          setScreenBlockingOverlay({
            open: true,
            wording: 'Adding Shoebox Items...',
          });

          await addListOfShoeboxItems({ listOfItems: whatToSave });
        }
        setScreenBlockingOverlay({ open: false });
      },
    });
  };

  const handleFiles = async passedFiles => {
    setScreenBlockingOverlay({
      open: true,
      wording: 'Uploading & Extracting Info...',
    });

    // need to check for duplicates first
    // then if there are any, we need to stop and ask the user if they want to add them again
    // we then need to remove any unwanted uploads
    // then proceed with the upload (which will be missing any unwanted uploads)

    const toUploadFiles = async files => {
      if (files.length === 0) {
        return null;
      }
      const mediaInfos = []; // { uri, aspectRatio, type }
      const uploads = files.map(async (file, index) => {
        const uploadResponse = await onUploadFile(file, managingCompanyId);
        const type = file.type.includes('image') ? 'image' : 'pdf';
        const toPush = {
          uri: uploadResponse.uri,
          aspectRatio: uploadResponse.aspectRatio,
          type,
          order: index + 1,
        };

        // ------------------- START OCR STUFF ------------------
        // do the OCR query, then if it returns something, add it to the media item
        const autoFillOcrData = await getOcrDataForMediaItem({
          mediaSource: uploadResponse.uri,
          mediaSourceType: 'URI',
        });
        if (autoFillOcrData) {
          toPush.ocrData = autoFillOcrData;
        }
        // ------------------- END OCR STUFF ------------------

        mediaInfos.push(toPush);
      });
      await Promise.allSettled(uploads);
      return mediaInfos;
    };
    let uploadedFiles = await toUploadFiles(passedFiles);
    // couldnt figure out how to get the promises to return in order they were called so doing this for now
    // once they return in ordrr, we can remove this, but make sure to reverse the array before uploading so the order is correct
    uploadedFiles = _.orderBy(uploadedFiles, ['order'], ['desc']);
    uploadedFiles = uploadedFiles.map(file => {
      const copyOfFile = { ...file };
      delete copyOfFile.order;
      return copyOfFile;
    });

    const filesWithDupes = [];
    uploadedFiles.forEach((mediaInfo, index) => {
      if (mediaInfo.ocrData?.duplicateInfo) {
        // check for duplicateInfo and if it exists, ask the user if they want to add it again
        // since only admins have access to the shoebox, they won't ever get an empty array imply it's a duplicate but they dont have access
        // so we can just check for the existence of duplicateInfo
        filesWithDupes.push(mediaInfo);
      }
      delete uploadedFiles[index].ocrData.duplicateInfo; // gets rid of the attribute even if it's null
    });

    if (filesWithDupes.length > 0) {
      handleDuplicates({ filesWithDupes, uploadedFiles });
      return;
    }

    await addListOfShoeboxItems({ listOfItems: uploadedFiles });
    setScreenBlockingOverlay({ open: false });
  };

  const handleShoeboxItemRemoval = async itemToRemove => {
    setScreenBlockingOverlay({ open: true, wording: 'Updating...' });
    // need to pass the original shoebox item id since it's not the same as the content
    await onRemoveCompanyShoeboxItem(itemToRemove.contentId);
    setShoeboxItemBeingUsed(null);
    setScreenBlockingOverlay({ open: false });
  };

  const verifyShoeboxItemRemoval = itemToRemove => {
    setDialogInfo({
      title: 'Just making sure...',
      message: 'Are you sure you want to delete this item?',
      open: true,
      onClose: () => setDialogInfo({ ...dialogInfo, open: false }),
      hideCancel: false,
      onConfirm: () => {
        handleShoeboxItemRemoval(itemToRemove);
      },
    });
  };

  const handleAddAgain = async startingInfo => {
    setScreenBlockingOverlay({ open: true, wording: 'Prepping...' });
    setCreateContentFlowInfo({
      ...createContentFlowInfo,
      parentInfo: null,
    });
    // make another upload of the same file
    const mediaItemToUse = _.get(shoeboxItemBeingUsed, 'media');
    if (!mediaItemToUse) return;
    const uriToReupload = mediaItemToUse[0].uri;

    const fileUploadResponse = await onUploadFile(
      uriToReupload,
      managingCompanyId
    );
    mediaItemToUse[0].uri = fileUploadResponse.uri;
    // copy the startingInfo
    const newContentInfo = {
      ...startingInfo,
      contentId: uuid(),
      contentUrl: JSON.stringify(mediaToContentUrl(mediaItemToUse)),
      amount: null,
    };
    // to pass to addContentForm the contentUrl needs to be JSON string of media (uri, aspectRatio, type)
    setCreateContentFlowInfo({
      ...createContentFlowInfo,
      parentInfo: null,
      item: newContentInfo,
    });
    setScreenBlockingOverlay({ open: false });
  };

  const handleCreateContent = item => {
    // close drawer
    handleDrawerClose();
    // open create content flow with item
    createContentFromShoeboxItem(item);
  };

  const handleUpdate = item => {
    // close drawer
    handleDrawerClose();
    const cleanItem = removeAttribute(_.cloneDeep(item), '__typename');
    onUpdateCompanyShoeboxItem(cleanItem);
  };

  const shoeboxEmail = _.get(companies, '[0].shoeboxEmail');
  const assignFlowItemMediaType = _.get(
    createContentFlowInfo,
    'item.media[0].type'
  );

  const showChooseProject =
    createContentFlowInfo.type &&
    createContentFlowInfo.type !== CONTENT_TYPE.GLOBAL_PAYMENT &&
    !createContentFlowInfo.parentInfo &&
    !createContentFlowInfo.projectsToExpense;

  const chooseProjectAsMultipleSelection =
    showChooseProject &&
    CONTENT_DEFINITION[createContentFlowInfo.type].isGlobalType;

  const handleAbortProcess = () => {
    setCreateContentFlowInfo({ open: false });
  };

  const handleProjectSelection = projectSelectionData => {
    if (!chooseProjectAsMultipleSelection) {
      handleParentChoice(projectSelectionData);
    } else {
      setMultipleSelection(projectSelectionData);
    }
  };

  return (
    <Grid
      container
      style={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 64px)' }}
    >
      <Grid item xs={12} container className={classes.pageTitleWrapper}>
        <Grid container item xs={12}>
          <Grid
            item
            xs={12}
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginBottom: 8 }}
          >
            <Grid item style={{ flex: 0 }}>
              <Typography
                variant="h1"
                gutterBottom={false}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <InboxIcon fontSize="large" style={{ marginRight: 10 }} />{' '}
                Shoebox
              </Typography>
            </Grid>
            <Grid item style={{ flex: 1, textAlign: 'right' }}>
              {!!shoeboxEmail && (
                <>
                  YOUR SHOEBOX EMAIL:
                  <br />
                  <span style={{ fontWeight: 'bold' }}>{shoeboxEmail}</span>
                  <Button
                    size="small"
                    variant="contained"
                    disableElevation
                    endIcon={
                      copyButtonState === 'clicked' ? <DoneIcon /> : undefined
                    }
                    style={{
                      marginLeft: 8,
                      marginTop: 4,
                      backgroundColor:
                        copyButtonState === 'clicked'
                          ? 'transparent'
                          : undefined,
                    }}
                    onClick={() => {
                      setCopyButtonState('clicked');
                      setTimeout(() => {
                        setCopyButtonState('initial');
                      }, 3000);

                      copy(shoeboxEmail);
                    }}
                  >
                    {copyButtonState === 'clicked' ? 'Copied!' : 'Copy'}
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ marginTop: 8, marginBottom: 16 }}
            >
              Welcome to your company&apos;s shoebox! Drag and drop images or
              PDFs into your shoebox below (max{' '}
              {SHOEBOX_MAX_UPLOAD_NUM_OF_ITEMS} at a time) or forward emails to
              your shoebox email address to have them automatically uploaded
              here. Then from here, you can assign each to a project by clicking
              on the shoebox item. And don&apos;t forget, the subject and body
              of the email will be included, so add any notes you want.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        spacing={2}
        style={{
          marginTop: 4,
          paddingTop: 16,
          paddingBottom: 16,
          background: '#fff',
          marginBottom: 1,
          position: 'relative',
        }}
        className={classes.pageTitleWrapper}
      >
        <Grid item xs={8}>
          <Tooltip title="Refresh Items">
            <IconButton
              onClick={() => {
                getCompanyShoeboxItemsRefetch();
              }}
              style={{
                position: 'absolute',
                bottom: 0,
                left: 10,
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <FileUploadArea
            mode="imageOrPdf"
            allowMultiple
            maxFiles={SHOEBOX_MAX_UPLOAD_NUM_OF_ITEMS}
            noDrag={false}
            passback={handleFiles}
            dropWording="Click or drag &amp; drop files here to upload"
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {getCompanyShoeboxItemsLoading ? (
          <div className={classes.paddedContentWrapper}>Loading...</div>
        ) : (
          <>
            {companyShoeboxItems && !!companyShoeboxItems.length ? (
              <>
                <ShoeboxItemsDatagrid
                  items={companyShoeboxItems}
                  viewThisItem={viewThisItem}
                  createContentFromShoeboxItem={createContentFromShoeboxItem}
                  removeThisItem={verifyShoeboxItemRemoval}
                  companyProjects={companyProjects}
                  companyCrew={companyCrew}
                />
                {companyShoeboxItems?.length <
                  companyShoeboxItemsTotalCount && (
                  <Grid
                    container
                    justifyContent="center"
                    style={{
                      marginTop: 64,
                      marginBottom: 64,
                      textAlign: 'center',
                    }}
                  >
                    <Warning color="error" style={{ marginRight: 8 }} />
                    You have hit a max of {companyShoeboxItems.length} items
                    (with{' '}
                    {companyShoeboxItemsTotalCount - companyShoeboxItems.length}{' '}
                    more to go).
                    <br />
                    Please address some items and refresh to load more.
                  </Grid>
                )}
              </>
            ) : (
              <div className={classes.paddedContentWrapper}>
                Currently no shoebox items
              </div>
            )}
          </>
        )}
      </Grid>
      {createContentFlowInfo.open && (
        <Dialog
          maxWidth="lg"
          fullWidth
          open
          PaperProps={{ style: { minHeight: '80%' } }}
        >
          <DialogTitle onClose={handleAbortProcess} />
          <DialogContent>
            {!createContentFlowInfo.type && (
              <AddWhatToProject
                parentInfo={createContentFlowInfo.parentInfo}
                canEdit
                passOptionsBack={passedOptions =>
                  handleWhatToAddChoice(passedOptions)
                }
                mode="shoebox"
                contentIs={assignFlowItemMediaType}
              />
            )}
            {showChooseProject && (
              // Everything except bill payments should come here
              <>
                <Typography
                  variant="h3"
                  gutterBottom
                  align="center"
                  color="primary"
                >
                  Choose which project
                  {chooseProjectAsMultipleSelection ? 's' : ''} to add this to:
                </Typography>
                <ChooseProjectWithSubs
                  setSelectedProject={handleProjectSelection}
                  multipleSelection={chooseProjectAsMultipleSelection}
                />
              </>
            )}
            {createContentFlowInfo.type &&
              (createContentFlowInfo.parentInfo ||
                createContentFlowInfo.projectsToExpense ||
                createContentFlowInfo.type === CONTENT_TYPE.GLOBAL_PAYMENT) && (
                <AddContentForm
                  parentId={
                    createContentFlowInfo?.parentInfo?.contentId || null
                  }
                  passedType={createContentFlowInfo.type}
                  parentInfo={createContentFlowInfo.parentInfo || null}
                  projectsToExpense={
                    createContentFlowInfo.projectsToExpense || null
                  }
                  baseContentInfo={createContentFlowInfo.item || null}
                  fromShoebox
                  onComplete={response => {
                    if (response.success) {
                      if (response.startingInfo) {
                        // run the process again, but upload the image againt to cloudinary so no two items have the same image url (easier for deleting content later)
                        handleAddAgain(response.startingInfo);
                      } else {
                        // remove the shoeboxItem
                        handleShoeboxItemRemoval(shoeboxItemBeingUsed);
                        handleAbortProcess();
                      }
                    } else {
                      handleAbortProcess();
                    }
                  }}
                />
              )}
          </DialogContent>
          {chooseProjectAsMultipleSelection && (
            <DialogActions>
              <Button onClick={handleAbortProcess} color="primary">
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (multipleSelection.length === 0) {
                    setCreateContentFlowInfo({
                      ...createContentFlowInfo,
                      projectsToExpense: [],
                    });
                  } else if (multipleSelection.length === 1) {
                    const [{ project }] = multipleSelection;
                    setCreateContentFlowInfo({
                      ...createContentFlowInfo,
                      parentInfo: project,
                    });
                  } else {
                    setCreateContentFlowInfo({
                      ...createContentFlowInfo,
                      projectsToExpense: _.map(
                        multipleSelection,
                        ({ project, projectPath }) => ({
                          project,
                          projectPath,
                          amount: (0).toFixed(2),
                          description: '',
                          existingContentInfo: null,
                          billable: false,
                        })
                      ),
                    });
                  }
                }}
              >
                Done
              </Button>
            </DialogActions>
          )}
        </Dialog>
      )}
      {screenBlockingOverlay.open && (
        <LoadingCover customStyles={{ zIndex: 100 }}>
          <Typography variant="h3" align="center">
            {screenBlockingOverlay.wording || 'Upload Shoebox Items...'}
          </Typography>
        </LoadingCover>
      )}
      <Drawer
        anchor="right"
        open={viewItemPanel.open}
        onClose={handleDrawerClose}
        PaperProps={{ className: classes.drawerPaper }}
      >
        {viewItemPanel.paperworkItem && (
          <ViewShoeboxItem
            paperworkItem={viewItemPanel.paperworkItem}
            handleCreateContent={handleCreateContent}
            handleUpdate={handleUpdate}
            onClose={handleDrawerClose}
          />
        )}
      </Drawer>
      {dialogInfo.open && (
        <OkCancelDialog
          title={dialogInfo.title}
          open={dialogInfo.open}
          onClose={dialogInfo.onClose}
          hideCancel={dialogInfo.hideCancel}
          onConfirm={dialogInfo.onConfirm}
        >
          <Typography>{dialogInfo.message}</Typography>
        </OkCancelDialog>
      )}
      {handleDupesDialogInfo.open && (
        <ShoeboxDupesHandler
          dupes={handleDupesDialogInfo.dupes}
          allFiles={handleDupesDialogInfo.allFiles}
          whatToSave={handleDupesDialogInfo.whatToSave}
        />
      )}
    </Grid>
  );
};
function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
    managingCompanyId: _.get(
      state.appState,
      'managingCompanyInfo.managingCompanyId',
      null
    ),
  };
}

export default compose(
  // Queries
  GetCompanyCrewAction,
  GetCompanyInfoAction,
  ListCompanyProjectsAction,
  // Mutations
  AddCompanyShoeboxItemsAction,
  UpdateCompanyShoeboxItemAction,
  RemoveCompanyShoeboxItemAction,
  withApollo
)(connect(mapStateToProps)(ShoeboxDashboard));
