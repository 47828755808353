import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import ContentLoader from 'react-content-loader';
import FadeIn from 'react-fade-in/lib/FadeIn';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
    margin: '0 auto',
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const UserDisplay = ({ userInfo, managingCompanyInfo, errorOccurred }) => {
  const classes = useStyles();
  const displayNameRef = useRef(null);

  const isCopilot =
    userInfo?.managingFirmId || !!managingCompanyInfo?.isSubscriptionBased;

  useEffect(() => {
    // decrease font size by 1px until it fits
    if (displayNameRef.current?.offsetWidth) {
      if (displayNameRef.current.offsetWidth > 200) {
        const currentFontSize = _.replace(
          window.getComputedStyle(displayNameRef.current).fontSize,
          'px',
          ''
        );

        displayNameRef.current.style.fontSize = `${currentFontSize - 1}px`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayNameRef.current?.offsetWidth]);

  return (
    <Grid container>
      {errorOccurred && `An error occurred.`}
      {!errorOccurred && !userInfo && (
        <ContentLoader
          height={95}
          width={208}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="57" y="75" rx="3" ry="3" width="94" height="18" />
          <circle cx="104" cy="30" r="30" />
        </ContentLoader>
      )}
      {!errorOccurred && userInfo && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <FadeIn>
              {!isCopilot && (
                <Avatar
                  alt="Person"
                  className={classes.avatar}
                  src={userInfo.profilePic}
                />
              )}
              <Typography
                ref={displayNameRef}
                className={classes.name}
                variant={isCopilot ? 'body1' : 'h5'}
                align="center"
              >
                {isCopilot ? userInfo.email : userInfo.username}
              </Typography>
            </FadeIn>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = ({ appState, userInfo }) => ({
  userInfo,
  managingCompanyInfo: appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(UserDisplay);
