import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import QboCustomerSearch from '../qbo-customer-search';

function ImportProjectStepChooseProject({
  handleProjectSelection,
  selectedCustomer,
}) {
  let firstAndLast = '';
  if (selectedCustomer?.firstName) {
    firstAndLast += selectedCustomer.firstName;
  }
  if (selectedCustomer?.lastName) {
    if (firstAndLast) {
      firstAndLast += ' ';
    }
    firstAndLast += selectedCustomer.lastName;
  }

  let selectedCustomerName = firstAndLast;
  if (selectedCustomer.companyName) {
    if (selectedCustomerName) {
      selectedCustomerName += ` (${selectedCustomer.companyName})`;
    } else {
      selectedCustomerName += selectedCustomer.companyName;
    }
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body1">
          <b>Choose Sub-Customer</b>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          Select the QuickBooks Online sub-customer of{' '}
          <b>{selectedCustomerName}</b> that you would like to import as a Level
          project.
        </Typography>
      </Grid>
      <Grid item>
        <QboCustomerSearch
          handleSelection={handleProjectSelection}
          parentCustomerInfo={selectedCustomer}
          allowEmpty
          searchOnMount
          searchByParentQboId
        />
      </Grid>
    </Grid>
  );
}

export default ImportProjectStepChooseProject;
