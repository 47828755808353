import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Rfi}
  mutation AddCompanyRfis(
    $companyId: String!
    $listOfRfis: [SingleRfiInput!]!
  ) {
    addCompanyRfis(input: { companyId: $companyId, listOfRfis: $listOfRfis }) {
      items {
        ...RfiBaseFields
      }
    }
  }
`;
