import React from 'react';
import {
  Avatar,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Tooltip,
} from '@material-ui/core';
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';

import palette from '../../theme/palette';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    marginTop: 4,
    marginBottom: 4,
    padding: 8,
    '&:hover': {
      backgroundColor: `${palette.brandColorPrimary25} !important`,
    },
  },
  name: {
    flex: 1,
    marginLeft: 8,
    textTransform: 'uppercase',
  },
  description: {
    paddingRight: 16,
    marginLeft: 20,
  },
  iconButton: {
    backgroundColor: palette.brandColorLightGrey,
  },
  productLogo: {
    width: 120,
    height: 120,
    marginLeft: 16,
  },
  productDetails: {
    marginTop: 8,
    marginBottom: 16,
  },
}));

const ProductItem = ({
  productId,
  name,
  logoSrc,
  description,
  selected,
  expanded,
  disabled,
  disabledTooltip,
  onClick,
  onExpandClick,
  style,
}) => {
  const classes = useStyles();

  const tooltip = disabled ? disabledTooltip : '';

  const handleExpandClick = event => {
    event.stopPropagation();
    onExpandClick();
  };

  return (
    <Grid
      className={classes.container}
      onClick={() => {
        if (!disabled) {
          onClick(productId);
        }
      }}
      component={expanded ? Paper : 'div'}
      style={{
        cursor: disabled ? 'default' : 'pointer',
        backgroundColor: selected ? palette.brandColorPrimary10 : 'inherit',
        ...style,
      }}
    >
      <Tooltip title={tooltip}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          spacing={3}
        >
          <Grid
            item
            xs={12}
            container
            direction="column"
            style={{ textAlign: 'left' }}
          >
            <Grid
              item
              container
              xs={12}
              justifyContent="space-between"
              alignItems="center"
            >
              <IconButton
                size="small"
                onClick={handleExpandClick}
                className={classes.iconButton}
              >
                {expanded ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </IconButton>
              <Grid item className={classes.name}>
                {name}
              </Grid>
              <Grid item>
                <Checkbox
                  checked={selected}
                  color="primary"
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Grid
                item
                container
                direction="row"
                wrap="nowrap"
                xs={12}
                justifyContent="space-between"
                alignItems="center"
                className={classes.productDetails}
              >
                {!isMobile && (
                  <Grid item>
                    <Avatar
                      alt="product-logo"
                      variant="square"
                      src={logoSrc}
                      className={classes.productLogo}
                    />
                  </Grid>
                )}
                <Grid item className={classes.description}>
                  {description}
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Tooltip>
    </Grid>
  );
};

export default ProductItem;
