import gql from 'graphql-tag';

export default gql`
  mutation AddOrUpdateCompanyQuestions(
    $questions: [AddTimetrackingQuestionInput!]!
    $companyId: String!
  ) {
    addOrUpdateCompanyQuestions(
      input: { questions: $questions, companyId: $companyId }
    ) {
      status
      msg
    }
  }
`;
