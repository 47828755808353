import gql from 'graphql-tag';

export default gql`
  query GetProductPricesByType($productType: String!) {
    getProductPricesByType(input: { productType: $productType }) {
      items {
        id
        active
        currency
        nickname
        product
        recurring {
          interval
          intervalCount
          trialPeriodDays
        }
        type
        unitAmount
      }
      nextToken
    }
  }
`;
