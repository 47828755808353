import gql from 'graphql-tag';

import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Customer}
  mutation SyncCustomerToExisting(
    $companyId: String!
    $customerId: String!
    $qboCustomerId: String!
  ) {
    syncCustomerToExisting(
      input: {
        companyId: $companyId
        customerId: $customerId
        qboCustomerId: $qboCustomerId
      }
    ) {
      ...CustomerFields
    }
  }
`;
