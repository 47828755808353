import React from 'react';
import { Tooltip, Typography, makeStyles } from '@material-ui/core';
import { Help as HelpIcon } from '@material-ui/icons';

import palette from '../../../theme/palette';

const useStyles = makeStyles(() => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    color: palette.brandColorDarkGrey,
    '.Mui-focused &': {
      color: palette.brandColorPrimary,
    },
  },
}));

const Label = ({ text, required, tooltip }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.label}>
      {text}
      {!!required && <span style={{ color: palette.brandColorError }}>*</span>}
      &nbsp;
      {!!tooltip && (
        <Tooltip title={tooltip}>
          <HelpIcon fontSize="small" color="primary" />
        </Tooltip>
      )}
    </Typography>
  );
};

export default Label;
