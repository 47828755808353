import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import FadeIn from 'react-fade-in';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';

import {
  Dashboard as DashboardIcon,
  Videocam as VideocamIcon,
  BorderColor as BorderColorIcon,
  CalendarToday as CalendarTodayIcon,
  TimerOutlined as TimerOutlinedIcon,
  CheckBoxOutlined as CheckBoxOutlinedIcon,
  PictureAsPdf as PictureAsPdfIcon,
  ReceiptOutlined as ReceiptOutlinedIcon,
  AttachMoney as AttachMoneyIcon,
} from '@material-ui/icons';

import RefreshIcon from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';
import { cloudinaryifyProfilePic } from '../../helpers/cloudinary';
import { renderDateString } from '../../helpers/renderDateString';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: 0,
    overflow: 'hidden',
  },
  addActivityLink: {
    width: '100%',
  },
  paper: {
    margin: `${theme.spacing(1)}px auto 0 auto`,
    padding: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eee',
      color: '#fff',
    },
    transition: 'all 0.3s ease',
    width: '100%',
  },
  paperAddActivity: {
    padding: 0,
    margin: `${theme.spacing(3)}px auto`,
  },
  addIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 40,
  },
  refreshIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 20,
    padding: 0,
    margin: 0,
  },
  bigIcon: {
    height: '50%',
    width: '50%',
    borderRadius: 0,
    color: theme.palette.brandColorPrimary,
  },
  bigAvatar: {
    height: '100%',
    width: '100%',
    borderRadius: 0,
    color: theme.palette.brandColorPrimary,
  },
  ActivityDate: {
    fontSize: '80%',
  },
  ActivityDesc: {
    marginTop: theme.spacing(1),
  },
  listView: {
    alignItems: 'center',
  },
  timestamp: {
    fontSize: '11px',
    bottom: '0px',
    right: '10px',
    position: 'absolute',
  },
}));

const ListOfActivitiesView = props => {
  const {
    activity,
    className,
    onClickOfActivity,
    refetchListingActivitys,
    userId,
  } = props;
  const classes = useStyles();
  const [useThisData, setUseThisData] = React.useState(activity);

  React.useEffect(() => {
    setUseThisData(activity);
  }, [activity]);

  const renderVisual = passedActivity => {
    let useThisForPic;

    if (passedActivity.contentUrl) {
      if (passedActivity.type === 'image') {
        if (passedActivity.contentUrl.indexOf('cloudinary') !== -1) {
          useThisForPic = cloudinaryifyProfilePic(passedActivity.contentUrl);
        } else {
          useThisForPic = passedActivity.contentUrl;
        }
      } else if (passedActivity.type === 'gallery') {
        let allImages;
        try {
          allImages = JSON.parse(passedActivity.contentUrl);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('issue parsing contentUrl: ', e);
        }
        if (allImages && allImages.length) {
          const firstImage = allImages[0].uri; // get first item of array
          if (firstImage.indexOf('cloudinary') !== -1) {
            useThisForPic = cloudinaryifyProfilePic(firstImage);
          } else {
            useThisForPic = firstImage;
          }
        }
      }
    }

    // TODO: use the renderIconComponentFromType from appDefaults once this page is up and usable again
    switch (passedActivity.type) {
      case 'project':
        return <DashboardIcon className={classes.bigIcon} />;
      case 'receipt':
      case 'bill':
        return <ReceiptOutlinedIcon className={classes.bigIcon} />;
      case 'invoice':
      case 'payment':
        return <AttachMoneyIcon className={classes.bigIcon} />;
      case 'textnote':
        return <BorderColorIcon className={classes.bigIcon} />;
      case 'image':
        return <Avatar src={useThisForPic} className={classes.bigAvatar} />;
      case 'gallery':
        return <Avatar src={useThisForPic} className={classes.bigAvatar} />;
      case 'video':
        return <VideocamIcon className={classes.bigIcon} />;
      case 'task':
        return <CheckBoxOutlinedIcon className={classes.bigIcon} />;
      case 'timetracking':
        return <TimerOutlinedIcon className={classes.bigIcon} />;
      case 'shift':
        return <CalendarTodayIcon className={classes.bigIcon} />;
      case 'pdf': {
        return <PictureAsPdfIcon className={classes.bigIcon} />;
      }
      default:
        return null;
    }
  };
  const vowelCheck = (checkedvalue, isStartOfSentence) => {
    const firstLetter = checkedvalue.charAt(0).toLowerCase();
    const nextLetter =
      ['a', 'e', 'i', 'o', 'u'].indexOf(firstLetter) >= 0 ? 'n' : '';
    return `${isStartOfSentence ? 'A' : 'a'}${nextLetter} ${checkedvalue}`;
  };

  // a task was added eg bob added a task
  const renderTitle = passedActivity => {
    let { actionType } = passedActivity;
    // added - any content type - a piece of content was added to a project you’re on - INSERT (or MODIFY for images/gallery)
    //    - check ins/outs = timetracking + contentStatus
    // assignmentChange - task, shift  - when someone assigns one to you - INSERT or MODIFY
    // taskStatusChange - task - when a task's status changes - MODIFY
    // addedTo - project - when you’re added to a project

    // TODO: https://www.npmjs.com/package/html2plaintext to get basic text for preview

    if (passedActivity.type === 'task' || passedActivity.type === 'shift') {
      if (
        passedActivity.actionType === 'shiftAssignment' ||
        passedActivity.actionType === 'taskAssignment'
      ) {
        if (passedActivity.assignedTo === userId) {
          actionType = 'assignedToYou';
        }
      } else if (passedActivity.actionType === 'shiftReassignment') {
        if (userId === passedActivity.assignedTo) {
          actionType = 'assignedToYou';
        } else {
          // it was modified but was assigned to you so as far as you know it's just a new shift
          actionType = 'shiftReassignment';
        }
      } else if (passedActivity.actionType === 'shiftModification') {
        actionType = 'shiftModification';
      } else if (passedActivity.actionType === 'shiftRemoval') {
        actionType = 'shiftRemoval';
      } else if (passedActivity.actionType === 'taskCompletion') {
        actionType = 'unassignedComplete';
      } else {
        actionType = 'added';
      }
    } else if (
      passedActivity.type === 'project' ||
      passedActivity.type === 'subproject'
    ) {
      actionType = 'addedTo';
    } else if (passedActivity.type === 'timetracking') {
      const aboutMe = passedActivity.assignedTo === userId;
      const iDidIt =
        passedActivity.assignedTo === userId &&
        passedActivity.userId === userId;

      if (passedActivity.contentStatus === 'checkin') {
        actionType = !iDidIt && aboutMe ? 'checkedYouIn' : 'checkedIn';
      } else {
        actionType = !iDidIt && aboutMe ? 'checkedYouOut' : 'checkedOut';
      }
    }
    const youOrSomeoneElse =
      passedActivity.userId === userId
        ? 'You'
        : passedActivity.username || 'Someone';

    let textToUse;
    switch (actionType) {
      case 'added':
        textToUse = `${youOrSomeoneElse} added ${vowelCheck(
          passedActivity.type
        )}`;
        break;
      case 'assignedToYou':
        textToUse = `${youOrSomeoneElse} assigned you ${vowelCheck(
          passedActivity.type
        )}`;
        break;
      case 'shiftReassignment':
        textToUse = `${youOrSomeoneElse} assigned you ${vowelCheck(
          passedActivity.type
        )}`;
        break;
      case 'shiftModification':
        textToUse = 'Your shift details were changed (time or notes).';
        break;
      case 'shiftRemoval':
        textToUse = 'One of your shifts was removed.';
        break;
      case 'unassignedComplete':
        textToUse = `${youOrSomeoneElse} completed a task`;
        break;
      case 'youAssignedCompleted':
        textToUse = `
            ${youOrSomeoneElse} completed the task &quot;
            ${passedActivity.contentTitle.substring(0, 20)}...&quot;
          `;
        break;
      case 'addedTo':
        textToUse = `${youOrSomeoneElse} added you to a project`;
        break;
      case 'checkedIn':
        textToUse = `${youOrSomeoneElse} clocked in`;
        break;
      case 'checkedOut':
        textToUse = `${youOrSomeoneElse} clocked out`;
        break;
      case 'checkedYouIn':
        textToUse = `You were clocked in`;
        break;
      case 'checkedYouOut':
        textToUse = `You were clocked out`;
        break;
      default:
        textToUse = `${youOrSomeoneElse} added ${vowelCheck(
          passedActivity.type
        )}`;
        break;
    }

    return <Typography variant="h5">{textToUse}</Typography>;
  };
  // and where it happened eg acme renue
  const renderSubText = passedActivity => {
    return (
      <Typography variant="body2">
        {passedActivity.contentParentTitle}
      </Typography>
    );
  };

  const renderWholeItem = passedActivity => (
    <Paper
      className={classes.paper}
      onClick={() => onClickOfActivity(passedActivity)}
      key={passedActivity.viewerId + passedActivity.timestamp}
    >
      <Grid
        container
        wrap="nowrap"
        spacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={3}
          container
          justifyContent="center"
          alignItems="center"
          style={{ padding: '5px' }}
        >
          {renderVisual(passedActivity)}
        </Grid>
        <Grid item xs={9}>
          {renderTitle(passedActivity)}
          {renderSubText(passedActivity)}
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.timestamp}>
              {renderDateString(
                passedActivity.timestamp,
                null,
                'MMMM D, YYYY @ h:mma'
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <>
      {/* <FilterList
        data={Activitys}
        isFiltered={filterStatus => setIsFiltered(filterStatus)}
        passBack={filteredData => setUseThisData(filteredData)}
      /> */}
      <Grid
        container
        alignItems="center"
        className={clsx(classes.root, className)}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Tooltip title="Refresh the list">
              <IconButton onClick={() => refetchListingActivitys()}>
                <RefreshIcon
                  style={{ fontSize: 'inherit', color: 'inherit' }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FadeIn style={{ width: '100%' }}>
            {useThisData && useThisData.map(renderWholeItem)}
          </FadeIn>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            align="center"
            style={{ padding: 24, paddingTop: 40 }}
          >
            The activity feed goes back 30 days.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

function mapStateToProps(state) {
  return {
    userId: state.userInfo.userId,
  };
}

export default connect(mapStateToProps)(ListOfActivitiesView);
