import React, { useState } from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import QboCustomerSearch from '../qbo-customer-search';

const QboSyncExistingCustomer = ({
  syncToExistingCustomer,
  handleClose,
  recordType,
  parentCustomerInfo,
  searchDefault = '',
}) => {
  const [selectedRemoteCustomer, setSelectedRemoteCustomer] = useState(null);

  const handleSync = () => {
    syncToExistingCustomer(selectedRemoteCustomer);
    handleClose();
  };

  return (
    <Dialog open maxWidth="md">
      <DialogTitle>Sync to existing QuickBooks Online customer</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" style={{ marginBottom: 16 }}>
          This {recordType} will be synced with an existing customer in
          QuickBooks Online. After that, the Level {recordType} and QuickBooks
          Online customer will be connected so changes made in QuickBooks Online
          will automatically be made in Level and vice-versa!
        </Typography>
        <QboCustomerSearch
          initialValue={searchDefault}
          parentCustomerInfo={parentCustomerInfo}
          handleSelection={setSelectedRemoteCustomer}
          allowEmpty={!!parentCustomerInfo}
          searchOnMount={!!parentCustomerInfo}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSync}
          disabled={selectedRemoteCustomer === null}
          variant="contained"
          color="primary"
        >
          Sync
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QboSyncExistingCustomer;
