import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ButtonBase from '@material-ui/core/ButtonBase';
import CloseIcon from '@material-ui/icons/Close';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import palette from '../../theme/palette';

const useStyles = makeStyles(theme => ({
  supportingItemThumbWrapper: {
    position: 'relative',
    height: 110,
    width: 150,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
    overflow: 'hidden',
  },
  supportingItemThumb: {
    height: '100%',
    width: 'auto',
  },
  removeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'rgba(0, 0, 0, 0.6)',
    height: 24,
    width: 24,
  },
}));

const RenderSupportingItem = ({
  supportingItem,
  index,
  handleOnlickThumbnail,
  handleRemoveFromMedia,
  isReadonly = false,
}) => {
  const classes = useStyles();
  // need to show PDFs & images, both before they've been uploaded and after
  // rfiToEdit.media = [
  //   uri: String!
  //   aspectRatio: String
  //   type: String
  // ]
  // Show a list of thumbnails with a built in "remove" button and clickable to a mostly full screen slideshow
  let src = supportingItem.uri;
  const contentUrl = supportingItem.uri;
  const contentPath = supportingItem.path;
  const isLocal = supportingItem.uri.startsWith('blob:');
  const isPdf =
    (contentUrl && contentUrl.endsWith('.pdf')) ||
    // if the file has not been uploaded yet (blob),
    // check if the path ends with .pdf
    (contentPath && contentPath.endsWith('.pdf'));

  if (isPdf) {
    src = supportingItem.uri.replace('.pdf', '.jpg');
  }
  if (isLocal) {
    src = supportingItem.uri;
  }

  return (
    <div
      key={supportingItem.uri}
      className={classes.supportingItemThumbWrapper}
    >
      <ButtonBase
        className={classes.supportingItemThumbWrapper}
        onClick={
          handleOnlickThumbnail ? () => handleOnlickThumbnail(index) : null
        }
        style={{ backgroundColor: palette.background.alt }}
      >
        {isLocal && isPdf ? (
          <PictureAsPdfIcon style={{ fontSize: 42 }} />
        ) : (
          <img
            src={src}
            alt="supporting item"
            className={classes.supportingItemThumb}
          />
        )}
      </ButtonBase>
      {!isReadonly && handleRemoveFromMedia && (
        <ButtonBase
          className={classes.removeButton}
          onClick={() => handleRemoveFromMedia(index)}
        >
          <CloseIcon htmlColor="#fff" />
        </ButtonBase>
      )}
    </div>
  );
};

export default RenderSupportingItem;
