import React, { useState, useMemo, useEffect } from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { CancelOutlined as CancelOutlinedIcon } from '@material-ui/icons';
import { withSnackbar } from 'notistack';
import _ from 'lodash';

import { GLOBAL_EXPENSE_STATUS } from '../../config/appDefaults';

// GraphQL
import ListCompanyGlobalFinancialItems from '../../graphql/queries/list-company-global-financial-items';
import { GetVendorAction } from '../../graphql/graphql';

import FilterList from '../FilterSearch/filterSearch';
import GlobalBillItem from './global-bill-item';
import { useRepetitiveQuery } from '../../hooks';

const ChooseBillsDialog = ({
  initialSelectedBills,
  vendorId,
  vendor,
  vendorLoading,
  multipleSelection,
  managingCompanyInfo,
  open,
  onClose,
  onBillSelectionDone,
}) => {
  const [billsToShow, setBillsToShow] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedBillMap, setSelectedBillMap] = useState({});

  const listCompanyGlobalFinancialItemsAccessor =
    'listCompanyGlobalFinancialItems';
  const variables = {
    companyId: managingCompanyInfo.managingCompanyId,
    typesToInclude: ['BILL'],
  };

  if (vendorId) {
    variables.vendorId = vendorId;
  }

  const listCompanyGlobalBillsQuery = useRepetitiveQuery(
    ListCompanyGlobalFinancialItems,
    {
      skip: !managingCompanyInfo.managingCompanyId,
      variables,
      fetchPolicy: 'cache-and-network',
      accessor: listCompanyGlobalFinancialItemsAccessor,
    }
  );
  const globalBills = _.get(
    listCompanyGlobalBillsQuery,
    `data.${listCompanyGlobalFinancialItemsAccessor}.items`,
    null
  );

  const listCompanyGlobalBillsLoading = _.get(
    listCompanyGlobalBillsQuery,
    'loading'
  );

  const openBills = useMemo(() => {
    const initialSelectedBillsMap = _.keyBy(initialSelectedBills, 'contentId');

    const openAndUnorderedBills = _.filter(
      globalBills,
      ({ contentId, contentStatus }) => {
        return (
          !initialSelectedBillsMap[contentId] &&
          (contentStatus === GLOBAL_EXPENSE_STATUS.UNPAID ||
            contentStatus === GLOBAL_EXPENSE_STATUS.PARTIALLY_PAID)
        );
      }
    );

    return _.orderBy(openAndUnorderedBills, ['endDate'], ['asc']);
  }, [initialSelectedBills, globalBills]);

  useEffect(() => {
    if (!openBills) {
      setBillsToShow(null);
    } else {
      setBillsToShow(openBills);
    }
  }, [openBills]);

  const handleGlobalBillItemSelect = globalBill => {
    if (multipleSelection) {
      setSelectedBillMap(currentState => {
        return {
          ...currentState,
          [globalBill.contentId]: currentState[globalBill.contentId]
            ? null
            : globalBill,
        };
      });
    } else {
      setSelectedBillMap({ [globalBill.contentId]: globalBill });
    }
  };

  const handleDoneButtonClick = () => {
    const selectedBills = [];
    _.forEach(selectedBillMap, bill => {
      if (bill) {
        selectedBills.push(bill);
      }
    });

    onBillSelectionDone(selectedBills);
  };

  const renderBillList = () => {
    if (_.isEmpty(billsToShow)) {
      return (
        <Typography variant="body1" align="center">
          {!isFiltered
            ? `No open bills found${vendor?.name ? ` for ${vendor.name}` : ''}`
            : 'No search results found'}
        </Typography>
      );
    }

    return _.map(billsToShow, globalBill => (
      <GlobalBillItem
        key={globalBill.contentId}
        globalBillItem={globalBill}
        selected={!!selectedBillMap[globalBill.contentId]}
        onItemSelect={handleGlobalBillItemSelect}
      />
    ));
  };

  const loading = vendorLoading || listCompanyGlobalBillsLoading;

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      PaperProps={{ style: { minHeight: '80%' } }}
    >
      <DialogTitle onClose={onClose}>
        <div>
          <Typography variant="h2" gutterBottom align="center" color="primary">
            {multipleSelection ? 'Choose another bills' : 'Choose another bill'}
          </Typography>
        </div>
        <IconButton
          onClick={onClose}
          style={{ position: 'absolute', right: 0, top: 0, fontSize: 40 }}
        >
          <CancelOutlinedIcon fontSize="inherit" color="primary" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid item style={{ alignSelf: 'center' }}>
            {vendor ? (
              <Typography variant="subtitle1">
                Unpaid and partially-paid bills for <b>{vendor.name}</b>
              </Typography>
            ) : (
              <Typography variant="subtitle1">
                Unpaid and partially-paid bills
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={8}
            style={{
              margin: '0 auto',
              width: '100%',
              maxHeight: '40vh',
              alignItems: 'center',
            }}
          >
            <FilterList
              data={openBills}
              isFiltered={filterStatus => setIsFiltered(filterStatus)}
              passBack={setBillsToShow}
              searchBy={['documentNumber', 'amount', 'vendor.name']}
              placeholder={
                vendorId
                  ? 'Search by bill number or amount'
                  : 'Search by bill number, amount or vendor name'
              }
            />
            <div style={{ height: 32 }} />
            {loading ? (
              <Grid container justifyContent="center">
                <CircularProgress />
              </Grid>
            ) : (
              renderBillList()
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDoneButtonClick}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || {},
});

export default compose(
  GetVendorAction,
  withSnackbar,
  connect(mapStateToProps)
)(ChooseBillsDialog);
