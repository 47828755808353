import numeral from 'numeral';

const monetaryRender = ({ value, withDecimals = false }) => {
  if (typeof value !== 'number') {
    return 'n/a';
  }
  const format = withDecimals ? '$0,0.00' : '$0,0';
  return numeral(value).format(format);
};

export default monetaryRender;
