import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  query ListMyJrns($userId: String!) {
    listMyJrns(userId: $userId) {
      items {
        address
        contentId
        jrnId
        title
        creatorId
        contentUrl
        description
        endDate
        qboCustomerId
        privacyLevel
        inviteCode
        allowedToEdit
        allowedToAdd
        allowedToView
        usersEverOnJrn
        startDate
        synced
        labels
        address
        latitude
        longitude
        phoneNumber
        phoneNumberActive
        type
        lastMessageSent
        useTimetracking
        useTimetrackingQuestions
        timetrackingQuestions
        timetrackingQuestionsCheckout
        # REMOVEME: remove this when "bugets" field is deprecated
        budgets {
          ...BudgetFields
        }
        fromTemplate
        timetrackingType
      }
    }
  }
`;
