import { useQuery } from 'react-apollo-hooks';

import { QBO_SYNCABLE_TYPE } from '../config/appDefaults';
import { QBO_SYNC_STATE, getQboSyncState } from '../helpers/qbo-sync';
import GetJrnQuery from '../graphql/queries/GetJrn';
import GetCustomerByIdQuery from '../graphql/queries/get-customer-by-id';

function useQboStatusUpdateChecker({ recordInfo, recordType }) {
  let syncState;
  if (recordInfo) {
    syncState = getQboSyncState(recordInfo);
  }

  // Poll for customer updates
  // Watch for change in qboCustomerId from new
  const {
    stopPolling: getCustomerStopPolling,
    data: getCustomerData,
  } = useQuery(GetCustomerByIdQuery, {
    variables: {
      customerId: recordInfo.customerId || null,
    },
    skip:
      !recordInfo ||
      !recordType ||
      syncState !== QBO_SYNC_STATE.SYNCING ||
      recordType !== QBO_SYNCABLE_TYPE.CUSTOMER,
    pollInterval: 3000,
  });

  // Cache should take care of the sync status
  if (
    getCustomerData &&
    getCustomerData.getCustomerById &&
    getCustomerData.getCustomerById.qboCustomerId !== 'NEW'
  ) {
    getCustomerStopPolling();
  }

  // Poll for project or lead updates
  // Watch for change in qboCustomerId from new
  const { stopPolling: getJrnStopPolling, data: getJrnData } = useQuery(
    GetJrnQuery,
    {
      variables: {
        jrnId: recordInfo.contentId || null,
      },
      skip:
        !recordInfo ||
        !recordType ||
        syncState !== QBO_SYNC_STATE.SYNCING ||
        (recordType !== QBO_SYNCABLE_TYPE.PROJECT &&
          recordType !== QBO_SYNCABLE_TYPE.LEAD),
      pollInterval: 3000,
    }
  );

  // If qboCustomerId is then set, stop polling
  // Cache should take care of the sync status
  if (
    getJrnData &&
    getJrnData.getJrn &&
    getJrnData.getJrn.qboCustomerId !== 'NEW'
  ) {
    getJrnStopPolling();
  }
}

export default useQboStatusUpdateChecker;
