/* eslint-disable no-continue */
/* eslint-disable no-labels */
/* eslint-disable no-restricted-syntax */
import moment from 'moment';
import _ from 'lodash';

export const generateGraphRange = (projectInfo, graphDataArray) => {
  // Project range
  const projectStartDate = new Date(projectInfo.startDate);
  const projectEndDate = new Date(projectInfo.endDate);

  let startDate = projectStartDate;
  let endDate = projectEndDate;
  let daysToPad = 0;

  // Report data range
  _.forEach(graphDataArray, graphData => {
    if (graphData.values && graphData.values.length > 0) {
      const firstDate = graphData.values[0].x;
      const lastDate = graphData.values[graphData.values.length - 1].x;

      if (firstDate < startDate) {
        startDate = firstDate;
      }
      if (lastDate > endDate) {
        endDate = lastDate;
      }

      if (projectEndDate < lastDate) {
        daysToPad = 2;
      }
    }
  });

  return { startDate, endDate, daysToPad };
};

export const generateDailyValues = (
  startDate,
  endDate,
  eventDrivenValues,
  { daysToPad = 0 } = {}
) => {
  const minMoment = moment(startDate);
  const maxMoment = moment(endDate);

  const baseDate = minMoment.clone();
  baseDate.set('hours', 0);
  baseDate.set('minutes', 0);
  baseDate.set('seconds', 0);
  baseDate.set('milliseconds', 0);
  baseDate.add(baseDate.utcOffset(), 'minutes');

  const diffInDays = maxMoment.diff(baseDate, 'days') + 1;

  // Intialize block data
  const blockValues = [];
  for (let i = 0; i <= diffInDays + daysToPad; i += 1) {
    const thisDate = baseDate.clone();
    thisDate.add(i, 'days');
    const xDate = new Date(thisDate.toISOString().split('T')[0]);
    blockValues.push({
      x: xDate,
      y: null,
    });
  }

  // Write block data values
  let nextToWrite = 0;
  eventDrivenLoop: for (let i = 0; i < eventDrivenValues.length; i += 1) {
    for (let j = nextToWrite; j < blockValues.length; j += 1) {
      if (
        i === 0 &&
        blockValues[j].x.getTime() < eventDrivenValues[i].x.getTime()
      ) {
        // If before first event
        blockValues[j].y = 0;
        nextToWrite = j + 1;
      } else if (
        i === eventDrivenValues.length - 1 &&
        blockValues[j].x.getTime() >= eventDrivenValues[i].x.getTime()
      ) {
        // Is last event or after
        if (
          blockValues[j].x.getTime() < Date.now() ||
          blockValues[j].x.getTime() === eventDrivenValues[i].x.getTime()
        ) {
          blockValues[j].y = eventDrivenValues[i].y;
          nextToWrite = j + 1;
        } else {
          // In future
          blockValues[j].y = null;
          nextToWrite = j + 1;
        }
      } else if (
        blockValues[j].x.getTime() >= eventDrivenValues[i].x.getTime() &&
        blockValues[j].x.getTime() < eventDrivenValues[i + 1].x.getTime()
      ) {
        // Is in report range
        blockValues[j].y = eventDrivenValues[i].y;
        nextToWrite = j + 1;
      } else {
        continue eventDrivenLoop;
      }
    }
  }

  return blockValues;
};
