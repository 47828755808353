import React from 'react';
import { Typography } from '@material-ui/core';

import LevelSupportEmailAddressLink from '../level-support-email-address-link/level-support-email-address-link';
import WarningBox from '../warning-box/warning-box';

const ResponseTooBigWarning = ({ managingCompanyId }) => {
  return (
    <WarningBox>
      <Typography
        variant="h4"
        style={{ padding: 32, color: 'inherit', width: '100%' }}
        align="center"
      >
        Not all results were returned due to a large number of items.
        <br />
        Please contact support at&nbsp;
        <LevelSupportEmailAddressLink
          href={`mailto:support@checkthelevel.com?subject=Data%20Response%20Too%20Large&body=${window.location.href}%0A${managingCompanyId}%0A-------------------------------%0A%0AHi%20Support%2C%20please%20help%20with%20this%20issue.`}
          style={{ color: 'inherit', textDecoration: 'underline' }}
        />
        &nbsp;for help.
      </Typography>
    </WarningBox>
  );
};

export default ResponseTooBigWarning;
