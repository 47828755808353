import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const ErrorHandle = props => {
  const { message, refetch, buttonColor, buttonVariant, buttonWording } = props;
  return (
    <Grid
      spacing={2}
      container
      direction="column"
      justifyContent="center"
      style={{ marginTop: 30, textAlign: 'center' }}
    >
      <Grid item>
        {message || 'Sorry, there was a network error. It happens.'}
      </Grid>
      <Grid item>
        {refetch ? (
          <Button
            color={buttonColor || 'primary'}
            variant={buttonVariant || 'outlined'}
            onClick={() => refetch()}
          >
            {buttonWording || 'Retry'}
          </Button>
        ) : (
          <span>Please try again later.</span>
        )}
      </Grid>
    </Grid>
  );
};

export default ErrorHandle;
