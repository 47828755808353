const checked = (value, options) => {
  if (value !== true) {
    return options.message || 'must be checked';
  }
  return null;
};

export default {
  checked,
};

export const isValidEmail = emailToCheck => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegex.test(emailToCheck);
};
