import React from 'react';
import palette from '../../theme/palette';

const LevelSupportEmailAddressLink = ({ href, style, children, underline }) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={href || 'mailto:support@checkthelevel.com'}
      style={{
        color: palette.brandColorPrimary,
        textDecoration: underline ? 'underline' : 'none',
        ...style,
      }}
    >
      {children || 'support@checkthelevel.com'}
    </a>
  );
};

export default LevelSupportEmailAddressLink;
