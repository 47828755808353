import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.AccountingTxn}
  query GetQboCustomerTxnReport(
    $companyId: String!
    $reportPeriod: String!
    $customPeriodStartDate: String
    $customPeriodEndDate: String
    $options: [String]
  ) {
    getQboCustomerTxnReport(
      input: {
        companyId: $companyId
        reportPeriod: $reportPeriod
        customPeriodStartDate: $customPeriodStartDate
        customPeriodEndDate: $customPeriodEndDate
        options: $options
      }
    ) {
      items {
        ...AccountingTxnFields
      }
      startDate
      endDate
    }
  }
`;
