import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Grid,
  Tabs,
  Tab,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  Add as AddIcon,
  Refresh as RefreshIcon,
  ArrowUpward as UpIcon,
  ArrowDownward as DownIcon,
} from '@material-ui/icons';

import FilterSearch from '../../../components/FilterSearch/filterSearch';

const useStyles = makeStyles(() => ({
  filterIcon: {
    marginBottom: -3,
    marginTop: -3,
    fontSize: 18,
  },
}));

export const LIST_TYPE = {
  ACTIVE_VENDORS: 'activeVendors',
  ARCHIVED_VENDORS: 'archivedVendors',
  ALL_VENDORS: 'allVendors',
};

export const SORT_TYPE = {
  NAME_ASC: 'NAME_ASC',
  NAME_DECS: 'NAME_DECS',
};

const ListOfVendorsHeader = ({
  selectedTab,
  vendors,
  refetchVendorList,
  selectedSortOption,
  onAddButtonClick,
  onFilteredVendorsChange,
  onIsFilteredChange,
  onSortOptionChange,
  onTabChange,
  dispatch,
}) => {
  const classes = useStyles();

  const { state: locationState } = useLocation();

  const handleSortOptionChange = e => {
    const sort = e.target.value;
    onSortOptionChange(sort);
    dispatch({
      type: 'VENDOR_SORT',
      payload: sort,
    });
  };

  const handleTabChange = (event, value) => {
    onTabChange(value);
  };

  const renderTabs = () => {
    return (
      <Grid item style={{ width: '100%' }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
        >
          <Tab
            value={LIST_TYPE.ACTIVE_VENDORS}
            label="Active"
            style={{ flex: 1, minWidth: 70, maxWidth: 'unset' }}
          />
          <Tab
            value={LIST_TYPE.ARCHIVED_VENDORS}
            label="Archive"
            style={{ flex: 1, minWidth: 70, maxWidth: 'unset' }}
          />
          <Tab
            value={LIST_TYPE.ALL_VENDORS}
            label="All"
            style={{ flex: 1, minWidth: 70, maxWidth: 'unset' }}
          />
        </Tabs>
      </Grid>
    );
  };

  return (
    <>
      {renderTabs()}
      <Grid container item style={{ width: '100%', gap: 8 }}>
        <Grid item style={{ flex: 2 }}>
          <FilterSearch
            data={vendors}
            searchBy={['name']}
            isFiltered={filterStatus => onIsFilteredChange(filterStatus)}
            passBack={filteredData => onFilteredVendorsChange(filteredData)}
            initialTextToSearch={locationState?.searchTerm || ''}
          />
        </Grid>
        <Grid item style={{ display: 'flex', flex: 1, minWidth: 220 }}>
          <Grid item style={{ flex: 1 }}>
            <InputLabel shrink id="sort-by-label">
              Sort by
            </InputLabel>
            <Select
              value={selectedSortOption}
              onChange={handleSortOptionChange}
              style={{
                width: '100%',
              }}
            >
              <MenuItem value={SORT_TYPE.NAME_ASC}>
                Name <UpIcon className={classes.filterIcon} />
              </MenuItem>
              <MenuItem value={SORT_TYPE.NAME_DECS}>
                Name <DownIcon className={classes.filterIcon} />
              </MenuItem>
            </Select>
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              flex: 0,
              flexDirection: 'row',
              marginLeft: 8,
            }}
          >
            <Grid item>
              <Tooltip title="Refresh the list">
                <IconButton onClick={() => refetchVendorList()}>
                  <RefreshIcon
                    style={{ fontSize: 'inherit', color: 'inherit' }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            {selectedTab !== LIST_TYPE.ARCHIVED_VENDORS && (
              <Grid item>
                <Tooltip title="Add a vendor">
                  <span>
                    <IconButton onClick={onAddButtonClick}>
                      <AddIcon
                        style={{ fontSize: 'inherit', color: 'inherit' }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo,
});

export default connect(mapStateToProps)(ListOfVendorsHeader);
