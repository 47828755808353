// @ts-check

/**
 * QBO sync state enum
 * @readonly
 * @enum {String}
 */
export const QBO_SYNC_STATE = {
  ACTIVE: 'ACTIVE',
  SYNCING: 'SYNCING',
  NOT_SYNCED: 'NOT_SYNCED',
  ERROR: 'ERROR',
};
