import React, { useEffect, useMemo } from 'react';
import { compose } from 'react-apollo';
import _ from 'lodash';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import ContentLoader from 'react-content-loader';

import { GetAllContentByGlobalExpenseIdAction } from '../../graphql/graphql';
import GlobalExpenseProjectSplit from './global-expense-project-split';
import styles from './view-global-item.styles';
import { CONTENT_TYPE } from '../../config/appDefaults';
import GlobalExpensePaymentSplit from './global-expense-payment-split';

const useStyles = makeStyles(styles);

const GlobalExpenseSplits = ({
  globalExpenseContentLoading,
  globalExpenseContentItems,
  globalExpense,
  selectable,
  companyCrew,
  parentClasses,
  onViewGlobalItem,
  onDeleteBillPayment,
  startPollingGlobalExpense,
  stopPollingGlobalExpense,
  onClose,
}) => {
  const classes = useStyles();

  const { expenses, billPayments } = useMemo(() => {
    let paymentsToUse = null;
    let expensesToUse = null;

    if (globalExpenseContentItems) {
      paymentsToUse = _.filter(globalExpenseContentItems, {
        type: CONTENT_TYPE.BILL_PAYMENT,
      });

      expensesToUse = _.filter(globalExpenseContentItems, {
        type:
          globalExpense.type === CONTENT_TYPE.GLOBAL_BILL
            ? CONTENT_TYPE.BILL
            : CONTENT_TYPE.RECEIPT,
      });
    }

    return { expenses: expensesToUse, billPayments: paymentsToUse };
  }, [globalExpenseContentItems, globalExpense.type]);

  // This is a workaround to make sure the global bill status is updated
  // after a bill payment is made or deleted.
  useEffect(() => {
    if (globalExpense?.type === CONTENT_TYPE.GLOBAL_BILL) {
      const globalAmount = _.get(globalExpense, 'amount.value', 0);
      const globalBalance = _.get(globalExpense, 'balance.value', 0);
      const totalBillPayment = _.sumBy(billPayments, 'amount.value');

      // Edge case: if the globalAmount is 24.95, globalBalance is -100, and the totalBillPayment is 124.95,
      // then the totalBillPayment + globalBalance will be 24.950000000000003, instead of 24.95.
      // So to fix this, we round the result to 2 decimal places.
      if (
        Number((totalBillPayment + globalBalance).toFixed(2)) !==
        Number(globalAmount.toFixed(2))
      ) {
        startPollingGlobalExpense(1000);
      } else {
        stopPollingGlobalExpense();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billPayments, globalExpense]);

  return (
    <>
      <Grid
        container
        item
        direction="column"
        xs={12}
        className={classes && classes.infoSection}
        spacing={1}
      >
        <Grid item xs={12}>
          <Typography variant="h5" className={parentClasses.label}>
            Project Allocation
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {globalExpenseContentLoading && (
            <ContentLoader
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
              className={classes.projectSplitContainer}
              height={50}
            >
              <circle cx="25" cy="25" r="15" />
              <rect x="50" y="12" rx="0" ry="0" width="150" height="12" />
              <rect x="50" y="30" rx="0" ry="0" width="50" height="8" />
            </ContentLoader>
          )}
          {!globalExpenseContentLoading && _.isEmpty(expenses) && (
            <Typography variant="body1">
              Not allocated to any projects.
            </Typography>
          )}
          {!globalExpenseContentLoading &&
            _.map(expenses, contentItem => (
              <GlobalExpenseProjectSplit
                classes={classes}
                key={contentItem.contentId}
                content={contentItem}
                selectable={selectable}
                onClose={onClose}
              />
            ))}
        </Grid>
      </Grid>
      {globalExpense.type === CONTENT_TYPE.GLOBAL_BILL && (
        <Grid
          container
          item
          direction="column"
          xs={12}
          className={classes && classes.infoSection}
          spacing={1}
        >
          <Grid item xs={12}>
            <Typography variant="h5" className={parentClasses.label}>
              Payments
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {globalExpenseContentLoading && (
              <ContentLoader
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                className={classes.paymentSplitContainer}
                height={20}
              >
                <rect x="0" y="12" rx="0" ry="0" width="50" height="8" />
                <rect x="65" y="12" rx="0" ry="0" width="150" height="8" />
                <rect x="350" y="12" rx="0" ry="0" width="50" height="8" />
              </ContentLoader>
            )}
            {!globalExpenseContentLoading && _.isEmpty(billPayments) && (
              <Typography variant="caption">No payments recorded.</Typography>
            )}
            {!globalExpenseContentLoading &&
              _.map(billPayments, contentItem => (
                <GlobalExpensePaymentSplit
                  classes={classes}
                  key={contentItem.contentId}
                  content={contentItem}
                  selectable={selectable}
                  companyCrew={companyCrew}
                  onViewClick={onViewGlobalItem}
                  onDeleteClick={onDeleteBillPayment}
                />
              ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default compose(GetAllContentByGlobalExpenseIdAction)(
  GlobalExpenseSplits
);
