import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  TextField,
  InputLabel,
  InputAdornment,
  FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  Close as CloseIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@material-ui/icons';

// UTILITIES
import numeral from 'numeral';
import _ from 'lodash';

// UI COMPONENTS
import AccessLimitedToInfo from '../access-limited-to-info/access-limited-to-info';
import FormikSelect from '../formik-custom-components/formik-select';
import LoadingCover from '../LoadingCover/loadingCover';

import {
  CREW_ROLES_OPTIONS,
  CREW_DEPARTMENT_OPTIONS,
  CREW_PAY_TYPES,
  CREW_PAY_TYPES_OPTIONS,
  CREW_VACA_ACCRUAL_TYPES_OPTIONS,
} from '../../config/appDefaults';

const useStyles = makeStyles(() => ({
  formSectionWrapper: {
    marginBottom: 16,
  },
  percentageField: {
    '& input': {
      textAlign: 'right',
    },
  },
  infoIcon: {
    fontSize: 16,
    marginLeft: 6,
  },
}));

const EditCrewMemberDialog = ({ user, onClose, toUpdate }) => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState(user);
  const [saving, setSaving] = useState(false);
  const [storedRates, setStoredRates] = useState({});

  useEffect(() => {
    setFormValues(prevState => {
      const totalLaborBurden =
        Number(prevState.vacaAccrualRate) +
        Number(prevState.laborBurdenPercentage);
      return {
        ...prevState,
        totalLaborBurdenPercentage: numeral(totalLaborBurden).format('0.00'),
      };
    });
  }, [formValues.vacaAccrualRate, formValues.laborBurdenPercentage]);

  useEffect(() => {
    let newPayRate = null;
    let newOvertimeRate = null;

    if (formValues.payType !== CREW_PAY_TYPES.NO_PAY) {
      // Check if there is a previously stored value
      if (!formValues.payRate && storedRates.payRate) {
        newPayRate = storedRates.payRate;
      }
      if (!formValues.overtimeRate && storedRates.overtimeRate) {
        newOvertimeRate = storedRates.overtimeRate;
      }

      // Stored rates should always be emptied after this
      if (!_.isEqual(storedRates, {})) {
        setStoredRates({});
      }
    } else {
      // Store the value then clear
      const ratesToStore = {
        ...(formValues.payRate && { payRate: formValues.payRate }),
        ...(formValues.overtimeRate && {
          overtimeRate: formValues.overtimeRate,
        }),
      };

      setStoredRates(ratesToStore);

      newPayRate = '';
      newOvertimeRate = '';
    }

    if (newPayRate !== null || newOvertimeRate !== null) {
      setFormValues(prevState => {
        return {
          ...prevState,
          payRate: newPayRate,
          overtimeRate: newOvertimeRate,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.payType]);

  const handleNumberBlur = (value, fieldId) => {
    if (value) {
      let valToUse = numeral(value);
      if (valToUse < 0) {
        valToUse *= -1;
      }
      setFormValues({
        ...formValues,
        [fieldId]: numeral(valToUse).format('0.00'),
      });
    } else {
      setFormValues({
        ...formValues,
        [fieldId]: '',
      });
    }
  };

  const handleNumberChange = (value, fieldId) => {
    let val = value;
    val = val.replace(/[^0-9.]+/g, '');
    setFormValues({
      ...formValues,
      [fieldId]: val,
    });
  };

  const handleSubmit = async () => {
    setSaving(true);

    const fieldsToInclude = [
      { key: 'userId', nullAllowed: false, emptyAllowed: false, trim: false },
      { key: 'firstName', nullAllowed: true, emptyAllowed: true, trim: true },
      { key: 'lastName', nullAllowed: true, emptyAllowed: true, trim: true },
      {
        key: 'phoneNumber',
        nullAllowed: true,
        emptyAllowed: true,
        trim: true,
      },
      { key: 'type', nullAllowed: true, emptyAllowed: false, trim: false },
      { key: 'role', nullAllowed: true, emptyAllowed: false, trim: false },
      {
        key: 'department',
        nullAllowed: true,
        emptyAllowed: false,
        trim: false,
      },
      { key: 'payType', nullAllowed: true, emptyAllowed: false, trim: false },
      {
        key: 'vacaAccrualRate',
        nullAllowed: true,
        emptyAllowed: true,
        trim: false,
      },
      {
        key: 'vacaAccrualType',
        nullAllowed: true,
        emptyAllowed: false,
        trim: false,
      },
      {
        key: 'laborBurdenPercentage',
        nullAllowed: true,
        emptyAllowed: true,
        trim: false,
      },
      {
        key: 'overtimeRate',
        nullAllowed: true,
        emptyAllowed: true,
        trim: false,
      },
      { key: 'payrollId', nullAllowed: true, emptyAllowed: true, trim: true },
      { key: 'payRate', nullAllowed: true, emptyAllowed: true, trim: false },
    ];
    const valsToUpdate = {};
    fieldsToInclude.forEach(field => {
      let val = formValues[field.key];
      // if null is allowed, include the field even if it's null or empty string
      // for any of the drop downs, allow null but not an empty string, so if it's a emptyAllowed:false field and it's empty string, set it to null and include it
      if (!field.emptyAllowed && !val) {
        val = null;
      }

      if (val && field.trim) {
        valsToUpdate[field.key] = val.trim();
      } else {
        valsToUpdate[field.key] = val;
      }
    });

    await toUpdate(valsToUpdate);
    setSaving(false);
    onClose();
  };

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle style={{ paddingTop: 8, paddingBottom: 8, paddingRight: 8 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Edit Crew Details</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Typography
          variant="h4"
          color="primary"
          style={{ marginTop: 8, marginBottom: 8 }}
        >
          Personal Info
        </Typography>
        <Grid container>
          <Grid
            container
            spacing={2}
            item
            xs={12}
            className={classes.formSectionWrapper}
          >
            <Grid item xs={4}>
              <Tooltip title="Not editable">
                <TextField
                  label="Username"
                  value={formValues.username}
                  fullWidth
                  disabled
                  InputLabelProps={{ shrink: true }}
                  onChange={null}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="First Name"
                value={formValues.firstName || ''}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={event => {
                  setFormValues({
                    ...formValues,
                    firstName: event.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Last Name"
                value={formValues.lastName || ''}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={event => {
                  setFormValues({
                    ...formValues,
                    lastName: event.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            item
            xs={12}
            className={classes.formSectionWrapper}
          >
            <Grid item xs={6}>
              <TextField
                label="Phone Number"
                value={formValues.phoneNumber || ''}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={event => {
                  setFormValues({
                    ...formValues,
                    phoneNumber: event.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <Tooltip title="Not editable">
                <TextField
                  label="Email"
                  value={formValues.email}
                  disabled
                  fullWidth
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        <Typography variant="h4" color="primary" style={{ marginTop: 16 }}>
          Job Costing Info
        </Typography>
        <AccessLimitedToInfo
          companyOwner
          companyBookkeeper
          companyAdmin
          containerStyle={{ marginBottom: 10 }}
        />

        <Grid
          container
          spacing={2}
          item
          xs={12}
          className={classes.formSectionWrapper}
        >
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel shrink>
                <Typography>Role</Typography>
              </InputLabel>
              <FormikSelect
                name="role"
                label="Role"
                value={formValues.role || ''}
                options={CREW_ROLES_OPTIONS}
                handleChange={event => {
                  setFormValues({
                    ...formValues,
                    role: event.target.value,
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel shrink>
                <Typography>Department</Typography>
              </InputLabel>
              <FormikSelect
                name="department"
                label="Department"
                value={formValues.department || ''}
                options={CREW_DEPARTMENT_OPTIONS}
                handleChange={event => {
                  setFormValues({
                    ...formValues,
                    department: event.target.value,
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Payroll ID"
              value={formValues.payrollId || ''}
              fullWidth
              InputLabelProps={{ shrink: true }}
              onChange={event => {
                setFormValues({
                  ...formValues,
                  payrollId: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel shrink>
                <Typography>Pay Type</Typography>
              </InputLabel>
              <FormikSelect
                name="payType"
                label="Pay Type"
                value={formValues.payType || ''}
                options={CREW_PAY_TYPES_OPTIONS}
                handleChange={event => {
                  setFormValues({
                    ...formValues,
                    payType: event.target.value,
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={`Pay Rate (${
                formValues.payType === CREW_PAY_TYPES.FIXED_SALARY
                  ? 'per year'
                  : 'per hour'
              })`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={formValues.payRate || ''}
              margin="none"
              disabled={
                !formValues.payType ||
                formValues.payType === CREW_PAY_TYPES.NO_PAY
              }
              onChange={e => {
                handleNumberChange(e.target.value, 'payRate');
              }}
              onBlur={e => {
                handleNumberBlur(e.target.value, 'payRate');
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Overtime Rate (per hour)"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={formValues.overtimeRate || ''}
              margin="none"
              disabled={
                !formValues.payType ||
                formValues.payType === CREW_PAY_TYPES.NO_PAY
              }
              onChange={e => {
                handleNumberChange(e.target.value, 'overtimeRate');
              }}
              onBlur={e => {
                handleNumberBlur(e.target.value, 'overtimeRate');
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          item
          xs={12}
          className={classes.formSectionWrapper}
        >
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel shrink>
                <Typography>Vacation Accrual Type</Typography>
              </InputLabel>
              <FormikSelect
                name="vacationAccrualType"
                label="Vacation Accrual Type"
                value={formValues.vacaAccrualType || ''}
                options={CREW_VACA_ACCRUAL_TYPES_OPTIONS}
                handleChange={event => {
                  setFormValues({
                    ...formValues,
                    vacaAccrualType: event.target.value,
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography>Vacation Accrual</Typography>
                  <Tooltip title="1 day of leave equates to a ~0.4% Vacation Accrual. For example, 10 leave days = 4% Vacation Accrual (0.4% x 10)">
                    <InfoOutlinedIcon className={classes.infoIcon} />
                  </Tooltip>
                </Grid>
              }
              className={classes.percentageField}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
              name="amount"
              fullWidth
              value={formValues.vacaAccrualRate || ''}
              margin="none"
              onChange={e => {
                handleNumberChange(e.target.value, 'vacaAccrualRate');
              }}
              onBlur={e => {
                handleNumberBlur(e.target.value, 'vacaAccrualRate');
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Labor Burden (excl. Vacation Accrual)"
              className={classes.percentageField}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
              name="laborBurdenPercentage"
              fullWidth
              value={formValues.laborBurdenPercentage || ''}
              margin="none"
              onChange={e => {
                handleNumberChange(e.target.value, 'laborBurdenPercentage');
              }}
              onBlur={e => {
                handleNumberBlur(e.target.value, 'laborBurdenPercentage');
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Total Labor Burden"
              className={classes.percentageField}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
              name="totalLaborBurdenPercentage"
              fullWidth
              value={formValues.totalLaborBurdenPercentage || ''}
              margin="none"
              disabled
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
      {saving && (
        <LoadingCover>
          <Typography variant="h3" align="center">
            Saving...
          </Typography>
        </LoadingCover>
      )}
    </Dialog>
  );
};
export default EditCrewMemberDialog;
