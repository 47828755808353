import React, { useState } from 'react';
import { compose } from 'react-apollo';
import { Button, Divider, Grid, Typography } from '@material-ui/core';
import ImportProjectStepOverview from './import-project-step-overview';
import ImportProjectStepChooseCustomer from './import-project-step-choose-customer';
import ImportProjectStepChooseProject from './import-project-step-choose-project';
import ImportProjectStepImport from './import-project-step-import';
import GraphQL from '../../graphql';

const IMPORT_PROJECT_STEP = {
  OVERVIEW: 0,
  CHOOSE_TOP_LEVEL_CUSTOMER: 1,
  CHOOSE_SUB_CUSTOMER: 2,
  IMPORT: 3,
};

const ImportProjectFromQbo = ({ onImportJrnFromQbo, handleClose }) => {
  const [customer, setCustomer] = useState(null);
  const [project, setProject] = useState(null);
  const [syncedProject, setSyncedProject] = useState(null);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [importComplete, setImportComplete] = useState(false);
  const [step, setStep] = useState(IMPORT_PROJECT_STEP.OVERVIEW);

  const handleCustomerSelection = selectedTopLevelCustomer => {
    setCustomer(selectedTopLevelCustomer);
    setStep(step + 1);
  };

  const handleProjectSelection = selectedTopLevelCustomer => {
    setProject(selectedTopLevelCustomer);
  };

  const importCustomerAndProject = async () => {
    const qboCustomerIdToImport = project.contentId;

    try {
      const onImportJrnFromQboResult = await onImportJrnFromQbo(
        qboCustomerIdToImport
      );

      const importedProject = onImportJrnFromQboResult?.data?.importJrnFromQbo;

      setSyncedProject(importedProject);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('importCustomerAndProject ~ err', err);
      setErrorOccurred(true);
    }

    setImportComplete(true);
  };

  const nextButtonInfo = {
    [IMPORT_PROJECT_STEP.OVERVIEW]: {
      text: 'CHOOSE CUSTOMER',
      action: () => setStep(step + 1),
      disabled: false,
    },
    [IMPORT_PROJECT_STEP.CHOOSE_SUB_CUSTOMER]: {
      text: 'IMPORT',
      action: () => {
        importCustomerAndProject();
        setStep(step + 1);
      },
      disabled: !project,
    },
    [IMPORT_PROJECT_STEP.IMPORT]: {
      text: 'DONE',
      action: () => handleClose(),
      disabled: !importComplete,
    },
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Typography variant="h5">
          Import Project from QuickBooks Online
        </Typography>
      </Grid>
      <Divider />
      <Grid item style={{ flex: 1 }}>
        {step === IMPORT_PROJECT_STEP.OVERVIEW && (
          <ImportProjectStepOverview
            nextButtonText={nextButtonInfo[IMPORT_PROJECT_STEP.OVERVIEW].text}
          />
        )}
        {step === IMPORT_PROJECT_STEP.CHOOSE_TOP_LEVEL_CUSTOMER && (
          <ImportProjectStepChooseCustomer
            handleCustomerSelection={handleCustomerSelection}
          />
        )}
        {step === IMPORT_PROJECT_STEP.CHOOSE_SUB_CUSTOMER && (
          <ImportProjectStepChooseProject
            selectedCustomer={customer}
            handleProjectSelection={handleProjectSelection}
          />
        )}
        {step === IMPORT_PROJECT_STEP.IMPORT && (
          <ImportProjectStepImport
            importComplete={importComplete}
            syncedProject={syncedProject}
            errorOccurred={errorOccurred}
            handleClose={handleClose}
          />
        )}
      </Grid>
      <Grid container item justifyContent="space-between">
        <div>
          {step !== 0 && step !== IMPORT_PROJECT_STEP.IMPORT && (
            <Button
              variant="contained"
              color="default"
              onClick={() => setStep(step - 1)}
            >
              Back
            </Button>
          )}
        </div>
        <div>
          {nextButtonInfo[step] && (
            <Button
              variant="contained"
              color="primary"
              onClick={nextButtonInfo[step].action}
              disabled={nextButtonInfo[step].disabled}
            >
              {nextButtonInfo[step].text}
            </Button>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default compose(GraphQL.ImportJrnFromQboAction)(ImportProjectFromQbo);
