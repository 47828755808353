import gql from 'graphql-tag';

export default gql`
  mutation AddCompanyShoeboxItems(
    $companyId: String!
    $listOfShoeboxItems: [SingleCompanyShoeboxItemInput!]!
  ) {
    addCompanyShoeboxItems(
      input: { companyId: $companyId, listOfShoeboxItems: $listOfShoeboxItems }
    ) {
      items {
        companyId
        contentId
        dateCreated
        status
        subject
        notes
        amount {
          value
        }
        media {
          aspectRatio
          type
          uri
          ocrData {
            status
            error
            uri
            dataJson
          }
        }
      }
    }
  }
`;
