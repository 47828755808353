import React from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Tabs,
  Tab,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  Add as AddIcon,
  Refresh as RefreshIcon,
  ArrowUpward as UpIcon,
  ArrowDownward as DownIcon,
} from '@material-ui/icons';
import _ from 'lodash';

import FilterList from '../FilterSearch/filterSearch';
import {
  LIST_TYPE,
  DATA_TYPE,
  SORT_TYPE,
} from './list-of-projects-definitions';

const useStyles = makeStyles(() => ({
  filterIcon: {
    marginBottom: -3,
    marginTop: -3,
    fontSize: 18,
  },
}));

const ListOfProjectsHeader = ({
  selectedData,
  setSelectedData,
  userInfo,
  companyInfo,
  projects,
  setIsFiltered,
  setUseThisData,
  setUpgradeDialogOption,
  setShowManualAddDialog,
  refresh,
  managingCompanyInfo,
  selectedSortOption,
  setSelectedSortOption,
  dispatch,
}) => {
  const classes = useStyles();

  // Check if company admin
  const isCompanyAdmin = !!managingCompanyInfo?.isCompanyAdmin;

  // Determine which header should be set as active
  let headerType = DATA_TYPE.PROJECTS;
  if (selectedData === LIST_TYPE.TEMPLATES) {
    headerType = DATA_TYPE.TEMPLATES;
  }

  const handleProjectSort = e => {
    const sort = e.target.value;
    setSelectedSortOption(sort);
    dispatch({
      type: 'PROJECT_SORT',
      payload: sort,
    });
  };

  const handleDataTypeChange = event => {
    const newDataTypeSelection = event.target.value;
    if (newDataTypeSelection === DATA_TYPE.PROJECTS) {
      setSelectedData(LIST_TYPE.MY_PROJECTS);
    } else {
      setSelectedData(LIST_TYPE.TEMPLATES);
    }
  };

  const handleSelectedDataChange = (event, value) => {
    setSelectedData(value);
  };

  const isProjects =
    selectedData === LIST_TYPE.MY_PROJECTS ||
    selectedData === LIST_TYPE.COMPANY_PROJECTS;

  // This block is to prevent an error on sign out after the company info has been purged, but before the redirect has happened
  let safeSelectedData = selectedData;
  if (!isCompanyAdmin && selectedData === LIST_TYPE.COMPANY_PROJECTS) {
    safeSelectedData = LIST_TYPE.MY_PROJECTS;
  }

  const renderTopLevelNav = () => {
    let loading = false;
    if (!userInfo || _.isEmpty(userInfo) || (userInfo.type && !companyInfo)) {
      loading = true;
    }

    if (isCompanyAdmin) {
      return (
        <Grid item style={{ width: '100%' }}>
          <Select
            value={headerType}
            onChange={handleDataTypeChange}
            style={{ width: '100%' }}
            readOnly={loading}
          >
            <MenuItem value={DATA_TYPE.PROJECTS}>Projects</MenuItem>
            <MenuItem value={DATA_TYPE.TEMPLATES}>Templates</MenuItem>
          </Select>
        </Grid>
      );
    }

    return null;
  };

  const renderSecondLevelNav = () => {
    if (headerType === DATA_TYPE.PROJECTS) {
      return (
        <Grid item style={{ width: '100%' }}>
          <Tabs
            value={safeSelectedData}
            onChange={handleSelectedDataChange}
            width="100%"
            indicatorColor="primary"
          >
            <Tab
              value={LIST_TYPE.MY_PROJECTS}
              label="My Active"
              style={{ flex: 1, minWidth: 60 }}
            />
            {isCompanyAdmin && (
              <Tab
                value={LIST_TYPE.COMPANY_PROJECTS}
                label="Company"
                style={{ flex: 1, minWidth: 60 }}
              />
            )}
            <Tab
              value={LIST_TYPE.ARCHIVED_PROJECTS}
              label="Archive"
              style={{ flex: 1, minWidth: 60 }}
            />
          </Tabs>
        </Grid>
      );
    }

    return null;
  };

  return (
    <>
      {renderTopLevelNav()}
      {renderSecondLevelNav()}
      <Grid container item style={{ width: '100%', gap: 8 }}>
        <Grid item style={{ flex: 2 }}>
          <FilterList
            data={projects}
            isFiltered={filterStatus => setIsFiltered(filterStatus)}
            passBack={filteredData => setUseThisData(filteredData)}
          />
        </Grid>
        <Grid item style={{ display: 'flex', flex: 1, minWidth: 220 }}>
          <Grid item style={{ flex: 1 }}>
            <InputLabel shrink id="sort-by-label">
              Sort by
            </InputLabel>
            <Select
              value={selectedSortOption}
              onChange={handleProjectSort}
              style={{
                width: '100%',
              }}
            >
              <MenuItem value={SORT_TYPE.STARTDATEDECS}>
                Start Date <DownIcon className={classes.filterIcon} />
              </MenuItem>
              <MenuItem value={SORT_TYPE.STARTDATEASC}>
                Start Date <UpIcon className={classes.filterIcon} />
              </MenuItem>
              <MenuItem value={SORT_TYPE.TITLEDECS}>
                Title <DownIcon className={classes.filterIcon} />
              </MenuItem>
              <MenuItem value={SORT_TYPE.TITLEASC}>
                Title <UpIcon className={classes.filterIcon} />
              </MenuItem>
            </Select>
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              flex: 0,
              flexDirection: 'row',
              marginLeft: 8,
            }}
          >
            <Grid item>
              <Tooltip title="Refresh the list">
                <IconButton onClick={() => refresh()}>
                  <RefreshIcon
                    style={{ fontSize: 'inherit', color: 'inherit' }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            {isProjects && (
              <Grid item>
                <Tooltip title="Add a project">
                  <span>
                    <IconButton
                      disabled={!userInfo}
                      onClick={() => {
                        if (!isCompanyAdmin) {
                          setUpgradeDialogOption({ open: true });
                        } else {
                          setShowManualAddDialog({
                            open: true,
                            mode: 'addTopLevel',
                            existingInfo: { type: 'project' },
                          });
                        }
                      }}
                    >
                      <AddIcon
                        style={{ fontSize: 'inherit', color: 'inherit' }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo,
});

export default connect(mapStateToProps)(ListOfProjectsHeader);
