import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import {
  Avatar,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Description as BillIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';
import { monetaryRender } from '../../helpers';

const useStyles = makeStyles(theme => {
  return {
    container: {
      margin: '8px 0px',
      border: '1px solid #ececec',
      paddingBottom: 0,
    },
    listItem: {
      paddingRight: 0,
    },
    field: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    accordion: {
      marginTop: 0,
      '& .MuiAccordionSummary-root': {
        paddingRight: 0,
      },
    },
    accordionSummaryIcon: {
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
    },
    editor: {
      border: `1px solid #ccc`,
      width: '100%',
    },
    billableLabel: {
      paddingLeft: theme.spacing(2),
    },
  };
});

const SplitFinancialItemListBill = ({
  entryInfo,
  entryIndex,
  showVendor = false,
  autoFocusOnAmount,
  invalidAmount,
  onResetInvalidAmount = () => {},
  onDeleteButtonClick,
  onFieldChange,
  allowDeleteExisting,
  userId,
  managingCompanyInfo,
  disabled,
}) => {
  const classes = useStyles();

  const { bill: billItem, amount, isNew } = entryInfo;

  const isAllowedEditBillItem = useMemo(() => {
    if (managingCompanyInfo.isCompanyAdmin) {
      return true;
    }

    if (billItem?.creatorId === userId) {
      return true;
    }

    return false;
  }, [managingCompanyInfo, billItem, userId]);

  const contentUrl = _.replace(billItem.contentUrl, '.pdf', '.jpg');

  const disabledDelete = !isNew && !allowDeleteExisting;

  useEffect(() => {
    onResetInvalidAmount(entryIndex, entryInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const handleNumberBlur = e => {
    let { value } = e.target;
    if (value) {
      let valToUse = numeral(value);
      if (valToUse < 0) {
        valToUse *= -1;
      }
      value = valToUse.format('0.00');
    } else {
      value = '';
    }
    onFieldChange(value, 'amount', entryIndex);
  };

  const handleNumberChange = e => {
    let { value } = e.target;
    value = value.replace(/[^0-9.]+/g, '');
    onFieldChange(value, 'amount', entryIndex);
  };

  const handleDeleteButtonClick = () => {
    onDeleteButtonClick(entryIndex);
  };

  const balance = billItem?.balance?.value || billItem?.amount?.value || 0;

  const amountExceedsBalance = +amount > balance;

  const amountToUse = billItem?.amount?.value || 0;
  const balanceToUse = billItem?.balance ? billItem.balance.value : amountToUse;

  return (
    <Tooltip
      title={
        isAllowedEditBillItem
          ? ''
          : 'You do not have permission to edit or delete this item'
      }
    >
      <Grid container direction="column" className={classes.container}>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ minHeight: 74 }}
          >
            <Grid item xs={7}>
              <ListItem className={classes.listItem}>
                <ListItemAvatar>
                  {isAllowedEditBillItem && contentUrl ? (
                    <Avatar alt="Bill Cover Image" src={contentUrl} />
                  ) : (
                    <Avatar>
                      <BillIcon />
                    </Avatar>
                  )}
                </ListItemAvatar>
                {isAllowedEditBillItem && (
                  <Grid container item direction="column">
                    <Grid item>
                      <Typography variant="h6">
                        Bill No.: {billItem.documentNumber}
                      </Typography>
                    </Grid>
                    {showVendor && billItem.vendor?.name && (
                      <Grid item>
                        <Typography variant="caption">
                          Vendor: {billItem.vendor.name}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item>
                      <Typography
                        variant="caption"
                        color={amountExceedsBalance ? 'error' : 'initial'}
                      >
                        Remaining balance:{' '}
                        {monetaryRender({
                          value: balanceToUse,
                          withDecimals: true,
                        })}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">
                        Due on:{' '}
                        {moment(billItem.endDate).format('MMM DD, YYYY')}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {!isAllowedEditBillItem && (
                  <ListItemText primary="Other Bill" />
                )}
              </ListItem>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Paid (incl. tax)"
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={amount || ''}
                margin="none"
                onChange={handleNumberChange}
                onBlur={handleNumberBlur}
                autoFocus={autoFocusOnAmount}
                error={invalidAmount || amountExceedsBalance}
                disabled={!isAllowedEditBillItem || disabled}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                size="small"
                disabled={disabledDelete || !isAllowedEditBillItem || disabled}
                onClick={handleDeleteButtonClick}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Tooltip>
  );
};

const mapStateToProps = state => ({
  userId: state.userInfo && state.userInfo.userId,
  managingCompanyInfo: state.appState.managingCompanyInfo,
});

export default connect(mapStateToProps)(SplitFinancialItemListBill);
