import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Company}
  mutation TransferCompanyAdmin(
    $companyId: String!
    $fromUserId: String!
    $toUserId: String
    $role: String
  ) {
    transferCompanyAdmin(
      input: {
        companyId: $companyId
        fromUserId: $fromUserId
        toUserId: $toUserId
        role: $role
      }
    ) {
      ...CompanyFields
    }
  }
`;
