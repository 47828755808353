import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import styles from './job-cost.style';

const useStyles = makeStyles(styles);

const JobCostOverlay = ({ graph: { name, label }, showSettingsModal }) => {
  const classes = useStyles();

  const handleShowSettings = () => {
    showSettingsModal();
  };

  return (
    <Grid container key={name} className={classes.overlay}>
      <Grid
        container
        item
        justifyContent="center"
        className={classes.overlayInner}
      >
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={classes.overlayContent}
        >
          <Grid item>
            <Typography
              variant="h6"
              align="center"
              style={{ marginBottom: 16 }}
            >
              <b>{label}</b>
            </Typography>
          </Grid>
          <Grid
            container
            item
            direction="column"
            justifyContent="center"
            spacing={2}
          >
            <Grid item>
              <Typography variant="body1">
                A QuickBooks Online Cost of Goods Sold (COGS) account must be
                selected to view this chart. It can be hidden in the settings if
                desired.
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Button
                color="default"
                variant="contained"
                onClick={handleShowSettings}
              >
                Select Account
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JobCostOverlay;
