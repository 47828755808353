import React, { useCallback } from 'react';
import { min, max } from 'd3-array';
import {
  AnimatedAxis,
  AnimatedGrid,
  AnimatedLineSeries,
  AnimatedGlyphSeries,
  AnimatedBarSeries,
  AnimatedBarStack,
  AnimatedAreaSeries,
  AnimatedAnnotation,
  AnnotationLabel,
  XYChart,
  Tooltip,
  DataProvider,
  buildChartTheme,
} from '@visx/xychart';
import { scaleOrdinal } from '@visx/scale';
import { LegendOrdinal } from '@visx/legend';
import { curveMonotoneX } from '@visx/curve';
import { GlyphCircle } from '@visx/glyph';
import { withParentSize } from '@visx/responsive';
import { LinearGradient } from '@visx/gradient';
import ResizeObserver from 'resize-observer-polyfill';
import _ from 'lodash';
import { Typography } from '@material-ui/core';
import { addDays } from 'date-fns';

import palette from '../../theme/palette';
import prettyString from './pretty-string';
import {
  GRAPH_TYPE,
  REPORT_TIME_BASIS,
  SERIES_DATA_TYPE,
  TIME_UNIT,
} from '../../config/appDefaults';

const accessors = {
  xAccessor: d => (d ? d.x : null),
  yAccessor: d => (d ? d.y : null),
};

const defaultDataColor = palette.brandColorPrimary;
const positiveTargetColor = palette.brandColorGreen75;
const negativeTargetColor = palette.brandColorOrange75;

const levelTheme = buildChartTheme({
  backgroundColor: palette.brandColorLightGrey,
  colors: [defaultDataColor],
  gridColor: palette.brandColorDarkGrey,
  gridColorDark: palette.brandColorDarkGrey50,
  tickLength: 8, // 8 is the default
});

const VisXChart = ({
  title,
  dataSeries,
  target = null,
  targetSentiment = 'positive',
  targetText = null,
  targetColor = null,
  limit = null,
  parentHeight,
  parentWidth,
  noPadding = false,
  showLegend = false,
  infoShowing = false,
  type: chartType,
  showDayOfWeek = false,
  showDateRange = false,
  onBarStackClick = () => {},
  tooltipNote = '',
  renderTooltipNote,
  fiscalYearStartMonth,
  defaultYScaleDomain,
  showGlyphs = false,
  onLineClick,
  onLineHover,
  xAxisMode = 'band', // band or time
  startX = null, // only used for xAxisMode time
  endX = null, // only used for xAxisMode time
}) => {
  let displayTargetColor;
  if (targetColor) {
    displayTargetColor = targetColor;
  } else {
    displayTargetColor =
      targetSentiment === 'positive'
        ? positiveTargetColor
        : negativeTargetColor;
  }

  const getThresholdData = threshold => {
    // If no series were provided, don't do anything
    if (!dataSeries || dataSeries.length === 0) {
      return [];
    }

    // Determine minX and maxX from series
    let minX = null;
    let maxX = null;

    // Get all x's and sort
    const everyX = [];
    dataSeries.forEach(series =>
      everyX.push(...(series.data || []).map(accessors.xAccessor))
    );
    everyX.sort((a, b) => a.getTime() - b.getTime());

    // eslint-disable-next-line prefer-destructuring
    minX = everyX[0];
    maxX = everyX[everyX.length - 1];

    return [
      { x: minX, y: threshold },
      { x: maxX, y: threshold },
    ];
  };

  const getAllDataPoints = () => {
    const allDataPoints = [];

    if (dataSeries) {
      dataSeries.forEach(series => allDataPoints.push(...(series.data || [])));
    }

    if (target !== null) {
      allDataPoints.push(...getThresholdData(target));
    }

    return allDataPoints;
  };

  const getLowestY = () => {
    const allDataPoints = getAllDataPoints();
    return min(allDataPoints, accessors.yAccessor);
  };

  const getHighestY = () => {
    const allDataPoints = getAllDataPoints();
    return max(allDataPoints, accessors.yAccessor);
  };

  const getDomain = () => {
    const allDataPoints = getAllDataPoints();
    const allXs = allDataPoints.map(accessors.xAccessor);

    // Remove duplicates
    const uniqueXs = _.uniq(allXs);

    // Get ordered, unique x values
    const sortedXs = uniqueXs.sort((a, b) => a.getTime() - b.getTime());

    if (xAxisMode === 'time') {
      const minX = startX || sortedXs[0];
      const maxX = endX || sortedXs[sortedXs.length - 1];
      return [minX, maxX];
    }

    return sortedXs;
  };

  const lowestY = getLowestY();
  const highestY = getHighestY();

  let minY;
  let maxY;
  let numberOfYTicks;
  if (lowestY !== undefined && highestY !== undefined) {
    if (
      defaultYScaleDomain &&
      lowestY >= defaultYScaleDomain.min &&
      highestY <= defaultYScaleDomain.max
    ) {
      minY = defaultYScaleDomain.min;
      maxY = defaultYScaleDomain.max;
      numberOfYTicks = defaultYScaleDomain.numberOfTicks;
    } else {
      const yRange = Math.abs(highestY - (lowestY > 0 ? 0 : lowestY));
      minY = lowestY >= 0 ? 0 : lowestY - yRange * 0.1;
      maxY = highestY <= 0 ? 0 : highestY + yRange * 0.2;
    }
  } else {
    minY = 0;
    maxY = 100;
  }

  if (limit && limit.y) {
    if (
      (limit.y.max !== null || limit.y.max !== undefined) &&
      maxY > limit.y.max
    ) {
      maxY = limit.y.max;
    }
    if (
      (limit.y.min !== null || limit.y.min !== undefined) &&
      minY < limit.y.min
    ) {
      minY = limit.y.min;
    }

    // Set minimum range if not set
    if (maxY === minY) {
      maxY = minY + 0.1 * (limit.y.max - limit.y.min);
    }
  }

  let targetPosition = targetSentiment === 'positive' ? 'above' : 'below';
  // If target is in the bottom 15% of y-range show it above the line
  if (
    target !== null &&
    targetPosition === 'below' &&
    (target - minY) / (maxY - minY) < 0.25
  ) {
    targetPosition = 'above';
  }

  const renderGlyph = useCallback(
    ({ x, y, color, onPointerMove, onPointerOut, onPointerUp }) => {
      return (
        <GlyphCircle
          left={x}
          top={y}
          fill="white"
          stroke={color || defaultDataColor}
          strokeWidth={2}
          onPointerMove={onPointerMove}
          onPointerOut={onPointerOut}
          onPointerUp={onPointerUp}
        />
      );
    },
    []
  );

  const renderSeries = () =>
    dataSeries.map(({ type, data, name, color, fromColor, toColor }) => {
      if (type === GRAPH_TYPE.LINE) {
        return (
          <React.Fragment key={name}>
            <AnimatedLineSeries
              dataKey={name}
              data={data}
              curve={curveMonotoneX}
              {...accessors}
              {...(color ? { stroke: color } : {})}
              onPointerUp={onLineClick}
              onPointerMove={onLineHover}
            />
            {showGlyphs && (
              <AnimatedGlyphSeries
                renderLine
                dataKey={name}
                data={data}
                {...accessors}
                renderGlyph={renderGlyph}
              />
            )}
          </React.Fragment>
        );
      }

      if (type === GRAPH_TYPE.BAR) {
        let colorAccessor = null;
        if (color) {
          if (typeof color === 'function') {
            colorAccessor = color;
          } else {
            colorAccessor = () => color;
          }
        }

        return (
          <AnimatedBarSeries
            key={name}
            dataKey={name}
            data={data}
            {...accessors}
            {...(colorAccessor ? { colorAccessor } : {})}
          />
        );
      }

      if (type === GRAPH_TYPE.AREA) {
        return (
          <svg key={name}>
            <LinearGradient
              id="color-gradient"
              from={color || fromColor || palette.brandColorPrimary}
              to={color || toColor || palette.brandColorPrimary75}
            />
            <AnimatedAreaSeries
              dataKey={name}
              data={data}
              curve={curveMonotoneX}
              {...accessors}
              renderLine
              {...(color || fromColor
                ? {
                    lineProps: { stroke: color || fromColor },
                    fill: "url('#color-gradient')",
                  }
                : { fill: "url('#color-gradient')" })}
            />
          </svg>
        );
      }
      return null;
    });

  // Use first series to determine the dataTypes for axes
  const dataType = dataSeries && dataSeries[0] && dataSeries[0].dataType;
  if (dataType.x === SERIES_DATA_TYPE.DATE) {
    switch (dataSeries[0].dataBasis) {
      case REPORT_TIME_BASIS.DAYS:
        dataType.x = TIME_UNIT.DAY;
        break;
      case REPORT_TIME_BASIS.WEEKS:
        dataType.x = TIME_UNIT.WEEK;
        break;
      case REPORT_TIME_BASIS.MONTHS:
        dataType.x = TIME_UNIT.MONTH;
        break;
      case REPORT_TIME_BASIS.QUARTERS:
        dataType.x = TIME_UNIT.QUARTER;
        break;
      case REPORT_TIME_BASIS.YEARS:
        dataType.x = TIME_UNIT.YEAR;
        break;
      default:
        dataType.x = TIME_UNIT.MONTH;
    }
  }

  const colorMap = {};
  dataSeries.forEach(series => {
    if (series.color || series.fromColor) {
      if (typeof series.color === 'function') {
        colorMap[series.name] = series.color();
      } else {
        colorMap[series.name] = series.color || series.fromColor;
      }
    }
  });

  const dateRangeMap = {};
  const shouldShowDateRange =
    showDateRange ||
    (dataSeries &&
      dataSeries[0] &&
      (dataSeries[0].dataBasis === REPORT_TIME_BASIS.WEEKS ||
        dataSeries[0].dataBasis === REPORT_TIME_BASIS.QUARTERS));
  if (shouldShowDateRange) {
    for (let i = 0; i < dataSeries[0].data.length; i += 1) {
      const thisDate = accessors.xAccessor(dataSeries[0].data[i]);
      const nextDate = accessors.xAccessor(dataSeries[0].data[i + 1]);
      dateRangeMap[thisDate] = nextDate;
      if (i === dataSeries[0].data.length - 1 && dataSeries[0].metadata) {
        dateRangeMap[thisDate] = addDays(
          dataSeries[0].metadata.reportPeriodEndDate,
          1
        );
      }
    }
  }

  const formatAxisDate = targetDate => {
    let etc = null;
    if (shouldShowDateRange) {
      etc = dateRangeMap[targetDate];
    }
    return prettyString(
      targetDate,
      accessors.xAccessor(dataType),
      true,
      etc,
      false,
      false,
      fiscalYearStartMonth,
      xAxisMode !== 'time'
    );
  };

  const getDateRangeString = targetDate => {
    let etc = null;

    if (shouldShowDateRange) {
      etc = dateRangeMap[targetDate];
    }

    return prettyString(
      targetDate,
      accessors.xAccessor(dataType),
      false,
      etc,
      showDayOfWeek,
      showDateRange,
      fiscalYearStartMonth,
      xAxisMode !== 'time'
    );
  };

  const formatBarstackTooltip = ({ tooltipData, colorScale }) => {
    // If tooltip is above max Y, do not show it
    if (accessors.yAccessor(tooltipData.nearestDatum.datum) > maxY) {
      return null;
    }

    const targetDate = accessors.xAccessor(tooltipData.nearestDatum.datum);
    const stringForX = getDateRangeString(targetDate);

    let total = 0;
    const rows = [];
    const yValueType = accessors.yAccessor(dataType);

    _.keys(tooltipData.datumByKey)
      .sort()
      .forEach(key => {
        const data = tooltipData.datumByKey[key];
        const yValue = accessors.yAccessor(data.datum);
        total += yValue;
        rows.push({
          name: key,
          hoursString: prettyString(yValue, yValueType),
          color: colorMap[data.key] || colorScale(data.key),
        });
      });

    rows.push({
      name: 'Total',
      hoursString: prettyString(total, yValueType),
      color: colorMap.Total,
    });

    return (
      <div style={{ padding: 8 }}>
        <div>
          <b>{stringForX}</b>
        </div>
        {rows.map(row => {
          return (
            <div key={row.name} style={{ marginTop: 8 }}>
              <span
                style={{
                  color: row.color,
                }}
              >
                {`${row.name}: `}
              </span>
              {row.hoursString}
            </div>
          );
        })}
        {!!renderTooltipNote && renderTooltipNote({ tooltipData })}
        {!renderTooltipNote && !!tooltipNote && (
          <div style={{ marginTop: 12 }}>
            <i>{tooltipNote}</i>
          </div>
        )}
      </div>
    );
  };

  const formatTooltip = ({ tooltipData, colorScale }) => {
    // If tooltip is above max Y, do not show it
    if (accessors.yAccessor(tooltipData.nearestDatum.datum) > maxY) {
      return null;
    }

    // If using time, only show tooltip if in proximity
    if (xAxisMode === 'time') {
      const distanceToNearestDatum = tooltipData.nearestDatum?.distance || null;

      if (distanceToNearestDatum && distanceToNearestDatum > 100) {
        return null;
      }

      const targetDate = accessors.xAccessor(tooltipData.nearestDatum.datum);
      if (startX && endX) {
        if (targetDate < startX || targetDate > endX) {
          return null;
        }
      }
    }

    const targetDate = accessors.xAccessor(tooltipData.nearestDatum.datum);

    const stringForX = getDateRangeString(targetDate);

    return (
      <div style={{ padding: 8 }}>
        <div
          style={{
            color:
              colorMap[tooltipData.nearestDatum.key] ||
              colorScale(tooltipData.nearestDatum.key),
          }}
        >
          <b>{tooltipData.nearestDatum.key}</b>
        </div>
        <div style={{ marginTop: 8 }}>
          {stringForX}
          {', '}
          {prettyString(
            accessors.yAccessor(tooltipData.nearestDatum.datum),
            accessors.yAccessor(dataType)
          )}
        </div>
        {!!renderTooltipNote && renderTooltipNote({ tooltipData })}
        {!renderTooltipNote && !!tooltipNote && (
          <div style={{ marginTop: 12 }}>
            <i>{tooltipNote}</i>
          </div>
        )}
      </div>
    );
  };

  const bandOpts = {
    paddingInner: chartType === 'barstack' ? 0.2 : 1,
    paddingOuter: !noPadding ? 0.55 : 0.02,
  };

  return (
    <div style={{ paddingTop: 50 }}>
      <DataProvider
        key={title}
        xScale={{
          type: xAxisMode,
          domain: getDomain(),
          ...(xAxisMode === 'band' ? bandOpts : {}),
        }}
        yScale={{ type: 'linear', domain: [minY, maxY] }}
        theme={levelTheme}
      >
        <XYChart
          width={parentWidth - 45}
          height={parentHeight - 45}
          margin={{ top: 5, right: 50, left: 56, bottom: 50 }}
        >
          <AnimatedAxis orientation="bottom" tickFormat={formatAxisDate} />
          <AnimatedAxis
            orientation="left"
            numTicks={numberOfYTicks}
            tickFormat={v =>
              prettyString(v, accessors.yAccessor(dataType), true)
            }
          />
          <AnimatedGrid rows columns={false} tickValues={[0]} />
          {!chartType && renderSeries()}
          {chartType === 'barstack' && (
            <AnimatedBarStack
              onPointerUp={event => {
                const targetDate = accessors.xAccessor(event.datum);
                const dateRangeString = getDateRangeString(targetDate);
                onBarStackClick({ ...event, dateRangeString });
              }}
            >
              {renderSeries()}
            </AnimatedBarStack>
          )}
          {target && (
            <>
              <AnimatedLineSeries
                dataKey="Goal"
                data={getThresholdData(target)}
                strokeDasharray={[4, 4]}
                stroke={displayTargetColor}
                {...accessors}
              />
              <AnimatedAnnotation
                dataKey="Goal"
                datum={getThresholdData(target)[1]}
                dy={targetPosition === 'above' ? -10 : 10}
                dx={0}
              >
                <AnnotationLabel
                  title={
                    targetText ||
                    `GOAL ${
                      targetSentiment === 'positive' ? '≥' : '≤'
                    } ${prettyString(target, dataType.y, true)}`
                  }
                  showAnchorLine={false}
                  backgroundFill="none"
                  fontColor={displayTargetColor}
                  resizeObserverPolyfill={ResizeObserver}
                  titleProps={{ opacity: 1 }}
                />
              </AnimatedAnnotation>
            </>
          )}
          <Tooltip
            detectBounds
            snapTooltipToDatumX
            snapTooltipToDatumY
            showHorizontalCrosshair
            showVerticalCrosshair
            showSeriesGlyphs
            renderTooltip={
              chartType === 'barstack' ? formatBarstackTooltip : formatTooltip
            }
            resizeObserverPolyfill={ResizeObserver} // For extended browser support
            offsetTop={-40}
          />
        </XYChart>
        {showLegend && (
          <div
            style={{
              position: 'absolute',
              top: infoShowing ? 70 : 45,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography component="span" variant="body1">
              <LegendOrdinal
                scale={scaleOrdinal({
                  domain: dataSeries.map(series => series.name),
                  range: dataSeries.map(series => {
                    if (typeof series.color === 'function') {
                      return series.color();
                    }
                    return series.color || series.fromColor || defaultDataColor;
                  }),
                })}
                direction="row"
                labelMargin="0 15px 0 0"
              />
            </Typography>
          </div>
        )}
      </DataProvider>
    </div>
  );
};

export default withParentSize(VisXChart);
