import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';

import Paper from '@material-ui/core/Paper';
// Helpers
import { monetaryRender, fixedValueRender } from '../../../helpers/index';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    padding: 0,
  },
}));

const LeadStatsDialog = ({ data, onClose }) => {
  const classes = useStyles();

  const createData = (name, payments, receiptsBills, labor) => {
    return { name, payments, receiptsBills, labor };
  };

  const rows = [
    createData(
      'Count',
      data.count.paymentsBudgets,
      data.count.receiptsAndBillsBudgets,
      data.count.laborBudgets
    ),
    createData(
      'Total Budget',
      monetaryRender({ value: data.budget.paymentsTotal }),
      monetaryRender({ value: data.budget.receiptsAndBillsTotal }),
      fixedValueRender({ value: data.budget.laborTotal })
    ),
    createData(
      'Average Budget',
      monetaryRender({ value: data.budget.paymentsTotalAvg }),
      monetaryRender({ value: data.budget.receiptsAndBillsTotalAvg }),
      fixedValueRender({ value: data.budget.laborTotalAvg })
    ),
  ];

  const closeModal = () => {
    onClose();
  };

  return (
    <Dialog
      open
      onClose={closeModal}
      aria-labelledby="data stats"
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <Paper className={classes.root}>
          <Table aria-label="project stats table">
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell align="center">Quoted</TableCell>
                <TableCell align="center">Non-Labor Expenses</TableCell>
                <TableCell align="center">Labor (hrs)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">{row.payments}</TableCell>
                  <TableCell align="center">{row.receiptsBills}</TableCell>
                  <TableCell align="center">{row.labor}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeadStatsDialog;
