import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import {
  Paper,
  Grid,
  Typography,
  Button,
  Tooltip,
  IconButton,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import ContentLoader from 'react-content-loader';
import {
  Add as AddIcon,
  Edit as EditIcon,
  SpeakerNotes as SpeakerNotesIcon,
  OpenInNew as OpenInNewIcon,
} from '@material-ui/icons';
import AddCustomerDialog from '../../../../components/add-customer-dialog';
import QboSyncActionButton from '../../../../components/qbo-sync-action-button';
import { googlifyAddress } from '../../../../helpers/index';
import { QBO_SYNCABLE_TYPE } from '../../../../config/appDefaults';
import GraphQL from '../../../../graphql';
import palette from '../../../../theme/palette';

const useStyles = makeStyles(theme => ({
  descriptionWrapper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    display: 'block',
    background: palette.background.alt,
    width: '100%',
  },
  customerInfoItemWrapper: {
    marginTop: theme.spacing(0.5),
  },
}));

const CustomerInfoHeader = ({
  passedCustomerInfo,
  customerInfoError,
  managingCompanyInfo,
  onAddLead,
}) => {
  const classes = useStyles();
  const [showNotes, setShowNotes] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showAddCustomerDialog, setShowAddCustomerDialog] = useState({
    open: false,
  });

  useEffect(() => {
    if (!passedCustomerInfo && customerInfoError && !showError) {
      setShowError(true);
    } else if (passedCustomerInfo && showError) {
      setShowError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedCustomerInfo, customerInfoError]);

  const handleEditCustomer = () => {
    setShowAddCustomerDialog({
      open: true,
    });
  };

  const handleAddLead = () => {
    onAddLead({
      customerId: passedCustomerInfo.customerId,
      address: passedCustomerInfo.address,
      latitude: passedCustomerInfo.latitude,
      longitude: passedCustomerInfo.longitude,
    });
  };

  const hasFirstOrLast =
    passedCustomerInfo &&
    (passedCustomerInfo.firstName || passedCustomerInfo.lastName);

  const renderCustomerLoading = () => {
    const contentPlaceholder = (width, height, marginBottom) => (
      <Grid item style={{ width, height, marginBottom }}>
        <ContentLoader
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
          viewBox={`0 0 ${width} ${height}`}
          height={height}
        >
          <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
        </ContentLoader>
      </Grid>
    );

    return (
      <Grid item container direction="column">
        {contentPlaceholder(200, 32, 0)}
        {contentPlaceholder(100, 10, 8)}
        {contentPlaceholder(250, 18, 8)}
        {contentPlaceholder(225, 18, 8)}
        {contentPlaceholder(125, 18, 0)}
      </Grid>
    );
  };

  const renderCustomerInfo = () => {
    if (showError) {
      // Customer was not found
      return (
        <Typography variant="h4">
          This customer does not exist or you do not have access.
        </Typography>
      );
    }

    if (!passedCustomerInfo) {
      return renderCustomerLoading();
    }

    return (
      <>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            {hasFirstOrLast && (
              <Typography variant="h3" color="primary">
                {passedCustomerInfo.firstName}
                {passedCustomerInfo.firstName &&
                  passedCustomerInfo.lastName &&
                  ' '}
                {passedCustomerInfo.lastName}
              </Typography>
            )}

            {!!passedCustomerInfo.companyName && (
              <Grid item style={{ lineHeight: 0 }}>
                <Typography
                  variant={hasFirstOrLast ? 'caption' : 'h3'}
                  color={hasFirstOrLast ? undefined : 'primary'}
                >
                  {passedCustomerInfo.companyName}
                </Typography>
              </Grid>
            )}

            {!!passedCustomerInfo.address && (
              <Grid item className={classes.customerInfoItemWrapper}>
                <Typography
                  variant="body1"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <a
                    href={googlifyAddress(passedCustomerInfo.address, 'search')}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="basicStyledLink"
                  >
                    {passedCustomerInfo.address}
                  </a>
                  <OpenInNewIcon
                    style={{ marginLeft: 4, fontSize: 16, color: '#ccc' }}
                  />
                </Typography>
              </Grid>
            )}

            {!!passedCustomerInfo.email && (
              <Grid item className={classes.customerInfoItemWrapper}>
                <Typography variant="body1">
                  <a
                    href={`mailto:${passedCustomerInfo.email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="basicStyledLink"
                  >
                    {passedCustomerInfo.email}
                  </a>
                </Typography>
              </Grid>
            )}

            {!!passedCustomerInfo.phoneNumber && (
              <Grid item className={classes.customerInfoItemWrapper}>
                <Typography variant="body1">
                  {passedCustomerInfo.phoneNumber}
                </Typography>
              </Grid>
            )}

            {!!passedCustomerInfo.notes && (
              <Grid item style={{ alignSelf: 'flex-end' }}>
                <Button
                  size="small"
                  onClick={() => {
                    setShowNotes(!showNotes);
                  }}
                  startIcon={<SpeakerNotesIcon style={{ color: '#aaa' }} />}
                >
                  {!showNotes ? 'view' : 'hide'} notes
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid item>
            {managingCompanyInfo &&
              managingCompanyInfo.isCustomerSyncEnabled && (
                <QboSyncActionButton
                  recordInfo={passedCustomerInfo}
                  recordType={QBO_SYNCABLE_TYPE.CUSTOMER}
                />
              )}
            <Tooltip title="Add Lead">
              <IconButton onClick={handleAddLead}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Customer">
              <IconButton onClick={handleEditCustomer}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {!!passedCustomerInfo.notes && showNotes && (
          <Grid
            container
            direction="row"
            className={classes.descriptionWrapper}
          >
            <div className="levelParsedHtml">
              {ReactHtmlParser(passedCustomerInfo.notes)}
            </div>
          </Grid>
        )}
      </>
    );
  };

  return (
    <Paper style={{ padding: 16 }}>
      <Grid container direction="column" wrap="nowrap">
        {renderCustomerInfo()}
      </Grid>
      {showAddCustomerDialog.open && (
        <AddCustomerDialog
          initialValues={passedCustomerInfo}
          toClose={() => {
            setShowAddCustomerDialog({
              open: false,
            });
          }}
        />
      )}
    </Paper>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(
  compose(GraphQL.GetCustomerByIdAction)(CustomerInfoHeader)
);
