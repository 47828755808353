import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import _ from 'lodash';
import numeral from 'numeral';
import {
  ACCOUNTING_METHOD,
  REPORT_PERIOD,
  SCOREBOARD_NAME,
} from '../../../config/appDefaults';
import {
  Dashboard,
  DashboardSettings,
  DashboardSettingsReportData,
  DashboardSettingsTargets,
} from '../../dashboard';
import Graph, { processXYTypes } from '../../graph';
import GetJobCostingData from '../../../graphql/queries/get-job-cost-data';
import {
  GetCompanyTargetsAction,
  ListScoreboardSettingsAction,
} from '../../../graphql/graphql';
import JobCostOverlay from './job-cost-overlay';
import JobCostSettings from './job-cost-settings';
import jobCostGraphs from './job-cost-graphs';
import JobCostControlBar from './job-cost-control-bar';

const title = 'Direct Costs';

const JobCostScoreboard = ({
  companyTargets,
  scoreboardSettings,
  managingCompanyInfo,
}) => {
  const [showSettings, setShowSettings] = useState(false);

  const [accountingMethod, setAccountingMethod] = useState(null);

  useEffect(() => {
    if (scoreboardSettings) {
      // Set defaults
      let updatedAccountingMethod = ACCOUNTING_METHOD.CASH.key;

      // Find settings for scoreboard
      const profitabilityScoreboardSettings = _.find(
        scoreboardSettings,
        ({ scoreboardName }) => scoreboardName === SCOREBOARD_NAME.DIRECT_COSTS
      );

      // Override accounting method default with user settings
      if (
        profitabilityScoreboardSettings &&
        profitabilityScoreboardSettings.accountingMethod
      ) {
        const companyAccountingMethodSetting = _.find(
          ACCOUNTING_METHOD,
          ({ value }) =>
            value === profitabilityScoreboardSettings.accountingMethod
        );
        if (companyAccountingMethodSetting) {
          updatedAccountingMethod = companyAccountingMethodSetting.key;
        }
      }

      setAccountingMethod(updatedAccountingMethod);
    }
  }, [scoreboardSettings]);

  const [reportPeriod, setReportPeriod] = useState(
    REPORT_PERIOD.LAST_12_MONTHS.key
  );

  const targetData = _.map(
    jobCostGraphs,
    ({ name: reportName, label, targetType }) => ({
      name: reportName,
      label,
      dataType: targetType,
    })
  );

  const targetsByKey = {};
  if (companyTargets) {
    _.each(jobCostGraphs, ({ name: reportName }) => {
      targetsByKey[reportName] = _.find(
        companyTargets,
        ({ name }) => name === reportName
      );
    });
  }

  const JobCostDataQuery = useQuery(GetJobCostingData, {
    skip:
      !(managingCompanyInfo && managingCompanyInfo.managingCompanyId) ||
      accountingMethod === null,
    variables: {
      companyId: managingCompanyInfo && managingCompanyInfo.managingCompanyId,
      reportPeriod: _.find(REPORT_PERIOD, ({ key }) => key === reportPeriod)
        .value,
      accountingMethod:
        accountingMethod !== null
          ? _.find(ACCOUNTING_METHOD, ({ key }) => key === accountingMethod)
              .value
          : null,
    },
  });

  const jobCostData =
    JobCostDataQuery.data &&
    JobCostDataQuery.data.getJobCostingData &&
    JobCostDataQuery.data.getJobCostingData.items;

  const reportData = useMemo(() => {
    const compiledReportData = {};

    if (jobCostData) {
      _.forEach(jobCostGraphs, ({ name: sourceName }) => {
        const data = _.find(
          jobCostData,
          ({ name }) => name.split(':')[1] === sourceName
        );

        if (data) {
          data.values = processXYTypes(
            data.values,
            data.labels.x,
            data.labels.y
          );

          data.isUnset = false;
          if (data.metadata) {
            const accountKvPair = _.find(
              data.metadata,
              metadatum => metadatum.key === 'account'
            );

            const sourceAccount = accountKvPair && accountKvPair.value;
            data.sourceAccount = sourceAccount;
            data.isUnset = !sourceAccount;

            const isHiddenKvPair = _.find(
              data.metadata,
              metadatum => metadatum.key === 'isHidden'
            );
            data.isHidden = false;
            if (
              isHiddenKvPair &&
              isHiddenKvPair.value &&
              isHiddenKvPair.value === 'true'
            ) {
              data.isHidden = true;
            }

            data.total = null;
            const totalKvPair = _.find(
              data.metadata,
              metadatum => metadatum.key === 'total'
            );
            if (totalKvPair && totalKvPair.value) {
              const total = parseFloat(totalKvPair.value);
              if (!Number.isNaN(total)) {
                data.total = total;
              }
            }

            data.percentageForPeriod = null;
            const periodPercentageKvPair = _.find(
              data.metadata,
              metadatum => metadatum.key === 'percentageForPeriod'
            );
            if (periodPercentageKvPair && periodPercentageKvPair.value) {
              data.percentageForPeriod = parseFloat(
                periodPercentageKvPair.value
              );
            }
          }
        }

        compiledReportData[sourceName] = data;
      });
    }

    return compiledReportData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobCostData]);

  const renderGraph = graph => {
    const { name, graphType, description, label } = graph;

    const thisReportData = reportData[name];

    let totalOverrideString = '';
    if (thisReportData) {
      if (
        thisReportData.percentageForPeriod !== null ||
        thisReportData.percentageForPeriod !== undefined
      ) {
        totalOverrideString += numeral(
          thisReportData.percentageForPeriod
        ).format('0.00%');
      }
      if (thisReportData.total !== null || thisReportData.total !== undefined) {
        if (totalOverrideString) {
          totalOverrideString += ' ';
        }
        totalOverrideString += `(${numeral(thisReportData.total).format(
          '$0,0.00'
        )})`;
      }
    }

    return (
      <div key={name} style={{ width: '100%', height: '100%' }}>
        <Graph
          description={description}
          title={label}
          dataSeries={[
            {
              type: graphType,
              data: thisReportData && thisReportData.values,
              dataType: thisReportData && thisReportData.labels,
              name: label,
            },
          ]}
          target={targetsByKey[name] && targetsByKey[name].value}
          targetSentiment="negative"
          loading={!(thisReportData && thisReportData.values)}
          showTotal={!!totalOverrideString}
          totalOverride={totalOverrideString}
          limit={{ y: { min: 0, max: 1 } }}
        />
        {thisReportData && thisReportData.isUnset && (
          <JobCostOverlay
            graph={graph}
            showSettingsModal={() => setShowSettings(true)}
          />
        )}
      </div>
    );
  };

  const settingsSections = [
    {
      label: 'Account Selection',
      component: JobCostSettings,
      sectionProps: {
        reportData,
        updateReportData: JobCostDataQuery.refetch,
      },
    },
    {
      label: 'Report Data',
      component: DashboardSettingsReportData,
      sectionProps: {
        scoreboardName: SCOREBOARD_NAME.DIRECT_COSTS,
        accountingMethod,
      },
    },
  ];

  if (managingCompanyInfo && managingCompanyInfo.isCompanyBookkeeper) {
    settingsSections.push({
      label: 'Targets',
      component: DashboardSettingsTargets,
      sectionProps: {
        title: 'Set Job Cost Targets',
        targetsToEdit: targetData,
      },
    });
  }

  return (
    <>
      <Dashboard
        controlBar={
          <JobCostControlBar
            title={title}
            reportPeriod={reportPeriod}
            accountingMethod={accountingMethod}
            setReportPeriod={setReportPeriod}
            setShowSettings={setShowSettings}
          />
        }
      >
        {jobCostGraphs
          .filter(
            ({ name }) =>
              !(reportData && reportData[name] && reportData[name].isHidden)
          )
          .map(graph => renderGraph(graph))}
      </Dashboard>
      {showSettings && (
        <DashboardSettings
          title={`${title} Scoreboard Settings`}
          sections={settingsSections}
          handleClose={() => setShowSettings(false)}
        />
      )}
    </>
  );
};

JobCostScoreboard.title = title;

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(
  compose(
    GetCompanyTargetsAction,
    ListScoreboardSettingsAction
  )(JobCostScoreboard)
);
