import gql from 'graphql-tag';

export default gql`
  mutation UpdateCompanyShoeboxItem(
    $contentId: String!
    $companyId: String!
    $media: [MediaInfoInput]
    $subject: String
    $notes: String
    $amount: MoneyInput
  ) {
    updateCompanyShoeboxItem(
      input: {
        contentId: $contentId
        companyId: $companyId
        media: $media
        subject: $subject
        notes: $notes
        amount: $amount
      }
    ) {
      companyId
      contentId
      dateCreated
      status
      subject
      notes
      amount {
        value
      }
      media {
        aspectRatio
        type
        uri
        ocrData {
          status
          error
          uri
          dataJson
        }
      }
    }
  }
`;
