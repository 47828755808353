import gql from 'graphql-tag';
import * as Fragments from '../fragments';

export default gql`
  ${Fragments.BookkeepingAlert}
  mutation UpdateBookkeepingAlert(
    $id: String!
    $enabled: Boolean!
    $companyId: String!
  ) {
    updateBookkeepingAlert(
      input: { id: $id, enabled: $enabled, companyId: $companyId }
    ) {
      ...BookkeepingAlertFields
    }
  }
`;
