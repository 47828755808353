import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, CircularProgress, Checkbox, Tooltip } from '@material-ui/core';

import styles from './bookkeeping-alerts.styles';

const useStyles = makeStyles(styles);

export const COMPONENT_TYPES = {
  SWITCH: 'switch',
  CHECKBOX: 'checkbox',
};

const BookkeepingAlertsToggle = ({
  checked,
  disabled,
  isLoading,
  tooltip,
  disabledTooltip,
  onToggle,
  component = COMPONENT_TYPES.SWITCH,
  size,
  ...otherProps
}) => {
  const classes = useStyles();

  const renderSwitch = () => {
    return (
      <Switch
        classes={{
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        checked={checked}
        variant="inline"
        onChange={onToggle}
        disabled={disabled}
        size={size}
        {...otherProps}
      />
    );
  };

  const renderCheckbox = () => {
    return (
      <Checkbox
        checked={checked}
        onChange={onToggle}
        onClick={event => {
          event.stopPropagation();
        }}
        color="primary"
        disabled={disabled}
        size={size}
        {...otherProps}
      />
    );
  };

  const renderComponent =
    component === COMPONENT_TYPES.SWITCH ? renderSwitch : renderCheckbox;

  const tooltipText = disabled ? disabledTooltip : tooltip;

  if (isLoading) {
    return (
      <CircularProgress
        size={size === 'small' ? 16 : 20}
        style={{ margin: 11 }}
      />
    );
  }

  return tooltipText ? (
    <Tooltip
      title={tooltipText}
      placement="right"
      PopperProps={{ style: { zIndex: 9999 } }}
    >
      <span>{renderComponent()}</span>
    </Tooltip>
  ) : (
    renderComponent()
  );
};

export default BookkeepingAlertsToggle;
