import gql from 'graphql-tag';

export default gql`
  query GetQuestionsByProjectId($projectId: String!) {
    getQuestionsByProjectId(projectId: $projectId) {
      items {
        questionId
        questionType
        questionText
        answers
        isRequired
        managingCompanyId
        creatorId
      }
    }
  }
`;
