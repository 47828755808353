import gql from 'graphql-tag';

export default gql`
  fragment RelationFields on Relation {
    relationId
    name
    managingCompanyId
    type
    isArchived
  }
`;
