import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';

const NoImageDialog = ({
  showNoImageDialog,
  setShowNoImageDialog,
  handleSubmit,
}) => {
  return (
    <Dialog
      open={showNoImageDialog}
      onClose={() => setShowNoImageDialog(false)}
    >
      <DialogContent>
        <Typography>
          Are you sure you don&apos;t want to include an image or PDF?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => setShowNoImageDialog(false)}
          color="primary"
          autoFocus
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setShowNoImageDialog(false);
            handleSubmit();
          }}
          variant="contained"
          color="primary"
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NoImageDialog;
