// @ts-check
import React from 'react';
import {
  MuiPickersUtilsProvider,
  TimePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { AccessTime as ClockIcon } from '@material-ui/icons';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

const FormikTimePicker = props => {
  const {
    field,
    form,
    timeFieldName,
    blockKeyboard,
    fullWidth,
    disabled,
    variant = 'inline',
    label,
    onAccept,
  } = props;
  const currentError =
    form.errors[field.name] || (timeFieldName && form.errors[timeFieldName]);

  const handleChange = date => {
    // If is valid set the time and date fields
    if (date && date.isValid()) {
      // Only set time component of date if the date is valid
      const currentDateWithNewTime = moment(form.values[field.name]);

      currentDateWithNewTime.set({
        hour: date.hour(),
        minute: date.minute(),
        second: date.second(),
      });

      form.setFieldValue(field.name, currentDateWithNewTime, false);
    }

    // Set the time field
    if (date) {
      form.setFieldValue(timeFieldName, date.toDate(), false);
    } else {
      form.setFieldValue(timeFieldName, null, false);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <>
        {!blockKeyboard && (
          <KeyboardTimePicker
            name={field.name}
            value={field.value}
            helperText={currentError}
            error={Boolean(currentError)}
            onChange={handleChange}
            format="hh:mm a"
            ampm
            keyboardIcon={<ClockIcon />}
            fullWidth={fullWidth}
            disabled={disabled}
            variant={variant}
            label={label}
            onAccept={onAccept}
          />
        )}
        {blockKeyboard && (
          <TimePicker
            name={field.name}
            value={field.value}
            helperText={currentError}
            error={Boolean(currentError)}
            onChange={date => form.setFieldValue(field.name, date, false)}
            format="h:mm a"
            fullWidth={fullWidth}
            disabled={disabled}
            variant={variant}
            label={label}
            onAccept={onAccept}
          />
        )}
      </>
    </MuiPickersUtilsProvider>
  );
};

export default FormikTimePicker;
