import React from 'react';

import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

import numeral from 'numeral';

import { calculateCompletePercentage } from './helpers';

const useStyles = makeStyles(() => ({
  row: {
    marginBottom: 12,
    paddingRight: 24,
  },
  rowValue: {
    width: 120,
    textAlign: 'right',
    fontWeight: 'bold',
  },
}));

const OverviewSection = ({
  data,
  listAllTotals,
  overageIsGood,
  overviewMode,
  tabValue,
}) => {
  const classes = useStyles();

  let totalToUse = data[tabValue].parentX;
  let budgetToUse = data[tabValue].parentOfY;
  if (overviewMode) {
    totalToUse = data[tabValue].statTotal;
    budgetToUse = data[tabValue].totalBudgetValue;
  }

  const {
    budget,
    fontColor: currentStatusFontColor,
    percentCompleteValue,
    varianceByUnit,
  } = calculateCompletePercentage({
    budget: budgetToUse,
    total: totalToUse,
    numberFormat: data[tabValue].numberFormat,
    overageIsGood,
  });

  const renderTotals = () => {
    const dataList = listAllTotals ? data : [data[tabValue]];
    return dataList.map(datum => {
      const wording = datum.pluralWording || datum.tabName;
      return (
        <Grid
          key={datum.pluralWording}
          item
          container
          justifyContent="flex-end"
          className={classes.row}
        >
          <Typography variant="h6">Total{` ${wording}`}:</Typography>
          <Typography
            variant="h6"
            className={classes.rowValue}
            style={{
              color: datum.totalColor || null,
            }}
          >
            {numeral(datum.statTotal).format(datum.numberFormat)}
          </Typography>
        </Grid>
      );
    });
  };

  return (
    <>
      {renderTotals()}
      {!!data[tabValue] && !!data[tabValue].totalBudgetValue && (
        <>
          <Grid
            item
            container
            justifyContent="flex-end"
            alignItems="center"
            className={classes.row}
          >
            <Typography variant="h6">
              {overviewMode ? 'Total Budgeted' : 'Project Budget'}:
            </Typography>
            <Typography variant="h6" className={classes.rowValue}>
              {budget}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            className={classes.row}
          >
            <Typography variant="h6" style={{ color: currentStatusFontColor }}>
              Variance:
            </Typography>
            <Typography
              variant="h6"
              className={classes.rowValue}
              style={{
                color: currentStatusFontColor,
              }}
            >
              {varianceByUnit}
            </Typography>
          </Grid>
        </>
      )}

      <Grid item container justifyContent="flex-end" className={classes.row}>
        <Typography variant="h6" style={{ color: currentStatusFontColor }}>
          % Complete:
        </Typography>

        <Typography
          variant="h6"
          className={classes.rowValue}
          style={{
            color: currentStatusFontColor,
          }}
        >
          {percentCompleteValue}
        </Typography>
      </Grid>
    </>
  );
};

export default OverviewSection;
