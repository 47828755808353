import gql from 'graphql-tag';

export default gql`
  mutation UpdateNotificationPrefs(
    $requestorId: String!
    $jrnId: String!
    $type: String!
    $expiryDate: String!
  ) {
    updateNotificationPrefs(
      input: {
        requestorId: $requestorId
        jrnId: $jrnId
        type: $type
        expiryDate: $expiryDate
      }
    ) {
      __typename
      msg
      readyToReceive
      token
      dontSendFor {
        jrnId
        chat
        newContent
        newComment
      }
      userId
    }
  }
`;
