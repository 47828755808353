import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'scroll',
    height: 'calc(100vh - 64px)',
  },
  paper: {
    backgroundColor: '#fff',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  filterButton: {
    color: theme.palette.brandColorPrimary,
    backgroundColor: '#f4f4f4',
  },
  filterButtonActive: {
    color: '#fff',
    backgroundColor: theme.palette.brandColorPrimary,
    '&:hover': {
      backgroundColor: theme.palette.brandColorPrimary,
    },
  },
  smallAvatar: {
    height: 20,
    width: 20,
    marginRight: 3,
  },
  mediumAvatar: {
    height: 40,
    width: 40,
    marginRight: 3,
  },
  bigAvatar: {
    height: 100,
    width: 100,
    marginRight: theme.spacing(2),
  },
  modalProjectTitle: {
    color: theme.palette.brandColorPrimary,
  },
  descriptionWrapper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    background: '#eaeaea',
    padding: theme.spacing(2),
  },
  descriptionLabel: {
    marginBottom: theme.spacing(1),
  },
  listSubheader: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  modalTitle: {
    color: theme.palette.brandColorPrimary,
  },
}));
