import React, { useMemo } from 'react';

import {
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ContentLoader from 'react-content-loader';

import palette from '../../theme/palette';
import TimezoneSelect, {
  getTimezoneAbbreviation,
} from '../timezone-select/timezone-select';

const useStyles = makeStyles(theme => ({
  listHeader: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: 16,
  },
  selectGroupHeader: {
    backgroundColor: palette.background.alt,
  },
}));

const OPTIONS = [
  { isSubheader: true, label: 'Morning' },
  { value: '00:00', label: '12:00am' },
  { value: '00:30', label: '12:30am' },
  { value: '01:00', label: '1:00am' },
  { value: '01:30', label: '1:30am' },
  { value: '02:00', label: '2:00am' },
  { value: '02:30', label: '2:30am' },
  { value: '03:00', label: '3:00am' },
  { value: '03:30', label: '3:30am' },
  { value: '04:00', label: '4:00am' },
  { value: '04:30', label: '4:30am' },
  { value: '05:00', label: '5:00am' },
  { value: '05:30', label: '5:30am' },
  { value: '06:00', label: '6:00am' },
  { value: '06:30', label: '6:30am' },
  { value: '07:00', label: '7:00am' },
  { value: '07:30', label: '7:30am' },
  { value: '08:00', label: '8:00am' },
  { value: '08:30', label: '8:30am' },
  { value: '09:00', label: '9:00am' },
  { value: '09:30', label: '9:30am' },
  { value: '10:00', label: '10:00am' },
  { value: '10:30', label: '10:30am' },
  { value: '11:00', label: '11:00am' },
  { value: '11:30', label: '11:30am' },
  { isSubheader: true, label: 'Afternoon' },
  { value: '12:00', label: '12:00pm' },
  { value: '12:30', label: '12:30pm' },
  { value: '13:00', label: '1:00pm' },
  { value: '13:30', label: '1:30pm' },
  { value: '14:00', label: '2:00pm' },
  { value: '14:30', label: '2:30pm' },
  { value: '15:00', label: '3:00pm' },
  { value: '15:30', label: '3:30pm' },
  { value: '16:00', label: '4:00pm' },
  { value: '16:30', label: '4:30pm' },
  { isSubheader: true, label: 'Evening' },
  { value: '17:00', label: '5:00pm' },
  { value: '17:30', label: '5:30pm' },
  { value: '18:00', label: '6:00pm' },
  { value: '18:30', label: '6:30pm' },
  { value: '19:00', label: '7:00pm' },
  { value: '19:30', label: '7:30pm' },
  { value: '20:00', label: '8:00pm' },
  { value: '20:30', label: '8:30pm' },
  { value: '21:00', label: '9:00pm' },
  { value: '21:30', label: '9:30pm' },
  { value: '22:00', label: '10:00pm' },
  { value: '22:30', label: '10:30pm' },
  { value: '23:00', label: '11:00pm' },
  { value: '23:30', label: '11:30pm' },
];

const ChooseTimeTimezone = ({
  label = 'Time to send',
  selectedTimezone,
  selectedTimeOfDay,
  passbackChoice,
  disabled,
  loading,
  hideTimezoneDropdownInput = false,
  showTimezoneAbbrevInTimeInput = false,
}) => {
  const classes = useStyles();
  const handleChangeTimeOfDay = event => {
    const timeOfDay = event.target.value;
    if (timeOfDay) {
      passbackChoice({ timeOfDay });
    }
  };

  const handleChangeTimeZone = timeZone => {
    passbackChoice({ timeZone });
  };

  const loaderRectangle = (
    <div style={{ width: 300 }}>
      <ContentLoader width={100} height={10}>
        <rect x="0" y="0" rx="0" ry="0" width="100" height="10" />
      </ContentLoader>
    </div>
  );

  const timezoneAbbrev = useMemo(() => {
    if (!showTimezoneAbbrevInTimeInput) {
      return '';
    }

    return getTimezoneAbbreviation(selectedTimezone);
  }, [selectedTimezone, showTimezoneAbbrevInTimeInput]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputLabel id="time-to-send" shrink>
          {label}
        </InputLabel>
        {loading && loaderRectangle}
        {!loading && (
          <Select
            labelId="time-to-send"
            value={selectedTimeOfDay}
            onChange={handleChangeTimeOfDay}
            style={{ width: '100%' }}
            autoWidth
            disabled={disabled}
            renderValue={value => {
              const option = OPTIONS.find(opt => opt.value === value);
              if (!option) {
                // NOTE: This should never happen but if it does (time is changed manually on
                // the backend such as 15:32), we should not crash the app.
                // Just return the value as is.
                return value;
              }

              return timezoneAbbrev
                ? `${option.label} (${timezoneAbbrev})`
                : option.label;
            }}
          >
            {OPTIONS.map(option => {
              if (option.isSubheader) {
                return (
                  <ListSubheader
                    key={option.label}
                    className={classes.selectGroupHeader}
                  >
                    {option.label}
                  </ListSubheader>
                );
              }
              return (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </Grid>
      {!hideTimezoneDropdownInput && (
        <Grid item xs={12}>
          <InputLabel shrink>Timezone</InputLabel>
          {loading && loaderRectangle}
          {!loading && (
            <TimezoneSelect
              value={selectedTimezone}
              labelStyle="abbrev"
              onChange={handleChangeTimeZone}
              showCurrentTime={false}
              disabled={disabled}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ChooseTimeTimezone;
