import React, { useState, useEffect } from 'react';
import {
  withRouter,
  useParams,
  useLocation,
  Switch,
  Route,
} from 'react-router-dom';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import { v4 as uuid } from 'uuid';

import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import _ from 'lodash';

import {
  DeleteJrnAction,
  DeleteContentAction,
  UpdateJrnMutationAction,
} from '../../../graphql/graphql';
import GetCustomerLeads from '../../../graphql/queries/get-customer-leads';
import GetCustomerProjects from '../../../graphql/queries/get-customer-projects';
import { createJrnInputMask } from '../../../graphql/masks/create-jrn-input';

// UI
import CustomerInfoHeader from './customer-info-header/customer-info-header';
import CustomerContent from './customer-content/customer-content';
import SingleLead from '../../../components/Lead/lead';
import AddWhatToProject from '../../add-to-project/add-what-to-project';
import AddContentForm from '../../add-to-project/add-content-form';
import LoadingCover from '../../../components/LoadingCover/loadingCover';
import AddLead from '../../../components/add-lead/add-lead';
import OkCancelDialog from '../../../components/OkCancelDialog/okCancelDialog';
import ContentDetailsModal from '../../add-to-project/content-details-modal';
import { CONTENT_DETAILS_MODAL_MODE } from '../../../config/appDefaults';

const CustomerDetails = ({
  managingCompanyInfo,
  customerInfo,
  onDeleteJrn,
  onDeleteContent,
  history,
  onUpdateJrn,
}) => {
  const { customerId } = useParams();
  const location = useLocation();

  const [infoWeNeed, setInfoWeNeed] = useState(null);
  const [sortedLeads, setSortedLeads] = useState(null);
  const [addEditLeadContent, setAddEditLeadContent] = useState({ open: false });
  const [addEditLeadInfo, setAddEditLeadInfo] = useState({ open: false });
  const [deletingOverlay, setDeletingOverlay] = useState({
    open: false,
    message: '',
  });
  const [
    showCreateProjectFromLeadDialog,
    setShowCreateProjectFromLeadDialog,
  ] = useState({
    open: false,
    mode: CONTENT_DETAILS_MODAL_MODE.CREATE_PROJECT_FROM_CUSTOMER_LEADS,
    parentInfo: null,
    existingInfo: null,
    // fromWhichAdminTool: 'lead',
  });

  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    title: '',
    message: '',
    onClose: () => {}, // Set based on context
    hideCancel: false,
    onConfirm: () => {}, // Set based on context
  });

  const onAddLead = baseContentInfo => {
    setAddEditLeadInfo({
      open: true,
      customerInfo,
      baseContentInfo,
      fromWhichAdminTool: 'createLeadFromCustomerLeads',
    });
  };

  const onUpdateLead = ({ leadDetails }) => {
    setAddEditLeadInfo({
      open: true,
      customerInfo,
      existingInfo: leadDetails,
    });
  };

  const onUpdateContentStatus = async newDetails => {
    await onUpdateJrn(newDetails);
  };

  const onAddEditLeadContent = ({ contentDetails, leadDetails }) => {
    if (contentDetails && leadDetails) {
      // this means they want to edit existing content
      setInfoWeNeed({
        type: contentDetails.type,
        parentInfo: leadDetails,
        existingInfo: contentDetails,
      });
      setAddEditLeadContent({ open: true });
    } else {
      setInfoWeNeed(null);
      // this means they want to add new content
      setAddEditLeadContent({ open: true, leadDetails });
    }
  };
  const handleWhatToAddChoice = ({ type }) => {
    setInfoWeNeed({
      type: type || addEditLeadContent.type,
      parentInfo: addEditLeadContent.leadDetails,
      existingInfo: addEditLeadContent.contentDetails,
    });
  };

  const backToCustomerContent = () => {
    const leadInUrl = location.pathname.indexOf('/lead/');
    const backToPath = location.pathname.substring(0, leadInUrl);
    history.push(backToPath);
  };

  const goToProject = projectId => {
    history.push(`/projects/${projectId}`);
  };

  const showAskToGoToProjectDialog = goToProjectId => {
    setDialogInfo({
      open: true,
      title: 'Project Created!',
      message: 'Would you like to go to the project now?',
      onClose: () => {
        setDialogInfo({ ...dialogInfo, open: false });
      },
      hideCancel: false,
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      onConfirm: () => {
        goToProject(goToProjectId);
      },
    });
  };

  const handleAfterProjectCreatedFromLead = creationResponse => {
    setShowCreateProjectFromLeadDialog({
      ...showCreateProjectFromLeadDialog,
      open: false,
    });
    if (creationResponse && !creationResponse.cancelled) {
      if (creationResponse.contentId) {
        showAskToGoToProjectDialog(creationResponse.contentId);
      } else {
        const runErrorDialog = passedError => {
          let errorStringed;
          try {
            errorStringed = JSON.stringify(passedError);
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log('JSON.stringify err: ', err);
            errorStringed = '';
          }

          const message = errorStringed.includes('Existing Parent ID')
            ? 'This lead already has a project associated with it. If you believe this was an error, please refresh the page and try again.'
            : 'There was an error creating the project. Please try again.';
          setDialogInfo({
            open: true,
            title: 'Error Creating Project',
            message,
            onClose: () => {
              setDialogInfo({ ...dialogInfo, open: false });
            },
            hideCancel: true,
          });
        };
        runErrorDialog(creationResponse);
      }
    }
  };

  const handleProjectCreation = async leadDetails => {
    const newProjectDetails = createJrnInputMask(leadDetails);
    newProjectDetails.type = 'project';
    newProjectDetails.contentId = uuid();
    if (newProjectDetails.budgets) {
      newProjectDetails.budgets.forEach((answer, budgetIndex) => {
        delete newProjectDetails.budgets[budgetIndex].__typename;
      });
    }
    delete newProjectDetails.contentStatus;
    setShowCreateProjectFromLeadDialog({
      open: true,
      mode: CONTENT_DETAILS_MODAL_MODE.CREATE_PROJECT_FROM_CUSTOMER_LEADS,
      existingInfo: newProjectDetails,
      leadDetails,
    });
  };

  const onDeleteLead = async leadDetails => {
    setDeletingOverlay({ open: true, message: 'Deleting Lead...' });
    await onDeleteJrn(
      leadDetails.contentId,
      'lead',
      undefined,
      leadDetails.customerId
    );
    setDeletingOverlay({ ...deletingOverlay, open: false });
  };

  const handleDeleteContent = async ({ contentItem }) => {
    setDeletingOverlay({ open: true, message: 'Deleting Item...' });
    const contentIdToDelete = contentItem.contentId;
    const parentProjectId = contentItem.jrnId;
    await onDeleteContent(contentIdToDelete, parentProjectId);
    setDeletingOverlay({ ...deletingOverlay, open: false });
  };

  const managingCompanyId =
    managingCompanyInfo && managingCompanyInfo.managingCompanyId;

  // START -  CustomerContent
  // get customer leads
  const leadsQuery = useQuery(GetCustomerLeads, {
    skip: !managingCompanyId,
    variables: {
      companyId: managingCompanyId,
      customerId,
    },
    fetchPolicy: 'cache-and-network',
  });
  const leads = _.get(leadsQuery, 'data.getCustomerLeads.items');
  const leadsLoading = _.get(leadsQuery, 'loading');
  useEffect(() => {
    setSortedLeads(_.orderBy(leads, ['date', 'title'], ['desc', 'asc']));
  }, [leads]);

  // get customer projects
  const projectsQuery = useQuery(GetCustomerProjects, {
    skip: !managingCompanyId,
    variables: { companyId: managingCompanyId, customerId },
    fetchPolicy: 'cache-and-network',
  });
  const projectsLoading = _.get(projectsQuery, 'loading');
  const projects = _.get(projectsQuery, 'data.getCustomerProjects.items');

  // END -  CustomerContent

  return (
    <Grid container direction="column">
      <Grid item style={{ marginBottom: 16 }}>
        <CustomerInfoHeader
          passedCustomerInfo={customerInfo}
          onAddLead={onAddLead}
          customerId={customerId} // Used for polling
        />
      </Grid>
      <Grid item>
        <Switch>
          <Route path="/customers/:customerId/lead/:leadId">
            <SingleLead
              onUpdateLead={onUpdateLead}
              onUpdateContentStatus={onUpdateContentStatus}
              onAddEditLeadContent={onAddEditLeadContent}
              onCreateProjectFromLead={handleProjectCreation}
              backToCustomerContent={backToCustomerContent}
              onDeleteLead={onDeleteLead}
              handleDeleteContent={handleDeleteContent}
              goToProject={goToProject}
              customerInfo={customerInfo}
            />
          </Route>
          <Route path="/customers/:customerId">
            <CustomerContent
              leadsLoading={leadsLoading}
              leads={sortedLeads}
              projectsLoading={projectsLoading}
              projects={projects}
              onUpdateLead={onUpdateLead}
            />
          </Route>
        </Switch>
      </Grid>
      {addEditLeadInfo.open && (
        <AddLead
          addEditLeadInfo={addEditLeadInfo}
          toClose={() => {
            setAddEditLeadInfo({ open: false });
          }}
          hideCustomerOption
        />
      )}

      {addEditLeadContent.open && (
        <Dialog maxWidth="lg" fullWidth open>
          <DialogTitle disableTypography style={{ paddingBottom: 0 }}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setAddEditLeadContent({ open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            {!infoWeNeed ? (
              <AddWhatToProject
                parentInfo={addEditLeadContent.leadDetails}
                canEdit
                passOptionsBack={passedOptions =>
                  handleWhatToAddChoice(passedOptions)
                }
              />
            ) : (
              <AddContentForm
                parentId={infoWeNeed.parentInfo.contentId}
                passedType={infoWeNeed.type}
                parentInfo={infoWeNeed.parentInfo}
                existingInfo={infoWeNeed.existingInfo}
                passedContentInfo={infoWeNeed.existingInfo || null}
                onComplete={() => {
                  setAddEditLeadContent({ open: false });
                }}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
      {deletingOverlay && deletingOverlay.open && (
        <LoadingCover loader="linear">
          <Typography variant="h3" align="center">
            {deletingOverlay.message}
          </Typography>
        </LoadingCover>
      )}
      {dialogInfo.open && (
        <OkCancelDialog {...dialogInfo}>
          <Typography>{dialogInfo.message}</Typography>
        </OkCancelDialog>
      )}
      {showCreateProjectFromLeadDialog.open && (
        <ContentDetailsModal
          mode={showCreateProjectFromLeadDialog.mode}
          existingInfo={showCreateProjectFromLeadDialog.existingInfo}
          leadDetails={showCreateProjectFromLeadDialog.leadDetails}
          onClose={handleAfterProjectCreatedFromLead}
          hideCustomerOption
        />
      )}
    </Grid>
  );
};

export default withRouter(
  compose(
    DeleteJrnAction,
    DeleteContentAction,
    UpdateJrnMutationAction
  )(CustomerDetails)
);
