export const PAYMENT_TERMS = {
  DUE_ON_RECEIPT: 'DUE_ON_RECEIPT',
  NET_10: 'NET_10',
  NET_15: 'NET_15',
  NET_30: 'NET_30',
  OTHER: 'OTHER',
};

export const PAYMENT_TERMS_LABELS = {
  [PAYMENT_TERMS.DUE_ON_RECEIPT]: 'Due on Receipt',
  [PAYMENT_TERMS.NET_10]: 'Net 10',
  [PAYMENT_TERMS.NET_15]: 'Net 15',
  [PAYMENT_TERMS.NET_30]: 'Net 30',
  [PAYMENT_TERMS.OTHER]: 'Other',
};

export const PAYMENT_DUE_IN_DAYS = {
  [PAYMENT_TERMS.DUE_ON_RECEIPT]: 0,
  [PAYMENT_TERMS.NET_10]: 10,
  [PAYMENT_TERMS.NET_15]: 15,
  [PAYMENT_TERMS.NET_30]: 30,
  [PAYMENT_TERMS.OTHER]: 0,
};

export const DAYS_TO_PAYMENT_TERMS = {
  0: PAYMENT_TERMS.DUE_ON_RECEIPT,
  10: PAYMENT_TERMS.NET_10,
  15: PAYMENT_TERMS.NET_15,
  30: PAYMENT_TERMS.NET_30,
};
