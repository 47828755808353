import React from 'react';
import ScrollableFeed from 'react-scrollable-feed';
import Linkify from 'react-linkify';
import { withRouter, Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LeaveIcon from '@material-ui/icons/TransitEnterexit';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import _ from 'lodash';
import moment from 'moment';

import { cloudinaryifyProfilePic } from '../../../helpers/cloudinary';
import { runAnalytics } from '../../../helpers';

import { renderDateString } from '../../../helpers/renderDateString';

class MessagesListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageContent: '',
      deleteModalOpen: false,
      leaveModalOpen: false,
    };
  }

  componentDidMount() {
    const { subscribeToNewMessages } = this.props;
    // subscribe to new messages here from the props being passed down
    subscribeToNewMessages();
  }

  componentDidUpdate() {
    const { subscribeToNewMessages } = this.props;
    if (subscribeToNewMessages) {
      subscribeToNewMessages();
    }
  }

  onKeyDown = event => {
    const { createNewMessage } = this.props;
    if (event.key === 'Enter' && event.shiftKey === false) {
      // this.myFormRef.submit();
      event.preventDefault();
      event.stopPropagation();
      // send the message and clear the input
      createNewMessage(event.target.value);
      this.setState({ messageContent: '' });
    }
  };

  createNewMessage = () => {
    const { createNewMessage } = this.props;
    const { messageContent } = this.state;
    createNewMessage(messageContent);
    this.setState({ messageContent: '' });
  };

  setAnchorEl = passedElement => {
    this.setState({ anchorEl: passedElement });
  };

  handleClick = event => {
    this.setAnchorEl(event.currentTarget);
  };

  handleClose = () => {
    this.setAnchorEl(null);
  };

  startDeleteProcess = () => {
    // close the little menu
    this.handleClose();
    // dialog asking if they're sure
    this.setState({ deleteModalOpen: true });
  };

  startLeaveProcess = () => {
    // close the little menu
    this.handleClose();
    // dialog asking if they're sure
    this.setState({ leaveModalOpen: true });
  };

  closeProject = () => {
    const { convoInfo } = this.props;

    const {
      history,
      location: { pathname },
    } = this.props;
    // get current url
    let newUrl = pathname;
    // remove current projects id
    newUrl = newUrl.replace(`/${convoInfo.contentId}`, '');
    // push new url to history
    history.push(newUrl);
  };

  deleteProject = () => {
    const { onDeleteJrn, userInfo, convoInfo } = this.props;

    const projectIdToDelete = convoInfo.contentId;

    if (onDeleteJrn) {
      onDeleteJrn(projectIdToDelete, 'conversation');
      runAnalytics('Contents', {
        contentAction: 'Delete Content',
        projectId: convoInfo.jrnId,
        userId: convoInfo.creatorId,
        username: userInfo.username,
        type:
          convoInfo.title !== 'hold' && convoInfo.title !== null
            ? 'groupConversation'
            : convoInfo.type,
        contentId: convoInfo.contentId,
      });
    }
    this.setState({ deleteModalOpen: false });
    this.closeProject();
  };

  leaveConversationById = () => {
    const { onLeaveJrn, convoInfo } = this.props;
    if (onLeaveJrn) {
      onLeaveJrn(convoInfo.contentId, 'conversation');
    }
    this.setState({ leaveModalOpen: false });
    this.closeProject();
  };

  render() {
    const {
      messages,
      userId,
      allConvoUsers,
      convoInfo,
      titleToUse,
      thumbnailToUse,
      loading,
      theme,
      hideHeader,
      setShowManualAddDialog,
      styleForFeed,
      onlyUser,
      onLoadMore,
      nextToken,
    } = this.props;
    const {
      messageContent,
      anchorEl,
      deleteModalOpen,
      leaveModalOpen,
    } = this.state;
    const styles = {
      moreButton: {
        marginLeft: theme.spacing(2),
        fontSize: 30,
      },
      actionButtons: {
        color: theme.palette.brandColorDarkGrey,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      },
      messageListWrapper: {
        backgroundColor: '#fff',
      },
      messageWrapper: {
        padding: theme.spacing(1),
        color: theme.palette.brandColorDarkGrey,
      },
      userProfilePic: {
        marginTop: theme.spacing(2),
      },
      messageContentTimeWrapper: {
        minWidth: '8%',
      },
      messageContentWrapper: {
        padding: theme.spacing(1),
        backgroundColor: '#eee',
        borderRadius: 10,
      },
      messageTimeWrapper: {
        textAlign: 'right',
      },
      dateSectionHeaderWrapper: {
        padding: theme.spacing(1),
      },
      convoHeaderWrapper: {
        background: '#fff',
        borderBottom: '1px solid #ccc',
        height: '59px',
        width: '100%',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      },
      convoMessagesListWrapper: {
        height: 'calc(100vh - 64px - 64px - 59px)',
      },
      convoMessagesListFeedWrapper: {
        height: '40vh',
      },
      convoInputWrapper: {
        height: '62px',
        width: '100%',
        background: '#fff',
        borderTop: '1px solid #ccc',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: theme.spacing(1),
      },
      convoInput: {
        width: '100%',
        border: 0,
        height: '100%',
        padding: theme.spacing(1),
        outline: 0,
        fontSize: '20px',
      },
      userName: {
        display: 'flex',
        paddingTop: '10px',
        height: 40,
        width: 40,
        borderRadius: 30,
        background: '#eee',
        alignItems: 'center',
        justifyContent: 'center',
      },
    };

    let combinedLists = [];
    if (convoInfo) {
      const ate = convoInfo.allowedToEdit;
      const ata = convoInfo.allowedToAdd || [];
      combinedLists = ate.concat(ata);
    }

    return (
      <Grid
        container
        alignItems="flex-end"
        justifyContent="flex-start"
        style={styles.messageListWrapper}
      >
        <Grid container direction="column">
          {!hideHeader && (
            <Grid item style={styles.convoHeaderWrapper}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Avatar
                      alt="conversation cover"
                      src={
                        thumbnailToUse
                          ? cloudinaryifyProfilePic(thumbnailToUse)
                          : null
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    style={{
                      maxWidth: 500,
                    }}
                  >
                    <Typography
                      variant="h4"
                      style={{
                        color: onlyUser ? theme.palette.brandColorOrange : null,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {titleToUse}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* header MoreHoriz here */}

              <>
                <Tooltip title="Project options">
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={this.handleClick}
                  >
                    <MoreHorizIcon />
                  </Button>
                </Tooltip>

                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={this.handleClose}
                >
                  {convoInfo &&
                    convoInfo.type !== 'project' &&
                    convoInfo.allowedToEdit.includes(userId) &&
                    convoInfo.title !== 'hold' &&
                    convoInfo.title !== null && (
                      <MenuItem
                        onClick={() => {
                          this.handleClose();
                          setShowManualAddDialog({
                            open: true,
                            mode: 'edit',
                            existingInfo: convoInfo,
                          });
                        }}
                      >
                        <EditIcon />
                        &nbsp;&nbsp;Edit Convo
                      </MenuItem>
                    )}
                  {convoInfo &&
                    convoInfo.type !== 'project' &&
                    combinedLists.length !== 1 && (
                      <MenuItem onClick={this.startLeaveProcess}>
                        <LeaveIcon />
                        &nbsp;&nbsp;Leave Convo
                      </MenuItem>
                    )}
                  {convoInfo &&
                    convoInfo.type !== 'project' &&
                    combinedLists.length === 1 && (
                      <MenuItem onClick={this.startDeleteProcess}>
                        <DeleteIcon />
                        &nbsp;&nbsp;Delete Convo
                      </MenuItem>
                    )}

                  {convoInfo && convoInfo.type === 'project' && (
                    <MenuItem style={styles.actionButtons}>
                      <Link
                        to={`/projects/${convoInfo.contentId}`}
                        style={styles.actionButtons}
                      >
                        <ExitToAppIcon />
                        &nbsp;&nbsp;Go To Project
                      </Link>
                    </MenuItem>
                  )}
                </Menu>
              </>
            </Grid>
          )}
          <Grid
            item
            style={
              styleForFeed
                ? styles.convoMessagesListFeedWrapper
                : styles.convoMessagesListWrapper
            }
          >
            <ScrollableFeed>
              <Grid
                item
                container
                justifyContent="center"
                style={{ marginTop: 8, marginBottom: 8 }}
              >
                {nextToken ? (
                  <Button
                    style={{ alignSelf: 'center' }}
                    onClick={onLoadMore}
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <CircularProgress size="small" /> Loading...
                      </>
                    ) : (
                      <>Load More...</>
                    )}
                  </Button>
                ) : (
                  <Button onClick={null} disabled>
                    End Of Chat
                  </Button>
                )}
              </Grid>
              {messages &&
                messages.map((message, index) => {
                  const timestamp = moment
                    .unix(message.createdAt / 1000)
                    .toDate();
                  let user = _.find(allConvoUsers, {
                    userId: message.authorId,
                  });
                  if (!user) {
                    user = {
                      username: message.authorId.substring(0, 7),
                      profilePic: null,
                    };
                  }

                  // **************if it's the LAST message from that user******************
                  const nextAuthorId = messages[index - 1]
                    ? messages[index - 1].authorId
                    : '';
                  let isSameUserAsLastMsg = false;
                  if (message.authorId === nextAuthorId) {
                    isSameUserAsLastMsg = true;
                  }
                  // *************if it's the LAST message from that day, show the date**********
                  const previousDay = messages[index - 1]
                    ? moment.unix(messages[index - 1].createdAt / 1000).toDate()
                    : null;
                  let isSameDayAsLastMsg = false;
                  if (
                    previousDay &&
                    moment(timestamp).isSame(previousDay, 'day')
                  ) {
                    isSameDayAsLastMsg = true;
                  }
                  const isMyMessage = message.authorId === userId;
                  return (
                    <React.Fragment key={message.messageId}>
                      {!isSameDayAsLastMsg && (
                        <Grid container justifyContent="center">
                          <Grid item style={styles.dateSectionHeaderWrapper}>
                            {renderDateString(timestamp, null, 'MMM D')}
                          </Grid>
                        </Grid>
                      )}
                      <Grid
                        container
                        direction="column"
                        style={styles.messageWrapper}
                      >
                        <Grid item style={{ height: 'auto' }}>
                          <Grid
                            container
                            wrap="nowrap"
                            direction={isMyMessage ? 'row-reverse' : 'row'}
                            spacing={1}
                            style={{
                              paddingLeft: isMyMessage ? '20%' : 0,
                              paddingRight: isMyMessage ? 0 : '20%',
                            }}
                          >
                            <Grid item>
                              {(!isSameUserAsLastMsg || !isSameDayAsLastMsg) &&
                                (user.profilePic ? (
                                  <Avatar
                                    alt={user.username}
                                    src={
                                      user.profilePic
                                        ? cloudinaryifyProfilePic(
                                            user.profilePic
                                          )
                                        : null
                                    }
                                    style={styles.userProfilePic}
                                  />
                                ) : (
                                  <div style={styles.userName}>
                                    {user.username.substring(0, 2)}
                                  </div>
                                ))}
                              {!(
                                !isSameUserAsLastMsg || !isSameDayAsLastMsg
                              ) && (
                                <div
                                  style={{
                                    ...styles.userName,
                                    background: 'transparent',
                                  }}
                                />
                              )}
                            </Grid>
                            <Grid item style={styles.messageContentTimeWrapper}>
                              <Grid container direction="column">
                                {(!isSameUserAsLastMsg ||
                                  !isSameDayAsLastMsg) && (
                                  <Grid
                                    item
                                    style={{
                                      textAlign: isMyMessage ? 'right' : 'left',
                                      paddingLeft: '4px',
                                      paddingRight: '4px',
                                    }}
                                  >
                                    <Typography variant="caption">
                                      {user.username}
                                    </Typography>
                                  </Grid>
                                )}
                                <Grid item>
                                  <Grid
                                    container
                                    direction="column"
                                    style={styles.messageContentWrapper}
                                  >
                                    <Grid item>
                                      <Linkify>{message.content}</Linkify>
                                    </Grid>
                                    <Grid
                                      item
                                      style={styles.messageTimeWrapper}
                                    >
                                      <Typography variant="caption">
                                        {renderDateString(
                                          timestamp,
                                          null,
                                          'h:mma'
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  );
                })}
            </ScrollableFeed>
          </Grid>
          <Grid item>
            <Grid container style={styles.convoInputWrapper}>
              <Grid item style={{ flex: 1 }}>
                <TextareaAutosize
                  maxRows={4}
                  style={styles.convoInput}
                  aria-label="message input"
                  placeholder="Type a message..."
                  value={messageContent}
                  onChange={event =>
                    this.setState({ messageContent: event.target.value })
                  }
                  onKeyDown={this.onKeyDown}
                />
              </Grid>
              <Grid item>
                <Button color="primary" onClick={this.createNewMessage}>
                  SEND
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          maxWidth="sm"
          aria-labelledby="confirmation-dialog"
          open={deleteModalOpen}
        >
          <DialogContent>
            Are you sure you want to delete this conversation?
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => this.setState({ deleteModalOpen: false })}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={this.deleteProject} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          maxWidth="sm"
          aria-labelledby="confirmation-dialog"
          open={leaveModalOpen}
        >
          <DialogContent>
            Are you sure you want to leave this conversation?
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => this.setState({ leaveModalOpen: false })}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={this.leaveConversationById} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default withRouter(withTheme(MessagesListView));
