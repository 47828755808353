import React, { useState } from 'react';
import SingleInputDialog from '../../../components/single-input-dialog/single-input-dialog';
import { RFI_ADMIN_PW } from '../../../config/appDefaults';

const RfiAdminModePrompt = ({ shouldShow, onSuccess, onCancel }) => {
  const [showAdminDialog, setShowAdminDialog] = useState({ isError: false });

  if (!shouldShow) {
    return null;
  }

  return (
    <SingleInputDialog
      title={null}
      message="Please enter an admin password to allow saving RFI's directly for client viewing. Otherwise, RFI's will require admin approval."
      handleClose={() => {
        onCancel();
      }}
      type="password"
      isError={showAdminDialog.isError}
      errorMsg="Incorrect password"
      handleSubmit={textFromInput => {
        if (textFromInput === RFI_ADMIN_PW) {
          onSuccess();
        } else {
          setShowAdminDialog({
            isError: true,
          });
        }
      }}
    />
  );
};

export default RfiAdminModePrompt;
