import React, { useState, useEffect } from 'react';
import { compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography, Grid, Avatar } from '@material-ui/core';
import { AccountCircle as AccountCircleIcon } from '@material-ui/icons';
import Dropzone from 'react-dropzone';

import { cloudinaryifyProfilePic } from '../../helpers/cloudinary';

import BasicDialog from '../basic-dialog/basic-dialog';
import ModalImageManipulator from '../ModalImageManipulator/modalImageManipulator';
import SettingsSection from '../settings-section';

import {
  UpdateUserMutationAction,
  uploadProfilePhoto,
} from '../../graphql/graphql';
import GetUserInfo from '../../graphql/queries/GetUserInfo';
import palette from '../../theme/palette';

const useStyles = makeStyles(theme => ({
  listItem: {
    width: '100%',
    backgroundColor: '#fff',
    borderBottom: '1px solid #eee',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: palette.background.alt,
    },
    alignItems: 'center',
    justifyContent: 'center',
  },
  bigAvatar: {
    height: '150px',
    width: '150px',
    flexShrink: 0,
    flexGrow: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    position: 'relative',
  },

  gridCenter: {
    textAlign: 'center',
  },
  profileGrid: {
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 5,
  },
  label: {
    cursor: 'pointer',
  },
  uploadPhoto: {
    opacity: 0,
    position: 'absolute',
    zIndex: -1,
  },
  secondaryAction: {},
}));

const ProfilePic = props => {
  const { client } = props;
  const [localUserInfo, setLocalUserInfo] = useState({});

  const [imageForManipulation, setImageForManipulation] = useState(null);
  const [openManipulationModal, setOpenManipulationModal] = useState(false);
  const [showBasicDialog, setShowBasicDialog] = useState({
    open: false,
    title: '',
    message: '',
  });
  const [loadingPic, setLoadingPic] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    // https://github.com/facebook/react/issues/14369#issuecomment-468267798
    // flag to make sure we're not doing anything while the component is unmounted since it could have since we asyn/await'd
    let didCancel = false;
    let userInfoQuery;
    const runQuery = async () => {
      userInfoQuery = await client.query({
        query: GetUserInfo,
        options: { fetchPolicy: 'network-only' },
        variables: { userId: 'willBePulledFromCognitoSubContentInResolver' },
      });
      const tempInfo = _.get(userInfoQuery, 'data.getMyUserInfo');
      if (!didCancel) {
        setLocalUserInfo(tempInfo);
      }
    };
    runQuery();
    return () => {
      didCancel = true; // this will happen when the component unmounts
    };
  }, [client]);

  const saveProfileImage = async file => {
    setLoadingPic(true);
    const uploadedResponse = await uploadProfilePhoto(
      {
        profilePic: localUserInfo.profilePic,
        username: localUserInfo.username,
        email: localUserInfo.email,
        userId: localUserInfo.userId,
        synced: false,
        coverImage: null,
        hasLoggedIn: true,
      },
      file
    );
    if (_.get(uploadedResponse, 'response.secure_url')) {
      setLocalUserInfo({
        profilePic: uploadedResponse.response.secure_url,
        username: localUserInfo.username,
        email: localUserInfo.email,
        userId: localUserInfo.userId,
        synced: false,
        coverImage: null,
      });
    } else {
      //
    }
    setLoadingPic(false);
  };

  return (
    <SettingsSection
      icon={<AccountCircleIcon />}
      title="Display Picture"
      description="Update your display picture"
    >
      {localUserInfo && (
        <Grid item>
          <Dropzone
            accept="image/*"
            onDrop={acceptedFiles => {
              const filesToPass = [...acceptedFiles];
              filesToPass[0].uri = URL.createObjectURL(filesToPass[0]);
              // saveProfileImage(filesToPass[0]);
              setImageForManipulation(filesToPass[0].uri);
              setOpenManipulationModal(true);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <>
                <Grid
                  item
                  xs={12}
                  className={classes.gridCenter}
                  style={{ position: 'relative', cursor: 'pointer' }}
                  {...getRootProps()}
                >
                  <Avatar
                    alt="user profile image"
                    src={
                      localUserInfo.profilePic
                        ? cloudinaryifyProfilePic(localUserInfo.profilePic)
                        : null
                    }
                    className={classes.bigAvatar}
                  />
                  {loadingPic && (
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        color: '#fff',
                      }}
                    >
                      <CircularProgress color="inherit" />
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  {...getRootProps()}
                >
                  <Grid item style={{ cursor: 'pointer' }}>
                    <input {...getInputProps()} />
                    <Typography variant="body1" color="textSecondary">
                      Click to update
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Dropzone>
        </Grid>
      )}
      <ModalImageManipulator
        open={openManipulationModal}
        handleClose={() => setOpenManipulationModal(false)}
        passedImage={imageForManipulation}
        passbackManipulatedImage={blob => {
          const blobToFile = (theBlob, fileName) => {
            const blobParam = theBlob;
            // A Blob() is almost a File() - it's just missing the two properties below which we will add
            blobParam.lastModifiedDate = new Date();
            blobParam.name = fileName;
            return blobParam;
          };
          const saveThis = blobToFile(blob, 'profilepic.jpg');
          setOpenManipulationModal(false);
          saveProfileImage(saveThis);
        }}
      />
      <BasicDialog
        open={!!showBasicDialog.open}
        title={showBasicDialog.title}
        customChildren
        handleClose={() => setShowBasicDialog({ open: false, message: '' })}
      >
        <Typography>{showBasicDialog.message}</Typography>
      </BasicDialog>
    </SettingsSection>
  );
};
export default compose(
  UpdateUserMutationAction,
  withApollo
)(withRouter(ProfilePic));
