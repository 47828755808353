import React from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import moment from 'moment';
import { Field } from 'formik';
import LevelNumberInput from '../level-number-input/level-number-input';
import FormikTimePicker from '../../../components/formik-custom-components/formik-time-picker';

const TemplateDateInput = ({
  classes,
  disabled,
  values,
  name,
  timeFieldName,
  templateName,
  dateLabel,
  timeLabel,
  templateChanges,
  setTemplateChanges,
  showTimeInput,
  isBeforeTemplateName,
  isBeforeName,
  isAfterTemplateName,
  isAfterName,
}) => {
  const handleDateChange = value => {
    if (isBeforeTemplateName) {
      const changes = {
        [templateName]: value,
      };
      if (value >= templateChanges[isBeforeTemplateName]) {
        // if this made it higher than the end day, move the end day up too
        if (moment(values[name]).isAfter(moment(values[isBeforeName]))) {
          // if on top of that the end time is before the start time, bump the day up to the next day
          //  or else we'd end up with a task that still ends before it starts
          changes[isBeforeTemplateName] = value + 1;
        } else {
          changes[isBeforeTemplateName] = value;
        }
      }
      setTemplateChanges({
        ...templateChanges,
        ...changes,
      });
    } else {
      setTemplateChanges({
        ...templateChanges,
        [templateName]: value,
      });
    }
  };

  const handleTimeChange = value => {
    if (isBeforeName && moment(value).isAfter(values[name])) {
      // if they put the startTime after the endTime,
      if (
        templateChanges[isBeforeTemplateName] <= templateChanges[templateName]
      ) {
        //  then check to make sure it's at least a day ahead
        //  if not set it a day ahead
        setTemplateChanges({
          ...templateChanges,
          [isBeforeTemplateName]: templateChanges[templateName] + 1,
        });
      }
    }
    if (isAfterName && moment(value).isBefore(values.startDate)) {
      // if they put the endTime before the startTime,
      if (
        templateChanges[templateName] <= templateChanges[isAfterTemplateName]
      ) {
        //  then check to make sure it's at least a day ahead
        //  if not set it a day ahead
        setTemplateChanges({
          ...templateChanges,
          [templateName]: templateChanges[isAfterTemplateName] + 1,
        });
      }
    }
  };

  let min = 1;
  if (isAfterName) {
    min =
      moment(values[isAfterName]).isAfter(values[name]) &&
      templateChanges[isAfterTemplateName] <= templateChanges[templateName]
        ? templateChanges[isAfterTemplateName] + 1
        : templateChanges[isAfterTemplateName];
  }

  return (
    <>
      <Grid item xs={12} sm={6}>
        <InputLabel className={classes.staticLabel} disabled={disabled}>
          {dateLabel}
        </InputLabel>

        <LevelNumberInput
          value={templateChanges[templateName]}
          onChange={handleDateChange}
          inlineLabel="Day #"
          min={min}
        />
      </Grid>
      {showTimeInput && (
        <Grid item xs={12} sm={6}>
          <Field
            label={timeLabel}
            name={name}
            value={moment(values[name]).utc()}
            component={FormikTimePicker}
            variant="inline"
            fullWidth
            disabled={disabled}
            timeFieldName={timeFieldName}
            blockKeyboard
            onAccept={handleTimeChange}
          />
        </Grid>
      )}
    </>
  );
};

export default TemplateDateInput;
