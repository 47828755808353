import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose, withApollo } from 'react-apollo';
import { useMutation } from 'react-apollo-hooks';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Checkbox,
  CircularProgress,
  Button,
  Tooltip,
  Drawer,
} from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  HelpOutline as HelpIcon,
} from '@material-ui/icons';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import uuid from 'uuid';
import _ from 'lodash';

import {
  CONTENT_DEFINITION,
  CONTENT_TYPE,
  WORKFLOW_STAGE,
  RFI_SOURCES,
  PRODUCT,
  WORKFLOW_STAGE_REASON,
} from '../../config/appDefaults';

import UpdateContentMutation from '../../graphql/mutations/mutation_updateContent';

import {
  GetCompanyCrewAction,
  CreateCommentMutationAction,
  GetContentByIdAction,
  AddCompanyRfisAction,
  ListCompanyProjectsAction,
} from '../../graphql/graphql';
import Comments from '../../components/ProjectContentListing/Comments/comments';
import CommentInput from '../../components/ProjectContentListing/Comments/CommentInput';
import LoadingCover from '../../components/LoadingCover/loadingCover';
import ContentGallery from '../../components/ProjectContentListing/ContentTypes/contentGallery';
import { monetaryRender, prepRfisForSaving } from '../../helpers';
// eslint-disable-next-line import/no-cycle
import AdminCreateEditRfiForm from './manage-requests-for-info/admin-create-edit-rfi-form';
// eslint-disable-next-line import/no-cycle
import ViewOrEditRfi from '../../components/ProjectContentListing/view-or-edit-rfi';
import palette from '../../theme/palette';
import { RelatedRfisFinancialItems } from '../../components/related-rfis-financial-items/related-rfis-financial-tems';

const heightOfFooter = 68;
const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  mainContainerWithFooter: {
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: heightOfFooter,
  },
  rightWrapper: {
    padding: theme.spacing(2),
  },
  infoSection: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid #ccc',
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    fontWeight: 'bold',
    flex: 0,
    minWidth: 140,
    paddingRight: 16,
  },
  descriptionWrapper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    display: 'block',
    background: palette.background.alt,
  },
  drawerPaper: {
    width: '90%',
    maxWidth: 1200,
  },
  footer: {
    left: 0,
    right: 0,
    bottom: 0,
    background: '#ececec',
    padding: 16,
    height: heightOfFooter,
    position: 'absolute',
  },
}));

const FinancialItemAdminView = ({
  // passed in props
  onEditButtonClick,
  onDeleteButtonClick,
  hideDeleteButton,
  contentInfo,
  parentInfo,
  showFooter,
  getNextItem,
  fromWhichAdminTool,
  includeArchive,
  hideAccountingAndRfi = false,

  // HOC props
  managingCompanyInfo,
  companyCrew,
  onAddComment,
  contentInfoLoading,
  userInfo,
  companyProjects,
  onAddCompanyRfis,
  readonly,
  rfiViewMode,
}) => {
  const classes = useStyles();

  const [showCommentInput, setShowCommentInput] = useState(false);
  const [createOrEditPanel, setCreateOrEditPanel] = useState({ open: false });
  const [showSaving, setShowSaving] = useState(false);
  const [viewOrEditRfiDialog, setViewOrEditRfiDialog] = useState({
    requestId: null,
  });

  const [updateWorkflowStage, { loading: workflowStageSaving }] = useMutation(
    UpdateContentMutation
  );
  const [
    updateWorkflowStageReason,
    { loading: workflowStageReasonSaving },
  ] = useMutation(UpdateContentMutation);

  const hasRfiProduct = managingCompanyInfo?.products?.includes(PRODUCT.RFI);

  const amount =
    contentInfo &&
    contentInfo.amount &&
    typeof contentInfo.amount.value === 'number'
      ? monetaryRender({ value: contentInfo.amount.value, withDecimals: true })
      : 'not provided';

  const renderOnlyBookkeeperExplanation = () => (
    <Tooltip title="This field can only be changed by bookkeepers">
      <HelpIcon
        fontSize="small"
        color="primary"
        style={{ marginLeft: 4, marginTop: -2 }}
      />
    </Tooltip>
  );

  const handleDrawerClose = ({ closeEvent = {}, askIfSure = false }) => {
    // console.log('closeEvent: ', closeEvent);
    if (closeEvent.type === 'keydown' || closeEvent.type === 'click') {
      return;
    }
    // TODO: are you sure you want to close? In case they had changes and clicked by accident
    if (askIfSure) {
      // console.log('should have asked if sure');
    }
    setCreateOrEditPanel({ open: false });
  };

  const handleCreateRfi = () => {
    // figure out of it's money in our money out
    // parentInfo
    const parentHasParent =
      parentInfo && parentInfo.jrnId && parentInfo.jrnId !== '00000';

    const parentPath = [parentInfo.title];

    // if parentHasParent then we need to get the title of the parent
    if (parentHasParent) {
      const grandParentInfo = _.find(companyProjects, {
        contentId: parentInfo.jrnId,
      });
      if (grandParentInfo) {
        parentPath.unshift(grandParentInfo.title);
      }
    }

    if (contentInfo.type)
      setCreateOrEditPanel({
        open: true,
        relatedItemInfo: contentInfo,
        baseInfo: {
          requestId: uuid(),
          txnDate: moment(),
          dateCreated: moment().toISOString(),
          local: true,
          requestSource: RFI_SOURCES.LEVEL.value,
          idOfRelatedItem: contentInfo.contentId,
          projectId: contentInfo?.jrnId,
          parentPath, // [String!]
        },
      });
  };

  const handleSaveRfis = async ({ listOfRfis: saveThese }) => {
    setShowSaving(true);
    const allRfis = prepRfisForSaving({
      rfisToSave: saveThese,
      companyId: managingCompanyInfo.managingCompanyId,
    });

    try {
      await onAddCompanyRfis({
        listOfRfis: allRfis,
        relatedContentProjectId: contentInfo?.jrnId,
        fromWhichAdminTool,
        includeArchive,
        skipGetCompanyRfisUpdate: !!fromWhichAdminTool,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('contentItem onAddCompanyRfis err: ', err);
    }
    setShowSaving(false);
  };

  const loadExistingRfi = async ({ rfiId }) => {
    setViewOrEditRfiDialog({ requestId: rfiId });
  };

  const isCompanyAdmin =
    managingCompanyInfo && managingCompanyInfo.isCompanyAdmin;
  const isBookkeepingCustomer =
    managingCompanyInfo && managingCompanyInfo.isBookkeepingCustomer;
  const isCompanyBookkeeper =
    managingCompanyInfo && managingCompanyInfo.isCompanyBookkeeper;
  // disabled if they are a bookkeeping customer and not a bookkeeper
  //    or if they are not a company admin
  const allowFor =
    (isBookkeepingCustomer && isCompanyBookkeeper) ||
    (!isBookkeepingCustomer && isCompanyAdmin);
  const disableAccessToIsRecorded = !allowFor;

  const isExpense =
    contentInfo &&
    (contentInfo.type === CONTENT_TYPE.RECEIPT ||
      contentInfo.type === CONTENT_TYPE.BILL);

  let addingUser = null;
  if (contentInfo && contentInfo.creatorId && companyCrew) {
    addingUser = _.find(companyCrew, { userId: contentInfo.creatorId });
  }

  return (
    <Grid
      container
      className={
        showFooter ? classes.mainContainerWithFooter : classes.mainContainer
      }
    >
      <Grid item xs={6}>
        <ContentGallery
          contentPiece={contentInfo}
          glassMagnifier
          showCopyUrl
          compactView
        />
      </Grid>
      <Grid item xs={6} className={classes.rightWrapper}>
        <Grid container>
          <Grid item xs={12} className={classes.infoSection}>
            <span className={classes.label}>Type</span>{' '}
            {contentInfo &&
              contentInfo.type &&
              CONTENT_DEFINITION[contentInfo.type].name}
          </Grid>
          {contentInfo?.type !== CONTENT_TYPE.PDF && (
            <Grid item xs={12} className={classes.infoSection}>
              <span className={classes.label}>Amount</span>{' '}
              {contentInfo && contentInfo.contentStatus === 'refund' && '-'}
              {amount}
            </Grid>
          )}
          {isExpense && (
            <>
              <Grid item xs={6} className={classes.infoSection}>
                <span className={classes.label}>Is this a refund?</span>{' '}
                {contentInfo.contentStatus === 'refund' ? 'Yes' : 'No'}
              </Grid>
              <Grid item xs={6} className={classes.infoSection}>
                <span className={classes.label}>Is this billable?</span>{' '}
                {contentInfo.billable ? 'Yes' : 'No'}
              </Grid>
            </>
          )}
          <Grid item xs={12} className={classes.infoSection}>
            <span className={classes.label}>Added By User</span>{' '}
            {addingUser?.username || ''}
          </Grid>
          <Grid item xs={12} className={classes.infoSection}>
            <span className={classes.label}>From Project</span>{' '}
            {contentInfo?.jrnId && (
              <a
                href={`/projects/${contentInfo.jrnId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="basicStyledLink"
              >
                {parentInfo.title}
              </a>
            )}
          </Grid>
          <Grid item xs={12} className={classes.infoSection}>
            <span className={classes.label}>Date</span>{' '}
            {moment(contentInfo && contentInfo.date).format(
              'MMMM D, YYYY @ hh:mma'
            )}
          </Grid>

          {!!(contentInfo && contentInfo.description) && (
            <Grid
              item
              xs={12}
              className={classes.infoSection}
              style={{ flexDirection: 'column' }}
            >
              <div style={{ marginBottom: 8 }}>
                <span className={classes.label}>Notes / Description</span>
              </div>
              <Grid
                container
                direction="row"
                className={classes.descriptionWrapper}
              >
                <div className="levelParsedHtml">
                  {ReactHtmlParser(contentInfo.description)}
                </div>
              </Grid>
            </Grid>
          )}
          {contentInfo?.labels && (
            <Grid item xs={12} className={classes.infoSection}>
              <span className={classes.label}>Labels</span> {contentInfo.labels}
            </Grid>
          )}
          {contentInfo && !hideAccountingAndRfi && (
            <Grid
              item
              xs={12}
              className={classes.infoSection}
              style={{ flexDirection: 'column' }}
            >
              <div className={classes.label}>Accounting Status</div>{' '}
              <Grid
                container
                style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 8 }}
                direction="column"
              >
                <Grid
                  container
                  item
                  alignItems="center"
                  style={{ minHeight: 42 }}
                >
                  <Grid
                    item
                    style={{ flex: 1, display: 'flex', alignItems: 'center' }}
                  >
                    Recorded in QuickBooks?
                    {isBookkeepingCustomer &&
                      !managingCompanyInfo?.isCompanyBookkeeper &&
                      renderOnlyBookkeeperExplanation()}
                  </Grid>
                  <Grid item style={{ flex: 0 }}>
                    {workflowStageSaving ? (
                      <CircularProgress size={19} color="primary" />
                    ) : (
                      <Checkbox
                        color="primary"
                        edge="end"
                        onChange={() =>
                          updateWorkflowStage({
                            variables: {
                              contentId: contentInfo && contentInfo.contentId,
                              jrnId: contentInfo?.jrnId,
                              workflowStage:
                                contentInfo &&
                                contentInfo.workflowStage ===
                                  WORKFLOW_STAGE.RECORDED
                                  ? WORKFLOW_STAGE.UNRECORDED
                                  : WORKFLOW_STAGE.RECORDED,
                            },
                          })
                        }
                        checked={
                          contentInfo.workflowStage === WORKFLOW_STAGE.RECORDED
                        }
                        disabled={readonly || disableAccessToIsRecorded}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  style={{ minHeight: 42 }}
                >
                  <Grid
                    item
                    style={{ flex: 1, display: 'flex', alignItems: 'center' }}
                  >
                    Requires more information (RFI)?
                    {!managingCompanyInfo?.isCompanyBookkeeper &&
                      renderOnlyBookkeeperExplanation()}
                  </Grid>
                  <Grid item style={{ flex: 0 }}>
                    {hasRfiProduct && (
                      <Checkbox
                        color="primary"
                        edge="end"
                        onChange={() => {
                          handleCreateRfi(contentInfo);
                        }}
                        checked={!!contentInfo.rfiIds?.length}
                        disabled={
                          readonly ||
                          !managingCompanyInfo?.isCompanyBookkeeper ||
                          !!contentInfo.rfiIds?.length
                        }
                      />
                    )}
                    {!hasRfiProduct && workflowStageReasonSaving && (
                      <CircularProgress size={19} color="primary" />
                    )}
                    {!hasRfiProduct && !workflowStageReasonSaving && (
                      <Checkbox
                        color="primary"
                        edge="end"
                        onChange={() => {
                          updateWorkflowStageReason({
                            variables: {
                              contentId: contentInfo?.contentId,
                              jrnId: contentInfo?.jrnId,
                              workflowStageReason:
                                contentInfo?.workflowStageReason ===
                                WORKFLOW_STAGE_REASON.RFI_REQUIRED
                                  ? null
                                  : WORKFLOW_STAGE_REASON.RFI_REQUIRED,
                            },
                          });
                        }}
                        checked={
                          contentInfo?.workflowStageReason ===
                          WORKFLOW_STAGE_REASON.RFI_REQUIRED
                        }
                        disabled={
                          !(
                            managingCompanyInfo &&
                            managingCompanyInfo.isCompanyBookkeeper
                          )
                        }
                      />
                    )}
                  </Grid>
                </Grid>
                {!rfiViewMode && (
                  <RelatedRfisFinancialItems
                    contentInfo={contentInfo}
                    loadExistingRfi={loadExistingRfi}
                  />
                )}
              </Grid>
            </Grid>
          )}
          <Grid
            container
            direction="column"
            className={classes.commentsWrapper}
          >
            <Comments
              comments={(contentInfo && contentInfo.comments.items) || []}
              allUsers={companyCrew}
            />
            {showCommentInput ? (
              <CommentInput
                addCommentAction={onAddComment}
                contentItem={contentInfo}
              />
            ) : (
              <Button onClick={() => setShowCommentInput(true)}>
                + Add comment
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      {(contentInfoLoading || showSaving) && <LoadingCover />}
      <Drawer
        anchor="right"
        open={createOrEditPanel.open}
        onClose={closeEvent =>
          handleDrawerClose({ closeEvent, askIfSure: true })
        }
        PaperProps={{ className: classes.drawerPaper }}
      >
        {createOrEditPanel.baseInfo && (
          <AdminCreateEditRfiForm
            companyCrew={companyCrew}
            onSave={handleSaveRfis}
            onClose={closeEvent =>
              handleDrawerClose({ closeEvent, askIfSure: true })
            }
            onCancel={() => handleDrawerClose({ askIfSure: true })}
            adminMode={false}
            managingCompanyId={managingCompanyInfo.managingCompanyId}
            userInfo={userInfo}
            relatedItemInfo={createOrEditPanel.relatedItemInfo}
            baseRfiInfo={createOrEditPanel.baseInfo}
          />
        )}
      </Drawer>

      <ViewOrEditRfi
        open={!!viewOrEditRfiDialog.requestId}
        requestId={viewOrEditRfiDialog.requestId}
        onClose={() => {
          setViewOrEditRfiDialog({ requestId: null });
        }}
        fromWhichAdminTool={fromWhichAdminTool}
        skipGetCompanyRfisUpdate
      />
      {showFooter && (
        <div className={classes.footer}>
          <Grid container>
            <Grid container item style={{ flex: 1 }} />
            {getNextItem && (
              <Grid item container justifyContent="center" style={{ flex: 3 }}>
                <Button
                  onClick={() => {
                    getNextItem('previous');
                  }}
                  variant="contained"
                  startIcon={<ArrowBackIcon />}
                  style={{ marginRight: 8 }}
                >
                  Previous Item
                </Button>
                <Button
                  onClick={() => {
                    getNextItem('next');
                  }}
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                >
                  Next Item
                </Button>
              </Grid>
            )}
            {(onEditButtonClick || !hideDeleteButton) && (
              <Grid
                container
                item
                justifyContent="flex-end"
                style={{ flex: 1 }}
              >
                {!hideDeleteButton && (
                  <Button
                    onClick={onDeleteButtonClick}
                    variant="contained"
                    style={{
                      color: 'white',
                      backgroundColor: palette.brandColorError,
                    }}
                  >
                    Delete Item
                  </Button>
                )}
                <Button
                  onClick={onEditButtonClick}
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 16 }}
                >
                  Edit Item
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </Grid>
  );
};

const mapStateToProps = state => ({
  userId: state.userInfo && state.userInfo.userId,
  userInfo: state.userInfo,
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default compose(
  GetCompanyCrewAction,
  CreateCommentMutationAction,
  GetContentByIdAction,
  AddCompanyRfisAction,
  ListCompanyProjectsAction,
  withApollo
)(connect(mapStateToProps)(FinancialItemAdminView));
