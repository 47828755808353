import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { v4 as uuid } from 'uuid';
import { Grid, Button, Typography } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import QboCustomerSearch from '../qbo-customer-search';
import GraphQL from '../../graphql';
import LoadingCover from '../LoadingCover/loadingCover';

function AddCustomerFromQbo({
  managingCompanyInfo,
  enqueueSnackbar,
  handleClose,
  onAddCustomer,
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [customerForImport, setCustomerForImport] = useState(null);

  const handleImport = async () => {
    const customerToAdd = { ...customerForImport };

    // ID is QBO id for queried object
    // Reassign customerId to new uuid, and set qboCustomerId
    const qboCustomerId = customerToAdd.customerId;
    customerToAdd.customerId = uuid();
    customerToAdd.qboCustomerId = qboCustomerId; // Backend will add the realm to this
    customerToAdd.managingCompanyId =
      managingCompanyInfo && managingCompanyInfo.managingCompanyId;

    try {
      setIsProcessing(true);
      await onAddCustomer(customerToAdd);
      handleClose(customerToAdd);
    } catch (err) {
      let errorMessage;
      if (
        err.graphQLErrors &&
        err.graphQLErrors[0] &&
        err.graphQLErrors[0].errorType === 'ALREADY_SYNCED'
      ) {
        errorMessage =
          'Sorry! It seems like that customer has already been synced.';
      } else {
        errorMessage =
          "Sorry! We weren't able to import that customer. Please try again later.";
      }

      enqueueSnackbar(errorMessage, {
        variant: 'error',
        autoHideDuration: 3000,
      });

      handleClose();
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body1">
          Create a customer by importing a customer that already exists in your
          QuickBooks Online account! Simply search for the QuickBooks Online
          customer by their display name, select the customer in the list below,
          then click &quot;Import&quot;.
        </Typography>
      </Grid>
      <Grid item>
        <QboCustomerSearch
          initialValue=""
          handleSelection={setCustomerForImport}
        />
      </Grid>
      <Grid container item justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handleImport}
            disabled={customerForImport === null}
            variant="contained"
            color="primary"
          >
            Import
          </Button>
        </Grid>
      </Grid>
      {isProcessing && <LoadingCover />}
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    managingCompanyInfo: state.appState.managingCompanyInfo,
  };
}

export default compose(GraphQL.AddCustomerAction)(
  connect(mapStateToProps)(withSnackbar(AddCustomerFromQbo))
);
