import numeral from 'numeral';

export const startsWithFilter = {
  name: 'Starts With',
  fn: ({ value, filterValue }) => {
    // Regex to allow negative, numbers and decimal point
    const regExToUse = /[^-0-9.a-zA-Z]/g;

    // Clean dollar signs and any non-relevant characters from string
    const cleanedFilterString = filterValue.toString().replace(regExToUse, '');
    if (!cleanedFilterString) {
      return true;
    }

    // Convert value to string with two decimal points
    const cleanedTestString = numeral(value).format('0.00');

    // Once cleaned, convert to float
    const cleanedFilterValue = parseFloat(cleanedFilterString);

    // If cannot convert to float, return false
    if (Number.isNaN(cleanedFilterValue)) {
      if (cleanedFilterString === '-') {
        // User is about to type a negative number
        return cleanedTestString.startsWith('-');
      }
      return false;
    }

    // if filterValue is negative search only negative values
    //  otherwise search both positive and negative values
    const valueIsNegative = value < 0;
    const filterValueIsNegative = cleanedFilterValue < 0;

    let testStringToSearch = cleanedTestString;
    if (valueIsNegative && !filterValueIsNegative) {
      // If value is negative and filterValue is positive, search only negative values
      testStringToSearch = testStringToSearch.replace('-', '');
    }

    if (!cleanedTestString) {
      // Safeguard unexpected result
      return false;
    }

    // Check if test string includes the search
    return testStringToSearch.startsWith(cleanedFilterString);
  },
};
export const lessThanFilter = {
  name: 'Less Than',
  fn: ({ value, filterValue }) => {
    if (filterValue === null) {
      return true;
    }
    return value < filterValue;
  },
};

export const greaterThanFilter = {
  name: 'Greater Than',
  fn: ({ value, filterValue }) => {
    if (filterValue === null) {
      return true;
    }
    return value > filterValue;
  },
};

export const amountFilter = {
  amount: {
    name: 'amount',
    operators: [startsWithFilter, lessThanFilter, greaterThanFilter],
  },
};

export const simpleSortForAmount = (a, b) => {
  return a - b;
};
