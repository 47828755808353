import React from 'react';
import _ from 'lodash';
import ReactDataGrid from '@inovua/reactdatagrid-community';

const QboExistingCustomerTable = ({
  noDataMessage,
  searchResults,
  setSelectedRemoteCustomer,
  isSubcustomerList,
}) => {
  let dataSource = [];
  const customerColumns = [
    {
      name: 'firstName',
      header: 'First Name',
      defaultFlex: 2,
    },
    {
      name: 'lastName',
      header: 'Last Name',
      defaultFlex: 2,
    },
    {
      name: 'companyName',
      header: 'Company Name',
      defaultFlex: 3,
    },
    {
      name: 'email',
      header: 'Email',
      defaultFlex: 3,
    },
    {
      name: 'phoneNumber',
      header: 'Phone Number',
      defaultFlex: 2,
    },
  ];

  const subcustomerColumns = [
    {
      name: 'title',
      header: 'Title',
      defaultFlex: 2,
    },
    {
      name: 'address',
      header: 'Address',
      defaultFlex: 2,
    },
  ];

  if (searchResults) {
    dataSource = searchResults;
  }

  const handleRowChange = ({ selected: selectedCustomerId }) => {
    let remoteCustomer;
    if (isSubcustomerList) {
      remoteCustomer = _.find(
        searchResults,
        ({ contentId }) => contentId === selectedCustomerId
      );
    } else {
      remoteCustomer = _.find(
        searchResults,
        ({ customerId }) => customerId === selectedCustomerId
      );
    }

    setSelectedRemoteCustomer(remoteCustomer || null);
  };

  return (
    <ReactDataGrid
      columns={isSubcustomerList ? subcustomerColumns : customerColumns}
      dataSource={dataSource}
      emptyText={noDataMessage}
      idProperty={isSubcustomerList ? 'contentId' : 'customerId'}
      enableSelection
      toggleRowSelectOnClick
      onSelectionChange={handleRowChange}
      rowProps={{ style: { cursor: 'pointer' } }}
    />
  );
};

export default QboExistingCustomerTable;
