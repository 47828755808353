import React, { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
} from '@material-ui/core';
import { CancelOutlined as CancelOutlinedIcon } from '@material-ui/icons';
import { withSnackbar } from 'notistack';
import numeral from 'numeral';
import _ from 'lodash';
import ContentLoader from 'react-content-loader';

import SplitFinancialItemList from '../split-financial-item-list';
import ChooseProjectWithSubs from '../choose-project-dialog/choose-project-with-subs';
import palette from '../../theme/palette';
import LoadingCover from '../LoadingCover/loadingCover';
import { calculateUnallocatedAmount } from '../../helpers/split-item/split-item.util';

const ExpenseProjectSplitter = ({
  amount = null,
  projectList,
  setProjectList,
  invalidFieldMap = null,
  setInvalidFieldMap = null,
  chillMode = false, // Chill mode ignores any amount validation limits and does not show split details
  showLoader = false,
  headingClass = null,
  // HOCs
  enqueueSnackbar,
  disabled = false,
}) => {
  const [showChooseProjectDialog, setShowChooseProjectDialog] = useState(false);

  const amountAsFloat = parseFloat(amount?.value || 0);

  // On change in amount, update the project list if only one project is set and update the unallocated amount
  useEffect(() => {
    if (
      projectList &&
      projectList.length === 1 &&
      !projectList[0].existingContentInfo &&
      amount !== null
    ) {
      // Update if new and amount changes
      setProjectList([
        {
          ...projectList[0],
          amount: numeral(amountAsFloat).format('0.00'),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const visibleProjectList = useMemo(() => {
    return _.filter(projectList, project => {
      return !project.deletionPending;
    });
  }, [projectList]);

  const { unappliedAmountString, unappliedAmountStyle } = useMemo(() => {
    const unallocatedAmount = calculateUnallocatedAmount({
      totalAmount: amountAsFloat,
      contentList: visibleProjectList,
    });

    const unallocatedAmountString = numeral(unallocatedAmount).format('$0.00');

    let amountStyle = {
      color: amountAsFloat !== 0 ? palette.brandColorGreen : 'inherit',
    };
    if (unallocatedAmount < 0) {
      amountStyle = {
        fontWeight: 'bold',
        color: palette.brandColorError,
      };
    } else if (unallocatedAmount > 0) {
      amountStyle = {
        fontWeight: 'bold',
        color: palette.brandColorOrange,
      };
    }

    return {
      unappliedAmountString: unallocatedAmountString,
      unappliedAmountStyle: amountStyle,
    };
  }, [amountAsFloat, visibleProjectList]);

  const handleAddButtonClick = () => {
    setShowChooseProjectDialog(true);
  };

  const handleProjectSelected = newProjectInfo => {
    const newProjectList = [...projectList];

    setShowChooseProjectDialog(false);

    const existingProjectIndex = _.findIndex(newProjectList, ({ project }) => {
      return project.contentId === newProjectInfo.project.contentId;
    });

    if (existingProjectIndex >= 0) {
      // if it is pending deletion, remove the deletion flag
      if (newProjectList[existingProjectIndex].deletionPending) {
        newProjectList[existingProjectIndex].deletionPending = false;
        // set the amount to empty
        newProjectList[existingProjectIndex].amount = '';
      } else {
        enqueueSnackbar('The selected project was already in the list', {
          variant: 'warning',
          autoHideDuration: 2000,
        });
        return;
      }
    } else {
      newProjectList.push({
        project: newProjectInfo.project,
        projectPath: newProjectInfo.path,
        amount: '',
        description: '',
        existingContentInfo: null,
        billable: false,
      });
    }

    setProjectList(newProjectList);
  };

  const handleProjectDelete = index => {
    const projectIdToDelete = visibleProjectList[index].project?.contentId;
    setProjectList(currentState => {
      const newProjectList = [...currentState];

      const projectIndex = _.findIndex(newProjectList, ({ project }) => {
        return project.contentId === projectIdToDelete;
      });

      if (
        newProjectList[projectIndex] &&
        newProjectList[projectIndex].existingContentInfo
      ) {
        newProjectList[projectIndex].deletionPending = true;
      } else if (newProjectList[projectIndex]) {
        newProjectList.splice(projectIndex, 1);
      }

      return newProjectList;
    });
  };

  const handleFieldChange = (value, fieldName, index) => {
    const projectIdToChange = visibleProjectList[index].project?.contentId;

    setProjectList(currentState => {
      const newProjectList = [...currentState];
      const projectIndex = _.findIndex(newProjectList, ({ project }) => {
        return project.contentId === projectIdToChange;
      });
      if (newProjectList[projectIndex]) {
        newProjectList[projectIndex][fieldName] = value;
      }
      return newProjectList;
    });
  };

  return (
    <Grid item style={{ paddingTop: 16 }}>
      <Grid container>
        <Grid item style={{ flex: 1 }}>
          <Typography
            color="primary"
            variant="h6"
            className={headingClass || null}
          >
            Which projects does this apply to?
          </Typography>
        </Grid>
        {!chillMode && (
          <Grid item>
            <Typography variant="h6" style={unappliedAmountStyle}>
              Unapplied Amount: {unappliedAmountString}
            </Typography>
          </Grid>
        )}
      </Grid>
      {showLoader && (
        <ContentLoader
          viewBox="0 0 100 10"
          width="100%"
          style={{ paddingTop: 16, paddingBottom: 16 }}
        >
          <rect x="0" y="0" width="100" height="10" />
        </ContentLoader>
      )}
      {!showLoader && projectList && (
        <SplitFinancialItemList
          itemList={visibleProjectList}
          disabled={disabled}
          onAddButtonClick={handleAddButtonClick}
          onFieldChange={handleFieldChange}
          onDeleteButtonClick={handleProjectDelete}
          hideDetails={chillMode}
          hideTotal
          listStyle={{ paddingLeft: 0, paddingRight: 0 }}
          allowDeleteExisting={chillMode || visibleProjectList.length > 1}
          invalidAmountMap={invalidFieldMap?.amountMap}
          setInvalidAmountMap={newInvalidAmountMap => {
            if (setInvalidFieldMap) {
              setInvalidFieldMap(currentState => {
                return {
                  ...currentState,
                  amountMap: newInvalidAmountMap,
                };
              });
            }
          }}
        />
      )}
      {!projectList && <LoadingCover />}
      <Dialog
        maxWidth="lg"
        fullWidth
        open={showChooseProjectDialog}
        PaperProps={{ style: { minHeight: '80%' } }}
      >
        <DialogTitle
          onClose={() => {
            setShowChooseProjectDialog(false);
          }}
        >
          <div>
            <Typography
              variant="h2"
              gutterBottom
              align="center"
              color="primary"
            >
              Choose another project:
            </Typography>
          </div>
          <IconButton
            onClick={() => setShowChooseProjectDialog(false)}
            style={{ position: 'absolute', right: 0, top: 0, fontSize: 40 }}
          >
            <CancelOutlinedIcon fontSize="inherit" color="primary" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ChooseProjectWithSubs setSelectedProject={handleProjectSelected} />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default withSnackbar(ExpenseProjectSplitter);
