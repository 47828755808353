import {
  createStore,
  // compose
} from 'redux';
import {
  persistStore,
  persistReducer,
  // persistCombineReducers,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native

import rootReducer from '../reducers';

const persistConfig = {
  timeout: 10000,
  key: 'root',
  version: 0,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
/* eslint-disable no-underscore-dangle */
const store = createStore(
  persistedReducer /* preloadedState, */,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
/* eslint-enable */

export default store;
export const persistor = persistStore(store);
