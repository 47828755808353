import React, { useEffect, useState, useRef } from 'react';

import { CircularProgress, Dialog, Grid, Paper } from '@material-ui/core';
import { GoogleApiWrapper } from 'google-maps-react';
import _ from 'lodash';
import { useQuery } from 'react-apollo-hooks';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';

import GetRequestKeyQuery from '../../graphql/queries/get-request-key';
import GetMyUserInfo from '../../graphql/queries/GetUserInfo';
import GetCompanyById from '../../graphql/queries/get-company-by-id';

import {
  determineManagingCompanyInfo,
  isDefaultCompanyName,
  isDefaultUsername,
} from '../../helpers';
import OnboardingCompanyInfoStep from './onboarding-company-info-step';
import OnboardingCongratsOnPurchase from './onboarding-congrats-on-purchase';
import OnboardingFinalMobileStep from './onboarding-final-mobile-step';
import OnboardingLoading from './onboarding-loading';
import OnboardingSelectProductsStep from './onboarding-select-products-step';
import OnboardingUserProfileStep from './onboarding-user-profile-step';
import OnboardingWelcomeStep from './onboarding-welcome-step';
import OnboardingChooseCompanyTypeStep from './onboarding-choose-company-type';
import { COMPANY_TYPE, ONBOARDING_STEP } from './onboarding.constants';
import OnboardingContext from './onboarding.context';
import { useStyles } from './onboarding.styles';
import { PRODUCT } from '../../config/appDefaults';

const Onboarding = ({ dispatch, companyRegistrationKey }) => {
  const classes = useStyles();

  const initialCompanyInfoDeterminedRef = useRef(false);

  const [steps, setSteps] = useState([ONBOARDING_STEP.WELCOME_TO_LEVEL]);
  const [stepsHaveBeenSet, setStepsHaveBeenSet] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedCompanyType, setSelectedCompanyType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [companyId, setCompanyId] = useState(null);

  const requestKeyResponse = useQuery(GetRequestKeyQuery, {
    variables: { requestKey: companyRegistrationKey },
    skip: !companyRegistrationKey,
  });
  const {
    loading: userInfoLoading,
    data: userInfoData,
    refetch: getMyUserInfoRefetch,
  } = useQuery(GetMyUserInfo, {
    variables: { userId: 'willBePulledFromCognitoSubContentInResolver' },
    fetchPolicy: 'network-only',
  });
  const userInfo = _.get(userInfoData, 'getMyUserInfo', null);

  useEffect(() => {
    if (userInfo) {
      if (userInfo.managingFirmId) {
        setCompanyId(userInfo.managingFirmId);
      } else if (userInfo.companies?.[0]) {
        setCompanyId(userInfo.companies[0]);
      }
    }
  }, [userInfo]);

  const { refetch: getCompanyByIdRefetch, data: getCompanyByIdData } = useQuery(
    GetCompanyById,
    {
      skip: !companyId,
      variables: { companyId },
      fetchPolicy: 'network-only',
    }
  );

  const companyInfo = _.get(getCompanyByIdData, 'getCompanyById', null);

  useEffect(() => {
    if (!stepsHaveBeenSet && companyInfo) {
      // We have everything we need to understand the context
      initialCompanyInfoDeterminedRef.current = true;

      const newSteps = [...steps];

      const hasProjectManagementProduct = _.includes(
        companyInfo.products,
        PRODUCT.PROJECT_MANAGEMENT
      );
      const isCompanyOwner = companyInfo.owners?.includes(userInfo.userId);
      const isCompanyBookkeeper =
        companyInfo.admins?.includes(userInfo.userId) &&
        userInfo.type === 'bookkeeper';

      if (hasProjectManagementProduct) {
        // User has project management product, add update profile info step if needed
        const hasNotFilledInPersonal =
          !userInfo.username ||
          isDefaultUsername(userInfo.username) ||
          !userInfo.firstName ||
          !userInfo.lastName;

        if (hasNotFilledInPersonal) {
          newSteps.push(ONBOARDING_STEP.UPDATE_PROFILE_INFO);
        }
      }

      if (
        (isCompanyOwner || isCompanyBookkeeper) &&
        isDefaultCompanyName(companyInfo.companyName)
      ) {
        newSteps.push(ONBOARDING_STEP.CHOOSE_COMPANY_TYPE);
        newSteps.push(ONBOARDING_STEP.ENTER_COMPANY_INFO);
      }

      if (isMobile && hasProjectManagementProduct) {
        newSteps.push(ONBOARDING_STEP.FINAL_IS_MOBILE);
      }

      // Finish the onboarding setup
      setSteps(newSteps);
      setStepsHaveBeenSet(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, companyInfo]);

  const isCopilot =
    !!userInfo?.managingFirmId || !!companyInfo?.subscriptionStatus;

  const onOnboardingComplete = async () => {
    setLoading(true);

    const { data: userInfoRefetchResult } = await getMyUserInfoRefetch();
    const latestUserInfo = _.get(userInfoRefetchResult, 'getMyUserInfo');

    let companyIdToGet = null;
    let isFirm = false;
    if (latestUserInfo.managingFirmId) {
      isFirm = true;
      companyIdToGet = latestUserInfo.managingFirmId;
    } else if (latestUserInfo.companies?.[0]) {
      [companyIdToGet] = latestUserInfo.companies;
    }

    let managingCompanyInfo = null;
    let firmInfo = null;
    if (companyIdToGet) {
      const {
        data: getCompanyByIdRefetchResult,
      } = await getCompanyByIdRefetch();
      const latestCompany = _.get(
        getCompanyByIdRefetchResult,
        'getCompanyById'
      );

      if (isFirm) {
        firmInfo = { ...latestCompany };
      } else {
        managingCompanyInfo = determineManagingCompanyInfo({
          companyInfo: latestCompany,
          userInfo: latestUserInfo,
        });
      }
    }

    // Update the redux store for user and company info
    dispatch({
      type: 'ONBOARDING_COMPLETE',
      payload: {
        userInfo: latestUserInfo,
        managingCompanyInfo,
        firmInfo,
      },
    });

    setLoading(false);
  };

  let stepComponent;
  switch (steps[activeStep]) {
    case ONBOARDING_STEP.WELCOME_TO_LEVEL:
      stepComponent = <OnboardingWelcomeStep />;
      break;
    case ONBOARDING_STEP.UPDATE_PROFILE_INFO:
      stepComponent = (
        <OnboardingUserProfileStep
          userInfo={userInfo}
          userInfoLoading={userInfoLoading}
          setLoading={setLoading}
        />
      );
      break;
    case ONBOARDING_STEP.CHOOSE_COMPANY_TYPE:
      stepComponent = (
        <OnboardingChooseCompanyTypeStep
          selectedCompanyType={selectedCompanyType}
          setSelectedCompanyType={setSelectedCompanyType}
        />
      );
      break;
    case ONBOARDING_STEP.ENTER_COMPANY_INFO:
      stepComponent = (
        <OnboardingCompanyInfoStep
          companyInfo={companyInfo}
          companyType={selectedCompanyType}
          setLoading={setLoading}
          isCopilot={isCopilot}
        />
      );
      break;
    case ONBOARDING_STEP.SELECT_PRODUCTS:
      stepComponent = (
        <OnboardingSelectProductsStep
          companyInfo={companyInfo}
          setLoading={setLoading}
        />
      );
      break;
    case ONBOARDING_STEP.FINAL_IS_MOBILE:
      stepComponent = <OnboardingFinalMobileStep userInfo={userInfo} />;
      break;
    case ONBOARDING_STEP.CONGRATS_ON_PURCHASE:
      stepComponent = (
        <OnboardingCongratsOnPurchase
          onOnboardingComplete={onOnboardingComplete}
        />
      );
      break;

    default:
      stepComponent = <OnboardingLoading />;
  }

  // What to do at end of onboarding workflow
  if (stepsHaveBeenSet && activeStep === steps.length) {
    let whereToGo = '';
    if (selectedCompanyType === COMPANY_TYPE.FIRM) {
      whereToGo = '/firm';
    } else if (isCopilot) {
      whereToGo = '/alerts';
    } else {
      whereToGo = '/projects';
      if (_.includes(steps, ONBOARDING_STEP.ENTER_COMPANY_INFO)) {
        whereToGo += '?from-company-onboarding=true';
      }
    }

    return <Redirect to={whereToGo} />;
  }

  const showInitialLoading =
    !initialCompanyInfoDeterminedRef.current || requestKeyResponse.loading;

  return (
    <OnboardingContext.Provider
      value={{ activeStep, steps, setActiveStep, onOnboardingComplete }}
    >
      <Grid container className={classes.root}>
        <Dialog fullScreen open>
          <Grid container item className={classes.container}>
            <div className={classes.backgroundImage} />

            {(showInitialLoading || loading) && (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                className={classes.loader}
              >
                <CircularProgress color="inherit" />
              </Grid>
            )}

            {!showInitialLoading && userInfo && !!steps.length && (
              <Grid
                container
                direction="column"
                justifyContent="center"
                item
                xs={12}
                className={classes.onboardingContainer}
                elevation={20}
                component={Paper}
              >
                <Grid container className={classes.stepContainer}>
                  {stepComponent}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Dialog>
      </Grid>
    </OnboardingContext.Provider>
  );
};

const mapStateToProps = state => {
  return {
    companyRegistrationKey: state.appState.companyRegistrationKey || null,
    changeFilenamesOnUpload: state.appState.changeFilenamesOnUpload || false,
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
  };
};

export default compose(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    LoadingContainer: () => <div />, // Hide "Loading..." message
  }),
  connect(mapStateToProps)
)(Onboarding);
