import palette from '../../theme/palette';

export default theme => {
  return {
    optionsBar: {
      marginBottom: 8,
      background: '#fff',
      paddingLeft: 8,
      paddingRight: 8,
    },
    optionsBarInnerLeft: {
      padding: 8,
    },
    settingsButton: {
      background: palette.background.alt,
      color: '#666',
      '&:hover': {
        background: palette.background.alt,
      },
      borderRadius: 20,
      padding: '8px 12px',
    },
    dashboardInner: {
      backgroundColor: '#fff',
      padding: 16,
      justifyContent: 'center',
    },
    dayStatsWrapper: {
      marginBottom: 32,
      padding: 16,
      boxShadow: '0 0 10px 2px #ccc',
      borderRadius: 20,
    },
    viewDetailsButton: {
      padding: '8px 12px',
      borderRadius: 20,
      background: palette.background.alt,
      '&:hover': {
        background: '#eee',
      },
    },
    alertContainer: {
      marginBottom: 16,
      paddingBottom: 32,
      borderBottom: '1px solid #ccc',
    },
    alertListItem: {
      listStyleType: 'none',
      marginBottom: 3,
    },
    table: {
      minWidth: 650,
    },
    headerContainer: {
      paddingTop: 16,
      paddingLeft: 16,
      paddingBottom: 32,
    },
    optionsHeaderWrapper: {
      padding: 16,
      borderBottom: '1px solid #e0e0e0',
    },
    optionsHeader: {
      color: theme.palette.brandColorPrimary,
      fontWeight: 'bold',
      fontSize: '120%',
    },
    optionsBody: {
      padding: 16,
    },
    optionsSection: {
      marginBottom: 16,
    },
    switchBase: {
      color: '#eee',
      '&$checked': {
        color: theme.palette.brandColorPrimary,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.brandColorPrimary60,
      },
    },
    thumb: {},
    track: {},
    checked: {},
    listHeader: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      paddingTop: 16,
      paddingRight: 16,
      paddingBottom: 16,
      borderBottom: '1px solid #e0e0e0',
      color: theme.palette.brandColorPrimary,
      fontWeight: 'bold',
      fontSize: '120%',
      flexWrap: 'nowrap',
      zIndex: 1,
      left: 0,
      boxShadow: '0px 2px 7px 0px #ededed',
      minWidth: '100%',
    },
    listSubheader: {
      width: '100%',
      paddingTop: 16,
      paddingRight: 16,
      paddingBottom: 16,
      paddingLeft: 16,
      borderBottom: '1px solid #e0e0e0',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      fontSize: '110%',
      background: '#fff',
    },
    alertRow: ({ hasSubs }) => {
      const toReturn = {
        width: '100%',
        paddingTop: 8,
        paddingRight: 0,
        paddingBottom: 8,
        paddingLeft: 24,
        borderBottom: '1px solid #e0e0e0',
        minHeight: 40,
      };

      if (!hasSubs) {
        toReturn['&:hover'] = {
          backgroundColor: theme.palette.background.alt,
        };
      }

      return toReturn;
    },
    toggleRow: {
      flexWrap: 'nowrap',
      minWidth: '100%',
      justifyContent: 'space-between',
      backgroundColor: '#fff',
    },
    subWrapper: {
      minHeight: 40,
      '&:hover': {
        backgroundColor: theme.palette.background.alt,
      },
    },

    noSubsRowWrapper: {
      flexWrap: 'nowrap',
      minWidth: '100%',
      backgroundColor: '#fff',
    },
    hasSubsRowWrapper: {
      marginTop: 0,
      marginBottom: 0,
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      backgroundColor: '#fff',
    },
    alertName: {
      width: '100%',
      color: theme.palette.brandColorPrimary,
    },
    newIndicator: {
      background: theme.palette.brandColorGreenDark,
      color: '#fff',
      padding: '4px 8px',
      borderRadius: 10,
      marginRight: -16,
      transform: 'translate(0, 1.5px) scale(0.55)',
    },
    alertTitle: {
      fontWeight: 'bold',
    },
    alertDescription: {
      width: '100%',
      fontSize: '75%',
      fontStyle: 'italic',
      color: '#333',
      lineHeight: '1.5em',
    },
    alertPreferencesLabel: {
      fontSize: '75%',
      color: '#333',
      lineHeight: '1.5em',
      width: '100%',
    },
    alertPreferencesValue: {
      fontSize: '75%',
      color: '#333',
      lineHeight: '1.5em',
      fontWeight: 'bold',
    },
    alertPreferencesValueNote: {
      fontSize: 10,
      color: '#333',
      lineHeight: '1.5em',
      fontStyle: 'italic',
      fontWeight: 'normal',
    },
    subDescription: {
      width: '100%',
      fontSize: '85%',
      color: '#333',
      lineHeight: '1.5em',
      paddingLeft: 4,
    },
    accordionSummaryContent: {
      display: 'flex',
      flexDirection: 'row-reverse', // Reverses the row direction
      alignItems: 'center',
      width: '100%',
    },
    expandIcon: {
      order: -1, // Moves the expand icon to the beginning
      marginRight: theme.spacing(1),
    },
    accordionRoot: {
      marginTop: 0,
    },
    accordionExpanded: {
      marginTop: '0 !important',
    },
    header: {
      width: '100%',
      padding: theme.spacing(2),
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      alignItems: 'center',
      backgroundColor: '#fff',
      boxShadow: '0 2px 4px 0px #ccc',
      borderBottom: '1px solid #e0e0e0',
      borderRadius: '0 0 20px 20px',
      zIndex: 10,
    },
    settingsHeader: {
      width: '100%',
      padding: theme.spacing(2),
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      alignItems: 'center',
      zIndex: 10,
    },
    headerWarning: {
      width: '100%',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      alignItems: 'center',
      backgroundColor: palette.brandColorOrange,
      marginTop: 8,
    },
    backButton: {
      marginLeft: 8,
      marginRight: 8,
    },
    card: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      minHeight: 300,
    },
    printButton: {
      position: 'fixed',
      top: 144,
      right: 20,
    },
  };
};
