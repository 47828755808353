import project from './project.contentinfo';
import lead from './lead.contentinfo';
import conversation from './conversation.contentinfo';
import gallery from './gallery.contentinfo';
import video from './video.contentinfo';
import image from './image.contentinfo';
import receipt from './receipt.contentinfo';
import globalReceipt from './global-receipt.contentinfo';
import bill from './bill.contentinfo';
import globalBill from './global-bill.contentinfo';
import globalPayment from './global-payment.contentinfo';
import invoice from './invoice.contentinfo';
import payment from './payment.contentinfo';
import textnote from './textnote.contentinfo';
import task from './task.contentinfo';
import punchlistItem from './punchlist.contentinfo';
import timetracking from './timetracking.contentinfo';
import shift from './shift.contentinfo';
import pdf from './pdf.contentinfo';
import template from './template.contentinfo';
import projectFromTemplate from './project-from-template.contentinfo';

export const typesAndSchema = {
  project,
  lead,
  conversation,
  gallery,
  video,
  image,
  receipt,
  globalReceipt,
  bill,
  globalBill,
  globalPayment,
  invoice,
  payment,
  textnote,
  task,
  punchlistItem,
  timetracking,
  shift,
  pdf,
  template,
  projectFromTemplate,
};
