import React, { useState, useRef } from 'react';
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';
import { HighlightOff, PlaylistAdd, Remove } from '@material-ui/icons';

import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import RenameGroupInput from './bookkeeping-alerts-settings-rename-group-input';
import AddRecipientInput from './bookkeeping-alerts-settings-recipient-input';
import LevelButtonSegment from '../../level-button-segment/level-button-segment';
import palette from '../../../theme/palette';

const useStyles = makeStyles(() => ({
  listItem: {
    paddingTop: 12,
    paddingBottom: 12,
    borderBottom: '1px solid #ddd',
    '&:hover': {
      background: 'rgba(0,0,0,0.02)',
    },
  },
}));

const ManageEmailList = ({
  listOfEmailLists,
  initialListId,
  toClose,
  onEmailListsChange,
  emptyMessage,
}) => {
  const classes = useStyles();
  const [internalEmailLists, setInternalEmailLists] = useState([
    ...listOfEmailLists,
  ]);
  const [groupNameError, setGroupNameError] = useState({});

  const [tabIndexValue, setTabIndexValue] = useState(() => {
    if (initialListId) {
      return _.findIndex(internalEmailLists, { listId: initialListId });
    }

    return 0;
  });

  const tempEmailInputMapRef = useRef({});

  const attemptAddEmail = ({ emailToAdd }) => {
    setInternalEmailLists(currentInternalEmailLists => {
      tempEmailInputMapRef.current = {
        ...tempEmailInputMapRef.current,
        [currentInternalEmailLists[tabIndexValue].listId]: '',
      };

      return _.map(currentInternalEmailLists, (list, index) => {
        if (index === tabIndexValue) {
          const uniqueEmails = new Set([...list.recipientEmails, emailToAdd]);
          return {
            ...list,
            recipientEmails: Array.from(uniqueEmails),
          };
        }
        return list;
      });
    });
  };

  const addGroup = () => {
    const newList = {
      listId: uuid(),
      listName: 'New Group',
      recipientEmails: [],
      initializeFromRules: false,
    };

    setInternalEmailLists(currentInternalEmailLists => [
      ...currentInternalEmailLists,
      newList,
    ]);

    setTabIndexValue(internalEmailLists.length);
  };

  const removeGroup = () => {
    // remove the list at the current tab index
    setInternalEmailLists(currentInternalEmailLists => {
      return _.filter(currentInternalEmailLists, (list, index) => {
        return index !== tabIndexValue;
      });
    });

    setTabIndexValue(currentTabIndexValue => {
      // set the tab index to the tab before the one just deleted unless it was the first tab, then set it to the first tab
      if (currentTabIndexValue === 0) {
        return 0;
      }

      return currentTabIndexValue - 1;
    });
  };

  const handleSaveAndClose = async () => {
    // check for any empty group names and set the tab index to that group
    for (let i = 0; i < internalEmailLists.length; i += 1) {
      const groupName = _.trim(internalEmailLists[i].listName);
      if (!groupName) {
        setTabIndexValue(i);
        return;
      }
    }

    await onEmailListsChange({ newEmailLists: internalEmailLists });
    toClose();
  };

  const handleTabIndexChange = (event, newValue) => {
    setTabIndexValue(newValue);
  };
  const renderEmailListTabs = () => {
    const TabPanel = props => {
      const { children, value, index, ...other } = props;

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`scrollable-auto-tabpanel-${index}`}
          aria-labelledby={`scrollable-auto-tab-${index}`}
          {...other}
        >
          {value === index && children}
        </div>
      );
    };

    const handleRenameGroup = ({ listId, newName }) => {
      const trimmedName = _.trim(newName);

      setInternalEmailLists(currentInternalEmailLists => {
        return _.map(currentInternalEmailLists, list => {
          if (list.listId === listId) {
            return {
              ...list,
              listName: trimmedName,
            };
          }
          return list;
        });
      });

      setGroupNameError(currentState => ({
        ...currentState,
        [listId]: trimmedName ? '' : 'Group name is required',
      }));
    };

    const handleMatchEnabledRulesChange = ({ listId, value }) => {
      setInternalEmailLists(currentInternalEmailLists => {
        return _.map(currentInternalEmailLists, list => {
          if (list.listId === listId) {
            return {
              ...list,
              initializeFromRules: value,
            };
          }
          return list;
        });
      });
    };

    const isEmpty = !internalEmailLists?.length;

    return (
      <Grid container id="bka-email-lists-wrapper">
        <Grid
          item
          xs={12}
          style={{
            border: '1px solid #ccc',
            marginBottom: 16,
            background: '#f7f7f7',
          }}
        >
          {isEmpty && (
            <Typography variant="body2" style={{ padding: 16 }}>
              No recipient groups found
            </Typography>
          )}
          {!isEmpty && (
            <Tabs
              value={tabIndexValue}
              onChange={handleTabIndexChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="on"
              aria-label="scrollable auto tabs example"
            >
              {_.map(internalEmailLists, list => {
                return <Tab key={list.listId} label={list.listName} />;
              })}
            </Tabs>
          )}
        </Grid>
        {isEmpty && (
          <Grid item style={{ paddingLeft: 16, paddingRight: 16 }}>
            <Typography variant="body2">
              Select Add Group to create a recipient group
            </Typography>
          </Grid>
        )}
        {_.map(internalEmailLists, (list, key) => {
          return (
            <TabPanel
              key={list.listId}
              value={tabIndexValue}
              index={key}
              style={{ padding: '0 16px 16px 16px', width: '100%' }}
            >
              <Grid container direction="column" spacing={4}>
                <Grid item container>
                  <Grid item style={{ width: 320 }}>
                    <Typography variant="h6" style={{ marginBottom: 8 }}>
                      Group Name
                    </Typography>
                    <RenameGroupInput
                      handleRenameGroup={handleRenameGroup}
                      list={list}
                      errorMessage={groupNameError[list.listId]}
                    />
                  </Grid>
                  {typeof list.initializeFromRules === 'boolean' && (
                    <Grid item style={{ marginLeft: 16 }}>
                      <Typography variant="h6">
                        Which notifications would you like turned on initially?
                      </Typography>
                      <Grid item style={{ marginTop: 4 }}>
                        <LevelButtonSegment
                          size="medium"
                          initiallySelected={
                            list.initializeFromRules
                              ? 'matchEnabledRules'
                              : 'none'
                          }
                          options={[
                            {
                              label: 'NONE',
                              value: 'none',
                            },
                            {
                              label: 'MATCH THE ENABLED RULES',
                              value: 'matchEnabledRules',
                            },
                          ]}
                          passbackChosenValue={value => {
                            handleMatchEnabledRulesChange({
                              listId: list.listId,
                              value: value === 'matchEnabledRules',
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="h6" style={{ marginBottom: 4 }}>
                    Recipients
                  </Typography>
                  <AddRecipientInput
                    initialValue={
                      tempEmailInputMapRef.current[list.listId] || ''
                    }
                    attemptAddEmail={attemptAddEmail}
                    list={list}
                    onInputBlur={value => {
                      tempEmailInputMapRef.current = {
                        ...tempEmailInputMapRef.current,
                        [list.listId]: value,
                      };
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ padding: '8px 16px 0 16px' }}>
                <List style={{ padding: 0 }}>
                  {list.recipientEmails.length > 0 ? (
                    list.recipientEmails.map(email => (
                      <ListItem key={email} className={classes.listItem}>
                        <ListItemText primary={email} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              // find this list by id in the internalEmailLists list and update it with the filtered list
                              const updatedList = list.recipientEmails.filter(
                                e => e !== email
                              );
                              const newList = internalEmailLists.map(
                                internalList => {
                                  if (internalList.listId === list.listId) {
                                    return {
                                      ...internalList,
                                      recipientEmails: updatedList,
                                    };
                                  }
                                  return internalList;
                                }
                              );
                              setInternalEmailLists(newList);
                            }}
                            style={{ color: '#e00' }}
                          >
                            <HighlightOff />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))
                  ) : (
                    <Grid container alignItems="center" style={{ padding: 16 }}>
                      <Typography
                        variant="body2"
                        align="center"
                        style={{ width: '100%' }}
                      >
                        {emptyMessage || 'No recipients on the list'}
                      </Typography>
                    </Grid>
                  )}
                </List>
              </Grid>
            </TabPanel>
          );
        })}
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {renderEmailListTabs()}
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        item
        xs={12}
        style={{
          marginTop: 24,
          padding: 16,
          borderTop: '1px solid #ddd',
          background: palette.background.alt,
        }}
      >
        <Grid item>
          <Button
            variant="contained"
            onClick={addGroup}
            startIcon={<PlaylistAdd />}
            style={{ marginRight: 4 }}
          >
            Add Group
          </Button>
          <Button
            variant="contained"
            onClick={removeGroup}
            startIcon={<Remove />}
          >
            Remove Group
          </Button>
        </Grid>

        <Grid item>
          <Button
            onClick={() => {
              // just close and don't pass back the list
              toClose();
            }}
            style={{ marginRight: 4 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveAndClose}
          >
            Save & Close
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ManageEmailList;
