import _ from 'lodash';
import { nonProdConsoleLog } from '../../helpers';

const addItemToQuery = ({
  query,
  queryDataKey,
  variables,
  userAddedItem,
  proxy,
  typename,
  keyName = 'contentId',
  hasItemsInPath = true,
}) => {
  let data;
  try {
    data = _.cloneDeep(
      proxy.readQuery({
        query,
        variables,
      })
    );
  } catch (e) {
    data = false;
    nonProdConsoleLog(
      `addItemToQuery: ${queryDataKey} query was never run before, e:`,
      e
    );
  }
  if (hasItemsInPath) {
    if (data) {
      const existingIndex = _.findIndex(data[queryDataKey].items, {
        [keyName]: userAddedItem[keyName],
      });
      const hasBeenAdded = existingIndex >= 0;
      if (hasBeenAdded) {
        Object.assign(data[queryDataKey].items[existingIndex], userAddedItem);
      } else if (!hasBeenAdded) {
        data[queryDataKey].items.push(userAddedItem);
      }
    } else {
      data = {
        [queryDataKey]: {
          items: [userAddedItem],
          __typename: typename,
        },
      };
    }
  } else if (data) {
    _.assign(data[queryDataKey], userAddedItem);
  } else {
    data = {
      [queryDataKey]: userAddedItem,
      __typename: typename,
    };
  }

  proxy.writeQuery({ query, data, variables });
};

export default addItemToQuery;
