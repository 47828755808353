import React, { useMemo } from 'react';
import {
  FormControl,
  Grid,
  Hidden,
  InputAdornment,
  InputLabel,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';

import numeral from 'numeral';

import LaborBudgetTextField from './labor-budget-text-field';
import FormikSelect from '../../../components/formik-custom-components/formik-select';
import {
  CREW_DEPARTMENT_OPTIONS,
  CREW_PAY_TYPES,
  CREW_PAY_TYPES_OPTIONS,
} from '../../../config/appDefaults';
import { getFullUserString } from '../../../helpers';

const LaborBudgetUserPayInfoItem = ({
  companyCrewMap,
  userBudget,
  isDeactiveUser,
  onInfoChange,
  onInfoBlur,
}) => {
  const {
    userId,
    department,
    payType,
    payRate,
    laborBurdenPercentage,
    vacaAccrualRate,
    hourlyRate,
  } = userBudget;

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const userFullString = useMemo(() => {
    return getFullUserString(companyCrewMap[userId]);
  }, [companyCrewMap, userId]);

  const handleInfoChange = event => {
    let { value } = event.target;
    const { name: fieldName } = event.target;
    value = value.replace(/[^0-9.]+/g, '');
    onInfoChange({ userId, fieldName, value });
  };

  const handleInfoBlur = event => {
    const { name: fieldName, value } = event.target;
    onInfoBlur({ userId, fieldName, value });
  };

  const handleSelectChange = event => {
    const { name: fieldName, value } = event.target;
    onInfoChange({ userId, fieldName, value });
  };

  return (
    <Grid
      container
      key={userId}
      item
      xs={12}
      justifyContent="space-between"
      alignItems="center"
      spacing={mdDown ? 1 : 0}
    >
      <Grid item lg={3} xs={2}>
        <Tooltip
          title={isDeactiveUser ? 'Deactive user (ever on project)' : ''}
        >
          <span>
            <LaborBudgetTextField
              label="Username"
              value={userFullString}
              multiline
              disabled
              InputProps={
                isDeactiveUser
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <InfoOutlinedIcon style={{ fontSize: 16 }} />
                        </InputAdornment>
                      ),
                    }
                  : {}
              }
            />
          </span>
        </Tooltip>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth size="small" variant="outlined">
          <InputLabel>Department</InputLabel>
          <FormikSelect
            name="department"
            label="Department"
            value={department || ''}
            options={CREW_DEPARTMENT_OPTIONS}
            handleChange={handleSelectChange}
          />
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth size="small" variant="outlined">
          <InputLabel>Pay Type</InputLabel>
          <FormikSelect
            name="payType"
            label="Pay Type"
            value={payType || ''}
            options={CREW_PAY_TYPES_OPTIONS}
            handleChange={handleSelectChange}
          />
        </FormControl>
      </Grid>
      <Grid item lg={1} xs={2}>
        {(payType === CREW_PAY_TYPES.VARIABLE_HOURLY ||
          payType === CREW_PAY_TYPES.FIXED_SALARY) && (
          <LaborBudgetTextField
            label={
              payType === CREW_PAY_TYPES.FIXED_SALARY
                ? 'Annual Rate'
                : 'Hourly Rate'
            }
            name="payRate"
            type="number"
            value={payRate || ''}
            adornmentChar="$"
            showZeroAsEmpty={false}
            onChange={handleInfoChange}
            onBlur={handleInfoBlur}
          />
        )}
      </Grid>

      <Grid item lg={1} xs={2}>
        {(payType === CREW_PAY_TYPES.VARIABLE_HOURLY ||
          payType === CREW_PAY_TYPES.FIXED_SALARY) && (
          <LaborBudgetTextField
            label="Labor Burden"
            name="laborBurdenPercentage"
            type="number"
            value={laborBurdenPercentage || ''}
            adornmentChar="%"
            onChange={handleInfoChange}
            onBlur={handleInfoBlur}
          />
        )}
      </Grid>
      <Grid item lg={1} xs={2}>
        {(payType === CREW_PAY_TYPES.VARIABLE_HOURLY ||
          payType === CREW_PAY_TYPES.FIXED_SALARY) && (
          <LaborBudgetTextField
            label="Vacation Accrual"
            name="vacaAccrualRate"
            type="number"
            value={vacaAccrualRate || ''}
            adornmentChar="%"
            onChange={handleInfoChange}
            onBlur={handleInfoBlur}
          />
        )}
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1}>
          {payType === CREW_PAY_TYPES.VARIABLE_HOURLY && (
            <LaborBudgetTextField
              name="hourlyRate"
              value={numeral(hourlyRate).format('0.00')}
              adornmentChar="$"
              disabled
            />
          )}
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default LaborBudgetUserPayInfoItem;
