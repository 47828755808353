import React from 'react';

import QboRequiredWrapperComponent from '../../qbo-required-wrapper/qbo-required-wrapper.component';
import BookkeepingAlertsReportDashboard from '../../../components/bookkeeping-alerts/bookkeeping-alerts-dashboard';

const BookkeepingAlertsDashboardView = () => {
  return (
    <QboRequiredWrapperComponent redirectToOnQboConnect="/alerts">
      <BookkeepingAlertsReportDashboard />
    </QboRequiredWrapperComponent>
  );
};

export default BookkeepingAlertsDashboardView;
