import gql from 'graphql-tag';

export default gql`
  query GetCustomerLeads($companyId: String!, $customerId: String!) {
    getCustomerLeads(
      input: { companyId: $companyId, customerId: $customerId }
    ) {
      items {
        contentStatus
        customerId
        customerInfo {
          customerId
          managingCompanyId
          firstName
          lastName
          address
          email
          latitude
          longitude
          phoneNumber
          notes
          companyName
          qboCustomerId
        }
        address
        contentId
        jrnId
        title
        creatorId
        contentUrl
        description
        date
        endDate
        startDate
        labels
        latitude
        longitude
        type
        qboCustomerId
      }
    }
  }
`;
