import React from 'react';
import {
  makeStyles,
  ButtonBase,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import {
  CheckCircleOutline as SelectedIcon,
  RadioButtonUncheckedOutlined as UnselectedIcon,
} from '@material-ui/icons';

import { getStripePricingDetails } from '../../helpers';

const useStyles = makeStyles(theme => ({
  radioButtons: {
    color: theme.palette.brandColorPrimary,
  },
  radioButtonsUnchecked: {
    color: '#666',
  },
  pricingOptionButton: {
    height: '100%',
    width: '100%',
    cursor: 'pointer',
  },
  pricingOptionPaper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    padding: 10,
    '&:hover': {
      backgroundColor: '#fafafa',
    },
  },
  optionTitle: {
    fontSize: 22,
    textTransform: 'capitalize',
  },
  optionSecondary: {
    fontSize: 16,
  },
  bestValueWrapper: {
    color: '#000',
    backgroundColor: '#fee84e',
    padding: '4px 8px',
    borderRadius: 25,
    border: '2px solid #000',
    display: 'flex',
    fontSize: 12,
    fontStyle: 'italic',
    fontWeight: 'bold',
    height: 50,
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    boxShadow: '0 3px 6px 0px #555',
    position: 'absolute',
    right: 0,
    top: 0,
    marginTop: -15,
    marginRight: -25,
  },
  optionSubtext: {
    color: 'inherit',
    fontSize: 14,
  },
}));

const PricingOption = ({
  pricingInfo = {},
  savingsPercentage,
  selected,
  onSelect,
  isFirm,
}) => {
  const classes = useStyles();

  const { unitAmount, recurring } = pricingInfo;

  const {
    term,
    title,
    readableMonthlyPrice,
    readableTermPrice,
  } = getStripePricingDetails({
    unitAmount,
    recurring,
  });

  return (
    <ButtonBase
      onClick={onSelect}
      disableRipple
      className={classes.pricingOptionButton}
    >
      <Paper
        elevation={5}
        className={classes.pricingOptionPaper}
        style={{
          border: selected ? '2px solid #3a7ca5' : '2px solid transparent',
        }}
      >
        {term === 'annually' && (
          <span className={classes.bestValueWrapper}>Best Value</span>
        )}
        <Grid container>
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            style={{ marginRight: 12 }}
          >
            <Grid item>
              {selected ? (
                <SelectedIcon className={classes.radioButtons} />
              ) : (
                <UnselectedIcon className={classes.radioButtonsUnchecked} />
              )}
            </Grid>
            {!!savingsPercentage && (
              <Grid item style={{ margin: 2 }}>
                <Typography
                  variant="body1"
                  style={{ color: 'red', fontWeight: 'bold' }}
                >
                  Save {savingsPercentage}%
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container item style={{ flex: 1 }} direction="column">
            <Grid container item direction="column" style={{ padding: 16 }}>
              <Grid item>
                <Typography variant="body1" className={classes.optionTitle}>
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.optionSecondary}>
                  {readableMonthlyPrice}
                  {isFirm ? '/mth/client' : '/month'}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              style={{ width: '100%', textAlign: 'left', paddingTop: 8 }}
            >
              <Typography variant="caption" className={classes.optionSubtext}>
                Billed {term} at {readableTermPrice}. Plus applicable taxes.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ButtonBase>
  );
};

export default PricingOption;
