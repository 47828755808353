import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Query, compose, withApollo } from 'react-apollo';
import _ from 'lodash';
import {
  Grid,
  TextField as MuiBasicTextField,
  Button,
  Typography,
  InputLabel,
  Switch as MuiSwitch,
  Tooltip,
  CircularProgress,
  DialogTitle,
} from '@material-ui/core';

import {
  Warning as WarningIcon,
  Equalizer as EqualizerIcon,
  People as PeopleIcon,
  ScreenShare as ScreenShareIcon,
  HelpOutline as HelpOutlineIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';

import {
  TASK_STATUS_OPTIONS,
  adminTaskStatusesForAdmins,
  taskPriorities,
  timetrackingStatuses,
  completionTypes,
  DURATION_UNITS,
  MAX_CHARACTERS_IN_DESCRIPTION_FIELD,
  QBO_SYNCABLE_TYPE,
  CONTENT_TYPE,
  FINANCE_INFO_OPENER,
  FINANCE_INFO_CLOSER,
  PAYMENT_METHOD,
  CONTENT_DETAILS_MODAL_MODE,
  TOP_PROJECT_ID,
  GLOBAL_CONTENT_TYPES,
} from '../../../config/appDefaults';
import PickUserDialog from '../../../components/PickUserDialog/pickUserDialog';
import BasicDialog from '../../../components/basic-dialog/basic-dialog';
import QuestionsDialog from '../questions-dialog/questions-dialog';
import BudgetsDialog from '../budgets-dialog/budgets-dialog';
import PickUserPermissionsDialog from '../../../components/PickUserPermissionsDialog/pickUserPermissionsDialog';
import SlideshowSettingsDialog from '../../../components/SlideshowSettingsDialog/slideshowSettingsDialog';
import LoadingCover from '../../../components/LoadingCover/loadingCover';
import ChooseCustomer from '../choose-customer/choose-customer';
import OkCancelDialog from '../../../components/OkCancelDialog/okCancelDialog';
import FormikSelect from '../../../components/formik-custom-components/formik-select';
import FormikCreatableSelect from '../../../components/formik-custom-components/formik-creatable-select';
import LevelPellEditor from '../level-pell-editor/level-pell-editor';
import LevelNumberInput from '../level-number-input/level-number-input';
import ChangeLeadStatusRadioButtons from '../../../components/change-lead-status/change-lead-status-radio-buttons';
import LocationSearchInput from '../../../components/location-search-input/location-search-input';
import QboSyncStatusIcon from '../../../components/qbo-sync-status-icon';

import GetUserInfo from '../../../graphql/queries/GetUserInfo';

import styles from './add-content-form.styles';
import buildYupSchema from './build-yup-schema.util';
import buildFieldsForType from './build-form-fields.util';
import NoImageDialog from './no-image-dialog';
import ValidationIssuesDialog from './validation-issues-dialog';
import AssignFlowInfoDialog from './assign-flow-info-dialog';
import NewConversationPermissions from './new-conversation-permissions';
import SelectVendorField from './select-vendor.field';
import ReadonlyVendorField from './readonly-vendor.field';
import AmountField from './amount.field';
import TemplateDateInput from './template-date-input.field';
import DateInputField from './date-input.field';
import ManageMedia from './manage-media';
import PaymentTermsField from './payment-terms.field';
import { PAYMENT_TERMS } from './add-content-form.constants';
import AddContentFormActions from './add-content-form-actions';
import ExpenseProjectSplitter from '../../../components/expense-project-splitter/expense-project-splitter';
import BillPaymentBillSplitter from '../../../components/billpayment-bill-splitter/billpayment-bill-splitter';
import { calculateUnallocatedAmount } from '../../../helpers/split-item/split-item.util';
import { getStreetViewLocationAsFile } from '../../../helpers/google-streetview';
import {
  determinePaymentTerms,
  generateDataFromOcr,
  localFileToBase64,
} from '../../../helpers';
import PaymentMethodField from './payment-method.field';
import FinancialTypeButtonsSegment from './financial-type-buttons.segment';
import EditAnswersButton from './edit-answers.button';
// eslint-disable-next-line import/no-cycle
import ContentDetailsModal from '../content-details-modal';
import GetJrn from '../../../graphql/queries/GetJrn';
import CenteredSpinner from '../../../components/centered-spinner/centered-spinner';

const useStyles = makeStyles(styles);

const initialSetShowContentDetailsModal = {
  open: false,
  mode: null,
  projectInfo: null,
  existingInfo: null,
  contentToDelete: null,
};

const AddContentFormView = ({
  // Redux
  userInfo,
  managingCompanyInfo,
  uploadProgressPercent,
  // Passed props
  // Input data - general
  allLabelOptions,
  allUsersOnProject,
  defaultType,
  existingConversations,
  financialSegmentKey,
  initialValues,
  myCrew,
  myCrewLoading,
  saveButtonIndex,
  // Input data - modes
  createFromTemplate,
  creatingProjectFromLead,
  editingMode,
  fromShoebox,
  isFinancial,
  isGlobalExpense,
  isGlobalPayment,
  isInTemplate,
  isSubproject,
  restrictedMode,
  // Forwarded props
  isParentExtended,
  hideCustomerOption,
  parentInfo,
  // Handlers
  getProjectQuestions,
  goToConversation,
  handleParentChoice,
  onComplete,
  onFormSubmit,
  resetVisualAssignedTo,
  whatShouldStatusBe,
  getOcrDataForMediaItem,
  handleBack,
  // Shared states
  setAddAnother,
  assignFlowInfo,
  setAssignFlowInfo,
  convoIsGroup,
  setConvoIsGroup,
  setDidAnswersChange,
  localCurrentTimetrackingStatus,
  setSplitWithAnother,
  templateChanges,
  setTemplateChanges,
  projectQuestions,
  setProjectQuestions,
  showUnknownIssueDialog,
  setShowUnknownIssueDialog,
  visualAssignedTo,
  setVisualAssignedTo,
  projectList,
  setProjectList,
  billList,
  setBillList,
  client,
}) => {
  const classes = useStyles();

  const selectVendorFieldRef = useRef();

  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    title: '',
    message: '',
    onClose: () => {}, // Set based on context
    hideCancel: false,
    onConfirm: () => {}, // Set based on context
  });

  const [permissionsPicked, setPermissionsPicked] = useState(false);
  const [chooseCustomerOpen, setChooseCustomerOpen] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [loadingDupeOriginalInfo, setLoadingDupeOriginalInfo] = useState(false);

  const [openQuestionsDialog, setOpenQuestionsDialog] = useState(false);
  const [openPermsDialog, setOpenPermsDialog] = useState(false);
  const [openBudgetsDialog, setOpenBudgetsDialog] = useState(false);
  const [showNoImageDialog, setShowNoImageDialog] = useState(false);
  const [openSlideshowDialog, setOpenSlideshowDialog] = useState(false);
  const [addressChanged, setAddressChanged] = useState(false);
  const [alertPossibleDuplicate, setAlertPossibleDuplicate] = useState(null);
  const [showPickUserDialog, setShowPickUserDialog] = useState(false);
  const [showValidationIssuesDialog, setShowValidationIssuesDialog] = useState({
    open: false,
    issues: [],
  });
  const [simpleMessageDialog, setSimpleMessageDialog] = useState({
    open: false,
  });
  const [hasBeenAutofilled, setHasBeenAutofilled] = useState(false);
  const [showAutofillLoading, setShowAutofillLoading] = useState(false);

  const [invalidFieldMap, setInvalidFieldMap] = useState({
    totalAmount: false,
    amountMap: {},
  });

  const [showContentDetailsModal, setShowContentDetailsModal] = useState(
    initialSetShowContentDetailsModal
  );

  const typeToUse = initialValues.type;

  const globalAllowCrewEditingEnabled = !!managingCompanyInfo
    ?.timetrackingSettings?.allowCrewEditing;

  const editableClockInMessageToShow = globalAllowCrewEditingEnabled
    ? `This setting cannot be disabled for this project because the company-wide time tracking setting to allow crew member editing has been enabled. To update your company-wide settings, navigate to Settings > Time Tracking.`
    : `When clocking-out, this allows a user to edit their own clock-in or clock-out times for that shift. This is helpful for fixing a missed clock in/out. This can be turned on or off after the project is created.`;

  const showAssignedToDialog = () => {
    if (typeToUse === 'timetracking' && editingMode) {
      return;
    }
    setShowPickUserDialog(true);
  };

  const onEditQuestionsClick = async () => {
    setLoadingQuestions(true);
    let whatToSetTo;
    if (projectQuestions) {
      whatToSetTo = projectQuestions;
    } else if (!editingMode && !createFromTemplate) {
      // if this is a new project, just return an empty array
      whatToSetTo = []; // set these for now
    } else {
      // if we're editing
      whatToSetTo = await getProjectQuestions();
    }
    setProjectQuestions(whatToSetTo);
    setLoadingQuestions(false);

    setOpenQuestionsDialog(true);
  };

  const userId = userInfo && userInfo.userId;

  const parentIsLead =
    parentInfo && parentInfo.type && parentInfo.type === CONTENT_TYPE.LEAD;

  let typeToBuildFormFrom = typeToUse || defaultType;
  if (isInTemplate && typeToBuildFormFrom === CONTENT_TYPE.PROJECT) {
    // Subprojects that are part of templates should use the template schema
    typeToBuildFormFrom = CONTENT_TYPE.TEMPLATE;
  }

  const { userEditableFields } = buildFieldsForType({
    contentType: typeToBuildFormFrom,
    isInTemplate,
  });
  const validationSchema = buildYupSchema({ contentType: typeToBuildFormFrom });

  const kickOffOcrAutofill = async ({ setFieldValue, file, description }) => {
    setShowAutofillLoading(true);
    // upload to the ocrData endpoint and wait for the returned data
    const mediaSource = file;
    const mediaSourceType = 'BASE64'; // URI, BASE64

    let base64Image;
    try {
      // base64Image = await blobToBase64(mediaSource);
      base64Image = await localFileToBase64({
        file: mediaSource,
        withoutHeader: true,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('localFileToBase64 err: ', err);
    }

    if (base64Image) {
      const autoFillOcrData = await getOcrDataForMediaItem({
        mediaSource: base64Image,
        mediaSourceType,
      });
      if (autoFillOcrData) {
        if (autoFillOcrData.duplicateInfo) {
          let linkToProject = '';
          let titleWording = '';
          const { duplicateInfo } = autoFillOcrData;
          let isGlobalItem = false;

          if (duplicateInfo.length) {
            let contentInOrderParentFirst = [];
            const lastItemsType = _.last(duplicateInfo).type;
            isGlobalItem = GLOBAL_CONTENT_TYPES.includes(lastItemsType);

            if (duplicateInfo[0].type === CONTENT_TYPE.PROJECT) {
              // put them in order parent, then child
              linkToProject = '/projects';
              contentInOrderParentFirst = _.sortBy(
                autoFillOcrData.duplicateInfo,
                [
                  // First criteria: non-project types go last
                  o => o.type !== CONTENT_TYPE.PROJECT,

                  // Second criteria: prioritize items with jrnId '00000' or non-existent
                  o => !(o.jrnId === TOP_PROJECT_ID || !o.jrnId),
                ]
              );
              const arrForTitle = [];
              contentInOrderParentFirst.forEach(content => {
                if (content.type === CONTENT_TYPE.PROJECT) {
                  arrForTitle.push(content.title);
                  // build the link to the project
                  linkToProject += `/${content.contentId}`;
                }
              });
              titleWording = arrForTitle.join(' > ');
            }
          }

          setAlertPossibleDuplicate({
            duplicateInfo: autoFillOcrData.duplicateInfo,
            href: linkToProject,
            isGlobalItem,
            duplicatePath: titleWording,
          });
        }
        // on success process the ocrData to get formdata, then put that data on the form values
        const formDataFromOcr = generateDataFromOcr({
          ocrData: autoFillOcrData,
        });
        if (formDataFromOcr) {
          // if the description already includes some finance info, then remove it before adding the new info
          const indexOfFinanceInfoOpener = description.indexOf(
            FINANCE_INFO_OPENER
          );
          const indexOfFinanceInfoCloser = description.indexOf(
            FINANCE_INFO_CLOSER
          );
          let descriptionStart = '';
          let descriptionEnd = '';
          if (indexOfFinanceInfoOpener > -1 && indexOfFinanceInfoCloser > -1) {
            descriptionStart = description.substring(
              0,
              indexOfFinanceInfoOpener - 1
            );
            descriptionEnd = description.substring(
              indexOfFinanceInfoCloser + FINANCE_INFO_CLOSER.length
            );
          }
          const descriptionToWrite = formDataFromOcr.description
            ? `
            ${descriptionStart}
            <br /><br />
            ${formDataFromOcr.description}
            <br /><br />
            ${descriptionEnd}
            `
            : description;
          setFieldValue('description', descriptionToWrite);
          setFieldValue('amount', formDataFromOcr.amount);

          if (formDataFromOcr.date) {
            setFieldValue('date', formDataFromOcr.date);

            if (typeToUse === CONTENT_TYPE.GLOBAL_BILL) {
              setFieldValue('startDate', formDataFromOcr.date);
            }
          }

          if (typeToUse === CONTENT_TYPE.GLOBAL_BILL) {
            if (formDataFromOcr.dueDate) {
              const paymentTerms = determinePaymentTerms({
                billDate: formDataFromOcr.date,
                dueDate: formDataFromOcr.dueDate,
              });
              setFieldValue('paymentTerms', paymentTerms);
              setFieldValue('endDate', formDataFromOcr.dueDate);
            }

            if (formDataFromOcr.invoiceNumber) {
              setFieldValue('documentNumber', formDataFromOcr.invoiceNumber);
            }
          }

          if (isGlobalExpense) {
            if (formDataFromOcr.poNumber) {
              setFieldValue('poNumber', formDataFromOcr.poNumber);
            }

            if (formDataFromOcr.vendorName && selectVendorFieldRef.current) {
              // reset the vendorId field
              setFieldValue('vendorId', '', true);
              selectVendorFieldRef.current.selectOrCreateVendor({
                vendorName: formDataFromOcr.vendorName,
              });
            }
          }

          if (formDataFromOcr.ocrId) {
            setFieldValue('ocrId', formDataFromOcr.ocrId);
          }
        }
        // and add the ocrData to the form values
        delete autoFillOcrData.__typename;
        setFieldValue('ocrData', autoFillOcrData);
        setHasBeenAutofilled(true);
      } else {
        setDialogInfo({
          open: true,
          title: 'Issue',
          message: 'Could not get Autofill data for this item.',
          onClose: () => {
            setDialogInfo({
              ...dialogInfo,
              open: false,
            });
          },
          hideCancel: true,
          okButtonText: 'Ok',
        });
      }
    } else {
      setDialogInfo({
        open: true,
        title: 'Issue',
        message: 'Something went wrong. Please try again later.',
        onClose: () => {
          setDialogInfo({
            ...dialogInfo,
            open: false,
          });
        },
        hideCancel: true,
        okButtonText: 'Ok',
      });
    }
    setShowAutofillLoading(false);
  };

  const handleViewingGlobalDupeOriginal = async () => {
    const idOfGlobalExpenseToShow =
      alertPossibleDuplicate?.duplicateInfo?.[0].contentId;
    if (!idOfGlobalExpenseToShow) {
      return;
    }
    let getRelatedItemQuery;
    setLoadingDupeOriginalInfo(true);
    try {
      getRelatedItemQuery = await client.query({
        query: GetJrn,
        variables: { jrnId: idOfGlobalExpenseToShow },
        fetchPolicy: 'no-cache',
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('err getting dupe info: ', err);
    }
    const originalOfDupeItemInfo = _.get(
      getRelatedItemQuery,
      `data.getJrn`,
      null
    );

    if (originalOfDupeItemInfo) {
      setShowContentDetailsModal({
        open: true,
        mode: CONTENT_DETAILS_MODAL_MODE.GLOBAL_ITEM_VIEW,
        projectInfo: null,
        existingInfo: originalOfDupeItemInfo,
      });
    }
    setLoadingDupeOriginalInfo(false);
  };

  return (
    <Grid container style={{ width: '100%', paddingBottom: 32 }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
        style={{ width: '100%' }}
      >
        {formikProps => {
          const {
            values,
            touched,
            errors,
            validateForm,
            handleChange,
            handleBlur,
            handleSubmit,
            setValues,
            setFieldValue,
            setFieldTouched,
          } = formikProps;

          const ocrTheFile = ({ askFirst = true, file }) => {
            if (askFirst) {
              setDialogInfo({
                open: true,
                title: 'Autofill?',
                message:
                  'Would you like to autofill the information from this into the form? E.g. date, amount, supplier info, etc.',
                onClose: () => {
                  setDialogInfo({
                    ...dialogInfo,
                    open: false,
                  });
                },
                onConfirm: () => {
                  kickOffOcrAutofill({
                    setFieldValue,
                    file,
                    description: values.description,
                  });
                },
                hideCancel: false,
                cancelButtonText: 'No, thanks',
                okButtonText: 'Yes, please',
              });
            } else {
              kickOffOcrAutofill({
                setFieldValue,
                file,
                description: values.description,
              });
            }
          };

          let assignedToToShow;
          if (_.includes(userEditableFields, 'assignedTo')) {
            if (typeToUse === 'timetracking' && !visualAssignedTo) {
              assignedToToShow = userInfo.username;
            } else {
              assignedToToShow = visualAssignedTo || 'unassigned';
            }
          }

          let initialPermissionsUsers;
          if (
            [
              CONTENT_TYPE.CONVERSATION,
              CONTENT_TYPE.PROJECT,
              CONTENT_TYPE.TEMPLATE,
              CONTENT_TYPE.LEAD,
            ].includes(typeToUse)
          ) {
            initialPermissionsUsers = [
              ...initialValues.allowedToEdit,
              ...initialValues.allowedToAdd,
              ...initialValues.allowedToView,
            ];
          }

          const currentTime = new Date().getTime();
          const currentSetTime = new Date(values.date).getTime();
          const isTimetracking = values.type === 'timetracking';
          // check if the set time is ahead of the current time and that this is timetracking
          if (isTimetracking && currentSetTime > currentTime) {
            values.date = new Date();
          }

          const combinedConvoList = [
            ...(values.allowedToEdit || []),
            ...(values.allowedToAdd || []),
            ...(values.allowedToView || []),
          ];

          let showPermissions = false;
          if (!isInTemplate) {
            if (typeToUse === CONTENT_TYPE.CONVERSATION && convoIsGroup) {
              showPermissions = true;
            } else if (
              typeToUse !== CONTENT_TYPE.CONVERSATION &&
              _.includes(userEditableFields, 'allowedToEdit') &&
              !(typeToUse === CONTENT_TYPE.TEMPLATE && managingCompanyInfo)
            ) {
              showPermissions = true;
            }
          }

          const canEditTimetrackingOptions =
            _.includes(userEditableFields, 'useTimetracking') ||
            _.includes(userEditableFields, 'useTimetrackingQuestions');

          const canEditTask =
            _.includes(userEditableFields, 'subtype' && !isGlobalPayment) ||
            _.includes(userEditableFields, 'requireAdmin');

          const newProjectOrLeadAndCustomerSyncEnabled =
            managingCompanyInfo &&
            managingCompanyInfo.isCustomerSyncEnabled &&
            [CONTENT_TYPE.PROJECT, CONTENT_TYPE.LEAD].includes(typeToUse) &&
            !editingMode &&
            !creatingProjectFromLead;

          const openSyncIssueDialog = message => {
            setDialogInfo({
              open: true,
              title: `Cannot sync ${values.type} to QuickBooks Online`,
              message,
              onClose: () => {
                setDialogInfo({
                  ...dialogInfo,
                  open: false,
                });
              },
              hideCancel: false,
              okButtonText: 'Choose customer',
              cancelButtonText: 'Cancel',
              onConfirm: () => setChooseCustomerOpen(true),
            });
          };

          const validateBillList = () => {
            const { amount, type } = values;

            const invalidAmountMap = {};
            const validationIssues = [];
            let invalidTotalAmount = false;
            const amountAsFloat = parseFloat((amount && amount.value) || 0);
            if (amountAsFloat <= 0) {
              invalidTotalAmount = true;
              validationIssues.push(
                'The amount must be greater than zero (amount > 0).'
              );
            }
            const unallocatedAmount = calculateUnallocatedAmount({
              totalAmount: amountAsFloat,
              contentList: billList,
            });
            if (unallocatedAmount !== 0) {
              let message;
              switch (type) {
                case CONTENT_TYPE.GLOBAL_PAYMENT:
                  if (!(billList?.length > 0)) {
                    message = 'Please select the bill to pay.';
                  } else {
                    message =
                      'The sum of the amounts allocated to the selected bills must equal the total bill payment amount.';
                  }

                  break;
                default:
                  message =
                    'The sum of the amounts allocated must equal the total amount.';
                  break;
              }

              validationIssues.push(message);
            }
            billList.forEach(item => {
              if (item.deletionPending) {
                // ignore items that are pending deletion
                return;
              }
              const amountToPay = Number(item.amount);
              const balanceDue = Number(
                item.bill.balance?.value || item.bill.amount?.value
              );
              const isAmountValid =
                amountToPay > 0 && amountToPay <= balanceDue;
              if (!isAmountValid) {
                invalidAmountMap[item.bill.contentId] = !isAmountValid;
                validationIssues.push(
                  `The amount allocated to the bill No. "${item.bill.documentNumber}" must be greater than zero and less than or equal to the balance due (0 < amount <= balance due).`
                );
              }
            });

            return { invalidTotalAmount, validationIssues, invalidAmountMap };
          };

          const validateProjectList = () => {
            const { amount, type } = values;

            const invalidAmountMap = {};
            const validationIssues = [];
            let invalidTotalAmount = false;
            const amountAsFloat = parseFloat((amount && amount.value) || 0);
            if (amountAsFloat < 0) {
              invalidTotalAmount = true;
              validationIssues.push(
                'The amount must be greater than or equal to zero (amount >= 0).'
              );
            }
            const unallocatedAmount = calculateUnallocatedAmount({
              totalAmount: amountAsFloat,
              contentList: projectList,
            });
            if (unallocatedAmount !== 0) {
              let message;
              switch (type) {
                case CONTENT_TYPE.GLOBAL_BILL:
                  message =
                    'The sum of the amounts allocated to the selected projects must equal the total bill amount.';
                  break;
                case CONTENT_TYPE.GLOBAL_RECEIPT:
                  message =
                    'The sum of the amounts allocated to the selected projects must equal the total receipt amount.';
                  break;
                default:
                  message =
                    'The sum of the amounts allocated must equal the total amount.';
                  break;
              }

              validationIssues.push(message);
            }

            projectList.forEach(item => {
              if (item.deletionPending) {
                // ignore items that are pending deletion
                return;
              }
              const isProjectAmountValid = Number(item.amount) >= 0;
              if (!isProjectAmountValid) {
                invalidAmountMap[
                  item.project.contentId
                ] = !isProjectAmountValid;
                validationIssues.push(
                  `The amount allocated to the project "${item.projectPath.join(
                    ' > '
                  )}" must be greater than or equal to zero (allocated amount >= 0).`
                );
              }
            });

            return { invalidTotalAmount, validationIssues, invalidAmountMap };
          };

          const verifyAndSubmit = async () => {
            const formikValidationErrors = await validateForm();
            const isFormikValid = _.isEmpty(formikValidationErrors);

            let isCustomValidationValid = true;
            if (isGlobalExpense || isGlobalPayment) {
              const {
                invalidTotalAmount,
                validationIssues,
                invalidAmountMap,
              } = isGlobalExpense ? validateProjectList() : validateBillList();

              if (!_.isEmpty(validationIssues)) {
                isCustomValidationValid = false;
                setInvalidFieldMap(currentState => ({
                  ...currentState,
                  totalAmount: invalidTotalAmount,
                  amountMap: invalidAmountMap,
                }));
                setShowValidationIssuesDialog({
                  open: true,
                  issues: validationIssues,
                });
              }
            }

            // NOTE: this workaround to make sure all the errors are shown when submitting the form
            // if isFormikValid is false, then we proceed to handleSubmit
            // then the formik validation will kick in and show the errors

            if (isFormikValid && !isCustomValidationValid) {
              return;
            }

            if (
              values.type === CONTENT_TYPE.PROJECT &&
              formikValidationErrors?.allowedToEdit
            ) {
              setDialogInfo({
                open: true,
                title: 'Oops!',
                message: 'A project needs to have at least one admin user.',
                onClose: () => {
                  setDialogInfo(currentState => ({
                    ...currentState,
                    open: false,
                  }));
                },
                hideCancel: true,
                okButtonText: 'Choose admin',
                onConfirm: () => setOpenPermsDialog(true),
              });
              return;
            }

            // Check if financial type and no content
            // and only show dialog if the form is completely valid
            if (
              isFormikValid &&
              isCustomValidationValid &&
              isFinancial &&
              values.contentUrl &&
              values.contentUrl.length === 0
            ) {
              setShowNoImageDialog(true);
              return;
            }

            // Check if qboCustomerId state is okay
            if (newProjectOrLeadAndCustomerSyncEnabled) {
              if (
                values.qboCustomerId === 'NEW' &&
                values.type === CONTENT_TYPE.PROJECT && // Leads require projects already
                !values.customerId
              ) {
                // If no customer is assigned (only required for projects)
                openSyncIssueDialog(
                  `Only ${values.type}s with assigned customers can be synced to QuickBooks Online. To sync this ${values.type}, choose a synced customer or uncheck Sync to QuickBooks Online.`
                );
                return;
              }
              if (
                values.qboCustomerId === 'NEW' &&
                values.customerInfo &&
                (!values.customerInfo.qboCustomerId ||
                  values.customerInfo.qboCustomerId === 'NEW')
              ) {
                // If assigned customer is not synced
                openSyncIssueDialog(
                  `This ${values.type}'s assigned customer must be synced to QuickBooks Online before we can sync this ${values.type}. Please choose a synced customer or uncheck Sync to QuickBooks Online.`
                );
                return;
              }
            }

            handleSubmit();
          };

          const showGeneralOptions =
            canEditTimetrackingOptions ||
            canEditTask ||
            newProjectOrLeadAndCustomerSyncEnabled;

          const openAddressDialog = selectedCustomerInfo => {
            setDialogInfo({
              open: true,
              title: 'Set Address?',
              message: `Would you like to use this customer's address?`,
              onClose: () => {
                setDialogInfo({
                  ...dialogInfo,
                  open: false,
                });
              },
              hideCancel: false,
              okButtonText: 'Yes',
              cancelButtonText: 'No',
              onConfirm: () => {
                // set the address to the passed address
                setFieldValue('address', selectedCustomerInfo.address);
                setAddressChanged(true);
                setFieldValue('latitude', selectedCustomerInfo.latitude);
                setFieldValue('longitude', selectedCustomerInfo.longitude);
              },
            });
          };

          const handlePickUser = async passedUserInfo => {
            if (passedUserInfo) {
              if (typeToUse === 'timetracking') {
                // if this is a timetracking, need to check what the person's last
                //  timetracking event was to set helper wording
                const lastStatus = await whatShouldStatusBe(
                  passedUserInfo.userId
                );
                setFieldValue(
                  'contentStatus',
                  lastStatus === 'checkin' ? 'checkout' : 'checkin',
                  false
                );
              }
              setVisualAssignedTo(passedUserInfo.username);
              setFieldValue('assignedTo', passedUserInfo.userId, false);
            } else if (typeToUse === 'task') {
              setFieldValue('assignedTo', '', false);
              resetVisualAssignedTo();
            }
            setShowPickUserDialog(false);
          };

          const allowStreetViewCall =
            values.address &&
            (!values.contentUrl || !values.contentUrl.length || addressChanged);

          const getStreetViewCoverImage = async ({ location }) => {
            const imageFile = await getStreetViewLocationAsFile({ location });

            if (imageFile) {
              imageFile.path = imageFile.name;
              imageFile.uri = URL.createObjectURL(imageFile);
              setFieldValue('contentUrl', [imageFile]);
              setAddressChanged(false);
            } else {
              setSimpleMessageDialog({
                open: true,
                message: `Sorry, we could't get the street view image for that address.`,
              });
            }
          };

          // !restrictedMode && !fromShoebox also apply from wrapper above the button
          const creatingFromRfi = !!values.rfiIds?.length;
          const showAutofillButton =
            !hasBeenAutofilled &&
            isFinancial &&
            !editingMode &&
            !creatingFromRfi;

          const showVendorReadonly =
            typeToUse === CONTENT_TYPE.GLOBAL_PAYMENT && billList?.length > 0;

          return (
            <Form style={{ width: '100%' }}>
              {typeToUse === CONTENT_TYPE.CONVERSATION &&
              !permissionsPicked &&
              !editingMode ? (
                <NewConversationPermissions
                  userId={userId}
                  myCrew={myCrew}
                  myCrewLoading={myCrewLoading}
                  values={values}
                  setValues={setValues}
                  existingConversations={existingConversations}
                  handleSubmit={handleSubmit}
                  setConvoIsGroup={setConvoIsGroup}
                  setPermissionsPicked={setPermissionsPicked}
                  goToConversation={goToConversation}
                />
              ) : (
                <>
                  {typeToUse !== CONTENT_TYPE.LEAD && !parentIsLead && (
                    <Grid container alignItems="center" justifyContent="center">
                      <Grid item>
                        <Typography
                          variant="h3"
                          gutterBottom
                          align="center"
                          color="primary"
                        >
                          {typeToUse === CONTENT_TYPE.TEMPLATE && editingMode
                            ? 'Edit your template settings'
                            : 'Please enter the details below'}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={2}>
                    <Grid container item xs={12} sm={6}>
                      <ManageMedia
                        contentType={typeToUse}
                        disabled={restrictedMode}
                        isEditableField={_.includes(
                          userEditableFields,
                          'contentUrl'
                        )}
                        classes={classes}
                        values={values}
                        setFieldValue={setFieldValue}
                        convoIsGroup={convoIsGroup}
                        fromShoebox={fromShoebox}
                        isFinancial={isFinancial}
                        allowStreetViewCall={allowStreetViewCall}
                        getStreetViewCoverImage={getStreetViewCoverImage}
                        showAutofillButton={showAutofillButton}
                        ocrTheFile={ocrTheFile}
                        setHasBeenAutofilled={setHasBeenAutofilled}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid container direction="row" spacing={2}>
                        {typeToUse === CONTENT_TYPE.CONVERSATION &&
                        !convoIsGroup ? (
                          <input
                            name="title"
                            type="hidden"
                            value={values.title}
                          />
                        ) : (
                          _.includes(userEditableFields, 'title') && (
                            <Grid item xs={12}>
                              <Field
                                name="title"
                                label={
                                  values.type === CONTENT_TYPE.CONVERSATION
                                    ? 'Group Name'
                                    : 'Title'
                                }
                                InputLabelProps={{ shrink: true }}
                                value={values.title}
                                type="text"
                                component={TextField}
                                disabled={restrictedMode}
                                fullWidth
                              />
                            </Grid>
                          )
                        )}
                        {_.includes(userEditableFields, 'address') && (
                          <>
                            <Grid item xs={12}>
                              <LocationSearchInput
                                passBack={(address, latlng) => {
                                  setFieldValue('address', address);
                                  setAddressChanged(true);
                                  setFieldValue('latitude', latlng.lat);
                                  setFieldValue('longitude', latlng.lng);
                                }}
                                defaultValue={initialValues.address || ''}
                                initialLatLng={{
                                  lat: initialValues.latitude,
                                  lng: initialValues.longitude,
                                }}
                                pushedAddress={values.address}
                                label="Address"
                                disabled={restrictedMode}
                              />
                            </Grid>
                            {allowStreetViewCall && (
                              <Grid item xs={12}>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  onClick={async () => {
                                    return getStreetViewCoverImage({
                                      location: values.address,
                                      setFieldValue,
                                    });
                                  }}
                                >
                                  Use Street View as cover image
                                </Button>
                              </Grid>
                            )}
                          </>
                        )}
                        {userEditableFields.includes('vendorId') &&
                          !showVendorReadonly && (
                            <Grid item xs={12} sm={6}>
                              <SelectVendorField
                                initialVendorName={initialValues.vendorName}
                                ref={selectVendorFieldRef}
                                value={values.vendorId}
                                setFieldValue={setFieldValue}
                                classes={classes}
                                error={errors?.vendorId}
                                touched={touched?.vendorId}
                                onBlur={() => setFieldTouched('vendorId')}
                                editingMode={editingMode}
                              />
                            </Grid>
                          )}
                        {showVendorReadonly && (
                          <Grid item xs={12} sm={6}>
                            <ReadonlyVendorField vendorId={values.vendorId} />
                          </Grid>
                        )}
                        {userEditableFields.includes('amount') &&
                          (isGlobalExpense || isGlobalPayment) && (
                            <Grid item xs={12} sm={6}>
                              <AmountField
                                classes={classes}
                                error={errors?.amount}
                                values={values}
                                setFieldValue={setFieldValue}
                                touched={touched?.amount}
                                onBlur={() => setFieldTouched('amount')}
                                invalidAmount={invalidFieldMap.totalAmount}
                                onResetInvalidAmount={() => {
                                  setInvalidFieldMap(currentState => ({
                                    ...currentState,
                                    totalAmount: false,
                                  }));
                                }}
                              />
                            </Grid>
                          )}
                        {userEditableFields.includes('documentNumber') &&
                          typeToUse !== CONTENT_TYPE.GLOBAL_PAYMENT && (
                            <Grid item xs={12} sm={6}>
                              <Field
                                name="documentNumber"
                                label="Bill No."
                                InputLabelProps={{ shrink: true }}
                                value={values.documentNumber}
                                type="text"
                                component={TextField}
                                disabled={restrictedMode}
                                fullWidth
                              />
                            </Grid>
                          )}
                        {userEditableFields.includes('poNumber') && (
                          <Grid item xs={12} sm={6}>
                            <Field
                              name="poNumber"
                              label="PO Number"
                              InputLabelProps={{ shrink: true }}
                              value={values.poNumber}
                              type="text"
                              component={TextField}
                              disabled={restrictedMode}
                              fullWidth
                            />
                          </Grid>
                        )}
                        {typeToUse === CONTENT_TYPE.CONVERSATION &&
                        !convoIsGroup ? (
                          <input
                            name="description"
                            type="hidden"
                            value={values.description}
                          />
                        ) : (
                          _.includes(userEditableFields, 'description') && (
                            <Grid item xs={12}>
                              {[
                                CONTENT_TYPE.PROJECT,
                                CONTENT_TYPE.TEMPLATE,
                                CONTENT_TYPE.LEAD,
                                CONTENT_TYPE.CONVERSATION,
                              ].includes(typeToUse) ? (
                                <Field
                                  name="description"
                                  value={values.description}
                                  label={
                                    values.type === CONTENT_TYPE.CONVERSATION
                                      ? 'Group Description'
                                      : 'Description'
                                  }
                                  InputLabelProps={{ shrink: true }}
                                  type="text"
                                  placeholder="e.g. Full kitchen renovation"
                                  component={TextField}
                                  fullWidth
                                  multiline
                                  maxRows={3}
                                  disabled={restrictedMode}
                                />
                              ) : (
                                <>
                                  <InputLabel
                                    className={classes.staticLabel}
                                    disabled={restrictedMode}
                                  >
                                    {isFinancial && 'Notes / '}
                                    Description
                                    {errors.description && (
                                      <span
                                        style={{
                                          marginLeft: 10,
                                          color: 'red',
                                        }}
                                      >
                                        <WarningIcon
                                          color="inherit"
                                          style={{ fontSize: 16 }}
                                        />{' '}
                                        Description is too long (
                                        {values.description.length} of{' '}
                                        {MAX_CHARACTERS_IN_DESCRIPTION_FIELD})
                                      </span>
                                    )}
                                  </InputLabel>
                                  <div
                                    style={{
                                      marginTop: 5,
                                      border: `1px solid ${
                                        errors.description ? '#e00' : '#ccc'
                                      }`,
                                    }}
                                  >
                                    <LevelPellEditor
                                      defaultContent={
                                        values && values.description
                                          ? values.description
                                          : ''
                                      }
                                      onChange={htmlText => {
                                        setFieldValue('description', htmlText);
                                      }}
                                      containerClass="level-pell-content-addContentForm"
                                      disabled={
                                        !isFinancial ? restrictedMode : false
                                      }
                                    />
                                  </div>
                                </>
                              )}
                            </Grid>
                          )
                        )}
                        {_.includes(userEditableFields, 'date') && (
                          <>
                            {isInTemplate ? (
                              <TemplateDateInput
                                classes={classes}
                                values={values}
                                dateLabel="Date (from start of project)"
                                timeLabel="Time"
                                name="date"
                                timeFieldName="timeForDate"
                                templateName="dateInt"
                                templateChanges={templateChanges}
                                setTemplateChanges={setTemplateChanges}
                                disabled={restrictedMode}
                                showTimeInput
                              />
                            ) : (
                              <DateInputField
                                name="date"
                                timeFieldName="timeForDate"
                                dateLabel={
                                  values.type === CONTENT_TYPE.GLOBAL_PAYMENT
                                    ? 'Payment Date'
                                    : 'Date'
                                }
                                timeLabel="Time"
                                values={values}
                                noFuture={isTimetracking}
                                disabled={!isFinancial ? restrictedMode : false}
                                showTimeInput={!isGlobalPayment}
                                setFieldValue={setFieldValue}
                              />
                            )}
                          </>
                        )}
                        {typeToUse !== CONTENT_TYPE.TEMPLATE &&
                          _.includes(userEditableFields, 'startDate') &&
                          isInTemplate && (
                            <TemplateDateInput
                              dateLabel="Start Day (from start of project)"
                              timeLabel="Start Time"
                              classes={classes}
                              templateName="startDateInt"
                              name="startDate"
                              isBeforeName="endDate"
                              isBeforeTemplateName="endDateInt"
                              timeFieldName="timeForStartDate"
                              disabled={restrictedMode}
                              values={values}
                              templateChanges={templateChanges}
                              setTemplateChanges={setTemplateChanges}
                              showTimeInput={typeToUse === CONTENT_TYPE.TASK}
                            />
                          )}
                        {typeToUse !== CONTENT_TYPE.TEMPLATE &&
                          _.includes(userEditableFields, 'startDate') &&
                          !isInTemplate && (
                            <DateInputField
                              values={values}
                              dateLabel={
                                typeToUse === CONTENT_TYPE.GLOBAL_BILL
                                  ? 'Bill Date'
                                  : 'Start Date'
                              }
                              timeLabel="Start Time"
                              name="startDate"
                              timeFieldName="timeForStartDate"
                              disabled={restrictedMode}
                              showTimeInput={typeToUse === CONTENT_TYPE.TASK}
                              templateMode={createFromTemplate}
                              setFieldValue={setFieldValue}
                              isBeforeName="endDate"
                            />
                          )}
                        {_.includes(userEditableFields, 'subtype') &&
                          isGlobalPayment && (
                            <Grid item xs={12} sm={6}>
                              <PaymentMethodField
                                classes={classes}
                                values={values}
                                error={errors?.subtype}
                                touched={touched?.subtype}
                                onClose={() => setFieldTouched('subtype')}
                                disabled={restrictedMode}
                                setFieldValue={setFieldValue}
                              />
                            </Grid>
                          )}
                        {_.includes(userEditableFields, 'documentNumber') &&
                          isGlobalPayment &&
                          values.subtype === PAYMENT_METHOD.CHECK && (
                            <Grid item xs={12} sm={6}>
                              <Field
                                name="documentNumber"
                                label="Check Number"
                                InputLabelProps={{ shrink: true }}
                                value={values.documentNumber}
                                type="text"
                                component={TextField}
                                disabled={restrictedMode}
                                fullWidth
                              />
                            </Grid>
                          )}
                        {_.includes(userEditableFields, 'paymentTerms') && (
                          <Grid item xs={12} sm={6}>
                            <PaymentTermsField
                              classes={classes}
                              values={values}
                              error={errors?.paymentTerms}
                              touched={touched?.paymentTerms}
                              onClose={() => setFieldTouched('paymentTerms')}
                              disabled={restrictedMode}
                              setFieldValue={setFieldValue}
                            />
                          </Grid>
                        )}
                        {typeToUse !== CONTENT_TYPE.TEMPLATE &&
                          _.includes(userEditableFields, 'endDate') &&
                          isInTemplate && (
                            <TemplateDateInput
                              classes={classes}
                              disabled={restrictedMode}
                              values={values}
                              templateChanges={templateChanges}
                              setTemplateChanges={setTemplateChanges}
                              name="endDate"
                              timeFieldName="timeForEndDate"
                              templateName="endDateInt"
                              dateLabel="End Day (from start of project)"
                              timeLabel="End Time"
                              showTimeInput={typeToUse === CONTENT_TYPE.TASK}
                              isAfterName="startDate"
                              isAfterTemplateName="startDateInt"
                            />
                          )}
                        {typeToUse !== CONTENT_TYPE.TEMPLATE &&
                          _.includes(userEditableFields, 'endDate') &&
                          !isInTemplate && (
                            <DateInputField
                              values={values}
                              dateLabel={
                                typeToUse === CONTENT_TYPE.GLOBAL_BILL
                                  ? 'Due Date'
                                  : 'End Date'
                              }
                              timeLabel="End Time"
                              name="endDate"
                              timeFieldName="timeForEndDate"
                              disabled={
                                restrictedMode ||
                                !!createFromTemplate ||
                                (values.paymentTerms &&
                                  values.paymentTerms !== PAYMENT_TERMS.OTHER)
                              }
                              showTimeInput={typeToUse === CONTENT_TYPE.TASK}
                              templateMode={createFromTemplate}
                              setFieldValue={setFieldValue}
                              isAfterName="startDate"
                              tooltipText={
                                createFromTemplate
                                  ? 'Length Set By Template'
                                  : null
                              }
                            />
                          )}
                        {typeToUse === CONTENT_TYPE.TEMPLATE && (
                          <>
                            <Grid container item xs={12}>
                              <Grid item xs={12}>
                                <InputLabel
                                  className={classes.staticLabel}
                                  htmlFor="project-length"
                                >
                                  Project Length
                                </InputLabel>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={3}
                                style={{ paddingRight: 5 }}
                              >
                                <LevelNumberInput
                                  value={values.projectLength.value}
                                  onChange={value => {
                                    setFieldValue('projectLength', {
                                      ...values.projectLength,
                                      value,
                                      days: value * values.projectLength.unit,
                                    });
                                  }}
                                  inlineLabel=""
                                  min={1}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={3}
                                style={{ paddingLeft: 5 }}
                              >
                                <FormikSelect
                                  name="project-length-unit"
                                  value={values.projectLength.unit}
                                  options={DURATION_UNITS}
                                  handleChange={event => {
                                    const newDays =
                                      values.projectLength.value *
                                      event.target.value;

                                    setFieldValue('projectLength', {
                                      ...values.projectLength,
                                      days: newDays,
                                      unit: event.target.value,
                                    });
                                  }}
                                  disabled={restrictedMode}
                                />
                              </Grid>
                            </Grid>
                          </>
                        )}
                        {showPermissions && (
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              className={classes.staticLabel}
                              htmlFor="visualPermissions"
                              disabled={restrictedMode}
                            >
                              Permissions
                            </InputLabel>
                            {!restrictedMode && (
                              <Button
                                variant="outlined"
                                onClick={() => setOpenPermsDialog(true)}
                                className={classes.advancedButton}
                                startIcon={<PeopleIcon />}
                              >
                                {typeToUse === CONTENT_TYPE.TEMPLATE
                                  ? 'Share template with…'
                                  : 'Choose your crew...'}
                              </Button>
                            )}
                            {typeToUse !== CONTENT_TYPE.TEMPLATE &&
                              initialPermissionsUsers && (
                                <Grid container alignItems="center">
                                  <Typography
                                    style={{
                                      marginTop: '5px',
                                      marginBottom: '5px',
                                      fontStyle: 'italic',
                                      color: '#999',
                                    }}
                                  >
                                    {combinedConvoList.length} member
                                    {combinedConvoList.length !== 1
                                      ? 's'
                                      : ''}{' '}
                                    currently
                                  </Typography>
                                  {createFromTemplate && (
                                    <Tooltip title="These permissions will be applied to all sub-projects that are included in the template. After creation, user permissions can be added or removed individually.">
                                      <HelpOutlineIcon
                                        fontSize="small"
                                        color="inherit"
                                        style={{ marginLeft: 5 }}
                                      />
                                    </Tooltip>
                                  )}
                                </Grid>
                              )}

                            <Query
                              query={GetUserInfo}
                              variables={{
                                userId:
                                  'willBePulledFromCognitoSubContentInResolver',
                              }}
                            >
                              {({ loading: userInfoLoading, error, data }) => {
                                if (userInfoLoading) return null;
                                if (error)
                                  return 'Sorry, there was an error. Please retry.';
                                const me = data.getMyUserInfo || null;
                                return (
                                  <PickUserPermissionsDialog
                                    myCrew={myCrew}
                                    users={_.uniqBy(
                                      [me, ...myCrew, ...allUsersOnProject],
                                      'userId'
                                    )}
                                    single
                                    hideViewOption={
                                      values.type === CONTENT_TYPE.CONVERSATION
                                    }
                                    open={openPermsDialog}
                                    value={{
                                      allowedToEdit:
                                        [...values.allowedToEdit] || [],
                                      allowedToAdd:
                                        [...values.allowedToAdd] || [],
                                      allowedToView:
                                        [...values.allowedToView] || [],
                                    }}
                                    onClose={userLists => {
                                      if (userLists) {
                                        setValues({
                                          ...values,
                                          allowedToEdit:
                                            userLists.allowedToEdit,
                                          allowedToAdd: userLists.allowedToAdd,
                                          allowedToView:
                                            userLists.allowedToView,
                                        });
                                      }
                                      setOpenPermsDialog(false);
                                    }}
                                    permissionsForContentType={typeToUse}
                                  />
                                );
                              }}
                            </Query>
                          </Grid>
                        )}
                        {[
                          CONTENT_TYPE.PROJECT,
                          CONTENT_TYPE.TEMPLATE,
                          CONTENT_TYPE.LEAD,
                        ].includes(typeToUse) && (
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              className={classes.staticLabel}
                              htmlFor="contentStatus"
                              disabled={restrictedMode}
                            >
                              Budgets
                            </InputLabel>

                            <Button
                              variant="outlined"
                              onClick={() => setOpenBudgetsDialog(true)}
                              className={classes.advancedButton}
                              startIcon={<EqualizerIcon />}
                            >
                              Set Budgets...
                            </Button>
                            {openBudgetsDialog && (
                              <BudgetsDialog
                                open
                                projectId={values.contentId}
                                useTimetracking={values.useTimetracking}
                                handleClose={() => setOpenBudgetsDialog(false)}
                                handleDone={({ budgets, userPayInfo }) => {
                                  setFieldValue('budgets', budgets, false);
                                  setFieldValue(
                                    'userPayInfo',
                                    userPayInfo,
                                    false
                                  );

                                  setOpenBudgetsDialog(false);
                                }}
                                allUsers={_.uniqBy(
                                  [
                                    ...allUsersOnProject,
                                    ...[
                                      ...values.allowedToAdd,
                                      ...values.allowedToEdit,
                                      ...values.allowedToView,
                                    ].map(id => ({ userId: id })),
                                  ],
                                  'userId'
                                )}
                                activeUserIds={_.uniq([
                                  ...values.allowedToAdd,
                                  ...values.allowedToEdit,
                                  ...values.allowedToView,
                                ])}
                                localBudgetInfo={{
                                  budgets: values.budgets,
                                  userPayInfo: values.userPayInfo,
                                }}
                              />
                            )}
                          </Grid>
                        )}
                        {(_.includes(userEditableFields, 'contentStatus') ||
                          _.includes(userEditableFields, 'priority') ||
                          _.includes(userEditableFields, 'assignedTo')) && (
                          <Grid item xs={12}>
                            <Grid container direction="row" spacing={2}>
                              {_.includes(
                                userEditableFields,
                                'contentStatus'
                              ) && (
                                <>
                                  {typeToUse === 'timetracking' && (
                                    <Grid item xs={12} sm={3}>
                                      <InputLabel
                                        className={classes.staticLabel}
                                        htmlFor="contentStatus"
                                        disabled={restrictedMode}
                                      >
                                        Type
                                      </InputLabel>
                                      <FormikSelect
                                        name="contentStatus"
                                        value={values.contentStatus}
                                        error={errors.contentStatus}
                                        options={timetrackingStatuses}
                                        handleChange={event => {
                                          setFieldValue(
                                            'contentStatus',
                                            event.target.value,
                                            false
                                          );
                                        }}
                                        disabled={restrictedMode}
                                      />
                                    </Grid>
                                  )}
                                  {typeToUse === 'task' && (
                                    <Grid item xs={12} sm={3}>
                                      <InputLabel
                                        className={classes.staticLabel}
                                        htmlFor="contentStatus"
                                        disabled={restrictedMode}
                                      >
                                        Item Status
                                      </InputLabel>
                                      <FormikSelect
                                        name="contentStatus"
                                        value={values.contentStatus}
                                        error={errors.contentStatus}
                                        options={
                                          values.requireAdmin
                                            ? adminTaskStatusesForAdmins
                                            : TASK_STATUS_OPTIONS
                                        }
                                        handleChange={event => {
                                          setFieldValue(
                                            'contentStatus',
                                            event.target.value,
                                            false
                                          );
                                        }}
                                        disabled={restrictedMode}
                                      />
                                    </Grid>
                                  )}
                                  {typeToUse === CONTENT_TYPE.LEAD && (
                                    <Grid item xs={12}>
                                      <InputLabel
                                        className={classes.staticLabel}
                                        htmlFor="contentStatus"
                                        disabled={restrictedMode}
                                      >
                                        Lead Status
                                      </InputLabel>
                                      <ChangeLeadStatusRadioButtons
                                        initialStatus={values.contentStatus}
                                        onChange={value => {
                                          setFieldValue(
                                            'contentStatus',
                                            value,
                                            false
                                          );
                                        }}
                                        buttonPadding={8}
                                      />
                                    </Grid>
                                  )}
                                </>
                              )}
                              {_.includes(userEditableFields, 'priority') && (
                                <Grid item xs={12} sm={3}>
                                  <InputLabel
                                    className={classes.staticLabel}
                                    htmlFor="priority"
                                    disabled={restrictedMode}
                                  >
                                    Item Priority
                                  </InputLabel>
                                  <FormikSelect
                                    name="priority"
                                    value={values.priority}
                                    error={errors.priority}
                                    // touched={touched.priority}
                                    options={taskPriorities}
                                    handleChange={event => {
                                      setFieldValue(
                                        'priority',
                                        event.target.value,
                                        false
                                      );
                                    }}
                                    disabled={restrictedMode}
                                  />
                                </Grid>
                              )}
                              {_.includes(userEditableFields, 'assignedTo') && (
                                <>
                                  <Grid item xs={12} sm={6}>
                                    <InputLabel
                                      className={classes.staticLabel}
                                      htmlFor="assignedTo"
                                      disabled={restrictedMode || isInTemplate}
                                    >
                                      Assigned To
                                    </InputLabel>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ position: 'relative' }}
                                    >
                                      <MuiBasicTextField
                                        className={`${
                                          classes.assignedToInput
                                        } ${
                                          restrictedMode || isInTemplate
                                            ? classes.assignedToInputDisabled
                                            : ''
                                        }`}
                                        name="visualAssignedTo"
                                        disabled
                                        type="text"
                                        fullWidth
                                        margin="none"
                                        value={assignedToToShow}
                                      />
                                      {typeToUse === 'timetracking' &&
                                        localCurrentTimetrackingStatus && (
                                          <Typography variant="caption">
                                            {visualAssignedTo === null
                                              ? userInfo.username
                                              : visualAssignedTo}{' '}
                                            is currently clocked{' '}
                                            {localCurrentTimetrackingStatus ===
                                            'checkin'
                                              ? 'in'
                                              : 'out'}
                                          </Typography>
                                        )}
                                      {!restrictedMode && !isInTemplate && (
                                        <Button
                                          style={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                            background: 'transparent',
                                            width: '100%',
                                          }}
                                          onClick={showAssignedToDialog}
                                        >
                                          {' '}
                                        </Button>
                                      )}
                                    </Grid>
                                  </Grid>
                                  <PickUserDialog
                                    options={allUsersOnProject || []}
                                    allowNull={typeToUse === 'task'}
                                    single
                                    open={showPickUserDialog}
                                    value={values.assignedTo || 'unassigned'}
                                    onClose={handlePickUser}
                                  />
                                </>
                              )}
                            </Grid>
                          </Grid>
                        )}
                        {typeToUse === 'task' && (
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              className={classes.staticLabel}
                              htmlFor="completionType"
                              disabled={restrictedMode}
                            >
                              Completion Requirement
                            </InputLabel>
                            <FormikSelect
                              name="contentStatus"
                              value={values.completionType}
                              error={errors.completionType}
                              options={completionTypes}
                              handleChange={event => {
                                setFieldValue(
                                  'completionType',
                                  event.target.value,
                                  false
                                );
                              }}
                              disabled={restrictedMode}
                            />
                          </Grid>
                        )}
                        {typeToUse === 'task' &&
                          values.completionType !== 'none' && (
                            <Grid item xs={12} sm={6}>
                              <Field
                                name="completionInfo_instructions"
                                placeholder="e.g. Take a picture of..."
                                label="Requirement Instructions"
                                InputLabelProps={{ shrink: true }}
                                value={values.completionInfo_instructions}
                                type="text"
                                component={TextField}
                                fullWidth
                                style={{ top: '-2px' }}
                                disabled={restrictedMode}
                              />
                            </Grid>
                          )}
                        {typeToUse === CONTENT_TYPE.PROJECT &&
                          editingMode &&
                          initialValues.contentId &&
                          !restrictedMode &&
                          !isInTemplate && (
                            <Grid item xs={12} sm={6}>
                              <InputLabel
                                className={classes.staticLabel}
                                htmlFor="slideshowSettings"
                                disabled={restrictedMode}
                              >
                                Slideshow
                              </InputLabel>
                              <Button
                                variant="outlined"
                                onClick={() => setOpenSlideshowDialog(true)}
                                className={classes.advancedButton}
                                startIcon={<ScreenShareIcon />}
                              >
                                Slideshow Settings...
                              </Button>
                              {openSlideshowDialog && (
                                <SlideshowSettingsDialog
                                  open
                                  onClose={() => setOpenSlideshowDialog(false)}
                                  projectId={initialValues.contentId}
                                />
                              )}
                            </Grid>
                          )}
                        {!hideCustomerOption &&
                          _.includes(userEditableFields, 'customerId') &&
                          !isSubproject && (
                            <Grid item xs={12} sm={6}>
                              <ChooseCustomer
                                open={chooseCustomerOpen}
                                mode={values.type}
                                errorMessage={errors.customerId}
                                onChoose={passedCustomer => {
                                  setFieldValue(
                                    'customerInfo',
                                    passedCustomer || undefined
                                  );
                                  setFieldValue(
                                    'customerId',
                                    (passedCustomer &&
                                      passedCustomer.customerId) ||
                                      undefined
                                  );

                                  if (
                                    !values.address &&
                                    passedCustomer &&
                                    passedCustomer.address
                                  ) {
                                    // open a modal to ask if they want to set the address to the customer's address
                                    openAddressDialog(passedCustomer);
                                  }
                                }}
                                initialId={values.customerId}
                                onClose={() => setChooseCustomerOpen(false)}
                                disabledMessage={
                                  values.qboCustomerId &&
                                  values.qboCustomerId !== 'NEW'
                                    ? 'Changing the customer of a QuickBooks Online synced project is not available in Level. Please perform this action from QuickBooks Online.'
                                    : null
                                }
                              />
                              {values.customerInfo &&
                                values.customerInfo.customerId && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      marginTop: 4,
                                    }}
                                  >
                                    <Typography variant="caption">
                                      Assigned to:{' '}
                                      {values.customerInfo.firstName ||
                                      values.customerInfo.lastName ? (
                                        <>
                                          {values.customerInfo.firstName}
                                          {!!values.customerInfo.firstName &&
                                            !!values.customerInfo.lastName &&
                                            ' '}
                                          {values.customerInfo.lastName}
                                        </>
                                      ) : (
                                        values.customerInfo.companyName || ''
                                      )}
                                    </Typography>
                                    <QboSyncStatusIcon
                                      mode="inline"
                                      recordInfo={values.customerInfo}
                                      recordType={QBO_SYNCABLE_TYPE.CUSTOMER}
                                    />
                                  </div>
                                )}
                            </Grid>
                          )}
                      </Grid>

                      {showGeneralOptions && (
                        <Grid
                          container
                          item
                          xs={12}
                          style={{
                            width: '100%',
                            justifyContent: 'flex-end',
                            marginTop: 32,
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              borderBottom: '1px solid #ccc',
                              paddingBottom: 8,
                            }}
                          >
                            <InputLabel className={classes.generalOptionsTitle}>
                              General Options
                            </InputLabel>
                          </Grid>
                          {newProjectOrLeadAndCustomerSyncEnabled && (
                            <Grid
                              container
                              item
                              xs={12}
                              style={{
                                borderBottom: '1px solid #ccc',
                              }}
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <InputLabel
                                  className={classes.inlineLabel}
                                  disabled={isSubproject}
                                >
                                  Sync to QuickBooks Online?
                                </InputLabel>
                                {isSubproject && (
                                  <Tooltip title="Sub-projects cannot be synced to QuickBooks Online">
                                    <HelpOutlineIcon
                                      fontSize="small"
                                      style={{
                                        marginLeft: 5,
                                        color: '#999999',
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </div>
                              <MuiSwitch
                                name="syncToQbo"
                                value={values.qboCustomerId}
                                checked={!!values.qboCustomerId}
                                variant="inline"
                                onChange={() => {
                                  setFieldValue(
                                    'qboCustomerId',
                                    values.qboCustomerId === 'NEW'
                                      ? null
                                      : 'NEW'
                                  );
                                }}
                                disabled={isSubproject}
                              />
                            </Grid>
                          )}
                          {typeToUse === 'task' && (
                            <Grid
                              container
                              item
                              xs={12}
                              style={{ width: '100%' }}
                            >
                              {_.includes(
                                userEditableFields,
                                'requireAdmin'
                              ) && (
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    borderBottom: '1px solid #ccc',
                                  }}
                                >
                                  <InputLabel
                                    className={classes.inlineLabel}
                                    disabled={restrictedMode}
                                  >
                                    Require admin approval to complete?
                                  </InputLabel>

                                  <MuiSwitch
                                    name="requireAdmin"
                                    value={values.requireAdmin}
                                    checked={values.requireAdmin}
                                    variant="inline"
                                    onChange={handleChange}
                                    disabled={restrictedMode}
                                  />
                                </Grid>
                              )}

                              {_.includes(userEditableFields, 'subtype') &&
                                !isParentExtended && (
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      borderBottom: '1px solid #ccc',
                                    }}
                                  >
                                    <InputLabel
                                      className={classes.inlineLabel}
                                      disabled={restrictedMode}
                                    >
                                      Will include smaller tasks inside it
                                      (sub-tasks)?
                                    </InputLabel>
                                    <MuiSwitch
                                      name="subtype"
                                      value={values.subtype}
                                      checked={values.subtype === 'extended'}
                                      variant="inline"
                                      onChange={event => {
                                        setFieldValue(
                                          'subtype',
                                          event.target.value
                                            ? undefined
                                            : 'extended'
                                        );
                                      }}
                                      disabled={restrictedMode}
                                    />
                                  </Grid>
                                )}
                            </Grid>
                          )}
                          {[
                            CONTENT_TYPE.PROJECT,
                            CONTENT_TYPE.TEMPLATE,
                          ].includes(typeToUse) && (
                            <>
                              {_.includes(
                                userEditableFields,
                                'includeSubsInStats'
                              ) &&
                                !isSubproject && (
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      borderBottom: '1px solid #ccc',
                                    }}
                                  >
                                    <InputLabel
                                      className={classes.inlineLabel}
                                      disabled={restrictedMode}
                                    >
                                      Include sub-projects when calculating
                                      stats?
                                    </InputLabel>
                                    <MuiSwitch
                                      name="includeSubsInStats"
                                      value={values.includeSubsInStats}
                                      checked={values.includeSubsInStats}
                                      variant="inline"
                                      onChange={handleChange}
                                      disabled={restrictedMode}
                                    />
                                  </Grid>
                                )}
                              {_.includes(
                                userEditableFields,
                                'excludeFromStats'
                              ) &&
                                isSubproject && (
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      borderBottom: '1px solid #ccc',
                                    }}
                                  >
                                    <InputLabel
                                      className={classes.inlineLabel}
                                      disabled={restrictedMode}
                                    >
                                      Exclude this project when calculating
                                      parent stats?
                                    </InputLabel>
                                    <MuiSwitch
                                      name="excludeFromStats"
                                      value={values.excludeFromStats}
                                      checked={values.excludeFromStats}
                                      variant="inline"
                                      onChange={handleChange}
                                      disabled={restrictedMode}
                                    />
                                  </Grid>
                                )}
                              {_.includes(
                                userEditableFields,
                                'useTimetracking'
                              ) && (
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    borderBottom: '1px solid #ccc',
                                  }}
                                >
                                  <InputLabel
                                    className={classes.inlineLabel}
                                    disabled={restrictedMode}
                                  >
                                    Use time tracking (clock-ins and
                                    clock-outs)?
                                  </InputLabel>
                                  <MuiSwitch
                                    name="useTimetracking"
                                    value={values.useTimetracking}
                                    checked={values.useTimetracking}
                                    variant="inline"
                                    onChange={handleChange}
                                    disabled={restrictedMode}
                                  />
                                </Grid>
                              )}
                              {_.includes(
                                userEditableFields,
                                'useTimetrackingQuestions'
                              ) &&
                                values.useTimetracking && (
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      borderBottom: '1px solid #ccc',
                                    }}
                                  >
                                    <InputLabel
                                      className={classes.inlineLabel}
                                      disabled={restrictedMode}
                                    >
                                      Ask questions on clock-in/out?
                                    </InputLabel>
                                    <div>
                                      {values.useTimetrackingQuestions && (
                                        <Button
                                          variant="outlined"
                                          onClick={onEditQuestionsClick}
                                          disabled={loadingQuestions}
                                          size="small"
                                        >
                                          {loadingQuestions && (
                                            <CircularProgress
                                              size={16}
                                              style={{ marginRight: 8 }}
                                            />
                                          )}
                                          Edit questions
                                        </Button>
                                      )}
                                      <MuiSwitch
                                        name="useTimetrackingQuestions"
                                        value={values.useTimetrackingQuestions}
                                        checked={
                                          values.useTimetrackingQuestions
                                        }
                                        variant="inline"
                                        onChange={handleChange}
                                      />
                                    </div>
                                    {openQuestionsDialog && (
                                      <QuestionsDialog
                                        open={openQuestionsDialog}
                                        projectInfo={values}
                                        existingQuestions={projectQuestions}
                                        handleClose={() =>
                                          setOpenQuestionsDialog(false)
                                        }
                                        handleDone={data => {
                                          setValues(currentState => ({
                                            ...currentState,
                                            timetrackingQuestions:
                                              data.checkinQuestionIds,
                                            timetrackingQuestionsCheckout:
                                              data.checkoutQuestionIds,
                                          }));
                                          setProjectQuestions(
                                            data.allQuestions
                                          );
                                          setOpenQuestionsDialog(false);
                                        }}
                                        allUsers={allUsersOnProject}
                                      />
                                    )}
                                  </Grid>
                                )}
                              {_.includes(
                                userEditableFields,
                                'timetrackingType'
                              ) &&
                                values.useTimetracking && (
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      borderBottom: '1px solid #ccc',
                                    }}
                                  >
                                    <Grid
                                      item
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <InputLabel
                                        className={classes.inlineLabel}
                                        disabled={
                                          restrictedMode ||
                                          globalAllowCrewEditingEnabled
                                        }
                                      >
                                        Allow editable clock-ins/outs?
                                      </InputLabel>
                                      <Tooltip
                                        title={editableClockInMessageToShow}
                                      >
                                        <HelpOutlineIcon
                                          fontSize="small"
                                          color="inherit"
                                          style={{
                                            marginLeft: 5,
                                            opacity: globalAllowCrewEditingEnabled
                                              ? 0.5
                                              : 1,
                                          }}
                                        />
                                      </Tooltip>
                                    </Grid>
                                    <MuiSwitch
                                      name="timetrackingType"
                                      value={values.timetrackingType}
                                      checked={
                                        (values.timetrackingType !== null &&
                                          values.timetrackingType !==
                                            'fixed') ||
                                        globalAllowCrewEditingEnabled
                                      }
                                      variant="inline"
                                      onChange={event => {
                                        setFieldValue(
                                          'timetrackingType',
                                          event.target.value === null ||
                                            event.target.value === 'fixed'
                                            ? 'fulledit'
                                            : 'fixed'
                                        );
                                      }}
                                      disabled={
                                        restrictedMode ||
                                        globalAllowCrewEditingEnabled
                                      }
                                    />
                                  </Grid>
                                )}
                            </>
                          )}
                        </Grid>
                      )}
                      {_.includes(userEditableFields, 'billable') &&
                        !isFinancial && (
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              borderBottom: '1px solid #ccc',
                            }}
                          >
                            <Grid
                              item
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <InputLabel
                                className={classes.inlineLabel}
                                disabled={restrictedMode}
                              >
                                Is this a billable project?
                              </InputLabel>
                              <Tooltip title="A billable project is a revenue-generating project. Toggle this on to have all time entries associated with this project marked as billable hours, so that you can analyze all your billable and non-billable hours from your Level Scoreboard.">
                                <HelpOutlineIcon
                                  fontSize="small"
                                  color="inherit"
                                  style={{ marginLeft: 5 }}
                                />
                              </Tooltip>
                            </Grid>
                            <MuiSwitch
                              name="billable"
                              value={values.billable}
                              checked={values.billable}
                              variant="inline"
                              onChange={handleChange}
                              disabled={restrictedMode}
                            />
                          </Grid>
                        )}
                      {((_.includes(userEditableFields, 'amount') &&
                        !(isGlobalExpense || isGlobalPayment)) ||
                        _.includes(userEditableFields, 'labels') ||
                        typeToUse === 'timetracking') && (
                        <Grid container spacing={2} style={{ marginTop: 16 }}>
                          {_.includes(userEditableFields, 'labels') && ( // always put labels on their own line
                            <Grid item xs={12} sm={8}>
                              <InputLabel
                                className={classes.staticLabel}
                                htmlFor="labels"
                              >
                                Labels
                              </InputLabel>
                              <FormikCreatableSelect
                                options={allLabelOptions}
                                value={values.labels}
                                onChange={(name, value) => {
                                  // avoid a null value which throws a Formik error and stops the submit
                                  setFieldValue('labels', value || []);
                                }}
                                onBlur={setFieldTouched}
                                error={errors?.labels}
                                touched={touched?.labels}
                                placeholder="Select or type to create new ..."
                                name="labels"
                                isMulti
                                menuPlacement="top"
                              />
                              <Typography variant="caption">
                                Useful when filtering later
                              </Typography>
                            </Grid>
                          )}
                          {_.includes(userEditableFields, 'amount') &&
                            !(isGlobalExpense || isGlobalPayment) && (
                              <Grid item xs={12} sm={4}>
                                <AmountField
                                  classes={classes}
                                  error={errors?.amount}
                                  touched={touched?.amount}
                                  onBlur={() => setFieldTouched('amount')}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                />
                              </Grid>
                            )}
                          {typeToUse === 'timetracking' &&
                            (values.contentStatus === 'checkin' ||
                              values.contentStatus === 'checkout') && (
                              <EditAnswersButton
                                classes={classes}
                                getProjectQuestions={getProjectQuestions}
                                parentInfo={parentInfo}
                                setDidAnswersChange={setDidAnswersChange}
                                setFieldValue={setFieldValue}
                                values={values}
                              />
                            )}
                        </Grid>
                      )}
                      {isFinancial && !isGlobalPayment && (
                        <>
                          {typeToUse !== CONTENT_TYPE.GLOBAL_BILL && (
                            <Grid
                              item
                              xs={12}
                              style={{
                                marginTop: 32,
                                borderBottom: '1px solid #ccc',
                                paddingBottom: 8,
                              }}
                            >
                              <InputLabel
                                className={classes.generalOptionsTitle}
                              >
                                General Options
                              </InputLabel>
                            </Grid>
                          )}
                          {!isGlobalExpense && (
                            <FinancialTypeButtonsSegment
                              classes={classes}
                              defaultType={defaultType}
                              error={errors?.type}
                              financialSegmentKey={financialSegmentKey}
                              setFieldValue={setFieldValue}
                              values={values}
                            />
                          )}
                          {(values.type === CONTENT_TYPE.RECEIPT ||
                            values.type === CONTENT_TYPE.GLOBAL_RECEIPT) && (
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderBottom: '1px solid #ccc',
                              }}
                            >
                              <InputLabel className={classes.inlineLabel}>
                                Is this a refund?
                              </InputLabel>
                              <MuiSwitch
                                name="contentStatus"
                                checked={!!values.contentStatus}
                                value={!!values.contentStatus}
                                variant="inline"
                                onChange={handleChange}
                                color="primary"
                              />
                            </Grid>
                          )}
                          {[CONTENT_TYPE.RECEIPT, CONTENT_TYPE.BILL].includes(
                            typeToUse
                          ) && (
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderBottom: '1px solid #ccc',
                              }}
                            >
                              <InputLabel
                                className={classes.inlineLabel}
                                disabled={restrictedMode}
                              >
                                Is this expense billable?
                              </InputLabel>
                              <MuiSwitch
                                name="billable"
                                value={!!values.billable}
                                checked={!!values.billable}
                                variant="inline"
                                onChange={handleChange}
                                disabled={restrictedMode}
                              />
                            </Grid>
                          )}
                        </>
                      )}
                      {isGlobalExpense && (
                        <ExpenseProjectSplitter
                          projectList={projectList}
                          setProjectList={setProjectList}
                          amount={values.amount}
                          invalidFieldMap={invalidFieldMap}
                          setInvalidFieldMap={setInvalidFieldMap}
                        />
                      )}
                      {isGlobalPayment && (
                        <BillPaymentBillSplitter
                          billList={billList}
                          setBillList={setBillList}
                          amount={values.amount}
                          vendorId={values.vendorId}
                          invalidFieldMap={invalidFieldMap}
                          setInvalidFieldMap={setInvalidFieldMap}
                          requireVendor
                        />
                      )}
                      <AddContentFormActions
                        classes={classes}
                        formikProps={formikProps}
                        contentType={typeToUse}
                        userEditableFields={userEditableFields}
                        values={values}
                        onChange={handleChange}
                        onBack={handleBack}
                        onBlur={handleBlur}
                        onComplete={onComplete}
                        parentIsLead={parentIsLead}
                        editingMode={editingMode}
                        createFromTemplate={createFromTemplate}
                        creatingProjectFromLead={creatingProjectFromLead}
                        fromShoebox={fromShoebox}
                        verifyAndSubmit={verifyAndSubmit}
                        setSplitWithAnother={setSplitWithAnother}
                        setAddAnother={setAddAnother}
                        setAssignFlowInfo={setAssignFlowInfo}
                        assignFlowInfo={assignFlowInfo}
                        setFieldValue={setFieldValue}
                        isFinancial={isFinancial}
                        isGlobalExpense={isGlobalExpense}
                        isGlobalPayment={isGlobalPayment}
                        saveButtonIndex={saveButtonIndex}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              {formikProps.isSubmitting && (
                <LoadingCover>
                  {typeToUse === CONTENT_TYPE.VIDEO ? (
                    <>
                      <Typography variant="h3" align="center">
                        Saving...
                        {uploadProgressPercent
                          ? `${uploadProgressPercent}%`
                          : ''}
                      </Typography>
                      <Typography variant="h3" align="center">
                        For long or high quality videos this can take a bit.
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="h3" align="center">
                      {createFromTemplate
                        ? 'Creating your new project...'
                        : 'Saving...'}
                    </Typography>
                  )}
                </LoadingCover>
              )}
              <NoImageDialog
                showNoImageDialog={showNoImageDialog}
                setShowNoImageDialog={setShowNoImageDialog}
                handleSubmit={handleSubmit}
              />
              {showValidationIssuesDialog.open && (
                <ValidationIssuesDialog
                  open={showValidationIssuesDialog.open}
                  setShowValidationIssuesDialog={setShowValidationIssuesDialog}
                  validationIssues={showValidationIssuesDialog.issues}
                  editingMode={editingMode}
                />
              )}
            </Form>
          );
        }}
      </Formik>
      <BasicDialog
        open={showUnknownIssueDialog}
        title="Hmmm..."
        handleClose={() => setShowUnknownIssueDialog(false)}
      >
        <span>
          Something went wrong and we couldn&apos;t save that. Please try again
          later.
        </span>
      </BasicDialog>
      <OkCancelDialog {...dialogInfo}>
        <Typography>{dialogInfo.message}</Typography>
      </OkCancelDialog>
      <AssignFlowInfoDialog
        assignFlowInfo={assignFlowInfo}
        setAssignFlowInfo={setAssignFlowInfo}
        handleParentChoice={handleParentChoice}
      />
      <BasicDialog
        open={!!simpleMessageDialog.open}
        title={simpleMessageDialog.title || 'Hmmm...'}
        handleClose={() =>
          setSimpleMessageDialog({ ...simpleMessageDialog, open: false })
        }
      >
        <span>{simpleMessageDialog.message}</span>
      </BasicDialog>

      <OkCancelDialog
        // dialog to alert the user to a possible duplicate upload
        open={!!alertPossibleDuplicate}
        customTitle={
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h5"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              Possible Duplicate
            </Typography>
            <WarningIcon style={{ color: '#e00', fontSize: 30 }} />
          </DialogTitle>
        }
        cancelButtonText="Proceed anyway"
        okButtonText="Cancel content creation"
        cancelButtonVariant="outlined"
        onClose={() => {
          if (alertPossibleDuplicate) {
            setAlertPossibleDuplicate(null);
          }
        }}
        onConfirm={() => {
          setAlertPossibleDuplicate(null);
          onComplete({ cancelled: true });
        }}
      >
        <Typography variant="body1" gutterBottom>
          It looks like this document has been uploaded before
          {alertPossibleDuplicate?.duplicatePath ? (
            <>
              {' '}
              to &quot;<b>{alertPossibleDuplicate.duplicatePath}</b>&quot;
            </>
          ) : (
            <>
              {!alertPossibleDuplicate?.isGlobalItem && (
                <>
                  {' '}
                  but you may not have access to it or the project it was
                  uploaded to
                </>
              )}
            </>
          )}
          . Please ensure that this is not a duplicate before proceeding.
        </Typography>
        {managingCompanyInfo.isCompanyAdmin &&
          !!alertPossibleDuplicate?.duplicateInfo?.[0] && (
            <Grid container justifyContent="flex-end">
              {!alertPossibleDuplicate.isGlobalItem &&
              alertPossibleDuplicate.href ? (
                <>
                  <Button
                    variant="outlined"
                    href={alertPossibleDuplicate.href}
                    target="_blank"
                  >
                    View Existing Item On Project
                  </Button>
                </>
              ) : (
                <Button
                  variant="outlined"
                  onClick={handleViewingGlobalDupeOriginal}
                  style={{ minWidth: 80 }}
                  disabled={loadingDupeOriginalInfo}
                >
                  {loadingDupeOriginalInfo ? (
                    <CenteredSpinner size={18} customPadding={1} />
                  ) : (
                    <>View Existing Item</>
                  )}
                </Button>
              )}
            </Grid>
          )}
      </OkCancelDialog>

      {showContentDetailsModal.open && (
        <ContentDetailsModal
          mode={showContentDetailsModal.mode}
          modeInfo={showContentDetailsModal.modeInfo}
          parentInfo={showContentDetailsModal.projectInfo}
          existingInfo={showContentDetailsModal.existingInfo}
          contentToDelete={showContentDetailsModal.contentToDelete}
          onClose={() => {
            setShowContentDetailsModal(initialSetShowContentDetailsModal);
          }}
          // onViewGlobalItem={handleViewGlobalItem}
          // onDeleteBillPayment={handleDeleteBillPayment}
          hideDeleteButton
        />
      )}

      {assignFlowInfo.loading && (
        <LoadingCover>
          <Typography variant="h3" align="center">
            Loading...
          </Typography>
        </LoadingCover>
      )}
      {showAutofillLoading && (
        <LoadingCover>
          <Typography variant="h3" align="center">
            Loading Autofill Info...
          </Typography>
        </LoadingCover>
      )}
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
    uploadProgressPercent: state.appState.uploadProgressPercent || null,
  };
}

export default connect(mapStateToProps)(
  compose(withApollo)(AddContentFormView)
);
