import gql from 'graphql-tag';

export default gql`
  mutation UpdateUnreadCount($unreadCount: Int!) {
    updateUnreadCount(input: { unreadCount: $unreadCount }) {
      __typename
      status
      msg
    }
  }
`;
