import React from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import {
  CheckCircle as CheckIcon,
  BrokenImage as ErrorIcon,
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  submitButtonWrapper: {
    textAlign: 'right',
    marginTop: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: 40,
  },
  inlineLabel: {
    float: 'left',
    'text-transform': 'capitalize',
  },
  nonUploadIconWrapper: {
    color: theme.palette.brandColorPrimary,
    fontSize: 90,
  },
  successIconWrapper: {
    color: theme.palette.brandColorGreen,
    fontSize: 90,
  },
  errorIconWrapper: {
    color: theme.palette.brandColorMidGrey,
    fontSize: 90,
  },
  generalOptionsTitle: {
    color: theme.palette.brandColorPrimary,
  },
}));

const NewProjectResult = ({ errorOccurred, project, handleClose, history }) => {
  const classes = useStyles();

  // On go to project click
  const handleGoToProject = () => {
    if (project && project.contentId) {
      history.push(`/projects/${project.contentId}`);
    }
    handleClose({ setQueryTo: 'projects' });
  };

  const renderModalContent = () => {
    if (errorOccurred) {
      return (
        <>
          <Grid item className={classes.errorIconWrapper}>
            <ErrorIcon fontSize="inherit" color="inherit" />
          </Grid>
          <Grid item>
            <Typography variant="h4">
              Sorry! An error occurred while creating your project.
            </Typography>
          </Grid>
        </>
      );
    }
    return (
      <>
        <Grid item className={classes.successIconWrapper}>
          <CheckIcon fontSize="inherit" color="inherit" />
        </Grid>
        <Grid item>
          <Typography variant="h4">
            Your project was created successfully!
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleGoToProject}
          >
            Go to Project
          </Button>
        </Grid>
      </>
    );
  };

  return (
    <Grid container direction="column" style={{ flexGrow: 1 }}>
      <Grid container style={{ height: '100%', flexGrow: 1 }}>
        <Grid container item direction="column" xs={12} sm={12}>
          <Grid
            container
            item
            direction="column"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            xs={12}
            style={{ flex: 1 }}
          >
            {renderModalContent()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(NewProjectResult);
