import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  mutation CreateJrn(
    $contentId: ID!
    $jrnId: String
    $creatorId: String!
    $customerId: String
    $type: String!
    $subtype: String
    $date: String!
    $description: String
    $contentUrl: String
    $aspectRatio: String
    $isPublic: Boolean!
    $synced: Boolean
    $labels: [String]
    $latitude: String
    $longitude: String
    $address: String
    $title: String
    $endDate: String
    $privacyLevel: String
    $inviteCode: String
    $allowedToEdit: [String!]
    $allowedToAdd: [String]
    $allowedToView: [String]
    $usersEverOnJrn: [String]
    $startDate: String
    $addedBy: String
    $assignedTo: String
    $priority: String
    $contentStatus: String
    $useTimetracking: Boolean
    $useTimetrackingQuestions: Boolean
    $timetrackingQuestions: [String]
    $timetrackingQuestionsCheckout: [String]
    $billable: Boolean
    $budgets: [BudgetInput]
    $userPayInfo: [UserPayInfoInput]
    $fromTemplate: String
    $timetrackingType: String
    $managingCompanyId: String
    $fromLeadId: String
    $workflowStage: String
    $workflowStageReason: String
    $includeSubsInStats: Boolean
    $excludeFromStats: Boolean
    $qboCustomerId: String
    $amount: MoneyInput
    $paymentTerms: String
    $documentNumber: String
    $poNumber: String
    $vendorId: String
    $rfiIds: [String]
    $media: [MediaInfoInput]
    $ocrId: String
  ) {
    createJrn(
      input: {
        contentId: $contentId
        jrnId: $jrnId
        creatorId: $creatorId
        customerId: $customerId
        type: $type
        subtype: $subtype
        date: $date
        description: $description
        contentUrl: $contentUrl
        aspectRatio: $aspectRatio
        isPublic: $isPublic
        synced: $synced
        labels: $labels
        latitude: $latitude
        longitude: $longitude
        address: $address
        title: $title
        endDate: $endDate
        privacyLevel: $privacyLevel
        inviteCode: $inviteCode
        allowedToEdit: $allowedToEdit
        allowedToAdd: $allowedToAdd
        allowedToView: $allowedToView
        usersEverOnJrn: $usersEverOnJrn
        startDate: $startDate
        addedBy: $addedBy
        assignedTo: $assignedTo
        priority: $priority
        contentStatus: $contentStatus
        useTimetracking: $useTimetracking
        useTimetrackingQuestions: $useTimetrackingQuestions
        timetrackingQuestions: $timetrackingQuestions
        timetrackingQuestionsCheckout: $timetrackingQuestionsCheckout
        billable: $billable
        budgets: $budgets
        userPayInfo: $userPayInfo
        fromTemplate: $fromTemplate
        timetrackingType: $timetrackingType
        managingCompanyId: $managingCompanyId
        fromLeadId: $fromLeadId
        workflowStage: $workflowStage
        workflowStageReason: $workflowStageReason
        includeSubsInStats: $includeSubsInStats
        excludeFromStats: $excludeFromStats
        qboCustomerId: $qboCustomerId
        amount: $amount
        paymentTerms: $paymentTerms
        documentNumber: $documentNumber
        poNumber: $poNumber
        vendorId: $vendorId
        rfiIds: $rfiIds
        media: $media
        ocrId: $ocrId
      }
    ) {
      contentId
      jrnId
      title
      creatorId
      customerId
      customerInfo {
        customerId
        managingCompanyId
        firstName
        lastName
        address
        email
        latitude
        longitude
        phoneNumber
        notes
        companyName
      }
      contentUrl
      description
      privacyLevel
      inviteCode
      endDate
      startDate
      allowedToEdit
      allowedToAdd
      allowedToView
      usersEverOnJrn
      synced
      labels
      latitude
      longitude
      address
      type
      addedBy
      date
      isPublic
      aspectRatio
      phoneNumber
      phoneNumberActive
      assignedTo
      priority
      contentStatus
      useTimetracking
      useTimetrackingQuestions
      timetrackingQuestions
      timetrackingQuestionsCheckout
      billable
      timetrackingAnswers {
        questionId
        answer
      }
      subtype
      permissionsFrom
      requireAdmin
      completionType
      completionInfo {
        instructions
        completedById
        completedByDate
        textResponse
        mediaInfo {
          uri
          aspectRatio
        }
      }
      comments {
        items {
          content
          commentId
          dateCreated
          userId
        }
      }
      loves {
        items {
          loveId
          userId
          contentId
        }
      }
      amount {
        value
      }
      recurrence {
        rangeId
        rangeStartDate
        rangeEndDate
        daysOfTheWeek
      }
      # REMOVEME: remove this when "bugets" field is deprecated
      budgets {
        ...BudgetFields
      }
      fromTemplate
      timetrackingType
      workflowStage
      workflowStageReason
      lastMessageSent
      includeSubsInStats
      excludeFromStats
      qboCustomerId
      amount {
        value
      }
      paymentTerms
      documentNumber
      poNumber
      vendorId
      balance {
        value
      }
      rfiIds
    }
  }
`;
