import gql from 'graphql-tag';

export default gql`
  query GetAllContentByGlobalExpenseId($globalExpenseId: String!) {
    getAllContentByGlobalExpenseId(globalExpenseId: $globalExpenseId) {
      items {
        contentId
        jrnId
        jrn {
          contentId
          type
          subtype
          vendorId
          title
          jrnId
          description
          contentUrl
          aspectRatio
          contentStatus
          amount {
            value
          }
          documentNumber
          date
          startDate
          endDate
          creatorId
          labels
          allowedToAdd
          allowedToEdit
          allowedToView
          jrn {
            contentId
            title
            jrnId
            contentUrl
          }
        }
        creatorId
        type
        subtype
        contentUrl
        date
        description
        aspectRatio
        labels
        comments {
          items {
            content
            commentId
            dateCreated
            userId
          }
        }
        endDate
        startDate
        assignedTo
        contentStatus
        amount {
          value
        }
        billable
        globalExpenseId
      }
    }
  }
`;
