import { ButtonBase, Grid, Typography } from '@material-ui/core';
import React from 'react';
import userflow from 'userflow.js';

import camWave from '../../../../assets/images/cam/copilot_mascot_003.png';

const CamHelper = () => {
  const handleCamClick = () => {
    const resourceCenter = userflow.getResourceCenterState();
    if (resourceCenter?.isOpen) {
      userflow.closeResourceCenter();
    } else {
      userflow.openResourceCenter();
    }
  };

  return (
    <ButtonBase
      onClick={handleCamClick}
      style={{ width: '100%', justifyContent: 'flex-start' }}
      id="cam-helper-button-wrapper"
    >
      <img
        src={camWave}
        alt="robot waving"
        style={{ maxWidth: '100%', width: 80 }}
      />
      <Grid container direction="column" style={{ textAlign: 'left' }}>
        <Typography variant="body1" style={{ paddingLeft: 16 }}>
          Need help?
        </Typography>
        <Typography variant="body1" style={{ paddingLeft: 16 }}>
          Click here!
        </Typography>
      </Grid>
    </ButtonBase>
  );
};

export default CamHelper;
