import React, { useState, useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import _ from 'lodash';

import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, Tooltip, IconButton } from '@material-ui/core';
import { Add as AddIcon, Refresh as RefreshIcon } from '@material-ui/icons';

// UI
import ListOfCustomersView from './list-of-customers-view';
import FilterList from '../../../components/FilterSearch/filterSearch';
import AddCustomerDialog from '../../../components/add-customer-dialog';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: `${theme.spacing(1)}px auto 0 auto`,
    width: '100%',
  },
}));

const ListOfCustomers = ({
  getCompanyCustomersLoading,
  getCompanyCustomersRefetch,
  companyCustomers,
  idOfCustomerBeingViewed,
  goToCustomer,
}) => {
  const classes = useStyles();

  const [sortedData, setSortedData] = useState(null);
  const [useThisData, setUseThisData] = useState(sortedData);
  useEffect(() => {
    const sorted = _.orderBy(companyCustomers, ['lastName'], ['asc']);
    setSortedData(sorted);
    setUseThisData(sorted);
  }, [companyCustomers]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [showAddCustomerDialog, setShowAddCustomerDialog] = useState({
    open: false,
  });

  if (getCompanyCustomersLoading) {
    const howMany = [1, 2, 3, 4, 5];
    return howMany.map(key => (
      <Grid item key={key} style={{ width: '100%' }}>
        <Paper className={classes.paper}>
          <ContentLoader
            height={80}
            width={300}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            style={{ maxHeight: 80, maxWidth: 300 }}
          >
            <rect x="20" y="26" rx="0" ry="0" width="200" height="11" />
            <rect x="20" y="42" rx="0" ry="0" width="150" height="11" />
          </ContentLoader>
        </Paper>
      </Grid>
    ));
  }

  return (
    <Grid container item style={{ width: '100%' }}>
      <Grid container item xs={12} style={{ marginBottom: 16 }}>
        <Grid item style={{ flex: 1 }}>
          <FilterList
            data={sortedData}
            isFiltered={filterStatus => setIsFiltered(filterStatus)}
            passBack={filteredData => setUseThisData(filteredData)}
            searchBy={[
              'firstName',
              'lastName',
              'address',
              'phoneNumber',
              'companyName',
            ]}
          />
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            flex: 0,
            flexDirection: 'row',
            marginLeft: 8,
          }}
        >
          <Grid item>
            <Tooltip title="Refresh the list">
              <IconButton onClick={() => getCompanyCustomersRefetch()}>
                <RefreshIcon
                  style={{ fontSize: 'inherit', color: 'inherit' }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Add a Customer">
              <span>
                <IconButton
                  onClick={() => {
                    setShowAddCustomerDialog({
                      open: true,
                    });
                  }}
                >
                  <AddIcon style={{ fontSize: 'inherit', color: 'inherit' }} />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      <ListOfCustomersView
        data={useThisData}
        onClickOfCustomer={goToCustomer}
        idOfCustomerBeingViewed={idOfCustomerBeingViewed}
        isFiltered={isFiltered}
      />
      {showAddCustomerDialog.open && (
        <AddCustomerDialog
          initialValues={showAddCustomerDialog.existingCustomerInfo}
          toClose={newCustomerInfo => {
            setShowAddCustomerDialog({
              open: false,
            });
            if (newCustomerInfo && newCustomerInfo.customerId) {
              goToCustomer(newCustomerInfo);
            }
          }}
        />
      )}
    </Grid>
  );
};

export default ListOfCustomers;
