import React, { useState } from 'react';

import { WHO, DATA_TYPE, CALENDAR_MODE } from './calendar.constants';

import CalendarDataProvider from './calendar-data-provider';
import CalendarDisplay from './calendar-display';

const CalendarView = () => {
  const [whosData, setWhosData] = useState(WHO.MINE);
  const [calendarDataType, setCalendarDataType] = useState(DATA_TYPE.PROJECTS);
  const [calendarMode, setCalendarMode] = useState(CALENDAR_MODE.MY_PROJECTS);

  const handleSelectChange = selectEvent => {
    const newValue = selectEvent.target.value;

    setCalendarMode(newValue);

    switch (newValue) {
      case CALENDAR_MODE.MY_PROJECTS:
        setWhosData(WHO.MINE);
        setCalendarDataType(DATA_TYPE.PROJECTS);
        break;
      case CALENDAR_MODE.MY_TASKS:
        setWhosData(WHO.MINE);
        setCalendarDataType(DATA_TYPE.TASKS);
        break;
      case CALENDAR_MODE.MY_SHIFTS:
        setWhosData(WHO.MINE);
        setCalendarDataType(DATA_TYPE.SHIFTS);
        break;
      case CALENDAR_MODE.ADMIN_TASKS:
        setWhosData(WHO.MANAGED_BY_ME);
        setCalendarDataType(DATA_TYPE.TASKS);
        break;
      case CALENDAR_MODE.ADMIN_SHIFTS:
        setWhosData(WHO.MANAGED_BY_ME);
        setCalendarDataType(DATA_TYPE.SHIFTS);
        break;
      case CALENDAR_MODE.COMPANY_PROJECTS:
        setWhosData(WHO.COMPANY);
        setCalendarDataType(DATA_TYPE.PROJECTS);
        break;
      case CALENDAR_MODE.COMPANY_TASKS:
        setWhosData(WHO.COMPANY);
        setCalendarDataType(DATA_TYPE.TASKS);
        break;
      case CALENDAR_MODE.COMPANY_SHIFTS:
        setWhosData(WHO.COMPANY);
        setCalendarDataType(DATA_TYPE.SHIFTS);
        break;
      default:
        break;
    }
  };

  return (
    <CalendarDataProvider
      whosData={whosData}
      calendarDataType={calendarDataType}
    >
      <CalendarDisplay
        selectValue={calendarMode}
        handleSelectChange={handleSelectChange}
      />
    </CalendarDataProvider>
  );
};

export default CalendarView;
