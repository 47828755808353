import gql from 'graphql-tag';

export default gql`
  query GetMessagesByJrnId($jrnId: String!, $after: String) {
    getMessagesByJrnId(jrnId: $jrnId, after: $after) {
      items {
        __typename
        authorId
        content
        jrnId
        createdAt
        messageId
        synced
      }
      nextToken
    }
  }
`;
