import React, { useContext } from 'react';
import _ from 'lodash';
import { Grid, Avatar, makeStyles } from '@material-ui/core';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  Dashboard as DashboardIcon,
} from '@material-ui/icons';

import { CalendarDataContext } from './calendar-data-provider';
import { cloudinaryifyProfilePic } from '../../helpers/cloudinary';
import { WHO, DATA_TYPE } from './calendar.constants';
import { CONTENT_TYPE, TOP_PROJECT_ID } from '../../config/appDefaults';

const useStyles = makeStyles(() => ({
  smallAvatar: {
    height: 20,
    width: 20,
    marginRight: 3,
  },
  eventText: {
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const CalendarEvent = ({ event, timeText }) => {
  const classes = useStyles();

  const calendarDataContext = useContext(CalendarDataContext);

  const contentInfo = _.get(event, 'extendedProps.details');

  let parentInfo = null;
  if (contentInfo.jrnId && calendarDataContext.projectMap) {
    parentInfo = calendarDataContext.projectMap[contentInfo.jrnId] || null;
  }

  if (!contentInfo) {
    return null;
  }

  let showTimeText = true;
  let showUserImage = false;
  let assigneeInfo = null;
  let showContentImage = false;
  let contentImageToUse = null;
  let primaryText = '';
  let secondaryText = '';
  let backgroundColorToUse = null;
  if (calendarDataContext.calendarDataType === DATA_TYPE.PROJECTS) {
    let titleToUse = contentInfo.title || '';
    if (contentInfo?.jrnId && contentInfo.jrnId === TOP_PROJECT_ID) {
      const parentProject = calendarDataContext.projectMap[contentInfo.jrnId];
      if (parentProject?.title) {
        titleToUse = `${parentProject.title} > ${titleToUse}`;
      }
    }

    primaryText = titleToUse;
    contentImageToUse = contentInfo.contentUrl || null;
    showContentImage = true;
    if (calendarDataContext.projectColorMap[contentInfo.contentId]) {
      backgroundColorToUse =
        calendarDataContext.projectColorMap[contentInfo.contentId];
    }
  } else if (calendarDataContext.calendarDataType === DATA_TYPE.SHIFTS) {
    let titleToUse = parentInfo?.title || '';
    if (parentInfo?.jrnId && parentInfo.jrnId !== TOP_PROJECT_ID) {
      const parentProject =
        calendarDataContext.projectMap[parentInfo.jrnId] || null;
      if (parentProject?.title) {
        titleToUse = `${parentProject.title} > ${titleToUse}`;
      }
    }

    primaryText = titleToUse || 'Cannot access project'; // Project name

    if (calendarDataContext.projectColorMap[contentInfo.jrnId]) {
      backgroundColorToUse =
        calendarDataContext.projectColorMap[contentInfo.jrnId];
    }
    if (calendarDataContext.whosData === WHO.MINE) {
      showContentImage = true;
      contentImageToUse = parentInfo?.contentUrl || null;
    } else {
      showUserImage = true;
      if (
        contentInfo.assignedTo &&
        contentInfo.assignedTo !== 'unassigned' &&
        calendarDataContext.userMap
      ) {
        assigneeInfo = calendarDataContext.userMap[contentInfo.assignedTo];
      }
      primaryText = assigneeInfo?.username || 'Cannot access user'; // Username
      secondaryText = titleToUse || 'Cannot access project'; // Project name
    }
  } else if (calendarDataContext.calendarDataType === DATA_TYPE.TASKS) {
    const isSubTask = parentInfo?.type === CONTENT_TYPE.TASK;
    const parentProject = isSubTask
      ? calendarDataContext.projectMap[parentInfo?.jrnId]
      : parentInfo;

    let titleToUse = contentInfo.title || '';
    if (isSubTask) {
      titleToUse = `${parentInfo.title} > ${contentInfo.title}`;
    }

    let projectPathToUse = parentProject?.title || '';
    if (parentProject?.jrnId && parentProject.jrnId !== TOP_PROJECT_ID) {
      const topLevelProject =
        calendarDataContext.projectMap[parentProject.jrnId] || null;
      if (topLevelProject?.title) {
        projectPathToUse = `${topLevelProject.title} > ${projectPathToUse}`;
      }
    }

    primaryText = titleToUse; // Task name
    secondaryText = projectPathToUse || 'Cannot access project'; // Project name

    if (
      parentProject &&
      calendarDataContext.projectColorMap[parentProject.contentId]
    ) {
      backgroundColorToUse =
        calendarDataContext.projectColorMap[parentProject.contentId];
    }

    if (calendarDataContext.whosData === WHO.MINE) {
      showContentImage = true;
      contentImageToUse = contentInfo?.contentUrl || null;
    } else {
      showTimeText = false;
      showUserImage = true;
      if (
        contentInfo.assignedTo &&
        contentInfo.assignedTo !== 'unassigned' &&
        calendarDataContext.userMap
      ) {
        assigneeInfo = calendarDataContext.userMap[contentInfo.assignedTo];
      }
    }
  }

  return (
    <Grid
      container
      style={{
        cursor: 'pointer',
        alignItems: 'center',
        backgroundColor: backgroundColorToUse,
        flexWrap: 'nowrap',
      }}
    >
      {showUserImage && assigneeInfo && (
        <Grid item style={{ padding: 3, flex: 0 }}>
          <Avatar
            alt="user"
            src={
              assigneeInfo.profilePic
                ? cloudinaryifyProfilePic(assigneeInfo.profilePic)
                : null
            }
            className={classes.smallAvatar}
          />
        </Grid>
      )}
      {showContentImage && (
        <Grid item style={{ padding: 3, flex: 0 }}>
          <Avatar
            alt="project"
            src={
              contentImageToUse
                ? cloudinaryifyProfilePic(contentImageToUse)
                : null
            }
            className={classes.smallAvatar}
          >
            {calendarDataContext.calendarDataType === DATA_TYPE.PROJECTS && (
              <DashboardIcon fontSize="small" />
            )}
          </Avatar>
        </Grid>
      )}
      <Grid
        container
        item
        style={{
          flex: 1,
          padding: 3,
          overflow: 'hidden',
        }}
        direction="column"
      >
        {showTimeText && timeText && (
          <Grid item style={{ width: '100%' }}>
            {contentInfo.contentStatus === 'completed' && (
              <CheckCircleOutlineIcon
                style={{
                  marginLeft: 3,
                  marginRight: 3,
                  marginTop: 1,
                  color: '#fff',
                  fontSize: 12,
                }}
              />
            )}
            <p className={classes.eventText}>{timeText}</p>
          </Grid>
        )}
        {primaryText && (
          <Grid item style={{ width: '100%' }}>
            <p className={classes.eventText}>
              <b>{primaryText}</b>
            </p>
          </Grid>
        )}
        {secondaryText && (
          <Grid item style={{ width: '100%' }}>
            <p className={classes.eventText}>{secondaryText}</p>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CalendarEvent;
