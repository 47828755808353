/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { compose, withApollo } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import { connect } from 'react-redux';
import { withRouter, Redirect, useHistory } from 'react-router-dom';

import uuid from 'uuid';
import moment from 'moment';
import _ from 'lodash';
import omitDeep from 'omit-deep';
import { GoogleApiWrapper } from 'google-maps-react';
import { Grid } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import numeral from 'numeral';

import AdvancedShiftCreator from '../advanced-shift-creator';

import {
  CreateContentMutationAction,
  AddJrnMutationAction,
  UpdateJrnMutationAction,
  UpdateContentMutationAction,
  getAPresignedUrl,
  uploadToPresignedUrl,
  AddOrUpdateCompanyQuestionsAction,
  CreateProjectFromTemplateAction,
  GetCompanyCrewAction,
  GetCompanyInfoAction,
  DeleteContentAction,
} from '../../../graphql/graphql';
import GetJrn from '../../../graphql/queries/GetJrn';
import { createJrnInputMask } from '../../../graphql/masks/create-jrn-input';
import ListMyConversations from '../../../graphql/queries/ListMyConversations';
import GetUsersByJrnId from '../../../graphql/queries/GetUsersByJrnId';
import GetUsersByIds from '../../../graphql/queries/GetUsersByIds';
import GetAllContentByJrnId from '../../../graphql/queries/GetAllContentByJrnId';
import GetQuestionsByProjectId from '../../../graphql/queries/GetQuestionsByProjectId';
import GetAllContentByGlobalExpenseId from '../../../graphql/queries/get-all-content-by-global-expense-id';
import GetOcrDataForMedia from '../../../graphql/queries/get-ocr-data-for-media';

import {
  defaultLeadStatus,
  taskPrioritiesArray,
  DURATION_UNITS,
  FINANCIAL_TYPES,
  CONTENT_TYPE,
  JRN_TYPES,
  TOP_PROJECT_ID,
  GLOBAL_EXPENSE_STATUS,
  GLOBAL_EXPENSE_TYPES,
  GLOBAL_FINANCIAL_TYPES,
  PAYMENT_METHOD,
} from '../../../config/appDefaults';
import { PAYMENT_TERMS } from './add-content-form.constants';

import {
  batchRequest,
  runAnalytics,
  removeAttributesFromObject,
  daysAfterEpoch,
  intDaysAfterEpochAsIso,
  revokeUrls,
  generateDataFromOcr,
  determinePaymentTerms,
} from '../../../helpers';

import AddContentFormView from './add-content-form.view';
import splitItem, {
  ACTION as SPLIT_ACTION,
} from '../../../helpers/split-item/split-item.util';

const initializeProjectList = ({
  type,
  projectsToExpense,
  parentInfo,
  editingMode,
}) => {
  // If new global receipt or global bill, then initialize the first item in the list to be the parent project
  if (GLOBAL_EXPENSE_TYPES.includes(type) && !editingMode) {
    // If projectsToExpense intialization was provided, use that
    if (projectsToExpense) {
      return projectsToExpense;
    }

    if (parentInfo) {
      // Initialize project list from projectInfos
      return [
        {
          project: parentInfo,
          projectPath: [parentInfo.title],
          amount: '',
          description: '',
          existingContentInfo: null,
          billable: false,
        },
      ];
    }

    return [];
  }

  // If editing global receipt or global bill, leave null so that it will initialize after query
  return null;
};

const initializeBillList = ({ type, billInfos, editingMode }) => {
  // If new global receipt or global bill, then initialize the first item in the list to be the parent project
  if (type === CONTENT_TYPE.GLOBAL_PAYMENT && !editingMode) {
    if (!billInfos) {
      return [];
    }

    return _.map(billInfos, billInfo => {
      const balanceDue =
        billInfo?.balance?.value || billInfo?.amount?.value || 0;

      let paymentAmount = null;
      if (billInfo?.paymentAmount?.value) {
        paymentAmount = billInfo.paymentAmount.value;
      }

      return {
        bill: billInfo,
        amount: paymentAmount
          ? paymentAmount.toFixed(2)
          : balanceDue.toFixed(2),
        existingContentInfo: null,
      };
    });
  }

  // If editing global payment, leave null so that it will initialize after query
  return null;
};

const AddContentFormChecker = props => {
  const { location, ...rest } = props;
  const goodToGo =
    _.get(props, 'passedType') || _.get(props, 'passedContentInfo.type');
  if (!goodToGo) {
    return <Redirect to="/projects" />;
  }
  return <AddContentForm location={location} {...rest} />; // add the location and then pass the rest of the props
};

const AddContentForm = props => {
  const {
    // hocs
    enqueueSnackbar,
    // mutations
    onAddContent,
    onUpdateContent,
    onDeleteContent,
    onAddJrn,
    onUpdateJrn,
    onAddOrUpdateCompanyQuestions,
    onCreateProjectFromTemplate,
    // redux
    userId,
    userInfo,
    changeFilenamesOnUpload,
    contentLabelSuggestions,
    projectLabelSuggestions,
    // passed items
    passedType,
    passedContentInfo,
    parentId,
    parentInfo,
    permissionsFromParent,
    isParentExtended,
    nextTimetrackingStatus,
    createFromTemplate,
    creatingProjectFromLead, // flag to say if we're creating a project from a lead and whether it's from a template or blank project STRING "fromTemplate" or "fromBlank"
    baseContentInfo,
    leadDetails,
    billsToPay,
    projectsToExpense,
    contentToDelete,
    handleBack,
    // passed functions
    onAddAnother,
    onComplete,
    fromWhichAdminTool,
    includeArchive,
    managingCompanyInfo,
    getCompanyCrewLoading,
    companyCrew,
    companies,
    customerInfo,
    hideCustomerOption,
    fromShoebox,
    fromRfi,
  } = props;
  const [financialSegmentKey, setFinancialSegmentKey] = useState(uuid());
  const resetFinancialSegmentKey = () => {
    setFinancialSegmentKey(uuid());
  };
  const initialValues = {};
  const { client } = props;
  const history = useHistory();

  // Initialize state
  const isCompanyAdmin =
    managingCompanyInfo && managingCompanyInfo.isCompanyAdmin;

  const checkIsInTemplate = () =>
    !!(
      (parentInfo && parentInfo.fromTemplate) || // parent is an extended task
      (parentInfo && parentInfo.type === 'template') || // parent is template
      (passedContentInfo && passedContentInfo.fromTemplate)
    );

  // By settings this directly, useState hooks were not happy during the compile
  const isInTemplate = checkIsInTemplate();

  const [templateChanges, setTemplateChanges] = useState({
    startDateInt:
      isInTemplate && passedContentInfo && passedContentInfo.startDate
        ? daysAfterEpoch(passedContentInfo.startDate) + 1
        : 1,
    endDateInt:
      isInTemplate && passedContentInfo && passedContentInfo.endDate
        ? daysAfterEpoch(passedContentInfo.endDate) + 1
        : 1,
    dateInt:
      isInTemplate && passedContentInfo && passedContentInfo.date
        ? daysAfterEpoch(passedContentInfo.date) + 1
        : 1,
  });

  const [saveButtonIndex, setSaveButtonIndex] = useState(uuid());
  const [assignFlowInfo, setAssignFlowInfo] = useState({ open: false });

  const [showUnknownIssueDialog, setShowUnknownIssueDialog] = useState(false);

  // Generic states
  const [didAnswersChange, setDidAnswersChange] = useState(false);
  const [projectQuestions, setProjectQuestions] = useState(null);
  const [visualAssignedTo, setVisualAssignedTo] = useState(null);
  const [addAnother, setAddAnother] = useState(false);
  const [splitWithAnother, setSplitWithAnother] = useState(false);

  const passedTypeFromAbove =
    passedType || _.get(props, 'passedContentInfo.type');
  const editingMode = !!passedContentInfo;

  const isFinancial = FINANCIAL_TYPES.includes(passedTypeFromAbove);
  const isGlobalExpense = GLOBAL_EXPENSE_TYPES.includes(passedTypeFromAbove);
  const isGlobalPayment = passedTypeFromAbove === CONTENT_TYPE.GLOBAL_PAYMENT;

  // Global bill/receipt states
  const [projectList, setProjectList] = useState(() =>
    initializeProjectList({
      type: passedTypeFromAbove,
      projectsToExpense,
      parentInfo,
      editingMode: !!passedContentInfo,
    })
  );

  const [billList, setBillList] = useState(() =>
    initializeBillList({
      type: passedTypeFromAbove,
      billInfos: billsToPay,
      editingMode: !!passedContentInfo,
    })
  );

  const ListMyConversationsQuery = useQuery(ListMyConversations, {
    variables: { userId: 'willBePulledFromCognitoSubContentInResolver' },
    fetchPolicy: 'cache-and-network',
    skip: passedTypeFromAbove !== 'conversation',
  });

  const existingConversations = _.get(
    ListMyConversationsQuery,
    'data.listMyConversations.items',
    []
  );

  const skipGlobalExpenseContentQuery = !isGlobalExpense || !editingMode;

  const GetAllContentByGlobalExpenseIdQuery = useQuery(
    GetAllContentByGlobalExpenseId,
    {
      skip: skipGlobalExpenseContentQuery,
      variables: {
        globalExpenseId: passedContentInfo && passedContentInfo.contentId,
      },
      fetchPolicy: 'network-only',
    }
  );

  const existingProjectExpenses =
    GetAllContentByGlobalExpenseIdQuery?.data?.getAllContentByGlobalExpenseId
      ?.items;

  if (existingProjectExpenses) {
    if (projectList === null) {
      const contentIdToDelete = contentToDelete?.contentId;
      // Initialize the project list with the items
      const projectListToSet = [];
      _.forEach(existingProjectExpenses, expenseContentItem => {
        if (
          expenseContentItem.type === CONTENT_TYPE.BILL ||
          expenseContentItem.type === CONTENT_TYPE.RECEIPT
        ) {
          const projectPath = [];
          if (expenseContentItem.jrn) {
            projectPath.push(expenseContentItem.jrn.title);
            if (expenseContentItem.jrn.jrn?.title) {
              projectPath.unshift(expenseContentItem.jrn.jrn.title);
            }
          }

          projectListToSet.push({
            project: expenseContentItem.jrn || null,
            projectPath,
            amount: numeral(
              (expenseContentItem.amount && expenseContentItem.amount.value) ||
                0
            ).format('0.00'),
            description: expenseContentItem.description || '',
            existingContentInfo: expenseContentItem,
            billable: expenseContentItem.billable || false,
            deletionPending: expenseContentItem.contentId === contentIdToDelete,
          });
        }
      });

      setProjectList(projectListToSet);
    }
  }

  const skipGlobalPaymentContentQuery = !isGlobalPayment || !editingMode;

  const GetAllContentByJrnIdQuery = useQuery(GetAllContentByJrnId, {
    skip: skipGlobalPaymentContentQuery,
    variables: {
      jrnId: passedContentInfo && passedContentInfo.contentId,
    },
    fetchPolicy: 'network-only',
  });

  const billPaymentItems =
    GetAllContentByJrnIdQuery?.data?.getAllContentByJrnId?.items;

  if (billPaymentItems) {
    if (billList === null) {
      const contentIdToDelete = contentToDelete?.contentId;
      // Initialize the bill list with the items
      const billListToSet = [];
      _.forEach(billPaymentItems, contentItem => {
        if (contentItem.type === CONTENT_TYPE.BILL_PAYMENT) {
          const { amount, globalExpense } = contentItem;

          const globalBill = { ...globalExpense };
          // NOTE: As we are editing the payment, we need to recalculate the balance due
          if (globalBill.balance) {
            globalBill.balance = {
              ...globalBill.balance,
              value: (globalBill.balance.value || 0) + (amount?.value || 0),
            };
          }

          billListToSet.push({
            bill: globalBill,
            amount: numeral((amount && amount.value) || 0).format('0.00'),
            existingContentInfo: contentItem,
            deletionPending: contentItem.contentId === contentIdToDelete,
          });
        }
      });

      setBillList(billListToSet);
    }
  }

  // Only admins or add only users who are the creators should be shown this form
  // Restricted mode will disable fields that non-creating administrators cannot edit
  let restrictedMode = false;
  if (editingMode && passedContentInfo.creatorId !== userId) {
    const unrestrictedTypes = [
      CONTENT_TYPE.TIMETRACKING,
      CONTENT_TYPE.LEAD,
      CONTENT_TYPE.TASK,
    ];

    const restrictedTypes = [
      CONTENT_TYPE.PROJECT,
      CONTENT_TYPE.TEMPLATE,
      CONTENT_TYPE.CONVERSATION,
      ...FINANCIAL_TYPES,
    ];

    if (_.includes(restrictedTypes, passedContentInfo.type)) {
      const isAdminOnProject = _.includes(
        passedContentInfo.allowedToEdit,
        userId
      );
      if (!isAdminOnProject && !isCompanyAdmin) {
        restrictedMode = true;
      }
    } else if (!_.includes(unrestrictedTypes, passedContentInfo.type)) {
      restrictedMode = true;
    }
  }
  const resetVisualAssignedTo = () => setVisualAssignedTo(null);

  // If create from template, then
  let initializeFrom = null;
  if (editingMode) {
    initializeFrom = passedContentInfo;
  } else if (baseContentInfo) {
    initializeFrom = baseContentInfo;
    if (baseContentInfo && baseContentInfo.media) {
      const mediaToUse = baseContentInfo.media[0];
      const formDataFromOcr = generateDataFromOcr({
        ocrData: mediaToUse.ocrData,
      });
      if (formDataFromOcr) {
        const descriptionToWrite = formDataFromOcr.description
          ? `
            ${baseContentInfo.description}
            <br /><br />
            ${formDataFromOcr.description}
            `
          : baseContentInfo.description;

        const dataToWrite = {
          description: descriptionToWrite,
        };

        if (formDataFromOcr.date) {
          dataToWrite.date = formDataFromOcr.date;

          if (passedTypeFromAbove === CONTENT_TYPE.GLOBAL_BILL) {
            dataToWrite.startDate = formDataFromOcr.date;
          }
        }

        if (formDataFromOcr.amount && !baseContentInfo.amount) {
          dataToWrite.amount = formDataFromOcr.amount;
        }

        if (passedTypeFromAbove === CONTENT_TYPE.GLOBAL_BILL) {
          if (formDataFromOcr.dueDate) {
            dataToWrite.paymentTerms = determinePaymentTerms({
              billDate: formDataFromOcr.date,
              dueDate: formDataFromOcr.dueDate,
            });
            dataToWrite.endDate = formDataFromOcr.dueDate;
          }

          if (formDataFromOcr.invoiceNumber) {
            dataToWrite.documentNumber = formDataFromOcr.invoiceNumber;
          }
        }

        if (isGlobalExpense) {
          if (formDataFromOcr.poNumber) {
            dataToWrite.poNumber = formDataFromOcr.poNumber;
          }

          if (formDataFromOcr.vendorName) {
            dataToWrite.vendorName = formDataFromOcr.vendorName;
          }
        }

        initializeFrom = { ...initializeFrom, ...dataToWrite };
      }
    }
  } else if (createFromTemplate) {
    const projectFromTemplate = { ...createFromTemplate };
    // Will create a project
    projectFromTemplate.type = 'project';
    projectFromTemplate.subtype = null;

    // Remove uncopied properties
    delete projectFromTemplate.contentId;
    delete projectFromTemplate.allowedToEdit;
    delete projectFromTemplate.allowedToAdd;
    delete projectFromTemplate.allowedToView;
    delete projectFromTemplate.usersEversOnJrn;
    delete projectFromTemplate.creatorId;

    // Override specific properties
    const startOfToday = moment()
      .startOf('day')
      .toDate();
    projectFromTemplate.title = '';
    projectFromTemplate.date = new Date();
    projectFromTemplate.startDate = new Date(
      new Date(projectFromTemplate.startDate).getTime() + startOfToday.getTime()
    );
    projectFromTemplate.endDate = new Date(
      new Date(projectFromTemplate.endDate).getTime() + startOfToday.getTime()
    );
    initializeFrom = projectFromTemplate;
  }

  // if it's editing mode then apply the passed contentInfo and then fill in the rest of the default values so the form works

  if (initializeFrom) {
    // set passed in values
    _.keys(initializeFrom).forEach(key => {
      if (initializeFrom[key] === undefined || initializeFrom[key] === null) {
        initialValues[key] = '';
      } else {
        initialValues[key] = initializeFrom[key];
      }
    });
  }

  if (parentId) {
    if (isGlobalExpense || isGlobalPayment) {
      initialValues.parentId = TOP_PROJECT_ID;
    } else {
      initialValues.parentId = parentId;
    }
  }

  // <QUERIES> ///////////
  // If this is a project use contentId
  // otherwise use parent Id
  let idToUse = initialValues.parentId;

  const projectOrTemplate = _.includes(
    ['project', 'template'],
    passedTypeFromAbove
  );
  const projectTemplateLead = _.includes(
    ['project', 'template', 'lead'],
    passedTypeFromAbove
  );
  const projectOrLead = _.includes(['project', 'lead'], passedTypeFromAbove);

  const isTypeOfJrn = _.includes(JRN_TYPES, passedTypeFromAbove);

  const isLead = passedTypeFromAbove === 'lead';

  if (isTypeOfJrn) {
    idToUse = initialValues.contentId;
  }

  const [currentUsersOnProjectId, setCurrentUsersOnProjectId] = useState([]);

  const GetUsersByJrnIdQuery = useQuery(GetUsersByJrnId, {
    variables: { jrnId: idToUse },
    fetchPolicy: 'cache-and-network',
    skip: !idToUse,
  });

  const GetJrnQuery = useQuery(GetJrn, {
    variables: { jrnId: idToUse },
    skip: !idToUse,
  });

  const userSearchResponseQuery = useQuery(GetUsersByIds, {
    variables: { ids: currentUsersOnProjectId },
    skip: _.isEmpty(currentUsersOnProjectId),
  });

  const basicConvoInfo = _.get(GetJrnQuery, 'data.getJrn');

  useEffect(() => {
    if (basicConvoInfo) {
      // get users on project
      const combinedLists = [
        ...(basicConvoInfo.allowedToEdit || []),
        ...(basicConvoInfo.allowedToAdd || []),
        ...(basicConvoInfo.allowedToView || []),
      ];

      // users ever on project
      const usersEverOnJrn = [...basicConvoInfo.usersEverOnJrn];
      // remove the users that arent currently on jrn
      setCurrentUsersOnProjectId(_.uniq(combinedLists, usersEverOnJrn));
    }
  }, [basicConvoInfo]);

  const allUsersByJrnId = _.get(
    GetUsersByJrnIdQuery,
    'data.getUsersByJrnId.items'
  );

  const allUsersOnProject = useMemo(() => {
    let users = [];
    if (allUsersByJrnId) {
      users =
        _.orderBy(
          allUsersByJrnId,
          [user => user.username.toLowerCase()],
          ['asc']
        ) || [];
    }
    return users;
  }, [allUsersByJrnId]);

  const userSearchResponse = _.get(
    userSearchResponseQuery,
    'data.getUsersByIds.items'
  );

  const localAllUsersCurrentlyOnProject = useMemo(() => {
    let users = [];
    if (userSearchResponse) {
      users =
        _.orderBy(
          userSearchResponse,
          [user => user.username.toLowerCase()],
          ['asc']
        ) || [];
    }
    return users;
  }, [userSearchResponse]);

  let myCrew = [];

  try {
    if (
      managingCompanyInfo &&
      managingCompanyInfo.managingCompanyId &&
      companies &&
      companyCrew
    ) {
      const company = _.find(
        companies,
        ({ companyId }) => companyId === managingCompanyInfo.managingCompanyId
      );

      if (company) {
        const activeUserIds = [...company.admins, ...company.users];
        const activeUsers = companyCrew.filter(
          ({ userId: companyUserId }) =>
            activeUserIds.includes(companyUserId) && companyUserId !== userId
        );

        myCrew =
          _.orderBy(
            activeUsers,
            [user => user.username.toLowerCase()],
            ['asc']
          ) || [];
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('error building friendslist: ', e);
  }

  let isSubproject = false;
  if (
    passedTypeFromAbove === 'project' &&
    initialValues.parentId &&
    initialValues.parentId !== TOP_PROJECT_ID
  ) {
    isSubproject = true;
  }

  // Only allow null for new financial types (not global expense), override passed in value
  const isNewFinancial =
    isFinancial && !isGlobalExpense && !isGlobalPayment && !editingMode;
  if (!initialValues.type) {
    initialValues.type = isNewFinancial ? null : passedTypeFromAbove;
  }

  // </QUERIES> ///////////
  // APPLY ANY MISSING DEFAULT VALUES (WHICH IS ALL OF THEM IF IT'S NOT EDITING MODE)
  initialValues.address = initialValues.address || '';
  initialValues.parentId = initialValues.parentId || TOP_PROJECT_ID;

  initialValues.description = initialValues.description || '';
  initialValues.title = initialValues.title || '';
  initialValues.amount = initialValues.amount || null;
  initialValues.labels =
    initialValues.labels && initialValues.labels.length
      ? initialValues.labels.map(label => {
          return { label, value: label };
        })
      : [];
  initialValues.isPublic = initialValues.isPublic || false;

  if (projectTemplateLead || initialValues.type === 'task') {
    initialValues.useTimetracking =
      typeof initialValues.useTimetracking === 'boolean'
        ? initialValues.useTimetracking
        : true;
    initialValues.timetrackingType = initialValues.timetrackingType || 'fixed';
  }

  if (projectTemplateLead) {
    initialValues.useTimetrackingQuestions =
      typeof initialValues.useTimetrackingQuestions === 'boolean'
        ? initialValues.useTimetrackingQuestions
        : false;

    initialValues.includeSubsInStats = editingMode
      ? !!initialValues.includeSubsInStats
      : true;

    initialValues.excludeFromStats =
      typeof initialValues.excludeFromStats === 'boolean'
        ? initialValues.excludeFromStats
        : false;

    initialValues.billable =
      typeof initialValues.billable === 'boolean'
        ? initialValues.billable
        : true;

    if (initialValues.type === 'task') {
      initialValues.useTimetracking =
        typeof initialValues.useTimetracking === 'boolean'
          ? initialValues.useTimetracking
          : true;
      initialValues.timetrackingType =
        initialValues.timetrackingType || 'fixed';
    }
  }

  if (isInTemplate) {
    const now = new Date();
    const nowAtUtc = new Date(
      Date.UTC(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours(),
        now.getMinutes()
      )
    );

    initialValues.date = initialValues.date
      ? moment(initialValues.date)
      : moment(nowAtUtc);
  }

  if (initialValues.type === 'task') {
    if (!isInTemplate) {
      initialValues.date = initialValues.date
        ? new Date(initialValues.date)
        : new Date();
    }

    if (editingMode) {
      initialValues.startDate = initialValues.startDate
        ? new Date(initialValues.startDate)
        : new Date(initialValues.date);
      initialValues.endDate = initialValues.endDate
        ? new Date(initialValues.endDate)
        : new Date(initialValues.date);
    } else {
      initialValues.startDate =
        initialValues.startDate &&
        new Date(initialValues.startDate) >= initialValues.date
          ? new Date(initialValues.startDate)
          : initialValues.date;

      initialValues.endDate =
        initialValues.endDate &&
        new Date(initialValues.endDate) >= initialValues.startDate
          ? new Date(initialValues.endDate)
          : initialValues.startDate;
    }
  } else {
    initialValues.date = initialValues.date
      ? new Date(initialValues.date)
      : new Date();
    initialValues.startDate = initialValues.startDate
      ? new Date(initialValues.startDate)
      : new Date();
    initialValues.endDate = initialValues.endDate
      ? new Date(initialValues.endDate)
      : new Date();
  }

  // For time components only in validation
  initialValues.timeForDate = new Date(initialValues.date);
  initialValues.timeForStartDate = new Date(initialValues.startDate);
  initialValues.timeForEndDate = new Date(initialValues.endDate);

  if (initialValues.type === 'template' || createFromTemplate) {
    // Cast startDate and endDate to midnight
    const startDateMidnight = moment(initialValues.startDate).startOf('day');
    const endDateMidnight = moment(initialValues.endDate).startOf('day');

    // Determine days between
    const lengthInDays = endDateMidnight.diff(startDateMidnight, 'days') + 1; // Should be first day inclusive;

    let projectLengthValue = lengthInDays;
    let projectLengthUnit = 1; // Default to days
    if (lengthInDays !== 0) {
      // Find largest fitting unit of measurement
      const sortedTimeUnits = _.orderBy(DURATION_UNITS, ['value'], ['desc']);

      for (let i = 0; i < sortedTimeUnits.length; i += 1) {
        const remainder = lengthInDays % sortedTimeUnits[i].value;
        if (remainder === 0) {
          projectLengthValue = lengthInDays / sortedTimeUnits[i].value;
          projectLengthUnit = sortedTimeUnits[i].value;
          break;
        }
      }
    }

    initialValues.projectLength = {
      days: projectLengthValue * projectLengthUnit,
      value: projectLengthValue,
      unit: projectLengthUnit,
    };
  }

  initialValues.permissionsFrom = initialValues.parentId || TOP_PROJECT_ID;
  initialValues.globalExpense = initialValues.globalExpense || null;
  // <contentType based defaults> ///////////
  const [
    localCurrentTimetrackingStatus,
    setLocalCurrentTimetrackingStatus,
  ] = useState(null); // if the user's next status is clocked in then they are currently clocked out
  const [projectContentCopy, setProjectContentCopy] = useState(null);

  const whatShouldStatusBe = useCallback(
    async passedAssignedTo => {
      const parentProjectId = initialValues.parentId;
      let projectContent = projectContentCopy || [];
      if (!projectContentCopy) {
        try {
          const projectContentResponse = await client.query({
            query: GetAllContentByJrnId,
            variables: { jrnId: parentProjectId },
            fetchPolicy: 'no-cache',
          });
          projectContent =
            projectContentResponse.data.getAllContentByJrnId.items;
          setProjectContentCopy(projectContent);
          // then find the piece of content needing to be edited
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('whatShouldStatusBe query e: ', e);
        }
      }
      const lastTimetrackingEvent = _.find(projectContent, contentPiece => {
        return (
          contentPiece.type === 'timetracking' &&
          contentPiece.assignedTo === passedAssignedTo
        );
      });
      let lastStatus = 'checkout';
      if (
        lastTimetrackingEvent &&
        lastTimetrackingEvent.contentStatus === 'checkin'
      ) {
        lastStatus = 'checkin';
      }
      setLocalCurrentTimetrackingStatus(lastStatus);
      return lastStatus;
    },
    [client, initialValues.parentId, projectContentCopy]
  );

  useEffect(() => {
    // if it's a task/shift/timetracking and it's assigned to someone and there is a userlist
    if (
      ['task', 'punchlistItem', 'shift', 'timetracking'].indexOf(
        initialValues.type
      ) >= 0 &&
      initialValues.assignedTo &&
      allUsersOnProject &&
      allUsersOnProject.length
    ) {
      const assignedUserInfo = _.find(allUsersOnProject, {
        userId: initialValues.assignedTo,
      });
      if (assignedUserInfo) {
        setVisualAssignedTo(assignedUserInfo.username);

        if (initialValues.type === 'timetracking') {
          whatShouldStatusBe(initialValues.assignedTo);
        }
      }
    }
  }, [
    allUsersOnProject,
    initialValues.assignedTo,
    initialValues.type,
    whatShouldStatusBe,
  ]);

  if (initialValues.contentUrl) {
    if (!fromRfi) {
      const needsJsonParse =
        ['gallery', 'task', 'punchlistItem'].indexOf(initialValues.type) >= 0;

      if (needsJsonParse || fromShoebox) {
        try {
          initialValues.contentUrl = JSON.parse(initialValues.contentUrl);
          if (fromShoebox) {
            initialValues.aspectRatio = parseFloat(
              initialValues.contentUrl[0].aspectRatio
            );
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('problem converting gallery JSON to array');
          initialValues.contentUrl = [];
        }
      } else {
        initialValues.contentUrl = [
          {
            uri: initialValues.contentUrl,
            aspectRatio: initialValues.aspectRatio,
          },
        ];
      }
    }
  } else {
    initialValues.contentUrl = [];
  }

  const isTask =
    initialValues.type === 'task' || initialValues.type === 'punchlistItem';
  if (isTask) {
    initialValues.contentStatus = initialValues.contentStatus || 'incomplete';
    initialValues.priority =
      taskPrioritiesArray.indexOf(initialValues.priority) >= 0
        ? initialValues.priority
        : 'normal';
    initialValues.assignedTo = initialValues.assignedTo || 'unassigned';
    initialValues.requireAdmin = initialValues.requireAdmin || false;
    initialValues.completionType = initialValues.completionType || 'none';
    initialValues.completionInfo = initialValues.completionInfo || {
      instructions: null,
      completedById: null,
      completedByDate: null,
      textResponse: null,
      mediaInfo: [],
    };
    initialValues.completionInfo_instructions =
      _.get(initialValues, 'completionInfo.instructions') || '';

    // do this to set any punchlistItems back to "task" type so we can evetually get rid of worrying about punchlistItems
    initialValues.type = 'task';
  }
  if (initialValues.type === 'shift') {
    // if it's anew shift or somehow unassigned,
    initialValues.assignedTo = initialValues.assignedTo || userInfo.userId;
  }
  if (initialValues.type === 'timetracking') {
    initialValues.assignedTo = initialValues.assignedTo || userInfo.userId;
    if (nextTimetrackingStatus) {
      initialValues.contentStatus = nextTimetrackingStatus;
    }
  }
  if (isFinancial && !isGlobalExpense) {
    initialValues.contentStatus = !!initialValues.contentStatus;
  }
  if (
    [CONTENT_TYPE.RECEIPT, CONTENT_TYPE.BILL].includes(passedTypeFromAbove) &&
    (initialValues.billable === null || initialValues.billable === undefined)
  ) {
    initialValues.billable = false;
  }

  if (isTypeOfJrn) {
    initialValues.allowedToEdit = initialValues.allowedToEdit || [userId];
    initialValues.allowedToAdd = initialValues.allowedToAdd || [];
    initialValues.allowedToView = initialValues.allowedToView || [];
  }

  const [convoIsGroup, setConvoIsGroup] = useState(
    initialValues.type === 'conversation' &&
      initialValues.allowedToEdit.concat(initialValues.allowedToAdd).length > 2
  );

  if (isLead) {
    initialValues.parentId = initialValues.jrnId;
    initialValues.customerId = customerInfo ? customerInfo.customerId : '';
    initialValues.customerInfo = customerInfo;
    initialValues.contentStatus =
      initialValues && initialValues.contentStatus
        ? initialValues.contentStatus
        : defaultLeadStatus;
  }

  const isCustomerSyncEnabled =
    managingCompanyInfo && managingCompanyInfo.isCustomerSyncEnabled;

  if (projectOrLead) {
    // Only mutable in createJrn
    initialValues.qboCustomerId =
      (passedContentInfo && passedContentInfo.qboCustomerId) || null;

    // Default to on if customer sync is enabled and new top level project or lead
    if (isCustomerSyncEnabled && !isSubproject && !editingMode) {
      initialValues.qboCustomerId = 'NEW';
    }
  }

  if (GLOBAL_FINANCIAL_TYPES.includes(initialValues.type)) {
    initialValues.vendorId = initialValues.vendorId || '';
    initialValues.documentNumber = initialValues.documentNumber || '';
    initialValues.paymentTerms = initialValues.paymentTerms || '';
    initialValues.poNumber = initialValues.poNumber || '';

    if (initialValues.type === CONTENT_TYPE.GLOBAL_BILL) {
      initialValues.contentStatus =
        initialValues.contentStatus || GLOBAL_EXPENSE_STATUS.UNPAID;

      if (!initialValues.paymentTerms) {
        initialValues.paymentTerms = PAYMENT_TERMS.NET_30;
      }
    } else {
      initialValues.contentStatus = initialValues.contentStatus || null;
    }
  }

  if (isGlobalPayment && !editingMode) {
    initialValues.subtype = '';
  }

  // </contentType based defaults> ///////////

  let allLabelOptions;
  if (
    projectTemplateLead &&
    projectLabelSuggestions &&
    projectLabelSuggestions.length
  ) {
    allLabelOptions = projectLabelSuggestions.map(label => {
      return { label, value: label };
    });
  } else if (contentLabelSuggestions && contentLabelSuggestions.length) {
    allLabelOptions = contentLabelSuggestions.map(label => {
      return { label, value: label };
    });
  }

  const resetOutsideFormik = () => {
    resetVisualAssignedTo();
    setConvoIsGroup(false);
    // reset type if financial (not global expense)
    if (isFinancial && !isGlobalExpense) {
      resetFinancialSegmentKey();
    }
  };

  const getProjectQuestions = async () => {
    let questionsResponse;
    let projectId = initialValues.contentId;
    if (initialValues.type === 'timetracking') {
      projectId = initialValues.parentId;
    }
    if (createFromTemplate) {
      projectId = createFromTemplate.contentId;
    }

    try {
      questionsResponse = await client.query({
        query: GetQuestionsByProjectId,
        variables: { projectId },
        fetchPolicy: 'no-cache',
      });
      const returnedQuestions = _.get(
        questionsResponse,
        'data.getQuestionsByProjectId.items'
      );
      const whatToSetReturn = returnedQuestions || [];
      return whatToSetReturn;
      // then find the piece of content needing to be edited
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('getProjectQuestions query e: ', e);
      return null;
    }
  };

  const handleParentChoice = info => {
    // close assignFlow modal and block ui while uploading image/pdf
    setAssignFlowInfo({ ...assignFlowInfo, open: false, loading: true });
    // upload image

    // set new parentId
    assignFlowInfo.setFieldValue('contentId', uuid());
    assignFlowInfo.setFieldValue('parentId', info.project.contentId);
    assignFlowInfo.setFieldValue('permissionsFrom', info.project.contentId);
    // clear amount
    assignFlowInfo.setFieldValue('amount', { value: null });
    // reset save button
    setSaveButtonIndex(uuid());
    // set split flag back to false
    setSplitWithAnother(false);
    setAssignFlowInfo({ ...assignFlowInfo, open: false, loading: false });
  };

  const onFormSubmit = async (values, { resetForm }) => {
    const cleanCompletionInfo = omitDeep(initialValues.completionInfo, [
      '__typename',
    ]);
    let newCompletionInfo = null;

    if (values.type === 'task') {
      newCompletionInfo = {
        ...cleanCompletionInfo,
        instructions: values.completionInfo_instructions,
      };
      delete newCompletionInfo.__typename;
    }

    const labelsToUse = values.labels
      ? values.labels.map(selection => selection.label)
      : [];
    labelsToUse.sort();

    let endDateShouldBe;
    let startDateShouldBe;
    let dateShouldBe;

    if (isInTemplate) {
      const needsStartAndEnd = ['task', 'project'].includes(values.type);
      if (
        needsStartAndEnd &&
        templateChanges.startDateInt !== null &&
        templateChanges.startDateInt !== undefined
      ) {
        startDateShouldBe = intDaysAfterEpochAsIso(
          templateChanges.startDateInt
        );
        const YMD = moment(startDateShouldBe).format('YYYY-MM-DD');
        const timeOnlyFromDate = moment(values.startDate)
          .utc()
          .format('HH:mm');
        startDateShouldBe = `${YMD}T${timeOnlyFromDate}:00.000Z`;
      }
      if (
        needsStartAndEnd &&
        templateChanges.endDateInt !== null &&
        templateChanges.endDateInt !== undefined
      ) {
        endDateShouldBe = intDaysAfterEpochAsIso(templateChanges.endDateInt);
        const YMD = moment(endDateShouldBe).format('YYYY-MM-DD');
        const timeOnlyFromDate = moment(values.endDate)
          .utc()
          .format('HH:mm');
        endDateShouldBe = `${YMD}T${timeOnlyFromDate}:00.000Z`;
      }
      if (
        templateChanges.dateInt !== null &&
        templateChanges.dateInt !== undefined
      ) {
        dateShouldBe = intDaysAfterEpochAsIso(templateChanges.dateInt);
        const YMD = moment(dateShouldBe).format('YYYY-MM-DD');
        const timeOnlyFromDate = moment(values.date)
          .utc()
          .format('HH:mm');
        dateShouldBe = `${YMD}T${timeOnlyFromDate}:00.000Z`;
      }

      if (needsStartAndEnd) {
        dateShouldBe = startDateShouldBe;
      }
    } else {
      endDateShouldBe = values.endDate ? values.endDate.toISOString() : null;

      startDateShouldBe = values.startDate
        ? values.startDate.toISOString()
        : null;

      dateShouldBe = isTask
        ? startDateShouldBe || new Date().toISOString()
        : values.date.toISOString() || new Date().toISOString();
    }

    if (values.type === 'template' && values.projectLength) {
      // Update endDateShouldBe accordingly
      endDateShouldBe = moment(values.startDate)
        .add(values.projectLength.days - 1, 'days')
        .toISOString();
    }

    // add content mutation herrrrrrrrre
    const contentToPass = {
      contentId: values.contentId || uuid(),
      jrnId: values.parentId || TOP_PROJECT_ID,
      creatorId: userId,
      type: values.type || initialValues.type,
      subtype: values.subtype || null,
      date: dateShouldBe,
      contentUrl:
        values.contentUrl && values.contentUrl.length > 0
          ? values.contentUrl
          : null,
      aspectRatio: values.aspectRatio || null,
      isPublic: values.isPublic || false,
      synced: false,
      labels: labelsToUse,
      latitude: values.latitude || null,
      longitude: values.longitude || null,
      title: values.title === 'hold' ? null : values.title,
      description:
        values.type !== 'conversation' ||
        (values.type === 'conversation' && convoIsGroup && values.description)
          ? values.description
          : null,
      coverImage: null,
      endDate: endDateShouldBe,
      startDate: startDateShouldBe,
      privacyLevel: null,
      inviteCode: null,
      allowedToEdit: values.allowedToEdit || [],
      allowedToAdd: values.allowedToAdd || [],
      allowedToView: values.allowedToView || [],
      addedBy: 'webapp',
      assignedTo: values.assignedTo || null,
      contentStatus: values.contentStatus || null,
      priority: values.priority || null,
      address: values.address || null,
      requireAdmin: values.requireAdmin || false,
      completionType: values.completionType || null,
      completionInfo: newCompletionInfo,
      permissionsFrom:
        values.subtype === 'extended'
          ? initialValues.permissionsFrom || initialValues.parentId
          : null,
      timetrackingType:
        values.timetrackingType || initialValues.timetrackingType || null,
      paymentTerms: values.paymentTerms || null,
      documentNumber: values.documentNumber || null,
      poNumber: values.poNumber || null,
      vendorId: values.vendorId || null,
      billable: values.billable || false,
      fromTemplate: null, // default to null and set below
    };

    if (values.ocrId) {
      // this sets the value for ocrId which is used to index the relation from Veryfi to the content
      contentToPass.ocrId = values.ocrId;
    }

    if (fromRfi && baseContentInfo.rfiIds) {
      contentToPass.rfiIds = baseContentInfo.rfiIds;
    }

    if (creatingProjectFromLead) {
      contentToPass.fromLeadId = leadDetails.contentId;
    }

    let customerHasChanged = false;
    if (isTypeOfJrn) {
      if (!isSubproject) {
        contentToPass.customerId = values.customerId || null;

        if (values.qboCustomerId === 'NEW') {
          // If new project or lead, allow the 'NEW' value to be passed with customer info object
          contentToPass.qboCustomerId = values.qboCustomerId;
        }
      }

      if (editingMode) {
        // if it's editing mode and the passed in customerId doesn't match the one currently set
        if (
          passedContentInfo &&
          passedContentInfo.customerId !== values.customerId
        ) {
          customerHasChanged = true;
        }
      } else if (values.customerId) {
        // if it's add mode and there is a customerId
        customerHasChanged = true;
      }
    }

    if (isInTemplate) {
      let templateId = null;
      if (passedContentInfo && passedContentInfo.fromTemplate) {
        // If content already has been mapped to a template
        templateId = passedContentInfo.fromTemplate;
      } else if (parentInfo && parentInfo.type === 'template') {
        // If parentInfo is provided and is a template
        templateId = parentInfo.contentId;
      } else if (parentInfo && parentInfo.fromTemplate) {
        // If parentInfo is provided and is mapped to a template
        templateId = parentInfo.fromTemplate;
      }

      contentToPass.fromTemplate = templateId;
    }

    if (
      projectTemplateLead ||
      (values.type === 'task' && values.subtype === 'extended')
    ) {
      contentToPass.useTimetracking = values.useTimetracking;
    }
    if (projectOrTemplate) {
      contentToPass.includeSubsInStats = isSubproject
        ? false
        : values.includeSubsInStats;
      contentToPass.excludeFromStats = values.excludeFromStats;
      contentToPass.billable = values.billable;
    }

    const isContentFinancial = FINANCIAL_TYPES.includes(contentToPass.type);
    const isContentGlobalExpense = GLOBAL_EXPENSE_TYPES.includes(
      contentToPass.type
    );
    const isContentGlobalPayment =
      CONTENT_TYPE.GLOBAL_PAYMENT === contentToPass.type;

    if (isContentGlobalExpense || isContentGlobalPayment) {
      // Do not overwrite the permissions if editing a global expense or global payment
      if (editingMode) {
        delete contentToPass.allowedToAdd;
        delete contentToPass.allowedToEdit;
        delete contentToPass.allowedToView;
      }
    }

    if (isContentFinancial) {
      if (
        contentToPass.type === CONTENT_TYPE.GLOBAL_BILL ||
        isContentGlobalPayment
      ) {
        contentToPass.contentStatus = values.contentStatus || null;
      } else {
        contentToPass.contentStatus = values.contentStatus ? 'refund' : null;
      }

      if (
        isContentGlobalPayment &&
        contentToPass.subtype !== PAYMENT_METHOD.CHECK
      ) {
        // Don't store check number if the payment method is not check
        contentToPass.documentNumber = null;
      }

      const cleanAmount = {
        ...values.amount,
        value: values.amount.value ? parseFloat(values.amount.value) : 0.0,
      };
      if (cleanAmount.__typename) {
        delete cleanAmount.__typename;
      }
      contentToPass.amount = cleanAmount;
    }

    if (initialValues.type === 'timetracking') {
      if (didAnswersChange) {
        let answers = values.timetrackingAnswers;
        const questionIds =
          (values.contentStatus === 'checkin'
            ? parentInfo.timetrackingQuestions
            : parentInfo.timetrackingQuestionsCheckout) || [];
        const questionIdsMap = _.keyBy(questionIds);

        // Make sure the answers match with the questions
        answers = _.filter(
          answers,
          answer => questionIdsMap[answer.questionId]
        );
        contentToPass.timetrackingAnswers = answers;
      }
    }

    // START - deal with questions
    if (projectOrTemplate) {
      if (typeof values.useTimetrackingQuestions === 'boolean') {
        contentToPass.useTimetrackingQuestions =
          values.useTimetrackingQuestions;
      }
      // if there are timetracking question, figure out:
      //  which ones need creating
      //  which ones need updating
      //  did the order change (or do we care, just write back the strings array to the project)
      if (
        values.useTimetrackingQuestions &&
        projectQuestions &&
        projectQuestions.length > 0
      ) {
        const questionsToAdd = [];
        const questionsToUpdate = [];
        projectQuestions.forEach(question => {
          if (question.change) {
            if (question.change === 'new') {
              const toAdd = { ...question };
              delete toAdd.change; // dont need this on the object, was just for tracking changes
              delete toAdd.__typename;
              // run the add mutation
              questionsToAdd.push(toAdd);
            } else if (question.change === 'edit') {
              const toUpdate = { ...question };
              delete toUpdate.change; // dont need this on the object, was just for tracking changes
              delete toUpdate.__typename; // not in the input schema
              delete toUpdate.managingCompanyId; // not in the input schema - not allowed to change the managing company
              delete toUpdate.creatorId; // not in the input schema - not allowed to change creator
              // run the update mutation
              questionsToUpdate.push(toUpdate);
            }
          }
        });
        // now if questionsToAdd or questionsToUpdate have anything in them and the user has a company , run and await their updates
        if (
          (questionsToAdd.length || questionsToUpdate.length) &&
          managingCompanyInfo
        ) {
          const upsertQuestion = async questions => {
            try {
              await onAddOrUpdateCompanyQuestions({
                questions,
                companyId: managingCompanyInfo.managingCompanyId,
              });
            } catch (err) {
              // eslint-disable-next-line no-console
              console.log('onAddOrUpdateCompanyQuestions err: ', err);
            }
          };
          if (questionsToAdd.length) {
            await upsertQuestion(questionsToAdd);
          }
          if (questionsToUpdate.length) {
            await upsertQuestion(questionsToUpdate);
          }
        }

        // set the list of questionId's to add to the project
        contentToPass.timetrackingQuestions = values.timetrackingQuestions;
        contentToPass.timetrackingQuestionsCheckout =
          values.timetrackingQuestionsCheckout;
      }
      // END - deal with questions
      // START - deal with budgets
    }

    if (projectTemplateLead && values.budgets) {
      contentToPass.budgets = values.budgets.map(budget => {
        const cleanBudget = { ...budget };
        cleanBudget.value = budget.value ? parseFloat(budget.value) : 0.0;
        return removeAttributesFromObject(cleanBudget, '__typename');
      });
    }

    if (projectOrTemplate && values.userPayInfo) {
      contentToPass.userPayInfo = values.userPayInfo.map(payInfo => {
        const cleanPayInfo = { ...payInfo };
        return removeAttributesFromObject(cleanPayInfo, '__typename');
      });
    }

    // figure out if there's any permissions changes by comparing the initialValues to the values
    if (!creatingProjectFromLead && isTypeOfJrn) {
      const initialPermissions = [
        ...initialValues.allowedToEdit,
        ...initialValues.allowedToAdd,
        ...initialValues.allowedToView,
      ];
      const newPermissions = [
        ...values.allowedToEdit,
        ...values.allowedToAdd,
        ...values.allowedToView,
      ];
      // need a list of id's in the initial but NOT in the new - REMOVALS
      contentToPass.permissionsChangesRemove = _.difference(
        initialPermissions,
        newPermissions
      );
      // need a list of id's in the new but NOT in the initial - ADDITIONS
      contentToPass.permissionsChangesAdd = _.difference(
        newPermissions,
        initialPermissions
      );
    }

    if (!editingMode) {
      if (isTypeOfJrn && userInfo.type) {
        contentToPass.managingCompanyId =
          managingCompanyInfo && managingCompanyInfo.managingCompanyId;
      }
    }

    // need to upload images/video before adding content
    // only do this if there is something in the contentUrl and the uri doesn't have "cloudinary" in it
    let contentUrlToUse;
    if (
      values.contentUrl[0] &&
      !_.includes(values.contentUrl[0].uri, 'cloudinary')
    ) {
      const typeOfFile = contentToPass.type === 'video' ? 'video' : 'image';
      // Get all the presigned URLs
      const myAsyncLoopFunction = async (array, mappedFunction) => {
        const promises = array.map(mappedFunction);
        await Promise.all(promises);
      };

      let contentFolder;
      if (projectTemplateLead) {
        contentFolder = contentToPass.contentId;
      } else {
        contentFolder = permissionsFromParent || parentId;
      }

      const arrayOfPresignedUrls = [];
      const gettingAPresignedUrl = async () => {
        let presignedUrl;
        try {
          const presignUrlParams = {
            typeOfFile,
            customFolder: contentFolder,
          };
          // if (changeFilenamesOnUpload) {
          //   presignUrlParams.customFilename =
          //     changeFilenamesOnUpload || null;
          // }
          // *************couldn't we just ask for multiple signed URL's instead of doing seperate calls???
          presignedUrl = await getAPresignedUrl(presignUrlParams);
          arrayOfPresignedUrls.push(presignedUrl);
        } catch (e) {
          return {
            status: 'error',
            msg: 'could not get presignedUrl',
          };
        }
        return null;
      };
      await myAsyncLoopFunction(values.contentUrl, gettingAPresignedUrl);

      // ////////////////
      // now do the uploads and wait for the responses so we can replace the items in contentUrl

      // Upload all the files to their presigned URL's concurrently
      const arrayOfNewFiles = [...values.contentUrl];

      const uploadFile = async (presignedUrl, index) => {
        let uploadedResponse;
        try {
          uploadedResponse = await uploadToPresignedUrl(
            values.contentUrl[index],
            presignedUrl.signature,
            presignedUrl.timestamp,
            typeOfFile,
            false, // since this will never be called for profile photos
            changeFilenamesOnUpload || null, // pass the custome filename if it's there
            contentFolder // pass parent project id for custom folder
          );
        } catch (e) {
          return {
            status: 'error',
            msg: 'could not uploadToPresignedUrl',
          };
        }

        const aspectRatio =
          uploadedResponse.response.width / uploadedResponse.response.height;

        arrayOfNewFiles[index] = {
          uri: uploadedResponse.response.secure_url,
          aspectRatio,
        };

        return null;
      };

      await myAsyncLoopFunction(arrayOfPresignedUrls, uploadFile);

      let biggestAspectRatio = 0;
      arrayOfNewFiles.forEach(image => {
        if (image.aspectRatio > biggestAspectRatio) {
          biggestAspectRatio = image.aspectRatio;
        }
      });
      contentToPass.aspectRatio = biggestAspectRatio;
      contentUrlToUse = arrayOfNewFiles;
    } else {
      contentUrlToUse = values.contentUrl;
    }
    if (contentToPass.type === 'gallery' || contentToPass.type === 'image') {
      // if it's a gallery or image, make sure that if they chose more than 1 image, we set gallery, otherwise just image
      if (contentUrlToUse.length > 1) {
        contentToPass.type = 'gallery';
        contentToPass.contentUrl = JSON.stringify(contentUrlToUse);
      } else {
        contentToPass.type = 'image';
        contentToPass.contentUrl = contentUrlToUse[0].uri;
      }
    } else if (contentToPass.type === 'pdf') {
      contentToPass.contentUrl = contentUrlToUse[0].uri;
    } else if (
      contentToPass.type === 'task' ||
      contentToPass.type === 'punchlistItem'
    ) {
      contentToPass.contentUrl = JSON.stringify(contentUrlToUse);
    } else if (
      [
        CONTENT_TYPE.PROJECT,
        CONTENT_TYPE.LEAD,
        CONTENT_TYPE.TEMPLATE,
        CONTENT_TYPE.CONVERSATION,
        CONTENT_TYPE.VIDEO,
      ].includes(contentToPass.type) ||
      isFinancial
    ) {
      contentToPass.contentUrl =
        contentUrlToUse && contentUrlToUse[0] ? contentUrlToUse[0].uri : null;
    }

    if (values.ocrData) {
      // and add the ocrData to the media
      // MediaInfoInput {
      //   uri: String!
      //   aspectRatio: String
      //   type: String
      //   ocrData: OcrDataInput
      // }
      const cleanOcrData = { ...values.ocrData };
      delete cleanOcrData.duplicateInfo;
      delete cleanOcrData.__typename;

      const mediaObject = { ...contentUrlToUse[0], ocrData: cleanOcrData };
      contentToPass.media = [mediaObject];
    }

    let succeeded = false;
    let response;
    try {
      const options = { fromWhichAdminTool, user: userInfo, includeArchive };

      if (fromShoebox) {
        options.fromShoebox = true;
      }

      if (customerHasChanged) {
        options.fullCustomerDetails = values.customerInfo || {};
      }

      if (isTypeOfJrn) {
        if (editingMode) {
          try {
            response = await onUpdateJrn(contentToPass, options);
            succeeded = true;
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log('onUpdateJrn err: ', err);
          }
        } else if (createFromTemplate) {
          response = await onCreateProjectFromTemplate({
            templateId: createFromTemplate.contentId,
            projectInfo: createJrnInputMask(contentToPass),
            startDate: moment(contentToPass.startDate)
              .startOf('day')
              .toISOString(),
            fromLeadId: contentToPass.fromLeadId,
            options,
          });

          succeeded = true;
        } else {
          try {
            response = await onAddJrn(contentToPass, options);
            succeeded = true;
          } catch (err) {
            response = err;
            // eslint-disable-next-line no-console
            console.log('onAddJrn err: ', err);
          }
        }
      } else if (editingMode) {
        try {
          await onUpdateContent(contentToPass, options);
          succeeded = true;
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log('onUpdateContent err: ', err);
        }
      } else {
        try {
          // if the current user is an admin, he/she may not on the project yet,
          // pass their info in the options, so we can update the GetUsersByJrnId cache
          if (managingCompanyInfo && managingCompanyInfo.isCompanyAdmin) {
            options.createdByAdminUser = userInfo;
          }
          await onAddContent(contentToPass, options);
          succeeded = true;
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log('onAddContent err: ', err);
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('on Add or update Content error: ', err);
      response = err;
    }

    if (succeeded) {
      const analyticsOptions = {
        contentAction: editingMode ? 'Update Content' : 'Add Content',
        projectId: contentToPass.jrnId,
        userId,
        username: userInfo.username,
        email: userInfo.email,
        type:
          contentToPass.title !== 'hold' &&
          contentToPass.type === 'conversation'
            ? 'groupConversation'
            : contentToPass.type,
        contentId: contentToPass.contentId,
        fromTemplate: createFromTemplate ? createFromTemplate.contentId : null,
      };

      runAnalytics('Contents', analyticsOptions);

      if (values.contentUrl) {
        revokeUrls(values.contentUrl);
      }

      if (isContentGlobalExpense || isContentGlobalPayment) {
        const itemToSplit = editingMode
          ? response.data.updateJrn
          : response.data.createJrn;

        const splitInfo = {
          itemToSplit,
        };

        if (isContentGlobalExpense) {
          splitInfo.projectList = projectList;
        } else if (isContentGlobalPayment) {
          splitInfo.billList = billList;
        }

        const splitContentList = splitItem(splitInfo);

        const options = {
          fromWhichAdminTool,
          user: userInfo,
          includeArchive,
        };

        const { rejectedItems } = await batchRequest({
          items: splitContentList,
          noOfItemsPerBatch: 5,
          actionFunction: ({ contentInfo: projectContentInfo, action }) => {
            if (action === SPLIT_ACTION.UPDATE) {
              // Update content
              return onUpdateContent(projectContentInfo, {
                ...options,
              });
            }

            if (action === SPLIT_ACTION.DELETE) {
              // Delete content
              return onDeleteContent(
                projectContentInfo.contentId,
                projectContentInfo.jrnId,
                {
                  ...options,
                  // use this content to update cache
                  content:
                    projectContentInfo.type === CONTENT_TYPE.BILL_PAYMENT
                      ? projectContentInfo
                      : null,
                }
              );
            }

            if (action === SPLIT_ACTION.CREATE) {
              const newContentInfo = { ...projectContentInfo };

              return onAddContent(newContentInfo, {
                ...options,
                globalExpense: itemToSplit,
              });
            }

            return Promise.resolve();
          },
        });

        if (!_.isEmpty(rejectedItems)) {
          // eslint-disable-next-line no-console
          console.log('onFormSubmit ~ rejectedItems: ', rejectedItems);
          enqueueSnackbar('Sorry! An error occurred on split item.', {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      }

      if (fromShoebox) {
        const toReturn = { success: true };
        if (splitWithAnother) {
          toReturn.startingInfo = contentToPass;
        }
        // need to include if they wanted to add it to another project
        onComplete(toReturn);
        return;
      }

      if (splitWithAnother) {
        setAssignFlowInfo({ ...assignFlowInfo, open: true });
        return;
      }
      if (addAnother) {
        resetForm(initialValues);
        resetOutsideFormik();
        if (onAddAnother) {
          onAddAnother();
        }
      } else {
        if (creatingProjectFromLead) {
          const dataPath = createFromTemplate
            ? 'data.createProjectFromTemplate'
            : 'data.createJrn';
          onComplete(_.get(response, dataPath));
          return;
        }
        onComplete(contentToPass);
      }
    } else {
      if (creatingProjectFromLead) {
        onComplete(response);
        return;
      }
      if (createFromTemplate) {
        onComplete(contentToPass);
        return;
      }
      // show a dialog to let them know something went wrong and to try again later
      setShowUnknownIssueDialog(true);
    }

    if (!editingMode && !createFromTemplate && !fromWhichAdminTool) {
      if (contentToPass.type === 'project') {
        if (contentToPass.jrnId !== TOP_PROJECT_ID) {
          // if it's a subproject, go to the subproject through the parent project page
          // this will ensure that going back to parent project after deleting subproject works
          history.push(
            `/projects/${contentToPass.jrnId}/${contentToPass.contentId}`
          );
        } else {
          history.push(`/projects/${contentToPass.contentId}`);
        }
      } else if (contentToPass.type === 'conversation') {
        history.push(`/messages/${contentToPass.contentId}`);
      }
    }
  };

  if (initialValues.type === 'shift') {
    return (
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <AdvancedShiftCreator
          onDone={onComplete}
          userInfo={userInfo}
          allUsersOnProject={allUsersOnProject}
          currentUsersOnProject={localAllUsersCurrentlyOnProject}
          parentId={parentId}
          onAddContent={onAddContent}
          onUpdateContent={onUpdateContent}
          existingContentInfo={initialValues}
          labelOptions={allLabelOptions}
        />
      </Grid>
    );
  }

  const goToConversation = conversationId => {
    history.push(`/messages/${conversationId}`);
    onComplete();
  };

  const getOcrDataForMediaItem = async ({ mediaSource, mediaSourceType }) => {
    // mediaSourceType = URI, BASE64
    let ocrData;
    try {
      ocrData = await client.query({
        query: GetOcrDataForMedia,
        variables: {
          mediaSource,
          mediaSourceType,
        },
        fetchPolicy: 'no-cache',
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('getOcrDataForMediaItem -> GetOcrDataForMedia err: ', err);
    }
    const toReturn = _.get(ocrData, ['data', 'getOcrDataForMedia'], null);
    return toReturn;
  };

  return (
    <AddContentFormView
      // Input data - general
      allLabelOptions={allLabelOptions}
      allUsersOnProject={allUsersOnProject}
      defaultType={passedTypeFromAbove}
      existingConversations={existingConversations}
      financialSegmentKey={financialSegmentKey}
      initialValues={initialValues}
      myCrew={myCrew}
      myCrewLoading={getCompanyCrewLoading}
      saveButtonIndex={saveButtonIndex}
      // Input data - modes
      createFromTemplate={createFromTemplate}
      creatingProjectFromLead={creatingProjectFromLead}
      editingMode={editingMode}
      fromShoebox={fromShoebox}
      isFinancial={isFinancial}
      isGlobalExpense={isGlobalExpense}
      isGlobalPayment={isGlobalPayment}
      isInTemplate={isInTemplate}
      isSubproject={isSubproject}
      restrictedMode={restrictedMode}
      // Forwarded props
      isParentExtended={isParentExtended}
      hideCustomerOption={hideCustomerOption}
      parentInfo={parentInfo}
      // Handlers
      getProjectQuestions={getProjectQuestions}
      goToConversation={goToConversation}
      handleParentChoice={handleParentChoice}
      onComplete={onComplete}
      onFormSubmit={onFormSubmit}
      resetVisualAssignedTo={resetVisualAssignedTo}
      whatShouldStatusBe={whatShouldStatusBe}
      getOcrDataForMediaItem={getOcrDataForMediaItem}
      handleBack={handleBack}
      // Shared states
      setAddAnother={setAddAnother}
      assignFlowInfo={assignFlowInfo}
      setAssignFlowInfo={setAssignFlowInfo}
      convoIsGroup={convoIsGroup}
      setConvoIsGroup={setConvoIsGroup}
      setDidAnswersChange={setDidAnswersChange}
      localCurrentTimetrackingStatus={localCurrentTimetrackingStatus}
      setSplitWithAnother={setSplitWithAnother}
      templateChanges={templateChanges}
      setTemplateChanges={setTemplateChanges}
      projectQuestions={projectQuestions}
      setProjectQuestions={setProjectQuestions}
      showUnknownIssueDialog={showUnknownIssueDialog}
      setShowUnknownIssueDialog={setShowUnknownIssueDialog}
      visualAssignedTo={visualAssignedTo}
      setVisualAssignedTo={setVisualAssignedTo}
      projectList={projectList}
      setProjectList={setProjectList}
      billList={billList}
      setBillList={setBillList}
    />
  );
};

function mapStateToProps(state) {
  return {
    userId: state.userInfo.userId,
    userInfo: state.userInfo,
    changeFilenamesOnUpload: state.appState.changeFilenamesOnUpload || false,
    contentLabelSuggestions: state.appState.contentLabelSuggestions || null,
    projectLabelSuggestions: state.appState.projectLabelSuggestions || null,
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
  };
}

export default compose(
  CreateContentMutationAction,
  AddJrnMutationAction,
  UpdateJrnMutationAction,
  UpdateContentMutationAction,
  DeleteContentAction,
  AddOrUpdateCompanyQuestionsAction,
  CreateProjectFromTemplateAction,
  GetCompanyCrewAction,
  GetCompanyInfoAction,
  withApollo
)(
  connect(mapStateToProps)(
    GoogleApiWrapper({
      apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    })(withRouter(withSnackbar(AddContentFormChecker)))
  )
);
