import gql from 'graphql-tag';

export default gql`
  query ListMyActivityFeedItems($userId: String!) {
    listMyActivityFeed(input: { userId: $userId }) {
      items {
        viewerId
        type
        actionType
        timestamp
        contentUrl
        projectId
        contentTitle
        contentParentTitle
        userId
        username
        userProfilePic
        assignedTo
        contentStatus
      }
    }
  }
`;
