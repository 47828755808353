import { combineReducers } from 'redux';
import AppStateReducer from './reducer_app-state';
import currentAuthReducer from './reducer_current-auth';
import notificationNavReducer from './reducer_notification-nav';
import userInfoReducer from './reducer_user-info';

const reducers = combineReducers({
  appState: AppStateReducer,
  currentAuth: currentAuthReducer,
  notificationNav: notificationNavReducer,
  userInfo: userInfoReducer,
});

export default reducers;
