import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  ButtonBase,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  CheckCircleOutline as AssignCardIcon,
  RemoveCircleOutline as RevokeCardIcon,
} from '@material-ui/icons';
import _ from 'lodash';

import OkCancelDialog from '../OkCancelDialog/okCancelDialog';
import { MAZUMAGO_CREDIT_CARD_STATUS } from '../../config/appDefaults';
import TrussCardImage from '../../assets/images/truss/truss-card.png';

const useStyles = makeStyles(theme => {
  const boxShadow = '4px 4px 12px rgba(92, 88, 255, 0.6)';
  return {
    container: {
      paddingBottom: 0,
    },
    creditCard: {
      backgroundImage: `url(${TrussCardImage})`, // FIX
      backgroundSize: 'contain',
      width: 240,
      height: 150,
      borderRadius: 12,
      transition: 'box-shadow 0.3s ease 0s',
      '&:hover': {
        boxShadow,
      },
    },
    mouseOver: {
      boxShadow,
    },
    cardInfo: {
      height: 150,
      padding: theme.spacing(2),
      '& > h5': {
        color: '#FFFFFF',
        textAlign: 'left',
      },
      '& > h6': {
        color: '#FFFFFF',
        textAlign: 'left',
      },
      '& > p': {
        marginTop: 6,
        color: '#FFFFFF',
        textAlign: 'right',
      },
    },
    actionContainer: {
      width: 240,
      height: 30,
      marginTop: 4,
      paddingLeft: theme.spacing(2),
      '& span': {
        textTransform: 'none',
      },
    },
    frozenCard: {
      opacity: 0.7,
    },
    closedCard: {
      opacity: 0.4,
    },
  };
});

const CreditCardItem = ({
  card,
  user,
  isAssigned,
  onActionButtonClick,
  containerStyle,
  hideActionButton,
  disabled,
}) => {
  const classes = useStyles();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [mouseOverCardId, setMouseOverCardId] = useState(null);

  const userFullName = useMemo(() => {
    if (user) {
      const names = [];
      if (user.firstName) {
        names.push(user.firstName);
      }
      if (user.lastName) {
        names.push(user.lastName);
      }
      return names.join(' ');
    }
    return '';
  }, [user]);

  const cardStatus = useMemo(() => {
    if (card) {
      const status = _.find(MAZUMAGO_CREDIT_CARD_STATUS, {
        value: card.status,
      });
      if (status) {
        return status.displayValue;
      }
    }
    return '';
  }, [card]);

  const tooltipMessage = useMemo(() => {
    if (disabled) {
      return '';
    }

    return isAssigned
      ? `Click to revoke this card from ${userFullName}`
      : `Click to assign this card to ${userFullName}`;
  }, [disabled, isAssigned, userFullName]);

  if (!card) {
    return null;
  }

  return (
    <>
      <Grid
        container
        direction="column"
        className={classes.container}
        style={{ ...containerStyle }}
      >
        <Tooltip title={tooltipMessage}>
          <ButtonBase
            onClick={() => {
              setOpenConfirmDialog(true);
            }}
            disabled={disabled}
            className={`${classes.creditCard} ${mouseOverCardId ===
              card.cardId && classes.mouseOver} ${(card.status ===
              MAZUMAGO_CREDIT_CARD_STATUS.FROZEN.value ||
              card.status === MAZUMAGO_CREDIT_CARD_STATUS.INACTIVE.value) &&
              classes.frozenCard} ${card.status ===
              MAZUMAGO_CREDIT_CARD_STATUS.CLOSED_BY_CUSTOMER.value &&
              classes.closedCard}`}
          >
            <Grid
              container
              direction="column"
              className={classes.cardInfo}
              justifyContent="center"
            >
              <Typography variant="h5">{`•••• •••• •••• ${card.lastFourDigits}`}</Typography>
              <Typography variant="h5">{card.nickname}&nbsp;</Typography>
              <Typography>{cardStatus}</Typography>
            </Grid>
          </ButtonBase>
        </Tooltip>
        <Grid item className={classes.actionContainer}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>{isAssigned ? userFullName : ''}</Typography>
            {!hideActionButton && (
              <Tooltip title={tooltipMessage}>
                <IconButton
                  size="small"
                  onClick={() => {
                    setOpenConfirmDialog(true);
                  }}
                  onMouseOver={() => {
                    setMouseOverCardId(card.cardId);
                  }}
                  onMouseOut={() => {
                    setMouseOverCardId(null);
                  }}
                  disabled={disabled}
                >
                  {isAssigned ? <RevokeCardIcon /> : <AssignCardIcon />}
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>
      <OkCancelDialog
        open={openConfirmDialog}
        title="Are you sure?"
        onClose={() => {
          setOpenConfirmDialog(false);
        }}
        onConfirm={() => {
          onActionButtonClick(!isAssigned);
        }}
      >
        {isAssigned ? (
          <Typography>
            Are you sure you want to revoke the card ending with{' '}
            <b>{card.lastFourDigits}</b> from <b>{userFullName}</b>
          </Typography>
        ) : (
          <Typography>
            Are you sure you want to assign the card ending with{' '}
            <b>{card.lastFourDigits}</b> to <b>{userFullName}</b>
          </Typography>
        )}
      </OkCancelDialog>
    </>
  );
};

export default CreditCardItem;
