import React, { useState, useMemo, useEffect } from 'react';
import { compose } from 'react-apollo';
import _ from 'lodash';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import {
  GetCompanyJobCostAccountsAction,
  UpdateJobCostAccountsAction,
} from '../../../graphql/graphql';
import LoadingCover from '../../LoadingCover/loadingCover';
import JobCostSetting from './job-cost-setting';
import jobCostGraphs from './job-cost-graphs';

const JobCostSettings = ({
  reportData,
  updateReportData,
  jobCostAccounts,
  getCompanyJobCostAccountsLoading,
  onUpdateJobCostAccounts,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [chartSettings, setChartSettings] = useState([]);

  const currentSettings = useMemo(() => {
    const currentSettingsToSet = {};

    _.each(reportData, ({ sourceAccount, isHidden }, jobCostType) => {
      currentSettingsToSet[jobCostType] = {
        sourceAccount: sourceAccount || '',
        isHidden,
      };
    });

    return currentSettingsToSet;
  }, [reportData]);

  useEffect(() => {
    // Update the state to match any changes to the current settings
    setChartSettings(currentSettings);
  }, [currentSettings]);

  const handleJobCostAccountChanges = ({
    jobCostType,
    sourceAccount,
    isHidden,
  }) => {
    const chartSettingsUpdate = { ...chartSettings };

    chartSettingsUpdate[jobCostType] = {
      sourceAccount,
      isHidden,
    };

    setChartSettings(chartSettingsUpdate);
  };

  const hasUpdated = !_.isEqual(currentSettings, chartSettings);

  const updateJobCostAccounts = async () => {
    const jobCostAccountUpdates = [];
    _.each(chartSettings, (chartSetting, jobCostType) => {
      const settingUpdated = !_.isEqual(
        chartSetting,
        currentSettings[jobCostType]
      );

      if (settingUpdated) {
        jobCostAccountUpdates.push({
          jobCostType,
          quickBooksAccountFQN: chartSetting.sourceAccount,
          isHidden: chartSetting.isHidden,
        });
      }
    });

    if (jobCostAccountUpdates.length === 0) {
      return;
    }

    setIsLoading(true);

    // Update accounts
    await onUpdateJobCostAccounts(jobCostAccountUpdates);

    // Update dashboard charts
    await updateReportData();

    setIsLoading(false);
  };

  return (
    <>
      <Grid container item style={{ flex: 1 }}>
        <Grid container item>
          {jobCostGraphs.map(graph => {
            const settings = chartSettings[graph.name];

            if (!settings) {
              return null;
            }

            return (
              <Grid item style={{ width: '50%', padding: 8 }} key={graph.name}>
                <JobCostSetting
                  graph={graph}
                  settings={settings}
                  jobCostAccounts={jobCostAccounts}
                  onJobCostAccountChanges={handleJobCostAccountChanges}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid container item justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={updateJobCostAccounts}
            disabled={!hasUpdated}
          >
            Update
            {isLoading && (
              <CircularProgress
                size={16}
                style={{ marginLeft: 8, color: '#FFFFFF' }}
              />
            )}
          </Button>
        </Grid>
      </Grid>
      {getCompanyJobCostAccountsLoading && <LoadingCover />}
    </>
  );
};

export default compose(
  GetCompanyJobCostAccountsAction,
  UpdateJobCostAccountsAction
)(JobCostSettings);
