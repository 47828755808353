import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import _ from 'lodash';

import BookkeepingAlertsReportAlertListItemTable from './bookkeeping-alerts-report-alert-list-item-table';
import { BOOKKEEPING_ALERT_REPORT_DATA_TYPE } from '../bookkeeping-alerts.constants';
import palette from '../../../theme/palette';

const BookkeepingAlertsReportAlertListItem = ({
  alertIdentifier,
  data,
  dataType = null,
  name = null,
  addressedStateEventMetaMap,
  processingAlertInstanceMap,
  setProcessingAlertInstanceMap,
  hideAddressedColumn,
  isPrinting,
}) => {
  let dataToUse = data;

  if (dataType === BOOKKEEPING_ALERT_REPORT_DATA_TYPE.TXN_STATUS_INFO_ARRAY) {
    // Process data for txn status info arrays
    const countByTypeMap = {};
    _.map(data, ({ entityType }) => {
      if (!countByTypeMap[entityType]) {
        countByTypeMap[entityType] = 0;
      }
      countByTypeMap[entityType] += 1;
    });

    dataToUse = _.keys(countByTypeMap).map(entityType => ({
      entityType,
      count: countByTypeMap[entityType],
    }));
  }

  return (
    <Grid container item justifyContent="center" style={{ paddingTop: 16 }}>
      {name && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            gutterBottom
            style={{ color: palette.brandColorBlackish }}
          >
            Created or Modified By: {name}
          </Typography>
        </Grid>
      )}
      <BookkeepingAlertsReportAlertListItemTable
        alertIdentifier={alertIdentifier}
        dataType={dataType}
        data={dataToUse}
        addressedStateEventMetaMap={addressedStateEventMetaMap}
        processingAlertInstanceMap={processingAlertInstanceMap}
        setProcessingAlertInstanceMap={setProcessingAlertInstanceMap}
        hideAddressedColumn={hideAddressedColumn}
        isPrinting={isPrinting}
      />
    </Grid>
  );
};

export default BookkeepingAlertsReportAlertListItem;
