import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import copy from 'copy-to-clipboard';
import { Button, Typography, Tooltip, IconButton } from '@material-ui/core';

import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LinkIcon from '@material-ui/icons/Link';

import ActionButtonHolder from './action-button-holder';

const useStyles = makeStyles(() => ({
  documentWrapper: {
    '& canvas': {
      width: '100% !important',
      height: 'auto !important',
    },
  },
}));

const ViewPdf = ({
  initialScale = 1,
  fileLocation,
  showCopyUrl,
  wrapperStyle,
  compactView,
}) => {
  const classes = useStyles();
  const file = useMemo(() => fileLocation, [fileLocation]);
  const [howManyPages, setHowManyPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const changePageNumber = backOrForward => {
    let newPageNumber;
    if (backOrForward === 'back') {
      newPageNumber = pageNumber - 1 || 1;
    } else if (backOrForward === 'forward') {
      newPageNumber =
        pageNumber + 1 <= howManyPages ? pageNumber + 1 : howManyPages;
    }
    setPageNumber(newPageNumber);
  };

  const pageBack = () => {
    changePageNumber('back');
  };
  const pageForward = () => {
    changePageNumber('forward');
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setHowManyPages(numPages);
  };

  const copyLink = () => {
    copy(file);
  };

  const pinchZoomOptions = {
    limitToBounds: false,
  };

  return (
    <TransformWrapper {...pinchZoomOptions} initialScale={initialScale}>
      {({ zoomIn, zoomOut, resetTransform }) => (
        <>
          <ActionButtonHolder
            extraContainerStyles={{
              background: '#fff',
              paddingBottom: 16,
              marginBottom: 0,
            }}
            extraButtonsStyles={{ marginTop: 8, marginBottom: 0 }}
          >
            <Typography variant="body1" style={{ paddingRight: 16 }}>
              {`Page ${pageNumber} of ${howManyPages || '1'}`}
            </Typography>
            {howManyPages > 1 && (
              <>
                <Tooltip title="Previous Page">
                  <IconButton
                    onClick={pageBack}
                    size={compactView ? 'small' : undefined}
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Next Page">
                  <IconButton
                    onClick={pageForward}
                    size={compactView ? 'small' : undefined}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
            <Tooltip title="Zoom Out">
              <IconButton
                onClick={() => zoomOut()}
                size={compactView ? 'small' : undefined}
              >
                <ZoomOutIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Zoom In">
              <IconButton
                onClick={() => zoomIn()}
                size={compactView ? 'small' : undefined}
              >
                <ZoomInIcon />
              </IconButton>
            </Tooltip>
            <Button
              onClick={() => resetTransform()}
              size={compactView ? 'small' : undefined}
            >
              Reset
            </Button>
            {showCopyUrl && (
              <Tooltip title="Copy URL">
                <IconButton
                  onClick={copyLink}
                  size={compactView ? 'small' : undefined}
                >
                  <LinkIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Open in new tab">
              <IconButton
                href={file}
                target="_blank"
                rel="noopener noreferrer"
                size={compactView ? 'small' : undefined}
              >
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
          </ActionButtonHolder>
          <TransformComponent
            wrapperStyle={{
              width: '100%',
              border: '1px solid #ccc',
              ...wrapperStyle,
            }}
            contentStyle={{ width: '100%' }}
          >
            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              className={classes.documentWrapper}
            >
              <Page pageNumber={pageNumber} />
            </Document>
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  );
};

export default ViewPdf;
