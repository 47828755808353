import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import ContentLoader from 'react-content-loader';
import Paper from '@material-ui/core/Paper';

import { LIST_TYPE, SORT_TYPE } from './list-of-projects-definitions';
import ListOfProjectsHeader from './list-of-projects-header';
import SingleProject from './singleProject';
import ContentDetailsModal from '../../views/add-to-project/content-details-modal';
import UpgradeRequiredDialog from '../upgrade-required/upgrade-required';

import store from '../../store';

import { buildLabels } from '../../helpers/index';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: `${theme.spacing(1)}px auto 0 auto`,
    width: '100%',
  },
}));

const ListOfProjectsView = props => {
  const {
    projects,
    onClickOfProject,
    refetchListingProjects,
    idOfProjectBeingViewed,
    selectedData,
    setSelectedData,
    userInfo,
    companyInfo,
    projectSort,
    loading,
  } = props;

  const classes = useStyles();
  const [allProjects, setAllProjects] = useState([]);
  const [useThisData, setUseThisData] = useState([]);
  const [selectedSortOption, setSelectedSortOption] = useState(
    projectSort || SORT_TYPE.STARTDATEDECS
  );
  const [upgradeDialogOption, setUpgradeDialogOption] = useState({
    open: false,
  });
  const [isFiltered, setIsFiltered] = useState(false);
  const [showManualAddDialog, setShowManualAddDialog] = useState({
    open: false,
    canEdit: false,
    canAdd: false,
    parentId: null,
  });

  const sortAndSet = () => {
    let sortedData = projects;
    const projectTitleAsLowercase = project => project?.title?.toLowerCase();
    if (sortedData) {
      let sortWhat = ['startDate', projectTitleAsLowercase];
      let sortHow = ['desc', 'asc'];

      switch (selectedSortOption) {
        case SORT_TYPE.STARTDATEDECS:
          sortWhat = ['startDate', projectTitleAsLowercase];
          sortHow = ['desc', 'asc'];
          break;
        case SORT_TYPE.STARTDATEASC:
          sortWhat = ['startDate', projectTitleAsLowercase];
          sortHow = ['asc', 'desc'];
          break;
        case SORT_TYPE.TITLEASC:
          sortWhat = [projectTitleAsLowercase, 'startDate'];
          sortHow = ['asc', 'desc'];
          break;
        case SORT_TYPE.TITLEDECS:
          sortWhat = [projectTitleAsLowercase, 'startDate'];
          sortHow = ['desc', 'asc'];
          break;
        default:
          sortWhat = ['startDate', projectTitleAsLowercase];
          sortHow = ['desc', 'asc'];
          break;
      }

      sortedData = _.orderBy(sortedData, sortWhat, sortHow);
    }

    setAllProjects(sortedData);
  };

  useEffect(() => {
    const allProjectLabels =
      projects && projects.length ? buildLabels(projects) : [];
    store.dispatch({
      type: 'UPDATE_ALL_PROJECT_LABEL_SUGGESTIONS',
      payload: { projectLabelSuggestions: allProjectLabels },
    });

    sortAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, selectedSortOption]);

  const renderLoading = () => {
    const howMany = [1, 2, 3, 4, 5];
    return howMany.map(key => (
      <Grid item key={key} style={{ width: '100%' }}>
        <Paper className={classes.paper}>
          <ContentLoader
            height={112}
            width={300}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            style={{ maxHeight: 112, maxWidth: 300 }}
          >
            <circle cx="63" cy="56" r="40" />
            <rect x="120" y="40" rx="0" ry="0" width="168" height="18" />
            <rect x="120" y="65" rx="0" ry="0" width="120" height="12" />
          </ContentLoader>
        </Paper>
      </Grid>
    ));
  };

  let wording;
  switch (selectedData) {
    case LIST_TYPE.MY_PROJECTS:
      wording = 'projects';
      break;
    case LIST_TYPE.COMPANY_PROJECTS:
      wording = 'projects';
      break;
    case LIST_TYPE.ARCHIVED_PROJECTS:
      wording = 'archived projects';
      break;
    case LIST_TYPE.TEMPLATES:
      wording = 'templates';
      break;
    default:
      wording = 'items';
  }

  return (
    <Grid
      item
      container
      alignItems="center"
      direction="column"
      spacing={2}
      style={{ padding: 16 }}
    >
      <ListOfProjectsHeader
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        userInfo={userInfo}
        companyInfo={companyInfo}
        projects={allProjects}
        setIsFiltered={setIsFiltered}
        setUseThisData={setUseThisData}
        setUpgradeDialogOption={setUpgradeDialogOption}
        setShowManualAddDialog={setShowManualAddDialog}
        refresh={refetchListingProjects}
        selectedSortOption={selectedSortOption}
        setSelectedSortOption={setSelectedSortOption}
      />
      {loading ? (
        renderLoading()
      ) : (
        <Grid item style={{ width: '100%' }}>
          {useThisData && !useThisData.length && isFiltered && (
            <Typography variant="h5" align="center" style={{ padding: 24 }}>
              No search results.
            </Typography>
          )}
          {useThisData &&
            useThisData.length === 0 &&
            !isFiltered &&
            selectedData !== 'templates' && (
              <Typography variant="h5" align="center" style={{ padding: 24 }}>
                You have no {wording}.
              </Typography>
            )}

          {selectedData === 'templates' &&
            useThisData &&
            useThisData.length === 0 &&
            !isFiltered && (
              <Typography variant="h5" align="center" style={{ padding: 24 }}>
                You have no templates. Save a project as a template to add it to
                your list!
              </Typography>
            )}

          {useThisData &&
            useThisData.map(project => (
              <SingleProject
                key={project.contentId}
                project={project}
                onClickOfProject={onClickOfProject}
                highlightSelected={project.contentId === idOfProjectBeingViewed}
                templateMode={selectedData === 'templates'}
              />
            ))}
          {showManualAddDialog.open && (
            <ContentDetailsModal
              mode={showManualAddDialog.mode}
              parentInfo={showManualAddDialog.projectInfo}
              existingInfo={showManualAddDialog.existingInfo}
              onClose={() => {
                setShowManualAddDialog({
                  ...showManualAddDialog,
                  open: false,
                });
              }}
            />
          )}
          {upgradeDialogOption.open && (
            <UpgradeRequiredDialog
              handleClose={() => setUpgradeDialogOption({ open: false })}
              userInfo={userInfo}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    projectSort: state.appState.projectSort || null,
  };
}

export default connect(mapStateToProps)(ListOfProjectsView);
