import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import copy from 'copy-to-clipboard';
import { Button, Tooltip, IconButton } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LinkIcon from '@material-ui/icons/Link';

import ActionButtonHolder from '../ProjectContentListing/ContentTypes/action-button-holder';
import ViewPdf from '../ProjectContentListing/ContentTypes/view-pdf';
import { getToJpg } from '../../helpers';

const FullScreenGallery = ({
  images,
  showArrows,
  showThumbs,
  selectedItem,
  compactView,
  thumbsActions,
  thumbWidth = 80,
}) => {
  return (
    <Carousel
      showArrows={showArrows}
      showThumbs={showThumbs}
      showIndicators={false}
      showStatus={false}
      useKeyboardArrows
      infiniteLoop
      selectedItem={selectedItem}
      thumbWidth={thumbWidth}
      renderThumbs={children => {
        return children.map(child => {
          let imgSrc = child.key;
          const isPdf = child && child.key && child.key.endsWith('.pdf');
          if (isPdf) {
            imgSrc = child.key.replace('.pdf', '.jpg');
          }
          const thumbNail = (
            <img src={imgSrc} key={child.key} alt="supporting doc" />
          );
          if (thumbsActions) {
            return thumbsActions(thumbNail);
          }
          return thumbNail;
        });
      }}
      //   onChange={onChange}
      //   onClickItem={onClickItem}
      //   onClickThumb={onClickThumb}
    >
      {images.map(image => {
        const imageUri = image && image.uri;
        if (!imageUri) {
          return null;
        }
        const isPdf = imageUri.endsWith('.pdf');
        if (isPdf) {
          return (
            <ViewPdf
              fileLocation={image.uri}
              key={image.uri}
              compactView={compactView}
            />
          );
        }

        const imageUriToUse = getToJpg(imageUri);
        const imageToUse = { ...image, uri: imageUriToUse };

        return (
          <TransformWrapper initialScale={1} key={imageToUse.uri}>
            {({ zoomIn, zoomOut, resetTransform }) => (
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <TransformComponent>
                  <img src={imageToUse.uri} alt="supporting doc" />
                </TransformComponent>
                <div
                  style={{ position: 'absolute', left: 0, right: 0, top: 15 }}
                >
                  <ActionButtonHolder
                    extraButtonsStyles={{ background: '#fff' }}
                  >
                    <Tooltip title="Zoom Out">
                      <IconButton onClick={() => zoomOut()}>
                        <ZoomOutIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Zoom In">
                      <IconButton onClick={() => zoomIn()}>
                        <ZoomInIcon />
                      </IconButton>
                    </Tooltip>
                    <Button onClick={() => resetTransform()}>Reset</Button>
                    <Tooltip title="Copy URL">
                      <IconButton onClick={() => copy(imageToUse.uri)}>
                        <LinkIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Open in new tab">
                      <IconButton
                        href={imageToUse.uri}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </Tooltip>
                  </ActionButtonHolder>
                </div>
              </div>
            )}
          </TransformWrapper>
        );
      })}
    </Carousel>
  );
};

export default FullScreenGallery;
