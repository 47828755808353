import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import uuid from 'uuid';
import moment from 'moment';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    // padding: theme.spacing(2),
  },
  textfieldWrapper: {
    width: '100%',
    flex: 1,
  },
  textfield: {
    display: 'flex',
    flex: 1,
  },
  postButton: {
    marginLeft: theme.spacing(1),
  },
}));

const CommentInput = ({
  addCommentAction,
  contentItem,
  userId,
  autoFocus = true,
  contentAccessInfo,
}) => {
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [commentText, setCommentText] = useState('');

  const handleChange = event => {
    setCommentText(event.target.value);
  };

  const handlePost = () => {
    if (!commentText || commentText === '') return;
    setSubmitting(true);
    const newComment = {
      commentId: uuid(),
      contentId: contentItem.contentId,
      userId,
      dateCreated: moment().toISOString(),
      content: commentText.trim(),
      contentType: contentItem.type,
      synced: false,
    };

    if (contentAccessInfo && contentAccessInfo.contentType === 'rfi') {
      newComment.contentType = 'rfi';
    }
    if (!contentAccessInfo) {
      addCommentAction(newComment, { jrnId: contentItem.jrnId });
    } else {
      addCommentAction(newComment, contentAccessInfo);
    }

    setCommentText('');
    setSubmitting(false);
  };

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item className={classes.textfield}>
        <TextField
          autoFocus={autoFocus}
          className={classes.textfieldWrapper}
          label="Comment..."
          variant="outlined"
          value={commentText}
          onChange={handleChange}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handlePost();
            }
          }}
          disabled={submitting}
          style={{ background: '#fff' }}
        />
        <Button
          disabled={commentText === '' || submitting}
          className={classes.postButton}
          onClick={handlePost}
          variant="contained"
        >
          Post
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  userId: state.userInfo && state.userInfo.userId,
});

export default connect(mapStateToProps)(CommentInput);
