import React from 'react';
import { compose } from 'react-apollo';
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import _ from 'lodash';

import DialogTitle from '../../../components/dialog-title/dialog-title';
import ChooseProjectWithSubs from '../../../components/choose-project-dialog/choose-project-with-subs';
import { MoveContentToOtherProjectAction } from '../../../graphql/graphql';

const MoveTimetrackingEntry = ({
  showMoveDialog,
  setShowMoveDialog,
  setShowLoadingCover,
  onMoveContentToOtherProjectAction,
  callback,
}) => {
  const handleMovePair = async ({ project: projectToMoveTo }) => {
    setShowMoveDialog(currentState => {
      return { ...currentState, open: false };
    });

    setShowLoadingCover({ open: true, message: 'Moving...' });
    const { checkInContent, checkOutContent } = showMoveDialog;
    const contentsToMove = [];
    if (checkInContent) {
      contentsToMove.push(checkInContent);
    }
    if (checkOutContent) {
      contentsToMove.push(checkOutContent);
    }

    await _.reduce(
      contentsToMove,
      (promiseChain, contentToMove) => {
        return promiseChain.then(async () => {
          const content = {
            ...contentToMove,
          };

          await onMoveContentToOtherProjectAction({
            contentToMoveId: content.contentId,
            oldProjectId: content.jrnId,
            newProjectId: projectToMoveTo.contentId,
            existingContent: content,
            skipOptimisticResponse: true,
          });
        });
      },
      Promise.resolve()
    );
    callback({ msg: 'success', idOfProjectMovedTo: projectToMoveTo.contentId });
    setShowLoadingCover({ open: false, message: '' });
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open
      PaperProps={{ style: { minHeight: '50%' } }}
    >
      <DialogTitle
        onClose={() => {
          setShowMoveDialog({ open: false });
        }}
      />
      <DialogContent>
        <>
          <Typography variant="h2" gutterBottom align="center" color="primary">
            Choose which project to move this entry to:
          </Typography>
          <ChooseProjectWithSubs setSelectedProject={handleMovePair} />
        </>
      </DialogContent>
    </Dialog>
  );
};

export default compose(MoveContentToOtherProjectAction)(MoveTimetrackingEntry);
