import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import palette from '../../theme/palette';

const UnreviewedExpenseAccountsDialog = ({
  title,
  goToSettings,
  handleClose,
}) => (
  <Dialog
    open
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent dividers style={{ paddingTop: 20, paddingBottom: 20 }}>
      <DialogContentText style={{ color: palette.text.primary }}>
        Before relying on your break-even scoreboard data, you must first review
        and approve the classification of each expense and cost of goods sold
        account used to generate the results.
        <br />
        <br />
        To review and approve the accounts, select the Settings icon or choose
        &quot;Go to Settings&quot; below and navigate to the Account Mapping
        tab.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => {
          goToSettings();
          handleClose();
        }}
        variant="contained"
        color="primary"
      >
        Go to Settings
      </Button>
      <Button onClick={handleClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default UnreviewedExpenseAccountsDialog;
