import { SERIES_DATA_TYPE, FAKE_INFINITY } from '../../config/appDefaults';

export const processType = (value, type) => {
  switch (type) {
    case SERIES_DATA_TYPE.DATE:
      return new Date(value);
    case SERIES_DATA_TYPE.NUMBER:
    case SERIES_DATA_TYPE.PERCENTAGE:
    case SERIES_DATA_TYPE.HOUR:
    case SERIES_DATA_TYPE.MONEY:
      if (value === 'INF') {
        return FAKE_INFINITY;
      }
      return value !== null ? parseFloat(value) : null;
    default:
      return value;
  }
};

export const processXYTypes = (values, xType, yType) => {
  return values.map(({ x, y }) => ({
    x: processType(x, xType),
    y: processType(y, yType),
  }));
};
