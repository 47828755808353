import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-apollo-hooks';
import {
  Grid,
  Typography,
  Switch as MuiSwitch,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import { HelpOutline as HelpOutlineIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';

import { CONTENT_DEFINITION, CONTENT_TYPE } from '../../config/appDefaults';
import GetCompanyInfo from '../../graphql/queries/get-company-info';
import AddOrUpdateCompanyInfo from '../../graphql/mutations/mutation_add-or-update-company';

import SettingsSection from '../settings-section';
import SimpleDialog from '../SimpleTextDialog/simpleTextDialog';

const useStyles = makeStyles(() => ({
  control: {
    width: 65,
  },
  description: {
    flexGrow: 1,
  },
}));

const { Icon: TimetrackingIcon } = CONTENT_DEFINITION[
  CONTENT_TYPE.TIMETRACKING
];

const SettingsTimetracking = () => {
  const classes = useStyles();

  const [allowCrewEditing, setAllowCrewEditing] = useState(null);
  const [
    showConfirmAllowCrewEditingDialog,
    setShowConfirmAllowCrewEditingDialog,
  ] = useState(false);

  const { data: getCompanyInfoData, loading: loadingCompanyInfo } = useQuery(
    GetCompanyInfo
  );

  const companyInfo = _.get(
    getCompanyInfoData,
    'getCompanyInfo.items[0]',
    null
  );

  const [updateTimetrackingSettings] = useMutation(AddOrUpdateCompanyInfo);

  useEffect(() => {
    const newAllowCrewEditing = _.get(
      getCompanyInfoData,
      'getCompanyInfo.items[0].timetrackingSettings.allowCrewEditing',
      false
    );

    if (newAllowCrewEditing !== allowCrewEditing) {
      setAllowCrewEditing(newAllowCrewEditing);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCompanyInfoData]);

  // Add updating company info state
  const showLoading = allowCrewEditing === null || loadingCompanyInfo;

  const allowCrewMemberTimetrackingEditingDescription = `This setting will allow crew members to edit any of their own past clock-ins or clock-outs using the mobile app, including the date, time and answers to any clock-in/out questions.`;

  const closeCrewMemberTimetrackingConfirmation = () => {
    setShowConfirmAllowCrewEditingDialog(false);
  };

  const doCrewMemberTimetrackingEditingChange = value => {
    updateTimetrackingSettings({
      variables: {
        companyId: companyInfo.companyId,
        timetrackingSettings: {
          allowCrewEditing: value,
        },
      },
      optimisticResponse: () => {
        const optimisticCompanyInfo = _.cloneDeep(companyInfo);
        if (!optimisticCompanyInfo.timetrackingSettings) {
          optimisticCompanyInfo.timetrackingSettings = {};
        }
        optimisticCompanyInfo.timetrackingSettings.allowCrewEditing = value;

        return {
          __typename: 'Mutation',
          addOrUpdateCompany: {
            __typename: 'Company',
            ...optimisticCompanyInfo,
          },
        };
      },
    });

    closeCrewMemberTimetrackingConfirmation();
  };

  const handleCrewMemberTimetrackingEditingChange = event => {
    const valueToSet = event.target.checked;

    if (!valueToSet) {
      // If turning off, set to false right away
      doCrewMemberTimetrackingEditingChange(valueToSet);
      return;
    }

    // Otherwise show a confirmation message
    setShowConfirmAllowCrewEditingDialog(true);
  };

  return (
    <SettingsSection
      title="Time Tracking"
      description="Manage your company-wide time tracking settings"
      icon={<TimetrackingIcon />}
    >
      <Grid container>
        <Grid container item xs={12} alignItems="center">
          <Grid item className={classes.control}>
            {showLoading && <CircularProgress size={20} />}
            {!showLoading && (
              <MuiSwitch
                name="timetrackingType"
                checked={allowCrewEditing}
                variant="inline"
                onChange={handleCrewMemberTimetrackingEditingChange}
              />
            )}
          </Grid>
          <Grid
            item
            className={classes.description}
            style={{ display: 'flex', alignContent: 'center ' }}
          >
            <Typography variant="body1">
              Allow your crew members to edit past clock-in or clock-out events
            </Typography>
            <Tooltip title={allowCrewMemberTimetrackingEditingDescription}>
              <HelpOutlineIcon
                fontSize="small"
                color="inherit"
                style={{
                  marginLeft: 5,
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <SimpleDialog
        open={showConfirmAllowCrewEditingDialog}
        title="Heads up!"
        onNegative={closeCrewMemberTimetrackingConfirmation}
        onPositive={() => doCrewMemberTimetrackingEditingChange(true)}
        onClose={closeCrewMemberTimetrackingConfirmation}
        buttons={{
          positiveButton: { label: 'OK' },
          negativeButton: { label: 'Cancel' },
        }}
        fullWidth
      >
        <Typography variant="body1" style={{ fontSize: 16, marginTop: 8 }}>
          By turning this on, all crew members will be able to edit their
          timesheets at any point in time, including after you&apos;ve run
          payroll!
          <br />
          <br />
          We recommend that if you decide to enable this feature, you generate
          and save your Level Payroll Reports to your computer for each payroll
          run to avoid any unreconciled differences between your crew&apos;s
          payslips and Level timesheets.
        </Typography>
      </SimpleDialog>
    </SettingsSection>
  );
};

export default SettingsTimetracking;
