import gql from 'graphql-tag';

export default gql`
  mutation StartRetroactiveReportGeneration(
    $companyId: ID!
    $startDate: String!
    $endDate: String
  ) {
    startRetroactiveReportGeneration(
      input: { companyId: $companyId, startDate: $startDate, endDate: $endDate }
    ) {
      status
      msg
    }
  }
`;
