import React, { useMemo } from 'react';

import { Grid, makeStyles } from '@material-ui/core';

import {
  MenuBook as BookkeeperIcon,
  Star as OwnerIcon,
  VerifiedUser as AdminIcon,
} from '@material-ui/icons';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import _ from 'lodash';

import globalStyles from '../../theme/palette';
import LevelAccountingLogo from '../level-accounting-logo/level-accounting-logo';

import {
  GetCompanyCrewAction,
  GetCompanyInfoAction,
} from '../../graphql/graphql';
import { getCompanyCrewBreakdown } from '../../helpers';

const useStyles = makeStyles({
  roleIconPrimary: {
    color: globalStyles.brandColorPrimary,
  },
  roleIconSecondary: {
    color: globalStyles.brandColorDarkGrey,
  },
});

const AccessLimitedToInfo = ({
  companyOwner,
  companyAdmin,
  companyBookkeeper,
  containerStyle,
  managingCompanyInfo,
  companyCrew,
  companies,
}) => {
  const classes = useStyles();

  const showLevelAccounting = useMemo(() => {
    if (
      !_.isEmpty(companyCrew) &&
      !_.isEmpty(companies) &&
      managingCompanyInfo
    ) {
      const currentCompany = _.find(companies, ({ companyId }) => {
        return companyId === managingCompanyInfo.managingCompanyId;
      });

      const { levelAccounting } = getCompanyCrewBreakdown(
        currentCompany,
        companyCrew
      );

      const haveLevelAccounting = !_.isEmpty(levelAccounting);

      return managingCompanyInfo.isBookkeepingCustomer && haveLevelAccounting;
    }

    return false;
  }, [companyCrew, companies, managingCompanyInfo]);

  const renderCompanyOwner = () => {
    return companyOwner ? (
      <>
        Company Owner&nbsp;
        <OwnerIcon className={classes.roleIconPrimary} />
      </>
    ) : null;
  };

  const renderBookkeeper = () => {
    return companyBookkeeper ? (
      <>
        {companyOwner && ', '}
        {showLevelAccounting && (
          <>
            Level Accounting&nbsp;&nbsp;
            <LevelAccountingLogo />
            ,&nbsp;
          </>
        )}
        Bookkeeper&nbsp;&nbsp;
        <BookkeeperIcon className={classes.roleIconSecondary} />
      </>
    ) : null;
  };

  const renderCompanyAdmin = () => {
    return companyAdmin ? (
      <>
        {(companyOwner || companyBookkeeper) && ', '}
        Admin&nbsp;
        <AdminIcon className={classes.roleIconPrimary} />
      </>
    ) : null;
  };

  return (
    <Grid
      container
      item
      alignItems="center"
      wrap="nowrap"
      style={{ whiteSpace: 'nowrap', fontSize: 14, ...containerStyle }}
    >
      Access limited to: {renderCompanyOwner()}
      {renderBookkeeper()}
      {renderCompanyAdmin()}
    </Grid>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default compose(
  GetCompanyCrewAction,
  GetCompanyInfoAction,
  connect(mapStateToProps)
)(AccessLimitedToInfo);
