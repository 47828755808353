import React, { useState } from 'react';
import _ from 'lodash';
import { Grid, Typography, Tabs, Tab } from '@material-ui/core';
import LevelModal from '../Modal/level-modal';

const DashboardSettings = ({ title, sections, handleClose, onRefresh }) => {
  const [activeSection, setActiveSection] = useState(0);
  const [requireRefresh, setRequireRefresh] = useState(false);

  const handleChange = (event, newValue) => {
    setActiveSection(newValue);
  };

  if (!sections) {
    return null;
  }

  const checkForRefreshAndClose = () => {
    if (requireRefresh && onRefresh) {
      onRefresh();
      setRequireRefresh(false);
    }

    handleClose();
  };

  const { component: SectionComponent, sectionProps = {} } = sections[
    activeSection
  ];

  return (
    <LevelModal
      open
      fullScreen
      handleClose={checkForRefreshAndClose}
      showCloseButton
    >
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="h4">{title}</Typography>
        </Grid>
        <Grid container item style={{ flex: 1 }}>
          {sections && sections.length > 0 && (
            <Grid
              item
              style={{
                minWidth: 150,
                borderRight: '1px solid #EEEEEE',
              }}
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={activeSection}
                onChange={handleChange}
                aria-label="Settings menu"
                indicatorColor="primary"
                textColor="primary"
              >
                {_.map(sections, ({ label }) => (
                  <Tab
                    key={label}
                    label={
                      <span style={{ alignSelf: 'flex-end', marginRight: 8 }}>
                        {label}
                      </span>
                    }
                  />
                ))}
              </Tabs>
            </Grid>
          )}
          <Grid
            container
            item
            justifyContent="center"
            style={{
              flex: 1,
              paddingLeft: 16,
              paddingRight: 16,
            }}
          >
            <SectionComponent
              {...sectionProps}
              setRequireRefresh={setRequireRefresh}
            />
          </Grid>
        </Grid>
      </Grid>
    </LevelModal>
  );
};

export default DashboardSettings;
