import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  Switch as MuiSwitch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  InfoOutlined as InfoOutlinedIcon,
  Refresh as ResetIcon,
  Person as PersonIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';

import FilterSearch from '../../FilterSearch/filterSearch';
import InputContentLoader from '../../input-content-loader/input-content-loader';
import palette from '../../../theme/palette';

const CHECKBOX_STATUS = {
  CHECKED: 'checked',
  UNCHECKED: 'unchecked',
  INDETERMINATE: 'indeterminate',
};

const useStyles = makeStyles(() => {
  return {
    filterWrapper: {
      position: 'relative',
      backgroundColor: palette.brandColorPrimary10,
      marginBottom: 8,
      padding: '24px 24px 8px 24px',
      width: '100%',
    },
    resetButtonContainer: {
      position: 'absolute',
      top: 8,
      right: 24,
    },
    resetButton: {
      color: palette.brandColorError,
    },
    textField: {
      width: '100%',
    },
    itemContainer: {
      margin: 0,
      padding: 0,
    },
    menuItem: {
      padding: '0px 8px',
    },
    avatar: {
      width: 32,
      height: 32,
      marginLeft: 8,
    },
  };
});

const BillableHoursMultipleSelect = ({
  options,
  selectedValuesMap,
  singleWording,
  searchBy = 'displayValue',
  onChange,
  onMenuOpen = () => {},
  onMenuClose = () => {},
  hideAvatar = false,
  defaultAvatar = <PersonIcon />,
  menuMaxWidth = 'sm',
  loading,
  optionalFilters = [],
  ...props
}) => {
  const classes = useStyles();

  const filterSearchRef = useRef();

  const [optionsFromSearch, setOptionsFromSearch] = useState(options);
  const [showMenuDialog, setShowMenuDialog] = useState(false);
  const [totalSelection, setTotalSelection] = useState({
    total: 0,
    totalText: '',
    totalAltText: '',
  });
  const [isSearched, setIsSearched] = useState(false);
  const [selectAll, setSelectAll] = useState(CHECKBOX_STATUS.UNCHECKED);
  const [selectAllFilteredOptions, setSelectAllFilteredOptions] = useState(
    CHECKBOX_STATUS.UNCHECKED
  );
  const [hideUnselectedOptions, setHideUnselectedOptions] = useState(false);

  const resetSelectedOptionalFilters = () => {
    const selected = {};
    _.forEach(optionalFilters, optionalFilter => {
      selected[optionalFilter.keyToFilter] = optionalFilter.nonFilterValue;
    });
    return selected;
  };

  const [selectedOptionalFilters, setSelectdOptionalFilters] = useState(
    resetSelectedOptionalFilters
  );

  const optionalFilterFunction = useCallback(
    (optionsToFilter, filteringValues) => {
      if (_.isEmpty(optionalFilters)) {
        // no filtering
        return optionsToFilter;
      }

      const filteredOptions = [...optionsToFilter];

      _.forEach(optionalFilters, optionalFilter => {
        if (
          filteringValues[optionalFilter.keyToFilter] !==
          optionalFilter.nonFilterValue
        ) {
          _.remove(filteredOptions, option => {
            return (
              option[optionalFilter.keyToFilter] !==
              filteringValues[optionalFilter.keyToFilter]
            );
          });
        }
      });

      return filteredOptions;
    },
    [optionalFilters]
  );

  const optionsToUse = useMemo(() => {
    let filteredOptions = [...optionsFromSearch];
    if (hideUnselectedOptions) {
      filteredOptions = optionsFromSearch.filter(option => {
        return selectedValuesMap[option.value];
      });
    }

    filteredOptions = optionalFilterFunction(
      filteredOptions,
      selectedOptionalFilters
    );

    return filteredOptions;
  }, [
    hideUnselectedOptions,
    optionalFilterFunction,
    optionsFromSearch,
    selectedOptionalFilters,
    selectedValuesMap,
  ]);

  const isFiltered = useMemo(() => {
    return (
      isSearched ||
      hideUnselectedOptions ||
      _.some(optionalFilters, optionalFilter => {
        return (
          selectedOptionalFilters[optionalFilter.keyToFilter] !==
          optionalFilter.nonFilterValue
        );
      })
    );
  }, [
    isSearched,
    hideUnselectedOptions,
    optionalFilters,
    selectedOptionalFilters,
  ]);

  useEffect(() => {
    if (!_.isEmpty(options)) {
      const isFirstOptionSelected = !!selectedValuesMap[options[0].value];
      for (let i = 1; i < options.length; i += 1) {
        if (!!selectedValuesMap[options[i].value] !== isFirstOptionSelected) {
          setSelectAll(CHECKBOX_STATUS.INDETERMINATE);
          return;
        }
      }
      setSelectAll(
        isFirstOptionSelected
          ? CHECKBOX_STATUS.CHECKED
          : CHECKBOX_STATUS.UNCHECKED
      );
    }
  }, [selectedValuesMap, options]);

  useEffect(() => {
    if (!_.isEmpty(optionsToUse) && isFiltered) {
      const isFirstOptionSelected = !!selectedValuesMap[optionsToUse[0].value];
      for (let i = 1; i < optionsToUse.length; i += 1) {
        if (
          !!selectedValuesMap[optionsToUse[i].value] !== isFirstOptionSelected
        ) {
          setSelectAllFilteredOptions(CHECKBOX_STATUS.INDETERMINATE);
          return;
        }
      }
      setSelectAllFilteredOptions(
        isFirstOptionSelected
          ? CHECKBOX_STATUS.CHECKED
          : CHECKBOX_STATUS.UNCHECKED
      );
    }
  }, [isFiltered, selectedValuesMap, optionsToUse]);

  const totalToText = useCallback(
    total => {
      return `${total} ${singleWording}${total > 1 ? 's' : ''}`;
    },
    [singleWording]
  );

  useEffect(() => {
    setTotalSelection(() => {
      const total = _.keys(_.pickBy(selectedValuesMap)).length;
      const totalText = totalToText(total);
      const result = {
        total,
        totalText,
        totalAltText: `${totalText} Selected`,
      };
      if (total === options.length && total > 0) {
        result.totalAltText = `All (${totalText})`;
      }
      return result;
    });
  }, [options.length, selectedValuesMap, singleWording, totalToText]);

  const onAllClick = () => {
    const selectedOptions = {};
    if (
      selectAll === CHECKBOX_STATUS.UNCHECKED ||
      selectAll === CHECKBOX_STATUS.INDETERMINATE
    ) {
      options.forEach(option => {
        selectedOptions[option.value] = true;
      });
    }
    onChange(selectedOptions);
  };

  const onAllFilteredOptionsClick = () => {
    const selectedOptions = { ...selectedValuesMap };
    if (
      selectAllFilteredOptions === CHECKBOX_STATUS.UNCHECKED ||
      selectAllFilteredOptions === CHECKBOX_STATUS.INDETERMINATE
    ) {
      optionsToUse.forEach(option => {
        selectedOptions[option.value] = true;
      });
    } else {
      optionsToUse.forEach(option => {
        selectedOptions[option.value] = false;
      });
    }
    onChange(selectedOptions);
  };

  const onMenuItemClick = item => {
    const selectedOptions = {
      ...selectedValuesMap,
      [item.value]: !selectedValuesMap[item.value],
    };
    onChange(selectedOptions);
  };

  const resetAllFilters = () => {
    filterSearchRef.current.clearSearch();
    setSelectdOptionalFilters(resetSelectedOptionalFilters());
    setHideUnselectedOptions(false);
  };

  const onDialogClose = () => {
    setShowMenuDialog(false);
    resetAllFilters();
    onMenuClose();
  };

  const onResetAllFiltersClick = () => {
    resetAllFilters();
  };

  return (
    <>
      <InputContentLoader loading={loading} label={props.label}>
        <TextField
          {...props}
          value={totalSelection.totalAltText}
          onClick={() => {
            setShowMenuDialog(true);
            onMenuOpen();
          }}
          className={classes.textField}
        />
      </InputContentLoader>
      <Dialog
        open={showMenuDialog}
        maxWidth={menuMaxWidth}
        fullWidth
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onDialogClose();
          }
        }}
        disableEscapeKeyDown
      >
        <DialogTitle disableTypography>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Typography variant="h6">{props.label}</Typography>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            style={{ minHeight: 100, marginTop: 0, marginBottom: 2 }}
            justifyContent="flex-end"
          >
            <Grid item className={classes.filterWrapper}>
              <Tooltip title="Reset all filters">
                <span className={classes.resetButtonContainer}>
                  <IconButton
                    onClick={onResetAllFiltersClick}
                    size="small"
                    className={classes.resetButton}
                    disabled={!isFiltered}
                  >
                    <ResetIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Grid style={{ marginTop: 8, marginBottom: 8 }}>
                <FilterSearch
                  data={options}
                  searchBy={searchBy}
                  isFiltered={setIsSearched}
                  passBack={filteredData => {
                    setOptionsFromSearch(filteredData);
                  }}
                  ref={filterSearchRef}
                />
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                {!_.isEmpty(optionalFilters) &&
                  _.map(optionalFilters, optionalFilter => (
                    <Grid
                      key={optionalFilter.keyToFilter}
                      item
                      xs={6}
                      style={{ marginTop: 8, marginBottom: 8 }}
                    >
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          id={`optional-filter-${optionalFilter.keyToFilter}`}
                        >
                          {optionalFilter.label}
                        </InputLabel>
                        <MuiSelect
                          value={
                            selectedOptionalFilters[optionalFilter.keyToFilter]
                          }
                          onChange={event => {
                            setSelectdOptionalFilters(currentState => {
                              return {
                                ...currentState,
                                [optionalFilter.keyToFilter]:
                                  event.target.value,
                              };
                            });
                          }}
                        >
                          {_.map(
                            optionalFilter.options,
                            ({ key, displayValue, value }) => (
                              <MenuItem key={key} value={value}>
                                {displayValue}
                              </MenuItem>
                            )
                          )}
                        </MuiSelect>
                      </FormControl>
                    </Grid>
                  ))}
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  xs={6}
                >
                  <Typography variant="h6">Hide unselected options</Typography>
                  <MuiSwitch
                    name="hideUnselectedOptions"
                    value={hideUnselectedOptions}
                    checked={hideUnselectedOptions}
                    variant="inline"
                    color="primary"
                    onChange={() => {
                      setHideUnselectedOptions(currentState => !currentState);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {!_.isEmpty(options) && !isFiltered && (
              <Grid item xs={12} className={classes.itemContainer}>
                <MenuItem
                  onClick={() => {
                    onAllClick();
                  }}
                  className={classes.menuItem}
                >
                  <Checkbox
                    checked={selectAll === CHECKBOX_STATUS.CHECKED}
                    indeterminate={selectAll === CHECKBOX_STATUS.INDETERMINATE}
                    color="primary"
                  />
                  <ListItemText
                    primary={`All ${totalToText(options.length)} (${
                      totalSelection.totalText
                    } Selected)`}
                  />
                </MenuItem>
              </Grid>
            )}
            {!_.isEmpty(optionsToUse) && isFiltered && (
              <Grid item xs={12} className={classes.itemContainer}>
                <MenuItem
                  onClick={() => {
                    onAllFilteredOptionsClick();
                  }}
                  className={classes.menuItem}
                >
                  <Checkbox
                    checked={
                      selectAllFilteredOptions === CHECKBOX_STATUS.CHECKED
                    }
                    indeterminate={
                      selectAllFilteredOptions === CHECKBOX_STATUS.INDETERMINATE
                    }
                    color="primary"
                  />
                  <ListItemText
                    primary={`All ${totalToText(optionsToUse.length)} (${
                      totalSelection.totalText
                    } Selected)`}
                  />
                </MenuItem>
              </Grid>
            )}
            {!_.isEmpty(optionsToUse) &&
              optionsToUse.map(option => {
                return (
                  <Grid
                    item
                    xs={12}
                    key={option.key}
                    className={classes.itemContainer}
                    style={{ paddingLeft: 20 }}
                  >
                    <MenuItem
                      onClick={() => {
                        onMenuItemClick(option);
                      }}
                      className={classes.menuItem}
                    >
                      <Checkbox
                        checked={!!selectedValuesMap[option.value]}
                        style={
                          option.checkboxColor
                            ? { color: option.checkboxColor }
                            : {}
                        }
                        color="primary"
                      />
                      {!hideAvatar && (
                        <ListItemAvatar>
                          {option.avatarUrl ? (
                            <Avatar
                              src={option.avatarUrl}
                              className={classes.avatar}
                            />
                          ) : (
                            <Avatar className={classes.avatar}>
                              {defaultAvatar}
                            </Avatar>
                          )}
                        </ListItemAvatar>
                      )}
                      <ListItemText
                        primary={option.displayValue}
                        secondary={option.secondaryDisplayValue}
                      />
                      {!!option.tooltip && (
                        <Tooltip title={option.tooltip}>
                          <InfoOutlinedIcon style={{ fontSize: 18 }} />
                        </Tooltip>
                      )}
                    </MenuItem>
                  </Grid>
                );
              })}
            {_.isEmpty(optionsToUse) && (
              <Grid
                item
                container
                xs={12}
                justifyContent="center"
                style={{ marginTop: 16 }}
              >
                No search results
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose} color="primary" variant="contained">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BillableHoursMultipleSelect;
