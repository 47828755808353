import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import _ from 'lodash';

import {
  RFI_STATUSES,
  RFI_STATUSES_INTERNALLY_BASIC,
  RFI_STATUSES_INTERNALLY_ADMIN,
  RFI_DESCRIPTION_QUICKSTARTS,
} from '../../../config/appDefaults';
import { mergeWhileDeduping } from '../../../helpers';
import LoadingCover from '../../../components/LoadingCover/loadingCover';
import LevelSegmentButtons from '../../../components/level-button-segment/level-button-segment';

const useStyles = makeStyles(() => ({
  fieldWrapper: {
    marginBottom: 32,
  },
}));

const BulkRfiUpdater = ({ toClose, handleSaveRfis, rfis, adminMode }) => {
  const classes = useStyles();
  let anyRfisAreLocal = false;
  _.each(rfis, rfi => {
    if (!anyRfisAreLocal && rfi.local === true) {
      anyRfisAreLocal = true;
    }
  });
  const defaultRequestStatus = anyRfisAreLocal
    ? RFI_STATUSES.IN_DRAFT.value
    : '';
  const [requestStatus, setRequestStatus] = useState(defaultRequestStatus);
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [
    initialNotesAddOrReplaceChange,
    setInitialNotesAddOrReplaceChange,
  ] = useState('add');
  const [quickStart, setQuickStart] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [statusOptionsToUse] = useState(() => {
    if (adminMode) {
      // merge RFI_STATUSES_INTERNALLY_ADMIN to status avoiding duplicates
      return mergeWhileDeduping(
        RFI_STATUSES_INTERNALLY_BASIC,
        RFI_STATUSES_INTERNALLY_ADMIN,
        'value'
      );
    }
    // else return just RFI_STATUSES_INTERNALLY_BASIC
    return RFI_STATUSES_INTERNALLY_BASIC;
  });

  const onApply = async () => {
    setIsSaving(true);
    if (
      (additionalNotes === '' && requestStatus === '') ||
      (anyRfisAreLocal && requestStatus === '')
    ) {
      setIsSaving(false);
      return;
    }
    // for each rfi, apply the changes
    const rfisToSave = [...rfis];
    rfisToSave.forEach((rfi, index) => {
      if (additionalNotes) {
        rfisToSave[index].initialNotes =
          initialNotesAddOrReplaceChange === 'add'
            ? `${rfi.initialNotes}<br /><br />${additionalNotes}`
            : additionalNotes;
      }
      if (requestStatus) {
        rfisToSave[index].requestStatus = requestStatus;
      }
      _.keys(rfi).forEach(key => {
        if (key.startsWith('_')) {
          delete rfisToSave[index][key];
        }
      });
    });
    // figure out which RFI's are coming from local and need to be added and which ones just need to be updated
    const rfisToAdd = [];
    const rfisToUpdate = [];
    rfisToSave.forEach(rfi => {
      if (rfi.local) {
        rfisToAdd.push(rfi);
      } else {
        rfisToUpdate.push(rfi);
      }
    });
    // if there are any rfis to add, add them
    if (rfisToAdd.length > 0) {
      await handleSaveRfis({
        listOfRfis: rfisToAdd,
        addOrUpdate: 'add',
      });
    }
    // if there are any rfis to update, update them
    if (rfisToUpdate.length > 0) {
      await handleSaveRfis({
        listOfRfis: rfisToUpdate,
        addOrUpdate: 'update',
      });
    }
    setIsSaving(false);
    toClose();
  };

  const handleStatusChange = event => {
    setRequestStatus(event.target.value);
  };

  const handleAdditionalNotesChange = event => {
    setAdditionalNotes(event.target.value);
  };

  const handleQuickStartChange = event => {
    setQuickStart(event.target.value);
    setAdditionalNotes(event.target.value);
  };

  const handleInitialNotesAddOrReplaceChange = selection => {
    setInitialNotesAddOrReplaceChange(selection);
  };

  return (
    <Dialog maxWidth="md" fullWidth open>
      <DialogTitle disableTypography>
        <Typography variant="h4" align="center" color="primary">
          Bulk Update RFIs
        </Typography>
      </DialogTitle>
      <DialogContent style={{ margin: '32px 0' }}>
        <Grid container item xs={12} className={classes.fieldWrapper}>
          <Grid container item xs={4}>
            <Grid item xs={12}>
              Initial Notes:
            </Grid>
            <Grid item xs={12}>
              <LevelSegmentButtons
                initiallySelected={initialNotesAddOrReplaceChange}
                passbackChosenValue={handleInitialNotesAddOrReplaceChange}
                options={[
                  {
                    value: 'add',
                    label: 'Append',
                  },
                  {
                    value: 'replace',
                    label: 'Replace',
                  },
                ]}
              />
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Select
              name="quickStart"
              value={quickStart}
              onChange={handleQuickStartChange}
              style={{ width: '100%', marginBottom: 32 }}
              displayEmpty
            >
              <MenuItem value="">
                Choose from a quick start option here or enter your own below...
              </MenuItem>
              {_.map(RFI_DESCRIPTION_QUICKSTARTS, status => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </Select>

            <TextField
              fullWidth
              id="additionalNotes"
              name="additionalNotes"
              value={additionalNotes}
              onChange={handleAdditionalNotesChange}
              helperText={`Anything here will  ${
                initialNotesAddOrReplaceChange === 'add'
                  ? 'be added to the end of'
                  : "replace what's already in"
              }  the "Initial Notes" field on each selected RFI`}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.fieldWrapper}>
          <Grid item xs={4}>
            Status:
          </Grid>
          <Grid container item xs={8}>
            <Grid item xs={12}>
              <Select
                name="requestStatus"
                value={requestStatus}
                onChange={handleStatusChange}
                style={{ minWidth: 200 }}
                displayEmpty
              >
                {!anyRfisAreLocal && (
                  <MenuItem value="">No Change in Status</MenuItem>
                )}
                {_.map(statusOptionsToUse, status => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              {anyRfisAreLocal && (
                <Typography variant="caption">
                  At least one of the RFIs is in &quot;local&quot; mode, so the
                  statuses need to be updated to make any other changes.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={toClose}>
          Cancel
        </Button>
        <Button
          autoFocus
          onClick={onApply}
          color="primary"
          disabled={additionalNotes === '' && requestStatus === ''}
        >
          Apply
        </Button>
      </DialogActions>
      {isSaving && <LoadingCover />}
    </Dialog>
  );
};

export default BulkRfiUpdater;
