// @ts-check
import _ from 'lodash';

/** @typedef {{ sourceId: string, accountName: string, sourceParentId: string, children: Account[]?  }} Account */

/**
 * Organize parental hierarchy of accounts
 * @param {Account[]} flatList
 * @returns {Account[]}
 */
const generateTreeFromFlatList = flatList => {
  const relatedAccounts = _.cloneDeep(flatList);

  // Generate a map of accountId to account
  const accountMap = {};
  relatedAccounts.forEach(account => {
    const accountForUpdate = account;
    accountForUpdate.children = [];
    accountMap[accountForUpdate.sourceId] = accountForUpdate;
  });

  // Update the children property of each parent to point to its children
  relatedAccounts.forEach(childAccount => {
    const parentAccount = accountMap[childAccount.sourceParentId];
    if (parentAccount) {
      parentAccount.children.push(childAccount);
    }
  });

  // Get all top-level accounts
  return _.values(relatedAccounts).filter(account => !account.sourceParentId);
};

export default generateTreeFromFlatList;
