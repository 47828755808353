import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';

import palette from '../../theme/palette';

// This button is used in the Auth forms,
// if isProcessing is true, by default it will
//   - disable the button
//   - show a circular progress indicator instead of the button text
//   - block the page with a transparent div
const BigButton = ({
  buttonText,
  children,
  isProcessing,
  noBlockPageWhenProcessing,
  style,
  variant = 'contained',
  ...otherProps
}) => {
  let backgroundColor = 'inherit';
  let color = 'inherit';
  const colorToUse =
    process.env.REACT_APP_BACKEND === 'nonprod'
      ? palette.brandColorOrange
      : palette.brandColorPrimary;
  if (variant === 'contained') {
    backgroundColor = colorToUse;
    color = 'white';
  } else if (variant === 'text') {
    color = colorToUse;
  }

  return (
    <>
      <Button
        variant={variant}
        color="primary"
        size="large"
        disabled={isProcessing}
        style={{
          minWidth: 180,
          padding: '12px 16px',
          backgroundColor,
          color,
          ...style,
        }}
        {...otherProps}
      >
        {!!children && <>{children}</>}
        {!children && (
          <>
            {isProcessing ? (
              <CircularProgress size={26} color="inherit" />
            ) : (
              buttonText
            )}
          </>
        )}
      </Button>

      {!noBlockPageWhenProcessing && isProcessing && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            opacity: 0,
            zIndex: 9999,
          }}
        />
      )}
    </>
  );
};

export default BigButton;
