import gql from 'graphql-tag';

export default gql`
  mutation UpdateCompanyAccount(
    $companyId: String!
    $accountId: String!
    $projectCostType: String
    $productionCostType: String
    $laborBurden: Boolean
    $isApproved: Boolean
  ) {
    updateCompanyAccount(
      input: {
        companyId: $companyId
        accountId: $accountId
        projectCostType: $projectCostType
        productionCostType: $productionCostType
        laborBurden: $laborBurden
        isApproved: $isApproved
      }
    ) {
      accountId
      sourceId
      sourceParentId
      source
      displayName
      accountName
      accountType
      projectCostType
      productionCostType
      laborBurden
      isApproved
    }
  }
`;
