import React from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import TemplateSelector from '../template-selector';

const ApplyTemplateContentSelect = ({
  selectedTemplate,
  setSelectedTemplate,
  handleChoose,
}) => {
  return (
    <>
      <Grid item style={{ flex: 0 }}>
        <Typography variant="body1">
          Select a template to generate project content from! The templated
          content will use this project&apos;s start date as the date reference
          for all created content.
        </Typography>
      </Grid>
      <Grid
        container
        style={{
          justifyContent: 'center',
          flex: 1,
          overflowY: 'auto',
          marginTop: 8,
          marginBottom: 8,
          paddingTop: 8,
          paddingBottom: 8,
        }}
      >
        <TemplateSelector
          selectedTemplate={selectedTemplate}
          handleSelection={setSelectedTemplate}
        />
      </Grid>
      <Grid container style={{ justifyContent: 'right', flex: 0 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleChoose}
          disabled={!selectedTemplate}
        >
          Select
        </Button>
      </Grid>
    </>
  );
};

export default ApplyTemplateContentSelect;
