/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link, Grid, Typography, makeStyles } from '@material-ui/core';
import axios from 'axios';

import styles from './auth.styles';

import BigButton from './big-button';
import VerificationCode from '../verification-code';
import palette from '../../theme/palette';

const useStyles = makeStyles(styles);

const SmsMfaForm = ({
  pendingUser,
  verificationCode,
  isProcessing,
  setIsProcessing,
  authError,
  setAuthError,
  onVerificationCodeChange,
  onVerificationCodeSubmit,
  reSignIn,
}) => {
  const classes = useStyles();

  const lastFourDigits = (
    pendingUser?.challengeParam?.CODE_DELIVERY_DESTINATION || ''
  ).slice(-4);

  const [verificationCodeResent, setVerificationCodeResent] = useState(false);
  const [verificationCodeTimedOut, setVerificationCodeTimedOut] = useState(
    false
  );

  useEffect(() => {
    let timer = null;

    if (verificationCodeTimedOut) {
      timer = setTimeout(() => {
        setVerificationCodeTimedOut(false);
      }, 30000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [verificationCodeTimedOut]);

  const resendVerificationCode = async () => {
    if (verificationCodeTimedOut) {
      return;
    }

    setIsProcessing(true);
    setVerificationCodeTimedOut(true);

    try {
      // Re-opt in to SMS MFA if opted out
      // Resend the code
      // Post to send verification email
      await axios.post(
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/confirm-user-opt-in`,
        {
          username: pendingUser?.username || '',
          lastFourDigits,
        },
        {
          responseType: 'json',
        }
      );

      await reSignIn();

      setVerificationCodeResent(true);
    } catch (err) {
      setAuthError({
        form: 'Something went wrong. Please contact support.',
      });
    }

    setIsProcessing(false);
  };

  let helperTextToShow = '';
  let helperIsError = false;

  if (authError?.form) {
    helperTextToShow = authError.form;
    helperIsError = true;
  } else if (verificationCodeResent) {
    helperTextToShow = 'A new verification code was sent.';
  }

  return (
    <>
      <Typography className={classes.formTitle}>Enter your code</Typography>
      <Grid container item xs={12} className={classes.formBody} spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            style={{ color: palette.brandColorDarkestGrey }}
          >
            This account is protected by two-factor authentication. Please enter
            the code sent to your phone ending in <b>{lastFourDigits}</b>.
          </Typography>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <VerificationCode
            value={verificationCode}
            onChange={onVerificationCodeChange}
          />
        </Grid>
        {!!helperTextToShow && (
          <Grid item xs={12} className={classes.formErrorContainer}>
            <Typography
              className={helperIsError ? classes.errorText : classes.infoText}
            >
              {helperTextToShow}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item container xs={12} justifyContent="center">
        <BigButton
          buttonText="Verify"
          fullWidth
          isProcessing={isProcessing}
          onClick={onVerificationCodeSubmit}
        />
      </Grid>
      <Grid item style={{ paddingTop: 16 }}>
        <Typography variant="body1">
          Didn&apos;t receive a code?{' '}
          <Link
            onClick={resendVerificationCode}
            style={{
              cursor: 'pointer',
              color: verificationCodeTimedOut
                ? palette.brandColorMidGrey
                : palette.brandColorPrimary,
            }}
          >
            Click here to send a new code
          </Link>{' '}
          or contact{' '}
          <Link
            href="mailto:support@checkthelevel.com"
            target="_blank"
            rel="noreferrer"
          >
            support@checkthelevel.com
          </Link>
        </Typography>
      </Grid>
    </>
  );
};

export default SmsMfaForm;
