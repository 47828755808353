import React from 'react';

import { DashboardControlBar } from '../dashboard';

const FirmDashboardControlBar = ({ title, hideAccessExplainer }) => {
  return (
    <DashboardControlBar
      title={title}
      titleVariant="h2"
      hideAccessExplainer={hideAccessExplainer}
      hideBottomBorder
    />
  );
};

export default FirmDashboardControlBar;
