import React from 'react';
import _ from 'lodash';
import {
  Grid,
  InputLabel,
  Typography,
  Button,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import {
  CloudUploadOutlined as CloudUploadOutlinedIcon,
  BorderColorOutlined as BorderColorOutlinedIcon,
  TimerOutlined as TimerOutlinedIcon,
  Warning as WarningIcon,
  Spellcheck as SpellcheckIcon,
  Help as HelpIcon,
} from '@material-ui/icons';
import { ErrorMessage } from 'formik';
import Dropzone from 'react-dropzone';
import { withSnackbar } from 'notistack';
import {
  CONTENT_TYPE,
  MAX_IMAGES_IN_GALLERY,
  MAX_UPLOAD_TOTAL_FILESIZE_BYTES,
  MAX_PDF_FILE_SIZE,
} from '../../../config/appDefaults';
import { revokeUrls } from '../../../helpers';

import ContentGallery from '../../../components/ProjectContentListing/ContentTypes/contentGallery';
import ContentVideo from '../../../components/ProjectContentListing/ContentTypes/contentVideo';

const ManageMedia = ({
  enqueueSnackbar,
  contentType,
  convoIsGroup,
  values,
  classes,
  disabled,
  isEditableField,
  fromShoebox,
  isFinancial,
  setFieldValue,
  allowStreetViewCall,
  getStreetViewCoverImage,
  ocrTheFile,
  setHasBeenAutofilled,
  showAutofillButton,
}) => {
  let dropzoneRef;

  let acceptedType = 'image/*';
  if (contentType === 'pdf') {
    acceptedType = 'application/pdf';
  } else if (contentType === CONTENT_TYPE.VIDEO) {
    acceptedType = 'video/*';
  } else if (isFinancial) {
    acceptedType = 'image/*,application/pdf';
  } else if (contentType === 'task') {
    acceptedType = 'image/*,application/pdf';
  }

  return (
    <>
      {contentType === CONTENT_TYPE.CONVERSATION && !convoIsGroup && (
        <input type="hidden" value={values.contentUrl} />
      )}

      {(contentType === CONTENT_TYPE.CONVERSATION && convoIsGroup) ||
      (contentType !== CONTENT_TYPE.CONVERSATION && isEditableField) ? (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent={
            values.contentUrl.length === 0 ? 'center' : 'flex-start'
          }
          className={classes.uploadWrapper}
        >
          {contentType === CONTENT_TYPE.CONVERSATION && convoIsGroup && (
            <InputLabel
              htmlFor="contentUrl"
              className={classes.convoCoverLabel}
            >
              Group Cover Photo
            </InputLabel>
          )}
          <Grid
            item
            className={classes.uploadAreaWrapper}
            style={{
              display: values.contentUrl.length === 0 ? 'flex' : 'none',
            }}
          >
            <Dropzone
              ref={node => {
                dropzoneRef = node;
              }}
              disabled={disabled}
              accept={acceptedType}
              multiple={
                values.type === 'gallery' ||
                values.type === 'image' ||
                values.type === 'task' ||
                values.type === 'punchlistItem' ||
                false
              }
              maxSize={
                values.type === 'pdf'
                  ? MAX_PDF_FILE_SIZE
                  : MAX_UPLOAD_TOTAL_FILESIZE_BYTES
              }
              onDropRejected={() => {
                enqueueSnackbar(
                  `Sorry that file is too large or the wrong file type. Max size is ${MAX_UPLOAD_TOTAL_FILESIZE_BYTES /
                    1000 /
                    1000}Mb.`,
                  {
                    variant: 'error',
                    persist: false,
                  }
                );
              }}
              onDrop={acceptedFilesParam => {
                let acceptedFiles = [...acceptedFilesParam];

                if (acceptedFiles.length === 0) {
                  return;
                }

                if (values.contentUrl.length > 0) {
                  revokeUrls(values.contentUrl);
                  setFieldValue('contentUrl', []);
                }

                if (
                  values.type === CONTENT_TYPE.PROJECT ||
                  values.type === CONTENT_TYPE.LEAD ||
                  values.type === CONTENT_TYPE.TEMPLATE ||
                  values.type === CONTENT_TYPE.VIDEO ||
                  isFinancial
                ) {
                  if (acceptedFiles.length === 0) {
                    return;
                  }

                  if (acceptedFiles.length === 1) {
                    setHasBeenAutofilled(false);
                  }

                  if (acceptedFiles.length > 1) {
                    enqueueSnackbar('Only 1 file allowed', {
                      variant: 'warning',
                    });
                    acceptedFiles = acceptedFiles.slice(0, 1);
                  }
                } else if (
                  values.type === 'gallery' ||
                  values.type === 'image'
                ) {
                  if (acceptedFiles.length > MAX_IMAGES_IN_GALLERY) {
                    enqueueSnackbar(
                      `Maximum of ${MAX_IMAGES_IN_GALLERY} images.`,
                      {
                        variant: 'warning',
                      }
                    );
                    acceptedFiles = acceptedFiles.slice(
                      0,
                      MAX_IMAGES_IN_GALLERY
                    );
                  }
                }
                acceptedFiles.forEach((file, index) => {
                  acceptedFiles[index].uri = URL.createObjectURL(file);
                });
                setFieldValue('contentUrl', acceptedFiles);
                if (isFinancial) {
                  // offer to do autofill
                  ocrTheFile({ askFirst: true, file: acceptedFiles[0] });
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ height: '100%' }}
                  {...getRootProps()}
                >
                  <Grid item>
                    <input {...getInputProps()} />
                    {(values.type === CONTENT_TYPE.PROJECT ||
                      values.type === CONTENT_TYPE.LEAD) && (
                      <Typography variant="h3" color="primary" gutterBottom>
                        Cover Photo
                      </Typography>
                    )}
                    <CloudUploadOutlinedIcon
                      fontSize="inherit"
                      color="inherit"
                    />
                    <Typography variant="body1" style={{ padding: '0px 16px' }}>
                      {values.type === CONTENT_TYPE.PROJECT ||
                      values.type === CONTENT_TYPE.LEAD ||
                      isFinancial
                        ? `Drag 'n' drop the image here, or click here to select it`
                        : `Drag 'n' drop the file(s) here, or click here to select them`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Dropzone>
          </Grid>
          {allowStreetViewCall && (
            <Grid item>
              <Button
                onClick={() =>
                  getStreetViewCoverImage({
                    location: values.address,
                  })
                }
              >
                ...or use Street View as cover image
              </Button>
            </Grid>
          )}
          <Grid
            item
            className={classes.visualLeftWrapper}
            style={{
              display: values.contentUrl.length > 0 ? 'flex' : 'none',
            }}
          >
            <Grid container direction="column">
              <Grid
                item
                style={{
                  textAlign: 'none',
                  width: '100%',
                }}
              >
                {contentType === CONTENT_TYPE.VIDEO ? (
                  <>
                    {_.get(values, 'contentUrl[0].uri') &&
                    values.contentUrl[0].uri.indexOf('cloudinary') > -1 ? (
                      // Handle if it's cloudinary
                      <ContentVideo
                        contentPiece={{
                          contentUrl: JSON.stringify(
                            values.contentUrl,
                            null,
                            2
                          ),
                        }}
                      />
                    ) : (
                      // otherwise, it's a local file
                      // eslint-disable-next-line jsx-a11y/media-has-caption
                      <video
                        id="previewwww"
                        controls
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                          maxHeight: 300,
                        }}
                        // autoplay
                        src={
                          values.contentUrl && values.contentUrl[0]
                            ? values.contentUrl[0].uri
                            : null
                        }
                      />
                    )}
                  </>
                ) : (
                  <ContentGallery
                    hideDownload
                    glassMagnifier={values.type !== CONTENT_TYPE.PROJECT}
                    contentPiece={{
                      contentUrl: JSON.stringify(values.contentUrl, null, 2),
                    }}
                    compactView
                  />
                )}
              </Grid>
              {!disabled && !fromShoebox && (
                <Grid item>
                  <Grid
                    container
                    justifyContent={
                      showAutofillButton ? 'space-between' : 'flex-end'
                    }
                    item
                    style={{
                      textAlign: 'right',
                      marginTop: 8,
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={e => {
                        e.preventDefault(); // --> without this onClick fires 3 times
                        // remove objects from window too
                        revokeUrls(values.contentUrl);
                        dropzoneRef.open();
                      }}
                    >
                      Change...
                    </Button>
                    {showAutofillButton && (
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Tooltip title="Search the document for information and add it to the content form">
                          <IconButton color="primary">
                            <HelpIcon style={{ fontSize: 18 }} />
                          </IconButton>
                        </Tooltip>
                        <Button
                          color="primary"
                          className={classes.ocrButton}
                          variant="contained"
                          onClick={e => {
                            e.preventDefault(); // --> without this onClick fires 3 times
                            ocrTheFile({
                              askFirst: false,
                              file: _.isArray(values.contentUrl)
                                ? values.contentUrl[0]
                                : values.contentUrl,
                            });
                          }}
                          startIcon={<SpellcheckIcon />}
                        >
                          Autofill Info
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <ErrorMessage
              name="contentUrl"
              render={errorMessage => (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <WarningIcon color="error" style={{ marginRight: 5 }} />
                  <Typography variant="body1" style={{ color: 'red' }}>
                    {errorMessage}
                  </Typography>
                </Grid>
              )}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={classes.uploadWrapper}
        >
          <Grid item className={classes.nonUploadIconWrapper}>
            {contentType === 'timetracking' && (
              <TimerOutlinedIcon fontSize="inherit" color="inherit" />
            )}
            {contentType !== 'timetracking' && (
              <BorderColorOutlinedIcon fontSize="inherit" color="inherit" />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withSnackbar(ManageMedia);
