import _ from 'lodash';
import { typesAndSchema } from '../info-about-content';
import { IGNORE_FOR_TEMPLATES } from '../../../config/appDefaults';

const buildFieldsForType = ({ contentType, isInTemplate = false }) => {
  const typeWithSchema = typesAndSchema[contentType];

  const userEditableFields = [];

  typeWithSchema.forEach(item => {
    if (item.userEditable) {
      if (
        (contentType === 'template' || isInTemplate) &&
        _.includes(IGNORE_FOR_TEMPLATES, item.fieldName)
      ) {
        return;
      }
      userEditableFields.push(item.fieldName);
    }
  });

  return { userEditableFields };
};

export default buildFieldsForType;
