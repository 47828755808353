import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.BookkeepingAlert}
  mutation CreateBookkeepingAlert(
    $id: String!
    $companyId: String!
    $alertIdentifier: String!
    $alertIdentifierSub: String
    $enabled: Boolean!
    $recipientType: BookkeepingAlertRecipientTypes!
    $recipient: String
    $notificationMethod: BookkeepingAlertNotificationMethod
  ) {
    createBookkeepingAlert(
      input: {
        id: $id
        companyId: $companyId
        alertIdentifier: $alertIdentifier
        alertIdentifierSub: $alertIdentifierSub
        enabled: $enabled
        recipientType: $recipientType
        recipient: $recipient
        notificationMethod: $notificationMethod
      }
    ) {
      ...BookkeepingAlertFields
    }
  }
`;
