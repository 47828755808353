import React, { useState } from 'react';
import { compose } from 'react-apollo';
import _ from 'lodash';
import { Button, CircularProgress } from '@material-ui/core';

import CustomerDepositsAccountPicker from './customer-deposits-account-picker';
import GraphQL from '../../graphql';
import { SCOREBOARD_NAME } from '../../config/appDefaults';

const CustomerDepositsAccountSelector = ({
  listScoreboardSettingsLoading,
  scoreboardSettings,
}) => {
  const [showAccountSelection, setShowAccountSelection] = useState(false);

  const handleClick = () => {
    setShowAccountSelection(true);
  };

  let currentCustomerDepositAccounts = [];
  const generalSettings = _.find(scoreboardSettings, {
    scoreboardName: SCOREBOARD_NAME.GENERAL,
  });

  if (generalSettings) {
    currentCustomerDepositAccounts =
      generalSettings.customerDepositAccounts || [];
  }

  return (
    <>
      <Button
        variant="text"
        color="primary"
        onClick={handleClick}
        style={{ minWidth: 200 }}
      >
        {listScoreboardSettingsLoading && <CircularProgress size={16} />}
        {!listScoreboardSettingsLoading &&
          currentCustomerDepositAccounts.length === 0 &&
          'Select an account'}
        {!listScoreboardSettingsLoading &&
          currentCustomerDepositAccounts.length !== 0 &&
          `${currentCustomerDepositAccounts.length} account${
            currentCustomerDepositAccounts.length === 1 ? '' : 's'
          } selected`}
      </Button>
      {showAccountSelection && (
        <CustomerDepositsAccountPicker
          currentAccounts={currentCustomerDepositAccounts}
          handleDialogClose={() => setShowAccountSelection(false)}
        />
      )}
    </>
  );
};

export default compose(GraphQL.ListScoreboardSettingsAction)(
  CustomerDepositsAccountSelector
);
