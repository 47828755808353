import gql from 'graphql-tag';

export default gql`
  query GetSlideshowInfo($jrnId: String!, $requestorId: String!) {
    getSlideshowInfo(jrnId: $jrnId, requestorId: $requestorId) {
      __typename
      jrnId
      id
      active
      link
    }
  }
`;
