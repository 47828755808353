import { ref } from 'yup';

import {
  MAX_CHARACTERS_IN_JRN_TITLE_FIELD,
  MAX_CHARACTERS_IN_DESCRIPTION_FIELD,
} from '../../../config/appDefaults';

export const standardRequiredMsg = () => 'This field is required';

export const standardMaxMsg = maxLength => {
  return `Cannot be more than ${maxLength} characters.`;
};

export const endDateNotBeforeStartDateValidation = {
  type: 'min',
  params: [ref('startDate'), 'End date cannot be before start date'],
};

export const titleMaxValidation = {
  type: 'max',
  params: [
    MAX_CHARACTERS_IN_JRN_TITLE_FIELD,
    standardMaxMsg(MAX_CHARACTERS_IN_JRN_TITLE_FIELD),
  ],
};

export const descMaxValidation = {
  type: 'max',
  params: [
    MAX_CHARACTERS_IN_DESCRIPTION_FIELD,
    standardMaxMsg(MAX_CHARACTERS_IN_DESCRIPTION_FIELD),
  ],
};
