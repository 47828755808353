import React from 'react';
import { GRAPH_TYPE, TARGET_TYPE } from '../../../config/appDefaults';

const disclaimer = (
  <>
    <br />
    <br />
    <i>
      Note: This graph is based on the data in your accounting software, so work
      with your bookkeeper to make sure your books are up to date for a given
      period before relying on this graph.
    </i>
  </>
);

export const profitAndLossGraphs = [
  {
    name: 'monthlyOperatingProfitPercentage',
    label: 'Operating Net Profit (%)',
    targetType: TARGET_TYPE.PERCENTAGE,
    graphType: GRAPH_TYPE.LINE,
    description: (
      <>
        Also known as Operating Net Margin, your Operating Net Profit (%) shows
        you how efficient your company is at generating profit through its core
        operating activities. In other words, this chart helps you answer “What
        % of my company’s project sales is left over after deducting all direct
        project costs and overhead expenses generated by its day-to-day
        operations?”
        <br />
        <br />
        You can improve your Operating Net Profit (%) by implementing better
        operating procedures in your business, reducing direct project costs and
        overhead expenses, increasing prices, and being more effective with your
        marketing.
        <br />
        <br />
        Operating Net Profit (%) is calculated by taking your Operating Net
        Profit ($) and dividing it by your company’s project sales for each
        period.
        {disclaimer}
      </>
    ),
  },
  {
    name: 'marginPercentage',
    label: 'Gross Margin (%)',
    targetType: TARGET_TYPE.PERCENTAGE,
    graphType: GRAPH_TYPE.LINE,
    description: (
      <>
        Also known as Margin, your Gross Margin (%) shows you how efficient your
        company is at completing projects. In other words, this chart helps you
        answer “What % of my company’s project sales is left over after
        deducting direct project costs, like materials, subcontractors, and
        labor?”
        <br />
        <br />
        Gross Margin (%) is calculated by dividing your Gross Profit ($) by your
        company’s project sales for each period.
        <br />
        <br />
        Gross Margin (%) is different from your Markup, which is calculated by
        dividing Gross Profit ($) by your company’s direct project costs. For
        more information check out{' '}
        <a
          href="https://www.instagram.com/p/CP6KsGbH2Gl/"
          target="_blank"
          rel="noopener noreferrer"
        >
          this example
        </a>
        .{disclaimer}
      </>
    ),
  },
  {
    name: 'monthlyOperatingProfitAmount',
    label: 'Operating Net Profit ($)',
    targetType: TARGET_TYPE.NUMBER,
    graphType: GRAPH_TYPE.BAR,
    description: (
      <>
        Operating Net Profit ($) shows you the profit generated from your
        company’s core operations. It serves as a highly accurate indicator of
        your company’s health because it only includes income and expenses
        generated by your day-to-day operations.
        <br />
        <br />
        Net Operating Profit ($) is different from your Net Profit, because Net
        Profit includes non-operating income and expenses (i.e. Other Income
        &amp; Other Expenses in your chart of accounts).
        <br />
        <br />
        Operating Net Profit ($) is calculated by taking your company’s project
        sales, and deducting direct project costs and overhead expenses
        generated by its core operating activities for each period.
        {disclaimer}
      </>
    ),
  },
  {
    name: 'monthlyGrossProfitAmount',
    label: 'Gross Profit ($)',
    targetType: TARGET_TYPE.NUMBER,
    graphType: GRAPH_TYPE.BAR,
    description: (
      <>
        Gross Profit ($) shows you the profit generated from your company’s
        projects after deducting all direct project costs, like materials,
        subcontractors and labor. Since it excludes overhead expenses, your
        Gross Profit ($) is an indicator of your company’s ability to cover its
        overhead expenses.
        <br />
        <br />
        Changes in your Gross Profit ($) occur as a result of changes in your
        sales or direct project costs. You can improve your Gross Profit ($) by
        increasing your sales volume or prices, or by reducing and keeping track
        of movements in your direct project costs.
        <br />
        <br />
        Gross Profit ($) is calculated by deducting your company’s direct
        project costs from all project sales for each period.
        {disclaimer}
      </>
    ),
  },
];
