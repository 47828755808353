export default theme => ({
  dropzoneStyle: {
    border: '3px dashed #ccc',
    minHeight: '100px',
  },
  uploadWrapper: {
    padding: theme.spacing(2),
  },
  convoCoverLabel: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(1),
  },
  uploadAreaWrapper: {
    textAlign: 'center',
    borderRadius: 5,
    color: theme.palette.brandColorPrimary,
    backgroundColor: theme.palette.brandColorPrimary10,
    fontSize: 50,
    minHeight: 200,
    width: '100%',
    maxWidth: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eee',
    },
    transition: 'all 0.25s ease',
  },
  visualLeftWrapper: {
    maxWidth: '100%',
  },
  nonUploadIconWrapper: {
    color: theme.palette.brandColorPrimary,
    fontSize: 90,
  },
  assignedToInput: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'currentColor',
      '&:before': {
        borderBottomStyle: 'solid',
      },
    },
    cursor: 'pointer',
  },
  assignedToInputDisabled: {
    color: '#000000',
    opacity: 0.38,
  },
  isPublicWrapper: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    display: 'flex',
  },
  isPublicWrapperInner: {
    display: 'inline-flex',
    marginRight: theme.spacing(4),
  },
  isPublicLabel: {
    display: 'flex',
    margin: 0,
  },
  errorMessageListWrapper: {
    color: 'red',
    marginTop: theme.spacing(4),
  },
  submitButtonWrapper: {
    textAlign: 'right',
    marginTop: theme.spacing(4),
  },
  pdfIcon: {
    color: theme.palette.brandColorPrimary,
    fontSize: 200,
    paddingBottom: '10px',
  },
  staticLabel: {
    transform: 'scale(0.75)',
  },
  inlineLabel: {
    float: 'left',
  },
  refundLabel: {
    paddingLeft: theme.spacing(1.5),
  },
  generalOptionsTitle: {
    color: theme.palette.brandColorPrimary,
  },
  advancedButton: { marginTop: 3, minWidth: '80%' },
  ocrButton: {
    color: '#fff',
    background: theme.palette.brandOcrTurquoise,
  },
});
