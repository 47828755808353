import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import './level-scheduler-styles.scss';

import LevelModal from '../../../../components/Modal/level-modal';
import { colorsGoodForWhiteText } from '../../../../config/appDefaults';
import { cloudinaryifyProfilePic } from '../../../../helpers/cloudinary';
import { renderShiftDate } from '../../../../helpers/renderDateString';
import { DeleteContentAction } from '../../../../graphql/graphql';

const Scheduler = props => {
  const { shifts, allUsers, isAdmin, onDeleteContent, handleEdit } = props;
  const [events, setEvents] = useState([]);
  const [dialogOptions, setDialogOptions] = useState({
    edit: false,
    remove: false,
  });

  useEffect(() => {
    if (!shifts || !allUsers || allUsers.length === 0) return;
    // set each person's color different
    const userColors = {};
    let copyColorsGoodForWhiteText = [...colorsGoodForWhiteText];
    const eventItems = [];
    shifts.forEach(shift => {
      const userDetails = _.find(allUsers, { userId: shift.assignedTo });
      if (!userDetails) return;
      // use the same colors as the calendar to show the shifts
      //  copy the color list so we can use it up and then once it's empty, we'll refill it and use it again
      let userColor;
      if (userColors[shift.assignedTo]) {
        userColor = userColors[shift.assignedTo];
      } else {
        if (!copyColorsGoodForWhiteText.length > 0) {
          // if there are no colors left, reset/refill the colors
          copyColorsGoodForWhiteText = [...colorsGoodForWhiteText];
        }
        userColor = copyColorsGoodForWhiteText.shift();
        userColors[shift.assignedTo] = userColor;
      }
      eventItems.push({
        title: userDetails.username,
        start: new Date(shift.startDate),
        end: new Date(shift.endDate),
        color: userColor,
        id: shift.contentId,
        extendedProps: {
          shift,
          userDetails,
        },
      });
    });
    setEvents(eventItems);
  }, [shifts, allUsers]);

  const eventClick = event => {
    const { shift, userDetails } = event.event.extendedProps;
    setDialogOptions({
      edit: true,
      shift,
      userDetails,
    });
  };

  const deleteContent = () => {
    let contentIdToDelete = dialogOptions.shift.contentId;
    const parentProjectId = dialogOptions.shift.jrnId;
    if (dialogOptions.shift.recurrence) {
      contentIdToDelete = dialogOptions.shift.recurrence.rangeId;
    }

    onDeleteContent(contentIdToDelete, parentProjectId);

    setDialogOptions({
      ...dialogOptions,
      remove: false,
      edit: false,
    });
  };

  const renderDialogContents = () => {
    const { shift, userDetails } = dialogOptions;
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Avatar
                  alt="user profile image"
                  src={
                    userDetails.profilePic
                      ? cloudinaryifyProfilePic(userDetails.profilePic)
                      : null
                  }
                  style={{ height: '100px', width: '100px', marginRight: 16 }}
                />
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="body1">
                      {userDetails.username}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      {`${renderShiftDate(
                        moment(shift.startDate),
                        moment(shift.endDate)
                      )}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {isAdmin && (
            <Grid item>
              <Button
                onClick={() => {
                  setDialogOptions({
                    ...dialogOptions,
                    edit: false,
                  });
                  handleEdit(shift);
                }}
                style={{ color: 'red' }}
              >
                Edit
              </Button>
              <Button
                onClick={e => {
                  e.preventDefault();
                  setDialogOptions({
                    ...dialogOptions,
                    remove: true,
                  });
                }}
                style={{ color: 'red' }}
              >
                Remove
              </Button>
            </Grid>
          )}
        </Grid>

        {!!shift.description && (
          <Grid
            item
            container
            direction="column"
            style={{ marginTop: 24, background: '#eaeaea', padding: 16 }}
          >
            <Typography variant="h5" style={{ marginBottom: 8 }}>
              Shift Notes:
            </Typography>
            {ReactHtmlParser(shift.description)}
          </Grid>
        )}
      </Grid>
    );
  };

  const handleEditClose = () => {
    setDialogOptions({
      ...dialogOptions,
      edit: false,
    });
  };

  const handleRemoveClose = () => {
    setDialogOptions({
      ...dialogOptions,
      remove: false,
      edit: false,
    });
  };

  return (
    <div>
      <FullCalendar
        timeZone="local"
        initialView="timeGridWeek"
        eventClick={eventClick}
        events={events}
        plugins={[timeGridPlugin, interactionPlugin]}
        height="auto"
        buttonText={{
          today: 'Today',
        }}
      />
      {dialogOptions.edit && (
        <LevelModal
          open={dialogOptions.edit}
          handleClose={handleEditClose}
          maxWidth="sm"
        >
          {renderDialogContents()}
        </LevelModal>
      )}

      {dialogOptions.remove && (
        <LevelModal
          open={dialogOptions.remove}
          handleClose={handleRemoveClose}
          maxWidth="sm"
        >
          <Grid container>
            <Grid item>
              {dialogOptions.shift.recurrence ? (
                <>
                  This shift is part of a range. This will remove{' '}
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontStyle: 'italic',
                      color: 'red',
                    }}
                  >
                    all
                  </span>{' '}
                  shifts included in the range.
                  <br />
                  <br />
                  Are you sure you want to proceed? This cannot be undone.
                </>
              ) : (
                <>
                  Are you sure you want to remove this shift? This cannot be
                  undone.
                </>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginTop: '10px', textAlign: 'right' }}
            >
              <Button autoFocus onClick={handleRemoveClose} color="primary">
                Cancel
              </Button>
              <Button onClick={deleteContent} color="primary">
                Yes
              </Button>
            </Grid>
          </Grid>
        </LevelModal>
      )}
    </div>
  );
};
export default compose(DeleteContentAction)(withRouter(Scheduler));
