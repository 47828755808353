import gql from 'graphql-tag';
import * as Fragments from '../fragments';

export default gql`
  ${Fragments.BookkeepingRule}
  mutation UpsertBookkeepingRule(
    $id: String!
    $companyId: String!
    $alertIdentifier: String!
    $alertIdentifierSub: String
    $enabled: Boolean!
  ) {
    upsertBookkeepingRule(
      input: {
        id: $id
        companyId: $companyId
        alertIdentifier: $alertIdentifier
        alertIdentifierSub: $alertIdentifierSub
        enabled: $enabled
      }
    ) {
      ...BookkeepingRuleFields
    }
  }
`;
