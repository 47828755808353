import React from 'react';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';
import ContentLoader from 'react-content-loader';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import ListOfActivitiesView from './ListOfActivities.view';
import ErrorHandle from '../ErrorHandle/errorHandle';
import ListMyActivityFeedItems from '../../graphql/queries/ListMyActivityFeedItems';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: 0,
    overflow: 'hidden',
  },
  paper: {
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 ${theme.spacing(
      1
    )}px`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.brandColorPrimary,
      color: '#fff',
    },
    transition: 'all 0.3s ease',
  },
  ActivityDate: {
    fontSize: '80%',
  },
  ActivityDesc: {
    marginTop: theme.spacing(1),
  },
}));

const ListOfActivities = props => {
  const classes = useStyles();
  const { onClickOfActivity, userId } = props;

  return (
    <Query
      query={ListMyActivityFeedItems}
      variables={{ userId }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data, refetch }) => {
        if (loading) {
          const howMany = [1, 2, 3, 4, 5];
          return howMany.map(key => (
            <Paper className={classes.paper} key={key}>
              <ContentLoader
                height={112}
                width={300}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                style={{ maxHeight: 112, maxWidth: 300 }}
              >
                <circle cx="63" cy="56" r="40" />
                <rect x="110" y="26" rx="0" ry="0" width="168" height="11" />
                <rect x="110" y="42" rx="0" ry="0" width="168" height="11" />
              </ContentLoader>
            </Paper>
          ));
        }
        // if (error) return `Error! ${error}`;
        // TODO: send error to sentry/bugsnag???
        if (error) return <ErrorHandle refetch={refetch} />;

        const sortedData =
          _.orderBy(
            data.listMyActivityFeed.items,
            ['startDate', 'title'],
            ['desc', 'asc']
          ) || [];
        return (
          <div className="levelActivityListWrapper">
            <ListOfActivitiesView
              activity={sortedData}
              onClickOfActivity={onClickOfActivity}
              // errorListingActivitys={error}
              refetchListingActivitys={refetch}
              {...props}
            />
          </div>
        );
      }}
    </Query>
  );
};

function mapStateToProps(state) {
  return {
    userId: state.userInfo.userId,
  };
}

export default connect(mapStateToProps)(ListOfActivities);
