import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import _ from 'lodash';
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  IconButton,
  CircularProgress,
  Tooltip,
  InputLabel,
  Switch as MuiSwitch,
  Divider,
} from '@material-ui/core';
import {
  LinkOff as LinkOffIcon,
  HelpOutline as HelpOutlineIcon,
  Link as LinkIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import palette from '../../theme/palette';
import LoadingCover from '../LoadingCover/loadingCover';
import ConnectToQuickBooks from '../connect-to-quickbooks/connect-to-quickbooks';
import {
  GetCompanyInfoAction,
  DisconnectFromQuickBooksAction,
  SetCompanyCustomerSyncStatusAction,
} from '../../graphql/graphql';
import CheckQboConnectionStatus from '../../graphql/queries/check-qbo-connection-status';
import SettingsSection from '../settings-section';
import AddCustomerDialog, { WHAT_TO_SHOW } from '../add-customer-dialog';
import CustomerDepositsAccountControl from '../customer-deposits-account-control';
import QboIconImage from '../../assets/images/quickbooks/qb-logo-icon-button.svg';
// FUTURE: Post app store review, consider flipping icon back
// import QboHeaderImage from '../../assets/images/quickbooks/qb-logo-icon-button-header.svg';
import QboHorizontalImage from '../../assets/images/quickbooks/quickbooks-brand-preferred-logo-50-50-black-external.png';

const useStyles = makeStyles(() => ({
  formRow: {
    alignItems: 'center',
    width: '100%',
    minHeight: 38,
  },
  inlineIconButton: {
    marginTop: -10,
    marginBottom: -8,
  },
}));

const SettingsQbo = ({
  companies,
  managingCompanyInfo,
  disconnectFromQuickBooks,
  updateCompanyCustomerSyncStatus,
  isCopilot,
}) => {
  const [showDisconnectWarning, setShowDisconnectWarning] = useState(false);
  const [qboStatusLoading, setQboStatusLoading] = useState(false);
  const [qboSyncStatusLoading, setQboSyncStatusLoading] = useState(false);
  const [infoToShow, setInfoToShow] = useState(null);
  const [showImportCustomerForm, setShowImportCustomerForm] = useState(false);

  const handleDialogClose = () => setInfoToShow(null);

  const classes = useStyles();

  const { data: checkQboConnectionStatusData } = useQuery(
    CheckQboConnectionStatus,
    {
      skip:
        !managingCompanyInfo?.managingCompanyId ||
        !managingCompanyInfo?.isConnectedToQuickBooks,
      variables: { companyId: managingCompanyInfo?.managingCompanyId },
      fetchPolicy: 'network-only',
      pollInterval: 5000, // FUTURE: remove after app store review
    }
  );

  const existingCompany = companies
    ? _.find(companies, {
        companyId: managingCompanyInfo.managingCompanyId,
      })
    : null;

  const handleDisconnectFromQuickBooks = async () => {
    setQboStatusLoading(true);
    await disconnectFromQuickBooks();
    setQboStatusLoading(false);
  };

  // Any company account can now be connected to QuickBooks Online
  // This used to be only bookkeeping and advisory customers
  const allowQuickBooksConnection = true;

  const doSyncStatusChange = async () => {
    setQboSyncStatusLoading(true);
    setInfoToShow(false);
    await updateCompanyCustomerSyncStatus(!existingCompany.customerSyncEnabled);
    setQboSyncStatusLoading(false);
  };

  const customerSyncInfo = {
    title: 'QuickBooks Online Customer Sync',
    description: (
      <>
        By enabling customer sync with QuickBooks Online, you will be able to
        import QuickBooks Online customers to Level, create QuickBooks Online
        customers from existing Level customers and connect a Level customer
        with a QuickBooks Online customer to make sure everything is kept
        up-to-date! <br />
        <br />
        Once a QuickBooks Online customer has been connected to Level, you will
        be able to import their QuickBooks Online sub-customers as projects or
        leads, create QuickBooks Online sub-customers from existing projects or
        leads, and sync existing QuickBooks Online sub-customers with existing
        Level project and leads.
        <br />
        <br />
        Once activated, connected customers, projects and leads will show an
        active sync icon
        <img
          alt="QuickBooks Sync Icon"
          src={QboIconImage}
          height="20"
          style={{
            marginBottom: -4,
            paddingLeft: 8,
            paddingRight: 8,
          }}
        />
        to indicate that it is connected to a customer in QuickBooks Online. You
        can manage which items are synced by using this button!
      </>
    ),
  };

  const customerDepositsLiabilityAccountInfo = {
    title: 'QuickBooks Online Customer Deposits Account',
    description: (
      <>
        In some cases customer deposits can be booked to liability accounts in
        QuickBooks Online and will not appear in a project&apos;s profit and
        loss report, which may overestimate the amount financed by your company.
        <br />
        <br />
        To accurately reflect customer deposits in the cash flow analysis on
        your Project Scoreboards, you must identify the liability account used
        to book customer deposits.
      </>
    ),
  };

  const handleCustomerSyncStatusChange = () => {
    if (existingCompany.customerSyncEnabled) {
      doSyncStatusChange();
      return;
    }

    setInfoToShow({
      ...customerSyncInfo,
      action: { handler: doSyncStatusChange, text: 'Sync' },
    });
  };

  const QBO_HASH_KEY = 'qbo';

  const qboConnectionStatus = _.get(
    checkQboConnectionStatusData,
    'checkQboConnectionStatus.status',
    ''
  );

  const tokenWasRevokedOrConnectionLost = [
    'token-revoked',
    'not-connected',
  ].includes(qboConnectionStatus);

  const isConnectedToQuickBooks =
    existingCompany?.connectedToQuickBooks && !tokenWasRevokedOrConnectionLost;

  return (
    <SettingsSection
      title="QuickBooks Online"
      description="Manage your integration with QuickBooks Online"
      icon={<LinkIcon />}
      // icon={
      //   <img alt="QuickBooks Online logo" src={QboHeaderImage} width={20} />
      // }
      hashKey={QBO_HASH_KEY}
    >
      {!existingCompany && <LoadingCover />}
      {existingCompany && (
        <Grid container direction="column">
          <Grid
            container
            item
            justifyContent="flex-start"
            alignItems="center"
            style={{ marginBottom: 16 }}
          >
            <img
              alt="QuickBooks Online Logo"
              src={QboHorizontalImage}
              width={200}
            />
          </Grid>
          <Grid item>
            {isCopilot ? (
              <Typography variant="body1">
                Connect Level to QuickBooks Online to allow us to check your
                books for errors and to provide you with feedback.
              </Typography>
            ) : (
              <Typography variant="body1">
                Level can connect with your QuickBooks Online accounts to
                generate scoreboards to allow you to visualize your
                business&apos;s financial health in real-time.
                <br />
                <br />
                You can also optimize your workday by allowing Level to sync
                with QuickBooks Online to make sure your customers stay
                up-to-date and to connect your Level projects and leads with
                sub-customers in QuickBooks Online.
              </Typography>
            )}
          </Grid>
          {!isConnectedToQuickBooks && (
            <Grid container item xs={12} justifyContent="center">
              <Grid item style={{ marginTop: 16 }}>
                <ConnectToQuickBooks
                  locked={!allowQuickBooksConnection}
                  redirectTo={`/settings#${QBO_HASH_KEY}`}
                />
              </Grid>
            </Grid>
          )}
          {isConnectedToQuickBooks && (
            <Grid container style={{ paddingTop: 16 }}>
              <Grid container item className={classes.formRow}>
                <Grid item xs={6}>
                  <InputLabel>
                    <Typography color="textSecondary">
                      QuickBooks Status
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Typography
                    variant="h5"
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      color: palette.quickBooks.green,
                      fontWeight: 'bold',
                    }}
                    id="qbo-connected"
                  >
                    Connected
                  </Typography>
                  {qboStatusLoading && (
                    <CircularProgress
                      style={{
                        marginLeft: 12,
                        height: 24,
                        width: 24,
                      }}
                    />
                  )}
                  {!qboStatusLoading && (
                    <Tooltip title="Disconnect from QuickBooks">
                      <IconButton
                        onClick={() => setShowDisconnectWarning(true)}
                        className={classes.inlineIconButton}
                      >
                        <LinkOffIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>

              {!isCopilot && (
                <>
                  <Divider />
                  <Grid container item className={classes.formRow}>
                    <Grid item xs={6} style={{ display: 'flex' }}>
                      <InputLabel>
                        <Typography color="textSecondary">
                          Enable customer sync
                          <Tooltip title="Click for more info">
                            <HelpOutlineIcon
                              fontSize="small"
                              color="primary"
                              style={{
                                marginBottom: -4,
                                marginLeft: 4,
                              }}
                              onClick={() => setInfoToShow(customerSyncInfo)}
                            />
                          </Tooltip>
                        </Typography>
                      </InputLabel>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={6}
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      {qboSyncStatusLoading && (
                        <CircularProgress
                          style={{
                            height: 24,
                            width: 24,
                          }}
                        />
                      )}
                      <MuiSwitch
                        checked={!!existingCompany.customerSyncEnabled}
                        variant="inline"
                        onChange={handleCustomerSyncStatusChange}
                        disabled={qboSyncStatusLoading}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              {!isCopilot && (
                <>
                  <Divider />
                  <Grid container item className={classes.formRow}>
                    <Grid item xs={6} style={{ display: 'flex' }}>
                      <InputLabel>
                        <Typography color="textSecondary">
                          Customer Deposits Liability Account
                          <Tooltip
                            title="Click for more info"
                            style={{ cursor: 'pointer' }}
                          >
                            <HelpOutlineIcon
                              fontSize="small"
                              color="primary"
                              style={{
                                marginBottom: -4,
                                marginLeft: 4,
                              }}
                              onClick={() =>
                                setInfoToShow(
                                  customerDepositsLiabilityAccountInfo
                                )
                              }
                            />
                          </Tooltip>
                        </Typography>
                      </InputLabel>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={6}
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <CustomerDepositsAccountControl />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Grid>
      )}
      {showDisconnectWarning && (
        <Dialog open onClose={() => setShowDisconnectWarning(false)}>
          <DialogTitle id="alert-dialog-title">
            {isCopilot ? (
              <>
                Wait!
                <br />
                <br />
                Are you sure you want to disconnect Level from your QuickBooks
                Online?
                <br />
                <br />
                You will no longer be able to set rules or receive your alerts.
              </>
            ) : (
              <>
                Are you sure you want to disconnect Level from your QuickBooks
                Online? You will no longer be able to see your financial
                scoreboards and sync your Level customers, project and leads
                with QuickBooks Online.
              </>
            )}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => setShowDisconnectWarning(false)}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setShowDisconnectWarning(false);
                handleDisconnectFromQuickBooks();
              }}
              variant="contained"
              color="primary"
              autoFocus
            >
              Yes, Disconnect My Books
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {infoToShow && (
        <Dialog
          open
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{infoToShow.title}</DialogTitle>
          <DialogContent dividers style={{ paddingTop: 20, paddingBottom: 20 }}>
            <DialogContentText style={{ color: palette.text.primary }}>
              {infoToShow.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {infoToShow.action && (
              <>
                <Button autoFocus onClick={handleDialogClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={infoToShow.action.handler}
                  color="primary"
                  variant="contained"
                >
                  {infoToShow.action.text}
                </Button>
              </>
            )}
            {!infoToShow.action && (
              <Button onClick={handleDialogClose} color="primary">
                Close
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
      {showImportCustomerForm && (
        <AddCustomerDialog
          initialValues={null}
          toClose={() => setShowImportCustomerForm(false)}
          creationType={WHAT_TO_SHOW.IMPORT_FROM_QBO}
        />
      )}
    </SettingsSection>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default compose(
  GetCompanyInfoAction,
  DisconnectFromQuickBooksAction,
  SetCompanyCustomerSyncStatusAction,
  connect(mapStateToProps)
)(SettingsQbo);
