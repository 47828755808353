import React from 'react';

const PasswordRequirementsTooltip = () => {
  return (
    <div>
      Passwords require:
      <ul style={{ margin: 0, marginLeft: 16 }}>
        <li>min length of 6 characters</li>
        <li>at least 1 letter, 1 number</li>
      </ul>
    </div>
  );
};

export default PasswordRequirementsTooltip;
