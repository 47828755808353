import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Report}
  query GetBreakevenData(
    $companyId: String
    $reportPeriod: String
    $accountingMethod: String
    $breakevenPeriod: String
  ) {
    getBreakevenData(
      input: {
        companyId: $companyId
        reportPeriod: $reportPeriod
        accountingMethod: $accountingMethod
        breakevenPeriod: $breakevenPeriod
      }
    ) {
      items {
        ...ReportFields
      }
    }
  }
`;
