import React, { useRef, useState, useMemo, useEffect } from 'react';
import {
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@material-ui/core';
import { HelpOutline as HelpIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import ContentLoader from 'react-content-loader';
import VisXChart from './xy-chart';
import prettyString from './pretty-string';
import palette from '../../theme/palette';
import { FAKE_INFINITY } from '../../config/appDefaults';

const useStyles = makeStyles(() => ({
  graph: {
    height: '100%',
    width: '100%',
    position: 'relative',
    paddingTop: 20,
    paddingBottom: 10,
  },
  graphTitle: {
    position: 'absolute',
    top: 20,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
  },
  graphArea: {
    width: '100%',
    height: '100%',
    marginLeft: 10,
  },
}));

/**
 * An x-y co-ordinate
 * @typedef {Object} DataPointXY
 * @property {String} x - The position along the x-axis
 * @property {?Number} y - The position along the y-axis
 */

/**
 * A string representing the type for the x-y co-ordinates provided
 * @typedef {Object} DataTypeXY
 * @property {('percentage' | 'number' | 'month' | 'week' | 'day' | 'money')} x - The type along the x-axis
 * @property {('percentage' | 'number' | 'month' | 'week' | 'day' | 'money')} y - The type along the y-axis
 */

/**
 * An object describing a data series to plot
 * @typedef {Object} DataSeries
 * @property {('line' | 'bar' | 'area')} type - Type of chart
 * @property {DataPointXY[]} data - The data points to be plotted
 * @property {DataTypeXY} dataType - The data type to be plotted
 * @property {String} name - The name of the series shown in tooltip
 * @property {?String | colorFunction} color - The color to be used by the line, bar or area
 * @property {?String} fromColor - The color to be used by an area gradient
 * @property {?String} toColor - The color to be used by an area gradient
 */

/**
 * A function that defines color for bar graphs based on data position
 * @callback colorFunction
 * @param {Number} index Index of data
 * @returns {String} Hexcode for color
 */

/**
 * An object that describes min and max values
 * @typedef {Object} MinMax
 * @property {*} min - The min value along the access
 * @property {*} max - The max value along the access
 */

/**
 * An object used to describe x and y limits
 * @typedef {Object} Limits
 * @property {?MinMax} x - The min and/or max values for the x-axis
 * @property {?MinMax} y - The min and/or max values for the y-axis
 */

/**
 * Level custom graph component
 * @param {Object} props
 * @param {?String} props.description - Description to be shown for chart
 * @param {?String} props.title - Title displayed at top of chart
 * @param {DataSeries[]} props.dataSeries - The data to be plotted
 * @param {?String} props.target - Optional target to be plotted
 * @param {?('positive' | 'negative')} props.targetSentiment - Optional target sentiment to change goal line appearance
 * @param {?String} props.targetText - Optional override for target display text
 * @param {?String} props.targetColor - Optional override for target display color
 * @param {Boolean} props.loading - Will show chart in loading state
 * @param {Boolean} props.showAverage - Will show average below title
 * @param {Boolean} props.showTotal - Will show total below title
 * @param {Boolean} props.boldTotal - Will bold the total below title
 * @param {?String} props.totalOverride - Will show instead of the default total value
 * @param {?Limits} props.limit - Describes limits along axes
 * @param {Boolean} props.noPadding - Will remove padding at start and end of x range
 * @param {Boolean} props.showLegend - Will show legend top of chart
 * @param {Boolean} props.showDayOfWeek - Will show day of week in tooltip
 * @param {Boolean} props.showDateRange - Will show date range in tooltip
 * @param {Boolean} props.showGlyphs - Will show glyphs at the data points in the line chart
 * @param {Boolean} props.customInfoString - Custom info string will show instead of average or total text
 * @param {?String} props.type - Type of chart to be shown (null, 'barstack')
 * @param {?Object} props.defaultYScaleDomain - Default domain for y scale
 * @param {?Function} props.onBarStackClick - Function to be called when a bar stack is clicked
 * @param {?String} props.tooltipNote - Note to be shown in tooltip
 * @param {?Function} props.renderTooltipNote - Function to render tooltip note
 * @param {?Number} props.fiscalYearStartMonth - Month that fiscal year starts
 * @param {?Boolean} props.useTimeScale - Use time scale for x-axis
 * @param {?Date} props.startDate - Start date for x-axis
 * @param {?Date} props.endDate - End date for x-axis
 * @returns {JSX.Element}
 */
const Graph = ({
  description = null,
  title = null,
  dataSeries = [],
  target = null,
  targetSentiment = 'positive',
  targetText = null,
  targetColor = null,
  loading = false,
  showAverage = false,
  showTotal = false,
  boldTotal = true,
  averageOverride = null,
  totalOverride = null,
  limit = null,
  noPadding = false,
  showLegend = false,
  showDayOfWeek = false,
  showDateRange = false,
  customInfoString = null,
  type = null,
  onBarStackClick,
  tooltipNote,
  renderTooltipNote,
  fiscalYearStartMonth,
  defaultYScaleDomain,
  showGlyphs,
  onLineClick,
  onLineHover,
  useTimeScale = false,
  startDate = null, // Only used if time scale is true
  endDate = null, // Only used if time scale is true
}) => {
  const classes = useStyles();

  const [loaderDimensions, setLoaderDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [showDescription, setShowDescription] = useState(false);

  /** @type {React.RefObject} */
  const graphAreaRef = useRef();

  const updateLoaderDimensions = () => {
    if (graphAreaRef.current.clientHeight && graphAreaRef.current.clientWidth) {
      setLoaderDimensions({
        width: graphAreaRef.current.clientWidth,
        height: graphAreaRef.current.clientHeight,
      });
    }
  };

  useEffect(() => {
    updateLoaderDimensions();
  }, []);

  const totalAndOrAverageText = useMemo(() => {
    const primarySeries = dataSeries && dataSeries[0];

    if (
      loading ||
      (!showTotal && !showAverage) ||
      !primarySeries ||
      customInfoString
    ) {
      return null;
    }

    const { data, dataType } = primarySeries;

    // Get total, no nulls
    const dataWithNullsRemoved = data.filter(({ y }) => y !== null);
    const total = dataWithNullsRemoved.reduce(
      (accumulator, currentValue) => accumulator + currentValue.y,
      0
    );

    // If sum is greater or equal to fake infinity, the results will be garbage so don't show anything
    if (total >= FAKE_INFINITY) {
      return '';
    }

    let updatedTotalAndOrAverageText = '';
    if (showAverage) {
      if (averageOverride) {
        updatedTotalAndOrAverageText += `Average: ${averageOverride}`;
      } else if (dataWithNullsRemoved.length) {
        const average = total / dataWithNullsRemoved.length;
        updatedTotalAndOrAverageText += `Average: ${prettyString(
          average,
          dataType.y
        )}`;
      }
    }

    if (showTotal) {
      if (updatedTotalAndOrAverageText) {
        updatedTotalAndOrAverageText += ', ';
      }
      if (totalOverride) {
        updatedTotalAndOrAverageText += `Total: ${totalOverride}`;
      } else {
        updatedTotalAndOrAverageText += `Total: ${prettyString(
          total,
          dataType.y
        )}`;
      }
    }

    return updatedTotalAndOrAverageText;
  }, [
    loading,
    showTotal,
    showAverage,
    dataSeries,
    averageOverride,
    totalOverride,
    customInfoString,
  ]);

  return (
    <>
      <div className={classes.graph}>
        <div className={classes.graphTitle}>
          <div style={{ display: 'flex' }}>
            <Typography variant="h5">{title}</Typography>
            {description && (
              <Tooltip title="Click for more info">
                <HelpIcon
                  fontSize="small"
                  color="primary"
                  style={{ marginLeft: 15, cursor: 'pointer' }}
                  onClick={() => setShowDescription(true)}
                />
              </Tooltip>
            )}
          </div>
          {!customInfoString && totalAndOrAverageText && (
            <div style={{ marginTop: 6 }}>
              <Typography>
                {boldTotal ? (
                  <b>{totalAndOrAverageText}</b>
                ) : (
                  <>{totalAndOrAverageText}</>
                )}
              </Typography>
            </div>
          )}
          {customInfoString && (
            <div style={{ marginTop: 6 }}>
              <Typography>
                <b>{customInfoString}</b>
              </Typography>
            </div>
          )}
        </div>
        <div className={classes.graphArea} ref={graphAreaRef}>
          {loading && (
            <ContentLoader
              height={loaderDimensions.height}
              width={loaderDimensions.width}
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect
                x="40"
                y="40"
                rx="4"
                ry="4"
                width={loaderDimensions.width && loaderDimensions.width - 80}
                height={loaderDimensions.height && loaderDimensions.height - 80}
              />
            </ContentLoader>
          )}
          {!loading && (
            <VisXChart
              title={title}
              dataSeries={dataSeries}
              target={target}
              targetSentiment={targetSentiment}
              targetText={targetText}
              targetColor={targetColor}
              limit={limit}
              noPadding={noPadding}
              showLegend={showLegend}
              showDayOfWeek={showDayOfWeek}
              showDateRange={showDateRange}
              infoShowing={totalAndOrAverageText || customInfoString}
              type={type}
              onBarStackClick={onBarStackClick}
              tooltipNote={tooltipNote}
              renderTooltipNote={renderTooltipNote}
              fiscalYearStartMonth={fiscalYearStartMonth}
              defaultYScaleDomain={defaultYScaleDomain}
              showGlyphs={showGlyphs}
              onLineClick={onLineClick}
              onLineHover={onLineHover}
              xAxisMode={useTimeScale ? 'time' : 'band'}
              startX={startDate}
              endX={endDate}
            />
          )}
        </div>
      </div>
      {description && (
        <Dialog
          open={showDescription}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent dividers style={{ paddingTop: 20, paddingBottom: 20 }}>
            <DialogContentText style={{ color: palette.text.primary }}>
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDescription(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default Graph;
