import gql from 'graphql-tag';

import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Rfi}
  query GetCompanyRfis($companyId: String!, $mode: RfiModes!) {
    getCompanyRfis(input: { companyId: $companyId, mode: $mode }) {
      items {
        ...RfiBaseFields
      }
    }
  }
`;
