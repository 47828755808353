import React from 'react';
import DashboardReportTableRow from '../../dashboard/dashboard-report-table-row';
// eslint-disable-next-line import/no-cycle
import AccountList from './project-profitability-report-account-list';
import { asCurrency } from '../../dashboard/dashboard.utils';
import TransactionsSection from './project-profitability-report-transactions-section';

const AccountListItem = ({
  categoryType,
  accountName,
  accounts,
  subtotal,
  transactions,
}) => {
  let expandableBody = null;

  let accountsSection = null;
  if (accounts && accounts.length) {
    accountsSection = (
      <AccountList categoryType={categoryType} accountData={accounts} />
    );
  }

  let transactionSection = null;
  if (transactions && transactions.length) {
    transactionSection = (
      <TransactionsSection
        categoryType={categoryType}
        key={accountName}
        transactions={transactions}
      />
    );
  }

  if (accountsSection || transactionSection) {
    expandableBody = (
      <>
        {accountsSection}
        {transactionSection}
      </>
    );
  }

  return (
    <DashboardReportTableRow
      name={accountName}
      value={asCurrency(subtotal)}
      expandableBody={expandableBody}
    />
  );
};

export default AccountListItem;
