import gql from 'graphql-tag';

export default gql`
  mutation GenerateCompanyCheckoutSession(
    $companyId: String!
    $price: String!
    $count: Int
    $coupon: String
    $billingAddress: AddressInput
    $customEndpoints: CustomStripeEndpointsInput
  ) {
    generateCompanyCheckoutSession(
      input: {
        companyId: $companyId
        price: $price
        count: $count
        coupon: $coupon
        billingAddress: $billingAddress
        customEndpoints: $customEndpoints
      }
    ) {
      sessionId
    }
  }
`;
