import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  query ListMyConversations($userId: String!) {
    listMyConversations(userId: $userId) {
      items {
        __typename
        contentId
        jrnId
        title
        creatorId
        contentUrl
        description
        endDate
        privacyLevel
        inviteCode
        allowedToEdit
        allowedToAdd
        allowedToView
        usersEverOnJrn
        startDate
        synced
        labels
        latitude
        longitude
        phoneNumber
        phoneNumberActive
        type
        lastMessageSent
        # REMOVEME: remove this when "bugets" field is deprecated
        budgets {
          ...BudgetFields
        }
        fromTemplate
      }
    }
  }
`;
