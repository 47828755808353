import gql from 'graphql-tag';

export default gql`
  mutation UnarchiveCompanyProject($projectId: String!) {
    unarchiveCompanyProject(input: { projectId: $projectId }) {
      __typename
      status
      msg
    }
  }
`;
