import gql from 'graphql-tag';

export default gql`
  mutation DeleteContent($contentId: ID!, $jrnId: String!) {
    deleteContent(input: { contentId: $contentId, jrnId: $jrnId }) {
      __typename
      contentId
      jrnId
      synced
    }
  }
`;
