import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Report}
  query GetBillableHoursReportData(
    $companyId: String
    $reportPeriod: String
    $groupBy: String
    $timezone: String
    $customPeriodStartDate: String
    $customPeriodEndDate: String
    $userIds: [String]
    $customerIds: [String]
    $projectIds: [String]
  ) {
    getBillableHoursReportData(
      input: {
        companyId: $companyId
        reportPeriod: $reportPeriod
        groupBy: $groupBy
        timezone: $timezone
        customPeriodStartDate: $customPeriodStartDate
        customPeriodEndDate: $customPeriodEndDate
        userIds: $userIds
        customerIds: $customerIds
        projectIds: $projectIds
      }
    ) {
      items {
        ...ReportFields
      }
    }
  }
`;
