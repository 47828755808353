import React, { useState } from 'react';
import { Grid, Typography, Button, TextField } from '@material-ui/core';
import LevelModal from '../Modal/level-modal';
import { isValidEmail } from '../../common/validators';

const InviteCrewMember = ({ handleClose, sendInvitation }) => {
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = () => {
    if (!isValidEmail(email)) {
      setError('Please enter a valid email.');
      return;
    }

    sendInvitation(email, 'crew');

    handleClose();
  };

  const handleEmailChange = event => {
    if (error) {
      setError('');
    }

    setEmail(event.target.value);
  };

  return (
    <LevelModal open handleClose={handleClose} maxWidth="sm">
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <Typography variant="h3" gutterBottom align="center" color="primary">
            Who would you like to invite?
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        spacing={2}
        justifyContent="center"
        style={{ padding: 20 }}
      >
        <Grid item>
          <Typography variant="body1">
            The invited crew member will receive an email with a link to join
            your company on Level.
          </Typography>
        </Grid>
        <Grid item container justifyContent="center">
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="email"
                style={{ width: '100%' }}
                label="Email"
                onChange={handleEmailChange}
                value={email}
              />
            </Grid>
            <Grid item xs={12}>
              {error && (
                <Typography style={{ color: 'red' }}>{error}</Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            style={{ marginTop: 20 }}
          >
            <Button
              onClick={() => {
                handleClose();
              }}
              style={{ marginRight: 20 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleSubmit();
              }}
              disabled={!email}
            >
              Invite Crew Member
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </LevelModal>
  );
};

export default InviteCrewMember;
