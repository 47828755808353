import { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-apollo-hooks';
import _ from 'lodash';

/*
  Initial state of useQuery
  {
    data: undefined
    error: undefined
    errors: undefined // ignore
    fetchMore: ƒ() // ignore
    loading: true
    networkStatus: 1 // ignore
    partial: true // ignore
    refetch: ƒ()
    stale: undefined // ignore
    startPolling: ƒ() // ignore
    stopPolling: ƒ() // ignore
    updateQuery: ƒ() // ignore
  }
 */

const DEFAULT_BATCH_SIZE = 1000;

const useChunkedQuery = (
  query,
  { variables, fetchPolicy = 'cache-and-network', accessor, skip }
) => {
  // Take same inputs as useQuery
  const baseQuery = useQuery(query, {
    variables: {
      first: DEFAULT_BATCH_SIZE,
      after: null,
      ...variables,
    },
    fetchPolicy,
    skip,
  });

  const [nextToken, setNextToken] = useState(undefined);
  const [hookResult, setHookResult] = useState({
    data: baseQuery.data,
    loading: baseQuery.loading,
    error: baseQuery.error,
    refetch: baseQuery.refetch,
  });

  const loadingRef = useRef(baseQuery.loading);
  const nextTokenUnsetRef = useRef(true);

  // Update the existing query data
  function updateQuery(prev, { fetchMoreResult }) {
    if (!fetchMoreResult) {
      return prev;
    }

    const itemsToReturn = [...prev[accessor].items];

    const fetchedItems = fetchMoreResult[accessor].items;

    if (fetchedItems) {
      _.forEach(fetchedItems, item => {
        const existingIndex = _.findIndex(itemsToReturn, {
          contentId: item.contentId,
        });
        if (existingIndex === -1) {
          itemsToReturn.push(item);
        }
      });
    }

    return {
      [accessor]: {
        ...fetchMoreResult[accessor],
        items: itemsToReturn,
        nextToken: fetchMoreResult[accessor].nextToken || null,
      },
    };
  }

  useEffect(() => {
    const nextTokenToSet = _.get(
      baseQuery.data,
      `${accessor}.nextToken`,
      undefined
    );
    if (nextTokenUnsetRef.current || nextToken !== nextTokenToSet) {
      if (nextTokenUnsetRef.current) {
        nextTokenUnsetRef.current = false;
      }
      setNextToken(nextTokenToSet);
    }

    if (baseQuery.loading && !loadingRef.current) {
      // If the base query starts loading something, make sure the loading state is set
      loadingRef.current = true;

      setHookResult(lastResult => {
        return { ...lastResult, loading: baseQuery.loading };
      });
    }

    if (!baseQuery.loading && !nextTokenToSet) {
      // If not loading and not waiting on more data, set the data
      loadingRef.current = false;

      setHookResult({
        data: baseQuery.data,
        loading: loadingRef.current,
        error: baseQuery.error,
        refetch: baseQuery.refetch,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseQuery]);

  useEffect(() => {
    if (nextToken) {
      // Still loading...
      const loadMore = async ({ after }) => {
        try {
          await baseQuery.fetchMore({
            variables: {
              first: DEFAULT_BATCH_SIZE,
              ...variables,
              after,
            },
            updateQuery,
          });
        } catch (err) {
          // This is mostly just in case the component unmounts
          // eslint-disable-next-line no-console
          console.warn('error on chunked query loadMore ~ err:', err);
        }
      };

      loadMore({ after: nextToken });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextToken]);

  // Return same result as useQuery
  return hookResult;
};

export default useChunkedQuery;
