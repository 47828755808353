import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import LevelLogo from '../../components/level-logo/level-logo';
import LevelSupportEmailAddressLink from '../../components/level-support-email-address-link/level-support-email-address-link';

const useStyles = makeStyles(theme => ({
  explainerText: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: '#e0f3ff',
    borderRadius: 10,
  },
  inputField: {
    marginBottom: 20,
  },
}));

const CheckoutSuccess = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Grid item style={{ marginTop: 60 }}>
        <LevelLogo />
      </Grid>
      <Grid item>
        <Paper
          style={{
            display: 'flex',
            width: 500,
            minHeight: 350,
            padding: 30,
            margin: 30,
          }}
        >
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Typography variant="h3" align="center" color="primary">
                Thanks for joining Level!
              </Typography>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Typography variant="subtitle1" className={classes.explainerText}>
                We are glad you are here! An e-mail was sent to the billing
                email address with a link to create your Level company and user
                account.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                If you have any questions, please contact our support team
                at&nbsp;
                <LevelSupportEmailAddressLink />.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CheckoutSuccess;
