import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo-hooks';
import { useHistory, Redirect } from 'react-router-dom';
import { Button, Divider, Drawer, Grid, Typography } from '@material-ui/core';
import { ChevronLeft as BackIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import _ from 'lodash';

import { HEADER_HEIGHT, SIDEBAR_WIDTH } from '../../../../config/appDefaults';
import {
  checkAndValidateAuthSession,
  determineManagingCompanyInfo,
  isDefaultCompanyName,
  isDefaultUsername,
} from '../../../../helpers';
import { getAllowedPages } from '../../../../route-builder';
import CamHelper from './cam-helper';
import CompanyDisplay from './company-display';
import SidebarNav from './sidebar-nav';
import UserDisplay from './user-display';

import GetCompanyById from '../../../../graphql/queries/get-company-by-id';
import GetUserInfo from '../../../../graphql/queries/GetUserInfo';
import FirmDisplay from './firm-display';
import ClientDisplay from './client-display';
import palette from '../../../../theme/palette';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: SIDEBAR_WIDTH,
    [theme.breakpoints.up('xs')]: {
      marginTop: HEADER_HEIGHT,
      height: `calc(100% - ${HEADER_HEIGHT}px)`,
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(1),
  },
}));

const Sidebar = ({
  open,
  variant,
  onClose,
  // Redux store
  dispatch,
  currentAuth,
  userInfo,
  firmInfo,
  managingCompanyInfo,
}) => {
  const classes = useStyles();
  const [scopedCompanyId, setScopedCompanyId] = useState(null);
  const history = useHistory();

  // Get user info
  const { data: userInfoData, error: latestUserInfoError } = useQuery(
    GetUserInfo,
    {
      variables: {
        userId: 'willBePulledFromCognitoSubContentInResolver',
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const latestUserInfo = _.get(userInfoData, 'getMyUserInfo', null);
  const userFromFirm = !!latestUserInfo?.managingFirmId;

  // Get firm info
  const { data: latestFirmInfoData, error: latestFirmInfoError } = useQuery(
    GetCompanyById,
    {
      skip: !userFromFirm,
      variables: {
        companyId: latestUserInfo?.managingFirmId,
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const latestFirmInfo = _.get(latestFirmInfoData, 'getCompanyById', null);

  // Get default company info
  const {
    data: latestScopedCompanyInfoData,
    error: latestScopedCompanyInfoError,
  } = useQuery(GetCompanyById, {
    skip: !scopedCompanyId,
    variables: {
      companyId: scopedCompanyId,
    },
    fetchPolicy: 'cache-and-network',
  });
  const latestCompanyInfo = _.get(
    latestScopedCompanyInfoData,
    'getCompanyById',
    null
  );

  const isCopilot =
    !!userInfo?.managingFirmId || !!managingCompanyInfo?.isCopilot;

  const [completedOnBoarding, setCompletedOnBoarding] = useState(true);

  useEffect(() => {
    if (latestUserInfo) {
      const isFirmUser = !!latestUserInfo.managingFirmId;
      if (!isFirmUser) {
        // If the user does not have a managingFirmId, set the default companyId
        const [defaultCompanyIdToSet = null] = latestUserInfo.companies;

        if (
          defaultCompanyIdToSet &&
          scopedCompanyId !== defaultCompanyIdToSet
        ) {
          setScopedCompanyId(defaultCompanyIdToSet);
        }
      } else if (managingCompanyInfo) {
        // Set scoped company ID based on currently set managing company ID
        setScopedCompanyId(managingCompanyInfo.companyId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestUserInfo, managingCompanyInfo]);

  useEffect(() => {
    // Keep userInfo state up-to-date
    if (currentAuth.isLoggedIn && latestUserInfo) {
      let hasUpdates = false;

      _.keys(latestUserInfo).forEach(key => {
        if (!_.isEqual(latestUserInfo[key], userInfo[key])) {
          hasUpdates = true;
        }
      });

      if (hasUpdates) {
        dispatch({
          type: 'USER_INFO_UPDATE',
          payload: { ...latestUserInfo },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, latestUserInfo]);

  useEffect(() => {
    // Keep userInfo state up-to-date
    if (currentAuth.isLoggedIn && latestFirmInfo) {
      let hasUpdates = false;

      if (!firmInfo) {
        hasUpdates = true;
      } else {
        _.keys(latestFirmInfo).forEach(key => {
          if (!_.isEqual(latestFirmInfo[key], firmInfo[key])) {
            hasUpdates = true;
          }
        });
      }

      if (hasUpdates) {
        dispatch({
          type: 'SET_FIRM_INFO',
          payload: { ...latestFirmInfo },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmInfo, latestFirmInfo]);

  useEffect(() => {
    // Keep managingCompanyInfo state up-to-date
    if (
      currentAuth.isLoggedIn &&
      managingCompanyInfo &&
      latestUserInfo &&
      latestCompanyInfo
    ) {
      if (latestCompanyInfo.companyId !== managingCompanyInfo.companyId) {
        // lastest company info will be requeried with the same companyId
        return;
      }
      const latestManagingCompanyInfo = determineManagingCompanyInfo({
        companyInfo: latestCompanyInfo,
        userInfo: latestUserInfo,
      });

      if (!_.isEqual(latestManagingCompanyInfo, managingCompanyInfo)) {
        dispatch({
          type: 'SET_MANAGING_COMPANY_INFO',
          payload: { ...latestManagingCompanyInfo },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestCompanyInfo, latestUserInfo]);

  useEffect(() => {
    // check and validate auth session immediately
    checkAndValidateAuthSession();

    // run validation every 30 seconds thereafter
    const interval = setInterval(() => {
      checkAndValidateAuthSession();
    }, 30000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (managingCompanyInfo) {
      if (isDefaultCompanyName(managingCompanyInfo?.managingCompanyName)) {
        setCompletedOnBoarding(false);
      }

      if (managingCompanyInfo.hasProjectManagementProduct) {
        if (userInfo?.email && isDefaultUsername(userInfo.username)) {
          setCompletedOnBoarding(false);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, managingCompanyInfo]);

  if (!completedOnBoarding) {
    return <Redirect to="/onboarding" />;
  }

  const pages = getAllowedPages({ userInfo, managingCompanyInfo });
  const showDivider = isCopilot || managingCompanyInfo;

  const showFirmView = userInfo && firmInfo && !managingCompanyInfo;
  const showClientView = userInfo && firmInfo && managingCompanyInfo;
  const showCompanyView = userInfo && !firmInfo && managingCompanyInfo;
  // const isReady = showFirmView || showClientView || showCompanyView;

  const goBackToFirm = () => {
    history.replace('/firm');
  };

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      {showClientView && (
        <Grid item style={{ backgroundColor: palette.brandColorLightGrey }}>
          <Button
            variant="text"
            onClick={goBackToFirm}
            fullWidth
            style={{ paddingTop: 24, paddingBottom: 24 }}
            startIcon={<BackIcon style={{ fontSize: '200%' }} />}
          >
            <Typography variant="h5">Back to Firm</Typography>
          </Button>
        </Grid>
      )}
      <div className={classes.root}>
        <Grid item flex={0} style={{ paddingBottom: 8 }}>
          {showFirmView && <FirmDisplay errorOccurred={latestFirmInfoError} />}
          {showClientView && (
            <ClientDisplay errorOccurred={latestScopedCompanyInfoError} />
          )}
          {showCompanyView && (
            <UserDisplay errorOccurred={latestUserInfoError} />
          )}
        </Grid>
        <Divider />
        <Grid item flex={1} style={{ flexGrow: 1 }}>
          <SidebarNav pages={pages} />
        </Grid>
        {isCopilot && <CamHelper />}
        {showDivider && <Divider />}
        {showCompanyView && managingCompanyInfo && (
          <Grid item flex={0} style={{ paddingTop: 8 }}>
            <CompanyDisplay />
          </Grid>
        )}
      </div>
    </Drawer>
  );
};

function mapStateToProps(state) {
  return {
    currentAuth: state.currentAuth,
    userInfo: state.userInfo,
    firmInfo: state.appState.firmInfo || null,
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
  };
}

export default connect(mapStateToProps)(Sidebar);
