import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  ${Fragment.Stat}
  query GetAllContentByJrnId($jrnId: String!) {
    getAllContentByJrnId(jrnId: $jrnId) {
      items {
        allowedToEdit
        allowedToAdd
        allowedToView
        usersEverOnJrn
        contentId
        jrnId
        creatorId
        type
        subtype
        permissionsFrom
        requireAdmin
        completionType
        completionInfo {
          instructions
          completedById
          completedByDate
          textResponse
          mediaInfo {
            uri
            aspectRatio
          }
        }
        contentUrl
        date
        description
        aspectRatio
        labels
        address
        latitude
        longitude
        isPublic
        synced
        comments {
          items {
            content
            commentId
            dateCreated
            userId
          }
        }
        loves {
          items {
            loveId
            userId
            contentId
          }
        }
        title
        endDate
        privacyLevel
        inviteCode
        startDate
        addedBy
        assignedTo
        contentStatus
        priority
        amount {
          value
        }
        recurrence {
          rangeId
          rangeStartDate
          rangeEndDate
          daysOfTheWeek
        }
        timetrackingAnswers {
          questionId
          answer
        }
        fromTemplate
        timetrackingType
        workflowStage
        workflowStageReason
        # REMOVEME: remove this when "budgets" field is deprecated
        budgets {
          ...BudgetFields
        }
        billable
        globalExpenseId
        globalExpense {
          contentId
          type
          jrnId
          vendorId
          title
          description
          contentUrl
          aspectRatio
          contentStatus
          documentNumber
          poNumber
          amount {
            value
          }
          balance {
            value
          }
          paymentTerms
          date
          startDate
          endDate
          creatorId
          labels
          rfiIds
        }
        stats {
          ...StatFields
        }
        customerId
        includeSubsInStats
        excludeFromStats
        rfiIds
        useTimetracking
        useTimetrackingQuestions
        timetrackingQuestions
        timetrackingQuestionsCheckout
      }
    }
  }
`;
