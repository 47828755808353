import amplitude from 'amplitude-js';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { differenceInDays } from 'date-fns';
import _ from 'lodash';
import { round } from 'mathjs';
import moment from 'moment';
import numeral from 'numeral';
import qs from 'qs';
import userflow from 'userflow.js';
import uuid from 'uuid';

import {
  CONTENT_TYPE,
  CREW_PAY_TYPES,
  RECEIVING_TYPE_ACCOUNTS,
  SPENDING_TYPE_ACCOUNTS,
} from '../config/appDefaults';
import store from '../store';
import {
  DAYS_TO_PAYMENT_TERMS,
  PAYMENT_TERMS,
} from '../views/add-to-project/add-content-form/add-content-form.constants';
import determineUserRole from './determine-user-role.helper';

export { default as chartjs } from './chartjs';
export { default as determineManagingCompanyInfo } from './determine-managing-company-info.helper';
export { default as getInitials } from './getInitials';
export { default as monetaryRender } from './monetary-render';
export { default as generateDataFromOcr } from './ocr/generate-data-from-ocr';
export { default as formatNumberAsMoneyDollar } from './format-number-as-money-dollar';
export { default as getStripePricingDetails } from './get-stripe-pricing-details';
export { determineUserRole };

export const generateDefaultUsername = () => {
  const newUsername = `Level${moment().format(
    'YYYYMMDDHHmm_'
  )}${uuid().substring(0, 6)}`;

  return newUsername;
};

export const isDefaultCompanyName = companyName => {
  const defaultCompanyNameRe = /^Level[0-9]{12}_[a-z0-9A-Z]{6}_Company$/;
  return defaultCompanyNameRe.test(companyName);
};

export const isDefaultUsername = username => {
  const defaultUsernameRe = /^Level[0-9]{12}_[a-z0-9A-Z]{6}$/;
  return defaultUsernameRe.test(username);
};

export const runAnalytics = (ampCall, options) => {
  const { appState } = store.getState();

  const isActiveOrLogCall = ampCall === 'Log' || ampCall === 'Active User';
  const ampData = {
    ...options,
    managingCompanyId:
      (!isActiveOrLogCall &&
        appState.managingCompanyInfo &&
        appState.managingCompanyInfo.managingCompanyId) ||
      null,
  };

  const isCheckthelevel = _.endsWith(options.email, '@checkthelevel.com');
  const isDev =
    process && process.env && process.env.NODE_ENV === 'development';
  if (isDev || isCheckthelevel) {
    return;
  }

  try {
    amplitude.logEvent(ampCall, ampData);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('err: ', err);
  }
};

/**
 * Signs out the current user and performs necessary cleanup.
 *
 * This function signs out the user using `Auth.signOut()`, resets the userflow if it is identified,
 * clears the session storage, logs the sign-out event for analytics, and dispatches a sign-out success action to the store.
 *
 * @param {{ userInfo?: Object }} params - Optional parameters.
 * @returns {Promise<void>} A promise that resolves when the sign-out process is complete.
 */
export const performSignOut = async ({ userInfo } = {}) => {
  await Auth.signOut();

  if (userflow.isIdentified()) {
    userflow.reset();
  }

  sessionStorage.clear();

  if (userInfo) {
    const options = {
      userId: userInfo?.userId,
      username: userInfo?.username,
      type: 'Log Out',
      email: userInfo?.email,
    };

    runAnalytics('Log', options);
  }

  store.dispatch({ type: 'SIGNOUT_SUCCEEDED' });
};

const isInternetConnected = async () => {
  try {
    await axios.get('https://www.cloudflare.com/cdn-cgi/trace');
    return true;
  } catch (err) {
    return false;
  }
};

/**
 * Checks and validates the current user session.
 *
 * This function performs the following steps:
 * 1. Checks if the user is logged in by inspecting the current authentication state.
 * 2. If the user is logged in, it attempts to retrieve the current user information from Cognito.
 * 3. If the user information is empty, it checks for an internet connection.
 * 4. If there is an internet connection, it attempts to retrieve the user information from Cognito again.
 * 5. If the second attempt to retrieve user information is also empty, it assumes the user session is invalid
 *    (most likely due to signing out of all devices) and performs a sign-out.
 *
 * @returns {Promise<void>} A promise that resolves when the session check and validation is complete.
 */
export const checkAndValidateAuthSession = async () => {
  if (!store.getState().currentAuth?.isLoggedIn) {
    return;
  }

  try {
    // check user session from cognito
    const firstCheckUserInfo = await Auth.currentUserInfo();

    // NOTE: Auth.currentUserInfo() returns an empty object if the user is not signed in,
    // the session has expired, or there is no internet connection.
    if (_.isEmpty(firstCheckUserInfo)) {
      // check internet connection
      const isConnected = await isInternetConnected();

      if (isConnected) {
        // it is not an internet connection issue
        // then check the session again from cognito
        const secondCheckUserInfo = await Auth.currentUserInfo();

        // if the second check is empty, then we can assume the user session is invalid
        // (most likely due to signing out of all devices)
        if (_.isEmpty(secondCheckUserInfo)) {
          // no need to run analytics here
          await performSignOut();
        }
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('checkAndValidateAuthSession err: ', err);
  }
};

export const setAnalyticsUser = options => {
  const isCheckthelevel = _.endsWith(options.email, '@checkthelevel.com');
  const isDev =
    process && process.env && process.env.NODE_ENV === 'development';
  if (isDev || isCheckthelevel) {
    return;
  }
  try {
    amplitude.setUserId(options.userId);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('err: ', err);
  }
};

export const validateUrl = str => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)' + // require http(s)
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  const toReturn = !!pattern.test(str);
  return toReturn;
};

// <SELECTION>
export const saveSelection = () => {
  if (window.getSelection) {
    const sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      return sel.getRangeAt(0);
    }
  } else if (document.selection && document.selection.createRange) {
    return document.selection.createRange();
  }
  return null;
};

export const restoreSelection = range => {
  if (range) {
    if (window.getSelection) {
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (document.selection && range.select) {
      range.select();
    }
  }
};

/**
 * How to use:
 * You have a text editor, or a textarea, or any text element, then you want to create a widget
 * that adds a link, or anything that causes a new element to get focus so your text element looses it and
 * selection is lost, then you may want to restore that selection after.
 */

// const selectionRange = saveSelection();

// then, you loose focus

/**
 * You get what you wanted and you want your selection back there
 */
// restoreSelection(selectionRange);

// Credits: Tim Down's SO answer http://stackoverflow.com/a/3316483/1470564
// </SELECTION>

export const googlifyAddress = (addressText, mapAction = 'search') => {
  // https://developers.google.com/maps/documentation/urls/guide
  if (!addressText) return null;
  const actions = {
    search: {
      urlAction: 'search',
      param: 'query',
    },
    directions: {
      urlAction: 'dir',
      param: 'destination',
    },
  };
  const addressTextAsQuery = qs.stringify(
    {
      [actions[mapAction].param]: addressText,
    },
    { format: 'RFC1738' }
  );
  const address = `https://www.google.com/maps/${actions[mapAction].urlAction}/?api=1&${addressTextAsQuery}`;
  return address;
};

export const buildLabels = items => {
  if (_.isEmpty(items)) {
    return [];
  }
  const allLabels = [];
  const labelMap = {};
  items.forEach(item => {
    _.forEach(item.labels, label => {
      if (!labelMap[label]) {
        allLabels.push(label);
        labelMap[label] = true;
      }
    });
  });
  allLabels.sort();
  return allLabels;
};

export const calculateHourlyRate = ({
  laborBurdenPercentage = 0,
  payRate,
  payType,
  vacaAccrualRate = 0,
}) => {
  if (payType === CREW_PAY_TYPES.VARIABLE_HOURLY) {
    const totalLaborBurden =
      (Number(laborBurdenPercentage) + Number(vacaAccrualRate)) / 100;
    return Number(payRate) * (1 + totalLaborBurden);
  }
  return 0;
};

export const addUpBudgetItems = (
  budgetItems,
  decimalPlaces = 2,
  extraAmountToAdd = []
) => {
  let totalInt = 0;
  if ((!budgetItems || !budgetItems.length) && !extraAmountToAdd.length) {
    return 0;
  }

  if (budgetItems) {
    budgetItems.forEach(item => {
      if (
        item.amount &&
        item.amount.value &&
        typeof item.amount.value === 'number'
      ) {
        let amountToUse = item.amount.value;
        if (item.contentStatus && item.contentStatus === 'refund') {
          amountToUse = item.amount.value * -1;
        }
        totalInt += amountToUse * 100; // float to int to sum up
      }
    });
  }

  if (extraAmountToAdd && extraAmountToAdd.length) {
    extraAmountToAdd.forEach(item => {
      if (item) {
        totalInt += parseFloat(item, 10) * 100;
      }
    });
  }

  return parseFloat((totalInt / 100).toFixed(decimalPlaces));
};

export const toggleItemInArray = (existingArray, itemtoToggle) => {
  if (
    typeof existingArray === 'undefined' ||
    typeof itemtoToggle === 'undefined'
  ) {
    return [];
  }
  const existingIndex = existingArray.indexOf(itemtoToggle);
  const dowCopy = [...existingArray];
  if (existingIndex > -1) {
    dowCopy.splice(existingIndex, 1);
    return dowCopy;
  }
  return [...dowCopy, itemtoToggle];
};

export const locationify = (latitude, longitude) => {
  return latitude && longitude
    ? `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
    : 'No location';
};

export const removeAttributesFromObject = (item, listOfAttributesToRemove) => {
  const copy = { ...item };
  let allAttrs = [];
  if (typeof listOfAttributesToRemove === 'string') {
    allAttrs.push(listOfAttributesToRemove);
  } else if (Array.isArray(listOfAttributesToRemove)) {
    allAttrs = listOfAttributesToRemove;
  } else {
    return null;
  }
  allAttrs.forEach(attr => {
    delete copy[attr];
  });
  return copy;
};

export const daysAfterEpoch = passedDate => {
  const daysSinceEpoch = moment(passedDate).diff(new Date(0), 'days');
  return daysSinceEpoch;
};

export const intDaysAfterEpochAsIso = valueAsInt => {
  const numToUse = typeof valueAsInt !== 'number' ? 0 : valueAsInt;
  return new Date(
    1000 * 60 * 60 * 24 * numToUse // days after epoch in milliseconds
  ).toISOString();
};

export const utcADate = (passedDate, format = 'h:mma') => {
  return moment(passedDate)
    .utc()
    .format(format);
};

export const twoDatesWithinInterval = ({
  date1,
  date2,
  intervalAmount,
  intervalType,
}) => {
  const momentDate1 = moment(date1);
  const momentDate2 = moment(date2);
  const diff = momentDate2.diff(momentDate1, intervalType || 'seconds');
  return diff <= intervalAmount;
};

export const getUserFullName = user => {
  const names = [];
  if (user.firstName) {
    names.push(user.firstName.trim());
  }
  if (user.lastName) {
    names.push(user.lastName.trim());
  }

  return names.join(' ');
};

export const getFullUserString = user => {
  let userString = user.username;

  const fullName = getUserFullName(user);

  if (fullName) {
    userString += ` (${fullName})`;
  }

  return userString;
};

export const getCompanyCrewBreakdown = (company, companyCrew) => {
  const breakdown = {
    owners: [],
    levelAccounting: [],
    nonLevelBookkeepers: [],
    adminsWithoutOtherRoles: [],
    users: [],
    availableAdminSeats: 0,
  };

  if (_.isEmpty(company) || _.isEmpty(companyCrew)) {
    return breakdown;
  }

  const allAdmins = _.filter(companyCrew, ({ userId }) => {
    return _.includes(company.admins, userId);
  });

  _.forEach(allAdmins, admin => {
    if (_.includes(company.owners, admin.userId)) {
      breakdown.owners.push(admin);
    } else if (admin.type === 'bookkeeper') {
      if (admin.userType === 'level') {
        breakdown.levelAccounting.push(admin);
      } else {
        breakdown.nonLevelBookkeepers.push(admin);
      }
    } else {
      breakdown.adminsWithoutOtherRoles.push(admin);
    }
  });

  breakdown.users = _.filter(companyCrew, ({ userId }) => {
    return _.includes(company.users, userId);
  });

  const availableAdminSeats =
    (company.adminSeats || 0) -
    (allAdmins.length - breakdown.levelAccounting.length);

  breakdown.availableAdminSeats =
    availableAdminSeats >= 0 ? availableAdminSeats : 0;

  return breakdown;
};

export const numberWithCommas = x => {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const monetizeAmount = passedAmount => {
  const parsedNumber = Number.parseFloat(passedAmount);
  const isNegative = parsedNumber < 0;
  let toReturn = parsedNumber;
  // if negative * -1 to flip it
  if (isNegative) {
    toReturn *= -1;
  }
  // fix to 2
  toReturn = toReturn.toFixed(2);
  // add commas
  toReturn = numberWithCommas(toReturn);
  // add the dollar sign
  toReturn = `$${toReturn}`;
  // add the negative sign if needed
  if (isNegative) {
    toReturn = `-${toReturn}`;
  }
  return toReturn;
};

export const percentify = ({
  value,
  decimalPlaces = 0,
  alreadyTimes100 = false,
}) => {
  const toReturn = round(value * (alreadyTimes100 ? 1 : 100), decimalPlaces);
  return `${toReturn}%`;
};

export const percentRender = ({ value, decimalPlaces, alreadyTimes100 }) => {
  if (typeof value !== 'number') {
    return 'n/a';
  }
  return percentify({
    value,
    decimalPlaces: typeof decimalPlaces === 'number' ? decimalPlaces : 2,
    alreadyTimes100,
  });
};

export const fixedValueRender = ({ value }) => {
  if (typeof value !== 'number') {
    return 'n/a';
  }
  return value.toFixed(2);
};

export const nonProdConsoleLog = (...all) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(...all);
  }
};

export const isoStringToReadable = (
  passedDate,
  desiredFormat = 'MMM DD, YYYY'
) => {
  return moment(passedDate).format(desiredFormat);
};

export const handleFormattedNumberInput = (
  passedValue,
  numberFormat,
  showZeroAsEmpty = false
) => {
  const passedValueAsNumber = numeral(passedValue).value(); // Invalid or empty number strings returned as null

  let valueAsString = '';
  let valueAsNumber = null;
  if (_.isNumber(passedValueAsNumber)) {
    const expectsADecimal = numberFormat.includes('.');
    const formatTrailingZeros =
      expectsADecimal && numberFormat.split('.')[1].length;

    const valueIsADecimal = passedValue.includes('.');

    if (expectsADecimal) {
      // we need to allow them to TYPE in a dot but only format it once they type a number after as well
      // we dont want to force 0's on the end of numbers before the user is done typing
      // so if it matches a format so far, dont format it
      const valSplit = passedValue.split('.');
      const valAfterDot = valSplit && valSplit[1];
      const valAfterDotLength = valAfterDot && valAfterDot.length;

      if (!valueIsADecimal) {
        // CASE: So far typed "23"
        // if the value doesn't have a dot, don't format it
        valueAsString = passedValue;
        valueAsNumber = passedValueAsNumber;
      } else {
        if (!valAfterDot) {
          // CASE: So far typed "23."
          // if the value has a dot but there's nothing after it, dont change the value, but pass back a split formatted value
          valueAsString = passedValue;
          valueAsNumber = passedValueAsNumber; // numeral value returns "23." as 23
        }
        if (valAfterDotLength <= formatTrailingZeros) {
          // CASE: So far typed "23.5" for "0,0.0" or "0,0.00", or "23.57" for "0,0.00"
          // if the value has a dot and the length of the value after it is less than the length of the format after dot, dont format it
          valueAsString = passedValue;
          valueAsNumber = passedValueAsNumber;
        }
        if (valAfterDotLength > formatTrailingZeros) {
          // CASE: So far typed "23.56" or "0,0.0", or "23.576" for "0,0.00"
          // if the value has a dot and the length of the value after it is more than the length of the format after dot, format it
          valueAsString = numeral(passedValueAsNumber).format(numberFormat);
          valueAsNumber = numeral(valueAsString).value();
        }
      }
    } else {
      valueAsString = numeral(passedValueAsNumber).format(numberFormat);
      valueAsNumber = numeral(valueAsString).value();
    }
  }

  if (showZeroAsEmpty && valueAsNumber === 0) {
    valueAsString = '';
  }

  return {
    valueAsNumber,
    valueAsString,
  };
};

export const orderCustomers = data => {
  return _.orderBy(
    data,
    item => {
      const toUse = item.lastName || item.firstName || item.companyName || '';
      return toUse.toLowerCase();
    },
    ['asc']
  );
};

export const revokeUrls = files => {
  files.forEach(file => {
    if (file && file.uri) {
      URL.revokeObjectURL(file.uri);
    }
  });
};

export const ensureNum = value => {
  if (typeof value === 'number') {
    return value;
  }
  return 0;
};

export const downloadBlob = (blob, fileName = 'grid-data.csv') => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.position = 'absolute';
  link.style.visibility = 'hidden';

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export const mediaToContentUrl = media => {
  const mediaItemToContentUrlItem = mediaItem => {
    return {
      uri: mediaItem.uri,
      aspectRatio: mediaItem.aspectRatio,
      type: mediaItem.type,
    };
  };
  const contentUrlEntries = [];
  media.forEach(mediaItem => {
    const temp = mediaItemToContentUrlItem(mediaItem);
    contentUrlEntries.push(temp);
  });
  return contentUrlEntries;
};

export const contentUrlToMedia = contentUrl => {
  if (!contentUrl) return [];
  let mediaObjects;
  try {
    const parsed = JSON.parse(contentUrl) || [];
    // console.log('contentUrlToMedia imageObjects: ', mediaObjects);
    // if mediaObjects ends up as a string, then set the uri in an array
    if (typeof parsed === 'string') {
      // this happens if we're looking at a financial type since it has just a string for the image
      mediaObjects = [{ uri: parsed }];
    } else {
      mediaObjects = parsed;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('contentUrlToMedia e: ', e);
    // Check if the contentUrl is a single cloudinary url
    if (contentUrl.includes('cloudinary')) {
      mediaObjects = [{ uri: contentUrl }];
    } else {
      mediaObjects = [];
    }
  }
  return mediaObjects;
};

export const makeMoney = amount => {
  return {
    value: amount,
  };
};

export const prepRfisForSaving = ({ rfisToSave, companyId }) => {
  return rfisToSave.map(rfi => {
    const toSave = {
      ...rfi,
      managingCompanyId: companyId,
    };
    delete toSave.local;
    // setup txnReceived & txnSpent to be proper Money types
    if (!toSave.txnReceived?.value) {
      toSave.txnReceived = toSave.txnReceived
        ? makeMoney(toSave.txnReceived)
        : null;
    }
    if (!toSave.txnSpent?.value) {
      toSave.txnSpent = toSave.txnSpent ? makeMoney(toSave.txnSpent) : null;
    }
    if (toSave.txnDate) {
      toSave.txnDate = moment(toSave.txnDate).toISOString();
    }
    if (toSave.media && toSave.media.length > 0) {
      toSave.media = toSave.media.map(mediaItem => {
        const cleanMediaItem = { ...mediaItem };
        delete cleanMediaItem.__typename;
        return cleanMediaItem;
      });
    }
    return toSave;
  });
};

export const formatNumberAsMoney = value => {
  return numeral(value).format('0.00');
};

export const localFileToBase64 = ({ file, withoutHeader }) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (withoutHeader && reader.result) {
        resolve(reader.result.split(',')[1]);
      }
      resolve(reader.result);
    };
    reader.onerror = error => reject(error);
  });
};

export const browserBasedTimezoneAutoSelect = () => {
  const { timeZone } = window.Intl.DateTimeFormat().resolvedOptions();
  return timeZone;
};

export const removeAttribute = (obj, attr) => {
  // check if the input is an array
  if (Array.isArray(obj)) {
    // if it is, map over the array and call the function recursively
    // to remove the attr attribute from each element
    return obj.map(childObj => removeAttribute(childObj, attr));
  }

  // create a new object that will store the result
  const result = {};

  // iterate over the keys in the input object
  Object.keys(obj).forEach(key => {
    // check if the current key is attr
    if (key !== attr) {
      // if not, check if the value is an object or an array
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // if it is, call the function recursively to remove the attr
        // attribute from that object or array
        result[key] = removeAttribute(obj[key], attr);
      } else {
        // otherwise, just set the key-value pair on the result object
        result[key] = obj[key];
      }
    }
  });

  // return the result object
  return result;
};

export const renderTwoDatesAsARange = (date1, date2) => {
  if (date1 === date2) {
    return moment(date1, 'YYYY-MM-DD').format('MMMM D, YYYY');
  }
  // if the months are the same then just show the day range
  if (
    moment(date1, 'YYYY-MM-DD').month() ===
      moment(date2, 'YYYY-MM-DD').month() &&
    moment(date1, 'YYYY-MM-DD').year() === moment(date2, 'YYYY-MM-DD').year()
  ) {
    return `${moment(date1, 'YYYY-MM-DD').format('MMMM D')} - ${moment(
      date2,
      'YYYY-MM-DD'
    ).format('D, YYYY')}`;
  }
  // if the months are different but the years are the same then show the month and year
  if (
    moment(date1, 'YYYY-MM-DD').month() !==
      moment(date2, 'YYYY-MM-DD').month() &&
    moment(date1, 'YYYY-MM-DD').year() === moment(date2, 'YYYY-MM-DD').year()
  ) {
    return `${moment(date1, 'YYYY-MM-DD').format('MMMM D')} - ${moment(
      date2,
      'YYYY-MM-DD'
    ).format('MMMM D, YYYY')}`;
  }

  // otherwise show the full date range
  return `${moment(date1, 'YYYY-MM-DD').format('MMMM D, YYYY')} - ${moment(
    date2,
    'YYYY-MM-DD'
  ).format('MMMM D, YYYY')}`;
};

export const isItSpent = ({ accountType, isPositive, fromContentType }) => {
  const RECEIVING_CONTENT_TYPES = [CONTENT_TYPE.PAYMENT, CONTENT_TYPE.INVOICE];
  const SPENDING_CONTENT_TYPES = [
    CONTENT_TYPE.RECEIPT,
    CONTENT_TYPE.BILL,
    // CONTENT_TYPE.BILL_PAYMENT,
  ];
  let isSpent = false;
  const isReceivingAccountType = RECEIVING_TYPE_ACCOUNTS.includes(accountType);
  const isSpendingAccountType = SPENDING_TYPE_ACCOUNTS.includes(accountType);

  const fromSpendingContentType = SPENDING_CONTENT_TYPES.includes(
    fromContentType
  );
  const fromReceivingContentType = RECEIVING_CONTENT_TYPES.includes(
    fromContentType
  );

  if (!accountType) {
    if (isPositive) {
      if (fromSpendingContentType) {
        isSpent = true;
      } else {
        isSpent = false;
      }
    } else if (fromReceivingContentType) {
      isSpent = false;
    } else {
      isSpent = true;
    }
  } else if (isReceivingAccountType) {
    if (isPositive) {
      isSpent = false;
    } else {
      isSpent = true;
    }
  } else if (isSpendingAccountType) {
    if (isPositive) {
      isSpent = true;
    } else {
      isSpent = false;
    }
  }
  return isSpent;
};

export const batchRequest = async ({
  items,
  actionFunction,
  noOfItemsPerBatch = 5,
  progressUpdate = () => {},
}) => {
  const batches = _.chunk(items, noOfItemsPerBatch);

  const rejectedItems = [];
  const fulfilledItems = [];

  progressUpdate(0);
  // sequentially run the batches
  await _.reduce(
    batches,
    (promiseChain, batch, batchIndex) => {
      return promiseChain.then(async () => {
        const result = await Promise.allSettled(batch.map(actionFunction));

        _.forEach(result, (item, index) => {
          if (item.status === 'fulfilled') {
            fulfilledItems.push({ item: batch[index], result: item.value });
          } else {
            rejectedItems.push({ item: batch[index], error: item.reason });
          }
        });

        progressUpdate(((batchIndex + 1) / batches.length) * 100);
      });
    },
    Promise.resolve()
  );

  return {
    fulfilledItems,
    rejectedItems,
  };
};

export const determinePaymentTerms = ({ dueDate, billDate }) => {
  if (!billDate && !dueDate) {
    return PAYMENT_TERMS.NET_30;
  }

  if (!billDate) {
    return PAYMENT_TERMS.OTHER;
  }

  const days = differenceInDays(dueDate, billDate);
  return DAYS_TO_PAYMENT_TERMS[days] || PAYMENT_TERMS.OTHER;
};

/**
 * Apply the user's saved preferences to the table.
 * If no preferences provided, it will return the default values
 */
export const applyPreferences = ({
  preferences,
  defaultColumnsInfo,
  defaultFilterInfo,
  defaultSortInfo,
  defaultColumnOrder,
}) => {
  if (_.isNil(preferences) || _.isEmpty(preferences)) {
    return {
      newColumnsInfo: defaultColumnsInfo,
      newFilterInfo: defaultFilterInfo,
      newSortInfo: defaultSortInfo,
      newColumnOrder: defaultColumnOrder,
    };
  }

  // COLUMN VISIBILITY AND WIDTH
  const newColumnsInfo = defaultColumnsInfo.map(attribute => {
    const attributePlus = { ...attribute };
    const savedPreferenceForThisColumn = preferences?.[attributePlus.name];
    if (savedPreferenceForThisColumn) {
      // column width
      attributePlus.defaultWidth = savedPreferenceForThisColumn.computedWidth;
      attributePlus.defaultFlex = null;
      // column visibility
      attributePlus.defaultVisible =
        savedPreferenceForThisColumn.computedVisible;
    }
    return attributePlus;
  });

  // COLUMN FILTERS
  const newFilterInfo = defaultFilterInfo.map(filterDetails => {
    // if the columnSettings object has a key for the column in question, then
    //  overwrite the values with the new values, even if it's empty/nullish/etc
    const updatedFilterDetails = {
      ...preferences?.[filterDetails.name],
    };
    if (_.keys(updatedFilterDetails).length > 0) {
      return updatedFilterDetails.computedFilterValue;
    }
    return filterDetails;
  });

  // COLUMN SORTING
  const newSortInfo = [];
  _.each(preferences, columnSetting => {
    const newPref = columnSetting.computedSortInfo;
    if (newPref) {
      newSortInfo.push(newPref);
    }
  });

  // COLUMN ORDERING
  const newColumnOrder = new Array(defaultColumnsInfo.length).fill(null);
  //   - an array of null items allows us to splice an aitem into the right spot no matter when in the loop we're trying to do so
  const leftovers = [];
  // doing this so the items in the array are in the same right order and take any new columns into account by allowing them to be added at the end

  _.each(defaultColumnsInfo, column => {
    const indexPref = preferences?.[column.name]?.computedVisibleIndex;
    if (typeof indexPref === 'number' && indexPref < newColumnOrder.length) {
      newColumnOrder.splice(indexPref, 1, column.name); // replace the null in that spot with the column name
    } else {
      leftovers.push(column.name);
    }
  });
  // remove all nulls from the array
  _.remove(newColumnOrder, item => item === null);
  // add the leftovers to the end of the array (this could happen if we add columns to the  data and they have preferences safved already that don't include that column)
  newColumnOrder.push(...leftovers);
  return {
    newColumnsInfo,
    newSortInfo,
    newFilterInfo,
    newColumnOrder,
  };
};

export const getToJpg = (uri, { dontMessWithPdf } = {}) => {
  if (!uri) {
    return '';
  }

  const heicRegex = /\.heic$/i;
  let newUri = uri;
  if (heicRegex.test(newUri)) {
    // Case insensitively replace ".heic" with ".jpg" if it's at the end of the string
    newUri = newUri.replace(heicRegex, '.jpg');
  }

  if (!dontMessWithPdf) {
    const pdfRegex = /\.pdf$/i;
    // Case insensitively replace ".pdf" with ".jpg" if it's at the end of the string
    newUri = newUri.replace(pdfRegex, '.jpg');
  }

  return newUri;
};

export const capitalizeSentence = sentence => {
  const words = sentence.split(' ');

  return words
    .map(word => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
};

export const mergeWhileDeduping = (arr1, arr2, field) => {
  const map = new Map();

  [...arr1, ...arr2].forEach(item => {
    if (!map.has(item[field])) {
      map.set(item[field], item);
    }
  });

  return [...map.values()];
};

export const deepMerge = (target, source) => {
  const isObject = obj => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      // eslint-disable-next-line no-param-reassign
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      // eslint-disable-next-line no-param-reassign
      target[key] = deepMerge({ ...targetValue }, sourceValue);
    } else {
      // eslint-disable-next-line no-param-reassign
      target[key] = sourceValue;
    }
  });

  return target;
};

export const daysLeftInSubscription = subscriptionEndDate => {
  return moment(subscriptionEndDate * 1000).diff(moment(), 'days');
};

export const modifyQueryParams = ({
  history,
  paramsToModify = [],
  clearAllParams,
  pushOrReplace = 'push',
}) => {
  // DESCRIPTION: build a new history object and push or replace it
  const { location } = history;
  let historyInfo;
  // if all should be cleared, then just clear all and return
  if (clearAllParams) {
    historyInfo = {
      pathname: location.pathname,
      search: '',
    };
  } else {
    // if there's a list of params to clear then loop through the list and clear each one
    const queryParams = new URLSearchParams(location.search);
    paramsToModify.forEach(param => {
      if (param.value || param.value === false) {
        // take into account if the value is intentionally false (not falsey) or any other truthy value
        queryParams[param.name] = param.value;
      } else {
        queryParams.delete(param.name);
      }
    });
    historyInfo = {
      pathname: location.pathname,
      search: queryParams.toString(),
    };
  }

  // now push or replace
  if (pushOrReplace === 'replace') {
    history.replace(historyInfo);
  } else {
    history.push(historyInfo);
  }
};

export const extractAddressDetails = googleMapsAddress => {
  // Initialize an empty object to hold the extracted address
  const extractedAddress = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  };

  // Loop through address components and assign values based on types
  googleMapsAddress.address_components.forEach(component => {
    const componentType = component.types[0]; // Assuming primary type is the first in the array

    switch (componentType) {
      case 'street_number':
        extractedAddress.address1 += `${component.long_name} `;
        break;
      case 'route':
        extractedAddress.address1 += component.long_name;
        break;
      case 'subpremise':
        extractedAddress.address2 += component.long_name;
        break;
      case 'locality':
        extractedAddress.city = component.long_name;
        break;
      case 'administrative_area_level_1':
        extractedAddress.state = component.short_name;
        break;
      case 'postal_code':
        extractedAddress.zip = component.long_name;
        break;
      case 'country':
        extractedAddress.country = component.long_name;
        break;
      default:
        break;
      // Add more cases as needed for additional types
    }
  });

  return extractedAddress;
};

const createSessionStorageKey = ({ key, companyId }) => {
  if (!companyId) {
    // eslint-disable-next-line no-console
    console.warn('createSessionStorageKey ~ companyId is missing');
  }

  return `${companyId}-${key}`;
};

export const sessionStorageGetItem = ({ key, companyId }) => {
  const keyToGet = createSessionStorageKey({ key, companyId });
  return sessionStorage.getItem(keyToGet);
};

export const sessionStorageSetItem = ({ key, companyId, value }) => {
  const keyToSet = createSessionStorageKey({ key, companyId });
  sessionStorage.setItem(keyToSet, value);
};

export const sessionStorageRemoveItem = ({ key, companyId }) => {
  const keyToRemove = createSessionStorageKey({ key, companyId });
  sessionStorage.removeItem(keyToRemove);
};
