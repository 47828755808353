import React from 'react';
import { ButtonBase, Checkbox, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ReactHtmlParser from 'react-html-parser';
import Linkify from 'react-linkify';
import moment from 'moment';

import ContentImageInfoBlock from '../ProjectContentListing/ContentTypes/content-image/content-image-info-block';
import { getStatusDisplayInfo } from '../../helpers/bill-payments';
import { monetaryRender } from '../../helpers';

const useStyles = makeStyles(() => ({
  container: {
    borderBottom: '1px solid #ccc',
    padding: '8px 0',
  },
  buttonBase: {
    width: '100%',
    padding: '8px 8px 8px 16px',
    '&:hover': {
      backgroundColor: '#f4f4f4',
      boxShadow: '0px 0px 5px 0px #ccc',
      borderColor: '#ececec',
    },
  },
  labelContainer: {
    width: '120px',
    padding: '0 16px',
    textAlign: 'left',
  },
  label: {
    fontWeight: 'bold',
  },
  descriptionContainer: {
    flex: 1,
    alignSelf: 'stretch',
    margin: '0px 16px',
    padding: 8,
    backgroundColor: '#eee',
    borderRadius: 4,
    textAlign: 'left',
  },
}));

const GlobalBillItem = ({ globalBillItem, selected, onItemSelect }) => {
  const classes = useStyles();

  const { color, primaryText, secondaryText } = getStatusDisplayInfo({
    bill: globalBillItem,
  });

  const amountToUse = globalBillItem?.amount?.value || 0;
  const balanceToUse = globalBillItem?.balance
    ? globalBillItem.balance.value
    : amountToUse;

  return (
    <Grid className={classes.container}>
      <ButtonBase
        onClick={() => {
          onItemSelect(globalBillItem);
        }}
        className={classes.buttonBase}
      >
        <Grid container alignItems="center">
          <Grid item style={{ minWidth: 104 }}>
            <ContentImageInfoBlock
              color={color}
              primaryText={primaryText}
              secondaryText={secondaryText}
              small
            />
          </Grid>
          <Grid item style={{ width: 250 }}>
            <Grid container item xs={12}>
              <Grid item className={classes.labelContainer}>
                <Typography variant="body1" className={classes.label}>
                  Bill No.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {globalBillItem.documentNumber}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item className={classes.labelContainer}>
                <Typography variant="body1" className={classes.label}>
                  Balance Due
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {monetaryRender({
                    value: balanceToUse,
                    withDecimals: true,
                  })}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item className={classes.labelContainer}>
                <Typography variant="body1" className={classes.label}>
                  Amount
                </Typography>
              </Grid>{' '}
              <Grid item>
                <Typography variant="body1">
                  {monetaryRender({
                    value: amountToUse,
                    withDecimals: true,
                  })}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item className={classes.labelContainer}>
                <Typography variant="body1" className={classes.label}>
                  Bill Date
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {moment(globalBillItem.startDate).format('MMMM DD, YYYY')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item className={classes.labelContainer}>
                <Typography variant="body1" className={classes.label}>
                  Due Date
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {moment(globalBillItem.endDate).format('MMMM DD, YYYY')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={classes.descriptionContainer}>
            {globalBillItem.description && (
              <Typography variant="caption">
                <Linkify>{ReactHtmlParser(globalBillItem.description)}</Linkify>
              </Typography>
            )}
          </Grid>
          <Grid item style={{ alignItems: 'center', flex: 0 }}>
            <Checkbox checked={selected} color="primary" />
          </Grid>
        </Grid>
      </ButtonBase>
    </Grid>
  );
};

export default GlobalBillItem;
