import React, { useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { compose } from 'react-apollo';
import Question from './question';

const QuestionsDialog = props => {
  const {
    open,
    searchedQuestions,
    handleClose,
    handleDone,
    currentQuestionIds,
    currentQuestionMap,
    allUsersMap,
  } = props;

  const selectableQuestions = useMemo(() => {
    if (searchedQuestions) {
      const uniqQuestions = _.uniqBy(searchedQuestions, 'questionId');

      const existingIdMap = {};
      _.forEach(currentQuestionIds, questionId => {
        existingIdMap[questionId] = true;
      });

      const uniqQuestionsWithSelectedRemoved = uniqQuestions.filter(
        question => {
          return !existingIdMap[question.questionId];
        }
      );

      return _.orderBy(
        uniqQuestionsWithSelectedRemoved,
        ['questionText'],
        ['asc']
      );
    }
    return [];
  }, [currentQuestionIds, searchedQuestions]);

  const [questionMap, setQuestionMap] = React.useState(currentQuestionMap);

  const [questionIdsToAdd, setQuestionIdsToAdd] = React.useState([]);

  const handleDoneClick = () => {
    const toReturn = {
      questionIds: [...currentQuestionIds, ...questionIdsToAdd],
      questionMap: { ...questionMap },
    };

    handleDone(toReturn);
  };

  const onSelect = question => {
    setQuestionIdsToAdd(currentState => {
      return [...currentState, question.questionId];
    });

    setQuestionMap(currentState => {
      return { ...currentState, [question.questionId]: question };
    });
  };

  const onDeselect = question => {
    setQuestionIdsToAdd(currentState => {
      return _.filter(currentState, question.questionId);
    });

    setQuestionMap(currentState => {
      return _.omit(currentState, question.questionId);
    });
  };

  const renderListOfQuestions = () => {
    return selectableQuestions.map((question, index) => {
      const creatorInfo = allUsersMap[question.creatorId];
      return (
        <Question
          key={question.questionId}
          question={question}
          index={index}
          fromSearch
          creatorInfo={creatorInfo}
          onSelect={onSelect}
          onDeselect={onDeselect}
        />
      );
    });
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason);
        }
      }}
      disableEscapeKeyDown
    >
      <DialogTitle id="alert-dialog-title">Search Questions</DialogTitle>
      <DialogContent>
        {!selectableQuestions.length ? (
          <Grid item style={{ marginBottom: 5 }}>
            You have no questions.
          </Grid>
        ) : (
          renderListOfQuestions()
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Cancel
        </Button>
        <Button
          onClick={handleDoneClick}
          variant="contained"
          color="primary"
          autoFocus
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default compose(QuestionsDialog);
