import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

const RenameGroupInput = ({ handleRenameGroup, list, errorMessage }) => {
  const [newName, setNewName] = useState(list.listName);

  const renameGroup = () => {
    handleRenameGroup({ listId: list.listId, newName });
  };

  const handleNewNameChange = e => {
    setNewName(e.target.value);
  };

  return (
    <TextField
      fullWidth
      placeholder="Enter a group name"
      onChange={handleNewNameChange}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          renameGroup();
        }
      }}
      onBlur={() => renameGroup()}
      value={newName}
      error={!!errorMessage}
      helperText={errorMessage}
    />
  );
};

export default RenameGroupInput;
