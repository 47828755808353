import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Customer}
  mutation AddCustomer(
    $customerId: ID!
    $managingCompanyId: String!
    $qboCustomerId: String
    $firstName: String
    $lastName: String
    $address: String
    $email: String
    $latitude: String
    $longitude: String
    $phoneNumber: String
    $notes: String
    $companyName: String
  ) {
    addCustomer(
      input: {
        customerId: $customerId
        managingCompanyId: $managingCompanyId
        qboCustomerId: $qboCustomerId
        firstName: $firstName
        lastName: $lastName
        address: $address
        email: $email
        latitude: $latitude
        longitude: $longitude
        phoneNumber: $phoneNumber
        notes: $notes
        companyName: $companyName
      }
    ) {
      ...CustomerFields
    }
  }
`;
