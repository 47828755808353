import gql from 'graphql-tag';

export default gql`
  mutation UpdateBookkeepingReport(
    $companyId: String!
    $snapshotId: String!
    $hidden: Boolean
  ) {
    updateBookkeepingReport(
      input: { companyId: $companyId, snapshotId: $snapshotId, hidden: $hidden }
    ) {
      date
      snapshotId
      dataJson
      hidden
    }
  }
`;
