import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { compose } from 'react-apollo';
import Dropzone from 'react-dropzone';
import { Grid, Paper, Typography, Avatar } from '@material-ui/core';
import { AccountCircleOutlined as AccountCircleIcon } from '@material-ui/icons';
import {
  uploadProfilePhoto,
  UpdateUserMutationAction,
} from '../../graphql/graphql';

import {
  MAX_CHARACTERS_IN_NAME,
  MAX_CHARACTERS_IN_PHONENUMBER_FIELD,
  MAX_CHARACTERS_IN_USERNAME,
} from '../../config/appDefaults';
import { cloudinaryifyProfilePic } from '../../helpers/cloudinary';
import { useStyles } from './onboarding.styles';
import LevelLogo from '../../components/level-logo/level-logo';
import AuthTextField from '../../components/auth/auth-text-field/auth-text-field';
import ModalImageManipulator from '../../components/ModalImageManipulator/modalImageManipulator';
import { USERNAME_STATUS } from './onboarding.constants';
import OnboardingStepButtons from './onboarding-step-buttons';
import { isDefaultUsername } from '../../helpers';

const OnboardingUserProfileStep = ({
  userInfo,
  userInfoLoading,
  setLoading,
  onUpdateUser,
}) => {
  const classes = useStyles();

  const [userValues, setUserValues] = useState({
    username: '',
    shownUsername: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  });
  const [imageForManipulation, setImageForManipulation] = useState(null);
  const [openManipulationModal, setOpenManipulationModal] = useState(false);
  const [usernameStatus, setUsernameStatus] = useState(USERNAME_STATUS.IDLE);
  const [inputErrors, setInputErrors] = useState({
    username: null,
    firstName: null,
    lastName: null,
    companyName: null,
  });

  useEffect(() => {
    const updatedUserValues = {
      ...userValues,
      profilePic: userInfo.profilePic || null,
    };

    // Only overwrite if empty so local values will not be clobbered
    if (!userValues.username) {
      updatedUserValues.username = userInfo.username || '';
    }

    if (
      !userValues.shownUsername &&
      !isDefaultUsername(userInfo.username) // Do not show default username to user
    ) {
      updatedUserValues.shownUsername = userInfo.username || '';
    }

    if (!userValues.firstName) {
      updatedUserValues.firstName = userInfo.firstName || '';
    }

    if (!userValues.lastName) {
      updatedUserValues.lastName = userInfo.lastName || '';
    }

    setUserValues(updatedUserValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const noErrors = !_.some(inputErrors, error => error);
  const usernameNotTaken = usernameStatus !== USERNAME_STATUS.TAKEN;
  const mandatoryFieldsCompleted = _.every(
    ['shownUsername', 'firstName', 'lastName'],
    key => _.trim(userValues[key])
  );

  const allowGoToNext =
    noErrors && usernameNotTaken && mandatoryFieldsCompleted;

  const onNextClick = async () => {
    const cleanUserValues = { ...userValues };
    const PROFILE_FIELDS_TO_TRIM = ['firstName', 'lastName', 'phoneNumber'];
    PROFILE_FIELDS_TO_TRIM.forEach(field => {
      if (cleanUserValues[field]) {
        cleanUserValues[field] = cleanUserValues[field].trim();
      }
    });

    // If user information changed, submit to backend
    const hasProfileChange =
      (cleanUserValues.firstName &&
        cleanUserValues.firstName !== userInfo.firstName) ||
      (cleanUserValues.lastName &&
        cleanUserValues.lastName !== userInfo.lastName) ||
      (cleanUserValues.phoneNumber &&
        cleanUserValues.phoneNumber !== userInfo.phoneNumber);

    if (hasProfileChange) {
      setLoading(true);
      await onUpdateUser({
        ...userInfo,
        ...cleanUserValues,
      });

      setLoading(false);
    }
  };

  const cleanAndValidateUsername = usernameToCheck => {
    if (!usernameToCheck) return '';

    // change consecutive ".", "-", and "_" to singles
    const cleanUsername = usernameToCheck.replace(/[^-\w.]|(\W|_)(?=\1)/gi, '');

    // "-", word characters, and "." allowed, 2-30 characters
    //  long, but no consecutive ".", "-", or "_"
    const usernameRegex = new RegExp(
      /^(?!.*__.*)(?!.*\.\..*)(?!.*--.*)[-\w.]{2,30}$/
    );

    if (usernameRegex.test(cleanUsername)) {
      setInputErrors({ ...inputErrors, username: null });
    } else {
      setInputErrors({
        ...inputErrors,
        username: 'Username does not meet requirements',
      });
    }

    return cleanUsername;
  };

  const handleUsernameChange = e => {
    const passedUsername = e.target.value.replace(/\s/g, '');
    const username = cleanAndValidateUsername(passedUsername);
    setUserValues(currentValues => ({
      ...currentValues,
      username,
      shownUsername: username,
    }));
    setUsernameStatus(USERNAME_STATUS.IDLE);
  };

  const handleFirstNameChange = e => {
    const passedFirstName = e.target.value;
    setUserValues(currentValues => ({
      ...currentValues,
      firstName: passedFirstName,
    }));
  };

  const handleLastNameChange = e => {
    const passedLastName = e.target.value;
    setUserValues(currentValues => ({
      ...currentValues,
      lastName: passedLastName,
    }));
  };

  const handlePhoneNumberChange = e => {
    const passedPhoneNumber = e.target.value;
    setUserValues(currentValues => ({
      ...currentValues,
      phoneNumber: passedPhoneNumber,
    }));
  };

  const cleanInputValues = () => {
    const { firstName, lastName } = userValues;
    setUserValues(currentValues => ({
      ...currentValues,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
    }));
  };

  const usernameSubmit = async () => {
    const usernameToSubmit = _.trim(userValues.shownUsername);
    if (
      !usernameToSubmit ||
      (usernameToSubmit && usernameToSubmit === userInfo.username)
    ) {
      return;
    }

    setLoading(true);

    const updateResponse = await onUpdateUser({
      ...userInfo,
      username: usernameToSubmit,
    });

    const returnedUsername = _.get(updateResponse, 'data.updateUser.username');

    if (
      returnedUsername === userValues.username &&
      returnedUsername === userValues.shownUsername
    ) {
      setUsernameStatus(USERNAME_STATUS.ACCEPTED);
    } else {
      setUsernameStatus(USERNAME_STATUS.TAKEN);
    }
    setLoading(false);
  };

  const saveProfileImage = async file => {
    setLoading(true);

    await uploadProfilePhoto(userInfo, file);

    setLoading(false);
  };

  return (
    <>
      <Grid item xs={12}>
        <LevelLogo className={classes.smallLevelLogo} />
      </Grid>
      <Grid item xs={12} className={classes.title}>
        Profile Info
      </Grid>
      <Grid item xs={12} className={classes.description}>
        Any content you add or messages you send will show your username, so
        let&apos;s pick one that represents you best.
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 8 }}>
        <Dropzone
          accept="image/*"
          onDrop={acceptedFiles => {
            const filesToPass = [...acceptedFiles];
            filesToPass[0].uri = URL.createObjectURL(filesToPass[0]);
            setImageForManipulation(filesToPass[0].uri);
            setOpenManipulationModal(true);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              {...getRootProps()}
              style={{
                width: 250,
                cursor: 'pointer',
                padding: userValues.profilePic ? 0 : 8,
              }}
              component={userValues.profilePic ? 'div' : Paper}
            >
              <Grid item>
                <input {...getInputProps()} />

                {!userValues.profilePic && (
                  <>
                    <AccountCircleIcon className={classes.profileIcon} />
                    <Typography color="primary" variant="body1">
                      Click to choose profile pic
                    </Typography>
                  </>
                )}

                {!!userValues.profilePic && (
                  <>
                    <Avatar
                      alt="user profile image"
                      src={cloudinaryifyProfilePic(userValues.profilePic)}
                      className={classes.profilePic}
                    />
                    <Typography
                      variant="body1"
                      color="primary"
                      style={{
                        textAlign: 'right',
                        marginTop: -20,
                        marginRight: -40,
                      }}
                    >
                      Change...
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </Dropzone>
        <ModalImageManipulator
          open={openManipulationModal}
          handleClose={() => setOpenManipulationModal(false)}
          passedImage={imageForManipulation}
          passbackManipulatedImage={blob => {
            const blobToFile = (theBlob, fileName) => {
              const theBlobParam = theBlob;
              // A Blob() is almost a File() - it's just missing the two properties below which we will add
              theBlobParam.lastModifiedDate = new Date();
              theBlobParam.name = fileName;
              return theBlob;
            };
            const saveThis = blobToFile(blob, 'profilepic.jpg');
            setOpenManipulationModal(false);
            saveProfileImage(saveThis);
          }}
        />
      </Grid>
      <Grid container item xs={12} className={classes.fieldsContainer}>
        <Grid item xs={12}>
          <AuthTextField
            label="Username"
            required
            value={userValues.shownUsername}
            maxLength={MAX_CHARACTERS_IN_USERNAME}
            errorText={
              usernameStatus === USERNAME_STATUS.TAKEN
                ? 'That username is invalid or in use already.'
                : ''
            }
            successText={
              usernameStatus === USERNAME_STATUS.ACCEPTED
                ? 'That username looks great!'
                : ''
            }
            onBlur={usernameSubmit}
            onChange={handleUsernameChange}
          />
        </Grid>
        <Grid item xs={12}>
          <AuthTextField
            label="First Name"
            required
            value={userValues.firstName}
            maxLength={MAX_CHARACTERS_IN_NAME}
            onBlur={cleanInputValues}
            onChange={handleFirstNameChange}
          />
        </Grid>
        <Grid item xs={12}>
          <AuthTextField
            label="Last Name"
            required
            value={userValues.lastName}
            maxLength={MAX_CHARACTERS_IN_NAME}
            onBlur={cleanInputValues}
            onChange={handleLastNameChange}
          />
        </Grid>

        <Grid item xs={12}>
          <AuthTextField
            label="Phone Number"
            value={userValues.phoneNumber}
            maxLength={MAX_CHARACTERS_IN_PHONENUMBER_FIELD}
            onBlur={cleanInputValues}
            onChange={handlePhoneNumberChange}
            style={{ marginBottom: 0 }}
          />
        </Grid>
      </Grid>
      <OnboardingStepButtons
        allowGoToNext={allowGoToNext}
        nextAction={onNextClick}
        showLoading={userInfoLoading}
      />
    </>
  );
};

export default compose(UpdateUserMutationAction)(OnboardingUserProfileStep);
