import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  ${Fragment.Stat}
  mutation CreateContent(
    $aspectRatio: String
    $contentId: ID!
    $contentUrl: String
    $media: [MediaInfoInput]
    $creatorId: String!
    $date: String!
    $description: String
    $isPublic: Boolean!
    $jrnId: String!
    $labels: [String]
    $latitude: String
    $longitude: String
    $synced: Boolean
    $type: String!
    $subtype: String
    $requireAdmin: Boolean
    $completionType: String
    $title: String
    $endDate: String
    $privacyLevel: String
    $inviteCode: String
    $allowedToEdit: [String!]
    $allowedToAdd: [String]
    $allowedToView: [String]
    $usersEverOnJrn: [String]
    $startDate: String
    $addedBy: String
    $assignedTo: String
    $contentStatus: String
    $useTimetracking: Boolean
    $priority: String
    $permissionsFrom: String
    $completionInfo: CompletionInfoInput
    $amount: MoneyInput
    $recurrence: RecurringTimeRangeInput
    $timetrackingAnswers: [TimetrackingAnswerInput]
    $fromTemplate: String
    $workflowStage: String
    $workflowStageReason: String
    $rfiIds: [String]
    $globalExpenseId: String
    $billable: Boolean
    $ocrId: String
  ) {
    createContent(
      input: {
        aspectRatio: $aspectRatio
        contentId: $contentId
        contentUrl: $contentUrl
        media: $media
        creatorId: $creatorId
        date: $date
        description: $description
        isPublic: $isPublic
        jrnId: $jrnId
        labels: $labels
        latitude: $latitude
        longitude: $longitude
        synced: $synced
        type: $type
        subtype: $subtype
        requireAdmin: $requireAdmin
        completionType: $completionType
        title: $title
        endDate: $endDate
        privacyLevel: $privacyLevel
        inviteCode: $inviteCode
        allowedToEdit: $allowedToEdit
        allowedToAdd: $allowedToAdd
        allowedToView: $allowedToView
        usersEverOnJrn: $usersEverOnJrn
        startDate: $startDate
        addedBy: $addedBy
        assignedTo: $assignedTo
        contentStatus: $contentStatus
        useTimetracking: $useTimetracking
        priority: $priority
        permissionsFrom: $permissionsFrom
        completionInfo: $completionInfo
        amount: $amount
        recurrence: $recurrence
        timetrackingAnswers: $timetrackingAnswers
        fromTemplate: $fromTemplate
        workflowStage: $workflowStage
        workflowStageReason: $workflowStageReason
        rfiIds: $rfiIds
        globalExpenseId: $globalExpenseId
        billable: $billable
        ocrId: $ocrId
      }
    ) {
      customerId
      aspectRatio
      contentId
      contentUrl
      media {
        uri
        aspectRatio
        type
      }
      creatorId
      date
      dateCreated
      description
      isPublic
      jrnId
      labels
      address
      latitude
      longitude
      synced
      type
      subtype
      requireAdmin
      completionType
      comments {
        items {
          content
          commentId
          dateCreated
          userId
        }
      }
      loves {
        items {
          loveId
          userId
          contentId
        }
      }
      title
      endDate
      privacyLevel
      inviteCode
      allowedToEdit
      allowedToAdd
      allowedToView
      usersEverOnJrn
      startDate
      addedBy
      assignedTo
      contentStatus
      useTimetracking
      useTimetrackingQuestions
      timetrackingQuestions
      timetrackingQuestionsCheckout
      priority
      permissionsFrom
      completionInfo {
        instructions
        completedById
        completedByDate
        textResponse
        mediaInfo {
          uri
          aspectRatio
        }
      }
      amount {
        value
      }
      recurrence {
        rangeId
        rangeStartDate
        rangeEndDate
        daysOfTheWeek
      }
      timetrackingAnswers {
        questionId
        answer
      }
      fromTemplate
      timetrackingType
      qboCustomerId
      workflowStage
      workflowStageReason
      # REMOVEME: remove this when "bugets" field is deprecated
      budgets {
        ...BudgetFields
      }
      stats {
        ...StatFields
      }
      includeSubsInStats
      excludeFromStats
      qboCustomerId
      rfiIds
      globalExpenseId
      globalExpense {
        contentId
        type
        jrnId
        vendorId
        title
        description
        contentUrl
        aspectRatio
        contentStatus
        documentNumber
        poNumber
        amount {
          value
        }
        balance {
          value
        }
        paymentTerms
        date
        startDate
        endDate
        creatorId
        labels
      }
      billable
    }
  }
`;
