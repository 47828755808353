import { Typography } from '@material-ui/core';
import React from 'react';
import OkCancelDialog from '../OkCancelDialog/okCancelDialog';
import { QBO_SYNCABLE_TYPE } from '../../config/appDefaults';

const QboSyncNewCustomer = ({ handleClose, recordType, syncToNewCustomer }) => {
  const customerOrSubcustomer =
    recordType === QBO_SYNCABLE_TYPE.CUSTOMER ? 'customer' : 'sub-customer';

  const dialogTitle = `Sync to new QuickBooks Online ${customerOrSubcustomer}`;

  return (
    <OkCancelDialog
      open
      onClose={handleClose}
      onConfirm={syncToNewCustomer}
      title={dialogTitle}
      okButtonText="Confirm"
    >
      <Typography variant="body1">
        This {recordType} will be used to create a {customerOrSubcustomer} new
        in QuickBooks Online.{' '}
        {recordType !== QBO_SYNCABLE_TYPE.CUSTOMER && (
          <>
            The sub-customer will be parented by the project&apos;s Level
            customer.
          </>
        )}
        <br />
        <br />
        After that, the Level {recordType} and QuickBooks Online{' '}
        {customerOrSubcustomer} will be connected so changes made in QuickBooks
        Online will automatically be made in Level and vice-versa!
      </Typography>
    </OkCancelDialog>
  );
};

export default QboSyncNewCustomer;
