import formatNumberAsMoneyDollar from './format-number-as-money-dollar';

const getStripePricingDetails = ({ unitAmount, recurring, quantity = 1 }) => {
  let term;
  let title;
  if (recurring.interval === 'year') {
    term = 'annually';
    title = 'Annual Plan';
  } else if (recurring.interval === 'month') {
    if (recurring.intervalCount === 1) {
      term = 'monthly';
      title = 'Monthly Plan';
    } else if (recurring.intervalCount === 3) {
      term = 'quarterly';
      title = 'Quarterly Plan';
    }
  }

  const price = unitAmount / 100;
  const readableTermPrice = formatNumberAsMoneyDollar(price * quantity);

  let readableMonthlyPrice = formatNumberAsMoneyDollar(price * quantity); // default to monthly
  if (term === 'annually') {
    // annually
    readableMonthlyPrice = formatNumberAsMoneyDollar((price * quantity) / 12);
  } else if (recurring.interval === 'month' && term === 'quarterly') {
    // quarterly
    readableMonthlyPrice = formatNumberAsMoneyDollar((price * quantity) / 3);
  }

  return {
    term,
    title,
    readableMonthlyPrice,
    readableTermPrice,
  };
};

export default getStripePricingDetails;
