import gql from 'graphql-tag';

export default gql`
  query ListCompanyGlobalFinancialItems(
    $companyId: String!
    $typesToInclude: [GlobalFinancialTypes]
    $vendorId: String
    $first: Int
    $after: String
    $nextToken: String
  ) {
    listCompanyGlobalFinancialItems(
      input: {
        companyId: $companyId
        typesToInclude: $typesToInclude
        vendorId: $vendorId
        first: $first
        after: $after
        nextToken: $nextToken
      }
    ) {
      items {
        contentId
        type
        subtype
        creatorId
        contentUrl
        aspectRatio
        description
        date
        startDate
        endDate
        dateCreated
        labels
        amount {
          value
        }
        balance {
          value
        }
        billable
        documentNumber
        paymentTerms
        poNumber
        workflowStage
        workflowStageReason
        contentStatus
        vendorId
        vendor {
          relationId
          name
          isArchived
        }
        rfiIds
      }
      nextToken
    }
  }
`;
