import gql from 'graphql-tag';

export default gql`
  fragment LicenseFields on License {
    licenseId
    productId
    companyId
    isActive
    products
    subscriptionEndDate
    dateCreated
    dateModified
    dateAssigned
  }
`;
