import React from 'react';
import { Button, Checkbox, Tooltip } from '@material-ui/core';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import moment from 'moment';
import _ from 'lodash';

import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';

import { simpleSortForAmount } from '../../helpers/react-datagrid-helpers';
import { monetaryRender } from '../../helpers';

import palette from '../../theme/palette';

import {
  GLOBAL_EXPENSE_STATUS_LABEL,
  GLOBAL_EXPENSE_STATUS,
  WORKFLOW_STAGE,
  CONTENT_DETAILS_MODAL_MODE,
  PRODUCT,
  WORKFLOW_STAGE_REASON,
} from '../../config/appDefaults';

const actionButtonColumnWidth = 50;
const columnMinWidthSmall = 120;
// const columnMinWidthMedium = 180;
const columnMinWidthLarge = 240;

export const BILLS_TABLE_COLUMN = {
  CONTENT_ID: 'contentId',
  USERNAME: 'username',
  ACTIONS: 'actions',
  BILL_NUMBER: 'documentNumber',
  VENDOR: 'vendor',
  DUE_DATE: 'dueDate',
  BILL_DATE: 'billDate',
  AMOUNT: 'amount',
  BALANCE_DUE: 'balanceDue',
  PAYMENT_STATUS: 'paymentStatus',
  PO_NUMBER: 'poNumber',
  DATE_ADDED: 'dateAdded',
  IS_RECORDED: 'isRecorded',
  IS_RFI_REQUIRED: 'isRfiRequired',
};

export default ({
  classes,
  companyCrew,
  vendors,
  managingCompanyInfo,
  updateJrn,
  setShowContentDetailsModal,
}) => {
  const isBookkeepingCustomer = !!managingCompanyInfo?.isBookkeepingCustomer;
  const isCompanyBookkeeper = !!managingCompanyInfo?.isCompanyBookkeeper;

  const hasRfiProduct = managingCompanyInfo?.products?.includes(PRODUCT.RFI);

  const renderDate = ({ value }) => {
    if (!value) return 'n/a';
    return moment(value).format('MMM D, YYYY');
  };

  const defaultSortInfo = [
    {
      name: BILLS_TABLE_COLUMN.DUE_DATE,
      dir: 1,
      type: 'date',
    },
  ];

  const columns = [
    {
      name: BILLS_TABLE_COLUMN.CONTENT_ID,
      filterInfo: null,
      tableInfo: {
        header: 'Expense ID',
        defaultVisible: false,
      },
    },
    {
      name: BILLS_TABLE_COLUMN.ACTIONS,
      filterInfo: null,
      tableInfo: {
        header: null,
        minWidth: actionButtonColumnWidth,
        maxWidth: actionButtonColumnWidth,
        render: ({ data, rowIndex }) => {
          return (
            <Tooltip title="View Payment">
              <Button
                onClick={() => {
                  setShowContentDetailsModal({
                    open: true,
                    mode: CONTENT_DETAILS_MODAL_MODE.ADMIN_GLOBAL_ITEM_VIEW,
                    contentToShow: data.globalBill,
                    currentIndex: rowIndex,
                    showNextPreviousButtons: true,
                  });
                }}
                className={classes.actionButton}
              >
                <VisibilityIcon />
              </Button>
            </Tooltip>
          );
        },
      },
    },
    {
      name: BILLS_TABLE_COLUMN.USERNAME,
      filterInfo: {
        operator: 'inlist',
        type: 'select',
        value: null,
      },
      tableInfo: {
        header: 'Username',
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: 'All',
          multiple: true,
          wrapMultiple: true,
          dataSource: _.orderBy(
            companyCrew.map(user => ({
              id: user.username,
              label: user.username,
            })),
            ['label'],
            ['asc']
          ),
        },
        defaultFlex: 20,
        minWidth: columnMinWidthSmall,
      },
    },
    {
      name: BILLS_TABLE_COLUMN.BILL_NUMBER,
      filterInfo: {
        operator: 'contains',
        type: 'string',
        value: '',
      },
      tableInfo: {
        header: 'Bill No.',
        defaultFlex: 20,
        minWidth: columnMinWidthSmall,
      },
    },
    {
      name: BILLS_TABLE_COLUMN.VENDOR,
      filterInfo: {
        operator: 'inlist',
        type: 'select',
        value: null,
      },
      tableInfo: {
        header: 'Vendor',
        defaultFlex: 40,
        minWidth: columnMinWidthLarge,
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: '',
          multiple: true,
          wrapMultiple: true,
          dataSource: _.orderBy(
            vendors.map(({ name }) => ({
              id: name,
              label: name,
            })),
            ['label'],
            ['asc']
          ),
        },
      },
    },
    {
      name: BILLS_TABLE_COLUMN.BILL_DATE,
      filterInfo: {
        operator: 'afterOrOn',
        type: 'date',
        value: '',
      },
      tableInfo: {
        header: 'Bill Date',
        dateFormat: 'YYYY-MM-DD',
        filterEditor: DateFilter,
        filterEditorProps: () => {
          return {
            dateFormat: 'MMM D, YYYY',
            cancelButton: false,
            highlightWeekends: false,
          };
        },
        defaultFlex: 20,
        minWidth: columnMinWidthSmall,
        render: renderDate,
      },
    },
    {
      name: BILLS_TABLE_COLUMN.DUE_DATE,
      filterInfo: {
        operator: 'afterOrOn',
        type: 'date',
        value: '',
      },
      tableInfo: {
        header: 'Due Date',
        dateFormat: 'YYYY-MM-DD',
        filterEditor: DateFilter,
        filterEditorProps: () => {
          return {
            dateFormat: 'MMM D, YYYY',
            cancelButton: false,
            highlightWeekends: false,
          };
        },
        defaultFlex: 20,
        minWidth: columnMinWidthSmall,
        render: ({ value, data }) => {
          if (!value) return 'n/a';
          const billDueDate = moment(value);
          const dueDateString = billDueDate.format('MMM D, YYYY');
          let color = palette.brandColorError;

          if (
            data.paymentStatus ===
            GLOBAL_EXPENSE_STATUS_LABEL[GLOBAL_EXPENSE_STATUS.PAID]
          ) {
            color = palette.brandColorGreen;
          } else if (
            data.paymentStatus ===
            GLOBAL_EXPENSE_STATUS_LABEL[GLOBAL_EXPENSE_STATUS.OVER_PAID]
          ) {
            color = palette.brandColorError;
          } else if (billDueDate >= moment().startOf('day')) {
            if (
              billDueDate <
              moment()
                .startOf('day')
                .add(1, 'day')
            ) {
              // TODAY
              color = palette.brandColorOrange75;
            } else if (
              billDueDate >=
                moment()
                  .startOf('day')
                  .add(1, 'day') &&
              billDueDate <
                moment()
                  .startOf('day')
                  .add(2, 'day')
            ) {
              // TOMORROW
              color = palette.brandColorOrange75;
            } else {
              // UPCOMING
              color = palette.brandColorPrimary;
            }
          }

          return <span style={{ color }}>{dueDateString}</span>;
        },
      },
    },
    {
      name: BILLS_TABLE_COLUMN.AMOUNT,
      filterInfo: {
        operator: 'Starts With',
        type: 'amount',
        value: '',
      },
      tableInfo: {
        header: 'Amount',
        type: 'amount',
        defaultFlex: 20,
        minWidth: columnMinWidthSmall,
        sort: simpleSortForAmount,
        render: ({ value }) => {
          if (typeof value === 'string') return value;
          if (value === -Number.EPSILON) {
            return 'n/a';
          }
          return monetaryRender({ value, withDecimals: true });
        },
      },
    },
    {
      name: BILLS_TABLE_COLUMN.BALANCE_DUE,
      filterInfo: {
        operator: 'Starts With',
        type: 'amount',
        value: '',
      },
      tableInfo: {
        header: 'Balance Due',
        type: 'amount',
        defaultFlex: 20,
        minWidth: columnMinWidthSmall,
        sort: simpleSortForAmount,
        render: ({ value }) => {
          if (typeof value === 'string') return value;
          if (value === -Number.EPSILON) {
            return 'n/a';
          }
          return monetaryRender({ value, withDecimals: true });
        },
      },
    },
    {
      name: BILLS_TABLE_COLUMN.PAYMENT_STATUS,
      filterInfo: {
        operator: 'inlist',
        type: 'select',
        value: [
          GLOBAL_EXPENSE_STATUS_LABEL[GLOBAL_EXPENSE_STATUS.PARTIALLY_PAID],
          GLOBAL_EXPENSE_STATUS_LABEL[GLOBAL_EXPENSE_STATUS.UNPAID],
        ],
      },
      tableInfo: {
        header: 'Payment Status',
        defaultFlex: 20,
        minWidth: columnMinWidthLarge,
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: '',
          multiple: true,
          wrapMultiple: true,
          dataSource: _.values(GLOBAL_EXPENSE_STATUS_LABEL).map(value => ({
            id: value,
            label: value,
          })),
        },
      },
    },
    {
      name: BILLS_TABLE_COLUMN.PO_NUMBER,
      filterInfo: {
        operator: 'contains',
        type: 'string',
        value: '',
      },
      tableInfo: {
        header: 'PO Number',
        defaultFlex: 20,
        minWidth: columnMinWidthSmall,
      },
    },
    {
      name: BILLS_TABLE_COLUMN.IS_RECORDED,
      filterInfo: {
        operator: ['eq'],
        type: 'boolean',
        value: null,
      },
      tableInfo: {
        header: 'Is Recorded?',
        textAlign: 'center',
        defaultFlex: 10,
        minWidth: columnMinWidthSmall,
        filterEditor: BoolFilter,
        render: ({ value, data }) => {
          // disabled if they are a bookkeeping customer and not a bookkeeper
          // or if they are not a company admin
          const allowFor =
            (isBookkeepingCustomer && isCompanyBookkeeper) ||
            (!isBookkeepingCustomer && managingCompanyInfo?.isCompanyAdmin);
          const disabled = !allowFor;
          return (
            <Checkbox
              color="primary"
              onChange={() => {
                updateJrn({
                  variables: {
                    contentId: data.contentId,
                    workflowStage: value
                      ? WORKFLOW_STAGE.UNRECORDED
                      : WORKFLOW_STAGE.RECORDED,
                  },
                });
              }}
              checked={value}
              disabled={disabled}
            />
          );
        },
      },
    },
    {
      name: BILLS_TABLE_COLUMN.IS_RFI_REQUIRED,
      filterInfo: {
        operator: ['eq'],
        type: 'boolean',
        value: null,
      },
      tableInfo: {
        header: "RFI Req'd",
        textAlign: 'center',
        defaultFlex: 10,
        minWidth: columnMinWidthSmall,
        filterEditor: BoolFilter,
        render: ({ value, data }) => {
          return (
            <Checkbox
              color="primary"
              onChange={() => {
                if (!hasRfiProduct && managingCompanyInfo.isCompanyBookkeeper) {
                  updateJrn({
                    variables: {
                      contentId: data.contentId,
                      workflowStageReason: value
                        ? null
                        : WORKFLOW_STAGE_REASON.RFI_REQUIRED,
                    },
                  });
                }
              }}
              checked={value}
              disabled={
                hasRfiProduct || !managingCompanyInfo.isCompanyBookkeeper
              }
            />
          );
        },
      },
    },
    {
      name: BILLS_TABLE_COLUMN.DATE_ADDED,
      filterInfo: {
        operator: 'afterOrOn',
        type: 'date',
        value: '',
      },
      tableInfo: {
        header: 'Date Added',
        dateFormat: 'YYYY-MM-DD',
        filterEditor: DateFilter,
        filterEditorProps: () => {
          return {
            dateFormat: 'MMM D, YYYY',
            cancelButton: false,
            highlightWeekends: false,
          };
        },
        defaultFlex: 20,
        minWidth: columnMinWidthSmall,
        render: renderDate,
      },
    },
  ];

  const filterDefs = columns
    .filter(({ filterInfo }) => !!filterInfo)
    .map(({ name, filterInfo }) => ({
      name,
      ...filterInfo,
    }));

  const columnDefs = columns
    .filter(({ tableInfo }) => !!tableInfo)
    .map(({ name, tableInfo }) => ({
      name,
      ...tableInfo,
    }));

  return {
    defaultSortInfo,
    filterDefs,
    columnDefs,
  };
};
