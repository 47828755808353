import React, { useEffect } from 'react';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import SimpleDialog from '../../SimpleDialog/simpleDialog';
import palette from '../../../theme/palette';

import {
  TASK_STATUS_OPTIONS,
  adminTaskStatusesForNonAdmins,
  adminTaskStatusesForAdmins,
} from '../../../config/appDefaults';

const TaskStatusChangeDialog = props => {
  const { contentPiece, isAdmin, onClose } = props;

  const [options, setOptions] = React.useState([]);

  useEffect(() => {
    // 1. figure out the options to show the user for completing the task
    let filteredOptions;
    if (!contentPiece.requireAdmin) {
      filteredOptions = TASK_STATUS_OPTIONS;
    } else if (isAdmin) {
      filteredOptions = adminTaskStatusesForAdmins;
    } else {
      filteredOptions = adminTaskStatusesForNonAdmins;
    }

    // 2. remove the task's current status
    filteredOptions = filteredOptions.filter(
      ({ value }) => value !== contentPiece.contentStatus
    );

    // 3. build the options for the dialog
    const optionsToUse = filteredOptions.map(option => {
      let iconToUse;
      switch (option.value) {
        case 'completed':
          iconToUse = (
            <CheckCircleOutlineIcon htmlColor={palette.taskStatusCompleted} />
          );
          break;
        case 'in-progress':
          iconToUse = (
            <RemoveCircleOutlineIcon htmlColor={palette.taskStatusInProgress} />
          );
          break;
        case 'incomplete':
          iconToUse = (
            <HighlightOffIcon htmlColor={palette.taskStatusIncomplete} />
          );
          break;
        case 'pending-approval':
          iconToUse = (
            <CheckCircleOutlineIcon
              htmlColor={palette.taskStatusPendingApproval}
            />
          );
          break;
        default:
          break;
      }
      return { ...option, icon: iconToUse };
    });

    // 4. set local state that's holding the options
    setOptions(optionsToUse);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPiece, isAdmin]);

  return <SimpleDialog open options={options} onClose={onClose} />;
};

export default TaskStatusChangeDialog;
