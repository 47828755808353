import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { Sort as SortIcon } from '@material-ui/icons';
import _ from 'lodash';

import FilterSearch from '../FilterSearch/filterSearch';

const useStyles = makeStyles(theme => ({
  filterWrapper: {
    backgroundColor: theme.palette.brandColorPrimary10,
    margin: '0 auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  filterButton: {
    color: theme.palette.brandColorPrimary,
    backgroundColor: '#fff',
  },
  filterButtonActive: {
    color: '#fff',
    backgroundColor: theme.palette.brandColorPrimary,
    '&:hover': {
      backgroundColor: theme.palette.brandColorPrimary,
    },
  },
  iconFlipped: {
    transform: 'scaleY(-1)',
  },
}));

const ProjectSearchAndSort = ({
  allProjects,
  onFilterStatusChange,
  passBack,
}) => {
  const classes = useStyles();

  const [projectsToUse, setProjectsToUse] = useState(null);
  const [filteringActive, setFilteringActive] = useState(false);

  const [sortProjectCriteria, setSortProjectCriteria] = useState({
    by: 'startDate',
    startDateOrder: 'desc',
    titleOrder: 'asc',
  });

  useEffect(() => {
    if (onFilterStatusChange) {
      onFilterStatusChange(filteringActive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteringActive]);

  useEffect(() => {
    const { by, startDateOrder, titleOrder } = sortProjectCriteria;
    const order = by === 'title' ? titleOrder : startDateOrder;
    const sortedProjects = _.orderBy(projectsToUse, [by], [order]);
    passBack(sortedProjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortProjectCriteria, projectsToUse]);

  const handleSortSubprojectChange = sortBy => {
    const orderProp = `${sortBy}Order`;
    setSortProjectCriteria(prevState => {
      let newOrder = prevState[orderProp];
      if (prevState.by === sortBy) {
        newOrder = prevState[orderProp] === 'asc' ? 'desc' : 'asc';
      }
      return {
        ...prevState,
        by: sortBy,
        [orderProp]: newOrder,
      };
    });
  };

  return (
    <Grid item style={{ width: '100%' }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        spacing={3}
        className={classes.filterWrapper}
      >
        <Grid
          container
          direction="row"
          style={{
            justifyContent: 'center',
            paddingTop: '15px',
            paddingBottom: '15px',
          }}
        >
          <Button
            className={
              sortProjectCriteria.by === 'startDate'
                ? classes.filterButtonActive
                : classes.filterButton
            }
            onClick={() => handleSortSubprojectChange('startDate')}
            style={{ margin: '5px' }}
          >
            Sort By Date&nbsp;
            <SortIcon
              className={
                sortProjectCriteria.startDateOrder === 'asc'
                  ? classes.iconFlipped
                  : null
              }
            />
          </Button>
          <Button
            className={
              sortProjectCriteria.by === 'title'
                ? classes.filterButtonActive
                : classes.filterButton
            }
            onClick={() => handleSortSubprojectChange('title')}
            style={{ margin: '5px' }}
          >
            Sort By Title&nbsp;
            <SortIcon
              className={
                sortProjectCriteria.titleOrder === 'asc'
                  ? classes.iconFlipped
                  : null
              }
            />
          </Button>
          <div style={{ marginLeft: 12 }}>
            <FilterSearch
              data={allProjects}
              isFiltered={filterStatus => setFilteringActive(filterStatus)}
              passBack={filteredData => setProjectsToUse(filteredData)}
              searchBy="title"
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectSearchAndSort;
