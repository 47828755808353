import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import { asCurrency } from '../../dashboard/dashboard.utils';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.brandColorLightGrey,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles(() => ({
  root: {
    fontSize: 12,
  },
}))(TableCell);

const OpenBalancesSection = ({ customerName, openInvoices, parents = [] }) => {
  return (
    <>
      <StyledTableCell colSpan={6} style={{ fontWeight: 'bold' }}>
        {[...parents, customerName].join(' > ')}
      </StyledTableCell>
      {openInvoices.map(
        ({
          invoiceDueDate,
          invoiceNumber,
          invoiceAmount,
          invoiceOpenBalance,
        }) => (
          <StyledTableRow key={invoiceNumber && invoiceNumber.value}>
            <StyledTableCell component="th" scope="row">
              {invoiceDueDate &&
                invoiceDueDate.value &&
                moment(invoiceDueDate.value).format('YYYY-MM-DD')}
            </StyledTableCell>
            <StyledTableCell>
              {invoiceNumber && invoiceNumber.value}
            </StyledTableCell>
            <StyledTableCell align="right">
              {invoiceAmount &&
                invoiceAmount.value &&
                asCurrency(parseFloat(invoiceAmount.value))}
            </StyledTableCell>
            <StyledTableCell align="right">
              {invoiceOpenBalance &&
                invoiceOpenBalance.value &&
                asCurrency(parseFloat(invoiceOpenBalance.value))}
            </StyledTableCell>
          </StyledTableRow>
        )
      )}
    </>
  );
};

export default OpenBalancesSection;
