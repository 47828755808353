import React, { useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import numeral from 'numeral';
import ContentLoader from 'react-content-loader';
import { HelpOutline as HelpIcon } from '@material-ui/icons';

import styles from '../../dashboard/dashboard.styles';
import palette from '../../../theme/palette';

const STATS_DEFAULT = [
  { key: 'Billable Hours', format: '0,0.00' },
  { key: 'Non-Billable Hours', format: '0,0.00' },
  { key: 'Total Hours', format: '0,0.00' },
  { key: 'Efficiency', format: '0.00%' },
];

const useStyles = makeStyles(theme => styles(theme));

const BillableHoursStats = ({ stats, loading }) => {
  const classes = useStyles();

  const [showDescription, setShowDescription] = useState(false);

  const rowsToUse = useMemo(() => {
    if (_.isEmpty(stats)) {
      // No stats, so show placeholder
      return _.map(STATS_DEFAULT, ({ key }) => ({ name: key }));
    }

    return _.map(STATS_DEFAULT, ({ key, format }) => {
      return {
        name: key,
        total: numeral(stats[key].total).format(format),
        average: numeral(stats[key].average).format(format),
        color: stats[key].color,
      };
    });
  }, [stats]);

  const renderContentLoader = () => {
    const width = 80;
    const height = 14;
    return (
      <Grid container justifyContent="flex-end">
        <Grid item xs={11}>
          <ContentLoader
            width={width}
            height={height}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
          >
            <rect x="0" y="0" rx="4" ry="4" width={width} height={height} />
          </ContentLoader>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <div className={classes.reportWrapper}>
        <Paper
          elevation={4}
          className={classes.report}
          style={{ maxHeight: 190, padding: 24 }}
        >
          <Grid item container>
            <Grid item container style={{ marginBottom: 8 }}>
              <Grid item container xs={6}>
                <Typography variant="h5">
                  <b>STATS</b>
                </Typography>
                <Tooltip title="Click for more info">
                  <HelpIcon
                    fontSize="small"
                    color="primary"
                    style={{ marginLeft: 8, cursor: 'pointer' }}
                    onClick={() => {
                      setShowDescription(true);
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h5" style={{ textAlign: 'right' }}>
                  <b>Total</b>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h5" style={{ textAlign: 'right' }}>
                  <b>Average</b>
                </Typography>
              </Grid>
            </Grid>
            {rowsToUse.map(row => (
              <Grid key={row.name} item container>
                <Grid item xs={6}>
                  <Typography variant="h5">{row.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                  {loading && renderContentLoader()}
                  {!loading && (
                    <Typography
                      variant="h5"
                      style={{ textAlign: 'right', color: row.color }}
                    >
                      {row.total}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3}>
                  {loading && renderContentLoader()}
                  {!loading && (
                    <Typography
                      variant="h5"
                      style={{ textAlign: 'right', color: row.color }}
                    >
                      {row.average}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </div>
      {showDescription && (
        <Dialog
          open={showDescription}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>Note</DialogTitle>
          <DialogContent dividers style={{ paddingTop: 20, paddingBottom: 20 }}>
            <DialogContentText style={{ color: palette.text.primary }}>
              Only completed data/periods will be included in the stats.&nbsp;
              <i>Efficiency</i>&nbsp;is your Billable Hours divided by your
              Total Hours
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDescription(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default BillableHoursStats;
