import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { isDefaultCompanyName, isDefaultUsername } from '../../helpers';

const UnauthRoute = ({
  component: Component,
  layout: Layout = null,
  currentAuth,
  userInfo,
  managingCompanyInfo,
  location,
  dispatch,
  redirectLink,
  ...rest
}) => {
  if (currentAuth.isLoggedIn === true) {
    if (
      location.pathname &&
      (location.pathname.startsWith('/auth/signup') ||
        location.pathname.startsWith('/auth/invitation') ||
        location.pathname.startsWith('/auth/email-sign-up'))
    ) {
      let hasCompletedOnboarding = false;

      const userBelongsToFirm = !!userInfo?.managingFirmId;

      if (userInfo && userBelongsToFirm) {
        // User has already been through onboarding if they belong to a firm
        // FUTURE: we will need to add default username check if adding back multi-user support
        hasCompletedOnboarding = true;
      }

      if (userInfo && managingCompanyInfo) {
        if (!isDefaultCompanyName(managingCompanyInfo?.managingCompanyName)) {
          hasCompletedOnboarding = true;
        }

        if (managingCompanyInfo.hasProjectManagementProduct) {
          // Only users with project management have to set up a username
          if (userInfo.email && !isDefaultUsername(userInfo.username)) {
            hasCompletedOnboarding = true;
          }
        }
      }

      if (!hasCompletedOnboarding) {
        // Force to onboarding rather than sending them to saved auth route
        dispatch({
          type: 'LINK_REDIRECT',
          payload: null,
        });
        return <Redirect to="/onboarding" />;
      }
    }

    if (redirectLink) {
      dispatch({
        type: 'LINK_REDIRECT',
        payload: null,
      });
      return <Redirect to={redirectLink} />;
    }

    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      render={matchProps =>
        Layout ? (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Component {...matchProps} />
        )
      }
    />
  );
};

function mapStateToProps(state) {
  return {
    currentAuth: state.currentAuth,
    redirectLink: state.appState.redirectLink,
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
    userInfo: state.userInfo,
  };
}

export default connect(mapStateToProps)(UnauthRoute);
