import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Jrn}
  query ListProjectsAndSubprojects(
    $companyId: String!
    $first: Int
    $after: String
    $archived: Boolean
  ) {
    listProjectsAndSubprojects(
      input: {
        companyId: $companyId
        first: $first
        after: $after
        archived: $archived
      }
    ) {
      items {
        ...JrnBaseFields
      }
      nextToken
    }
  }
`;
