import React, { useState, useRef, useMemo } from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo-hooks';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { GoogleApiWrapper } from 'google-maps-react';

import LevelSupportEmailAddressLink from '../../../../../components/level-support-email-address-link/level-support-email-address-link';
import LevelLogo from '../../../../../components/level-logo/level-logo';
import DialogTitle from '../../../../../components/dialog-title/dialog-title';
import SelectBookkeepingCopilotPrice from '../../../../../components/select-pricing-step/select-pricing-step';
import BillingAddressCapture from '../../../../../components/billing-address-capture/billing-address-capture';
import LoadingCover from '../../../../../components/LoadingCover/loadingCover';
import ListFirmClients from '../../../../../graphql/queries/list-firm-clients';
import ListFirmLicenses from '../../../../../graphql/queries/list-firm-licenses';

const ManageSubscriptionDialog = ({
  closeDialog,
  userInfo,
  managingCompanyInfo,
  firmInfo,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [whatToShow, setWhatToShow] = useState('initialMessage');
  const [companyBillingAddress, setCompanyBillingAddress] = useState(null);
  const onClose = () => {
    closeDialog();
  };

  const goToBillingAddressCapture = () => {
    setWhatToShow('billingAddress');
  };

  const billingAddressFormRef = useRef(null);

  const userBelongsToFirm = !!userInfo?.managingFirmId;
  const companyInfo = userBelongsToFirm ? firmInfo : managingCompanyInfo;

  const { data: listFirmLicensesData } = useQuery(ListFirmLicenses, {
    skip: !userBelongsToFirm,
    fetchPolicy: 'cache-and-network',
  });
  const { data: listFirmClientsData } = useQuery(ListFirmClients, {
    fetchPolicy: 'cache-and-network',
  });

  const requiredLicenseCount = useMemo(() => {
    if (!userBelongsToFirm) {
      return 1;
    }

    const firmLicenses = _.get(
      listFirmLicensesData,
      'listFirmLicenses.items',
      null
    );

    const firmClients = _.get(
      listFirmClientsData,
      'listFirmClients.items',
      null
    );

    let clientsWithoutALicense = 0;
    let availableLicenses = 0;
    if (firmLicenses && firmClients) {
      availableLicenses = _.filter(
        firmLicenses,
        ({ isActive, companyId }) => isActive && !companyId
      ).length;

      _.forEach(firmClients, client => {
        const clientHasLicense = _.some(
          firmLicenses,
          ({ companyId }) => companyId === client.companyId
        );

        if (!clientHasLicense) {
          clientsWithoutALicense += 1;
        }
      });
    }

    return Math.max(clientsWithoutALicense - availableLicenses, 0);
  }, [userBelongsToFirm, listFirmLicensesData, listFirmClientsData]);

  const redirectLink = `${window.location.origin}/${
    userBelongsToFirm ? 'firm' : 'alerts'
  }`;

  return (
    <Dialog aria-labelledby="simple-dialog-title" open fullWidth maxWidth="sm">
      <DialogTitle onClose={onClose} />
      <DialogContent>
        <Grid container direction="column" style={{ paddingBottom: 16 }}>
          <Grid
            item
            container
            justifyContent="center"
            style={{ width: '100%' }}
          >
            <LevelLogo logoVariation="tagline" style={{ maxWidth: 240 }} />
          </Grid>

          {whatToShow === 'initialMessage' && (
            <>
              <Grid
                container
                style={{ paddingTop: 36 }}
                direction="column"
                spacing={4}
              >
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{ fontSize: 16, textAlign: 'center' }}
                  >
                    Thank you for choosing to purchase a Level Subscription.
                    This shouldn&apos;t take more than a few minutes. Let&apos;s
                    get you started!
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{ fontSize: 16, textAlign: 'center' }}
                  >
                    If you have any issues, please contact Level Support at{' '}
                    <LevelSupportEmailAddressLink />.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                style={{ width: '100%' }}
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={goToBillingAddressCapture}
                >
                  Next
                </Button>
              </Grid>
            </>
          )}

          {whatToShow === 'billingAddress' && (
            <Grid container item style={{ width: '100%' }} direction="column">
              <Grid item>
                <Typography
                  variant="body1"
                  style={{ marginTop: 36, width: '100%' }}
                >
                  Please enter your billing address to continue.
                </Typography>
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <BillingAddressCapture
                  formRef={billingAddressFormRef}
                  companyInfo={companyInfo}
                  setLoading={setIsLoading}
                  hideCompanyAddressInput
                  isFirm={userBelongsToFirm}
                />
              </Grid>
              <Grid
                container
                item
                style={{ width: '100%' }}
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    // submit the form and if it's valid, then go to pricing
                    const form = billingAddressFormRef.current;
                    form.submitForm();
                    const validForm = form.isFormValid();
                    const dirtyForm = form.isFormDirty();
                    if (validForm && dirtyForm) {
                      // need to make sure the address is set first
                      setCompanyBillingAddress(form.values);
                      setWhatToShow('pricing');
                    }
                  }}
                  disabled={isLoading}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}

          {whatToShow === 'pricing' && (
            <Grid container style={{ marginBottom: 24 }}>
              <SelectBookkeepingCopilotPrice
                companyInfo={companyInfo}
                setLoading={setIsLoading}
                companyBillingAddress={companyBillingAddress}
                stepBack={() => setWhatToShow('billingAddress')}
                hideLogo
                customEndpoints={{
                  success: redirectLink,
                  cancel: redirectLink,
                }}
                isFirm={userBelongsToFirm}
                defaultQuantity={requiredLicenseCount}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      {isLoading && <LoadingCover />}
    </Dialog>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  firmInfo: state.appState.firmInfo,
  managingCompanyInfo: state.appState.managingCompanyInfo,
});

const load = () => (
  <Dialog aria-labelledby="simple-dialog-title" open fullWidth maxWidth="sm">
    <DialogTitle />
    <DialogContent>
      <Grid container direction="column" style={{ paddingBottom: 160 }}>
        <Grid item container justifyContent="center" style={{ width: '100%' }}>
          <LevelLogo logoVariation="tagline" style={{ maxWidth: 240 }} />
        </Grid>
      </Grid>
      <LoadingCover />
    </DialogContent>
  </Dialog>
);

export default compose(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    LoadingContainer: load,
  }),
  connect(mapStateToProps)
)(ManageSubscriptionDialog);
