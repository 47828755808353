import gql from 'graphql-tag';

export default gql`
  query GetMyUserInfo($userId: ID!) {
    getMyUserInfo(userId: $userId) {
      __typename
      userId
      username
      email
      firstName
      lastName
      phoneNumber
      profilePic
      coverImage
      hasLoggedIn
      unreadCount
      userType
      type
      accessMode
      activeSessions {
        uniqueId
        dateCreated
        date
        creatorId
        assignedToId
        projectId
        projectTitle
        checkinContentId
      }
      companies
      managingFirmId
      managingFirmRole
    }
  }
`;
