import React, { useMemo, useState, useEffect } from 'react';
import _ from 'lodash';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';

import ConditionalTooltip from '../ConditionalTooltip/conditionalTooltip';
import FilterList from '../FilterSearch/filterSearch';
import { cloudinaryifyProfilePic } from '../../helpers/cloudinary';
import * as GraphQL from '../../graphql/graphql';

const useStyles = makeStyles(theme => ({
  infoSection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  iconWrapper: {
    border: '1px solid #ddd',
    borderRadius: 10,
    padding: theme.spacing(2),
  },
  moreInfoWrapper: {
    flexGrow: null,
    marginBottom: theme.spacing(2),
  },
  moreInfoTextWrapper: {
    paddingRight: 12,
  },
  selectedPermission: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.brandColorPrimary,
      color: '#fff',
    },
    '&.MuiToggleButton-root:hover': {
      backgroundColor: theme.palette.brandColorPrimary50,
      color: '#fff',
    },
  },
  paper: {
    margin: `${theme.spacing(1)}px auto 0 auto`,
    padding: theme.spacing(2),
    width: '100%',
    height: '100%',
    textAlign: 'center',
    justifyContent: 'center',
  },
  bigAvatar: {
    height: 160,
    width: 160,
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: 140,
  },
  modalTitle: {
    fontWeight: 'bold',
    color: theme.palette.brandColorPrimary,
  },
}));

const PickUserPermissionsDialog = props => {
  const {
    onClose,
    value: valueProp,
    open,
    users,
    hideViewOption,
    permissionsForContentType,
    managingCompanyInfo,
    companies,
  } = props;

  const classes = useStyles();
  const [listsOfChosenUsers, setListsOfChosenUsers] = useState(valueProp);
  const sortedUsers = useMemo(() => {
    return _.orderBy(users, [user => user.username.toLowerCase()], ['asc']);
  }, [users]);
  const [showThisData, setShowThisData] = useState([]);

  const [openWarning, setOpenWarning] = useState(false);
  const [currentCrewMember, setCurrentCrewMember] = useState(null);
  const [openListAdd, setOpenListAdd] = useState(false);
  const [useThisPic, setUseThisPic] = useState(null);

  useEffect(() => {
    if (!open) {
      setListsOfChosenUsers(valueProp);
    }
  }, [valueProp, open]);

  const company = useMemo(() => {
    if (
      !(
        managingCompanyInfo &&
        managingCompanyInfo.managingCompanyId &&
        companies &&
        companies.length !== 0
      )
    ) {
      return null;
    }

    const foundCompany = _.find(
      companies,
      ({ companyId }) => companyId === managingCompanyInfo.managingCompanyId
    );

    return foundCompany;
  }, [companies, managingCompanyInfo]);

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    // check that there is at least one admin
    if (listsOfChosenUsers.allowedToEdit < 1) {
      setOpenWarning(true);
    } else {
      onClose(listsOfChosenUsers);
    }
  };

  const handleClickOpen = user => {
    setUseThisPic(
      user.profilePic ? cloudinaryifyProfilePic(user.profilePic) : null
    );
    setOpenListAdd(true);
    setCurrentCrewMember(user);
  };

  const handleClose = () => {
    setOpenListAdd(false);
  };

  const handleChange = (userId, selectedValue) => {
    // listsOfChosenUsers[selectedValue];
    // is it already in a list
    const localValues = { ...listsOfChosenUsers };
    let existingPermission;
    if (_.includes(localValues.allowedToAdd, userId)) {
      existingPermission = 'allowedToAdd';
    } else if (_.includes(localValues.allowedToEdit, userId)) {
      existingPermission = 'allowedToEdit';
    } else if (_.includes(localValues.allowedToView, userId)) {
      existingPermission = 'allowedToView';
    }
    //  if it is
    if (existingPermission) {
      if (selectedValue && existingPermission !== selectedValue) {
        // if it is, but it's another list, remove it from that list,
        //  add it to the list it needs to be added to and DONE
        localValues[selectedValue].push(userId);
      }
      // is it the same list, if so remove it and DONE
      _.remove(localValues[existingPermission], id => id === userId);
    } else {
      // if not, add it to the list it needs to be added to and DONE
      localValues[selectedValue].push(userId);
    }
    setListsOfChosenUsers({
      allowedToEdit: [...localValues.allowedToEdit],
      allowedToAdd: [...localValues.allowedToAdd],
      allowedToView: [...localValues.allowedToView],
    });
  };

  let dialogTitle;
  let dialogDescription;
  let editInfoText;
  let addInfoText;
  let viewInfoText;
  switch (permissionsForContentType) {
    case 'template': {
      dialogTitle = 'Share your template with your crew';
      dialogDescription =
        'Tap one of the buttons beside each person to provide access to the template. The different permission levels give you control over who can do what. These settings will also apply to all sub-projects within the template.';
      editInfoText = `Admin - can change template settings, add/edit all template content, and create projects from this template.`;
      addInfoText = `Add Only - can add content, edit content they've added, and create projects from this template.`;
      viewInfoText = `View Only - can only view and create projects from this template.`;
      break;
    }
    case 'conversation': {
      dialogTitle = 'Choose user permissions';
      dialogDescription =
        'Tap one of the buttons beside each person to add them to the conversation. The different permission levels give you control over who can do what.';
      editInfoText = `Admin - can edit the conversation details and user access, as well as read and send messages.`;
      addInfoText = `Add Only - can read and send messages in the conversation.`;
      viewInfoText = null;
      break;
    }
    default: {
      dialogTitle = 'Choose user permissions';
      dialogDescription =
        'Tap one of the buttons beside each person to add them to the project. The different permission levels give you control over who can do what.';
      editInfoText = `Admin - can edit the project details, all content, and user access, and can access all project financials.`;
      addInfoText = `Add Only - can add to a project, edit their own content, and has limited access to project financials`;
      viewInfoText = `View Only - can only view content and take part in the chat.`;
      break;
    }
  }

  const forTemplate = permissionsForContentType === 'template';

  const userRow = (user, index) => {
    // Allowed as admin
    const canBeProjectAdmin = _.includes(company?.admins, user.userId);

    let existingPermission;
    if (_.includes(listsOfChosenUsers.allowedToAdd, user.userId)) {
      existingPermission = 'allowedToAdd';
    } else if (_.includes(listsOfChosenUsers.allowedToEdit, user.userId)) {
      existingPermission = 'allowedToEdit';
    } else if (_.includes(listsOfChosenUsers.allowedToView, user.userId)) {
      existingPermission = 'allowedToView';
    }

    return (
      <ListItem
        key={user.userId}
        style={{
          backgroundColor:
            index % 2 === 0 ? 'rgb(247, 247, 247)' : 'transparent',
        }}
      >
        <Button
          onClick={() => {
            handleClickOpen(user);
          }}
          style={{ flex: 1 }}
        >
          <ListItemAvatar>
            <Avatar
              alt={`Profile pic for ${user.username}`}
              src={user.profilePic}
            >
              {!user.profilePic
                ? user.username && user.username.substring(0, 1)
                : null}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            id={user.userId}
            primary={user.username}
            style={{ textAlign: 'left', textTransform: 'none' }}
          />
        </Button>

        <ToggleButtonGroup
          value={existingPermission} // need to figure out if they have an existing permission
          exclusive
          aria-label="permission selection"
          size="small"
          style={{ marginLeft: 15 }}
        >
          <ConditionalTooltip
            showIf={!canBeProjectAdmin}
            title="Only available for company admins"
          >
            {/* Note: Using <span> here allows us to show tooltip when the button is disabled.
              However the buttonGroup change event is broken. So we handle click event separately */}
            <span>
              <ToggleButton
                disabled={!canBeProjectAdmin}
                value="allowedToEdit"
                selected={existingPermission === 'allowedToEdit'}
                aria-label="allowed to edit"
                classes={{ selected: classes.selectedPermission }}
                size="small"
                onClick={() => {
                  handleChange(user.userId, 'allowedToEdit');
                }}
                style={{ borderRadius: '4px 0 0 4px', borderRight: 'none' }}
              >
                <EditIcon />
              </ToggleButton>
            </span>
          </ConditionalTooltip>
          <ConditionalTooltip
            showIf={canBeProjectAdmin && existingPermission !== 'allowedToAdd'}
            title="Not available for company admins"
          >
            <span>
              <ToggleButton
                disabled={
                  (canBeProjectAdmin &&
                    existingPermission !== 'allowedToAdd') ||
                  (!canBeProjectAdmin && forTemplate)
                }
                value="allowedToAdd"
                selected={existingPermission === 'allowedToAdd'}
                aria-label="allowed to add"
                classes={{ selected: classes.selectedPermission }}
                size="small"
                onClick={() => {
                  handleChange(user.userId, 'allowedToAdd');
                }}
                style={{ borderRadius: 0 }}
              >
                <AddIcon />
              </ToggleButton>
            </span>
          </ConditionalTooltip>
          {!hideViewOption && (
            <ConditionalTooltip
              showIf={
                canBeProjectAdmin && existingPermission !== 'allowedToView'
              }
              title="Not available for company admins"
            >
              <span>
                <ToggleButton
                  disabled={
                    (canBeProjectAdmin &&
                      existingPermission !== 'allowedToView') ||
                    (!canBeProjectAdmin && forTemplate)
                  }
                  value="allowedToView"
                  selected={existingPermission === 'allowedToView'}
                  aria-label="allowed to view"
                  classes={{ selected: classes.selectedPermission }}
                  size="small"
                  onClick={() => {
                    handleChange(user.userId, 'allowedToView');
                  }}
                  style={{ borderRadius: '0 4px 4px 0', borderLeft: 'none' }}
                >
                  <VisibilityIcon />
                </ToggleButton>
              </span>
            </ConditionalTooltip>
          )}
        </ToggleButtonGroup>
      </ListItem>
    );
  };

  return (
    <>
      <Dialog
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onClose(event, reason);
          }
        }}
        disableEscapeKeyDown
        maxWidth="sm"
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogTitle id="confirmation-dialog-title" disableTypography>
          <Typography
            variant="h4"
            className={classes.modalTitle}
            align="center"
          >
            {dialogTitle}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1">{dialogDescription}</Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            className={classes.infoSection}
          >
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.iconWrapper}
              >
                <EditIcon />
                <Typography variant="body1">Admin</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.iconWrapper}
              >
                <AddIcon />
                <Typography variant="body1">Can Add</Typography>
              </Grid>
            </Grid>
            {!hideViewOption && (
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.iconWrapper}
                >
                  <VisibilityIcon />
                  <Typography variant="body1">
                    {forTemplate ? 'Use Only' : 'View Only'}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Accordion className={classes.moreInfoWrapper}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>More Info...</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body1"
                className={classes.moreInfoTextWrapper}
              >
                {editInfoText}
              </Typography>
              <Typography
                variant="body1"
                className={classes.moreInfoTextWrapper}
              >
                {addInfoText}
              </Typography>
              {!hideViewOption && (
                <Typography
                  variant="body1"
                  className={classes.moreInfoTextWrapper}
                >
                  {viewInfoText}
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
          <FilterList
            data={sortedUsers}
            passBack={setShowThisData}
            searchBy="username"
            isUserSearch
          />

          <List dense>{showThisData.map(userRow)}</List>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
        {/* Warning dialog */}
        <Dialog
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          aria-labelledby="warning-dialog-title"
        >
          <DialogTitle id="warning-dialog-title">Sorry, but...</DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}
            >
              A project needs to have at least one admin user&nbsp;
              <EditIcon />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenWarning(false)}
              color="primary"
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
      {currentCrewMember && (
        <Dialog
          open={openListAdd}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <Grid container spacing={3} className={classes.paper}>
            <Grid container justifyContent="flex-end">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              {useThisPic ? (
                <Avatar
                  alt="user profile image"
                  src={useThisPic}
                  className={classes.bigAvatar}
                />
              ) : (
                <Avatar
                  alt="user profile image"
                  style={{ backgroundColor: '#bdbdbd' }}
                  className={classes.bigAvatar}
                >
                  {currentCrewMember.username &&
                    currentCrewMember.username.substring(0, 1)}
                </Avatar>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className={classes.crewName}>
                {currentCrewMember.username}
              </div>
            </Grid>
          </Grid>
        </Dialog>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    managingCompanyInfo: state.appState.managingCompanyInfo,
  };
}

export default compose(GraphQL.GetCompanyInfoAction)(
  connect(mapStateToProps)(PickUserPermissionsDialog)
);
