import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import _ from 'lodash';
import {
  ACCOUNTING_METHOD,
  GRAPH_TYPE,
  REPORT_TIME_BASIS,
  SCOREBOARD_NAME,
} from '../../../config/appDefaults';
import { Dashboard } from '../../dashboard';
import Graph, { processXYTypes } from '../../graph';
import GetProjectProfitabilityData from '../../../graphql/queries/get-project-profitability-data';
import {
  GetCompanyTargetsAction,
  ListScoreboardSettingsAction,
} from '../../../graphql/graphql';
import ProjectProfitabilityControlBar from './project-profitability-control-bar';
import {
  projectProfitabilityGraphs,
  PROJECT_PROFITABILITY_GRAPH,
} from './project-profitability-graphs';
import palette from '../../../theme/palette';
import ProjectProfitabilityReport from './project-profitability-report';
import {
  generateDailyValues,
  generateGraphRange,
} from './project-profitability-graphs.utils';

const title = 'Project Scoreboard';

const ProjectProfitabilityScoreboard = ({
  projectInfo,
  mode,
  companyTargets,
  managingCompanyInfo,
  scoreboardSettings,
}) => {
  const [accountingMethod, setAccountingMethod] = useState(null);
  const [showSecondary, setShowSecondary] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const projectId = projectInfo && projectInfo.contentId;

  useEffect(() => {
    if (scoreboardSettings) {
      // Set defaults
      let updatedAccountingMethod = ACCOUNTING_METHOD.CASH.key;

      // Find settings for scoreboard
      const projectProfitabilityScoreboardSettings = _.find(
        scoreboardSettings,
        ({ scoreboardName }) =>
          scoreboardName === SCOREBOARD_NAME.PROJECT_PROFITABILITY
      );

      // Override accounting method default with user settings
      if (
        projectProfitabilityScoreboardSettings &&
        projectProfitabilityScoreboardSettings.accountingMethod
      ) {
        const companyAccountingMethodSetting = _.find(
          ACCOUNTING_METHOD,
          ({ value }) =>
            value === projectProfitabilityScoreboardSettings.accountingMethod
        );
        if (companyAccountingMethodSetting) {
          updatedAccountingMethod = companyAccountingMethodSetting.key;
        }
      }

      setAccountingMethod(updatedAccountingMethod);
    }
  }, [scoreboardSettings]);

  const targetsByKey = {};
  if (companyTargets) {
    _.each(projectProfitabilityGraphs, ({ name: reportName }) => {
      const targetName = `${reportName}:${projectId}`;
      targetsByKey[targetName] = _.find(
        companyTargets,
        ({ name }) => name === targetName
      );
    });
  }

  const ProjectProfitabilityQuery = useQuery(GetProjectProfitabilityData, {
    skip:
      !(managingCompanyInfo && managingCompanyInfo.managingCompanyId) ||
      !projectId,
    variables: {
      companyId: managingCompanyInfo && managingCompanyInfo.managingCompanyId,
      projectId,
    },
    fetchPolicy: 'network-only',
  });

  const projectProfitabilityData =
    ProjectProfitabilityQuery.data &&
    ProjectProfitabilityQuery.data.getProjectProfitabilityData &&
    ProjectProfitabilityQuery.data.getProjectProfitabilityData.items;

  const reportData = useMemo(() => {
    const compiledReportData = { cash: null, accrual: null, overview: null };

    if (projectProfitabilityData) {
      const processedReportData = {};

      // Check if project data ranges exceed the project start and end date
      _.forEach(
        [
          'projectProfitabilityReportCash',
          'projectProfitabilityReportAccrual',
          'overviewData',
        ],
        reportName => {
          // Process report data
          const data = _.cloneDeep(
            _.find(projectProfitabilityData, ({ name }) => name === reportName)
          );

          if (data) {
            data.values = processXYTypes(
              data.values,
              data.labels.x,
              data.labels.y
            );
          }

          processedReportData[reportName] = data;
        }
      );

      // Generate the date range for the graph to use
      const { startDate, endDate, daysToPad } = generateGraphRange(
        projectInfo,
        _.values(processedReportData)
      );

      // Generate the data sets for the graph to use
      _.forEach(processedReportData, (seriesData, reportName) => {
        const data = seriesData;

        if (data) {
          if (data.values && data.values.length > 0) {
            data.values = generateDailyValues(startDate, endDate, data.values, {
              daysToPad,
            });
          }

          if (reportName === 'projectProfitabilityReportCash') {
            compiledReportData.cash = data;
          } else if (reportName === 'projectProfitabilityReportAccrual') {
            compiledReportData.accrual = data;
          } else if (reportName === 'overviewData') {
            compiledReportData.overview = data;
          }
        }
      });
    }

    return compiledReportData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectProfitabilityData]);

  const projectProfitability = _.find(
    projectProfitabilityGraphs,
    ({ name }) => name === PROJECT_PROFITABILITY_GRAPH.PROJECT_PROFITABILITY
  );

  // TO CONSIDER: Change color based on rating

  const projectProfitabilitySeries = [];
  if (
    reportData &&
    reportData.cash &&
    reportData.accrual &&
    reportData.overview
  ) {
    let primaryReportData;
    let secondaryReportData;
    let primarySeriesName;
    let secondarySeriesName;
    const primaryColorFrom = palette.brandColorPrimary;
    const primaryColorTo = palette.brandColorPrimary50;
    const secondaryColor = palette.brandColorOrange;

    if (accountingMethod === ACCOUNTING_METHOD.CASH.key) {
      primaryReportData = reportData.cash;
      secondaryReportData = reportData.accrual;
      primarySeriesName = 'Cash Profit';
      secondarySeriesName = 'Accrued Profit';
    } else {
      primaryReportData = reportData.accrual;
      secondaryReportData = reportData.cash;
      primarySeriesName = 'Accrued Profit';
      secondarySeriesName = 'Cash Profit';
    }

    projectProfitabilitySeries.push({
      data: primaryReportData.values,
      dataType: primaryReportData.labels,
      type: GRAPH_TYPE.AREA,
      name: primarySeriesName,
      fromColor: primaryColorFrom,
      toColor: primaryColorTo,
      dataBasis: REPORT_TIME_BASIS.DAYS,
    });
    if (showSecondary) {
      projectProfitabilitySeries.push({
        data: secondaryReportData.values,
        dataType: secondaryReportData.labels,
        type: GRAPH_TYPE.LINE,
        name: secondarySeriesName,
        color: secondaryColor,
        dataBasis: REPORT_TIME_BASIS.DAYS,
      });
    }
  }

  const projectProfitabilityTargetName = `${projectProfitability.name}:${projectId}`;
  const projectProfitabilityTarget =
    targetsByKey[projectProfitabilityTargetName] &&
    targetsByKey[projectProfitabilityTargetName].value;

  return (
    <Dashboard
      controlBar={
        <ProjectProfitabilityControlBar
          projectId={projectId}
          title={title}
          accountingMethod={accountingMethod}
          setShowSecondary={setShowSecondary}
          onRefresh={ProjectProfitabilityQuery.refetch}
          showSettings={showSettings}
          setShowSettings={setShowSettings}
        />
      }
      layout="bigLeft"
      mode={mode}
    >
      <Graph
        key={projectProfitability.name}
        dataSeries={projectProfitabilitySeries}
        description={projectProfitability.description}
        title={projectProfitability.label}
        target={projectProfitabilityTarget}
        loading={projectProfitabilitySeries.length === 0}
        showAverage={false}
        showTotal={false}
        noPadding
        showLegend
      />
      <ProjectProfitabilityReport
        projectInfo={projectInfo}
        reportData={reportData}
        accountingMethod={accountingMethod}
        showSecondary={showSecondary}
        target={projectProfitabilityTarget}
        setShowSettings={setShowSettings}
      />
    </Dashboard>
  );
};

ProjectProfitabilityScoreboard.title = title;

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(
  compose(
    GetCompanyTargetsAction,
    ListScoreboardSettingsAction
  )(ProjectProfitabilityScoreboard)
);
