import React, { useState, useEffect } from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';

// UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Switch as MuiSwitch,
  Tooltip,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  Add as AddIcon,
  ArchiveOutlined as ArchiveOutlinedIcon,
  Block as BlockIcon,
  Cached as CachedIcon,
  Create as CreateIcon,
  DeleteOutline as DeleteOutlineIcon,
  Equalizer as EqualizerIcon,
  MoreHoriz as MoreHorizIcon,
  Publish as PublishIcon,
  Save as SaveIcon,
  Unarchive as UnarchiveIcon,
} from '@material-ui/icons';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Papa from 'papaparse';

// utilities
import _, { uniqueId } from 'lodash';
import moment from 'moment';

// UI components
import ContentDetailsModal from '../../add-to-project/content-details-modal';
import OkCancelDialog from '../../../components/OkCancelDialog/okCancelDialog';
import ProjectStatsDialog from './project-stats-dialog';
import AdminToolsIconButton from '../../../components/admin-tools-icon-button/admin-tools-icon-button';
import AdminToolsTitle from '../../../components/admin-tools-title/admin-tools-title';
import LoadingCover from '../../../components/LoadingCover/loadingCover';
import ResponseTooBigWarning from '../../../components/response-too-big-warning/response-too-big-warning';
import { useRepetitiveQuery } from '../../../hooks';

// GraphQL
import GetCompanyAdminProjectData from '../../../graphql/queries/get-company-admin-project-data';
import {
  ArchiveCompanyProjectAction,
  DeleteJrnAction,
  ListCompanyProjectsAction,
  UnarchiveCompanyProjectAction,
} from '../../../graphql/graphql';

// Helpers
import {
  runAnalytics,
  monetaryRender,
  percentRender,
  fixedValueRender,
  applyPreferences,
} from '../../../helpers/index';

import {
  amountFilter,
  simpleSortForAmount,
} from '../../../helpers/react-datagrid-helpers';

import {
  BUDGET_TYPE_PAYMENTS,
  BUDGET_TYPE_RECEIPTS_INVOICES,
  BUDGET_TYPE_UNASSIGNED_LABOR_HOURS,
  BUDGET_TYPE_USER_LABOR_HOURS,
  CONTENT_DEFINITION,
  CONTENT_DETAILS_MODAL_MODE,
  CONTENT_TYPE,
  DEFAULT_DATE_FORMAT,
  TOP_PROJECT_ID,
} from '../../../config/appDefaults';
import themePalette from '../../../theme/palette';

import store from '../../../store';

const filterTypes = {
  ...ReactDataGrid.defaultProps.filterTypes,
  ...amountFilter,
};

// Styling
const useStyles = makeStyles(theme => ({
  scrollableColumn: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'scroll',
    height: 'calc(100vh - 64px)',
  },
  editButton: {
    padding: theme.spacing(0.5),
    margin: 0,
    minWidth: 0,
    color: '#aaa',
    '&:hover': {
      background: 'transparent',
      color: '#333',
    },
  },
  headerWrapper: {
    padding: theme.spacing(3),
  },
  actionButtonsContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    minWidth: 460,
    '& button': {
      marginLeft: theme.spacing(2),
    },
    '& div': {
      marginLeft: theme.spacing(2),
    },
  },
  headerIcon: {
    color: '#999',
    fontSize: 30,
    position: 'absolute',
    top: 5,
  },
}));

// ReactDataGrid configuration
window.moment = moment;

const basicSortInfo = {
  name: 'startDate',
  dir: -1,
  type: 'date',
};

const levelFilterValues = [
  {
    name: 'projectName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'customer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'startDate',
    operator: 'afterOrOn',
    type: 'date',
    value: '',
  },
  {
    name: 'endDate',
    operator: 'beforeOrOn',
    type: 'date',
    value: '',
  },
  {
    name: 'labels',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'admins',
    operator: 'contains',
    type: 'string',
    value: '',
  },

  {
    name: 'receiptsBillsActual',
    type: 'amount',
    operator: 'Starts With',
    value: '',
  },
  {
    name: 'receiptsBillsBudget',
    type: 'amount',
    operator: 'Starts With',
    value: '',
  },
  {
    name: 'receiptsBillsVariance',
    type: 'amount',
    operator: 'Starts With',
    value: '',
  },
  {
    name: 'receiptsBillsPercentReceived',
    type: 'amount',
    operator: 'Starts With',
    value: '',
  },
  {
    name: 'paymentsBudget',
    type: 'amount',
    operator: 'Starts With',
    value: '',
  },
  {
    name: 'paymentsReceived',
    type: 'amount',
    operator: 'Starts With',
    value: '',
  },
  {
    name: 'paymentsVariance',
    type: 'amount',
    operator: 'Starts With',
    value: '',
  },
  {
    name: 'paymentsPercentReceived',
    type: 'amount',
    operator: 'Starts With',
    value: '',
  },
  {
    name: 'laborBudget',
    type: 'amount',
    operator: 'Starts With',
    value: '',
  },
  {
    name: 'laborActual',
    type: 'amount',
    operator: 'Starts With',
    value: '',
  },
  {
    name: 'laborVariance',
    type: 'amount',
    operator: 'Starts With',
    value: '',
  },
  {
    name: 'laborPercentComplete',
    type: 'amount',
    operator: 'Starts With',
    value: '',
  },
];

const scrollProps = {
  ...ReactDataGrid.defaultProps.scrollProps,
  autoHide: false,
  scrollThumbWidth: 12,
  scrollThumbStyle: {
    background: themePalette.brandColorPrimary,
  },
};

const columnMinWidthExtraSmall = 82;
const columnWidthButtons = 112;
const columnMinWidthMedium = 180;
const columnMinWidthMediumMore = 185;
const dateTimeFormat = 'MMM D, YYYY';

// Helper functions
const capitalizeSentence = sentence => {
  const words = sentence.split(' ');
  words
    .map(word => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
  return words;
};

const buildColumnObj = options => {
  if (!options.name) return null;
  const basicColumn = {
    ...options,
    header:
      options.header !== undefined
        ? options.header
        : capitalizeSentence(options.name),
  };
  return basicColumn;
};

const downloadBlob = (blob, fileName = 'grid-data.csv') => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.position = 'absolute';
  link.style.visibility = 'hidden';

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

const ManageProjects = ({
  companyProjects,
  listCompanyProjectsRefetch,
  listCompanyProjectsLoading,
  onDeleteJrn,
  onArchiveCompanyProject,
  onUnarchiveCompanyProject,
  userInfo,
  managingCompanyInfo,
  columnSettings,
}) => {
  // Style hooks
  const classes = useStyles();

  // Ref hooks
  const [gridRef, setGridRef] = useState(null);

  // State hooks
  const [includeArchive, setIncludeArchive] = useState(false);
  const [entriesStats, setEntriesStats] = useState(null);
  const [statsDialogInfo, setStatsDialogInfo] = useState({ open: false });
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    title: '',
    message: '',
    onClose: () => {}, // Set based on context
    hideCancel: false,
    onConfirm: () => {}, // Set based on context
  });
  const [showManualAddDialog, setShowManualAddDialog] = useState({
    open: false,
    mode: CONTENT_DETAILS_MODAL_MODE.EDIT,
    parentInfo: null,
    existingInfo: null,
  });

  const activeProjectsMap = {};
  if (companyProjects) {
    companyProjects.forEach(project => {
      activeProjectsMap[project.contentId] = true;
    });
  }

  // START - get admin content
  const getCompanyAdminProjectDataAccessor = 'getCompanyAdminProjectData';
  const adminContentQuery = useRepetitiveQuery(GetCompanyAdminProjectData, {
    fetchPolicy: 'cache-and-network',
    skip: !managingCompanyInfo.managingCompanyId,
    variables: {
      companyId: managingCompanyInfo.managingCompanyId,
      includeArchive,
    },
    accessor: getCompanyAdminProjectDataAccessor,
  });

  const {
    projects: adminContent,
    users,
    adminData: reportData,
    queryNote: adminContentQueryNote,
  } = _.get(
    adminContentQuery,
    `data.${getCompanyAdminProjectDataAccessor}`,
    {}
  );

  const projectsRefresh = _.get(adminContentQuery, 'refetch');
  const projectsLoading = _.get(adminContentQuery, 'loading');

  const editThisContent = projectInfo => {
    setShowManualAddDialog({
      open: true,
      mode: CONTENT_DETAILS_MODAL_MODE.EDIT_FROM_PROJECT_ADMIN,
      parentInfo: null,
      existingInfo: projectInfo,
    });
  };

  const handleIncludeArchiveChange = event => {
    setIncludeArchive(event.target.checked);
  };

  const openAddModal = () => {
    setShowManualAddDialog({
      mode: CONTENT_DETAILS_MODAL_MODE.ADD_FROM_PROJECT_ADMIN,
      open: true,
      existingInfo: { type: 'project' },
    });
  };

  const deleteThisContent = async projectInfo => {
    setDialogInfo({
      title: 'Just making sure...',
      message: `Erasing the project will erase it for everyone else too. It will also delete all of the content in the project (including the content in any subprojects)`,
      open: true,
      onClose: () => setDialogInfo({ ...dialogInfo, open: false }),
      hideCancel: false,
      onConfirm: async () => {
        await onDeleteJrn(
          projectInfo.contentId,
          projectInfo.type,
          'project',
          null,
          includeArchive
        );
      },
    });
  };

  const archiveThisProject = async projectInfo => {
    const message = `This will archive the project for all users and hide its data from the
      calendar and admin reporting tools.`;
    setDialogInfo({
      title: 'Just making sure...',
      message,
      open: true,
      onClose: () => setDialogInfo({ ...dialogInfo, open: false }),
      hideCancel: false,
      onConfirm: async () => {
        await onArchiveCompanyProject(projectInfo.contentId, {
          fromWhichAdminTool: 'project',
          includeArchive,
        });
      },
    });
  };

  const unarchiveThisProject = async projectInfo => {
    const message = `This will unarchive the project for all users.`;
    setDialogInfo({
      title: 'Just making sure...',
      message,
      open: true,
      onClose: () => setDialogInfo({ ...dialogInfo, open: false }),
      hideCancel: false,
      onConfirm: async () => {
        await onUnarchiveCompanyProject(projectInfo.contentId, {
          fromWhichAdminTool: 'project',
          includeArchive,
          projectInfo,
        });
      },
    });
  };

  const getAdminString = projectInfo => {
    const { allowedToEdit = [] } = projectInfo;
    let missingCount = 0;
    const admins = [];

    const userListToUse = users || [];

    allowedToEdit.forEach(thisUserId => {
      const foundUser = _.find(
        userListToUse,
        ({ userId }) => thisUserId === userId
      );

      if (foundUser) {
        admins.push(foundUser);
      } else {
        missingCount += 1;
      }
    });

    let adminString = _.sortBy(admins, [
      admin => String(admin.username).toLowerCase(),
    ])
      .map(({ username }) => username)
      .join(', ');
    if (missingCount) {
      if (adminString) {
        adminString += ', ';
      }
      adminString += `${missingCount} other${missingCount === 1 ? '' : 's'}`;
    }

    return adminString;
  };

  const [dataSource, setDataSource] = useState(null);
  const [columns, setColumns] = useState(null);
  const [columnOrder, setColumnOrder] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [defaultSortInfo, setDefaultSortInfo] = useState(basicSortInfo);
  const [tableReady, setTableReady] = useState(false);
  const [tableKey, setTableKey] = useState(uniqueId());
  const resetTableKey = () => setTableKey(uniqueId());

  const buildDataGrid = ({ withoutPreferences = false } = {}) => {
    // START - preeeee
    if (!adminContent) {
      return;
    }
    // START - compiledProjectNameMap
    const projectNameMap = {};
    const hasSubprojectsMap = {};

    adminContent.forEach(project => {
      let projectName = project.title;

      if (project.jrnId && project.jrnId !== TOP_PROJECT_ID) {
        hasSubprojectsMap[project.jrnId] = true;
        const parentProjectInfo = _.find(adminContent, {
          contentId: project.jrnId,
        });

        if (parentProjectInfo) {
          projectName = `${parentProjectInfo.title} > ${project.title}`;
        }
      }

      projectNameMap[project.contentId] = projectName;
    });

    // setCompiledProjectNameMap(projectNameMap);
    const compiledProjectNameMap = projectNameMap;
    // END - compiledProjectNameMap

    // START - compiledCustomerInfoMap, compiledProjectCustomerMap
    const customerInfoMap = {};
    const projectCustomerMap = {};

    const addCustomerInfoToMaps = project => {
      if (project.customerId && project.customerInfo) {
        // If has customer info, add to maps
        customerInfoMap[project.customerId] = project.customerInfo;
        projectCustomerMap[project.contentId] = project.customerId;

        return project.customerId;
      }

      if (project.jrnId !== TOP_PROJECT_ID && project.jrnId) {
        // If is subproject recursively search for parent then determine if it should have a customer
        const parentProject = _.find(
          adminContent,
          projectToCheck => projectToCheck.contentId === project.jrnId
        );

        const parentCustomerId = addCustomerInfoToMaps(parentProject);

        projectCustomerMap[project.contentId] = parentCustomerId;

        return parentCustomerId;
      }

      // If not a subproject and no customer info, there is no customer
      projectCustomerMap[project.contentId] = null;
      return null;
    };

    adminContent.forEach(project => addCustomerInfoToMaps(project));

    // setCompiledCustomerInfoMap(customerInfoMap);
    // setCompiledProjectCustomerMap(projectCustomerMap);

    const compiledCustomerInfoMap = customerInfoMap;
    const compiledProjectCustomerMap = projectCustomerMap;
    // END - compiledCustomerInfoMap, compiledProjectCustomerMap

    // START - dataSource
    let allProjects = null;

    const getCustomerName = projectId => {
      if (compiledProjectCustomerMap && compiledCustomerInfoMap) {
        const customerId = compiledProjectCustomerMap[projectId];
        if (customerId !== null) {
          const customerInfo = compiledCustomerInfoMap[customerId];
          if (customerInfo) {
            if (customerInfo.firstName || customerInfo.lastName) {
              const customerNameParts = [];
              if (customerInfo.firstName) {
                customerNameParts.push(customerInfo.firstName);
              }
              if (customerInfo.lastName) {
                customerNameParts.push(customerInfo.lastName);
              }
              return customerNameParts.join(' ');
            }
            if (customerInfo.companyName) {
              return customerInfo.companyName;
            }
          }
        }
      }

      return null;
    };

    if (adminContent) {
      let reportDataMap = null;
      if (reportData) {
        reportDataMap = {};
        reportData.forEach(perProjectReportData => {
          reportDataMap[perProjectReportData.projectId] = {
            ...perProjectReportData,
          };
        });
      }

      allProjects = adminContent.map(project => {
        // Format dates
        const startDateMoment = moment(project.startDate);
        const startDateToUse = startDateMoment.format('YYYY-MM-DDTHH:mm:ssZ');
        const endDateMoment = moment(project.endDate);
        const endDateToUse = endDateMoment.format('YYYY-MM-DDTHH:mm:ssZ');

        // Format labels
        const labels = project.labels ? project.labels.join(', ') : null;

        const crewCount = _.uniq([
          ...(project.allowedToEdit || []),
          ...(project.allowedToAdd || []),
          ...(project.allowedToView || []),
        ]).length;

        let receiptsBillsBudget = 0;
        let laborBudget = 0;
        let paymentsBudget = 0;

        if (project.budgets) {
          project.budgets.forEach(({ label, value }) => {
            switch (label) {
              case BUDGET_TYPE_UNASSIGNED_LABOR_HOURS: {
                laborBudget += value || 0;
                break;
              }
              case BUDGET_TYPE_USER_LABOR_HOURS: {
                laborBudget += value || 0;
                break;
              }
              case BUDGET_TYPE_RECEIPTS_INVOICES: {
                receiptsBillsBudget = value || 0;
                break;
              }
              case BUDGET_TYPE_PAYMENTS: {
                paymentsBudget = value || 0;
                break;
              }
              default:
                break;
            }
          });
        }

        let invoiceTotal = 0;

        let paymentsReceived = 0;
        let receiptsBillsActual = 0;
        let laborActual = 0;
        if (reportDataMap && reportDataMap[project.contentId]) {
          const projectReportData = reportDataMap[project.contentId];
          receiptsBillsActual = projectReportData.receiptAndBillTotal;
          laborActual = projectReportData.timetrackingTotal;
          paymentsReceived = projectReportData.paymentTotal;
          invoiceTotal = projectReportData.invoiceTotal;
        }

        let receiptsBillsVariance = 0;
        let receiptsBillsPercentReceived = 0;
        if (receiptsBillsBudget) {
          receiptsBillsVariance = receiptsBillsBudget - receiptsBillsActual;
          receiptsBillsPercentReceived =
            (receiptsBillsActual / receiptsBillsBudget) * 100; // need to do this here so the filtering workings properly (e.g. filtering 0.23 vs 23)
        }

        let paymentsVariance = 0;
        let paymentsPercentReceived = 0;
        if (paymentsBudget) {
          paymentsVariance = (paymentsBudget - paymentsReceived) * -1;
          paymentsPercentReceived = (paymentsReceived / paymentsBudget) * 100;
        }

        let laborVariance = 0;
        let laborPercentComplete = 0;
        if (laborBudget) {
          laborVariance = laborBudget - laborActual;
          laborPercentComplete = (laborActual / laborBudget) * 100;
        }

        const admins = getAdminString(project);

        // add up info modal items

        return {
          projectId: project.contentId,
          projectName: compiledProjectNameMap[project.contentId],
          hasSubprojects: !!hasSubprojectsMap[project.contentId],
          customer: getCustomerName(project.contentId),
          startDate: startDateToUse,
          endDate: endDateToUse,
          labels,
          admins,
          crewCount,

          receiptsBillsActual,
          receiptsBillsBudget,
          receiptsBillsVariance,
          receiptsBillsPercentReceived,

          paymentsBudget,
          paymentsReceived,
          paymentsVariance,
          paymentsPercentReceived,

          laborBudget,
          laborActual,
          laborVariance,
          laborPercentComplete,

          invoiceTotal,

          parentId: project.jrnId,
          contentItem: project,
        };
      });
      setDataSource(allProjects);
    }
    // END - dataSource

    // END - preeeee

    const buildColumnsInfo = () => {
      const uniqueProjectNames = [];

      _.forEach(compiledProjectNameMap, projectName => {
        if (!uniqueProjectNames.includes(projectName)) {
          uniqueProjectNames.push(projectName);
        }
      });

      // Sort project names
      uniqueProjectNames.sort((a, b) => {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });

      // prep data by allowlisting wanted attributes
      const reportColumns = [
        {
          name: 'edit',
          header: null,
          minWidth: columnWidthButtons,
          maxWidth: columnWidthButtons,
          render: ({ data }) => {
            const isSubproject = data.contentItem.jrnId !== TOP_PROJECT_ID;
            const isArchived = !activeProjectsMap[data.contentItem.contentId];

            const canDelete =
              !data.hasSubprojects &&
              !data.paymentsReceived &&
              !data.invoiceTotal &&
              !data.laborActual &&
              !data.receiptsBillsActual;

            return (
              <>
                {canDelete && (
                  <Tooltip title="Delete Project">
                    <Button
                      onClick={() => {
                        deleteThisContent(data.contentItem);
                      }}
                      className={classes.editButton}
                    >
                      <DeleteOutlineIcon />
                    </Button>
                  </Tooltip>
                )}
                {!isSubproject && (
                  <Tooltip
                    title={isArchived ? 'Unarchive Project' : 'Archive Project'}
                  >
                    <Button
                      onClick={() => {
                        if (isArchived) {
                          unarchiveThisProject(data.contentItem);
                        } else {
                          archiveThisProject(data.contentItem);
                        }
                      }}
                      className={classes.editButton}
                    >
                      {isArchived ? <UnarchiveIcon /> : <ArchiveOutlinedIcon />}
                    </Button>
                  </Tooltip>
                )}
                <Tooltip title="Edit Project">
                  <Button
                    onClick={() => {
                      editThisContent(data.contentItem);
                    }}
                    className={classes.editButton}
                  >
                    <CreateIcon />
                  </Button>
                </Tooltip>
              </>
            );
          },
        },
        { name: 'projectId', header: 'Project Id', defaultVisible: false },
        {
          name: 'projectName',
          header: 'Title',
          defaultFlex: 30,
          minWidth: columnMinWidthMedium,
          render: ({ value, data }) => {
            return (
              <a
                href={`/projects/${data.projectId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="basicStyledLink"
              >
                {value}
              </a>
            );
          },
        },
        {
          name: 'customer',
          header: 'Customer',
          defaultFlex: 30,
          minWidth: columnMinWidthMedium,
          render: ({ value, data }) => {
            if (!value) {
              return '';
            }
            return (
              <a
                href={`/customers/${
                  compiledProjectCustomerMap[data.projectId]
                }`}
                target="_blank"
                rel="noopener noreferrer"
                className="basicStyledLink"
              >
                {value}
              </a>
            );
          },
        },
        {
          name: 'startDate',
          header: 'Start Date',
          dateFormat: 'YYYY-MM-DD',
          filterEditor: DateFilter,
          filterEditorProps: () => {
            // for range and notinrange operators, the index is 1 for the after field
            return {
              cancelButton: false,
              highlightWeekends: false,
            };
          },
          defaultFlex: 20,
          minWidth: columnMinWidthMedium,
          render: ({ value }) => {
            if (!value) {
              return 'n/a';
            }
            return moment(value).format(dateTimeFormat);
          },
        },
        {
          name: 'endDate',
          header: 'End Date',
          dateFormat: 'YYYY-MM-DD',
          filterEditor: DateFilter,
          filterEditorProps: () => {
            // for range and notinrange operators, the index is 1 for the after field
            return {
              cancelButton: false,
              highlightWeekends: false,
            };
          },
          defaultFlex: 20,
          minWidth: columnMinWidthMedium,
          render: ({ value }) => {
            if (!value) {
              return 'n/a';
            }
            return moment(value).format(dateTimeFormat);
          },
        },
        {
          name: 'labels',
          header: 'Labels',
          defaultFlex: 10,
          minWidth: columnMinWidthMediumMore,
        },
        {
          name: 'admins',
          header: 'Admins',
          defaultFlex: 10,
          minWidth: columnMinWidthExtraSmall,
        },
        {
          name: 'crewCount',
          header: 'Crew',
          type: 'number',
          defaultFlex: 10,
          textAlign: 'center',
          minWidth: columnMinWidthExtraSmall,
        },
        {
          name: 'paymentsReceived',
          header: 'Payments Received',
          type: 'amount',
          defaultFlex: 10,
          textAlign: 'center',
          minWidth: columnMinWidthMediumMore,
          render: monetaryRender,
          sort: simpleSortForAmount,
        },
        {
          name: 'paymentsBudget',
          header: 'Quoted',
          type: 'amount',
          defaultFlex: 10,
          textAlign: 'center',
          minWidth: columnMinWidthMediumMore,
          render: monetaryRender,
          sort: simpleSortForAmount,
        },
        {
          name: 'paymentsVariance',
          header: 'Payment Variance',
          type: 'amount',
          defaultFlex: 10,
          textAlign: 'center',
          minWidth: columnMinWidthMediumMore,
          render: monetaryRender,
          sort: simpleSortForAmount,
        },
        {
          name: 'paymentsPercentReceived',
          header: '% Received',
          type: 'amount',
          defaultFlex: 10,
          textAlign: 'center',
          minWidth: columnMinWidthMediumMore,
          render: ({ value }) => {
            return percentRender({
              value,
              decimalPlaces: 2,
              alreadyTimes100: true,
            });
          },
          sort: simpleSortForAmount,
        },
        {
          name: 'receiptsBillsActual',
          header: 'Actual - Receipts/Bills',
          type: 'amount',
          defaultFlex: 10,
          textAlign: 'center',
          minWidth: columnMinWidthMediumMore,
          render: monetaryRender,
          sort: simpleSortForAmount,
        },
        {
          name: 'receiptsBillsBudget',
          header: 'Budget - Receipt/Bills',
          type: 'amount',
          defaultFlex: 10,
          textAlign: 'center',
          minWidth: columnMinWidthMediumMore,
          render: monetaryRender,
          sort: simpleSortForAmount,
        },
        {
          name: 'receiptsBillsVariance',
          header: 'Receipt/Bill Variance',
          type: 'amount',
          textAlign: 'center',
          defaultFlex: 10,
          minWidth: columnMinWidthMediumMore,
          render: monetaryRender,
          sort: simpleSortForAmount,
        },
        {
          name: 'receiptsBillsPercentReceived',
          header: '% Spent',
          type: 'amount',
          defaultFlex: 10,
          textAlign: 'center',
          minWidth: columnMinWidthMediumMore,
          render: ({ value }) => {
            return percentRender({
              value,
              decimalPlaces: 2,
              alreadyTimes100: true,
            });
          },
          sort: simpleSortForAmount,
        },
        {
          name: 'laborActual',
          header: 'Actual - Labor Hours',
          type: 'amount',
          defaultFlex: 10,
          textAlign: 'center',
          minWidth: columnMinWidthMedium,
          render: fixedValueRender,
          sort: simpleSortForAmount,
        },
        {
          name: 'laborBudget',
          header: 'Budget - Labor Hours',
          type: 'amount',
          defaultFlex: 10,
          textAlign: 'center',
          minWidth: columnMinWidthMedium,
          render: fixedValueRender,
          sort: simpleSortForAmount,
        },
        {
          name: 'laborVariance',
          header: 'Labor Variance',
          type: 'amount',
          defaultFlex: 10,
          textAlign: 'center',
          minWidth: columnMinWidthMediumMore,
          render: fixedValueRender,
          sort: simpleSortForAmount,
        },
        {
          name: 'laborPercentComplete',
          header: '% Worked',
          type: 'amount',
          defaultFlex: 10,
          textAlign: 'center',
          minWidth: columnMinWidthMediumMore,
          render: ({ value }) => {
            return percentRender({
              value,
              decimalPlaces: 2,
              alreadyTimes100: true,
            });
          },
          sort: simpleSortForAmount,
        },
      ];

      if (!managingCompanyInfo.managingCompanyId) {
        _.remove(reportColumns, ({ name }) =>
          ['laborActual', 'receiptsBillsActual'].includes(name)
        );
      }

      return reportColumns.map(columnDefinition =>
        buildColumnObj(columnDefinition)
      );
    };
    const columnsInfo = buildColumnsInfo();

    const {
      newColumnsInfo: newColumns,
      newSortInfo,
      newFilterInfo,
      newColumnOrder,
    } = applyPreferences({
      preferences: withoutPreferences ? null : columnSettings,
      defaultColumnsInfo: columnsInfo,
      defaultFilterInfo: levelFilterValues,
      defaultSortInfo: basicSortInfo,
      defaultColumnOrder: null,
    });

    if (!tableReady || withoutPreferences) {
      setColumns(newColumns);
      setDefaultSortInfo(newSortInfo);
      setFilterValue(newFilterInfo);
      setColumnOrder(newColumnOrder);

      resetTableKey(); // this is to force the table to re-render
    }

    if (!tableReady) {
      setTableReady(true);
    }
  };

  useEffect(() => {
    if (adminContent) {
      buildDataGrid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminContent]);

  const exportCSV = (allOrVisibleColumns = 'allColumns') => {
    const monetaryRenders = [
      'receiptsBillsActual',
      'receiptsBillsBudget',
      'receiptsBillsVariance',
      'paymentsBudget',
      'paymentsReceived',
      'paymentsVariance',
    ];
    const percentRenders = [
      'receiptsBillsPercentReceived',
      'paymentsPercentReceived',
      'laborPercentComplete',
    ];
    const fixedValueRenders = ['laborBudget', 'laborActual', 'laborVariance'];
    const currentColumns = gridRef.current[allOrVisibleColumns];
    const columnsToUpdate = {
      projectLatLon: { header: 'Project Address Link' },
    };
    currentColumns.forEach((columnInfo, index) => {
      if (columnsToUpdate[columnInfo.name]) {
        currentColumns[index].header = columnsToUpdate[columnInfo.name].header;
      }
    });

    // remove the delete/edit column
    _.remove(currentColumns, column => column.name === 'edit');

    const rows = gridRef.current.data.map(data => {
      return currentColumns.map(column => {
        if (column.id === 'startDate' || column.id === 'endDate') {
          return moment(data[column.id]).format(DEFAULT_DATE_FORMAT);
        }
        if (monetaryRenders.includes(column.id)) {
          return monetaryRender({ value: data[column.id] });
        }
        if (percentRenders.includes(column.id)) {
          return percentRender({
            value: data[column.id],
            alreadyTimes100: true,
          });
        }
        if (fixedValueRenders.includes(column.id)) {
          return fixedValueRender({ value: data[column.id] });
        }

        // default case
        return data[column.id];
      });
    });

    const csvOutput = Papa.unparse({
      fields: currentColumns.map(column => column.header),
      data: rows,
    });

    const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8;' });

    const filename = `project-export-${moment().format(
      'YYYY-MM-DD-HH-mm-ss'
    )}.csv`;

    downloadBlob(blob, filename);
    runAnalytics('Contents', {
      contentAction: 'Export Content',
      userId: userInfo.userId,
      username: userInfo.username,
      type: 'Admin Projects',
    });
  };

  const calcInfoStats = () => {
    const statsInfo = {
      actual: {
        paymentsTotal: 0,
        paymentsTotalAvg: 0,
        receiptsAndBillsTotal: 0,
        receiptsAndBillsTotalAvg: 0,
        laborTotal: 0,
        laborTotalAvg: 0,
      },
      budget: {
        paymentsTotal: 0,
        paymentsTotalAvg: 0,
        receiptsAndBillsTotal: 0,
        receiptsAndBillsTotalAvg: 0,
        laborTotal: 0,
        laborTotalAvg: 0,
      },
      count: {
        paymentsBudgets: 0,
        paymentsActuals: 0,
        receiptsAndBillsBudgets: 0,
        receiptsAndBillsActuals: 0,
        laborBudgets: 0,
        laborActuals: 0,
      },
      variance: {
        paymentsTotal: 0,
        // paymentsTotalAvg: 0,
        receiptsAndBillsTotal: 0,
        // receiptsAndBillsTotalAvg: 0,
        laborTotal: 0,
        // laborTotalAvg: 0,
      },
      percentCompleted: {
        payments: 0,
        receiptsAndBills: 0,
        labor: 0,
      },
    };

    let reportDataMap = null;
    if (reportData) {
      reportDataMap = {};
      reportData.forEach(perProjectReportData => {
        reportDataMap[perProjectReportData.projectId] = {
          ...perProjectReportData,
        };
      });
    }

    gridRef.current.data.forEach(project => {
      // get actual for payments, receipts, labor
      if (project.paymentsReceived) {
        statsInfo.actual.paymentsTotal += project.paymentsReceived || 0;
        statsInfo.count.paymentsActuals += 1;
      }
      if (project.receiptsBillsActual) {
        statsInfo.actual.receiptsAndBillsTotal +=
          project.receiptsBillsActual || 0;
        statsInfo.count.receiptsAndBillsActuals += 1;
      }
      if (project.laborActual) {
        statsInfo.actual.laborTotal += project.laborActual || 0;
        statsInfo.count.laborActuals += 1;
      }

      // get budget for payments, receipts, labor
      if (project.paymentsBudget) {
        statsInfo.budget.paymentsTotal += project.paymentsBudget || 0;
        statsInfo.count.paymentsBudgets += 1;
      }
      if (project.receiptsBillsBudget) {
        statsInfo.budget.receiptsAndBillsTotal +=
          project.receiptsBillsBudget || 0;
        statsInfo.count.receiptsAndBillsBudgets += 1;
      }
      if (project.laborBudget) {
        statsInfo.budget.laborTotal += project.laborBudget || 0;
        statsInfo.count.laborBudgets += 1;
      }
    });
    // calc the rest of the stats info now that we have the basics we need
    // AVERAGES
    statsInfo.actual.paymentsTotalAvg = statsInfo.count.paymentsActuals
      ? statsInfo.actual.paymentsTotal / statsInfo.count.paymentsActuals
      : 0;
    statsInfo.budget.paymentsTotalAvg = statsInfo.count.paymentsBudgets
      ? statsInfo.budget.paymentsTotal / statsInfo.count.paymentsBudgets
      : 0;

    statsInfo.actual.receiptsAndBillsTotalAvg = statsInfo.count
      .receiptsAndBillsActuals
      ? statsInfo.actual.receiptsAndBillsTotal /
        statsInfo.count.receiptsAndBillsActuals
      : 0;
    statsInfo.budget.receiptsAndBillsTotalAvg = statsInfo.count
      .receiptsAndBillsBudgets
      ? statsInfo.budget.receiptsAndBillsTotal /
        statsInfo.count.receiptsAndBillsBudgets
      : 0;

    statsInfo.actual.laborTotalAvg = statsInfo.count.laborActuals
      ? statsInfo.actual.laborTotal / statsInfo.count.laborActuals
      : 0;
    statsInfo.budget.laborTotalAvg = statsInfo.count.laborBudgets
      ? statsInfo.budget.laborTotal / statsInfo.count.laborBudgets
      : 0;

    // ACTUAL VARIANCES
    statsInfo.variance.paymentsTotal = statsInfo.budget.paymentsTotal
      ? statsInfo.actual.paymentsTotal - statsInfo.budget.paymentsTotal
      : null;

    statsInfo.variance.receiptsAndBillsTotal = statsInfo.budget
      .receiptsAndBillsTotal
      ? statsInfo.budget.receiptsAndBillsTotal -
        statsInfo.actual.receiptsAndBillsTotal
      : null;

    statsInfo.variance.laborTotal = statsInfo.budget.laborTotal
      ? statsInfo.budget.laborTotal - statsInfo.actual.laborTotal
      : null;

    // PERCENT COMPLETE
    statsInfo.percentCompleted.payments = statsInfo.budget.paymentsTotal
      ? statsInfo.actual.paymentsTotal / statsInfo.budget.paymentsTotal
      : null;
    statsInfo.percentCompleted.receiptsAndBills = statsInfo.budget
      .receiptsAndBillsTotal
      ? statsInfo.actual.receiptsAndBillsTotal /
        statsInfo.budget.receiptsAndBillsTotal
      : null;
    statsInfo.percentCompleted.labor = statsInfo.budget.laborTotal
      ? statsInfo.actual.laborTotal / statsInfo.budget.laborTotal
      : null;

    // AVERAGE VARIANCES
    statsInfo.variance.paymentsTotalAvg =
      statsInfo.actual.paymentsTotalAvg - statsInfo.budget.paymentsTotalAvg;

    statsInfo.variance.receiptsAndBillsTotalAvg =
      statsInfo.budget.receiptsAndBillsTotalAvg -
      statsInfo.actual.receiptsAndBillsTotalAvg;

    statsInfo.variance.laborTotalAvg =
      statsInfo.budget.laborTotalAvg - statsInfo.actual.laborTotalAvg;

    setEntriesStats(statsInfo);
    setStatsDialogInfo({ open: true });
  };

  const refreshData = () => {
    listCompanyProjectsRefetch();
    projectsRefresh();
  };

  const handleStatDialogClose = () => {
    setStatsDialogInfo({
      ...statsDialogInfo,
      open: false,
    });
  };

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const showPreferencesMenu = event => {
    setMenuAnchorEl(event.currentTarget);
  };
  const closePreferencesMenu = () => {
    setMenuAnchorEl(null);
  };

  const saveColumnPrefs = () => {
    const allColumnInfo = gridRef.current.columnsMap;
    store.dispatch({
      type: 'SET_ADMIN_TOOLS_SETTINGS',
      payload: { projects: allColumnInfo },
    });
    closePreferencesMenu();
  };

  const clearColumnPrefs = () => {
    store.dispatch({
      type: 'SET_ADMIN_TOOLS_SETTINGS',
      payload: { projects: null },
    });
    // rebuild the table now
    buildDataGrid({ withoutPreferences: true });
    closePreferencesMenu();
  };

  const gridStyle = { height: '100%', minHeight: '100%' };

  return (
    <div className={classes.scrollableColumn}>
      {tableReady && !!dataSource && !projectsLoading ? (
        <>
          <div style={{ flex: 0, background: '#eee' }}>
            <Grid
              container
              justifyContent="space-between"
              className={classes.headerWrapper}
              spacing={1}
            >
              <Grid container item xs={12} justifyContent="space-between">
                <Grid item>
                  <AdminToolsTitle
                    Icon={CONTENT_DEFINITION[CONTENT_TYPE.PROJECT].Icon}
                    titleText="Manage Projects"
                  />
                </Grid>
                <Grid item className={classes.actionButtonsContainer}>
                  <AdminToolsIconButton
                    tooltipText="Reload Data"
                    transparentBackground
                    onClick={refreshData}
                    disabled={projectsLoading || listCompanyProjectsLoading}
                    isLoading={projectsLoading || listCompanyProjectsLoading}
                  >
                    <CachedIcon />
                  </AdminToolsIconButton>
                  <AdminToolsIconButton
                    tooltipText="Export to Excel/CSV"
                    onClick={() => {
                      exportCSV();
                    }}
                  >
                    <PublishIcon />
                  </AdminToolsIconButton>

                  <AdminToolsIconButton
                    tooltipText="View stats for current data"
                    onClick={calcInfoStats}
                  >
                    <EqualizerIcon />
                  </AdminToolsIconButton>

                  <AdminToolsIconButton
                    tooltipText="Add a project"
                    onClick={openAddModal}
                  >
                    <AddIcon />
                  </AdminToolsIconButton>

                  <AdminToolsIconButton
                    tooltipText="View other options"
                    onClick={showPreferencesMenu}
                  >
                    <MoreHorizIcon />
                  </AdminToolsIconButton>
                  <Menu
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={Boolean(menuAnchorEl)}
                    onClose={closePreferencesMenu}
                  >
                    <MenuItem onClick={saveColumnPrefs}>
                      <SaveIcon />
                      &nbsp;&nbsp;Save Column Preferences
                    </MenuItem>
                    <MenuItem onClick={clearColumnPrefs}>
                      <BlockIcon />
                      &nbsp;&nbsp;Clear Column Preferences
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
              <Grid item xs={6} />
              <Grid
                item
                xs={6}
                style={{ justifyContent: 'flex-end', display: 'flex' }}
              >
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={includeArchive}
                      onChange={handleIncludeArchiveChange}
                    />
                  }
                  label="Include archived projects"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
          </div>

          {!!adminContentQueryNote && (
            <Grid container style={{ marginBottom: 32 }}>
              <ResponseTooBigWarning
                managingCompanyId={managingCompanyInfo?.managingCompanyId}
              />
            </Grid>
          )}

          <div style={{ flex: 1, height: 200 }}>
            <ReactDataGrid
              key={tableKey}
              // grab the ref
              onReady={setGridRef}
              // set which property is used as the ID
              idProperty="projectId"
              // set which columns show
              columns={columns}
              // column order
              defaultColumnOrder={columnOrder}
              // set the data to build from
              dataSource={dataSource}
              // set basic styling for the overall table
              style={gridStyle}
              className="reactDataGridFixLastItemOverlap"
              // filtering
              enableFiltering
              defaultFilterValue={filterValue}
              filterTypes={filterTypes}
              // sorting
              defaultSortInfo={defaultSortInfo}
              allowUnsort={false}
              // scrollbar
              scrollProps={scrollProps}
              headerHeight={0}
            />
          </div>
        </>
      ) : (
        <LoadingCover loader="linear">
          <Typography variant="h3" align="center">
            Loading data from across your projects...
          </Typography>
          {!!includeArchive && (
            <Typography variant="h4" align="center" style={{ marginTop: 8 }}>
              Including archived data can take a while to process. Please give
              us a few moments.
            </Typography>
          )}
        </LoadingCover>
      )}
      {dialogInfo.open && (
        <OkCancelDialog
          title={dialogInfo.title}
          open={dialogInfo.open}
          onClose={dialogInfo.onClose}
          hideCancel={dialogInfo.hideCancel}
          onConfirm={dialogInfo.onConfirm}
        >
          <Typography>{dialogInfo.message}</Typography>
        </OkCancelDialog>
      )}
      {showManualAddDialog.open && (
        <ContentDetailsModal
          includeArchive={includeArchive}
          mode={showManualAddDialog.mode}
          canEdit={showManualAddDialog.canEdit}
          parentInfo={showManualAddDialog.parentInfo}
          existingInfo={showManualAddDialog.existingInfo}
          onClose={() => {
            setShowManualAddDialog({
              ...showManualAddDialog,
              open: false,
            });
          }}
        />
      )}
      {statsDialogInfo.open && (
        <ProjectStatsDialog
          data={entriesStats}
          onClose={handleStatDialogClose}
        />
      )}
    </div>
  );
};
const mapStateToProps = state => {
  const columnSettings = _.get(
    state,
    'appState.adminToolsSettings.projects',
    null
  );
  return {
    userInfo: state.userInfo,
    managingCompanyInfo: state.appState.managingCompanyInfo || {},
    columnSettings,
  };
};

export default compose(
  ArchiveCompanyProjectAction,
  DeleteJrnAction,
  ListCompanyProjectsAction,
  UnarchiveCompanyProjectAction
)(connect(mapStateToProps)(ManageProjects));
