import React, { useState } from 'react';
import { Button, TextField, makeStyles } from '@material-ui/core';
import { AddCircleOutline as AddCircleOutlineIcon } from '@material-ui/icons';

import { isValidEmail } from '../../../common/validators';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginBottom: 8,
    borderRadius: 10,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
}));

const AddRecipientInput = ({ initialValue, attemptAddEmail, onInputBlur }) => {
  const classes = useStyles();
  const [typingEmail, setTypingEmail] = useState(initialValue || '');
  const [error, setError] = useState(false);

  const validateEmail = ({ emailAddress }) => {
    const validEmail = isValidEmail(emailAddress);
    if (!validEmail) {
      setError(true);
      return false;
    }
    setError(false);
    return true;
  };

  const passbackEmailToAdd = () => {
    if (validateEmail({ emailAddress: typingEmail })) {
      attemptAddEmail({ emailToAdd: typingEmail });
    }
    return false;
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div style={{ width: 320 }}>
          <TextField
            placeholder="Add a new recipient by email address"
            fullWidth
            error={error}
            value={typingEmail}
            helperText={error ? 'Please enter a valid email address' : ''}
            onChange={event => {
              setTypingEmail(event.target.value);
              if (error) {
                // only validate on change if there was previously an error
                validateEmail({
                  emailAddress: event.target.value,
                });
              }
            }}
            onBlur={event => {
              onInputBlur(event.target.value);
            }}
            onKeyDown={event => {
              // check if it was the enter key and if so, run attemptAddEmail
              if (event.key === 'Enter') {
                passbackEmailToAdd();
              }
            }}
          />
        </div>
        <div style={{ flex: 0 }}>
          <Button
            variant="outlined"
            onClick={passbackEmailToAdd}
            style={{ marginLeft: 16, minWidth: 120 }}
            startIcon={<AddCircleOutlineIcon />}
            color="primary"
          >
            Add
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddRecipientInput;
