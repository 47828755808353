import React from 'react';

import QboRequiredWrapperComponent from '../qbo-required-wrapper/qbo-required-wrapper.component';
import CustomScanDashboard from '../../components/custom-scan-dashboard';

const CustomScanDashboardView = () => {
  return (
    <QboRequiredWrapperComponent redirectToOnQboConnect="/custom-scan">
      <CustomScanDashboard />
    </QboRequiredWrapperComponent>
  );
};

export default CustomScanDashboardView;
