import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Store as VendorIcon } from '@material-ui/icons';
import { compose } from 'redux';

import uuid from 'uuid/v4';
import _ from 'lodash';

import { AddVendorAction, UpdateVendorAction } from '../../../graphql/graphql';

import LoadingCover from '../../../components/LoadingCover/loadingCover';

const AddVenderDialog = ({
  allVendors,
  open,
  editingVendor,
  onClose,
  onAddVendor,
  onUpdateVendor,
}) => {
  const [savingVendor, setSavingVendor] = useState(false);
  const [vendorName, setVendorName] = useState(editingVendor?.name || '');
  const [error, setError] = useState({});

  const handleDoneButtonClick = async () => {
    const name = _.trim(vendorName);
    if (!name) {
      setError(currentState => ({
        ...currentState,
        vendorName: 'Vendor name is required',
      }));
      return;
    }

    if (editingVendor && editingVendor.name === name) {
      // the name is exactly the same as the original name
      // no need to update
      onClose();
      return;
    }

    // check if vendor name (case-insensitive) already exists
    const nameAsLowercase = _.toLower(name);
    const existingVendor = _.find(allVendors, vendor => {
      return _.toLower(vendor.name) === nameAsLowercase;
    });

    if (existingVendor) {
      if (
        !editingVendor ||
        (editingVendor &&
          existingVendor.relationId !== editingVendor.relationId)
      ) {
        setError(currentState => ({
          ...currentState,
          vendorName:
            'Vendor name already exists. It may be in the archived list.',
        }));
        return;
      }
    }

    setSavingVendor(true);

    if (editingVendor) {
      // update existing vendor
      await onUpdateVendor({
        relationId: editingVendor.relationId,
        name,
        isArchived: editingVendor.isArchived,
      });
    } else {
      // add new vendor
      const newVendorId = uuid();

      await onAddVendor({
        relationId: newVendorId,
        name,
        isArchived: false,
      });
    }

    setSavingVendor(false);
    onClose();
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography>
        <Grid container alignItems="center">
          <VendorIcon />
          &nbsp;
          <Typography variant="h6">
            {editingVendor ? 'Edit Vendor' : 'Create New Vendor'}
          </Typography>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          spacing={3}
          style={{ minHeight: 100, marginTop: 0, marginBottom: 2 }}
          alignContent="flex-start"
        >
          <Grid item xs={12}>
            <TextField
              label="Vendor Name"
              name="newVendorName"
              placeholder="Enter a unique vendor name"
              fullWidth
              value={vendorName}
              onChange={event => {
                setError(currentState => ({
                  ...currentState,
                  vendorName: '',
                }));
                setVendorName(event.target.value);
              }}
              error={!!error.vendorName}
              helperText={
                error.vendorName ||
                'Hint: Vendor name is case-insensitive. For example, "Level" and "level" are the same vendor.'
              }
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent="flex-end" alignItems="center" flex={1}>
          <Button onClick={onClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button
            onClick={handleDoneButtonClick}
            variant="contained"
            color="primary"
            autoFocus
          >
            Done
          </Button>
        </Grid>
      </DialogActions>
      {savingVendor && (
        <LoadingCover loader="linear">
          <Typography variant="h3" align="center">
            Saving vendor...
          </Typography>
        </LoadingCover>
      )}
    </Dialog>
  );
};

export default compose(AddVendorAction, UpdateVendorAction)(AddVenderDialog);
