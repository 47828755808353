import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

import AddContentForm from '../../views/add-to-project/add-content-form';

const AddLead = ({ addEditLeadInfo, toClose, hideCustomerOption }) => {
  return (
    <Dialog maxWidth="md" fullWidth open>
      <DialogTitle disableTypography>
        <Typography variant="h4" align="center" color="primary">
          {addEditLeadInfo.existingInfo ? 'Edit' : 'Add'} Customer Lead
        </Typography>
      </DialogTitle>
      <DialogContent>
        <AddContentForm
          passedType="lead"
          customerInfo={addEditLeadInfo.customerInfo}
          fromWhichAdminTool={addEditLeadInfo.fromWhichAdminTool}
          passedContentInfo={addEditLeadInfo.existingInfo || null}
          baseContentInfo={addEditLeadInfo.baseContentInfo || null}
          onComplete={toClose}
          // hide when editing a lead
          hideCustomerOption={
            addEditLeadInfo.existingInfo || hideCustomerOption
          }
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddLead;
