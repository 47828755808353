import React from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';

const FormikDatePicker = props => {
  const { field, form, variant, noFuture, disablePast, ...other } = props;
  const currentError = form.errors[field.name];
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        name={field.name}
        value={field.value}
        variant={variant || 'inline'}
        disablePast={disablePast || false}
        maxDate={noFuture ? new Date() : undefined}
        format="MMMM D, YYYY"
        helperText={currentError}
        error={Boolean(currentError)}
        onChange={date => {
          form.setFieldValue(field.name, date, false);
        }}
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
};

export default FormikDatePicker;
