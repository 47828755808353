import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  ${Fragment.Stat}
  mutation UpdateContent(
    $contentId: ID!
    $jrnId: String!
    $contentUrl: String
    $media: [MediaInfoInput]
    $description: String
    $date: String
    $startDate: String
    $endDate: String
    $aspectRatio: String
    $synced: Boolean
    $labels: [String]
    $latitude: String
    $longitude: String
    $isPublic: Boolean
    $assignedTo: String
    $contentStatus: String
    $priority: String
    $type: String
    $subtype: String
    $permissionsFrom: String
    $title: String
    $requireAdmin: Boolean
    $completionType: String
    $completionInfo: CompletionInfoInput
    $amount: MoneyInput
    $recurrence: RecurringTimeRangeInput
    $useTimetracking: Boolean
    $timetrackingAnswers: [TimetrackingAnswerInput]
    $workflowStage: String
    $workflowStageReason: String
    $rfiIds: [String]
    $billable: Boolean
  ) {
    updateContent(
      input: {
        contentId: $contentId
        jrnId: $jrnId
        contentUrl: $contentUrl
        media: $media
        description: $description
        date: $date
        startDate: $startDate
        endDate: $endDate
        aspectRatio: $aspectRatio
        synced: $synced
        labels: $labels
        latitude: $latitude
        longitude: $longitude
        isPublic: $isPublic
        assignedTo: $assignedTo
        contentStatus: $contentStatus
        priority: $priority
        type: $type
        subtype: $subtype
        permissionsFrom: $permissionsFrom
        title: $title
        requireAdmin: $requireAdmin
        completionType: $completionType
        completionInfo: $completionInfo
        amount: $amount
        recurrence: $recurrence
        useTimetracking: $useTimetracking
        timetrackingAnswers: $timetrackingAnswers
        workflowStage: $workflowStage
        workflowStageReason: $workflowStageReason
        rfiIds: $rfiIds
        billable: $billable
      }
    ) {
      customerId
      contentId
      jrnId
      synced
      contentUrl
      media {
        uri
        aspectRatio
        type
      }
      aspectRatio
      description
      date
      startDate
      endDate
      labels
      latitude
      longitude
      isPublic
      assignedTo
      contentStatus
      priority
      type
      subtype
      permissionsFrom
      title
      requireAdmin
      completionType
      completionInfo {
        instructions
        completedById
        completedByDate
        textResponse
        mediaInfo {
          uri
          aspectRatio
        }
      }
      amount {
        value
      }
      recurrence {
        rangeId
        rangeStartDate
        rangeEndDate
        daysOfTheWeek
      }
      useTimetracking
      useTimetrackingQuestions
      timetrackingQuestions
      timetrackingQuestionsCheckout
      timetrackingAnswers {
        questionId
        answer
      }
      fromTemplate
      timetrackingType
      workflowStage
      workflowStageReason
      # REMOVEME: remove this when "bugets" field is deprecated
      budgets {
        ...BudgetFields
      }
      stats {
        ...StatFields
      }
      includeSubsInStats
      excludeFromStats
      qboCustomerId
      rfiIds
      globalExpenseId
      billable
    }
  }
`;
