import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import {
  ACCOUNTING_METHOD,
  BREAKEVEN_PERIOD,
  REPORT_PERIOD,
  SCOREBOARD_NAME,
} from '../../../config/appDefaults';
import { Dashboard } from '../../dashboard';
import Graph, { processXYTypes } from '../../graph';
import GetBreakevenData from '../../../graphql/queries/get-breakeven-data';
import {
  GetCompanyTargetsAction,
  ListScoreboardSettingsAction,
} from '../../../graphql/graphql';
import BreakevenControlBar from './breakeven-control-bar';
import { breakevenGraphs, BREAKEVEN_GRAPH } from './breakeven-graphs';
import BreakevenProgressReport from './breakeven-progress-report';
import palette from '../../../theme/palette';
import prettyString from '../../graph/pretty-string';

const title = 'Break-even';

const BreakevenScoreboard = ({
  companyTargets,
  managingCompanyInfo,
  scoreboardSettings,
}) => {
  const [monthlySalesTarget, setMonthlySalesTarget] = useState(null);

  const [breakevenPeriod, setBreakevenPeriod] = useState(null);
  const [accountingMethod, setAccountingMethod] = useState(null);

  useEffect(() => {
    if (scoreboardSettings) {
      // Set defaults
      let updatedBreakevenPeriod = BREAKEVEN_PERIOD.LAST_12_MONTHS.key;
      let updatedAccountingMethod = ACCOUNTING_METHOD.CASH.key;

      // Find settings for scoreboard
      const breakevenScoreboardSettings = _.find(
        scoreboardSettings,
        ({ scoreboardName }) => scoreboardName === SCOREBOARD_NAME.BREAKEVEN
      );

      // Override breakeven period default with user settings
      if (
        breakevenScoreboardSettings &&
        breakevenScoreboardSettings.breakevenPeriod
      ) {
        const companyBreakevenPeriodSetting = _.find(
          BREAKEVEN_PERIOD,
          ({ value }) => value === breakevenScoreboardSettings.breakevenPeriod
        );
        if (companyBreakevenPeriodSetting) {
          updatedBreakevenPeriod = companyBreakevenPeriodSetting.key;
        }
      }

      // Override accounting method default with user settings
      if (
        breakevenScoreboardSettings &&
        breakevenScoreboardSettings.accountingMethod
      ) {
        const companyAccountingMethodSetting = _.find(
          ACCOUNTING_METHOD,
          ({ value }) => value === breakevenScoreboardSettings.accountingMethod
        );
        if (companyAccountingMethodSetting) {
          updatedAccountingMethod = companyAccountingMethodSetting.key;
        }
      }

      setBreakevenPeriod(updatedBreakevenPeriod);
      setAccountingMethod(updatedAccountingMethod);
    }
  }, [scoreboardSettings]);

  const [reportPeriod, setReportPeriod] = useState(
    REPORT_PERIOD.THIS_FISCAL_YEAR.key
  );

  const targetsByKey = {};
  if (companyTargets) {
    _.each(breakevenGraphs, ({ name: reportName }) => {
      targetsByKey[reportName] = _.find(
        companyTargets,
        ({ name }) => name === reportName
      );
    });
  }

  const BreakevenDataQuery = useQuery(GetBreakevenData, {
    skip:
      !(managingCompanyInfo && managingCompanyInfo.managingCompanyId) ||
      breakevenPeriod === null ||
      accountingMethod === null,
    variables: {
      companyId: managingCompanyInfo && managingCompanyInfo.managingCompanyId,
      reportPeriod: _.find(REPORT_PERIOD, ({ key }) => key === reportPeriod)
        .value,
      accountingMethod:
        accountingMethod !== null &&
        _.find(ACCOUNTING_METHOD, ({ key }) => key === accountingMethod).value,
      breakevenPeriod:
        breakevenPeriod !== null &&
        _.find(BREAKEVEN_PERIOD, ({ key }) => key === breakevenPeriod).value,
    },
  });

  const breakevenData =
    BreakevenDataQuery.data &&
    BreakevenDataQuery.data.getBreakevenData &&
    BreakevenDataQuery.data.getBreakevenData.items;

  const reportData = useMemo(() => {
    const compiledReportData = {};

    if (breakevenData) {
      _.forEach(breakevenGraphs, ({ name: sourceName }) => {
        const data = _.cloneDeep(
          _.find(breakevenData, ({ name }) => name === sourceName)
        );

        if (data) {
          data.values = processXYTypes(
            data.values,
            data.labels.x,
            data.labels.y
          );
        }

        compiledReportData[sourceName] = data;
      });
    }

    return compiledReportData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakevenData]);

  const monthlySales = _.find(
    breakevenGraphs,
    ({ name }) => name === BREAKEVEN_GRAPH.MONTHLY_SALES
  );

  const annualBreakevenSalesForecast = _.find(
    breakevenGraphs,
    ({ name }) => name === BREAKEVEN_GRAPH.ANNUAL_BREAKEVEN_SALES_FORECAST
  );

  const salesToDate = _.find(
    breakevenGraphs,
    ({ name }) => name === BREAKEVEN_GRAPH.SALES_TO_DATE
  );

  const breakevenChartSeries = [];
  let currentSalesToDate = null;
  if (reportData && reportData[salesToDate.name]) {
    breakevenChartSeries.push({
      data: reportData[salesToDate.name].values,
      dataType: reportData[salesToDate.name].labels,
      type: salesToDate.graphType,
      name: salesToDate.label,
      fromColor: palette.brandColorGreen,
      toColor: palette.brandColorGreen75,
    });
    const lastNonNull = _.filter(
      reportData[salesToDate.name].values,
      value => value.y !== null
    ).pop();

    if (lastNonNull) {
      currentSalesToDate = lastNonNull.y;
    }
  }

  let currentBreakevenSales = null;
  if (reportData && reportData[annualBreakevenSalesForecast.name]) {
    breakevenChartSeries.push({
      data: reportData[annualBreakevenSalesForecast.name].values,
      dataType: reportData[annualBreakevenSalesForecast.name].labels,
      type: annualBreakevenSalesForecast.graphType,
      name: annualBreakevenSalesForecast.label,
      color: palette.brandColorPrimary,
    });

    const lastNonNull = _.filter(
      reportData[annualBreakevenSalesForecast.name].values,
      value => value.y !== null
    ).pop();

    if (lastNonNull) {
      currentBreakevenSales = lastNonNull.y;
    }
  }

  const monthlySalesDataSeries = [];
  let monthlySalesAverage = null;
  let monthlySalesTotal = null;

  const nowMoment = moment();
  function hasMonthHasCompleted(datum) {
    // datum.x is the UTC start of the month at GMT
    const localStartOfMonth = new Date(
      datum.x.getUTCFullYear(),
      datum.x.getUTCMonth(),
      datum.x.getUTCDate()
    );

    // Get local end of period
    const localStartOfMonthMoment = moment(localStartOfMonth);
    const localEndOfMonthMoment = localStartOfMonthMoment.endOf('month');

    // If start of period is before now, then we are showing incomplete data
    return moment(localEndOfMonthMoment).isBefore(nowMoment);
  }

  if (reportData && reportData[monthlySales.name]) {
    monthlySalesDataSeries.push({
      type: monthlySales.graphType,
      data: reportData[monthlySales.name].values,
      dataType: reportData[monthlySales.name].labels,
      name: monthlySales.label,
      color: datum => {
        if (datum && !hasMonthHasCompleted(datum)) {
          return palette.brandColorGreen50;
        }

        return palette.brandColorGreen;
      },
    });

    // Calculate the average and total to use, should only include completed months
    let totalToUse = 0;
    let completedMonths = 0;

    _.forEach(reportData[monthlySales.name].values, datum => {
      if (datum && hasMonthHasCompleted(datum)) {
        totalToUse += datum.y;
        completedMonths += 1;
      }
    });

    if (totalToUse && completedMonths !== 0) {
      monthlySalesAverage = numeral(totalToUse / completedMonths).format(
        '$0,0.00'
      );
      monthlySalesTotal = numeral(totalToUse).format('$0,0.00');
    }
  }

  let customInfoString = '';
  if (currentSalesToDate !== null) {
    customInfoString += `Sales to Date: ${prettyString(
      currentSalesToDate,
      'money'
    )}`;
  }
  if (currentBreakevenSales !== null) {
    if (customInfoString) {
      customInfoString += `, `;
    }
    customInfoString += `Break-even Sales: ${prettyString(
      currentBreakevenSales,
      'money'
    )}`;
  }

  return (
    <>
      <Dashboard
        controlBar={
          <BreakevenControlBar
            title={title}
            reportPeriod={reportPeriod}
            setReportPeriod={setReportPeriod}
            breakevenPeriod={breakevenPeriod}
            accountingMethod={accountingMethod}
            onRefresh={BreakevenDataQuery.refetch}
          />
        }
        layout="bigLeft"
      >
        <Graph
          key={annualBreakevenSalesForecast.name}
          dataSeries={breakevenChartSeries}
          description={annualBreakevenSalesForecast.description}
          title={annualBreakevenSalesForecast.label}
          target={
            targetsByKey[annualBreakevenSalesForecast.name] &&
            targetsByKey[annualBreakevenSalesForecast.name].value
          }
          targetSentiment="negative"
          loading={breakevenChartSeries.length === 0}
          showAverage={false}
          showTotal={false}
          customInfoString={customInfoString || null}
          noPadding
          showLegend
        />
        <BreakevenProgressReport
          reportData={reportData}
          monthlySalesTarget={monthlySalesTarget}
          setMonthlySalesTarget={setMonthlySalesTarget}
        />
        <Graph
          key={monthlySales.name}
          description={monthlySales.description}
          title={monthlySales.label}
          dataSeries={monthlySalesDataSeries}
          target={monthlySalesTarget && monthlySalesTarget.value}
          targetText="BREAK-EVEN"
          targetColor={monthlySalesTarget && monthlySalesTarget.color}
          loading={monthlySalesDataSeries.length === 0}
          showAverage
          showTotal
          averageOverride={monthlySalesAverage}
          totalOverride={monthlySalesTotal}
        />
      </Dashboard>
    </>
  );
};

BreakevenScoreboard.title = title;

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(
  compose(
    GetCompanyTargetsAction,
    ListScoreboardSettingsAction
  )(BreakevenScoreboard)
);
