import { Typography } from '@material-ui/core';
import React from 'react';
import OkCancelDialog from '../OkCancelDialog/okCancelDialog';

const QboDisconnectCustomerDialog = ({
  handleClose,
  recordType,
  disconnectSyncedCustomer,
}) => {
  const dialogTitle = 'Disconnect from QuickBooks Online customer';

  return (
    <OkCancelDialog
      open
      onClose={handleClose}
      onConfirm={disconnectSyncedCustomer}
      title={dialogTitle}
      okButtonText="Confirm"
    >
      <Typography variant="body1">
        This {recordType} will be disconnected from QuickBooks Online.
        <br />
        <br />
        The Level {recordType} and QuickBooks Online customer will no longer be
        synced but will be left active in each platform.
      </Typography>
    </OkCancelDialog>
  );
};

export default QboDisconnectCustomerDialog;
