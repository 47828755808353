import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Visibility as VisibilityIcon } from '@material-ui/icons';

export const RelatedRfisFinancialItems = ({ contentInfo, loadExistingRfi }) => {
  if (!contentInfo?.rfiIds?.length) {
    return null;
  }

  return (
    <Grid container item alignItems="center" style={{ marginTop: 8 }}>
      {contentInfo.rfiIds.map(rfiId => (
        <Button
          onClick={() => {
            loadExistingRfi({ rfiId });
          }}
          startIcon={<VisibilityIcon />}
          variant="contained"
          color="primary"
        >
          View RFI
        </Button>
      ))}
    </Grid>
  );
};
