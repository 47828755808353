import gql from 'graphql-tag';

export default gql`
  query GetJobCostAccounts($companyId: String!) {
    getCompanyJobCostAccounts(companyId: $companyId) {
      items {
        __typename
        displayName
        accountName
      }
    }
  }
`;
