import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';

// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { renderDateString } from '../../../helpers/renderDateString';
import palette from '../../../theme/palette';

const useStyles = makeStyles(theme => ({
  commentWrapper: {
    marginBottom: theme.spacing(2),
  },
  usernameWrapper: {
    paddingTop: '7px',
    marginRight: theme.spacing(2),
  },
  contentWrapper: props => ({
    background: props.bubbleColor || palette.background.alt,
    padding: theme.spacing(1),
    borderRadius: '10px',
  }),
  dateWrapper: props => ({
    paddingLeft: theme.spacing(1),
    textAlign: props.dateAligment || 'left',
  }),
}));

const Comments = props => {
  const { comments, allUsers } = props;
  const classes = useStyles(props);

  return (
    <Grid container direction="column">
      {comments.map(comment => {
        if (!comment || !comment.content) return null;
        const userDetails = _.find(allUsers, { userId: comment.userId });
        return (
          <Grid
            key={comment.commentId}
            className={classes.commentWrapper}
            container
            direction="column"
            justifyContent="space-between"
          >
            <Grid item>
              <Grid container direction="row">
                <Grid item className={classes.usernameWrapper}>
                  {userDetails
                    ? userDetails.username
                    : comment.userId.substring(0, 7)}
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <Grid container direction="column">
                    <Grid item className={classes.contentWrapper}>
                      {comment.content}
                    </Grid>
                    <Grid item className={classes.dateWrapper}>
                      <Typography variant="caption">
                        {renderDateString(
                          comment.dateCreated,
                          null,
                          'MMM D, YYYY @ h:mma'
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Comments;
