import React from 'react';
import { compose } from 'react-apollo';
import _ from 'lodash';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  GetCompanyTargetsAction,
  UpdateCompanyTargetsAction,
} from '../../graphql/graphql';
import LoadingCover from '../LoadingCover/loadingCover';

const useStyles = makeStyles(() => ({
  inputField: {
    marginBottom: 20,
    minWidth: 400,
  },
}));

const DashboardSettingsTargets = ({
  targetsToEdit,
  companyTargets,
  getCompanyTargetsLoading,
  onUpdateCompanyTargets,
}) => {
  const classes = useStyles();

  const initialValues = {};
  if (companyTargets) {
    targetsToEdit.forEach(({ name }) => {
      initialValues[name] = '';
    });
    companyTargets.forEach(({ name, value }) => {
      if (initialValues[name] || initialValues[name] === '') {
        initialValues[name] = value || '';
      }
    });
  }

  const handleFormSubmit = async values => {
    const targetsToUpdate = targetsToEdit.map(({ name, dataType }) => ({
      name,
      dataType,
      value: values[name] || null,
    }));

    await onUpdateCompanyTargets(targetsToUpdate);
  };

  return (
    <div style={{ flex: 1 }}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
      >
        {formikProps => {
          const { values, handleSubmit, isSubmitting, dirty } = formikProps;

          return (
            <Form style={{ position: 'relative' }}>
              <Grid container direction="column" alignItems="center">
                {_.map(
                  targetsToEdit,
                  ({ name: targetName, label: targetLabel }) => (
                    <Grid key={targetName} item xs={12}>
                      <Field
                        name={targetName}
                        label={targetLabel}
                        placeholder="n/a"
                        value={values[targetName]}
                        type="number"
                        component={TextField}
                        className={classes.inputField}
                        fullWidth
                      />
                    </Grid>
                  )
                )}
                <Grid item style={{ textAlign: 'right', width: '100%' }}>
                  <Button
                    disabled={!dirty || isSubmitting}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
              {isSubmitting && (
                <LoadingCover>
                  <Typography variant="h3" align="center">
                    Saving...
                  </Typography>
                </LoadingCover>
              )}
            </Form>
          );
        }}
      </Formik>
      {getCompanyTargetsLoading && <LoadingCover />}
    </div>
  );
};

export default compose(
  GetCompanyTargetsAction,
  UpdateCompanyTargetsAction
)(DashboardSettingsTargets);
