import gql from 'graphql-tag';

export default gql`
  mutation DeleteJrn($jrnId: ID!) {
    deleteJrn(input: { jrnId: $jrnId }) {
      contentId
      jrnId
    }
  }
`;
