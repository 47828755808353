import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Avatar,
  ListItemAvatar,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import {
  ReceiptOutlined as ReceiptOutlinedIcon,
  GetApp as GetAppIcon,
  Link as CopyLinkIcon,
} from '@material-ui/icons';
import copy from 'copy-to-clipboard';
import { withSnackbar } from 'notistack';

import _ from 'lodash';
import moment from 'moment';

import {
  cloudinaryifyProfilePic,
  cloudinaryDownloadUrl,
} from '../../../../helpers/cloudinary';
import {
  CONTENT_DEFINITION,
  FINANCIAL_TYPES,
} from '../../../../config/appDefaults';

import LevelCloudinaryImage from '../level-cloudinary-image';
import ContentImageInfo from './content-image-info';

const useStyles = makeStyles(() => ({
  imageWrapper: {
    position: 'relative',
    background: '#eee',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 300,
    '&:hover .optionsWrapper': {
      background: '#fff',
    },
  },
  image: {
    maxHeight: '50vh',
    minHeight: '30vh',
    maxWidth: '100%',
    height: 'auto',
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  zoomButton: {
    color: '#666',
  },
  optionButtonWrapper: {
    opacity: 1,
    color: '#fff',
    position: 'absolute',
    bottom: 10,
    right: 10,
    textAlign: 'center',
    backgroundColor: '#5f5f5f',
    '&:hover': {
      background: '#888',
    },
    height: 50,
    width: 50,
    borderRadius: '50%',
  },
  optionButton: {
    color: '#fff',
    fontSize: 30,
    width: '100%',
  },
}));

const imageTranformWidth = parseInt(window.innerWidth, 10) || 500;
const imageTranformHeight = Math.round(window.innerHeight * 0.75);

const ContentImage = props => {
  const {
    contentPiece,
    projectCanEdit,
    isCreator,
    allUsers,
    showDownload,
    showCopyUrl,
    enqueueSnackbar,
  } = props;
  const classes = useStyles();

  const isFinancial = FINANCIAL_TYPES.includes(contentPiece.type);

  if (isFinancial && !projectCanEdit && !isCreator) {
    const user = _.find(allUsers, { userId: contentPiece.creatorId });
    if (!user) return null;
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ paddingTop: 10, paddingBottom: 10 }}
      >
        <Grid item>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.bigAvatarWrapper}
          >
            <ListItemAvatar>
              <Avatar
                alt={user.username}
                className={classes.bigAvatar}
                src={
                  user.profilePic
                    ? cloudinaryifyProfilePic(user.profilePic)
                    : null
                }
              />
            </ListItemAvatar>
          </Grid>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Typography variant="body1">
            {user.username} added a {contentPiece.type}.
          </Typography>
          <Typography variant="caption">
            {moment(contentPiece.date).format('MMM D, YYYY @ h:mma')}
          </Typography>
        </Grid>
        <Grid item>
          <ReceiptOutlinedIcon color="disabled" style={{ marginRight: 16 }} />
        </Grid>
      </Grid>
    );
  }

  const copyToClipboard = imageUrl => {
    copy(imageUrl);
    enqueueSnackbar('Link copied!', {
      variant: 'success',
      autoHideDuration: 1000,
    });
  };

  let contentUrlToUse = contentPiece.contentUrl;
  if (contentPiece.globalExpense) {
    contentUrlToUse = contentPiece.globalExpense.contentUrl;
  }

  let ContentIcon = null;
  if (!!contentPiece.type && CONTENT_DEFINITION[contentPiece.type]?.Icon) {
    ContentIcon = CONTENT_DEFINITION[contentPiece.type].Icon;
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.imageWrapper}
    >
      <Grid item>
        {contentUrlToUse ? (
          <a href={contentUrlToUse} target="_blank" rel="noopener noreferrer">
            <LevelCloudinaryImage
              contentUrl={contentUrlToUse}
              imageTranformWidth={imageTranformWidth}
              imageTranformHeight={imageTranformHeight}
            />
          </a>
        ) : (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            item
            style={{ minHeight: 150 }}
          >
            {ContentIcon && <ContentIcon style={{ fontSize: 50 }} />}
            <Typography variant="body2">No image provided</Typography>
          </Grid>
        )}
      </Grid>
      <ContentImageInfo contentInfo={contentPiece} />
      {contentUrlToUse && (showDownload || showCopyUrl) && (
        <div className={classes.optionsWrapper}>
          {showCopyUrl && (
            <div className={classes.optionButtonWrapper}>
              <Tooltip title="Copy to clipboard">
                <IconButton
                  onClick={() => copyToClipboard(contentUrlToUse)}
                  className={classes.optionButton}
                >
                  <CopyLinkIcon fontSize="inherit" style={{ marginTop: -3 }} />
                </IconButton>
              </Tooltip>
            </div>
          )}
          {showDownload && (
            <a
              href={
                _.includes(contentUrlToUse, 'cloudinary')
                  ? cloudinaryDownloadUrl(contentUrlToUse)
                  : contentUrlToUse
              }
              className={classes.optionButtonWrapper}
            >
              <IconButton className={classes.optionButton}>
                <GetAppIcon fontSize="inherit" style={{ marginTop: -3 }} />
              </IconButton>
            </a>
          )}
        </div>
      )}
    </Grid>
  );
};

export default withSnackbar(ContentImage);
