import React, { useState, useImperativeHandle } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { geocodeByAddress } from 'react-places-autocomplete';

import LocationSearchInput from '../location-search-input/location-search-input';
import Label from '../auth/auth-text-field/label';
import { extractAddressDetails } from '../../helpers';

const startingAddressObj = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
};

const useStyles = makeStyles(() => ({
  billingAddressField: {
    width: '100%',
    marginBottom: 8,
    paddingRight: 8,
  },
}));

const BillingAddressCapture = ({
  formRef,
  companyInfo,
  passBack,
  setLoading,
  hideCompanyAddressInput,
  disableCompanyAddressInput,
  companyAddressInputNotRequired,
  isFirm,
}) => {
  const classes = useStyles();

  const [billingAddress, setBillingAddress] = useState(startingAddressObj);

  const handlePassback = () => {
    if (passBack) {
      passBack(billingAddress);
    }
  };

  const formik = useFormik({
    initialValues: startingAddressObj,
    validationSchema: Yup.object({
      address1: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
      zip: Yup.string().required('Required'),
      // phoneNumber: Yup.string().required('Required'),
      country: Yup.string().required('Required'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
        handlePassback();
      }, 400);
    },
  });

  // Use useImperativeHandle to expose the submitForm method to the parent component
  useImperativeHandle(formRef, () => ({
    submitForm: () => {
      formik.submitForm();
    },
    isFormValid: () => {
      return formik.isValid;
    },
    isFormDirty: () => {
      return formik.dirty;
    },
    values: formik.values,
  }));

  const [localCompanyAddressObj, setLocalCompanyAddressObj] = useState(null);

  const [sameBillingAddress, setSameBillingAddress] = useState(false);

  const handleSameBillingAddress = async e => {
    setSameBillingAddress(e.target.checked);
    if (e.target.checked) {
      let addressToUse = localCompanyAddressObj;
      if (!addressToUse && companyInfo?.address) {
        setLoading(true);
        const cleanAddressObj = await geocodeByAddress(companyInfo?.address);
        addressToUse = extractAddressDetails(cleanAddressObj[0]);
        setLoading(false);
      }
      if (addressToUse) {
        setBillingAddress(addressToUse);
        formik.setValues({ ...startingAddressObj, ...addressToUse });
      }
    }
  };

  return (
    <Grid item style={{ marginTop: 16, marginBottom: 16 }}>
      {!hideCompanyAddressInput && (
        <Grid item container xs={12} justifyContent="center">
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <LocationSearchInput
              label={
                <Label
                  text="Company Address"
                  required={!companyAddressInputNotRequired}
                />
              }
              InputLabelProps={{ shrink: true }}
              multiline
              pushedAddress={companyInfo?.address}
              disabled={disableCompanyAddressInput}
              placeholder={disableCompanyAddressInput ? ' ' : undefined}
            />
          </Grid>
        </Grid>
      )}

      <Grid
        item
        container
        xs={12}
        style={{ marginTop: 32, backgroundColor: '#f5f5f5' }}
      >
        <Grid
          item
          xs={12}
          container
          style={{ textAlign: 'left', padding: 16, position: 'relative' }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-start"
            item
            xs={12}
            style={{ textAlign: 'left' }}
          >
            <Typography style={{ fontSize: 16 }} display="inline">
              Billing Address *
            </Typography>

            <FormControlLabel
              control={
                <Checkbox
                  checked={sameBillingAddress}
                  onChange={handleSameBillingAddress}
                  name="sameBillingAddress"
                  color="primary"
                />
              }
              label={`Copy from ${isFirm ? 'Firm' : 'Company'} Address`}
              labelPlacement="start"
            />
          </Grid>

          <form onSubmit={formik.handleSubmit} ref={formRef}>
            <Grid container item xs={12}>
              <Grid item xs={12} style={{ marginBottom: 8 }}>
                <LocationSearchInput
                  InputLabelProps={{ shrink: true }}
                  multiline
                  pushedAddress={formik.values.address1}
                  passbackAddressObject={async addressObject => {
                    const cleanAddressObj = extractAddressDetails(
                      addressObject
                    );
                    setLocalCompanyAddressObj(cleanAddressObj);
                    formik.setValues({
                      ...startingAddressObj,
                      ...cleanAddressObj,
                    });
                  }}
                  // //////////////////////////////
                  name="address1"
                  label="Address 1 *"
                  error={
                    formik?.touched?.address1 &&
                    Boolean(formik?.errors?.address1)
                  }
                  helperText={
                    formik?.touched?.address1 && formik?.errors?.address1
                  }
                  className={classes.billingAddressField}
                  onChange={formik.handleChange}
                  value={formik.values.address1}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="address2"
                  label="Address 2 (Unit, Suite, etc.)"
                  error={
                    formik?.touched?.address2 &&
                    Boolean(formik?.errors?.address2)
                  }
                  helperText={
                    formik?.touched?.address2 && formik?.errors?.address2
                  }
                  InputLabelProps={{ shrink: true }}
                  className={classes.billingAddressField}
                  onChange={formik.handleChange}
                  value={formik.values.address2}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="city"
                  label="City *"
                  error={formik?.touched?.city && Boolean(formik?.errors?.city)}
                  helperText={formik?.touched?.city && formik?.errors?.city}
                  InputLabelProps={{ shrink: true }}
                  className={classes.billingAddressField}
                  onChange={formik.handleChange}
                  value={formik.values.city}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="state"
                  label="State *"
                  error={
                    formik?.touched?.state && Boolean(formik?.errors?.state)
                  }
                  helperText={formik?.touched?.state && formik?.errors?.state}
                  InputLabelProps={{ shrink: true }}
                  className={classes.billingAddressField}
                  onChange={formik.handleChange}
                  value={formik.values.state}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="zip"
                  label="Zip/Postal Code *"
                  error={formik?.touched?.zip && Boolean(formik?.errors?.zip)}
                  helperText={formik?.touched?.zip && formik?.errors?.zip}
                  InputLabelProps={{ shrink: true }}
                  className={classes.billingAddressField}
                  onChange={formik.handleChange}
                  value={formik.values.zip}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="country"
                  label="Country *"
                  error={
                    formik?.touched?.country && Boolean(formik?.errors?.country)
                  }
                  helperText={
                    formik?.touched?.country && formik?.errors?.country
                  }
                  InputLabelProps={{ shrink: true }}
                  className={classes.billingAddressField}
                  onChange={formik.handleChange}
                  value={formik.values.country}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BillingAddressCapture;
