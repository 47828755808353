import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  mutation CreateProjectFromTemplate(
    $templateId: String!
    $projectInfo: CreateJrnInput!
    $startDate: String!
    $fromLeadId: String
  ) {
    createProjectFromTemplate(
      input: {
        templateId: $templateId
        projectInfo: $projectInfo
        startDate: $startDate
        fromLeadId: $fromLeadId
      }
    ) {
      contentId
      customerId
      jrnId
      title
      creatorId
      contentUrl
      description
      privacyLevel
      inviteCode
      endDate
      startDate
      allowedToEdit
      allowedToAdd
      allowedToView
      usersEverOnJrn
      synced
      labels
      latitude
      longitude
      address
      type
      addedBy
      date
      isPublic
      aspectRatio
      phoneNumber
      phoneNumberActive
      assignedTo
      priority
      contentStatus
      useTimetracking
      useTimetrackingQuestions
      timetrackingQuestions
      timetrackingQuestionsCheckout
      timetrackingAnswers {
        questionId
        answer
      }
      subtype
      permissionsFrom
      requireAdmin
      completionType
      completionInfo {
        instructions
        completedById
        completedByDate
        textResponse
        mediaInfo {
          uri
          aspectRatio
        }
      }
      comments {
        items {
          content
          commentId
          dateCreated
          userId
        }
      }
      loves {
        items {
          loveId
          userId
          contentId
        }
      }
      amount {
        value
      }
      recurrence {
        rangeId
        rangeStartDate
        rangeEndDate
        daysOfTheWeek
      }
      fromTemplate
      timetrackingType
      # REMOVEME: remove this when "bugets" field is deprecated
      budgets {
        ...BudgetFields
      }
      workflowStage
      workflowStageReason
      lastMessageSent
      qboCustomerId
    }
  }
`;
