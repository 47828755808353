import React, { useState } from 'react';
import {
  Button,
  Collapse,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@material-ui/icons';

import copyToClipboard from 'copy-to-clipboard';
import palette from '../../theme/palette';

const useStyles = makeStyles(theme => ({
  statModalLabel: {
    textTransform: 'uppercase',
  },
  statSectionWrapper: {
    paddingTop: 25,
  },
  statSectionHeader: {
    color: theme.palette.brandColorPrimary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    paddingBottom: 5,
  },
  statText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    paddingTop: 5,
    paddingBottom: 5,
  },
  subStatText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    paddingTop: 5,
    paddingBottom: 5,
    marginLeft: 32,
  },
}));

const BillsViewStatsSection = ({ stat, defaultExpansion }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(defaultExpansion || false);

  return (
    <div className={classes.statSectionWrapper}>
      <Typography variant="h4" className={classes.statSectionHeader}>
        {stat.sectionHeader}
      </Typography>
      <Typography variant="h6" className={classes.statText}>
        <span className={classes.statModalLabel}># of {stat.statusLabel}</span>
        <Tooltip title="Click to copy">
          <Button
            onClick={() => {
              copyToClipboard(stat.entriesCount);
            }}
          >
            {stat.entriesCount}
          </Button>
        </Tooltip>
      </Typography>
      <Typography variant="h6" className={classes.statText}>
        <span className={classes.statModalLabel}>Total</span>
        <Tooltip title="Click to copy">
          <Button
            onClick={() => {
              copyToClipboard(stat.total);
            }}
          >
            {stat.total}
          </Button>
        </Tooltip>
      </Typography>
      {!!stat.balanceDue && (
        <Typography variant="h6" className={classes.statText}>
          <span className={classes.statModalLabel}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
            Total Balance Due
          </span>
          <Tooltip title="Click to copy">
            <Button
              onClick={() => {
                copyToClipboard(stat.balanceDue);
              }}
              style={{ color: stat.color.balanceDue }}
            >
              {stat.balanceDue}
            </Button>
          </Tooltip>
        </Typography>
      )}
      <Collapse in={open} timeout="auto" unmountOnExit>
        {!!stat.overDueBalance && (
          <Typography variant="h6" className={classes.subStatText}>
            <span className={classes.statModalLabel}>OverDue</span>
            <Tooltip title="Click to copy">
              <Button
                onClick={() => {
                  copyToClipboard(stat.overDueBalance);
                }}
                style={{ color: stat.color.overDueBalance }}
              >
                {stat.overDueBalance}
              </Button>
            </Tooltip>
          </Typography>
        )}
        {!!stat.todayBalanceDue && (
          <Typography variant="h6" className={classes.subStatText}>
            <span className={classes.statModalLabel}>Due Today</span>
            <Tooltip title="Click to copy">
              <Button
                onClick={() => {
                  copyToClipboard(stat.todayBalanceDue);
                }}
                style={{ color: stat.color.todayBalanceDue }}
              >
                {stat.todayBalanceDue}
              </Button>
            </Tooltip>
          </Typography>
        )}
        {!!stat.upcomingBalanceDue && (
          <Typography variant="h6" className={classes.subStatText}>
            <span className={classes.statModalLabel}>Upcoming Due</span>
            <Tooltip title="Click to copy">
              <Button
                onClick={() => {
                  copyToClipboard(stat.upcomingBalanceDue);
                }}
                style={{ color: stat.color.upcomingBalanceDue }}
              >
                {stat.upcomingBalanceDue}
              </Button>
            </Tooltip>
          </Typography>
        )}
      </Collapse>
      {!!stat.overPaid && (
        <Typography variant="h6" className={classes.statText}>
          <span className={classes.statModalLabel}>Over paid</span>
          <Tooltip title="Click to copy">
            <Button
              onClick={() => {
                copyToClipboard(stat.overPaid);
              }}
              style={{ color: palette.brandColorError }}
            >
              {stat.overPaid}
            </Button>
          </Tooltip>
        </Typography>
      )}
    </div>
  );
};

export default BillsViewStatsSection;
