import React from 'react';
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import Linkify from 'react-linkify';
import { asCurrency } from '../../dashboard/dashboard.utils';
import { ACCOUNTING_CATEGORY } from '../../../config/appDefaults';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.brandColorLightGrey,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles(() => ({
  root: {
    fontSize: 12,
  },
}))(TableCell);

const TransactionsSection = ({ categoryType, transactions }) => {
  return (
    <div style={{ width: '100%', marginTop: 8, marginBottom: 4 }}>
      <Paper
        variant="outlined"
        style={{
          padding: 8,
        }}
      >
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ minWidth: '105px' }}>
                  Date
                </StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>No.</StyledTableCell>
                <StyledTableCell>
                  {categoryType === ACCOUNTING_CATEGORY.INCOME
                    ? 'Customer'
                    : 'Supplier'}
                </StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell align="right">Amount</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map(
                ({
                  date,
                  docNum,
                  txnType,
                  customerName,
                  memo: description,
                  amount,
                }) => (
                  <StyledTableRow key={`${customerName}-${docNum}-${date}`}>
                    <StyledTableCell component="th" scope="row">
                      {date &&
                        moment(date)
                          .utc()
                          .format('YYYY-MM-DD')}
                    </StyledTableCell>
                    <StyledTableCell>{txnType}</StyledTableCell>
                    <StyledTableCell>{docNum}</StyledTableCell>
                    <StyledTableCell>{customerName}</StyledTableCell>
                    <StyledTableCell>
                      <Linkify>{description}</Linkify>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {amount && asCurrency(parseFloat(amount))}
                    </StyledTableCell>
                  </StyledTableRow>
                )
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  );
};

export default TransactionsSection;
