import React from 'react';

import { Button, Grid } from '@material-ui/core';
import { DashboardControlBar } from '../dashboard';

const CustomScanDashboardControlBar = ({
  title,
  loading,
  hideAccessExplainer,
  disabled,
  onNewScanClick,
}) => {
  return (
    <DashboardControlBar
      title={title}
      hideAccessExplainer={hideAccessExplainer}
    >
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          style={{ borderRadius: 32, margin: 4 }}
          onClick={onNewScanClick}
          disabled={disabled || loading}
        >
          New Scan
        </Button>
      </Grid>
    </DashboardControlBar>
  );
};

export default CustomScanDashboardControlBar;
