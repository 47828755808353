import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import {
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
} from '@material-ui/icons';
import { ButtonBase, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';

import FilterList from '../FilterSearch/filterSearch';
import LoadingCover from '../LoadingCover/loadingCover';
import ConditionalTooltip from '../ConditionalTooltip/conditionalTooltip';

const useStyles = makeStyles(theme => ({
  checkedCheckbox: {
    color: theme.palette.brandColorPrimary,
  },
  uncheckedCheckbox: {
    color: theme.palette.brandColorPrimary,
  },
}));

const PickMultiUserDialog = props => {
  const {
    onCancel,
    onSave,
    initialSelectedItems,
    open,
    single,
    options,
    allowNull,
    showSaving,
    titleText,
    ...other
  } = props;
  const classes = useStyles();

  const [selectedItems, setSelectedItems] = useState(
    initialSelectedItems || []
  );
  const [useThisData, setUseThisData] = useState(options);

  useEffect(() => {
    setUseThisData(options);
  }, [options]);

  const handleCancel = () => {
    onCancel();
  };

  const handleOk = () => {
    if (selectedItems?.length > 0) {
      onSave(selectedItems);
    }
  };

  const handleChange = clickedUserId => {
    let newSelectedItems = [];
    if (selectedItems.includes(clickedUserId)) {
      newSelectedItems = selectedItems.filter(item => item !== clickedUserId);
    } else {
      newSelectedItems = [...selectedItems, clickedUserId];
    }
    setSelectedItems(newSelectedItems);
  };

  const resetValue = () => {
    setSelectedItems([]);
  };

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onCancel();
        }
      }}
      disableEscapeKeyDown
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        {titleText || 'Choose recipients'}
      </DialogTitle>
      {selectedItems?.length < 1 && (
        <Typography
          variant="body1"
          style={{ color: '#e00' }}
          gutterBottom
          align="center"
        >
          You must choose at least 1 recipient.
        </Typography>
      )}
      <DialogContent dividers>
        <FilterList
          data={options}
          passBack={filteredData => setUseThisData(filteredData)}
          searchBy="username"
          isUserSearch
        />
        {useThisData.map(option => (
          <Grid
            container
            key={option.userId}
            style={{
              paddingTop: 8,
              borderBottom: '1px solid #eee',
              paddingBottom: 8,
            }}
          >
            <Grid item container alignItems="center" style={{ flex: 1 }}>
              <Avatar
                style={{ marginRight: '5px' }}
                alt={`Profile pic for ${option.username}`}
                src={option.profilePic}
              />{' '}
              <span style={{ marginLeft: '5px' }}>{option.username}</span>
            </Grid>
            <Grid item container alignItems="center" style={{ flex: 0 }}>
              <ButtonBase onClick={() => handleChange(option.userId)}>
                {selectedItems.includes(option.userId) ? (
                  <CheckBoxOutlined className={classes.checkedCheckbox} />
                ) : (
                  <CheckBoxOutlineBlankOutlined
                    className={classes.uncheckedCheckbox}
                  />
                )}
              </ButtonBase>
            </Grid>
          </Grid>
        ))}
        {allowNull && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '24px' }}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<PersonAddDisabledIcon />}
                onClick={resetValue}
              >
                Do not send to anyone
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <ConditionalTooltip
          showIf={selectedItems?.length < 1}
          title="Must choose at least 1"
          placement="top"
        >
          <span>
            <Button
              onClick={handleOk}
              color="primary"
              disabled={selectedItems?.length < 1}
            >
              Ok
            </Button>
          </span>
        </ConditionalTooltip>
      </DialogActions>
      {!!showSaving && <LoadingCover />}
    </Dialog>
  );
};

export default PickMultiUserDialog;
