import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  query GetCompanyAdminContent(
    $contentType: String!
    $companyId: String!
    $includeArchive: Boolean
    $nextToken: String
  ) {
    getCompanyAdminContent(
      input: {
        contentType: $contentType
        companyId: $companyId
        includeArchive: $includeArchive
        nextToken: $nextToken
      }
    ) {
      items {
        contentId
        jrnId
        creatorId
        address
        type
        subtype
        permissionsFrom
        requireAdmin
        completionType
        completionInfo {
          instructions
          completedById
          completedByDate
          textResponse
          mediaInfo {
            uri
            aspectRatio
          }
        }
        contentUrl
        date
        dateCreated
        description
        labels
        latitude
        longitude
        isPublic
        synced
        title
        endDate
        startDate
        addedBy
        assignedTo
        contentStatus
        priority
        recurrence {
          rangeId
          rangeStartDate
          rangeEndDate
          daysOfTheWeek
        }
        timetrackingAnswers {
          questionId
          answer
        }
        amount {
          value
        }
        workflowStage
        workflowStageReason
        rfiIds
        globalExpenseId
        billable
      }
      projects {
        address
        contentId
        jrnId
        title
        creatorId
        contentUrl
        description
        endDate
        customerId
        customerInfo {
          customerId
          managingCompanyId
          firstName
          lastName
          address
          email
          latitude
          longitude
          phoneNumber
          notes
          companyName
          qboCustomerId
        }
        qboCustomerId
        privacyLevel
        inviteCode
        allowedToEdit
        allowedToAdd
        allowedToView
        usersEverOnJrn
        startDate
        synced
        labels
        latitude
        longitude
        phoneNumber
        phoneNumberActive
        type
        lastMessageSent
        useTimetracking
        useTimetrackingQuestions
        timetrackingQuestions
        timetrackingQuestionsCheckout
        # REMOVEME: remove this when "bugets" field is deprecated
        budgets {
          ...BudgetFields
        }
        fromTemplate
        timetrackingType
        workflowStage
        workflowStageReason
      }
      users {
        userId
        username
        firstName
        lastName
        profilePic
        accessMode
        userType
      }
      timetrackingQuestions {
        questionId
        questionType
        questionText
        answers
        isRequired
        managingCompanyId
        creatorId
      }
      nextToken
      queryNote
    }
  }
`;
