import { makeStyles } from '@material-ui/styles';
import { isMobile } from 'react-device-detect';

import palette from '../../theme/palette';
import BackgroundImage from '../../assets/images/background.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100dvh',
  },
  button: {
    marginRight: theme.spacing(1),
    minWidth: 140,
  },
  title: {
    color: palette.brandColorDarkGrey,
    marginBottom: 24,
    fontSize: 28,
  },
  message: {
    marginBottom: 24,
    maxWidth: 460,
  },
  messageText: {
    color: palette.brandColorDarkGrey,
    lineHeight: 1.5,
    textAlign: 'left',
  },
  container: {
    maxWidth: 600,
    padding: 32,
    marginRight: isMobile ? 0 : 120,
    marginLeft: isMobile ? 0 : 120,
    height: isMobile ? '100dvh' : 720,
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    zIndex: -1,
  },
  subContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 auto',
    minHeight: isMobile ? 0 : 650,
  },
  levelLogo: {
    width: 160,
    marginBottom: 40,
  },
}));

export { useStyles };
