import React from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  IconButton,
  TextField,
  Typography,
  Switch,
} from '@material-ui/core';
import { Settings as SettingsIcon } from '@material-ui/icons';
import _ from 'lodash';
import {
  ACCOUNTING_METHOD,
  SCOREBOARD_NAME,
} from '../../../config/appDefaults';
import {
  DashboardControlBar,
  DashboardSettings,
  DashboardSettingsTargets,
  DashboardSettingsReportData,
} from '../../dashboard';
import {
  PROJECT_PROFITABILITY_GRAPH,
  projectProfitabilityGraphs,
} from './project-profitability-graphs';

const ProjectProfitabilityControlBar = ({
  title,
  projectId,
  accountingMethod,
  setShowSecondary,
  onRefresh,
  showSettings,
  setShowSettings,
}) => {
  const handleSettingsClick = () => {
    setShowSettings(!showSettings);
  };

  const targetData = projectProfitabilityGraphs
    .filter(
      ({ name: reportName }) =>
        reportName === PROJECT_PROFITABILITY_GRAPH.PROJECT_PROFITABILITY
    )
    .map(({ name: reportName, label, targetType }) => ({
      name: `${reportName}:${projectId}`,
      label,
      dataType: targetType,
    }));

  const accountingMethodDetails = (
    <Typography variant="body1" style={{ marginRight: 24 }}>
      Most businesses should use the Cash accounting method to discover the true
      risk of delayed or withheld payments and upfront expenses on their
      financial health.
    </Typography>
  );

  const settingsSections = [
    {
      label: 'Report Data',
      component: DashboardSettingsReportData,
      sectionProps: {
        scoreboardName: SCOREBOARD_NAME.PROJECT_PROFITABILITY,
        accountingMethod,
        accountingMethodDetails,
      },
    },
    {
      label: 'Targets',
      component: DashboardSettingsTargets,
      sectionProps: {
        targetsToEdit: targetData,
      },
    },
  ];

  const accountingMethodDisplay =
    accountingMethod !== null
      ? _.find(ACCOUNTING_METHOD, ({ key }) => key === accountingMethod)
          .displayValue
      : ' ';

  return (
    <>
      <DashboardControlBar title={title}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 4,
          }}
        >
          <div
            style={{
              transform: 'translate(-18.5px, -1px) scale(0.75)',
              fontSize: 14,
              width: 148,
              textAlign: 'left',
            }}
          >
            Show{' '}
            {accountingMethod === ACCOUNTING_METHOD.CASH.key
              ? 'Accrual'
              : 'Cash'}{' '}
            Data?
          </div>
          <div style={{ height: 30.5 }}>
            <div style={{ marginTop: -4 }}>
              <Switch
                color="primary"
                defaultChecked={false}
                onChange={event => {
                  const isChecked = event.target.checked;
                  setShowSecondary(isChecked);
                }}
                edge="start"
              />
            </div>
          </div>
        </div>
        <Grid item>
          <TextField
            label="Accounting Method"
            value={accountingMethodDisplay}
            disabled
            style={{
              width: 150,
            }}
          />
        </Grid>
        <Grid item>
          <IconButton
            color="primary"
            variant="outlined"
            onClick={handleSettingsClick}
          >
            <SettingsIcon />
          </IconButton>
        </Grid>
      </DashboardControlBar>
      {showSettings && (
        <DashboardSettings
          title={`${title} Scoreboard Settings`}
          handleClose={() => setShowSettings(false)}
          sections={settingsSections}
          onRefresh={onRefresh}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(ProjectProfitabilityControlBar);
