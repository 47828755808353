import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from '@material-ui/core';
import _ from 'lodash';
// Components
// eslint-disable-next-line import/no-cycle
import ProjectContentListing from '../../../../components/ProjectContentListing/projectContentListing';
// eslint-disable-next-line import/no-cycle
import ProjectDetailsHeader from './projectDetailsHeader';
import ContentDetailsModal from '../../../add-to-project/content-details-modal';
import SaveAsTemplateModal from '../project-templates/save-as-template-modal';
import ProjectProfitabilityScoreboard from '../../../../components/scoreboards/project-profitability';
import LevelModal from '../../../../components/Modal/level-modal';
import ApplyTemplateContentModal from '../../../../components/apply-template-content';
import LoadingCover from '../../../../components/LoadingCover/loadingCover';
import OkCancelDialog from '../../../../components/OkCancelDialog/okCancelDialog';

const useStyles = makeStyles(theme => ({
  root: {
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  projectDetailsWrapper: {
    position: 'relative',
    marginBottom: theme.spacing(4),
  },
  closeButton: {
    color: '#666',
  },
  coverWrapper: {
    textAlign: 'center',
    backgroundColor: '#f4f4f4',
    minHeight: 200,
    color: theme.palette.brandColorDarkGrey,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  coverImage: {
    maxWidth: '100%',
    height: 'auto',
    maxHeight: 300,
    alignSelf: 'center',
  },
  projectInfoWrapper: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.brandColorDarkGrey,
  },
  projectButtonsWrapper: {
    width: '100%',
    textAlign: 'right',
    marginBottom: theme.spacing(2),
  },
  actionButtons: {
    color: theme.palette.brandColorDarkGrey,
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
}));

const ProjectDetailsView = ({
  userId,
  contentBasicInfo,
  projectUsers,
  companyCrew,
  assignedToUsername,
  contentToShow,
  scrollPosition,
  isLoading,
  resourceFound,
  isExtendedTask,
  refetchAll,
  refetchJrnContent,
  deleteThisContent,
  closeProject,
  className,
  lastTabIndex,
  projectCanAdd,
  projectCanEdit,
  isArchive,
  hideShowProject,
  timeManager,
  toTriggerQueryChange,
  managingCompanyInfo,
  applyPermsToSubs,
  applyLaborRatesToSubs,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showManualAddDialog, setShowManualAddDialog] = useState({
    open: false,
    canEdit: false,
    canAdd: false,
    parentId: null,
    projectInfo: null,
  });
  const [showProjectScoreboard, setShowProjectScoreboard] = useState(false);
  const [
    showApplyTemplateContentModal,
    setShowApplyTemplateContentModal,
  ] = useState(false);
  const [showSaveAsTemplateDialog, setShowSaveAsTemplateDialog] = useState(
    false
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const isTemplate = contentBasicInfo && contentBasicInfo.type === 'template';
  const [showLoaderCover, setShowLoaderCover] = useState({
    open: false,
    message: '',
  });
  const [okCancelDialogSettings, setOkCancelDialogSettings] = useState({
    open: false,
  });

  const { url } = useRouteMatch();
  const cleanedUrl =
    url.substring(url.length - 1, url.length) === '/'
      ? url.substring(0, url.length - 1)
      : url;
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleSimpleMenuClose = () => {
    setAnchorEl(null);
  };

  const startDeleteProcess = () => {
    setDeleteModalOpen(true);
  };

  const startApplyTemplateProcess = () => {
    if (managingCompanyInfo && managingCompanyInfo.isCompanyAdmin) {
      setShowApplyTemplateContentModal(true);
    }
  };

  const startArchivalProcess = () => {
    if (managingCompanyInfo && !isArchive) {
      setArchiveModalOpen(true);
    } else {
      hideShowProject(contentBasicInfo.contentId, isArchive);
      closeProject();
    }
  };

  // Check if requested content is loading
  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        className={classes.projectDetailsWrapper}
      >
        <ContentLoader
          height={300}
          width={600}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
          style={{ flex: 1 }}
        >
          <rect x="0" y="0" rx="0" ry="0" width="300" height="200" />
          <rect x="367" y="58" rx="0" ry="0" width="160" height="27" />
          <rect x="326" y="100" rx="0" ry="0" width="244" height="12" />
          <rect x="326" y="120" rx="0" ry="0" width="244" height="12" />
          <rect x="326" y="140" rx="0" ry="0" width="244" height="12" />
        </ContentLoader>
      </Grid>
    );
  }

  // Check if requested content is valid
  if (!resourceFound) {
    return (
      <Grid
        container
        justifyContent="center"
        className={classes.projectDetailsWrapper}
      >
        <Grid item>
          <Typography variant="h4" align="center" style={{ marginTop: 32 }}>
            You do not have permission to view this project or no project was
            found.
          </Typography>
          <br />
          <br />
          <Typography variant="h5" align="center">
            Please check back later if you believe you received this message in
            error.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  // All systems go from here on out...
  // Control add or edit dialog content
  const showAddOrEdit = mode => {
    let parentInfo = null;
    let existingInfo = null;

    if (mode === 'edit') {
      // Edit this content
      existingInfo = contentBasicInfo;
      if (isExtendedTask) {
        parentInfo = { contentId: contentBasicInfo.jrnId }; // since this is all that's needed in the next screen if it's an extended task
      }
    } else {
      // Add child to this content
      parentInfo = contentBasicInfo;
    }

    // Invoke dialog
    setShowManualAddDialog({
      open: true,
      mode: mode || 'addTo',
      canEdit: projectCanEdit,
      parentInfo,
      existingInfo,
    });
  };

  // Handle apply permissions from the parent down to the subs
  const performPermsPush = async () => {
    const permsPushResponse = await applyPermsToSubs();
    if (!permsPushResponse || permsPushResponse.status !== 'success') {
      // show error dialog
      setOkCancelDialogSettings({
        open: true,
        title: 'Error',
        onConfirm: () => {
          setOkCancelDialogSettings({ open: false });
        },
        onClose: () => {
          setOkCancelDialogSettings({ open: false });
        },
        okButtonText: 'Ok',
        hideCancel: true,
        children: (
          <Typography variant="body1" gutterBottom>
            Sorry, something went wrong while trying to apply the permissions.
            Please try again.
          </Typography>
        ),
      });
    }
  };
  const handleApplyPermsToSubs = () => {
    // ask user if they want to apply permissions to subs with message about it overwriting existing permissions and that if they change the permissions on the parent, they'll need to run this again
    setOkCancelDialogSettings({
      open: true,
      title: 'Push Permissions To All Subprojects?',
      onClose: () => {
        setOkCancelDialogSettings({ open: false });
      },
      onConfirm: () => {
        performPermsPush();
      },
      okButtonText: 'Yes, push permissions',
      children: (
        <>
          <Typography variant="body1" gutterBottom>
            This will apply the permissions you have set on this project to all
            of its subprojects. This cannot be undone.
          </Typography>
          <Typography variant="body1">
            <span style={{ color: '#e00' }}>PLEASE NOTE:</span> If you change
            the permissions of this project in the future, you will need to run
            this again.
          </Typography>
        </>
      ),
    });
  };

  // Handle apply labor rates from the parent down to the subs
  const performLaborRatesPush = async () => {
    const permsLaborRatesResponse = await applyLaborRatesToSubs();
    if (
      !permsLaborRatesResponse ||
      permsLaborRatesResponse.status !== 'success'
    ) {
      // show error dialog
      setOkCancelDialogSettings({
        open: true,
        title: 'Error',
        onConfirm: () => {
          setOkCancelDialogSettings({ open: false });
        },
        onClose: () => {
          setOkCancelDialogSettings({ open: false });
        },
        okButtonText: 'Ok',
        hideCancel: true,
        children: (
          <Typography variant="body1" gutterBottom>
            Sorry, something went wrong while trying to apply the labor rates.
            Please try again.
          </Typography>
        ),
      });
    }
  };

  const handleApplyLaborRatesToSubs = () => {
    // ask user if they want to apply permissions to subs with message about it overwriting existing permissions and that if they change the permissions on the parent, they'll need to run this again
    setOkCancelDialogSettings({
      open: true,
      title: 'Push Crew Labor Rates To All Subprojects?',
      onClose: () => {
        setOkCancelDialogSettings({ open: false });
      },
      onConfirm: () => {
        performLaborRatesPush();
      },
      okButtonText: 'Yes, push all crew labor rates',
      children: (
        <>
          <Typography variant="body1" gutterBottom>
            This will apply the labor rates you have set on this project to all
            of its subprojects. This cannot be undone.
          </Typography>
          <Typography variant="body1">
            <span style={{ color: '#e00' }}>PLEASE NOTE:</span> If you change
            the labor rates of this project in the future, you will need to run
            this again.
          </Typography>
        </>
      ),
    });
  };

  // Control add or edit dialog content
  const showSaveAsTemplate = () => {
    setShowSaveAsTemplateDialog(true);
  };

  // Delete extended tasks or projects
  const deleteContent = async () => {
    await deleteThisContent();
  };

  // Archive this project
  const archiveProject = () => {
    hideShowProject(contentBasicInfo.contentId, isArchive);
    setArchiveModalOpen(false);
    closeProject();
  };

  let allUsers = null;
  if (projectUsers && companyCrew) {
    allUsers = [...projectUsers];
    _.forEach(companyCrew, crewMember => {
      if (
        !_.includes(
          allUsers,
          projectUser => projectUser.userId === crewMember.userId
        )
      ) {
        allUsers.push(crewMember);
      }
    });
  }

  return (
    <>
      <Grid
        container
        justifyContent="center"
        className={classes.projectDetailsWrapper}
      >
        {contentBasicInfo && (
          <>
            <ProjectDetailsHeader
              userId={userId}
              projectInfo={contentBasicInfo}
              className={className}
              showActionButtons
              handleClick={handleClick}
              anchorEl={anchorEl}
              handleClose={handleSimpleMenuClose}
              projectCanEdit={projectCanEdit}
              projectCanAdd={projectCanAdd}
              assignedToUsername={assignedToUsername}
              handleAddOrEdit={showAddOrEdit}
              handleSaveAsTemplate={showSaveAsTemplate}
              cleanedUrl={cleanedUrl}
              startDeleteProcess={startDeleteProcess}
              startApplyTemplateProcess={startApplyTemplateProcess}
              startArchivalProcess={startArchivalProcess}
              closeProject={closeProject}
              refetchAll={refetchAll}
              isThisExtTask={isExtendedTask}
              isArchive={isArchive}
              contentToShow={contentToShow}
              timeManager={timeManager}
              projectUsers={projectUsers}
              hideStatTiles={isTemplate}
              showProjectScoreboard={showProjectScoreboard}
              setShowProjectScoreboard={setShowProjectScoreboard}
              handleApplyPermsToSubs={handleApplyPermsToSubs}
              handleApplyLaborRatesToSubs={handleApplyLaborRatesToSubs}
            />
            {contentToShow && (
              <ProjectContentListing
                userId={userId}
                scrollPosition={scrollPosition}
                content={contentToShow}
                allUsers={allUsers}
                projectInfo={contentBasicInfo}
                projectCanEdit={projectCanEdit}
                projectCanAdd={projectCanAdd}
                lastTabIndex={lastTabIndex}
                timeManager={timeManager}
                setShowLoaderCover={setShowLoaderCover}
              />
            )}
          </>
        )}
      </Grid>
      {deleteModalOpen && (
        <OkCancelDialog
          title="Are you sure?"
          open={deleteModalOpen}
          onClose={() => {
            setDeleteModalOpen(false);
          }}
          onConfirm={deleteContent}
        >
          Erasing the {!isExtendedTask ? contentBasicInfo.type : 'task'} will
          erase it for everyone else too.
          <br />
          It will also delete all of the content in the{' '}
          {!isExtendedTask
            ? `${contentBasicInfo.type} (including the content in any subprojects).`
            : 'task.'}
        </OkCancelDialog>
      )}
      <Dialog
        maxWidth="sm"
        aria-labelledby="confirmation-dialog"
        open={archiveModalOpen}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          This will archive the project for all users and hide its data from the
          calendar and admin reporting tools. Make sure your bookkeeper has
          everything they need before archiving it!
          <br />
          <br />
          If it&apos;s a subproject, you will still be able to access it through
          the main project.
          <br />
          <br />
          <i>
            Hint: Archiving projects speeds up the loading time of your
            company&apos;s active data. Great job! &#128077;
          </i>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setArchiveModalOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={archiveProject} color="primary">
            Archive
          </Button>
        </DialogActions>
      </Dialog>
      {showManualAddDialog.open && (
        <ContentDetailsModal
          mode={showManualAddDialog.mode}
          canEdit={showManualAddDialog.canEdit}
          parentInfo={showManualAddDialog.parentInfo}
          existingInfo={showManualAddDialog.existingInfo}
          onClose={() => {
            setShowManualAddDialog({
              ...showManualAddDialog,
              open: false,
            });
          }}
        />
      )}
      {showSaveAsTemplateDialog && (
        <SaveAsTemplateModal
          projectInfo={contentBasicInfo}
          contentToInclude={contentToShow}
          handleClose={({ setQueryTo }) => {
            if (setQueryTo) {
              toTriggerQueryChange(setQueryTo);
            }
            setShowSaveAsTemplateDialog(false);
          }}
        />
      )}
      {showProjectScoreboard && (
        <LevelModal
          open
          handleClose={() => setShowProjectScoreboard(false)}
          maxWidth="xl"
          showCloseButton
        >
          <ProjectProfitabilityScoreboard
            projectInfo={contentBasicInfo}
            mode="modal"
          />
        </LevelModal>
      )}
      {showApplyTemplateContentModal && (
        <ApplyTemplateContentModal
          projectInfo={contentBasicInfo}
          handleClose={() => setShowApplyTemplateContentModal(false)}
          onSuccess={refetchJrnContent}
        />
      )}
      {showLoaderCover.open && (
        <LoadingCover loader="linear">
          <Typography variant="h3" align="center">
            {showLoaderCover.message}
          </Typography>
        </LoadingCover>
      )}
      {okCancelDialogSettings.open && (
        <OkCancelDialog {...okCancelDialogSettings} />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(ProjectDetailsView);
