import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.CompanyWithoutProducts}
  mutation AddCompanyCrewMember($companyId: String!, $userId: String!) {
    addCompanyCrewMember(input: { companyId: $companyId, userId: $userId }) {
      ...CompanyFieldsWithoutProducts
    }
  }
`;
