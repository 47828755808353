import React, { useMemo } from 'react';
import { compose } from 'react-apollo';
import _ from 'lodash';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import ContentLoader from 'react-content-loader';

import { GetAllContentByJrnIdAction } from '../../graphql/graphql';
import GlobalPaymentBillSplit from './global-payment-bill-split';
import styles from './view-global-item.styles';
import { CONTENT_TYPE } from '../../config/appDefaults';

const useStyles = makeStyles(styles);

const GlobalPaymentSplits = ({
  contentLoading,
  jrnContent,
  selectable,
  parentClasses,
  onViewGlobalItem,
}) => {
  const classes = useStyles();

  const billPayments = useMemo(() => {
    let paymentsToUse = null;

    if (jrnContent) {
      paymentsToUse = _.filter(jrnContent, {
        type: CONTENT_TYPE.BILL_PAYMENT,
      });
    }

    return paymentsToUse;
  }, [jrnContent]);

  return (
    <>
      <Grid
        container
        item
        direction="column"
        xs={12}
        className={classes && classes.infoSection}
        spacing={1}
      >
        <Grid item xs={12}>
          <Typography variant="h5" className={parentClasses.label}>
            Bill Allocation
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {contentLoading && (
            <ContentLoader
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
              className={classes.projectSplitContainer}
              height={50}
            >
              <circle cx="25" cy="25" r="15" />
              <rect x="50" y="12" rx="0" ry="0" width="150" height="12" />
              <rect x="50" y="30" rx="0" ry="0" width="50" height="8" />
            </ContentLoader>
          )}
          {!contentLoading && _.isEmpty(billPayments) && (
            <Typography variant="body1">Not allocated to any bills.</Typography>
          )}
          {!contentLoading &&
            _.map(billPayments, contentItem => (
              <GlobalPaymentBillSplit
                classes={classes}
                key={contentItem.contentId}
                content={contentItem}
                selectable={selectable}
                onViewGlobalItem={onViewGlobalItem}
              />
            ))}
        </Grid>
      </Grid>
    </>
  );
};

export default compose(GetAllContentByJrnIdAction)(GlobalPaymentSplits);
