import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import BillsViewStatsSection from './bills-view-stats-section';

const BillsViewStatsDialog = ({ open, billStats, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="data stats"
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        {billStats.map(stat => (
          <BillsViewStatsSection
            defaultExpansion={stat.sectionHeader === 'Overall'}
            stat={stat}
            key={stat.sectionHeader}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BillsViewStatsDialog;
