import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const JoinRedirect = ({ location, dispatch, currentAuth }) => {
  if (location.search) {
    const qsParams = new URLSearchParams(location.search);
    const key = qsParams.get('key');
    if (key) {
      dispatch({
        type: 'COMPANY_REGISTRATION_KEY',
        payload: Buffer.from(key, 'base64').toString('utf-8'),
      });
    }
  }

  if (!currentAuth.isLoggedIn) {
    return <Redirect to="/auth/signup" />;
  }

  return <Redirect to="/" />;
};

const mapStateToProps = state => ({
  currentAuth: state.currentAuth,
});

export default withRouter(connect(mapStateToProps)(JoinRedirect));
