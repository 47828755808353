import React from 'react';
import CreatableSelect from 'react-select/creatable';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  DateTimePicker,
} from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import moment from 'moment';
import uuid from 'uuid';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ContentLoader from 'react-content-loader';

import SplitButton from '../../components/SplitButton/splitButton';
import LevelPellEditor from './level-pell-editor/level-pell-editor';
import ErrorMessage from './error-message';
import LoadingCover from '../../components/LoadingCover/loadingCover';
import OkCancelDialog from '../../components/OkCancelDialog/okCancelDialog';

import { toggleItemInArray, runAnalytics } from '../../helpers';
import palette from '../../theme/palette';

const weekDays = [
  { label: 'S', value: 'Sunday', zeroBasedIndex: 0 },
  { label: 'M', value: 'Monday', zeroBasedIndex: 1 },
  { label: 'T', value: 'Tuesday', zeroBasedIndex: 2 },
  { label: 'W', value: 'Wednesday', zeroBasedIndex: 3 },
  { label: 'T', value: 'Thursday', zeroBasedIndex: 4 },
  { label: 'F', value: 'Friday', zeroBasedIndex: 5 },
  { label: 'S', value: 'Saturday', zeroBasedIndex: 6 },
];

const useStyles = makeStyles(theme => ({
  labelBasics: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  bigLabelBasics: {
    fontSize: 18,
    marginBottom: theme.spacing(1),
  },
  formSection: {
    marginTop: theme.spacing(3),
  },
  repeatingOptionsSection: {
    marginTop: theme.spacing(1),
  },
  dayOfWeekButton: {
    height: 40,
    width: 40,
    minWidth: 0,
    borderRadius: 20,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    background: palette.background.alt,
  },
  dayOfWeekButtonActive: {
    background: theme.palette.brandColorPrimary,
    color: '#fff',
    '&:hover': {
      background: theme.palette.brandColorPrimary,
    },
  },
  errorMessageText: {
    marginLeft: 10,
    color: theme.palette.brandColorError,
  },
}));

const AdvancedShiftCreator = ({
  currentUsersOnProject,
  allUsersOnProject,
  parentId,
  onDone,
  userInfo,
  onAddContent,
  onUpdateContent,
  existingContentInfo,
  labelOptions,
}) => {
  const classes = useStyles();
  const initialEmptyValues = {
    daysOfTheWeek: [],
    startDate: moment(),
    endDate: moment(),
    rangeStartDate: moment().startOf('day'),
    rangeEndDate: moment().endOf('day'),
    whosItFor: [],
    description: '',
    labels: [],
    shouldRepeat: false,
  };

  let useTheseValues = initialEmptyValues;
  const editingMode = !!existingContentInfo.contentId;
  let editingRange = false;

  let activeUsers = [];

  if (currentUsersOnProject && currentUsersOnProject.length > 0) {
    activeUsers.push(...currentUsersOnProject);
  }

  if (editingMode && activeUsers.length > 0) {
    // check current users
    const activeUser = _.find(
      currentUsersOnProject,
      currentUser => currentUser.userId === existingContentInfo.assignedTo
    );
    if (!activeUser) {
      const removedUser = _.find(
        allUsersOnProject,
        currentUser => currentUser.userId === existingContentInfo.assignedTo
      );

      if (removedUser) {
        removedUser.removedFromProject = true;
        activeUsers.push(removedUser);
      }
    }

    // sort list
    activeUsers =
      _.orderBy(activeUsers, [user => user.username.toLowerCase()], ['asc']) ||
      [];
  }

  if (existingContentInfo.contentId) {
    useTheseValues = {
      ...initialEmptyValues,
      startDate: moment(existingContentInfo.startDate),
      endDate: moment(existingContentInfo.endDate),
      whosItFor: [existingContentInfo.assignedTo],
      description: existingContentInfo.description,
      labels: existingContentInfo.labels,
    };
    if (existingContentInfo.recurrence) {
      editingRange = true;
      useTheseValues.rangeStartDate = moment(
        existingContentInfo.recurrence.rangeStartDate
      ).startOf('day');
      useTheseValues.rangeEndDate = moment(
        existingContentInfo.recurrence.rangeEndDate
      ).endOf('day');
      useTheseValues.daysOfTheWeek =
        existingContentInfo.recurrence.daysOfTheWeek;
      useTheseValues.shouldRepeat = true;
    }
  }
  const defaultClosedDialogInfo = { open: false, title: 'Info' };

  const [daysOfTheWeek, setDaysOfTheWeek] = React.useState(
    useTheseValues.daysOfTheWeek
  );
  const [startDate, setStartDate] = React.useState(useTheseValues.startDate);
  const [endDate, setEndDate] = React.useState(useTheseValues.endDate);
  const [rangeStartDate, setRangeStartDate] = React.useState(
    useTheseValues.rangeStartDate
  );
  const [rangeEndDate, setRangeEndDate] = React.useState(
    useTheseValues.rangeEndDate
  );
  const [whosItFor, setWhosItFor] = React.useState(useTheseValues.whosItFor);
  const [shouldRepeat, setShouldRepeat] = React.useState(
    useTheseValues.shouldRepeat
  );
  const [description, setDescription] = React.useState(
    useTheseValues.description
  );
  const [labels, setLabels] = React.useState(useTheseValues.labels);
  const [dialogInfo, setDialogInfo] = React.useState(defaultClosedDialogInfo);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState({});
  const thereAreErrors = Object.keys(errorMessages).length > 0;

  const resetInputs = () => {
    setDaysOfTheWeek(initialEmptyValues.daysOfTheWeek);
    setStartDate(initialEmptyValues.startDate);
    setEndDate(initialEmptyValues.endDate);
    setRangeStartDate(initialEmptyValues.startDate);
    setRangeEndDate(initialEmptyValues.rangeEndDate);
    setWhosItFor(initialEmptyValues.whosItFor);
    setDescription(initialEmptyValues.description);
    setLabels(initialEmptyValues.labels);
  };

  const handleDayOfWeekChange = day => {
    const dayOfWeekRequired = moment(startDate).day();
    if (
      dayOfWeekRequired !== day ||
      !daysOfTheWeek.includes(dayOfWeekRequired)
    ) {
      setDaysOfTheWeek(toggleItemInArray(daysOfTheWeek, day));
    }
  };

  React.useEffect(() => {
    if (editingRange) {
      setDialogInfo({
        title: 'Just a heads up...',
        message:
          'Since this shift is part of a set of shifts for this user, you are editing the entire set, not just a single shift.',
        open: true,
        onClose: () => setDialogInfo({ ...dialogInfo, open: false }),
        hideCancel: true,
      });
    }

    // onMount set the day of the week to repeat to the startDate day
    handleDayOfWeekChange(moment(startDate).day());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleADateChange = (value, forWhat) => {
    if (forWhat === 'startDate') {
      setStartDate(value);
      setRangeStartDate(moment(value).startOf('day'));
      // make sure dayoftheweek that this shift is on is included in the days of the week now
      const day = moment(value).day();
      if (!daysOfTheWeek.includes(day)) {
        setDaysOfTheWeek(toggleItemInArray(daysOfTheWeek, day));
      }
    } else if (forWhat === 'endDate') {
      setEndDate(value);
    } else if (forWhat === 'rangeEndDate') {
      setRangeEndDate(moment(value).endOf('day'));
    }
  };

  const handleNotesChange = notes => {
    setDescription(notes);
  };

  const handleWhosItForChange = userId => {
    setWhosItFor(toggleItemInArray(whosItFor, userId));
  };

  const renderAUser = user => {
    if (!user) return null;

    const toggleInState = () => {
      handleWhosItForChange(user.userId);
    };
    const renderMainPart = () => {
      return (
        <>
          <Grid item style={{ marginLeft: 8, marginRight: 8 }}>
            <Avatar alt={user.username} src={user.profilePic} />
          </Grid>
          <Grid item>
            <Typography variant="body1">{user.username}</Typography>
          </Grid>
        </>
      );
    };

    if (editingRange) {
      return (
        <Grid container alignItems="center" key={user.userId}>
          {renderMainPart()}
        </Grid>
      );
    }

    return (
      <Grid item xs={4} key={user.userId}>
        <ListItem
          button
          disabled={user.removedFromProject && !whosItFor.includes(user.userId)}
          onClick={toggleInState}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <Grid item>
            {whosItFor.includes(user.userId) ? (
              <CheckBoxIcon />
            ) : (
              <CheckBoxOutlineBlankIcon />
            )}
          </Grid>
          {renderMainPart()}
        </ListItem>
      </Grid>
    );
  };

  const inputValidation = () => {
    const errors = {
      // 'label of input here': 'Message here'
    };
    if (whosItFor.length < 1) {
      // at least 1 crew member needs to be selected
      errors.whosItFor = 'At least one crew member must be chosen.';
    }
    if (startDate > endDate) {
      // end date must be after the start date
      errors.endDate = 'End date must be after start date.';
    }
    if (shouldRepeat) {
      if (daysOfTheWeek.length < 1) {
        // some days of the week must be selected
        errors.daysOfTheWeek = 'At least one day of the week must be chosen.';
      }
      if (rangeStartDate > rangeEndDate) {
        // end date must be after the start date
        errors.rangeEndDate = 'End date must be after start date.';
      }
      if (rangeStartDate > startDate) {
        // end date must be after the start date
        errors.rangeStartDate =
          'Range start date must be before your first shift.';
      }
      // if (endDate > rangeEndDate) {
      //   // range end date must be after the initial shift end date
      //   errors.rangeEndDate = 'Range end date must include your first shift.';
      // }
    }
    // start date must be before end date
    // NOTE: start time does not have to be before the end time since it could go overnight
    //  (we will not be taking into account a shift that is longer than 24 hours)
    setErrorMessages(errors);
    const areThereErrors = Object.keys(errors).length > 0;
    return areThereErrors;
  };

  const handleLabelChange = passedLabels => {
    setLabels(passedLabels);
  };

  const turnOffIsSubmitting = () => {
    setIsSubmitting(false);
  };

  const handleSubmit = async afterSubmitAction => {
    setIsSubmitting(true);
    const errors = inputValidation();
    if (errors) {
      turnOffIsSubmitting();
      return;
    }
    // prep labels for submission
    const labelsToUse = labels ? labels.map(selection => selection.label) : [];

    const baseShift = {
      // contentId: uuid(), // set in loop
      // assignedTo: userId, // set in loop
      jrnId: parentId,
      creatorId: userInfo.userId,
      date: startDate.toISOString(),
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      title: null,
      description,
      isPublic: false,
      type: 'shift',
      synced: false,
      labels: labelsToUse,
      subtype: null,
    };

    const shiftsForAllUsers = whosItFor.map(userId => {
      const toReturn = {
        ...baseShift,
        contentId: editingMode ? existingContentInfo.contentId : uuid(),
        assignedTo: userId,
      };

      if (shouldRepeat) {
        toReturn.recurrence = {
          rangeId: toReturn.contentId,
          rangeStartDate: rangeStartDate.toISOString(),
          rangeEndDate: rangeEndDate.toISOString(),
          daysOfTheWeek,
        };
      }

      return toReturn;
    });

    let shiftsSavedProperly = false;
    if (editingMode) {
      try {
        await onUpdateContent(shiftsForAllUsers[0]);
        shiftsSavedProperly = true;
        const options = {
          contentAction: 'Update Content',
          projectId: parentId,
          userId: userInfo.userId,
          username: userInfo.username,
          email: userInfo.email,
          type: 'shift',
          contentId: shiftsForAllUsers[0].contentId,
        };
        runAnalytics('Contents', options);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('onUpdateContent shift err: ', err);
      }
    } else {
      //  uploaded regardless of if they repeat or not (will always be 1 item per user)
      const shiftActions = shiftsForAllUsers.map(shift => {
        const options = {
          contentAction: 'Add Content',
          projectId: parentId,
          userId: userInfo.userId,
          username: userInfo.username,
          email: userInfo.email,
          type: 'shift',
          contentId: shift.contentId,
        };
        runAnalytics('Contents', options);
        return onAddContent(shift);
      });
      try {
        await Promise.all(shiftActions);
        shiftsSavedProperly = true;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Shift saving error: ', err);
      }
    }

    if (shiftsSavedProperly) {
      if (afterSubmitAction === 'saveKeep') {
        turnOffIsSubmitting();
      } else if (afterSubmitAction === 'saveClear') {
        // reset the form and allow them to add another set of shifts
        resetInputs();
        turnOffIsSubmitting();
      } else {
        turnOffIsSubmitting();
        onDone();
      }
    } else {
      // let the user know that the shifts didnt save properly and to check their calendar
      // after we let them know
      // turnOffIsSubmitting();
    }
  };

  const renderDaysOfTheWeek = () => {
    return weekDays.map(weekday => {
      const tooltipTitle =
        weekday.zeroBasedIndex === moment(startDate).day()
          ? 'This is required based on the Start Date'
          : weekday.value;
      return (
        <Tooltip key={weekday.value} title={tooltipTitle}>
          <Button
            className={`${classes.dayOfWeekButton} ${daysOfTheWeek.includes(
              weekday.zeroBasedIndex
            ) && classes.dayOfWeekButtonActive}`}
            onClick={() => handleDayOfWeekChange(weekday.zeroBasedIndex)}
          >
            {weekday.label}
          </Button>
        </Tooltip>
      );
    });
  };

  return (
    <>
      <Grid container style={{ padding: '0px 30px 20px 30px' }}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid item container>
            <Grid item xs={12}>
              <Typography variant="h3" align="center">
                Shift {editingRange && 'Range '}
                {editingMode ? 'Editor' : 'Creator'}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container spacing={2} className={classes.formSection}>
            <Grid item xs={6}>
              <InputLabel className={classes.labelBasics}>
                Shift Start
              </InputLabel>
              <DateTimePicker
                name="startDate"
                value={startDate}
                helperText={errorMessages.startDate}
                error={!!errorMessages.startDate}
                onChange={value => handleADateChange(value, 'startDate')}
                variant="inline"
                fullWidth
                format="dddd MMMM D, YYYY @ h:mma"
                minutesStep={5}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.labelBasics}>Shift End</InputLabel>
              <DateTimePicker
                name="endDate"
                value={endDate}
                helperText={errorMessages.endDate}
                error={!!errorMessages.endDate}
                onChange={value => handleADateChange(value, 'endDate')}
                variant="inline"
                fullWidth
                format="dddd MMMM D, YYYY @ h:mma"
                minutesStep={5}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            className={classes.formSection}
            style={{ marginTop: 30 }}
          >
            <Grid item xs={12}>
              <InputLabel className={classes.labelBasics}>
                Crew members
                {editingRange && <> (Not editable for shift ranges)</>}:{' '}
                <ErrorMessage errorMessage={errorMessages.whosItFor} />
              </InputLabel>
            </Grid>
            <Grid container>
              {editingRange &&
                renderAUser(_.find(activeUsers, { userId: whosItFor[0] }))}
              {!editingRange &&
                activeUsers?.length > 0 &&
                activeUsers.map(renderAUser)}
              {!editingRange && !(activeUsers?.length > 0) && (
                <Grid container>
                  {_.times(3, index => (
                    <Grid item xs={4} key={index}>
                      <div style={{ maxWidth: 180 }}>
                        <ContentLoader viewBox="0 0 100 25" width="100%">
                          <rect x="0" y="0" width="100" height="25" />
                        </ContentLoader>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            container
            className={classes.formSection}
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.labelBasics}>
                Notes (will be included in each shift)
              </InputLabel>
              <div style={{ border: '1px solid #ccc' }}>
                <LevelPellEditor
                  defaultContent={description}
                  onChange={handleNotesChange}
                  containerClass="level-pell-content-addContentForm"
                  height={150}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.labelBasics}>Labels</InputLabel>
              <CreatableSelect
                isMulti
                options={labelOptions}
                value={labels}
                onChange={handleLabelChange}
                placeholder="Add labels to the shifts..."
                menuPlacement="auto"
              />
              <Typography variant="caption">
                Useful when filtering later
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            container
            spacing={2}
            className={classes.formSection}
            style={{
              border: '1px solid #bdbdbd',
              padding: 12,
              borderRadius: 16,
              marginTop: 30,
            }}
          >
            <Typography
              variant="h3"
              align="center"
              style={{
                marginTop: -27,
                background: '#fff',
                marginLeft: 12,
                padding: '0 12px',
              }}
            >
              Repeating Options{' '}
              {!editingRange && (
                <span className={classes.bigLabelBasics}>
                  (the shift range will be created for each crew member)
                </span>
              )}
            </Typography>

            <Grid container>
              {!editingRange && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={shouldRepeat}
                      onChange={() => {
                        setShouldRepeat(!shouldRepeat);
                      }}
                      name="shouldRepeat"
                      color="primary"
                    />
                  }
                  label="Would you like to repeat this shift?"
                />
              )}
            </Grid>
            {shouldRepeat && (
              <>
                <Grid item xs={12} className={classes.repeatingOptionsSection}>
                  <InputLabel className={classes.labelBasics}>
                    Days of the week{' '}
                    <ErrorMessage errorMessage={errorMessages.daysOfTheWeek} />
                  </InputLabel>
                  {renderDaysOfTheWeek()}
                </Grid>

                <Grid item xs={6} className={classes.repeatingOptionsSection}>
                  <InputLabel className={classes.labelBasics}>
                    Start repeating on
                  </InputLabel>
                  <Tooltip title="Pulled from start date above">
                    <span>
                      <DatePicker
                        disabled
                        name="rangeStartDate"
                        value={rangeStartDate}
                        format="dddd MMMM D, YYYY"
                        helperText={errorMessages.rangeStartDate}
                        error={!!errorMessages.rangeStartDate}
                        variant="inline"
                        disableToolbar
                        disablePast
                        fullWidth
                        autoOk
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} className={classes.repeatingOptionsSection}>
                  <InputLabel className={classes.labelBasics}>
                    Last day of repeating
                  </InputLabel>
                  <DatePicker
                    name="rangeEndDate"
                    value={rangeEndDate}
                    format="dddd MMMM D, YYYY"
                    helperText={errorMessages.rangeEndDate}
                    error={!!errorMessages.rangeEndDate}
                    onChange={value => handleADateChange(value, 'rangeEndDate')}
                    variant="inline"
                    disableToolbar
                    disablePast
                    fullWidth
                    autoOk
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Grid
            item
            container
            justifyContent="flex-end"
            spacing={2}
            className={classes.formSection}
            style={{ marginTop: 30 }}
          >
            {thereAreErrors && (
              <div style={{ display: 'inline-flex', marginRight: 10 }}>
                <ErrorMessage errorMessage="Please fix errors above." />
              </div>
            )}
            {editingMode ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSubmit('save');
                }}
              >
                Save
              </Button>
            ) : (
              <SplitButton
                options={[
                  {
                    label: 'Save + close',
                    action: () => {
                      handleSubmit('save');
                    },
                  },
                  {
                    label: 'Save + add more (keep details)',
                    action: () => {
                      handleSubmit('saveKeep');
                    },
                  },
                  {
                    label: 'Save + add more (clear the form)',
                    action: () => {
                      handleSubmit('saveClear');
                    },
                  },
                ]}
                disabled={isSubmitting}
              />
            )}
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
      {isSubmitting && (
        <LoadingCover loader="linear">
          <Typography variant="h3" align="center">
            Please wait while we build all your shifts...
          </Typography>
        </LoadingCover>
      )}
      <OkCancelDialog
        title={dialogInfo.title}
        open={dialogInfo.open}
        onClose={dialogInfo.onClose}
        hideCancel={dialogInfo.hideCancel}
      >
        <Typography>{dialogInfo.message}</Typography>
      </OkCancelDialog>
    </>
  );
};

export default AdvancedShiftCreator;
