import React from 'react';
import { Typography } from '@material-ui/core';

const AdminToolsTitle = ({ Icon, titleText }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: 440,
        padding: '8px 0',
      }}
    >
      <Icon style={{ fontSize: 32, color: '#333', marginRight: 10 }} />
      <Typography variant="h3">{titleText}</Typography>
    </div>
  );
};

export default AdminToolsTitle;
