import { useEffect, useRef, useState } from 'react';

import _ from 'lodash';
import { useQuery } from 'react-apollo-hooks';

import { ACTIVITY_STATUS } from '../config/appDefaults';
import GetCompanyById from '../graphql/queries/get-company-by-id';

const useCompanyActivityStatus = ({
  companyId,
  trackBackfillReports = false,
  onBackfillReportsSuccess,
  onSetupBookkeepingAlertsSuccess,
}) => {
  const [isSettingUp, setIsSettingUp] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [isBackfillingReports, setIsBackfillingReports] = useState(false);
  const [activityErrorMessage, setActivityErrorMessage] = useState(null);

  const previousBackfillStatusRef = useRef(null);
  const previousSetupStatusRef = useRef(null);
  const previousScanStatusRef = useRef(null);

  const getCompanyByIdQuery = useQuery(GetCompanyById, {
    skip: !companyId,
    variables: {
      companyId,
    },
    fetchPolicy: 'cache-and-network',
  });
  const companyInfo = _.get(getCompanyByIdQuery, 'data.getCompanyById', null);

  useEffect(() => {
    const checkActivityStatus = async () => {
      const backfillStatus = companyInfo?.backfillActivityInfo?.status;
      const previousBackfillStatus = previousBackfillStatusRef.current;

      const scanStatus = companyInfo?.qboUsageBasedRulesActivityInfo?.status;
      const previousScanStatus = previousScanStatusRef.current;

      const setupStatus =
        companyInfo?.bookkeepingAlertSetupActivityInfo?.status;
      const previousSetupStatus = previousSetupStatusRef.current;

      if (
        (trackBackfillReports && backfillStatus) ||
        scanStatus ||
        setupStatus
      ) {
        const isBackFillPending =
          trackBackfillReports && backfillStatus === ACTIVITY_STATUS.PENDING;
        const isScanPending = scanStatus === ACTIVITY_STATUS.PENDING;
        const isSetupPending = setupStatus === ACTIVITY_STATUS.PENDING;

        if (isBackFillPending || isScanPending || isSetupPending) {
          setIsSettingUp(isSetupPending);

          setIsScanning(isScanPending);

          setIsBackfillingReports(isBackFillPending);

          getCompanyByIdQuery.startPolling(5000);
        } else {
          // no activity is in progress
          getCompanyByIdQuery.stopPolling();

          // only show error message if setup is in progress
          if (setupStatus === ACTIVITY_STATUS.ERROR && isSettingUp) {
            setActivityErrorMessage(
              "We're sorry, but an error occurred while setting up bookkeeping alerts. Please try again later!"
            );
          }

          // only show error message if scan is in progress
          if (scanStatus === ACTIVITY_STATUS.ERROR && isScanning) {
            setActivityErrorMessage(
              'We ran into an issue while customizing your rules. Instead, we set a default set of rules for your type of business.'
            );
          }

          // only show error message if backfill is in progress
          if (
            trackBackfillReports &&
            backfillStatus === ACTIVITY_STATUS.ERROR &&
            isBackfillingReports
          ) {
            setActivityErrorMessage(
              "We're sorry, but an error occurred while generating your retroactive reports. Please try again later!"
            );
          }

          // only fire success callback if setupStatus or scanStatus are changing from pending to success
          if (
            ((previousSetupStatus === ACTIVITY_STATUS.PENDING &&
              setupStatus === ACTIVITY_STATUS.SUCCESS) ||
              (previousScanStatus === ACTIVITY_STATUS.PENDING &&
                scanStatus === ACTIVITY_STATUS.SUCCESS)) &&
            onSetupBookkeepingAlertsSuccess
          ) {
            await onSetupBookkeepingAlertsSuccess();
          }

          // only fire success callback if backfillStatus is changing from pending to success
          if (
            trackBackfillReports &&
            previousBackfillStatus === ACTIVITY_STATUS.PENDING &&
            backfillStatus === ACTIVITY_STATUS.SUCCESS &&
            onBackfillReportsSuccess
          ) {
            await onBackfillReportsSuccess();
          }

          if (!isSetupPending) {
            setIsSettingUp(false);
          }
          if (!isScanPending) {
            setIsScanning(false);
          }
          if (!isBackFillPending) {
            setIsBackfillingReports(false);
          }
        }
      }

      previousBackfillStatusRef.current = backfillStatus;
      previousScanStatusRef.current = scanStatus;
      previousSetupStatusRef.current = setupStatus;
    };

    checkActivityStatus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo, trackBackfillReports]);

  return {
    isBackfillingReports,
    setIsBackfillingReports,
    // NOTE: setup bookkeeping-alerts includes creating default rules, email lists
    // and scan qbo usage for features
    isSettingUpBookkeepingAlerts: isSettingUp || isScanning,
    backfillActivityStatus: companyInfo?.backfillActivityInfo?.status,
    activityErrorMessage,
    setActivityErrorMessage,
    getCompanyByIdQuery,
  };
};

export default useCompanyActivityStatus;
