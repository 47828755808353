import React, { useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import { monetaryRender } from '../../../helpers';

import BillPaymentsViewStatsSection from './bill-payments-view-stats-section';

const BillPaymentsViewStatsDialog = ({ open, stat, onClose }) => {
  const monetizeAmount = moneyValue => {
    return monetaryRender({ value: moneyValue, withDecimals: true });
  };

  const statToUse = useMemo(() => {
    return {
      sectionHeader: 'Overall',
      entriesCount: stat.entriesCount,
      total: monetizeAmount(stat.total),
    };
  }, [stat]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="data stats"
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <BillPaymentsViewStatsSection stat={statToUse} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BillPaymentsViewStatsDialog;
