import React from 'react';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import {
  Business as BusinessIcon,
  Store as StoreIcon,
} from '@material-ui/icons';

import LevelLogo from '../../components/level-logo/level-logo';
import { useStyles } from './onboarding.styles';
import OnboardingStepButtons from './onboarding-step-buttons';
import CompanyTypeOption from './onboarding-choose-company-type-option';
import { COMPANY_TYPE } from './onboarding.constants';

const COMPANY_TYPE_OPTIONS = [
  {
    Icon: BusinessIcon,
    value: COMPANY_TYPE.FIRM,
    title: 'Accounting/Bookkeeping Firm',
    description:
      'I use QuickBooks Online Accountant for my bookkeeping/accounting firm with access to client accounts.',
  },
  {
    Icon: StoreIcon,
    value: COMPANY_TYPE.COMPANY,
    title: 'Company',
    description: `I have a QuickBooks Online account to do my company's accounting and bookkeeping.`,
  },
];

const OnboardingChooseCompanyTypeStep = ({
  selectedCompanyType,
  setSelectedCompanyType,
}) => {
  const classes = useStyles();

  const handleSelected = value => {
    setSelectedCompanyType(value);
  };

  return (
    <>
      <Grid item xs={12}>
        <LevelLogo className={classes.smallLevelLogo} />
      </Grid>
      <Grid item xs={12} className={classes.title}>
        Choose Your Company Type
      </Grid>
      <Grid item xs={12} className={classes.description}>
        How do you use QuickBooks Online?
      </Grid>
      <Grid
        container
        item
        xs={12}
        direction="column"
        style={{ flexGrow: 1, justifyContent: 'flex-start', padding: 16 }}
        spacing={2}
      >
        {_.map(COMPANY_TYPE_OPTIONS, ({ Icon, title, description, value }) => (
          <CompanyTypeOption
            key={value}
            Icon={Icon}
            title={title}
            description={description}
            value={value}
            selected={selectedCompanyType === value}
            onSelected={handleSelected}
          />
        ))}
      </Grid>
      <OnboardingStepButtons allowGoToNext={!!selectedCompanyType} />
    </>
  );
};

export default OnboardingChooseCompanyTypeStep;
