import gql from 'graphql-tag';

export default gql`
  mutation UpdateCompanyTargets($targets: [TargetInput]!, $companyId: String!) {
    updateCompanyTargets(input: { targets: $targets, companyId: $companyId }) {
      items {
        name
        value
        dataType
      }
    }
  }
`;
