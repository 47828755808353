import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Customer}
  mutation CheckForQboUpdatesToCustomer(
    $customerId: String!
    $companyId: String!
  ) {
    checkForQboUpdatesToCustomer(
      input: { customerId: $customerId, companyId: $companyId }
    ) {
      ...CustomerFields
    }
  }
`;
