import gql from 'graphql-tag';

import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Rfi}
  ${Fragment.Jrn}
  ${Fragment.Relation}
  query GetCompanyRfi($companyId: String!, $requestId: String!) {
    getCompanyRfi(input: { companyId: $companyId, requestId: $requestId }) {
      ...RfiBaseFields
      relatedProjects {
        ...JrnBaseFields
        jrn {
          ...JrnBaseFields
        }
      }
      relatedBills {
        ...JrnBaseFields
        vendor {
          ...RelationFields
        }
      }
    }
  }
`;
