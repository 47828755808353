import gql from 'graphql-tag';

export default gql`
  mutation RecordSmsConsent($phoneNumber: String!, $messageType: String!) {
    recordSmsConsent(
      input: { messageType: $messageType, phoneNumber: $phoneNumber }
    ) {
      status
      msg
    }
  }
`;
