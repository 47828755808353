import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="body1">
        <Link component="a" href="https://checkthelevel.com/" target="_blank">
          &copy; Level Software Inc., 2019
        </Link>
      </Typography>
      <Typography variant="caption">
        Take your projects to the next Level!
      </Typography>
    </div>
  );
};

export default Footer;
