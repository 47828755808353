import React from 'react';

import { Avatar, ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ContentLoader from 'react-content-loader';

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: 20,
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    fontSize: 18,
  },
  inactive: {
    backgroundColor: theme.palette.brandColorLightGrey,
    color: theme.palette.brandColorDarkGrey75,
    fontSize: 18,
  },
  error: {
    backgroundColor: theme.palette.brandColorLightGrey,
    color: theme.palette.brandColorError,
    border: `1px solid ${theme.palette.brandColorError}`,
    fontSize: 18,
  },
  diabledActive: {
    backgroundColor: theme.palette.brandColorDarkGrey25,
    color: theme.palette.brandColorDarkGrey50,
    fontSize: 18,
  },
  disabledInactive: {
    backgroundColor: theme.palette.white,
    color: theme.palette.brandColorDarkGrey50,
    border: `1px solid ${theme.palette.brandColorLightGrey}`,
    fontSize: 18,
  },
}));

const CustomScanSettingsLetterToggleButton = ({
  label,
  loading,
  selected,
  disabled,
  error,
  style,
  onChange,
}) => {
  const classes = useStyles();

  const handleOnClick = () => {
    if (onChange) {
      onChange({ selected: !selected });
    }
  };

  if (loading) {
    return (
      <div style={{ width: 40, height: 40, ...style }}>
        <ContentLoader width={40} height={40}>
          <rect x="0" y="0" rx="20" ry="20" width="40" height="40" />
        </ContentLoader>
      </div>
    );
  }

  let classToUse = classes.inactive;
  if (disabled) {
    if (selected) {
      classToUse = classes.diabledActive;
    } else {
      classToUse = classes.disabledInactive;
    }
  } else if (error) {
    classToUse = classes.error;
  } else if (selected) {
    classToUse = classes.active;
  }

  return (
    <ButtonBase
      disabled={disabled}
      onClick={handleOnClick}
      className={classes.button}
      style={style}
    >
      <Avatar className={classToUse}>{label.charAt(0)}</Avatar>
    </ButtonBase>
  );
};

export default CustomScanSettingsLetterToggleButton;
