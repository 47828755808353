import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { generateTreeFromFlatList } from '../../helpers/accounts';
import QuickbooksAccountOption from './quickbooks-account-option';

const ChooseQuickbooksAccounts = ({
  accounts,
  currentAccounts,
  selectedAccounts,
  onChooseAccount,
  isLoading,
}) => {
  const parentedAccounts = useMemo(() => {
    if (accounts) {
      // Sort accounts by lowercase displayName
      const sortedAccounts = accounts.sort((a, b) =>
        a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())
      );

      if (currentAccounts && currentAccounts.length > 0) {
        const accountMap = {};
        sortedAccounts.forEach(account => {
          accountMap[account.sourceId] = account;
        });

        currentAccounts.forEach(currentAccountSourceId => {
          // Recursively find the parent and mark default to expanded
          let currentAccount = accountMap[currentAccountSourceId];
          let isChildAccount = currentAccount && currentAccount.sourceParentId;

          // If the account is parented, set the parent to expanded
          while (isChildAccount) {
            // Find the parent
            currentAccount = accountMap[currentAccount.sourceParentId];

            // If the parent exists, mark as expanded
            if (currentAccount) {
              currentAccount.isExpanded = true;
            }

            // If the parent exists, and is also parented keep expanding
            isChildAccount = currentAccount && currentAccount.sourceParentId;
          }
        });
      }

      // Return parental hierarchy of accounts
      return generateTreeFromFlatList(sortedAccounts);
    }

    return null;
  }, [accounts, currentAccounts]);

  if (!accounts || isLoading) {
    return (
      <Typography
        style={{
          color: '#999',
          fontStyle: 'italic',
        }}
      >
        Loading...
      </Typography>
    );
  }

  if (accounts.length === 0) {
    return (
      <Typography
        style={{
          color: '#999',
          fontStyle: 'italic',
        }}
      >
        No QuickBooks liablity accounts found.
      </Typography>
    );
  }

  return (
    <div>
      {parentedAccounts.map(account => (
        <QuickbooksAccountOption
          key={account.sourceId}
          account={account}
          onChooseAccount={onChooseAccount}
          selectedAccounts={selectedAccounts}
        />
      ))}
    </div>
  );
};

export default ChooseQuickbooksAccounts;
