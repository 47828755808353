import gql from 'graphql-tag';

export default gql`
  mutation SendCrewMemberInvitation(
    $companyId: ID!
    $email: String!
    $role: String
  ) {
    sendCrewMemberInvitation(
      input: { companyId: $companyId, email: $email, role: $role }
    ) {
      __typename
      invitationId
      email
      sentOn
      companyId
      isActive
      role
    }
  }
`;
