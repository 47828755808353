import gql from 'graphql-tag';

export default gql`
  query GetStripeProductInfo {
    getStripeProductInfo {
      coupons {
        items {
          id
          name
        }
      }
      prices {
        items {
          id
          nickname
          product
        }
      }
      products {
        items {
          id
          name
        }
      }
      taxRates {
        items {
          country
          displayName
          id
          percentage
          state
        }
      }
    }
  }
`;
