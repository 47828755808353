import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { useQuery } from 'react-apollo-hooks';
import _ from 'lodash';
import qs from 'qs';
import { Grid } from '@material-ui/core';
import { Element } from 'react-scroll';
import ListOfProjects from '../../components/ListOfProjects/ListOfProjects.data';
import ProjectDetails from './components/ProjectDetails/projectDetails.data';
import GetContentById from '../../graphql/queries/GetContentById';
import GetUsersByJrnId from '../../graphql/queries/GetUsersByJrnId';
import DeepLinkModal from './DeepLinkModal';

const styles = {
  scrollableColumn: {
    overflowY: 'scroll',
    height: 'calc(100vh - 64px)',
    overflowX: 'hidden',
  },
  calendarButton: {
    color: 'rgba(57, 124, 165, 1)',
  },
};

const Projects = props => {
  const { match, location } = props;

  const [modalContentId, setModalContentId] = useState(null);
  const [showContentModal, setShowContentModal] = useState(false);
  const [triggerQueryChange, setTriggerQueryChange] = useState(null);

  // Query modal content if set
  const modalContentInfoQuery = useQuery(GetContentById, {
    variables: { contentId: modalContentId },
    fetchPolicy: 'cache-and-network',
    skip: !modalContentId,
  });

  const modalContentInfo = _.get(modalContentInfoQuery, 'data.getContentById');

  // CHeck if projectId is in path
  const projectId = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  );

  const projectUsersQuery = useQuery(GetUsersByJrnId, {
    variables: { jrnId: projectId },
    fetchPolicy: 'cache-and-network',
  });

  let projectUsers = null;
  if (
    projectUsersQuery.data &&
    projectUsersQuery.data.getUsersByJrnId &&
    projectUsersQuery.data.getUsersByJrnId.items
  ) {
    projectUsers = projectUsersQuery.data.getUsersByJrnId.items;
  }

  // Check to see if content ID was provided in querystring
  // Check if cid value is provided in querystring
  // If provided, is it different than the current modal state
  // If so update the current modal state
  const queryStringParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const cid = _.get(queryStringParams, 'cid', null);

  const fromCompanyOnboarding = _.get(
    queryStringParams,
    'from-company-onboarding',
    false
  );

  if (fromCompanyOnboarding) {
    // Drop the query string from url
    window.history.replaceState({}, document.title, window.location.pathname);
  }

  if (cid && cid !== modalContentId) {
    setModalContentId(cid);
    setShowContentModal(true);
  }

  // Navigate to project on selection
  const onClickOfProject = project => {
    const { history } = props;
    history.push(`/projects/${project.contentId}`);
  };

  // Break out URL in projects
  const projectUrlSegments = location.pathname
    .replace(`${match.url}/`, '')
    .split('/');

  let lastTabIndex = null;
  if (
    location.state &&
    !Number.isNaN(parseInt(location.state.lastTabIndex, 10))
  ) {
    lastTabIndex = location.state.lastTabIndex;
  }

  let parentIdInUrl;
  let buttonUrl;
  let urlToUse;
  const firstProjectId = projectUrlSegments[0];
  if (projectUrlSegments.length && projectUrlSegments.length > 1) {
    parentIdInUrl = projectUrlSegments.pop();
    buttonUrl = `${match.url}/${projectUrlSegments.join('/')}`;
    urlToUse = buttonUrl;
  } else {
    urlToUse = `${match.url}`;
  }
  return (
    <Grid container>
      <Grid item xs={4} style={styles.scrollableColumn}>
        <ListOfProjects
          onClickOfProject={onClickOfProject}
          idOfProjectBeingViewed={firstProjectId}
          triggerQueryChange={triggerQueryChange}
          setTriggerQueryChange={setTriggerQueryChange}
          fromCompanyOnboarding={fromCompanyOnboarding}
        />
      </Grid>
      <Grid
        item
        xs={8}
        lg={8}
        style={styles.scrollableColumn}
        id="ContainerElementID"
      >
        <Element name="topOfProjects" />
        <Switch>
          <Route path={`${urlToUse}/:projectId`}>
            <ProjectDetails
              backToParentPathname={parentIdInUrl ? buttonUrl : null}
              toTriggerQueryChange={setTriggerQueryChange}
              lastTabIndex={lastTabIndex}
            />
          </Route>
        </Switch>
      </Grid>
      {showContentModal && modalContentInfo && (
        <DeepLinkModal
          modalContentInfo={modalContentInfo}
          showContentModal={showContentModal}
          setShowContentModal={setShowContentModal}
          allUsers={projectUsers}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = state => ({ userInfo: state.userInfo });

export default withRouter(connect(mapStateToProps)(Projects));
