import React from 'react';
import _ from 'lodash';
import {
  Grid,
  Switch as MuiSwitch,
  Button,
  FormControlLabel,
} from '@material-ui/core';

import SplitButton from '../../../components/SplitButton/splitButton';

const AddContentFormActions = ({
  classes,
  formikProps,
  contentType,
  userEditableFields,
  values,
  onBlur,
  onChange,
  onComplete,
  onBack,
  parentIsLead,
  editingMode,
  createFromTemplate,
  creatingProjectFromLead,
  fromShoebox,
  verifyAndSubmit,
  setSplitWithAnother,
  setAddAnother,
  setAssignFlowInfo,
  assignFlowInfo,
  setFieldValue,
  isFinancial,
  isGlobalExpense,
  isGlobalPayment,
  saveButtonIndex,
}) => {
  const showIsPublic =
    _.includes(userEditableFields, 'isPublic') && !parentIsLead;

  let buttonText = 'Save';
  let useSplitButton = true;
  if (!editingMode) {
    buttonText = 'Create';
  }

  if (
    editingMode ||
    createFromTemplate ||
    creatingProjectFromLead ||
    (fromShoebox && contentType === 'pdf')
  ) {
    useSplitButton = false;
  }

  return (
    <Grid container>
      <Grid item className={classes.submitButtonWrapper} xs={12} sm={12}>
        {showIsPublic && (
          <div className={classes.isPublicWrapperInner}>
            <FormControlLabel
              control={
                <MuiSwitch
                  name="isPublic"
                  checked={values.isPublic}
                  value={values.isPublic}
                  onChange={onChange}
                  onBlur={onBlur}
                  color="primary"
                />
              }
              className={classes.isPublicLabel}
              label="Show in online slideshow (if applicable)?"
              labelPlacement="start"
            />
          </div>
        )}
        {onBack && (
          <Button
            variant="contained"
            onClick={() => onBack()}
            disabled={formikProps.isSubmitting}
            style={{ marginRight: 10 }}
          >
            Back
          </Button>
        )}
        {!onBack && (
          <Button
            variant="contained"
            onClick={() => onComplete({ cancelled: true })}
            disabled={formikProps.isSubmitting}
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>
        )}
        {!useSplitButton && (
          <Button
            variant="contained"
            color="primary"
            onClick={verifyAndSubmit}
            disabled={formikProps.isSubmitting}
          >
            {buttonText}
          </Button>
        )}
        {useSplitButton && (
          <SplitButton
            options={() => {
              const initialOptions = [
                {
                  label: buttonText,
                  action: () => {
                    setSplitWithAnother(false);
                    setAddAnother(false);
                    verifyAndSubmit();
                  },
                },
              ];
              const addAnotherOption = {
                label: `${buttonText} + add another`,
                action: () => {
                  setSplitWithAnother(false);
                  setAddAnother(true);
                  verifyAndSubmit();
                },
              };
              const splitOption = {
                label: `${buttonText} + split with another project`,
                action: () => {
                  setAddAnother(false);
                  setSplitWithAnother(true);
                  setAssignFlowInfo({
                    ...assignFlowInfo,
                    setFieldValue,
                  });
                  verifyAndSubmit();
                },
              };

              if (!fromShoebox && !isGlobalPayment) {
                initialOptions.push(addAnotherOption);
              }
              if (isFinancial && !isGlobalExpense && !isGlobalPayment) {
                initialOptions.push(splitOption);
              }

              // return the options we want
              return initialOptions;
            }}
            disabled={formikProps.isSubmitting}
            setOptionIndex={saveButtonIndex}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default AddContentFormActions;
