import React from 'react';
import {
  Grid,
  Dialog,
  Typography,
  CircularProgress,
  Button,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import {
  ArrowForwardOutlined,
  Done as DoneIcon,
  MailOutline,
  Warning,
} from '@material-ui/icons';

const ReadyApprovalDialog = ({ status, toClose, toProceed }) => {
  return (
    <Dialog
      open
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      style={{ textAlign: 'center' }}
    >
      {status === 'loading' && (
        <DialogContent>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={12}
          >
            <CircularProgress style={{ marginBottom: 24, marginTop: 30 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" style={{ paddingBottom: 50 }}>
              Sending email...
            </Typography>
          </Grid>
        </DialogContent>
      )}
      {status === 'asking' && (
        <DialogContent>
          <Grid container alignItems="center" justifyContent="center">
            <MailOutline
              style={{
                fontSize: 60,
                color: '#aaa',
                marginBottom: 12,
              }}
            />
            <ArrowForwardOutlined
              style={{ fontSize: 30, color: '#aaa', marginBottom: 12 }}
            />
          </Grid>
          <Typography variant="body1" gutterBottom align="left">
            This will trigger an email to let your manager know that there are
            RFIs that are ready for their approval.
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginTop: 16 }}>
            Would you like to proceed?
          </Typography>
          <DialogActions style={{ marginTop: 16 }}>
            <Button autoFocus onClick={toClose} color="primary">
              Cancel
            </Button>
            <Button onClick={toProceed} color="primary" variant="contained">
              Yes, send email
            </Button>
          </DialogActions>
        </DialogContent>
      )}
      {status === 'success' && (
        <DialogContent>
          <DoneIcon
            style={{ fontSize: 80, color: 'green', marginBottom: 24 }}
          />
          <Typography variant="h3" gutterBottom>
            Email sent!
          </Typography>
          <DialogActions style={{ marginTop: 16 }}>
            <Button autoFocus onClick={toClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      )}
      {status === 'failed' && (
        <DialogContent>
          <Warning
            style={{ fontSize: 80, color: 'orange', marginBottom: 24 }}
          />
          <Typography variant="body1" gutterBottom>
            Something went wrong and the email could not be sent. Please try
            again later.
          </Typography>
          <Typography variant="body1" gutterBottom>
            If this persists, please contact support.
          </Typography>
          <Button autoFocus onClick={toClose} color="primary">
            Close
          </Button>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ReadyApprovalDialog;
