import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useMutation, useQuery } from 'react-apollo-hooks';
import _ from 'lodash';
import {
  Grid,
  Link,
  Typography,
  IconButton,
  CircularProgress,
  Tooltip,
  InputLabel,
} from '@material-ui/core';
import { Cached as CachedIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import palette from '../../theme/palette';
import LoadingCover from '../LoadingCover/loadingCover';
import RegisterWithMazumagoButton from '../register-with-mazumago-button';
import {
  MAZUMAGO_REGISTRATION_URL,
  MAZUMAGO_STATUS,
} from '../../config/appDefaults';
import ConnectToMazumago from '../../graphql/mutations/mutation_connect-to-mazumago';
import CheckMazumaGoConnection from '../../graphql/queries/check-mazumago-connection';
import SettingsSection from '../settings-section';

import TrussLogoImage from '../../assets/images/truss/truss-logo.svg';
import TrussLogoBlackImage from '../../assets/images/truss/truss-logo-black.svg';

const useStyles = makeStyles(() => ({
  formRow: {
    alignItems: 'center',
    width: '100%',
    minHeight: 38,
  },
  inlineIconButton: {
    marginTop: -10,
    marginBottom: -8,
  },
}));

const MazumaGoSettings = ({ managingCompanyInfo }) => {
  const classes = useStyles();
  const [
    requestMazumaGoRegistration,
    setRequestMazumaGoRegistration,
  ] = useState(false);

  const connectionQuery = useQuery(CheckMazumaGoConnection, {
    variables: { companyId: managingCompanyInfo.managingCompanyId },
    fetchPolicy: 'cache-and-network',
    skip: !managingCompanyInfo.managingCompanyId,
  });

  const company = _.get(connectionQuery, 'data.checkMazumaGoConnection', null);
  const {
    loading: checkMazumaGoConnectionLoading,
    refetch: recheckMazumaGoConnection,
    startPolling,
    stopPolling,
  } = connectionQuery;

  const isMazumaGoConnected =
    !!company && company.mazumaGoStatus === MAZUMAGO_STATUS.CONNECTED;
  const isMazumaGoPending =
    !!company && company.mazumaGoStatus === MAZUMAGO_STATUS.PENDING;

  useEffect(() => {
    // if current status is pending
    if (isMazumaGoPending) {
      // check mazumaGo connection every 10 seconds
      startPolling(10000);
    }

    return () => {
      stopPolling();
    };
  }, [isMazumaGoPending, startPolling, stopPolling]);

  const createMazumaGoRegistrationUrl = clientId => {
    return `${MAZUMAGO_REGISTRATION_URL}${clientId}`;
  };

  const [
    connectToMazumaGo,
    { data: connectToMazumaGoData, loading: connectToMazumaGoLoading },
  ] = useMutation(ConnectToMazumago, {
    variables: { companyId: managingCompanyInfo.managingCompanyId },
    update: (proxy, { data: { connectToMazumaGo: updatedCompany } }) => {
      const query = CheckMazumaGoConnection;
      const variables = { companyId: managingCompanyInfo.managingCompanyId };
      const data = _.cloneDeep(proxy.readQuery({ query, variables }));
      data.checkMazumaGoConnection = { ...updatedCompany };
      proxy.writeQuery({ query, variables, data });
    },
  });

  if (requestMazumaGoRegistration && connectToMazumaGoData) {
    const {
      connectToMazumaGo: { mazumaGoClientId },
    } = connectToMazumaGoData;
    const registrationUrl = createMazumaGoRegistrationUrl(mazumaGoClientId);
    window.open(registrationUrl, '_blank', 'noopener=true');
    setRequestMazumaGoRegistration(false);
  }

  const checkMazumaGoStatus = () => {
    if (!checkMazumaGoConnectionLoading) {
      recheckMazumaGoConnection();
    }
  };

  const onConnectToMazumaGo = () => {
    setRequestMazumaGoRegistration(true);
    connectToMazumaGo();
  };

  return (
    <SettingsSection
      title="Truss"
      description="Manage your integration with Truss"
      icon={<img alt="Truss logo" src={TrussLogoImage} width={20} />}
    >
      {!company && <LoadingCover />}
      <Grid container direction="column">
        <Grid
          container
          item
          alignItems="center"
          style={{ marginTop: 24, marginBottom: 36 }}
        >
          <img alt="Truss Logo" src={TrussLogoBlackImage} width={200} />
        </Grid>
        <Grid item>
          <Typography variant="body1">
            Never waste time chasing down receipts again with Truss and Level!
            <br />
            <br />
            Connect Level to Truss and sign up for your Truss debit and credit
            cards to unlock an industry leading level of transparency and
            empowerment to enable your team to make purchases on behalf of the
            company.
            <br />
            <br />
            Access to your company cards is managed in Level and requires users
            to activate the card through the Level mobile app by creating a
            purchase order before each purchase, creating a seamless integration
            for your accounting solutions team!
          </Typography>
        </Grid>
        {company && !company.mazumaGoClientId && (
          <Grid container item xs={12} justifyContent="center">
            <Grid item style={{ marginTop: 16 }}>
              <RegisterWithMazumagoButton
                onConnectToMazumaGo={onConnectToMazumaGo}
                loading={connectToMazumaGoLoading}
              />
            </Grid>
          </Grid>
        )}
        {company && company.mazumaGoClientId && (
          <Grid container style={{ paddingTop: 16 }}>
            <Grid
              container
              item
              className={classes.formRow}
              style={{ width: 250 }}
            >
              <Grid item xs={6}>
                <InputLabel>
                  <Typography color="textSecondary">Truss Status</Typography>
                </InputLabel>
              </Grid>
              <Grid
                container
                item
                xs={6}
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: 5,
                    paddingBottom: 5,
                    color: isMazumaGoConnected
                      ? palette.truss.green
                      : palette.brandColorDarkGrey,
                    fontWeight: isMazumaGoConnected ? 'bold' : undefined,
                  }}
                >
                  {_.capitalize(company.mazumaGoStatus)}
                </Typography>
                {isMazumaGoPending && (
                  <Tooltip title="Check Truss Status">
                    <IconButton
                      onClick={checkMazumaGoStatus}
                      className={classes.inlineIconButton}
                      disabled={checkMazumaGoConnectionLoading}
                    >
                      {checkMazumaGoConnectionLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <CachedIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
            <Grid container item className={classes.formRow}>
              {isMazumaGoPending && !!company.mazumaGoClientId && (
                <Grid container item className={classes.formRow}>
                  <Grid item xs={12} style={{ display: 'flex' }}>
                    <InputLabel>
                      <Typography color="textSecondary">
                        Haven&apos;t completed the Truss registration?&nbsp;
                        <Link
                          href={createMazumaGoRegistrationUrl(
                            company.mazumaGoClientId
                          )}
                          target="_blank"
                        >
                          Click here
                        </Link>
                      </Typography>
                    </InputLabel>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </SettingsSection>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || {},
});

export default connect(mapStateToProps)(MazumaGoSettings);
