/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { CloudUploadOutlined as CloudUploadOutlinedIcon } from '@material-ui/icons';
import { withSnackbar } from 'notistack';

import {
  MAX_IMAGES_IN_GALLERY,
  MAX_UPLOAD_TOTAL_FILESIZE_BYTES,
  MAX_PDF_FILE_SIZE,
  // MAX_UPLOAD_SINGLE_FILESIZE_BYTES,
} from '../../config/appDefaults';
import palette from '../../theme/palette';

const useStyles = makeStyles(theme => ({
  fileInputAreaWrapper: ({ dropzoneState }) => {
    return {
      background:
        dropzoneState === 'dragEnter' ? '#ddd' : palette.background.alt,
      textAlign: 'center',
      border: '1px dashed #ccc',
      padding: theme.spacing(2),
      cursor: 'pointer',
      '&:hover': {
        background: '#ddd',
      },
    };
  },
  uploadIcon: {
    fontSize: 50,
    color: theme.palette.brandColorPrimary,
  },
  addFilesMessage: {
    padding: `0 ${theme.spacing(2)}px}`,
  },
  justText: {},
}));

const fileTypes = {
  image: 'image/*',
  pdf: 'application/pdf',
};

const FileUploadArea = ({
  enqueueSnackbar,
  mode = 'image',
  disabled,
  allowMultiple = true,
  maxSize,
  maxFiles,
  acceptedTypes, // ['image/*', 'application/pdf'],
  // maxIndividualSize = MAX_UPLOAD_SINGLE_FILESIZE_BYTES,
  noDrag = true,
  justText,
  passback,
  passbackRejected,
  dropWording,
}) => {
  const classes = useStyles();
  const [modeValues] = useState(() => {
    if (mode === 'image') {
      return {
        acceptedTypes: [fileTypes.image],
        maxSize: maxSize || MAX_UPLOAD_TOTAL_FILESIZE_BYTES,
        maxFiles: maxFiles || MAX_IMAGES_IN_GALLERY,
      };
    }
    if (mode === 'pdf') {
      return {
        acceptedTypes: [fileTypes.pdf],
        maxSize: maxSize || MAX_PDF_FILE_SIZE,
        maxFiles: maxFiles || MAX_IMAGES_IN_GALLERY,
      };
    }
    if (mode === 'imageAndPdf') {
      return {
        acceptedTypes: [fileTypes.image, fileTypes.pdf],
        maxSize: maxSize || MAX_UPLOAD_TOTAL_FILESIZE_BYTES,
        maxFiles: maxFiles || MAX_IMAGES_IN_GALLERY,
      };
    }
    if (mode === 'allTypes') {
      return {
        acceptedTypes: undefined,
        maxSize: maxSize || MAX_UPLOAD_TOTAL_FILESIZE_BYTES,
        maxFiles: maxFiles || MAX_IMAGES_IN_GALLERY,
      };
    }
    return {};
  });

  // implement overrides if they're passed in
  if (acceptedTypes) {
    modeValues.acceptedTypes = acceptedTypes;
  }
  if (maxSize) {
    modeValues.maxSize = maxSize;
  }
  if (maxFiles) {
    modeValues.maxFiles = maxFiles;
  }

  const handlePassback = files => {
    passback(files);
  };

  const handleOnDrop = (acceptedFiles, fileRejections) => {
    if (acceptedFiles.length === 0) {
      return;
    }
    acceptedFiles.forEach((file, index) => {
      acceptedFiles[index].uri = URL.createObjectURL(file);
    });
    if (passbackRejected) {
      passbackRejected(fileRejections);
    }
    handlePassback(acceptedFiles);
  };

  const handleOnDropRejected = () => {
    enqueueSnackbar(
      `Sorry that file is too large or the wrong file type. Max size is ${MAX_UPLOAD_TOTAL_FILESIZE_BYTES /
        1000 /
        1000}Mb.`,
      {
        variant: 'error',
        persist: false,
      }
    );
  };

  return (
    <Dropzone
      disabled={disabled}
      accept={modeValues.acceptedTypes}
      multiple={modeValues.maxFiles > 1 && allowMultiple}
      maxSize={modeValues.maxSize}
      maxFiles={modeValues.maxFiles}
      noDrag={noDrag}
      onDropRejected={handleOnDropRejected}
      onDrop={handleOnDrop}
    >
      {({ getRootProps, getInputProps }) => {
        if (justText) {
          return (
            <Button className={classes.justText} {...getRootProps()}>
              {justText}
              <input {...getInputProps()} />
            </Button>
          );
        }
        return (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.fileInputAreaWrapper}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <CloudUploadOutlinedIcon
              fontSize="inherit"
              className={classes.uploadIcon}
            />
            <Typography variant="body1" className={classes.addFilesMessage}>
              {dropWording || 'Click here to add supporting paperwork.'}
            </Typography>
          </Grid>
        );
      }}
    </Dropzone>
  );
};

export default withSnackbar(FileUploadArea);
