import gql from 'graphql-tag';

export default gql`
  query GetInternalComments(
    $companyId: String! # to get company bookkeepers
    $itemType: ItemTypes # tells the lambda which table to look for the content
    $itemId: String! # to check if item is owned by company
  ) {
    getInternalComments(
      input: { companyId: $companyId, itemType: $itemType, itemId: $itemId }
    ) {
      items {
        __typename
        commentId
        contentId
        userId
        dateCreated
        content
        synced
      }
    }
  }
`;
