import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import LevelSupportEmailAddressLink from '../level-support-email-address-link/level-support-email-address-link';
import LoadingCover from '../LoadingCover/loadingCover';
import SingleProject from '../ListOfProjects/singleProject';

function ImportProjectStepImport({
  syncedProject,
  importComplete,
  errorOccurred = false,
  history,
  handleClose,
}) {
  const goToProject = project => {
    history.push(`/projects/${project.contentId}`);
  };

  const goToLead = lead => {
    history.push(`/customers/${lead.customerId}/lead/${lead.contentId}`);
  };

  return (
    <Grid container direction="column" spacing={2} style={{ padding: 8 }}>
      {!importComplete && (
        <LoadingCover loader="linear">
          <Typography variant="h3" align="center">
            Importing data from QuickBooks Online...
          </Typography>
        </LoadingCover>
      )}
      {importComplete && syncedProject && (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="body1">
              <b>Import Complete</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              You project was imported successfully. Click the project below to
              navigate to the project page where you will be able to update
              permissions and add content!
            </Typography>
          </Grid>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            style={{ flex: 1 }}
          >
            <div style={{ minWidth: 600 }}>
              <SingleProject
                key={syncedProject.contentId}
                project={syncedProject}
                onClickOfProject={() => {
                  if (syncedProject.type === 'lead') {
                    goToLead(syncedProject);
                  } else {
                    goToProject(syncedProject);
                  }

                  handleClose();
                }}
              />
            </div>
          </Grid>
        </Grid>
      )}
      {importComplete && errorOccurred && (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="body1">
              <b>Error on Import</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Oh no! Your project didn&apos;t import for some reason. Please try
              again later or contact our support team at&nbsp;
              <LevelSupportEmailAddressLink />.
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default withRouter(ImportProjectStepImport);
