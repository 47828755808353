import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const BasicDialog = props => {
  const {
    open,
    title,
    handleClose,
    handleSecondaryAction,
    children,
    buttonText,
    buttonVariant,
    buttonDisabled = false,
    secondaryButtonText,
    secondaryButtonDisabled = false,
    customChildren,
    ...rest
  } = props;

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...rest}
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        {customChildren ? (
          <>{children}</>
        ) : (
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          autoFocus
          variant={buttonVariant || 'text'}
          disabled={buttonDisabled}
        >
          {buttonText || 'Ok'}
        </Button>
        {!!handleSecondaryAction && !!secondaryButtonText && (
          <Button
            onClick={handleSecondaryAction}
            color="primary"
            variant={buttonVariant || 'text'}
            disabled={secondaryButtonDisabled}
          >
            {secondaryButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BasicDialog;
