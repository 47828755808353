import React from 'react';
import moment from 'moment';
import { Grid, Avatar, Paper } from '@material-ui/core';
import {
  Dashboard as DashboardIcon,
  Extension as ExtensionIcon,
  Assignment as AssignmentIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { cloudinaryifyProfilePic } from '../../helpers/cloudinary';
import { renderDateString } from '../../helpers/renderDateString';

import LeadChip from '../lead-chip/lead-chip';
import QboSyncStatusIcon from '../qbo-sync-status-icon';
import { QBO_SYNCABLE_TYPE } from '../../config/appDefaults';
import palette from '../../theme/palette';

const useStyles = makeStyles(theme => {
  return {
    paper: props => {
      // let bgColor = '#fff';
      let bgColorHover = theme.palette.brandColorPrimary;
      let textColor = null;
      let textColorHover = '#fff';
      if (props.highlightSelected) {
        textColor = '#fff';
      }
      if (props.softHighlight) {
        bgColorHover = palette.background.alt;
        textColorHover = null;
      }
      return {
        margin: `${theme.spacing(1)}px auto 0 auto`,
        padding: theme.spacing(2),
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: bgColorHover,
          color: textColorHover,
        },
        backgroundColor: props.highlightSelected
          ? theme.palette.brandColorPrimary
          : null,
        color: textColor,

        transition: 'all 0.3s ease',
        width: '100%',
      };
    },
    bigAvatar: {
      height: 80,
      width: 80,
    },
    projectTitle: {
      marginBottom: 5,
      wordBreak: 'break-word',
    },
    projectAddress: {
      fontSize: '80%',
      wordBreak: 'break-word',
    },
    projectDate: {
      fontSize: '80%',
    },
    projectDesc: {
      marginTop: theme.spacing(1),
      fontSize: '80%',
      wordBreak: 'break-word',
    },
  };
});

const SingleProject = props => {
  const {
    project,
    onClickOfProject,
    templateMode,
    showStatus,
    showAddress,
  } = props;
  const classes = useStyles(props);

  let dayLength;
  if (templateMode) {
    dayLength =
      moment(project.endDate).diff(moment(project.startDate), 'days') + 1;
  }

  return (
    <Paper
      className={classes.paper}
      onClick={() => onClickOfProject(project)}
      key={project.contentId}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        spacing={3}
      >
        <Grid item>
          <Grid container alignItems="center" wrap="nowrap" spacing={3}>
            <Grid item style={{ position: 'relative' }}>
              <Avatar
                alt="user profile image"
                src={
                  project.contentUrl
                    ? cloudinaryifyProfilePic(project.contentUrl)
                    : null
                }
                className={classes.bigAvatar}
              >
                {project.type === 'lead' ? (
                  <AssignmentIcon />
                ) : (
                  <>
                    {!project.contentUrl && (
                      <>
                        {templateMode ? <ExtensionIcon /> : <DashboardIcon />}
                      </>
                    )}
                  </>
                )}
              </Avatar>
              <QboSyncStatusIcon
                recordInfo={project}
                recordType={QBO_SYNCABLE_TYPE.PROJECT}
                mode="badge"
              />
            </Grid>
            <Grid item zeroMinWidth>
              <Grid container direction="column">
                <Grid item className={classes.projectTitle}>
                  {project.title}
                </Grid>
                {showAddress && project.address && (
                  <Grid item className={classes.projectAddress}>
                    {project.address}
                  </Grid>
                )}
                <Grid item className={classes.projectDate}>
                  {templateMode ? (
                    <>
                      Length: {dayLength} day{dayLength === 1 ? '' : 's'}
                    </>
                  ) : (
                    renderDateString(project.startDate, project.endDate)
                  )}
                </Grid>
                {!!project.description && (
                  <Grid item className={classes.projectDesc}>
                    {project.description.length > 75
                      ? `${project.description.substring(0, 75)}...`
                      : project.description}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {showStatus && (
          <Grid item>
            <LeadChip contentStatus={project.contentStatus} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default SingleProject;
