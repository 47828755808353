import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-apollo';
import _ from 'lodash';
import { GetCompanyInfoAction } from '../../graphql/graphql';
import ManageCompanyCrew from '../../components/manage-company-crew';
import LoadingCover from '../../components/LoadingCover/loadingCover';

const Crew = ({ companies, managingCompanyInfo }) => {
  if (!companies) {
    return <LoadingCover />;
  }
  const company = _.find(companies, {
    companyId: managingCompanyInfo?.managingCompanyId,
  });

  if (!company) return null; // double check they have access to the company (and update the getCompanyInfo cached result)

  return <ManageCompanyCrew />;
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default withRouter(
  connect(mapStateToProps)(compose(GetCompanyInfoAction)(Crew))
);
