export const LIST_TYPE = {
  MY_PROJECTS: 'projects',
  COMPANY_PROJECTS: 'companyProjects',
  ARCHIVED_PROJECTS: 'archivedProjects',
  TEMPLATES: 'templates',
};

export const DATA_TYPE = {
  PROJECTS: 'projects',
  TEMPLATES: 'templates',
};

export const SORT_TYPE = {
  STARTDATEASC: 'STARTDATEASC',
  STARTDATEDECS: 'STARTDATEDECS',
  TITLEASC: 'TITLEASC',
  TITLEDECS: 'TITLEDECS',
};
