import React from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import palette from '../../theme/palette';

const useStyles = makeStyles(() => ({
  root: {
    height: 100,
    padding: 8,
    marginTop: 8,
    marginBottom: 8,
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    color: palette.brandColorDarkestGrey,
    '&:hover': {
      backgroundColor: palette.brandColorPrimary25,
    },
  },
  selected: {
    border: '1px solid',
    borderColor: palette.brandColorPrimary,
    backgroundColor: palette.brandColorPrimary10,
  },
}));

const CompanyTypeOption = ({
  Icon,
  value,
  title,
  description,
  selected,
  onSelected,
}) => {
  const classes = useStyles();

  return (
    <Paper
      className={`${classes.root} ${selected ? classes.selected : ''}`}
      onClick={() => onSelected(value)}
      elevation={selected ? 3 : 1}
    >
      <Grid container style={{ height: '100%' }}>
        <Grid container item xs={2} justifyContent="center" alignItems="center">
          <Icon style={{ fontSize: 36 }} />
        </Grid>
        <Grid container item xs={10} direction="column" justifyContent="center">
          <Grid item>
            <Typography variant="h4" align="left" style={{ color: 'inherit' }}>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              align="left"
              style={{ color: 'inherit' }}
            >
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CompanyTypeOption;
