import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  mutation CheckForQboUpdatesToJrn($contentId: String!, $companyId: String!) {
    checkForQboUpdatesToJrn(
      input: { contentId: $contentId, companyId: $companyId }
    ) {
      contentId
      jrnId
      title
      creatorId
      customerId
      customerInfo {
        customerId
        managingCompanyId
        firstName
        lastName
        address
        email
        latitude
        longitude
        phoneNumber
        notes
        companyName
      }
      contentUrl
      description
      privacyLevel
      inviteCode
      endDate
      startDate
      allowedToEdit
      allowedToAdd
      allowedToView
      usersEverOnJrn
      synced
      labels
      latitude
      longitude
      address
      type
      addedBy
      date
      isPublic
      aspectRatio
      phoneNumber
      phoneNumberActive
      assignedTo
      priority
      contentStatus
      useTimetracking
      useTimetrackingQuestions
      timetrackingQuestions
      timetrackingQuestionsCheckout
      timetrackingAnswers {
        questionId
        answer
      }
      subtype
      permissionsFrom
      requireAdmin
      completionType
      completionInfo {
        instructions
        completedById
        completedByDate
        textResponse
        mediaInfo {
          uri
          aspectRatio
        }
      }
      comments {
        items {
          content
          commentId
          dateCreated
          userId
        }
      }
      loves {
        items {
          loveId
          userId
          contentId
        }
      }
      amount {
        value
      }
      recurrence {
        rangeId
        rangeStartDate
        rangeEndDate
        daysOfTheWeek
      }
      # REMOVEME: remove this when "bugets" field is deprecated
      budgets {
        ...BudgetFields
      }
      fromTemplate
      timetrackingType
      workflowStage
      workflowStageReason
      lastMessageSent
      qboCustomerId
    }
  }
`;
