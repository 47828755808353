import React from 'react';
import { Video } from 'cloudinary-react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { publicIdFromUrl } from '../../../helpers/cloudinary';

const useStyles = makeStyles(() => ({
  videoWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    background: '#eee',
  },
  video: {
    height: '100%',
    maxHeight: '50vh',
    minHeight: '30vh',
    outline: 0,
    maxWidth: '100%',
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
}));

const ContentVideo = props => {
  const { contentPiece } = props;
  const classes = useStyles();
  const publicId = publicIdFromUrl(contentPiece.contentUrl);
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.videoWrapper}
    >
      <Grid item>
        {contentPiece.contentUrl.includes('file://') ? (
          <Grid container item style={{ padding: 30 }}>
            <Typography
              variant="h4"
              align="center"
              style={{ marginBottom: 30, width: '100%' }}
            >
              This video is still uploading. Please check back later.
            </Typography>
            <Typography variant="subtitle2">
              If this message persists, there may have been an error while
              uploading, so please try the upload again.
            </Typography>
          </Grid>
        ) : (
          <Video
            className={classes.video}
            publicId={publicId}
            fallbackContent="Your browser does not support HTML5 video tags."
            controls
            transformation={{
              videoCodec: 'auto',
              fetchFormat: 'auto',
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ContentVideo;
