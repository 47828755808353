import gql from 'graphql-tag';

export default gql`
  subscription subscribeToNewMessages($jrnId: ID!) {
    subscribeToNewMessages(jrnId: $jrnId) {
      # items {
      __typename
      authorId
      content
      jrnId
      createdAt
      messageId
      synced
      # }
    }
  }
`;
