import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Paper, Typography } from '@material-ui/core';

import LevelLogo from '../../components/level-logo/level-logo';
import QboHorizontalImage from '../../assets/images/quickbooks/quickbooks-brand-preferred-logo-50-50-black-external.png';
import { useStyles } from './disconnect.styles';

const Disconnect = () => {
  const classes = useStyles();

  const history = useHistory();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <div className={classes.backgroundImage} />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        elevation={20}
        component={Paper}
        className={classes.container}
      >
        <Grid container className={classes.subContainer}>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            style={{ flex: 1 }}
          >
            <Grid container item justifyContent="center">
              <Grid item xs={6}>
                <LevelLogo className={classes.levelLogo} />
              </Grid>
              <Grid item xs={6}>
                <img
                  alt="QuickBooks Online Logo"
                  src={QboHorizontalImage}
                  width={200}
                />
              </Grid>
              <Grid item xs={12} className={classes.title}>
                Disconnection Notice
              </Grid>
              <Grid item xs={12} className={classes.message}>
                <Typography variant="body1" className={classes.messageText}>
                  Your Level account has been disconnected from QuickBooks
                  Online.
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.message}>
                <Typography variant="body1" className={classes.messageText}>
                  To continue using Level&apos;s bookkeeping reports and custom
                  scan features, please sign in to your Level account and
                  reconnect to QuickBooks Online.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent="center"
            style={{ marginTop: 24 }}
          >
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() => history.push('/')}
            >
              Go to app
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Disconnect;
