import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Relation}
  mutation AddRelation(
    $relationId: ID!
    $name: String!
    $type: RelationType!
    $managingCompanyId: String!
    $isArchived: Boolean
  ) {
    addRelation(
      input: {
        relationId: $relationId
        name: $name
        type: $type
        managingCompanyId: $managingCompanyId
        isArchived: $isArchived
      }
    ) {
      ...RelationFields
    }
  }
`;
