import React, { useEffect, useRef, useState } from 'react';

import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import _ from 'lodash';
import { compose, withApollo } from 'react-apollo';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { isValidEmail } from '../../common/validators';
import {
  generateDefaultUsername,
  runAnalytics,
  setAnalyticsUser,
} from '../../helpers';
import {
  EMAIL_IN_USE,
  INVALID_EMAIL,
  LINK_EXPIRED,
  PASSWORD_MISMATCH,
  PASSWORD_REQUIREMENTS,
  UNEXPECTED_ERROR,
} from './auth-errors.json';
import { LEVEL_ROLE } from '../../config/appDefaults';
import styles from './auth.styles';
import InitiateSignUpForm from './initiate-sign-up-form';
import InvitationCard from './invitation-card';
import SignUpForm from './sign-up-form';

const INVITATION_ERROR_STATE = {
  DOES_NOT_EXIST: 1,
  IS_INACTIVE: 2,
};

const useStyles = makeStyles(styles);

const SignUpView = ({
  client,
  invitationMode = false,
  invitationData = null,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const reCaptchaRef = useRef(null);
  const { signUpToken = null } = useParams();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [authError, setAuthError] = useState({});
  const [loading, setLoading] = useState(false);
  const role = LEVEL_ROLE.BOOKKEEPER;
  const dispatch = useDispatch();

  const validEmailChecker = emailToCheck => {
    if (emailToCheck === '') {
      setEmail('');
    }

    if (isValidEmail(emailToCheck)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };

  useEffect(() => {
    if (invitationMode && invitationData) {
      const {
        invitation: { email: invitedEmail = '' },
      } = invitationData;

      setEmail(invitedEmail);
      validEmailChecker(invitedEmail);
    }
  }, [invitationData, invitationMode]);

  let signUpTokenInfo = null;
  if (signUpToken) {
    // Redirect if token is invalid
    try {
      const signUpTokenToUtf8 = Buffer.from(signUpToken, 'base64').toString(
        'utf8'
      );
      const { emailAddress, keyId } = JSON.parse(signUpTokenToUtf8);
      if (!emailAddress || !keyId) {
        throw new Error('Invalid token');
      }

      signUpTokenInfo = {
        emailAddress,
        keyId,
      };

      if (emailAddress && emailAddress !== email) {
        setEmail(emailAddress);
      }
    } catch (err) {
      history.replace('/');
    }
  }

  // START - HANDLERS
  const handleEmailChange = e => {
    const passedEmail = e.target.value.replace(/\s/g, '');
    setEmail(passedEmail);
    validEmailChecker(passedEmail);
  };

  const handleRecaptchaChange = value => {
    setRecaptchaValue(value);
  };

  const validPasswordChecker = passwordToCheck => {
    const matchCognitoRequirementsRegex = new RegExp(
      '^(((?=.*[a-z]))((?=.*[a-z])(?=.*[0-9]))((?=.*[0-9])))(?=.{6,})'
    );

    const passwordValid = matchCognitoRequirementsRegex.test(passwordToCheck);
    if (passwordValid) {
      setPassword(passwordToCheck);
      setValidPassword(true);
      return;
    }
    setValidPassword(false);
  };

  const handlePasswordChange = e => {
    const passedPassword = e.target.value;
    setPassword(passedPassword);
    validPasswordChecker(passedPassword);
  };

  const handleConfirmPasswordChange = e => {
    const passedConfirmedPassword = e.target.value;
    setConfirmPassword(passedConfirmedPassword);
  };

  const handleSignIn = async (passedUsername, passedPassword) => {
    try {
      await Auth.signIn(passedUsername, passedPassword);

      await client.resetStore();
      const session = await Auth.currentSession();
      const token = session.getIdToken();
      const userId = token.payload.sub;

      const options1 = { userId, email, username: passedUsername };
      setAnalyticsUser(options1);
      runAnalytics('Active User', options1);
      runAnalytics('SignUp', options1);

      const options2 = { userId, type: 'Log In', email };
      runAnalytics('Log', options2);
      setLoading(false);

      // This dispatch will cause the UnauthRoute HOC to redirect
      dispatch({
        type: 'SIGNIN_SUCCEEDED',
        payload: {
          auth: { isLoggedIn: true },
          userInfo: { userId, email, username: passedUsername },
        },
      });
    } catch (err) {
      setLoading(false);
    }
  };

  const handleInitiateSignUp = async () => {
    // reset errors messages
    setAuthError({});

    if (!validEmail) {
      setAuthError({ email: INVALID_EMAIL });
      return;
    }
    if (!recaptchaValue) {
      setAuthError({ form: 'Please complete the reCAPTCHA' });
      return;
    }

    setLoading(true);

    try {
      // Post to send verification email
      await axios.post(
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/pre-signup-verification`,
        {
          emailAddress: email,
          recaptchaToken: recaptchaValue,
        },
        {
          responseType: 'json',
        }
      );

      // Redirect to /auth/check-email
      history.replace('/auth/check-email');
    } catch (err) {
      if (reCaptchaRef.current) {
        reCaptchaRef.current.reset();
      }

      setRecaptchaValue('');

      const axiosError = /** @type {import('axios').AxiosError} */ (err);

      const emailAlreadyInUse =
        _.get(axiosError, 'response.data.code') === 'EMAIL_IN_USE';

      const rateLimitHit =
        _.get(axiosError, 'response.data.code') === 'RATE_LIMIT_HIT';

      if (emailAlreadyInUse) {
        setAuthError({ email: EMAIL_IN_USE });
      } else if (rateLimitHit) {
        setAuthError({
          email: 'You have already received a link! Please check your email.',
        });
      } else {
        setAuthError({
          email: 'An unexpected error occurred. Please try again later.',
        });
      }

      setLoading(false);
    }
  };

  const handleSignUp = async () => {
    // reset errors messages
    setAuthError({});

    if (!validEmail && !signUpTokenInfo) {
      // No need to validate email if it's coming from a token sign-up
      setAuthError({ email: INVALID_EMAIL });
      return;
    }

    if (!validPassword) {
      setAuthError({ password: PASSWORD_REQUIREMENTS });
      return;
    }

    if (!validPassword || !confirmPassword || password !== confirmPassword) {
      setAuthError({ form: PASSWORD_MISMATCH });
      return;
    }

    // From here, submission is immenent
    setLoading(true);

    const signUpAttributes = {
      email,
    };

    if (invitationData?.invitation) {
      const { invitationId } = invitationData.invitation;
      signUpAttributes['custom:invitationId'] = invitationId;
    } else if (role) {
      // no invitation, but role is selected
      // means user is signing up for their own company
      signUpAttributes['custom:role'] = role;
    }

    if (!signUpTokenInfo) {
      // The old way, still used for invitation sign-ups for legacy customers
      let attemptNum = 0;
      const userNameToBe = generateDefaultUsername();

      const doSignup = async () => {
        let formErrorMessage = null;
        try {
          await Auth.signUp({
            username: userNameToBe,
            password,
            attributes: signUpAttributes,
          });

          await handleSignIn(userNameToBe, password);
        } catch (err) {
          if (_.includes(err.message, 'EMAIL_ALREADY_IN_USE')) {
            setAuthError({ email: EMAIL_IN_USE });
            setLoading(false);
          } else if (_.includes(err.message, 'PostConfirmation')) {
            formErrorMessage = _.replace(
              err.message,
              'PostConfirmation failed with error ',
              ''
            );
          } else if (attemptNum >= 2) {
            formErrorMessage = UNEXPECTED_ERROR;
          } else {
            attemptNum += 1;
            await doSignup();
          }

          if (formErrorMessage) {
            setAuthError({ form: formErrorMessage });
            setLoading(false);
          }
        }
      };

      await doSignup();

      return;
    }

    // Submit sign-up request
    try {
      await axios.post(
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/complete-email-signup`,
        {
          keyId: signUpTokenInfo.keyId,
          attributes: signUpAttributes,
        },
        {
          headers: {
            Authorization: `Bearer ${Buffer.from(
              `${email}:${password}`
            ).toString('base64')}`,
          },
        }
      );

      await handleSignIn(email, password);
    } catch (err) {
      const axiosError = /** @type {import('axios').AxiosError} */ (err);

      const statusCode = _.get(axiosError, 'response.status');

      if (statusCode === 404) {
        setAuthError({
          form: LINK_EXPIRED,
        });
      }

      setLoading(false);
    }
  };

  let formTitle = !invitationMode ? '' : 'Create your account';

  // If invalid invitation, show message
  let invitationError = null;
  if (invitationMode && invitationData === false) {
    invitationError = INVITATION_ERROR_STATE.DOES_NOT_EXIST;
    formTitle = 'Invitation not found';
  } else if (
    invitationMode &&
    invitationData &&
    invitationData.invitation &&
    !invitationData.invitation.isActive
  ) {
    invitationError = INVITATION_ERROR_STATE.IS_INACTIVE;
    formTitle = 'Invitation no longer available';
  }

  if (invitationMode) {
    return (
      <Grid container item xs={12} className={classes.formContainer}>
        {!invitationError && (
          <>
            <InvitationCard invitationData={invitationData} />
            <SignUpForm
              onSignUp={handleSignUp}
              onEmailChange={handleEmailChange}
              email={email}
              onPasswordChange={handlePasswordChange}
              onConfirmPasswordChange={handleConfirmPasswordChange}
              password={password}
              confirmPassword={confirmPassword}
              authError={authError}
              loading={loading}
              title={formTitle}
            />
          </>
        )}
        {invitationError === INVITATION_ERROR_STATE.DOES_NOT_EXIST && (
          <Grid item style={{ flex: 1, marginTop: 30 }}>
            <Typography className={classes.formMessage}>
              Sorry! We could not find that invitation. Please verify you are
              using the correct link.
            </Typography>
          </Grid>
        )}
        {invitationError === INVITATION_ERROR_STATE.IS_INACTIVE && (
          <Grid item style={{ flex: 1, marginTop: 30 }}>
            <Typography className={classes.formMessage}>
              Sorry! It looks like the invitation was removed by the company. If
              you think this was a mistake, please ask the company administrator
              to send another invitation.
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }

  if (signUpTokenInfo) {
    return (
      <Grid container item xs={12} className={classes.formContainer}>
        <SignUpForm
          onSignUp={handleSignUp}
          onEmailChange={handleEmailChange}
          email={email}
          onPasswordChange={handlePasswordChange}
          onConfirmPasswordChange={handleConfirmPasswordChange}
          password={password}
          confirmPassword={confirmPassword}
          authError={authError}
          loading={loading}
          title={formTitle}
          signUpTokenMode={!!signUpTokenInfo}
        />
      </Grid>
    );
  }

  return (
    <Grid container item xs={12} className={classes.formContainer}>
      <InitiateSignUpForm
        reCaptchaRef={reCaptchaRef}
        onEmailChange={handleEmailChange}
        onRecaptchaChange={handleRecaptchaChange}
        email={email}
        authError={authError}
        loading={loading}
        onInitiateSignUp={handleInitiateSignUp}
      />
    </Grid>
  );
};

export default compose(withApollo)(SignUpView);
