import gql from 'graphql-tag';

export default gql`
  query GetUserActivity($activityId: ID!, $companyId: ID!) {
    getUserActivity(input: { activityId: $activityId, companyId: $companyId }) {
      id
      companyId
      userId
      type
      status
      message
      dataJson
    }
  }
`;
