import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo-hooks';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import { addMilliseconds, endOfDay, isBefore, isEqual } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import moment from 'moment';

import GetBillableHoursReportData from '../../../graphql/queries/get-billable-hours-report-data';

import { Dashboard } from '../../dashboard';
import Graph, { processXYTypes } from '../../graph';
import { hoursGraphs, HOURS_GRAPH } from './billable-hours-graphs';
import BillableHoursControlBar from './billable-hours-control-bar';
import BillableHoursBreakdownDialog from './billable-hours-breakdown-dialog';
import BillableHoursFilters from './billable-hours-filters';
import BillableHoursStats from './billable-hours-stats';
import palette from '../../../theme/palette';

import {
  BILLABLE_HOURS_GROUP_BY,
  REPORTING_PERIOD,
  REPORT_TIME_BASIS,
} from '../../../config/appDefaults';

const title = 'Billable Hours';

const BillableHoursScoreboard = ({ managingCompanyInfo }) => {
  const [somethingLoading, setSomethingLoading] = useState(false);
  const [filterSettings, setFilterSettings] = useState({});
  const [companyCustomers, setCompanyCustomers] = useState([]);
  const [companyCrew, setCompanyCrew] = useState([]);
  const [projectIdToCustomerIdMap, setProjectIdToCustomerIdMap] = useState({});
  const [projectIdToPathNameMap, setProjectIdToPathNameMap] = useState({});
  const [showBreakdownDialog, setShowBreakdownDialog] = useState({
    open: false,
  });
  const [csvExportUrl, setCsvExportUrl] = useState(null);

  const BillableHoursReportDataQuery = useQuery(GetBillableHoursReportData, {
    skip: !(managingCompanyInfo && managingCompanyInfo.managingCompanyId),
    variables: {
      companyId: managingCompanyInfo && managingCompanyInfo.managingCompanyId,
      groupBy: BILLABLE_HOURS_GROUP_BY.DAY.value,
      reportPeriod: REPORTING_PERIOD.THIS_WEEK.value,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    fetchPolicy: 'network-only',
  });

  const billableHoursReportData =
    BillableHoursReportDataQuery.data &&
    BillableHoursReportDataQuery.data.getBillableHoursReportData &&
    BillableHoursReportDataQuery.data.getBillableHoursReportData.items;

  const refetchBillableHoursReportData = BillableHoursReportDataQuery.refetch;

  const reportTimeBasis = useMemo(() => {
    if (filterSettings.groupBy) {
      switch (filterSettings.groupBy) {
        case BILLABLE_HOURS_GROUP_BY.DAY.value:
          return REPORT_TIME_BASIS.DAYS;
        case BILLABLE_HOURS_GROUP_BY.WEEK.value:
          return REPORT_TIME_BASIS.WEEKS;
        case BILLABLE_HOURS_GROUP_BY.MONTH.value:
          return REPORT_TIME_BASIS.MONTHS;
        case BILLABLE_HOURS_GROUP_BY.QUARTER.value:
          return REPORT_TIME_BASIS.QUARTERS;
        case BILLABLE_HOURS_GROUP_BY.YEAR.value:
          return REPORT_TIME_BASIS.YEARS;
        default:
          return REPORT_TIME_BASIS.DAYS;
      }
    }
    return REPORT_TIME_BASIS.DAYS;
  }, [filterSettings.groupBy]);

  const reportDataMap = useMemo(() => {
    const compiledReportData = {};

    if (billableHoursReportData) {
      _.forEach(hoursGraphs, ({ name: sourceName }) => {
        const data = _.cloneDeep(
          _.find(billableHoursReportData, ({ name }) => name === sourceName)
        );

        if (data) {
          data.values = processXYTypes(
            data.values,
            data.labels.x,
            data.labels.y
          );
        }

        compiledReportData[sourceName] = data;
      });
    }

    return compiledReportData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billableHoursReportData]);

  const billableHours = _.find(
    hoursGraphs,
    ({ name }) => name === HOURS_GRAPH.BILLABLE_HOURS
  );

  const metadataMap = useMemo(() => {
    const data = {};
    let csvUrl;
    if (reportDataMap) {
      _.forEach(reportDataMap, (reportDataValue, reportDataKey) => {
        data[reportDataKey] = {};
        _.forEach(reportDataValue.metadata, ({ key, value }) => {
          if (key === 'qbFiscalYearStartMonth') {
            data[reportDataKey][key] = value;
          } else if (key === 'csvExportUrl') {
            data[reportDataKey][key] = value;
            csvUrl = value;
          } else if (key === 'hoursBreakdown') {
            data[reportDataKey][key] = JSON.parse(value);
          } else {
            data[reportDataKey][key] = new Date(value);
            if (key.includes('StartDate')) {
              data[reportDataKey][`${key}ZonedTimeInUtc`] = zonedTimeToUtc(
                new Date(
                  data[reportDataKey][key].getUTCFullYear(),
                  data[reportDataKey][key].getUTCMonth(),
                  data[reportDataKey][key].getUTCDate()
                ),
                filterSettings.timezone
              );
            } else if (key.includes('EndDate')) {
              data[reportDataKey][`${key}ZonedTimeInUtc`] = zonedTimeToUtc(
                endOfDay(
                  new Date(
                    data[reportDataKey][key].getUTCFullYear(),
                    data[reportDataKey][key].getUTCMonth(),
                    data[reportDataKey][key].getUTCDate()
                  )
                ),
                filterSettings.timezone
              );
            }
          }
        });
      });
      setCsvExportUrl(csvUrl);
    }
    return data;
  }, [filterSettings.timezone, reportDataMap]);

  const nonBillableHours = _.find(
    hoursGraphs,
    ({ name }) => name === HOURS_GRAPH.NON_BILLABLE_HOURS
  );

  const hasCompletedData = (datum, index, data, metadata) => {
    const startDateZonedTimeInUtc = zonedTimeToUtc(
      new Date(
        datum.x.getUTCFullYear(),
        datum.x.getUTCMonth(),
        datum.x.getUTCDate()
      ),
      filterSettings.timezone
    );

    let endDateZonedTimeInUtc = null;
    if (index < data.length - 1) {
      endDateZonedTimeInUtc = zonedTimeToUtc(
        addMilliseconds(
          new Date(
            data[index + 1].x.getUTCFullYear(),
            data[index + 1].x.getUTCMonth(),
            data[index + 1].x.getUTCDate()
          ),
          -1
        ),
        filterSettings.timezone
      );
    } else {
      endDateZonedTimeInUtc = metadata.reportPeriodEndDateZonedTimeInUtc;
    }

    const now = new Date();

    if (index === 0) {
      return (
        isBefore(endDateZonedTimeInUtc, now) &&
        isEqual(
          startDateZonedTimeInUtc,
          metadata.reportPeriodSoftStartDateZonedTimeInUtc
        )
      );
    }

    if (index === data.length - 1) {
      return (
        isBefore(endDateZonedTimeInUtc, now) &&
        isEqual(
          endDateZonedTimeInUtc,
          metadata.reportPeriodSoftEndDateZonedTimeInUtc
        )
      );
    }

    return isBefore(endDateZonedTimeInUtc, now);
  };

  const hoursDataSeries = useMemo(() => {
    const dataSeries = [];
    if (reportDataMap && reportDataMap[nonBillableHours.name]) {
      dataSeries.push({
        type: nonBillableHours.graphType,
        data: reportDataMap[nonBillableHours.name].values,
        dataType: reportDataMap[nonBillableHours.name].labels,
        metadata: metadataMap[nonBillableHours.name],
        name: nonBillableHours.label,
        dataBasis: reportTimeBasis,
        color: (datum, index) => {
          if (
            datum &&
            !hasCompletedData(
              datum,
              index,
              reportDataMap[nonBillableHours.name].values,
              metadataMap[nonBillableHours.name]
            )
          ) {
            return palette.brandColorError50;
          }
          return palette.brandColorError;
        },
      });
    }

    if (reportDataMap && reportDataMap[billableHours.name]) {
      dataSeries.push({
        type: billableHours.graphType,
        data: reportDataMap[billableHours.name].values,
        dataType: reportDataMap[billableHours.name].labels,
        metadata: metadataMap[billableHours.name],
        name: billableHours.label,
        dataBasis: reportTimeBasis,
        color: (datum, index) => {
          if (
            datum &&
            !hasCompletedData(
              datum,
              index,
              reportDataMap[billableHours.name].values,
              metadataMap[billableHours.name]
            )
          ) {
            return palette.brandColorGreen50;
          }
          return palette.brandColorGreen;
        },
      });
    }
    return dataSeries;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    billableHours,
    metadataMap,
    nonBillableHours,
    reportDataMap,
    reportTimeBasis,
  ]);

  // calculate the total hours and average hours for the report period
  // only include completed data/periods
  const statsToUse = useMemo(() => {
    const ALL_HOURS = 'Total Hours';
    const EFFICIENCY = 'Efficiency';
    const stats = {};
    if (!_.isEmpty(hoursDataSeries)) {
      stats[ALL_HOURS] = {
        total: 0,
        average: 0,
      };
      const completedColumns = {};
      _.forEach(hoursDataSeries, dataSeries => {
        stats[dataSeries.name] = {
          total: 0,
          average: 0,
          color: dataSeries.color(),
        };
        completedColumns[dataSeries.name] = 0;
        _.forEach(dataSeries.data, (datum, index) => {
          if (
            datum &&
            hasCompletedData(datum, index, dataSeries.data, dataSeries.metadata)
          ) {
            if (reportTimeBasis === REPORT_TIME_BASIS.DAYS) {
              // if the report is in days, then only include sunday and saturday if the value > 0
              const dayOfWeek = moment(datum.x)
                .utc()
                .day();

              if (
                (dayOfWeek !== 0 && dayOfWeek !== 6) || // not sunday or saturday
                (dayOfWeek === 0 && datum.y > 0) || // sunday
                (dayOfWeek === 6 && datum.y > 0) // saturday
              ) {
                stats[dataSeries.name].total += datum.y;
                completedColumns[dataSeries.name] += 1;
              }
            } else {
              stats[dataSeries.name].total += datum.y;
              completedColumns[dataSeries.name] += 1;
            }
          }
        });
        stats[ALL_HOURS].total += stats[dataSeries.name].total;
        if (completedColumns[dataSeries.name] > 0) {
          stats[dataSeries.name].average =
            stats[dataSeries.name].total / completedColumns[dataSeries.name];
          stats[ALL_HOURS].average += stats[dataSeries.name].average;
        }
      });

      stats[EFFICIENCY] = {
        total: stats[ALL_HOURS].total
          ? stats[billableHours.label].total / stats[ALL_HOURS].total
          : 0,
        average: stats[ALL_HOURS].average
          ? stats[billableHours.label].average / stats[ALL_HOURS].average
          : 0,
      };
    }

    return stats;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoursDataSeries]);

  const handleFiltersUpdate = async updatedFilterSettings => {
    const { uniqueName, ...payload } = updatedFilterSettings;
    setSomethingLoading(true);
    setFilterSettings(updatedFilterSettings);
    await refetchBillableHoursReportData(payload);
    setSomethingLoading(false);
  };

  const handleBarStackClick = event => {
    const { key, datum, dateRangeString } = event;
    if (key === billableHours.label) {
      const xLabel = moment(datum.x)
        .utc()
        .format('YYYY-MM-DD');
      const billableHoursGroupedByProjectIdMap =
        metadataMap[billableHours.name].hoursBreakdown[xLabel];
      const nonBillableHoursGroupedByProjectIdMap =
        metadataMap[nonBillableHours.name].hoursBreakdown[xLabel];
      setShowBreakdownDialog({
        open: true,
        title: dateRangeString,
        billableHoursGroupedByProjectIdMap,
        nonBillableHoursGroupedByProjectIdMap,
      });
    }
  };

  const handleFiltersDataLoaded = data => {
    setCompanyCustomers(data.companyCustomers);
    setCompanyCrew(data.companyCrew);
    setProjectIdToCustomerIdMap(data.projectIdToCustomerIdMap);
    setProjectIdToPathNameMap(data.projectIdToPathNameMap);
  };

  return (
    <>
      <Dashboard
        controlBar={
          <BillableHoursControlBar
            title={title}
            csvExportUrl={csvExportUrl}
            loading={somethingLoading || BillableHoursReportDataQuery.loading}
          />
        }
      >
        <Grid container direction="row" style={{ height: '100%' }}>
          <Grid container item xs={4} direction="column">
            <Grid item>
              <BillableHoursFilters
                onFiltersUpdate={handleFiltersUpdate}
                onFiltersDataLoaded={handleFiltersDataLoaded}
                loading={
                  somethingLoading ||
                  BillableHoursReportDataQuery.loading ||
                  _.isEmpty(companyCrew) ||
                  _.isEmpty(projectIdToPathNameMap)
                }
              />
            </Grid>
            <Grid item>
              <BillableHoursStats
                stats={statsToUse}
                loading={
                  somethingLoading || BillableHoursReportDataQuery.loading
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={8} style={{ height: '100%' }}>
            <Graph
              key={billableHours.name}
              description={billableHours.description}
              title={
                filterSettings.uniqueName ||
                `${billableHours.label} vs ${nonBillableHours.label}`
              }
              dataSeries={hoursDataSeries}
              loading={hoursDataSeries.length === 0 || somethingLoading}
              type="barstack"
              noPadding
              showLegend
              showDayOfWeek={reportTimeBasis === REPORT_TIME_BASIS.DAYS}
              showDateRange
              onBarStackClick={handleBarStackClick}
              tooltipNote="Click on a bar to see the breakdown."
              fiscalYearStartMonth={
                metadataMap[billableHours.name]?.qbFiscalYearStartMonth
              }
            />
          </Grid>
        </Grid>
      </Dashboard>
      {showBreakdownDialog.open && (
        <BillableHoursBreakdownDialog
          open={showBreakdownDialog.open}
          title={showBreakdownDialog.title}
          billableHoursGroupedByProjectIdMap={
            showBreakdownDialog.billableHoursGroupedByProjectIdMap
          }
          nonBillableHoursGroupedByProjectIdMap={
            showBreakdownDialog.nonBillableHoursGroupedByProjectIdMap
          }
          companyCustomers={companyCustomers}
          companyCrew={companyCrew}
          projectIdToCustomerIdMap={projectIdToCustomerIdMap}
          projectIdToPathNameMap={projectIdToPathNameMap}
          handleClose={() => setShowBreakdownDialog({ open: false })}
        />
      )}
    </>
  );
};

BillableHoursScoreboard.title = title;

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(BillableHoursScoreboard);
