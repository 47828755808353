import gql from 'graphql-tag';

export default gql`
  mutation CreateInternalComment(
    $commentId: ID!
    $contentId: String!
    $userId: String!
    $dateCreated: String!
    $content: String!
    $synced: Boolean!
    $contentType: String
    $companyId: String!
  ) {
    createInternalComment(
      input: {
        commentId: $commentId
        contentId: $contentId
        userId: $userId
        dateCreated: $dateCreated
        content: $content
        synced: $synced
        contentType: $contentType
        companyId: $companyId
      }
    ) {
      __typename
      commentId
      contentId
      userId
      dateCreated
      content
      synced
    }
  }
`;
