import gql from 'graphql-tag';

export default gql`
  query GetRequestKey($requestKey: String!) {
    getRequestKey(requestKey: $requestKey) {
      keyId
      type
      data
    }
  }
`;
