import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Paper, Button } from '@material-ui/core';
import { AddCircleOutline as AddCircleOutlineIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import QuickBooksIconImage from '../../assets/images/quickbooks/qb-logo-icon-button.svg';

import { WHAT_TO_SHOW } from './add-customer-enums';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: theme.spacing(2),
    minHeight: '50vh',
  },
  paper: {
    height: 250,
    width: 250,
    padding: theme.spacing(5),
    textAlign: 'center',
    color: theme.palette.brandColorPrimary,
  },
  selectContentTypeLink: {
    border: 0,
    backgroundColor: 'transparent',
    padding: theme.spacing(3),
  },
  iconWrapper: {
    fontSize: 70,
  },
  stepInstructionText: {
    marginBottom: theme.spacing(2),
  },
}));

function AddCustomerChooseMode({ managingCompanyInfo, setWhatToShow }) {
  const classes = useStyles();

  const buttons = [
    {
      label: 'Blank Customer',
      icon: <AddCircleOutlineIcon fontSize="inherit" />,
      onClick: () => {
        setWhatToShow(WHAT_TO_SHOW.NEW_CUSTOMER_FORM);
      },
    },
  ];

  if (managingCompanyInfo && managingCompanyInfo.isCustomerSyncEnabled) {
    buttons.unshift({
      label: 'Import from QuickBooks Online',
      icon: (
        <img
          alt="QuickBooks Online Logo"
          src={QuickBooksIconImage}
          width={70}
        />
      ),
      onClick: () => {
        setWhatToShow(WHAT_TO_SHOW.IMPORT_FROM_QBO);
      },
    });
  }

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            className={classes.stepInstructionText}
          >
            Create new customer from:
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          {buttons.map(buttonInfo => {
            return (
              <Grid key={buttonInfo.label} item>
                <Button
                  className={classes.selectContentTypeLink}
                  onClick={buttonInfo.onClick}
                >
                  <Paper
                    className={classes.paper}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      style={{ flex: 1 }}
                    >
                      <Grid item className={classes.iconWrapper}>
                        {buttonInfo.icon}
                      </Grid>
                      <Grid
                        container
                        item
                        direction="column"
                        style={{ flex: 1, justifyContent: 'center' }}
                      >
                        {buttonInfo.label}
                      </Grid>
                    </Grid>
                  </Paper>
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
  };
}

export default connect(mapStateToProps)(AddCustomerChooseMode);
