import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  mutation SyncJrnToExisting(
    $companyId: String!
    $contentId: String!
    $qboCustomerId: String!
  ) {
    syncJrnToExisting(
      input: {
        companyId: $companyId
        contentId: $contentId
        qboCustomerId: $qboCustomerId
      }
    ) {
      __typename
      managingCompanyId
      customerId
      customerInfo {
        __typename
        customerId
        managingCompanyId
        firstName
        lastName
        address
        email
        latitude
        longitude
        phoneNumber
        notes
        companyName
      }
      contentId
      jrnId
      title
      creatorId
      contentUrl
      description
      endDate
      qboCustomerId
      privacyLevel
      inviteCode
      allowedToEdit
      allowedToAdd
      allowedToView
      usersEverOnJrn
      startDate
      synced
      labels
      latitude
      longitude
      phoneNumber
      phoneNumberActive
      type
      address
      useTimetracking
      useTimetrackingQuestions
      timetrackingQuestions
      timetrackingQuestionsCheckout
      # REMOVEME: remove this when "bugets" field is deprecated
      budgets {
        ...BudgetFields
      }
      fromTemplate
      timetrackingType
    }
  }
`;
