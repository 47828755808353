import React from 'react';
import { IconButton } from '@material-ui/core';

import {
  CheckCircle as CheckCircleIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  RadioButtonUnchecked as CircleIcon,
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/styles';
import palette from '../../theme/palette';

const useStyles = makeStyles(() => ({
  activeAccount: {
    color: palette.brandColorGreen,
  },
  activeAccountButton: {
    '& .active-hover': {
      color: palette.brandColorDarkGrey75,
      display: 'none',
    },
    '& .active-default': {
      color: palette.brandColorDarkGrey50,
    },
    '&:hover .active-hover': {
      display: 'initial',
    },
    '&:hover .active-default': {
      display: 'none',
    },
  },
  tableButtonWrapper: {
    flex: 1,
    justifyContent: 'center',
    display: 'flex',
  },
}));

const RowCircleSelect = ({ isActive, onSelect, target }) => {
  const classes = useStyles();

  return (
    <div className={classes.tableButtonWrapper}>
      <IconButton
        className={classes.activeAccountButton}
        size="small"
        onClick={() => onSelect({ target, isActive: !isActive })}
      >
        {isActive && <CheckCircleIcon className={classes.activeAccount} />}
        {!isActive && (
          <>
            <CircleIcon className="active-default" />
            <CheckCircleOutlineIcon className="active-hover" />
          </>
        )}
      </IconButton>
    </div>
  );
};

export default RowCircleSelect;
