/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useState, forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';
import { Lock as LockIcon } from '@material-ui/icons';
import ServicesRequiredDialog from '../../../../components/services-required';

const useStyles = makeStyles(theme => {
  const buttonStyles = {
    color: colors.blueGrey[800],
    padding: '8px 16px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.icon,
    },
  };

  return {
    item: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0,
    },
    button: buttonStyles,
    lockedButton: {
      ...buttonStyles,
      opacity: 0.5,
    },
    icon: {
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1),
    },
    active: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      '& $icon': {
        color: theme.palette.primary.main,
      },
    },
  };
});

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <NavLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  const [showServicesRequired, setShowServicesRequired] = useState(false);

  const ButtonDisplay = ({ Icon, title, locked }) => (
    <>
      <div className={classes.icon}>{Icon}</div>
      {title}
      {locked && (
        <LockIcon className={classes.icon} style={{ marginLeft: 4 }} />
      )}
    </>
  );

  return (
    <>
      <List {...rest}>
        {pages.map(page => (
          <ListItem className={classes.item} disableGutters key={page.title}>
            {!page.locked ? (
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to={page.href}
              >
                {ButtonDisplay(page)}
              </Button>
            ) : (
              <Button
                className={classes.lockedButton}
                onClick={() => setShowServicesRequired(true)}
              >
                {ButtonDisplay(page)}
              </Button>
            )}
          </ListItem>
        ))}
      </List>
      {showServicesRequired && (
        <ServicesRequiredDialog
          handleClose={() => setShowServicesRequired(false)}
        />
      )}
    </>
  );
};

export default SidebarNav;
