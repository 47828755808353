import React, { useState } from 'react';
import { compose } from 'react-apollo';
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
} from '@material-ui/core';
import {
  CloseOutlined as CloseIcon,
  CheckCircle as CheckIcon,
  BrokenImage as ErrorIcon,
} from '@material-ui/icons';
import moment from 'moment';

import * as GraphQL from '../../graphql/graphql';
import { createJrnInputMask } from '../../graphql/masks/create-jrn-input';
import ApplyTemplateContentSelect from './apply-template-content-select';
import LoadingCover from '../LoadingCover/loadingCover';
import palette from '../../theme/palette';
import { removeAttribute } from '../../helpers';

const STATUS = {
  SELECT: 0,
  CREATE: 1,
  DONE: 2,
};

const ApplyTemplateContentModal = ({
  projectInfo,
  handleClose,
  onCreateProjectFromTemplate,
  onSuccess,
}) => {
  const [status, setStatus] = useState(STATUS.SELECT);
  const [result, setResult] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleChoose = async () => {
    setStatus(STATUS.CREATE);
    const cleanProjectData = removeAttribute(projectInfo, '__typename');

    try {
      await onCreateProjectFromTemplate({
        templateId: selectedTemplate.contentId,
        projectInfo: createJrnInputMask(cleanProjectData),
        startDate: moment(projectInfo.startDate)
          .startOf('day')
          .toISOString(),
      });

      setResult(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('handleChoose ~ err', err);

      setResult(false);
    }

    setStatus(STATUS.DONE);
    onSuccess();
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle style={{ paddingTop: 8, paddingBottom: 8, paddingRight: 8 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Add Template Content</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent
        dividers
        style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
      >
        {status === STATUS.SELECT && (
          <ApplyTemplateContentSelect
            handleChoose={handleChoose}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        )}
        {status === STATUS.CREATE && (
          <LoadingCover>
            <Typography variant="h3">
              Adding content from template...
            </Typography>
          </LoadingCover>
        )}
        {status === STATUS.DONE && (
          <Grid container direction="column" style={{ flexGrow: 1 }}>
            <Grid container style={{ height: '100%', flexGrow: 1 }}>
              <Grid container item direction="column" xs={12} sm={12}>
                <Grid
                  container
                  item
                  direction="column"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  xs={12}
                  style={{ flex: 1 }}
                >
                  <Grid
                    item
                    style={{
                      fontSize: 90,
                      color: result
                        ? palette.brandColorGreen
                        : palette.brandColorMidGrey,
                    }}
                  >
                    {result ? (
                      <CheckIcon fontSize="inherit" color="inherit" />
                    ) : (
                      <ErrorIcon fontSize="inherit" color="inherit" />
                    )}
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">
                      {result ? (
                        <>
                          Content from template {selectedTemplate.title} was
                          added successfully!
                        </>
                      ) : (
                        <>
                          Sorry! An error occurred while adding content from
                          template {selectedTemplate.title}.
                        </>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginTop: 20 }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default compose(GraphQL.CreateProjectFromTemplateAction)(
  ApplyTemplateContentModal
);
