/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */

import React, { useContext, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo-hooks';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { Link as LinkIcon, MoreVert as MoreVertIcon } from '@material-ui/icons';
import ReactDataGrid from '@inovua/reactdatagrid-community';

import palette from '../../theme/palette';
import { FISCAL_YEAR_START_MONTH_TO_LAST_MONTH_MAP } from '../bookkeeping-alerts/bookkeeping-alerts-settings/bookkeeping-report-settings-info';
import { determineManagingCompanyInfo } from '../../helpers';
import LoadingCover from '../LoadingCover/loadingCover';
import GetCompanyById from '../../graphql/queries/get-company-by-id';
import { PRODUCT, SUBSCRIPTION_STATUSES } from '../../config/appDefaults';
import NeedALicenseModal from './need-a-license-modal.component';
import { FirmContext } from '../firm-dashboard/firm.context';

// #region DataGrid setup
const MIN_COLUMN_WIDTH = 200;
const COLUMN_WIDTH_SM = 180;

const gridStyle = { height: '100%', minHeight: '100%' };

// Grid scroll stype
const scrollProps = {
  ...ReactDataGrid.defaultProps.scrollProps,
  autoHide: false,
  scrollThumbWidth: 12,
  scrollThumbStyle: {
    background: palette.brandColorPrimary,
  },
};
// #endregion DataGrid setup

const yearEndSort = (a, b) => {
  const currentMonth = new Date().getMonth() + 1; // 1-12
  const monthNumberMap = {};
  _.keys(FISCAL_YEAR_START_MONTH_TO_LAST_MONTH_MAP).forEach((month, index) => {
    monthNumberMap[month] = index + 1;
  });

  const getMonthValue = month => {
    const monthNumber = monthNumberMap[month];
    if (monthNumber > currentMonth) {
      return monthNumber;
    }

    return monthNumber + 12;
  };

  const aValue = getMonthValue(a);
  const bValue = getMonthValue(b);

  if (aValue < bValue) return -1;
  if (aValue > bValue) return 1;
  return 0;
};

const CLIENT_ACTIONS = {
  REVOKE_LICENSE: 'REVOKE_LICENSE',
  ASSIGN_LICENSE: 'ASSIGN_LICENSE',
  DELETE: 'DELETE_CLIENT',
};

const emptyConfirmModal = {
  open: false,
  content: null,
  confirmAction: undefined,
  cancelAction: undefined,
};

const emptyNeedALicenseInfo = {
  open: false,
  companyInfo: null,
};

const hiddenLoaderOption = { show: false, title: '', content: '' };

const FirmClientList = ({
  visibleClientList,
  userInfo,
  firmInfo,
  dispatch,
}) => {
  const {
    firmClients,
    firmLicenses,
    availableLicenses,
    assignLicense,
    revokeLicense,
  } = useContext(FirmContext);

  const history = useHistory();

  const { refetch: getCompanyById } = useQuery(GetCompanyById, {
    fetchPolicy: 'network-only',
    skip: true,
    variables: {
      companyId: '',
    },
  });

  const [confirmModalOptions, setConfirmModalOptions] = useState(
    emptyConfirmModal
  );
  const [needALicenseInfo, setNeedALicenseInfo] = useState(
    emptyNeedALicenseInfo
  );
  const [loaderOption, setLoaderOption] = useState({
    ...hiddenLoaderOption,
  });

  const goToClientSpace = async ({ data }) => {
    const { companyId = null } = data || {};
    if (companyId) {
      let companyInfo = _.find(firmClients, { companyId });
      let hasBookkeepingAlertsProduct = companyInfo?.products.includes(
        PRODUCT.BOOKKEEPING_ALERTS
      );
      if (!hasBookkeepingAlertsProduct) {
        // If no bookkeepingAlerts product, show a dialog telling them they need to license it
        setLoaderOption({
          show: true,
          title: '',
          content: '',
        });

        const { data: getCompanyByIdData } = await getCompanyById({
          companyId,
        });
        companyInfo = _.get(getCompanyByIdData, 'getCompanyById', {});

        hasBookkeepingAlertsProduct = companyInfo?.products.includes(
          PRODUCT.BOOKKEEPING_ALERTS
        );

        setLoaderOption({ ...hiddenLoaderOption });
      }

      if (hasBookkeepingAlertsProduct) {
        // Otherwise, set the managing company info
        const managingCompanyInfo = determineManagingCompanyInfo({
          userInfo,
          companyInfo,
        });

        dispatch({
          type: 'SET_MANAGING_COMPANY_INFO',
          payload: {
            ...managingCompanyInfo,
          },
        });

        // Explicitly navigate to /alerts
        history.push('/alerts');
      } else {
        // Show need a license dialog
        setNeedALicenseInfo({
          open: true,
          companyInfo,
        });
      }
    }
  };

  const resetConfirmModal = () => setConfirmModalOptions(emptyConfirmModal);

  const buildModalContentFromCompany = ({ company, action }) => {
    const { companyName, companyLogo } = company;

    let wording = '';
    switch (action) {
      case CLIENT_ACTIONS.REVOKE_LICENSE:
        wording = (
          <>
            <Typography variant="body1">
              This will revoke the license from {companyName}. What this means
              is that any scheduled reports (e.g. Daily Alerts) will stop
              running. The client&apos;s settings, history of reports, etc, will
              all be kept if you re-license them at a later date.
            </Typography>
            <Typography
              variant="body1"
              style={{ paddingTop: 32, paddingBottom: 32, fontWeight: 'bold' }}
              align="center"
            >
              Are you sure you want to revoke their license?{' '}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ fontStyle: 'italic' }}
            >
              Note: Once removed from this client, the license will be available
              to assign to another client.
            </Typography>
          </>
        );
        break;
      case CLIENT_ACTIONS.ASSIGN_LICENSE:
        wording = (
          <>
            <Typography variant="body1" gutterBottom>
              Are you sure you want to assign a license to <b>{companyName}</b>?
            </Typography>
          </>
        );
        break;
      case CLIENT_ACTIONS.DELETE:
        wording = (
          <>
            <Typography variant="body1" gutterBottom>
              This will remove <b>{companyName}</b> from your Level account.
              What this means is that their settings, history of reports, etc
              will all be removed immediately and will not be available if you
              re-add them at a later date.
            </Typography>
            <br />
            <Typography
              variant="body1"
              gutterBottom
              align="right"
              style={{ fontWeight: 'bold' }}
            >
              Are you sure you want to delete them?
            </Typography>
            <br />
            <Typography
              variant="body1"
              gutterBottom
              style={{ fontStyle: 'italic' }}
            >
              Note: Once they&apos;re removed, their license will be available
              to assign to another client.
            </Typography>
          </>
        );
        break;
      default:
        wording = '';
    }
    return (
      <Grid container direction="column" alignItems="center">
        <Grid item>
          {!!companyLogo && (
            <img
              src={companyLogo}
              alt="company logo"
              style={{ width: 100, height: 100, marginBottom: 16 }}
            />
          )}
          <Grid item style={{ paddingTop: 16, paddingBottom: 16 }}>
            <Typography variant="h4">{companyName}</Typography>
          </Grid>
        </Grid>
        <Grid item>{wording}</Grid>
      </Grid>
    );
  };

  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState(null);
  const [selectedClientId, setSelectedClientIndex] = useState(null);

  const handleActionsClick = (event, id) => {
    setActionsMenuAnchorEl(event.currentTarget);
    setSelectedClientIndex(id);
  };
  const handleClose = () => {
    setActionsMenuAnchorEl(null);
    setSelectedClientIndex(null);
  };

  const columns = [
    {
      name: 'clientActions',
      header: '',
      minWidth: 64,
      maxWidth: 64,
      defaultFlex: 1,
      render: ({ data }) => {
        const { actions, activeLicenses } = data;
        if (actions) {
          const clientActions = _.map(actions, action => {
            switch (action) {
              case CLIENT_ACTIONS.REVOKE_LICENSE:
                return {
                  label: 'Revoke License',
                  action: async () => {
                    // build the action to take
                    const actionToTake = async () => {
                      setLoaderOption({
                        show: true,
                        title: `Revoking License from ${data.companyName}...`,
                      });
                      resetConfirmModal();
                      await revokeLicense({
                        companyId: data.companyId,
                        licenseId: activeLicenses[0].licenseId,
                      });
                      setLoaderOption({ ...hiddenLoaderOption });
                    };
                    // trigger the confirmation modal and pass in the action to take
                    setConfirmModalOptions({
                      open: true,
                      confirmAction: actionToTake,
                      cancelAction: () => {
                        setConfirmModalOptions({ open: false });
                      },
                      content: buildModalContentFromCompany({
                        company: data,
                        action: CLIENT_ACTIONS.REVOKE_LICENSE,
                      }),
                    });
                  },
                };
              case CLIENT_ACTIONS.ASSIGN_LICENSE:
                return {
                  label: 'Assign License',
                  action: async () => {
                    // build the action to take
                    const actionToTake = async () => {
                      setLoaderOption({
                        show: true,
                        title: `Assigning License to ${data.companyName}...`,
                      });
                      resetConfirmModal();
                      await assignLicense({
                        companyId: data.companyId,
                        productId: availableLicenses[0].productId,
                      });
                      setLoaderOption({ ...hiddenLoaderOption });
                    };
                    // trigger the confirmation modal and pass in the action to take
                    setConfirmModalOptions({
                      open: true,
                      confirmAction: actionToTake,
                      cancelAction: () => {
                        setConfirmModalOptions({ open: false });
                      },
                      content: buildModalContentFromCompany({
                        company: data,
                        action: CLIENT_ACTIONS.ASSIGN_LICENSE,
                      }),
                    });
                  },
                };
              default:
                return null;
            }
          });

          return (
            <div
              style={{ height: '100%', width: '100%' }}
              onClick={e => e.stopPropagation()}
            >
              <>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={event => handleActionsClick(event, data.companyId)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={actionsMenuAnchorEl}
                  open={
                    Boolean(actionsMenuAnchorEl) &&
                    selectedClientId === data.companyId
                  }
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      goToClientSpace({ data });
                      handleClose();
                    }}
                  >
                    Go To {data.companyName} Client Space
                  </MenuItem>

                  {clientActions.map(action => (
                    <MenuItem
                      key={action}
                      onClick={async () => {
                        await action.action();
                        handleClose();
                      }}
                    >
                      {action.label}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            </div>
          );
        }

        return null;
      },
    },
    {
      name: 'companyName',
      header: 'Client Name',
      minWidth: MIN_COLUMN_WIDTH,
      defaultFlex: 2,
    },
    {
      name: 'activeLicenses',
      header: 'License Status',
      minWidth: 150,
      maxWidth: COLUMN_WIDTH_SM,
      defaultFlex: 1,
      render: ({ value }) => {
        if (!value) {
          return null;
        }

        const inFreeTrial =
          firmInfo?.subscriptionStatus ===
          SUBSCRIPTION_STATUSES.ACTIVE_FREE_TRIAL;
        const hasActiveLicenses = value.length > 0;

        let licenseStatusTextColor = null;
        let licenseStatusText = '';
        if (hasActiveLicenses) {
          licenseStatusTextColor = palette.brandColorGreen;
          licenseStatusText = 'Active';
        } else if (inFreeTrial) {
          licenseStatusTextColor = palette.brandColorGreen;
          licenseStatusText = 'Trial';
        } else {
          licenseStatusText = palette.warning.main;
          licenseStatusText = 'Inactive';
        }

        return (
          <Grid
            item
            style={{
              ...(licenseStatusTextColor
                ? { color: licenseStatusTextColor }
                : {}),
            }}
          >
            {licenseStatusText}
          </Grid>
        );
      },
    },
    {
      name: 'connectedToQuickBooks',
      header: 'QuickBooks Status',
      minWidth: 180,
      defaultFlex: 1,
      render: ({ value }) => {
        return value ? (
          <Grid
            container
            item
            alignContent="center"
            style={{ color: palette.quickBooks.green }}
          >
            Connected
            <LinkIcon style={{ marginTop: -4, marginLeft: 2 }} />
          </Grid>
        ) : (
          <Grid item>Not connected</Grid>
        );
      },
    },
    {
      name: 'address',
      header: 'Address',
      minWidth: MIN_COLUMN_WIDTH,
      defaultFlex: 1,
    },
    {
      name: 'financialYearEnd',
      header: 'Financial Year End',
      minWidth: 170,
      defaultFlex: 1,
      sort: yearEndSort,
    },
    {
      name: 'industry',
      header: 'Industry',
      minWidth: COLUMN_WIDTH_SM,
      defaultFlex: 1,
    },
  ];

  const {
    dataSource: clientListDataSource,
    isInitializing: clientListInitializing,
  } = useMemo(() => {
    let dataSource = [];
    let isInitializing = false;

    if (!visibleClientList || !firmLicenses) {
      isInitializing = true;
    }

    dataSource = _.map(
      visibleClientList,
      ({
        companyId,
        companyName,
        industry,
        address,
        fiscalYearStart,
        connectedToQuickBooks,
      }) => {
        const companyInfo = {
          companyId,
          companyName,
          industry,
          address,
          connectedToQuickBooks,
        };

        /// Get the preceeding month
        if (fiscalYearStart) {
          const financialYearEnd =
            FISCAL_YEAR_START_MONTH_TO_LAST_MONTH_MAP[fiscalYearStart];

          if (financialYearEnd) {
            companyInfo.financialYearEnd = financialYearEnd;
          }
        }

        // Determine the license status
        const activeClientLicenses = _.filter(
          firmLicenses,
          ({ companyId: licenseCompanyId, isActive }) =>
            companyId === licenseCompanyId && isActive
        );

        // Active licenses
        companyInfo.activeLicenses = activeClientLicenses;

        // Determine the actions
        const actions = [];
        if (companyInfo.activeLicenses.length > 0) {
          actions.push(CLIENT_ACTIONS.REVOKE_LICENSE);
        } else if (availableLicenses.length > 0) {
          actions.push(CLIENT_ACTIONS.ASSIGN_LICENSE);
        }
        if (actions.length > 0) {
          companyInfo.actions = actions;
        } else {
          companyInfo.actions = null;
        }

        return companyInfo;
      }
    );

    return {
      dataSource,
      isInitializing,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleClientList, firmLicenses]);

  return (
    <>
      <Grid container item direction="column">
        <ReactDataGrid
          idProperty="companyId"
          columns={columns}
          dataSource={clientListDataSource}
          style={gridStyle}
          scrollProps={scrollProps}
          className="clickable-rows"
          defaultSortInfo={{
            name: 'companyName',
            dir: 1,
            type: 'string',
          }}
          allowUnsort={false}
          showActiveRowIndicator={false}
          onRowClick={goToClientSpace}
          emptyText={clientListInitializing ? '' : 'No clients found'}
        />
        {clientListInitializing && <LoadingCover />}
      </Grid>

      {loaderOption.show && (
        <LoadingCover>
          {!!loaderOption.title && (
            <Typography variant="h3">{loaderOption.title}</Typography>
          )}
          {!!loaderOption.content && (
            <Typography variant="body1">{loaderOption.content}</Typography>
          )}
        </LoadingCover>
      )}

      <NeedALicenseModal
        open={needALicenseInfo.open}
        onClose={() => setNeedALicenseInfo(emptyNeedALicenseInfo)}
        companyInfo={needALicenseInfo.companyInfo}
      />

      {confirmModalOptions.open && (
        <Dialog maxWidth="sm" fullWidth open>
          <DialogTitle>Please confirm...</DialogTitle>
          <DialogContent dividers>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              {confirmModalOptions.content}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={confirmModalOptions.cancelAction}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={confirmModalOptions.confirmAction}
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  firmInfo: state.appState.firmInfo || null,
});

export default connect(mapStateToProps)(FirmClientList);
