// @ts-check
import { BOOKKEEPING_ALERT } from '../bookkeeping-alerts.constants';

/** @enum {string} */
const BOOKKEEPING_ALERT_GROUP = {
  GENERAL_ALERTS: 'General Alerts',
  SECURITY_ALERTS: 'Security Alerts',
  NEW_ITEM_ALERTS: 'New Item Alerts',
  PROJECT_JOB_COSTING_ALERTS: 'Project/Job Costing Alerts',
};

// This drives the layout of the settings sheet
/**
 * @type {Array<{group: BOOKKEEPING_ALERT_GROUP, alerts: Array<BOOKKEEPING_ALERT>}>}
 */
export const BOOKKEEPING_ALERT_SETTINGS_LAYOUT = [
  {
    group: BOOKKEEPING_ALERT_GROUP.GENERAL_ALERTS,
    alerts: [
      BOOKKEEPING_ALERT.TRANSACTIONS_POSTED_TO_CLOSED_PERIOD,
      BOOKKEEPING_ALERT.VENDORS_WITH_NEGATIVE_BALANCES,
      BOOKKEEPING_ALERT.CUSTOMERS_WITH_NEGATIVE_BALANCES,
      BOOKKEEPING_ALERT.DUPLICATE_EXPENSE_TRANSACTIONS,
      BOOKKEEPING_ALERT.JOURNAL_ENTRY_CREATED_OR_MODIFIED,
      BOOKKEEPING_ALERT.MISSING_BILL_NUMBERS,
      BOOKKEEPING_ALERT.TRANSACTIONS_MISSING_VENDOR_CUSTOMER_INFORMATION,
      BOOKKEEPING_ALERT.NEW_VENDOR_IN_ACCOUNT,
      // alerts with subaccounts
      BOOKKEEPING_ALERT.CLASS_ALERTS,
      BOOKKEEPING_ALERT.LOCATION_ALERTS,
      BOOKKEEPING_ALERT.TRANSACTIONS_MISSING_PRODUCT_OR_SERVICE,
      BOOKKEEPING_ALERT.TRANSACTIONS_POSTED_TO_PARENT_ACCOUNTS,
    ],
  },
  {
    group: BOOKKEEPING_ALERT_GROUP.SECURITY_ALERTS,
    alerts: [
      BOOKKEEPING_ALERT.VOIDED_TRANSACTIONS,
      BOOKKEEPING_ALERT.DELETED_TRANSACTIONS,
    ],
  },
  {
    group: BOOKKEEPING_ALERT_GROUP.NEW_ITEM_ALERTS,
    alerts: [BOOKKEEPING_ALERT.NEW_PRODUCT, BOOKKEEPING_ALERT.NEW_VENDOR],
  },
  {
    group: BOOKKEEPING_ALERT_GROUP.PROJECT_JOB_COSTING_ALERTS,
    alerts: [
      BOOKKEEPING_ALERT.EXPENSE_TRANSACTIONS_WITH_CUSTOMERS_NOT_CODED_TO_COST_OF_SALES,
      BOOKKEEPING_ALERT.COST_OF_SALES_TRANSACTIONS_MISSING_CUSTOMER_INFORMATION,
      BOOKKEEPING_ALERT.NONBILLABLE_TRANSACTIONS_WITH_CUSTOMERS,
      BOOKKEEPING_ALERT.BILLABLE_EXPENSE_TRANSACTIONS_WITHOUT_ATTACHMENT,
      BOOKKEEPING_ALERT.NONBILLABLE_TIME_ACTIVITY,
      BOOKKEEPING_ALERT.TRANSACTIONS_POSTED_TO_PARENT_CUSTOMERS,
    ],
  },
];
