import React from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import LevelModal from '../Modal/level-modal';
import productIcon from '../../assets/images/cam/copilot_mascot_004.jpg';

const StripeCheckoutSuccessDialog = ({ onClose, children }) => {
  return (
    <LevelModal
      open
      fullWidth={false}
      maxWidth="sm"
      dialogStyle={{ zIndex: '9999' }}
    >
      <Grid container alignItems="center" style={{ position: 'relative' }}>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: 16 }}
        >
          <Typography
            variant="h3"
            style={{ textAlign: 'center', paddingLeft: 8, flex: 1 }}
          >
            Congratulations, you&apos;re subscribed to Level! 🎉
          </Typography>
          <IconButton onClick={onClose} style={{ flex: 0 }}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ marginBottom: 16 }}
          alignItems="center"
        >
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <img
              src={productIcon}
              style={{ width: 'auto', height: 200 }}
              alt="group of abstract objects representing bookkeeping alerts"
            />
          </Grid>
          {children && (
            <Grid item xs={8}>
              {children}
            </Grid>
          )}
        </Grid>
      </Grid>
    </LevelModal>
  );
};

export default StripeCheckoutSuccessDialog;
