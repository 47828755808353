import gql from 'graphql-tag';

export default gql`
  mutation UpdateUser(
    $userId: ID!
    $username: String
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $email: String
    $profilePic: String
    $coverImage: String
    $synced: Boolean
    $hasLoggedIn: Boolean
  ) {
    updateUser(
      input: {
        userId: $userId
        username: $username
        firstName: $firstName
        lastName: $lastName
        phoneNumber: $phoneNumber
        email: $email
        profilePic: $profilePic
        coverImage: $coverImage
        synced: $synced
        hasLoggedIn: $hasLoggedIn
      }
    ) {
      __typename
      userId
      username
      firstName
      lastName
      phoneNumber
      email
      profilePic
      coverImage
      synced
      hasLoggedIn
      companies
      userType
      type
    }
  }
`;
