import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.License}
  mutation AssignLicenseToClient($companyId: String!, $productId: String!) {
    assignLicenseToClient(
      input: { companyId: $companyId, productId: $productId }
    ) {
      ...LicenseFields
    }
  }
`;
