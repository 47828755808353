import gql from 'graphql-tag';

export default gql`
  fragment BudgetFields on Budget {
    budgetType
    label
    value
    context
  }
`;
