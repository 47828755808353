import React from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import FadeIn from 'react-fade-in';
import copy from 'copy-to-clipboard';
import uuid from 'uuid';

import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import LoadingCover from '../LoadingCover/loadingCover';

import {
  AddSlideshowToJrnAction,
  UpdateSlideshowOnJrnAction,
  GetSlideshowInfoAction,
} from '../../graphql/graphql';

import {
  SHARING_URL_MIN_LENGTH,
  SHARING_URL_MAX_LENGTH,
  SHARING_URL_PREFIX,
} from '../../config/appDefaults';

const useStyles = makeStyles(theme => ({
  dontForget: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.brandColorOrange,
    color: '#fff',
  },
  linkWrapper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.brandColorPrimary25,
    color: theme.palette.brandColorDarkGrey,
    position: 'relative',
  },
  saveButtonWrapper: {
    textAlign: 'right',
  },
  linkCoverup: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'transparent',
  },
}));

const validateUrl = url => {
  // do this on SAVE
  const shareUrlRegex = new RegExp(/^[a-z0-9]+(?:-[a-z0-9]+)*$/);
  const validPattern = shareUrlRegex.test(url);
  // do length checks
  if (
    url.length >= SHARING_URL_MIN_LENGTH &&
    url.length <= SHARING_URL_MAX_LENGTH &&
    validPattern
  ) {
    return true;
  }
  return false;
};

const SlideshowSettingsDialog = props => {
  const {
    onClose,
    open,
    userId,
    projectId,
    onAddSlideShowToJrn,
    onUpdateSlideshowOnJrn,
    slideshowInfo,
    slideshowLoading,
    enqueueSnackbar,
  } = props;
  const classes = useStyles();

  // const [link, setLink] = React.useState('');
  // const [active, setActive] = React.useState(null);

  const [isInitialSave, setIsInitialSave] = React.useState(null);
  const [linkIsValid, setLinkIsValid] = React.useState(false);

  const [unsavedChangesFlag, setUnsavedChangesFlag] = React.useState(null);
  const [isSaving, setIsSaving] = React.useState(false);

  const [exitDialog, setExitDialog] = React.useState(false);
  const [errorDialog, setErrorDialog] = React.useState({
    open: false,
    message: '',
  });

  const [updatedInfo, setUpdatedInfo] = React.useState({ ...slideshowInfo });
  React.useEffect(() => {
    const linkToUse =
      slideshowInfo.link && validateUrl(slideshowInfo.link)
        ? slideshowInfo.link
        : uuid();
    setUpdatedInfo({ ...slideshowInfo, link: linkToUse });

    const initSave = slideshowInfo.id ? !slideshowInfo.id : true;
    setIsInitialSave(initSave);

    if (validateUrl(linkToUse)) {
      setLinkIsValid(true);
    }
  }, [slideshowInfo]);

  const addSettings = async saveThis => {
    return onAddSlideShowToJrn(saveThis);
  };

  const updateSettings = async saveThis => {
    return onUpdateSlideshowOnJrn(saveThis);
  };

  const modalStuff = response => {
    // const response = data.data.updateSlideshowOnJrn;
    if (response.status === 'error') {
      let message;
      switch (response.msg) {
        case 'link invalid':
          message = 'That link is not valid. Please try another.';
          break;
        case 'no jrn':
          message = 'We ran into a issue. Please restart Level and try again.';
          break;
        case 'not allowed':
          message =
            "Sorry, you aren't allowed to make this change. Please check that you still have access to this project.";
          break;
        case 'duplicate link':
          message = 'That link is taken. Please try another one.';
          break;
        default:
          message = 'Unknown error. Please try again later.';
      }
      setErrorDialog({
        open: true,
        message,
      });
    }
  };

  const saveSettings = async () => {
    let response;
    const saveWith = {
      requestorId: userId,
      jrnId: projectId,
      link: updatedInfo.link,
      active: updatedInfo.active,
    };
    try {
      if (isInitialSave) {
        saveWith.id = uuid();
        response = await addSettings(saveWith);
        modalStuff(response.data.addSlideshowToJrn);
      } else {
        if (!updatedInfo.active) {
          saveWith.link = slideshowInfo.link;
          saveWith.active = false;
        }
        response = await updateSettings(saveWith);
        modalStuff(response.data.updateSlideshowOnJrn);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('slideshow saving error: ', e);
      modalStuff({ status: 'error' });
    }
  };

  const handleCancel = () => {
    onClose(false);
  };
  const handleDone = () => {
    // need to check if there are unsaved changes and if so prompt them before closing
    if (unsavedChangesFlag) {
      // you have unsaved changes - would you like to save them before exiting or ignore them
      setExitDialog(true);
    } else {
      onClose(false);
    }
  };

  const handleSave = async closeAfterSave => {
    if (!updatedInfo.active) {
      // save with the original info but with active set to false
      await saveSettings();
    }
    setIsSaving(true);
    // first validate the URL
    const urlValid = validateUrl(updatedInfo.link);
    if (!urlValid) {
      // show warning dialog
      modalStuff({ status: 'error', msg: 'link invalid' });
      setIsSaving(false);
      return;
    }
    // if it's valid, do the SAVE
    if (unsavedChangesFlag) {
      // check if there are unsaved changes and prompt before closing
      await saveSettings();
    }
    setIsSaving(false);
    if (closeAfterSave) {
      onClose(false);
    } else {
      enqueueSnackbar('Slideshow info save', {
        variant: 'success',
        autoHideDuration: 1000,
      });
      setUnsavedChangesFlag(false);
    }
  };

  const handleToggleEnable = event => {
    setUnsavedChangesFlag(true);
    setUpdatedInfo({ ...updatedInfo, active: event.target.checked });
    // setActive(event.target.checked);
  };

  const handleLinkTextChange = event => {
    const { value } = event.target;
    if (value === '') {
      setUpdatedInfo({ ...updatedInfo, link: '' });
      // setLink('');
      setLinkIsValid(false);
      setUnsavedChangesFlag(true);
      return;
    }

    const characterRegex = new RegExp(/^[a-z0-9-]/);
    let cleanValue = [];
    value
      .toLowerCase()
      .split('')
      .forEach(letter => {
        const innerValid = characterRegex.test(letter);
        // only push into the new
        if (innerValid) cleanValue.push(letter);
      });

    cleanValue = cleanValue.join('');
    // setLink(cleanValue);
    setLinkIsValid(true);
    setUpdatedInfo({ ...updatedInfo, link: cleanValue });
    setUnsavedChangesFlag(true);
  };

  const handleCopyLink = () => {
    // copy full link to clipboard
    const fullLink = `${SHARING_URL_PREFIX}${updatedInfo.link}`;
    copy(fullLink);
    // do confirmation via snack
    enqueueSnackbar('Link copied!', {
      variant: 'success',
      autoHideDuration: 1000,
    });
  };
  // const handleShareLink = () => {
  //   // email and?...
  // };

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose(event, reason);
        }
      }}
      disableEscapeKeyDown
      maxWidth="sm"
      aria-labelledby="slideshow-settings"
      open={open}
    >
      <DialogTitle id="slideshow-settings">Slideshow settings</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Enabling a slideshow means that the content in it may become
              publicly accessible to anyone with the link.
            </Typography>
            <Grid item xs={12}>
              <Paper className={classes.dontForget}>
                <Typography variant="h5" color="inherit">
                  And don&apos;t forget
                </Typography>
                <Typography component="p" color="inherit">
                  The simpler a link is, the higher the chance that someone
                  could guess it.
                </Typography>
              </Paper>
            </Grid>

            {slideshowLoading && <CircularProgress />}
            {/* unsavedChanges = {'' + unsavedChangesFlag} */}
            {!slideshowLoading && (
              <FadeIn>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        // needed to do !! since the original value was null and that was screwing up the ability to read an updatd value
                        checked={!!updatedInfo.active}
                        onChange={handleToggleEnable}
                        value
                        color="primary"
                      />
                    }
                    label="Enable Slideshow?"
                  />
                </Grid>
                {updatedInfo.active && (
                  <FadeIn>
                    <Grid item xs={12}>
                      <TextField
                        label="Customize the Link"
                        value={updatedInfo.link}
                        onChange={handleLinkTextChange}
                        maxLength={SHARING_URL_MAX_LENGTH}
                        fullWidth
                        error={!linkIsValid}
                        helperText={linkIsValid ? null : 'Invalid link'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Paper className={classes.linkWrapper}>
                        <Typography variant="body2" align="center">
                          {`${SHARING_URL_PREFIX}${updatedInfo.link}`}
                        </Typography>
                      </Paper>
                      <div className={classes.linkCoverup} />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Tooltip title="Copy to clipboard">
                          <Button
                            onClick={handleCopyLink}
                            color="primary"
                            variant="outlined"
                            style={
                              !linkIsValid || unsavedChangesFlag
                                ? { color: '#ddd', borderColor: '#ddd' }
                                : null
                            }
                          >
                            Copy Link
                          </Button>
                        </Tooltip>
                        {(!linkIsValid || unsavedChangesFlag) && (
                          <Tooltip title="You have unsaved changes">
                            <div className={classes.linkCoverup} />
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                  </FadeIn>
                )}
                {/* <pre>ssi = {JSON.stringify(slideshowInfo, null, 2)}</pre>
                <pre>ui = {JSON.stringify(updatedInfo, null, 2)}</pre> */}
              </FadeIn>
            )}
          </Grid>
          <Grid item xs={12} className={classes.saveButtonWrapper}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography variant="body1">
                  Be sure to save your changes before leaving.
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleSave(false)}
                  disabled={
                    (unsavedChangesFlag && linkIsValid === false) ||
                    !unsavedChangesFlag
                  }
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDone} color="primary">
          Done
        </Button>
      </DialogActions>

      {/* ////////////// <Warning dialog> */}
      <Dialog
        open={errorDialog.open}
        onClose={() => setErrorDialog({ ...errorDialog, open: false })}
        aria-labelledby="warning-dialog-title"
      >
        <DialogTitle id="warning-dialog-title">Sorry, but...</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorDialog.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setErrorDialog({ ...errorDialog, open: false })}
            color="primary"
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* ////////////// </Warning dialog> */}
      {/* ////////////// <Exiting dialog> */}
      <Dialog
        open={exitDialog}
        onClose={() => setExitDialog(false)}
        aria-labelledby="exit-dialog-title"
      >
        {/* <DialogTitle id="exit-dialog-title">Sorry, but...</DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            You have unsaved changes. Would you like to save them or ignore
            them?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setExitDialog(false)}
            color="secondary"
            autoFocus
            style={{ alignSelf: 'flex-start' }}
          >
            Cancel
          </Button>
          <Button onClick={handleCancel} color="primary" autoFocus>
            Ignore
          </Button>
          <Button onClick={() => handleSave(true)} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* ////////////// </Exiting dialog> */}
      {isSaving && (
        <LoadingCover>
          <Typography variant="h3">Saving...</Typography>
        </LoadingCover>
      )}
    </Dialog>
  );
};

function mapStateToProps(state) {
  return {
    userId: state.userInfo.userId,
    changeFilenamesOnUpload: state.appState.changeFilenamesOnUpload || false,
  };
}

export default compose(
  AddSlideshowToJrnAction,
  UpdateSlideshowOnJrnAction,
  GetSlideshowInfoAction
)(connect(mapStateToProps)(withSnackbar(SlideshowSettingsDialog)));
