import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import {
  Grid,
  IconButton,
  TextField,
  Typography,
  Badge,
} from '@material-ui/core';
import { Settings as SettingsIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import {
  BREAKEVEN_PERIOD,
  REPORT_PERIOD,
  ACCOUNTING_METHOD,
  SCOREBOARD_NAME,
} from '../../../config/appDefaults';
import {
  DashboardControlBar,
  DashboardControlBarSelect,
  DashboardSettings,
  DashboardSettingsTargets,
  DashboardSettingsReportData,
  DashboardSettingsAccountMapping,
  UnreviewedExpenseAccountsDialog,
} from '../../dashboard';
import { breakevenGraphs, BREAKEVEN_GRAPH } from './breakeven-graphs';
import { GetCompanyExpenseAccountsAction } from '../../../graphql/graphql';
import BreakevenSettingsExportData from './breakeven-settings-export-data';

const useStyles = makeStyles(theme => ({
  warningBadge: {
    backgroundColor: theme.palette.brandColorOrange75,
    color: theme.palette.white,
  },
}));

const BreakevenControlBar = ({
  managingCompanyInfo,
  title,
  reportPeriod,
  breakevenPeriod,
  setReportPeriod,
  accountingMethod,
  expenseAccounts,
  onRefresh,
}) => {
  const classes = useStyles();

  const [showSettings, setShowSettings] = useState(false);

  const handleSettingsClick = () => {
    setShowSettings(!showSettings);
  };

  const targetData = breakevenGraphs
    .filter(
      ({ name: reportName }) =>
        reportName === BREAKEVEN_GRAPH.ANNUAL_BREAKEVEN_SALES_FORECAST
    )
    .map(({ name: reportName, label, targetType }) => ({
      name: reportName,
      label,
      dataType: targetType,
    }));

  const accountingMethodDetails = (
    <Typography variant="body1" style={{ marginRight: 24 }}>
      Most businesses should use the Cash accounting method to discover the true
      risk of delayed or withheld payments and upfront expenses on their
      financial health.
    </Typography>
  );

  let warning = null;
  const unreviewedExpenseAccounts =
    expenseAccounts &&
    expenseAccounts.filter(account => account.isApproved === false).length;

  if (unreviewedExpenseAccounts) {
    const warningTitle = `${unreviewedExpenseAccounts} unapproved expense accounts`;

    warning = {
      title: warningTitle,
      component: UnreviewedExpenseAccountsDialog,
      componentProps: {
        title: warningTitle,
        goToSettings: () => {
          setShowSettings(true);
        },
      },
    };
  }

  const settingsSections = [
    {
      label: 'Report Data',
      component: DashboardSettingsReportData,
      sectionProps: {
        scoreboardName: SCOREBOARD_NAME.BREAKEVEN,
        breakevenPeriod,
        accountingMethod,
        accountingMethodDetails,
      },
    },
    {
      label: (
        <Badge
          classes={{ badge: classes.warningBadge }}
          badgeContent={unreviewedExpenseAccounts || 0}
        >
          Account Mapping
        </Badge>
      ),
      component: DashboardSettingsAccountMapping,
      sectionProps: {
        expenseAccounts,
      },
    },
    {
      label: 'Export Data',
      component: BreakevenSettingsExportData,
      sectionProps: {
        reportPeriod,
        accountingMethod,
      },
    },
  ];

  if (managingCompanyInfo && managingCompanyInfo.isCompanyBookkeeper) {
    settingsSections.push({
      label: 'Targets',
      component: DashboardSettingsTargets,
      sectionProps: {
        targetsToEdit: targetData,
      },
    });
  }

  const accountingMethodDisplay =
    accountingMethod !== null
      ? _.find(ACCOUNTING_METHOD, ({ key }) => key === accountingMethod)
          .displayValue
      : ' ';

  const breakevenInputDisplay =
    breakevenPeriod !== null
      ? _.find(BREAKEVEN_PERIOD, ({ key }) => key === breakevenPeriod)
          .displayValue
      : ' ';

  return (
    <>
      <DashboardControlBar title={title} warning={warning}>
        <Grid item style={{ minWidth: 150 }}>
          <TextField
            label="Accounting Method"
            value={accountingMethodDisplay}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item style={{ minWidth: 150 }}>
          <TextField
            label="Break-even Input"
            value={breakevenInputDisplay}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item style={{ minWidth: 150 }}>
          <DashboardControlBarSelect
            label="Report Period"
            options={REPORT_PERIOD}
            selected={reportPeriod}
            onChange={setReportPeriod}
          />
        </Grid>
        <Grid item>
          <IconButton
            color="primary"
            variant="outlined"
            onClick={handleSettingsClick}
          >
            <Badge
              variant="dot"
              classes={{
                badge: classes.warningBadge,
              }}
              badgeContent={unreviewedExpenseAccounts || 0}
              showZero={false}
            >
              <SettingsIcon />
            </Badge>
          </IconButton>
        </Grid>
      </DashboardControlBar>
      {showSettings && (
        <DashboardSettings
          title={`${title} Scoreboard Settings`}
          handleClose={() => setShowSettings(false)}
          sections={settingsSections}
          onRefresh={onRefresh}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(
  compose(GetCompanyExpenseAccountsAction)(BreakevenControlBar)
);
