import gql from 'graphql-tag';

export default gql`
  mutation MarkAllExpenseAccountsApproved($companyId: String!) {
    markAllExpenseAccountsApproved(input: { companyId: $companyId }) {
      items {
        accountId
        sourceId
        sourceParentId
        source
        displayName
        accountName
        accountType
        projectCostType
        productionCostType
        laborBurden
        isApproved
      }
    }
  }
`;
