import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import _ from 'lodash';
import moment from 'moment';
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import GetBreakevenExport from '../../../graphql/queries/get-breakeven-export';
import { REPORT_PERIOD, ACCOUNTING_METHOD } from '../../../config/appDefaults';
import palette from '../../../theme/palette';

const BreakevenExportDataSettings = ({
  client,
  reportPeriod,
  accountingMethod,
  managingCompanyInfo,
}) => {
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState(false);

  const handleDownloadClick = async () => {
    if (downloading) {
      return;
    }

    setDownloading(true);

    const response = await client.query({
      query: GetBreakevenExport,
      variables: {
        companyId: managingCompanyInfo.managingCompanyId,
        reportPeriod: _.find(REPORT_PERIOD, ({ key }) => key === reportPeriod)
          .value,
        accountingMethod: _.find(
          ACCOUNTING_METHOD,
          ({ key }) => key === accountingMethod
        ).value,
      },
      fetchPolicy: 'no-cache',
    });

    if (response && response.data && response.data.getBreakevenCsvExport) {
      const a = document.createElement('a');
      const file = new Blob([response.data.getBreakevenCsvExport], {
        type: 'text/csv',
      });
      a.href = URL.createObjectURL(file);
      a.download = `breakeven-data-${moment().format(
        'YYYY-MM-DD-HH-mm-ss'
      )}.csv`;
      a.click();
    } else {
      setError('Unable to retrieve CSV data. Please try again later.');
    }

    setDownloading(false);
  };

  return (
    <Grid container item direction="column" spacing={2}>
      <Grid item>
        <Typography style={{ fontWeight: 'bold' }} variant="body1">
          Export Break-even Data
        </Typography>
      </Grid>
      <Grid container item>
        <Grid item xs={9}>
          <Typography variant="body1" style={{ marginRight: 24 }}>
            The financial data that was used to determine the break-even point
            for each month can be exported to CSV to better understand how the
            break-even point was derived. The export will show how each
            financial account was classified and changed month-over-month
            throughout the report period.
          </Typography>
          {error && (
            <Typography
              variant="body1"
              style={{ color: palette.brandColorError }}
            >
              {error}
            </Typography>
          )}
        </Grid>
        <Grid container item xs={3} justifyContent="center">
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={handleDownloadClick}
              style={{ minWidth: 180 }}
            >
              {downloading ? (
                <CircularProgress size={24} style={{ color: 'white' }} />
              ) : (
                'Download'
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo,
});

export default connect(mapStateToProps)(
  withApollo(BreakevenExportDataSettings)
);
