import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';

import Label from './label';
import HelperText from './helper-text';

const useStyles = makeStyles(() => ({
  textField: {
    marginBottom: 16,
  },
}));

/**
 * NOTE: errorText, successText and helperText are mutually exclusive.
 * Priority is errorText > successText > helperText
 */
const AuthTextField = ({
  name,
  label,
  lableTooltip,
  value,
  required,
  maxLength,
  errorText,
  helperText,
  successText,
  type,
  disabled,
  onBlur,
  onChange,
  onEnterKeyPress,
  onKeyPress,
  placeholder,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <TextField
      name={name}
      label={<Label text={label} required={required} tooltip={lableTooltip} />}
      type={type}
      fullWidth
      className={classes.textField}
      InputLabelProps={{ shrink: true }}
      value={value}
      inputProps={{ maxLength }}
      placeholder={placeholder || null}
      helperText={
        <HelperText
          text={errorText || successText || helperText}
          error={!!errorText}
          success={!!successText}
        />
      }
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      onKeyPress={event => {
        if (onEnterKeyPress && event.key === 'Enter') {
          onEnterKeyPress(event);
        }

        if (onKeyPress) {
          onKeyPress(event);
        }
      }}
      {...otherProps}
    />
  );
};

export default AuthTextField;
