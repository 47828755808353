import React from 'react';

import { TextField, InputAdornment } from '@material-ui/core';

const LaborBudgetTextField = ({
  label,
  value,
  adornmentChar,
  showZeroAsEmpty = true,
  ...rest
}) => {
  return (
    <TextField
      label={label}
      fullWidth
      value={showZeroAsEmpty ? value || '' : value}
      InputProps={{
        startAdornment: adornmentChar ? (
          <InputAdornment position="start">{adornmentChar}</InputAdornment>
        ) : null,
      }}
      variant="outlined"
      size="small"
      {...rest}
    />
  );
};

export default LaborBudgetTextField;
