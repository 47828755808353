import gql from 'graphql-tag';

export default gql`
  mutation LeaveJrn($jrnId: ID!) {
    leaveJrn(input: { jrnId: $jrnId }) {
      __typename
      jrnId
    }
  }
`;
