import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    minWidth: 320,
  },
  route: {
    display: 'block',
    width: '100%',
    paddingTop: 16,
    paddingBottom: 12,
    color: theme.palette.brandColorPrimary,
    fontSize: 22,
  },
  activeRoute: {
    display: 'block',
    width: '100%',
    paddingTop: 16,
    paddingBottom: 12,
    borderBottom: '2px solid rgb(57, 124, 165)',
    color: theme.palette.brandColorPrimary,
    fontSize: 22,
  },
}));

const AuthNav = () => {
  const classes = useStyles();

  const location = useLocation();

  const isSignIn =
    location.pathname.includes('signin') &&
    !location.pathname.includes('reset');
  const isSignUp = location.pathname.includes('signup');
  const isReset = location.pathname.includes('reset');
  const signInOnly = location.pathname.startsWith('/signin');

  const SignInTab = () => (
    <Grid item style={{ flex: 1 }}>
      <Link
        className={isSignIn ? classes.activeRoute : classes.route}
        to="/auth/signin"
        replace
      >
        Sign In
      </Link>
    </Grid>
  );

  const SignUpTab = () => (
    <Grid item style={{ flex: 1 }}>
      <Link
        className={isSignUp ? classes.activeRoute : classes.route}
        to="/auth/signup"
        replace
      >
        Sign Up
      </Link>
    </Grid>
  );

  const ResetTab = () => (
    <Grid item style={{ flex: 1 }}>
      <Link
        className={isReset ? classes.activeRoute : classes.route}
        to={!signInOnly ? '/auth/reset' : '/signin/reset'}
        replace
      >
        Reset
      </Link>
    </Grid>
  );

  if (signInOnly) {
    return (
      <Grid container item xs={12} className={classes.buttonWrapper}>
        <SignInTab />
        <ResetTab />
      </Grid>
    );
  }

  return (
    <Grid container item xs={12} className={classes.buttonWrapper}>
      <SignInTab />
      {!signInOnly && <SignUpTab />}
      <ResetTab />
    </Grid>
  );
};

export default AuthNav;
