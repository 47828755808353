import React, { useMemo } from 'react';
import {
  FormHelperText,
  MenuItem,
  Select as MuiSelect,
} from '@material-ui/core';
import spacetime from 'spacetime';
import soft from 'timezone-soft';
import { useTime } from 'react-timer-hook';
import { formatInTimeZone } from 'date-fns-tz';

import DEFAULT_TIMEZONES from './default-timezones';

/**
 * Gets the abbreviation of a given timezone.
 *
 * @param {string} timezone - The timezone to get the abbreviation for.
 * @returns {string} The abbreviation of the given timezone.
 */
export const getTimezoneAbbreviation = timezone => {
  const now = spacetime.now(timezone);
  const tzStrings = soft(timezone);
  return now.isDST()
    ? tzStrings[0].daylight?.abbr
    : tzStrings[0].standard?.abbr;
};

const TimezoneSelect = ({
  value,
  onChange,
  timezones = DEFAULT_TIMEZONES,
  labelStyle = 'original',
  showCurrentTime = true,
  disabled = false,
}) => {
  const options = useMemo(() => {
    return Object.entries(timezones)
      .reduce((selectOptions, zone) => {
        const now = spacetime.now(zone[0]);
        const tz = now.timezone();
        const tzStrings = soft(zone[0]);

        let label = '';
        const abbr = now.isDST()
          ? tzStrings[0].daylight?.abbr
          : tzStrings[0].standard?.abbr;
        const altName = now.isDST()
          ? tzStrings[0].daylight?.name
          : tzStrings[0].standard?.name;

        const min = tz.current.offset * 60;
        const hr = `${Math.floor(min / 60)}:${
          min % 60 === 0 ? '00' : Math.abs(min % 60)
        }`;
        const prefix = `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${zone[1]}`;

        switch (labelStyle) {
          case 'original':
            label = prefix;
            break;
          case 'altName':
            label = `${prefix} ${altName?.length ? `(${altName})` : ''}`;
            break;
          case 'abbrev':
            label = `${prefix} ${abbr?.length < 5 ? `(${abbr})` : ''}`;
            break;
          default:
            label = `${prefix}`;
        }

        selectOptions.push({
          value: tz.name,
          label,
          offset: tz.current.offset,
          abbrev: abbr,
          altName,
        });

        return selectOptions;
      }, [])
      .sort((a, b) => a.offset - b.offset);
  }, [labelStyle, timezones]);

  const findFuzzyTz = zone => {
    let currentTime = spacetime.now('GMT');
    try {
      currentTime = spacetime.now(zone);
    } catch (err) {
      return null;
    }

    return options
      .filter(tz => tz.offset === currentTime.timezone().current.offset)
      .map(tz => {
        let score = 0;
        if (
          currentTime.timezones[tz.value.toLowerCase()] &&
          !!currentTime.timezones[tz.value.toLowerCase()].dst ===
            currentTime.timezone().hasDst
        ) {
          if (
            tz.value
              .toLowerCase()
              .indexOf(
                currentTime.tz.substring(currentTime.tz.indexOf('/') + 1)
              ) !== -1
          ) {
            score += 8;
          }
          if (
            tz.label
              .toLowerCase()
              .indexOf(
                currentTime.tz.substring(currentTime.tz.indexOf('/') + 1)
              ) !== -1
          ) {
            score += 4;
          }
          if (
            tz.value
              .toLowerCase()
              .indexOf(currentTime.tz.substring(0, currentTime.tz.indexOf('/')))
          ) {
            score += 2;
          }
          score += 1;
        } else if (tz.value === 'GMT') {
          score += 1;
        }
        return { tz, score };
      })
      .sort((a, b) => b.score - a.score)
      .map(({ tz }) => tz)[0];
  };

  const parseTimezone = zone => {
    if (typeof zone === 'object' && zone.value && zone.label) {
      return zone;
    }
    if (typeof zone === 'string') {
      return (
        options.find(tz => tz.value === zone) ||
        (zone.indexOf('/') !== -1 && findFuzzyTz(zone))
      );
    }
    if (zone.value && !zone.label) {
      return options.find(tz => tz.value === zone.value);
    }
    return null;
  };

  const timeZoneValue = useMemo(() => {
    return parseTimezone(value)?.value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, value]);

  const { minutes } = useTime({});

  const currentZonedTimeString = useMemo(() => {
    let currentZonedTime = '';
    if (showCurrentTime) {
      currentZonedTime = formatInTimeZone(
        new Date().setMinutes(minutes),
        timeZoneValue,
        'MMM dd, yyyy hh:mm a'
      );
    }
    return currentZonedTime;
  }, [minutes, showCurrentTime, timeZoneValue]);

  const handleChange = event => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <>
      <MuiSelect
        fullWidth
        value={timeZoneValue}
        onChange={handleChange}
        disabled={disabled}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {showCurrentTime && (
        <FormHelperText>{currentZonedTimeString}</FormHelperText>
      )}
    </>
  );
};

export default TimezoneSelect;
