import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.License}
  mutation RevokeLicenseFromClient($companyId: String!, $licenseId: String!) {
    revokeLicenseFromClient(
      input: { companyId: $companyId, licenseId: $licenseId }
    ) {
      ...LicenseFields
    }
  }
`;
