import React from 'react';
import { connect } from 'react-redux';
import { compose, withApollo } from 'react-apollo';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddEditTimetrackingForm from './add-edit-timetracking-form';
import GetUsersByJrnId from '../../../graphql/queries/GetUsersByJrnId';
import LoadingCover from '../../../components/LoadingCover/loadingCover';

import ChooseProjectWithSubs from '../../../components/choose-project-dialog/choose-project-with-subs';
import palette from '../../../theme/palette';

const useStyles = makeStyles(theme => ({
  stepInstructionText: {
    color: theme.palette.brandColorPrimary,
    textAlign: 'center',
  },
  chooseSections: {
    paddingBottom: theme.spacing(4),
  },
}));

const AddEditTimetracking = ({
  open,
  onClose,
  checkInContent,
  checkOutContent,
  projectToAddToProp,
  assigneeInfo,
  userInfo,
  client,
  includeArchive,
}) => {
  const classes = useStyles();
  const isEditMode = !!checkInContent || !!checkOutContent;
  const [projectToAddTo, setProjectToAddTo] = React.useState(
    projectToAddToProp
  );
  const [projectUsers, setProjectUsers] = React.useState(null);
  const [localAssigneeInfo, setLocalAssigneeInfo] = React.useState(
    assigneeInfo
  );
  const [loadingUser, setLoadingUser] = React.useState(false);

  const getProjectUsers = async project => {
    let projectUsersResponse;
    const projectId = project.contentId;
    try {
      projectUsersResponse = await client.query({
        query: GetUsersByJrnId,
        variables: { jrnId: projectId },
        fetchPolicy: 'no-cache',
      });
      let returnedUsers = _.get(
        projectUsersResponse,
        'data.getUsersByJrnId.items'
      );
      if (returnedUsers) {
        returnedUsers = _.orderBy(
          returnedUsers,
          [user => user.username.toLowerCase()],
          ['asc']
        );
      }
      return returnedUsers || [];
      // then find the piece of content needing to be edited
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('GetUsersByJrnId query e: ', e);
      return null;
    }
  };

  const triggerUserList = async project => {
    setLoadingUser(true);
    let users;
    try {
      users = await getProjectUsers(project);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('getProjectUsers err: ', err);
    }
    setProjectUsers(users);
    setLoadingUser(false);
  };

  const renderContent = () => {
    if (isEditMode && projectToAddTo && assigneeInfo) {
      // just show the edit form with the info prefilled
      return (
        <AddEditTimetrackingForm
          checkInContent={checkInContent}
          checkOutContent={checkOutContent}
          projectToAddTo={projectToAddTo}
          assigneeInfo={assigneeInfo}
          onClose={onClose}
          userInfo={userInfo}
          includeArchive={includeArchive}
        />
      );
    }
    if (projectToAddTo && localAssigneeInfo) {
      return (
        <AddEditTimetrackingForm
          projectToAddTo={projectToAddTo}
          assigneeInfo={localAssigneeInfo}
          onClose={onClose}
          userInfo={userInfo}
          includeArchive={includeArchive}
        />
      );
    }
    if (loadingUser) {
      return (
        <Grid container style={{ minHeight: 300 }}>
          <LoadingCover>
            <Typography variant="h3">Loading project crew...</Typography>
          </LoadingCover>
        </Grid>
      );
    }

    if (projectToAddTo) {
      return (
        <Grid container direction="column" className={classes.chooseSections}>
          <Typography
            variant="h4"
            gutterBottom
            className={classes.stepInstructionText}
          >
            Which user would you like to assign this to?
          </Typography>
          <Grid
            item
            xs={6}
            style={{
              margin: '16px auto',
              width: '100%',
              backgroundColor: palette.background.alt,
            }}
          >
            {projectUsers &&
              projectUsers.map(user => {
                const allPermissionsLists = projectToAddTo.allowedToEdit
                  .concat(...(projectToAddTo.allowedToAdd || []))
                  .concat(...(projectToAddTo.allowedToView || []));
                const inList = allPermissionsLists.includes(user.userId);
                return (
                  <ListItem
                    key={user.userId}
                    button
                    onClick={() => setLocalAssigneeInfo(user)}
                    style={{ width: '100%' }}
                  >
                    <ListItemAvatar>
                      <Avatar alt={user.username} src={user.profilePic} />
                    </ListItemAvatar>
                    <ListItemText primary={user.username} />
                    {!inList && (
                      <ListItemText
                        style={{ textAlign: 'right' }}
                        secondary="no longer on project"
                      />
                    )}
                  </ListItem>
                );
              })}
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container direction="column" className={classes.chooseSections}>
        <Grid item>
          <Typography
            variant="h4"
            gutterBottom
            className={classes.stepInstructionText}
          >
            Which project would you like to add to:
          </Typography>
        </Grid>
        <Grid
          item
          xs={9}
          style={{ margin: '0 auto', width: '100%', maxHeight: '40vh' }}
        >
          <ChooseProjectWithSubs
            setSelectedProject={selectedProjectInfo => {
              setProjectToAddTo(selectedProjectInfo.project);
              triggerUserList(selectedProjectInfo.project);
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle
        disableTypography
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">
          {isEditMode ? 'Edit' : 'Add'} Time-tracking
        </Typography>
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: 40, paddingTop: 0 }}>
        {renderContent()}
      </DialogContent>
    </Dialog>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
  };
}

export default compose(withApollo)(
  connect(mapStateToProps)(AddEditTimetracking)
);
