import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@material-ui/core';
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
} from '@material-ui/icons';
import ContentLoader from 'react-content-loader';
import moment from 'moment';
import _ from 'lodash';

import { DashboardReportTableRow } from '../../dashboard';
import TransactionTable, {
  COLUMN_DEF,
  ORDER_DIRECTION,
} from './transaction-table';
import palette from '../../../theme/palette';

const MODE = {
  GROUP_BY_CUSTOMERS: {
    label: 'Group By Customers',
    value: 'groupByCustomers',
  },
  FLAT_LIST: { label: 'Flat List', value: 'flatList' },
};

const CustomerTransactionsBreakdown = ({
  defaultTitle,
  reportData,
  containerStyle,
  loading,
  onHideSettingsButtonClick,
  hideFilterSettings,
}) => {
  const containerRef = useRef(null);
  const [mode, setMode] = useState(MODE.GROUP_BY_CUSTOMERS.value);
  const [order, setOrder] = useState(ORDER_DIRECTION.ASC);
  const [orderBy, setOrderBy] = useState(COLUMN_DEF.TXN_DATE.key);
  const [loaderDimensions, setLoaderDimensions] = useState({
    height: 16,
    width: 16,
  });

  const hideCustomerName = mode === MODE.GROUP_BY_CUSTOMERS.value;

  useEffect(() => {
    if (containerRef?.current) {
      setLoaderDimensions({
        height: containerRef.current.clientHeight,
        width: containerRef.current.clientWidth,
      });
    }
  }, []);

  const orderByOptions = useMemo(() => {
    const options = [];
    _.values(COLUMN_DEF).forEach(({ key, label, isSortable }) => {
      if (hideCustomerName && key === COLUMN_DEF.CUSTOMER_NAME.key) {
        return;
      }

      if (!isSortable) {
        return;
      }

      options.push({
        key,
        label,
      });
    });

    const sortedOptions = _.orderBy(options, 'label');

    // reset orderBy if customer name is hidden
    if (hideCustomerName && orderBy === COLUMN_DEF.CUSTOMER_NAME.key) {
      setOrderBy(COLUMN_DEF.TXN_DATE.key);
    }

    return sortedOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideCustomerName]);

  const generateGroupByCustomersData = () => {
    const txnsByCustomerMap = {};
    const unassignedTxns = [];

    // group transactions by customerId
    _.forEach(reportData?.items, txn => {
      const { customerId } = txn;
      if (!customerId) {
        unassignedTxns.push(txn);
        return;
      }

      if (!txnsByCustomerMap[customerId]) {
        txnsByCustomerMap[customerId] = [];
      }
      txnsByCustomerMap[customerId].push(txn);
    });

    let breakdownData = [];
    _.forEach(txnsByCustomerMap, (txns, customerId) => {
      const { customerName } = txns[0];
      breakdownData.push({
        key: customerId,
        name: customerName,
        value: txns.length,
        expandableBody: (
          <TransactionTable
            transactions={txns}
            order={order}
            orderBy={orderBy}
            hideCustomerName
          />
        ),
      });
    });

    breakdownData = _.orderBy(breakdownData, 'name', ORDER_DIRECTION.ASC);

    // add unassigned txns to the end
    if (!_.isEmpty(unassignedTxns)) {
      breakdownData.push({
        key: 'unassigned',
        name: 'Unassigned within Cost of Sales Accounts',
        value: unassignedTxns.length,
        expandableBody: (
          <TransactionTable
            transactions={unassignedTxns}
            order={order}
            orderBy={orderBy}
            hideCustomerName
          />
        ),
      });
    }

    return breakdownData;
  };

  const generateFlatListData = () => {
    const data = [];
    if (!_.isEmpty(reportData?.items)) {
      data.push({
        key: 'all',
        name: 'All',
        value: reportData.items.length,
        expandableBody: (
          <TransactionTable
            transactions={reportData.items}
            order={order}
            orderBy={orderBy}
          />
        ),
        defaultExpanded: true,
      });
    }

    return data;
  };

  const title = useMemo(() => {
    if (!reportData) {
      return defaultTitle;
    }

    const startOfPeriod = moment(reportData.startDate);
    const endOfPeriod = moment(reportData.endDate);

    return startOfPeriod.year() === endOfPeriod.year()
      ? `${startOfPeriod.format('MMM D')} - ${endOfPeriod.format(
          'MMM D, YYYY'
        )}`
      : `${startOfPeriod.format('MMM D, YYYY')} - ${endOfPeriod.format(
          'MMM D, YYYY'
        )}`;
  }, [defaultTitle, reportData]);

  const breakdownDataToUse = useMemo(() => {
    if (!reportData) {
      return [];
    }

    return mode === MODE.GROUP_BY_CUSTOMERS.value
      ? generateGroupByCustomersData()
      : generateFlatListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, reportData, order, orderBy]);

  return (
    <Grid style={containerStyle} innerRef={containerRef}>
      {loading && (
        <ContentLoader
          width={loaderDimensions.width - 16}
          height={loaderDimensions.height - 16}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
          style={{ marginTop: 16 }}
        >
          <rect
            x="0"
            y="0"
            rx="4"
            ry="4"
            width={loaderDimensions.width - 16}
            height={loaderDimensions.height - 16}
          />
        </ContentLoader>
      )}
      {!loading && (
        <>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container item xs={5} alignItems="center">
              <Typography
                variant="h5"
                style={{
                  fontWeight: 'bold',
                  marginRight: 16,
                  marginTop: 8,
                  marginBottom: 8,
                }}
              >
                {title}
              </Typography>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={onHideSettingsButtonClick}
              >
                {`${hideFilterSettings ? 'Show' : 'Hide'} Settings`}
              </Button>
            </Grid>
            <Grid
              container
              item
              xs={7}
              justifyContent="flex-end"
              alignItems="center"
            >
              <IconButton
                onClick={() => {
                  setOrder(currentOrder =>
                    currentOrder === ORDER_DIRECTION.ASC
                      ? ORDER_DIRECTION.DESC
                      : ORDER_DIRECTION.ASC
                  );
                }}
              >
                {order === ORDER_DIRECTION.ASC ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )}
              </IconButton>
              <Select
                value={orderBy}
                disableUnderline
                onChange={event => {
                  setOrderBy(event.target.value);
                }}
                style={{ marginRight: 16 }}
              >
                {_.map(orderByOptions, ({ key, label }) => (
                  <MenuItem key={key} value={key}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
              <FormControl>
                <RadioGroup
                  row
                  aria-label="mode"
                  name="mode"
                  value={mode}
                  onChange={event => {
                    setMode(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value={MODE.GROUP_BY_CUSTOMERS.value}
                    control={<Radio color="primary" />}
                    label={MODE.GROUP_BY_CUSTOMERS.label}
                  />
                  <FormControlLabel
                    value={MODE.FLAT_LIST.value}
                    control={<Radio color="primary" />}
                    label={MODE.FLAT_LIST.label}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <div
            style={{
              // minus 50px for the header bar (title + settings button)
              height: 'calc(100% - 50px)',
              overflowY: 'auto',
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{
                backgroundColor: palette.brandColorLightGrey,
                padding: '8px 16px',
              }}
            >
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                Customer Name
              </Typography>
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                # of Transactions
              </Typography>
            </Grid>
            <Grid
              container
              direction="column"
              style={{
                paddingRight: 16,
                paddingBottom: 16,
              }}
            >
              {!reportData && (
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: 16 }}
                >
                  <Typography>
                    Click RUN REPORT to view scoreboard data
                  </Typography>
                </Grid>
              )}
              {!!reportData && _.isEmpty(breakdownDataToUse) && (
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: 16 }}
                >
                  <Typography>No transactions</Typography>
                </Grid>
              )}
              {_.map(breakdownDataToUse, data => (
                <DashboardReportTableRow
                  key={data.key}
                  name={data.name}
                  value={data.value}
                  color={data.color}
                  expandableBody={data.expandableBody}
                  defaultExpanded={data.defaultExpanded}
                  dividerBelowExpandableBody
                />
              ))}
            </Grid>
          </div>
        </>
      )}
    </Grid>
  );
};

export default CustomerTransactionsBreakdown;
