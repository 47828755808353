import React, { useContext, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { AddCircle, InfoOutlined } from '@material-ui/icons';

import BackToTopButton from '../back-to-top/back-to-top';

import FilterSearch from '../FilterSearch/filterSearch';
import { Dashboard, DASHBOARD_LAYOUT } from '../dashboard';
import FirmDashboardControlBar from './firm-dashboard-control-bar';
import FirmClientList from '../firm-client-list/firm-client-list.component';
import { SUBSCRIPTION_STATUSES } from '../../config/appDefaults';
import { FirmContext } from './firm.context';
import ManageSubscriptionDialog from '../../layouts/Main/components/Topbar/manage-subscription-dialog/manage-subscription-dialog';
import palette from '../../theme/palette';
import camIcon from '../../assets/images/cam/copilot_mascot_004.jpg';

const FirmDashboard = ({ firmInfo }) => {
  const {
    setShowAddClientDialog,
    firmLicenses,
    availableLicenses,
    openManageStripeLicenseWindow,
    firmClients,
  } = useContext(FirmContext);
  const containerRef = useRef(null);

  const [clientListToShow, setClientListToShow] = useState(null);

  const filterClientList = filteredList => {
    setClientListToShow(filteredList);
  };

  const numOfFirmLicenses = firmLicenses?.length || 0;
  const numOfFirmLicensesInUse =
    (firmLicenses?.length || 0) - (availableLicenses?.length || 0);

  const hasFreeTrial =
    firmInfo?.subscriptionStatus === SUBSCRIPTION_STATUSES.ACTIVE_FREE_TRIAL;

  const hasActiveSubscription =
    firmInfo?.subscriptionStatus === SUBSCRIPTION_STATUSES.ACTIVE_SUBSCRIPTION;

  const hasExpired =
    firmInfo?.subscriptionStatus === SUBSCRIPTION_STATUSES.EXPIRED_FREE_TRIAL ||
    firmInfo?.subscriptionStatus === SUBSCRIPTION_STATUSES.EXPIRED_SUBSCRIPTION;

  const [
    showFreeTrialExplanationModal,
    setShowFreeTrialExplanationModal,
  ] = useState(false);
  const handleShowFreeTrialExplanationModalOpen = () => {
    setShowFreeTrialExplanationModal(true);
  };
  const handleShowFreeTrialExplanationModalClose = () => {
    setShowFreeTrialExplanationModal(false);
  };

  const [showGoToPaymentModal, setShowGoToPaymentModal] = useState(false);
  const handlePurchaseSubscriptionClick = () => {
    handleShowFreeTrialExplanationModalClose();
    setShowGoToPaymentModal(true);
  };

  const title = (
    <Grid item container>
      <Grid item>
        <Typography variant="h2">Clients</Typography>
      </Grid>

      <Grid item container direction="row" style={{ padding: '4px 0 8px 3px' }}>
        <Grid item xs={12} style={{ marginBottom: 4 }}>
          {hasFreeTrial && (
            <Grid item container direction="row" alignItems="center">
              <ButtonBase onClick={handleShowFreeTrialExplanationModalOpen}>
                <Typography variant="body1" style={{ marginRight: 4 }}>
                  Free Trial - UNLIMITED Licenses!
                </Typography>
                <InfoOutlined style={{ color: palette.brandColorPrimary }} />
              </ButtonBase>
            </Grid>
          )}
          {hasActiveSubscription && (
            <Typography variant="body1">
              Licenses in use: {numOfFirmLicensesInUse?.toString() || '-'} of{' '}
              {numOfFirmLicenses?.toString() || '-'}
            </Typography>
          )}
          {hasExpired && (
            <ButtonBase onClick={handlePurchaseSubscriptionClick}>
              <Typography variant="body1">
                No licenses available.
                <span
                  style={{
                    color: palette.brandColorPrimary,
                    cursor: 'pointer',
                  }}
                >
                  {' '}
                  Please purchase a subscription to get going!
                </span>
              </Typography>
            </ButtonBase>
          )}
        </Grid>
      </Grid>
      {showGoToPaymentModal && (
        <ManageSubscriptionDialog
          closeDialog={() => {
            setShowGoToPaymentModal(false);
          }}
        />
      )}
      {showFreeTrialExplanationModal && (
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={showFreeTrialExplanationModal}
          onClose={handleShowFreeTrialExplanationModalClose}
          maxWidth="sm"
        >
          <DialogContent dividers>
            <Typography variant="h2" style={{ marginBottom: 16 }}>
              Free Trial
            </Typography>
            <Grid item container>
              <Grid item xs={3} style={{ paddingRight: 16 }}>
                <img
                  src={camIcon}
                  alt="Level mascot"
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body1" style={{ marginBottom: 16 }}>
                  You are currently on a free trial of Level Copilot which
                  includes <b>unlimited licenses</b>! This means you can
                  experience the full potential of Level with all your clients{' '}
                  <b>without limits</b>, ensuring you get firsthand insight into
                  how our powerful features can streamline your workflow.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Once you&apos;re ready to purchase,{' '}
                  <b>buy only as many licenses as you need</b>. Purchasing
                  licenses is quick, easy, and cost-effective!
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              color="primary"
              onClick={() => setShowFreeTrialExplanationModal(false)}
              style={{ marginRight: 16 }}
            >
              Maybe Later
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePurchaseSubscriptionClick}
            >
              Purchase Licenses Now
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Grid>
  );

  return (
    <Dashboard
      layout={DASHBOARD_LAYOUT.SIMPLE}
      controlBar={<FirmDashboardControlBar title={title} hideAccessExplainer />}
    >
      <Grid container direction="column">
        <Grid
          container
          item
          style={{ width: '100%', padding: 16, paddingTop: 0 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Button
              variant="contained"
              startIcon={<AddCircle />}
              size="small"
              style={{ backgroundColor: '#eee' }}
              onClick={() => setShowAddClientDialog(true)}
            >
              Add Client
            </Button>
            {hasActiveSubscription && (
              <Button
                variant="contained"
                startIcon={<AddCircle />}
                size="small"
                onClick={openManageStripeLicenseWindow}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginLeft: 8, backgroundColor: '#eee' }}
              >
                Add Licenses
              </Button>
            )}
          </Grid>
          <Grid item>
            <Grid item style={{ paddingRight: 16 }}>
              <FilterSearch
                data={firmClients}
                passBack={filterClientList}
                searchBy="companyName"
                placeholder="Search your client list..."
                hideLabel
                searchInputProps={{
                  style: { minWidth: 300 },
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="center"
          style={{
            overflowY: 'auto',
            paddingTop: 0,
            paddingLeft: 16,
            paddingRight: 16,
            flex: 1,
          }}
          ref={containerRef}
        >
          <FirmClientList visibleClientList={clientListToShow} />
          <BackToTopButton scrollElementRef={containerRef} />
        </Grid>
      </Grid>
    </Dashboard>
  );
};

const mapStateToProps = state => ({
  firmInfo: state.appState.firmInfo,
});

export default connect(mapStateToProps)(FirmDashboard);
