import React, { useMemo, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import numeral from 'numeral';
import _ from 'lodash';
import ContentLoader from 'react-content-loader';

import SplitFinancialItemList from '../split-financial-item-list';
import palette from '../../theme/palette';
import LoadingCover from '../LoadingCover/loadingCover';
import { calculateUnallocatedAmount } from '../../helpers/split-item/split-item.util';
import ChooseBillsDialog from '../choose-bills-dialog/choose-bills-dialog';

const BillPaymentBillSplitter = ({
  amount,
  billList,
  setBillList,
  vendorId,
  invalidFieldMap,
  setInvalidFieldMap,
  chillMode = false, // Chill mode ignores any amount validation limits and does not show split details
  showVendor = false,
  showLoader = false,
  disabled = false,
  requireVendor = false,
}) => {
  const [showChooseBillDialog, setShowChooseBillDialog] = useState(false);

  const amountAsFloat = parseFloat((amount && amount.value) || 0);

  const visibleBillList = useMemo(() => {
    return _.filter(billList, project => {
      return !project.deletionPending;
    });
  }, [billList]);

  // this will be passed to choose-bill-dialog to exclude the pre-selected bills
  const selectedGlobalBills = useMemo(() => {
    return _.map(visibleBillList, ({ bill }) => bill);
  }, [visibleBillList]);

  const { unappliedAmountString, unappliedAmountStyle } = useMemo(() => {
    const unallocatedAmount = calculateUnallocatedAmount({
      totalAmount: amountAsFloat,
      contentList: visibleBillList,
    });

    const unallocatedAmountString = numeral(unallocatedAmount).format('$0.00');

    let amountStyle = {
      color: amountAsFloat !== 0 ? palette.brandColorGreen : 'inherit',
    };
    if (unallocatedAmount < 0) {
      amountStyle = {
        fontWeight: 'bold',
        color: palette.brandColorError,
      };
    } else if (unallocatedAmount > 0) {
      amountStyle = {
        fontWeight: 'bold',
        color: palette.brandColorOrange,
      };
    }

    return {
      unappliedAmountString: unallocatedAmountString,
      unappliedAmountStyle: amountStyle,
    };
  }, [amountAsFloat, visibleBillList]);

  const handleAddButtonClick = () => {
    setShowChooseBillDialog(true);
  };

  const handleBillSelectionDone = bills => {
    const newBillList = [...billList];
    let isBillListChanged = false;

    setShowChooseBillDialog(false);

    _.forEach(bills, bill => {
      const amountToUse = bill.balance?.value || bill.amount?.value || 0;

      const existingBillIndex = _.findIndex(
        newBillList,
        b => b.bill.contentId === bill.contentId
      );

      if (existingBillIndex >= 0) {
        // if it is pending deletion, remove the deletion flag
        if (newBillList[existingBillIndex].deletionPending) {
          newBillList[existingBillIndex] = {
            ...newBillList[existingBillIndex],
            deletionPending: false,
            amount: amountToUse,
          };
          isBillListChanged = true;
        }
      } else {
        newBillList.push({
          bill,
          amount: (+amountToUse).toFixed(2),
          existingContentInfo: null,
        });
        isBillListChanged = true;
      }
    });

    if (isBillListChanged) {
      setBillList(newBillList);
    }
  };

  const handleBillDelete = index => {
    const billIdToDelete = visibleBillList[index].bill?.contentId;
    setBillList(currentState => {
      const newBillList = [...currentState];

      const billIndex = _.findIndex(
        newBillList,
        ({ bill }) => bill.contentId === billIdToDelete
      );

      if (
        newBillList[billIndex] &&
        newBillList[billIndex].existingContentInfo
      ) {
        newBillList[billIndex].deletionPending = true;
      } else if (newBillList[billIndex]) {
        newBillList.splice(billIndex, 1);
      }

      return newBillList;
    });
  };

  const handleFieldChange = (value, fieldName, index) => {
    const billIdToChange = visibleBillList[index].bill.contentId;

    setBillList(currentState => {
      const newBillList = [...currentState];
      const billIndex = _.findIndex(
        newBillList,
        ({ bill }) => bill.contentId === billIdToChange
      );

      if (newBillList[billIndex]) {
        newBillList[billIndex][fieldName] = value;
      }

      return newBillList;
    });
  };

  return (
    <Grid item style={{ paddingTop: 16 }}>
      <Grid container>
        <Grid item style={{ flex: 1 }}>
          <Typography color="primary" variant="h6">
            Which bills does this apply to?
          </Typography>
        </Grid>
        {!chillMode && (
          <Grid item>
            <Typography variant="h6" style={unappliedAmountStyle}>
              Unapplied Amount: {unappliedAmountString}
            </Typography>
          </Grid>
        )}
      </Grid>
      {showLoader && (
        <ContentLoader
          viewBox="0 0 100 10"
          width="100%"
          style={{ paddingTop: 16, paddingBottom: 16 }}
        >
          <rect x="0" y="0" width="100" height="10" />
        </ContentLoader>
      )}
      {!showLoader && billList && (
        <SplitFinancialItemList
          itemList={visibleBillList}
          onAddButtonClick={handleAddButtonClick}
          onDeleteButtonClick={handleBillDelete}
          onFieldChange={handleFieldChange}
          hideTotal
          listStyle={{ paddingLeft: 0, paddingRight: 0 }}
          allowDeleteExisting={chillMode || visibleBillList.length > 1}
          billMode
          invalidAmountMap={invalidFieldMap?.amountMap}
          setInvalidAmountMap={newInvalidAmountMap => {
            if (setInvalidFieldMap) {
              setInvalidFieldMap(currentState => {
                return {
                  ...currentState,
                  amountMap: newInvalidAmountMap,
                };
              });
            }
          }}
          showVendor={showVendor}
          disabled={disabled || (requireVendor && !vendorId)}
          disabledMessage={
            requireVendor &&
            !vendorId &&
            'Vendor is required before you can choose bills'
          }
        />
      )}
      {!billList && <LoadingCover />}
      {showChooseBillDialog && (
        <ChooseBillsDialog
          open={showChooseBillDialog}
          initialSelectedBills={selectedGlobalBills}
          multipleSelection
          vendorId={vendorId}
          onBillSelectionDone={handleBillSelectionDone}
          onClose={() => {
            setShowChooseBillDialog(false);
          }}
        />
      )}
    </Grid>
  );
};

export default BillPaymentBillSplitter;
