import gql from 'graphql-tag';

export default gql`
  fragment StatFields on StatGroup {
    timetrackingTotal
    timetrackingCostTotal
    receiptTotal
    billTotal
    invoiceTotal
    paymentTotal
    taskIncomplete
    taskInProgress
    taskPendingApproval
    taskCompleted
  }
`;
