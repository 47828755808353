import React from 'react';

import { Grid, ButtonBase, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import palette from '../../theme/palette';

const useStyles = makeStyles(() => ({
  //   theme.palette.brandColorPrimary
  statTab: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 8,
    paddingTop: 8,
    borderRight: '1px solid #aaa',
    flex: 1,
    borderBottomColor: '#aaa',
    minHeight: 65,
  },
}));

const LevelTabs = ({ tabs = [] }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      style={{
        marginBottom: 12,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {tabs.map((tab, index) => {
        const { isActive, title, subtitle, onClick, visible } = tab;

        if (!visible) return null;

        return (
          <ButtonBase
            key={title}
            className={classes.statTab}
            style={{
              borderBottom: isActive ? 'none' : '1px solid #aaa',
              backgroundColor: isActive
                ? 'transparent'
                : palette.background.alt,
              ...(index === tabs.length - 1 && { borderRight: 'none' }),
            }}
            onClick={onClick}
          >
            <Grid style={{ flexDirection: 'column' }}>
              <Typography color="primary" align="center">
                {title}
              </Typography>
              {!!subtitle && (
                <Typography variant="caption" color="primary" align="center">
                  {subtitle}
                </Typography>
              )}
            </Grid>
          </ButtonBase>
        );
      })}
    </Grid>
  );
};

export default LevelTabs;
