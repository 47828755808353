export const USERNAME_STATUS = {
  IDLE: 'idle',
  TAKEN: 'taken',
  ACCEPTED: 'accepted',
};

export const ONBOARDING_STEP = {
  WELCOME_TO_LEVEL: 'welcomeToLevel',
  CHOOSE_COMPANY_TYPE: 'chooseCompanyType',
  UPDATE_PROFILE_INFO: 'updateProfileInfo',
  ENTER_COMPANY_INFO: 'enterCompanyInfo',
  SELECT_PRODUCTS: 'selectProducts',
  CHOOSE_BOOKKEEPING_SUBSCRIPTION: 'chooseBookkeepingSubscription',
  CHOOSE_BOOKKEEPING_ALERTS_PRICE: 'chooseBookkeepingAlertsPrice',
  CONGRATS_ON_PURCHASE: 'congratsOnPurchase',
  FINAL_IS_MOBILE: 'fromMobile',
};

export const COMPANY_TYPE = {
  FIRM: 'firm',
  CLIENT: 'client',
  COMPANY: 'company',
};
