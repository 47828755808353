import React from 'react';

import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import DashboardControlBar from './dashboard-control-bar';
import styles from './dashboard.styles';

export const DASHBOARD_MODE = {
  DEFAULT: 'default',
  MODAL: 'modal',
};

export const DASHBOARD_LAYOUT = {
  DEFAULT: 'default',
  BIG_LEFT: 'bigLeft',
  SIMPLE: 'simple', // No tab section bar, paddings or margins
};

const useDefaultStyles = makeStyles(theme => styles(theme));
const useModalStyles = makeStyles(theme => styles(theme, DASHBOARD_MODE.MODAL));
const useSimpleDashboardStyles = makeStyles(theme =>
  styles(theme, DASHBOARD_MODE.DEFAULT, DASHBOARD_LAYOUT.SIMPLE)
);

/**
 * @param {Object} dashboardProperties Properties that describe the dashboard layout
 * @param {String} dashboardProperties.title Dashboard title
 * @param {ReactElement?} dashboardProperties.controlBar Optional control bar component
 * @param {'default' | 'bigLeft'} dashboardProperties.layout Optional preset dashboard arrangements
 */
const Dashboard = ({
  title = '',
  controlBar = null,
  hideControlBar = false,
  children,
  layout = DASHBOARD_LAYOUT.DEFAULT,
  mode = DASHBOARD_MODE.DEFAULT,
  dashboardFullStyle,
  containerStyle,
}) => {
  const modalClasses = useModalStyles();
  const defaultClasses = useDefaultStyles();
  const simpleDashboardClasses = useSimpleDashboardStyles();

  let classes;
  if (mode === DASHBOARD_MODE.MODAL) {
    classes = modalClasses;
  } else {
    classes = defaultClasses;

    if (layout === DASHBOARD_LAYOUT.SIMPLE) {
      classes = simpleDashboardClasses;
    }
  }

  const renderChildren = () => {
    // Big left layout
    if (layout === DASHBOARD_LAYOUT.BIG_LEFT && children.length === 3) {
      const [bigLeft, topRight, bottomRight] = children;

      return (
        <>
          <div style={{ flex: 1, height: '100%' }}>
            <div className={classes.dashboardHalf}>{bigLeft}</div>
          </div>
          <div
            style={{
              flex: 1,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div className={classes.dashboardQuadrant}>{topRight}</div>
            <div className={classes.dashboardQuadrant}>{bottomRight}</div>
          </div>
        </>
      );
    }

    if (_.isArray(children)) {
      // Default layout
      let sectionClass = null;
      // eslint-disable-next-line valid-typeof
      switch (children.length) {
        case 1:
          sectionClass = classes.dashboardFull;
          break;
        case 2:
          sectionClass = classes.dashboardHalf;
          break;
        case 3:
        case 4:
          sectionClass = classes.dashboardQuadrant;
          break;
        default:
          break;
      }

      if (sectionClass) {
        return children.map((quadrant, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} className={sectionClass}>
            {quadrant}
          </div>
        ));
      }
    } else if (children) {
      return (
        <div className={classes.dashboardFull} style={dashboardFullStyle}>
          {children}
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={
        layout === DASHBOARD_LAYOUT.SIMPLE
          ? classes.simpleDashboard
          : classes.dashboard
      }
      style={containerStyle}
    >
      {!hideControlBar && (controlBar || <DashboardControlBar title={title} />)}
      <div className={classes.dashboardGraphArea} style={dashboardFullStyle}>
        {renderChildren()}
      </div>
    </div>
  );
};

export default Dashboard;
