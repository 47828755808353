import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Grid, ButtonBase, Typography, Tooltip } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import moment from 'moment';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';

import { asCurrency } from '../dashboard/dashboard.utils';
import { PAYMENT_METHOD_LABEL } from '../../config/appDefaults';
import ButtonWithTooltip from '../button-with-tooltip/button-with-tooltip';

const GlobalExpensePaymentSplit = ({
  classes,
  content,
  companyCrew,
  selectable,
  userId,
  managingCompanyInfo,
  onViewClick,
  onDeleteClick,
}) => {
  const addingUser = useMemo(() => {
    if (content && content.creatorId && companyCrew) {
      return _.find(companyCrew, { userId: content.creatorId });
    }
    return null;
  }, [content, companyCrew]);

  const paymentMethod = PAYMENT_METHOD_LABEL[content.subtype];

  const isAllowedToAccessGlobalPayment = useMemo(() => {
    if (managingCompanyInfo.isCompanyAdmin) {
      return true;
    }

    if (content?.jrn) {
      const { allowedToEdit } = content.jrn;

      if (_.includes(allowedToEdit, userId)) {
        return true;
      }
    }

    return false;
  }, [content, managingCompanyInfo, userId]);

  return (
    <Grid container>
      <Grid
        item
        xs={onDeleteClick ? 11 : 12}
        container
        direction="column"
        style={{ position: 'relative' }}
        className={
          selectable && isAllowedToAccessGlobalPayment
            ? classes.selectablePaymentSplitContainer
            : classes.paymentSplitContainer
        }
      >
        <Grid item container>
          <Grid item style={{ flex: 2 }}>
            <Typography variant="caption">
              {moment(content.date).format('MMM DD, YYYY')}
            </Typography>
          </Grid>
          <Grid container item style={{ flex: 8 }} direction="column">
            <Grid item>
              {paymentMethod && (
                <Typography variant="caption">
                  Payment made by {paymentMethod.toLowerCase()}
                  {addingUser && `, `}
                </Typography>
              )}
              {addingUser && (
                <Typography variant="caption">
                  {paymentMethod ? 'r' : 'R'}ecorded by {addingUser.username}
                </Typography>
              )}
            </Grid>
            {content.description && (
              <Typography variant="caption">
                <div className={classes.descriptionWrapper}>
                  {ReactHtmlParser(content.description || 'No notes provided')}
                </div>
              </Typography>
            )}
          </Grid>
          <Grid item style={{ flex: 2, textAlign: 'right' }}>
            <Typography variant="caption">
              {asCurrency((content.amount && content.amount.value) || 0)}
            </Typography>
          </Grid>
        </Grid>
        {selectable && onViewClick && (
          <Tooltip
            title={
              isAllowedToAccessGlobalPayment
                ? 'View this payment'
                : 'You do not have permission to view this payment'
            }
          >
            {isAllowedToAccessGlobalPayment ? (
              <ButtonBase
                className={classes.selectableButton}
                onClick={() => {
                  onViewClick(content.jrn);
                }}
              />
            ) : (
              <div className={classes.selectableButton} />
            )}
          </Tooltip>
        )}
      </Grid>
      {!!onDeleteClick && (
        <Grid item xs={1} container justifyContent="flex-end">
          <ButtonWithTooltip
            iconButton
            tooltipText={
              isAllowedToAccessGlobalPayment
                ? 'Delete this payment'
                : 'You do not have permission to delete this payment'
            }
            size="small"
            onClick={() => {
              onDeleteClick(content);
            }}
            disabled={!isAllowedToAccessGlobalPayment}
          >
            <DeleteIcon />
          </ButtonWithTooltip>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = state => ({
  userId: state.userInfo && state.userInfo.userId,
  managingCompanyInfo: state.appState.managingCompanyInfo,
});

export default connect(mapStateToProps)(GlobalExpensePaymentSplit);
