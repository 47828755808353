import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import camIcon from '../../assets/images/cam/copilot_mascot_004.jpg';

import styles from './auth.styles';

const useStyles = makeStyles(styles);

const VerificationSentView = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      xs={12}
      className={classes.formContainer}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        item
        xs={12}
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={4}
      >
        <Grid item>
          <Typography variant="h3">Congratulations!</Typography>
        </Grid>
        <img src={camIcon} alt="Level mascot" style={{ width: '20%' }} />
        <Grid item>
          <Typography variant="body1" style={{ textAlign: 'center' }}>
            Our journey together has begun! Please click the link sent to your
            email to finish signing up.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" style={{ textAlign: 'center' }}>
            You can close this window.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VerificationSentView;
