import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { Grid, Button, Typography } from '@material-ui/core';
import _ from 'lodash';
import ContentLoader from 'react-content-loader';

import { modifyQueryParams } from '../../helpers';
import { useQueryStringParams } from '../../hooks';
import FirmDashboard from '../../components/firm-dashboard/firm-dashboard';
import QboRedirectHandlerWrapper from '../../components/qbo-redirect-handler-wrapper/qbo-redirect-handler-wrapper';
import StripeErrorOnCheckout from '../../components/stripe-dialogs/stripe-error-on-checkout';
import StripeCheckoutSuccessDialog from '../../components/stripe-dialogs/stripe-checkout-success-dialog';
import AssignLicenseToClient from '../../graphql/mutations/mutation_assign-license-to-client';
import GetCompanyById from '../../graphql/queries/get-company-by-id';
import LoadingCover from '../../components/LoadingCover/loadingCover';
import { FirmContext } from '../../components/firm-dashboard/firm.context';
import ConnectToQuickbooks from '../../components/connect-to-quickbooks';

const Firm = ({ firmInfo }) => {
  const {
    listFirmLicensesQuery,
    listFirmClientsQuery,
    firmClients,
    firmLicenses,
    availableLicenses,
  } = useContext(FirmContext);
  const [congratsPaidDialog, setCongratsPaidDialog] = useState(false);
  const [congratsPaidDialogLoading, setCongratsPaidDialogLoading] = useState(
    false
  );
  const [errorOnCheckoutDialog, setErrorOnCheckoutDialog] = useState(false);

  const history = useHistory();
  const queryStringParams = useQueryStringParams();
  const queryCheckoutParam = queryStringParams.get('checkout');

  const getFirmInfo = useQuery(GetCompanyById, {
    fetchPolicy: 'network-only',
    skip: true,
    variables: { companyId: firmInfo?.companyId },
  });
  const [assignLicense] = useMutation(AssignLicenseToClient);

  useEffect(() => {
    if (queryCheckoutParam) {
      if (queryCheckoutParam === 'success') {
        setCongratsPaidDialog(true);
      } else {
        setErrorOnCheckoutDialog(true);
      }
    }
  }, [queryCheckoutParam]);

  useEffect(() => {
    if (congratsPaidDialog) {
      getFirmInfo.startPolling(2000);
      listFirmLicensesQuery.startPolling(2000); // Speed up interval temporarily
    } else {
      getFirmInfo.stopPolling();
      listFirmLicensesQuery.stopPolling(); // Return to 10s interval
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [congratsPaidDialog]);

  // Get firm license list
  const getLatestLicenseData = async () => {
    const {
      data: listFirmLicensesData,
    } = await listFirmLicensesQuery.refetch();
    return _.get(listFirmLicensesData, 'listFirmLicenses.items', []);
  };

  const getLatestClientData = async () => {
    const { data: listFirmClientsData } = await listFirmClientsQuery.refetch();
    return _.get(listFirmClientsData, 'listFirmClients.items', []);
  };

  const closeCongratsPaidDialog = () => {
    setCongratsPaidDialog(false);
    modifyQueryParams({
      history,
      clearAllParams: true,
      pushOrReplace: 'replace',
    });
  };

  const autoAssignLicenses = async () => {
    setCongratsPaidDialogLoading(true);

    const latestFirmLicenses = await getLatestLicenseData();

    const licensesToAssign = _.filter(
      latestFirmLicenses,
      ({ isActive, companyId }) => isActive && !companyId
    );

    const companyIdsToLicense = [];
    const orderedFirmClients = _.orderBy(
      firmClients,
      ['connectedToQuickBooks', 'dateFirstConnected'],
      ['desc', 'asc']
    );

    _.forEach(orderedFirmClients, client => {
      const clientHasLicense = _.some(
        latestFirmLicenses,
        ({ companyId }) => companyId === client.companyId
      );

      if (!clientHasLicense) {
        companyIdsToLicense.push(client.companyId);
      }
    });

    const assignLicenseParams = [];
    _.forEach(licensesToAssign, async (license, i) => {
      const companyId = companyIdsToLicense[i];
      if (companyId) {
        assignLicenseParams.push({
          companyId,
          productId: license.productId,
        });
      }
    });

    // Assign licenses sequentially
    await _.reduce(
      assignLicenseParams,
      async (promise, { companyId, productId }) => {
        await promise;
        await assignLicense({ variables: { companyId, productId } });
      },
      Promise.resolve()
    );

    // Call to update after all the shanenigans
    await Promise.all([getLatestLicenseData(), getLatestClientData()]);

    // Close the dialog
    closeCongratsPaidDialog();

    setCongratsPaidDialogLoading(false);
  };

  const hasAvailableLicenses = availableLicenses?.length > 0;

  const clientsMissingLicenses = firmClients?.filter(({ companyId }) => {
    const foundLicense = _.find(
      firmLicenses,
      ({ companyId: assignedToCompanyId, isActive }) =>
        companyId === assignedToCompanyId && isActive
    );
    return !foundLicense;
  });

  const hasClientsMissingLicenses = clientsMissingLicenses?.length > 0;

  return (
    <QboRedirectHandlerWrapper showGoToClientDialog>
      <FirmDashboard />
      {errorOnCheckoutDialog && (
        <StripeErrorOnCheckout
          onClose={() => {
            setErrorOnCheckoutDialog(false);
            modifyQueryParams({
              history,
              clearAllParams: true,
              pushOrReplace: 'replace',
            });
          }}
        />
      )}
      {congratsPaidDialog && (
        <StripeCheckoutSuccessDialog onClose={closeCongratsPaidDialog}>
          {congratsPaidDialogLoading && <LoadingCover />}
          <Grid container spacing={2} style={{ paddingLeft: 8 }}>
            {!firmClients && (
              <>
                <Grid item xs={12}>
                  <ContentLoader
                    width={400}
                    height={100}
                    style={{ padding: 8 }}
                  />
                </Grid>
                <Grid container justifyContent="center">
                  <ContentLoader
                    width={100}
                    height={20}
                    style={{ maxWidth: 220, marginTop: 8 }}
                  />
                </Grid>
              </>
            )}
            {firmClients?.length > 0 && !hasClientsMissingLicenses && (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: 16,
                    }}
                  >
                    You can now assign licenses to new clients. Connect more
                    clients using the button below!
                  </Typography>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: 32 }}
                >
                  <ConnectToQuickbooks redirectTo="/firm" />
                </Grid>
              </>
            )}
            {firmClients?.length > 0 && hasClientsMissingLicenses && (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: 16,
                    }}
                  >
                    You now have licenses available to assign to your clients.
                    We can help you get started by automatically assigning them.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: 16,
                    }}
                  >
                    We&apos;ll assign licenses to your clients in the order that
                    they were added. If you want to make changes afterwards, you
                    can easily re-assign them!
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  style={{ padding: 16 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={autoAssignLicenses}
                    disabled={!hasAvailableLicenses}
                  >
                    Auto-Assign Licenses
                  </Button>
                </Grid>
              </>
            )}
            {firmClients?.length === 0 && (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: 16,
                    }}
                  >
                    So exciting!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: 16,
                    }}
                  >
                    Now, let&apos;s add a client to take advantage of that new
                    subscription.
                  </Typography>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: 32 }}
                >
                  <ConnectToQuickbooks redirectTo="/firm" />
                </Grid>
              </>
            )}
          </Grid>
        </StripeCheckoutSuccessDialog>
      )}
    </QboRedirectHandlerWrapper>
  );
};

const mapStateToProps = state => ({ firmInfo: state.appState.firmInfo });

export default connect(mapStateToProps)(Firm);
