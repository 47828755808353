import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  query GetCompanyAdminProjectData(
    $companyId: String!
    $includeArchive: Boolean
    $nextToken: String
  ) {
    getCompanyAdminProjectData(
      input: {
        companyId: $companyId
        includeArchive: $includeArchive
        nextToken: $nextToken
      }
    ) {
      projects {
        __typename
        address
        contentId
        jrnId
        title
        creatorId
        contentUrl
        description
        endDate
        customerId
        customerInfo {
          __typename
          customerId
          managingCompanyId
          firstName
          lastName
          address
          email
          latitude
          longitude
          phoneNumber
          notes
          companyName
          qboCustomerId
        }
        qboCustomerId
        privacyLevel
        inviteCode
        allowedToEdit
        allowedToAdd
        allowedToView
        usersEverOnJrn
        startDate
        synced
        labels
        latitude
        longitude
        phoneNumber
        phoneNumberActive
        type
        lastMessageSent
        useTimetracking
        useTimetrackingQuestions
        timetrackingQuestions
        timetrackingQuestionsCheckout
        includeSubsInStats
        billable
        # REMOVEME: remove this when "bugets" field is deprecated
        budgets {
          ...BudgetFields
        }
        fromTemplate
        timetrackingType
      }
      users {
        userId
        username
        firstName
        lastName
        profilePic
        accessMode
        userType
      }
      adminData {
        projectId
        timetrackingTotal
        receiptAndBillTotal
        paymentTotal
        invoiceTotal
      }
      nextToken
      queryNote
    }
  }
`;
