export const createJrnInputMask = objectToMask => ({
  contentId: objectToMask.contentId,
  jrnId: objectToMask.jrnId,
  creatorId: objectToMask.creatorId,
  type: objectToMask.type,
  date: objectToMask.date,
  description: objectToMask.description,
  contentUrl: objectToMask.contentUrl,
  aspectRatio: objectToMask.aspectRatio,
  isPublic: objectToMask.isPublic || false,
  synced: objectToMask.synced,
  labels: objectToMask.labels,
  latitude: objectToMask.latitude,
  longitude: objectToMask.longitude,
  address: objectToMask.address,
  title: objectToMask.title,
  endDate: objectToMask.endDate,
  privacyLevel: objectToMask.privacyLevel,
  inviteCode: objectToMask.inviteCode,
  allowedToEdit: objectToMask.allowedToEdit,
  allowedToAdd: objectToMask.allowedToAdd,
  allowedToView: objectToMask.allowedToView,
  usersEverOnJrn: objectToMask.usersEverOnJrn,
  startDate: objectToMask.startDate,
  addedBy: objectToMask.addedBy,
  priority: objectToMask.priority,
  contentStatus: objectToMask.contentStatus,
  useTimetracking: objectToMask.useTimetracking,
  useTimetrackingQuestions: objectToMask.useTimetrackingQuestions,
  timetrackingQuestions: objectToMask.timetrackingQuestions,
  timetrackingQuestionsCheckout: objectToMask.timetrackingQuestionsCheckout,
  billable: objectToMask.billable,
  budgets: objectToMask.budgets,
  fromTemplate: objectToMask.fromTemplate,
  managingCompanyId: objectToMask.managingCompanyId,
  customerId: objectToMask.customerId,
  includeSubsInStats: objectToMask.includeSubsInStats,
  qboCustomerId: objectToMask.qboCustomerId,
  amount: objectToMask.amount || null,
  paymentTerms: objectToMask.paymentTerms,
  documentNumber: objectToMask.documentNumber,
  poNumber: objectToMask.poNumber,
  vendorId: objectToMask.vendorId || null,
});
