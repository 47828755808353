import gql from 'graphql-tag';

export default gql`
  mutation PinChatToConvosActions($projectId: String!, $action: String!) {
    pinChatToConvosActions(input: { projectId: $projectId, action: $action }) {
      __typename
      status
      msg
    }
  }
`;
