import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { Grid, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';

import { RFI_QUERY_TO_MODE } from '../../config/appDefaults';
import CommentInput from '../../components/ProjectContentListing/Comments/CommentInput';
import Comments from '../../components/ProjectContentListing/Comments/comments';
import {
  CreateCommentMutationAction,
  CreateInternalCommentMutationAction,
  GetInternalCommentsAction,
  GetCompanyCrewAction,
} from '../../graphql/graphql';

const useStyles = makeStyles(() => ({
  commentsWrapper: {
    paddingTop: 32,
  },
}));

const RfiEditFormComments = ({
  rfi,
  bookkeeperMode, // required for GetInternalCommentsAction

  // HOCs
  userInfo,
  companyCrew,
  internalCommentsLoading,
  onCreateInternalComment,
  onAddComment,
  internalComments,
}) => {
  const classes = useStyles();

  const [tabValue, setTabValue] = useState(0);

  const showingInternalComments = tabValue === 1;

  const thereAreComments = showingInternalComments
    ? internalComments?.length > 0
    : rfi.comments?.items.length > 0;

  const addInternalComment = comment => {
    onCreateInternalComment({
      comment: { ...comment, contentType: 'RFI' },
      itemType: 'RFI',
      itemId: rfi.requestId,
    });
  };

  const handleChangeIndex = (event, newValue) => {
    setTabValue(newValue);
  };

  const externalCommentsAsc = useMemo(() => {
    return _.reverse([...(rfi?.comments?.items || [])]);
  }, [rfi]);

  if (!rfi) {
    // Don't render anything if no RFI in context
    return null;
  }

  return (
    <Grid container direction="column" className={classes.commentsWrapper}>
      {bookkeeperMode && (
        <Tabs
          value={tabValue}
          onChange={handleChangeIndex}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Client Comments" />
          <Tab label="Internal Comments" />
        </Tabs>
      )}
      <div style={{ height: 16 }} />
      <CommentInput
        addCommentAction={
          showingInternalComments ? addInternalComment : onAddComment
        }
        contentAccessInfo={{
          contentType: 'rfi',
          mode: RFI_QUERY_TO_MODE[rfi.requestStatus],
        }}
        contentItem={{ contentId: rfi.requestId, type: 'rfi' }}
        userId={userInfo.userId}
        autoFocus={false}
      />
      <div style={{ height: 16 }} />
      {bookkeeperMode && internalCommentsLoading && (
        <div>Loading Internal Comments...</div>
      )}
      {thereAreComments ? (
        <Comments
          comments={
            bookkeeperMode && showingInternalComments
              ? internalComments
              : externalCommentsAsc
          }
          allUsers={companyCrew}
          dateAligment="right"
          bubbleColor="#e0e0e0"
        />
      ) : (
        <Grid item xs={12} style={{ padding: 24 }}>
          No comments yet.
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userInfo,
});

export default connect(mapStateToProps)(
  compose(
    CreateCommentMutationAction,
    CreateInternalCommentMutationAction,
    GetCompanyCrewAction,
    GetInternalCommentsAction
  )(RfiEditFormComments)
);
