import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';

const baseStyle = {
  borderRadius: 6,
  paddingTop: 6,
  paddingBottom: 6,
  paddingLeft: 10,
  paddingRight: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'center',
};

const ContentImageInfoBlock = ({
  primaryText,
  secondaryText,
  color,
  small,
  fullHeight,
  label,
  containerStyle,
}) => {
  const { styleToUse, textColorToUse } = useMemo(() => {
    const baseColor = color || 'rgba(0, 0, 0, 0.8)';
    const textColor = !label ? '#ffffff' : baseColor;

    const solidStyle = {
      ...baseStyle,
      backgroundColor: baseColor,
      height: fullHeight ? '100%' : 'unset',
    };

    const labelStyle = {
      ...baseStyle,
      backgroundColor: 'none',
      borderColor: baseColor,
      borderWidth: 1,
      borderStyle: 'solid',
      color: baseColor,
      height: fullHeight ? '100%' : 'unset',
    };

    return {
      styleToUse: { ...(label ? labelStyle : solidStyle), ...containerStyle },
      textColorToUse: textColor,
    };
  }, [color, fullHeight, label, containerStyle]);

  return (
    <div style={styleToUse}>
      {primaryText && small && (
        <Typography
          variant="body1"
          style={{
            color: textColorToUse,
            fontWeight: 'bold',
          }}
        >
          {primaryText.toUpperCase()}
        </Typography>
      )}
      {primaryText && !small && (
        <div style={{ fontSize: 20, color: textColorToUse }}>
          {primaryText.toUpperCase()}
        </div>
      )}
      {secondaryText && small && (
        <Typography variant="body1" style={{ color: textColorToUse }}>
          {secondaryText}
        </Typography>
      )}
      {secondaryText && !small && (
        <div style={{ fontSize: 16, color: textColorToUse }}>
          {secondaryText}
        </div>
      )}
    </div>
  );
};

export default ContentImageInfoBlock;
