import gql from 'graphql-tag';

export default gql`
  mutation UpdateJobCostAccounts(
    $companyId: String!
    $jobCostAccountUpdates: [UpdateJobCostAccountInput!]!
  ) {
    updateJobCostAccounts(
      input: {
        companyId: $companyId
        jobCostAccountUpdates: $jobCostAccountUpdates
      }
    ) {
      status
      msg
    }
  }
`;
