import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';

import Paper from '@material-ui/core/Paper';

// Helpers
import {
  monetaryRender,
  percentRender,
  fixedValueRender,
} from '../../../helpers/index';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    padding: 0,
  },
}));

const ProjectStatsDialog = ({ data, onClose }) => {
  const classes = useStyles();

  const createData = (name, payments, receiptsBills, labor) => {
    return { name, payments, receiptsBills, labor };
  };

  const rows = [
    createData(
      'Total Actual',
      monetaryRender({ value: data.actual.paymentsTotal }),
      monetaryRender({ value: data.actual.receiptsAndBillsTotal }),
      fixedValueRender({ value: data.actual.laborTotal })
    ),
    createData(
      'Total Budget',
      monetaryRender({ value: data.budget.paymentsTotal }),
      monetaryRender({ value: data.budget.receiptsAndBillsTotal }),
      fixedValueRender({ value: data.budget.laborTotal })
    ),
    createData(
      'Total Variance',
      monetaryRender({ value: data.variance.paymentsTotal }),
      monetaryRender({ value: data.variance.receiptsAndBillsTotal }),
      fixedValueRender({ value: data.variance.laborTotal })
    ),
    createData(
      '% Complete',
      percentRender({ value: data.percentCompleted.payments }),
      percentRender({ value: data.percentCompleted.receiptsAndBills }),
      percentRender({ value: data.percentCompleted.labor })
    ),

    createData(' '),

    createData(
      'Count (Actual)',
      data.count.paymentsActuals,
      data.count.receiptsAndBillsActuals,
      data.count.laborActuals
    ),
    createData(
      'Count (Budget)',
      data.count.paymentsBudgets,
      data.count.receiptsAndBillsBudgets,
      data.count.laborBudgets
    ),

    createData('  '),

    createData(
      'Average Actual',
      monetaryRender({ value: data.actual.paymentsTotalAvg }),
      monetaryRender({ value: data.actual.receiptsAndBillsTotalAvg }),
      fixedValueRender({ value: data.actual.laborTotalAvg })
    ),
    createData(
      'Average Budget',
      monetaryRender({ value: data.budget.paymentsTotalAvg }),
      monetaryRender({ value: data.budget.receiptsAndBillsTotalAvg }),
      fixedValueRender({ value: data.budget.laborTotalAvg })
    ),
    createData(
      'Average Variance',
      monetaryRender({ value: data.variance.paymentsTotalAvg }),
      monetaryRender({ value: data.variance.receiptsAndBillsTotalAvg }),
      fixedValueRender({ value: data.variance.laborTotalAvg })
    ),
  ];

  const closeModal = () => {
    onClose();
  };

  return (
    <Dialog
      open
      onClose={closeModal}
      aria-labelledby="data stats"
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <Paper className={classes.root}>
          <Table aria-label="project stats table">
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell align="center">Payments</TableCell>
                <TableCell align="center">Receipts/Bills</TableCell>
                <TableCell align="center">Labor (hrs)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">{row.payments}</TableCell>
                  <TableCell align="center">{row.receiptsBills}</TableCell>
                  <TableCell align="center">{row.labor}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectStatsDialog;
