import React from 'react';

import FirmProvider from '../../components/firm-dashboard/firm.context';
import FirmView from './firm.view';

const ProvidedFirmView = () => {
  return (
    <FirmProvider>
      <FirmView />
    </FirmProvider>
  );
};

export default ProvidedFirmView;
