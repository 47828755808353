import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  DialogActions,
  Checkbox,
  Typography,
} from '@material-ui/core';

import _ from 'lodash';

import FullScreenGallery from '../../components/full-screen-gallery/full-screen-gallery';

const ShoeboxDupesHandler = ({ dupes, allFiles, whatToSave }) => {
  const [itemsToKeep, setItemsToKeep] = useState(
    dupes.map(dupe => ({ ...dupe, keep: true }))
  );

  const handleSaving = () => {
    // filter out the items that are NOT in the itemsToKeep array from the allFiles array
    // and pass the result to the whatToSave function
    const itemsToSave = allFiles.filter(file => {
      const matchingItem = _.find(itemsToKeep, { uri: file.uri });
      if (!matchingItem) {
        // then it wasn't a problem
        return true;
      }
      if (matchingItem.keep) {
        // then we want to keep it
        return true;
      }
      return false;
    });
    whatToSave(itemsToSave);
  };

  const handleCancelling = () => {
    whatToSave([]);
  };

  const wrapThumbnails = thumbNail => {
    return (
      <Grid
        container
        direction="column"
        key={thumbNail.key}
        style={{ flexWrap: 'wrap', minWidth: '110px' }}
      >
        <Grid
          container
          item
          alignItems="center"
          justifyContent="center"
          style={{ borderBottom: '1px solid #666' }}
        >
          <Checkbox
            checked={itemsToKeep.some(item => {
              // find the item in the array and check its keep property
              return item.uri === thumbNail.key && item.keep;
            })}
            onChange={() => {
              const newItemsToKeep = itemsToKeep.map(item => {
                if (item.uri === thumbNail.key) {
                  return { ...item, keep: !item.keep };
                }
                return item;
              });
              setItemsToKeep(newItemsToKeep);
            }}
          />
        </Grid>
        <Grid item>{thumbNail}</Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open
      PaperProps={{ style: { minHeight: '80%' } }}
    >
      <DialogTitle>Possible Duplicates</DialogTitle>
      <DialogContent>
        <Typography variant="h4" style={{ marginBottom: 32 }}>
          Some items that you&apos;are attempting to upload are duplicates.
          Please select the items you want to keep.
        </Typography>
        <FullScreenGallery
          images={dupes}
          selectedItem={0}
          showThumbs
          thumbsActions={wrapThumbnails}
          thumbWidth={120}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelling} variant="outlined" color="primary">
          Cancel All
        </Button>
        <Button onClick={handleSaving} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShoeboxDupesHandler;
