import gql from 'graphql-tag';

export default gql`
  query GetAdminTimetrackingData(
    $companyId: String!
    $includeArchive: Boolean
    $nextToken: String
  ) {
    getAdminTimetrackingData(
      input: {
        companyId: $companyId
        includeArchive: $includeArchive
        nextToken: $nextToken
      }
    ) {
      items {
        contentId
        jrnId
        address
        aspectRatio
        assignedTo
        contentStatus
        contentUrl
        creatorId
        date
        dateCreated
        latitude
        longitude
        media {
          uri
          aspectRatio
        }
        timetrackingAnswers {
          questionId
          answer
        }
        timetrackingType
        type
      }
      projects {
        contentId
        jrnId
        address
        billable
        customerId
        endDate
        startDate
        title
        type
        timetrackingQuestions
        timetrackingQuestionsCheckout
      }
      users {
        userId
        username
        firstName
        lastName
        profilePic
        phoneNumber
        accessMode
        userType
        type
      }
      timetrackingQuestions {
        questionId
        questionType
        questionText
        answers
        isRequired
        managingCompanyId
        creatorId
      }
      customers {
        customerId
        firstName
        lastName
        companyName
      }
      nextToken
      queryNote
    }
  }
`;
