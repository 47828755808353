import React from 'react';
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  Typography,
  DialogTitle,
} from '@material-ui/core';

import { ErrorOutline as ErrorIcon } from '@material-ui/icons';

import _ from 'lodash';

import globalStyles from '../../../theme/palette';

const ValidationIssuesDialog = ({
  editingMode,
  validationIssues,
  open,
  setShowValidationIssuesDialog,
}) => {
  const closeDialog = () => {
    setShowValidationIssuesDialog({ open: false, issues: [] });
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Unable to {editingMode ? 'save' : 'create'}...</DialogTitle>
      <DialogContent>
        {_.map(validationIssues, (message, index) => {
          return (
            <Typography
              key={index}
              style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}
            >
              <ErrorIcon
                style={{
                  marginRight: 8,
                  color: globalStyles.brandColorError,
                }}
              />
              <span>{message}</span>
            </Typography>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ValidationIssuesDialog;
