import moment from 'moment';

import {
  CONTENT_TYPE,
  GLOBAL_EXPENSE_STATUS_LABEL,
  GLOBAL_EXPENSE_STATUS,
  DEFAULT_DATE_FORMAT,
} from '../../config/appDefaults';

import palette from '../../theme/palette';

export default ({ bill }) => {
  if (!bill || bill.type !== CONTENT_TYPE.GLOBAL_BILL) {
    return null;
  }

  if (bill.contentStatus === GLOBAL_EXPENSE_STATUS.PAID) {
    return {
      primaryText: GLOBAL_EXPENSE_STATUS_LABEL[bill.contentStatus],
      color: palette.brandColorGreen,
    };
  }

  if (bill.contentStatus === GLOBAL_EXPENSE_STATUS.OVER_PAID) {
    return {
      primaryText: GLOBAL_EXPENSE_STATUS_LABEL[bill.contentStatus],
      color: palette.brandColorError75,
    };
  }

  const billDueDate = moment(bill.endDate);
  if (billDueDate >= moment().startOf('day')) {
    let dateString;
    let color;
    if (
      billDueDate <
      moment()
        .startOf('day')
        .add(1, 'day')
    ) {
      // TODAY
      dateString = 'Today';
      color = palette.brandColorOrange75;
    } else if (
      billDueDate >=
        moment()
          .startOf('day')
          .add(1, 'day') &&
      billDueDate <
        moment()
          .startOf('day')
          .add(2, 'day')
    ) {
      // TOMORROW
      dateString = 'Tomorrow';
      color = palette.brandColorOrange75;
    } else {
      dateString = billDueDate.format(DEFAULT_DATE_FORMAT);
      color = palette.brandColorPrimary;
    }

    // DATE
    return {
      primaryText: dateString,
      secondaryText: 'due on',
      color,
    };
  }

  // OVERDUE
  return {
    primaryText: 'OVERDUE',
    color: palette.brandColorError75,
  };
};
