import React, { useState, useCallback } from 'react';
import {
  // makeStyles,
  withStyles,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';

import { styles } from './styles';

const ModalImageManipulator = props => {
  const {
    open,
    handleClose,
    contentStyle,
    passedImage,
    passbackManipulatedImage,
    classes,
  } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback((croppedArea, passedCroppedAreaPixels) => {
    setCroppedAreaPixels(passedCroppedAreaPixels);
  }, []);

  const passBackEditedImage = useCallback(async () => {
    try {
      const newlyCroppedImage = await getCroppedImg(
        passedImage,
        croppedAreaPixels,
        rotation,
        true
      );
      // console.log('donee', newlyCroppedImage);
      // setCroppedImage(newlyCroppedImage);
      passbackManipulatedImage(newlyCroppedImage);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [passedImage, croppedAreaPixels, rotation, passbackManipulatedImage]);

  // const onClose = useCallback(() => {
  //   setCroppedImage(null);
  // }, []);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
    >
      <MuiDialogContent dividers style={contentStyle}>
        <div className={classes.cropContainer}>
          <Cropper
            image={passedImage}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={1 / 1}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            // restrictPosition
          />
        </div>
        <div className={classes.controls}>
          <div className={classes.sliderContainer}>
            <Typography
              variant="overline"
              classes={{ root: classes.sliderLabel }}
            >
              Zoom
            </Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              className={classes.slider}
              onChange={(e, thisZoom) => setZoom(thisZoom)}
            />
          </div>
          {/* <div className={classes.sliderContainer}>
            <Typography
              variant="overline"
              classes={{ root: classes.sliderLabel }}
            >
              Rotation
            </Typography>
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={1}
              aria-labelledby="Rotation"
              // classes={{ container: classes.slider }}
              onChange={(e, rotation) => setRotation(rotation)}
            />
          </div> */}
        </div>
        {/* <img
          src={croppedImage ? URL.createObjectURL(croppedImage) : null}
          alt="profile"
          style={{ background: '#ccc', height: 150, width: 150 }}
        /> */}
      </MuiDialogContent>
      <MuiDialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button autoFocus onClick={passBackEditedImage} color="primary">
          Save changes
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};

const StyledModalImageManipulator = withStyles(styles)(ModalImageManipulator);

export default StyledModalImageManipulator;
