import gql from 'graphql-tag';

export default gql`
  query GetBreakevenCsvExport(
    $companyId: String
    $reportPeriod: String
    $accountingMethod: String
  ) {
    getBreakevenCsvExport(
      input: {
        companyId: $companyId
        reportPeriod: $reportPeriod
        accountingMethod: $accountingMethod
      }
    )
  }
`;
