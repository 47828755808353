import gql from 'graphql-tag';

export default gql`
  fragment RfiBaseFields on Rfi {
    managingCompanyId
    initialNotes
    clientNotes
    requestSource
    requestType
    requestId
    txnId
    txnType
    txnPayee
    txnDescription
    txnDate
    txnAccount
    txnAccountType
    bankAccount
    txnSpent {
      value
    }
    txnReceived {
      value
    }
    requestStatus
    projectId
    billList {
      contentId
      amount {
        value
      }
    }
    projectList {
      contentId
      amount {
        value
      }
    }
    idOfRelatedItem
    parentPath
    assignedTo
    media {
      uri
      aspectRatio
      type
    }
    dateCreated
    dateModified
    comments {
      items {
        content
        commentId
        dateCreated
        userId
      }
    }
  }
`;
