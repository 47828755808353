import gql from 'graphql-tag';

export default gql`
  mutation ApplyLaborRatesToSubprojects(
    $companyId: String!
    $contentId: String!
  ) {
    applyLaborRatesToSubprojects(
      input: { contentId: $contentId, companyId: $companyId }
    ) {
      status
      msg
    }
  }
`;
