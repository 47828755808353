import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import { getFullUserString } from '../../helpers';

const ConfirmUserAddition = ({ onCancel, onConfirm, userToAdd }) => {
  if (!userToAdd) {
    return null;
  }

  const fullUserString = getFullUserString(userToAdd);

  return (
    <Dialog maxWidth="sm" aria-labelledby="confirmation-dialog" open>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent dividers style={{ paddingTop: 20, paddingBottom: 20 }}>
        <Grid container>
          <Grid item>
            <Typography>
              <b>{fullUserString}</b> will be re-added to the company crew
              members and regain access to the company&apos;s data on Level.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onConfirm({ userToAdd });
          }}
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmUserAddition;
