import gql from 'graphql-tag';

export default gql`
  mutation CreateLove(
    $loveId: ID!
    $contentId: String!
    $userId: String!
    $synced: Boolean!
  ) {
    createLove(
      input: {
        loveId: $loveId
        contentId: $contentId
        userId: $userId
        synced: $synced
      }
    ) {
      __typename
      loveId
      contentId
      userId
      synced
    }
  }
`;
