import gql from 'graphql-tag';

export default gql`
  query CheckQboConnectionStatus($companyId: String!) {
    checkQboConnectionStatus(input: { companyId: $companyId }) {
      status
      msg
    }
  }
`;
