import React, { useState } from 'react';
import _ from 'lodash';
import {
  Typography,
  Grid,
  Button,
  InputLabel,
  Switch as MuiSwitch,
  Tooltip,
  InputAdornment,
} from '@material-ui/core';
import {
  Extension as ExtensionIcon,
  CheckCircle as CheckIcon,
  CancelOutlined as CancelOutlinedIcon,
  BrokenImage as ErrorIcon,
  HelpOutline as HelpOutlineIcon,
} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import * as Yup from 'yup';
import moment from 'moment';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { runAnalytics } from '../../../../helpers';
import { CONTENT_DEFINITION } from '../../../../config/appDefaults';
import LevelModal from '../../../../components/Modal/level-modal';
import LoadingCover from '../../../../components/LoadingCover/loadingCover';
import { typesAndSchema } from '../../../add-to-project/info-about-content';
import { SaveProjectAsTemplateAction } from '../../../../graphql/graphql';

const SUBMISSION_STATE = {
  PENDING: 0,
  SUCCESS: 1,
  ERRORED: 2,
};

const useStyles = makeStyles(theme => ({
  contentSection: {
    minHeight: 522,
  },
  explainerText: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    background: '#e0f3ff',
    borderRadius: 10,
  },
  submitButtonWrapper: {
    textAlign: 'right',
    marginTop: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: 40,
  },
  inlineLabel: {
    display: 'flex',
    'text-transform': 'capitalize',
    alignItems: 'center',
  },
  templateInclusionItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ccc',
    minHeight: 40,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  templateSubprojectItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 40,
  },
  templateSubprojectItemWrapper: {
    borderBottom: '1px solid #ccc',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  templateSubprojectItemDisclaimer: {
    color: theme.palette.brandColorOrange,
    marginTop: theme.spacing(1) * -1,
    marginBottom: theme.spacing(1),
  },
  otherContentNote: {
    padding: theme.spacing(2),
  },
  nonUploadIconWrapper: {
    color: theme.palette.brandColorPrimary,
    fontSize: 90,
  },
  successIconWrapper: {
    color: theme.palette.brandColorGreen,
    fontSize: 90,
  },
  errorIconWrapper: {
    color: theme.palette.brandColorMidGrey,
    fontSize: 90,
  },
  generalOptionsTitle: {
    color: theme.palette.brandColorPrimary,
  },
}));

const defaultContentToInclude = {};
_.forEach(CONTENT_DEFINITION, (value, key) => {
  defaultContentToInclude[key] = {
    ...value,
    contentType: key,
    includeInTemplate: false,
  };
});

const SaveAsTemplateModal = props => {
  const { projectInfo, handleClose, userInfo, onSaveProjectAsTemplate } = props;

  const [contentForTemplate, setContentForTemplate] = useState(
    defaultContentToInclude
  );
  const [template, setTemplate] = useState(null);
  const [submissionState, setSubmissionState] = useState(
    SUBMISSION_STATE.PENDING
  );

  const initialValues = {};

  initialValues.title = '';

  const handleFormSubmit = async formData => {
    const { title: templateName } = formData;
    const { contentId: projectId, startDate } = projectInfo;

    const contentTypesToInclude = [];
    _.forEach(contentForTemplate, (value, key) => {
      if (value.includeInTemplate && value.allowedInTemplate) {
        contentTypesToInclude.push(key);
      }
    });

    const startDateMidnight = moment(startDate)
      .startOf('day')
      .toISOString();

    try {
      const {
        data: { createTemplateFromProject: createdTemplate },
      } = await onSaveProjectAsTemplate({
        templateName,
        projectId,
        contentTypesToInclude,
        startDate: startDateMidnight,
      });

      // Add analytics
      runAnalytics('Contents', {
        contentAction: 'Add Content',
        projectId: createdTemplate.jrnId,
        userId: userInfo.userId,
        username: userInfo.username,
        email: userInfo.email,
        type: createdTemplate.type,
        contentId: createdTemplate.contentId,
        fromProject: projectId,
      });

      setTemplate(createdTemplate);
      setSubmissionState(SUBMISSION_STATE.SUCCESS);
    } catch (err) {
      setSubmissionState(SUBMISSION_STATE.ERRORED);
    }
  };

  const handleIncludeChange = contentType => {
    contentForTemplate[contentType].includeInTemplate = !contentForTemplate[
      contentType
    ].includeInTemplate;

    // Special case for image and gallery handling
    if (contentType === 'image') {
      contentForTemplate.gallery.includeInTemplate =
        contentForTemplate.image.includeInTemplate;
    }

    setContentForTemplate({ ...contentForTemplate });
  };

  const handleGoToTemplate = () => {
    if (template && template.contentId) {
      const { history } = props;
      history.push(`/projects/${template.contentId}`);
    }

    handleClose({ setQueryTo: 'templates' });
  };

  const classes = useStyles();

  const renderNa = () => {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ width: 58 }}
      >
        <Typography
          variant="body2"
          style={{ alignItems: 'center', display: 'flex' }}
        >
          n/a
        </Typography>
      </Grid>
    );
  };

  const renderHelpIcon = (tooltipTitle = 'Not Available') => {
    return (
      <Tooltip title={tooltipTitle}>
        <Typography
          variant="body2"
          style={{
            alignItems: 'center',
            display: 'flex',
            paddingLeft: 4,
            paddingRight: 4,
          }}
        >
          <HelpOutlineIcon fontSize="small" color="inherit" />
        </Typography>
      </Tooltip>
    );
  };

  const renderSubprojectOption = () => {
    const {
      project: { pluralName, contentType, includeInTemplate },
    } = contentForTemplate;
    return (
      <Grid
        item
        container
        xs={12}
        className={classes.templateSubprojectItemWrapper}
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          className={classes.templateSubprojectItem}
        >
          <Grid item>
            <InputLabel className={classes.inlineLabel}>
              {pluralName}
            </InputLabel>
          </Grid>
          <Grid item>
            <MuiSwitch
              checked={includeInTemplate}
              variant="inline"
              onChange={() => handleIncludeChange(contentType)}
            />
          </Grid>
        </Grid>
        {includeInTemplate && (
          <Grid item className={classes.templateSubprojectItemDisclaimer}>
            <Typography variant="overline" style={{ color: 'inherit' }}>
              The content selections made below will be applied to all
              sub-projects as well.
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderTypes = () => {
    const specialNotes = {
      task: 'Task statuses will be set to incomplete',
    };

    return _.sortBy(
      _.values(contentForTemplate),
      contentTypeForTemplate => contentTypeForTemplate.pluralName
    )
      .filter(
        ({ allowedOnProjects, contentType, allowedInTemplate }) =>
          !allowedOnProjects && contentType !== 'project' && allowedInTemplate
      )
      .map(
        ({ pluralName, contentType, includeInTemplate, allowedInTemplate }) => (
          <Grid
            item
            xs={12}
            sm={12}
            container
            className={classes.templateInclusionItem}
            key={contentType}
          >
            <Grid item>
              <InputLabel className={classes.inlineLabel}>
                {pluralName}
                {!allowedInTemplate &&
                  renderHelpIcon('Not included in templates')}
                {allowedInTemplate &&
                  specialNotes[contentType] &&
                  renderHelpIcon(specialNotes[contentType])}
              </InputLabel>
            </Grid>
            <Grid item>
              {allowedInTemplate ? (
                <MuiSwitch
                  checked={includeInTemplate}
                  variant="inline"
                  onChange={() => handleIncludeChange(contentType)}
                />
              ) : (
                renderNa()
              )}
            </Grid>
          </Grid>
        )
      );
  };

  if (!projectInfo || !contentForTemplate) return null;

  const createYupSchema = (schema, config) => {
    // schema is the accumulator of the reduce function that is being added to
    const schemaObj = schema;

    const { fieldName, validationType, validations = [] } = config;

    if (!Yup[validationType]) {
      return schemaObj;
    }

    let validator = Yup[validationType]();
    validations.forEach(validation => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });
    schemaObj[fieldName] = validator;

    return schemaObj;
  };

  // Create validation schema
  const projectFromTemplateSchema = typesAndSchema.projectFromTemplate;
  const yupSchema = projectFromTemplateSchema.reduce(createYupSchema, {});
  const validationSchema = Yup.object().shape(yupSchema);

  const renderModalContent = ({ values, handleSubmit, isSubmitting }) => {
    switch (submissionState) {
      case SUBMISSION_STATE.PENDING: {
        return (
          <>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={8}>
                <Typography
                  variant="subtitle1"
                  className={classes.explainerText}
                >
                  Don&apos;t build the same project twice! Templates help you
                  create consistency, save time, and reduce rework from job to
                  job.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  name="title"
                  label="Template Title"
                  placeholder="e.g. Kitchen Renovation Template"
                  value={values.title}
                  type="text"
                  component={TextField}
                  disabled={false}
                  fullWidth
                  InputProps={{
                    style: { height: 50 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <ExtensionIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item container xs={12} sm={8}>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 32,
                    marginBottom: 16,
                  }}
                >
                  <Typography variant="h4">
                    What content would you like to include in this template?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {renderSubprojectOption()}
                </Grid>
                <Grid item xs={12}>
                  {renderTypes()}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    container
                    className={classes.otherContentNote}
                    justifyContent="center"
                  >
                    <Grid item>
                      <Typography variant="body1">
                        Note: Receipts, check ins/outs, and shifts are not
                        included in templates.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.submitButtonWrapper} xs={12} sm={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Grid>
          </>
        );
      }
      case SUBMISSION_STATE.SUCCESS: {
        return (
          <Grid
            container
            direction="column"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item className={classes.successIconWrapper}>
              <CheckIcon fontSize="inherit" color="inherit" />
            </Grid>
            <Grid item>
              <Typography variant="h4">
                Your template was created successfully!
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: 20 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleGoToTemplate}
              >
                Go to Template
              </Button>
            </Grid>
          </Grid>
        );
      }
      case SUBMISSION_STATE.ERRORED: {
        return (
          <Grid
            container
            direction="column"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item className={classes.errorIconWrapper}>
              <ErrorIcon fontSize="inherit" color="inherit" />
            </Grid>
            <Grid item>
              <Typography variant="h4">
                Sorry! An error occurred while creating your template.
              </Typography>
            </Grid>
          </Grid>
        );
      }
      default:
        return null;
    }
  };

  return (
    <LevelModal open handleClose={handleClose}>
      <IconButton onClick={handleClose} className={classes.closeButton}>
        <CancelOutlinedIcon fontSize="inherit" color="primary" />
      </IconButton>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {formikProps => {
          const { values, handleSubmit, isSubmitting } = formikProps;

          return (
            <Form>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item>
                    <Typography variant="h3" align="center" color="primary">
                      Save Project as Template
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" style={{ marginTop: 2 }}>
                      &nbsp;- create a copy of this project by saving it as a
                      template.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    className={classes.contentSection}
                  >
                    {renderModalContent({
                      handleSubmit,
                      values,
                      isSubmitting,
                    })}
                  </Grid>
                </Grid>
              </Grid>
              {isSubmitting && (
                <LoadingCover>
                  <Typography variant="h3" align="center">
                    Saving...
                  </Typography>
                </LoadingCover>
              )}
            </Form>
          );
        }}
      </Formik>
    </LevelModal>
  );
};

const mapStateToProps = state => {
  return {
    userInfo: state.userInfo,
  };
};

export default withRouter(
  compose(SaveProjectAsTemplateAction)(
    connect(mapStateToProps)(SaveAsTemplateModal)
  )
);
