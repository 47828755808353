import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const InvitationRedirect = ({ location, dispatch, currentAuth }) => {
  const qsParams = new URLSearchParams(location.search);
  const invitationKey = qsParams.get('key');

  if (invitationKey) {
    dispatch({
      type: 'COMPANY_REGISTRATION_KEY',
      payload: invitationKey,
    });
  }

  if (currentAuth.isLoggedIn) {
    dispatch({
      type: 'SHOW_ERROR_INVITE_MODAL',
      payload: true,
    });
    return <Redirect to="/" />;
  }

  const path = `/auth/invitation/${invitationKey}`;

  return <Redirect to={path} />;
};

const mapStateToProps = state => ({
  currentAuth: state.currentAuth,
});

export default withRouter(connect(mapStateToProps)(InvitationRedirect));
