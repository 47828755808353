import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import _ from 'lodash';

const styles = {
  actionButtons: {
    padding: 16,
    marginHorizontal: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  questionListItem: {
    padding: 8,
    margin: 6,
  },
  redText: {
    color: 'red',
  },
  questionFieldLabel: {
    marginBottom: 3,
  },
  iconBase: {
    color: '#ccc',
  },
  successIcon: {
    color: 'green',
  },
};

const AnswersDialog = props => {
  const {
    open,
    handleDone,
    handleClose,
    questions,
    existingAnswers,
    title,
  } = props;

  const [answersMap, setAnswersMap] = React.useState({});

  useEffect(() => {
    const map = {};
    if (!_.isEmpty(questions)) {
      const existingAnswersMap = _.keyBy(existingAnswers, 'questionId');
      _.forEach(questions, question => {
        const existingAnswer = existingAnswersMap[question.questionId];
        if (existingAnswer) {
          map[question.questionId] = existingAnswer.answer;
        }
      });
    }
    setAnswersMap(map);
  }, [existingAnswers, questions]);

  const [requiredAnswerMissing, setRequiredAnswerMissing] = React.useState(
    false
  );

  const isARequiredNotFilled = () => {
    let requiredNotFilled = false;
    questions.forEach(question => {
      if (!requiredNotFilled) {
        // short circuit if a required answer is already missed
        if (question.isRequired) {
          // check if this question's answer is required
          if (
            !answersMap[question.questionId] ||
            (question.questionType === 'checkbox' &&
              answersMap[question.questionId] === 'false')
          ) {
            // if this questions answer is required but is falsey (empty string, no dropdown selection or checkbox not checked)
            requiredNotFilled = true;
          }
        }
      }
    });
    return requiredNotFilled;
  };

  const handleSubmit = async () => {
    if (isARequiredNotFilled()) {
      setRequiredAnswerMissing(true);
      return;
    }

    // gather answers and go back to project edit page
    const answers = [];
    _.forEach(questions, question => {
      answers.push({
        questionId: question.questionId,
        answer: answersMap[question.questionId] || null,
      });
    });
    handleDone(answers);
  };

  const handleQuestionAnswer = ({ value, questionId }) => {
    setAnswersMap(currentState => {
      return { ...currentState, [questionId]: value };
    });
  };

  const renderQuestions = () => {
    if (!questions) {
      return <span>There are no questions.</span>;
    }
    return questions.map((question, index) => {
      const renderQuestionText = () => (
        <div style={styles.questionFieldLabel}>
          {index + 1}. {question.questionText}
          {question.isRequired && <span style={styles.redText}>*</span>}
        </div>
      );
      return (
        <Grid style={styles.questionListItem} key={question.questionId}>
          {question.questionType === 'text' && (
            <>
              {renderQuestionText()}
              <Grid style={styles.questionField}>
                <TextField
                  value={answersMap[question.questionId] || ''}
                  onChange={event => {
                    const { value } = event.target;
                    handleQuestionAnswer({
                      value,
                      questionId: question.questionId,
                    });
                  }}
                  fullWidth
                  style={{ background: '#eee' }}
                />
              </Grid>
            </>
          )}
          {question.questionType === 'dropdown' && (
            <>
              {renderQuestionText()}
              <TextField
                select
                value={answersMap[question.questionId] || ''}
                onChange={event => {
                  const { value } = event.target;
                  handleQuestionAnswer({
                    value,
                    questionId: question.questionId,
                  });
                }}
                fullWidth
                style={{ background: '#eee' }}
              >
                {question.answers.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </>
          )}
          {question.questionType === 'checkbox' && (
            <Grid
              container
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Grid item style={{ flex: 1 }}>
                {renderQuestionText()}
              </Grid>
              <Grid item>
                <Checkbox
                  checked={answersMap[question.questionId] === 'true'}
                  onChange={event => {
                    const value = event.target.checked;
                    handleQuestionAnswer({
                      value: value.toString(),
                      questionId: question.questionId,
                    });
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      );
    });
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason);
        }
      }}
      disableEscapeKeyDown
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {questions && questions.length > 0 ? (
          renderQuestions()
        ) : (
          <span>No questions are listed for this project.</span>
        )}
      </DialogContent>
      <DialogActions>
        {requiredAnswerMissing && (
          <span>
            Please fill in all required (<span style={styles.redText}>*</span>)
            answers
          </span>
        )}
        <Button onClick={handleClose} color="primary" autoFocus>
          Cancel
        </Button>
        {questions && questions.length > 0 && (
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            autoFocus
          >
            Done
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AnswersDialog;
