import gql from 'graphql-tag';

export default gql`
  query ListMyTasks($userId: String!) {
    listMyTasks(input: { userId: $userId }) {
      items {
        __typename
        contentId
        jrnId
        creatorId
        type
        startDate
        endDate
        title
        description
        labels
        contentStatus
        useTimetracking
        useTimetrackingQuestions
        timetrackingQuestions
        timetrackingQuestionsCheckout
        timetrackingType
      }
    }
  }
`;
