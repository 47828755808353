import gql from 'graphql-tag';

export default gql`
  mutation SignOutAllDevices {
    signOutAllDevices {
      status
      msg
    }
  }
`;
