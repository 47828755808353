import React from 'react';
// eslint-disable-next-line import/no-cycle
import AccountListItem from './project-profitability-report-account-list-item';

const AccountList = ({ categoryType, accountData }) => {
  return accountData.map((account, i) => {
    return (
      <AccountListItem
        categoryType={categoryType}
        key={account.accountId || i}
        {...account}
      />
    );
  });
};

export default AccountList;
