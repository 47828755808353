import React from 'react';
import { Tooltip } from '@material-ui/core';
import TooltippedButton from './tooltipped-button';
import TooltippedIconButton from './tooltipped-icon-button';

const ButtonWithTooltip = ({
  disabled,
  iconButton,
  onClick,
  tooltipText,
  ...other
}) => {
  const adjustedButtonProps = {
    disabled,
    component: disabled ? 'div' : undefined,
    onClick: disabled ? undefined : onClick,
  };

  let buttonComponent;
  if (iconButton) {
    buttonComponent = (
      <TooltippedIconButton
        color={disabled ? 'default' : 'primary'}
        {...other}
        {...adjustedButtonProps}
      />
    );
  } else {
    buttonComponent = (
      <TooltippedButton
        variant="outlined"
        color={disabled ? 'inherit' : 'primary'}
        {...other}
        {...adjustedButtonProps}
      />
    );
  }

  // If no tooltip text, just show the button
  if (!tooltipText) {
    return buttonComponent;
  }

  // Otherwise support the tool tip
  return <Tooltip title={tooltipText}>{buttonComponent}</Tooltip>;
};

export default ButtonWithTooltip;
