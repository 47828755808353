import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';

import AppleStoreImage from '../../assets/images/download-app-store.png';
import PlayStoreImage from '../../assets/images/download-google-play.png';
import LevelLogo from '../../components/level-logo/level-logo';
import { useStyles } from './onboarding.styles';
import palette from '../../theme/palette';

const OnboardingFinalMobileStep = ({ userInfo }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} style={{ marginTop: 16, marginBottom: 10 }}>
        <LevelLogo className={classes.smallLevelLogo} />
        <Typography
          style={{
            color: palette.brandColorPrimary,
            marginBottom: 10,
          }}
        >
          You&apos;ve successfully signed up to start using Level! <br />
          Let&apos;s get you logged in.
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ flexDirection: 'row' }}>
        <Typography style={{ fontSize: 18, fontWeight: 600 }}>
          {userInfo.username}
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigator.clipboard.writeText(userInfo.username);
            }}
            style={{ marginLeft: 16 }}
          >
            Copy
          </Button>
        </Typography>
      </Grid>

      <Typography
        style={{
          padding: 16,
          paddingTop: 8,
          marginBottom: 20,
          textAlign: 'left',
          fontStyle: 'italic',
        }}
      >
        You&apos;ll need your username and password to login, so copy your
        username using the &quot;copy&quot; button above.
      </Typography>
      <Grid
        item
        xs={12}
        style={{
          background: palette.brandColorLightGrey,
          marginBottom: 15,
          padding: 20,
        }}
      >
        <Typography style={{ fontWeight: 600, marginBottom: 10 }}>
          If you already have the Level app on your phone, tap below to open it:
        </Typography>
        <Button
          color="primary"
          variant="contained"
          style={{ width: '60%' }}
          onClick={() => {
            window.location.href = 'checkthelevel://';
          }}
        >
          Open Level
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 600, marginBottom: 5 }}>
          If you don&apos;t already have Level installed, tap below to download
          it:
        </Typography>
        <a href="https://apps.apple.com/ca/app/level-check-the-level/id1469742990?ls=1">
          <img
            src={AppleStoreImage}
            alt="App-Store-Logo.png"
            style={{ padding: 5, width: 250 }}
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.ajrninc.level">
          <img
            src={PlayStoreImage}
            alt="Android-Store-Logo.png"
            style={{ padding: 5, width: 250 }}
          />
        </a>
      </Grid>
    </>
  );
};

export default OnboardingFinalMobileStep;
