import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Content}
  query ListContentByType(
    $companyId: String!
    $contentTypes: [String]!
    $first: Int
    $after: String
    $archived: Boolean
  ) {
    listContentByType(
      input: {
        companyId: $companyId
        contentTypes: $contentTypes
        first: $first
        after: $after
        archived: $archived
      }
    ) {
      items {
        ...ContentBaseFields
      }
    }
  }
`;
