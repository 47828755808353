import React, { useEffect, useMemo } from 'react';
import { compose, withApollo } from 'react-apollo';
import _ from 'lodash';
import uuid from 'uuid';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';

import GetCompanyQuestions from '../../../graphql/queries/get-company-questions';

import LoadingCover from '../../../components/LoadingCover/loadingCover';
import SearchQuestionsDialog from './search-questions-dialog';
import Question from './question';
import BasicDialog from '../../../components/basic-dialog/basic-dialog';

const CLOCK_IN_QUESTIONS_TAB = 0;
const CLOCK_OUT_QUESTIONS_TAB = 1;

const QuestionsDialog = ({
  open,
  projectInfo,
  existingQuestions,
  handleClose,
  handleDone,
  allUsers,
  client,
  managingCompanyInfo,
}) => {
  const [tabValue, setTabValue] = React.useState(CLOCK_IN_QUESTIONS_TAB);
  const [questionIdsToShow, setQuestionIdsToShow] = React.useState({});
  const [loadingQuestions, setLoadingQuestions] = React.useState(false);
  const [issueDialogInfo, setIssueDialogInfo] = React.useState({ open: false });
  const [companyQuestions, setCompanyQuestions] = React.useState([]);
  const [searchedQuestions, setSearchedQuestions] = React.useState([]);
  const [
    openSearchQuestionsDialog,
    setOpenSearchQuestionsDialog,
  ] = React.useState(false);
  const [questionMap, setQuestionMap] = React.useState({});

  const allUsersMap = useMemo(() => {
    return allUsers ? _.keyBy(allUsers, 'userId') : {};
  }, [allUsers]);

  useEffect(() => {
    if (existingQuestions) {
      setQuestionMap(_.keyBy(existingQuestions, 'questionId'));
    }
  }, [existingQuestions]);

  useEffect(() => {
    if (projectInfo) {
      const checkinQuestionIds = projectInfo.timetrackingQuestions || [];
      const checkoutQuestionIds =
        projectInfo.timetrackingQuestionsCheckout || [];

      setQuestionIdsToShow({
        [CLOCK_IN_QUESTIONS_TAB]: checkinQuestionIds,
        [CLOCK_OUT_QUESTIONS_TAB]: checkoutQuestionIds,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAddExistingQuestion = async () => {
    setLoadingQuestions(true);
    let originalCompanyQuestions = companyQuestions;

    // get the questions from the company if we don't already have them
    if (_.isEmpty(originalCompanyQuestions)) {
      if (managingCompanyInfo.managingCompanyId) {
        try {
          const questionsResponse = await client.query({
            query: GetCompanyQuestions,
            variables: { companyId: managingCompanyInfo.managingCompanyId },
            fetchPolicy: 'no-cache',
          });

          originalCompanyQuestions = _.get(
            questionsResponse,
            'data.getCompanyQuestions.items'
          );
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('getCompanyQuestions query e: ', e);
        }
      }

      setCompanyQuestions(originalCompanyQuestions);
    }

    const companyQuestionsMap = _.keyBy(originalCompanyQuestions, 'questionId');
    const allQuestions = [...originalCompanyQuestions];

    // add locally created questions to the list so they can be shared between check-in and check-out tabs
    _.forEach(questionMap, (question, questionId) => {
      if (question.change === 'new' && !companyQuestionsMap[questionId]) {
        allQuestions.push(question);
        companyQuestionsMap[questionId] = question;
      }
    });

    setSearchedQuestions(allQuestions);
    setLoadingQuestions(false);
    setOpenSearchQuestionsDialog(true);
  };

  const emptyQuestion = () => ({
    questionId: uuid(),
    questionText: '',
    questionType: 'text',
    isRequired: false,
  });

  const handleAddNewQuestion = () => {
    const newQuestion = { ...emptyQuestion(), change: 'new' };

    setQuestionMap(currentState => {
      return {
        ...currentState,
        [newQuestion.questionId]: newQuestion,
      };
    });

    setQuestionIdsToShow(currentState => {
      const currentQuestions = _.get(currentState, tabValue, []);
      return {
        ...currentState,
        [tabValue]: [...currentQuestions, newQuestion.questionId],
      };
    });
  };

  const handleQuestionUpdate = newQuestionInfo => {
    const questionToSave = { ...newQuestionInfo };
    if (!questionToSave.change) {
      questionToSave.change = 'edit'; // set to edit unless it's already set (to 'edit' or 'new')
    }
    setQuestionMap(currentState => {
      return {
        ...currentState,
        [questionToSave.questionId]: questionToSave,
      };
    });
  };

  const handleQuestionDelete = questionToDelete => {
    setQuestionIdsToShow(currentState => {
      return {
        ...currentState,
        [tabValue]: _.filter(
          currentState[tabValue],
          id => questionToDelete.questionId !== id
        ),
      };
    });

    setQuestionMap(currentState => {
      const otherTabValue =
        tabValue === CLOCK_IN_QUESTIONS_TAB
          ? CLOCK_OUT_QUESTIONS_TAB
          : CLOCK_IN_QUESTIONS_TAB;

      const map = { ...currentState };

      // Remove the question from the questionMap if it's not being used in the other tab
      if (
        !questionIdsToShow[otherTabValue].includes(questionToDelete.questionId)
      ) {
        delete map[questionToDelete.questionId];
      }
      return map;
    });
  };

  const handleSearchQuestionsDialogDone = updatedInfo => {
    setQuestionIdsToShow(currentState => {
      return {
        ...currentState,
        [tabValue]: updatedInfo.questionIds,
      };
    });
    setQuestionMap(updatedInfo.questionMap);
    setOpenSearchQuestionsDialog(false);
  };

  const closeIssueDialog = () => {
    setIssueDialogInfo({
      ...issueDialogInfo,
      open: false,
    });
  };

  const handleDoneClick = () => {
    let textLablesAreFine = true;
    let dropDownsAreFine = true;
    const allQuestions = [];
    const allQuestionIds = _.uniq([
      ...questionIdsToShow[CLOCK_IN_QUESTIONS_TAB],
      ...questionIdsToShow[CLOCK_OUT_QUESTIONS_TAB],
    ]);
    allQuestionIds.forEach(questionId => {
      const question = _.get(questionMap, questionId, {});
      allQuestions.push(question);
      // make sure all questions have text in the question part
      if (!question.questionText) {
        textLablesAreFine = false;
      }
      // make sure all drop downs have answers
      if (question.questionType === 'dropdown') {
        if (!question.answers || !question.answers.length) {
          dropDownsAreFine = false;
        }
      }
    });
    if (textLablesAreFine && dropDownsAreFine) {
      handleDone({
        allQuestions,
        checkinQuestionIds: questionIdsToShow[CLOCK_IN_QUESTIONS_TAB],
        checkoutQuestionIds: questionIdsToShow[CLOCK_OUT_QUESTIONS_TAB],
      });
    } else {
      // trigger some messaging
      let message =
        'Question text cannot be empty and drop downs must have at least 1 option provided.';
      const bothBad = !textLablesAreFine && !dropDownsAreFine;
      if (!bothBad) {
        if (!textLablesAreFine) {
          message = 'Question text cannot be empty.';
        } else {
          message = 'Drop downs must have at least 1 option provided.';
        }
      }
      setIssueDialogInfo({
        ...issueDialogInfo,
        open: true,
        title: 'Heads up...',
        message,
      });
    }
  };

  const renderListOfQuestions = () => {
    return questionIdsToShow[tabValue].map((questionId, index) => {
      const question = questionMap[questionId];

      return question ? (
        <Question
          question={question}
          index={index}
          key={question.questionId}
          onUpdate={handleQuestionUpdate}
          onDelete={handleQuestionDelete}
        />
      ) : null;
    });
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason);
        }
      }}
      disableEscapeKeyDown
    >
      <DialogTitle id="alert-dialog-title">
        <Tabs
          variant="fullWidth"
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          onChange={handleTabChange}
        >
          <Tab label="Clock-in Questions" />
          <Tab label="Clock-out Questions" />
        </Tabs>
      </DialogTitle>
      <DialogContent>
        {questionIdsToShow[tabValue] &&
          questionIdsToShow[tabValue].length > 0 &&
          renderListOfQuestions()}
        {questionIdsToShow[tabValue] &&
          questionIdsToShow[tabValue].length === 0 && (
            <Grid item style={{ marginBottom: 10 }}>
              This project has no clock-
              {tabValue === CLOCK_IN_QUESTIONS_TAB ? 'in' : 'out'} questions.
            </Grid>
          )}
        <Button onClick={handleAddNewQuestion}>+ Add a new question</Button>
        <Button
          style={{ marginLeft: 10 }}
          onClick={handleAddExistingQuestion}
          disabled={loadingQuestions}
        >
          + Add existing question
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Cancel
        </Button>
        <Button
          onClick={handleDoneClick}
          variant="contained"
          color="primary"
          autoFocus
        >
          Done
        </Button>
      </DialogActions>
      {openSearchQuestionsDialog && (
        <SearchQuestionsDialog
          open={openSearchQuestionsDialog}
          projectInfo={projectInfo}
          searchedQuestions={searchedQuestions}
          handleDone={handleSearchQuestionsDialogDone}
          currentQuestionIds={questionIdsToShow[tabValue]}
          currentQuestionMap={questionMap}
          handleClose={() => {
            setOpenSearchQuestionsDialog(false);
          }}
          allUsersMap={allUsersMap}
        />
      )}

      {loadingQuestions && <LoadingCover />}

      {issueDialogInfo.open && (
        <BasicDialog
          open={issueDialogInfo.open}
          title={issueDialogInfo.title}
          handleClose={closeIssueDialog}
          customChildren
        >
          <Typography>{issueDialogInfo.message}</Typography>
        </BasicDialog>
      )}
    </Dialog>
  );
};

function mapStateToProps(state) {
  return {
    managingCompanyInfo: state.appState.managingCompanyInfo || {},
  };
}

export default compose(withApollo)(connect(mapStateToProps)(QuestionsDialog));
