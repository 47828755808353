import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ArchiveIcon from '@material-ui/icons/Archive';
import Button from '@material-ui/core/Button';
import CreatableSelect from 'react-select/creatable';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import LoadingCover from '../../../components/LoadingCover/loadingCover';

const useStyles = makeStyles(theme => ({
  questionListItem: {
    boxShadow: '0px 1px 4px 0px #ccc',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingLeft: 0,
    position: 'relative',
  },
  topWrapper: {
    paddingLeft: 16,
    paddingRight: 8,
  },
  questionLabel: {
    flex: 1,
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
  },
  questionActions: {
    flex: 0,
    flexWrap: 'nowrap',
  },
  questionTypeAndCheck: {
    color: 'grey',
    display: 'flex',
    alignItems: 'center',
  },
  questionDetails: {
    padding: theme.spacing(2),
  },
  editingWarning: {
    backgroundColor: '#ffa9a9',
    padding: 16,
  },
}));

const customStyles = {
  control: provided => ({
    ...provided,
    border: 0,
    outline: 0,
    borderRadius: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
  }),
};

const questionTypes = ['text', 'checkbox', 'dropdown'];

const Question = props => {
  const {
    question,
    index,
    fromSearch = null,
    isOnProject,
    fromAdminView,
    questionSaving,
    onDelete,
    onUpdate,
    onSelect,
    onDeselect,
    onArchive,
    onSubmit,
  } = props;

  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [localQuestion, setLocalQuestion] = useState(question);
  const [selectQuestion, setSelectQuestion] = useState(false);
  const [localOriginalQuestion] = useState(question);

  useEffect(() => {
    // Use this timeout to prevent the parent component from re-rendering every keystroke
    const timer = setTimeout(() => {
      if (!localQuestion) return;

      // on each change of the question, pass the info back to the parent
      if (localQuestion !== question) {
        onUpdate(localQuestion);
      }
    }, 500);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localQuestion]);

  const handleDeleteClick = () => {
    onDelete(question);
  };

  const handleArchiveClick = event => {
    event.stopPropagation();
    event.preventDefault();
    onArchive(question);
  };

  const handleOptionChange = options => {
    const updatedOptions = options || [];
    setLocalQuestion({
      ...localQuestion,
      answers: updatedOptions.map(option => option.value),
    });
  };

  const handleQuestionTextChange = event => {
    const text = event.target.value;
    setLocalQuestion({ ...localQuestion, questionText: text });
  };

  const handleQuestionTypeChange = event => {
    const type = event.target.value;
    setLocalQuestion({ ...localQuestion, questionType: type });
  };

  const handleRequiredChange = event => {
    setLocalQuestion({ ...localQuestion, isRequired: event.target.checked });
  };

  const handleSelectQuestion = event => {
    const isChecked = event.target.checked;
    setSelectQuestion(isChecked);
    if (isChecked) {
      onSelect(question);
    } else {
      onDeselect(question);
    }
  };

  const answersList = localQuestion.answers || [];

  const decideIsEditing = () => {
    if (!isEditing && !fromSearch) {
      setIsEditing(true);
    }
  };

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const showEditButton =
    (question.change !== 'new' && !fromAdminView) ||
    (question.change !== 'new' && !isEditing && fromAdminView);
  const showDeleteButton =
    !fromAdminView || (fromAdminView && question.change === 'new');

  const emptyText = localQuestion.questionText.trim() === '';
  const textChanged =
    localOriginalQuestion.questionText !== localQuestion.questionText;
  const toggleChanged =
    localOriginalQuestion.isRequired !== localQuestion.isRequired;
  const optionsChanged =
    localOriginalQuestion.answers !== localQuestion.answers;
  const enableSave =
    (textChanged && !emptyText) ||
    (toggleChanged && !emptyText) ||
    optionsChanged;

  return (
    <Grid
      container
      item
      key={question.questionId}
      className={classes.questionListItem}
      onClick={decideIsEditing}
    >
      <Grid item container className={classes.topWrapper}>
        <Grid
          item
          container
          alignItems="center"
          className={classes.questionLabel}
        >
          {index + 1}. {localQuestion.questionText}
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-end"
          alignItems="center"
          className={classes.questionActions}
        >
          {fromSearch ? (
            <Grid item className={classes.questionTypeAndCheck}>
              <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
                Type: {localQuestion.questionType}
              </Typography>
              <Checkbox
                checked={isOnProject || selectQuestion}
                onChange={handleSelectQuestion}
                disabled={isOnProject}
              />
            </Grid>
          ) : (
            <>
              {fromAdminView && (
                <Typography
                  variant="body2"
                  style={{ whiteSpace: 'nowrap', marginRight: 8 }}
                >
                  Type: {localQuestion.questionType}
                </Typography>
              )}
              {showEditButton && (
                <Tooltip title="Edit question">
                  <IconButton onClick={toggleIsEditing} size="medium">
                    <CreateIcon />
                  </IconButton>
                </Tooltip>
              )}

              {showDeleteButton && (
                <Tooltip title="Remove from project">
                  <IconButton onClick={handleDeleteClick} size="medium">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}

              {fromAdminView && (
                <Tooltip title="Archive question">
                  <IconButton onClick={handleArchiveClick} size="medium">
                    <ArchiveIcon />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </Grid>
        {(isEditing || question.change === 'new') && (
          <Grid
            item
            xs={12}
            container
            alignItems="center"
            className={classes.questionDetails}
            spacing={2}
          >
            <Grid item xs={9}>
              <TextField
                label="Question"
                value={localQuestion.questionText}
                onChange={handleQuestionTextChange}
                helperText="What do want to ask?"
                error={!localQuestion.questionText}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                label="Type (not changeable once set)"
                value={localQuestion.questionType}
                onChange={handleQuestionTypeChange}
                helperText="What type of question is it?"
                fullWidth
                disabled={localQuestion.change !== 'new'}
              >
                {questionTypes.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {localQuestion.questionType === 'dropdown' && (
              <Grid item xs={6}>
                <InputLabel>Answer Options:</InputLabel>
                <CreatableSelect
                  components={{
                    DropdownIndicator: null,
                  }}
                  styles={customStyles}
                  isMulti
                  name="options"
                  value={answersList.map(answer => ({
                    label: answer,
                    value: answer,
                  }))}
                  onChange={handleOptionChange}
                  placeholder="Answer options..."
                  menuPlacement="auto"
                />
                <FormHelperText>
                  Type an option, hit enter. Repeat.
                </FormHelperText>
                {(!localQuestion.answers || !localQuestion.answers.length) && (
                  <FormHelperText error>
                    At least 1 option must be provided.
                  </FormHelperText>
                )}
              </Grid>
            )}
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={localQuestion.isRequired}
                    onChange={handleRequiredChange}
                    name="isRequired"
                    color="primary"
                  />
                }
                label="Is this answer required?"
                labelPlacement="start"
              />
            </Grid>
            {fromAdminView && (
              <Grid item container justifyContent="flex-end">
                <Button
                  variant="contained"
                  onClick={() => {
                    setLocalQuestion(localOriginalQuestion);
                    setIsEditing(false);
                  }}
                  style={{ marginRight: 10 }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!enableSave}
                  onClick={async () => {
                    await onSubmit(question);
                    setIsEditing(false);
                  }}
                >
                  Save
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>

      {isEditing && question.change !== 'new' && !fromAdminView && (
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          className={classes.editingWarning}
        >
          <Grid item>
            Please note that editing a question will change it for all projects
            it is used on. Changing the text will also change it for past
            answers.
            <br />
            <br /> If this is not what you wanted, please create a new question
            instead.
          </Grid>
        </Grid>
      )}
      {questionSaving === question.questionId && (
        <LoadingCover>
          <Typography variant="h3" align="center">
            Saving...
          </Typography>
        </LoadingCover>
      )}
    </Grid>
  );
};

export default Question;
