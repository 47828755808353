import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Select as MuiSelect,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
  Dashboard as DashboardIcon,
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Save as SaveIcon,
} from '@material-ui/icons';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import _ from 'lodash';
import moment from 'moment';
import omitDeep from 'omit-deep';

import {
  GetCompanyCrewAdminAction,
  GetCompanyCustomersAction,
  GetCompanyInfoAction,
  ListCompanyProjectsAction,
  ListScoreboardSettingsAction,
  UpdateScoreboardSettingsAction,
} from '../../../graphql/graphql';
import GetCompanyAdminProjectData from '../../../graphql/queries/get-company-admin-project-data';

import BillableHoursMultipleSelect from './billable-hours-multiple-select';
import TimezoneSelect from '../../timezone-select/timezone-select';

import { getFullUserString, getUserFullName } from '../../../helpers';
import {
  BILLABLE_HOURS_GROUP_BY,
  REPORTING_PERIOD,
  BILLABLE_HOURS_PRESET_FILTERS,
  BILLABLE_HOURS_PRESET_FILTER_OPTIONS,
  CREW_DEPARTMENT_OPTIONS,
  SCOREBOARD_NAME,
  TOP_PROJECT_ID,
} from '../../../config/appDefaults';
import LoadingCover from '../../LoadingCover/loadingCover';
import InputContentLoader from '../../input-content-loader/input-content-loader';
import { useRepetitiveQuery } from '../../../hooks';
import palette from '../../../theme/palette';

const CREW_OPTIONAL_FILTERS = [
  {
    label: 'Member Type',
    nonFilterValue: 'All', // default value
    keyToFilter: 'isActiveMember',
    options: [
      { value: 'All', key: 'All', displayValue: 'All' },
      {
        value: true,
        key: 'Active',
        displayValue: 'Active',
      },
      {
        value: false,
        key: 'Removed',
        displayValue: 'Removed',
      },
    ],
  },
];

const PROJECT_OPTIONAL_FILTERS = [
  {
    label: 'Project Type',
    nonFilterValue: 'All', // default value
    keyToFilter: 'isBillableProject',
    options: [
      { value: 'All', key: 'All', displayValue: 'All' },
      {
        value: true,
        key: 'Billable',
        displayValue: 'Billable',
      },
      {
        value: false,
        key: 'Non-Billable',
        displayValue: 'Non-Billable',
      },
    ],
  },
  {
    label: 'Project Status',
    nonFilterValue: 'All', // default value
    keyToFilter: 'isActiveProject',
    options: [
      { value: 'All', key: 'All', displayValue: 'All' },
      {
        value: true,
        key: 'active',
        displayValue: 'Active',
      },
      {
        value: false,
        key: 'archived',
        displayValue: 'Archived',
      },
    ],
  },
];

const useStyles = makeStyles(theme => {
  return {
    filtersWrapper: {
      display: 'flex',
      width: '100%',
      height: '100%',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    paper: {
      height: '100%',
      width: '100%',
      padding: 24,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    formControl: {
      minWidth: 120,
    },
    updateButton: {
      marginTop: theme.spacing(2),
      minWidth: 150,
    },
  };
});

const BillableHoursFilters = ({
  companyCrew,
  companyCustomers,
  companyProjects,
  managingCompanyInfo,
  companies,
  scoreboardSettings,
  loading,
  onFiltersDataLoaded = () => {},
  onFiltersUpdate,
  onUpdateScoreboardSettings,
}) => {
  const classes = useStyles();

  const [selectedSavedFilter, setSelectedSavedFilter] = useState(
    BILLABLE_HOURS_PRESET_FILTERS.THIS_WEEK
  );
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [selectedGroupBy, setSelectedGroupBy] = useState(
    BILLABLE_HOURS_GROUP_BY.DAY.value
  );

  const [isFilterSettingsChanged, setIsFilterSettingsChanged] = useState(false);

  const [reportPeriodOptions, setReportPeriodOptions] = useState([]);
  const [selectedReportPeriod, setSelectedReportPeriod] = useState(
    REPORTING_PERIOD.THIS_WEEK.value
  );
  const [customPeriodStartDate, setCustomPeriodStartDate] = useState(null);
  const [customPeriodEndDate, setCustomPeriodEndDate] = useState(null);
  const [maxCustomEndDate, setMaxCustomEndDate] = useState(null);

  const [companyCrewOptions, setCompanyCrewOptions] = useState([]);
  const [companyCustomerOptions, setCompanyCustomerOptions] = useState([]);

  const [selectedCrewIdsMap, setSelectedCrewIdsMap] = useState({});
  const [selectedCustomerIdsMap, setSelectedCustomerIdsMap] = useState({});
  const [selectedProjectIdsMap, setSelectedProjectIdsMap] = useState({});

  const [crewMenuOpen, setCrewMenuOpen] = useState(false);
  const [customerMenuOpen, setCustomerMenuOpen] = useState(false);
  const [projectMenuOpen, setProjectMenuOpen] = useState(false);

  const [showCreateFilterDialog, setShowCreateFilterDialog] = useState({
    open: false,
    editingFilter: null,
    filterToSave: null,
  });
  const [newFilterName, setNewFilterName] = useState('');
  const [error, setError] = useState({
    newFilterName: '',
    customPeriodStartDate: '',
    customPeriodEndDate: '',
  });
  const [savingOrDeleteFilter, setSavingOrDeleteFilter] = useState(false);

  const [
    showDeleteFilterOptionDialog,
    setShowDeleteFilterOptionDialog,
  ] = useState({
    open: false,
    filterToDelete: null,
  });

  const closeDeleteFilterOptionDialog = () => {
    setShowDeleteFilterOptionDialog({
      open: false,
      filterToDelete: null,
    });
  };

  const [
    billableHoursScoreboardSettings,
    setBillableHoursScoreboardSettings,
  ] = useState({});

  const closeCreateFilterDialog = () => {
    setShowCreateFilterDialog({
      open: false,
      editingFilter: null,
      filterToSave: null,
    });
    setNewFilterName('');
  };

  const companyInfo = useMemo(() => {
    const foundCompany = companies
      ? _.find(companies, {
          companyId: managingCompanyInfo.managingCompanyId,
        })
      : null;
    return foundCompany;
  }, [companies, managingCompanyInfo]);

  const activeCrewMap = useMemo(() => {
    const map = {};
    if (companyInfo) {
      const activeUserIds = [...companyInfo.admins, ...companyInfo.users];
      _.forEach(activeUserIds, userId => {
        map[userId] = true;
      });
    }
    return map;
  }, [companyInfo]);

  // Use this check to see if the filter is a preset filter
  const presetFilterMap = useMemo(() => {
    const map = {};
    _.forEach(BILLABLE_HOURS_PRESET_FILTER_OPTIONS, option => {
      map[option.key] = true;
    });
    return map;
  }, []);

  const savedFilterOptions = useMemo(() => {
    const options = [];
    if (scoreboardSettings) {
      // Find settings for scoreboard
      const settings = _.find(
        scoreboardSettings,
        ({ scoreboardName }) =>
          scoreboardName === SCOREBOARD_NAME.BILLABLE_HOURS
      );

      if (settings) {
        _.sortBy(settings.billableHoursFilters, 'uniqueName').forEach(
          (filter, index) => {
            options.push({
              key: index,
              value: filter.uniqueName,
              displayValue: filter.uniqueName,
              filter,
            });
          }
        );

        setBillableHoursScoreboardSettings(settings);
      }
    }
    return options;
  }, [scoreboardSettings]);

  // Populate the company crew options
  useEffect(() => {
    const options = [];
    if (companyCrew && _.isEmpty(companyCrewOptions)) {
      const sortedCrew = _.orderBy(
        companyCrew,
        [crewMember => crewMember.username.toLowerCase()],
        ['asc']
      );

      const selectedIds = {};
      _.forEach(sortedCrew, crewMember => {
        const departmentInfo = _.find(CREW_DEPARTMENT_OPTIONS, {
          value: crewMember.department,
        });
        options.push({
          value: crewMember.userId,
          key: crewMember.userId,
          displayValue: getFullUserString(crewMember),
          secondaryDisplayValue: departmentInfo
            ? departmentInfo.label
            : 'Unassigned Department',
          avatarUrl: crewMember.profilePic,
          isActiveMember: !!activeCrewMap[crewMember.userId],
          tooltip: !activeCrewMap[crewMember.userId]
            ? 'Removed From Active Crew'
            : '',
        });
        selectedIds[crewMember.userId] = true;
      });

      setCompanyCrewOptions(options);

      // if the crew menu is not opened, all crew members are selected
      if (!crewMenuOpen) {
        setSelectedCrewIdsMap(selectedIds);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCrew]);

  // Populate the company customer options
  useEffect(() => {
    const options = [
      {
        value: null,
        key: 'unassigned',
        displayValue: 'Unassigned',
      },
    ];

    if (companyCustomers && _.isEmpty(companyCustomerOptions)) {
      const sortedCustomers = _.orderBy(
        companyCustomers,
        [
          customer =>
            (getUserFullName(customer) || customer.companyName)?.toLowerCase(),
        ],
        ['asc']
      );

      const selectedIds = { null: true };
      _.forEach(sortedCustomers, customer => {
        options.push({
          value: customer.customerId,
          key: customer.customerId,
          displayValue: getUserFullName(customer) || customer.companyName,
          secondaryDisplayValue: customer.address,
        });
        selectedIds[customer.customerId] = true;
      });

      setCompanyCustomerOptions(options);

      // if customer menu is not opened, all customers are selected
      if (!customerMenuOpen) {
        setSelectedCustomerIdsMap(selectedIds);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCustomers]);

  const getCompanyAdminProjectDataAccessor = 'getCompanyAdminProjectData';
  const companyAdminProjectDataQuery = useRepetitiveQuery(
    GetCompanyAdminProjectData,
    {
      skip: !managingCompanyInfo.managingCompanyId,
      variables: {
        companyId: managingCompanyInfo.managingCompanyId,
        includeArchive: true,
      },
      fetchPolicy: 'cache-and-network',
      accessor: getCompanyAdminProjectDataAccessor,
    }
  );

  const allProjects = _.get(
    companyAdminProjectDataQuery,
    `data.${getCompanyAdminProjectDataAccessor}.projects`,
    []
  );

  const projectIdToCustomerIdMap = useMemo(() => {
    const customerMap = {};
    const allProjectsMap = _.keyBy(allProjects, 'contentId');

    const addCustomerIdToMap = project => {
      if (project.customerId) {
        // If has customer info, add to maps
        customerMap[project.contentId] = project.customerId;

        return project.customerId;
      }

      if (project.jrnId && project.jrnId !== TOP_PROJECT_ID) {
        // If is subproject recursively search for parent then determine if it should have a customer
        const parentProject = allProjectsMap[project.jrnId];

        const parentCustomerId = addCustomerIdToMap(parentProject);

        customerMap[project.contentId] = parentCustomerId;

        return parentCustomerId;
      }

      // If not a subproject and no customer info, there is no customer
      customerMap[project.contentId] = null;
      return null;
    };

    _.forEach(allProjects, project => {
      addCustomerIdToMap(project);
    });

    return customerMap;
  }, [allProjects]);

  const projectIdToPathNameMap = useMemo(() => {
    const pathNameMap = {};
    const allProjectsMap = _.keyBy(allProjects, 'contentId');

    _.forEach(allProjects, project => {
      // gather parent info and pass it back
      const titleArray = [];
      const buildPathInfo = checkThisProject => {
        // put this item in the path
        titleArray.push(checkThisProject.title);
        // if it has a parent, find the parent and run it through the function again
        const parentId = checkThisProject.jrnId;
        if (parentId && parentId !== TOP_PROJECT_ID) {
          if (allProjectsMap[parentId]) {
            buildPathInfo(allProjectsMap[parentId]);
          }
        }
      };
      buildPathInfo(project);

      pathNameMap[project.contentId] = titleArray.reverse().join(' > ');
    });

    return pathNameMap;
  }, [allProjects]);

  const activeProjectMap = useMemo(() => {
    const activeMap = {};
    const allProjectsMap = _.keyBy(allProjects, 'contentId');

    _.forEach(companyProjects, project => {
      activeMap[project.contentId] = true;
    });

    const addProjectStatusToMap = project => {
      if (activeMap[project.contentId]) {
        return true;
      }

      if (project.jrnId && project.jrnId !== TOP_PROJECT_ID) {
        // If is subproject recursively search for parent then determine the status
        const parentProject = allProjectsMap[project.jrnId];

        const parentProjectStatus = addProjectStatusToMap(parentProject);
        activeMap[project.contentId] = parentProjectStatus;

        return parentProjectStatus;
      }

      activeMap[project.contentId] = false;
      return false;
    };

    _.forEach(allProjects, project => {
      addProjectStatusToMap(project);
    });

    return activeMap;
  }, [allProjects, companyProjects]);

  const companyProjectOptions = useMemo(() => {
    const options = [];

    if (!_.isEmpty(allProjects)) {
      let filteredProjects = [...allProjects];

      // filter projects by selected crews
      filteredProjects = _.filter(filteredProjects, project => {
        return _.some(
          project.usersEverOnJrn,
          userId => selectedCrewIdsMap[userId]
        );
      });

      // filter projects by selected customers
      filteredProjects = _.filter(filteredProjects, project => {
        const customerId = projectIdToCustomerIdMap[project.contentId];
        return selectedCustomerIdsMap[customerId];
      });

      const sortedProjects = _.orderBy(
        filteredProjects,
        [project => projectIdToPathNameMap[project.contentId].toLowerCase()],
        ['asc']
      );

      const allSelectedIds = {};
      _.forEach(sortedProjects, project => {
        options.push({
          value: project.contentId,
          key: project.contentId,
          displayValue: projectIdToPathNameMap[project.contentId],
          secondaryDisplayValue:
            project.billable === false
              ? 'Non-Billable Project'
              : 'Billable Project',
          avatarUrl: project.contentUrl,
          checkboxColor:
            project.billable === false
              ? palette.brandColorError
              : palette.brandColorGreen,
          isActiveProject: activeProjectMap[project.contentId],
          isBillableProject: project.billable !== false,
          tooltip: !activeProjectMap[project.contentId]
            ? 'Archived Project'
            : '',
        });
        allSelectedIds[project.contentId] = true;
      });

      // If the project menu is not open, set the selected projects Ids
      if (!projectMenuOpen) {
        setSelectedProjectIdsMap(() => {
          // if a preset filter selected, select all projects by default
          if (presetFilterMap[selectedSavedFilter]) {
            return allSelectedIds;
          }

          // if a custom filter is selected, make sure selected projects are in available project options
          const filterOption = _.find(savedFilterOptions, {
            value: selectedSavedFilter,
          });

          // if projectIds is null, it means all projects are selected
          if (
            filterOption &&
            filterOption.filter &&
            !filterOption.filter.projectIds
          ) {
            return allSelectedIds;
          }

          const selectedIdsFromSavedFilter = {};
          _.forEach(filterOption.filter.projectIds, projectId => {
            selectedIdsFromSavedFilter[projectId] = true;
          });

          _.keys(selectedIdsFromSavedFilter).forEach(projectId => {
            if (!allSelectedIds[projectId]) {
              delete selectedIdsFromSavedFilter[projectId];
            }
          });
          return selectedIdsFromSavedFilter;
        });
      }
    }
    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allProjects,
    presetFilterMap,
    projectIdToCustomerIdMap,
    projectIdToPathNameMap,
    selectedCrewIdsMap,
    selectedCustomerIdsMap,
  ]);

  // Update report period options when selectedGroupBy changes
  useEffect(() => {
    const groupBy = _.find(BILLABLE_HOURS_GROUP_BY, { value: selectedGroupBy });

    if (
      _.findIndex(groupBy.availablePeriods, { value: selectedReportPeriod }) ===
      -1
    ) {
      setSelectedReportPeriod(groupBy.availablePeriods[0].value);
    }

    setReportPeriodOptions(groupBy.availablePeriods);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupBy]);

  useEffect(() => {
    if (customPeriodStartDate) {
      // There is a max of 14 columns in the report, so we need to set max end date accordingly
      let whatToAdd;
      let numberToAdd;
      switch (selectedGroupBy) {
        case BILLABLE_HOURS_GROUP_BY.DAY.value:
          whatToAdd = 'days';
          numberToAdd = 14;
          break;
        case BILLABLE_HOURS_GROUP_BY.WEEK.value:
          whatToAdd = 'weeks';
          numberToAdd = 13;
          break;
        case BILLABLE_HOURS_GROUP_BY.MONTH.value:
          whatToAdd = 'months';
          numberToAdd = 13;
          break;
        case BILLABLE_HOURS_GROUP_BY.QUARTER.value:
          whatToAdd = 'quarters';
          numberToAdd = 13;
          break;
        case BILLABLE_HOURS_GROUP_BY.YEAR.value:
          whatToAdd = 'years';
          numberToAdd = 13;
          break;
        default:
          whatToAdd = 'days';
          numberToAdd = 14;
          break;
      }
      const maxEndDate = moment.min(
        moment(customPeriodStartDate)
          .add(numberToAdd, whatToAdd)
          .subtract(1, 'days'),
        moment()
      );

      setMaxCustomEndDate(maxEndDate);

      // if the end date is after the max end date, set it to the max end date
      if (customPeriodEndDate) {
        if (moment(customPeriodEndDate).isAfter(maxEndDate)) {
          setCustomPeriodEndDate(maxEndDate);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customPeriodStartDate, selectedGroupBy]);

  useEffect(() => {
    if (
      companyCrew &&
      companyCustomers &&
      !_.isEmpty(projectIdToPathNameMap) &&
      !_.isEmpty(projectIdToCustomerIdMap)
    ) {
      onFiltersDataLoaded({
        companyCustomers,
        companyCrew,
        projectIdToCustomerIdMap,
        projectIdToPathNameMap,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companyCustomers,
    companyCrew,
    projectIdToCustomerIdMap,
    projectIdToPathNameMap,
  ]);

  const stringToDate = str => {
    const date = new Date(str);
    date.setFullYear(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    );
    return date;
  };

  const dateToString = date => {
    return moment(date).format('YYYY-MM-DD');
  };

  const getFilterSettings = () => {
    const filters = {
      groupBy: selectedGroupBy,
      timezone: selectedTimezone,
      reportPeriod: selectedReportPeriod,
    };

    if (selectedReportPeriod === REPORTING_PERIOD.CUSTOM.value) {
      if (!customPeriodStartDate) {
        setError(currentState => ({
          ...currentState,
          customPeriodStartDate: 'Start date is required',
        }));
        return null;
      }
      if (!customPeriodEndDate) {
        setError(currentState => ({
          ...currentState,
          customPeriodEndDate: 'End date is required',
        }));
        return null;
      }
      filters.customPeriodStartDate = dateToString(customPeriodStartDate);
      filters.customPeriodEndDate = dateToString(customPeriodEndDate);
    }

    filters.userIds = _.keys(_.pickBy(selectedCrewIdsMap));
    if (filters.userIds.length === companyCrewOptions.length) {
      // set userIds to null if all crew members are selected
      filters.userIds = null;
    }

    filters.customerIds = _.keys(_.pickBy(selectedCustomerIdsMap)).map(
      customerId => {
        if (customerId === 'null') {
          // make sure null is a null value and not a string
          return null;
        }
        return customerId;
      }
    );
    if (filters.customerIds.length === companyCustomerOptions.length) {
      // set customerIds to null if all customers are selected
      filters.customerIds = null;
    }

    filters.projectIds = _.keys(_.pickBy(selectedProjectIdsMap));
    if (filters.projectIds.length === companyProjectOptions.length) {
      // set projectIds to null if all projects are selected
      filters.projectIds = null;
    }

    return filters;
  };

  const handleSaveButtonClick = () => {
    const filterToSave = getFilterSettings();

    if (!filterToSave) {
      return;
    }

    if (presetFilterMap[selectedSavedFilter]) {
      setNewFilterName('');
      setShowCreateFilterDialog({
        open: true,
        editingFilter: null,
        filterToSave,
      });
    } else {
      setNewFilterName(selectedSavedFilter);
      setShowCreateFilterDialog({
        open: true,
        editingFilter: selectedSavedFilter,
        filterToSave,
      });
    }
  };

  const handleSavedFilterChange = event => {
    const filterName = event.target.value;
    if (!filterName) {
      return;
    }
    setSelectedSavedFilter(filterName);

    // reset the filter settings changed flag
    setIsFilterSettingsChanged(false);

    const allOptions = [
      ...BILLABLE_HOURS_PRESET_FILTER_OPTIONS,
      ...savedFilterOptions,
    ];
    const filterOption = _.find(allOptions, { value: filterName });
    if (filterOption && filterOption.filter) {
      const { filter } = filterOption;
      setSelectedGroupBy(filter.groupBy);
      setSelectedReportPeriod(filter.reportPeriod);

      const timezone = filter.timezone
        ? filter.timezone
        : Intl.DateTimeFormat().resolvedOptions().timeZone;
      setSelectedTimezone(timezone);

      if (filter.reportPeriod === REPORTING_PERIOD.CUSTOM.value) {
        setCustomPeriodStartDate(stringToDate(filter.customPeriodStartDate));
        setCustomPeriodEndDate(stringToDate(filter.customPeriodEndDate));
      } else {
        setCustomPeriodStartDate(null);
        setCustomPeriodEndDate(null);
      }

      const crewIdsMap = {};
      if (filter.userIds) {
        _.forEach(filter.userIds, userId => {
          crewIdsMap[userId] = true;
        });
      } else {
        // select all crew members by default
        _.forEach(companyCrewOptions, option => {
          crewIdsMap[option.value] = true;
        });
      }
      setSelectedCrewIdsMap(crewIdsMap);

      const customerIdsMap = {};
      if (filter.customerIds) {
        _.forEach(filter.customerIds, customerId => {
          customerIdsMap[customerId] = true;
        });
      } else {
        // select all customers by default
        _.forEach(companyCustomerOptions, option => {
          customerIdsMap[option.value] = true;
        });
      }
      setSelectedCustomerIdsMap(customerIdsMap);

      // NOTE: We do NOT need to care about projectIds
      // because they are handled in the memo above (companyProjectOptions)
    }
  };

  const handleGroupByChange = event => {
    setSelectedGroupBy(event.target.value);
    setIsFilterSettingsChanged(true);
  };

  const handleReportPeriodChange = event => {
    setSelectedReportPeriod(event.target.value);
    setIsFilterSettingsChanged(true);
  };

  const handleCustomStartDateChange = date => {
    setError(currentState => ({
      ...currentState,
      customPeriodStartDate: '',
    }));
    setCustomPeriodStartDate(date);
    setIsFilterSettingsChanged(true);
  };

  const handleCustomEndDateChange = date => {
    setError(currentState => ({
      ...currentState,
      customPeriodEndDate: '',
    }));
    setCustomPeriodEndDate(date);
    setIsFilterSettingsChanged(true);
  };

  const updateBillableHoursScoreboardSettings = async billableHoursFilters => {
    const cleanBillableHoursFilters = omitDeep(
      _.cloneDeep(billableHoursFilters),
      ['__typename']
    );

    const sortedFilters = _.sortBy(cleanBillableHoursFilters, 'uniqueName');

    await onUpdateScoreboardSettings([
      {
        scoreboardName: SCOREBOARD_NAME.BILLABLE_HOURS,
        billableHoursFilters: sortedFilters,
      },
    ]);
  };

  const handleAddorUpdateSavedFilterOption = async () => {
    setSavingOrDeleteFilter(true);
    if (!newFilterName) {
      setError(currentState => ({
        ...currentState,
        newFilterName: 'Filter name is required',
      }));
      setSavingOrDeleteFilter(false);
      return;
    }

    const billableHoursFilters = _.cloneDeep(
      billableHoursScoreboardSettings.billableHoursFilters || []
    );

    if (newFilterName !== showCreateFilterDialog.editingFilter) {
      // check if filter name already exists
      const existingFilter = _.find(billableHoursFilters, {
        uniqueName: newFilterName,
      });
      if (existingFilter) {
        // filter name already exists
        setError(currentState => ({
          ...currentState,
          newFilterName: 'Filter name already exists',
        }));
        setSavingOrDeleteFilter(false);
        return;
      }
    }

    // find existing filter
    const index = _.findIndex(billableHoursFilters, {
      uniqueName: showCreateFilterDialog.editingFilter,
    });

    const { filterToSave } = showCreateFilterDialog;

    if (index >= 0) {
      billableHoursFilters[index] = {
        ...filterToSave,
        uniqueName: newFilterName,
      };
    } else {
      billableHoursFilters.push({
        ...filterToSave,
        uniqueName: newFilterName,
      });
    }

    await updateBillableHoursScoreboardSettings(billableHoursFilters);

    setSelectedSavedFilter(newFilterName);
    setSavingOrDeleteFilter(false);

    closeCreateFilterDialog();

    // reset the filter settings changed flag
    setIsFilterSettingsChanged(false);

    // refresh the chart
    onFiltersUpdate({ ...filterToSave, uniqueName: newFilterName });
  };

  const handleUpdateButtonClick = () => {
    const payload = getFilterSettings();

    if (!payload) {
      return;
    }

    // if filter is not a preset filter, then add the unique name
    if (!presetFilterMap[selectedSavedFilter]) {
      payload.uniqueName = selectedSavedFilter;
    }

    onFiltersUpdate(payload);
  };

  const handleDeleteSavedFilterOptionClick = optionValue => () => {
    setShowDeleteFilterOptionDialog({
      open: true,
      filterToDelete: optionValue,
    });
  };

  const handleDeleteSavedFilterOption = async () => {
    setSavingOrDeleteFilter(true);
    let billableHoursFilters = _.cloneDeep(
      billableHoursScoreboardSettings.billableHoursFilters || []
    );

    if (_.isEmpty(billableHoursFilters)) {
      setSavingOrDeleteFilter(false);
      return;
    }

    const { filterToDelete } = showDeleteFilterOptionDialog;

    billableHoursFilters = _.filter(
      billableHoursFilters,
      filter => filter.uniqueName !== filterToDelete
    );

    await updateBillableHoursScoreboardSettings(billableHoursFilters);

    setSavingOrDeleteFilter(false);

    closeDeleteFilterOptionDialog();
  };

  return (
    <>
      <div className={classes.filtersWrapper}>
        <Paper elevation={4} className={classes.paper}>
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">
                <b>Filters</b>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<SaveIcon />}
                size="small"
                disabled={loading}
                onClick={handleSaveButtonClick}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing={1}>
            <Grid item xs={12}>
              <InputContentLoader
                loading={loading || savingOrDeleteFilter}
                label="Saved Filter"
              >
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="billable-hours-select-saved-filter-label">
                    Saved Filter
                  </InputLabel>
                  <MuiSelect
                    value={selectedSavedFilter}
                    onChange={handleSavedFilterChange}
                    // override the renderValue function to hide the delete button
                    renderValue={value => {
                      // Displayed blank if preset filter is selected and its settings have been changed
                      if (isFilterSettingsChanged && presetFilterMap[value]) {
                        return '';
                      }

                      const allOptions = [
                        ...BILLABLE_HOURS_PRESET_FILTER_OPTIONS,
                        ...savedFilterOptions,
                      ];
                      const option = _.find(allOptions, { value });
                      return option?.displayValue;
                    }}
                  >
                    <ListSubheader>Preset filters</ListSubheader>
                    {_.map(BILLABLE_HOURS_PRESET_FILTER_OPTIONS, option => (
                      <MenuItem key={option.key} value={option.value}>
                        {option.displayValue}
                      </MenuItem>
                    ))}
                    {!_.isEmpty(savedFilterOptions) && (
                      <ListSubheader>Your filters</ListSubheader>
                    )}
                    {_.map(
                      savedFilterOptions,
                      ({ key, value, displayValue }) => (
                        <MenuItem key={key} value={value}>
                          <ListItemText>{displayValue}</ListItemText>
                          <ListItemSecondaryAction>
                            <Tooltip
                              title={
                                value !== selectedSavedFilter
                                  ? 'Click to delete this filter'
                                  : 'Cannot delete the active filter'
                              }
                            >
                              <span>
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  disabled={value === selectedSavedFilter}
                                  onClick={handleDeleteSavedFilterOptionClick(
                                    value
                                  )}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </MenuItem>
                      )
                    )}
                  </MuiSelect>
                  {isFilterSettingsChanged &&
                    presetFilterMap[selectedSavedFilter] && (
                      <FormHelperText>
                        Click Save to create a new saved filter!
                      </FormHelperText>
                    )}
                  {isFilterSettingsChanged &&
                    !presetFilterMap[selectedSavedFilter] && (
                      <FormHelperText>
                        Filter settings are changed. Click Save to save it!
                      </FormHelperText>
                    )}
                </FormControl>
              </InputContentLoader>
            </Grid>
            <Grid item xs={6}>
              <InputContentLoader
                loading={loading || savingOrDeleteFilter}
                label="Group By"
              >
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="billable-hours-select-group-by-label">
                    Group By
                  </InputLabel>
                  <MuiSelect
                    value={selectedGroupBy}
                    onChange={handleGroupByChange}
                  >
                    {_.map(
                      BILLABLE_HOURS_GROUP_BY,
                      ({ key, displayValue, value }) => (
                        <MenuItem key={key} value={value}>
                          {displayValue}
                        </MenuItem>
                      )
                    )}
                  </MuiSelect>
                </FormControl>
              </InputContentLoader>
            </Grid>
            {!_.isEmpty(reportPeriodOptions) && (
              <Grid item xs={6}>
                <InputContentLoader
                  loading={loading || savingOrDeleteFilter}
                  label="Report Period"
                >
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel id="billable-hours-select-report-period-label">
                      Report Period
                    </InputLabel>
                    <MuiSelect
                      value={selectedReportPeriod}
                      onChange={handleReportPeriodChange}
                    >
                      {_.map(
                        reportPeriodOptions,
                        ({ key, displayValue, value }) => (
                          <MenuItem key={key} value={value}>
                            {displayValue}
                          </MenuItem>
                        )
                      )}
                    </MuiSelect>
                  </FormControl>
                </InputContentLoader>
              </Grid>
            )}
            {selectedReportPeriod === 'Custom' && (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid item container justifyContent="space-between" spacing={1}>
                  <Grid item xs={6}>
                    <InputContentLoader
                      loading={loading || savingOrDeleteFilter}
                      label="Start Date"
                    >
                      <DatePicker
                        label="Start Date"
                        variant="inline"
                        value={customPeriodStartDate}
                        format="MMM D, YYYY"
                        onChange={handleCustomStartDateChange}
                        autoOk
                        maxDate={new Date()}
                        fullWidth
                        error={!!error.customPeriodStartDate}
                        helperText={error.customPeriodStartDate}
                      />
                    </InputContentLoader>
                  </Grid>
                  <Grid item xs={6}>
                    <InputContentLoader
                      loading={loading || savingOrDeleteFilter}
                      label="End Date"
                    >
                      <DatePicker
                        label="End Date"
                        variant="inline"
                        value={customPeriodEndDate}
                        format="MMM D, YYYY"
                        onChange={handleCustomEndDateChange}
                        autoOk
                        minDate={customPeriodStartDate}
                        maxDate={maxCustomEndDate}
                        fullWidth
                        error={!!error.customPeriodEndDate}
                        helperText={error.customPeriodEndDate}
                        disabled={!customPeriodStartDate}
                      />
                    </InputContentLoader>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            )}
            <Grid item xs={6}>
              <BillableHoursMultipleSelect
                label="Crew"
                loading={loading || savingOrDeleteFilter}
                options={companyCrewOptions}
                selectedValuesMap={selectedCrewIdsMap}
                singleWording="Member"
                searchBy={['displayValue', 'secondaryDisplayValue']}
                optionalFilters={CREW_OPTIONAL_FILTERS}
                onChange={selectedValuesMap => {
                  setSelectedCrewIdsMap(selectedValuesMap);
                  setIsFilterSettingsChanged(true);
                }}
                onMenuOpen={() => {
                  setCrewMenuOpen(true);
                }}
                onMenuClose={() => {
                  setCrewMenuOpen(false);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <BillableHoursMultipleSelect
                label="Customers"
                loading={loading || savingOrDeleteFilter}
                options={companyCustomerOptions}
                selectedValuesMap={selectedCustomerIdsMap}
                singleWording="Customer"
                searchBy={['displayValue', 'secondaryDisplayValue']}
                onChange={selectedValuesMap => {
                  setSelectedCustomerIdsMap(selectedValuesMap);
                  setIsFilterSettingsChanged(true);
                }}
                onMenuOpen={() => {
                  setCustomerMenuOpen(true);
                }}
                onMenuClose={() => {
                  setCustomerMenuOpen(false);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <BillableHoursMultipleSelect
                label="Projects"
                loading={loading || savingOrDeleteFilter}
                options={companyProjectOptions}
                selectedValuesMap={selectedProjectIdsMap}
                singleWording="Project"
                searchBy={['displayValue', 'secondaryDisplayValue']}
                defaultAvatar={<DashboardIcon />}
                menuMaxWidth="md"
                optionalFilters={PROJECT_OPTIONAL_FILTERS}
                onChange={selectedValuesMap => {
                  setSelectedProjectIdsMap(selectedValuesMap);
                  setIsFilterSettingsChanged(true);
                }}
                onMenuOpen={() => {
                  setProjectMenuOpen(true);
                }}
                onMenuClose={() => {
                  setProjectMenuOpen(false);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputContentLoader
                loading={loading || savingOrDeleteFilter}
                label="Timezone"
              >
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="billable-hours-select-timezone-label">
                    Timezone
                  </InputLabel>
                  <TimezoneSelect
                    value={selectedTimezone}
                    labelStyle="abbrev"
                    onChange={timezone => {
                      setSelectedTimezone(timezone);
                      setIsFilterSettingsChanged(true);
                    }}
                  />
                </FormControl>
              </InputContentLoader>
            </Grid>
            <Grid item container justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                className={classes.updateButton}
                disabled={loading}
                onClick={handleUpdateButtonClick}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
      {showCreateFilterDialog.open && (
        <Dialog open={showCreateFilterDialog.open} maxWidth="sm" fullWidth>
          <DialogTitle disableTypography>
            <Grid container alignItems="center">
              <FilterListIcon />
              &nbsp;
              <Typography variant="h6">
                {showCreateFilterDialog.editingFilter
                  ? 'Edit Filter'
                  : 'Create New Filter'}
              </Typography>
            </Grid>
          </DialogTitle>

          <DialogContent>
            <Grid
              container
              spacing={3}
              style={{ minHeight: 100, marginTop: 0, marginBottom: 2 }}
              alignContent="flex-start"
            >
              <Grid item xs={12}>
                <TextField
                  label="Filter Name"
                  name="newFilterName"
                  placeholder="Enter an unique name"
                  fullWidth
                  value={newFilterName}
                  onChange={event => {
                    setError(currentState => ({
                      ...currentState,
                      newFilterName: '',
                    }));
                    setNewFilterName(event.target.value);
                  }}
                  error={!!error.newFilterName}
                  helperText={error.newFilterName}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              flex={1}
            >
              <Button
                onClick={() => {
                  closeCreateFilterDialog();
                }}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
              <Button
                onClick={handleAddorUpdateSavedFilterOption}
                variant="contained"
                color="primary"
                autoFocus
              >
                Done
              </Button>
            </Grid>
          </DialogActions>
          {savingOrDeleteFilter && (
            <LoadingCover loader="linear">
              <Typography variant="h3" align="center">
                Saving filter...
              </Typography>
            </LoadingCover>
          )}
        </Dialog>
      )}
      {showDeleteFilterOptionDialog.open && (
        <Dialog
          open={showDeleteFilterOptionDialog.open}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle disableTypography>
            <Grid container alignItems="center">
              <Typography variant="h6">Just making sure...</Typography>
            </Grid>
          </DialogTitle>

          <DialogContent dividers>
            <Grid container alignContent="flex-start">
              <Grid item xs={12}>
                <Typography>{`Are you sure you want to delete the filter named "${showDeleteFilterOptionDialog.filterToDelete}"?`}</Typography>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              flex={1}
            >
              <Button
                onClick={() => {
                  closeDeleteFilterOptionDialog();
                }}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
              <Button
                onClick={handleDeleteSavedFilterOption}
                variant="contained"
                color="primary"
                autoFocus
              >
                Ok
              </Button>
            </Grid>
          </DialogActions>
          {savingOrDeleteFilter && (
            <LoadingCover loader="linear">
              <Typography variant="h3" align="center">
                Deleting filter...
              </Typography>
            </LoadingCover>
          )}
        </Dialog>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    managingCompanyInfo: state.appState.managingCompanyInfo || {},
  };
}

export default compose(
  GetCompanyCrewAdminAction,
  GetCompanyCustomersAction,
  GetCompanyInfoAction,
  ListCompanyProjectsAction,
  ListScoreboardSettingsAction,
  UpdateScoreboardSettingsAction,
  connect(mapStateToProps)
)(BillableHoursFilters);
