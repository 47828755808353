import gql from 'graphql-tag';

export default gql`
  mutation SaveProjectAsTemplate(
    $contentTypesToInclude: [String]!
    $projectId: String!
    $startDate: String!
    $templateName: String!
  ) {
    createTemplateFromProject(
      input: {
        contentTypesToInclude: $contentTypesToInclude
        projectId: $projectId
        startDate: $startDate
        templateName: $templateName
      }
    ) {
      customerId
      contentId
      jrnId
      title
      creatorId
      contentUrl
      description
      privacyLevel
      inviteCode
      endDate
      startDate
      allowedToEdit
      allowedToAdd
      allowedToView
      usersEverOnJrn
      synced
      labels
      latitude
      longitude
      address
      type
      addedBy
      date
      isPublic
      aspectRatio
      phoneNumber
      phoneNumberActive
      assignedTo
      priority
      contentStatus
      useTimetracking
      useTimetrackingQuestions
      timetrackingQuestions
      timetrackingQuestionsCheckout
      timetrackingAnswers {
        questionId
        answer
      }
      subtype
      permissionsFrom
      requireAdmin
      completionType
      completionInfo {
        instructions
        completedById
        completedByDate
        textResponse
        mediaInfo {
          uri
          aspectRatio
        }
      }
      comments {
        items {
          content
          commentId
          dateCreated
          userId
        }
      }
      loves {
        items {
          loveId
          userId
          contentId
        }
      }
      amount {
        value
      }
      recurrence {
        rangeId
        rangeStartDate
        rangeEndDate
        daysOfTheWeek
      }
      fromTemplate
      timetrackingType
      workflowStage
      workflowStageReason
      lastMessageSent
    }
  }
`;
