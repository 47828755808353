import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';

import { FirmContext } from '../firm-dashboard/firm.context';
import { SUBSCRIPTION_STATUSES } from '../../config/appDefaults';

const LicenseRequiredActionButton = ({
  firmInfo, // state
  companyInfo, // props
  setLoading = () => {}, // props
  onComplete, // props
}) => {
  const {
    assignLicense,
    availableLicenses,
    setShowPurchaseSubscriptionModal,
    openManageStripeLicenseWindow,
  } = useContext(FirmContext);

  // Show assign license option
  const showAssignOption = availableLicenses.length > 0;
  const assignAction = async () => {
    setLoading(true);

    await assignLicense({
      companyId: companyInfo.companyId,
      productId: availableLicenses[0].productId,
    });

    setLoading(false);
    onComplete();
  };

  let showPurchaseOption = false;
  let showBuyMoreOption = false;
  if (!showAssignOption) {
    if (
      firmInfo?.subscriptionStatus === SUBSCRIPTION_STATUSES.ACTIVE_SUBSCRIPTION
    ) {
      // Show buy more licenses option
      showBuyMoreOption = true;
    } else {
      // Show purchase subscription option
      showPurchaseOption = true;
    }
  }

  const buyMoreAction = () => {
    openManageStripeLicenseWindow();

    onComplete();
  };

  const purchaseSubscriptionAction = () => {
    setShowPurchaseSubscriptionModal(true);
    onComplete();
  };

  return (
    <>
      {showAssignOption && (
        <Button variant="contained" onClick={assignAction} color="primary">
          Assign License
        </Button>
      )}
      {showBuyMoreOption && (
        <Button variant="contained" onClick={buyMoreAction} color="primary">
          Get More Licenses
        </Button>
      )}
      {showPurchaseOption && (
        <Button
          variant="contained"
          onClick={purchaseSubscriptionAction}
          color="primary"
        >
          Purchase Subscription
        </Button>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  firmInfo: state.appState.firmInfo,
});

export default connect(mapStateToProps)(LicenseRequiredActionButton);
