import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Report}
  query GetJobCostingData(
    $companyId: String
    $reportPeriod: String
    $accountingMethod: String
  ) {
    getJobCostingData(
      input: {
        companyId: $companyId
        reportPeriod: $reportPeriod
        accountingMethod: $accountingMethod
      }
    ) {
      items {
        ...ReportFields
      }
    }
  }
`;
