import BreakevenScoreboard from '../../components/scoreboards/breakeven';
import ProfitabilityScoreboard from '../../components/scoreboards/profitability';
import JobCostScoreboard from '../../components/scoreboards/job-cost/job-cost-scoreboard';
import BillableHoursScoreboard from '../../components/scoreboards/billable-hours';
import CustomerTransactionsScoreboard from '../../components/scoreboards/customer-transactions';

export default [
  {
    path: 'breakeven',
    component: BreakevenScoreboard,
  },
  {
    path: 'profitability',
    component: ProfitabilityScoreboard,
  },
  {
    path: 'direct-costs',
    component: JobCostScoreboard,
  },
  {
    path: 'billable-hours',
    component: BillableHoursScoreboard,
  },
  {
    path: 'customer-transactions',
    component: CustomerTransactionsScoreboard,
  },
];
