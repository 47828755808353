import gql from 'graphql-tag';

export default gql`
  fragment CustomerFields on Customer {
    customerId
    managingCompanyId
    firstName
    lastName
    companyName
    email
    phoneNumber
    address
    latitude
    longitude
    notes
    qboCustomerId
  }
`;
