import React, { useState } from 'react';
import { Grid, InputLabel, Button, CircularProgress } from '@material-ui/core';
import _ from 'lodash';

import AnswersDialog from '../answers-dialog/answers-dialog';

const EditAnswersButton = ({
  classes,
  getProjectQuestions,
  parentInfo,
  setDidAnswersChange,
  setFieldValue,
  values,
}) => {
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [editAnswersDialog, setEditAnswersDialog] = useState({
    open: false,
  });

  return (
    <Grid item xs={12} sm={4}>
      <InputLabel className={classes.staticLabel}>
        {values.contentStatus === 'checkin'
          ? 'Clock-in Answers'
          : 'Clock-out Answers'}
      </InputLabel>
      <Button
        variant="outlined"
        disabled={loadingQuestions}
        onClick={async () => {
          setLoadingQuestions(true);
          const allQuestions = await getProjectQuestions();
          const allQuestionsMap = _.keyBy(allQuestions, 'questionId');
          setLoadingQuestions(false);

          const questionIds =
            (values.contentStatus === 'checkin'
              ? parentInfo.timetrackingQuestions
              : parentInfo.timetrackingQuestionsCheckout) || [];

          const questions = [];
          _.forEach(questionIds, questionId => {
            questions.push(allQuestionsMap[questionId]);
          });

          setEditAnswersDialog({
            open: true,
            title:
              values.contentStatus === 'checkin'
                ? 'Clock-in Questions'
                : 'Clock-out Questions',
            questions,
            existingAnswers: values.timetrackingAnswers,
          });
        }}
        style={{ marginTop: 3 }}
      >
        {loadingQuestions && (
          <CircularProgress size={16} style={{ marginRight: 8 }} />
        )}
        Edit Answers
      </Button>
      {editAnswersDialog.open && (
        <AnswersDialog
          open
          title={editAnswersDialog.title}
          questions={editAnswersDialog.questions}
          existingAnswers={editAnswersDialog.existingAnswers}
          handleClose={() =>
            // just close the answer dialog since it was cancelled
            setEditAnswersDialog({
              ...editAnswersDialog,
              open: false,
            })
          }
          handleDone={updatedAnswers => {
            setDidAnswersChange(true);
            setFieldValue('timetrackingAnswers', updatedAnswers);
            // then close the answer dialog
            setEditAnswersDialog({
              ...editAnswersDialog,
              open: false,
            });
          }}
        />
      )}
    </Grid>
  );
};

export default EditAnswersButton;
