import React, { useCallback, useState, useRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  Add as AddIcon,
  Block as BlockIcon,
  Cached as CachedIcon,
  Equalizer as EqualizerIcon,
  MoreHoriz as MoreHorizIcon,
  Save as SaveIcon,
} from '@material-ui/icons';

import _ from 'lodash';

import AdminToolsIconButton from '../../../components/admin-tools-icon-button/admin-tools-icon-button';
import AdminToolsTitle from '../../../components/admin-tools-title/admin-tools-title';
import BillPaymentsTable from './bill-payments-table';
import BillPaymentsViewStatsDialog from './bill-payments-view-stats-dialog';
import LoadingCover from '../../../components/LoadingCover/loadingCover';
import ChooseBillsDialog from '../../../components/choose-bills-dialog/choose-bills-dialog';
import ContentDetailsModal from '../../add-to-project/content-details-modal';
import { useRepetitiveQuery } from '../../../hooks';

import { BILL_PAYMENTS_TABLE_COLUMN } from './bill-payments-table-column-defs';
import store from '../../../store';

import ListCompanyGlobalFinancialItems from '../../../graphql/queries/list-company-global-financial-items';
import {
  GetCompanyCrewAction,
  GetVendorsAction,
} from '../../../graphql/graphql';
import {
  CONTENT_DEFINITION,
  CONTENT_DETAILS_MODAL_MODE,
  CONTENT_TYPE,
} from '../../../config/appDefaults';

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    padding: theme.spacing(3),
  },
  actionButtonsContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    minWidth: 460,
    '& button': {
      marginLeft: theme.spacing(2),
    },
    '& div': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const ManageBillPayments = ({
  managingCompanyInfo,
  // HOC props
  companyCrew,
  getCompanyCrewLoading,
  getCompanyCrewRefetch,

  vendors,
  vendorsLoading,
  vendorsRefetch,
}) => {
  const classes = useStyles();

  const billPaymentsTableRef = useRef(null);
  const [gridRef, setGridRef] = useState(null);

  const [selectedItemsMap, setSelectedItemsMap] = useState({});
  const [showStatsDialog, setShowStatsDialog] = useState({
    open: false,
    stat: null,
  });

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const showPreferencesMenu = event => {
    setMenuAnchorEl(event.currentTarget);
  };
  const closePreferencesMenu = () => {
    setMenuAnchorEl(null);
  };

  const listCompanyGlobalFinancialItemsAccessor =
    'listCompanyGlobalFinancialItems';
  const listCompanyGlobalPaymentsQuery = useRepetitiveQuery(
    ListCompanyGlobalFinancialItems,
    {
      skip: !managingCompanyInfo.managingCompanyId,
      variables: {
        companyId: managingCompanyInfo.managingCompanyId,
        typesToInclude: ['PAYMENT'],
      },
      fetchPolicy: 'cache-and-network',
      accessor: listCompanyGlobalFinancialItemsAccessor,
    }
  );
  const globalPayments = _.get(
    listCompanyGlobalPaymentsQuery,
    `data.${listCompanyGlobalFinancialItemsAccessor}.items`,
    null
  );

  const listCompanyGlobalPaymentsLoading = _.get(
    listCompanyGlobalPaymentsQuery,
    'loading'
  );
  const listCompanyGlobalPaymentsRefetch = _.get(
    listCompanyGlobalPaymentsQuery,
    'refetch'
  );

  const somethingLoading =
    getCompanyCrewLoading || listCompanyGlobalPaymentsLoading || vendorsLoading;

  const [showChooseBillsDialog, setShowChooseBillsDialog] = useState({
    open: false,
  });

  const [showContentDetailsModal, setShowContentDetailsModal] = useState({
    open: false,
    mode: null,
    modeInfo: null,
    existingInfo: null,
  });

  const saveColumnPrefs = () => {
    const allColumnInfo = gridRef.current.columnsMap;
    store.dispatch({
      type: 'SET_ADMIN_TOOLS_SETTINGS',
      payload: { globalBillPayments: allColumnInfo },
    });
    closePreferencesMenu();
  };

  const clearColumnPrefs = () => {
    store.dispatch({
      type: 'SET_ADMIN_TOOLS_SETTINGS',
      payload: { globalBillPayments: null },
    });
    // rebuild the table now
    billPaymentsTableRef.current?.buildTableDefs({ withoutPreferences: true });
    closePreferencesMenu();
  };

  const refetchData = () => {
    if (somethingLoading) {
      return;
    }

    getCompanyCrewRefetch();
    listCompanyGlobalPaymentsRefetch();
    vendorsRefetch();
  };

  const handleSelectionChange = useCallback(({ selected }) => {
    setSelectedItemsMap(selected);
  }, []);

  const calcInfoStat = () => {
    const total = _.sumBy(
      gridRef.current.data,
      BILL_PAYMENTS_TABLE_COLUMN.AMOUNT
    );
    const entriesCount = gridRef.current.data.length;

    return { entriesCount, total };
  };

  const handleViewStats = () => {
    const infoStat = calcInfoStat();
    setShowStatsDialog({ open: true, stat: infoStat });
  };

  const handleBillSelectionDone = selectedBills => {
    setShowChooseBillsDialog({ open: false });
    setShowContentDetailsModal({
      open: true,
      mode: CONTENT_DETAILS_MODAL_MODE.MARK_BILL_AS_PAID,
      modeInfo: {
        bills: selectedBills,
        fromWhichAdminTool: CONTENT_TYPE.GLOBAL_PAYMENT,
      },
      existingInfo: selectedBills[0],
    });
  };

  return (
    <>
      <Grid
        container
        direction="column"
        style={{ height: 'calc(100vh - 64px)', position: 'relative' }}
      >
        <Grid container item>
          <Grid
            container
            justifyContent="space-between"
            className={classes.headerWrapper}
            spacing={1}
          >
            <Grid container item xs={12} justifyContent="space-between">
              <Grid item>
                <AdminToolsTitle
                  Icon={CONTENT_DEFINITION[CONTENT_TYPE.GLOBAL_PAYMENT].Icon}
                  titleText="Manage Bill Payments"
                />
              </Grid>
              <Grid item className={classes.actionButtonsContainer}>
                <AdminToolsIconButton
                  tooltipText="Reload Data"
                  transparentBackground
                  onClick={refetchData}
                  disabled={somethingLoading}
                  isLoading={somethingLoading}
                >
                  <CachedIcon />
                </AdminToolsIconButton>
                <AdminToolsIconButton
                  tooltipText="View stats for current data"
                  onClick={handleViewStats}
                  disabled={!gridRef?.current}
                >
                  <EqualizerIcon />
                </AdminToolsIconButton>
                <AdminToolsIconButton
                  tooltipText="Add a bill payment"
                  onClick={() => {
                    setShowChooseBillsDialog({ open: true });
                  }}
                >
                  <AddIcon />
                </AdminToolsIconButton>
                <AdminToolsIconButton
                  tooltipText="View other options"
                  onClick={showPreferencesMenu}
                >
                  <MoreHorizIcon />
                </AdminToolsIconButton>
                <Menu
                  anchorEl={menuAnchorEl}
                  keepMounted
                  open={Boolean(menuAnchorEl)}
                  onClose={closePreferencesMenu}
                >
                  <MenuItem onClick={saveColumnPrefs}>
                    <SaveIcon />
                    &nbsp;&nbsp;Save Column Preferences
                  </MenuItem>
                  <MenuItem onClick={clearColumnPrefs}>
                    <BlockIcon />
                    &nbsp;&nbsp;Clear Column Preferences
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {_.isNil(companyCrew) || _.isNil(globalPayments) || _.isNil(vendors) ? (
          <LoadingCover loader="linear">
            <Typography variant="h3" align="center">
              Loading data from across your projects...
            </Typography>
          </LoadingCover>
        ) : (
          <Grid item style={{ flex: 1 }}>
            <BillPaymentsTable
              ref={billPaymentsTableRef}
              companyCrew={companyCrew}
              globalPayments={globalPayments}
              vendors={vendors}
              gridRef={gridRef}
              selectedItemsMap={selectedItemsMap}
              onDataGridReady={setGridRef}
              onSelectionChange={handleSelectionChange}
              refetchData={refetchData}
            />
          </Grid>
        )}
      </Grid>
      {showStatsDialog.open && (
        <BillPaymentsViewStatsDialog
          open={showStatsDialog.open}
          stat={showStatsDialog.stat}
          onClose={() => {
            setShowStatsDialog({ open: false });
          }}
        />
      )}
      {showChooseBillsDialog.open && (
        <ChooseBillsDialog
          open={showChooseBillsDialog.open}
          onBillSelectionDone={handleBillSelectionDone}
          onClose={() => {
            setShowChooseBillsDialog({ open: false });
          }}
        />
      )}
      {showContentDetailsModal.open && (
        <ContentDetailsModal
          mode={showContentDetailsModal.mode}
          modeInfo={showContentDetailsModal.modeInfo}
          existingInfo={showContentDetailsModal.existingInfo}
          onClose={() => {
            setShowContentDetailsModal({
              open: false,
              mode: null,
              modeInfo: null,
              existingInfo: null,
            });
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || {},
});

export default compose(
  GetCompanyCrewAction,
  GetVendorsAction,
  connect(mapStateToProps)
)(ManageBillPayments);
