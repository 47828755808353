import gql from 'graphql-tag';

export default gql`
  mutation ArchiveCompanyProject($projectId: String!) {
    archiveCompanyProject(input: { projectId: $projectId }) {
      __typename
      status
      msg
    }
  }
`;
