import gql from 'graphql-tag';

export default gql`
  mutation ArchiveOrRestoreCompanyQuestion(
    $action: String!
    $companyId: String!
    $questionId: String!
  ) {
    archiveOrRestoreCompanyQuestion(
      input: { action: $action, companyId: $companyId, questionId: $questionId }
    ) {
      questions
      archivedQuestions
    }
  }
`;
