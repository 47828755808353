import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import * as Scroll from 'react-scroll';

import ListOfActivities from '../../components/ListOfActivities/ListOfActivities.data';
import ProjectDetails from '../Projects/components/ProjectDetails/projectDetails.data';

const styles = {
  scrollableColumn: {
    overflowY: 'scroll',
    height: 'calc(100vh - 64px)',
  },
  calendarButton: {
    color: 'rgba(57, 124, 165, 1)',
  },
};
const { Element } = Scroll;

const ActivityFeed = props => {
  const onClickOfActivity = activity => {
    const { history } = props;
    history.push(`/activity-feed/${activity.projectId}`);
  };
  const { match, location } = props;
  // break out URL in projects
  const projectUrlSegments = location.pathname
    .replace(`${match.url}/`, '')
    .split('/');
  let parentIdInUrl;
  let buttonUrl;
  let urlToUse;
  if (projectUrlSegments.length && projectUrlSegments.length > 1) {
    parentIdInUrl = projectUrlSegments.pop();
    buttonUrl = `${match.url}/${projectUrlSegments.join('/')}`;
    urlToUse = buttonUrl;
  } else {
    urlToUse = `${match.url}`;
  }

  let lastTabIndex = null;
  if (
    location.state &&
    !Number.isNaN(parseInt(location.state.lastTabIndex, 10))
  ) {
    lastTabIndex = location.state.lastTabIndex;
  }

  return (
    <Grid container>
      <Grid item xs={4} lg={4} style={styles.scrollableColumn}>
        <ListOfActivities onClickOfActivity={onClickOfActivity} />
      </Grid>
      <Grid
        item
        xs={8}
        lg={8}
        style={styles.scrollableColumn}
        id="ContainerElementID"
      >
        <Element name="topOfActivityFeed" />
        <Switch>
          <Route path={`${urlToUse}/:projectId`}>
            <ProjectDetails
              backToParentPathname={parentIdInUrl ? buttonUrl : null}
              lastTabIndex={lastTabIndex}
            />
          </Route>
        </Switch>
      </Grid>
    </Grid>
  );
};

export default withRouter(ActivityFeed);
