import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import _ from 'lodash';
import LoadingCover from '../LoadingCover/loadingCover';

function SimpleDialog(props) {
  const {
    open,
    title,
    onNegative,
    onPositive,
    onClose,
    buttons,
    showLoader,
    children,
    fullWidth,
    maxWidth = 'sm',
  } = props;

  return (
    <Dialog
      onClose={onClose || onNegative}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      {showLoader && <LoadingCover />}
      {title && <DialogTitle id="simple-dialog-title">{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <DialogActions>
          {onNegative && (
            <Button onClick={onNegative}>
              {_.get(buttons, 'negativeButton.label')
                ? buttons.negativeButton.label
                : 'Cancel'}
            </Button>
          )}
          {onPositive && (
            <Button
              onClick={onPositive}
              color="primary"
              variant="contained"
              autoFocus
            >
              {_.get(buttons, 'positiveButton.label')
                ? buttons.positiveButton.label
                : 'Ok'}
            </Button>
          )}
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
}

export default SimpleDialog;
