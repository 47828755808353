import React, { useEffect, useMemo, useState, useRef } from 'react';

import MomentUtils from '@date-io/moment';
import { Button, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { subDays } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import { compose } from 'react-apollo';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  ACTIVITY_STATUS,
  GRAPH_TYPE,
  REPORT_TIME_BASIS,
  SERIES_DATA_TYPE,
} from '../../../config/appDefaults';
import demoAccountMap from '../../../config/demo-accounts.json';
import StartRetroactiveReportGeneration from '../../../graphql/mutations/mutation_start-retroactive-report-generation';
import GetBookkeepingReportSummary from '../../../graphql/queries/get-bookkeeping-report-summary';
import GetBookkeepingRules from '../../../graphql/queries/get-bookkeeping-rules';
import { renderDateString } from '../../../helpers/renderDateString';
import { useCompanyActivityStatus } from '../../../hooks';
import LoadingCover from '../../LoadingCover/loadingCover';
import OkCancelDialog from '../../OkCancelDialog/okCancelDialog';
import { DASHBOARD_LAYOUT, Dashboard, SELECT_ALL_VALUE } from '../../dashboard';
import Graph, { processXYTypes } from '../../graph';
import { BOOKKEEPING_REPORT_ALERT_DICTIONARY } from '../bookkeeping-alert-dictionary';
import BookkeepingAlertsBadge from '../bookkeeping-alerts-badge';
import {
  BOOKKEEPING_ALERT_REPORT_PERIOD,
  BOOKKEEPING_ALERT_REPORT_PERIOD_CUSTOM,
  IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER,
} from '../bookkeeping-alerts.constants';
import BookkeepingAlertsDashboardControlBar from './bookkeeping-alerts-dashboard-control-bar';
import ReportGenerationLoadingCover from './loader-is-backfilling-reports';
import NextReportWillRun from './next-report-will-run';
import LicenseRequiredDialog from './license-required-dialog';

const BOOKKEEPING_ALERT_COUNT = 'Bookkeeping Alert Count';
const MAX_RETRO_REPORT_DAYS = 14;

const BookkeepingAlertsReportDashboard = ({
  managingCompanyInfo,
  userInfo,
}) => {
  const history = useHistory();
  const location = useLocation();
  const isCopilot = managingCompanyInfo?.isCopilot;

  const demoAccountConfig =
    demoAccountMap[managingCompanyInfo?.managingCompanyId || ''];

  const enableDemoMode = !!demoAccountConfig;

  const [selectedAlertTypeId, setSelectedAlertTypeId] = useState(
    SELECT_ALL_VALUE
  );
  const [selectedReportPeriod, setSelectedReportPeriod] = useState(
    enableDemoMode
      ? BOOKKEEPING_ALERT_REPORT_PERIOD_CUSTOM.value
      : BOOKKEEPING_ALERT_REPORT_PERIOD.LAST_30_DAYS.value
  );
  const [retroReportStartDate, setRetroReportStartDate] = useState(
    subDays(new Date(), MAX_RETRO_REPORT_DAYS)
  );
  const [
    showGenerateRetroReportsDialog,
    setShowGenerateRetroReportsDialog,
  ] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const showLicenseRequiredDialogTimeoutRef = useRef(false);
  const [showLicenseRequiredDialog, setShowLicenseRequiredDialog] = useState(
    false
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');
    searchParams.delete('type');

    const reportPeriod = searchParams.get('period');
    searchParams.delete('period');

    if (type && BOOKKEEPING_REPORT_ALERT_DICTIONARY[type]) {
      setSelectedAlertTypeId(type);
    }

    if (reportPeriod) {
      const foundPeriod = _.find(BOOKKEEPING_ALERT_REPORT_PERIOD, {
        value: reportPeriod,
      });

      if (foundPeriod) {
        setSelectedReportPeriod(foundPeriod.value);
      }
    }

    const searchParamsString = searchParams.toString();
    const url = searchParamsString
      ? `${window.location.pathname}?${searchParamsString}`
      : window.location.pathname;
    window.history.replaceState({}, document.title, url);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      if (showLicenseRequiredDialogTimeoutRef.current) {
        clearTimeout(showLicenseRequiredDialogTimeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { startDate, endDate, selectedReportPeriodReadable } = useMemo(() => {
    let startDateToSet;
    let endDateToSet;
    switch (selectedReportPeriod) {
      case BOOKKEEPING_ALERT_REPORT_PERIOD_CUSTOM.value: {
        startDateToSet = moment(demoAccountConfig.startDate);
        endDateToSet = moment(demoAccountConfig.endDate).endOf('day');
        break;
      }
      case BOOKKEEPING_ALERT_REPORT_PERIOD.LAST_90_DAYS.value: {
        startDateToSet = moment()
          .subtract(90, 'days')
          .startOf('day');
        endDateToSet = moment().endOf('day');
        break;
      }
      case BOOKKEEPING_ALERT_REPORT_PERIOD.LAST_12_MONTHS.value: {
        startDateToSet = moment()
          .subtract(12, 'months')
          .startOf('month');
        endDateToSet = moment().endOf('day');
        break;
      }
      case BOOKKEEPING_ALERT_REPORT_PERIOD.LAST_30_DAYS.value:
      default: {
        startDateToSet = moment()
          .subtract(30, 'days')
          .startOf('day');
        endDateToSet = moment().endOf('day');
        break;
      }
    }
    const selectedReportPeriodReadableToSet = renderDateString(
      startDateToSet,
      endDateToSet
    );

    return {
      startDate: startDateToSet,
      endDate: endDateToSet,
      selectedReportPeriodReadable: selectedReportPeriodReadableToSet,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReportPeriod]);

  const bookkeepingRulesQuery = useQuery(GetBookkeepingRules, {
    variables: {
      companyId: managingCompanyInfo?.companyId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !managingCompanyInfo?.companyId,
  });

  const bookkeepingRules = _.get(
    bookkeepingRulesQuery,
    'data.getBookkeepingRules.items',
    null
  );

  const [startRetroactiveReportGeneration] = useMutation(
    StartRetroactiveReportGeneration
  );

  const bookkeepingReportSummaryQuery = useQuery(GetBookkeepingReportSummary, {
    variables: {
      companyId: managingCompanyInfo?.companyId,
      startDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0],
    },
    fetchPolicy: 'cache-and-network',
    skip: !managingCompanyInfo?.companyId,
  });

  const bookkeepingReportSummaryData = _.get(
    bookkeepingReportSummaryQuery,
    'data.getBookkeepingReportSummary',
    null
  );

  const {
    isBackfillingReports,
    setIsBackfillingReports,
    isSettingUpBookkeepingAlerts,
    backfillActivityStatus,
    activityErrorMessage,
    setActivityErrorMessage,
    getCompanyByIdQuery,
  } = useCompanyActivityStatus({
    companyId: managingCompanyInfo?.companyId,
    trackBackfillReports: true,
    onBackfillReportsSuccess: bookkeepingReportSummaryQuery.refetch,
  });

  const startRetroReportGeneration = async () => {
    setIsBackfillingReports(true);

    try {
      // send the request to start the retroactive report generation
      const response = await startRetroactiveReportGeneration({
        variables: {
          companyId: managingCompanyInfo?.companyId,
          startDate: retroReportStartDate.toISOString(),
        },
      });

      const responseStatus = _.get(
        response,
        'data.startRetroactiveReportGeneration.status'
      );

      if (responseStatus !== 'success') {
        setErrorMessage(
          'An unexpected error occurred. Please try again later!'
        );
        setIsBackfillingReports(false);
        return;
      }

      // start polling the company info query to check the backfill status
      getCompanyByIdQuery.startPolling(5000);
    } catch (error) {
      // it can be a network error
      setErrorMessage('An unexpected error occurred. Please try again later!');
      setIsBackfillingReports(false);
    }
  };

  const { reportDateSnapshotIdMap, reportHiddenDateTimes } = useMemo(() => {
    let dateSnapshotIdMap = {};

    if (!bookkeepingReportSummaryData) {
      return dateSnapshotIdMap;
    }

    const dateSnapshotIdMapStringVal = _.find(
      bookkeepingReportSummaryData.metadata,
      ({ key }) => {
        return key === 'dateSnapshotIdMap';
      }
    );
    dateSnapshotIdMap = JSON.parse(dateSnapshotIdMapStringVal?.value || '{}');

    const hiddenDateTimesStringVal = _.find(
      bookkeepingReportSummaryData.metadata,
      ({ key }) => {
        return key === 'hiddenDateTimes';
      }
    );
    const hiddenDateTimes = JSON.parse(hiddenDateTimesStringVal?.value || '[]');

    return {
      reportDateSnapshotIdMap: dateSnapshotIdMap,
      reportHiddenDateTimes: hiddenDateTimes,
    };
  }, [bookkeepingReportSummaryData]);

  const alertTypeOptions = useMemo(() => {
    if (!bookkeepingRules) {
      return null; // means still loading
    }

    const typeOptions = [];
    const typeOptionsMap = {};
    _.forEach(bookkeepingRules, rule => {
      if (
        BOOKKEEPING_REPORT_ALERT_DICTIONARY[rule.alertIdentifier] &&
        rule.alertIdentifierSub !== IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER && // not the ignore undeposited funds rule
        !typeOptionsMap[rule.alertIdentifier]
      ) {
        typeOptions.push({
          key: rule.alertIdentifier,
          displayValue:
            BOOKKEEPING_REPORT_ALERT_DICTIONARY[rule.alertIdentifier].name,
        });
        typeOptionsMap[rule.alertIdentifier] = true;
      }
    });

    return typeOptions;
  }, [bookkeepingRules]);

  const handleReportDateClick = reportDateString => {
    const searchParams = new URLSearchParams();

    // set date, type and snapshotId query params for the report page
    searchParams.set('date', new Date(reportDateString).getTime());
    if (selectedAlertTypeId && selectedAlertTypeId !== SELECT_ALL_VALUE) {
      searchParams.set('type', selectedAlertTypeId);
    }
    if (reportDateSnapshotIdMap[reportDateString]) {
      searchParams.set('snapshotId', reportDateSnapshotIdMap[reportDateString]);
    }

    // set backType and backPeriod query params for going back to this page
    searchParams.set('backType', selectedAlertTypeId);
    searchParams.set('backPeriod', selectedReportPeriod);

    const queryString = searchParams.toString();

    history.push(`/alerts/report?${queryString}`);
  };

  const handleLineGraphClick = clickEvent => {
    const { datum, distanceX, distanceY } = clickEvent;

    const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);

    if (_.isNil(datum?.x) || _.isNil(datum?.y) || distance > 100) {
      return;
    }

    const dateTimeIso = moment(datum.x).toISOString();

    handleReportDateClick(dateTimeIso);
  };

  const handleLineGraphHover = ({ datum, event }) => {
    // eslint-disable-next-line no-param-reassign
    event.target.style.cursor = 'default';

    if (!_.isNil(datum?.y)) {
      // eslint-disable-next-line no-param-reassign
      event.target.style.cursor = 'pointer';
    }
  };

  const renderTooltipNote = ({ tooltipData }) => {
    if (_.isNil(tooltipData?.nearestDatum?.datum?.y)) {
      return null;
    }

    return (
      <div style={{ marginTop: 12 }}>
        <i>Click to view report</i>
      </div>
    );
  };

  const { dataSeries, countSummaryList } = useMemo(() => {
    const series = [];
    const alertCountByDate = {};
    const addressedAlertCountByDate = {};
    const countList = [];
    if (bookkeepingReportSummaryData && selectedAlertTypeId) {
      _.forEach(
        bookkeepingReportSummaryData.categories,
        (dateString, index) => {
          let dailyCount = 0;
          let dailyAddressedCount = 0;

          if (selectedAlertTypeId === SELECT_ALL_VALUE) {
            _.forEach(bookkeepingReportSummaryData.series, seriesData => {
              // Note: always make sure the label is a valid key in the dictionary

              if (_.endsWith(seriesData.label, '-addressed')) {
                const label = seriesData.label.replace(/-addressed$/, '');
                if (BOOKKEEPING_REPORT_ALERT_DICTIONARY[label]) {
                  dailyAddressedCount += seriesData.values[index];
                }
              } else if (
                BOOKKEEPING_REPORT_ALERT_DICTIONARY[seriesData.label]
              ) {
                dailyCount += seriesData.values[index];
              }
            });
          } else if (BOOKKEEPING_REPORT_ALERT_DICTIONARY[selectedAlertTypeId]) {
            const alertData = _.find(bookkeepingReportSummaryData.series, {
              label: selectedAlertTypeId,
            });

            if (alertData) {
              dailyCount += alertData.values[index];
            }

            const addressedAlertData = _.find(
              bookkeepingReportSummaryData.series,
              {
                label: `${selectedAlertTypeId}-addressed`,
              }
            );

            if (addressedAlertData) {
              dailyAddressedCount += addressedAlertData.values[index];
            }
          }

          alertCountByDate[dateString] = dailyCount;
          addressedAlertCountByDate[dateString] = dailyAddressedCount;
        }
      );

      const dataType = {
        x: SERIES_DATA_TYPE.DATE,
        y: SERIES_DATA_TYPE.NUMBER,
      };

      const values = _.keys(alertCountByDate)
        .filter(dateString => !reportHiddenDateTimes.includes(dateString))
        .map(dateString => {
          return {
            x: moment(dateString).toDate(),
            y: alertCountByDate[dateString],
          };
        });

      const data = processXYTypes(values, dataType.x, dataType.y);

      series.push({
        type: GRAPH_TYPE.LINE,
        data,
        dataType,
        name: BOOKKEEPING_ALERT_COUNT,
        dataBasis: REPORT_TIME_BASIS.DAYS,
      });

      _.keys(alertCountByDate)
        .sort()
        .reverse()
        .forEach(reportDateString => {
          const count = alertCountByDate[reportDateString];

          // only show dates that have alerts
          if (count) {
            const addressedCount =
              addressedAlertCountByDate[reportDateString] || 0;

            const addressedRate = addressedCount / count;
            let color = 'primary';
            if (addressedRate >= 0.5 && addressedRate < 1) {
              color = 'secondary';
            } else if (addressedRate === 1) {
              color = 'default';
            }

            countList.push({
              reportDateString,
              badgeContent: `${addressedCount} of ${count}`,
              color,
              displayReportDate: moment(reportDateString).format(
                'MMM DD, YYYY @ h:mma'
              ),
              hidden: reportHiddenDateTimes.includes(reportDateString),
            });
          }
        });
    }

    return {
      dataSeries: series,
      countSummaryList: countList,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookkeepingReportSummaryData, selectedAlertTypeId]);

  const hasAnyReports = bookkeepingReportSummaryData?.categories?.length > 0;

  const isGraphLoading =
    getCompanyByIdQuery.loading ||
    bookkeepingRulesQuery.loading ||
    _.isEmpty(dataSeries);

  const showBackfillReportButton =
    !isGraphLoading &&
    !hasAnyReports &&
    (!backfillActivityStatus ||
      backfillActivityStatus === ACTIVITY_STATUS.ERROR);

  const userBelongsToFirm = !!userInfo?.managingFirmId;

  // If firm user and company has no products, show a dialog to prompt them to add a license
  if (!!userBelongsToFirm && managingCompanyInfo?.products?.length === 0) {
    // Store the dismissed state in session storage so they only see it once per visit
    if (!sessionStorage.getItem('noProductsMessagePrompted')) {
      sessionStorage.setItem('noProductsMessagePrompted', 'true');
      showLicenseRequiredDialogTimeoutRef.current = setTimeout(() => {
        // Wait 5 seconds for better UX
        setShowLicenseRequiredDialog(true);
      }, 5000);
    }
  }

  const handleLicenseRequiredDialogClose = () => {
    setShowLicenseRequiredDialog(false);
  };

  return (
    <>
      <Dashboard
        layout={DASHBOARD_LAYOUT.SIMPLE}
        controlBar={
          <BookkeepingAlertsDashboardControlBar
            title="Bookkeeping Alerts"
            alertTypeOptions={alertTypeOptions}
            selectedAlertTypeId={selectedAlertTypeId}
            setSelectedAlertTypeId={setSelectedAlertTypeId}
            selectedReportPeriod={selectedReportPeriod}
            setSelectedReportPeriod={setSelectedReportPeriod}
            loading={
              getCompanyByIdQuery.loading ||
              bookkeepingRulesQuery.loading ||
              isSettingUpBookkeepingAlerts ||
              isBackfillingReports
            }
            hideAccessExplainer={isCopilot}
            enableDemoMode={enableDemoMode}
          />
        }
      >
        <Grid
          container
          justifyContent="flex-end"
          style={{ width: '100%' }}
          wrap="nowrap"
        >
          {hasAnyReports && (
            <Grid
              item
              container
              style={{ flex: 1, height: '100%' }}
              id="bka-report-graph-wrapper"
            >
              <Graph
                title={selectedReportPeriodReadable}
                boldTotal={false}
                dataSeries={dataSeries}
                loading={isGraphLoading}
                defaultYScaleDomain={{ min: 0, max: 5, numberOfTicks: 5 }}
                showTotal
                showGlyphs={
                  selectedReportPeriod !==
                  BOOKKEEPING_ALERT_REPORT_PERIOD.LAST_12_MONTHS.value
                }
                onLineClick={handleLineGraphClick}
                onLineHover={handleLineGraphHover}
                renderTooltipNote={renderTooltipNote}
                useTimeScale
                startDate={startDate.toDate()}
                endDate={endDate.toDate()}
              />
            </Grid>
          )}
          {showBackfillReportButton && (
            <Grid item container style={{ flex: 1, height: '100%' }}>
              <Grid container item justifyContent="center" alignItems="center">
                <Grid
                  container
                  item
                  direction="column"
                  alignItems="center"
                  style={{ maxWidth: 500 }}
                >
                  <Typography
                    variant="h5"
                    style={{ textAlign: 'center', fontStyle: 'italic' }}
                  >
                    To help you get started using Level&apos;s Bookkeeping
                    Copilot, we can retroactively generate bookkeeping reports
                    going back up to {MAX_RETRO_REPORT_DAYS} days.
                  </Typography>
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{
                      borderRadius: 32,
                    }}
                    onClick={() => {
                      setShowGenerateRetroReportsDialog(true);
                    }}
                  >
                    Generate Reports
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            item
            direction="column"
            justifyContent="space-between"
            wrap="nowrap"
            style={{ width: 320, padding: 8, paddingRight: 16, height: '100%' }}
          >
            <Grid
              item
              style={{
                width: '100%',
                height: 'calc(100% - 60px)',
              }}
            >
              <Paper style={{ width: '100%', height: '100%' }}>
                {!isGraphLoading &&
                  !isSettingUpBookkeepingAlerts &&
                  !isBackfillingReports && (
                    <Grid
                      container
                      item
                      direction="column"
                      alignItems="center"
                      style={{ height: '100%' }}
                      id="bka-reports-list-wrapper"
                    >
                      <Grid
                        item
                        style={{ flex: 0, padding: 8, paddingTop: 16 }}
                      >
                        <Typography variant="h5">
                          Bookkeeping Reports
                        </Typography>
                      </Grid>

                      <Grid item style={{ flex: 0, width: '100%' }}>
                        <Divider />
                      </Grid>

                      <NextReportWillRun customStyles={{ borderRadius: 0 }} />

                      <Grid
                        item
                        style={{ flex: 1, width: '100%', overflowY: 'scroll' }}
                      >
                        {!hasAnyReports && (
                          <Typography
                            variant="body1"
                            style={{ padding: 16, textAlign: 'center' }}
                          >
                            No reports found.
                          </Typography>
                        )}
                        {hasAnyReports &&
                          _.map(
                            countSummaryList,
                            ({
                              displayReportDate,
                              reportDateString,
                              badgeContent,
                              color,
                              hidden,
                            }) => {
                              return (
                                <Grid
                                  item
                                  key={reportDateString}
                                  style={{ width: '100%' }}
                                >
                                  <Button
                                    onClick={() =>
                                      handleReportDateClick(reportDateString)
                                    }
                                    style={{
                                      textTransform: 'none',
                                      ...(hidden && {
                                        color: '#dddddd',
                                      }),
                                    }}
                                    fullWidth
                                  >
                                    <BookkeepingAlertsBadge
                                      badgeContent={badgeContent}
                                      color={hidden ? 'error' : color}
                                      style={{ minWidth: 210 }}
                                    >
                                      {displayReportDate}
                                    </BookkeepingAlertsBadge>
                                  </Button>
                                </Grid>
                              );
                            }
                          )}
                      </Grid>
                    </Grid>
                  )}
              </Paper>
            </Grid>
            <Grid item container justifyContent="center">
              <Button
                variant="outlined"
                fullWidth
                color="primary"
                style={{ textTransform: 'none' }}
                onClick={() => {
                  const newWindow = window.open(
                    'mailto:support@checkthelevel.com?subject=Bookkeeping%20Alerts%20Dashboard%20Beta%20Feedback',
                    '_blank',
                    'noopener,noreferrer'
                  );

                  if (newWindow) {
                    // prevent the new window from having access to the current window
                    newWindow.opener = null;
                  }
                }}
              >
                Have feedback?
              </Button>
            </Grid>
          </Grid>
          {showGenerateRetroReportsDialog && (
            <OkCancelDialog
              open
              okButtonText="Generate My Reports"
              cancelButtonText="Cancel"
              title="Generate Recent Bookkeeping Reports?"
              dividers={false}
              onClose={() => {
                setShowGenerateRetroReportsDialog(false);
              }}
              onConfirm={() => {
                // NOTE: please don't await this function,
                // as it will block the dialog from closing
                startRetroReportGeneration();
              }}
            >
              <Typography>
                To help you get started using Level&apos;s Bookkeeping Copilot,
                we can retroactively generate bookkeeping reports going back up
                to {MAX_RETRO_REPORT_DAYS} days.
              </Typography>
              <br />
              <Typography>
                Please choose the first date you would like to have a
                bookkeeping report generated for!
              </Typography>
              <br />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  label="First Report Date"
                  value={retroReportStartDate}
                  variant="inline"
                  minDate={subDays(new Date(), MAX_RETRO_REPORT_DAYS)}
                  maxDate={subDays(new Date(), 1)}
                  format="MMMM D, YYYY"
                  autoOk
                  onChange={date => {
                    setRetroReportStartDate(date);
                  }}
                  style={{ width: 310, marginBottom: 24 }}
                />
              </MuiPickersUtilsProvider>
            </OkCancelDialog>
          )}
          {(!!errorMessage || !!activityErrorMessage) && !isGraphLoading && (
            <OkCancelDialog
              open
              okButtonText="Ok"
              hideCancel
              title="Oops!"
              dividers={false}
              loaderStyle={{ opacity: 1 }}
              onConfirm={() => {
                setErrorMessage(null);
                setActivityErrorMessage(null);
              }}
            >
              <Typography>{errorMessage || activityErrorMessage}</Typography>
            </OkCancelDialog>
          )}
          {isGraphLoading && (
            <LoadingCover withCam>
              <Typography variant="h2">
                Loading your bookkeeping alerts data...
              </Typography>
            </LoadingCover>
          )}

          {isBackfillingReports && <ReportGenerationLoadingCover />}

          {isSettingUpBookkeepingAlerts && (
            <LoadingCover loader="linear" customStyles={{ opacity: 1 }}>
              <Grid container direction="column" alignItems="center">
                <Typography variant="h3" align="center">
                  We&apos;re currently scanning your QuickBooks to customize
                  <br />
                  notifications specifically for your business. 🔎
                </Typography>
                <Typography
                  variant="h4"
                  style={{ marginTop: 16 }}
                  align="center"
                >
                  Thanks for your patience! We promise it&apos;s worth the wait!
                </Typography>
              </Grid>
            </LoadingCover>
          )}
        </Grid>
      </Dashboard>
      <LicenseRequiredDialog
        open={showLicenseRequiredDialog}
        companyInfo={managingCompanyInfo}
        onClose={handleLicenseRequiredDialogClose}
      />
    </>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userInfo || null,
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default compose(connect(mapStateToProps))(
  BookkeepingAlertsReportDashboard
);
