import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from '@material-ui/core';

const LicenseRequiredDialog = ({ open, onClose, companyInfo }) => {
  const history = useHistory();

  const goToFirm = () => {
    history.push('/firm');
  };

  return (
    <Dialog maxWidth="xs" fullWidth open={open}>
      <DialogTitle>License Required</DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          style={{ padding: 12 }}
        >
          <Typography variant="body1">
            You will need to add a license to{' '}
            <b>{companyInfo?.companyName || 'this client'}</b> before you can
            start generating bookkeeping reports.
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Close
        </Button>
        <Button onClick={goToFirm} color="primary">
          Go to Firm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  firmInfo: state.appState.firmInfo,
});

export default connect(mapStateToProps)(LicenseRequiredDialog);
