import React, { useContext, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Grid, IconButton, Typography, Button } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import productIcon from '../../assets/images/cam/copilot_mascot_004.jpg';
import LevelModal from '../Modal/level-modal';
import { determineManagingCompanyInfo } from '../../helpers';
import LoadingCover from '../LoadingCover/loadingCover';
import { FirmContext } from '../firm-dashboard/firm.context';
import LicenseRequiredActionButton from '../license-required-action-button/license-required-action-button';
import { SUBSCRIPTION_STATUSES } from '../../config/appDefaults';
import ConnectToQuickbooks from '../connect-to-quickbooks';

const GoToClientDialog = ({
  userInfo,
  firmInfo,
  companyInfo,
  onClose,
  dispatch,
}) => {
  const { firmLicenses, availableLicenses } = useContext(FirmContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { hasLicense } = useMemo(() => {
    let hasLicenseToReturn = null;

    if (companyInfo && firmLicenses) {
      hasLicenseToReturn = _.some(
        firmLicenses,
        ({ companyId, isActive }) =>
          companyId === companyInfo.companyId && isActive
      );
    }

    return {
      hasLicense: hasLicenseToReturn,
    };
  }, [firmLicenses, companyInfo]);

  const goToClient = () => {
    if (companyInfo) {
      const newManagingCompanyInfo = determineManagingCompanyInfo({
        userInfo,
        companyInfo,
      });

      if (newManagingCompanyInfo) {
        // this will cause the app to redirect to the client
        dispatch({
          type: 'SET_MANAGING_COMPANY_INFO',
          payload: { ...newManagingCompanyInfo },
        });

        // Explicitly navigate to /alerts
        history.push('/alerts');
      }
    }
  };

  const showAssignLicense = !hasLicense && availableLicenses?.length > 0;
  const hasActiveSubscription =
    firmInfo?.subscriptionStatus === SUBSCRIPTION_STATUSES.ACTIVE_SUBSCRIPTION;
  const inFreeTrial =
    firmInfo?.subscriptionStatus === SUBSCRIPTION_STATUSES.ACTIVE_FREE_TRIAL;
  const showBuyMore =
    !hasLicense && !availableLicenses?.length && hasActiveSubscription;
  const showPurchaseSubscription =
    !hasLicense &&
    !availableLicenses?.length &&
    !hasActiveSubscription &&
    !inFreeTrial;

  const assignLicenseSection = (
    <>
      <Grid item xs={4} style={{ textAlign: 'center' }}>
        <img
          src={productIcon}
          style={{ width: 'auto', height: 200 }}
          alt="group of abstract objects representing bookkeeping alerts"
        />
      </Grid>
      <Grid container item justifyContent="center" xs={8}>
        <Typography variant="body1" style={{ fontSize: 16 }}>
          To start generating bookkeeping reports for{' '}
          <b>{companyInfo.companyName}</b>, you will need to assign a license
          using the button below!
        </Typography>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          style={{ marginTop: 32 }}
        >
          <LicenseRequiredActionButton
            companyInfo={companyInfo}
            onComplete={onClose}
            setLoading={setLoading}
          />
        </Grid>
      </Grid>
    </>
  );

  const buyMoreSection = (
    <>
      <Grid item xs={4} style={{ textAlign: 'center' }}>
        <img
          src={productIcon}
          style={{ width: 'auto', height: 200 }}
          alt="group of abstract objects representing bookkeeping alerts"
        />
      </Grid>
      <Grid container item justifyContent="center" xs={8}>
        <Typography variant="body1" style={{ fontSize: 16 }}>
          To start generating bookkeeping reports for{' '}
          <b>{companyInfo.companyName}</b>, you will need to purchase more
          licenses. You can make the changes to your subscription using the
          button below!
        </Typography>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          style={{ marginTop: 32 }}
        >
          <LicenseRequiredActionButton
            companyInfo={companyInfo}
            onComplete={onClose}
            setLoading={setLoading}
          />
        </Grid>
      </Grid>
    </>
  );

  const purchaseSubscriptionSection = (
    <>
      <Grid item xs={4} style={{ textAlign: 'center' }}>
        <img
          src={productIcon}
          style={{ width: 'auto', height: 200 }}
          alt="group of abstract objects representing bookkeeping alerts"
        />
      </Grid>
      <Grid container item justifyContent="center" xs={8}>
        <Typography variant="body1" style={{ fontSize: 16 }}>
          To start generating bookkeeping reports for{' '}
          <b>{companyInfo.companyName}</b>, you will need to purchase a
          subscription.
        </Typography>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          style={{ marginTop: 32 }}
        >
          <LicenseRequiredActionButton
            companyInfo={companyInfo}
            onComplete={onClose}
            setLoading={setLoading}
          />
        </Grid>
      </Grid>
    </>
  );

  const goToClientSection = (
    <>
      <Grid item xs={4} style={{ textAlign: 'center' }}>
        <img
          src={productIcon}
          style={{ width: 'auto', height: 200, paddingRight: 16 }}
          alt="robot mascot with a happy look on its face"
        />
      </Grid>
      <Grid container item xs={8}>
        <Typography variant="body1" style={{ fontSize: 16 }}>
          Amazing! You can now setup bookkeeping alerts and start generating
          reports for this client&apos;s .
        </Typography>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          style={{ marginTop: 16 }}
        >
          <Button variant="contained" color="primary" onClick={goToClient}>
            Go To &quot;{companyInfo.companyName}&quot;
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          style={{ marginTop: 32 }}
        >
          <Typography variant="body1" style={{ fontSize: 16, width: '100%' }}>
            Or add another client quickly by clicking below:
          </Typography>
          <ConnectToQuickbooks
            redirectTo="/firm"
            customButtonStyles={{ height: 36 }}
          />
        </Grid>
      </Grid>
    </>
  );

  let sectionToShow = null;
  if (showAssignLicense) {
    // If licenses available, first action should be to assign a license
    sectionToShow = assignLicenseSection;
  } else if (showBuyMore) {
    // If no licenses available, redirect to stripe to add more
    sectionToShow = buyMoreSection;
  } else if (showPurchaseSubscription) {
    // If no active subscription, redirect to stripe to purchase
    sectionToShow = purchaseSubscriptionSection;
  } else {
    sectionToShow = goToClientSection;
  }

  return (
    <LevelModal
      open
      fullWidth={false}
      maxWidth="sm"
      dialogStyle={{ zIndex: '9999' }}
    >
      {loading && <LoadingCover />}
      <Grid container alignItems="center" style={{ position: 'relative' }}>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: 16 }}
        >
          <Typography
            variant="h3"
            style={{ textAlign: 'center', paddingLeft: 8, flex: 1 }}
          >
            Your client has been added to Level! 🎉
          </Typography>
          <IconButton onClick={onClose} style={{ flex: 0 }}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ marginBottom: 16 }}
          alignItems="center"
        >
          {sectionToShow}
        </Grid>
      </Grid>
    </LevelModal>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  firmInfo: state.appState.firmInfo,
});

export default connect(mapStateToProps)(GoToClientDialog);
