import gql from 'graphql-tag';

export default gql`
  query ListMazumaGoCardsForUser($companyId: String!, $userId: String!) {
    listMazumaGoCardsForUser(
      input: { companyId: $companyId, userId: $userId }
    ) {
      items {
        cardId
        nickname
        lastFourDigits
        status
        cardType
      }
    }
  }
`;
