import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import cloudinary from 'cloudinary-core';

import { publicIdFromUrl } from '../../../helpers/cloudinary';
import {
  cloudindaryCloudName,
  brokenImageUrl,
} from '../../../config/appDefaults';

const cl = new cloudinary.Cloudinary({
  cloud_name: cloudindaryCloudName,
  secure: true,
});

const useStyles = makeStyles(() => ({
  image: {
    maxHeight: '50vh',
    minHeight: '30vh',
    maxWidth: '100%',
    height: 'auto',
  },
}));

const CloudinaryImage = ({
  contentUrl,
  imageTranformWidth,
  imageTranformHeight,
  imgAlt,
}) => {
  const classes = useStyles();

  const thisUrl = imageInfo => {
    if (imageInfo) {
      if (_.includes(imageInfo, 'cloudinary')) {
        return cl.url(publicIdFromUrl(imageInfo), {
          quality: 'auto',
          width: imageTranformWidth,
          height: imageTranformHeight,
          crop: 'limit',
          fetchFormat: 'jpg',
        });
      }
      return imageInfo;
    }
    return { uri: brokenImageUrl };
  };

  if (!contentUrl) {
    return null;
  }

  return (
    <img
      alt={imgAlt || ''}
      src={thisUrl(contentUrl)}
      className={classes.image}
    />
  );
};

export default CloudinaryImage;
