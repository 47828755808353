import React, { useEffect, useState } from 'react';

import {
  AppBar,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { TimerOutlined as TimerOutlinedIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import { compose, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import userflow from 'userflow.js';

import clockRunOut from '../../../../assets/images/subscriptions/clock-run-out-01.jpg';
import subscriptionPurchaseCart from '../../../../assets/images/subscriptions/purchase-subscription-icon-2.png';
import LoadingCover from '../../../../components/LoadingCover/loadingCover';
import OkCancelDialog from '../../../../components/OkCancelDialog/okCancelDialog';
import ConnectCompanyModal from '../../../../components/connect-company-modal';
import InvitedWhileLoggedInDialog from '../../../../components/invited-while-logged-in-dialog/invited-while-logged-in-dialog';
import LevelLogo from '../../../../components/level-logo/level-logo';
import {
  SUBSCRIPTION_STATUSES,
  USERFLOW_USERTYPE_COMPANY,
  USERFLOW_USERTYPE_FIRM,
} from '../../../../config/appDefaults';
import { GetMyUserInfoAction } from '../../../../graphql/graphql';
import GetUserInfo from '../../../../graphql/queries/GetUserInfo';
import RefreshMyActiveSessions from '../../../../graphql/queries/refresh-my-active-sessions';
import { daysLeftInSubscription, performSignOut } from '../../../../helpers';
import ManageSubscriptionDialog from './manage-subscription-dialog/manage-subscription-dialog';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor:
      process.env.REACT_APP_BACKEND === 'nonprod'
        ? theme.palette.brandColorOrange
        : theme.palette.brandColorPrimary,
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(3),
  },
  headerIconButton: {
    color: 'white',
    marginRight: theme.spacing(2),
  },
  purchaseSubscriptionButton: {
    color: theme.palette.brandColorPrimary,
    background: '#fff',
    borderColor: 'white',
    position: 'relative',
    transition: 'all 0.3s ease',
    '&:hover': {
      background: '#fff',
      transform: 'scale(1.04)',
    },
    height: 40,
  },
  clockImage: {
    height: 34,
    width: 'auto',
    marginRight: 8,
  },
}));

const purchaseSubscriptionButtonInfoInitialState = {
  show: false,
  textToShow: '',
  icon: null,
};

const Topbar = ({
  className,
  userInfo,
  history,
  client,
  companyRegistrationKey,
  showErrorInviteModal,
  currentAuth,
  managingCompanyInfo,
  firmInfo,
}) => {
  const classes = useStyles();

  const [
    purchaseSubscriptionButtonInfo,
    setPurchaseSubscriptionButtonInfo,
  ] = useState(purchaseSubscriptionButtonInfoInitialState);
  const [refreshingSessions, setRefreshingSessions] = useState(false);
  const [showActiveSessionModal, setShowActiveSessionModal] = useState({
    open: false,
  });
  const [dismissConnectCompanyModal, setDismissConnectCompanyModal] = useState(
    false
  );

  const showCompanyView = userInfo && !firmInfo && managingCompanyInfo;

  useEffect(() => {
    const isFirmUser = userInfo?.managingFirmId;
    const isCopilot =
      userInfo?.userId &&
      managingCompanyInfo?.companyId &&
      managingCompanyInfo?.isCopilot;

    if (isFirmUser || isCopilot) {
      if (currentAuth.isLoggedIn) {
        userflow.identify(userInfo?.userId, {
          name: managingCompanyInfo?.companyName,
          email: userInfo?.email,
          level_user_type: {
            set: isFirmUser
              ? USERFLOW_USERTYPE_FIRM
              : USERFLOW_USERTYPE_COMPANY,
          },
        });
        userflow.setCustomNavigate(to => {
          history.push(to);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managingCompanyInfo, userInfo, firmInfo]);

  const [
    showSignOutConfirmationDialog,
    setShowSignOutConfirmationDialog,
  ] = useState(false);

  const handleSignOut = async () => {
    await performSignOut({ userInfo });
  };

  const handleClose = () => {
    setShowActiveSessionModal({ ...showActiveSessionModal, open: false });
  };

  const goToSession = sessionInfo => {
    const { projectId } = sessionInfo;
    handleClose();
    history.push(`/projects/${projectId}`);
  };

  const refreshSessions = async () => {
    setRefreshingSessions(true);
    let newUserInfo;
    try {
      const result = await client.query({
        query: RefreshMyActiveSessions,
        fetchPolicy: 'network-only',
      });
      newUserInfo = _.get(result, 'data.refreshMyActiveSessions');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    if (newUserInfo) {
      // write it back to the cache for getMyUserInfo
      client.writeQuery({
        query: GetUserInfo,
        variables: { userId: 'willBePulledFromCognitoSubContentInResolver' },
        data: {
          getMyUserInfo: newUserInfo,
        },
      });
    }
    setRefreshingSessions(false);
  };

  const handleCheckedIn = () => {
    refreshSessions();
    const activeSessions = _.orderBy(
      userInfo.activeSessions,
      ['dateCreated'],
      ['asc']
    );
    setShowActiveSessionModal({ open: true, activeSessions });
  };

  let activeSessions = null;
  let activeSessionsLength = 0;
  if (userInfo && userInfo.activeSessions && userInfo.activeSessions.length) {
    activeSessions = _.orderBy(
      userInfo.activeSessions,
      ['dateCreated'],
      ['asc']
    );
    activeSessionsLength = userInfo.activeSessions.length;
  }

  useEffect(() => {
    if (managingCompanyInfo || firmInfo) {
      const isFirm = userInfo?.managingFirmId;

      let companyToCheck;
      if (isFirm) {
        companyToCheck = firmInfo;
      } else {
        companyToCheck = managingCompanyInfo;
      }

      if (
        companyToCheck?.subscriptionStatus ===
          SUBSCRIPTION_STATUSES.ACTIVE_FREE_TRIAL &&
        companyToCheck?.subscriptionEndDate
      ) {
        const daysLeft = daysLeftInSubscription(
          companyToCheck?.subscriptionEndDate
        );

        let whatToShow = 'Some';
        if (typeof daysLeft === 'number') {
          if (daysLeft >= 0) {
            whatToShow = daysLeft;
          } else {
            whatToShow = '0';
          }
        }

        setPurchaseSubscriptionButtonInfo({
          show: true,
          textToShow: (
            <>
              <b>{whatToShow}</b>&nbsp;days left in your free trial
            </>
          ),
          subtextToShow: <>Click to view subscription options</>,
          icon: clockRunOut,
        });
      } else if (
        isFirm &&
        companyToCheck?.subscriptionStatus !==
          SUBSCRIPTION_STATUSES.ACTIVE_SUBSCRIPTION
      ) {
        setPurchaseSubscriptionButtonInfo({
          show: true,
          textToShow: 'Purchase a subscription',
          icon: subscriptionPurchaseCart,
        });
      } else if (
        companyToCheck?.subscriptionStatus ===
        SUBSCRIPTION_STATUSES.ACTIVE_SUBSCRIPTION
      ) {
        setPurchaseSubscriptionButtonInfo({
          ...purchaseSubscriptionButtonInfoInitialState,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, managingCompanyInfo, firmInfo]);

  const [showGoToPaymentModal, setShowGoToPaymentModal] = useState(false);
  const handlePurchaseSubscriptionClick = () => {
    setShowGoToPaymentModal(true);
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      id={showCompanyView ? 'company-indicator' : 'firm-indicator'}
    >
      <Toolbar>
        <RouterLink to="/">
          <LevelLogo
            logoVariation="alt"
            style={{ height: 45, width: 'auto' }}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        {activeSessionsLength > 0 && (
          <Button
            onClick={handleCheckedIn}
            className={classes.headerIconButton}
          >
            <TimerOutlinedIcon style={{ marginRight: 8 }} />
            Clocked into {activeSessionsLength} project
            {activeSessionsLength !== 1 ? 's' : ''}
          </Button>
        )}
        {purchaseSubscriptionButtonInfo.show && (
          <Button
            onClick={handlePurchaseSubscriptionClick}
            color="default"
            variant="outlined"
            className={classes.purchaseSubscriptionButton}
          >
            {purchaseSubscriptionButtonInfo.icon && (
              <img
                src={purchaseSubscriptionButtonInfo.icon}
                className={classes.clockImage}
                alt="a clock show time running out"
              />
            )}
            <Grid container direction="column">
              <Typography variant="body1" style={{ color: 'inherit' }}>
                {purchaseSubscriptionButtonInfo.textToShow}
              </Typography>
              {!!purchaseSubscriptionButtonInfo.subtextToShow && (
                <>
                  <Typography variant="caption" style={{ color: 'inherit' }}>
                    {purchaseSubscriptionButtonInfo.subtextToShow}
                  </Typography>
                </>
              )}
            </Grid>
          </Button>
        )}
        {currentAuth.isLoggedIn && (
          <Tooltip title="Sign out">
            <Button
              onClick={() => {
                setShowSignOutConfirmationDialog(true);
              }}
              className={classes.signOutButton}
              color="inherit"
            >
              Logout
            </Button>
          </Tooltip>
        )}
      </Toolbar>
      {showActiveSessionModal.open && (
        <Dialog
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="simple-dialog-title">
            Active Clock-In Sessions
          </DialogTitle>
          <List>
            {activeSessionsLength > 0 ? (
              activeSessions.map(session => {
                return (
                  <ListItem
                    button
                    onClick={() => goToSession(session)}
                    key={session.uniqueId}
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.avatar}>
                        <TimerOutlinedIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={session.projectTitle}
                      secondary={`Since: ${moment(session.date).format(
                        'MMM D @ h:mma'
                      )}`}
                    />
                  </ListItem>
                );
              })
            ) : (
              <Typography align="center">No active sessions</Typography>
            )}
            <ListItem
              button
              onClick={refreshSessions}
              style={{ textAlign: 'right' }}
            >
              <ListItemText primary="Refresh My Sessions" />
            </ListItem>
          </List>
          {refreshingSessions && <LoadingCover />}
        </Dialog>
      )}

      {showGoToPaymentModal && (
        <ManageSubscriptionDialog
          closeDialog={() => {
            setShowGoToPaymentModal(false);
          }}
        />
      )}

      {showErrorInviteModal && <InvitedWhileLoggedInDialog />}

      {companyRegistrationKey && !dismissConnectCompanyModal && (
        <ConnectCompanyModal
          handleClose={() => setDismissConnectCompanyModal(true)}
        />
      )}

      {showSignOutConfirmationDialog && (
        <OkCancelDialog
          open
          okButtonText="Yes"
          cancelButtonText="Cancel"
          title="Confirm Sign Out"
          dividers={false}
          onClose={() => {
            setShowSignOutConfirmationDialog(false);
          }}
          onConfirm={() => {
            handleSignOut();
          }}
          style={{ zIndex: 10000 }}
        >
          <Typography>Are you sure you want to sign out of Level?</Typography>
        </OkCancelDialog>
      )}
    </AppBar>
  );
};

function mapStateToProps(state) {
  return {
    currentAuth: state.currentAuth,
    showErrorInviteModal: state.appState.showErrorInviteModal,
    companyRegistrationKey: state.appState.companyRegistrationKey || null,
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
    firmInfo: state.appState.firmInfo || null,
  };
}

export default withRouter(
  connect(mapStateToProps)(compose(GetMyUserInfoAction, withApollo)(Topbar))
);
