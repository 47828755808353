import React from 'react';
import { TARGET_TYPE } from '../../../config/appDefaults';

const disclaimer = (
  <>
    <br />
    <br />
    <i>
      Note: This graph is based on the data in your accounting software, so work
      with your bookkeeper to make sure your books are up to date for a given
      period before relying on this graph.
    </i>
  </>
);

export const PROJECT_PROFITABILITY_GRAPH = {
  PROJECT_PROFITABILITY: 'projectProfitabilityReport',
};

export const projectProfitabilityGraphs = [
  {
    name: PROJECT_PROFITABILITY_GRAPH.PROJECT_PROFITABILITY,
    label: `Project Profitability ($)`,
    targetType: TARGET_TYPE.NUMBER,
    description: (
      <>
        Project Profitability ($) shows you your project’s cash flow and profits
        on a timeline. Since everything starts at production in your business,
        if a project is not generating cash or a profit your whole business
        suffers. For example, if your project generates a cash outflow at any
        point in time it’s very likely you won’t have sufficient cash to pay the
        overhead in your business.
        <br />
        <br />
        Monitoring your Project Profitability ($) will ensure your payment
        schedules are set up correctly, you’re asking for the correct amounts to
        cover project costs, and you’re collecting on sales invoices on-time.
        {disclaimer}
      </>
    ),
  },
];
