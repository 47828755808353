import gql from 'graphql-tag';

export default gql`
  mutation UpdateNotificationDetails(
    $token: String
    $readyToReceive: Boolean!
  ) {
    updateNotificationDetails(
      input: { token: $token, readyToReceive: $readyToReceive }
    ) {
      __typename
      status
      token
      readyToReceive
    }
  }
`;
