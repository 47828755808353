import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { addDays, format as datefnsFormat, startOfDay } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { compose } from 'react-apollo';

import { GetBookkeepingAlertsPreferencesAction } from '../../../graphql/graphql';
import { getTimezoneAbbreviation } from '../../timezone-select/timezone-select';

const useStyles = makeStyles(() => ({
  alertMessage: {
    alignSelf: 'center',
  },
  alertIcon: {
    alignSelf: 'center',
  },
}));

const NextReportWillRun = ({ bookkeepingAlertPreferences, customStyles }) => {
  const classes = useStyles();
  const figureOutNextBkaRun = ({ bkaPreferences }) => {
    if (bkaPreferences?.timeOfDay && bkaPreferences?.timeZone) {
      const getNextOccurrenceInBrowserTimezone = (
        time,
        timezone,
        preferredFormat = "yyyy-MM-dd'T'HH:mm:ssXXX"
      ) => {
        // Parse the input time string to a Date object
        const timeParts = time.split(':');
        const targetTime = new Date();
        targetTime.setHours(
          parseInt(timeParts[0], 10),
          parseInt(timeParts[1], 10),
          0,
          0
        );

        // Get the current date in the target timezone
        const now = new Date();
        const nowInTargetTimezone = utcToZonedTime(now, timezone);

        // Set the target time on today's date in the target timezone
        let targetDateTimeInTargetTimezone = startOfDay(nowInTargetTimezone);
        targetDateTimeInTargetTimezone.setHours(
          targetTime.getHours(),
          targetTime.getMinutes(),
          0,
          0
        );

        // Convert the target datetime back to UTC
        let targetDateTimeInUtc = zonedTimeToUtc(
          targetDateTimeInTargetTimezone,
          timezone
        );

        // If the target time has already passed today, set the target time to tomorrow
        if (targetDateTimeInUtc <= now) {
          targetDateTimeInTargetTimezone = addDays(
            targetDateTimeInTargetTimezone,
            1
          );
          targetDateTimeInUtc = zonedTimeToUtc(
            targetDateTimeInTargetTimezone,
            timezone
          );
        }

        // Format the result to a readable string
        return datefnsFormat(targetDateTimeInUtc, preferredFormat);
      };

      // get browser timezone
      const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timezoneReadable = getTimezoneAbbreviation(browserTimezone);

      const nextOccurrence = getNextOccurrenceInBrowserTimezone(
        bkaPreferences.timeOfDay,
        bkaPreferences.timeZone,
        'MMM d @ h:mma'
      );

      return `${nextOccurrence} (${timezoneReadable})`;
    }
    return null;
  };

  const nextReportDate = figureOutNextBkaRun({
    bkaPreferences: bookkeepingAlertPreferences,
  });

  if (nextReportDate) {
    return (
      <Alert
        severity="info"
        style={{ width: '100%', ...customStyles }}
        classes={{
          message: classes.alertMessage,
          icon: classes.alertIcon,
        }}
      >
        Next report: {nextReportDate}
      </Alert>
    );
  }
  return null;
};

export default compose(GetBookkeepingAlertsPreferencesAction)(
  NextReportWillRun
);
