import gql from 'graphql-tag';

export default gql`
  query GetCompanyCrewMemberInvitations($companyId: String!) {
    getCompanyCrewMemberInvitations(companyId: $companyId) {
      items {
        invitationId
        email
        sentOn
        companyId
        isActive
        role
      }
    }
  }
`;
