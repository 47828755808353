import gql from 'graphql-tag';
import * as Fragments from '../fragments';

export default gql`
  ${Fragments.BookkeepingAlert}
  query GetBookkeepingAlerts($companyId: String!) {
    getBookkeepingAlerts(input: { companyId: $companyId }) {
      items {
        ...BookkeepingAlertFields
      }
    }
  }
`;
