import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

// UI
import {
  Grid,
  Typography,
  LinearProgress,
  ButtonBase,
  Dialog,
  DialogContent,
  Tooltip,
  Button,
} from '@material-ui/core';
import {
  DeleteOutline as DeleteOutlineIcon,
  Visibility as VisibilityIcon,
  Dashboard as DashboardIcon,
} from '@material-ui/icons';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';

// utilities
import _ from 'lodash';
import moment from 'moment';
import { fromString } from 'html-to-text';
import numeral from 'numeral';

// UI components
import DialogTitle from '../../components/dialog-title/dialog-title';
import FullScreenGallery from '../../components/full-screen-gallery/full-screen-gallery';

// helpers
import palette from '../../theme/palette';
import { cloudinaryifyProfilePic } from '../../helpers/cloudinary';
import { getToJpg, capitalizeSentence } from '../../helpers';
import {
  simpleSortForAmount,
  amountFilter,
} from '../../helpers/react-datagrid-helpers';

// for ReactDataGrid
window.moment = moment;
const filterTypes = {
  ...ReactDataGrid.defaultProps.filterTypes,
  ...amountFilter,
};

const ROW_HEIGHT = 80;

const useStyles = makeStyles(theme => ({
  formInputWrapper: {
    marginTop: 80,
    padding: theme.spacing(2),
  },
  levelNotesWrapper: {
    background: theme.palette.brandColorPrimary40,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    color: '#000',
  },
  formFieldWrapper: {
    marginBottom: theme.spacing(2),
  },
  titleButtonWrapper: {
    position: 'absolute',
    top: 0,
    padding: theme.spacing(2),
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#eee',
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  supportingItemThumbWrapper: {
    position: 'relative',
    height: 50,
    width: 50,
    marginRight: theme.spacing(1),
    cursor: 'pointer',
    overflow: 'hidden',
  },
  supportingItemThumb: {
    height: '100%',
    width: 'auto',
  },
  removeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'rgba(0, 0, 0, 0.6)',
    height: 24,
    width: 24,
  },
  editButton: {
    padding: theme.spacing(0.5),
    margin: 0,
    minWidth: 0,
    color: '#aaa',
    '&:hover': {
      background: 'transparent',
      color: '#333',
    },
  },
}));

const ShoeboxItemsDatagrid = ({
  companyCrew,
  companyProjects,
  items,
  viewThisItem,
  createContentFromShoeboxItem,
  removeThisItem,
}) => {
  const classes = useStyles();
  const [setGridRef] = useState(null);
  const dynamicHeight = items.length * ROW_HEIGHT + ROW_HEIGHT * 2;
  const gridStyle = { height: dynamicHeight, minHeight: '100%' };
  const [dataSource, setDataSource] = useState([]);
  const [selected] = useState({});
  const [fullScreenGalleryInfo, setFullScreenGalleryInfo] = useState({
    open: false,
    images: [],
  });

  useEffect(() => {
    if (!items) return;
    // Clone deep so we can modify without messing with original data
    const sortedItems = _.cloneDeep(
      _.orderBy(items, ['dateCreated'], ['desc'])
    );

    // Flatten or format the data for the datagrid
    const flattenedItems = _.map(sortedItems, item => {
      const flattenedItem = _.cloneDeep(item);
      delete flattenedItem.amount;

      if (item.amount) {
        flattenedItem.amount = item.amount.value || 0;
      } else {
        flattenedItem.amount = 0;
      }

      return flattenedItem;
    });

    setDataSource(flattenedItems);
  }, [items]);

  const deleteThisContent = content => {
    removeThisItem(content);
  };

  const renderMedia = (value, data) => {
    const handleOnlickThumbnail = passedIndex => {
      let showThumbs = true;
      _.each(data.media, mediaItem => {
        if (showThumbs !== false) {
          if (mediaItem.type !== 'image') {
            showThumbs = false;
          }
        }
      });
      setFullScreenGalleryInfo({
        open: true,
        images: data.media,
        selectedItem: passedIndex,
        showThumbs,
      });
    };

    const renderSupportingItem = (supportingItem, index) => {
      // need to show PDFs & images, both before they've been uploaded and after
      // supportingItem.media = [
      //   uri: String!
      //   aspectRatio: String
      //   type: String
      // ]

      // Show a list of thumbnails with a built in "remove" button and clickable to a mostly full screen slideshow
      if (!supportingItem.uri) {
        return null;
      }
      let src = getToJpg(supportingItem.uri);

      src = cloudinaryifyProfilePic(src, true);

      return (
        <div
          key={supportingItem.uri}
          className={classes.supportingItemThumbWrapper}
        >
          <ButtonBase
            className={classes.supportingItemThumbWrapper}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleOnlickThumbnail(index);
            }}
            style={{ backgroundColor: palette.background.alt }}
          >
            <img
              src={src}
              alt="supporting item"
              className={classes.supportingItemThumb}
            />
          </ButtonBase>
        </div>
      );
    };

    return (
      <Grid container justifyContent="center" alignItems="center">
        {value.map(renderSupportingItem)}
      </Grid>
    );
  };

  const columnMinWidthExtraSmall = 52;
  const columnMinWidthSmall = 140;
  const columnMinWidthMedium = 180;
  const columnMinWidthLarge = 250;
  const showInTable = [
    {
      name: 'listNumber',
      header: null,
      minWidth: columnMinWidthExtraSmall,
      maxWidth: columnMinWidthExtraSmall,
    },
    {
      name: 'edit',
      header: null,
      minWidth: columnMinWidthSmall,
      maxWidth: columnMinWidthSmall,
    },
    {
      name: 'dateCreated',
      header: 'Date Received',
      dateFormat: 'YYYY-MM-DD',
      filterEditor: DateFilter,
      filterEditorProps: () => {
        // for range and notinrange operators, the index is 1 for the after field
        return {
          dateFormat: 'MMM D, YYYY',
          cancelButton: false,
          highlightWeekends: false,
        };
      },
      defaultFlex: 30,
      minWidth: columnMinWidthSmall,
    },
    {
      name: 'subject',
      header: 'Subject',
      defaultFlex: 30,
      minWidth: columnMinWidthMedium,
    },
    {
      name: 'notes',
      header: 'Notes',
      defaultFlex: 30,
      minWidth: columnMinWidthMedium,
    },
    {
      name: 'amount',
      header: 'Total',
      defaultFlex: 10,
      minWidth: columnMinWidthMedium,
      sort: simpleSortForAmount,
    },
    {
      name: 'media',
      header: 'Attachments',
      defaultFlex: 30,
      minWidth: columnMinWidthLarge,
    },
  ];

  // build the table columns off the datasource
  const columns = showInTable.map(attribute => {
    const attributePlus = { ...attribute, editable: false };
    if (attribute.name === 'edit') {
      attributePlus.render = ({ data }) => {
        return (
          <Grid container alignItems="center" justifyContent="center">
            <Tooltip title="View Item">
              <Button
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();

                  const itemToEdit = _.find(items, {
                    contentId: data.contentId,
                  });

                  viewThisItem(itemToEdit);
                }}
                className={classes.editButton}
                style={{ marginRight: 6 }}
              >
                <VisibilityIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Assign to Project or Bill">
              <Button
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();

                  const itemToAssign = _.find(items, {
                    contentId: data.contentId,
                  });

                  createContentFromShoeboxItem(itemToAssign);
                }}
                className={classes.editButton}
                style={{ marginRight: 6 }}
              >
                <DashboardIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Delete Item">
              <Button
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  deleteThisContent(data);
                }}
                className={classes.editButton}
                style={{ marginRight: 6 }}
              >
                <DeleteOutlineIcon />
              </Button>
            </Tooltip>
          </Grid>
        );
      };
    } else if (attribute.name === 'listNumber') {
      attributePlus.render = params => {
        return <div style={{ textAlign: 'center' }}>{params.rowIndex + 1}</div>;
      };
    } else if (attribute.name === 'parentName') {
      attributePlus.render = ({ data }) => {
        return <>{data.parentPath && data.parentPath.join(' > ')}</>;
      };
    } else if (attribute.name === 'dateCreated') {
      attributePlus.render = ({ value }) => {
        if (!value) {
          return '-';
        }
        return moment(value).format('MMM D, YYYY @ h:mma');
      };
    } else if (attribute.name === 'subject') {
      attributePlus.render = ({ value }) => {
        if (!value) {
          return 'No subject.';
        }
        return value;
      };
    } else if (attribute.name === 'notes') {
      attributePlus.render = ({ value }) => {
        if (!value) {
          return 'No notes.';
        }
        return fromString(value);
      };
    } else if (attribute.name === 'media') {
      attributePlus.render = ({ value, data }) => {
        if (!value) {
          return 'No attachments';
        }
        return renderMedia(value, data);
      };
    } else if (attribute.name === 'amount') {
      attributePlus.render = ({ value }) => {
        if (!value) {
          return '-';
        }
        return numeral(value).format('$0,0.00');
      };
    }

    if (!attributePlus.name) {
      return null;
    }

    return {
      ...attributePlus,
      header:
        attributePlus.header !== undefined
          ? attributePlus.header
          : capitalizeSentence(attributePlus.name),
    };
  });

  const onSelectionChange = useCallback(
    ({ selected: selectedRow }) => {
      const selectedRowId = Object.keys(selectedRow)[0];
      const itemToEdit = _.find(items, { contentId: selectedRowId });
      viewThisItem(itemToEdit);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  const filterValue = [
    {
      name: 'dateCreated',
      operator: 'afterOrOn',
      type: 'date',
      value: '',
    },
    {
      name: 'subject',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'notes',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'amount',
      type: 'amount',
      operator: 'Starts With',
      value: '',
    },
  ];

  const scrollProps = {
    ...ReactDataGrid.defaultProps.scrollProps,
    autoHide: false,
    scrollThumbWidth: 12,
    scrollThumbStyle: {
      background: palette.brandColorPrimary,
    },
  };

  return (
    <div>
      {items && companyProjects && companyCrew ? (
        <div style={{ flex: 1, height: dynamicHeight }}>
          <ReactDataGrid
            rowHeight={ROW_HEIGHT}
            rowStyle={{ cursor: 'pointer' }}
            // grab the red
            onReady={setGridRef}
            // set which property is used as the ID
            idProperty="contentId"
            // set which columns show
            columns={columns}
            // set the data to build from
            dataSource={dataSource}
            // set basic styling for the overall table
            style={gridStyle}
            // filtering
            enableFiltering
            defaultFilterValue={filterValue}
            // filtering
            defaultSortInfo={[]}
            allowUnsort={false}
            // scrollbar
            scrollProps={scrollProps}
            // checkbox column
            selected={selected}
            // editing options
            editable
            enableKeyboardNavigation={false}
            onSelectionChange={onSelectionChange}
            filterTypes={filterTypes}
          />
        </div>
      ) : (
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%' }}
        >
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              Loading Shoebox Items...
            </Typography>
            <LinearProgress
              style={{ width: '50%', margin: '0 auto', marginTop: 16 }}
            />
          </Grid>
        </Grid>
      )}
      {fullScreenGalleryInfo.open && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open
          onClose={() => setFullScreenGalleryInfo({ open: false })}
        >
          <DialogTitle
            onClose={() => setFullScreenGalleryInfo({ open: false })}
          >
            Supporting Paperwork
          </DialogTitle>
          <DialogContent>
            <FullScreenGallery
              images={fullScreenGalleryInfo.images}
              selectedItem={fullScreenGalleryInfo.selectedItem}
              showThumbs={fullScreenGalleryInfo.showThumbs}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
    managingCompanyId: _.get(
      state.appState,
      'managingCompanyInfo.managingCompanyId',
      null
    ),
  };
}

export default connect(mapStateToProps)(ShoeboxItemsDatagrid);
