import gql from 'graphql-tag';

export default gql`
  mutation AddCardAccessForUser(
    $companyId: String!
    $userId: String!
    $cardId: String!
    $cardType: String!
  ) {
    addCardAccessForUser(
      input: {
        companyId: $companyId
        userId: $userId
        cardId: $cardId
        cardType: $cardType
      }
    ) {
      cardAccessId
      userId
      cardId
    }
  }
`;
