import moment from 'moment';

export const renderDateString = (startDate, endDate, format) => {
  let formatHolder = 'MMMM';
  if (format === 'short') {
    formatHolder = 'MMM';
  } else if (format) {
    formatHolder = format;
  }
  const startDateDayOnly = moment(startDate).format(`${formatHolder} D, YYYY`);
  const endDateDayOnly = moment(endDate).format(`${formatHolder} D, YYYY`);
  if (startDateDayOnly === endDateDayOnly) {
    // if the dates are the same
    return startDateDayOnly;
  }
  if (!endDate) {
    return moment(startDate).format(`${formatHolder}`);
  }
  if (
    moment(startDate).format(`${formatHolder}YYYY`) ===
    moment(endDate).format(`${formatHolder}YYYY`)
  ) {
    // if the dates are in the same month and year
    return `${moment(startDate).format(`${formatHolder} D`)} - ${moment(
      endDate
    ).format('D, YYYY')}`;
  }

  if (moment(startDate).format('YYYY') === moment(endDate).format('YYYY')) {
    // if the dates are only in the year
    return `${moment(startDate).format(`${formatHolder} D`)} - ${moment(
      endDate
    ).format(`${formatHolder} D, YYYY`)}`;
  }

  return `${moment(startDate).format(`${formatHolder} D, YYYY`)} - ${moment(
    endDate
  ).format(`${formatHolder} D, YYYY`)}`;
};

export const renderShiftDate = (startDateMoment, endDateMoment) => {
  let dateString;
  if (startDateMoment.isSame(endDateMoment, 'day')) {
    dateString = `
      ${startDateMoment.format('MMMM D: h:mma')}
      - 
      ${endDateMoment.format('h:mma')}
    `;
  } else if (startDateMoment.isSame(endDateMoment, 'month')) {
    dateString = `
      ${startDateMoment.format('MMMM D')} - ${endDateMoment.format(
      'D'
    )}: ${startDateMoment.format('h:mma')}
      - 
      ${endDateMoment.format('h:mma')}
    `;
  } else if (startDateMoment.isSame(endDateMoment, 'year')) {
    dateString = `
      ${startDateMoment.format('MMMM D: h:mma')}
      - 
      ${endDateMoment.format('MMMM D: h:mma')}
    `;
  } else {
    dateString = `
    ${startDateMoment.format('MMMM D, YYYY: h:mma')}
    - 
    ${endDateMoment.format('MMMM D, YYYY: h:mma')}
  `;
  }
  return dateString;
};
