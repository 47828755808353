import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Grid, TextField } from '@material-ui/core';
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';

const PhoneNumberInput = props => {
  const {
    value,
    label,
    helperText,
    onChange,
    error,
    setError,
    allowedCountries = ['US', 'CA'],
    inputProps,
  } = props;

  const DEBOUNCE_INTERNAL_MS = 1000;
  const defaultCountry = allowedCountries?.[0] || 'US';

  let initialPrettyValue = '';
  if (value) {
    // If cleaned value is different than pretty value, then update
    // Format the phone number when the value changes
    const phoneNumber = parsePhoneNumberFromString(value, 'US');
    const prettyStringToSet = phoneNumber.formatNational();
    if (prettyStringToSet) {
      initialPrettyValue = phoneNumber.formatNational();
    }
  }
  const [prettyValue, setPrettyValue] = useState(initialPrettyValue);

  const validatePhoneNumber = passedPhoneNumber => {
    setError('');

    if (!passedPhoneNumber) {
      onChange('');
      return;
    }

    try {
      const phoneNumber = parsePhoneNumberFromString(
        passedPhoneNumber,
        defaultCountry
      );

      const isValidPhoneNumber = phoneNumber.isValid();

      if (!isValidPhoneNumber) {
        setError('Invalid phone number');
        return;
      }

      if (
        allowedCountries &&
        !_.includes(allowedCountries, phoneNumber.country)
      ) {
        setError('Sorry! This is only available to US & Canada phone numbers');
        return;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('handleDone ~ err:', err);
      setError('Invalid phone number');
    }
  };

  useEffect(() => {
    // Set new debounced validation
    const debounceValidation = _.debounce(
      () => validatePhoneNumber(prettyValue),
      DEBOUNCE_INTERNAL_MS
    );

    debounceValidation();

    return () => {
      debounceValidation.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prettyValue]);

  const handleChange = event => {
    const passedPhoneNumber = event.target.value;

    const phoneNumber = parsePhoneNumberFromString(
      passedPhoneNumber,
      defaultCountry
    );
    onChange(phoneNumber?.number || '');

    const inputText = event.target.value;
    const asYouTypeFormatted = new AsYouType({
      defaultCountry,
    }).input(inputText);

    setPrettyValue(asYouTypeFormatted);
  };

  return (
    <Grid container direction="column">
      <TextField
        value={prettyValue}
        label={label}
        onChange={handleChange}
        type="tel"
        helperText={error || helperText}
        error={!!error}
        {...inputProps}
      />
    </Grid>
  );
};

export default PhoneNumberInput;
