import gql from 'graphql-tag';

export default gql`
  fragment ReportFields on Report {
    name
    labels {
      x
      y
    }
    values {
      x
      y
    }
    metadata {
      key
      value
    }
  }
`;
