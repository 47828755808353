import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const ChooseFriends = props => {
  const { data, onFinished } = props;
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value.userId);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value.userId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <List dense className={classes.root}>
      {data.map(friend => {
        const labelId = friend.userId;
        return (
          <ListItem key={friend.userId} button onClick={handleToggle(friend)}>
            <ListItemAvatar>
              <Avatar alt={friend.username} src={friend.profilePic} />
            </ListItemAvatar>
            <ListItemText id={labelId} primary={friend.username} />
            <ListItemSecondaryAction>
              <Checkbox
                color="primary"
                edge="end"
                onChange={handleToggle(friend)}
                checked={checked.indexOf(friend.userId) !== -1}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
      <div style={{ textAlign: 'right', marginTop: 20 }}>
        <Button
          variant="contained"
          disabled={!(checked.length && checked.length > 0)}
          onClick={() => {
            onFinished(checked);
          }}
        >
          Next
        </Button>
      </div>
    </List>
  );
};

export default ChooseFriends;
