import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  warnResults: {
    background: theme.palette.warning.main,
    color: '#fff',
  },
}));

const WarningBox = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={1}
      className={classes.warnResults}
    >
      {children}
    </Grid>
  );
};

export default WarningBox;
