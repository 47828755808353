import { createContext } from 'react';

const OnboardingContext = createContext({
  steps: [],
  activeStep: 0,
  setActiveStep: () => {},
  onOnboardingComplete: () => {},
});

export default OnboardingContext;
