import gql from 'graphql-tag';

export default gql`
  fragment CompanyFieldsWithoutProducts on Company {
    companyId
    companyName
    companyLogo
    address
    admins
    users
    usersEverOnCompany
    owners
    adminSeats
    connectedToQuickBooks
    customerSyncEnabled
    # products   NOTE: Please don't include this field. If you need it, use the full CompanyFields
    industry
    shoeboxEmail
    mazumaGoClientId
    mazumaGoStatus
    countryCode
    bookkeepingAlertEmailLists {
      listId
      listName
      recipientEmails
    }
    uncategorizedTxnAccounts
    isLockedReason
    rfiRecipients
    timetrackingSettings {
      allowCrewEditing
    }
    subscriptionStatus
    subscriptionEndDate
    timeZone
    backfillActivityInfo {
      status
      msg
    }
    qboUsageBasedRulesActivityInfo {
      status
      msg
    }
    bookkeepingAlertSetupActivityInfo {
      status
      msg
    }
    fiscalYearStart
    dateFirstConnected
    dateLastConnected
  }
`;
