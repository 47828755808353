import React from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import FilterList from '../FilterSearch/filterSearch';

const ConfirmationDialogRaw = props => {
  const {
    onClose,
    value: valueProp,
    open,
    single,
    options,
    allowNull,
    ...other
  } = props;
  const [value, setValue] = React.useState(valueProp);
  const [useThisData, setUseThisData] = React.useState(options);
  React.useEffect(() => {
    setUseThisData(options);
  }, [options]);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    let passBack;
    if (value) {
      passBack = _.find(options, { userId: value });
    } else {
      passBack = null;
    }
    onClose(passBack);
  };

  const handleChange = event => {
    setValue(event.target.value);
  };

  const resetValue = () => {
    setValue(null);
  };

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose(event, reason);
        }
      }}
      disableEscapeKeyDown
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        Choose user to assign this to:
      </DialogTitle>
      <DialogContent dividers>
        <FilterList
          data={options}
          passBack={filteredData => setUseThisData(filteredData)}
          searchBy="username"
          isUserSearch
        />
        {useThisData.map(option => (
          <Grid
            container
            key={option.userId}
            style={{
              alignItems: 'center',
              margin: '5px',
            }}
          >
            <Radio
              checked={value === option.userId}
              onChange={handleChange}
              value={option.userId}
              name="user"
              inputProps={{ 'aria-label': option.userId }}
              style={{ marginRight: '5px' }}
            />
            <Avatar
              style={{ marginRight: '5px' }}
              alt={`Profile pic for ${option.username}`}
              src={option.profilePic}
            />{' '}
            <span style={{ marginLeft: '5px' }}>{option.username}</span>
          </Grid>
        ))}
        {allowNull && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '24px' }}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<PersonAddDisabledIcon />}
                onClick={resetValue}
              >
                Unassign
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialogRaw;
