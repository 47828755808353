import React from 'react';

import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';

import styles from './bookkeeping-alerts.styles';

const useStyles = makeStyles(styles);

const BookkeepingAlertsBackButton = ({ to }) => {
  const classes = useStyles();

  return (
    <NavLink to={to}>
      <IconButton color="primary" className={classes.backButton}>
        <ArrowBackIcon />
      </IconButton>
    </NavLink>
  );
};

export default BookkeepingAlertsBackButton;
