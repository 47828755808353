import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { useMutation } from 'react-apollo-hooks';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import moment from 'moment';
import _ from 'lodash';
import { Typography, makeStyles } from '@material-ui/core';

import themePalette from '../../../theme/palette';
import { amountFilter } from '../../../helpers/react-datagrid-helpers';
import { applyPreferences } from '../../../helpers';

import UpdateJrnMutation from '../../../graphql/mutations/mutation_updateJrn';
import { DeleteContentAction, DeleteJrnAction } from '../../../graphql/graphql';

import generateTableDefs, {
  BILL_PAYMENTS_TABLE_COLUMN,
} from './bill-payments-table-column-defs';
import {
  CONTENT_DETAILS_MODAL_MODE,
  PAYMENT_METHOD_LABEL,
  PRODUCT,
  WORKFLOW_STAGE,
  WORKFLOW_STAGE_REASON,
} from '../../../config/appDefaults';

import ContentDetailsModal from '../../add-to-project/content-details-modal';
import OkCancelDialog from '../../../components/OkCancelDialog/okCancelDialog';

// Initialize for ReactDataGrid
window.moment = moment;

const filterTypes = {
  ...ReactDataGrid.defaultProps.filterTypes,
  ...amountFilter,
};

const scrollProps = {
  ...ReactDataGrid.defaultProps.scrollProps,
  autoHide: false,
  scrollThumbWidth: 12,
  scrollThumbStyle: {
    background: themePalette.brandColorPrimary,
  },
};

const useStyles = makeStyles(theme => ({
  actionButton: {
    padding: theme.spacing(0.5),
    margin: 0,
    minWidth: 0,
    color: '#aaa',
    '&:hover': {
      background: 'transparent',
      color: '#333',
    },
  },
}));

const BillPaymentsTable = ({
  gridRef,
  companyCrew,
  globalPayments,
  vendors,
  selectedItemsMap,
  managingCompanyInfo,
  onDataGridReady,
  onSelectionChange,
  onDeleteContent,
  onDeleteJrn,
  columnSettings,
  billPaymentsTableRef,
}) => {
  const classes = useStyles();

  const [updateJrn] = useMutation(UpdateJrnMutation);
  const [dataSource, setDataSource] = useState(null);
  const [columnDefs, setColumnDefs] = useState(null);
  const [defaultSortInfo, setDefaultSortInfo] = useState(null);
  const [filterDefs, setFilterDefs] = useState(null);
  const [columnOrder, setColumnOrder] = useState(null);

  const [isTableDefsReady, setIsTableDefsReady] = useState(false);

  const [showContentDetailsModal, setShowContentDetailsModal] = useState({
    open: false,
    mode: CONTENT_DETAILS_MODAL_MODE.ADMIN_GLOBAL_ITEM_VIEW,
    contentToShow: null,
    showNextPreviousButtons: false,
  });

  const [
    showDeleteConfirmationDialog,
    setShowDeleteConfirmationDialog,
  ] = useState({ open: false, contentToDelete: null });

  const [showMessageDialog, setShowMessageDialog] = useState({
    title: '',
    open: false,
    message: '',
  });

  const [tableKey, setTableKey] = useState(_.uniqueId());
  const resetTableKey = () => setTableKey(_.uniqueId());

  const hasRfiProduct = managingCompanyInfo?.products?.includes(PRODUCT.RFI);

  useEffect(() => {
    if (companyCrew && globalPayments) {
      const companyCrewMap = _.keyBy(companyCrew, 'userId');

      const newDataSource = _.map(globalPayments, globalPayment => {
        const {
          contentId,
          creatorId,
          startDate,
          vendor,
          amount,
          dateCreated,
          subtype,
          workflowStage,
          workflowStageReason,
          rfiIds,
        } = globalPayment;

        const vendorName = _.get(vendor, 'name', '');

        const amountToUse = amount ? amount.value : 0;

        let isRfiRequired;
        if (hasRfiProduct) {
          isRfiRequired = !!(rfiIds?.length > 0);
        } else {
          isRfiRequired =
            workflowStageReason === WORKFLOW_STAGE_REASON.RFI_REQUIRED;
        }

        return {
          [BILL_PAYMENTS_TABLE_COLUMN.CONTENT_ID]: contentId,
          [BILL_PAYMENTS_TABLE_COLUMN.USERNAME]:
            companyCrewMap[creatorId]?.username || '',
          [BILL_PAYMENTS_TABLE_COLUMN.PAYMENT_DATE]: moment(startDate).endOf(
            'day'
          ),
          [BILL_PAYMENTS_TABLE_COLUMN.VENDOR]: vendorName,
          [BILL_PAYMENTS_TABLE_COLUMN.AMOUNT]: amountToUse,
          [BILL_PAYMENTS_TABLE_COLUMN.PAYMENT_METHOD]:
            PAYMENT_METHOD_LABEL[subtype] || '',
          [BILL_PAYMENTS_TABLE_COLUMN.DATE_ADDED]: moment(dateCreated).endOf(
            'day'
          ),
          [BILL_PAYMENTS_TABLE_COLUMN.ACTIONS]: null,
          [BILL_PAYMENTS_TABLE_COLUMN.IS_RECORDED]:
            workflowStage === WORKFLOW_STAGE.RECORDED,
          [BILL_PAYMENTS_TABLE_COLUMN.IS_RFI_REQUIRED]: isRfiRequired,
          globalPayment,
        };
      });

      setDataSource(newDataSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCrew, globalPayments]);

  const buildTableDefs = ({ withoutPreferences = false } = {}) => {
    const tableDefs = generateTableDefs({
      classes,
      vendors: vendors || [],
      companyCrew: companyCrew || [],
      managingCompanyInfo,
      updateJrn,
      setShowContentDetailsModal,
    });

    const {
      newColumnsInfo: newColumns,
      newSortInfo,
      newFilterInfo,
      newColumnOrder,
    } = applyPreferences({
      preferences: withoutPreferences ? null : columnSettings,
      defaultColumnsInfo: tableDefs.columnDefs,
      defaultFilterInfo: tableDefs.filterDefs,
      defaultSortInfo: tableDefs.defaultSortInfo,
      defaultColumnOrder: null,
    });

    if (!isTableDefsReady || withoutPreferences) {
      setColumnDefs(newColumns);
      setDefaultSortInfo(newSortInfo);
      setFilterDefs(newFilterInfo);
      setColumnOrder(newColumnOrder);

      resetTableKey(); // this is to force the table to re-render
    }

    if (!isTableDefsReady) {
      setIsTableDefsReady(true);
    }
  };

  // expose buildTableDefs function to parent component
  useImperativeHandle(billPaymentsTableRef, () => ({
    buildTableDefs,
  }));

  useEffect(() => {
    buildTableDefs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCrew, vendors, managingCompanyInfo]);

  const getNextItem = nextOrPrevious => {
    if (!showContentDetailsModal.showNextPreviousButtons) {
      return;
    }

    const currentData = gridRef.current.data; // with filters applied

    let indexOfCurrentlyBeingView = _.findIndex(currentData, {
      contentId: showContentDetailsModal.contentToShow.contentId,
    });

    if (indexOfCurrentlyBeingView < 0) {
      // current item has been deleted
      indexOfCurrentlyBeingView = showContentDetailsModal.currentIndex - 1;
    }

    const indexOfRequested =
      nextOrPrevious === 'next'
        ? indexOfCurrentlyBeingView + 1
        : indexOfCurrentlyBeingView - 1;

    let nextItemIndex;
    if (nextOrPrevious === 'next' && indexOfRequested >= currentData.length) {
      nextItemIndex = 0;
    } else if (nextOrPrevious === 'previous' && indexOfRequested < 0) {
      nextItemIndex = currentData.length - 1;
    } else {
      nextItemIndex = indexOfRequested;
    }

    const nextItem = currentData[nextItemIndex];

    if (!nextItem) {
      setShowContentDetailsModal({ open: false });
      return;
    }

    const nextItemToShow = _.find(globalPayments, {
      contentId: nextItem.contentId,
    });

    setShowContentDetailsModal(currentState => ({
      ...currentState,
      contentToShow: nextItemToShow,
      currentIndex: nextItemIndex,
    }));
  };

  const handleViewGlobalItem = globalItem => {
    setShowContentDetailsModal({
      open: true,
      mode: CONTENT_DETAILS_MODAL_MODE.ADMIN_GLOBAL_ITEM_VIEW,
      contentToShow: globalItem,
      showNextPreviousButtons: false,
    });
  };

  const handleContentDetailsModalClose = () => {
    setShowContentDetailsModal({
      open: false,
      mode: CONTENT_DETAILS_MODAL_MODE.ADMIN_GLOBAL_ITEM_VIEW,
      contentToShow: null,
      showNextPreviousButtons: false,
    });
  };

  const handleDeleteBillPayment = billPayment => {
    setShowDeleteConfirmationDialog({
      open: true,
      contentToDelete: billPayment,
    });
  };

  const handleDeleteBillPaymentConfirm = async () => {
    const { contentToDelete: billPayment } = showDeleteConfirmationDialog;
    const { jrn: globalPayment } = billPayment;
    const billPaymentAmount = Number(billPayment.amount?.value);
    const globalPaymentAmount = Number(globalPayment.amount?.value);
    if (billPaymentAmount === globalPaymentAmount) {
      // global payment item has only one bill payment
      // delete the bill payment item
      await onDeleteContent(billPayment.contentId, billPayment.jrnId, {
        content: billPayment,
      });

      // delete global payement
      onDeleteJrn(globalPayment.contentId, globalPayment.type);
    } else {
      // global payment item has more than one bill payment
      // open editing modal to edit the global payment item
      // and remove this bill payment item by setting its deletionPending flag to true.

      setShowMessageDialog({
        title: 'Info',
        open: true,
        message: `This bill payment is shared with other bills. You will be redirected to the editing form to allocate the remaining amount.`,
        onConfirm: () => {
          setShowMessageDialog({ open: false });
          setShowContentDetailsModal({
            open: true,
            mode: CONTENT_DETAILS_MODAL_MODE.EDIT,
            projectInfo: null,
            contentToShow: globalPayment,
            contentToDelete: billPayment,
            showNextPreviousButtons: false,
          });
        },
      });
    }
  };

  const emptyMessage = 'No bill payments found';

  return (
    <div style={{ flex: 1, height: '100%' }}>
      {dataSource && isTableDefsReady && (
        <ReactDataGrid
          key={tableKey}
          onReady={onDataGridReady}
          // set which property is used as the ID
          idProperty="contentId"
          // general
          emptyText={emptyMessage}
          // set which columns show
          columns={columnDefs}
          // set the data to build from
          dataSource={dataSource}
          // column order
          defaultColumnOrder={columnOrder}
          // filtering
          enableFiltering
          defaultFilterValue={filterDefs}
          filterTypes={filterTypes}
          // sorting
          defaultSortInfo={defaultSortInfo}
          allowUnsort={false}
          // scrollbar
          scrollProps={scrollProps}
          selected={selectedItemsMap}
          onSelectionChange={onSelectionChange}
          // set basic styling for the overall table
          style={{ height: '100%', minHeight: '100%' }}
          className="reactDataGridFixLastItemOverlap"
          headerHeight={0}
        />
      )}
      {showContentDetailsModal.open && (
        <ContentDetailsModal
          open={showContentDetailsModal.open}
          mode={showContentDetailsModal.mode}
          existingInfo={showContentDetailsModal.contentToShow}
          onClose={handleContentDetailsModalClose}
          onViewGlobalItem={handleViewGlobalItem}
          onDeleteBillPayment={handleDeleteBillPayment}
          contentToDelete={showContentDetailsModal.contentToDelete}
          getNextItem={
            showContentDetailsModal.showNextPreviousButtons ? getNextItem : null
          }
        />
      )}
      {showDeleteConfirmationDialog.open && (
        <OkCancelDialog
          open={showDeleteConfirmationDialog.open}
          title="Just making sure..."
          okButtonText="Yes"
          okButtonVariant="text"
          autoFocusButton="cancelButton"
          onClose={() => {
            setShowDeleteConfirmationDialog(currentState => ({
              ...currentState,
              open: false,
            }));
          }}
          onConfirm={handleDeleteBillPaymentConfirm}
        >
          <Typography>
            Are you sure you want to erase this payment? This cannot be undone.
          </Typography>
        </OkCancelDialog>
      )}
      {showMessageDialog.open && (
        <OkCancelDialog
          open={showMessageDialog.open}
          title={showMessageDialog.title || 'Oops...'}
          okButtonVariant="text"
          hideCancel
          onConfirm={
            showMessageDialog.onConfirm
              ? showMessageDialog.onConfirm
              : () => {
                  setShowMessageDialog({ open: false });
                }
          }
        >
          <Typography>
            {showMessageDialog.message || 'Something went wrong.'}
          </Typography>
        </OkCancelDialog>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || {},
  columnSettings: state.appState.adminToolsSettings?.globalBillPayments || null,
});

const ComposedBillPaymentsTable = connect(mapStateToProps)(
  compose(DeleteContentAction, DeleteJrnAction)(BillPaymentsTable)
);

export default forwardRef((props, ref) => (
  <ComposedBillPaymentsTable {...props} billPaymentsTableRef={ref} />
));
