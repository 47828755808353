import numeral from 'numeral';
import _ from 'lodash';

import {
  BUDGET_TYPE_PAYMENTS,
  BUDGET_TYPE_RECEIPTS_INVOICES,
  BUDGET_TYPE_TOTAL_LABOR_COST,
  BUDGET_TYPE_TOTAL_LABOR_HOURS,
  BUDGET_TYPE_UNASSIGNED_LABOR_HOURS,
  BUDGET_TYPE_USER_LABOR_HOURS,
  CREW_PAY_TYPES,
} from '../../../../../../config/appDefaults';
import { calculateHourlyRate } from '../../../../../../helpers';
import globalStyles from '../../../../../../theme/palette';

export const calculateCompletePercentage = ({
  total,
  budget,
  numberFormat,
  overageIsGood,
  useColor = true,
}) => {
  const totalN = Number(total);
  const budgetN = Number(budget);
  let varianceByUnit = 0;
  let percentCompleteValue = 'n/a';
  varianceByUnit = totalN - budgetN;
  percentCompleteValue = totalN / budgetN;
  if (!overageIsGood) {
    varianceByUnit *= -1;
  }

  const isOver = percentCompleteValue > 1;
  let fontColor = 'inherit';
  if (useColor && isOver) {
    if (overageIsGood) {
      fontColor = globalStyles.brandColorGreen;
    } else {
      fontColor = globalStyles.brandColorError;
    }
  }

  const formattedTotal = numeral(totalN).format(numberFormat);
  const formattedBudget = numeral(budgetN).format(numberFormat);
  const formattedVarianceByUnit = numeral(varianceByUnit).format(numberFormat);
  let formattedPercentCompleteValue = 'n/a';
  if (budgetN) {
    formattedPercentCompleteValue = numeral(percentCompleteValue).format(
      '0.0%'
    );
  }

  return {
    total: formattedTotal,
    budget: formattedBudget,
    varianceByUnit: formattedVarianceByUnit,
    percentCompleteValue: formattedPercentCompleteValue,
    fontColor,
  };
};

export const calculateTotalLaborBudget = budgetInfo => {
  let totalLaborBudget = 0;
  let totalHoursBudget = 0;

  if (budgetInfo.userPayInfo && budgetInfo.budgets) {
    const userPayInfoMap = _.keyBy(budgetInfo.userPayInfo, 'userId');

    budgetInfo.budgets.forEach(budget => {
      if (budget.label === BUDGET_TYPE_UNASSIGNED_LABOR_HOURS) {
        const unassignedHours = budget.value;
        totalHoursBudget += unassignedHours;
        if (budget.context) {
          const averageHourlyRate = Number(budget.context);
          totalLaborBudget += unassignedHours * averageHourlyRate;
        }
      }
      if (budget.label === BUDGET_TYPE_USER_LABOR_HOURS) {
        // calculate total hours budget
        totalHoursBudget += budget.value;

        // calculate total labor budget
        const payInfo = userPayInfoMap[budget.context];
        if (payInfo && payInfo.payType === CREW_PAY_TYPES.VARIABLE_HOURLY) {
          const hourlyRate = calculateHourlyRate(payInfo);
          totalLaborBudget += hourlyRate * budget.value;
        }
      }
    });
  }

  return {
    cost: totalLaborBudget,
    hours: totalHoursBudget,
  };
};

export const calculateSubprojectBudgetTotals = budgetInfo => {
  const budgetTotals = {};
  if (budgetInfo && budgetInfo.subprojects) {
    budgetInfo.subprojects.forEach(subBudgetItem => {
      subBudgetItem.budgets.forEach(budget => {
        const keyToUse = budget.label;
        if (
          keyToUse === BUDGET_TYPE_PAYMENTS ||
          keyToUse === BUDGET_TYPE_RECEIPTS_INVOICES
        ) {
          if (budget.value) {
            if (!budgetTotals[keyToUse]) {
              budgetTotals[keyToUse] = 0;
            }
            budgetTotals[keyToUse] += budget.value;
          }
        }
      });

      // calculate total labor budget and total hours budget for this subproject
      const subLabor = calculateTotalLaborBudget(subBudgetItem);
      if (!budgetTotals[BUDGET_TYPE_TOTAL_LABOR_COST]) {
        budgetTotals[BUDGET_TYPE_TOTAL_LABOR_COST] = 0;
      }
      budgetTotals[BUDGET_TYPE_TOTAL_LABOR_COST] += subLabor.cost;

      if (!budgetTotals[BUDGET_TYPE_TOTAL_LABOR_HOURS]) {
        budgetTotals[BUDGET_TYPE_TOTAL_LABOR_HOURS] = 0;
      }
      budgetTotals[BUDGET_TYPE_TOTAL_LABOR_HOURS] += subLabor.hours;
    });
  }
  return budgetTotals;
};

export const jsDelay = ms => new Promise(res => setTimeout(res, ms));
