import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo-hooks';
import _ from 'lodash';

import BasicDialog from '../../basic-dialog/basic-dialog';
import CustomerTransactionsBreakdown from './customer-transactions-breakdown';
import CustomerTransactionsReportSettings from './customer-transactions-report-settings';
import { Dashboard } from '../../dashboard';

import GetQboCustomerTxnReport from '../../../graphql/queries/get-qbo-customer-txn-report';

const DASHBOARD_TITLE = 'Customer Transactions';

const CustomerTransactionsScoreboard = ({ managingCompanyInfo }) => {
  const [qboCustomerTxnReport, setQboCustomerTxnReport] = useState(null);
  const [somethingLoading, setSomethingLoading] = useState(false);
  const [hideFilterSettings, setHideFilterSettings] = useState(false);
  const [reportPeriod, setReportPeriod] = useState('');
  const [showBasicDialog, setShowBasicDialog] = useState({
    open: false,
    title: '',
    message: '',
  });

  const { refetch: getQboCustomerTxnReport } = useQuery(
    GetQboCustomerTxnReport,
    {
      skip: true, // NOTE: This query is skipped until the user clicks the "Run Report" button
      variables: {
        companyId: managingCompanyInfo?.managingCompanyId,
      },
      fetchPolicy: 'network-only',
    }
  );

  const handleSettingsUpdate = async updatedFilterSettings => {
    setSomethingLoading(true);

    // reset the breakdown table
    setQboCustomerTxnReport(null);

    try {
      const qboCustomerTxnReportResult = await getQboCustomerTxnReport(
        updatedFilterSettings
      );

      const report = _.get(
        qboCustomerTxnReportResult,
        'data.getQboCustomerTxnReport',
        {}
      );

      setQboCustomerTxnReport(report);
    } catch (error) {
      let errorMessage = 'Something went wrong. Please try again later!';
      if (
        _.includes(
          error?.message,
          'Response payload size exceeded maximum allowed payload size'
        )
      ) {
        errorMessage =
          'Too many transactions in the report. Please choose a shorter reporting window.';
      }

      setShowBasicDialog({
        open: true,
        title: 'Oops!',
        message: errorMessage,
      });
    }

    setSomethingLoading(false);
  };

  return (
    <>
      <Dashboard title={DASHBOARD_TITLE}>
        <Grid container direction="row" style={{ height: '100%' }}>
          <Grid
            container
            item
            xs={4}
            direction="column"
            style={{
              display: hideFilterSettings ? 'none' : 'flex',
            }}
          >
            <Grid item>
              <CustomerTransactionsReportSettings
                onSettingsUpdate={handleSettingsUpdate}
                onReportPeriodChange={setReportPeriod}
                loading={somethingLoading}
                hideSettingsChangedWarning={!qboCustomerTxnReport}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={hideFilterSettings ? 12 : 8}
            style={{ height: '100%' }}
          >
            <CustomerTransactionsBreakdown
              defaultTitle={reportPeriod}
              reportData={qboCustomerTxnReport}
              containerStyle={{ paddingRight: 16, height: '100%' }}
              hideFilterSettings={hideFilterSettings}
              loading={somethingLoading}
              onHideSettingsButtonClick={() =>
                setHideFilterSettings(currentState => !currentState)
              }
            />
          </Grid>
        </Grid>
      </Dashboard>
      {showBasicDialog.open && (
        <BasicDialog
          open={!!showBasicDialog.open}
          title={showBasicDialog.title || 'Hmmm...'}
          handleClose={() =>
            setShowBasicDialog(currentState => ({
              ...currentState,
              open: false,
            }))
          }
        >
          <span>{showBasicDialog.message}</span>
        </BasicDialog>
      )}
    </>
  );
};

CustomerTransactionsScoreboard.title = DASHBOARD_TITLE;

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(CustomerTransactionsScoreboard);
