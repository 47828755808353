import React, { useState, useEffect } from 'react';
import { compose } from 'react-apollo';
import { GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import TextField from '@material-ui/core/TextField';

const LocationSearchInput = ({
  defaultValue,
  pushedAddress: pushedFromParentAddress,
  label,
  placeholder,
  disabled = false,
  passBack,
  multiline,
  passbackAddressObject,
}) => {
  const [address, setAddress] = useState(defaultValue || '');
  const [, setLatLng] = useState({ lat: null, lng: null });

  useEffect(() => {
    if (pushedFromParentAddress && pushedFromParentAddress !== address) {
      setAddress(pushedFromParentAddress);
    }
    // eslint-disable-next-line
  }, [pushedFromParentAddress]);

  const handleChange = passedAddress => {
    setAddress(passedAddress);
    if (!passedAddress) {
      if (passBack) {
        passBack('', { lat: null, lng: null });
      }
    }
  };

  const handleSelect = passedAddress => {
    setAddress(passedAddress);

    geocodeByAddress(passedAddress)
      .then(results => {
        if (passbackAddressObject) {
          passbackAddressObject(results[0]);
        }
        getLatLng(results[0]);
      })
      .then(gotLatLng => {
        if (passBack) {
          passBack(passedAddress, gotLatLng);
        }
        setLatLng(gotLatLng);
      })
      // eslint-disable-next-line no-console
      .catch(error => console.error('Error', error));
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      shouldFetchSuggestions
      debounce={500}
      onError={error => {
        // eslint-disable-next-line no-console
        console.log('PlacesAutocomplete Error', error);
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ position: 'relative' }}>
          <TextField
            {...getInputProps({
              placeholder:
                placeholder || 'Start typing and choose from the options...',
              className: 'location-search-input',
            })}
            label={label}
            InputLabelProps={{ shrink: true }}
            value={address}
            fullWidth
            style={{ maxWidth: '100%' }}
            disabled={disabled}
            name="address"
            multiline={multiline}
          />
          <div
            className="autocomplete-dropdown-container"
            style={{
              position: 'absolute',
              zIndex: 100,
              backgroundColor: '#eee',
            }}
          >
            {loading && (
              <div style={{ padding: '8px 96px 8px 12px' }}>Loading...</div>
            )}
            {suggestions.map(suggestion => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              // inline style for demonstration purpose
              const style = {
                width: '100%',
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 12,
                paddingRight: 8,
                cursor: 'pointer',
                backgroundColor: suggestion.active ? '#ddd' : '#eee',
              };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  key={suggestion.description}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default compose(
  GoogleApiWrapper({ apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY })
)(LocationSearchInput);
