import React, { useMemo } from 'react';
import numeral from 'numeral';

import { makeStyles } from '@material-ui/styles';
import {
  ButtonBase,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';

import globalStyles from '../../../../../theme/palette';

const baseTileStyles = {
  boxShadow: '0 6px 10px -1px #ccc',
  marginTop: 16,
};

const useStyles = makeStyles(theme => ({
  horizontalStatTile: {
    ...baseTileStyles,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  horizontalStatTileInnerWrapper: {
    padding: 16,
  },
  verticalStatTile: {
    ...baseTileStyles,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flex: 1,
  },
  statTileInnerWrapper: {
    padding: 16,
  },
  statTileIconLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  statTileTopline: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginBottom: 4,
  },
  statTileIcon: {
    color: theme.palette.brandColorPrimary,
    marginRight: 5,
  },
  tileInfoBig: {
    fontSize: 24,
  },
  statTileAmountTotal: {
    color: theme.palette.brandColorPrimary,
  },
  expandButton: {
    marginLeft: 8,
    marginRight: 8,
  },
  progressBarContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  tooltip: {
    fontSize: 14,
    color: 'inherit',
    lineHeight: 'inherit',
  },
}));

const CustomButtonBase = ({ disabled, onClick, ...props }) => {
  if (disabled) {
    return <div {...props} />;
  }
  return <ButtonBase component="div" onClick={onClick} {...props} />;
};

const StatTile = ({
  budget,
  budgetNumberFormat = '0,0',
  containerClassName,
  completionAsGreen,
  disabled,
  label,
  hideProgressBar,
  onStatTileClick,
  progressBarData,
  renderIcon,
  total,
  totalNumberFormat = '0,0',
  verticalStatTile = false,
  onLabelButtonClick,
  renderLabelButtonIcon,
}) => {
  const classes = useStyles();

  const localProgressBarData = useMemo(() => {
    if (hideProgressBar) {
      return null;
    }

    if (progressBarData) {
      return progressBarData;
    }

    if (!budget) {
      return null;
    }

    const thickness = 8;
    const currently = total;
    const totalValue = budget;
    let percentage = 0;
    let barColor = globalStyles.progressGreen;
    let percentageGreen = { value: 0, width: '0%' };
    const percentageOrange = { value: 0, width: '0%' };
    const percentageWhite = { value: 0, width: '0%' };

    // figure out the percentage for the width
    if (totalValue !== 0) {
      percentage = (currently / totalValue) * 100;
    }
    // max out width if over 100 so we dont overrun the tile
    const widthNum = Math.min(percentage, 100);

    // set bar color
    if (percentage >= 100) {
      barColor = globalStyles.progressRed;
      if (completionAsGreen) {
        barColor = globalStyles.progressGreen;
      }
    } else if (percentage > 75) {
      barColor = globalStyles.progressOrange;
    }

    percentageGreen = { value: percentage, width: `${widthNum}%` };

    if (percentage < 100) {
      percentageWhite.value = 100 - percentage;
      percentageWhite.width = `${percentageWhite.value}%`;
    }

    return {
      barColor,
      percentageGreen,
      percentageOrange,
      percentageWhite,
      thickness,
    };
  }, [budget, completionAsGreen, hideProgressBar, progressBarData, total]);

  const handleLabelButtonClick = e => {
    e.stopPropagation();
    if (onLabelButtonClick) {
      onLabelButtonClick();
    }
  };

  const renderProgressBar = () => {
    if (!localProgressBarData) {
      return null;
    }

    const {
      thickness,
      barColor,
      percentageGreen,
      percentageOrange,
      percentageWhite,
    } = localProgressBarData;

    return (
      <Tooltip
        title={
          <>
            <Grid container alignItems="center">
              <Grid
                style={{
                  height: thickness,
                  background: barColor,
                  width: 20,
                  marginRight: 8,
                }}
              />
              <Typography className={classes.tooltip}>
                {numeral(percentageGreen.value / 100).format('0.0%')}
              </Typography>
            </Grid>
            {percentageOrange.value > 0 && (
              <Grid container alignItems="center">
                <Grid
                  style={{
                    height: thickness,
                    background: globalStyles.progressOrange,
                    width: 20,
                    marginRight: 8,
                  }}
                />
                <Typography className={classes.tooltip}>
                  {numeral(percentageOrange.value / 100).format('0.0%')}
                </Typography>
              </Grid>
            )}
          </>
        }
      >
        <Grid
          container
          className={classes.progressBarContainer}
          style={{
            height: thickness,
          }}
        >
          {percentageOrange.value > 0 &&
            percentageOrange.value < percentageGreen.value && (
              <Grid
                item
                style={{
                  backgroundColor: globalStyles.progressOrange,
                  height: '100%',
                  width: percentageOrange.width,
                }}
              />
            )}
          <Grid
            item
            style={{
              backgroundColor: barColor,
              height: '100%',
              width: percentageGreen.width,
            }}
          />
          {percentageOrange.value > 0 &&
            percentageOrange.value > percentageGreen.value && (
              <Grid
                item
                style={{
                  backgroundColor: globalStyles.progressOrange,
                  height: '100%',
                  width: percentageOrange.width,
                }}
              />
            )}
          <Grid
            item
            style={{
              backgroundColor: '#eee',
              height: '100%',
              width: percentageWhite.width,
            }}
          />
        </Grid>
      </Tooltip>
    );
  };

  return verticalStatTile ? (
    <CustomButtonBase
      onClick={onStatTileClick}
      className={`${classes.verticalStatTile} ${containerClassName}`}
      style={{ width: '100%' }}
      disabled={disabled}
    >
      <Grid container item className={classes.statTileInnerWrapper}>
        <Grid
          container
          item
          xs={12}
          className={classes.statTileIconLabelWrapper}
        >
          <Grid item className={classes.statTileTopline}>
            {renderIcon({ className: classes.statTileIcon })}
            <span className={classes.tileInfoBig}>
              {numeral(total).format(totalNumberFormat)}
            </span>
            {!!budget && (
              <>
                &nbsp;&nbsp;of&nbsp;&nbsp;
                <span className={classes.tileInfoBig}>
                  {numeral(budget).format(budgetNumberFormat)}
                </span>
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            {label}
            {onLabelButtonClick && renderLabelButtonIcon && (
              <IconButton
                size="small"
                onClick={handleLabelButtonClick}
                className={classes.expandButton}
                // prevent propagating ripple to the parent button
                onMouseDown={e => e.stopPropagation()}
              >
                {renderLabelButtonIcon()}
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      {renderProgressBar()}
    </CustomButtonBase>
  ) : (
    <CustomButtonBase
      disabled={disabled}
      onClick={onStatTileClick}
      className={`${classes.horizontalStatTile} ${containerClassName}`}
      style={{ width: '100%' }}
    >
      <Grid
        item
        container
        direction="column"
        className={classes.horizontalStatTile}
      >
        <Grid container item className={classes.horizontalStatTileInnerWrapper}>
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item className={classes.statTileTopline}>
              {renderIcon({ className: classes.statTileIcon })}
              {label}
              {onLabelButtonClick && renderLabelButtonIcon && (
                <IconButton
                  size="small"
                  onClick={handleLabelButtonClick}
                  className={classes.expandButton}
                  // prevent propagating ripple to the parent button
                  onMouseDown={e => e.stopPropagation()}
                >
                  {renderLabelButtonIcon()}
                </IconButton>
              )}
            </Grid>
            <Grid item className={classes.statTileTopline}>
              <span
                className={`${classes.statTileAmountTotal} ${classes.tileInfoBig}`}
              >
                {numeral(total).format(totalNumberFormat)}
              </span>
              {!!budget && (
                <>
                  &nbsp;&nbsp;of&nbsp;&nbsp;
                  <span
                    className={`${classes.statTileAmountTotal} ${classes.tileInfoBig}`}
                  >
                    {numeral(budget).format(budgetNumberFormat)}
                  </span>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        {renderProgressBar()}
      </Grid>
    </CustomButtonBase>
  );
};

export default StatTile;
