import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import uuid from 'uuid/v4';

import { InputLabel, Typography } from '@material-ui/core';
import { compose } from 'redux';
import { Link as RouterLink } from 'react-router-dom';

import FormikCreatableSelect from '../../../components/formik-custom-components/formik-creatable-select';
import GraphQL from '../../../graphql';
import OkCancelDialog from '../../../components/OkCancelDialog/okCancelDialog';
import { LIST_TYPE } from '../../vendors/list-of-vendors/list-of-vendors-header';

import globalStyles from '../../../theme/palette';

const SelectVendorField = ({
  initialVendorName,
  value,
  setFieldValue,
  classes,
  error,
  touched,
  onBlur,
  vendors,
  editingMode,
  onAddVendor,
  managingCompanyInfo,
  selectVendorFieldRef,
}) => {
  const [showDuplicatedVendorDialog, setShowDuplicatedVendorDialog] = useState({
    open: false,
    vendorName: '',
  });

  const [showCreationConfirmDialog, setShowCreationConfirmDialog] = useState({
    open: false,
    vendorName: '',
  });

  // when editing a financial item, if the initial selected vendor is archived,
  // make sure it is still displayed in the dropdown list.
  // Store it in a separate variable so that it can be added to the list of vendor options later
  const archivedVendorOption = useMemo(() => {
    if (editingMode && value) {
      const initialVendor = _.find(vendors, { relationId: value });
      if (initialVendor && initialVendor.isArchived) {
        return { label: initialVendor.name, value: initialVendor.relationId };
      }
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const vendorOptions = useMemo(() => {
    if (!vendors) {
      return [{ label: 'Loading...', value: null }];
    }

    const activeVendorOptions = [];
    _.forEach(vendors, ({ relationId, name, isArchived }) => {
      if (!isArchived) {
        activeVendorOptions.push({ label: name, value: relationId });
      }
    });

    // Make sure the archived vendor is still displayed in the dropdown list
    if (archivedVendorOption) {
      activeVendorOptions.push(archivedVendorOption);
    }

    const sortedVendorOptions = _.sortBy(activeVendorOptions, 'label');

    return sortedVendorOptions;
  }, [archivedVendorOption, vendors]);

  const handleChange = (fieldName, selection) => {
    setFieldValue('vendorId', (selection && selection.value) || '', true);
  };

  const createVendor = vendorName => {
    const newVendorId = uuid();
    onAddVendor({
      relationId: newVendorId,
      name: vendorName,
      isArchived: false,
    });

    setFieldValue('vendorId', newVendorId, true);
  };

  const selectOrCreateVendor = ({
    vendorName,
    skipCreationConfirmDialog = false,
  }) => {
    const newVendorName = _.trim(vendorName);

    if (!newVendorName) {
      return;
    }

    const newNameAsLowerCase = _.toLower(newVendorName);
    const existingVendor = _.find(vendors, vendor => {
      return _.toLower(vendor.name) === newNameAsLowerCase;
    });

    if (existingVendor) {
      if (
        !existingVendor.isArchived ||
        archivedVendorOption?.value === existingVendor.relationId
      ) {
        setFieldValue('vendorId', existingVendor.relationId, true);
      } else {
        setFieldValue('vendorId', '', true);
        setShowDuplicatedVendorDialog({
          open: true,
          vendorName: newVendorName,
        });
      }
      return;
    }

    if (!skipCreationConfirmDialog) {
      setShowCreationConfirmDialog({ open: true, vendorName: newVendorName });
      return;
    }

    // create a new vendor
    createVendor(newVendorName);
  };

  // expose selectOrCreateVendor function to parent component
  useImperativeHandle(selectVendorFieldRef, () => ({
    selectOrCreateVendor,
  }));

  useEffect(() => {
    // vendor name is passed in from shoebox
    if (initialVendorName && vendors) {
      selectOrCreateVendor({ vendorName: initialVendorName });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialVendorName, vendors]);

  const handleCreate = vendorName => {
    selectOrCreateVendor({ vendorName, skipCreationConfirmDialog: true });
  };

  const selectedOption = useMemo(() => {
    return _.find(vendorOptions, { value }) || null;
  }, [value, vendorOptions]);

  const showError = !!error && touched;

  return (
    <>
      <InputLabel
        className={classes.staticLabel}
        htmlFor="vendor"
        error={showError}
      >
        Vendor
      </InputLabel>
      <FormikCreatableSelect
        options={vendorOptions}
        value={selectedOption}
        onChange={handleChange}
        onBlur={onBlur}
        error={showError}
        placeholder="Select or type to create new ..."
        name="vendor"
        onCreateOption={handleCreate}
      />
      {showError && (
        <Typography variant="caption" color="error" style={{ marginTop: 4 }}>
          This field is required
        </Typography>
      )}
      {showDuplicatedVendorDialog.open && (
        <OkCancelDialog
          open={showDuplicatedVendorDialog.open}
          title="Oops..."
          hideCancel
          onConfirm={() => {
            setShowDuplicatedVendorDialog({ open: false, vendorName: '' });
          }}
        >
          {managingCompanyInfo.isCompanyAdmin ? (
            <Typography>
              The vendor <b>{showDuplicatedVendorDialog.vendorName}</b>
              already exists and has been archived. Please click&nbsp;
              <RouterLink
                to={{
                  pathname: '/vendors',
                  state: {
                    tab: LIST_TYPE.ALL_VENDORS,
                    searchTerm: showDuplicatedVendorDialog.vendorName,
                  },
                }}
                style={{ color: globalStyles.brandColorPrimary }}
              >
                here
              </RouterLink>
              &nbsp;to manage your vendors.
            </Typography>
          ) : (
            <Typography>
              The vendor <b>{showDuplicatedVendorDialog.vendorName}</b>
              already exists and has been archived. Please contact your company
              administrator to unarchive it.
            </Typography>
          )}
        </OkCancelDialog>
      )}
      {showCreationConfirmDialog.open && (
        <OkCancelDialog
          open={showCreationConfirmDialog.open}
          title="Please confirm..."
          okButtonText="Yes"
          cancelButtonText="No"
          onConfirm={() => {
            createVendor(showCreationConfirmDialog.vendorName);
          }}
          onClose={() => {
            setShowCreationConfirmDialog({ open: false, vendorName: '' });
          }}
        >
          <Typography>
            The vendor <b>{showCreationConfirmDialog.vendorName}</b> does not
            exist. Do you want to create it?
          </Typography>
        </OkCancelDialog>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    managingCompanyInfo: state.appState.managingCompanyInfo || {},
  };
};

const ComposedSelectVendorField = compose(
  GraphQL.GetVendorsAction,
  GraphQL.AddVendorAction,
  connect(mapStateToProps)
)(SelectVendorField);

export default forwardRef((props, ref) => (
  <ComposedSelectVendorField {...props} selectVendorFieldRef={ref} />
));
