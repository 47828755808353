import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import { Link, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { daysAfterEpoch } from '../../../helpers/index';
import { renderDateString } from '../../../helpers/renderDateString';
import { cloudinaryifyProfilePic } from '../../../helpers/cloudinary';

import palette from '../../../theme/palette';

const useStyles = makeStyles(theme => ({
  subprojectWrapper: {
    cursor: 'pointer',
  },
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    margin: 10,
    width: 50,
    height: 50,
  },
  goButton: {
    color: '#666',
  },
  moreButtonStyle: {
    paddingRight: theme.spacing(1),
  },
}));

const ContentProjectOrLead = ({ contentPiece, templateInfo }) => {
  const classes = useStyles();
  const useThisUrl = contentPiece.contentUrl
    ? cloudinaryifyProfilePic(contentPiece.contentUrl)
    : null;
  const { url } = useRouteMatch();
  const isLead = contentPiece.type === 'lead';
  const isConnectedToParent = !contentPiece.excludeFromStats;

  const goToLocation = isLead
    ? {
        pathname: `/customers/${contentPiece.customerId}/lead/${contentPiece.contentId}`,
      }
    : {
        pathname: `${url}/${contentPiece.contentId}`,
        state: { lastTabIndex: 0 },
      };

  let dateString = renderDateString(
    contentPiece.startDate,
    contentPiece.endDate
  );

  if (templateInfo) {
    // e.g. "Day 3 - 5"
    const diffStartFromStart = daysAfterEpoch(contentPiece.startDate) + 1;
    const diffEndFromStart = daysAfterEpoch(contentPiece.endDate) + 1;
    if (diffStartFromStart === diffEndFromStart) {
      dateString = `Day ${diffStartFromStart}`;
    } else {
      dateString = `Day ${diffStartFromStart} - ${diffEndFromStart}`;
    }
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item>
        {isLead ? (
          <AssignmentIcon style={{ fontSize: 40, color: '#999', margin: 16 }} />
        ) : (
          <Link to={goToLocation}>
            <Avatar
              sizes="small"
              alt="project cover"
              src={useThisUrl}
              className={classes.bigAvatar}
              style={{
                backgroundColor: isConnectedToParent
                  ? palette.brandColorPrimary50
                  : 'transparent',
                border: isConnectedToParent ? 'none' : `1px dashed #999`,
              }}
            >
              <SubdirectoryArrowRightIcon
                style={{
                  color: isConnectedToParent ? '#fff' : '#999',
                  marginLeft: 5,
                }}
              />
            </Avatar>
          </Link>
        )}
      </Grid>
      <Grid item style={{ flex: 1 }}>
        <Link to={goToLocation}>
          <ListItemText
            primary={`${isLead ? 'LEAD: ' : ''}${contentPiece.title}`}
            secondary={dateString}
          />
        </Link>
      </Grid>
      <Link to={goToLocation}>
        <Grid item>
          <IconButton>
            <ArrowForwardIosIcon className={classes.goButton} />
          </IconButton>
        </Grid>
      </Link>
    </Grid>
  );
};

export default ContentProjectOrLead;
