import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import QboCustomerSearch from '../qbo-customer-search';

function ImportProjectStepChooseCustomer({ handleCustomerSelection }) {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body1">
          <b>Choose Customer</b>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          Select the QuickBooks Online parent customer that you would like to
          import a sub-customer or project from.
        </Typography>
      </Grid>
      <Grid item>
        <QboCustomerSearch handleSelection={handleCustomerSelection} />
      </Grid>
    </Grid>
  );
}

export default ImportProjectStepChooseCustomer;
