import gql from 'graphql-tag';

export default gql`
  query GetBookkeepingReportSummary(
    $companyId: String!
    $startDate: String!
    $endDate: String!
  ) {
    getBookkeepingReportSummary(
      input: { companyId: $companyId, startDate: $startDate, endDate: $endDate }
    ) {
      name # String
      metadata {
        # [KeyValuePair]
        key # String!
        value # String
      }
      categories # [String]
      series {
        # [LineSeries]
        label # String
        values # [Float]
      }
    }
  }
`;
