import './qbo-required-wrapper.scss';

import React, { useEffect, useState } from 'react';

import { Grid, Button, Typography } from '@material-ui/core';
import _ from 'lodash';
import { useQuery } from 'react-apollo-hooks';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ConnectToQuickBooks from '../../components/connect-to-quickbooks';
import ConnectQboWrapper from '../../components/connect-qbo-wrapper/connect-qbo-wrapper';
import QboRedirectHandlerWrapper from '../../components/qbo-redirect-handler-wrapper/qbo-redirect-handler-wrapper';
import CheckQboConnectionStatus from '../../graphql/queries/check-qbo-connection-status';
import { modifyQueryParams } from '../../helpers';
import { useQueryStringParams } from '../../hooks';
import StripeErrorOnCheckoutDialog from '../../components/stripe-dialogs/stripe-error-on-checkout';
import StripeCheckoutSuccessDialog from '../../components/stripe-dialogs/stripe-checkout-success-dialog';

const QboRequiredWrapperComponent = ({
  redirectToOnQboConnect,
  managingCompanyInfo,
  children,
}) => {
  const history = useHistory();

  const [congratsPaidDialog, setCongratsPaidDialog] = useState(false);
  const [errorOnCheckoutDialog, setErrorOnCheckoutDialog] = useState(false);

  const queryStringParams = useQueryStringParams();
  const queryCheckoutParam = queryStringParams.get('checkout');

  const companyId = managingCompanyInfo?.managingCompanyId || null;

  const { data: checkQboConnectionStatusData } = useQuery(
    CheckQboConnectionStatus,
    {
      skip: !companyId || !managingCompanyInfo?.isConnectedToQuickBooks,
      variables: { companyId },
      fetchPolicy: 'network-only',
      pollInterval: 5000, // FUTURE: remove after app is approved for QBO app store
    }
  );

  useEffect(() => {
    if (queryCheckoutParam) {
      if (queryCheckoutParam === 'success') {
        setCongratsPaidDialog(true);
      } else {
        setErrorOnCheckoutDialog(true);
      }
    }
  }, [queryCheckoutParam]);

  const qboConnectionStatus = _.get(
    checkQboConnectionStatusData,
    'checkQboConnectionStatus.status',
    ''
  );

  const tokenWasRevokedOrConnectionLost = [
    'token-revoked',
    'not-connected',
  ].includes(qboConnectionStatus);

  const isCompanyConnectedToQuickBooks =
    !!managingCompanyInfo?.isConnectedToQuickBooks &&
    !tokenWasRevokedOrConnectionLost;

  const goToSettings = () => {
    history.push('/alerts/settings');
  };

  return (
    <QboRedirectHandlerWrapper>
      {!isCompanyConnectedToQuickBooks ? (
        <ConnectQboWrapper
          title="Step 1: Connect your QuickBooks Online account"
          titleAlign="center"
          redirectTo={redirectToOnQboConnect}
          customCssId="bookkeeping-alerts-connect-qbo-wrapper" // do not remove - for Userflow targeting
        >
          <Typography variant="body1" align="center">
            You&apos;re on your way to getting email alerts setup! Just click
            below to connect your QuickBooks Online account.
          </Typography>
        </ConnectQboWrapper>
      ) : (
        children
      )}

      {congratsPaidDialog && (
        <StripeCheckoutSuccessDialog
          onClose={() => {
            setCongratsPaidDialog(false);
            modifyQueryParams({
              history,
              paramsToModify: [{ name: 'checkout', value: null }],
              pushOrReplace: 'replace',
            });
          }}
        >
          {isCompanyConnectedToQuickBooks ? (
            <>
              <Typography
                variant="body1"
                style={{ fontSize: 18, paddingLeft: 16 }}
              >
                If you haven&apos;t already, we recommend you customize your
                bookkeeping rules to suit your business needs.
              </Typography>
              <Grid
                item
                xs={12}
                container
                justifyContent="flex-end"
                style={{ marginTop: 32 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={goToSettings}
                >
                  Go to settings
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Typography
                variant="body1"
                style={{
                  fontSize: 18,
                  paddingLeft: 16,
                  marginBottom: 16,
                }}
              >
                We notice that you haven&apos;t connected your QuickBooks
                account yet though.
              </Typography>
              <Typography
                variant="body1"
                style={{ fontSize: 18, paddingLeft: 16 }}
              >
                Let&apos;s get that done so you can get the most out of Level.
              </Typography>

              <Grid container justifyContent="center" style={{ marginTop: 16 }}>
                <ConnectToQuickBooks redirectTo={redirectToOnQboConnect} />
              </Grid>
            </>
          )}
        </StripeCheckoutSuccessDialog>
      )}

      {errorOnCheckoutDialog && (
        <StripeErrorOnCheckoutDialog
          onClose={() => {
            setErrorOnCheckoutDialog(false);
            modifyQueryParams({
              history,
              clearAllParams: true,
              pushOrReplace: 'replace',
            });
          }}
        />
      )}
    </QboRedirectHandlerWrapper>
  );
};
const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(QboRequiredWrapperComponent);
