import gql from 'graphql-tag';
import BudgetFragment from './budget';
import StatFragment from './stat';

export default gql`
  ${BudgetFragment}
  ${StatFragment}
  fragment ContentBaseFields on Content {
    customerId
    contentId
    jrnId
    creatorId
    address
    type
    subtype
    permissionsFrom
    requireAdmin
    completionType
    completionInfo {
      instructions
      completedById
      completedByDate
      textResponse
      mediaInfo {
        uri
        aspectRatio
      }
    }
    contentUrl
    date
    dateCreated
    description
    labels
    latitude
    longitude
    isPublic
    synced
    title
    endDate
    startDate
    addedBy
    assignedTo
    contentStatus
    priority
    recurrence {
      rangeId
      rangeStartDate
      rangeEndDate
      daysOfTheWeek
    }
    timetrackingAnswers {
      questionId
      answer
    }
    amount {
      value
    }
    workflowStage
    workflowStageReason
    # REMOVEME: remove this when "bugets" field is deprecated
    budgets {
      ...BudgetFields
    }
    stats {
      ...StatFields
    }
    includeSubsInStats
    excludeFromStats
    rfiIds
    globalExpenseId
    billable
  }
`;
