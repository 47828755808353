import gql from 'graphql-tag';

export default gql`
  query ListMazumaGoCards($companyId: String!) {
    listMazumaGoCards(input: { companyId: $companyId }) {
      items {
        cardId
        nickname
        lastFourDigits
        status
        cardType
      }
    }
  }
`;
