import React, { useState } from 'react';
import _ from 'lodash';
import {
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import styles from '../../dashboard/dashboard.styles';
import DashboardReportTableRow from '../../dashboard/dashboard-report-table-row';
import palette from '../../../theme/palette';
import {
  ACCOUNTING_METHOD,
  ACCOUNTING_CATEGORY,
} from '../../../config/appDefaults';
import { asCurrency, asPercentage } from '../../dashboard/dashboard.utils';
import AccountList from './project-profitability-report-account-list';
import OpenBalancesTable from './project-profitability-report-open-balances-table';

const useStyles = makeStyles(styles);

const ProjectProfitabilityReport = ({
  projectInfo,
  reportData,
  accountingMethod = null,
  showSecondary,
  setShowSettings,
  target = null,
}) => {
  const classes = useStyles();

  const [infoToShow, setInfoToShow] = useState(null);

  const cashMetadata = {};
  const accrualMetadata = {};
  const overviewMetadata = {};

  if (
    reportData &&
    reportData.cash &&
    reportData.accrual &&
    reportData.overview
  ) {
    const nonFloatKeys = ['accountTransactions', 'openInvoices'];

    [reportData.cash, reportData.accrual].forEach((eachReportData, i) => {
      if (eachReportData) {
        const metadataObj = i === 0 ? cashMetadata : accrualMetadata;
        const { metadata } = eachReportData;
        _.forEach(metadata || {}, ({ key, value }) => {
          if (nonFloatKeys.includes(key)) {
            metadataObj[key] = JSON.parse(value);
          } else {
            metadataObj[key] = value ? parseFloat(value) : 0;
          }
        });

        metadataObj.totalProfit =
          metadataObj.totalProjectIncome +
          metadataObj.totalProjectCustomerDeposits -
          metadataObj.totalProjectExpenses -
          metadataObj.totalProjectCogs;

        metadataObj.variance = null;
        if (target) {
          metadataObj.variance = metadataObj.totalProfit - target;
        }

        metadataObj.margin = null;
        if (metadataObj.totalProjectIncome) {
          metadataObj.margin =
            metadataObj.totalProfit /
            (metadataObj.totalProjectIncome +
              metadataObj.totalProjectCustomerDeposits);
        }
        metadataObj.markup = null;
        if (metadataObj.totalProjectCogs || metadataObj.totalProjectExpenses) {
          metadataObj.markup =
            metadataObj.totalProfit /
            (metadataObj.totalProjectExpenses + metadataObj.totalProjectCogs);
        }
      }
    });

    _.forEach(reportData.overview.metadata || {}, ({ key, value }) => {
      if (nonFloatKeys.includes(key)) {
        overviewMetadata[key] = JSON.parse(value);
      } else {
        overviewMetadata[key] = value ? parseFloat(value) : 0;
      }
    });
  }

  let primaryMetadata = null;
  if (accountingMethod === ACCOUNTING_METHOD.CASH.key) {
    primaryMetadata = cashMetadata;
  } else {
    primaryMetadata = accrualMetadata;
  }

  const overviewDetails = [
    {
      name: 'Cash flow status',
      value: overviewMetadata.maxFinanced ? (
        <span style={{ fontWeight: 'bold', color: palette.brandColorError }}>
          Financed {asCurrency(overviewMetadata.maxFinanced)}
        </span>
      ) : (
        <span style={{ fontWeight: 'bold', color: palette.brandColorGreen }}>
          Healthy
        </span>
      ),
      description: (
        <>
          See how much you financed the job costs in this project. Financing job
          costs means you needed to inject cash out of your own pocket to
          complete this project.
          <br />
          <br />
          If this project requires financing, it will receive a cash flow status
          that represents the maximum amount of financing the project
          accumulated at a point in time. If this project requires no financing,
          it&apos;ll receive a &quot;Healthy&quot; cash flow status.
          <br />
          <br />
          <b>Important!</b>
          <br />
          Since everything starts at production in your business, if a project
          is not generating cash your whole business suffers. For example, if
          you&apos;re financing project costs at any point in time it&apos;s
          very likely you won&apos;t have sufficient cash to pay the overhead in
          your business.
          <br />
          <br />
          So if you are financing job costs try changing your payment schedule,
          collect payments on invoices sooner or ask for a larger deposit
          upfront before the job starts.
        </>
      ),
    },
    {
      name:
        accountingMethod === ACCOUNTING_METHOD.CASH.key
          ? 'Cash profit'
          : 'Accrued profit',
      value: (
        <span
          style={{
            fontWeight: 'bold',
            color:
              primaryMetadata.totalProfit > 0
                ? palette.brandColorGreen
                : palette.brandColorError,
          }}
        >
          {asCurrency(primaryMetadata.totalProfit || 0)}
        </span>
      ),
      description: null,
    },
    {
      name: 'Target profit',
      value: asCurrency(target || 0),
      description: (
        <Grid container spacing={2}>
          <Grid item>
            This is the target gross profit on your project.
            <br />
            <br />
            To update the target profit, select the Settings icon or choose
            &quot;Go to Settings&quot; below and navigate to the Targets tab.
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                setShowSettings(true);
                setInfoToShow(null);
              }}
              variant="contained"
              color="primary"
            >
              Go to Settings
            </Button>
          </Grid>
        </Grid>
      ),
    },
    {
      name: 'Variance',
      value: asCurrency(primaryMetadata.variance || 0),
      description: null,
    },
  ];

  const generateTransactionDetails = accountTransactions => {
    let income = null;
    let costOfGoodsSold = null;
    let expenses = null;
    let customerDeposits = null;

    if (accountTransactions.income && accountTransactions.income.length > 0) {
      income = (
        <AccountList
          categoryType={ACCOUNTING_CATEGORY.INCOME}
          accountData={accountTransactions.income}
        />
      );
    }

    if (
      accountTransactions.costOfGoodsSold &&
      accountTransactions.costOfGoodsSold.length > 0
    ) {
      costOfGoodsSold = (
        <AccountList
          categoryType={ACCOUNTING_CATEGORY.COGS}
          accountData={accountTransactions.costOfGoodsSold}
        />
      );
    }

    if (
      accountTransactions.expenses &&
      accountTransactions.expenses.length > 0
    ) {
      expenses = (
        <AccountList
          categoryType={ACCOUNTING_CATEGORY.EXPENSES}
          accountData={accountTransactions.expenses}
        />
      );
    }

    if (accountTransactions.customerDeposits) {
      customerDeposits = (
        <AccountList
          categoryType={ACCOUNTING_CATEGORY.LIABILITIES}
          accountData={accountTransactions.customerDeposits}
        />
      );
    }

    return {
      income,
      costOfGoodsSold,
      expenses,
      customerDeposits,
    };
  };

  const {
    income: cashIncomeTransactionDetails,
    costOfGoodsSold: cashCogsTransactionDetails,
    expenses: cashExpensesTransactionDetails,
    customerDeposits: cashCustomerDeposits,
  } = generateTransactionDetails(cashMetadata.accountTransactions || {});

  const cashRows = [
    {
      name: 'Unapplied customer deposits received',
      value: asCurrency(cashMetadata.totalProjectCustomerDeposits || 0),
      description: null,
      expandableBody:
        cashMetadata.accountTransactions &&
        cashMetadata.accountTransactions.customerDeposits &&
        cashMetadata.accountTransactions.customerDeposits.length > 0 &&
        cashCustomerDeposits,
      isVisible: !!cashCustomerDeposits,
    },
    {
      name: 'Sales received',
      value: asCurrency(cashMetadata.totalProjectIncome),
      description: null,
      expandableBody: cashIncomeTransactionDetails,
    },
    {
      name: 'Cost of Goods Sold paid',
      value: asCurrency(cashMetadata.totalProjectCogs),
      description: null,
      expandableBody: cashCogsTransactionDetails,
    },
    {
      name: 'Expenses paid',
      value: asCurrency(cashMetadata.totalProjectExpenses),
      description: null,
      isVisible: cashMetadata.totalProjectExpenses !== 0,
      expandableBody: cashExpensesTransactionDetails,
    },
    {
      name: 'Cash profit',
      value: asCurrency(cashMetadata.totalProfit),
      description: null,
    },
    {
      name: 'Margin',
      value: asPercentage(cashMetadata.margin),
      description: null,
    },
    {
      name: 'Markup',
      value: asPercentage(cashMetadata.markup),
      description: null,
    },
  ];

  const {
    income: accrualIncomeTransactionDetails,
    costOfGoodsSold: accrualCogsTransactionDetails,
    expenses: accrualExpensesTransactionDetails,
    customerDeposits: accrualCustomerDeposits,
  } = generateTransactionDetails(accrualMetadata.accountTransactions || {});

  const accrualRows = [
    {
      name: 'Unapplied customer deposits accrued',
      value: asCurrency(accrualMetadata.totalProjectCustomerDeposits || 0),
      description: null,
      expandableBody:
        accrualMetadata.accountTransactions &&
        accrualMetadata.accountTransactions.customerDeposits &&
        accrualMetadata.accountTransactions.customerDeposits.length > 0 &&
        accrualCustomerDeposits,
      isVisible: !!accrualCustomerDeposits,
    },
    {
      name: 'Sales accrued',
      value: asCurrency(accrualMetadata.totalProjectIncome),
      description: null,
      expandableBody: accrualIncomeTransactionDetails,
    },
    {
      name: 'Cost of Goods Sold accrued',
      value: asCurrency(accrualMetadata.totalProjectCogs),
      description: null,
      expandableBody: accrualCogsTransactionDetails,
    },
    {
      name: 'Expenses accrued',
      value: asCurrency(accrualMetadata.totalProjectExpenses),
      description: null,
      isVisible: accrualMetadata.totalProjectExpenses !== 0,
      expandableBody: accrualExpensesTransactionDetails,
    },
    {
      name: 'Accrued profit',
      value: asCurrency(accrualMetadata.totalProfit),
      description: null,
    },
    {
      name: 'Margin',
      value: asPercentage(accrualMetadata.margin),
      description: null,
    },
    {
      name: 'Markup',
      value: asPercentage(accrualMetadata.markup),
      description: null,
    },
  ];

  let openInvoiceDetails = null;
  if (overviewMetadata.openInvoices) {
    openInvoiceDetails = (
      <OpenBalancesTable openInvoices={overviewMetadata.openInvoices} />
    );
  }

  const balanceRows = [
    {
      name: 'Owed from customers',
      value: asCurrency(overviewMetadata.owedFromCustomers),
      description:
        'This value is inclusive of sales tax, and is calculated based on the balance of open invoices in your accounting software.',
      expandableBody: openInvoiceDetails,
    },
    {
      name: 'Owed to suppliers',
      value: asCurrency(overviewMetadata.owedToSuppliers),
      description:
        'This value is an approximate and does not include sales tax. The approximate amount owed to suppliers is calculated using the difference between your accrual-based and cash-based Cost of Goods Sold and Expenses in your accounting software.',
    },
  ];

  const toTableRow = ({ row, rowId }) => (
    <DashboardReportTableRow
      key={rowId}
      {...row}
      setInfoToShow={setInfoToShow}
      isLoading={_.isEmpty(reportData.cash || reportData.accrual)}
    />
  );

  const cashSection = (
    <Grid item container direction="column" className={classes.reportSection}>
      <Grid item>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Cash Flow to Date
        </Typography>
      </Grid>
      <Grid item className={classes.tableWrapper}>
        {cashRows.map((row, i) => toTableRow({ row, rowId: `cash${i}` }))}
      </Grid>
    </Grid>
  );

  const accrualSection = (
    <Grid item container direction="column" className={classes.reportSection}>
      <Grid item>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Accruals to Date
        </Typography>
      </Grid>
      <Grid item className={classes.tableWrapper}>
        {accrualRows.map((row, i) => toTableRow({ row, rowId: `accrual${i}` }))}
      </Grid>
    </Grid>
  );

  const primarySection = (
    <>
      {accountingMethod === ACCOUNTING_METHOD.CASH.key && cashSection}
      {accountingMethod === ACCOUNTING_METHOD.ACCRUAL.key && accrualSection}
    </>
  );

  const secondarySection = (
    <>
      {accountingMethod === ACCOUNTING_METHOD.ACCRUAL.key && cashSection}
      {accountingMethod === ACCOUNTING_METHOD.CASH.key && accrualSection}
    </>
  );

  return (
    <>
      <div className={classes.reportWrapper}>
        <Paper elevation={4} className={classes.report}>
          <Grid
            container
            item
            style={{ height: '100%', overflowY: 'auto', paddingRight: 8 }}
          >
            <Grid container item justifyContent="center">
              <Typography variant="h5">{projectInfo.title}</Typography>
            </Grid>
            {accountingMethod !== null && (
              <>
                <Grid
                  item
                  container
                  direction="column"
                  className={classes.reportSection}
                >
                  <Grid item>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Overview
                    </Typography>
                  </Grid>
                  <Grid item className={classes.tableWrapper}>
                    {overviewDetails.map((row, i) =>
                      toTableRow({ row, rowId: `overview${i}` })
                    )}
                  </Grid>
                </Grid>
                {primarySection}
                {showSecondary && secondarySection}
                <Grid
                  item
                  container
                  direction="column"
                  className={classes.reportSection}
                  xs={12}
                >
                  <Grid item>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Amounts Outstanding
                    </Typography>
                  </Grid>
                  <Grid item className={classes.tableWrapper}>
                    {balanceRows.map((row, i) =>
                      toTableRow({ row, rowId: `balance${i}` })
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </div>
      {infoToShow && (
        <Dialog
          open
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{infoToShow.title}</DialogTitle>
          <DialogContent dividers style={{ paddingTop: 20, paddingBottom: 20 }}>
            <DialogContentText style={{ color: palette.text.primary }}>
              {infoToShow.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setInfoToShow(null)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ProjectProfitabilityReport;
