import gql from 'graphql-tag';

export default gql`
  query ListScoreboardSettings($companyId: String!) {
    listScoreboardSettings(companyId: $companyId) {
      items {
        scoreboardName
        breakevenPeriod
        accountingMethod
        customerDepositAccounts
        billableHoursFilters {
          uniqueName
          reportPeriod
          groupBy
          timezone
          customPeriodStartDate
          customPeriodEndDate
          userIds
          customerIds
          projectIds
        }
      }
    }
  }
`;
