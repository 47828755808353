import gql from 'graphql-tag';

export default gql`
  mutation MoveContentToOtherProject(
    $companyId: String!
    $contentToMoveId: String!
    $oldProjectId: String!
    $newProjectId: String!
  ) {
    moveContentToOtherProject(
      input: {
        companyId: $companyId
        contentToMoveId: $contentToMoveId
        oldProjectId: $oldProjectId
        newProjectId: $newProjectId
      }
    ) {
      customerId
      contentId
      jrnId
      synced
      contentUrl
      aspectRatio
      description
      date
      dateCreated
      startDate
      endDate
      labels
      latitude
      longitude
      isPublic
      assignedTo
      contentStatus
      priority
      type
      subtype
      permissionsFrom
      title
      requireAdmin
      completionType
      completionInfo {
        instructions
        completedById
        completedByDate
        textResponse
        mediaInfo {
          uri
          aspectRatio
        }
      }
      amount {
        value
      }
      recurrence {
        rangeId
        rangeStartDate
        rangeEndDate
        daysOfTheWeek
      }
      useTimetracking
      useTimetrackingQuestions
      timetrackingQuestions
      timetrackingQuestionsCheckout
      timetrackingAnswers {
        questionId
        answer
      }
      fromTemplate
      timetrackingType
      workflowStage
      workflowStageReason
      qboCustomerId
      globalExpenseId
      billable
    }
  }
`;
