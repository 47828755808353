import React from 'react';
import { Grid, Button, Tooltip } from '@material-ui/core';

import { DashboardControlBar } from '../../dashboard';

const BillableHoursControlBar = ({ csvExportUrl, loading, title }) => {
  const handleDownloadClick = () => {
    const a = document.createElement('a');
    a.href = csvExportUrl;
    a.download = `breakeven-data.csv`;
    a.click();
  };

  return (
    <>
      <DashboardControlBar title={title}>
        <Grid item>
          <Tooltip title="Export to CSV">
            <span>
              <Button
                color="primary"
                variant="outlined"
                disabled={loading}
                onClick={handleDownloadClick}
              >
                Export
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </DashboardControlBar>
    </>
  );
};

export default BillableHoursControlBar;
