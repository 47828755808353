import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo-hooks';
import { Image } from 'cloudinary-react';
import cloudinary from 'cloudinary-core';
import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Button,
  Grid,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  ButtonBase,
} from '@material-ui/core';
import {
  ArrowBackIos as ArrowBackIosIcon,
  Wallpaper as WallpaperIcon,
  Dashboard as DashboardIcon,
} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';

import ContentImage from '../ProjectContentListing/ContentTypes/content-image';
import ContentGallery from '../ProjectContentListing/ContentTypes/contentGallery';
import ContentVideo from '../ProjectContentListing/ContentTypes/contentVideo';
import ContentTextnote from '../ProjectContentListing/ContentTypes/contentTextnote';
import LeadChip from '../lead-chip/lead-chip';
import OkCancelDialog from '../OkCancelDialog/okCancelDialog';
import ChangeLeadStatusDialog from '../change-lead-status/change-lead-status-dialog';
import QboSyncActionButton from '../qbo-sync-action-button';

import GetJrn from '../../graphql/queries/GetJrn';
import GetAllContentByJrnId from '../../graphql/queries/GetAllContentByJrnId';

import { renderDateString } from '../../helpers/renderDateString';
import { googlifyAddress } from '../../helpers';
import {
  cloudinaryDownloadUrl,
  downloadAFileFromAUrl,
  publicIdFromUrl,
} from '../../helpers/cloudinary';

import {
  cloudindaryCloudName,
  LEAD_STATUS_IN_PROGRESS_ACCEPTED,
  CONTENT_DEFINITION,
  QBO_SYNCABLE_TYPE,
  TOP_PROJECT_ID,
} from '../../config/appDefaults';

const useStyles = makeStyles(theme => ({
  footerWrapper: {
    width: '100%',
    padding: theme.spacing(1),
    borderBottom: `2px solid ${theme.palette.brandColorPrimary}`,
  },
  descriptionWrapper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    display: 'block',
  },
  coverImage: {
    maxWidth: '100%',
  },
  projectButtonsWrapper: {
    width: '100%',
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

const SingleLead = ({
  onUpdateLead,
  onAddEditLeadContent,
  onCreateProjectFromLead,
  backToCustomerContent,
  onDeleteLead,
  handleDeleteContent,
  onUpdateContentStatus,
  goToProject,
  customerInfo,
  managingCompanyInfo,
}) => {
  const classes = useStyles();
  const { leadId } = useParams();

  const [headerAnchorEl, setHeaderAnchorEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogInfo, setDialogInfo] = React.useState({ open: false });
  const [showChangeLeadStatusDialog, setShowChangeLeadStatusDialog] = useState({
    open: false,
  });
  const [tabIndex, setTabIndex] = useState(0);
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  // //////////////////////////////////////
  // START - Lead Info/Content
  // getcontentbyId
  const leadDetailsQuery = useQuery(GetJrn, {
    variables: { jrnId: leadId },
    fetchPolicy: 'cache-and-network',
  });
  const leadDetails = _.get(leadDetailsQuery, 'data.getJrn', {});
  const leadDetailsLoading = _.get(leadDetailsQuery, 'loading');
  const hasBeenConverted =
    leadDetails && leadDetails.jrnId && leadDetails.jrnId !== TOP_PROJECT_ID;

  // getcontentByJrnId
  const contentQuery = useQuery(GetAllContentByJrnId, {
    variables: { jrnId: leadId },
    fetchPolicy: 'cache-and-network',
  });
  const allContent = _.get(contentQuery, 'data.getAllContentByJrnId.items', []);
  const allContentLoading = _.get(contentQuery, 'loading');
  // END -  Lead Info/Content
  // //////////////////////////////////////////////

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleHeaderOptions = event => {
    setHeaderAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setHeaderAnchorEl(null);
  };
  const handleEditContent = contentPiece => {
    onAddEditLeadContent({ leadDetails, contentDetails: contentPiece });
  };

  const startLeadDeleteProcess = () => {
    handleClose();
    setDialogInfo({
      title: 'Just making sure...',
      message: `Are you sure you want to delete this lead?`,
      open: true,
      onClose: () => setDialogInfo({ ...dialogInfo, open: false }),
      hideCancel: false,
      onConfirm: async () => {
        await onDeleteLead(leadDetails);
        // Go back to leads list
        backToCustomerContent();
      },
    });
  };

  const startContentDeleteProcess = contentItem => {
    handleClose();
    setDialogInfo({
      title: 'Just making sure...',
      message: `Are you sure you want to delete this?`,
      open: true,
      onClose: () => setDialogInfo({ ...dialogInfo, open: false }),
      hideCancel: false,
      onConfirm: async () => {
        await handleDeleteContent({ contentItem });
      },
    });
  };
  const cl = new cloudinary.Cloudinary({
    cloud_name: cloudindaryCloudName,
    secure: true,
  });
  const imageTranformWidth = parseInt(window.innerWidth, 10) || 500;

  const pdfUrl = imageInfo => {
    if (_.includes(imageInfo, 'cloudinary')) {
      return cl.url(publicIdFromUrl(imageInfo), {
        quality: 'auto',
        width: imageTranformWidth,
        crop: 'scale',
        fetchFormat: 'pdf',
      });
    }
    return imageInfo;
  };
  const downloadAllFromItem = contentItem => {
    handleClose();
    const messWithThis = { ...contentItem };
    // get all the url's and prompt for downloads/saving
    // contentItem.contentUrl
    let imageObjects = [];

    if (messWithThis.type === 'pdf') {
      let urlObject;
      try {
        urlObject = JSON.parse(messWithThis.contentUrl);
      } catch (error) {
        urlObject = null;
      }
      if (urlObject) {
        const { uri } = urlObject;
        messWithThis.contentUrl = pdfUrl(uri);
      }
    }
    if (messWithThis.type === 'gallery') {
      try {
        imageObjects = JSON.parse(messWithThis.contentUrl) || [];
      } catch (e) {
        imageObjects = null;
      }
      if (!imageObjects || imageObjects.length === 0) {
        return;
      }
    } else {
      imageObjects.push({ uri: messWithThis.contentUrl });
    }
    imageObjects.forEach((singleImageUri, index) => {
      const downloadUrl = cloudinaryDownloadUrl(singleImageUri.uri);
      // trigger download of it
      setTimeout(() => downloadAFileFromAUrl(downloadUrl), index * 1000);
    });
  };

  const renderMenu = contentItem => {
    // Can edit, either full edit or admin field edit
    const canEdit = true;
    // Can delete
    const canDelete = true;

    // Can download
    const canDownload =
      contentItem.type === 'gallery' ||
      contentItem.type === 'image' ||
      contentItem.type === 'video' ||
      contentItem.type === 'pdf';

    // Show options
    const showOptions = {
      edit: canEdit,
      delete: canDelete,
      download: canDownload,
    };

    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleEditContent(contentItem);
          }}
          className={classes.actionButtons}
        >
          <EditIcon />
          &nbsp;&nbsp;Edit This
        </MenuItem>
        {canDownload && (
          <MenuItem
            onClick={() => downloadAllFromItem(contentItem)}
            className={classes.actionButtons}
          >
            <GetAppIcon />
            &nbsp;&nbsp;Download
            {contentItem.type === 'gallery' ? ' All' : null}
          </MenuItem>
        )}
        {canDelete && (
          <MenuItem
            onClick={() => startContentDeleteProcess(contentItem)}
            className={classes.actionButtons}
          >
            <DeleteIcon />
            &nbsp;&nbsp;Delete This
          </MenuItem>
        )}

        {/* add the opposite of all the conditional statements above to this one so we can show a "no options" message if no options will show */}
        {/* eventually put them in an array and check the length, this is hacky */}
        {!showOptions.edit && !showOptions.download && !showOptions.delete && (
          <MenuItem className={classes.actionButtons} onClick={handleClose}>
            <CloseIcon />
            &nbsp;&nbsp;No Options
          </MenuItem>
        )}
      </Menu>
    );
  };

  const renderMore = notEdge => {
    return (
      <IconButton
        className={classes.moreButton}
        edge={notEdge ? null : 'end'}
        aria-label="delete"
        onClick={handleClick}
      >
        <MoreHorizIcon fontSize="inherit" />
      </IconButton>
    );
  };

  const renderContentHeader = contentPiece => {
    const dateString = renderDateString(
      contentPiece.date,
      null,
      'MMM D, YYYY @ h:mma'
    );

    return (
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        className={classes.headerWrapper}
      >
        <Grid item>
          {dateString}
          {renderMenu(contentPiece)}
          {renderMore()}
        </Grid>
      </Grid>
    );
  };

  const renderContentFooter = contentPiece => (
    <Grid container direction="column" className={classes.footerWrapper}>
      <Grid item>
        {!!contentPiece.description &&
          contentPiece.type !== 'textnote' &&
          contentPiece.type !== 'task' &&
          contentPiece.type !== 'punchlistItem' && (
            <Grid
              container
              direction="row"
              className={classes.descriptionWrapper}
            >
              <div className="levelParsedHtml">
                {ReactHtmlParser(contentPiece.description)}
              </div>
            </Grid>
          )}
      </Grid>
    </Grid>
  );

  const renderContentContent = contentPiece => {
    switch (contentPiece.type) {
      case 'pdf':
      case 'image': {
        return (
          <ContentImage
            key={contentPiece.contentId}
            contentPiece={contentPiece}
          />
        );
      }
      case 'gallery': {
        return (
          <ContentGallery
            key={contentPiece.contentId}
            noMaxHeight
            contentPiece={contentPiece}
          />
        );
      }
      case 'video': {
        return (
          <ContentVideo
            key={contentPiece.contentUrl} // video wasnt visually updating without this on edit
            contentPiece={contentPiece}
          />
        );
      }
      case 'textnote': {
        return (
          <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <ContentTextnote
              key={contentPiece.contentId}
              contentPiece={contentPiece}
            />
          </div>
        );
      }
      default: {
        break;
      }
    }
    return null;
  };

  const renderEachContent = contentPiece => {
    return (
      <React.Fragment key={contentPiece.contentId}>
        {renderContentHeader(contentPiece)}
        {renderContentContent(contentPiece)}
        {renderContentFooter(contentPiece)}
      </React.Fragment>
    );
  };

  const handleAddContent = () => {
    // need to show addWhatToProject Modal with the options limited to just photo/vidoe/notes/docs
    onAddEditLeadContent({ leadDetails });
  };

  const handleEditLead = () => {
    onUpdateLead({ leadDetails });
  };

  const handleChipClick = () => {
    setShowChangeLeadStatusDialog({
      open: true,
      contentToChange: leadDetails,
    });
  };

  const handleContentStatusChange = async newStatus => {
    const toPass = {
      ...showChangeLeadStatusDialog.contentToChange,
      contentStatus: newStatus,
    };
    if (toPass.budgets) {
      toPass.budgets.forEach((answer, budgetIndex) => {
        delete toPass.budgets[budgetIndex].__typename;
      });
    }

    await onUpdateContentStatus(toPass);
    const doesntHaveProjectYet =
      !toPass.jrnId || toPass.jrnId === TOP_PROJECT_ID;
    if (
      newStatus === LEAD_STATUS_IN_PROGRESS_ACCEPTED &&
      doesntHaveProjectYet
    ) {
      setDialogInfo({
        title: 'Successfully Updated Status',
        message: 'Would you like to create a project from this lead?',
        open: true,
        onClose: () => setDialogInfo({ ...dialogInfo, open: false }),
        hideCancel: false,
        okButtonText: 'Yes',
        cancelButtonText: 'No',
        onConfirm: async () => {
          onCreateProjectFromLead(toPass);
        },
      });
    }
  };

  const handleGoToProject = () => {
    goToProject(leadDetails.jrnId);
  };

  const handleCreateProjectFromLead = () => {
    onCreateProjectFromLead(leadDetails);
  };

  const createOrNavigate = () => {
    if (hasBeenConverted) {
      handleGoToProject();
    } else {
      handleCreateProjectFromLead();
    }
  };

  const renderLeadOptionsMenu = () => {
    return (
      <Grid item className={classes.projectButtonsWrapper}>
        {!hasBeenConverted &&
          managingCompanyInfo &&
          managingCompanyInfo.isCustomerSyncEnabled && (
            <QboSyncActionButton
              recordInfo={leadDetails}
              recordType={QBO_SYNCABLE_TYPE.LEAD}
              parentCustomerInfo={customerInfo}
            />
          )}
        <Tooltip title="Add Content">
          <IconButton
            onClick={() => {
              handleClose();
              handleAddContent();
            }}
            variant="contained"
            className={classes.closeButton}
            aria-label="add content"
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Lead Options">
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleHeaderOptions}
            className={classes.closeButton}
          >
            <MoreHorizIcon />
          </IconButton>
        </Tooltip>

        <Menu
          id="simple-menu"
          anchorEl={headerAnchorEl}
          keepMounted
          open={Boolean(headerAnchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            className={classes.actionButtons}
            onClick={() => {
              handleClose();
              handleEditLead();
            }}
          >
            <EditIcon />
            <>&nbsp;&nbsp;Edit Lead</>
          </MenuItem>
          <MenuItem
            className={classes.actionButtons}
            onClick={() => {
              handleClose();
              createOrNavigate();
            }}
          >
            <DashboardIcon />
            <>
              &nbsp;&nbsp;
              {hasBeenConverted ? 'Go To Project' : 'Create Project From Lead'}
            </>
          </MenuItem>
          <MenuItem
            onClick={startLeadDeleteProcess}
            className={classes.actionButtons}
          >
            <DeleteIcon />
            <>&nbsp;&nbsp;Delete Lead</>
          </MenuItem>
        </Menu>
      </Grid>
    );
  };

  const renderEmptyMessage = type => {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ padding: 32 }}
      >
        <Typography variant="body1" align="center">
          No {type} have been added yet.
        </Typography>
      </Grid>
    );
  };

  let pictures = [];
  let notes = [];
  let docs = [];
  let videos = [];
  if (allContent && allContent.length) {
    pictures = _.filter(
      allContent,
      content => content.type === 'image' || content.type === 'gallery'
    );
    notes = _.filter(allContent, content => content.type === 'textnote');
    docs = _.filter(allContent, content => content.type === 'pdf');
    videos = _.filter(allContent, content => content.type === 'video');
  }
  let picturesTabWording = CONTENT_DEFINITION.image.pluralName;
  if (pictures.length) {
    picturesTabWording += ` (${pictures.length})`;
  }
  let notesTabWording = CONTENT_DEFINITION.textnote.pluralName;
  if (notes.length) {
    notesTabWording += ` (${notes.length})`;
  }
  let docsTabWording = 'Docs';
  if (docs.length) {
    docsTabWording += ` (${docs.length})`;
  }
  let videosTabWording = CONTENT_DEFINITION.video.pluralName;
  if (videos.length) {
    videosTabWording += ` (${videos.length})`;
  }

  const renderCoverImageArea = () => {
    if (leadDetailsLoading) return null;
    if (leadDetails.contentUrl) {
      return (
        <Image
          publicId={publicIdFromUrl(leadDetails.contentUrl)}
          cloudName={cloudindaryCloudName}
          className={classes.coverImage}
          alt="project cover"
          transformation={{
            quality: 'auto',
            width: imageTranformWidth,
            crop: 'scale',
            fetchFormat: 'jpg',
          }}
        />
      );
    }
    return (
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ color: '#666' }}
      >
        <Grid item style={{ fontSize: 60 }}>
          <WallpaperIcon fontSize="inherit" color="inherit" />
        </Grid>
        <Grid item>
          <Typography variant="body2">No cover photo</Typography>
        </Grid>
      </Grid>
    );
  };

  const renderBackButton = () => {
    return (
      <Button
        onClick={backToCustomerContent}
        style={{ marginTop: 16, marginBottom: 16 }}
      >
        <ArrowBackIosIcon /> Back To Customer&apos;s Leads List
      </Button>
    );
  };

  const dateString = renderDateString(
    leadDetails.startDate,
    leadDetails.endDate,
    'short'
  );

  if (!leadDetailsLoading && leadDetails.type !== 'lead') {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Typography variant="h3" align="center">
            This content either does not exist or has been converted to a
            project.
          </Typography>
        </Grid>
        <Grid item>{renderBackButton()}</Grid>
      </Grid>
    );
  }

  return (
    <>
      {renderBackButton()}
      <Paper style={{ padding: 16 }}>
        <Grid
          container
          direction="column"
          style={{ maxWidth: '100%', marginBottom: 16 }}
        >
          <Grid item container direction="row">
            <Grid
              item
              xs={4}
              style={{
                minHeight: 200,
                background: '#ddd',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {renderCoverImageArea()}
            </Grid>
            <Grid item xs={8} container>
              <Grid container direction="column">
                <Grid container item justifyContent="flex-end">
                  {renderLeadOptionsMenu()}
                </Grid>
                <Grid item style={{ paddingLeft: 16 }}>
                  {leadDetailsLoading && <CircularProgress />}
                  {!leadDetailsLoading && (
                    <>
                      <Typography variant="h3" color="primary">
                        {leadDetails.title}
                      </Typography>
                      {leadDetails.address && (
                        <Typography variant="h5">
                          <a
                            href={googlifyAddress(
                              leadDetails.address,
                              'search'
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="basicStyledLink"
                          >
                            {leadDetails.address}
                          </a>
                        </Typography>
                      )}
                      <Typography variant="h5" color="primary">
                        {dateString}
                      </Typography>
                      {!!leadDetails.description && (
                        <>
                          <Typography variant="body1">
                            {leadDetails.description}
                          </Typography>
                        </>
                      )}
                      {!!leadDetails.contentStatus && (
                        <div style={{ marginTop: 16 }}>
                          <ButtonBase onClick={handleChipClick}>
                            <LeadChip
                              contentStatus={leadDetails.contentStatus}
                              justifyContent="flex-start"
                            />
                          </ButtonBase>
                        </div>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          style={{ maxWidth: '100%', wordBreak: 'break-all' }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            style={{ marginBottom: 8 }}
          >
            <Tab label={picturesTabWording} />
            <Tab label={videosTabWording} />
            <Tab label={notesTabWording} />
            <Tab label={docsTabWording} />
          </Tabs>
          {allContentLoading && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ padding: 32 }}
            >
              <CircularProgress />
            </Grid>
          )}
          {!allContentLoading && (
            <>
              {tabIndex === 0 && (
                <Grid container item>
                  {pictures.length > 0
                    ? pictures.map(renderEachContent)
                    : renderEmptyMessage('pictures')}
                </Grid>
              )}
              {tabIndex === 1 && (
                <Grid container item>
                  {videos.length > 0
                    ? videos.map(renderEachContent)
                    : renderEmptyMessage('videos')}
                </Grid>
              )}
              {tabIndex === 2 && (
                <Grid container item>
                  {notes.length > 0
                    ? notes.map(renderEachContent)
                    : renderEmptyMessage('notes')}
                </Grid>
              )}
              {tabIndex === 3 && (
                <Grid container item>
                  {docs.length > 0
                    ? docs.map(renderEachContent)
                    : renderEmptyMessage('docs')}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Paper>
      {dialogInfo.open && (
        <OkCancelDialog {...dialogInfo}>
          <Typography>{dialogInfo.message}</Typography>
        </OkCancelDialog>
      )}
      {showChangeLeadStatusDialog.open && (
        <ChangeLeadStatusDialog
          toClose={() => setShowChangeLeadStatusDialog(false)}
          initialStatus={
            showChangeLeadStatusDialog.contentToChange
              ? showChangeLeadStatusDialog.contentToChange.contentStatus
              : null
          }
          onConfirm={handleContentStatusChange}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
  };
}

export default withRouter(connect(mapStateToProps)(SingleLead));
