import React, { forwardRef } from 'react';
import { Avatar } from '@material-ui/core';

import levelLogo from '../../assets/images/Level-logo-192.png';

// use forwardRef to supress warning "Function components cannot be given refs"
// https://mui.com/material-ui/guides/composition/#caveat-with-refs
const LevelAccountingLogo = forwardRef(({ style, ...otherProps }, ref) => {
  return (
    <Avatar
      alt="Level Accounting Logo"
      src={levelLogo}
      style={{ height: 20, width: 20, ...style }}
      {...otherProps}
      ref={ref}
    />
  );
});

export default LevelAccountingLogo;
