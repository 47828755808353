import React, { useMemo } from 'react';
import { Collapse, Grid } from '@material-ui/core';
import _ from 'lodash';

import palette from '../../../theme/palette';
import BookkeepingAlertsToggle, {
  COMPONENT_TYPES,
} from '../bookkeeping-alerts-toggle';

import { BOOKKEEPING_REPORT_EVENT_META_STATE } from '../bookkeeping-alerts.constants';

const BookkeepingAlertsReportAlertListItemTableCellCheckboxArray = ({
  alertInstanceIds,
  addressedStateEventMetaMap,
  processingAlertInstanceMap,
  onChange,
  expanded,
}) => {
  const { allChecked, indeterminate } = useMemo(() => {
    if (_.isEmpty(alertInstanceIds)) {
      return { allChecked: false, indeterminate: false };
    }

    let checkedCount = 0;
    _.forEach(alertInstanceIds, alertInstanceId => {
      if (
        addressedStateEventMetaMap?.[alertInstanceId]?.value ===
        BOOKKEEPING_REPORT_EVENT_META_STATE.ADDRESSED
      ) {
        checkedCount += 1;
      }
    });

    if (checkedCount === alertInstanceIds.length) {
      return { allChecked: true, indeterminate: false };
    }

    return { allChecked: false, indeterminate: checkedCount > 0 };
  }, [alertInstanceIds, addressedStateEventMetaMap]);

  const processingCheckAll = useMemo(() => {
    return _.some(alertInstanceIds, alertInstanceId => {
      return processingAlertInstanceMap[alertInstanceId];
    });
  }, [alertInstanceIds, processingAlertInstanceMap]);

  const handleToggle = ({ alertInstanceId }) => event => {
    const newValue = event.target.checked
      ? BOOKKEEPING_REPORT_EVENT_META_STATE.ADDRESSED
      : '';

    onChange([{ alertInstanceId, newValue }]);
  };

  const handleToggleAll = event => {
    const newValue = event.target.checked
      ? BOOKKEEPING_REPORT_EVENT_META_STATE.ADDRESSED
      : '';

    const changedItems = alertInstanceIds.map(alertInstanceId => ({
      alertInstanceId,
      newValue,
    }));

    onChange(changedItems);
  };

  return (
    <Grid container direction="column">
      <Grid
        item
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <BookkeepingAlertsToggle
          component={COMPONENT_TYPES.CHECKBOX}
          checked={allChecked}
          indeterminate={indeterminate}
          isLoading={processingCheckAll}
          tooltip={allChecked ? 'Uncheck All' : 'Check All'}
          onToggle={handleToggleAll}
          style={{ color: palette.brandColorPrimary }}
        />
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {_.map(alertInstanceIds, (alertInstanceId, index) => (
          <Grid
            item
            key={alertInstanceId}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              height: 38,
              ...(index % 2 === 0 && {
                backgroundColor: palette.brandColorLightGrey,
              }),
            }}
          >
            <BookkeepingAlertsToggle
              component={COMPONENT_TYPES.CHECKBOX}
              checked={
                addressedStateEventMetaMap?.[alertInstanceId]?.value ===
                BOOKKEEPING_REPORT_EVENT_META_STATE.ADDRESSED
              }
              isLoading={processingAlertInstanceMap[alertInstanceId]}
              size="small"
              onToggle={handleToggle({ alertInstanceId })}
            />
          </Grid>
        ))}
      </Collapse>
    </Grid>
  );
};

export default BookkeepingAlertsReportAlertListItemTableCellCheckboxArray;
