const awsconfig = {
  aws_app_analytics: process.env.REACT_APP_AWS_APP_ANALYTICS,
  aws_mandatory_sign_in: 'enable',
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  federationTarget: 'COGNITO_USER_POOLS',
  aws_mobile_analytics_app_id:
    process.env.REACT_APP_AWS_MOBILE_ANALYTICS_APP_ID,
  aws_mobile_analytics_app_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_graphqlEndpoint:
    process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};

export default awsconfig;
