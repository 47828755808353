import React from 'react';
import { Grid, Avatar, Paper } from '@material-ui/core';
import {
  Dashboard as DashboardIcon,
  Group as GroupIcon,
  Person as PersonIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import ContentLoader from 'react-content-loader';
import { cloudinaryifyProfilePic } from '../../helpers/cloudinary';
import { renderDateString } from '../../helpers/renderDateString';

const useStyles = makeStyles(theme => {
  return {
    paper: props => {
      return {
        margin: `${theme.spacing(1)}px auto 0 auto`,
        padding: theme.spacing(2),
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.brandColorPrimary,
          color: '#fff',
        },
        backgroundColor: props.highlightSelected
          ? theme.palette.brandColorPrimary
          : null,
        color: props.highlightSelected ? '#fff' : null,

        transition: 'all 0.3s ease',
        width: '100%',
      };
    },
    bigAvatar: {
      height: 80,
      width: 80,
    },
    convoDate: {
      fontSize: '80%',
    },
  };
});

const SingleProject = props => {
  const { convo, onClickOfProject, loading } = props;
  const classes = useStyles(props);

  if (loading) {
    return (
      <Paper className={classes.paper}>
        <ContentLoader
          height={80}
          width={300}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
          style={{ maxHeight: 80, maxWidth: 300 }}
        >
          <circle cx="40" cy="40" r="40" />
          <rect x="110" y="26" rx="0" ry="0" width="168" height="11" />
          <rect x="110" y="42" rx="0" ry="0" width="168" height="11" />
        </ContentLoader>
      </Paper>
    );
  }

  return (
    <Paper
      className={classes.paper}
      onClick={() => onClickOfProject(convo)}
      key={convo.contentId}
    >
      <Grid container alignItems="center" wrap="nowrap" spacing={3}>
        <Grid item>
          <Avatar
            alt="conversation cover"
            src={
              convo.thumbnailSrc
                ? cloudinaryifyProfilePic(convo.thumbnailSrc)
                : null
            }
            className={classes.bigAvatar}
          >
            {convo.type === 'project' && !convo.thumbnailSrc && (
              <DashboardIcon />
            )}
            {convo.type === 'conversation' &&
              !convo.thumbnailSrc &&
              convo.usersEverOnJrn &&
              convo.usersEverOnJrn.length > 2 && <GroupIcon />}
            {convo.type === 'conversation' &&
              !convo.thumbnailSrc &&
              convo.usersEverOnJrn &&
              convo.usersEverOnJrn.length <= 2 && <PersonIcon />}
          </Avatar>
        </Grid>
        <Grid item>
          <div>{convo.title}</div>
          {!convo.isDirectMessage && (
            <div className={classes.convoDate}>
              {renderDateString(convo.startDate, convo.endDate, 'short')}
            </div>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SingleProject;
