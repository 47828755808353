import React from 'react';
import {
  Typography,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import OpenBalancesSection from './project-profitability-report-open-balances-section';

const StyledTableCell = withStyles(() => ({
  root: {
    fontSize: 12,
  },
}))(TableCell);

const renderCustomerSection = ({
  customerName,
  openInvoices,
  subcustomers,
  parents = [],
}) => {
  const children = [];

  if (openInvoices.length > 0) {
    children.push(
      <OpenBalancesSection
        key={customerName}
        customerName={customerName}
        openInvoices={openInvoices}
        parents={parents}
      />
    );
  }

  subcustomers.forEach(subcustomer => {
    const subcustomerSections = renderCustomerSection({
      ...subcustomer,
      parents: [...parents, customerName],
    });

    children.push(...subcustomerSections);
  });

  return children;
};

const OpenBalancesTable = ({ openInvoices }) => {
  return (
    <Paper variant="outlined" style={{ margin: 8, padding: 8 }}>
      <Typography variant="body1" gutterBottom>
        Open Invoices
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Due Date</StyledTableCell>
            <StyledTableCell>Invoice No.</StyledTableCell>
            <StyledTableCell align="right">Amount</StyledTableCell>
            <StyledTableCell align="right">Open Balance</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderCustomerSection(openInvoices)}</TableBody>
      </Table>
    </Paper>
  );
};

export default OpenBalancesTable;
