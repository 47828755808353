import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';

const CalendarDetailsGoToProjectButton = ({ projectId }) => {
  return (
    <Link to={`/projects/${projectId}`}>
      <Button variant="contained" color="primary">
        Go To Project&nbsp;&nbsp;
        <ArrowForwardIcon />
      </Button>
    </Link>
  );
};

export default CalendarDetailsGoToProjectButton;
