import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import BasicDialog from '../basic-dialog/basic-dialog';

const upgradeRequiredComponent = props => {
  const { handleClose } = props;

  return (
    <BasicDialog
      open
      handleClose={handleClose}
      title="Upgrade Required"
      customChildren
    >
      <Typography variant="h6">
        Like in any sports game, your business has a scoreboard, and its
        scoreboard is its financials. Upgrade your subscription to Level
        Bookkeeping or CFO services to see how your business is performing, and
        identify trends in profits, cash flow, and more, so that you can make
        better business decisions. Book a call with us today to learn more!
      </Typography>
      <Grid container justifyContent="center" alignItems="center">
        <Button
          href="https://www.checkthelevel.com/book-a-demo"
          target="_blank"
          rel="noopener noreferrer"
          color="primary"
          variant="contained"
          style={{
            marginTop: 32,
            marginBottom: 16,
            paddingLeft: 40,
            paddingRight: 40,
          }}
        >
          Book a call
        </Button>
      </Grid>
    </BasicDialog>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(upgradeRequiredComponent);
