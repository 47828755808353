import moment from 'moment';
import numeral from 'numeral';

const isAnInteger = (numberToCheck, asPercentage = false) => {
  const aVerySmallTolerance = 1e-6;
  let thisNumber = numberToCheck;
  if (asPercentage) {
    thisNumber *= 100;
  }

  if (thisNumber % 1 < aVerySmallTolerance) {
    return true;
  }
  return false;
};

/**
 * Convert month name to month index
 * @param {String} monthAsString
 * @returns {Number}
 */
const getMonthIndex = monthAsString => {
  switch (monthAsString) {
    case 'January':
      return 0;
    case 'February':
      return 1;
    case 'March':
      return 2;
    case 'April':
      return 3;
    case 'May':
      return 4;
    case 'June':
      return 5;
    case 'July':
      return 6;
    case 'August':
      return 7;
    case 'September':
      return 8;
    case 'October':
      return 9;
    case 'November':
      return 10;
    case 'December':
      return 11;
    default:
      throw new Error('Month index not found.');
  }
};

const toEndOfQuarter = (momentDate, fiscalYearStartMonth = 'January') => {
  const startMonthIndex = getMonthIndex(fiscalYearStartMonth);
  const currentMonthIndex = momentDate.month();
  let quarterEndMonth = 0;
  let yearAddition = 0;
  if (currentMonthIndex >= startMonthIndex) {
    for (let i = 0; i < 4; i += 1) {
      const start = startMonthIndex + i * 3;
      const end = start + 2;
      if (start <= currentMonthIndex && currentMonthIndex <= end) {
        quarterEndMonth = end % 12;
        yearAddition = Math.floor(end / 12);
        break;
      }
    }
  } else {
    for (let i = 0; i < 4; i += 1) {
      const end = startMonthIndex - 1 - i * 3;
      const start = end - 2;
      if (start <= currentMonthIndex && currentMonthIndex <= end) {
        quarterEndMonth = (12 + end) % 12;
        yearAddition = 0;
        break;
      }
    }
  }
  momentDate.year(momentDate.year() + yearAddition);
  momentDate.month(quarterEndMonth);
  momentDate.endOf('month');
};

/**
 * Returns a formatted string
 * @param {*} value The value to be transformed
 * @param {'number' | 'percentage' | quarter | 'month' | 'week' | 'day' | 'money' | 'hour' | 'time'} type The type used for formatting
 * @param {*} isAxis Determines whether to use abbreviation or full
 * @returns {String}
 */
const prettyString = (
  value,
  type = 'number',
  isAxis = false,
  etc,
  showDayOfWeek,
  showDateRange,
  fiscalYearStartMonth = 'January',
  showUtc = true
) => {
  if (type === 'percentage') {
    if (isAnInteger(value, true)) {
      return numeral(value).format('0%');
    }
    return numeral(value).format('0.0%');
  }
  if (type === 'day') {
    let momentDate;
    if (showUtc) {
      momentDate = moment(value).utc();
    } else {
      momentDate = moment(value);
    }

    if (isAxis) {
      return momentDate.format('M/D/YY');
    }
    return showDayOfWeek
      ? momentDate.format('ddd, MMM D, YYYY')
      : momentDate.format('MMM D, YYYY');
  }
  if (type === 'week') {
    const startOfPeriod = moment(value).utc();
    let endOfPeriod;
    if (etc) {
      endOfPeriod = moment(etc).utc();
      endOfPeriod.subtract(1, 'days');
    } else {
      endOfPeriod = startOfPeriod.clone();
      endOfPeriod.endOf('week');
    }

    if (isAxis) {
      return `${startOfPeriod.format('M/D/YY')}`;
    }
    return startOfPeriod.year() === endOfPeriod.year()
      ? `${startOfPeriod.format('MMM D')} - ${endOfPeriod.format(
          'MMM D, YYYY'
        )}`
      : `${startOfPeriod.format('MMM D, YYYY')} - ${endOfPeriod.format(
          'MMM D, YYYY'
        )}`;
  }
  if (type === 'month') {
    const momentDate = moment(value).utc();
    if (isAxis) {
      if (momentDate.month() === 0) {
        return momentDate.format('YYYY');
      }
      return momentDate.format('MMM');
    }
    if (showDateRange) {
      const startOfPeriod = moment(value).utc();
      let endOfPeriod;
      if (etc) {
        endOfPeriod = moment(etc).utc();
        endOfPeriod.subtract(1, 'days');
      } else {
        endOfPeriod = startOfPeriod.clone();
        endOfPeriod.endOf('month');
      }
      return startOfPeriod.year() === endOfPeriod.year()
        ? `${startOfPeriod.format('MMM D')} - ${endOfPeriod.format(
            'MMM D, YYYY'
          )}`
        : `${startOfPeriod.format('MMM D, YYYY')} - ${endOfPeriod.format(
            'MMM D, YYYY'
          )}`;
    }
    return momentDate.format('MMMM YYYY');
  }
  if (type === 'quarter') {
    const startOfPeriod = moment(value).utc();
    let endOfPeriod;
    if (etc) {
      endOfPeriod = moment(etc).utc();
      endOfPeriod.subtract(1, 'days');
    } else {
      endOfPeriod = startOfPeriod.clone();
      toEndOfQuarter(endOfPeriod, fiscalYearStartMonth);
    }

    if (isAxis) {
      return startOfPeriod.format('MMM, YYYY');
    }
    return startOfPeriod.year() === endOfPeriod.year()
      ? `${startOfPeriod.format('MMM D')} - ${endOfPeriod.format(
          'MMM D, YYYY'
        )}`
      : `${startOfPeriod.format('MMM D, YYYY')} - ${endOfPeriod.format(
          'MMM D, YYYY'
        )}`;
  }
  if (type === 'year') {
    const momentDate = moment(value).utc();
    if (isAxis) {
      return momentDate.format('YYYY');
    }
    if (showDateRange) {
      const startOfPeriod = moment(value).utc();
      let endOfPeriod;
      if (etc) {
        endOfPeriod = moment(etc).utc();
        endOfPeriod.subtract(1, 'days');
      } else {
        endOfPeriod = startOfPeriod.clone();
        endOfPeriod.endOf('year');
      }
      return `${startOfPeriod.format('MMM D')} - ${endOfPeriod.format(
        'MMM D, YYYY'
      )}`;
    }
    return momentDate.format('YYYY');
  }
  if (type === 'money') {
    let moneyString = numeral(value).format('$0,0.00');
    if (isAxis) {
      moneyString = numeral(value).format('$0.[00]a');
    }
    return moneyString;
  }
  if (type === 'hour') {
    let hourString = `${numeral(value).format('0,0.00')} hrs`;
    if (isAxis) {
      if (Number.isInteger(Number(value))) {
        hourString = numeral(value).format('0,0');
      } else {
        hourString = numeral(value).format('0,0.0');
      }
    }
    return hourString;
  }
  return Math.round(value);
};

export default prettyString;
