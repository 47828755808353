import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

import LevelLogo from '../../components/level-logo/level-logo';
import { useQueryStringParams } from '../../hooks';
import { useStyles } from './unsubscribe.styles';

import { UNSUBSCRIBE_TYPES } from '../../config/appDefaults';

const Unsubscribe = () => {
  const classes = useStyles();
  const queryStringParams = useQueryStringParams();
  const [unsubscribeData, setUnsubscribeData] = useState({});
  const [title, setTitle] = useState('Unsubscribe');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = React.useState('');
  const [isDone, setIsDone] = useState(false);

  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    const key = queryStringParams.get('key');

    // drop the query string
    window.history.replaceState({}, document.title, window.location.pathname);

    if (!key) {
      setRedirectTo('/');
      return;
    }

    try {
      const data = JSON.parse(Buffer.from(key, 'base64').toString('utf8'));
      setUnsubscribeData(data);

      switch (data.type) {
        case UNSUBSCRIBE_TYPES.BOOKKEEPING_ALERTS_LIST: {
          setTitle('Unsubscribe from Bookkeeping Alerts list');
          setMessage(
            <span>
              By clicking UNSUBSCRIBE, <strong>{data.email}</strong> will be
              removed from the Bookkeeping Alerts email recipient group and no
              longer receive these email notifications.
            </span>
          );
          break;
        }
        default: {
          setTitle('Unsubscribe');
          // eslint-disable-next-line no-console
          console.error('Unknown unsubscribe type:', data.type);
        }
      }
    } catch (err) {
      setMessage('Unexpected error occurred. Please try again!');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unsubscribeBookkeepingAlertsEmail = async ({
    type,
    companyId,
    emailListId,
    email,
  }) => {
    const requestBody = { type, companyId, emailListId, email };

    const response = await axios.post(
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/unsubscribe`,
      requestBody,
      { responseType: 'json' }
    );

    if (response?.status === 200) {
      setMessage(
        <>
          <strong>{email}</strong> was unsubscribed successfully. You will no
          longer receive emails from this Bookkeeping Alerts email recipient
          group.
        </>
      );
      setIsDone(true);
    } else {
      setMessage('Unexpected error occurred. Please try again!');
    }
  };

  const handleUnsubscribe = async () => {
    setIsLoading(true);
    try {
      switch (unsubscribeData.type) {
        case UNSUBSCRIBE_TYPES.BOOKKEEPING_ALERTS_LIST: {
          await unsubscribeBookkeepingAlertsEmail(unsubscribeData);
          break;
        }
        default: {
          // eslint-disable-next-line no-console
          console.error('Unknown unsubscribe type:', unsubscribeData.type);
        }
      }
    } catch (err) {
      setMessage('Unexpected error occurred. Please try again!');
    }

    setIsLoading(false);
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <div className={classes.backgroundImage} />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        elevation={20}
        component={Paper}
        className={classes.container}
      >
        <Grid container className={classes.subContainer}>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            style={{ flex: 1 }}
          >
            <Grid container item justifyContent="center">
              <Grid item xs={12}>
                <LevelLogo className={classes.levelLogo} />
              </Grid>
              <Grid item xs={12} className={classes.title}>
                {title}
              </Grid>
              <Grid item xs={12} className={classes.message}>
                <Typography variant="body1" className={classes.messageText}>
                  {message}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent="center"
            style={{ marginTop: 24 }}
          >
            {!isDone && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={isLoading}
                onClick={handleUnsubscribe}
              >
                {isLoading && <CircularProgress size={24} color="inherit" />}
                {!isLoading && 'Unsubscribe'}
              </Button>
            )}

            {isDone && (
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={() => {
                  setRedirectTo('/');
                }}
              >
                Back to app
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Unsubscribe;
