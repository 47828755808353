import gql from 'graphql-tag';

export default gql`
  mutation UpdateScoreboardSettings(
    $companyId: String!
    $scoreboardSettings: [ScoreboardSettingsInput]!
  ) {
    updateScoreboardSettings(
      input: { companyId: $companyId, scoreboardSettings: $scoreboardSettings }
    ) {
      items {
        scoreboardName
        breakevenPeriod
        accountingMethod
        customerDepositAccounts
        billableHoursFilters {
          uniqueName
          reportPeriod
          groupBy
          timezone
          customPeriodStartDate
          customPeriodEndDate
          userIds
          customerIds
          projectIds
        }
      }
    }
  }
`;
