import React from 'react';
import { Button, Tooltip, Typography, makeStyles } from '@material-ui/core';

import copyToClipboard from 'copy-to-clipboard';

const useStyles = makeStyles(theme => ({
  statModalLabel: {
    textTransform: 'uppercase',
  },
  statSectionWrapper: {
    paddingTop: 25,
  },
  statSectionHeader: {
    color: theme.palette.brandColorPrimary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    paddingBottom: 5,
  },
  statText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    paddingTop: 5,
    paddingBottom: 5,
  },
}));

const BillPaymentsViewStatsSection = ({ stat }) => {
  const classes = useStyles();

  return (
    <div className={classes.statSectionWrapper}>
      <Typography variant="h4" className={classes.statSectionHeader}>
        {stat.sectionHeader}
      </Typography>
      <Typography variant="h6" className={classes.statText}>
        <span className={classes.statModalLabel}># of Bill Payments</span>
        <Tooltip title="Click to copy">
          <Button
            onClick={() => {
              copyToClipboard(stat.entriesCount);
            }}
          >
            {stat.entriesCount}
          </Button>
        </Tooltip>
      </Typography>
      <Typography variant="h6" className={classes.statText}>
        <span className={classes.statModalLabel}>Total</span>
        <Tooltip title="Click to copy">
          <Button
            onClick={() => {
              copyToClipboard(stat.total);
            }}
          >
            {stat.total}
          </Button>
        </Tooltip>
      </Typography>
    </div>
  );
};

export default BillPaymentsViewStatsSection;
