import React from 'react';
import _ from 'lodash';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import InputContentLoader from '../input-content-loader/input-content-loader';

export const SELECT_ALL_VALUE = 'all';

const DashboardControlBarSelect = ({
  label,
  options,
  selected,
  loading,
  onChange,
  showOptionAll = false,
  keyPropName = 'key',
  valuePropName = 'key',
  displayValuePropName = 'displayValue',
}) => {
  return (
    <InputContentLoader loading={loading || !options} label={label}>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          value={selected}
          onChange={event => onChange(event.target.value)}
          MenuProps={{ style: { zIndex: 9999 } }}
        >
          {showOptionAll && (
            <MenuItem key={SELECT_ALL_VALUE} value={SELECT_ALL_VALUE}>
              All
            </MenuItem>
          )}
          {_.map(options, option => (
            <MenuItem key={option[keyPropName]} value={option[valuePropName]}>
              {option[displayValuePropName]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </InputContentLoader>
  );
};

export default DashboardControlBarSelect;
