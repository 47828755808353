const INITIAL_STATE = {
  navPassthrough: false,
  navPassthroughJrnId: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_JRN_NOTIFICATION_NAV': {
      return {
        navPassthrough: true,
        navPassthroughJrnId: action.navPassthroughJrnId,
      };
    }
    case 'RESET_NOTIFICATION_NAV': {
      return INITIAL_STATE;
    }
    case 'SIGNOUT_SUCCEEDED': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
