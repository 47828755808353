import React from 'react';
import { Checkbox, Tooltip, Button } from '@material-ui/core';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import moment from 'moment';
import _ from 'lodash';

import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';

import { simpleSortForAmount } from '../../../helpers/react-datagrid-helpers';
import { monetaryRender } from '../../../helpers';

import {
  CONTENT_DETAILS_MODAL_MODE,
  PAYMENT_METHOD_LABEL,
  PRODUCT,
  WORKFLOW_STAGE,
  WORKFLOW_STAGE_REASON,
} from '../../../config/appDefaults';

const actionButtonColumnWidth = 50;
const columnMinWidthSmall = 120;
// const columnMinWidthMedium = 180;
const columnMinWidthLarge = 240;

export const BILL_PAYMENTS_TABLE_COLUMN = {
  CONTENT_ID: 'contentId',
  ACTIONS: 'actions',
  USERNAME: 'username',
  VENDOR: 'vendor',
  PAYMENT_DATE: 'paymentDate',
  AMOUNT: 'amount',
  PAYMENT_STATUS: 'paymentStatus',
  DATE_ADDED: 'dateAdded',
  PAYMENT_METHOD: 'paymentMethod',
  IS_RECORDED: 'isRecorded',
  IS_RFI_REQUIRED: 'isRfiRequired',
};

export default ({
  classes,
  companyCrew,
  vendors,
  managingCompanyInfo,
  updateJrn,
  setShowContentDetailsModal,
}) => {
  const isBookkeepingCustomer = !!managingCompanyInfo?.isBookkeepingCustomer;
  const isCompanyBookkeeper = !!managingCompanyInfo?.isCompanyBookkeeper;

  const hasRfiProduct = !!managingCompanyInfo?.products?.includes(PRODUCT.RFI);

  const renderDate = ({ value }) => {
    if (!value) return 'n/a';
    return moment(value).format('MMM D, YYYY');
  };

  const defaultSortInfo = [
    {
      name: BILL_PAYMENTS_TABLE_COLUMN.PAYMENT_DATE,
      dir: -1,
      type: 'date',
    },
  ];

  const columns = [
    {
      name: BILL_PAYMENTS_TABLE_COLUMN.CONTENT_ID,
      filterInfo: null,
      tableInfo: {
        header: 'Bill Payment ID',
        defaultVisible: false,
      },
    },
    {
      name: BILL_PAYMENTS_TABLE_COLUMN.ACTIONS,
      filterInfo: null,
      tableInfo: {
        header: null,
        minWidth: actionButtonColumnWidth,
        maxWidth: actionButtonColumnWidth,
        render: ({ data, rowIndex }) => {
          return (
            <Tooltip title="View Payment">
              <Button
                onClick={() => {
                  setShowContentDetailsModal({
                    open: true,
                    mode: CONTENT_DETAILS_MODAL_MODE.ADMIN_GLOBAL_ITEM_VIEW,
                    contentToShow: data.globalPayment,
                    currentIndex: rowIndex,
                    showNextPreviousButtons: true,
                  });
                }}
                className={classes.actionButton}
              >
                <VisibilityIcon />
              </Button>
            </Tooltip>
          );
        },
      },
    },
    {
      name: BILL_PAYMENTS_TABLE_COLUMN.USERNAME,
      filterInfo: {
        operator: 'inlist',
        type: 'select',
        value: null,
      },
      tableInfo: {
        header: 'Username',
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: 'All',
          multiple: true,
          wrapMultiple: true,
          dataSource: _.orderBy(
            companyCrew.map(user => ({
              id: user.username,
              label: user.username,
            })),
            ['label'],
            ['asc']
          ),
        },
        defaultFlex: 20,
        minWidth: columnMinWidthSmall,
      },
    },
    {
      name: BILL_PAYMENTS_TABLE_COLUMN.VENDOR,
      filterInfo: {
        operator: 'inlist',
        type: 'select',
        value: null,
      },
      tableInfo: {
        header: 'Vendor',
        defaultFlex: 40,
        minWidth: columnMinWidthLarge,
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: '',
          multiple: true,
          wrapMultiple: true,
          dataSource: _.orderBy(
            vendors.map(({ name }) => ({
              id: name,
              label: name,
            })),
            ['label'],
            ['asc']
          ),
        },
      },
    },
    {
      name: BILL_PAYMENTS_TABLE_COLUMN.PAYMENT_DATE,
      filterInfo: {
        operator: 'afterOrOn',
        type: 'date',
        value: '',
      },
      tableInfo: {
        header: 'Payment Date',
        dateFormat: 'YYYY-MM-DD',
        filterEditor: DateFilter,
        filterEditorProps: () => {
          return {
            dateFormat: 'MMM D, YYYY',
            cancelButton: false,
            highlightWeekends: false,
          };
        },
        defaultFlex: 20,
        minWidth: columnMinWidthSmall,
        render: renderDate,
      },
    },
    {
      name: BILL_PAYMENTS_TABLE_COLUMN.AMOUNT,
      filterInfo: {
        type: 'amount',
        operator: 'Starts With',
        value: '',
      },
      tableInfo: {
        header: 'Amount',
        type: 'amount',
        defaultFlex: 20,
        minWidth: columnMinWidthSmall,
        sort: simpleSortForAmount,
        render: ({ value }) => {
          if (typeof value === 'string') return value;
          if (value === -Number.EPSILON) {
            return 'n/a';
          }
          return monetaryRender({ value, withDecimals: true });
        },
      },
    },
    {
      name: BILL_PAYMENTS_TABLE_COLUMN.PAYMENT_METHOD,
      filterInfo: {
        operator: 'inlist',
        type: 'select',
        value: null,
      },
      tableInfo: {
        header: 'Method',
        defaultFlex: 20,
        minWidth: columnMinWidthSmall,
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: '',
          multiple: true,
          wrapMultiple: true,
          dataSource: _.values(PAYMENT_METHOD_LABEL).map(methodLabel => ({
            id: methodLabel,
            label: methodLabel,
          })),
        },
      },
    },
    {
      name: BILL_PAYMENTS_TABLE_COLUMN.IS_RECORDED,
      filterInfo: {
        operator: ['eq'],
        type: 'boolean',
        value: null,
      },
      tableInfo: {
        header: 'Is Recorded?',
        textAlign: 'center',
        defaultFlex: 10,
        minWidth: columnMinWidthSmall,
        filterEditor: BoolFilter,
        render: ({ value, data }) => {
          // disabled if they are a bookkeeping customer and not a bookkeeper
          // or if they are not a company admin
          const allowFor =
            (isBookkeepingCustomer && isCompanyBookkeeper) ||
            (!isBookkeepingCustomer && managingCompanyInfo?.isCompanyAdmin);

          return (
            <Checkbox
              color="primary"
              onChange={() => {
                updateJrn({
                  variables: {
                    contentId: data.contentId,
                    workflowStage: value
                      ? WORKFLOW_STAGE.UNRECORDED
                      : WORKFLOW_STAGE.RECORDED,
                  },
                });
              }}
              checked={value}
              disabled={!allowFor}
            />
          );
        },
      },
    },
    {
      name: BILL_PAYMENTS_TABLE_COLUMN.IS_RFI_REQUIRED,
      filterInfo: {
        operator: ['eq'],
        type: 'boolean',
        value: null,
      },
      tableInfo: {
        header: "RFI Req'd",
        textAlign: 'center',
        defaultFlex: 10,
        minWidth: columnMinWidthSmall,
        filterEditor: BoolFilter,
        render: ({ value, data }) => {
          return (
            <Checkbox
              color="primary"
              onChange={() => {
                if (!hasRfiProduct && managingCompanyInfo.isCompanyBookkeeper) {
                  updateJrn({
                    variables: {
                      contentId: data.contentId,
                      workflowStageReason: value
                        ? null
                        : WORKFLOW_STAGE_REASON.RFI_REQUIRED,
                    },
                  });
                }
              }}
              checked={value}
              disabled={
                hasRfiProduct || !managingCompanyInfo.isCompanyBookkeeper
              }
            />
          );
        },
      },
    },
    {
      name: BILL_PAYMENTS_TABLE_COLUMN.DATE_ADDED,
      filterInfo: {
        operator: 'afterOrOn',
        type: 'date',
        value: '',
      },
      tableInfo: {
        header: 'Date Added',
        dateFormat: 'YYYY-MM-DD',
        filterEditor: DateFilter,
        filterEditorProps: () => {
          return {
            dateFormat: 'MMM D, YYYY',
            cancelButton: false,
            highlightWeekends: false,
          };
        },
        defaultFlex: 20,
        minWidth: columnMinWidthSmall,
        render: renderDate,
      },
    },
  ];

  const filterDefs = columns
    .filter(({ filterInfo }) => !!filterInfo)
    .map(({ name, filterInfo }) => ({
      name,
      ...filterInfo,
    }));

  const columnDefs = columns
    .filter(({ tableInfo }) => !!tableInfo)
    .map(({ name, tableInfo }) => ({
      name,
      ...tableInfo,
    }));

  return {
    defaultSortInfo,
    filterDefs,
    columnDefs,
  };
};
