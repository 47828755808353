import React, { useState } from 'react';
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import { Redirect } from 'react-router-dom';

import LevelLogo from '../../components/level-logo/level-logo';
import HandShakeImage from '../../assets/images/onboarding/handshake_01.jpg';
import { useStyles } from './onboarding.styles';
import palette from '../../theme/palette';

const OnboardingCongratsOnPurchase = ({ onOnboardingComplete }) => {
  const classes = useStyles();
  const [completingOnboarding, setCompletingOnboarding] = useState(false);
  const [onboardingComplete, setOnboardingComplete] = useState(false);
  const completeOnboarding = async () => {
    setCompletingOnboarding(true);
    await onOnboardingComplete();
    setCompletingOnboarding(false);
    setOnboardingComplete(true);
  };

  if (onboardingComplete) {
    return <Redirect to="/alerts" />;
  }

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: 24 }}>
        <LevelLogo className={classes.smallLevelLogo} />
      </Grid>
      <Grid
        container
        item
        xs={12}
        style={{ marginBottom: 6, textAlign: 'left' }}
      >
        <Grid item xs={3} style={{ textAlign: 'center' }}>
          <img
            src={HandShakeImage}
            alt="two hands shaking in agreement"
            style={{ width: 100, maxWidth: '100%', height: 'auto' }}
          />
        </Grid>
        <Grid item xs={9} style={{ paddingLeft: 16 }}>
          <Typography variant="h3" style={{ color: palette.brandColorPrimary }}>
            Congrats on taking your first step toward better books!
          </Typography>
          <Typography
            variant="h5"
            style={{ color: palette.brandColorPrimary, marginTop: 10 }}
          >
            We look forward to helping you keep your books clean and your
            business healthy.
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ marginTop: 32 }}>
        <Typography variant="h5" style={{ color: palette.brandColorPrimary }}>
          Click below to get your books connected to Level.
        </Typography>
      </Grid>

      <Grid item xs={12} style={{ marginTop: 16 }}>
        <Button
          color="primary"
          variant="contained"
          onClick={completeOnboarding}
        >
          {completingOnboarding ? (
            <CircularProgress size={24} style={{ color: '#fff' }} />
          ) : (
            'Get Started'
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

export default OnboardingCongratsOnPurchase;
