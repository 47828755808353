import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const TooltippedButton = withStyles({
  root: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
})(Button);

export default TooltippedButton;
