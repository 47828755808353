export default theme => ({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: 8,
    display: 'flex',
    direction: 'column',
    opacity: 0.95,
    zIndex: 15,
  },
  overlayInner: {
    width: '100%',
    flex: 1,
    background: theme.palette.brandColorLightGrey,
    border: '1px solid transparent',
    borderRadius: 4,
    padding: 24,
  },
  overlayContent: {
    maxWidth: 400,
  },
  settings: {
    border: '1px solid',
    borderColor: theme.palette.brandColorLightGrey,
    borderRadius: 4,
    padding: 32,
  },
  settingsContent: {
    maxWidth: 400,
  },
});
