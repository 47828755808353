import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';

import numeral from 'numeral';
import _ from 'lodash';

import { CONTENT_TYPE, FINANCIAL_TYPES } from '../../../../config/appDefaults';
import ContentImageInfoBlock from './content-image-info-block';
import { getStatusDisplayInfo } from '../../../../helpers/bill-payments';

const ContentImageInfo = ({ contentInfo, children }) => {
  const blockInfoToUse = useMemo(() => {
    const blockInfo = [];

    if (contentInfo) {
      const isFinancial = FINANCIAL_TYPES.includes(contentInfo.type);

      if (
        isFinancial &&
        contentInfo.amount &&
        (contentInfo.amount.value || contentInfo.amount.value === 0)
      ) {
        const primaryText = `${
          contentInfo.contentStatus && contentInfo.contentStatus === 'refund'
            ? '-'
            : ''
        }${numeral(contentInfo?.amount?.value).format('$0,0.00')}`;

        let secondaryText = contentInfo.type;

        if (
          contentInfo.type === CONTENT_TYPE.BILL &&
          contentInfo.globalExpense &&
          contentInfo.globalExpense.documentNumber
        ) {
          secondaryText += ` #: ${contentInfo.globalExpense.documentNumber}`;
        }

        blockInfo.push({ primaryText, secondaryText, color: '#000000' });
      }

      if (
        isFinancial &&
        contentInfo.globalExpense &&
        contentInfo.globalExpense.type === CONTENT_TYPE.GLOBAL_BILL
      ) {
        const globalBillDetails = getStatusDisplayInfo({
          bill: contentInfo.globalExpense,
        });

        if (globalBillDetails) {
          const { primaryText, secondaryText, color } = globalBillDetails;

          blockInfo.push({
            primaryText,
            secondaryText,
            color,
          });
        }
      }
    }

    return blockInfo;
  }, [contentInfo]);

  if (_.isEmpty(blockInfoToUse) && _.isEmpty(children)) {
    return null;
  }

  return (
    <div style={{ position: 'absolute', top: 8, right: 8 }}>
      <Grid container direction="column" spacing={1}>
        {_.map(
          blockInfoToUse,
          ({ color, primaryText, secondaryText }, index) => (
            <Grid item key={index}>
              <ContentImageInfoBlock
                primaryText={primaryText}
                secondaryText={secondaryText}
                color={color}
              />
            </Grid>
          )
        )}
        {children}
      </Grid>
    </div>
  );
};

export default ContentImageInfo;
