import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.AccountingTxn}
  query GetUncategorizedTxns($companyId: String!) {
    getUncategorizedTxns(input: { companyId: $companyId }) {
      items {
        ...AccountingTxnFields
      }
    }
  }
`;
