import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { withRouter } from 'react-router-dom';
import {
  Avatar,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  card: {
    minHeight: 200,
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    transition: 'all 0.2s ease',
    '&:hover': {
      boxShadow: '0 0 7px 3px #ccc',
    },
  },
  icon: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.grey[200],
  },
  pos: {
    textAlign: 'left',
    marginBottom: 12,
  },
  listItem: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
  },
  labelNew: {
    color: theme.palette.brandColorGreen,
    fontWeight: 'bold',
    borderColor: theme.palette.brandColorGreen,
    alignSelf: 'flex-start',
  },
}));

const AdminTile = ({
  title,
  subtitle,
  text,
  path,
  Icon,
  history,
  isNew = false,
}) => {
  const classes = useStyles();
  const goToPath = () => {
    history.push(path);
  };

  return (
    <ButtonBase onClick={goToPath} className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <ListItem className={classes.listItem}>
            {Icon && (
              <ListItemAvatar>
                <Avatar className={classes.icon}>
                  <Icon />
                </Avatar>
              </ListItemAvatar>
            )}
            <ListItemText
              primary={
                <Typography variant="h5" component="h2" color="textSecondary">
                  {title}
                </Typography>
              }
              secondary={subtitle}
            />
            {isNew && (
              <Chip
                label="NEW"
                variant="outlined"
                className={classes.labelNew}
                size="small"
              />
            )}
          </ListItem>
          <Typography className={classes.pos} color="textSecondary">
            {text}
          </Typography>
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end', padding: 16 }}>
          <ArrowForwardIcon />
        </CardActions>
      </Card>
    </ButtonBase>
  );
};

export default withRouter(AdminTile);
