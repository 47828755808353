import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  query GetCustomerProjects($companyId: String!, $customerId: String!) {
    getCustomerProjects(
      input: { companyId: $companyId, customerId: $customerId }
    ) {
      items {
        address
        contentId
        jrnId
        title
        creatorId
        contentUrl
        description
        endDate
        privacyLevel
        inviteCode
        allowedToEdit
        allowedToAdd
        allowedToView
        usersEverOnJrn
        startDate
        synced
        labels
        latitude
        longitude
        phoneNumber
        phoneNumberActive
        type
        lastMessageSent
        useTimetracking
        useTimetrackingQuestions
        timetrackingQuestions
        timetrackingQuestionsCheckout
        # REMOVEME: remove this when "bugets" field is deprecated
        budgets {
          ...BudgetFields
        }
        fromTemplate
        timetrackingType
        qboCustomerId
        customerId
      }
    }
  }
`;
