import React from 'react';

import levelLogo from '../../assets/images/Level-logo-300x112.png';
import levelLogoAlt from '../../assets/images/Level-logo-20200909_white_yellow.png';
import levelNewLogo from '../../assets/images/Level-logo-20200909@0.5x.png';
import levelNewLogoTagline from '../../assets/images/Level-logo-20240416_tagline.png';
import levelNewLogoWhiteLettersCamRight from '../../assets/images/Level-logo-20240416_horizontal_white-letters_cam-right.png';

const LevelLogo = ({ style = {}, className, logoVariation = 'base' }) => {
  let logoToShow;
  if (logoVariation === 'base') {
    logoToShow = levelLogo;
  } else if (logoVariation === 'alt') {
    logoToShow = levelLogoAlt;
  } else if (logoVariation === 'new') {
    logoToShow = levelNewLogo;
  } else if (logoVariation === 'tagline') {
    logoToShow = levelNewLogoTagline;
  } else if (logoVariation === 'whiteLettersCamRight') {
    logoToShow = levelNewLogoWhiteLettersCamRight;
  }

  return (
    <img
      src={logoToShow}
      alt="Level logo"
      className={className}
      style={{ maxWidth: '100%', ...style }}
    />
  );
};

export default LevelLogo;
