import React, { useState } from 'react';
import { compose } from 'react-apollo';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import palette from '../../theme/palette';
import GraphQL from '../../graphql';
import ChooseQuickbooksAccounts from '../choose-quickbooks-accounts';
import LoadingCover from '../LoadingCover/loadingCover';
import { SCOREBOARD_NAME } from '../../config/appDefaults';

const CustomerDepositsAccountPicker = ({
  currentAccounts,
  handleDialogClose,
  getCompanyLiabilityAccountsLoading,
  liabilityAccounts,
  onUpdateScoreboardSettings,
}) => {
  const [selectedAccounts, setSelectedAccounts] = useState(currentAccounts);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const hasChange = !_.isEqual(selectedAccounts, currentAccounts);

  const handleAccountChosen = sourceId => {
    if (selectedAccounts.includes(sourceId)) {
      setSelectedAccounts(
        selectedAccounts.filter(
          selectedSourceId => selectedSourceId !== sourceId
        )
      );
    } else {
      setSelectedAccounts([sourceId]);
    }
  };

  const handleDone = async () => {
    // Start loader
    setIsSubmitting(true);

    // Do update
    await onUpdateScoreboardSettings([
      {
        scoreboardName: SCOREBOARD_NAME.GENERAL,
        customerDepositAccounts: selectedAccounts,
      },
    ]);

    // Stop loader
    setIsSubmitting(false);

    // Close dialog
    handleDialogClose();
  };

  return (
    <Dialog
      open
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
    >
      <DialogTitle>Select a Customer Deposit Liability Account</DialogTitle>
      <DialogContent
        dividers
        style={{
          paddingTop: 20,
          paddingBottom: 20,
          minWidth: 550,
          minHeight: 250,
          position: 'relative',
        }}
      >
        <DialogContentText style={{ color: palette.text.primary }}>
          Select an account to use for your customer deposits.
        </DialogContentText>
        <ChooseQuickbooksAccounts
          accounts={liabilityAccounts}
          currentAccounts={currentAccounts}
          selectedAccounts={selectedAccounts}
          isLoading={getCompanyLiabilityAccountsLoading}
          onChooseAccount={handleAccountChosen}
        />
        {(getCompanyLiabilityAccountsLoading || isSubmitting) && (
          <LoadingCover />
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleDialogClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleDone}
          color="primary"
          variant="contained"
          disabled={
            !hasChange || isSubmitting || getCompanyLiabilityAccountsLoading
          }
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default compose(
  GraphQL.GetCompanyLiabilityAccountsAction,
  GraphQL.UpdateScoreboardSettingsAction
)(CustomerDepositsAccountPicker);
