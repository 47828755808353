import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Relation}
  query GetRelationById($relationId: ID!) {
    getRelationById(relationId: $relationId) {
      ...RelationFields
    }
  }
`;
