import gql from 'graphql-tag';

export default gql`
  mutation CreateBookkeepingAlertPreferences(
    $id: String!
    $companyId: String!
    $enabled: Boolean!
    $timeZone: String!
    $timeOfDay: String!
    $daysAfterPeriodBookCompleted: Int!
    $frequencyOfBookkeepingTasks: String!
  ) {
    createBookkeepingAlertPreferences(
      input: {
        id: $id
        companyId: $companyId
        enabled: $enabled
        timeZone: $timeZone
        timeOfDay: $timeOfDay
        daysAfterPeriodBookCompleted: $daysAfterPeriodBookCompleted
        frequencyOfBookkeepingTasks: $frequencyOfBookkeepingTasks
      }
    ) {
      id
      companyId
      enabled
      timeZone
      timeOfDay
      daysAfterPeriodBookCompleted
      frequencyOfBookkeepingTasks
      qbFiscalYearStartMonth
    }
  }
`;
