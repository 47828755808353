import React, { useState, useMemo } from 'react';
import { compose } from 'react-apollo';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import _ from 'lodash';

import GraphQL from '../../graphql';
import LoadingCover from '../LoadingCover/loadingCover';
import SingleProject from '../ListOfProjects/singleProject';
import FilterSearch from '../FilterSearch/filterSearch';

const useStyles = makeStyles(theme => ({
  stepInstructionText: {
    marginBottom: theme.spacing(2),
  },
  helperText: {
    marginBottom: theme.spacing(2),
    color: theme.palette.brandColorPrimary,
  },
}));

const TemplateSelector = ({
  companyTemplates,
  listCompanyTemplatesLoading,
  listCompanyTemplatesError,
  selectedTemplate,
  handleSelection,
}) => {
  const classes = useStyles();

  const [isFiltered, setIsFiltered] = useState([]);
  const [showThisData, setShowThisData] = useState([]);

  const sortedTemplates = useMemo(() => {
    if (!companyTemplates) {
      return [];
    }

    return _.orderBy(companyTemplates, ['title'], ['asc']);
  }, [companyTemplates]);

  if (listCompanyTemplatesLoading) {
    return (
      <LoadingCover>
        <Typography variant="h3">Loading templates...</Typography>
      </LoadingCover>
    );
  }

  if (listCompanyTemplatesError) {
    return (
      <Typography>
        Something went wrong getting your templates. Please check your internet
        connection and try again later.
      </Typography>
    );
  }

  return (
    <Grid container direction="column">
      <Grid item xs={6} style={{ margin: '0 auto', width: '100%' }}>
        <FilterSearch
          data={sortedTemplates}
          isFiltered={setIsFiltered}
          passBack={setShowThisData}
        />
        {showThisData && showThisData.length > 0 ? (
          showThisData.map(template => {
            return (
              <SingleProject
                key={template.contentId}
                project={template}
                onClickOfProject={() => {
                  // pass that info to the AddContentForm
                  handleSelection(template);
                }}
                templateMode
                highlightSelected={
                  selectedTemplate &&
                  selectedTemplate.contentId === template.contentId
                }
              />
            );
          })
        ) : (
          <>
            {isFiltered ? (
              <Typography variant="h5" align="center" style={{ padding: 24 }}>
                No filtered results
              </Typography>
            ) : (
              <>
                <Typography variant="h5" align="center" style={{ padding: 24 }}>
                  You have no templates.
                </Typography>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.helperText}
                  align="center"
                >
                  Save a project as a template to add it to your list!
                </Typography>
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default compose(GraphQL.ListCompanyTemplatesAction)(TemplateSelector);
