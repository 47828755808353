import React, { useState, useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useParams,
} from 'react-router-dom';
import { Grid, Link, Paper, Typography, makeStyles } from '@material-ui/core';
import axios from 'axios';

import AuthNav from '../../components/auth/auth-nav';
import SignInView from '../../components/auth/sign-in-view';
import SignUpView from '../../components/auth/sign-up-view';
import ResetPasswordView from '../../components/auth/reset-password-view';
import LevelLogo from '../../components/level-logo/level-logo';
import VerificationSentView from '../../components/auth/verification-sent-view';

import styles from '../../components/auth/auth.styles';

const useStyles = makeStyles(styles);

const AuthScreen = () => {
  const classes = useStyles();
  const location = useLocation();

  const [redirectTo, setRedirectTo] = useState(null);
  const [invitationData, setInvitationData] = useState(null);
  const { invitationKey } = useParams();
  const isInvitationPage = location.pathname.startsWith('/auth/invitation');
  const isVerificationSentPage = location.pathname.startsWith(
    '/auth/check-email'
  );
  const isEmailSignUpPage = location.pathname.startsWith('/auth/email-sign-up');

  const retrieveInvitation = async invitationId => {
    try {
      const { data: responseBody } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/invitation?invitationId=${invitationId}`,
        {
          responseType: 'json',
        }
      );

      const { data: invitationDataResponse } = responseBody;

      setInvitationData(invitationDataResponse);
    } catch (err) {
      setInvitationData(false);
    }
  };

  useEffect(() => {
    if (isInvitationPage) {
      let invitationId = null;
      if (invitationKey) {
        try {
          invitationId = Buffer.from(invitationKey, 'base64').toString('utf8');
        } catch (err) {
          // Just eat it
        }
      }

      if (!invitationId) {
        setRedirectTo('/not-found');
      } else {
        retrieveInvitation(invitationId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  const showNav = !(
    isInvitationPage ||
    isVerificationSentPage ||
    isEmailSignUpPage
  );

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      className={classes.authPage}
    >
      <div className={classes.backgroundImage} />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        elevation={20}
        component={Paper}
        className={classes.authContainer}
      >
        <Grid item xs={12} className={classes.levelLogoContainer}>
          <LevelLogo logoVariation="tagline" className={classes.levelLogo} />
          <Typography
            variant="body2"
            style={{
              width: '100%',
              textAlign: 'right',
              paddingRight: 70,
              marginTop: -4,
            }}
          >
            <Link
              href="https://www.checkthelevel.com/bookkeeping-copilot"
              target="_blank"
              rel="noreferrer"
              style={{ fontSize: '95%' }}
            >
              More info...
            </Link>
          </Typography>
        </Grid>
        <Grid container item xs={12} direction="column">
          {showNav && <AuthNav />}
          <Switch>
            <Route path="/auth/signin" component={SignInView} />
            <Route
              path="/auth/signup"
              render={props => <SignUpView {...props} />}
            />
            <Route path="/auth/reset" component={ResetPasswordView} />
            <Route path="/signin/reset" component={ResetPasswordView} />
            <Route exact path="/signin" component={SignInView} />
            <Route
              path="/auth/invitation"
              render={props => (
                <SignUpView
                  {...props}
                  invitationMode={isInvitationPage}
                  invitationData={invitationData}
                />
              )}
            />
            <Route
              path="/auth/email-sign-up/:signUpToken"
              component={SignUpView}
            />
            <Route path="/auth/check-email" component={VerificationSentView} />
            <Redirect exact path="/" to="/auth/signin" />
            <Redirect exact path="/auth" to="/auth/signin" />
            <Redirect to="/not-found" />
          </Switch>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AuthScreen;
