import { Button } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';

function BackToTopButton({ scrollElementRef }) {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (scrollElementRef.current.scrollTop > 100) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const addEventListener = () => {
    if (scrollElementRef.current) {
      scrollElementRef.current.addEventListener('scroll', handleScroll);
    }
  };

  const removeEventListener = () => {
    if (scrollElementRef.current) {
      scrollElementRef.current.removeEventListener('scroll', handleScroll);
    }
  };

  useEffect(() => {
    addEventListener();

    // Clean up the event listener
    return () => {
      removeEventListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    addEventListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollElementRef]);

  const handleClick = () => {
    scrollElementRef.current.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (!showButton) {
    return null;
  }

  return (
    <Button
      style={{
        position: 'fixed',
        bottom: 12,
        right: 20,
        backgroundColor: '#aaa',
        color: '#fff',
        borderRadius: 23,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        opacity: 0.9,
        zIndex: 1000,
      }}
      onClick={handleClick}
      endIcon={<ArrowUpward style={{ color: '#fff' }} />}
    >
      Back to Top
    </Button>
  );
}

export default BackToTopButton;
