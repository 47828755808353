import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Grid,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
} from '@material-ui/core';

import {
  Edit as EditIcon,
  Dashboard as DashboardIcon,
  Warning as WarningIcon,
  ArrowUpward as ArrowUpwardIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';

import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import _ from 'lodash';
import omitDeep from 'omit-deep';
import numeral from 'numeral';

import {
  UpdateCompanyRfisAction,
  UpdateContentMutationAction,
  UpdateJrnMutationAction,
} from '../../graphql/graphql';

import ChooseProjectDialog from '../Admin/manage-requests-for-info/choose-project-dialog';
import LoadingCover from '../../components/LoadingCover/loadingCover';
import LevelPellEditor from '../add-to-project/level-pell-editor/level-pell-editor';
import SplitButton from '../../components/SplitButton/splitButton';

import { monetaryRender, makeMoney } from '../../helpers';
import { onUploadFile } from '../../helpers/cloudinary';
import {
  RFI_STATUSES,
  RFI_STATUSES_EXTERNAL_CLIENT_CHOICES,
  RFI_STATUSES_BOOKKEEPER_EXTERNAL_EDIT,
  RFI_FIELDS_TO_SHOW_PER_SOURCE,
  RFI_SOURCES_ARRAY,
  MAX_CHARACTERS_IN_DESCRIPTION_FIELD,
  RFI_SOURCES,
  MAX_FILES_ON_RFI,
  RFI_TXN_ANSWER_TYPES,
  JRN_TYPES,
} from '../../config/appDefaults';
import FullScreenGallery from '../../components/full-screen-gallery/full-screen-gallery';
import DialogTitle from '../../components/dialog-title/dialog-title';
import RenderSupportingItem from './rfi-supporting-media';
import GetContentById from '../../graphql/queries/GetContentById';
import GetCompanyRfi from '../../graphql/queries/get-company-rfi';
import FileUploadArea from '../../components/file-upload-area/file-upload-area';
import SimpleDialog from '../../components/SimpleTextDialog/simpleTextDialog';
// eslint-disable-next-line import/no-cycle
import RfiViewRelatedContent from './rfi-view-related-content';
import RfiEditFormComments from './rfi-edit-form-comments';
import ExpenseProjectSplitter from '../../components/expense-project-splitter/expense-project-splitter';
import BillPaymentBillSplitter from '../../components/billpayment-bill-splitter/billpayment-bill-splitter';
import palette from '../../theme/palette';

const useStyles = makeStyles(theme => ({
  formInputWrapper: {
    marginTop: 68,
    padding: theme.spacing(2),
    alignContent: 'flex-start',
  },
  levelNotesWrapper: {
    background: theme.palette.brandColorPrimary40,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    color: '#000',
  },
  formFieldWrapper: {
    marginBottom: theme.spacing(2),
  },
  titleButtonWrapper: {
    position: 'absolute',
    top: 0,
    padding: theme.spacing(2),
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#eee',
  },
  cancelButton: {
    color: theme.palette.brandColorError,
    marginRight: theme.spacing(2),
  },
  rfiDataLabel: {
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: theme.palette.brandColorDarkestGrey,
  },
}));

const parseAmountAsString = amount => {
  let amountAsString = '';
  if (amount) {
    const amountAsValue = amount.value || 0;
    amountAsString = numeral(amountAsValue).format('0.00');
  }
  return amountAsString;
};

const RfiEditForm = ({
  requestId,
  toClose,

  // HOCs
  managingCompanyInfo,
  onUpdateCompanyRfis,
  onUpdateContent,
  onUpdateJrn,
}) => {
  const isInitialized = useRef(false);
  const managingCompanyId = managingCompanyInfo?.managingCompanyId || null;
  const bookkeeperMode = _.get(
    managingCompanyInfo,
    'isCompanyBookkeeper',
    false
  );

  const getRfiQuery = useQuery(GetCompanyRfi, {
    variables: {
      companyId: managingCompanyId,
      requestId,
    },
    skip: !managingCompanyId,
    fetchPolicy: 'network-only',
  });

  const rfi = _.get(getRfiQuery, 'data.getCompanyRfi', null);
  const refetchRfi = _.get(getRfiQuery, 'refetch', null);

  const getRelatedItemQuery = useQuery(GetContentById, {
    fetchPolicy: 'cache-and-network',
    variables: { contentId: rfi?.idOfRelatedItem },
    skip: !rfi?.idOfRelatedItem,
  });

  const isReadonly =
    !bookkeeperMode && rfi?.requestStatus === RFI_STATUSES.EXT_X_COMPLETE.value;

  const classes = useStyles();

  const [isSaving, setIsSaving] = useState(false);
  const [simpleDialogOptions, setSimpleDialogOptions] = useState({
    open: false,
  });
  const [showRelatedContentModal, setShowRelatedContentModal] = useState({
    open: false,
  });
  const [fullScreenGalleryInfo, setFullScreenGalleryInfo] = useState({
    open: false,
    images: [],
  });
  const [savingOverlay, setSavingOverlay] = useState(false);
  const [fileErrors, setFileErrors] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [projectList, setProjectList] = useState([]);
  const [billList, setBillList] = useState([]);

  const [chooseProjectDialogOptions, setChooseProjectDialogOptions] = useState({
    open: false,
  });
  const [rfiToUpdate, setRfiToUpdate] = useState(null);

  useEffect(() => {
    if (rfi && !isInitialized.current) {
      setRfiToUpdate(rfi);

      // Default the projectList and billList
      if (rfi.projectList && rfi.relatedProjects) {
        const projectListToUse = [];
        _.forEach(rfi.projectList, ({ contentId, amount }) => {
          // Get project
          const project = _.find(rfi.relatedProjects, { contentId }, null);

          if (!project) {
            return;
          }

          // Get parent project
          const parentProject = project.jrn;

          // Build path
          const projectPath = [project.title];
          if (parentProject) {
            projectPath.unshift(parentProject.title);
          }

          projectListToUse.push({
            project,
            projectPath,
            amount: parseAmountAsString(amount),
          });
        });

        setProjectList(projectListToUse);
      }

      if (rfi.billList && rfi.relatedBills) {
        const billListToUse = [];

        _.forEach(rfi.billList, ({ contentId, amount }) => {
          const bill = _.find(rfi.relatedBills, { contentId }, null);

          if (!bill) {
            return;
          }
          // Review and safeguard project not found situation
          billListToUse.push({
            bill,
            amount: parseAmountAsString(amount),
          });
        });

        setBillList(billListToUse);
      }

      isInitialized.current = true;
    }
  }, [rfi]);

  const relatedItemInfo = _.get(
    getRelatedItemQuery,
    `data.getContentById`,
    null
  );

  const monetizeAmount = moneyValue => {
    return monetaryRender({ value: moneyValue, withDecimals: true });
  };

  const toUploadFiles = async files => {
    if (files.length === 0) {
      return null;
    }
    const mediaInfos = [
      // {
      //   uri
      //   aspectRatio
      //   type
      // }
    ];
    const uploads = files.map(async file => {
      let uploadResponse;
      try {
        uploadResponse = await onUploadFile(file, managingCompanyId);
        const type = file.type.includes('image') ? 'image' : 'pdf';
        mediaInfos.push({
          uri: uploadResponse.uri,
          aspectRatio: uploadResponse.aspectRatio,
          type,
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('err uploading supporting doc/image: ', err);
      }
    });
    await Promise.all(uploads);
    return mediaInfos;
  };

  const doTheSave = async ({ rfiToSave, createContentToo, closeAfter }) => {
    // validate then save
    // also check if the status has changed to completed
    //  and possibly offer to create a piece of content based on the RFI if all necessary info is there
    setSavingOverlay(true);
    // if there are files to upload them, upload, set the media and then save the RFI
    // go through the updatedRfi and see if there are any new files to upload

    const actualRfiObjectForSave = _.cloneDeep(rfiToSave);

    const filesToUpload = _.remove(actualRfiObjectForSave.media, media => {
      return media.uri.startsWith('blob:');
    });

    if (filesToUpload.length > 0) {
      const uploadedMedia = await toUploadFiles(filesToUpload);
      actualRfiObjectForSave.media.push(...uploadedMedia);
    }

    // since these cant be updated, remove them from the call
    delete actualRfiObjectForSave.txnReceived;
    delete actualRfiObjectForSave.txnSpent;

    actualRfiObjectForSave.billList = null;
    actualRfiObjectForSave.projectList = null;

    // Update the projectList, billList or projectId
    if (
      RFI_TXN_ANSWER_TYPES[actualRfiObjectForSave.requestType]
        ?.hasProjectMultiSelect
    ) {
      // Write projectList and nullify others
      actualRfiObjectForSave.projectId = null;
      actualRfiObjectForSave.projectList = projectList.map(
        ({ project, amount: amountAsString }) => ({
          contentId: project.contentId,
          amount: makeMoney(amountAsString ? parseFloat(amountAsString) : 0),
        })
      );
      actualRfiObjectForSave.billList = null;
    } else if (
      RFI_TXN_ANSWER_TYPES[actualRfiObjectForSave.requestType]
        ?.hasBillMultiSelect
    ) {
      // Write billList and nullify others
      actualRfiObjectForSave.projectId = null;
      actualRfiObjectForSave.projectList = null;
      actualRfiObjectForSave.billList = billList.map(
        ({ bill, amount: amountAsString }) => ({
          contentId: bill.contentId,
          amount: makeMoney(amountAsString ? parseFloat(amountAsString) : 0),
        })
      );
    }

    let updatedRfi;
    try {
      await onUpdateCompanyRfis([actualRfiObjectForSave], rfi?.requestStatus);

      const updatedRfiRefetchResponse = await refetchRfi();

      updatedRfi = _.get(updatedRfiRefetchResponse, 'data.getCompanyRfi', null);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('RFI edit form onUpdateCompanyRfis err: ', err);
    }

    let toCloseInfo = null;

    if (createContentToo) {
      // pass necessary info back to parent
      toCloseInfo = { addContentForCompletedRfi: updatedRfi };
    }

    setSavingOverlay(false);

    if (closeAfter) {
      toClose(toCloseInfo);
    }

    return updatedRfi;
  };

  const handleSave = async ({
    createContentToo = false,
    closeAfter = true,
    needToCheckForReviewAsk = false,
  } = {}) => {
    // Don't continue if validation failed
    if (_.keys(validationErrors).length > 0) {
      return null;
    }

    const rfiToSave = { ...rfiToUpdate };

    // only ask if the status is active and they didn't just change it from pending approval to active
    const wasPendingNowActive =
      rfi?.requestStatus === RFI_STATUSES.EXT_A_PENDING_APPROVAL.value &&
      rfiToSave.requestStatus === RFI_STATUSES.EXT_A_ACTIVE.value;
    const alreadyAskingToPendingApproval =
      rfiToSave.requestStatus === RFI_STATUSES.EXT_A_PENDING_APPROVAL.value;

    if (
      needToCheckForReviewAsk &&
      !bookkeeperMode &&
      !wasPendingNowActive &&
      !alreadyAskingToPendingApproval
    ) {
      // ask the user if it's ready to be review by their bookkeeper and if so, change the requestStatus to RFI_STATUSES.EXT_A_PENDING_APPROVAL.value and resend the save
      setSimpleDialogOptions({
        open: true,
        // title: 'Ready for Review',
        body: (
          <>
            <Typography variant="body1" style={{ fontSize: 16, marginTop: 8 }}>
              Is this RFI ready to be reviewed?
            </Typography>
          </>
        ),
        buttons: {
          positiveButton: { label: 'Yes, it is' },
          negativeButton: { label: 'No, not yet' },
        },
        onNegative: () => {
          doTheSave({ rfiToSave, createContentToo, closeAfter });
          setSimpleDialogOptions({ open: false });
        },
        onPositive: async () => {
          rfiToSave.requestStatus = RFI_STATUSES.EXT_A_PENDING_APPROVAL.value;

          doTheSave({ rfiToSave, createContentToo, closeAfter });
          setSimpleDialogOptions({ open: false });
        },
      });

      return null;
    }

    return doTheSave({ rfiToSave, createContentToo, closeAfter });
  };

  const handleParentChosen = ({ project, path }) => {
    setRfiToUpdate(currentState => ({
      ...currentState,
      projectId: project.contentId,
      parentPath: path,
    }));
  };

  const handlePickParent = () => {
    setChooseProjectDialogOptions({ open: true });
  };

  const handleStatusChange = ({ target: { value: requestStatus } }) => {
    setRfiToUpdate(currentState => ({ ...currentState, requestStatus }));
  };

  const closePushMediaToProjectDialog = () => {
    setSimpleDialogOptions({ open: false });
  };

  const handlePushMediaToProject = async () => {
    const mediaIsLocal = !(
      rfiToUpdate.media && rfiToUpdate.media[0]?.uri?.includes('cloudinary.com')
    );

    setSimpleDialogOptions({
      open: true,
      title: `${mediaIsLocal ? 'Save & ' : ''}Replace Existing Media`,
      buttons: {
        positiveButton: { label: 'Replace Media' },
        negativeButton: { label: 'Cancel' },
      },
      body: (
        <>
          {mediaIsLocal && (
            <Typography variant="body1" gutterBottom>
              We&apos;ll need to save the RFI before updating the related
              paperwork. Would you like to proceed?
            </Typography>
          )}
          <Typography
            variant="body1"
            color="error"
            style={{ fontWeight: 'bold' }}
          >
            Head&apos;s up! This will replace any media on the existing item
            (not the RFI itself).
          </Typography>
        </>
      ),
      onNegative: () => closePushMediaToProjectDialog(),
      onPositive: async () => {
        setIsSaving(true);
        let rfiToUse = rfiToUpdate;
        // If required, update the RFI
        if (mediaIsLocal) {
          rfiToUse = await handleSave({ closeAfter: false });
        }

        // Update the related item
        const media = _.cloneDeep(rfiToUse.media);
        const contentUrl = media[0].uri;
        const cleanExistingContentItem = omitDeep(
          relatedItemInfo,
          '__typename'
        );
        const existingContentForUpdate = {
          ...cleanExistingContentItem,
          contentUrl,
        };

        const isTypeOfJrn = _.includes(
          JRN_TYPES,
          existingContentForUpdate.type
        );

        if (isTypeOfJrn) {
          await onUpdateJrn(existingContentForUpdate);
        } else {
          await onUpdateContent(existingContentForUpdate);
        }

        closePushMediaToProjectDialog();
        setIsSaving(false);
      },
    });
  };

  const handleOnlickThumbnail = passedIndex => {
    let showThumbs = true;
    _.each(rfiToUpdate.media, mediaItem => {
      if (showThumbs !== false) {
        if (mediaItem.type !== 'image') {
          showThumbs = false;
        }
      }
    });

    setFullScreenGalleryInfo({
      open: true,
      images: rfiToUpdate.media,
      selectedItem: passedIndex,
      showThumbs,
    });
  };

  const handleRemoveFromMedia = passedIndex => {
    const updatedMedia = [...rfiToUpdate.media];
    updatedMedia.splice(passedIndex, 1);
    setRfiToUpdate(currentState => ({ ...currentState, media: updatedMedia }));
  };

  const handleMediaPassback = media => {
    setRfiToUpdate(currentState => ({ ...currentState, media }));
  };

  const handleMediaPassbackRejected = fileRejections => {
    setFileErrors([]);
    const errorsToPush = [];
    fileRejections.forEach(file => {
      file.errors.forEach(err => {
        if (err.code === 'file-too-large' || err.code === 'file-invalid-type') {
          errorsToPush.push(`Error: ${err.message}`);
        }
      });
    });
    setFileErrors(errorsToPush);
  };

  if (!rfiToUpdate) {
    return <LoadingCover />;
  }

  const thereIsMedia = rfiToUpdate.media && !!rfiToUpdate?.media.length;

  const fieldsToShowForThisSource = _.get(
    RFI_FIELDS_TO_SHOW_PER_SOURCE,
    [rfiToUpdate.requestSource],
    []
  );

  let allowPushToProject = false;

  // check that it's not regarding existing content and is a completed RFI
  if (
    !rfiToUpdate.idOfRelatedItem &&
    rfiToUpdate.requestStatus === RFI_STATUSES.EXT_X_COMPLETE.value
  ) {
    // if it has a projectId, so we know where it's going or is a bill payment or expense
    if (
      rfiToUpdate.projectId ||
      [
        RFI_TXN_ANSWER_TYPES.billPayment.value,
        RFI_TXN_ANSWER_TYPES.debitCreditCardPurchase.value,
      ].includes(rfiToUpdate.requestType)
    ) {
      allowPushToProject = true;
    }
  }

  const renderRfiStatusSelect = () => {
    if (bookkeeperMode) {
      const rfiStatusOptions = RFI_STATUSES_BOOKKEEPER_EXTERNAL_EDIT.map(
        statusDetails => (
          <MenuItem key={statusDetails.value} value={statusDetails.value}>
            {statusDetails.label}
          </MenuItem>
        )
      );

      if (rfi?.requestStatus === RFI_STATUSES.IN_APPROVED.value) {
        rfiStatusOptions.splice(
          1,
          0,
          <MenuItem
            key={RFI_STATUSES.IN_APPROVED.value}
            value={RFI_STATUSES.IN_APPROVED.value}
            disabled
          >
            {RFI_STATUSES.IN_APPROVED.label}
          </MenuItem>
        );
      }

      return rfiStatusOptions;
    }

    const rfiStatusInfo = RFI_STATUSES[rfi?.requestStatus];
    if (rfiStatusInfo?.value.startsWith('EXT_X')) {
      // if the RFI is already complete, don't show the status select since we don't want them to be able to change it
      return (
        <MenuItem
          key={rfiStatusInfo.value}
          value={rfiStatusInfo.value}
          disabled
        >
          {rfiStatusInfo.label}
        </MenuItem>
      );
    }

    const choicesToShow = [...RFI_STATUSES_EXTERNAL_CLIENT_CHOICES];
    if (rfiStatusInfo?.value === RFI_STATUSES.EXT_A_REJECTED.value) {
      // If "Needs attention" selected, the company admin should be able to change it to see it and change it
      choicesToShow.push(RFI_STATUSES.EXT_A_REJECTED);
    }

    return choicesToShow
      .map(statusDetails => {
        return (
          <MenuItem
            key={statusDetails.value}
            value={statusDetails.value}
            disabled={statusDetails === RFI_STATUSES.EXT_A_REJECTED.value}
          >
            {statusDetails.label}
          </MenuItem>
        );
      })
      .filter(value => value !== null);
  };

  const viewThisContent = async () => {
    if (!relatedItemInfo) {
      return;
    }

    setShowRelatedContentModal({
      open: true,
      contentToShow: relatedItemInfo,
    });
  };

  const isInformational =
    rfiToUpdate.requestSource === RFI_SOURCES.INFORMATIONAL.value;

  const newMedia = rfiToUpdate.media && rfiToUpdate.media[0];
  const imagesAreTheSame =
    newMedia && newMedia.uri === relatedItemInfo?.contentUrl;

  if (!rfi) {
    return null;
  }

  const showProjectMultiSelect =
    fieldsToShowForThisSource.includes('projectList') &&
    RFI_TXN_ANSWER_TYPES[rfiToUpdate.requestType]?.hasProjectMultiSelect;

  const showBillMultiSelect =
    fieldsToShowForThisSource.includes('billList') &&
    RFI_TXN_ANSWER_TYPES[rfiToUpdate.requestType]?.hasBillMultiSelect;

  return (
    <div style={{ flexDirection: 'column', flexWrap: 'nowrap' }}>
      <div
        className={classes.titleButtonWrapper}
        style={{ flex: 0, zIndex: 9999 }}
      >
        <Typography variant="h4">Update RFI</Typography>
        <div>
          <Button className={classes.cancelButton} onClick={toClose}>
            {isReadonly ? 'Close' : 'Cancel'}
          </Button>
          {!isReadonly && allowPushToProject && (
            <SplitButton
              options={[
                {
                  label: `Save & ${
                    rfiToUpdate.projectId ? 'Push to Project' : 'Create Content'
                  }`,
                  action: () => {
                    handleSave({ createContentToo: true });
                  },
                },
                {
                  label: 'Save',
                  action: () => {
                    handleSave({ createContentToo: false });
                  },
                },
              ]}
            />
          )}
          {!isReadonly && !allowPushToProject && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSave({ needToCheckForReviewAsk: true })}
            >
              Save
            </Button>
          )}
        </div>
      </div>
      <Grid container style={{ flexGrow: 1, height: '100vh' }}>
        <Grid
          item
          xs={5}
          className={classes.formFieldWrapper}
          style={{
            padding: 32,
            background: palette.background.alt,
            borderRadius: 16,
            height: '100vh',
            overflowY: 'auto',
          }}
        >
          <Typography
            color="primary"
            className={classes.rfiDataLabel}
            gutterBottom
          >
            Comments
          </Typography>
          <RfiEditFormComments rfi={rfi} bookkeeperMode={bookkeeperMode} />
        </Grid>
        <Grid
          item
          xs={7}
          container
          alignItems="flex-start"
          className={classes.formInputWrapper}
          style={{
            flexGrow: 1,
            height: 'calc(100vh - 80px)',
            overflowY: 'auto',
          }}
        >
          <Grid
            item
            xs={12}
            container
            spacing={2}
            className={classes.formFieldWrapper}
          >
            <Grid item xs={4}>
              <Typography className={classes.rfiDataLabel}>
                RFI Status
              </Typography>
              <Select
                value={rfiToUpdate.requestStatus}
                onChange={handleStatusChange}
                style={{ width: '100%' }}
                readOnly={isReadonly}
              >
                {renderRfiStatusSelect()}
              </Select>
            </Grid>

            <Grid item xs={4}>
              <Typography className={classes.rfiDataLabel}>
                Request Date
              </Typography>
              <Typography variant="body1">
                {moment(rfiToUpdate.dateCreated).format('MMM D, YYYY')}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography className={classes.rfiDataLabel}>
                Last Updated
              </Typography>
              <Typography variant="body1">
                {rfiToUpdate.dateModified ? (
                  moment(rfiToUpdate.dateModified).format('MMM D, YYYY')
                ) : (
                  <>n/a</>
                )}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.levelNotesWrapper}>
            <Typography
              className={classes.rfiDataLabel}
              color="inherit"
              gutterBottom
            >
              Initial Notes from Level
            </Typography>
            {ReactHtmlParser(rfiToUpdate.initialNotes)}
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={2}
            className={classes.formFieldWrapper}
          >
            <Grid item xs={4}>
              <Typography className={classes.rfiDataLabel}>
                Request Source
              </Typography>
              <Typography variant="body1">
                {_.find(RFI_SOURCES_ARRAY, { value: rfiToUpdate.requestSource })
                  ?.label || '-'}
              </Typography>
            </Grid>

            {rfiToUpdate.requestType && (
              <Grid item xs={4}>
                <Typography className={classes.rfiDataLabel}>
                  Request Type
                </Typography>
                <Typography variant="body1">
                  {_.find(RFI_TXN_ANSWER_TYPES, {
                    value: rfiToUpdate.requestType,
                  })?.label || '-'}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={2}
            className={classes.formFieldWrapper}
          >
            {fieldsToShowForThisSource.includes('txnDate') && (
              <Grid item xs={4}>
                <Typography className={classes.rfiDataLabel}>
                  Transaction Date
                </Typography>
                <Typography variant="body1">
                  {moment(rfiToUpdate.txnDate).format('MMM D, YYYY')}
                </Typography>
              </Grid>
            )}

            {fieldsToShowForThisSource.includes('txnAccount') && (
              <Grid item xs={4}>
                <Typography className={classes.rfiDataLabel}>
                  Transaction Account
                </Typography>
                <Typography variant="body1">
                  {rfiToUpdate.txnAccount || '-'}
                </Typography>
              </Grid>
            )}

            {fieldsToShowForThisSource.includes('bankAccount') && (
              <Grid item xs={4}>
                <Typography className={classes.rfiDataLabel}>
                  Bank Account
                </Typography>
                <Typography variant="body1">
                  {rfiToUpdate.bankAccount || '-'}
                </Typography>
              </Grid>
            )}
          </Grid>

          {fieldsToShowForThisSource.includes('txnPayee') && (
            <Grid
              item
              xs={12}
              container
              spacing={2}
              className={classes.formFieldWrapper}
            >
              <Grid item xs={12}>
                <Typography className={classes.rfiDataLabel}>
                  Transaction Payee
                </Typography>
                <Typography variant="body1">
                  {rfiToUpdate.txnPayee || '-'}
                </Typography>
              </Grid>
            </Grid>
          )}

          {fieldsToShowForThisSource.includes('txnDescription') && (
            <Grid
              item
              xs={12}
              container
              spacing={2}
              className={classes.formFieldWrapper}
            >
              <Grid item xs={12}>
                <Typography className={classes.rfiDataLabel}>
                  Transaction Description
                </Typography>
                <Typography variant="body1">
                  {rfiToUpdate.txnDescription || '-'}
                </Typography>
              </Grid>
            </Grid>
          )}

          {(fieldsToShowForThisSource.includes('txnReceived') ||
            fieldsToShowForThisSource.includes('txnSpent')) && (
            <Grid
              item
              xs={12}
              container
              spacing={2}
              className={classes.formFieldWrapper}
            >
              {fieldsToShowForThisSource.includes('txnReceived') && (
                <Grid item xs={4}>
                  <Typography className={classes.rfiDataLabel}>
                    Amount Received
                  </Typography>
                  <Typography variant="body1">
                    {rfiToUpdate.txnReceived
                      ? monetizeAmount(rfiToUpdate.txnReceived.value)
                      : '-'}
                  </Typography>
                </Grid>
              )}
              {fieldsToShowForThisSource.includes('txnSpent') && (
                <Grid item xs={4}>
                  <Typography className={classes.rfiDataLabel}>
                    Amount Spent
                  </Typography>
                  <Typography variant="body1">
                    {rfiToUpdate.txnSpent
                      ? monetizeAmount(rfiToUpdate.txnSpent.value)
                      : '-'}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}

          {fieldsToShowForThisSource.includes('projectId') &&
            (RFI_TXN_ANSWER_TYPES[rfiToUpdate.requestType]?.hasProjectSelect ||
              !rfiToUpdate.requestType) && (
              <Grid
                item
                xs={12}
                container
                spacing={2}
                className={classes.formFieldWrapper}
              >
                <Grid item xs={6}>
                  <Typography className={classes.rfiDataLabel}>
                    Project
                  </Typography>
                  {rfiToUpdate.parentPath ? (
                    <Typography variant="body1" style={{ paddingTop: 8 }}>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`/projects/${rfiToUpdate.projectId}`}
                        className="basicStyledLink"
                      >
                        {rfiToUpdate.parentPath.join(' > ') || 'View Project'}
                      </a>
                      {!isReadonly &&
                        rfiToUpdate.requestSource !==
                          RFI_SOURCES.LEVEL.value && (
                          <IconButton
                            size="small"
                            onClick={handlePickParent}
                            style={{ marginLeft: 8, fontSize: 16 }}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                    </Typography>
                  ) : (
                    <Button
                      variant="outlined"
                      onClick={handlePickParent}
                      endIcon={<DashboardIcon />}
                    >
                      Choose Project
                    </Button>
                  )}
                </Grid>
              </Grid>
            )}

          {showProjectMultiSelect && (
            <Grid
              item
              xs={12}
              className={classes.formFieldWrapper}
              style={{ marginBottom: 24 }}
            >
              <ExpenseProjectSplitter
                projectList={projectList}
                setProjectList={setProjectList}
                chillMode
                disabled={isReadonly}
                headingClass={classes.rfiDataLabel}
              />
            </Grid>
          )}
          {showBillMultiSelect && (
            <Grid
              item
              xs={12}
              className={classes.formFieldWrapper}
              style={{ marginBottom: 24 }}
            >
              <BillPaymentBillSplitter
                billList={billList}
                setBillList={setBillList}
                chillMode
                showVendor
                disabled={isReadonly}
                headingClass={classes.rfiDataLabel}
              />
            </Grid>
          )}

          {rfiToUpdate.idOfRelatedItem && (
            <Grid
              container
              item
              xs={12}
              spacing={2}
              style={{ marginBottom: 16 }}
              direction="column"
            >
              <Grid item>
                <Typography className={classes.rfiDataLabel}>
                  Regarding This Content
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={viewThisContent}
                  startIcon={<VisibilityIcon />}
                  variant="contained"
                  color="primary"
                  disabled={!relatedItemInfo}
                >
                  View Content Details
                </Button>
              </Grid>
            </Grid>
          )}

          {fieldsToShowForThisSource.includes('media') &&
            (!isReadonly || (isReadonly && thereIsMedia)) && (
              <Grid item xs={12} className={classes.formFieldWrapper}>
                <Typography className={classes.rfiDataLabel}>
                  Supporting Paperwork
                </Typography>
                {thereIsMedia && (
                  <Grid
                    container
                    style={{ width: '100%', overflowX: 'auto', marginTop: 8 }}
                  >
                    <Grid item style={{ flex: 0 }}>
                      {rfiToUpdate.media.map((item, index) => (
                        <RenderSupportingItem
                          key={item.uri}
                          supportingItem={item}
                          index={index}
                          handleOnlickThumbnail={handleOnlickThumbnail}
                          handleRemoveFromMedia={handleRemoveFromMedia}
                          isReadonly={isReadonly}
                        />
                      ))}
                    </Grid>
                    {bookkeeperMode &&
                      thereIsMedia &&
                      !imagesAreTheSame &&
                      rfiToUpdate.requestSource === RFI_SOURCES.LEVEL.value && (
                        <Grid
                          item
                          style={{ alignItems: 'center', display: 'flex' }}
                        >
                          <Button
                            onClick={handlePushMediaToProject}
                            startIcon={<ArrowUpwardIcon />}
                          >
                            Use For Existing Content Media
                          </Button>
                        </Grid>
                      )}
                  </Grid>
                )}
                {!isReadonly && (
                  <FileUploadArea
                    justText={thereIsMedia ? 'Change...' : undefined}
                    passback={handleMediaPassback}
                    passbackRejected={handleMediaPassbackRejected}
                    // if GeneralInfo, allow any file type, otherwise, only images and pdfs
                    mode={isInformational ? 'allTypes' : 'imageAndPdf'}
                    maxSize={10 * 1024 * 1024}
                    maxFiles={isInformational ? MAX_FILES_ON_RFI : 1}
                  />
                )}
                {fileErrors && fileErrors.length > 0 && (
                  <>
                    {fileErrors.map(error => (
                      <Typography variant="body1" style={{ paddingTop: 8 }}>
                        {error.message}
                      </Typography>
                    ))}
                  </>
                )}
              </Grid>
            )}

          {fieldsToShowForThisSource.includes('clientNotes') && (
            <Grid
              container
              item
              xs={12}
              spacing={2}
              className={classes.formFieldWrapper}
            >
              <Grid item xs={12} sm={12}>
                <Typography className={classes.rfiDataLabel}>
                  Notes / Description
                  {validationErrors.clientNotes && (
                    <span
                      style={{
                        marginLeft: 10,
                        color: 'red',
                      }}
                    >
                      <WarningIcon color="inherit" style={{ fontSize: 16 }} />{' '}
                      {validationErrors.clientNotes}
                    </span>
                  )}
                </Typography>

                <div
                  style={{
                    marginTop: 5,
                    border: `1px solid ${
                      validationErrors.clientNotes ? '#e00' : '#ccc'
                    }`,
                  }}
                >
                  <LevelPellEditor
                    defaultContent={rfiToUpdate.clientNotes || ''}
                    onChange={htmlText => {
                      setRfiToUpdate(currentState => ({
                        ...currentState,
                        clientNotes: htmlText,
                      }));

                      if (
                        htmlText.length > MAX_CHARACTERS_IN_DESCRIPTION_FIELD
                      ) {
                        setValidationErrors({
                          ...validationErrors,
                          clientNotes: `too long (${htmlText.length} of ${MAX_CHARACTERS_IN_DESCRIPTION_FIELD})`,
                        });
                      } else {
                        const newValidationErrors = { ...validationErrors };
                        delete newValidationErrors.clientNotes;
                        setValidationErrors({ ...newValidationErrors });
                      }
                    }}
                    containerClass="level-pell-content-addContentForm"
                    disabled={isReadonly}
                  />
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>

        {chooseProjectDialogOptions.open && (
          <ChooseProjectDialog
            paperProps={{ style: { height: '75%' } }}
            setProjectAndPath={projectAndPath => {
              handleParentChosen(projectAndPath);
              setChooseProjectDialogOptions({ open: false });
            }}
            toClose={() => setChooseProjectDialogOptions({ open: false })}
          />
        )}
      </Grid>
      {savingOverlay && (
        <LoadingCover>
          <Typography variant="h3" align="center">
            Saving Changes...
          </Typography>
        </LoadingCover>
      )}
      {fullScreenGalleryInfo.open && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open
          onClose={() => setFullScreenGalleryInfo({ open: false })}
        >
          <DialogTitle
            onClose={() => setFullScreenGalleryInfo({ open: false })}
          >
            Supporting Paperwork
          </DialogTitle>
          <DialogContent>
            <FullScreenGallery
              images={fullScreenGalleryInfo.images}
              selectedItem={fullScreenGalleryInfo.selectedItem}
              showThumbs={fullScreenGalleryInfo.showThumbs}
            />
          </DialogContent>
        </Dialog>
      )}
      {showRelatedContentModal.open && (
        <RfiViewRelatedContent
          contentToShow={showRelatedContentModal.contentToShow}
          handleClose={() => setShowRelatedContentModal({ open: false })}
        />
      )}
      <SimpleDialog
        open={simpleDialogOptions.open}
        title={simpleDialogOptions.title}
        onNegative={simpleDialogOptions.onNegative}
        onPositive={simpleDialogOptions.onPositive}
        onClose={() => setSimpleDialogOptions({ open: false })}
        showLoader={isSaving}
        buttons={simpleDialogOptions.buttons}
        fullWidth
      >
        {simpleDialogOptions.body}
      </SimpleDialog>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
  };
}

export default compose(
  UpdateCompanyRfisAction,
  UpdateContentMutationAction,
  UpdateJrnMutationAction
)(connect(mapStateToProps)(RfiEditForm));
