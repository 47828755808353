import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const SingleInputDialog = ({
  title,
  message,
  handleClose,
  handleSubmit,
  isError,
  errorMsg,
  type = 'text',
  label,
}) => {
  const [inputValue, setInputValue] = useState('');
  const onClose = () => {
    handleClose();
  };

  const onSubmit = () => {
    handleSubmit(inputValue);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="xs">
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <TextField
          autoFocus
          label={label || 'Password...'}
          type={type}
          fullWidth
          value={inputValue}
          onChange={e => {
            setInputValue(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          error={isError}
          helperText={isError && errorMsg}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SingleInputDialog;
