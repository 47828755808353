import React, { useState, useEffect } from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';

import { Dialog, DialogContent, Typography } from '@material-ui/core';
import { withSnackbar } from 'notistack';

import _ from 'lodash';
import numeral from 'numeral';

import { onUploadFile } from '../../helpers/cloudinary';
import LevelModal from '../Modal/level-modal';
import DialogTitle from '../dialog-title/dialog-title';
import ChooseProjectWithSubs from '../choose-project-dialog/choose-project-with-subs';
import OkCancelDialog from '../OkCancelDialog/okCancelDialog';
import SplitFinancialForm from './split-financial-item.view';
import GraphQL from '../../graphql';
import splitItem, {
  ACTION as SPLIT_ACTION,
} from '../../helpers/split-item/split-item.util';
import { batchRequest } from '../../helpers';

const SplitFinancialItem = ({
  contentInfo,
  projectInfo,
  globalExpenseInfo,
  enqueueSnackbar,
  onClose,
  open,
  dialog,
  onAddContent,
  onUpdateContent,
}) => {
  const [projectList, setProjectList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    itemIndex: null,
    open: false,
  });
  const [invalidAmountMap, setInvalidAmountMap] = useState({});

  const [showChooseProjectDialog, setShowChooseProjectDialog] = useState(false);

  useEffect(() => {
    if (contentInfo) {
      setProjectList([
        {
          project: projectInfo,
          projectPath: projectInfo.title,
          amount: contentInfo.amount.value
            ? numeral(contentInfo.amount.value).format('0.00')
            : '0.00',
          description: contentInfo.description || '',
          existingContentInfo: contentInfo,
        },
      ]);
    }
    if (globalExpenseInfo) {
      setProjectList([
        {
          project: projectInfo,
          projectPath: projectInfo.title,
          amount: globalExpenseInfo.amount
            ? numeral(globalExpenseInfo.amount.value).format('0.00')
            : '0.00',
          description: '',
          existingContentInfo: null,
        },
      ]);
    }
  }, [globalExpenseInfo, contentInfo, projectInfo]);

  useEffect(() => {
    if (projectList.length > 0) {
      setTotalAmount(
        numeral(
          _.sumBy(projectList, project => Number(project.amount)) || 0
        ).format('$ 0,0.00')
      );
    }
  }, [projectList]);

  const handleFieldChange = (value, fieldName, index) => {
    setProjectList(prevState => {
      const newState = [...prevState];
      newState[index][fieldName] = value;
      return newState;
    });
  };

  const handleProjectSelected = info => {
    setShowChooseProjectDialog(false);
    if (
      _.findIndex(
        projectList,
        p => p.project.contentId === info.project.contentId
      ) !== -1
    ) {
      enqueueSnackbar('The project was already in the list', {
        variant: 'warning',
        autoHideDuration: 2000,
      });
      return;
    }
    setProjectList(prevState => {
      const newState = [...prevState];
      newState.push({
        project: info.project,
        projectPath: info.path,
        amount: 0,
        description: '',
        existingContentInfo: null,
        isNew: true,
      });
      return newState;
    });
  };

  const handleProjectDelete = index => {
    setShowConfirmDialog({ itemIndex: index, open: true });
  };

  const validate = () => {
    const invalidItem = _.find(projectList, ({ amount }) => {
      return !amount || Number(amount) === 0;
    });

    if (invalidItem) {
      const { project: projectItem, projectPath } = invalidItem;
      setInvalidAmountMap(currentState => {
        return {
          ...currentState,
          [projectItem.contentId]: true,
        };
      });

      enqueueSnackbar(
        `The amount of the project "${projectPath}" must be greater than 0`,
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    if (!validate()) {
      return;
    }

    setIsLoading(true);

    const splitContentList = splitItem({
      itemToSplit: contentInfo,
      projectList,
    });

    const { rejectedItems } = await batchRequest({
      items: splitContentList,
      noOfItemsPerBatch: 5,
      actionFunction: async ({ contentInfo: pendingContentInfo, action }) => {
        if (action === SPLIT_ACTION.UPDATE) {
          // Update content
          return onUpdateContent(pendingContentInfo);
        }

        if (action === SPLIT_ACTION.CREATE) {
          const newContentInfo = { ...pendingContentInfo };

          // Reupload the bill/receipt if any
          if (newContentInfo.contentUrl) {
            const uriToReupload = newContentInfo.contentUrl;
            const folder = newContentInfo.jrnId;
            const fileUploadResponse = await onUploadFile(
              uriToReupload,
              folder
            );
            newContentInfo.contentUrl = fileUploadResponse.uri;
          }

          return onAddContent(newContentInfo);
        }

        return Promise.resolve();
      },
    });

    if (!_.isEmpty(rejectedItems)) {
      // eslint-disable-next-line no-console
      console.log('handleSave ~ rejectedItems', rejectedItems);

      enqueueSnackbar('Sorry! An error occurred on split item.', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }

    setIsLoading(false);
    onClose();
  };

  const handleAddAnotherProject = () => {
    setShowChooseProjectDialog(true);
  };

  const splitFinancialForm = (
    <SplitFinancialForm
      contentInfo={contentInfo}
      projectList={projectList}
      handleFieldChange={handleFieldChange}
      handleProjectDelete={handleProjectDelete}
      handleAddAnotherProject={handleAddAnotherProject}
      handleSave={handleSave}
      onClose={onClose}
      totalAmount={totalAmount}
      isLoading={isLoading}
      invalidAmountMap={invalidAmountMap}
      setInvalidAmountMap={setInvalidAmountMap}
    />
  );

  return (
    <>
      {dialog && <LevelModal open={open}>{splitFinancialForm}</LevelModal>}
      {!dialog && splitFinancialForm}
      <Dialog
        maxWidth="lg"
        fullWidth
        open={showChooseProjectDialog}
        PaperProps={{ style: { minHeight: '50%' } }}
      >
        <DialogTitle
          onClose={() => {
            setShowChooseProjectDialog(false);
          }}
        />
        <DialogContent>
          <>
            <Typography
              variant="h2"
              gutterBottom
              align="center"
              color="primary"
            >
              Choose another project:
            </Typography>
            <ChooseProjectWithSubs setSelectedProject={handleProjectSelected} />
          </>
        </DialogContent>
      </Dialog>
      <OkCancelDialog
        title="Just making sure..."
        open={showConfirmDialog.open}
        onClose={() => {
          setShowConfirmDialog({ open: false, itemIndex: null });
        }}
        onConfirm={() => {
          setProjectList(prevState => {
            return _.filter(
              prevState,
              (p, i) => i !== showConfirmDialog.itemIndex
            );
          });
        }}
      >
        <Typography>Are you sure you want to remove this item?</Typography>
      </OkCancelDialog>
    </>
  );
};

function mapStateToProps(state) {
  return {
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
  };
}

export default connect(mapStateToProps)(
  compose(
    GraphQL.CreateContentMutationAction,
    GraphQL.UpdateContentMutationAction
  )(withSnackbar(SplitFinancialItem))
);
