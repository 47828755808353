import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';

import { LEVEL_ROLE } from '../../config/appDefaults';
import { getFullUserString } from '../../helpers';

const AssignRoleConfirmationDialog = ({
  role,
  userToAssign,
  onCancel,
  onConfirm,
}) => {
  if (!userToAssign || !role) {
    return null;
  }

  const fullUserString = getFullUserString(userToAssign);

  return (
    <Dialog maxWidth="sm" aria-labelledby="confirmation-dialog" open>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent dividers style={{ paddingTop: 20, paddingBottom: 20 }}>
        <Grid container>
          <Grid item>
            {role === LEVEL_ROLE.COMPANY_OWNER && (
              <Typography>
                <b>{fullUserString}</b> will be made a company owner and be able
                to view and manage all of the company&apos;s data on Level.
              </Typography>
            )}
            {role === LEVEL_ROLE.BOOKKEEPER && (
              <Typography>
                <b>{fullUserString}</b> will be made a company bookkeeper and be
                able to view and manage all of the company&apos;s data on Level.
              </Typography>
            )}
            {role === LEVEL_ROLE.ADMIN && (
              <Typography>
                <b>{fullUserString}</b> will be made a company admin and be able
                to view and manage all of the company&apos;s data on Level.
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onConfirm({ userToAssign, role });
          }}
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignRoleConfirmationDialog;
