import gql from 'graphql-tag';
import * as Fragments from '../fragments';

export default gql`
  ${Fragments.BookkeepingEventMeta}
  query ListBookkeepingSnapshotEventMeta(
    $companyId: String!
    $snapshotId: String!
    $metaType: String!
  ) {
    listBookkeepingSnapshotEventMeta(
      input: {
        companyId: $companyId
        snapshotId: $snapshotId
        metaType: $metaType
      }
    ) {
      items {
        ...BookkeepingEventMetaFields
      }
    }
  }
`;
