import React from 'react';
import { MenuItem, Select, Typography } from '@material-ui/core';

const FormikSelect = props => {
  const { name, value, options, handleChange, label, error, ...other } = props;

  return (
    <>
      <Select
        style={{ width: '100%' }}
        name={name}
        value={value}
        label={label}
        onChange={handleChange}
        error={!!error}
        {...other}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <Typography variant="caption" color="error" style={{ marginTop: 4 }}>
          {error}
        </Typography>
      )}
    </>
  );
};

export default FormikSelect;
