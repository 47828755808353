import React, { useEffect, useMemo } from 'react';
import { withRouter, useParams, Redirect, Link } from 'react-router-dom';
import { compose, withApollo } from 'react-apollo';

import { connect } from 'react-redux';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import { Paper, Grid, Button } from '@material-ui/core';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Extension as TemplateIcon,
  ArrowBackIos as ArrowBackIosIcon,
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from 'react-apollo-hooks';
import _ from 'lodash';
import { TimeManager } from 'level-foundation';

// Redux
import store from '../../../../store';

import ContentDetailsModal from '../../../add-to-project/content-details-modal';

// Queries
import GetAllContentByJrnId from '../../../../graphql/queries/GetAllContentByJrnId';
import GetUsersByJrnId from '../../../../graphql/queries/GetUsersByJrnId';
import GetContentById from '../../../../graphql/queries/GetContentById';
import GetUsersByIds from '../../../../graphql/queries/GetUsersByIds';
import ListCompanyArchivedProjects from '../../../../graphql/queries/list-company-archived-projects';
import GetJrnBasicInfo from '../../../../graphql/queries/GetJrn';
import ApplyPermissionsToSubProjectsAction from '../../../../graphql/mutations/mutation_apply-permissions-to-subprojects';
import ApplyLaborRatesToSubprojectsAction from '../../../../graphql/mutations/mutation_apply-labor-rates-to-subprojects';

// Mutations
import {
  DeleteJrnAction,
  DeleteContentAction,
  GetMyUserInfoAction,
  ArchiveCompanyProjectAction,
  UnarchiveCompanyProjectAction,
  GetCompanyCrewAction,
} from '../../../../graphql/graphql';

// Helpers
import { buildLabels } from '../../../../helpers/index';

// Components
// eslint-disable-next-line import/no-cycle
import ProjectDetailsView from './projectDetails.view';
// eslint-disable-next-line import/no-cycle
import { TAB_INDEX } from '../../../../components/ProjectContentListing/projectContentListing';
import UpgradeRequiredDialog from '../../../../components/upgrade-required/upgrade-required';
import {
  CONTENT_DETAILS_MODAL_MODE,
  CONTENT_TYPE,
  TOP_PROJECT_ID,
} from '../../../../config/appDefaults';
import { jsDelay } from './stat-tiles/stat-dialog/helpers';

// create a context to pass down to contentItem so we dont have to prop drill it down
export const TopParentContext = React.createContext();

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  progress: {
    margin: theme.spacing(2),
  },
  projectDetailsWrapper: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingTop: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  templateIndicator: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#4198E0',
    width: '100%',
    padding: theme.spacing(2),
    color: '#fff',
  },
}));

const ProjectDetails = ({
  userId,
  userInfo,
  onDeleteJrn,
  onArchiveCompanyProject,
  onUnarchiveCompanyProject,
  onDeleteContent,
  scrollPosition,
  backToParentPathname,
  location,
  history,
  client,
  toTriggerQueryChange,
  lastTabIndex,
  managingCompanyInfo,
  contentLabelSuggestions,
  companyCrew,
}) => {
  const { projectId } = useParams();
  const classes = useStyles();
  let isArchive = false;
  // State hooks
  const [goToParentProject, setGoToParentProject] = React.useState(false);
  const [goToProjectsList, setGoToProjectsList] = React.useState(false);
  const [topParentInfo, setTopParentInfo] = React.useState(null);
  const [
    applyingLaborRatesAndWaiting,
    setApplyingLaborRatesAndWaiting,
  ] = React.useState(false);

  const [showManualAddDialog, setShowManualAddDialog] = React.useState({
    open: false,
    fromThisTemplate: null,
  });

  const [upgradeDialogOption, setUpgradeDialogOption] = React.useState({
    open: false,
  });

  // Query hooks
  const contentBasicInfoQuery = useQuery(GetContentById, {
    variables: { contentId: projectId },
    fetchPolicy: 'cache-and-network',
  });
  // console.log('contentBasicInfoQuery: ', contentBasicInfoQuery);

  const [
    applyPermissionsToSubprojects,
    { loading: applyingPerms },
  ] = useMutation(ApplyPermissionsToSubProjectsAction);

  const [applyLaborRatesToSubprojects] = useMutation(
    ApplyLaborRatesToSubprojectsAction
  );

  // if we're showing a template then TopParentContext is contentBasicInfoQuery
  //  if we're showing an extendedTask or subproject of a template then TopParentContext needs to be queried
  useEffect(() => {
    const getTopParentInfo = async idOfTemplate => {
      let templateInfo;
      try {
        const templateInfoResponse = await client.query({
          query: GetJrnBasicInfo,
          variables: { jrnId: idOfTemplate },
          fetchPolicy: 'network-only',
        });
        templateInfo = templateInfoResponse.data.getJrn;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('e: ', e);
      }
      setTopParentInfo(templateInfo);
    };

    const currentData = _.get(contentBasicInfoQuery, 'data.getContentById');
    // this just means that it has info in it
    if (currentData && currentData.contentId) {
      if (currentData.type === 'template') {
        // is itself a template
        setTopParentInfo(currentData);
      } else if (currentData.fromTemplate) {
        // is a child of a template
        getTopParentInfo(currentData.fromTemplate);
      } else {
        // just the regular project flow
        setTopParentInfo(null);
      }
    }
  }, [contentBasicInfoQuery, client]);

  const contentToShowQuery = useQuery(GetAllContentByJrnId, {
    variables: { jrnId: projectId },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });
  const projectUsersQuery = useQuery(GetUsersByJrnId, {
    variables: { jrnId: projectId },
    fetchPolicy: 'cache-and-network',
  });

  const companyArchiveListQuery = useQuery(ListCompanyArchivedProjects, {
    skip: !(managingCompanyInfo && managingCompanyInfo.managingCompanyId),
    variables: {
      companyId: managingCompanyInfo
        ? managingCompanyInfo.managingCompanyId
        : null,
    },
    fetchPolicy: 'cache-and-network',
  });

  const archiveList = useMemo(() => {
    let foundArchiveList = [];

    if (
      companyArchiveListQuery.data &&
      companyArchiveListQuery.data.listCompanyArchivedProjects &&
      companyArchiveListQuery.data.listCompanyArchivedProjects.items
    ) {
      foundArchiveList =
        companyArchiveListQuery.data.listCompanyArchivedProjects.items;
    }

    return foundArchiveList;
  }, [companyArchiveListQuery]);

  // Reset after close event emitted since state is persisted
  if (
    location.state &&
    location.state.fromCloseEvent &&
    (goToParentProject || goToProjectsList)
  ) {
    const locationCopy = { ...location };
    delete locationCopy.state.fromCloseEvent;
    history.replace({ ...locationCopy });
    setGoToParentProject(false);
    setGoToProjectsList(false);
  }

  // Assigned to user depends on results of previous query
  let assignedToId = null;
  if (!contentBasicInfoQuery.loading) {
    const foundContent = _.get(contentBasicInfoQuery.data, 'getContentById');
    if (foundContent) {
      assignedToId = foundContent.assignedTo;
    }
  }

  // Conditionally query for the assigned to user
  const assignedToUserQuery = useQuery(GetUsersByIds, {
    fetchPolicy: 'network-only',
    variables: { ids: assignedToId },
    skip: !assignedToId || assignedToId === 'unassigned',
  });

  const isLoading = contentBasicInfoQuery.loading;

  let contentBasicInfo = null;
  let assignedToUsername = assignedToId === 'unassigned' ? assignedToId : null;

  let resourceFound = false;
  let projectCanEdit = false;
  let projectCanAdd = false;
  if (!isLoading) {
    if (
      contentBasicInfoQuery.data &&
      contentBasicInfoQuery.data.getContentById
    ) {
      contentBasicInfo = contentBasicInfoQuery.data.getContentById;
      if (contentBasicInfo && contentBasicInfo.contentId === projectId) {
        resourceFound = true;
        const isCompanyAdmin =
          managingCompanyInfo && managingCompanyInfo.isCompanyAdmin;

        projectCanEdit =
          _.includes(contentBasicInfo.allowedToEdit, userId) || isCompanyAdmin;
        projectCanAdd = _.includes(contentBasicInfo.allowedToAdd, userId);
      }
    }

    const assignedToUserData = _.get(
      assignedToUserQuery,
      'data.getUsersByIds.items'
    );
    if (assignedToUserData && assignedToUserData.length > 0) {
      assignedToUsername =
        assignedToUserQuery.data.getUsersByIds.items[0].username;
    }
  }

  if (archiveList) {
    const existingItemIndex = _.findIndex(archiveList, {
      contentId: projectId,
    });
    // merge the changes from the update with the existing item IN the existing list
    if (existingItemIndex > -1 && !isArchive) {
      isArchive = true;
    }
  }

  // Set project users
  let projectUsers = null;
  if (
    projectUsersQuery.data &&
    projectUsersQuery.data.getUsersByJrnId &&
    projectUsersQuery.data.getUsersByJrnId.items
  ) {
    projectUsers = projectUsersQuery.data.getUsersByJrnId.items;
  }

  // Set content
  let contentToShow = null;
  let timetrackingItems = null;

  if (
    contentToShowQuery.data &&
    contentToShowQuery.data.getAllContentByJrnId &&
    contentToShowQuery.data.getAllContentByJrnId.items
  ) {
    contentToShow = contentToShowQuery.data.getAllContentByJrnId.items;
    timetrackingItems = _.filter(
      contentToShow,
      contentItem => contentItem.type === 'timetracking'
    );
  }

  useEffect(() => {
    const contentLabels = buildLabels(contentToShow);
    const allContentLabels = _.uniq([
      ...contentLabels,
      ...contentLabelSuggestions,
    ]);
    store.dispatch({
      type: 'UPDATE_CONTENT_LABEL_SUGGESTIONS',
      payload: { contentLabelSuggestions: allContentLabels },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentToShow]);

  // Check if extended task
  let isExtendedTask = false;
  if (
    contentBasicInfo &&
    contentBasicInfo.type === 'task' &&
    contentBasicInfo.subtype === 'extended'
  ) {
    isExtendedTask = true;
  }

  // Refetch all
  const refetchAll = async () => {
    await Promise.all([
      contentBasicInfoQuery.refetch(),
      contentToShowQuery.refetch(),
      projectUsersQuery.refetch(),
    ]);
  };

  // Close project or extended task panel
  const closeProject = () => {
    setGoToProjectsList(true);
  };

  // Delete project or extended task
  const deleteThisContent = async () => {
    if (
      contentBasicInfo.type === CONTENT_TYPE.PROJECT ||
      contentBasicInfo.type === CONTENT_TYPE.TEMPLATE
    ) {
      await onDeleteJrn(contentBasicInfo.contentId, contentBasicInfo.type);
    } else {
      onDeleteContent(contentBasicInfo.contentId, contentBasicInfo.jrnId);
    }
    // Close project view
    if (
      isExtendedTask ||
      (contentBasicInfo.type === CONTENT_TYPE.PROJECT &&
        contentBasicInfo.jrnId !== TOP_PROJECT_ID)
    ) {
      // if it's an extended task or a subproject we should go back to the parent
      setGoToParentProject(true);
    } else {
      closeProject();
    }
  };

  const hideShowProject = (passedId, passedIsArchive) => {
    if (passedIsArchive) {
      onUnarchiveCompanyProject(passedId);
    } else {
      onArchiveCompanyProject(passedId);
    }
  };

  // Redirects
  if (goToParentProject && backToParentPathname) {
    // redirect to the parent after deleting an extended task from within itself
    return (
      <Redirect
        to={{
          pathname: backToParentPathname,
          state: { lastTabIndex: TAB_INDEX.TASKS, fromCloseEvent: true },
        }}
      />
    );
  }
  if (goToProjectsList) {
    return (
      <Redirect
        to={{ pathname: '/projects', state: { fromCloseEvent: true } }}
      />
    );
  }

  let timeManager = null;
  if (contentBasicInfo && timetrackingItems && projectUsers) {
    timeManager = new TimeManager(contentBasicInfo);
    try {
      timeManager.generateTimesheets({
        users: projectUsers,
        timetrackingItems,
      });
    } catch (err) {
      // ignore error
    }

    const processedTimetrackingItemMap = timeManager.getItemMap();

    _.forEach(timetrackingItems, timetrackingItem => {
      const _timetrackingItem = timetrackingItem;
      const processedItem = processedTimetrackingItemMap.get(
        timetrackingItem.contentId
      );
      if (processedItem) {
        _timetrackingItem.isInvalid = processedItem.isInvalid;
      }
    });
  }

  const isOrIsInTemplate = topParentInfo && topParentInfo.type === 'template';
  const styleOverides = {
    marginTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  };

  const hideUpgradeRequired = () => {
    setUpgradeDialogOption({ open: false });
  };

  const applyPermsToSubs = async () => {
    let applyPermsResponse;
    try {
      applyPermsResponse = await applyPermissionsToSubprojects({
        variables: {
          contentId: contentBasicInfo.contentId,
          companyId: managingCompanyInfo.managingCompanyId,
        },
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('applyPermsToSubs err: ', err);
    }

    if (contentToShowQuery) {
      // refetch the project's content now that it's been updated to ensure the UI is up to date
      await contentToShowQuery.refetch();
    }

    return _.get(
      applyPermsResponse,
      'data.applyParentPermissionsToSubprojects'
    );
  };

  const applyLaborRatesToSubs = async () => {
    setApplyingLaborRatesAndWaiting(true);
    let applyLaborRatesResponse;
    try {
      applyLaborRatesResponse = await applyLaborRatesToSubprojects({
        variables: {
          contentId: contentBasicInfo.contentId,
          companyId: managingCompanyInfo.managingCompanyId,
        },
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('applyLaborRatesToSubs err: ', err);
    }

    if (contentToShowQuery) {
      // we want to wait a few seconds here to allow the stats to be updated via the stream
      await jsDelay(3000);
      // refetch the project's content now that it's been updated to ensure the UI is up to date
      await contentToShowQuery.refetch();
    }
    setApplyingLaborRatesAndWaiting(false);
    return _.get(applyLaborRatesResponse, 'data.applyLaborRatesToSubprojects');
  };

  // Render
  return (
    <>
      {contentBasicInfo &&
        contentBasicInfo.jrnId &&
        contentBasicInfo.jrnId !== TOP_PROJECT_ID && (
          <Link
            to={{
              pathname: `/projects/${contentBasicInfo.jrnId}`,
              state: {
                lastTabIndex:
                  contentBasicInfo.type === 'project'
                    ? TAB_INDEX.SUBPROJECTS
                    : TAB_INDEX.TASKS,
              },
            }}
            style={{ display: 'flex', margin: '8px 16px', color: '#666' }}
          >
            <Button color="inherit">
              <ArrowBackIosIcon /> Go To Parent Project
            </Button>
          </Link>
        )}
      <div className={classes.projectDetailsWrapper}>
        {contentBasicInfo && contentBasicInfo.type === 'template' && (
          <Paper>
            <Grid container className={classes.templateIndicator}>
              <TemplateIcon color="inherit" fontSize="large" />
              <Button
                color="primary"
                variant="outlined"
                style={{ border: '1px solid #fff', color: '#fff' }}
                startIcon={<AddCircleOutlineIcon color="inherit" />}
                onClick={() => {
                  setShowManualAddDialog({
                    open: true,
                    fromThisTemplate: contentBasicInfo,
                  });
                }}
              >
                <span style={{ color: '#fff' }}>
                  Create Project From This Template
                </span>
              </Button>
            </Grid>
          </Paper>
        )}

        <Paper
          className={classes.root}
          style={isOrIsInTemplate ? styleOverides : {}}
        >
          <TopParentContext.Provider value={topParentInfo}>
            <ProjectDetailsView
              userId={userId}
              contentBasicInfo={contentBasicInfo}
              projectUsers={projectUsers}
              companyCrew={companyCrew}
              assignedToUsername={assignedToUsername}
              contentToShow={contentToShow}
              scrollPosition={scrollPosition}
              isLoading={
                isLoading || applyingPerms || applyingLaborRatesAndWaiting
              }
              resourceFound={resourceFound}
              isExtendedTask={isExtendedTask}
              refetchAll={refetchAll}
              refetchJrnContent={contentToShowQuery?.refetch}
              deleteThisContent={deleteThisContent}
              closeProject={closeProject}
              lastTabIndex={lastTabIndex}
              projectCanAdd={projectCanAdd}
              projectCanEdit={projectCanEdit}
              hideShowProject={hideShowProject}
              isArchive={isArchive || null}
              timeManager={timeManager}
              toTriggerQueryChange={toTriggerQueryChange}
              applyPermsToSubs={applyPermsToSubs}
              applyLaborRatesToSubs={applyLaborRatesToSubs}
            />
          </TopParentContext.Provider>
        </Paper>
        {showManualAddDialog.open && (
          <ContentDetailsModal
            mode={CONTENT_DETAILS_MODAL_MODE.ADD_TOP_LEVEL}
            actionType="createFromTemplate"
            fromThisTemplate={showManualAddDialog.fromThisTemplate}
            onClose={onCloseParams => {
              if (onCloseParams && onCloseParams.setQueryTo) {
                toTriggerQueryChange(onCloseParams.setQueryTo);
              }
              setShowManualAddDialog({
                ...showManualAddDialog,
                open: false,
              });
            }}
          />
        )}
        {upgradeDialogOption.open && (
          <UpgradeRequiredDialog
            handleClose={hideUpgradeRequired}
            userInfo={userInfo}
          />
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    userId: state.userInfo.userId,
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
    contentLabelSuggestions: state.appState.contentLabelSuggestions || [],
  };
}

export default compose(
  DeleteJrnAction,
  DeleteContentAction,
  ArchiveCompanyProjectAction,
  UnarchiveCompanyProjectAction,
  GetMyUserInfoAction,
  GetCompanyCrewAction,
  withApollo
)(trackWindowScroll(connect(mapStateToProps)(withRouter(ProjectDetails))));
