import gql from 'graphql-tag';

export default gql`
  mutation CreateMessage(
    $authorId: String!
    $content: String!
    $jrnId: ID!
    $createdAt: String!
    $messageId: ID!
    $synced: Boolean
  ) {
    createMessage(
      input: {
        authorId: $authorId
        content: $content
        jrnId: $jrnId
        createdAt: $createdAt
        messageId: $messageId
        synced: $synced
      }
    ) {
      __typename
      authorId
      content
      jrnId
      createdAt
      messageId
      synced
    }
  }
`;
