import React from 'react';

// UI
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import {
  HelpOutline as HelpOutlineIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@material-ui/icons';
import { connect } from 'react-redux';

import AdminTile from './admin-tile';
import {
  CONTENT_DEFINITION,
  CONTENT_TYPE,
  PRODUCT,
} from '../../../config/appDefaults';

const useStyles = makeStyles(theme => ({
  scrollableColumn: {
    overflowY: 'auto',
    height: 'calc(100vh - 64px)',
  },
  editButton: {
    padding: theme.spacing(0.5),
    margin: 0,
    minWidth: 0,
    color: '#aaa',
    '&:hover': {
      background: 'transparent',
      color: '#333',
    },
  },
  actionButtonsWrapper: {
    padding: theme.spacing(3),
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },
  headerIcon: {
    color: '#999',
    fontSize: 30,
  },
}));

const AdminHome = ({ managingCompanyInfo }) => {
  const classes = useStyles();
  // Check if they are the bookkeeper
  const isBookkeeper = managingCompanyInfo?.isCompanyBookkeeper;

  // Check if they have the RFI product
  const hasRfiProduct = managingCompanyInfo?.products?.includes(PRODUCT.RFI);

  return (
    <Grid
      container
      className={classes.scrollableColumn}
      style={{ padding: 30 }}
    >
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <AdminTile
              title="Time Tracking"
              Icon={CONTENT_DEFINITION[CONTENT_TYPE.TIMETRACKING].Icon}
              text="Manage time tracking content across all your projects"
              path="/admin/manage-time"
            />
          </Grid>
          <Grid item xs={4}>
            <AdminTile
              title="Expenses"
              subtitle="Receipts &amp; Bills"
              Icon={CONTENT_DEFINITION[CONTENT_TYPE.RECEIPT].Icon}
              text="Manage receipts and bills from all your projects"
              path="/admin/manage-receipts"
            />
          </Grid>
          <Grid item xs={4}>
            <AdminTile
              title="Income"
              subtitle="Invoices &amp; Payments"
              Icon={CONTENT_DEFINITION[CONTENT_TYPE.INVOICE].Icon}
              text="Manage invoices and payments from all your projects"
              path="/admin/manage-invoice-payments"
            />
          </Grid>
          <Grid item xs={4}>
            <AdminTile
              title="Bill Payments"
              Icon={CONTENT_DEFINITION[CONTENT_TYPE.GLOBAL_PAYMENT].Icon}
              text="Manage bill payments from all your projects"
              path="/admin/manage-bill-payments"
            />
          </Grid>
          <Grid item xs={4}>
            <AdminTile
              title="Docs"
              Icon={CONTENT_DEFINITION[CONTENT_TYPE.PDF].Icon}
              text="Manage docs from all your projects"
              path="/admin/manage-docs"
              isNew
            />
          </Grid>
          <Grid item xs={4}>
            <AdminTile
              title="Projects"
              Icon={CONTENT_DEFINITION[CONTENT_TYPE.PROJECT].Icon}
              text="Manage all your projects"
              path="/admin/manage-projects"
            />
          </Grid>
          <Grid item xs={4}>
            <AdminTile
              title="Leads"
              Icon={CONTENT_DEFINITION[CONTENT_TYPE.LEAD].Icon}
              text="Manage all your leads"
              path="/admin/manage-leads"
            />
          </Grid>
          {isBookkeeper && hasRfiProduct && (
            <Grid item xs={4}>
              <AdminTile
                title="RFI's"
                Icon={InfoOutlinedIcon}
                text="Create RFI's for clients"
                path="/admin/manage-requests-for-info"
              />
            </Grid>
          )}
          {/* NOTE: Generate-order (Stripe checkout) was previously only available to internal Level users,
              but haven't been used in a while. We may need them in future */}
          {/* {userInfo?.userType === 'level' && (
            <Grid item xs={4}>
              <AdminTile
                title="Generate Order"
                Icon={CreditCardOutlinedIcon}
                text="Generate a Stripe checkout link for a new customer"
                path="/admin/generate-order"
              />
            </Grid>
          )} */}
          {managingCompanyInfo?.managingCompanyId && (
            <Grid item xs={4}>
              <AdminTile
                title="Company Questions"
                Icon={HelpOutlineIcon}
                text="Access and manage questions from all your projects"
                path="/admin/manage-questions"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    userInfo: state.userInfo,
    managingCompanyInfo: state.appState.managingCompanyInfo,
  };
};

export default connect(mapStateToProps)(AdminHome);
