import React, { createContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useQuery, useMutation } from 'react-apollo-hooks';
import _ from 'lodash';

import ManageSubscriptionDialog from '../../layouts/Main/components/Topbar/manage-subscription-dialog/manage-subscription-dialog';
import GetCompanyById from '../../graphql/queries/get-company-by-id';
import AssignLicenseToClient from '../../graphql/mutations/mutation_assign-license-to-client';
import RevokeLicenseFromClient from '../../graphql/mutations/mutation_revoke-license-from-client';
import ListFirmLicenses from '../../graphql/queries/list-firm-licenses';
import ListFirmClients from '../../graphql/queries/list-firm-clients';
import FirmAddClientDialog from './firm-add-client-dialog';

// Create a context
export const FirmContext = createContext({
  firmLicenses: null,
  listFirmLicensesQuery: {},
  firmClients: null,
  listFirmClientsQuery: {},
  availableLicenses: null,
  setShowPurchaseSubscriptionModal: () => {},
  setShowAddClientDialog: () => {},
  openManageStripeLicenseWindow: () => {},
  assignLicense: () => {},
  revokeLicense: () => {},
});

// Create a provider component
const FirmProvider = ({ hasAddedAClient, dispatch, children }) => {
  const [
    showPurchaseSubscriptionModal,
    setShowPurchaseSubscriptionModal,
  ] = useState(null);
  const [showAddClientDialog, setShowAddClientDialog] = useState(false);

  const [assignLicenseToClient] = useMutation(AssignLicenseToClient);
  const [revokeLicenseFromClient] = useMutation(RevokeLicenseFromClient);
  const { refetch: getCompanyById } = useQuery(GetCompanyById, {
    skip: true,
    variables: {
      companyId: '',
    },
    fetchPolicy: 'network-only',
  });

  const listFirmClientsQuery = useQuery(ListFirmClients, {
    fetchPolicy: 'cache-and-network',
  });

  const listFirmLicensesQuery = useQuery(ListFirmLicenses, {
    fetchPolicy: 'cache-and-network',
  });

  const assignLicense = async ({ companyId, productId }) => {
    await assignLicenseToClient({
      variables: {
        companyId,
        productId,
      },
    });

    await getCompanyById({ companyId });
  };

  const revokeLicense = async ({ companyId, licenseId }) => {
    await revokeLicenseFromClient({
      variables: {
        companyId,
        licenseId,
      },
    });

    await getCompanyById({ companyId });
  };

  const firmLicenses = _.get(
    listFirmLicensesQuery,
    'data.listFirmLicenses.items',
    null
  );

  const firmClients = _.get(
    listFirmClientsQuery,
    'data.listFirmClients.items',
    null
  );

  useEffect(() => {
    if (firmClients && firmClients.length > 0 && !hasAddedAClient) {
      // This is for userflow
      dispatch({ type: 'HAS_ADDED_A_CLIENT' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmClients]);

  const availableLicenses = _.filter(
    firmLicenses,
    ({ companyId, isActive }) => !companyId && isActive
  );

  const openManageStripeLicenseWindow = () => {
    // If this was called, start polling every 10 seconds
    listFirmLicensesQuery.startPolling(10000);

    // Stop polling after 10 minutes
    setTimeout(() => {
      listFirmLicensesQuery.stopPolling();
    }, 600000);

    // Go to stripe to manage subscription
    window.open(process.env.REACT_APP_STRIPE_SELF_SERVE_PORTAL_LINK, '_blank');
  };

  return (
    <FirmContext.Provider
      value={{
        // Queries
        firmLicenses,
        listFirmLicensesQuery,
        firmClients,
        listFirmClientsQuery,
        // Computed
        availableLicenses,
        // Actions
        setShowPurchaseSubscriptionModal,
        setShowAddClientDialog,
        openManageStripeLicenseWindow,
        assignLicense,
        revokeLicense,
      }}
    >
      {children}
      {showPurchaseSubscriptionModal && (
        <ManageSubscriptionDialog
          closeDialog={() => setShowPurchaseSubscriptionModal(false)}
        />
      )}
      {!!showAddClientDialog && (
        <FirmAddClientDialog
          onClose={() => {
            setShowAddClientDialog(false);
          }}
        />
      )}
    </FirmContext.Provider>
  );
};

const mapStateToProps = state => ({
  hasAddedAClient: state.appState.hasAddedAClient,
});

export default connect(mapStateToProps)(FirmProvider);
