import { isMobile } from 'react-device-detect';
import palette from '../../theme/palette';
import BackgroundImage from '../../assets/images/background.jpg';

export default () => {
  const width = isMobile ? '100%' : 460;

  return {
    authPage: {
      minHeight: '100dvh',
    },
    authContainer: {
      maxWidth: 460,
      paddingTop: 16,
      paddingBottom: 32,
      paddingLeft: 16,
      paddingRight: 16,
      minHeight: isMobile ? '100dvh' : 'auto',
    },
    levelLogoContainer: {
      textAlign: 'center',
      width,
    },
    levelLogo: {
      width: '80%',
      height: 'auto',
      maxWidth: 300,
    },
    backgroundImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'white',
      backgroundImage: `url(${BackgroundImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      zIndex: -1,
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 360,
      minWidth: 320,
      width,
      padding: '20px 24px 0px 24px',
    },
    formTitle: {
      color: palette.brandColorDarkGrey,
      fontSize: 22,
      fontWeight: 500,
      paddingBottom: 12,
    },
    formMessage: {
      paddingBottom: 16,
      color: palette.brandColorDarkGrey,
    },
    formBody: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      paddingTop: 12,
      paddingBottom: 12,
    },
    formActions: {
      paddingLeft: isMobile ? 12 : 24,
      paddingRight: isMobile ? 12 : 24,
    },
    formErrorContainer: {
      marginTop: 8,
      marginBottom: 8,
    },
    errorText: {
      color: palette.brandColorError,
    },
    infoText: {
      color: palette.brandColorMidGrey,
    },
  };
};
