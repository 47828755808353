import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
  Button,
  Divider,
  Grid,
} from '@material-ui/core';

const HEADER_COLUMN_WIDTH = 240;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: 8,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
    paddingLeft: HEADER_COLUMN_WIDTH + 16,
  },
  headerColumn: {
    width: HEADER_COLUMN_WIDTH,
  },
  titleIcon: {
    color: theme.palette.text.secondary,
  },
}));

const SettingsSection = ({
  icon,
  title,
  description,
  children,
  onDone,
  onDoneText = 'Save',
  onCancel,
  onCancelText = 'Cancel',
  onDoneDisabled = false,
  hashKey = '',
}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (hashKey) {
      const { hash } = window.location;
      if (hash.includes(`#${hashKey}`)) {
        setExpanded(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
          onClick={() => setExpanded(!expanded)}
        >
          <Grid container alignItems="center">
            <Grid container item className={classes.headerColumn}>
              <Grid container item alignItems="center">
                {icon ? (
                  <Grid
                    item
                    className={classes.titleIcon}
                    style={{ marginRight: 6, marginTop: 2, width: 24 }}
                  >
                    {icon}
                  </Grid>
                ) : null}
                <Grid item>
                  <Typography variant="h6" color="textPrimary">
                    {title}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Typography variant="body1" color="textSecondary">
              {description}
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {children}
        </AccordionDetails>
        {(onDone || onCancel) && (
          <>
            <Divider />
            <AccordionActions>
              {onCancel && (
                <Button variant="outlined" onClick={onCancel}>
                  {onCancelText}
                </Button>
              )}
              {onDone && (
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={onDoneDisabled}
                  onClick={onDone}
                >
                  {onDoneText}
                </Button>
              )}
            </AccordionActions>
          </>
        )}
      </Accordion>
    </div>
  );
};

export default SettingsSection;
