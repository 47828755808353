import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  ${Fragment.Jrn}
  ${Fragment.Comment}
  ${Fragment.Customer}
  ${Fragment.Relation}
  query GetJrn($jrnId: ID!) {
    getJrn(jrnId: $jrnId) {
      ...JrnBaseFields
      customerInfo {
        ...CustomerFields
      }
      comments {
        items {
          ...CommentFields
        }
      }
      budgets {
        ...BudgetFields
      }
      vendor {
        ...RelationFields
      }
    }
  }
`;
