import './verification-code.scss';
import React from 'react';
import VerificationInput from 'react-verification-input';

const VerificationCodeInput = ({ value, onChange }) => {
  return (
    <VerificationInput
      value={value}
      onChange={onChange}
      validChars="0-9"
      autoFocus
      classNames={{
        character: 'character',
        characterSelected: 'character--selected',
      }}
    />
  );
};

export default VerificationCodeInput;
