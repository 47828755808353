import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import FilterList from '../FilterSearch/filterSearch';
import SingleConversation from './single-conversation';

const ListOfConvosView = props => {
  const {
    loading,
    convos,
    onClickOfProject,
    chosenConvoId,
    refetchListingConvos,
    setShowManualAddDialog,
    myCrew,
  } = props;

  const [useThisData, setUseThisData] = React.useState(convos);
  const [isFiltered, setIsFiltered] = React.useState(false);

  React.useEffect(() => {
    setUseThisData(convos);
  }, [convos]);

  return (
    <Grid
      item
      container
      alignItems="center"
      direction="column"
      spacing={2}
      style={{ padding: 16 }}
    >
      <Grid container item style={{ width: '100%' }}>
        <Grid item style={{ flex: 1 }}>
          <FilterList
            data={convos}
            isFiltered={filterStatus => setIsFiltered(filterStatus)}
            passBack={filteredData => setUseThisData(filteredData)}
          />
        </Grid>
        <Grid item>
          <Tooltip title="Refresh the list">
            <IconButton
              onClick={() => {
                refetchListingConvos();
              }}
              alt="Refresh List"
            >
              <RefreshIcon style={{ fontSize: 'inherit', color: 'inherit' }} />
            </IconButton>
          </Tooltip>
          {myCrew && myCrew.length > 0 && (
            <Tooltip title="Add a conversation">
              <IconButton
                onClick={() => {
                  setShowManualAddDialog({
                    open: true,
                    mode: 'addTopLevel',
                    existingInfo: { type: 'conversation' },
                  });
                }}
                alt="Add a conversation"
              >
                <AddIcon style={{ fontSize: 'inherit', color: 'inherit' }} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <Grid container item alignItems="center">
        {loading &&
          !useThisData &&
          [1, 2, 3, 4, 5].map(key => <SingleConversation key={key} loading />)}
        <Grid item xs={12}>
          {useThisData && useThisData.length === 0 && isFiltered && (
            <Typography variant="h5" align="center" style={{ padding: 24 }}>
              No search results.
            </Typography>
          )}
          {useThisData && useThisData.length === 0 && !isFiltered && (
            <Typography variant="h5" align="center" style={{ padding: 24 }}>
              No conversations yet.
            </Typography>
          )}

          {useThisData &&
            useThisData.map(convo => (
              <SingleConversation
                convo={convo}
                key={convo.contentId}
                onClickOfProject={onClickOfProject}
                highlightSelected={convo.contentId === chosenConvoId}
              />
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListOfConvosView;
