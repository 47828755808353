import moment from 'moment';
import _ from 'lodash';

import monetaryRender from '../monetary-render';

import {
  VERYFI_KEY_TO_INFO,
  FINANCE_INFO_OPENER,
  FINANCE_INFO_CLOSER,
} from '../../config/appDefaults';

const generateDataFromOcr = ({ ocrData }) => {
  if (!ocrData || ocrData.status !== 'ok') {
    return null;
  }

  let dataJsonAsObject;
  try {
    dataJsonAsObject = JSON.parse(ocrData.dataJson || '{}');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('err parsing ocrData.dataJson: ', err);
  }
  if (!dataJsonAsObject) {
    return null;
  }
  // set the value from the ocrData
  const isDuplicateToWrite = !!dataJsonAsObject.is_duplicate;
  const ocrId = dataJsonAsObject.is_duplicate
    ? dataJsonAsObject.duplicate_of
    : dataJsonAsObject.id;
  // initializeFrom.amount = { value: dataJsonAsObject.total };
  const amountToWrite = dataJsonAsObject.total
    ? { value: dataJsonAsObject.total }
    : null;

  const dateToWrite = dataJsonAsObject.date
    ? new Date(
        moment(dataJsonAsObject.date, 'YYYY-MM-DD HH:mm:ss').toISOString()
      )
    : null;
  let descriptionToWrite;
  const dueDateToWrite = dataJsonAsObject.due_date
    ? new Date(moment(dataJsonAsObject.due_date, 'YYYY-MM-DD').toISOString())
    : null;
  const invoiceNumberToWrite = dataJsonAsObject.invoice_number;
  const poNumberToWrite = dataJsonAsObject.purchase_order_number;
  const vendorNameToWrite = dataJsonAsObject.vendor?.name;

  // add important info to description
  // list fields to be included and their subfields
  const useForDescription = {
    subtotal: {},
    tax: {},
    tip: {},
    total: {},
    payment: {
      display_name: {},
    },
    vendor: {
      name: {},
      address: {},
    },
  };
  const processForShowing = (thing, fromPathAsArray) => {
    if (!thing) {
      return 'n/a';
    }
    if (typeof thing === 'object') {
      let toReturn = '<br />';
      _.each(thing, (value, key) => {
        // if the key exists in the useForDescription, then add it
        if (_.get(useForDescription, [...fromPathAsArray, key])) {
          const labelText =
            VERYFI_KEY_TO_INFO[fromPathAsArray[0]].subFields[key]?.label || key;
          const valueText = value || 'n/a';
          toReturn += `${labelText}: ${valueText}`;
          toReturn += '<br />';
        }
      });
      return toReturn;
    }
    return thing;
  };

  descriptionToWrite = `${FINANCE_INFO_OPENER}<br />`;
  _.each(useForDescription, (value, key) => {
    let label = VERYFI_KEY_TO_INFO[key]?.label || key;
    const typeOfData = VERYFI_KEY_TO_INFO[key]?.valueType;
    if (dataJsonAsObject[key] && typeof dataJsonAsObject[key] === 'object') {
      label = `<br /><b>${label}</b>`;
    }
    let valueText = processForShowing(dataJsonAsObject[key], [key]);
    if (typeOfData === 'float-money') {
      valueText = monetaryRender({
        value: valueText,
        withDecimals: true,
      });
    }
    descriptionToWrite += `${label}: ${valueText}`;
    descriptionToWrite += `<br />`;
  });
  descriptionToWrite += FINANCE_INFO_CLOSER;

  return {
    amount: amountToWrite,
    date: dateToWrite,
    description: descriptionToWrite,
    dueDate: dueDateToWrite,
    invoiceNumber: invoiceNumberToWrite,
    poNumber: poNumberToWrite,
    vendorName: vendorNameToWrite,
    isDuplicate: isDuplicateToWrite,
    ocrId,
  };
};

export default generateDataFromOcr;
