import React from 'react';
import { Grid } from '@material-ui/core';

import LevelLogo from '../../components/level-logo/level-logo';
import { useStyles } from './onboarding.styles';
import LoadingCover from '../../components/LoadingCover/loadingCover';

const OnboardingLoading = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: 24 }}>
        <LevelLogo logoVariation="new" className={classes.smallLevelLogo} />
      </Grid>
      <Grid
        container
        item
        xs={12}
        style={{ marginTop: 100, marginBottom: 100, textAlign: 'center' }}
      >
        <LoadingCover />
      </Grid>
    </Grid>
  );
};

export default OnboardingLoading;
