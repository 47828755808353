import React from 'react';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import uuid from 'uuid';

import { BOOKKEEPING_REPORT_ALERT_DICTIONARY } from '../bookkeeping-alert-dictionary';
import BookkeepingAlertsReportAlertListItem from './bookkeeping-alerts-report-alert-list-item';
import { BOOKKEEPING_ALERT_REPORT_DATA_TYPE } from '../bookkeeping-alerts.constants';

const BookkeepingAlertsReportAlertList = ({
  alertIdentifier,
  alertDetails,
  addressedStateEventMetaMap,
  processingAlertInstanceMap,
  setProcessingAlertInstanceMap,
  hideAddressedColumn,
  isPrinting,
}) => {
  if (!alertDetails) {
    return null;
  }

  const typeOfAlertDetails =
    BOOKKEEPING_REPORT_ALERT_DICTIONARY[alertIdentifier];
  if (!typeOfAlertDetails) {
    return null;
  }

  if (!alertDetails?.data?.length) {
    return null;
  }

  return (
    <Grid container>
      {alertDetails.type ===
        BOOKKEEPING_ALERT_REPORT_DATA_TYPE.USER_TXNS_ARRAY &&
        _.map(alertDetails.data, alertData => {
          const {
            name: userTxnUser,
            type: userTxnType,
            data: userTxnData,
          } = alertData;
          return (
            <BookkeepingAlertsReportAlertListItem
              key={uuid()}
              alertIdentifier={alertIdentifier}
              name={userTxnUser}
              dataType={userTxnType}
              data={userTxnData}
              addressedStateEventMetaMap={addressedStateEventMetaMap}
              processingAlertInstanceMap={processingAlertInstanceMap}
              setProcessingAlertInstanceMap={setProcessingAlertInstanceMap}
              hideAddressedColumn={hideAddressedColumn}
              isPrinting={isPrinting}
            />
          );
        })}
      {alertDetails.type !==
        BOOKKEEPING_ALERT_REPORT_DATA_TYPE.USER_TXNS_ARRAY && (
        <BookkeepingAlertsReportAlertListItem
          alertIdentifier={alertIdentifier}
          dataType={alertDetails.type}
          data={alertDetails.data}
          addressedStateEventMetaMap={addressedStateEventMetaMap}
          processingAlertInstanceMap={processingAlertInstanceMap}
          setProcessingAlertInstanceMap={setProcessingAlertInstanceMap}
          hideAddressedColumn={hideAddressedColumn}
          isPrinting={isPrinting}
        />
      )}
    </Grid>
  );
};

export default BookkeepingAlertsReportAlertList;
