import React from 'react';
import Grid from '@material-ui/core/Grid';

import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import {
  LEAD_STATUS_INITIAL,
  LEAD_STATUS_IN_PROGRESS_PENDING,
  LEAD_STATUS_REJECTED,
  LEAD_STATUS_IN_PROGRESS_ACCEPTED,
} from '../../config/appDefaults';

import palette from '../../theme/palette';

const renderStatusChip = ({
  contentStatus,
  justify = 'center',
  alignItems = 'center',
  hideIcons = false,
  style = {},
}) => {
  let chipColor = palette.brandColorPrimary;
  let chipWording = 'OPEN';
  let chipIcon = <RemoveCircleOutlineIcon style={{ color: chipColor }} />;
  switch (contentStatus) {
    case LEAD_STATUS_INITIAL:
      // just leave defaults above
      break;
    case LEAD_STATUS_IN_PROGRESS_PENDING:
      chipColor = palette.brandColorOrange;
      chipWording = 'SENT';
      chipIcon = <MailOutlineIcon style={{ color: chipColor }} />;
      break;
    case LEAD_STATUS_REJECTED:
      chipColor = palette.brandColorError;
      chipWording = 'REJECTED';
      chipIcon = <NotInterestedIcon style={{ color: chipColor }} />;
      break;
    case LEAD_STATUS_IN_PROGRESS_ACCEPTED:
      chipColor = palette.brandColorGreen;
      chipWording = 'ACCEPTED';
      chipIcon = <CheckCircleOutlineIcon style={{ color: chipColor }} />;
      break;
    default:
      break;
  }
  return (
    <Grid container justifyContent={justify} alignItems={alignItems}>
      <Grid
        item
        style={{
          backgroundColor: '#fff',
          border: `1px solid ${chipColor}`,
          borderRadius: 15,
          color: chipColor,
          padding: `0 8px`,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          ...style,
        }}
      >
        <Grid
          item
          style={{
            textTransform: 'uppercase',
          }}
        >
          {chipWording}
        </Grid>
        {!hideIcons && (
          <Grid item style={{ marginTop: 2, marginLeft: 8 }}>
            {chipIcon}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default renderStatusChip;
