import React, { useMemo, useRef, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import _ from 'lodash';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { FREQUENCY_OF_BOOKKEEPING_TASKS } from '../../../config/appDefaults';
import { UpdateBookkeepingAlertPreferencesAction } from '../../../graphql/graphql';
import palette from '../../../theme/palette';
import OkCancelDialog from '../../OkCancelDialog/okCancelDialog';
import BookkeepingAlertsToggle, {
  COMPONENT_TYPES,
} from '../bookkeeping-alerts-toggle';
import {
  BOOKKEEPING_ALERT_NOTIFICATION_METHODS,
  BOOKKEEPING_ALERT_RECIPIENT_TYPES,
  BOOKKEEPING_ALERT_TYPES,
} from '../bookkeeping-alerts.constants';
import styles from '../bookkeeping-alerts.styles';
import { WIDTH_OF_ALERT_TOGGLE_COLUMN } from './bookkeeping-alerts-settings.constants';
import { BOOKKEEPING_ALERT_SETTINGS_INFO } from './bookkeeping-report-settings-info';

const useStyles = makeStyles(styles);

const BookkeepingAlertsSettingsListItem = ({
  alertIdentifier,
  filteredRules,
  filteredAlerts,
  alertLists,
  onChange,
  subs,
  generalSettings,
  managingCompanyInfo,
  bookkeepingAlertPreferences,
  onUpdateBookkeepingAlertPreferences,
  useCheckboxForRules = false,
  toggleRowContainerStyle,
}) => {
  const hasSubs = !_.isEmpty(subs);
  const numberOfAlertColumns = _.size(alertLists);
  const totalColums = numberOfAlertColumns + 1; // 1 for the enabled (RULE) column
  const alertDetailsFromDictionary =
    BOOKKEEPING_ALERT_SETTINGS_INFO[alertIdentifier];
  const {
    name,
    headline,
    isNew,
    settingsFromPreferences,
  } = alertDetailsFromDictionary;

  const classes = useStyles({ hasSubs });

  const [
    showUpdatePreferencesDialog,
    setShowUpdatePreferencesDialog,
  ] = useState({ open: false, fieldsToEdit: null, errors: null });
  const [updateValueIdMap, setUpdatingValueIdMap] = useState({});
  const localAlertsRef = useRef([]);
  const localRulesRef = useRef([]);

  const [expanded, setExpanded] = useState(false);
  const handleAccordionChange = panel => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Generate a the list of alerts to use here and store using a memo
  const {
    generalSettingsToUse,
    ruleToUse,
    alertsToUse,
    subItemsToUse,
  } = useMemo(() => {
    const determineRuleToUse = ({ alertIdentifierSub = null }) => {
      const existingRule = _.find(filteredRules, { alertIdentifierSub });

      if (existingRule) {
        return existingRule;
      }

      const localExistingRule = _.find(localRulesRef.current, {
        alertIdentifierSub,
      });

      if (localExistingRule) {
        return localExistingRule;
      }

      const newLocalRule = {
        id: uuid(),
        companyId: managingCompanyInfo?.managingCompanyId,
        alertIdentifier,
        alertIdentifierSub,
        enabled: false,
      };

      localRulesRef.current.push(newLocalRule);

      return newLocalRule;
    };

    const determineAlertToUse = ({ recipient, alertIdentifierSub = null }) => {
      const existingAlert = _.find(filteredAlerts, {
        recipient,
        alertIdentifierSub,
        recipientType: BOOKKEEPING_ALERT_RECIPIENT_TYPES.GROUP,
      });

      if (existingAlert) {
        return existingAlert;
      }

      const localExistingAlert = _.find(localAlertsRef.current, {
        recipient,
        alertIdentifierSub,
        recipientType: BOOKKEEPING_ALERT_RECIPIENT_TYPES.GROUP,
      });

      if (localExistingAlert) {
        return localExistingAlert;
      }

      const newLocalAlert = {
        id: uuid(),
        companyId: managingCompanyInfo?.managingCompanyId,
        alertIdentifier,
        alertIdentifierSub,
        enabled: false,
        recipient,
        recipientType: BOOKKEEPING_ALERT_RECIPIENT_TYPES.GROUP,
        notificationMethod: BOOKKEEPING_ALERT_NOTIFICATION_METHODS.EMAIL_REPORT,
      };

      localAlertsRef.current.push(newLocalAlert);

      return newLocalAlert;
    };

    // General settings
    const generalSettingsRules = [];
    _.forEach(generalSettings, generalSetting => {
      const generalRule = determineRuleToUse({
        alertIdentifierSub: generalSetting.value,
      });

      generalSettingsRules.push({
        label: generalSetting.label,
        value: generalSetting.value,
        rule: generalRule,
      });
    });

    const baseRule = determineRuleToUse({ alertIdentifierSub: null });

    // BASE - ORDER CRITICAL
    const baseAlerts = [];

    _.forEach(alertLists, ({ listId }) => {
      const baseAlert = determineAlertToUse({
        recipient: listId,
        alertIdentifierSub: null,
      });

      baseAlerts.push(baseAlert);
    });

    // SUB ITEMS - ORDER CRITICAL
    const subItems = [];
    _.forEach(subs, sub => {
      const subRule = determineRuleToUse({ alertIdentifierSub: sub.value });

      const subAlerts = [];

      _.forEach(alertLists, ({ listId }) => {
        const subAlert = determineAlertToUse({
          recipient: listId,
          alertIdentifierSub: sub.value,
        });

        subAlerts.push(subAlert);
      });

      subItems.push({
        label: sub.label,
        value: sub.value,
        rule: subRule,
        alerts: subAlerts,
      });
    });

    return {
      generalSettingsToUse: generalSettingsRules,
      ruleToUse: baseRule,
      alertsToUse: baseAlerts,
      subItemsToUse: subItems,
    };
  }, [
    generalSettings,
    alertLists,
    subs,
    filteredRules,
    alertIdentifier,
    filteredAlerts,
    managingCompanyInfo,
  ]);

  const handleToggle = ({ item, type }) => async event => {
    const switchValue = event.target.checked;

    setUpdatingValueIdMap(currentState => {
      return { ...currentState, [item.id]: true };
    });

    await onChange({ ...item, enabled: switchValue, type });

    setUpdatingValueIdMap(currentState => {
      return { ...currentState, [item.id]: false };
    });
  };

  const handleEditSettingsFromPreferencesClick = () => {
    const fieldsToEdit = [];

    _.forEach(settingsFromPreferences, ({ key, isEditable, label }) => {
      if (isEditable) {
        fieldsToEdit.push({
          key,
          label,
          value: bookkeepingAlertPreferences[key],
        });
      }
    });

    setShowUpdatePreferencesDialog({
      open: true,
      fieldsToEdit,
      errors: {},
    });
  };

  const renderPreferencesInputFields = () => {
    const updateInputValue = ({ key, newValue, error }) => {
      setShowUpdatePreferencesDialog(currentState => {
        return {
          ...currentState,
          fieldsToEdit: _.map(currentState.fieldsToEdit, field => {
            if (field.key === key) {
              return {
                ...field,
                value: newValue,
              };
            }
            return field;
          }),
          errors: {
            ...currentState.errors,
            [key]: error,
          },
        };
      });
    };

    return _.map(
      showUpdatePreferencesDialog.fieldsToEdit,
      ({ key, label, value }) => {
        switch (key) {
          case 'daysAfterPeriodBookCompleted': {
            return (
              <React.Fragment key={key}>
                <Typography>{label}</Typography>
                <TextField
                  type="number"
                  name={key}
                  value={value}
                  error={!!showUpdatePreferencesDialog.errors?.[key]}
                  helperText={showUpdatePreferencesDialog.errors?.[key]}
                  onChange={event => {
                    const newValue = event.target.value;
                    let error = null;

                    const newValueAsNumber = parseFloat(newValue);
                    if (
                      !newValue ||
                      !_.isNumber(newValueAsNumber) ||
                      newValueAsNumber < 0
                    ) {
                      error = 'Must be greater than or equal zero (>= 0)';
                    }

                    updateInputValue({ key, newValue, error });
                  }}
                  style={{ width: 310, marginBottom: 24 }}
                />
              </React.Fragment>
            );
          }

          case 'frequencyOfBookkeepingTasks': {
            return (
              <React.Fragment key={key}>
                <Typography>{label}</Typography>
                <Select
                  value={value}
                  onChange={event => {
                    updateInputValue({
                      key,
                      newValue: event.target.value,
                      error: null,
                    });
                  }}
                  style={{ width: 310, marginBottom: 24 }}
                >
                  {_.values(FREQUENCY_OF_BOOKKEEPING_TASKS).map(frequency => {
                    return (
                      <MenuItem key={frequency} value={frequency}>
                        {frequency}
                      </MenuItem>
                    );
                  })}
                </Select>
              </React.Fragment>
            );
          }

          default: {
            // eslint-disable-next-line no-console
            console.error(
              `renderPreferencesField ~ No input field implemented for key: ${key}`
            );
            return null;
          }
        }
      }
    );
  };

  const renderToggleRow = ({ rule, alerts, tooltip }) => {
    return (
      <Grid
        container
        item
        justifyContent="flex-end"
        alignItems="center"
        style={{
          flexWrap: 'nowrap',
          width: WIDTH_OF_ALERT_TOGGLE_COLUMN * totalColums,
          marginRight: 16,
          ...toggleRowContainerStyle,
        }}
      >
        <Grid
          key={rule.id}
          container
          item
          justifyContent="center"
          alignItems="center"
          style={{
            width: WIDTH_OF_ALERT_TOGGLE_COLUMN,
          }}
        >
          <BookkeepingAlertsToggle
            checked={rule.enabled}
            component={
              useCheckboxForRules
                ? COMPONENT_TYPES.CHECKBOX
                : COMPONENT_TYPES.SWITCH
            }
            isLoading={updateValueIdMap[rule.id]}
            tooltip={tooltip}
            onToggle={handleToggle({
              item: rule,
              type: BOOKKEEPING_ALERT_TYPES.RULE,
            })}
          />
        </Grid>
        {_.map(alerts, alert => {
          return (
            <Grid
              key={alert.id}
              container
              item
              justifyContent="center"
              alignItems="center"
              style={{ width: WIDTH_OF_ALERT_TOGGLE_COLUMN }}
            >
              <BookkeepingAlertsToggle
                key={alert.id}
                checked={alert.enabled}
                component={COMPONENT_TYPES.CHECKBOX}
                isLoading={updateValueIdMap[alert.id]}
                disabled={!rule.enabled || updateValueIdMap[rule.id]}
                tooltip={tooltip}
                disabledTooltip="Rule must be enabled first"
                onToggle={handleToggle({
                  item: alert,
                  type: BOOKKEEPING_ALERT_TYPES.ALERT,
                })}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  if (!alertDetailsFromDictionary) {
    return null;
  }

  const generalInfo = (
    <Grid container direction="column" justifyContent="flex-start" item>
      <Grid item className={classes.alertName}>
        <Grid container justifyContent="flex-start">
          {isNew && (
            <InputLabel shrink className={classes.newIndicator}>
              NEW
            </InputLabel>
          )}
          <Typography variant="body1" className={classes.alertTitle}>
            {name}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={classes.alertDescription}>
          {headline}
        </Typography>
      </Grid>
      {generalSettingsToUse?.length > 0 && (
        <Grid container item direction="column">
          {_.map(generalSettingsToUse, ({ label: alertLabel, value, rule }) => (
            <Grid container item alignItems="center" key={value}>
              <BookkeepingAlertsToggle
                checked={rule.enabled}
                component={COMPONENT_TYPES.CHECKBOX}
                isLoading={updateValueIdMap[rule.id]}
                onToggle={handleToggle({
                  item: rule,
                  type: BOOKKEEPING_ALERT_TYPES.RULE,
                })}
              />
              <Typography variant="body2">{alertLabel}</Typography>
            </Grid>
          ))}
        </Grid>
      )}
      {settingsFromPreferences?.length > 0 && bookkeepingAlertPreferences && (
        <Grid container item direction="column">
          {_.map(
            settingsFromPreferences,
            ({ key, label, isEditable, valueNote, transformFunc }) => {
              return (
                <Grid
                  key={key}
                  container
                  item
                  direction="column"
                  style={{ marginTop: 4, marginLeft: 16 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      className={classes.alertPreferencesLabel}
                    >
                      {label}
                    </Typography>
                  </Grid>
                  <Grid container item direction="row" alignItems="center">
                    <Typography
                      variant="body1"
                      className={classes.alertPreferencesValue}
                    >
                      {transformFunc(bookkeepingAlertPreferences[key])}
                      {!!valueNote && (
                        <span className={classes.alertPreferencesValueNote}>
                          &nbsp;{valueNote}
                        </span>
                      )}
                    </Typography>
                    {!!isEditable && (
                      <IconButton
                        size="small"
                        onClick={handleEditSettingsFromPreferencesClick}
                        style={{ marginLeft: 4 }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              );
            }
          )}
        </Grid>
      )}
    </Grid>
  );

  return (
    <>
      {!hasSubs && (
        <Grid
          key={alertIdentifier}
          container
          item
          className={clsx(classes.alertRow, classes.noSubsRowWrapper)}
        >
          {generalInfo}
          {renderToggleRow({ rule: ruleToUse, alerts: alertsToUse })}
        </Grid>
      )}
      {hasSubs && (
        <Accordion
          key={alertIdentifier}
          expanded={expanded === alertIdentifier}
          onChange={handleAccordionChange(alertIdentifier)}
          elevation={0}
          className={clsx(classes.alertRow, classes.hasSubsRowWrapper)}
          classes={{
            expanded: classes.accordionExpanded,
          }}
        >
          <AccordionSummary
            expandIcon={
              <IconButton style={{ background: palette.background.alt }}>
                <ExpandMoreIcon />
              </IconButton>
            }
            classes={{
              content: classes.accordionSummaryContent,
              expandIcon: classes.expandIcon,
            }}
            IconButtonProps={{ style: { padding: 2 } }}
            style={{ paddingLeft: 0 }}
          >
            <Grid container alignItems="center">
              <Grid item>{generalInfo}</Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0, marginBottom: 24 }}>
            <Grid container>
              {subItemsToUse.map(
                ({ value: subValue, label: subLabel, alerts, rule }, index) => {
                  return (
                    <Grid
                      key={subValue}
                      container
                      item
                      alignItems="center"
                      className={classes.subWrapper}
                      style={{
                        borderBottom:
                          index === subItemsToUse.length - 1
                            ? 'none'
                            : '1px dotted #e0e0e0',
                        width: '100%',
                      }}
                    >
                      <Grid item style={{ paddingLeft: 24, flexGrow: 1 }}>
                        <Typography
                          variant="body1"
                          className={classes.subDescription}
                        >
                          {subLabel}
                        </Typography>
                      </Grid>
                      {renderToggleRow({ rule, alerts, tooltip: subLabel })}
                    </Grid>
                  );
                }
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
      {!!showUpdatePreferencesDialog?.open &&
        !!showUpdatePreferencesDialog?.fieldsToEdit && (
          <OkCancelDialog
            open
            okButtonText="Save"
            cancelButtonText="Cancel"
            title="Transactions Posted to a Closed Bookkeeping Period Settings"
            dividers={false}
            onClose={() => {
              setShowUpdatePreferencesDialog({
                open: false,
                fieldsToEdit: null,
              });
            }}
            onConfirm={async () => {
              const preferencesToUpdate = {
                ...bookkeepingAlertPreferences,
              };

              _.forEach(
                showUpdatePreferencesDialog.fieldsToEdit,
                ({ key, value }) => {
                  preferencesToUpdate[key] = value;
                }
              );

              await onUpdateBookkeepingAlertPreferences(preferencesToUpdate);
            }}
            disableOkButton={_.some(showUpdatePreferencesDialog.errors)}
          >
            {renderPreferencesInputFields()}
          </OkCancelDialog>
        )}
    </>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default compose(
  UpdateBookkeepingAlertPreferencesAction,
  connect(mapStateToProps)
)(BookkeepingAlertsSettingsListItem);
