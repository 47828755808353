import React from 'react';
import { GRAPH_TYPE, TARGET_TYPE } from '../../../config/appDefaults';

const whatAreIndirectAndOverhead = (
  <>
    <b>What are overhead and indirect job costs?</b>
    <br />
    Overhead costs are costs that cannot be directly assigned to a project.
    There are two types of overhead costs: “Indirect job costs”
    (fieldwork/production operations) and “Other overhead costs” (overhead costs
    that are OUTSIDE of fieldwork/production operations).
    <br />
    <br />
    Indirect job costs are incurred as a result of a project but are difficult
    to assign to it. For example small tools/supplies, field vehicle expenses,
    and field staff insurance. Indirect job costs can also be variable or fixed.
    Fixed costs stay constant whatever the production level.
    <br />
    <br />
    Other overhead costs include other ongoing business expenses, like marketing
    and general administration expenses.
    <br />
    <br />
    <i>
      Note: This graph is based on the data in your accounting software, so work
      with your bookkeeper to make sure your books are up to date for a given
      period before relying on this graph.
    </i>
  </>
);

export default [
  {
    name: 'labour',
    label: 'Direct Labor Costs (% of Sales)',
    targetType: TARGET_TYPE.PERCENTAGE,
    graphType: GRAPH_TYPE.LINE,
    description: (
      <>
        Your Direct Labor Costs (% of Sales) shows you how efficient your
        field/production workforce is at completing projects. In other words,
        this chart helps you answer “What % of my company’s project sales is
        spent paying field/production staff wages?”
        <br />
        <br />
        Direct Labor Costs ($) are variable costs, meaning they fluctuate with
        your production levels. So a decrease in your Direct Labor Costs (% of
        Sales) means you spent less on field/production wages and produced more
        for the given period. Whereas an increase in your Direct Labor Costs (%
        of Sales) means you spent more on field/production wages and produced
        less for the given period.
        <br />
        <br />
        Reducing and maintaining a low Direct Labor Costs (% of Sales) is a sign
        that your business is on the right track!
        <br />
        <br />
        Direct Labor Costs (% of Sales) is calculated by dividing your Direct
        Labor Costs ($) by your company’s project sales for each period.
        <br />
        <br />
        {whatAreIndirectAndOverhead}
      </>
    ),
  },
  {
    name: 'materials',
    label: 'Direct Supply & Material Costs (% of Sales)',
    targetType: TARGET_TYPE.PERCENTAGE,
    graphType: GRAPH_TYPE.LINE,
    description: (
      <>
        Your Direct Supply &amp; Material Costs (% of Sales) shows you how much
        of your company&apos;s project sales is spent on direct supplies &amp;
        materials. Direct means that the supply &amp; material costs can be
        easily and conveniently assigned to a specific project.
        <br />
        <br />
        Direct Supply &amp; Material Costs ($) are variable costs, meaning they
        fluctuate with your production levels. So a decrease in your Direct
        Supply &amp; Material Costs (% of Sales) means you spent less on direct
        supplies &amp; materials and produced more for the given period. Whereas
        an increase in your Direct Supply &amp; Material Costs (% of Sales)
        means you spent more on direct supplies &amp; materials and produced
        less for the given period.
        <br />
        <br />
        Reducing and maintaining a low Direct Supply &amp; Material Costs (% of
        Sales) is a sign that your business is on the right track!
        <br />
        <br />
        Direct Supply &amp; Material Costs (% of Sales) is calculated by
        dividing your Direct Supply &amp; Material Costs ($) by your
        company&apos;s project sales for each period.
        <br />
        <br />
        {whatAreIndirectAndOverhead}
      </>
    ),
  },
  {
    name: 'subcontractors',
    label: 'Direct Subcontractor Costs (% of Sales)',
    targetType: TARGET_TYPE.PERCENTAGE,
    graphType: GRAPH_TYPE.LINE,
    description: (
      <>
        Your Direct Subcontractor Costs (% of Sales) shows you how much of your
        company’s project sales is spent on direct subcontractors. Direct means
        that the subcontractor costs can be easily and conveniently assigned to
        a specific project.
        <br />
        <br />
        Direct Subcontractor Costs ($) are variable costs, meaning they
        fluctuate with your production levels. So a decrease in your Direct
        Subcontractor Costs (% of Sales) means you spent less on direct
        subcontractors and produced more for the given period. Whereas an
        increase in your Direct Subcontractor Costs (% of Sales) means you spent
        more on direct subcontractors and produced less for the given period.
        <br />
        <br />
        Reducing and maintaining a low Direct Subcontractor Costs (% of Sales)
        is a sign that your business is on the right track!
        <br />
        <br />
        Direct Subcontractor Costs (% of Sales) is calculated by dividing your
        Direct Subcontractor Costs ($) by your company’s project sales for each
        period.
        <br />
        <br />
        {whatAreIndirectAndOverhead}
      </>
    ),
  },
  {
    name: 'other',
    label: 'Direct Other Costs (% of Sales)',
    targetType: TARGET_TYPE.PERCENTAGE,
    graphType: GRAPH_TYPE.LINE,
    description: (
      <>
        Your Direct Other Costs (% of Sales) shows you how much of your
        company’s project sales is spent on other direct project costs, like
        equipment rental and permits. Direct means that these other project
        costs can be easily and conveniently assigned to a specific project.
        <br />
        <br />
        Direct Other Costs ($) are variable costs, meaning they fluctuate with
        your production levels. So a decrease in your Direct Other Costs (% of
        Sales) means you spent less on other project costs and produced more for
        the given period. Whereas an increase in your Direct Other Costs (% of
        Sales) means you spent more on other project costs and produced less for
        the given period.
        <br />
        <br />
        Reducing and maintaining a low Direct Other Costs (% of Sales) is a sign
        that your business is on the right track!
        <br />
        <br />
        Direct Other Costs (% of Sales) is calculated by dividing your Direct
        Other Costs ($) by your company’s project sales for each period.
        <br />
        <br />
        {whatAreIndirectAndOverhead}
      </>
    ),
  },
];
