import basicSchemaGenerator from './basic-schema-generator';
import { descMaxValidation, titleMaxValidation } from './schema-validation';

export default [
  //   run this             fieldname    type      edit   req  customValidations
  { ...basicSchemaGenerator('contentId', 'string', false, true, []) },
  { ...basicSchemaGenerator('customerId', 'string', true, false, []) },
  { ...basicSchemaGenerator('jrnId', 'string', false, true, []) },
  { ...basicSchemaGenerator('creatorId', 'string', false, true, []) },
  { ...basicSchemaGenerator('type', 'string', false, true, []) },
  { ...basicSchemaGenerator('date', 'date', false, false, []) },
  { ...basicSchemaGenerator('startDate', 'date', true, true, []) },
  {
    ...basicSchemaGenerator('endDate', 'date', true, true, []),
  },
  { ...basicSchemaGenerator('timeForDate', 'date', false, false, []) },
  { ...basicSchemaGenerator('timeForStartDate', 'date', true, true, []) },
  { ...basicSchemaGenerator('timeForEndDate', 'date', true, true, []) },
  {
    ...basicSchemaGenerator('description', 'string', true, false, [
      descMaxValidation,
    ]),
  },
  {
    ...basicSchemaGenerator('contentUrl', 'array', true, false, []),
  },
  { ...basicSchemaGenerator('aspectRatio', 'string', false, false, []) },
  { ...basicSchemaGenerator('labels', 'array', true, false, []) },
  {
    ...basicSchemaGenerator('title', 'string', true, true, [
      titleMaxValidation,
    ]),
  },
  { ...basicSchemaGenerator('coverImage', 'string', false, false, []) },
  { ...basicSchemaGenerator('addedBy', 'string', false, false, []) },
  { ...basicSchemaGenerator('useTimetracking', 'boolean', true, false, []) },
  {
    ...basicSchemaGenerator(
      'useTimetrackingQuestions',
      'boolean',
      true,
      false,
      []
    ),
  },
  { ...basicSchemaGenerator('timetrackingType', 'string', true, false, []) },
  {
    ...basicSchemaGenerator('includeSubsInStats', 'boolean', true, false, []),
  },
  { ...basicSchemaGenerator('excludeFromStats', 'boolean', true, false, []) },
  { ...basicSchemaGenerator('billable', 'boolean', true, false, []) },
];
