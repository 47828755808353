import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Select,
  MenuItem,
} from '@material-ui/core';
import { compose } from 'react-apollo';
import { BREAKEVEN_PERIOD, ACCOUNTING_METHOD } from '../../config/appDefaults';
import { UpdateScoreboardSettingsAction } from '../../graphql/graphql';
import LoadingCover from '../LoadingCover/loadingCover';
// import LoadingCover from '../../LoadingCover/loadingCover';

const DashboardSettingsReportData = ({
  scoreboardName,
  accountingMethod = null,
  accountingMethodDetails = null,
  breakevenPeriod = null,
  onUpdateScoreboardSettings,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [accountingMethodInput, setAccountingMethodInput] = useState(
    accountingMethod
  );
  const [breakevenPeriodInput, setBreakevenPeriodInput] = useState(
    breakevenPeriod
  );

  useEffect(() => {
    if (breakevenPeriod !== null) {
      setBreakevenPeriodInput(breakevenPeriod);
    }
    if (accountingMethod !== null) {
      setAccountingMethodInput(accountingMethod);
    }
  }, [breakevenPeriod, accountingMethod]);

  const updateBreakevenSettings = async () => {
    setIsLoading(true);

    const accountingMethodValue = _.find(
      ACCOUNTING_METHOD,
      ({ key }) => key === accountingMethodInput
    ).value;

    const thisScoreboardSettings = {
      scoreboardName,
      accountingMethod: accountingMethodValue,
    };

    // Breakeven period is optional for scoreboards
    if (breakevenPeriod !== null) {
      const breakevenPeriodValue = _.find(
        BREAKEVEN_PERIOD,
        ({ key }) => key === breakevenPeriodInput
      ).value;

      thisScoreboardSettings.breakevenPeriod = breakevenPeriodValue;
    }

    const scoreboardSettings = [thisScoreboardSettings];

    await onUpdateScoreboardSettings(scoreboardSettings);

    setIsLoading(false);
  };

  const handleBreakevenInputChange = event => {
    const inputValue = event.target.value;
    setBreakevenPeriodInput(inputValue);
  };

  const handleAccountingMethodChange = event => {
    const inputValue = event.target.value;
    setAccountingMethodInput(inputValue);
  };

  const hasUpdated =
    breakevenPeriod !== breakevenPeriodInput ||
    accountingMethod !== accountingMethodInput;

  return (
    <Grid container direction="row">
      <Grid container style={{ minHeight: 200 }} direction="column">
        <Grid container item style={{ flex: 1 }}>
          <Grid container item direction="column" spacing={2}>
            {breakevenPeriod !== null && (
              <>
                <Grid item>
                  <Typography style={{ fontWeight: 'bold' }} variant="body1">
                    Break-even Inputs
                  </Typography>
                </Grid>
                <Grid container item>
                  <Grid item xs={9}>
                    <Typography variant="body1" style={{ marginRight: 24 }}>
                      The break-even inputs selection is used to determine the
                      period from which your company&apos;s annual break-even
                      sales will be derived. The break-even sales for each month
                      is calculated using the average project sales, average
                      fixed costs and average variables costs for selected
                      period preceeding each month, so be sure to select the
                      period that best represents your future annual financials
                      (e.g. if the last 3 months represents the best picture of
                      your business moving forward, select &quot;Last 3
                      Months&quot;, if you are unsure select &quot;Last 12
                      Months&quot;).
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      value={breakevenPeriodInput}
                      onChange={handleBreakevenInputChange}
                      style={{
                        width: '100%',
                      }}
                    >
                      {_.map(BREAKEVEN_PERIOD, ({ key, displayValue }) => (
                        <MenuItem key={key} value={key}>
                          {displayValue}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item>
              <Typography style={{ fontWeight: 'bold' }} variant="body1">
                Accounting Method
              </Typography>
            </Grid>
            <Grid container item>
              <Grid container item xs={9} spacing={1} direction="column">
                <Grid item>
                  <Typography variant="body1" style={{ marginRight: 24 }}>
                    Choosing whether to recognize project sales and business
                    costs at the time transacted or at the time accrued while
                    analyzing your businesses health can reveal profoundly
                    different results. The <b>Cash</b> accounting method
                    recognizes your project sales and business costs when they
                    are paid, while the <b>Accrual</b> accounting method
                    recognizes sales and costs when they are earned or billed.
                  </Typography>
                </Grid>
                {accountingMethodDetails && (
                  <Grid item>{accountingMethodDetails}</Grid>
                )}
              </Grid>
              <Grid item xs={3}>
                <Select
                  value={accountingMethodInput}
                  onChange={handleAccountingMethodChange}
                  style={{
                    width: '100%',
                  }}
                >
                  {_.map(ACCOUNTING_METHOD, ({ key, displayValue }) => (
                    <MenuItem key={key} value={key}>
                      {displayValue}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item justifyContent="flex-end">
          <Grid item style={{ flex: 1 }}>
            {hasUpdated && (
              <Typography variant="body1">
                <i>
                  Note: These settings will be updated for other owners and
                  bookkeepers.
                </i>
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={updateBreakevenSettings}
              disabled={!hasUpdated}
            >
              Update
              {isLoading && (
                <CircularProgress
                  size={16}
                  style={{ marginLeft: 8, color: '#FFFFFF' }}
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {accountingMethod === null && <LoadingCover />}
    </Grid>
  );
};

export default compose(UpdateScoreboardSettingsAction)(
  DashboardSettingsReportData
);
