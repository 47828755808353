import React, { useState, useMemo } from 'react';

import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch as MuiSwitch,
  Tabs,
  Tab,
} from '@material-ui/core';

import _ from 'lodash';

import ByProjectTabPanel from './by-project-tab-panel';
import ByUserTabPanel from './by-user-tab-panel';
import OverviewSection from './overview-section';

const StatDialog = ({
  maxWidth = 'md',
  fullWidth = true,
  onClose,
  title,
  overviewMode,
  projects,
  projectUsers,
  parentProjectDetails,
  data,
  overageIsGood,
  location,
  timeManager,
  initialTabValue = 0,
  listAllTotalStatsInOverviewSection,
  budgetInfo,
}) => {
  const [hideZeroRows, setHideZeroRows] = useState(false);
  const [tabValue, setTabValue] = useState(initialTabValue);

  // Remove empty tab data
  const tabData = useMemo(() => {
    const newData = [];
    _.forEach(data, datum => {
      if (!_.isEmpty(datum)) {
        newData.push(datum);
      }
    });
    return newData;
  }, [data]);

  const tabHeadings = useMemo(() => {
    return tabData.map(datum => {
      return datum.tabName;
    });
  }, [tabData]);

  const moreThanOneTab = tabHeadings.length > 1;
  const showTabNavigator = moreThanOneTab;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderTab = (dataToUse, tabIndex) => {
    if (!dataToUse.breakdownByUser) {
      return (
        <ByProjectTabPanel
          key={tabIndex}
          data={dataToUse}
          hideZeroRows={hideZeroRows}
          location={location}
          overageIsGood={overageIsGood}
          parentProjectDetails={parentProjectDetails}
          projects={overviewMode ? projects : null}
          tabIndex={tabIndex}
          tabValue={tabValue}
          editBudgetByUser={dataToUse.editBudgetByUser}
          budgetInfo={budgetInfo}
          dialogTitle={title}
        />
      );
    }

    return (
      <ByUserTabPanel
        key={tabIndex}
        data={dataToUse}
        hideZeroRows={hideZeroRows}
        overageIsGood={overageIsGood}
        parentProjectDetails={parentProjectDetails}
        projectUsers={projectUsers}
        tabIndex={tabIndex}
        tabValue={tabValue}
        timeManager={timeManager}
        editBudgetByUser={dataToUse.editBudgetByUser}
        budgetInfo={budgetInfo}
        dialogTitle={title}
      />
    );
  };

  return (
    <>
      <Dialog maxWidth={maxWidth} fullWidth={fullWidth} open onClose={onClose}>
        {!!title && (
          <DialogTitle disableTypography>
            <Typography variant="h4">{title}</Typography>
          </DialogTitle>
        )}
        <DialogContent dividers style={{ padding: 8 }}>
          {showTabNavigator && (
            <Tabs
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="auto"
              value={tabValue}
              onChange={handleTabChange}
            >
              {tabHeadings.map(heading => (
                <Tab key={heading} label={heading} />
              ))}
            </Tabs>
          )}

          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            style={{ paddingTop: 8 }}
          >
            <Typography variant="h6">
              Hide rows with zero total and budget
            </Typography>
            <MuiSwitch
              name="hideZero"
              value={hideZeroRows}
              checked={hideZeroRows}
              variant="inline"
              onChange={() => {
                setHideZeroRows(!hideZeroRows);
              }}
            />
          </Grid>

          {tabData.map(renderTab)}

          <OverviewSection
            data={tabData}
            overageIsGood={overageIsGood}
            overviewMode={overviewMode}
            tabValue={tabValue}
            listAllTotals={listAllTotalStatsInOverviewSection}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StatDialog;
