import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo-hooks';
import { NavLink, useLocation, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import _ from 'lodash';
import { GoogleApiWrapper } from 'google-maps-react';

import GetUserInfo from '../../graphql/queries/GetUserInfo';
import GetCompanyById from '../../graphql/queries/get-company-by-id';
import constructionSign from '../../assets/images/construction-sign@4x.png';
import LevelSupportEmailAddressLink from '../../components/level-support-email-address-link/level-support-email-address-link';
import {
  EXPIRED_SUBSCRIPTION_STATUSES,
  FIRM_ROLE,
  LOCK_REASON,
  SUBSCRIPTION_STATUSES,
} from '../../config/appDefaults';
import { determineManagingCompanyInfo } from '../../helpers';
import ManageSubscriptionDialog from '../../layouts/Main/components/Topbar/manage-subscription-dialog/manage-subscription-dialog';
import LoadingCover from '../../components/LoadingCover/loadingCover';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: 100,
    textAlign: 'center',
  },
  title: {
    marginBottom: 32,
  },
  description: {
    marginBottom: 32,
  },
}));

const CompanyLocked = ({
  dispatch,
  currentAuth,
  managingCompanyInfo,
  firmInfo,
}) => {
  const classes = useStyles();
  const [showGoToPaymentModal, setShowGoToPaymentModal] = useState(false);
  const location = useLocation();
  const [checkComplete, setCheckComplete] = useState(false);
  const [redirectTo, setRedirectTo] = useState(false);

  const { state } = location;
  const { checkForProducts, checkForProductsSuccessRedirect } = state || {};

  const { data: userInfoData } = useQuery(GetUserInfo, {
    variables: {
      userId: 'willBePulledFromCognitoSubContentInResolver',
    },
    pollInterval: 5000,
  });
  const latestUserInfo = _.get(userInfoData, 'getMyUserInfo', null);

  let isFirm = false;
  let companyIdToCheck = null;
  if (latestUserInfo) {
    if (latestUserInfo.managingFirmId) {
      isFirm = true;
      companyIdToCheck = latestUserInfo.managingFirmId;
    } else if (latestUserInfo.companies?.[0]) {
      [companyIdToCheck] = latestUserInfo.companies;
    }
  }

  const { data: getCompanyByIdData } = useQuery(GetCompanyById, {
    skip: !companyIdToCheck,
    variables: {
      companyId: companyIdToCheck,
    },
    pollInterval: 3000,
    fetchPolicy: 'network-only',
  });
  const latestCompanyInfo = _.get(getCompanyByIdData, 'getCompanyById', null);

  useEffect(() => {
    // Give up on product check after 7 seconds
    const timeout = setTimeout(() => {
      setCheckComplete(true);
    }, 7000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (currentAuth.isLoggedIn) {
      if (latestUserInfo && latestCompanyInfo) {
        if (isFirm) {
          if (!_.isEqual(latestCompanyInfo, firmInfo)) {
            dispatch({
              type: 'SET_FIRM_INFO',
              payload: { ...latestCompanyInfo },
            });
          }
        } else {
          const latestManagingCompanyInfo = determineManagingCompanyInfo({
            companyInfo: latestCompanyInfo,
            userInfo: latestUserInfo,
          });

          if (!_.isEqual(latestManagingCompanyInfo, managingCompanyInfo)) {
            dispatch({
              type: 'SET_MANAGING_COMPANY_INFO',
              payload: { ...latestManagingCompanyInfo },
            });
          }

          if (checkForProducts) {
            // Check if the company now has the products
            let hasAllProducts = true;
            _.forEach(checkForProducts, product => {
              if (!latestManagingCompanyInfo.products.includes(product)) {
                hasAllProducts = false;
              }
            });

            if (hasAllProducts) {
              // If so, set redirect to the original route
              setRedirectTo(checkForProductsSuccessRedirect || '/');
              setCheckComplete(true);
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAuth, latestUserInfo, latestCompanyInfo]);

  // firms technically shouldn't see this page since their access never actual expires
  const emailToOffer = isFirm ? firmInfo?.email : latestUserInfo?.email;

  const renderLockedMessage = () => {
    let companyToCheck;
    if (latestUserInfo?.managingFirmId) {
      companyToCheck = firmInfo;
    } else {
      companyToCheck = managingCompanyInfo;
    }
    if (companyToCheck?.isLockedReason) {
      switch (companyToCheck.isLockedReason) {
        case LOCK_REASON.FREE_TRIAL_EXPIRED: {
          return (
            <>
              <Typography variant="h1" className={classes.title}>
                Your free trial has expired.
              </Typography>
              <Typography variant="h4" className={classes.description}>
                To continue using our project management, advisory or
                bookkeeping features, please contact&nbsp;
                <LevelSupportEmailAddressLink />.
              </Typography>
            </>
          );
        }
        case LOCK_REASON.SUBSCRIPTION_ENDED: {
          return (
            <>
              <Typography variant="h1" className={classes.title}>
                Your subscription has expired.
              </Typography>
              <Typography variant="h4" className={classes.description}>
                To continue using our project management, advisory or
                bookkeeping features, please contact&nbsp;
                <LevelSupportEmailAddressLink />.
              </Typography>
            </>
          );
        }
        case LOCK_REASON.PAYMENT_OVERDUE:
        default: {
          return (
            <>
              <Typography variant="h1" className={classes.title}>
                You do not have access.
              </Typography>
              <Typography variant="h4" className={classes.description}>
                This account has been locked out by a Level administrator. If
                you believe this was a mistake, please contact&nbsp;
                <LevelSupportEmailAddressLink /> to regain access.
              </Typography>
            </>
          );
        }
      }
    } else if (
      EXPIRED_SUBSCRIPTION_STATUSES.includes(companyToCheck?.subscriptionStatus)
    ) {
      const wasFreeTrial =
        companyToCheck?.subscriptionStatus ===
        SUBSCRIPTION_STATUSES.EXPIRED_FREE_TRIAL;

      let hasAbilityToResolve = false;
      if (
        latestUserInfo?.managingFirmId &&
        latestUserInfo.managingFirmRole === FIRM_ROLE.ADMIN
      ) {
        hasAbilityToResolve = true;
      } else if (latestUserInfo && !latestUserInfo.managingFirmId) {
        const { isCompanyOwner, isCompanyBookkeeper } = managingCompanyInfo;
        hasAbilityToResolve = isCompanyBookkeeper || isCompanyOwner;
      }
      return (
        <>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              {hasAbilityToResolve ? (
                <>
                  {wasFreeTrial ? (
                    <>
                      <Typography variant="h1" className={classes.title}>
                        Your free trial has expired.
                      </Typography>
                      <Typography variant="h4" className={classes.description}>
                        To continue using Level, please purchase a subscription.
                      </Typography>
                      <Grid item xs={12} style={{ marginTop: 16 }}>
                        <Grid container justifyContent="center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setShowGoToPaymentModal(true)}
                          >
                            Purchase a Subscription
                          </Button>
                        </Grid>
                      </Grid>
                      <Typography variant="h4" style={{ marginTop: 64 }}>
                        If you have any issues, please contact Level Support
                        at&nbsp;
                        <LevelSupportEmailAddressLink />.
                      </Typography>
                      {showGoToPaymentModal && (
                        <ManageSubscriptionDialog
                          closeDialog={() => {
                            setShowGoToPaymentModal(false);
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <Typography variant="h1" className={classes.title}>
                        Your subscription has expired.
                      </Typography>
                      <Typography variant="h4" className={classes.description}>
                        To continue using Level, please update your subscription
                        by clicking below and using your email address
                        {emailToOffer ? ` (${emailToOffer})` : ''}.
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        href={
                          process.env.REACT_APP_STRIPE_SELF_SERVE_PORTAL_LINK
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        endIcon={<OpenInNew />}
                        style={{ marginBottom: 64 }}
                      >
                        Manage billing and subscription
                      </Button>
                      <Typography
                        variant="h4"
                        style={{ paddingBottom: 16, fontStyle: 'italic' }}
                      >
                        We use Stripe to manage subscriptions in a safe,
                        reliable way. If you don&apos;t recall which email
                        address you use for billing, check the email address
                        that you&apos;re receiving current invoices to.
                      </Typography>
                      <Typography variant="h4" style={{ marginTop: 64 }}>
                        If you have any issues, please contact Level Support at{' '}
                        <LevelSupportEmailAddressLink />.
                      </Typography>
                    </>
                  )}
                </>
              ) : (
                <Typography
                  variant="body1"
                  style={{ paddingTop: 5, paddingBottom: 5 }}
                >
                  You do not have permission to change billing and subscription
                  details. Please ask the user who created your company account
                  to manage billing and subscription.
                </Typography>
              )}
            </Grid>
          </Grid>
        </>
      );
    }
    return (
      <>
        <Typography variant="h2" className={classes.title}>
          You do not have access.
        </Typography>
        <Button variant="contained" component={NavLink} to="/">
          Try Again
        </Button>
        <Typography variant="h5" style={{ marginTop: 64 }}>
          Please contact Level Support at <LevelSupportEmailAddressLink />.
        </Typography>
      </>
    );
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  if (checkForProducts && !checkComplete) {
    return <LoadingCover>Checking license status...</LoadingCover>;
  }

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item lg={7} xs={12}>
          <div className={classes.content}>
            {renderLockedMessage()}
            <img alt="construction sign" src={constructionSign} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  currentAuth: state.currentAuth,
  managingCompanyInfo: state.appState.managingCompanyInfo,
  firmInfo: state.appState.firmInfo,
});

export default connect(mapStateToProps)(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
  })(CompanyLocked)
);
