import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  mutation UpdateJrn(
    $contentId: ID!
    $jrnId: String
    $customerId: String
    $type: String
    $subtype: String
    $date: String
    $description: String
    $contentUrl: String
    $aspectRatio: String
    $isPublic: Boolean
    $synced: Boolean
    $labels: [String]
    $latitude: String
    $longitude: String
    $address: String
    $title: String
    $endDate: String
    $privacyLevel: String
    $inviteCode: String
    $allowedToEdit: [String!]
    $allowedToAdd: [String]
    $allowedToView: [String]
    $permissionsChangesAdd: [String]
    $permissionsChangesRemove: [String]
    $usersEverOnJrn: [String]
    $startDate: String
    $addedBy: String
    $useTimetracking: Boolean
    $useTimetrackingQuestions: Boolean
    $timetrackingQuestions: [String]
    $timetrackingQuestionsCheckout: [String]
    $billable: Boolean
    $budgets: [BudgetInput]
    $userPayInfo: [UserPayInfoInput]
    $timetrackingType: String
    $contentStatus: String
    $workflowStage: String
    $workflowStageReason: String
    $includeSubsInStats: Boolean
    $excludeFromStats: Boolean
    $amount: MoneyInput
    $paymentTerms: String
    $documentNumber: String
    $poNumber: String
    $vendorId: String
    $media: [MediaInfoInput]
  ) {
    updateJrn(
      input: {
        contentId: $contentId
        jrnId: $jrnId
        customerId: $customerId
        type: $type
        subtype: $subtype
        date: $date
        description: $description
        contentUrl: $contentUrl
        aspectRatio: $aspectRatio
        isPublic: $isPublic
        synced: $synced
        labels: $labels
        latitude: $latitude
        longitude: $longitude
        address: $address
        title: $title
        endDate: $endDate
        privacyLevel: $privacyLevel
        inviteCode: $inviteCode
        allowedToEdit: $allowedToEdit
        allowedToAdd: $allowedToAdd
        allowedToView: $allowedToView
        permissionsChangesAdd: $permissionsChangesAdd
        permissionsChangesRemove: $permissionsChangesRemove
        usersEverOnJrn: $usersEverOnJrn
        startDate: $startDate
        addedBy: $addedBy
        contentStatus: $contentStatus
        useTimetracking: $useTimetracking
        useTimetrackingQuestions: $useTimetrackingQuestions
        timetrackingQuestions: $timetrackingQuestions
        timetrackingQuestionsCheckout: $timetrackingQuestionsCheckout
        billable: $billable
        budgets: $budgets
        userPayInfo: $userPayInfo
        timetrackingType: $timetrackingType
        workflowStage: $workflowStage
        workflowStageReason: $workflowStageReason
        includeSubsInStats: $includeSubsInStats
        excludeFromStats: $excludeFromStats
        amount: $amount
        paymentTerms: $paymentTerms
        documentNumber: $documentNumber
        poNumber: $poNumber
        vendorId: $vendorId
        media: $media
      }
    ) {
      contentId
      jrnId
      title
      creatorId
      customerId
      customerInfo {
        customerId
        managingCompanyId
        firstName
        lastName
        address
        email
        latitude
        longitude
        phoneNumber
        notes
        companyName
        qboCustomerId
      }
      contentUrl
      description
      privacyLevel
      inviteCode
      endDate
      startDate
      allowedToEdit
      allowedToAdd
      allowedToView
      usersEverOnJrn
      synced
      labels
      latitude
      longitude
      address
      type
      subtype
      addedBy
      date
      isPublic
      aspectRatio
      assignedTo
      priority
      contentStatus
      useTimetracking
      useTimetrackingQuestions
      timetrackingQuestions
      timetrackingQuestionsCheckout
      permissionsFrom
      requireAdmin
      amount {
        value
      }
      billable
      fromTemplate
      timetrackingType
      lastMessageSent
      includeSubsInStats
      excludeFromStats
      qboCustomerId
      amount {
        value
      }
      paymentTerms
      documentNumber
      poNumber
      vendorId
      workflowStage
      workflowStageReason
      budgets {
        ...BudgetFields
      }
    }
  }
`;
