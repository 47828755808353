import React, { useState } from 'react';
import { compose, withApollo } from 'react-apollo';
import {
  ButtonBase,
  Typography,
  InputAdornment,
  TextField,
  Tooltip,
} from '@material-ui/core';

import {
  Cancel as CancelIcon,
  Edit as EditIcon,
  Done as DoneIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@material-ui/icons';

import numeral from 'numeral';
import omitDeep from 'omit-deep';
import _ from 'lodash';

import LaborBudgetDialog from '../../../../../add-to-project/budgets-dialog/labor-budget-dialog';

import {
  BUDGET_INFO,
  BASIC_PROJECT_BUDGETS,
} from '../../../../../../config/appDefaults';
import { handleFormattedNumberInput } from '../../../../../../helpers';

import { UpdateJrnMutationAction } from '../../../../../../graphql/graphql';

const EditableBudget = ({
  project,
  budget: budgetAmount,
  onUpdateJrn,
  nonEditableTextClass,
  budgetLabels,
  numberFormat,
  color,
  editBudgetByUser = false,
  timeManager,
  budgetInfo,
}) => {
  const [showEditable, setShowEditable] = useState(false);
  const [newBudgetAmount, setNewBudgetAmount] = useState(budgetAmount || 0);
  const [saving, setSaving] = useState(false);
  const [showLaborBudgetDialog, setShowLaborBudgetDialog] = useState({
    open: false,
    projectId: null,
    userIdsOnProject: null,
    hoursWorkedByUserId: null,
  });

  const budgetText = budgetAmount
    ? numeral(budgetAmount).format(numberFormat)
    : numeral(0).format(numberFormat);

  const cancelEdit = () => {
    setNewBudgetAmount(budgetAmount);
    setShowEditable(false);
  };

  const editThisBudget = () => {
    setShowEditable(true);
  };

  const handleChange = passedValue => {
    setNewBudgetAmount(passedValue);
  };

  const handleBlur = passedValue => {
    if (!budgetLabels || (budgetLabels && budgetLabels.length !== 1)) {
      return;
    }
    const budgetLabel = budgetLabels[0];
    const updatedValue = handleFormattedNumberInput(
      passedValue,
      BUDGET_INFO[budgetLabel].numberFormat,
      true
    ).valueAsString;
    setNewBudgetAmount(updatedValue);
  };

  const updateProject = async updatedProject => {
    const projectToUpdate = { ...updatedProject };
    try {
      const options = {
        fullCustomerDetails: updatedProject.customerInfo,
        refetchParentBudgetInfo: true,
      };
      delete projectToUpdate.customerInfo;
      delete projectToUpdate.stats;
      await onUpdateJrn(projectToUpdate, options);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('err updating budget: ', err);
    }
  };

  const saveBudget = async () => {
    if (!budgetLabels || (budgetLabels && budgetLabels.length !== 1)) {
      return;
    }

    const budgetLabel = budgetLabels[0];
    setSaving(true);
    const copyProject = _.cloneDeep(project);

    // if there already are budgets, find if the one we need is present and if so, change it
    // otherwise, add it to the list of budgets
    copyProject.budgets = _.cloneDeep(budgetInfo.budgets) || [];
    const budgetAlreadyExists = _.findIndex(copyProject.budgets, {
      label: budgetLabel,
    });
    const newBudgetAmountAsNumber = numeral(newBudgetAmount).value() || 0;
    if (budgetAlreadyExists > -1) {
      copyProject.budgets[budgetAlreadyExists].value = newBudgetAmountAsNumber;
    } else {
      const blankBudget = _.find(BASIC_PROJECT_BUDGETS, { label: budgetLabel });
      blankBudget.value = newBudgetAmountAsNumber;
      copyProject.budgets.push(blankBudget);
    }

    const cleanProjectInfo = omitDeep(copyProject, ['__typename']);

    await updateProject(cleanProjectInfo);

    setShowEditable(false);
    setSaving(false);
  };

  const handleEditBudgetByUser = () => {
    const hoursWorkedByUserId = {};
    if (timeManager) {
      project.usersEverOnJrn.forEach(userId => {
        hoursWorkedByUserId[userId] = timeManager.getHours({
          userIds: [userId],
        });
      });
    }

    setShowLaborBudgetDialog({
      open: true,
      projectId: project.contentId,
      userIdsOnProject: project.usersEverOnJrn,
      activeUserIds: _.uniq([
        ...project.allowedToAdd,
        ...project.allowedToEdit,
        ...project.allowedToView,
      ]),
      hoursWorkedByUserId,
    });
  };

  const closeLaborBudgetDialog = () => {
    setShowLaborBudgetDialog({
      open: false,
      projectId: null,
      userIdsOnProject: null,
      hoursWorkedByUserId: null,
    });
  };

  const handleLaborBudgetDialogDone = async updatedBudgetInfo => {
    setSaving(true);
    closeLaborBudgetDialog();
    const cleanProjectInfo = omitDeep(_.cloneDeep(project), ['__typename']);
    const cleanBudgetInfo = omitDeep(_.cloneDeep(updatedBudgetInfo), [
      '__typename',
    ]);

    cleanProjectInfo.budgets = cleanBudgetInfo.budgets;
    cleanProjectInfo.userPayInfo = cleanBudgetInfo.userPayInfo;

    await updateProject({ ...cleanProjectInfo });

    setSaving(false);
  };

  const hasTimetrackingDisabled = editBudgetByUser && !project.useTimetracking;
  let allowToEdit = budgetLabels && budgetLabels.length === 1;

  if (hasTimetrackingDisabled) {
    allowToEdit = false;
  }

  return (
    <>
      {allowToEdit && showEditable ? (
        <>
          <TextField
            InputProps={
              BUDGET_INFO[budgetLabels[0]].adornment
                ? {
                    startAdornment: (
                      <InputAdornment position="start">
                        {BUDGET_INFO[budgetLabels[0]].adornment}
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
            type="number"
            name="amount"
            fullWidth
            value={newBudgetAmount}
            margin="none"
            onChange={event => handleChange(event.target.value)}
            onBlur={event => handleBlur(event.target.value)}
          />
          <ButtonBase
            onClick={() => saveBudget({ project, budget: budgetAmount })}
            style={{ padding: 5, marginRight: 5 }}
            disabled={saving}
          >
            <DoneIcon
              style={{ fontSize: 20, color: saving ? '#ccc' : 'green' }}
            />
          </ButtonBase>
          <ButtonBase onClick={() => cancelEdit()} disabled={saving}>
            <CancelIcon
              style={{ fontSize: 20, color: saving ? '#ccc' : 'red' }}
            />
          </ButtonBase>
        </>
      ) : (
        <>
          <Typography
            variant="body2"
            className={nonEditableTextClass}
            style={{ minWidth: 80, textAlign: 'center', color }}
          >
            {budgetText}
          </Typography>
          {allowToEdit && (
            <ButtonBase
              onClick={() => {
                if (editBudgetByUser) {
                  handleEditBudgetByUser();
                } else {
                  editThisBudget();
                }
              }}
            >
              <EditIcon style={{ fontSize: 16, color: '#999' }} />
            </ButtonBase>
          )}
          {hasTimetrackingDisabled && (
            <Tooltip title="Time tracking feature is disabled for this project">
              <InfoOutlinedIcon style={{ fontSize: 16, color: '#999' }} />
            </Tooltip>
          )}
        </>
      )}
      {showLaborBudgetDialog.open && (
        <LaborBudgetDialog
          open
          projectId={showLaborBudgetDialog.projectId}
          userIdsOnProject={showLaborBudgetDialog.userIdsOnProject}
          activeUserIds={showLaborBudgetDialog.activeUserIds}
          hoursWorkedByUserId={showLaborBudgetDialog.hoursWorkedByUserId}
          handleClose={() => {
            closeLaborBudgetDialog();
          }}
          handleDone={handleLaborBudgetDialogDone}
        />
      )}
    </>
  );
};

export default compose(UpdateJrnMutationAction, withApollo)(EditableBudget);
