import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import {
  Grid,
  TextField,
  InputLabel,
  Typography,
  Button,
  Switch as MuiSwitch,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { v4 as uuid } from 'uuid';
import { GoogleApiWrapper } from 'google-maps-react';
import _ from 'lodash';

import LevelPellEditor from '../../views/add-to-project/level-pell-editor/level-pell-editor';
import LocationSearchInput from '../location-search-input/location-search-input';
import LoadingCover from '../LoadingCover/loadingCover';
import { isValidEmail } from '../../common/validators';
import { AddCustomerAction, UpdateCustomerAction } from '../../graphql/graphql';
import {
  MAX_CHARACTERS_IN_PHONENUMBER_FIELD,
  MAX_CHARACTERS_IN_CUSTOMER_FIELD,
} from '../../config/appDefaults';
import { standardMaxMsg } from '../../views/add-to-project/info-about-content/schema-validation';

const useStyles = makeStyles(theme => ({
  actionsButtonsWrapper: {
    marginTop: 16,
  },
  subsectionTitle: {
    color: theme.palette.brandColorPrimary,
  },
}));

const FIELD_LENGTH_LIMITS = {
  firstName: MAX_CHARACTERS_IN_CUSTOMER_FIELD,
  lastName: MAX_CHARACTERS_IN_CUSTOMER_FIELD,
  companyName: MAX_CHARACTERS_IN_CUSTOMER_FIELD,
  phoneNumber: MAX_CHARACTERS_IN_PHONENUMBER_FIELD,
};

const AddCustomerForm = ({
  managingCompanyInfo,
  initialValues,
  handleClose,
  onAddCustomer,
  onUpdateCustomer,
}) => {
  const classes = useStyles();
  const addOrEditMode = initialValues ? 'edit' : 'add';
  const basicEmptyCustomer = {
    customerId: uuid(),
    managingCompanyId: managingCompanyInfo.managingCompanyId,
    firstName: '',
    lastName: '',
    address: '',
    email: '',
    latitude: '',
    longitude: '',
    phoneNumber: '',
    notes: '',
  };

  let showQboSyncOption = false;
  if (managingCompanyInfo && managingCompanyInfo.isCustomerSyncEnabled) {
    showQboSyncOption = true;

    basicEmptyCustomer.qboCustomerId = null;
    if (addOrEditMode === 'add') {
      basicEmptyCustomer.qboCustomerId = 'NEW';
    }
  }

  const [savingOverlay, setSavingOverlay] = useState(false);
  const [errors, setErrors] = useState();
  const cleanNullToEmptyString = cleanThis => {
    const toReturn = {};
    Object.keys(cleanThis).forEach(key => {
      toReturn[key] = cleanThis[key] || '';
    });
    return toReturn;
  };

  const [formValues, setFormValues] = useState(
    initialValues ? cleanNullToEmptyString(initialValues) : basicEmptyCustomer
  );
  const [notes, setNotes] = useState(
    initialValues && initialValues.notes
      ? initialValues.notes
      : basicEmptyCustomer.notes
  );

  const areInputsValid = () => {
    const errorMessages = {};
    if (
      !formValues.firstName &&
      !formValues.lastName &&
      !formValues.companyName
    ) {
      const errorMessage = 'First, last, or company name must be filled in';
      errorMessages.firstName = errorMessage;
      errorMessages.lastName = errorMessage;
      errorMessages.companyName = errorMessage;
    }
    if (formValues.email && !isValidEmail(formValues.email)) {
      errorMessages.email = 'Please enter a valid email address';
    }

    _.forEach(FIELD_LENGTH_LIMITS, (limit, field) => {
      if (formValues[field] && formValues[field].length > limit) {
        errorMessages[field] = `${standardMaxMsg(limit)}`;
      }
    });

    if (Object.keys(errorMessages).length) {
      setErrors(errorMessages);
      return false;
    }
    setErrors(null);
    return true;
  };

  const handleSave = async () => {
    setSavingOverlay(true);

    const goodToGo = areInputsValid();
    if (!goodToGo) {
      // show modal/msg
      setSavingOverlay(false);
      return;
    }
    const cleanFormValue = { ...formValues, notes };
    Object.keys(cleanFormValue).forEach(key => {
      if (!cleanFormValue[key]) {
        cleanFormValue[key] = null;
      } else if (typeof cleanFormValue[key] === 'string') {
        cleanFormValue[key] = cleanFormValue[key].trim();
      }
    });
    let customerInfo;
    if (addOrEditMode === 'add') {
      const addCustomerResponse = await onAddCustomer(cleanFormValue);
      customerInfo = _.get(addCustomerResponse, 'data.addCustomer', null);
    } else {
      const updateCustomerResponse = await onUpdateCustomer(cleanFormValue);
      customerInfo = _.get(updateCustomerResponse, 'data.updateCustomer', null);
    }

    setSavingOverlay(false);
    handleClose(customerInfo);
  };

  const defaultNotes = notes || '';

  return (
    <>
      <Grid container spacing={3} item xs={12} sm={12}>
        <Grid item xs={6}>
          <TextField
            label="First Name"
            value={formValues.firstName}
            fullWidth
            error={errors && !!errors.firstName}
            helperText={errors && errors.firstName}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={event => {
              setFormValues({
                ...formValues,
                firstName: event.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Last Name"
            value={formValues.lastName}
            fullWidth
            error={errors && !!errors.lastName}
            helperText={errors && errors.lastName}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={event => {
              setFormValues({
                ...formValues,
                lastName: event.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Company Name"
            value={formValues.companyName}
            fullWidth
            error={errors && !!errors.companyName}
            helperText={errors && errors.companyName}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={event => {
              setFormValues({
                ...formValues,
                companyName: event.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Phone Number"
            value={formValues.phoneNumber}
            fullWidth
            error={errors && !!errors.phoneNumber}
            helperText={errors && errors.phoneNumber}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={event => {
              setFormValues({
                ...formValues,
                phoneNumber: event.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <LocationSearchInput
            passBack={(address, latlng) => {
              setFormValues({
                ...formValues,
                address,
                latitude: latlng.lat,
                longitude: latlng.lng,
              });
            }}
            defaultValue={formValues.address || ''}
            label="Address"
            avoidFormik
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Email Address"
            value={formValues.email}
            error={errors && !!errors.email}
            helperText={errors && errors.email}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={event => {
              setFormValues({
                ...formValues,
                email: event.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel shrink>Notes</InputLabel>
          <div
            style={{
              marginTop: 5,
              border: '1px solid #ccc',
            }}
          >
            <LevelPellEditor
              defaultContent={defaultNotes}
              onChange={htmlText => {
                setNotes(htmlText);
              }}
              containerClass="level-pell-content-addContentForm"
            />
          </div>
        </Grid>
        {showQboSyncOption && addOrEditMode === 'add' && (
          <Grid
            container
            item
            xs={6}
            alignItems="flex-start"
            direction="column"
          >
            <Grid
              item
              style={{
                width: '100%',
                borderBottom: '1px solid #ccc',
                paddingBottom: 8,
              }}
            >
              <InputLabel className={classes.subsectionTitle}>
                General Options
              </InputLabel>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
              style={{
                borderBottom: '1px solid #ccc',
              }}
            >
              <InputLabel className={classes.inlineLabel}>
                Sync to QuickBooks Online?
              </InputLabel>
              <MuiSwitch
                name="qboCustomerId"
                value={formValues.qboCustomerId}
                checked={formValues.qboCustomerId === 'NEW'}
                variant="inline"
                onChange={() => {
                  setFormValues({
                    ...formValues,
                    qboCustomerId:
                      formValues.qboCustomerId === 'NEW' ? null : 'NEW',
                  });
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        className={classes.actionsButtonsWrapper}
      >
        <Button
          onClick={handleClose}
          color="primary"
          autoFocus
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          autoFocus
        >
          Save
        </Button>
      </Grid>
      {savingOverlay && (
        <LoadingCover loader="linear">
          <Typography variant="h3" align="center">
            Saving...
          </Typography>
        </LoadingCover>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default compose(
  AddCustomerAction,
  UpdateCustomerAction
)(
  connect(mapStateToProps)(
    GoogleApiWrapper({
      apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    })(AddCustomerForm)
  )
);
