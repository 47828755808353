import React, { useCallback, useEffect, useState } from 'react';
import {
  useQuery,
  // useMutation
} from 'react-apollo-hooks';
import { compose, withApollo } from 'react-apollo';
import { connect } from 'react-redux';

// UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch as MuiSwitch,
  Tooltip,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  Block as BlockIcon,
  MoreHoriz as MoreHorizIcon,
  Visibility as VisibilityIcon,
  Save as SaveIcon,
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
  PictureAsPdf as PictureAsPdfIcon,
} from '@material-ui/icons';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { fromString } from 'html-to-text';

// utilities
import _, { uniqueId } from 'lodash';
import moment from 'moment';

import store from '../../../store';

// UI components
import ContentDetailsModal from '../../add-to-project/content-details-modal';
import AdminToolsIconButton from '../../../components/admin-tools-icon-button/admin-tools-icon-button';
import AdminToolsTitle from '../../../components/admin-tools-title/admin-tools-title';
import OkCancelDialog from '../../../components/OkCancelDialog/okCancelDialog';
import BulkAddLabelsDialog from '../bulk-add-labels-dialog';
import LoadingCover from '../../../components/LoadingCover/loadingCover';

// GrapQL
import ListContentByTypeQuery from '../../../graphql/queries/list-content-by-type';
import ListProjectsAndSubprojectsQuery from '../../../graphql/queries/list-projects-and-subprojects';
import GetCompanyCrewQuery from '../../../graphql/queries/get-company-crew';
import { DeleteContentAction } from '../../../graphql/graphql';
import { useChunkedQuery } from '../../../hooks';

// helpers
import {
  buildLabels,
  locationify,
  applyPreferences,
  capitalizeSentence,
} from '../../../helpers/index';

import {
  ADMIN_CONTENT_QUERIES,
  CONTENT_DEFINITION,
  CONTENT_DETAILS_MODAL_MODE,
  CONTENT_TYPE,
  TOP_PROJECT_ID,
} from '../../../config/appDefaults';

import themePalette from '../../../theme/palette';

// for ReactDataGrid
window.moment = moment;
const filterTypes = {
  ...ReactDataGrid.defaultProps.filterTypes,
};

const useStyles = makeStyles(theme => ({
  scrollableColumn: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'scroll',
    height: 'calc(100vh - 64px)',
  },
  editButton: {
    padding: theme.spacing(0.5),
    margin: 0,
    minWidth: 0,
    color: '#aaa',
    '&:hover': {
      background: 'transparent',
      color: '#333',
    },
  },
  headerWrapper: {
    padding: theme.spacing(3),
  },
  actionButtonsContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    minWidth: 460,
    '& button': {
      marginLeft: theme.spacing(2),
    },
    '& div': {
      marginLeft: theme.spacing(2),
    },
  },
  headerIcon: {
    color: '#999',
    fontSize: 30,
    position: 'absolute',
    top: 5,
  },
  statModalLabel: {
    textTransform: 'uppercase',
  },
  statText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    paddingTop: 5,
    paddingBottom: 5,
  },
  statSectionWrapper: {
    paddingTop: 25,
  },
  helperText: {
    color: theme.palette.brandColorPrimary,
    alignItems: 'center',
  },
  statSectionHeader: {
    color: theme.palette.brandColorPrimary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    paddingBottom: 5,
  },
}));

const checkInOutFormat = 'MMM D, YYYY @ h:mma';

const ManageDocs = ({
  onDeleteContent,
  managingCompanyInfo,
  contentLabelSuggestions,
  columnSettings,
}) => {
  const classes = useStyles();
  const [includeArchive, setIncludeArchive] = useState(false);
  const [gridRef, setGridRef] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [columnOrder, setColumnOrder] = useState(null);
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    title: '',
  });

  // #region Query Hooks
  // Get active projects list
  const listProjectsAndSubprojectsAccessor = 'listProjectsAndSubprojects';
  const activeProjectsAndSubprojectsQuery = useChunkedQuery(
    ListProjectsAndSubprojectsQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        companyId: managingCompanyInfo.managingCompanyId,
      },
      skip: !managingCompanyInfo.managingCompanyId,
      accessor: listProjectsAndSubprojectsAccessor,
    }
  );

  // Get active projects and subproject query items
  const { items: activeProjectsAndSubprojects = null } = _.get(
    activeProjectsAndSubprojectsQuery.data,
    listProjectsAndSubprojectsAccessor,
    {}
  );

  // Get archived projects list if required
  const archivedProjectsAndSubprojectsQuery = useChunkedQuery(
    ListProjectsAndSubprojectsQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        companyId: managingCompanyInfo.managingCompanyId,
        archived: true,
      },
      skip: !managingCompanyInfo.managingCompanyId || !includeArchive,
      accessor: listProjectsAndSubprojectsAccessor,
    }
  );

  // Get archived projects and subproject query items
  const { items: archivedProjectsAndSubprojects = null } = _.get(
    archivedProjectsAndSubprojectsQuery.data,
    listProjectsAndSubprojectsAccessor,
    {}
  );

  // Get active docs
  const activeDocsQuery = useQuery(ListContentByTypeQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      companyId: managingCompanyInfo.managingCompanyId,
      contentTypes: [CONTENT_TYPE.PDF],
    },
    skip: !managingCompanyInfo.managingCompanyId,
  });
  const activeDocs = _.get(
    activeDocsQuery,
    'data.listContentByType.items',
    null
  );

  // Get archived docs if required
  const archivedDocsQuery = useQuery(ListContentByTypeQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      companyId: managingCompanyInfo.managingCompanyId,
      contentTypes: [CONTENT_TYPE.PDF],
      archived: true,
    },
    skip: !managingCompanyInfo.managingCompanyId || !includeArchive,
  });
  const archivedDocs = _.get(
    archivedDocsQuery,
    'data.listContentByType.items',
    null
  );

  // Get company crew
  const getUsersQuery = useQuery(GetCompanyCrewQuery, {
    skip: !managingCompanyInfo.managingCompanyId,
    variables: {
      companyId: managingCompanyInfo.managingCompanyId,
    },
    fetchPolicy: 'cache-and-network',
  });
  const companyCrew = _.get(getUsersQuery, 'data.getCompanyCrew.items', null);
  // #endregion

  const isDataLoading =
    getUsersQuery.loading ||
    activeDocsQuery.loading ||
    archivedDocsQuery.loading ||
    activeProjectsAndSubprojectsQuery.loading ||
    archivedProjectsAndSubprojectsQuery.loading;

  const buildColumnObj = options => {
    if (!options.name) return null;
    const basicColumn = {
      ...options,
      header:
        options.header !== undefined
          ? options.header
          : capitalizeSentence(options.name),
    };
    return basicColumn;
  };

  const [currentlyBeingViewed, setCurrentlyBeingViewed] = useState(null);
  const [showContentDetailsModal, setShowContentDetailsModal] = useState({
    open: false,
    existingInfo: null,
  });
  const [showBulkAddLabelsDialog, setShowBulkAddLabelsDialog] = useState({
    open: false,
    selectedContentMap: {},
  });
  const gridStyle = { height: '100%', minHeight: '100%' };
  const [dataSource, setDataSource] = useState(null);
  const [columns, setColumns] = useState(null);
  const [tableReady, setTableReady] = useState(false);
  const [tableKey, setTableKey] = useState(uniqueId());
  const resetTableKey = () => setTableKey(uniqueId());

  const basicSortInfo = {
    name: 'date',
    dir: -1,
    type: 'date',
  };
  const [defaultSortInfo, setDefaultSortInfo] = useState(basicSortInfo);

  const [selected, setSelected] = useState({});
  const [downloadError, setDownloadError] = useState(false);

  const onSelectionChange = useCallback(({ selected: isSelected }) => {
    setSelected(isSelected);
  }, []);

  const handleIncludeArchiveChange = event => {
    setIncludeArchive(event.target.checked);
  };

  const viewOrEditThisContent = (itemInfo, index, mode = 'view') => {
    if (!itemInfo) {
      setShowContentDetailsModal({ open: false });
      return;
    }
    const contentToView = _.find(
      [...(activeDocs || []), ...(archivedDocs || [])],
      {
        contentId: itemInfo.contentId,
      }
    );

    const parentProjectDetails = _.find(
      [
        ...(activeProjectsAndSubprojects || []),
        ...(archivedProjectsAndSubprojects || []),
      ],
      {
        contentId: itemInfo.projectId,
      }
    );

    const itemIndex = !_.isNil(index)
      ? index
      : _.findIndex(gridRef.current.data, {
          contentId: itemInfo.contentId,
        });

    if (contentToView && parentProjectDetails) {
      // use addContentForm modal here instead
      setCurrentlyBeingViewed({ item: contentToView, index: itemIndex });
      setShowContentDetailsModal({
        open: true,
        mode:
          mode === 'edit'
            ? CONTENT_DETAILS_MODAL_MODE.EDIT
            : CONTENT_DETAILS_MODAL_MODE.ADMIN_DOC,
        parentInfo: parentProjectDetails,
        existingInfo: contentToView,
        itemInfo,
      });
    } else {
      // throw up a modal to let them know something went wrong
      // console.log('something went wrong getting the content');
    }
  };

  useEffect(() => {
    const labels = buildLabels([
      ...(activeDocs || []),
      ...(archivedDocs || []),
    ]);

    const allContentLabels = _.uniq([...labels, ...contentLabelSuggestions]);
    store.dispatch({
      type: 'UPDATE_CONTENT_LABEL_SUGGESTIONS',
      payload: { contentLabelSuggestions: allContentLabels },
    });

    // update the content in the modal if there are changes
    if (currentlyBeingViewed) {
      const updateFinancial = _.find(gridRef.current.dataSource, {
        contentId: currentlyBeingViewed.item.contentId,
      });
      if (updateFinancial) {
        viewOrEditThisContent(updateFinancial);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDocs, archivedDocs]);

  // const [updateContent] = useMutation(UpdateContentMutation);

  const getNextItem = nextOrPrevious => {
    if (!currentlyBeingViewed) {
      return;
    }

    // get the index of the currentlyBeingEdited item
    const currentData = gridRef.current.data; // with filters applied

    let indexOfCurrentlyBeingViewed = _.findIndex(currentData, {
      contentId: currentlyBeingViewed.item.contentId,
    });

    if (indexOfCurrentlyBeingViewed < 0) {
      // currentItem has been deleted
      indexOfCurrentlyBeingViewed = currentlyBeingViewed.index - 1;
    }

    // then using the index, get the next or previous item and pass it into the modal
    const indexOfRequested =
      nextOrPrevious === 'next'
        ? indexOfCurrentlyBeingViewed + 1
        : indexOfCurrentlyBeingViewed - 1;

    let nextItemIndex;
    // need to take into account if the user has change the sorting/filtering
    if (nextOrPrevious === 'next' && indexOfRequested >= currentData.length) {
      // if next and index is more than the length of currentData, go to start of currentData?
      nextItemIndex = 0;
    } else if (nextOrPrevious === 'previous' && indexOfRequested < 0) {
      // if previous and index is less than 0, go to end of currentData?
      nextItemIndex = currentData.length - 1;
    } else {
      nextItemIndex = indexOfRequested;
    }
    // figure out the next item from the current data
    const nextItem = currentData[nextItemIndex];

    viewOrEditThisContent(nextItem, nextItemIndex);
  };

  const deleteThisContent = async (data, goToNextItem) => {
    setDialogInfo({
      title: 'Just making sure...',
      message: `Are you sure you want to delete this ${_.toLower(
        CONTENT_DEFINITION[data.type].name
      )}? This cannot be undone.`,
      open: true,
      onClose: () => setDialogInfo({ ...dialogInfo, open: false }),
      hideCancel: false,
      onConfirm: async () => {
        await onDeleteContent(data.contentId, data.projectId, {
          fromWhichAdminTool: ADMIN_CONTENT_QUERIES.DOCS,
          includeArchive,
        });
        if (goToNextItem) {
          getNextItem('next');
        }
      },
    });
  };

  const buildDataGrid = ({ withoutPreferences = false } = {}) => {
    if (!activeDocs || !activeProjectsAndSubprojects || !companyCrew) {
      return;
    }
    if (includeArchive && (!archivedDocs || !archivedProjectsAndSubprojects)) {
      return;
    }

    const docs = [...activeDocs];
    const projects = [...activeProjectsAndSubprojects];
    if (includeArchive) {
      docs.push(...archivedDocs);
      projects.push(...archivedProjectsAndSubprojects);
    }

    // seperate the content by project
    const splitContentOnAttribute = (content, attribute) => {
      const contentByAttribute = new Map();
      content.forEach(item => {
        if (!contentByAttribute.get(item[attribute])) {
          contentByAttribute.set(item[attribute], []);
        }
        contentByAttribute.get(item[attribute]).push(item);
      });
      return contentByAttribute;
    };

    const adminContentByProject = splitContentOnAttribute(docs, 'jrnId');

    const uniqueProjectNames = [];
    const uniqueTypes = [];
    const allItems = [];
    adminContentByProject.forEach((arrayOfContent, projectId) => {
      if (!arrayOfContent || !arrayOfContent.length) {
        return;
      }
      const projectInfo = _.find(projects, { contentId: projectId });

      if (!projectInfo) return;

      if (projectInfo && arrayOfContent && companyCrew) {
        _.forEach(arrayOfContent, item => {
          const user = _.find(
            companyCrew,
            { userId: item.creatorId },
            'not found'
          );
          const username = user ? user.username : 'n/a';

          const projectDescription = projectInfo.description
            ? fromString(projectInfo.description)
            : null;
          const description = item.description
            ? fromString(item.description)
            : null;
          const labels = item.labels ? item.labels.join(', ') : null;

          let projectLatLon;
          if (
            projectInfo.address &&
            projectInfo.latitude &&
            projectInfo.longitude
          ) {
            projectLatLon = {
              lat: projectInfo.latitude,
              lon: projectInfo.longitude,
            };
          }

          let projectName = projectInfo.title;
          if (projectInfo.jrnId && projectInfo.jrnId !== TOP_PROJECT_ID) {
            const parentProjectInfo = _.find(projects, {
              contentId: projectInfo.jrnId,
            });
            if (parentProjectInfo) {
              projectName = `${parentProjectInfo.title} > ${projectInfo.title}`;
            }
          }

          if (!uniqueProjectNames.includes(projectName)) {
            uniqueProjectNames.push(projectName);
          }

          if (!uniqueTypes.includes(item.type)) {
            uniqueTypes.push(item.type);
          }

          const whatToAdd = {
            projectId: projectInfo.contentId,
            projectName,
            projectAddress: projectInfo.address || null,
            projectLatLon,
            projectDescription,
            userId: item.creatorId,
            username,
            parentId: item.jrnId,
            contentId: item.contentId,
            date: moment(item.date).format('YYYY-MM-DDTHH:mm:ssZ'),
            description,
            type: item.type,
            labels,
            itemImage: item.contentUrl,
            dateCreated: moment(item.dateCreated).format(
              'YYYY-MM-DDTHH:mm:ssZ'
            ),
          };
          allItems.push(whatToAdd);
        });
      }
    });

    // set the datasource
    setDataSource(allItems);

    uniqueProjectNames.sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });

    // prep data by allowlisting wanted attributes
    const uniqueUsernames = companyCrew.map(user => user.username);
    if (uniqueUsernames) {
      uniqueUsernames.sort((a, b) => {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
    }
    const editColumnWidth = 115;
    const columnMinWidthSmall = 100;
    const columnMinWidthMedium = 180;
    const showInTable = [
      {
        name: 'edit',
        header: null,
        minWidth: editColumnWidth,
        maxWidth: editColumnWidth,
      },
      { name: 'userId', header: 'User Id', defaultVisible: false },
      {
        name: 'username',
        header: 'Username',
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: 'All',
          multiple: true,
          wrapMultiple: true,
          dataSource: uniqueUsernames.map(value => ({
            id: value,
            label: value,
          })),
        },
        defaultFlex: 50,
        minWidth: columnMinWidthMedium,
      },
      { name: 'projectId', header: 'Project Id', defaultVisible: false },
      {
        name: 'projectName',
        header: 'Project Name',
        defaultFlex: 50,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'projectAddress',
        header: 'Project Address',
        defaultFlex: 30,
        defaultVisible: false,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'projectDescription',
        header: 'Project Description',
        defaultVisible: false,
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'description',
        header: 'Notes',
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'labels',
        header: 'Labels',
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'date',
        header: 'Date',
        dateFormat: 'YYYY-MM-DD',
        filterEditor: DateFilter,
        filterEditorProps: () => {
          // for range and notinrange operators, the index is 1 for the after field
          return {
            dateFormat: checkInOutFormat, // causing rtl error in console
            cancelButton: false,
            highlightWeekends: false,
          };
        },
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'type',
        header: 'Type',
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: 'All',
          multiple: true,
          wrapMultiple: true,
          dataSource: uniqueTypes.map(value => ({
            id: value,
            label: value,
          })),
        },
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
      },
      {
        name: 'itemImage',
        header: <PictureAsPdfIcon className={classes.headerIcon} />,
        headerAlign: 'center',
        headerVerticalAlign: 'bottom',
        textAlign: 'center',
        defaultFlex: 10,
        minWidth: columnMinWidthSmall,
      },
      {
        name: 'dateCreated',
        header: 'Date Added',
        dateFormat: 'YYYY-MM-DD',
        filterEditor: DateFilter,
        filterEditorProps: () => {
          // for range and notinrange operators, the index is 1 for the after field
          return {
            dateFormat: checkInOutFormat, // causing rtl error in console
            cancelButton: false,
            highlightWeekends: false,
          };
        },
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
      },
    ];

    // build the table columns off the datasource
    const columnsInfo = showInTable.map(attribute => {
      const attributePlus = { ...attribute };
      if (attribute.name === 'edit') {
        attributePlus.render = ({ data, rowIndex }) => {
          return (
            <>
              <Tooltip title="Delete Entry">
                <Button
                  onClick={() => {
                    deleteThisContent(data);
                  }}
                  className={classes.editButton}
                >
                  <DeleteOutlineIcon />
                </Button>
              </Tooltip>
              <Tooltip title="View Entry">
                <Button
                  onClick={() => {
                    viewOrEditThisContent(data, rowIndex, 'view');
                  }}
                  className={classes.editButton}
                >
                  <VisibilityIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Edit Entry">
                <Button
                  onClick={() => {
                    viewOrEditThisContent(data, rowIndex, 'edit');
                  }}
                  className={classes.editButton}
                >
                  <EditIcon />
                </Button>
              </Tooltip>
            </>
          );
        };
      } else if (attribute.name === 'projectName') {
        attributePlus.render = ({ value, data }) => {
          return (
            <a
              href={`/projects/${data.projectId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="basicStyledLink"
            >
              {value}
            </a>
          );
        };
      } else if (attribute.name === 'projectAddress') {
        attributePlus.render = ({ value, data }) => {
          if (!value) return null;
          const locationText = value;
          let latLonAsLink;
          if (data.projectLatLon) {
            latLonAsLink = locationify(
              data.projectLatLon.lat,
              data.projectLatLon.lon
            );
          }
          return (
            <a
              href={latLonAsLink}
              target="_blank"
              rel="noopener noreferrer"
              className="basicStyledLink"
            >
              {locationText}
            </a>
          );
        };
      } else if (attribute.name === 'description') {
        attributePlus.render = ({ value }) => {
          if (!value) return null;
          return value;
        };
      } else if (
        attribute.name === 'date' ||
        attribute.name === 'dateCreated'
      ) {
        attributePlus.render = ({ value }) => {
          if (!value) return 'n/a';
          return moment(value).format('MMM D, YYYY @ h:mma');
        };
      } else if (attribute.name === 'itemImage') {
        attributePlus.render = ({ value, data }) => {
          if (!value) return 'n/a';
          return (
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              className="basicStyledLink"
              style={{ textTransform: 'uppercase' }}
            >
              {data.type}
            </a>
          );
        };
      }

      return buildColumnObj(attributePlus);
    });

    setColumns(columnsInfo);
    const levelFilterValues = [
      {
        name: 'projectName',
        operator: 'contains',
        type: 'string',
        value: '',
      },
      {
        name: 'username',
        operator: 'inlist',
        type: 'select',
        value: null,
      },
      {
        name: 'date',
        operator: 'afterOrOn',
        type: 'date',
        value: '',
      },
      {
        name: 'description',
        operator: 'contains',
        type: 'string',
        value: '',
      },
      {
        name: 'labels',
        operator: 'contains',
        type: 'string',
        value: '',
      },
      {
        name: 'type',
        operator: 'inlist',
        type: 'select',
        value: null,
      },
      {
        name: 'dateCreated',
        operator: 'afterOrOn',
        type: 'date',
        value: '',
      },
    ];

    const {
      newColumnsInfo: newColumns,
      newSortInfo,
      newFilterInfo,
      newColumnOrder,
    } = applyPreferences({
      preferences: withoutPreferences ? null : columnSettings,
      defaultColumnsInfo: columnsInfo,
      defaultFilterInfo: levelFilterValues,
      defaultSortInfo: basicSortInfo,
      defaultColumnOrder: null,
    });

    if (!tableReady || withoutPreferences) {
      setColumns(newColumns);
      setDefaultSortInfo(newSortInfo);
      setFilterValue(newFilterInfo);
      setColumnOrder(newColumnOrder);

      resetTableKey(); // this is to force the table to re-render
    }

    if (!tableReady) {
      setTableReady(true);
    }
  };

  useEffect(() => {
    buildDataGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeDocs,
    archivedDocs,
    activeProjectsAndSubprojects,
    archivedProjectsAndSubprojects,
    companyCrew,
  ]);

  const scrollProps = {
    ...ReactDataGrid.defaultProps.scrollProps,
    autoHide: false,
    scrollThumbWidth: 12,
    scrollThumbStyle: {
      background: themePalette.brandColorPrimary,
    },
  };

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const showPreferencesMenu = event => {
    setMenuAnchorEl(event.currentTarget);
  };
  const closePreferencesMenu = () => {
    setMenuAnchorEl(null);
  };

  const saveColumnPrefs = () => {
    const allColumnInfo = gridRef.current.columnsMap;
    store.dispatch({
      type: 'SET_ADMIN_TOOLS_SETTINGS',
      payload: { docs: allColumnInfo },
    });
    closePreferencesMenu();
  };

  const clearColumnPrefs = () => {
    store.dispatch({
      type: 'SET_ADMIN_TOOLS_SETTINGS',
      payload: { docs: null },
    });

    // rebuild the table now
    buildDataGrid({ withoutPreferences: true });

    closePreferencesMenu();
  };

  return (
    <div className={classes.scrollableColumn}>
      {tableReady && !isDataLoading ? (
        <>
          <div style={{ flex: 0, background: '#eee' }}>
            <Grid
              container
              justifyContent="space-between"
              className={classes.headerWrapper}
            >
              <Grid container item xs={12} justifyContent="space-between">
                <Grid item>
                  <AdminToolsTitle
                    Icon={CONTENT_DEFINITION[CONTENT_TYPE.PDF].Icon}
                    titleText="Manage Docs"
                  />
                </Grid>
                <Grid item className={classes.actionButtonsContainer}>
                  <AdminToolsIconButton
                    tooltipText="View other options"
                    onClick={showPreferencesMenu}
                  >
                    <MoreHorizIcon />
                  </AdminToolsIconButton>
                  <Menu
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={Boolean(menuAnchorEl)}
                    onClose={closePreferencesMenu}
                  >
                    <MenuItem onClick={saveColumnPrefs}>
                      <SaveIcon />
                      &nbsp;&nbsp;Save Column Preferences
                    </MenuItem>
                    <MenuItem onClick={clearColumnPrefs}>
                      <BlockIcon />
                      &nbsp;&nbsp;Clear Column Preferences
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
              <Grid item xs={6} />
              <Grid
                item
                xs={6}
                style={{ justifyContent: 'flex-end', display: 'flex' }}
              >
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={includeArchive}
                      onChange={handleIncludeArchiveChange}
                    />
                  }
                  label="Include archived projects"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
          </div>
          <div style={{ flex: 1, height: 200 }}>
            <ReactDataGrid
              key={tableKey}
              // grab the ref
              onReady={setGridRef}
              // set which property is used as the ID
              idProperty="contentId"
              // set which columns show
              columns={columns}
              // column order
              defaultColumnOrder={columnOrder}
              // set the data to build from
              dataSource={dataSource}
              // set basic styling for the overall table
              style={gridStyle}
              className="reactDataGridFixLastItemOverlap"
              // filtering
              enableFiltering
              defaultFilterValue={filterValue}
              filterTypes={filterTypes}
              // sorting
              defaultSortInfo={defaultSortInfo}
              allowUnsort={false}
              // scrollbar
              scrollProps={scrollProps}
              // checkbox column
              // checkboxColumn
              selected={selected}
              onSelectionChange={onSelectionChange}
              headerHeight={0}
            />
          </div>
        </>
      ) : (
        <LoadingCover loader="linear">
          <Typography variant="h3" align="center">
            Loading data from across your projects...
          </Typography>
        </LoadingCover>
      )}

      {dialogInfo.open && (
        <OkCancelDialog
          title={dialogInfo.title}
          open={dialogInfo.open}
          onClose={dialogInfo.onClose}
          hideCancel={dialogInfo.hideCancel}
          onConfirm={dialogInfo.onConfirm}
        >
          <Typography>{dialogInfo.message}</Typography>
        </OkCancelDialog>
      )}
      {downloadError && (
        <Dialog open onClose={() => setDownloadError(false)} maxWidth="md">
          <DialogTitle>
            <Typography>Nothing to Download</Typography>
          </DialogTitle>
          <DialogContent dividers style={{ paddingTop: 20, paddingBottom: 20 }}>
            <Typography>
              None of the items you selected have an image.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDownloadError(false)}
              color="primary"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {showContentDetailsModal.open && (
        <ContentDetailsModal
          mode={showContentDetailsModal.mode}
          canEdit={showContentDetailsModal.canEdit}
          parentInfo={showContentDetailsModal.parentInfo}
          existingInfo={showContentDetailsModal.existingInfo}
          includeArchive={includeArchive}
          onClose={() => {
            setShowContentDetailsModal({
              open: false,
            });
            setCurrentlyBeingViewed(null);
          }}
          getNextItem={getNextItem}
          onDeleteButtonClick={() => {
            deleteThisContent(showContentDetailsModal.itemInfo, true);
          }}
        />
      )}

      {showBulkAddLabelsDialog.open && (
        <BulkAddLabelsDialog
          open={showBulkAddLabelsDialog.open}
          contentMap={showBulkAddLabelsDialog.selectedContentMap}
          handleClose={() => {
            setShowBulkAddLabelsDialog({ open: false, selectedContentMap: {} });
          }}
          handleDone={({ shouldRefetch }) => {
            setShowBulkAddLabelsDialog({ open: false, selectedContentMap: {} });

            if (shouldRefetch) {
              if (includeArchive) {
                archivedDocsQuery.refetch();
              }
              activeDocsQuery.refetch();
            }

            setSelected({});
          }}
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const columnSettings = _.get(state, 'appState.adminToolsSettings.docs', null);

  return {
    userInfo: state.userInfo,
    managingCompanyInfo: state.appState.managingCompanyInfo || {},
    contentLabelSuggestions: state.appState.contentLabelSuggestions || [],
    columnSettings,
  };
}

export default connect(mapStateToProps)(
  compose(DeleteContentAction, withApollo)(ManageDocs)
);
