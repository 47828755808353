import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from '@material-ui/core';
import AddCustomerForm from './add-customer-form';
import AddCustomerChooseMode from './add-customer-choose-mode';
import { WHAT_TO_SHOW } from './add-customer-enums';
import AddCustomerFromQbo from './add-customer-from-qbo';

const AddCustomerDialog = ({
  managingCompanyInfo,
  toClose,
  initialValues,
  creationType = null,
  optimisticSync,
}) => {
  const [whatToShow, setWhatToShow] = useState(null);

  useEffect(() => {
    if (!whatToShow) {
      if (initialValues) {
        setWhatToShow(WHAT_TO_SHOW.NEW_CUSTOMER_FORM);
      } else if (
        managingCompanyInfo.isCustomerSyncEnabled &&
        creationType === WHAT_TO_SHOW.IMPORT_FROM_QBO
      ) {
        // Per Leeroy's instructions, we don't want to offer a choose mode at this time
        setWhatToShow(WHAT_TO_SHOW.IMPORT_FROM_QBO);
      } else {
        setWhatToShow(WHAT_TO_SHOW.NEW_CUSTOMER_FORM);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managingCompanyInfo]);

  const handleClose = passThisBack => {
    toClose(passThisBack);
  };

  if (!whatToShow) {
    return null;
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="simple-dialog-title">
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5">
            {initialValues ? 'Edit' : 'Add'} Customer
          </Typography>

          {managingCompanyInfo &&
            managingCompanyInfo.isCustomerSyncEnabled &&
            whatToShow !== WHAT_TO_SHOW.IMPORT_FROM_QBO && (
              <Button
                color="primary"
                variant="text"
                onClick={() => setWhatToShow(WHAT_TO_SHOW.IMPORT_FROM_QBO)}
              >
                Import from QuickBooks Online
              </Button>
            )}
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {whatToShow === WHAT_TO_SHOW.CHOOSE_MODE && (
          <AddCustomerChooseMode setWhatToShow={setWhatToShow} />
        )}
        {whatToShow === WHAT_TO_SHOW.NEW_CUSTOMER_FORM && (
          <AddCustomerForm
            handleClose={handleClose}
            initialValues={initialValues}
            optimisticSync={optimisticSync}
          />
        )}
        {whatToShow === WHAT_TO_SHOW.IMPORT_FROM_QBO && (
          <AddCustomerFromQbo handleClose={handleClose} />
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(AddCustomerDialog);
