import gql from 'graphql-tag';

export default gql`
  query GetCompanyAccounts($companyId: String!, $accountTypes: [String]) {
    getCompanyAccounts(
      input: { companyId: $companyId, accountTypes: $accountTypes }
    ) {
      items {
        accountId
        sourceId
        sourceParentId
        source
        displayName
        accountName
        accountType
        projectCostType
        productionCostType
        laborBurden
        isApproved
      }
    }
  }
`;
