import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo-hooks';
import numeral from 'numeral';
import _ from 'lodash';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon, Done as DoneIcon } from '@material-ui/icons';
import { withSnackbar } from 'notistack';

import LoadingCover from '../../../components/LoadingCover/loadingCover';
import { RFI_STATUSES } from '../../../config/appDefaults';
import TriggerRfiClientEmail from '../../../graphql/queries/trigger-rfi-client-email';
import palette from '../../../theme/palette';

const SendApprovedRfis = ({
  rfisToSend,
  rfiRecipients,
  handleDialogClose,
  handleSaveRfis,
  managingCompanyInfo,
  enqueueSnackbar,
}) => {
  const [emailSent, setEmailSent] = useState(false);
  const [savingOrSending, setSavingOrSending] = useState(false);
  const [emailInfo] = useState(() => {
    let cashOutFlow = 0;
    let cashInFlow = 0;
    const numOfRfis = rfisToSend.length;
    rfisToSend.forEach(rfi => {
      if (rfi.txnReceived) {
        cashInFlow += rfi.txnReceived.value || 0;
      } else if (rfi.txnSpent) {
        cashOutFlow += rfi.txnSpent.value || 0;
      }
    });
    return {
      cashOutFlow,
      cashInFlow,
      numOfRfis,
    };
  });

  const managingCompanyId = managingCompanyInfo?.managingCompanyId;

  const requestIds = rfisToSend.map(rfi => rfi.requestId);
  const { refetch: triggerRfiClientEmail } = useQuery(TriggerRfiClientEmail, {
    fetchPolicy: 'no-cache',
    variables: {
      companyId: managingCompanyId,
      requestIds,
    },
    skip: true, // Wait until specifically invoked
  });

  const handleSave = async () => {
    // need to change the status of the rfis to 'EXT_A_ACTIVE'
    const status = RFI_STATUSES.EXT_A_ACTIVE.value;

    const rfisToSave = rfisToSend.map(rfi => ({
      ...rfi,
      requestStatus: status,
      companyId: managingCompanyId,
    }));

    try {
      await handleSaveRfis({
        listOfRfis: rfisToSave,
        addOrUpdate: 'update',
      });
    } catch (err) {
      // Show error notification to user
      enqueueSnackbar(
        'Sorry! We were unable to save your changes. Please try again later.',
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );

      // eslint-disable-next-line no-console
      console.error('handleSaveRfis err: ', err);
      return false;
    }
    return true;
  };

  const handleSendEmail = async () => {
    let sendQueryStatus = null;
    try {
      const triggerRfiClientEmailResponse = await triggerRfiClientEmail();

      sendQueryStatus = _.get(
        triggerRfiClientEmailResponse,
        'data.triggerRfiClientEmail.status',
        ''
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('TriggerRfiClientEmail err: ', err);
    }

    let result = false;
    if (sendQueryStatus === 'success' || sendQueryStatus === 'NO_RECIPIENTS') {
      result = true;
    } else {
      // Show error notification to user
      enqueueSnackbar(
        'Sorry! We were unable to send the email. Please try again later.',
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
    }

    return result;
  };

  const onSave = async () => {
    setSavingOrSending(true);
    const saveStatus = await handleSave(rfisToSend);

    let emailSentStatus = false;
    if (saveStatus) {
      emailSentStatus = await handleSendEmail();
    }

    setSavingOrSending(false);
    if (emailSentStatus) {
      setEmailSent(true);
    }
  };

  const hasRfiRecipientsSpecified = !!(rfiRecipients?.length > 0);

  return (
    <Dialog
      open
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      {emailSent ? (
        <>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{
              paddingTop: 8,
              paddingRight: 8,
              paddingBottom: 50,
              paddingLeft: 16,
            }}
          >
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-end"
              style={{ marginBottom: 8 }}
            >
              <IconButton
                onClick={handleDialogClose}
                style={{ background: '#eee' }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <DoneIcon
              style={{ fontSize: 80, color: 'green', marginBottom: 24 }}
            />
            <Typography variant="h3" gutterBottom>
              {hasRfiRecipientsSpecified &&
                'The RFI email has been sent to the client!'}
              {!hasRfiRecipientsSpecified &&
                'The RFIs have been released to the client!'}
            </Typography>
          </Grid>
        </>
      ) : (
        <>
          {savingOrSending && <LoadingCover />}
          <DialogTitle disableTypography style={{ padding: 8 }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography
                  variant="h4"
                  style={{ paddingLeft: 8, paddingTop: 12 }}
                >
                  {hasRfiRecipientsSpecified &&
                    'Send Notification Email to Company Admins'}
                  {!hasRfiRecipientsSpecified &&
                    'Release RFIs to Company Admins'}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleDialogClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ marginBottom: 24 }}
                  component="div"
                >
                  {hasRfiRecipientsSpecified && (
                    <p>
                      An email will be sent to the recipients specified on the
                      RFI Dashboard letting them know the following:
                    </p>
                  )}
                  {!hasRfiRecipientsSpecified && (
                    <>
                      <p style={{ color: palette.brandColorOrange }}>
                        No RFI recipients have been specified.
                      </p>
                      <br />
                      <p>
                        If you proceed, the RFIs will be released to the company
                        admins, but no one will be notified. To specify
                        recipients, go to the <b>RFI Dashboard</b> and click on
                        the <b>Manage Recipients</b> button.
                      </p>
                    </>
                  )}
                  <ul>
                    <li>
                      Number of RFIs:{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {emailInfo.numOfRfis}
                      </span>
                    </li>
                    <li>
                      Cash Outflow Value of RFIs:{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {numeral(emailInfo.cashOutFlow).format('$0,0.00')}
                      </span>
                    </li>
                    <li>
                      Cash Inflow Value of RFIs:{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {numeral(emailInfo.cashInFlow).format('$0,0.00')}
                      </span>
                    </li>
                  </ul>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  This will also move the RFIs out of creation mode and into the
                  list for the company to action.
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            {hasRfiRecipientsSpecified && (
              <Button onClick={onSave} color="primary" variant="contained">
                Send Email
              </Button>
            )}
            {!hasRfiRecipientsSpecified && (
              <Button
                onClick={onSave}
                variant="contained"
                style={{
                  background: palette.brandColorOrange,
                  color: '#ffffff',
                }}
              >
                Release Anyway
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    managingCompanyInfo: state.appState?.managingCompanyInfo || null,
  };
};

export default connect(mapStateToProps)(withSnackbar(SendApprovedRfis));
