import React, { useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import _ from 'lodash';

import { DashboardReportTableRow } from '../../dashboard';
import { hoursGraphs, HOURS_GRAPH } from './billable-hours-graphs';
import palette from '../../../theme/palette';
import { asHours } from '../../dashboard/dashboard.utils';
import { getFullUserString, getUserFullName } from '../../../helpers';
import { CREW_DEPARTMENT_OPTIONS } from '../../../config/appDefaults';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.brandColorLightGrey,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles(() => ({
  root: {
    fontSize: 12,
  },
}))(TableCell);

const GROUPED_BY = {
  PROJECT: 'project',
  USER: 'user',
};

const BillableHoursBreakdownDialog = ({
  title,
  open,
  handleClose,
  companyCustomers,
  companyCrew,
  projectIdToCustomerIdMap,
  projectIdToPathNameMap,
  billableHoursGroupedByProjectIdMap,
  nonBillableHoursGroupedByProjectIdMap,
}) => {
  const [groupedBy, setGroupedBy] = React.useState(GROUPED_BY.PROJECT);

  const billableHoursGraph = _.find(
    hoursGraphs,
    ({ name }) => name === HOURS_GRAPH.BILLABLE_HOURS
  );

  const nonBillableHoursGraph = _.find(
    hoursGraphs,
    ({ name }) => name === HOURS_GRAPH.NON_BILLABLE_HOURS
  );

  const companyCrewMap = useMemo(() => {
    const crewMap = {};
    _.forEach(companyCrew, crewMember => {
      const departmentInfo = _.find(CREW_DEPARTMENT_OPTIONS, {
        value: crewMember.department,
      });

      crewMap[crewMember.userId] = {
        ...crewMember,
        departmentName: departmentInfo
          ? departmentInfo.label
          : 'Unassigned Department',
      };
    });
    return crewMap;
  }, [companyCrew]);

  const customerIdToCustomerNameMap = useMemo(() => {
    const map = {};
    _.forEach(companyCustomers, customer => {
      map[customer.customerId] =
        getUserFullName(customer) || customer.companyName;
    });
    return map;
  }, [companyCustomers]);

  const generateHoursGroupedByUserIdMap = hoursGroupedByProjectIdMap => {
    const hoursByUserIdMap = {};
    let totalHours;
    _.forEach(hoursGroupedByProjectIdMap, (userIdToHoursMap, projectId) => {
      if (projectId === 'total') {
        // projectId is 'total', then the userIdToHoursMap is the total hours
        totalHours = userIdToHoursMap;
      } else {
        _.forEach(userIdToHoursMap, (hours, userId) => {
          if (userId !== 'total') {
            if (_.isNil(hoursByUserIdMap[userId])) {
              hoursByUserIdMap[userId] = { total: 0 };
            }
            if (_.isNil(hoursByUserIdMap[userId][projectId])) {
              hoursByUserIdMap[userId][projectId] = 0;
            }
            const hoursN = Number(hours);
            hoursByUserIdMap[userId].total += hoursN;
            hoursByUserIdMap[userId][projectId] += hoursN;
          }
        });
      }
    });
    hoursByUserIdMap.total = totalHours;
    return hoursByUserIdMap;
  };

  const billableHoursGroupedByUserIdMap = useMemo(() => {
    return generateHoursGroupedByUserIdMap(billableHoursGroupedByProjectIdMap);
  }, [billableHoursGroupedByProjectIdMap]);

  const nonBillableHoursGroupedByUserIdMap = useMemo(() => {
    return generateHoursGroupedByUserIdMap(
      nonBillableHoursGroupedByProjectIdMap
    );
  }, [nonBillableHoursGroupedByProjectIdMap]);

  const sort = breakdownArr => {
    return _.orderBy(
      breakdownArr,
      [({ value }) => Number(value), 'name'],
      ['desc', 'asc']
    );
  };

  const renderUserTable = userIdToHoursMap => {
    let rows = [];
    _.keys(userIdToHoursMap).forEach(userId => {
      if (userId !== 'total') {
        const user = companyCrewMap[userId];
        const fullName = getUserFullName(user);
        rows.push({
          username: user.username,
          fullName,
          department: user.departmentName,
          hours: userIdToHoursMap[userId],
        });
      }
    });

    rows = _.orderBy(
      rows,
      [({ hours }) => Number(hours), 'username'],
      ['desc', 'asc']
    );

    return (
      <div style={{ width: '100%', marginTop: 8, marginBottom: 4 }}>
        <Paper
          variant="outlined"
          style={{
            padding: 8,
          }}
        >
          <div style={{ width: '100%', overflowX: 'auto' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ width: 250 }}>
                    Username
                  </StyledTableCell>
                  <StyledTableCell style={{ width: 300 }}>Name</StyledTableCell>
                  <StyledTableCell style={{ width: 200 }}>
                    Department
                  </StyledTableCell>
                  <StyledTableCell align="right">Hours</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(({ username, fullName, department, hours }) => (
                  <StyledTableRow key={username}>
                    <StyledTableCell>{username}</StyledTableCell>
                    <StyledTableCell>{fullName}</StyledTableCell>
                    <StyledTableCell>{department}</StyledTableCell>
                    <StyledTableCell align="right">
                      {asHours(hours)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    );
  };

  const breakdownDataByProject = useMemo(() => {
    if (open) {
      let billableHours = [];
      let nonBillableHours = [];
      let totalHours = 0;
      let totalBillableHours = 0;
      let totalNonBillableHours = 0;
      _.forEach(billableHoursGroupedByProjectIdMap, (value, projectId) => {
        if (projectId === 'total') {
          totalBillableHours = Number(value);
          totalHours += totalBillableHours;
        } else {
          billableHours.push({
            name: projectIdToPathNameMap[projectId],
            value: asHours(value.total),
            expandableBody: renderUserTable(value),
          });
        }
      });

      billableHours = sort(billableHours);

      _.forEach(nonBillableHoursGroupedByProjectIdMap, (value, projectId) => {
        if (projectId === 'total') {
          totalNonBillableHours = Number(value);
          totalHours += totalNonBillableHours;
        } else {
          nonBillableHours.push({
            name: projectIdToPathNameMap[projectId],
            value: asHours(value.total),
            expandableBody: renderUserTable(value),
          });
        }
      });

      nonBillableHours = sort(nonBillableHours);

      return {
        name: 'Total Hours',
        value: asHours(totalHours),
        breakdownData: [
          {
            name: billableHoursGraph.label,
            value: asHours(totalBillableHours),
            breakdownData: billableHours,
            color: palette.brandColorGreen,
          },
          {
            name: nonBillableHoursGraph.label,
            value: asHours(totalNonBillableHours),
            breakdownData: nonBillableHours,
            color: palette.brandColorError,
          },
        ],
      };
    }
    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    billableHoursGroupedByProjectIdMap,
    nonBillableHoursGroupedByProjectIdMap,
    open,
  ]);

  const renderProjectTable = projectIdToHoursMap => {
    let rows = [];
    _.keys(projectIdToHoursMap).forEach(projectId => {
      if (projectId !== 'total') {
        rows.push({
          projectId,
          projectName: projectIdToPathNameMap[projectId],
          customerName:
            customerIdToCustomerNameMap[projectIdToCustomerIdMap[projectId]] ||
            'Unassigned',
          hours: projectIdToHoursMap[projectId],
        });
      }
    });

    rows = _.orderBy(
      rows,
      [({ hours }) => Number(hours), 'projectName'],
      ['desc', 'asc']
    );

    return (
      <div style={{ width: '100%', marginTop: 8, marginBottom: 4 }}>
        <Paper
          variant="outlined"
          style={{
            padding: 8,
          }}
        >
          <div style={{ width: '100%', overflowX: 'auto' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ width: 520 }}>
                    Project Name
                  </StyledTableCell>
                  <StyledTableCell>Customer Name</StyledTableCell>
                  <StyledTableCell align="right">Hours</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(({ customerName, projectId, projectName, hours }) => (
                  <StyledTableRow key={projectId}>
                    <StyledTableCell>{projectName}</StyledTableCell>
                    <StyledTableCell>{customerName}</StyledTableCell>
                    <StyledTableCell align="right">
                      {asHours(hours)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    );
  };

  const breakdownDataByUser = useMemo(() => {
    if (open) {
      let billableHours = [];
      let nonBillableHours = [];
      let totalHours = 0;
      let totalBillableHours = 0;
      let totalNonBillableHours = 0;
      _.forEach(billableHoursGroupedByUserIdMap, (value, userId) => {
        if (userId === 'total') {
          totalBillableHours = Number(value);
          totalHours += totalBillableHours;
        } else {
          const user = companyCrewMap[userId];
          const fullUserString = getFullUserString(user);
          billableHours.push({
            name: fullUserString,
            value: asHours(value.total),
            expandableBody: renderProjectTable(value),
          });
        }
      });

      billableHours = sort(billableHours);

      _.forEach(nonBillableHoursGroupedByUserIdMap, (value, userId) => {
        if (userId === 'total') {
          totalNonBillableHours = Number(value);
          totalHours += totalNonBillableHours;
        } else {
          const user = companyCrewMap[userId];
          const fullUserString = getFullUserString(user);
          nonBillableHours.push({
            name: fullUserString,
            value: asHours(value.total),
            expandableBody: renderProjectTable(value),
          });
        }
      });

      nonBillableHours = sort(nonBillableHours);

      return {
        name: 'Total Hours',
        value: asHours(totalHours),
        breakdownData: [
          {
            name: billableHoursGraph.label,
            value: asHours(totalBillableHours),
            breakdownData: billableHours,
            color: palette.brandColorGreen,
          },
          {
            name: nonBillableHoursGraph.label,
            value: asHours(totalNonBillableHours),
            breakdownData: nonBillableHours,
            color: palette.brandColorError,
          },
        ],
      };
    }
    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    billableHoursGroupedByUserIdMap,
    nonBillableHoursGroupedByUserIdMap,
    open,
  ]);

  const breakdownDataToUse = useMemo(() => {
    if (groupedBy === GROUPED_BY.PROJECT) {
      return breakdownDataByProject;
    }
    return breakdownDataByUser;
  }, [breakdownDataByProject, breakdownDataByUser, groupedBy]);

  return (
    <>
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle disableTypography>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5">{title}</Typography>
            <FormControl>
              <RadioGroup
                row
                aria-label="groupedBy"
                name="groupedBy"
                value={groupedBy}
                onChange={() => {
                  setGroupedBy(currentState => {
                    return currentState === GROUPED_BY.PROJECT
                      ? GROUPED_BY.USER
                      : GROUPED_BY.PROJECT;
                  });
                }}
              >
                <FormControlLabel
                  value="project"
                  control={<Radio color="primary" />}
                  label="By Project > User"
                />
                <FormControlLabel
                  value="user"
                  control={<Radio color="primary" />}
                  label="By User > Project"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid container direction="column">
            {!_.isEmpty(breakdownDataToUse) && (
              <DashboardReportTableRow
                name={breakdownDataToUse.name}
                value={breakdownDataToUse.value}
                defaultExpanded
                expandableBody={_.map(
                  breakdownDataToUse.breakdownData,
                  data => (
                    <DashboardReportTableRow
                      key={data.name}
                      name={data.name}
                      value={data.value}
                      color={data.color}
                      expandableBody={
                        !_.isEmpty(data.breakdownData)
                          ? _.map(data.breakdownData, d => (
                              <DashboardReportTableRow
                                key={d.name}
                                name={d.name}
                                value={d.value}
                                expandableBody={d.expandableBody}
                              />
                            ))
                          : null
                      }
                    />
                  )
                )}
              />
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            flex={1}
          >
            <Button onClick={handleClose} color="primary" autoFocus>
              Close
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BillableHoursBreakdownDialog;
