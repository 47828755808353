import React, { useEffect, useState } from 'react';
import cloudinary from 'cloudinary-core';
import _ from 'lodash';
import uuid from 'uuid';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Typography, IconButton, Tooltip, Grid } from '@material-ui/core';
import {
  GetApp as GetAppIcon,
  Link as CopyLinkIcon,
  PictureAsPdf as PictureAsPdfIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  OpenInNew as OpenInNewIcon,
  Link as LinkIcon,
} from '@material-ui/icons';
import { GlassMagnifier } from 'react-image-magnifiers';
import { makeStyles } from '@material-ui/styles';
import copy from 'copy-to-clipboard';
import { withSnackbar } from 'notistack';

import ContentImage from './content-image';
import ViewPdf from './view-pdf';
import ActionButtonHolder from './action-button-holder';

import {
  publicIdFromUrl,
  cloudinaryDownloadUrl,
} from '../../../helpers/cloudinary';
import {
  brokenImageUrl,
  cloudindaryCloudName,
} from '../../../config/appDefaults';
import { getToJpg } from '../../../helpers';

const cl = new cloudinary.Cloudinary({
  cloud_name: cloudindaryCloudName,
  secure: true,
});
const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

const arrowStyles = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 15px)',
  width: 30,
  height: 30,
  cursor: 'pointer',
};
const useStyles = makeStyles(theme => ({
  optionButtonWrapper: {
    opacity: 1,
    color: '#fff',
    position: 'absolute',
    bottom: 10,
    right: 10,
    textAlign: 'center',
    backgroundColor: '#5f5f5f',
    '&:hover': {
      background: '#888',
    },
    height: 50,
    width: 50,
    borderRadius: '50%',
  },
  optionsParent: {
    minHeight: 200,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    position: 'relative',
    '&:hover .optionsWrapper': {
      background: '#fff',
    },
  },
  arrrowButton: {
    backgroundColor: '#fff',
  },
  optionButton: {
    color: '#fff',
    fontSize: 30,
    width: '100%',
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  pdfIcon: {
    color: theme.palette.brandColorPrimary,
    fontSize: 200,
    paddingBottom: '10px',
  },
}));

const ContentGallery = props => {
  const {
    // NOTE: contentPiece.contentUrl can be a single cloudinary url or stringified array of cloudinary urls
    contentPiece,
    hideDownload,
    fromTask,
    renderMenu,
    renderMore,
    noMaxHeight,
    isCompany,
    showCopyUrl,
    enqueueSnackbar,
    // NOTE: if glassMagnifier is true, will render a glass magnifier for a single image
    // it will be ignored if contentPiece.contentUrl is not a single cloudinary url
    glassMagnifier,
    compactView,
  } = props;

  const [cleanImageObjects, setCleanImageObjects] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    if (contentPiece) {
      let imageObjects;
      try {
        imageObjects = JSON.parse(contentPiece.contentUrl) || [];
      } catch (e) {
        // Check if the contentUrl is a single cloudinary url
        if (
          contentPiece.contentUrl &&
          contentPiece.contentUrl.includes('cloudinary')
        ) {
          imageObjects = [{ uri: contentPiece.contentUrl }];
        } else {
          imageObjects = [];
        }
      }

      if (isCompany) {
        imageObjects = [{ uri: contentPiece.contentUrl }];
      }

      // need to clean out any null values just in case (Skylight had this issue)
      const cleanObjects = [];
      if (imageObjects.length) {
        imageObjects = imageObjects.forEach(image => {
          if (image && image.uri) {
            cleanObjects.push({
              ...image,
              uri: getToJpg(image.uri, { dontMessWithPdf: true }),
            });
          } else {
            cleanObjects.push({ id: uuid() }); // just so we can a unique key
          }
        });
      }

      setCleanImageObjects(cleanObjects);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPiece]);

  const copyToClipboard = imageUrl => {
    copy(imageUrl);
    enqueueSnackbar('Link copied!', {
      variant: 'success',
      autoHideDuration: 1000,
    });
  };

  if (!cleanImageObjects || cleanImageObjects.length === 0) {
    if (fromTask) {
      return null;
    }
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        item
        style={{ minHeight: 150, height: '100%' }}
      >
        {!!contentPiece?.Icon && <contentPiece.Icon style={{ fontSize: 50 }} />}
        <Typography variant="body2">No image provided</Typography>
      </Grid>
    );
  }

  if (cleanImageObjects.length === 1) {
    const contentUrl = cleanImageObjects[0].uri;
    const contentPath = cleanImageObjects[0].path;
    const isPdf =
      (contentUrl && contentUrl.endsWith('.pdf')) ||
      // if the file has not been uploaded yet (blob),
      // check if the path ends with .pdf
      (contentPath && contentPath.endsWith('.pdf'));

    if (isPdf) {
      return (
        <ViewPdf
          fileLocation={contentUrl}
          wrapperStyle={{ height: '65vh' }}
          showCopyUrl={showCopyUrl}
          compactView={compactView}
        />
      );
    }

    if (glassMagnifier) {
      return (
        <>
          {!!contentUrl && (
            <ActionButtonHolder>
              {showCopyUrl && (
                <Tooltip title="Copy URL">
                  <IconButton
                    onClick={() => {
                      copyToClipboard(contentUrl);
                    }}
                    size={compactView ? 'small' : undefined}
                  >
                    <LinkIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Open in new tab">
                <IconButton
                  href={contentUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  size={compactView ? 'small' : undefined}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
            </ActionButtonHolder>
          )}

          <GlassMagnifier
            imageSrc={contentUrl}
            imageAlt={contentUrl}
            magnifierSize="50%"
          />
        </>
      );
    }

    const passAsImage = { ...contentPiece };
    passAsImage.contentUrl = cleanImageObjects[0].uri;
    passAsImage.aspectRatio = cleanImageObjects[0].aspectRatio;
    return (
      <ContentImage
        contentPiece={passAsImage}
        renderMenu={renderMenu}
        renderMore={renderMore}
        showDownload={!hideDownload}
        showCopyUrl={showCopyUrl}
      />
    );
  }

  const imageTranformWidth = Math.round(windowWidth / 2);
  const imageTranformHeight =
    Math.round(windowHeight * 0.75) < 700
      ? 700
      : Math.round(windowHeight * 0.75);

  const thisUrl = (imageInfo, isPdf) => {
    if (_.includes(imageInfo.uri, 'cloudinary') && isPdf) {
      return cl.url(publicIdFromUrl(imageInfo.uri), {
        quality: 'auto',
        width: imageTranformWidth,
        crop: 'scale',
        fetchFormat: 'pdf',
      });
    }
    if (_.includes(imageInfo.uri, 'cloudinary')) {
      return cl.url(publicIdFromUrl(imageInfo.uri), {
        quality: 'auto',
        width: imageTranformWidth,
        height: imageTranformHeight,
        crop: 'limit',
        fetchFormat: 'jpg',
      });
    }
    return imageInfo.uri;
  };

  return (
    <Carousel
      showStatus={false}
      showIndicators={false}
      showArrows
      swipeable
      renderArrowPrev={(onClickHandler, hasPrev) =>
        hasPrev && (
          <ChevronLeftIcon
            onClick={onClickHandler}
            style={{ ...arrowStyles, left: 0 }}
          />
        )
      }
      renderArrowNext={(onClickHandler, hasNext) =>
        hasNext && (
          <ChevronRightIcon
            onClick={onClickHandler}
            style={{ ...arrowStyles, right: 0 }}
          />
        )
      }
      // // return an array
      renderThumbs={children => {
        const customThumbnail = [];
        children.forEach(child => {
          // the only time the displayName will be populated is when uploading a pdf
          const firstChild =
            child.props && child.props.children && child.props.children[0];
          const secondChild =
            firstChild &&
            firstChild.props &&
            firstChild.props.children &&
            firstChild.props.children[0];

          const isPdfOnUpload =
            secondChild && secondChild.type && secondChild.type.displayName;

          // Will either be url or uuid
          const isNotAUrl = !child.key.includes(':');

          if (isPdfOnUpload) {
            customThumbnail.push(
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                key={child.key}
              >
                <PictureAsPdfIcon
                  className={classes.pdfIcon}
                  style={{
                    maxHeight: '250px',
                    fontSize: 55,
                    paddingBottom: 0,
                  }}
                />
              </div>
            );
          } else if (isNotAUrl) {
            customThumbnail.push(<img alt="broken" src={brokenImageUrl} />);
          } else {
            customThumbnail.push(
              <img
                src={
                  child.key.endsWith('.pdf')
                    ? child.key.replace('.pdf', '.jpeg')
                    : child.key
                }
                style={{ maxHeight: '250px' }}
                alt="uploaded img/pdf"
                key={child.key}
              />
            );
          }
        });
        return customThumbnail;
      }}
    >
      {cleanImageObjects.map(imageObject => {
        if (!imageObject.uri) {
          return (
            <div
              key={imageObject.id}
              className={classes.optionsParent}
              style={{ position: 'relative' }}
            >
              <img
                alt="broken"
                src={brokenImageUrl}
                style={{ maxHeight: noMaxHeight ? null : '250px' }}
              />
              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 16,
                }}
              >
                <Typography variant="body1">
                  No image found.
                  <br />
                  There may have been an issue uploading the image.
                </Typography>
              </div>
            </div>
          );
        }
        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            key={imageObject.uri}
            className={classes.optionsParent}
            style={{
              cursor:
                imageObject &&
                imageObject.uri &&
                imageObject.uri.endsWith('.pdf')
                  ? 'pointer'
                  : null,
            }}
            onClick={() => {
              if (
                imageObject &&
                imageObject.uri &&
                imageObject.uri.endsWith('.pdf')
              ) {
                window.open(thisUrl(imageObject, true));
              }
            }}
            role="button"
            tabIndex={0}
          >
            {/* path is populated on upload */}
            {imageObject.path && imageObject.path.endsWith('.pdf') ? (
              <>
                <PictureAsPdfIcon className={classes.pdfIcon} />
                <Typography style={{ paddingBottom: 8 }}>
                  {imageObject.path}
                </Typography>
              </>
            ) : (
              <>
                {imageObject.uri && imageObject.uri.endsWith('.pdf') ? (
                  <>
                    <a
                      href={thisUrl(imageObject, true)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt=""
                        src={thisUrl(imageObject, true)}
                        style={{ maxHeight: noMaxHeight ? null : '250px' }}
                      />
                    </a>
                  </>
                ) : (
                  <img
                    alt=""
                    src={thisUrl(imageObject)}
                    style={{ maxHeight: noMaxHeight ? null : '250px' }}
                  />
                )}
              </>
            )}

            {(!hideDownload || showCopyUrl) && (
              <div className={classes.optionsWrapper}>
                {showCopyUrl && (
                  <div className={classes.optionButtonWrapper}>
                    <Tooltip title="Copy to clipboard">
                      <IconButton
                        onClick={() => copyToClipboard(imageObject.uri)}
                        className={classes.optionButton}
                      >
                        <CopyLinkIcon
                          fontSize="inherit"
                          style={{ marginTop: -3 }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                {!hideDownload && (
                  <a
                    href={
                      _.includes(imageObject.uri, 'cloudinary')
                        ? cloudinaryDownloadUrl(imageObject.uri)
                        : imageObject.uri
                    }
                    className={classes.optionButtonWrapper}
                  >
                    <IconButton className={classes.optionButton}>
                      <GetAppIcon
                        fontSize="inherit"
                        style={{ marginTop: -3 }}
                      />
                    </IconButton>
                  </a>
                )}
              </div>
            )}
          </div>
        );
      })}
    </Carousel>
  );
};

export default withSnackbar(ContentGallery);
