import gql from 'graphql-tag';

export default gql`
  fragment CommentFields on Comment {
    commentId
    userId
    dateCreated
    content
  }
`;
