import gql from 'graphql-tag';

export default gql`
  mutation MoveOrCopyContent(
    $requestorId: String!
    $srcJrnId: String!
    $destJrnId: String!
    $contentId: String!
    $contentAction: String!
  ) {
    moveOrCopyContent(
      input: {
        requestorId: $requestorId
        srcJrnId: $srcJrnId
        destJrnId: $destJrnId
        contentId: $contentId
        contentAction: $contentAction
      }
    ) {
      __typename
      status
      msg
    }
  }
`;
