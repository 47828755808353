import React from 'react';
// import { useRouteMatch, Redirect } from 'react-router-dom';
import { compose, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import GetAllContentByJrnId from '../../graphql/queries/GetAllContentByJrnId';

import {
  adminOnlyContentTypes,
  CONTENT_DEFINITION,
  TOP_PROJECT_ID,
} from '../../config/appDefaults';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.brandColorPrimary,
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  categoryTitleWrapper: {
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    borderBottom: `4px solid ${theme.palette.brandColorYellow}`,
  },
  categoryTitle: {
    color: theme.palette.brandColorPrimary,
    textTransform: 'uppercase',
    paddingLeft: theme.spacing(1),
  },
  contentOptionsWrapper: {
    padding: theme.spacing(2),
  },
  selectContentTypeLink: {
    width: '100%',
    display: 'block',
    border: 0,
    backgroundColor: 'transparent',
  },
  iconWrapper: {
    fontSize: 60,
  },
}));

const AddWhatToProject = ({
  parentId,
  canEdit,
  userId,
  client,
  passOptionsBack,
  isParentExtended,
  parentInfo,
  mode,
  contentIs,
}) => {
  const isLead = parentInfo && parentInfo.type === 'lead';
  const fromShoebox = mode === 'shoebox';
  const fromAdminReceiptBillAdd = mode === 'adminReceiptBillAdd';
  const classes = useStyles();
  const [spinner, setSpinner] = React.useState(false);
  const whatShouldStatusBe = async passedAssignedTo => {
    let projectContent = [];
    try {
      const projectContentResponse = await client.query({
        query: GetAllContentByJrnId,
        variables: { jrnId: parentId },
        fetchPolicy: 'network-only',
      });
      projectContent = projectContentResponse.data.getAllContentByJrnId.items;

      // then find the piece of content needing to be edited
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('e: ', e);
    }

    const lastTimetrackingEvent = _.find(projectContent, contentPiece => {
      return (
        contentPiece.type === 'timetracking' &&
        contentPiece.assignedTo === passedAssignedTo
      );
    });
    let nextTimetrackingStatus = 'checkin';
    if (
      lastTimetrackingEvent &&
      lastTimetrackingEvent.contentStatus === 'checkin'
    ) {
      nextTimetrackingStatus = 'checkout';
    }
    return nextTimetrackingStatus;
  };

  const renderContentTypeList = typeOfContent => {
    // create a tile based layout
    const isItAdmin = adminOnlyContentTypes.indexOf(typeOfContent.action) >= 0;
    if (!canEdit && isItAdmin) {
      return null;
    }

    // if useTimetracking is false hide check in
    if (
      parentInfo &&
      parentInfo.useTimetracking === false &&
      typeOfContent.action === 'timetracking'
    ) {
      return null;
    }

    let tileWording = typeOfContent.name;
    if (typeOfContent.name === 'Invoice') {
      tileWording = 'Income';
    }

    return (
      <Grid item xs={6} sm={2} key={typeOfContent.name}>
        <ButtonBase
          className={classes.selectContentTypeLink}
          onClick={async () => {
            setSpinner(true);
            const stateToBePassed = {
              type: typeOfContent.action,
            };
            if (typeOfContent.action === 'timetracking') {
              const ntts = await whatShouldStatusBe(userId);
              stateToBePassed.nextTimetrackingStatus = ntts;
            }
            setSpinner(false);
            passOptionsBack(stateToBePassed);
          }}
        >
          <Paper className={classes.paper}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {spinner && typeOfContent.action === 'timetracking' ? (
                <>
                  <Grid item className={classes.iconWrapper}>
                    <CircularProgress />
                  </Grid>
                  <Grid item>Loading...</Grid>
                </>
              ) : (
                <>
                  <Grid item className={classes.iconWrapper}>
                    {!!typeOfContent.Icon && (
                      <typeOfContent.Icon fontSize="inherit" />
                    )}
                  </Grid>
                  <Grid item>{tileWording}</Grid>
                </>
              )}
            </Grid>
          </Paper>
        </ButtonBase>
      </Grid>
    );
  };

  const categories = [];
  _.each(CONTENT_DEFINITION, contentDef => {
    if (categories.indexOf(contentDef.category) < 0) {
      categories.push(contentDef.category);
    }
  });

  const renderCategoryHeading = category => {
    if (isLead) return null;
    return (
      <Grid container className={classes.categoryTitleWrapper}>
        <Typography variant="h3" className={classes.categoryTitle}>
          {category}
        </Typography>
      </Grid>
    );
  };

  const renderCategoryOptions = category => {
    const contentDefinitionsToInclude = _.filter(
      CONTENT_DEFINITION,
      contentDefinition => {
        if (contentDefinition.category !== category) return false;
        if (
          parentInfo &&
          (parentInfo.fromTemplate || parentInfo.type === 'template') &&
          !contentDefinition.allowedInTemplate
        ) {
          return false;
        }
        const isSubProject =
          parentInfo &&
          parentInfo.type === 'project' &&
          parentInfo.jrnId &&
          parentInfo.jrnId !== TOP_PROJECT_ID;
        if (isSubProject && !contentDefinition.allowedInSubproject) {
          return false;
        }
        if (isParentExtended && !contentDefinition.allowedInExtendedTask) {
          return false;
        }
        if (isLead && !contentDefinition.allowedInLead) {
          // if the parent is a lead and it's not allowed in a lead
          return false;
        }
        if (fromShoebox) {
          if (!contentDefinition.allowedInShoebox) {
            return false;
          }
          const isNotPdfAndCheckingPdf =
            contentIs !== 'pdf' && contentDefinition.name === 'Pdf';
          if (isNotPdfAndCheckingPdf) {
            return false;
          }

          // Override allowedOnProjects if from shoebox
          return true;
        }

        if (fromAdminReceiptBillAdd) {
          if (!contentDefinition.allowedInAdminReceiptBillAdd) {
            return false;
          }
        }

        return contentDefinition.allowedOnProjects;
      }
    );

    const categoryOptions = _.map(contentDefinitionsToInclude, definition =>
      renderContentTypeList(definition)
    );

    if (categoryOptions.length) {
      return (
        <Grid container item key={category}>
          {renderCategoryHeading(category)}
          <Grid container spacing={3}>
            {categoryOptions}
          </Grid>
        </Grid>
      );
    }

    return null;
  };

  let titleDetail = '';
  if (!fromShoebox) {
    if (isLead) {
      titleDetail = ' to this lead';
    } else {
      titleDetail = ' to this project';
    }
  } else {
    titleDetail = ' this as';
  }

  return (
    <Grid container style={{ paddingBottom: 64 }} justifyContent="center">
      <Typography variant="h3" gutterBottom align="center" color="primary">
        What would you like to add{titleDetail}?
      </Typography>
      <Grid container className={classes.contentOptionsWrapper}>
        {categories.map(category => renderCategoryOptions(category))}
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    userId: state.userInfo.userId,
  };
}

export default compose(withApollo)(connect(mapStateToProps)(AddWhatToProject));
