import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const ConditionalTooltip = props => {
  const { children, showIf, title, ...rest } = props;

  if (showIf) {
    return (
      <Tooltip title={title} {...rest}>
        {children}
      </Tooltip>
    );
  }
  return <>{children}</>;
};

export default ConditionalTooltip;
