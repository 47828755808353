import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Customer}
  query GetCustomerById($customerId: ID!) {
    getCustomerById(customerId: $customerId) {
      ...CustomerFields
    }
  }
`;
