import React, { useState, useMemo } from 'react';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import { connect } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  ButtonBase,
  Paper,
} from '@material-ui/core';
import { Mail as MailIcon } from '@material-ui/icons';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { useHistory } from 'react-router-dom';
import { useQueryStringParams } from '../../hooks';

// GraphQL
import GetCompanyRfisQuery from '../../graphql/queries/get-company-rfis';
import {
  GetCompanyCrewAction,
  GetCompanyInfoAction,
  AddOrUpdateCompanyAction,
} from '../../graphql/graphql';

import {
  CONTENT_DEFINITION,
  RFI_MODES,
  RFI_TXN_ANSWER_TYPES,
} from '../../config/appDefaults';
import AddContentForm from '../add-to-project/add-content-form';

import { mediaToContentUrl } from '../../helpers';

import RfiDatagrid from './rfi-datagrid';
import DialogTitle from '../../components/dialog-title/dialog-title';
import ViewOrEditRfi from '../../components/ProjectContentListing/view-or-edit-rfi';
import AdminToolsIconButton from '../../components/admin-tools-icon-button/admin-tools-icon-button';
import PickMultiUserDialog from '../../components/PickMultiUserDialog/pick-multi-user-dialog';

const useStyles = makeStyles(theme => ({
  scrollableColumn: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'scroll',
    height: 'calc(100vh - 64px)',
  },
  paddedContentWrapper: {
    padding: theme.spacing(2),
  },
  centeredWrapper: {
    padding: theme.spacing(4),
    textAlign: 'center',
    background: theme.palette.grey[100],
    textTransform: 'uppercase',
  },
  pageTitleWrapper: {
    padding: theme.spacing(2),
  },
  drawerPaper: {
    width: '90%',
    maxWidth: 1200,
  },
  helperWrapper: {
    padding: 6,
    background: '#fff',
    marginTop: 16,
    marginBottom: 16,
    borderRadius: 30,
  },
  helpIcon: {
    fontSize: 40,
    marginRight: 16,
    color: theme.palette.brandColorPrimary,
  },
  categoryWrapper: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  categoryText: {
    marginBottom: theme.spacing(1),
  },
  rfiWrapper: {
    marginBottom: theme.spacing(1),
  },
  rfiButtonBase: {
    textAlign: 'left',
    width: '100%',
  },
  rfiPaperWrapper: {
    width: '100%',
    padding: theme.spacing(2),
  },
  rfiDataLabel: {
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  addOptionsWrapper: {
    padding: theme.spacing(2),
  },
  addOptionPaper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.brandColorPrimary,
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  addOptionTitleWrapper: {
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    borderBottom: `4px solid ${theme.palette.brandColorYellow}`,
  },
  addOptionBase: {
    width: '100%',
    display: 'block',
    border: 0,
    backgroundColor: 'transparent',
  },
  addOptionIconWrapper: {
    fontSize: 60,
  },
  actionButtonsContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    '& button': {
      marginLeft: theme.spacing(2),
    },
    '& div': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const RfiDashboard = ({
  // HOC - state
  managingCompanyInfo,
  // HOC - queries
  companyCrew,
  companies,
  // HOC - mutations
  onAddOrUpdateCompany,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const queryStringParams = useQueryStringParams();

  const [rfiMode, setRfiMode] = useState(RFI_MODES.ACTIVE.value);
  const [showRfiRecipientPicker, setShowRfiRecipientPicker] = useState(false);
  const [savingRfiRecipients, setSavingRfiRecipients] = useState(false);
  const [addContentFormInfo, setAddContentFormInfo] = useState({
    open: false,
  });

  const GetNeededRfisQuery = useQuery(GetCompanyRfisQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      companyId: managingCompanyInfo?.managingCompanyId,
      mode: rfiMode,
    },
    skip: !managingCompanyInfo?.managingCompanyId,
  });

  const rfisToUseLoading = _.get(GetNeededRfisQuery, 'loading');
  const rfisToUse = _.get(
    GetNeededRfisQuery,
    `data.getCompanyRfis.items`,
    null
  );

  const queryRequestId = queryStringParams.get('request');

  const { companyAdmins, companyRfiRecipientIds } = useMemo(() => {
    const foundCompany = companies
      ? _.find(companies, {
          companyId: managingCompanyInfo?.managingCompanyId,
        })
      : null;

    if (!foundCompany || !companyCrew) {
      return {
        companyAdmins: [],
        companyRfiRecipientIds: [],
      };
    }

    const foundCompanyAdmins = companyCrew.filter(({ userId, type }) => {
      if (foundCompany.admins?.includes(userId) && type !== 'bookkeeper') {
        return true;
      }
      return false;
    });

    return {
      companyAdmins: _.orderBy(foundCompanyAdmins, ({ username }) =>
        username.toLowerCase()
      ),
      companyRfiRecipientIds: foundCompany.rfiRecipients || [],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCrew, companies]);

  const handleDrawerClose = (closeEvent = {}) => {
    const completedRfiInfo = closeEvent?.addContentForCompletedRfi || null;

    if (completedRfiInfo) {
      // Convert media types for content
      let contentUrl = null;
      if (completedRfiInfo.media) {
        contentUrl = mediaToContentUrl(completedRfiInfo.media);
      }

      // Create base content info
      const baseContentInfo = {
        jrnId: completedRfiInfo.projectId || '00000',
        contentUrl,
        date: completedRfiInfo.txnDate || completedRfiInfo.dateCreated,
        startDate: completedRfiInfo.txnDate || completedRfiInfo.dateCreated,
        endDate: completedRfiInfo.txnDate || completedRfiInfo.dateCreated,
        rfiIds: [completedRfiInfo.requestId],
      };

      if (completedRfiInfo.txnReceived || completedRfiInfo.txnSpent) {
        // if there's a value on the txn, use that as the amount on the content
        baseContentInfo.amount = {
          value: completedRfiInfo.txnReceived
            ? completedRfiInfo.txnReceived.value
            : completedRfiInfo.txnSpent.value,
        };
      }

      if (completedRfiInfo.clientNotes) {
        baseContentInfo.description = completedRfiInfo.clientNotes;
      }

      // Map projectList to base content
      let projectsToExpense = null;
      if (
        completedRfiInfo.projectList?.length &&
        completedRfiInfo.relatedProjects?.length
      ) {
        projectsToExpense = [];

        _.forEach(completedRfiInfo.projectList, ({ contentId, amount }) => {
          const projectInfo = _.find(completedRfiInfo.relatedProjects, {
            contentId,
          });

          if (projectInfo) {
            const projectPath = [projectInfo.title];
            if (projectInfo.jrn) {
              projectPath.unshift(projectInfo.jrn.title);
            }

            projectsToExpense.push({
              project: projectInfo,
              projectPath,
              amount: (amount?.value || 0).toFixed(2),
              description: '',
              existingContentInfo: null,
              billable: false,
            });
          }
        });
      }

      // Map billList to base content
      let billsToPay = null;
      if (completedRfiInfo.billList?.length && completedRfiInfo.relatedBills) {
        billsToPay = [];

        _.forEach(completedRfiInfo.billList, ({ contentId, amount }, i) => {
          const billInfo = _.find(completedRfiInfo.relatedBills, {
            contentId,
          });

          if (i === 0) {
            baseContentInfo.vendorId = billInfo.vendorId;
          } else if (baseContentInfo.vendorId !== billInfo.vendorId) {
            return;
          }

          billsToPay.push({ ...billInfo, paymentAmount: amount });
        });
      }

      const typeOptionsToShow = [];

      // General content types that require a project
      if (completedRfiInfo.projectId) {
        typeOptionsToShow.push(
          CONTENT_DEFINITION.image,
          CONTENT_DEFINITION.pdf,
          CONTENT_DEFINITION.textnote
        );
      }

      // Specific content types that require a project
      if (
        completedRfiInfo.projectId &&
        completedRfiInfo.requestType ===
          RFI_TXN_ANSWER_TYPES.customerPayment.value
      ) {
        // The special types for customer payment
        typeOptionsToShow.unshift(CONTENT_DEFINITION.payment);
      } else if (
        completedRfiInfo.requestType === RFI_TXN_ANSWER_TYPES.billPayment.value
      ) {
        typeOptionsToShow.unshift(CONTENT_DEFINITION.globalPayment);
      } else if (
        completedRfiInfo.requestType ===
        RFI_TXN_ANSWER_TYPES.debitCreditCardPurchase.value
      ) {
        typeOptionsToShow.unshift(
          CONTENT_DEFINITION.globalBill,
          CONTENT_DEFINITION.globalReceipt
        );
      }

      // Do not show options that are incompatible with media
      const mediaType = _.get(completedRfiInfo, 'media[0].type', null);
      const filteredTypeOptionsToShow = _.filter(
        typeOptionsToShow,
        typeDefinition => {
          if (
            mediaType &&
            !typeDefinition.allowedMediaTypes?.includes(mediaType)
          ) {
            return false;
          }

          return true;
        }
      );

      setAddContentFormInfo({
        open: true,
        typeOptionsToShow: filteredTypeOptionsToShow,
        baseContentInfo,
        parentId: completedRfiInfo.projectId || null,
        projectsToExpense,
        billsToPay,
      });
    }

    history.replace({
      search: '',
    });
  };

  const editThisRfi = rfi => {
    // Set query string
    history.replace({
      search: `?request=${rfi.requestId}`,
    });
  };

  const handleRfiModeChange = (event, value) => {
    if (value) {
      setRfiMode(value);
    }
  };

  const handleCloseAddContentForm = () => {
    setAddContentFormInfo({ open: false });
  };

  const isCompanyOwner = managingCompanyInfo?.isCompanyOwner;
  const isCompanyBookkeeper = managingCompanyInfo?.isCompanyBookkeeper;

  const isOwnerOrBookkeeper = isCompanyOwner || isCompanyBookkeeper;

  const saveRfiRecipients = async selectedUsers => {
    setSavingRfiRecipients(true);
    try {
      await onAddOrUpdateCompany({ rfiRecipients: selectedUsers });
    } catch (err) {
      console.log('saveRfiRecipients onAddOrUpdateCompany err: ', err); // eslint-disable-line no-console
    }
    setSavingRfiRecipients(false);
    setShowRfiRecipientPicker(false);
  };

  return (
    <div className={classes.scrollableColumn}>
      <div id="rfiDashboardWrapper" style={{ flex: 0 }}>
        <Grid item xs={12} className={classes.pageTitleWrapper}>
          <Grid container justifyContent="space-between">
            <Grid item xs={9}>
              <Typography variant="h1" gutterBottom={false}>
                RFI Dashboard
              </Typography>
              <Typography variant="body1">
                Addressing your outstanding RFIs helps us provide you with the
                most accurate and up to date information about your business.
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={3}
              className={classes.actionButtonsContainer}
            >
              <AdminToolsIconButton
                tooltipText={
                  isOwnerOrBookkeeper
                    ? 'Manage Recipients'
                    : 'Recipients can be managed by owner or bookkeeper only'
                }
                onClick={() => {
                  if (isOwnerOrBookkeeper) {
                    setShowRfiRecipientPicker(true);
                  }
                }}
              >
                <MailIcon />
              </AdminToolsIconButton>

              {showRfiRecipientPicker && (
                <PickMultiUserDialog
                  open
                  onCancel={() => {
                    setShowRfiRecipientPicker(false);
                  }}
                  onSave={async selectedUsers => {
                    await saveRfiRecipients(selectedUsers);
                  }}
                  initialSelectedItems={companyRfiRecipientIds}
                  options={companyAdmins}
                  showSaving={savingRfiRecipients}
                  titleText="Choose which users you would like to receive the RFI notification emails:"
                />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{ marginTop: 16 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <ToggleButtonGroup
              value={rfiMode}
              exclusive
              onChange={handleRfiModeChange}
              aria-label="rfi mode"
            >
              <ToggleButton
                value={RFI_MODES.ACTIVE.value}
                aria-label={RFI_MODES.ACTIVE.value}
              >
                ACTIVE
              </ToggleButton>
              <ToggleButton
                value={RFI_MODES.COMPLETE.value}
                aria-label={RFI_MODES.COMPLETE.value}
              >
                COMPLETE
              </ToggleButton>
            </ToggleButtonGroup>
            {!!rfisToUse && rfiMode !== RFI_MODES.COMPLETE.value && (
              <div
                style={{
                  background: '#fff',
                  padding: '10px 22px',
                  borderRadius: 30,
                }}
              >
                <Typography variant="h5">
                  Number of outstanding RFIs: {rfisToUse.length}
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </div>

      <div style={{ flex: 1, height: 200 }}>
        {rfisToUseLoading || (rfisToUse && rfisToUse.length > 0) ? (
          <RfiDatagrid
            rfis={rfisToUse}
            editThisRfi={editThisRfi}
            rfiMode={rfiMode}
          />
        ) : (
          <div className={classes.centeredWrapper}>
            Currently no {RFI_MODES[rfiMode].label} RFIs
          </div>
        )}
      </div>

      <ViewOrEditRfi
        open={!!queryRequestId}
        requestId={queryRequestId || null}
        onClose={handleDrawerClose}
      />

      {addContentFormInfo.open && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open
          onClose={handleCloseAddContentForm}
          PaperProps={{ style: { minHeight: '50%' } }}
        >
          <DialogTitle onClose={handleCloseAddContentForm}>
            Add Content
          </DialogTitle>
          <DialogContent>
            {!addContentFormInfo.passedType && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ height: '100%' }}
              >
                <Typography variant="h4" gutterBottom>
                  What would you like to add this as?
                </Typography>
                <Grid
                  container
                  className={classes.addOptionsWrapper}
                  spacing={2}
                  justifyContent="center"
                >
                  {!addContentFormInfo.typeOptionsToShow?.length && (
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="body1">
                        Sorry! There are no content types that support this RFI.
                      </Typography>
                    </Grid>
                  )}
                  {!!addContentFormInfo.typeOptionsToShow?.length &&
                    _.map(addContentFormInfo.typeOptionsToShow, contentDef => (
                      <Grid key={contentDef.name} item xs={6} sm={2}>
                        <ButtonBase
                          key={contentDef.action}
                          className={classes.addOptionBase}
                          onClick={() => {
                            setAddContentFormInfo({
                              ...addContentFormInfo,
                              passedType: contentDef.action,
                            });
                          }}
                        >
                          <Paper className={classes.addOptionPaper}>
                            <Grid
                              container
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid
                                item
                                className={classes.addOptionIconWrapper}
                              >
                                <contentDef.Icon fontSize="inherit" />
                              </Grid>
                              <Grid item>{contentDef.name}</Grid>
                            </Grid>
                          </Paper>
                        </ButtonBase>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            )}
            {addContentFormInfo.passedType && (
              <AddContentForm
                fromRfi
                passedType={addContentFormInfo.passedType}
                parentId={addContentFormInfo.parentId}
                baseContentInfo={addContentFormInfo.baseContentInfo}
                onComplete={handleCloseAddContentForm}
                projectsToExpense={addContentFormInfo.projectsToExpense}
                billsToPay={addContentFormInfo.billsToPay}
                handleBack={() => {
                  setAddContentFormInfo({
                    ...addContentFormInfo,
                    passedType: null,
                  });
                }}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return {
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
  };
}

export default compose(
  GetCompanyCrewAction,
  GetCompanyInfoAction,
  AddOrUpdateCompanyAction
)(connect(mapStateToProps)(RfiDashboard));
