import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent as MuiDialogContent,
  IconButton,
} from '@material-ui/core';
import { CancelOutlined as CancelOutlinedIcon } from '@material-ui/icons';

const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: 40,
  },
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const LevelModal = ({
  open,
  handleClose,
  children,
  contentStyle,
  fullWidth,
  maxWidth,
  fullScreen,
  showCloseButton,
  dialogStyle = {},
}) => {
  const classes = useStyles();

  const handleDialogClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={handleDialogClose}
      fullWidth={typeof fullWidth === 'boolean' ? fullWidth : true}
      maxWidth={maxWidth || 'lg'}
      fullScreen={fullScreen}
      style={{ ...dialogStyle }}
    >
      {showCloseButton && handleClose && (
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CancelOutlinedIcon fontSize="inherit" color="primary" />
        </IconButton>
      )}
      <DialogContent dividers style={contentStyle}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default LevelModal;
