import gql from 'graphql-tag';

export default gql`
  mutation ApplyParentPermissionsToSubprojects(
    $companyId: String!
    $contentId: String!
  ) {
    applyParentPermissionsToSubprojects(
      input: { contentId: $contentId, companyId: $companyId }
    ) {
      status
      msg
    }
  }
`;
