import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  InputLabel,
  Switch as MuiSwitch,
  Tooltip,
} from '@material-ui/core';
import {
  ChevronRight as ChevronRightIcon,
  Dashboard as DashboardIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import numeral from 'numeral';
import _ from 'lodash';

import LevelPellEditor from '../../views/add-to-project/level-pell-editor/level-pell-editor';
import { renderDateString } from '../../helpers/renderDateString';

const useStyles = makeStyles(theme => {
  return {
    container: {
      margin: '8px 0px',
      border: '1px solid #ececec',
      paddingBottom: 0,
    },
    listItem: {
      paddingRight: 0,
    },
    field: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    accordion: {
      marginTop: 0,
      '& .MuiAccordionSummary-root': {
        paddingRight: 0,
      },
    },
    accordionSummaryIcon: {
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
    },
    editor: {
      border: `1px solid #ccc`,
      width: '100%',
    },
    billableLabel: {
      paddingLeft: theme.spacing(2),
    },
  };
});

const SplitFinancialItemListProject = ({
  entryInfo,
  entryIndex,
  autoFocusOnAmount,
  invalidAmount,
  onResetInvalidAmount = () => {},
  onDeleteButtonClick,
  onFieldChange,
  allowDeleteExisting,
  userId,
  managingCompanyInfo,
  hideDetails,
  disabled,
}) => {
  const classes = useStyles();

  const {
    project: projectItem,
    projectPath,
    description,
    amount,
    isNew,
    billable,
    existingContentInfo,
  } = entryInfo;

  const projectPathString = projectPath?.join(' > ') || '';

  const isAllowedEditProjectItem = useMemo(() => {
    if (!existingContentInfo) {
      // it is a new split item
      return true;
    }

    if (managingCompanyInfo.isCompanyAdmin) {
      return true;
    }

    if (projectItem) {
      const { allowedToEdit, allowedToAdd } = projectItem;

      if (
        _.includes(allowedToEdit, userId) ||
        (_.includes(allowedToAdd, userId) &&
          existingContentInfo.creatorId === userId)
      ) {
        return true;
      }
    }

    return false;
  }, [existingContentInfo, managingCompanyInfo, projectItem, userId]);

  const disabledDelete = !isNew && !allowDeleteExisting;

  const [expanded, setExpanded] = useState(!!description);

  useEffect(() => {
    onResetInvalidAmount(entryIndex, entryInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const handleNumberBlur = e => {
    let { value } = e.target;
    if (value) {
      let valToUse = numeral(value);
      if (valToUse < 0) {
        valToUse *= -1;
      }
      value = valToUse.format('0.00');
    } else {
      value = '';
    }
    onFieldChange(value, 'amount', entryIndex);
  };

  const handleNumberChange = e => {
    let { value } = e.target;
    value = value.replace(/[^0-9.]+/g, '');
    onFieldChange(value, 'amount', entryIndex);
  };

  const handleDescriptionChange = htmlText => {
    onFieldChange(htmlText, 'description', entryIndex);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleBillableToggle = event => {
    onFieldChange(event.target.checked, 'billable', entryIndex);
  };

  const handleDeleteButtonClick = () => {
    onDeleteButtonClick(entryIndex);
  };

  return (
    <Tooltip
      title={
        isAllowedEditProjectItem
          ? ''
          : 'You do not have permission to edit or delete this item'
      }
    >
      <Grid container direction="column" className={classes.container}>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={7}>
              <ListItem className={classes.listItem}>
                <ListItemAvatar>
                  {isAllowedEditProjectItem && projectItem.contentUrl ? (
                    <Avatar
                      alt="Project Cover Image"
                      src={projectItem.contentUrl}
                    />
                  ) : (
                    <Avatar>
                      <DashboardIcon />
                    </Avatar>
                  )}
                </ListItemAvatar>
                {isAllowedEditProjectItem && (
                  <ListItemText
                    primary={projectPathString}
                    secondary={renderDateString(
                      projectItem.startDate,
                      projectItem.endDate
                    )}
                  />
                )}
                {!isAllowedEditProjectItem && (
                  <ListItemText primary="Other Project" />
                )}
              </ListItem>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Amount"
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={amount || ''}
                margin="none"
                onChange={handleNumberChange}
                onBlur={handleNumberBlur}
                autoFocus={autoFocusOnAmount}
                error={invalidAmount}
                disabled={!isAllowedEditProjectItem || disabled}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                size="small"
                disabled={
                  disabledDelete || !isAllowedEditProjectItem || disabled
                }
                onClick={handleDeleteButtonClick}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        {isAllowedEditProjectItem && !hideDetails && (
          <>
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justifyContent="space-between"
              className={classes.field}
            >
              <InputLabel className={classes.billableLabel}>
                Is expense billable?
              </InputLabel>
              <MuiSwitch
                name="billable"
                value={!!billable}
                checked={!!billable}
                variant="inline"
                onChange={handleBillableToggle}
                disabled={disabled}
              />
            </Grid>
            <Grid item className={classes.field}>
              <Accordion
                square
                elevation={0}
                className={classes.accordion}
                expanded={expanded}
                onChange={handleAccordionChange}
              >
                <AccordionSummary
                  expandIcon={<ChevronRightIcon />}
                  className={classes.accordionSummaryIcon}
                >
                  <Typography>Description</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.editor}>
                    <LevelPellEditor
                      defaultContent={description}
                      onChange={handleDescriptionChange}
                      containerClass="level-pell-content-addContentForm"
                      disabled={disabled}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </>
        )}
      </Grid>
    </Tooltip>
  );
};

const mapStateToProps = state => ({
  userId: state.userInfo && state.userInfo.userId,
  managingCompanyInfo: state.appState.managingCompanyInfo,
});

export default connect(mapStateToProps)(SplitFinancialItemListProject);
