import gql from 'graphql-tag';

export default gql`
  query GetCompanyCrew($companyId: String!) {
    getCompanyCrew(companyId: $companyId) {
      items {
        userId
        username
        firstName
        lastName
        email
        phoneNumber
        profilePic
        accessMode
        userType
        type
      }
    }
  }
`;
