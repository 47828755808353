import moment from 'moment';
import { formatNumberAsMoneyDollar } from './index';

export const searchTextFieldInObject = (
  searchThisObject,
  searchThisField,
  searchTerm,
  searchAsMoneyDollar = false
) => {
  if (!searchThisObject || !searchThisObject[searchThisField]) {
    return false;
  }
  const toSearch = searchTerm.toLowerCase();
  if (searchThisObject[searchThisField]) {
    const asText = `${searchThisObject[searchThisField]}`
      .toLowerCase()
      .includes(toSearch);

    let matchAsDollar = false;
    if (searchAsMoneyDollar) {
      const valAsDollarVal = formatNumberAsMoneyDollar(
        searchThisObject[searchThisField]
      );
      matchAsDollar = valAsDollarVal.includes(toSearch);
    }
    return asText || matchAsDollar;
  }
  return false;
};

export const searchForStringInArray = (
  searchThisObject,
  searchThisField,
  findThisLabel
) => {
  if (searchThisObject[searchThisField]) {
    return (
      searchThisObject[searchThisField].indexOf(findThisLabel.toLowerCase()) >=
      0
    );
  }
  return false;
};

export const dateBetween = (date, start, end) => {
  return moment(date).isBetween(start, end);
};

// TODO: test this
export const dateAfter = (date, start) => moment(date).isAfter(start);

// TODO: test this
export const dateBefore = (date, end) => moment(date).isBefore(end);
