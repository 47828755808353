import React, { useEffect, useRef, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ContentLoader from 'react-content-loader';

const InputContentLoader = ({ loading, label, children }) => {
  const coverAreaRef = useRef();
  const [loaderDimensions, setLoaderDimensions] = useState({
    width: 80,
    height: 26,
  });

  useEffect(() => {
    if (loading && coverAreaRef && coverAreaRef.current) {
      setLoaderDimensions({
        width: coverAreaRef.current.clientWidth,
        height: coverAreaRef.current.clientHeight,
      });
    }
  }, [loading]);

  if (loading) {
    return (
      <Grid container direction="column">
        <Typography
          display="inline"
          style={{
            transform: 'scale(0.75)',
            transformOrigin: 'top left',
            height: 16,
          }}
        >
          {label}
        </Typography>
        <div
          style={{
            width: '100%',
            height: 26,
            marginTop: 4,
          }}
          ref={coverAreaRef}
        >
          <ContentLoader
            width={loaderDimensions.width}
            height={loaderDimensions.height}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
          >
            <rect
              x="0"
              y="0"
              rx="4"
              ry="4"
              width={loaderDimensions.width}
              height={loaderDimensions.height}
            />
          </ContentLoader>
        </div>
      </Grid>
    );
  }

  return children;
};

export default InputContentLoader;
