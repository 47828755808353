import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper, Grid } from '@material-ui/core';
import QboSyncStatusIcon from '../../../components/qbo-sync-status-icon';
import { QBO_SYNCABLE_TYPE } from '../../../config/appDefaults';

const useStyles = makeStyles(theme => {
  return {
    paper: props => {
      return {
        margin: `${theme.spacing(1)}px auto 0 auto`,
        padding: theme.spacing(2),
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.brandColorPrimary,
          color: '#fff',
          '& $customerTileTitle': {
            color: '#fff !important',
          },
        },
        backgroundColor: props.highlightSelected
          ? theme.palette.brandColorPrimary
          : null,
        color: props.highlightSelected ? '#fff' : null,
        transition: 'all 0.3s ease',
        width: '100%',
      };
    },
    customerTileTitle: props => {
      return {
        color: props.highlightSelected
          ? '#fff'
          : theme.palette.brandColorPrimary,
        fontSize: '130%',
      };
    },
    customerTileFirstName: ({ data: customer }) => {
      return {
        fontWeight: customer.firstName && !customer.lastName ? 'bold' : null,
      };
    },
    customerTileLastName: {
      fontWeight: 'bold',
    },
    customerTileText: props => {
      return {
        color: props.highlightSelected ? '#fff' : null,
        fontSize: '80%',
        marginTop: theme.spacing(0.5),
      };
    },
  };
});

const Customer = props => {
  const { data: customerInfo, onClick } = props;
  const classes = useStyles(props);

  const hasFirstOrLast = customerInfo.firstName || customerInfo.lastName;

  return (
    <Paper className={classes.paper} onClick={() => onClick(customerInfo)}>
      <Grid container alignItems="flex-start">
        <Grid
          container
          item
          direction="column"
          wrap="nowrap"
          style={{ flex: 1 }}
        >
          <Grid item>
            <div className={classes.customerTileTitle}>
              <span className={classes.customerTileFirstName}>
                {customerInfo.firstName}
              </span>
              {!!customerInfo.firstName && !!customerInfo.lastName && ' '}
              <span className={classes.customerTileLastName}>
                {customerInfo.lastName}
              </span>
            </div>
          </Grid>
          {!!customerInfo.companyName && (
            <div
              className={
                hasFirstOrLast
                  ? classes.customerTileText
                  : classes.customerTileTitle
              }
              style={{
                marginTop: 0,
                marginBottom: hasFirstOrLast ? 8 : 0,
              }}
            >
              {customerInfo.companyName}
            </div>
          )}

          {!!customerInfo.address && (
            <div className={classes.customerTileText}>
              {customerInfo.address}
            </div>
          )}

          {!!customerInfo.phoneNumber && (
            <div className={classes.customerTileText}>
              {customerInfo.phoneNumber}
            </div>
          )}
        </Grid>
        <QboSyncStatusIcon
          recordInfo={customerInfo}
          recordType={QBO_SYNCABLE_TYPE.CUSTOMER}
        />
      </Grid>
    </Paper>
  );
};

export default Customer;
