/** @enum {string} */
export const QBO_ACCOUNT_KEY = {
  BANK: 'bank',
  ACCOUNTS_RECEIVABLE: 'accountsReceivable',
  OTHER_CURRENT_ASSETS: 'otherCurrentAssets',
  FIXED_ASSET: 'fixedAsset',
  OTHER_ASSETS: 'otherAssets',
  ACCOUNTS_PAYABLE: 'accountsPayable',
  CREDIT_CARD: 'creditCard',
  OTHER_CURRENT_LIABILITIES: 'otherCurrentLiabilities',
  LONG_TERM_LIABILITIES: 'longTermLiabilities',
  EQUITY: 'equity',
  INCOME: 'income',
  COST_OF_GOODS_SOLD: 'costOfGoodsSold',
  EXPENSES: 'expenses',
  OTHER_INCOME: 'otherIncome',
  OTHER_EXPENSE: 'otherExpense',
};

/** @enum {string} */
export const QBO_ACCOUNT_TYPE = {
  ACCOUNTS_PAYABLE: 'Accounts Payable',
  ACCOUNTS_RECEIVABLE: 'Accounts Receivable',
  BANK: 'Bank',
  COST_OF_GOODS_SOLD: 'Cost of Goods Sold',
  CREDIT_CARD: 'Credit Card',
  EQUITY: 'Equity',
  EXPENSE: 'Expense',
  FIXED_ASSETS: 'Fixed Asset',
  INCOME: 'Income',
  OTHER_ASSETS: 'Other Asset',
  OTHER_CURRENT_ASSETS: 'Other Current Asset',
  OTHER_CURRENT_LIABILITIES: 'Other Current Liability',
  OTHER_EXPENSE: 'Other Expense',
  OTHER_INCOME: 'Other Income',
  LONG_TERM_LIABILITIES: 'Long Term Liability',
};

/** @enum {QBO_ACCOUNT_TYPE} */
export const ACCOUNT_KEY_TO_TYPE = {
  [QBO_ACCOUNT_KEY.BANK]: QBO_ACCOUNT_TYPE.BANK,
  [QBO_ACCOUNT_KEY.ACCOUNTS_RECEIVABLE]: QBO_ACCOUNT_TYPE.ACCOUNTS_RECEIVABLE,
  [QBO_ACCOUNT_KEY.OTHER_CURRENT_ASSETS]: QBO_ACCOUNT_TYPE.OTHER_CURRENT_ASSETS,
  [QBO_ACCOUNT_KEY.FIXED_ASSET]: QBO_ACCOUNT_TYPE.FIXED_ASSETS,
  [QBO_ACCOUNT_KEY.OTHER_ASSETS]: QBO_ACCOUNT_TYPE.OTHER_ASSETS,
  [QBO_ACCOUNT_KEY.ACCOUNTS_PAYABLE]: QBO_ACCOUNT_TYPE.ACCOUNTS_PAYABLE,
  [QBO_ACCOUNT_KEY.CREDIT_CARD]: QBO_ACCOUNT_TYPE.CREDIT_CARD,
  [QBO_ACCOUNT_KEY.OTHER_CURRENT_LIABILITIES]:
    QBO_ACCOUNT_TYPE.OTHER_CURRENT_LIABILITIES,
  [QBO_ACCOUNT_KEY.LONG_TERM_LIABILITIES]:
    QBO_ACCOUNT_TYPE.LONG_TERM_LIABILITIES,
  [QBO_ACCOUNT_KEY.EQUITY]: QBO_ACCOUNT_TYPE.EQUITY,
  [QBO_ACCOUNT_KEY.INCOME]: QBO_ACCOUNT_TYPE.INCOME,
  [QBO_ACCOUNT_KEY.COST_OF_GOODS_SOLD]: QBO_ACCOUNT_TYPE.COST_OF_GOODS_SOLD,
  [QBO_ACCOUNT_KEY.EXPENSES]: QBO_ACCOUNT_TYPE.EXPENSE,
  [QBO_ACCOUNT_KEY.OTHER_INCOME]: QBO_ACCOUNT_TYPE.OTHER_INCOME,
  [QBO_ACCOUNT_KEY.OTHER_EXPENSE]: QBO_ACCOUNT_TYPE.OTHER_EXPENSE,
};

/** @enum {string} */
// IMPORTANT: this drives the order of the email report
export const BOOKKEEPING_ALERT = {
  NEW_VENDOR: 'new-vendor',
  JOURNAL_ENTRY_CREATED_OR_MODIFIED: 'journal-entry-created-or-modified',
  TRANSACTIONS_MISSING_VENDOR_CUSTOMER_INFORMATION:
    'transactions-missing-vendor-customer-information',
  DUPLICATE_EXPENSE_TRANSACTIONS: 'duplicate-expense-transactions',
  MISSING_BILL_NUMBERS: 'missing-bill-numbers',
  DELETED_TRANSACTIONS: 'deleted-transactions',
  VOIDED_TRANSACTIONS: 'voided-transactions',
  NEW_VENDOR_IN_ACCOUNT: 'new-vendor-in-account',
  NEW_PRODUCT: 'new-product',
  TRANSACTIONS_POSTED_TO_PARENT_CUSTOMERS:
    'transactions-posted-to-parent-customers',
  EXPENSE_TRANSACTIONS_WITH_CUSTOMERS_NOT_CODED_TO_COST_OF_SALES:
    'expense-transactions-with-customers-not-coded-to-cost-of-sales',
  COST_OF_SALES_TRANSACTIONS_MISSING_CUSTOMER_INFORMATION:
    'cost-of-sales-transactions-missing-customer-information',
  NONBILLABLE_TRANSACTIONS_WITH_CUSTOMERS:
    'nonbillable-transactions-with-customers',
  BILLABLE_EXPENSE_TRANSACTIONS_WITHOUT_ATTACHMENT:
    'billable-expense-transactions-without-attachment',
  NONBILLABLE_TIME_ACTIVITY: 'nonbillable-time-activity',
  TRANSACTIONS_POSTED_TO_PARENT_ACCOUNTS:
    'transactions-posted-to-parent-accounts',
  CLASS_ALERTS: 'class-alerts',
  LOCATION_ALERTS: 'location-alerts',
  TRANSACTIONS_MISSING_PRODUCT_OR_SERVICE:
    'transactions-missing-product-or-service',
  TRANSACTIONS_POSTED_TO_CLOSED_PERIOD: 'transactions-posted-to-closed-period',
  VENDORS_WITH_NEGATIVE_BALANCES: 'vendors-with-negative-balances',
  VENDORS_WITH_NEGATIVE_BALANCES_SUMMARY:
    'vendors-with-negative-balances-summary',
  CUSTOMERS_WITH_NEGATIVE_BALANCES: 'customers-with-negative-balances',
  CUSTOMERS_WITH_NEGATIVE_BALANCES_SUMMARY:
    'customers-with-negative-balances-summary',
};

export const ALERT_IDENTIFIERS_NOT_SUPPORTED_BY_TRANSACTION_DATE_BASED_CUSTOM_SCAN = [
  BOOKKEEPING_ALERT.DELETED_TRANSACTIONS,
  BOOKKEEPING_ALERT.NEW_PRODUCT,
  BOOKKEEPING_ALERT.NEW_VENDOR,
  BOOKKEEPING_ALERT.NEW_VENDOR_IN_ACCOUNT,
  BOOKKEEPING_ALERT.TRANSACTIONS_POSTED_TO_CLOSED_PERIOD,
  BOOKKEEPING_ALERT.VOIDED_TRANSACTIONS,
];

export const ALERT_IDENTIFIERS_NOT_SUPPORTED_BY_CREATION_DATE_BASED_CUSTOM_SCAN = [
  BOOKKEEPING_ALERT.CUSTOMERS_WITH_NEGATIVE_BALANCES,
  BOOKKEEPING_ALERT.DELETED_TRANSACTIONS,
  BOOKKEEPING_ALERT.NEW_VENDOR_IN_ACCOUNT,
  BOOKKEEPING_ALERT.TRANSACTIONS_POSTED_TO_CLOSED_PERIOD,
  BOOKKEEPING_ALERT.VENDORS_WITH_NEGATIVE_BALANCES,
  BOOKKEEPING_ALERT.VOIDED_TRANSACTIONS,
];

export const CUSTOM_SCAN_TYPE = {
  TRANSACTION_DATE: 'TRANSACTION_DATE',
  CREATION_DATE: 'CREATION_DATE',
};

export const DAY_OF_WEEK = {
  SUNDAY: 'Sun',
  MONDAY: 'Mon',
  TUESDAY: 'Tue',
  WEDNESDAY: 'Wed',
  THURSDAY: 'Thu',
  FRIDAY: 'Fri',
  SATURDAY: 'Sat',
};

export const DAYS_OF_WEEK = [
  DAY_OF_WEEK.SUNDAY,
  DAY_OF_WEEK.MONDAY,
  DAY_OF_WEEK.TUESDAY,
  DAY_OF_WEEK.WEDNESDAY,
  DAY_OF_WEEK.THURSDAY,
  DAY_OF_WEEK.FRIDAY,
  DAY_OF_WEEK.SATURDAY,
];

export const BOOKKEEPING_ALERT_RECIPIENT_TYPES = {
  GROUP: 'GROUP',
};

export const BOOKKEEPING_ALERT_TYPES = {
  ALERT: 'ALERT',
  RULE: 'RULE',
};

export const IGNORE_UNDEPOSITED_FUNDS_SUB_IDENTIFIER =
  'IGNORE_UNDEPOSITED_FUNDS';

export const BOOKKEEPING_ALERT_NOTIFICATION_METHODS = {
  EMAIL_REPORT: 'EMAIL_REPORT',
};

export const BOOKKEEPING_ALERT_ENTITY_TYPE = {
  ACCOUNT: 'Account',
  DEPOSIT: 'Deposit',
  ESTIMATE: 'Estimate',
  PURCHASE: 'Purchase',
  INVOICE: 'Invoice',
  JOURNAL_ENTRY: 'JournalEntry',
  PAYMENT: 'Payment',
  SALES_RECEIPT: 'SalesReceipt',
  REFUND_RECEIPT: 'RefundReceipt',
  BILL_PAYMENT: 'BillPayment',
  BILL: 'Bill',
  CREDIT_MEMO: 'CreditMemo',
  TRANSFER: 'Transfer',
  PURCHASE_ORDER: 'PurchaseOrder',
};

export const BOOKKEEPING_ALERT_ENTITY_TYPE_PRETTY_AND_PLURAL = {
  [BOOKKEEPING_ALERT_ENTITY_TYPE.ACCOUNT]: 'Accounts',
  [BOOKKEEPING_ALERT_ENTITY_TYPE.DEPOSIT]: 'Deposits',
  [BOOKKEEPING_ALERT_ENTITY_TYPE.ESTIMATE]: 'Estimates',
  [BOOKKEEPING_ALERT_ENTITY_TYPE.PURCHASE]: 'Purchases',
  [BOOKKEEPING_ALERT_ENTITY_TYPE.INVOICE]: 'Invoices',
  [BOOKKEEPING_ALERT_ENTITY_TYPE.JOURNAL_ENTRY]: 'Journal Entries',
  [BOOKKEEPING_ALERT_ENTITY_TYPE.PAYMENT]: 'Payments',
  [BOOKKEEPING_ALERT_ENTITY_TYPE.SALES_RECEIPT]: 'Sales Receipts',
  [BOOKKEEPING_ALERT_ENTITY_TYPE.REFUND_RECEIPT]: 'Refund Receipts',
  [BOOKKEEPING_ALERT_ENTITY_TYPE.BILL_PAYMENT]: 'Bill Payments',
  [BOOKKEEPING_ALERT_ENTITY_TYPE.BILL]: 'Bills',
  [BOOKKEEPING_ALERT_ENTITY_TYPE.CREDIT_MEMO]: 'Credit Memos',
  [BOOKKEEPING_ALERT_ENTITY_TYPE.TRANSFER]: 'Transfers',
  [BOOKKEEPING_ALERT_ENTITY_TYPE.PURCHASE_ORDER]: 'Purchase Orders',
};

export const BOOKKEEPING_ALERT_REPORT_DATA_TYPE = {
  ENTITY_TXN_COUNT_ARRAY: 'EntityTxnCountArray',
  ITEM_INFO_ARRAY: 'ItemInfoArray',
  KEY_VALUE_PAIR_ARRAY: 'KeyValuePairArray',
  TIME_ACTIVITY_INFO_ARRAY: 'TimeActivityInfoArray',
  TXN_INFO: 'TxnInfo',
  TXN_PAIR: 'TxnPair',
  TXN_STATUS_INFO_ARRAY: 'TxnStatusInfoArray',
  USER_TXNS: 'UserTxns',
  USER_TXNS_ARRAY: 'UserTxnsArray',
  VENDOR_INFO_ARRAY: 'VendorInfoArray',
};

export const BOOKKEEPING_ALERT_REPORT_PERIOD = {
  LAST_30_DAYS: {
    key: 0,
    value: 'Last 30 Days',
    displayValue: 'Last 30 Days',
    numOfDays: 30,
  },
  LAST_90_DAYS: {
    key: 1,
    value: 'Last 90 Days',
    displayValue: 'Last 90 Days',
    numOfDays: 90,
  },
  LAST_12_MONTHS: {
    key: 2,
    value: 'Last 12 Months',
    displayValue: 'Last 12 Months',
    numOfDays: 365,
  },
};

export const BOOKKEEPING_ALERT_REPORT_PERIOD_CUSTOM = {
  key: 3,
  value: 'Custom',
  displayValue: 'Custom',
  numOfDays: 0,
};

export const BOOKKEEPING_REPORT_EVENT_META_TYPE = {
  ADDRESSED_STATE: 'ADDRESSED_STATE',
  NOTE: 'NOTE',
};

export const BOOKKEEPING_REPORT_EVENT_META_STATE = {
  ADDRESSED: 'addressed',
};
