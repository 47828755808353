import React, { Component } from 'react';
import { connect } from 'react-redux';

import userflow from 'userflow.js';

import Routes from './route-builder';
import { runAnalytics, setAnalyticsUser } from './helpers';

const isMain = window.location.hostname === 'webapp.checkthelevel.com';
const isStaging = window.location.hostname.includes('staging');
const useProductionUserFlow = isMain || isStaging;

if (useProductionUserFlow) {
  userflow.init(process.env.REACT_APP_USERFLOW_TOKEN);
} else {
  // eslint-disable-next-line no-console
  console.warn(`UserFlow in development mode.`);
  userflow.init(process.env.REACT_APP_USERFLOW_TOKEN_DEV);
}

userflow.setResourceCenterLauncherHidden(true);

class App extends Component {
  async componentDidMount() {
    const { userInfo } = this.props;

    if (userInfo) {
      const options = { userId: userInfo.userId, email: userInfo.email };
      if (options.userId) {
        setAnalyticsUser(options);
        runAnalytics('Active User', options);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { userInfo, managingCompanyInfo } = this.props;

    // get the current user Id
    const userId = userInfo && userInfo.userId;

    // check if managingCompanyInfo exist
    const managingCompanyId =
      managingCompanyInfo && managingCompanyInfo.managingCompanyId;

    // check if company id changed
    const prevManagingCompanyId =
      prevProps.managingCompanyInfo &&
      prevProps.managingCompanyInfo.managingCompanyId;

    const companyHasBeenSetOrChanged =
      managingCompanyId && managingCompanyId !== prevManagingCompanyId;

    // run analytics call when a users company been set or changed
    if (userId && companyHasBeenSetOrChanged) {
      const options = {
        userId: userInfo.userId,
        email: userInfo.email,
        type: 'Started',
      };

      runAnalytics('Company Session', options);
    }
  }

  render() {
    return <Routes />;
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
  };
}

export default connect(mapStateToProps)(App);
