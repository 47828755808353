import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import ContentLoader from 'react-content-loader';
import FadeIn from 'react-fade-in/lib/FadeIn';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  companyLogo: {
    width: 40,
    margin: '0 auto',
  },
}));

const FirmDisplay = ({ firmInfo, errorOccurred }) => {
  const classes = useStyles();
  const displayNameRef = useRef(null);

  useEffect(() => {
    // decrease font size by 1px until it fits
    if (displayNameRef.current?.offsetWidth) {
      if (displayNameRef.current.offsetWidth > 200) {
        const currentFontSize = _.replace(
          window.getComputedStyle(displayNameRef.current).fontSize,
          'px',
          ''
        );

        displayNameRef.current.style.fontSize = `${currentFontSize - 1}px`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayNameRef.current?.offsetWidth]);

  return (
    <Grid container>
      {errorOccurred && `An error occurred.`}
      {!errorOccurred && !firmInfo && (
        <ContentLoader
          height={95}
          width={208}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="57" y="75" rx="3" ry="3" width="94" height="18" />
          <circle cx="104" cy="30" r="30" />
        </ContentLoader>
      )}
      {!errorOccurred && firmInfo && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <FadeIn>
            <Grid container item alignItems="center">
              {firmInfo.companyLogo && (
                <Grid
                  item
                  style={{
                    flex: 0,
                    padding: 4,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    alt="Company Logo"
                    className={classes.companyLogo}
                    src={firmInfo.companyLogo}
                  />
                </Grid>
              )}
              <Grid item style={{ flex: 1, padding: 8 }}>
                <Typography
                  ref={displayNameRef}
                  variant="body1"
                  align={firmInfo.companyLogo ? 'left' : 'center'}
                  style={{
                    wordBreak: 'break-word',
                  }}
                >
                  {firmInfo.companyName}
                </Typography>
              </Grid>
            </Grid>
          </FadeIn>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = state => ({
  firmInfo: state.appState.firmInfo || null,
});

export default connect(mapStateToProps)(FirmDisplay);
