import React, { createContext, useMemo } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo-hooks';
import _ from 'lodash';
import { ShiftTools } from 'level-foundation';

import GetCompanyCrew from '../../graphql/queries/get-company-crew';
import ListMyTasks from '../../graphql/queries/ListMyTasks';
import ListMyShifts from '../../graphql/queries/ListMyShifts';
import ListMyJrns from '../../graphql/queries/ListMyJrns';
import ListMyProjectsForCalendarContext from '../../graphql/queries/list-my-projects-for-calendar-context';
import GetAdminContentByType from '../../graphql/queries/GetAdminContentByType';
import GetCompanyAdminContent from '../../graphql/queries/get-company-admin-content';
import ListCompanyProjects from '../../graphql/queries/list-company-projects';
import {
  CONTENT_TYPE,
  EMPTY_USERID,
  colorsGoodForWhiteText,
} from '../../config/appDefaults';
import { DATA_TYPE, WHO } from './calendar.constants';

export const CalendarDataContext = createContext(null);

const CalendarDataProvider = ({
  // Props
  whosData,
  calendarDataType,
  children,
  // Redux
  managingCompanyInfo,
}) => {
  const listMyProjectsForCalendarContextOpts = {
    required: false,
    contentType: null,
  };
  const listMyJrnsOpts = { required: false };
  const listMyShiftsOpts = { required: false };
  const listMyTasksOpts = { required: false };
  const getAdminContentByTypeOpts = { required: false, contentType: null };
  const listCompanyProjectsOpts = { required: false };
  const getCompanyAdminContent = { required: false, contentType: null };
  if (whosData === WHO.MINE) {
    if (calendarDataType === DATA_TYPE.PROJECTS) {
      listMyJrnsOpts.required = true;
    }
    if (calendarDataType === DATA_TYPE.SHIFTS) {
      listMyProjectsForCalendarContextOpts.required = true;
      listMyProjectsForCalendarContextOpts.contentType = CONTENT_TYPE.SHIFT;
      listMyShiftsOpts.required = true;
    }
    if (calendarDataType === DATA_TYPE.TASKS) {
      listMyProjectsForCalendarContextOpts.required = true;
      listMyProjectsForCalendarContextOpts.contentType = CONTENT_TYPE.TASK;
      listMyTasksOpts.required = true;
    }
  } else if (whosData === WHO.MANAGED_BY_ME) {
    if (calendarDataType === DATA_TYPE.PROJECTS) {
      listMyJrnsOpts.required = true;
    } else if (calendarDataType === DATA_TYPE.SHIFTS) {
      getAdminContentByTypeOpts.required = true;
      getAdminContentByTypeOpts.contentType = CONTENT_TYPE.SHIFT;
    } else if (calendarDataType === DATA_TYPE.TASKS) {
      getAdminContentByTypeOpts.required = true;
      getAdminContentByTypeOpts.contentType = CONTENT_TYPE.TASK;
    }
  } else if (whosData === WHO.COMPANY) {
    if (calendarDataType === DATA_TYPE.PROJECTS) {
      listCompanyProjectsOpts.required = true;
    } else if (calendarDataType === DATA_TYPE.SHIFTS) {
      getCompanyAdminContent.required = true;
      getCompanyAdminContent.contentType = CONTENT_TYPE.SHIFT;
    } else if (calendarDataType === DATA_TYPE.TASKS) {
      getCompanyAdminContent.required = true;
      getCompanyAdminContent.contentType = CONTENT_TYPE.TASK;
    }
  }

  const { data: companyCrewList } = useQuery(GetCompanyCrew, {
    variables: {
      companyId: managingCompanyInfo?.managingCompanyId,
    },
    skip: !managingCompanyInfo?.managingCompanyId,
  });

  const {
    loading: listMyProjectsForCalendarContextLoading,
    data: listMyProjectsForCalendarContextData,
  } = useQuery(ListMyProjectsForCalendarContext, {
    variables: {
      contentType: listMyProjectsForCalendarContextOpts.contentType,
    },
    skip: !listMyProjectsForCalendarContextOpts.required,
  });

  const { loading: listMyJrnsLoading, data: listMyJrnsData } = useQuery(
    ListMyJrns,
    {
      variables: { userId: EMPTY_USERID },
      skip: !listMyJrnsOpts.required,
    }
  );

  const { loading: listMyShiftsLoading, data: listMyShiftsData } = useQuery(
    ListMyShifts,
    {
      variables: { userId: EMPTY_USERID },
      skip: !listMyShiftsOpts.required,
    }
  );

  const { loading: listMyTasksLoading, data: listMyTasksData } = useQuery(
    ListMyTasks,
    {
      variables: { userId: EMPTY_USERID },
      skip: !listMyTasksOpts.required,
    }
  );

  const {
    loading: getAdminContentByTypeLoading,
    data: getAdminContentByTypeData,
  } = useQuery(GetAdminContentByType, {
    variables: {
      contentType: getAdminContentByTypeOpts.contentType,
    },
    skip: !getAdminContentByTypeOpts.required,
  });

  const {
    loading: listCompanyProjectsLoading,
    data: listCompanyProjectsData,
  } = useQuery(ListCompanyProjects, {
    variables: {
      companyId: managingCompanyInfo?.managingCompanyId,
    },
    skip: !listCompanyProjectsOpts.required,
  });

  const {
    loading: getCompanyAdminContentLoading,
    data: getCompanyAdminContentData,
  } = useQuery(GetCompanyAdminContent, {
    variables: {
      companyId: managingCompanyInfo?.managingCompanyId,
      contentType: getCompanyAdminContent.contentType,
    },
    skip: !getCompanyAdminContent.required,
  });

  const userMap = useMemo(() => {
    const userMapToReturn = {};

    const users = _.get(companyCrewList, 'getCompanyCrew.items', []);

    _.forEach(users, user => {
      userMapToReturn[user.userId] = user;
    });

    return userMapToReturn;
  }, [companyCrewList]);

  const { isLoading, dataToUse, dataWarning, projectsToUse } = useMemo(() => {
    let isLoadingToSet = false;
    let dataToUseToSet = null;
    let dataWarningToSet = null;
    let projectsToUseToSet = null;

    // #region My things
    if (whosData === WHO.MINE) {
      // this should be projects and subprojects because will be used for project map needing subprojects

      const myProjectsForCalendarContext = _.get(
        listMyProjectsForCalendarContextData,
        'listMyProjectsForCalendarContext.items',
        null
      );

      if (calendarDataType === DATA_TYPE.PROJECTS) {
        // MY PROJECTS
        const myJrns = _.get(listMyJrnsData, 'listMyJrns.items', null);
        // this should be only the top level projects
        dataToUseToSet = myJrns;
        projectsToUseToSet = myJrns;

        isLoadingToSet = listMyJrnsLoading;
      } else if (calendarDataType === DATA_TYPE.SHIFTS) {
        // MY SHIFTS
        const myShifts = _.get(listMyShiftsData, 'listMyShifts.items', null);

        isLoadingToSet =
          listMyProjectsForCalendarContextLoading || listMyShiftsLoading;
        dataToUseToSet = myShifts;
        projectsToUseToSet = myProjectsForCalendarContext;
      } else if (calendarDataType === DATA_TYPE.TASKS) {
        // MY TASKS
        const myTasks = _.get(listMyTasksData, 'listMyTasks.items', null);

        isLoadingToSet =
          listMyProjectsForCalendarContextLoading || listMyTasksLoading;
        dataToUseToSet = myTasks;
        projectsToUseToSet = myProjectsForCalendarContext;
      }
    }
    // #endregion
    // #region Managed things
    else if (whosData === WHO.MANAGED_BY_ME) {
      if (calendarDataType === DATA_TYPE.PROJECTS) {
        // MY MANAGED PROJECTS
        const myJrns = _.get(listMyJrnsData, 'listMyJrns.items', null);
        isLoadingToSet = listMyJrnsLoading;
        dataToUseToSet = myJrns;
        projectsToUseToSet = myJrns;
      } else if (
        calendarDataType === DATA_TYPE.SHIFTS ||
        calendarDataType === DATA_TYPE.TASKS
      ) {
        // MY MANAGED SHIFTS OR TASKS
        const adminProjectsToUse = _.get(
          getAdminContentByTypeData,
          'getAdminContentByType.projects',
          null
        );
        const adminItemsToUse = _.get(
          getAdminContentByTypeData,
          'getAdminContentByType.items',
          null
        );

        isLoadingToSet = getAdminContentByTypeLoading;
        dataToUseToSet = adminItemsToUse;
        projectsToUseToSet = adminProjectsToUse;
      }
    }
    // #endregion
    // #region Company things
    else if (whosData === WHO.COMPANY) {
      if (calendarDataType === DATA_TYPE.PROJECTS) {
        // COMPANY PROJECTS

        // WATCH OUT FOR THIS: this query could get too large
        const companyProjects = _.get(
          listCompanyProjectsData,
          'listCompanyProjects.items',
          null
        );

        isLoadingToSet = listCompanyProjectsLoading;
        dataToUseToSet = companyProjects;
        projectsToUseToSet = companyProjects;
      } else if (
        calendarDataType === DATA_TYPE.SHIFTS ||
        calendarDataType === DATA_TYPE.TASKS
      ) {
        // COMPANY MANAGED SHIFTS OR TASKS

        // WATCH OUT FOR THIS: this query could get too large
        const companyProjectsToUse = _.get(
          getCompanyAdminContentData,
          'getCompanyAdminContent.projects',
          null
        );
        const companyItemsToUse = _.get(
          getCompanyAdminContentData,
          'getCompanyAdminContent.items',
          null
        );
        // Report if over data limit
        const responseQueryNote = _.get(
          getCompanyAdminContentData,
          'getCompanyAdminContent.queryNote',
          null
        );

        dataToUseToSet = companyItemsToUse;
        dataWarningToSet = responseQueryNote;
        projectsToUseToSet = companyProjectsToUse;
        isLoadingToSet = getCompanyAdminContentLoading;
      }
    }

    return {
      isLoading: isLoadingToSet,
      dataToUse: dataToUseToSet,
      dataWarning: dataWarningToSet,
      projectsToUse: projectsToUseToSet,
    };
  }, [
    whosData,
    calendarDataType,
    // ListMyJrns
    listMyJrnsData,
    listMyJrnsLoading,
    // ListMyProjectsForCalendarContext
    listMyProjectsForCalendarContextData,
    listMyProjectsForCalendarContextLoading,
    // ListMyShifts
    listMyShiftsData,
    listMyShiftsLoading,
    // ListMyTasks
    listMyTasksData,
    listMyTasksLoading,
    // ListAdminContentByType
    getAdminContentByTypeData,
    getAdminContentByTypeLoading,
    // ListCompanyProjects
    listCompanyProjectsData,
    listCompanyProjectsLoading,
    // ListCompanyAdminContent
    getCompanyAdminContentData,
    getCompanyAdminContentLoading,
  ]);

  const { projectMap, projectColorMap } = useMemo(() => {
    const projectMapToSet = {};
    const projectColorMapToSet = {};
    if (projectsToUse) {
      // Generate project map
      _.forEach(projectsToUse, jrn => {
        projectMapToSet[jrn.contentId] = jrn;
      });

      // Create the project-color map
      const numberOfColorOptions = colorsGoodForWhiteText.length;
      _.forEach(projectsToUse, (projectInfo, i) => {
        projectColorMapToSet[projectInfo.contentId] =
          colorsGoodForWhiteText[i % numberOfColorOptions];
      });
    }

    return {
      projectMap: projectMapToSet,
      projectColorMap: projectColorMapToSet,
    };
  }, [projectsToUse]);

  const contextValue = useMemo(() => {
    // Generate calendar data
    let calendarData = [];
    if (dataToUse && calendarDataType === DATA_TYPE.SHIFTS) {
      calendarData = ShiftTools.spreadShiftRanges(dataToUse);
    } else {
      calendarData = dataToUse;
    }

    return {
      whosData,
      calendarDataType,
      calendarData,
      calendarDataLoading: isLoading,
      calendarDataWarning: dataWarning,
      projectMap,
      projectColorMap,
      userMap,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoading,
    dataToUse,
    dataWarning,
    projectsToUse,
    projectColorMap,
    projectMap,
    userMap,
  ]);

  return (
    <CalendarDataContext.Provider value={contextValue}>
      {children}
    </CalendarDataContext.Provider>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(CalendarDataProvider);
