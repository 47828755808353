import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useLocation } from 'react-router-dom';

import constructionSign from '../../assets/images/construction-sign@4x.png';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: 100,
    textAlign: 'center',
  },
}));

const NoCompanyAccess = () => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h1">You do not have access</Typography>
            <br />
            <br />
            <Typography variant="h4" gutterBottom>
              This account has been removed from the company by a company
              administrator.
            </Typography>
            <Typography variant="h4">
              If you believe this was a mistake, please contact your company
              administrator to be re-added.
            </Typography>
            <br />
            <br />
            <img alt="construction sign" src={constructionSign} />
            <Typography variant="h4" style={{ marginTop: 64 }}>
              If you think this is an error, please{' '}
              <a href={location?.state?.linkRedirect || '/'}>
                click here to try again
              </a>
              .
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NoCompanyAccess;
