import React, { useState } from 'react';
import { Grid, Typography, Tooltip } from '@material-ui/core';
import { Warning as WarningIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import styles from './dashboard.styles';
import palette from '../../theme/palette';

import AccessLimitedToInfo from '../access-limited-to-info/access-limited-to-info';

const useStyles = makeStyles(styles);

const DashboardControlBar = ({
  title,
  subtitle,
  children,
  warning = null,
  hideAccessExplainer,
  hideBottomBorder = false,
  titleVariant = 'h3',
}) => {
  const classes = useStyles();

  const [showWarningComponent, setShowWarningComponent] = useState(false);

  return (
    <>
      <div className={classes.controlBar}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.controlBarInner}
          style={{
            ...(hideBottomBorder && {
              borderBottom: '1px transparent',
            }),
          }}
        >
          <Grid item>
            <Grid container direction="column" style={{ flexWrap: 'nowrap' }}>
              <Grid container item alignItems="center">
                {typeof title === 'string' ? (
                  <Typography variant={titleVariant}>{title}</Typography>
                ) : (
                  title
                )}
                {warning && warning.title && (
                  <Tooltip title={warning.title}>
                    <WarningIcon
                      fontSize="small"
                      style={{
                        marginLeft: 15,
                        cursor: 'pointer',
                        color: palette.brandColorOrange,
                      }}
                      onClick={() => setShowWarningComponent(true)}
                    />
                  </Tooltip>
                )}
                {!!subtitle && (
                  <Grid item xs={12}>
                    {subtitle}
                  </Grid>
                )}
              </Grid>
              {!hideAccessExplainer && (
                <AccessLimitedToInfo companyOwner companyBookkeeper />
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems="center"
              spacing={1}
              style={{ flexWrap: 'nowrap' }}
            >
              {children}
            </Grid>
          </Grid>
        </Grid>
      </div>
      {showWarningComponent && (
        <warning.component
          {...warning.componentProps}
          handleClose={() => setShowWarningComponent(false)}
        />
      )}
    </>
  );
};

export default DashboardControlBar;
