import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Budget}
  ${Fragment.Stat}
  query GetAdminContentByType($contentType: String!) {
    getAdminContentByType(contentType: $contentType) {
      items {
        customerId
        contentId
        jrnId
        creatorId
        address
        type
        subtype
        permissionsFrom
        requireAdmin
        completionType
        completionInfo {
          instructions
          completedById
          completedByDate
          textResponse
          mediaInfo {
            uri
            aspectRatio
          }
        }
        contentUrl
        date
        dateCreated
        description
        labels
        latitude
        longitude
        isPublic
        synced
        title
        endDate
        startDate
        addedBy
        assignedTo
        contentStatus
        priority
        recurrence {
          rangeId
          rangeStartDate
          rangeEndDate
          daysOfTheWeek
        }
        timetrackingAnswers {
          questionId
          answer
        }
        amount {
          value
        }
        workflowStage
        workflowStageReason
        # REMOVEME: remove this when "bugets" field is deprecated
        budgets {
          ...BudgetFields
        }
        stats {
          ...StatFields
        }
        includeSubsInStats
        excludeFromStats
        rfiIds
      }
      projects {
        address
        contentId
        jrnId
        title
        creatorId
        contentUrl
        description
        endDate
        privacyLevel
        inviteCode
        allowedToEdit
        allowedToAdd
        allowedToView
        usersEverOnJrn
        startDate
        synced
        labels
        latitude
        longitude
        phoneNumber
        phoneNumberActive
        type
        lastMessageSent
        useTimetracking
        useTimetrackingQuestions
        timetrackingQuestions
        timetrackingQuestionsCheckout
        billable
        fromTemplate
        timetrackingType
        workflowStage
        workflowStageReason
        # REMOVEME: remove this when "bugets" field is deprecated
        budgets {
          ...BudgetFields
        }
        stats {
          ...StatFields
        }
        includeSubsInStats
        excludeFromStats
      }
      users {
        userId
        username
        firstName
        lastName
        profilePic
        accessMode
        userType
      }
      timetrackingQuestions {
        questionId
        questionType
        questionText
        answers
        isRequired
        managingCompanyId
        creatorId
      }
    }
  }
`;
