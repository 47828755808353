import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Relation}
  query GetRelationsByType(
    $companyId: String!
    $type: RelationType!
    $isArchived: Boolean
  ) {
    getRelationsByType(
      input: { companyId: $companyId, type: $type, isArchived: $isArchived }
    ) {
      items {
        ...RelationFields
      }
    }
  }
`;
