import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { compose } from 'react-apollo';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Checkbox,
  Select,
  MenuItem,
  Grid,
  Typography,
  CircularProgress,
  Button,
} from '@material-ui/core';

import LoadingCover from '../LoadingCover/loadingCover';
import BasicDialog from '../basic-dialog/basic-dialog';
import {
  PRODUCTION_COST_TYPE,
  PROJECT_COST_TYPE,
} from '../../config/appDefaults';
import {
  UpdateCompanyAccountAction,
  MarkAllExpenseAccountsApprovedAction,
} from '../../graphql/graphql';
import RowCircleSelect from '../row-circle-select';

const AccountMappingSettings = ({
  expenseAccounts,
  onUpdateCompanyAccount,
  onMarkAllExpenseAccountsApproved,
  managingCompanyInfo,
  setRequireRefresh,
}) => {
  const [expenseAccountMap, setExpenseAccountMap] = useState(null);
  const [showUnapprovedOnly, setShowUnapprovedOnly] = useState(false);

  const [showLoadingCover, setShowLoadingCover] = useState(false);
  const [
    showApproveAllConfirmaDialog,
    setShowAppproveAllConfirmDialog,
  ] = useState(false);

  useEffect(() => {
    // If uninitialized, then generate map
    if (expenseAccounts && !expenseAccountMap) {
      const newAccountMap = {};
      _.forEach(expenseAccounts, expenseAccount => {
        newAccountMap[expenseAccount.accountId] = expenseAccount;
      });

      setExpenseAccountMap(newAccountMap);
    } else if (expenseAccounts) {
      const updatedAccountMap = _.cloneDeep(expenseAccountMap);

      _.forEach(expenseAccounts, expenseAccount => {
        const mappedAccount = updatedAccountMap[expenseAccount.accountId];
        if (!mappedAccount || !mappedAccount.loading) {
          // If new or not altered, overwrite with the incoming value
          updatedAccountMap[expenseAccount.accountId] = expenseAccount;
        } else {
          // If altered, waiting until the incoming definition matches the expected before stopping the load
          const updatedMappedAccount = { ...mappedAccount };
          delete updatedMappedAccount.loading;

          if (_.isEqual(updatedMappedAccount, expenseAccount)) {
            updatedAccountMap[expenseAccount.accountId] = expenseAccount;
          }
        }
      });

      setExpenseAccountMap(updatedAccountMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseAccounts]);

  const rows = [];
  let noOfUnapprovedAccounts = 0;

  if (expenseAccountMap) {
    rows.push(
      ..._.map(expenseAccountMap, account => {
        if (!account.isApproved) {
          noOfUnapprovedAccounts += 1;
        }
        return account;
      })
        .sort((a, b) => (a.accountName > b.accountName ? 1 : -1))
        .filter(({ isApproved }) => {
          if (showUnapprovedOnly) {
            return !isApproved;
          }
          return true;
        })
        .map(
          ({
            accountId,
            accountName,
            accountType,
            projectCostType,
            productionCostType,
            laborBurden,
            isApproved,
            loading = false,
          }) => ({
            accountId,
            accountType,
            displayName: accountName.split(':').join(' > '),
            projectCostType,
            productionCostType,
            laborBurden,
            isApproved,
            loading,
          })
        )
    );
  }

  if (!expenseAccounts) {
    return <LoadingCover />;
  }

  const updateCompanyAccount = updateCompanyAccountInput => {
    const { accountId, ...paramsForUpdate } = updateCompanyAccountInput;

    const updatedAccountMap = _.cloneDeep(expenseAccountMap);
    const updatedAccount = {
      ...updatedAccountMap[accountId],
      ...paramsForUpdate,
    };

    if (!_.isEqual(updatedAccount, updatedAccountMap[accountId])) {
      updatedAccount.loading = true;
      updatedAccountMap[accountId] = updatedAccount;
      setExpenseAccountMap(updatedAccountMap);
    }

    setRequireRefresh(true);
    onUpdateCompanyAccount(updateCompanyAccountInput);
  };

  const handleApproveAllAccounts = async () => {
    setShowAppproveAllConfirmDialog(false);
    setShowLoadingCover(true);
    await onMarkAllExpenseAccountsApproved();
    setShowLoadingCover(false);
  };

  const accountProjectCostType = (accountId, projectCostType) => {
    return (
      <Select
        value={projectCostType}
        onChange={event => {
          const inputValue = event.target.value;

          updateCompanyAccount({
            accountId,
            projectCostType: inputValue,
          });
        }}
        style={{
          width: '100%',
        }}
      >
        {_.map(PROJECT_COST_TYPE, ({ value, displayValue }) => (
          <MenuItem key={value} value={value}>
            {displayValue}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const accountProductionCostType = (accountId, productionCostType) => {
    return (
      <Select
        value={productionCostType}
        onChange={event => {
          const inputValue = event.target.value;

          updateCompanyAccount({
            accountId,
            productionCostType: inputValue,
          });
        }}
        style={{
          width: '100%',
        }}
      >
        {_.map(PRODUCTION_COST_TYPE, ({ value, displayValue }) => (
          <MenuItem key={value} value={value}>
            {displayValue}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const accountLaborBurden = (accountId, laborBurden) => {
    return (
      <Checkbox
        checked={laborBurden}
        onChange={() =>
          updateCompanyAccount({
            accountId,
            laborBurden: !laborBurden,
          })
        }
        name={`labour-burden-${accountId}`}
        color="primary"
      />
    );
  };

  const handleApprovalSelect = ({
    target: accountId,
    isActive: isApproved,
  }) => {
    updateCompanyAccount({
      accountId,
      isApproved,
    });
  };

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="body1">
            <b>Account Mapping</b>
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item style={{ flex: 1 }}>
              <Typography variant="body1">
                Your Cost of Goods Sold (COGS) and Expense accounts from
                QuickBooks Online must be mapped to their respective cost types
                to accurately generate your financial reports. By default, we
                have mapped any COGS account to &quot;Variable&quot; and any
                Expense account to &quot;Fixed&quot;. Costs that fluctuate with
                your production levels should be marked as &quot;Variable&quot;
                (e.g. crew member wages, material costs and fuel costs), while
                costs that don’t change based on your production levels (e.g.
                salaries, rent, subscriptions, etc) should be marked as
                &quot;Fixed&quot;.
              </Typography>
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowUnapprovedOnly(!showUnapprovedOnly)}
                    style={{ width: 275 }}
                  >
                    {showUnapprovedOnly
                      ? 'Show all accounts'
                      : 'Show unapproved accounts'}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setShowAppproveAllConfirmDialog(true);
                    }}
                    style={{ width: 275 }}
                    disabled={noOfUnapprovedAccounts === 0}
                  >
                    Approve all accounts
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Table size="small" aria-label="Progress report chart">
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="column">
                  Account
                </TableCell>
                <TableCell component="th" scope="column">
                  Category
                </TableCell>
                <TableCell component="th" scope="column">
                  Break-even Cost Type
                </TableCell>
                {managingCompanyInfo &&
                  managingCompanyInfo.isCompanyBookkeeper && (
                    <>
                      <TableCell component="th" scope="column">
                        Project Cost Type
                      </TableCell>
                      <TableCell component="th" scope="column" align="center">
                        Labor Burden
                      </TableCell>
                    </>
                  )}
                <TableCell component="th" scope="column" align="center">
                  Is Approved?
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(
                ({
                  accountId,
                  accountType,
                  displayName,
                  projectCostType,
                  productionCostType,
                  laborBurden,
                  isApproved,
                  loading,
                }) => (
                  <TableRow key={accountId}>
                    <TableCell component="th" scope="row">
                      {displayName}
                      {loading && (
                        <CircularProgress
                          size={16}
                          style={{ marginLeft: 8, marginBottom: -2 }}
                        />
                      )}
                    </TableCell>
                    <TableCell>{accountType}</TableCell>
                    <TableCell>
                      {accountProductionCostType(accountId, productionCostType)}
                    </TableCell>
                    {managingCompanyInfo &&
                      managingCompanyInfo.isCompanyBookkeeper && (
                        <>
                          <TableCell>
                            {accountProjectCostType(accountId, projectCostType)}
                          </TableCell>
                          <TableCell align="center">
                            {accountLaborBurden(accountId, laborBurden)}
                          </TableCell>
                        </>
                      )}
                    <TableCell>
                      <RowCircleSelect
                        target={accountId}
                        isActive={isApproved}
                        onSelect={handleApprovalSelect}
                      />
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <BasicDialog
        open={showApproveAllConfirmaDialog}
        title="Confirmation"
        buttonText="Yes"
        secondaryButtonText="Cancel"
        handleClose={handleApproveAllAccounts}
        handleSecondaryAction={() => {
          setShowAppproveAllConfirmDialog(false);
        }}
      >
        <span>Are you sure to mark all accounts as approved?</span>
      </BasicDialog>
      {showLoadingCover && <LoadingCover customStyles={{ zIndex: 1 }} />}
    </>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(
  compose(
    UpdateCompanyAccountAction,
    MarkAllExpenseAccountsApprovedAction
  )(AccountMappingSettings)
);
