import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Button, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ExtensionIcon from '@material-ui/icons/Extension';
import TemplateSelector from '../../components/template-selector';
import ImportProjectFromQbo from '../../components/import-project-from-qbo';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: theme.spacing(2),
    minHeight: '50vh',
  },
  paper: {
    padding: theme.spacing(5),
    textAlign: 'center',
    color: theme.palette.brandColorPrimary,
  },
  selectContentWrapper: {},
  selectContentTypeLink: {
    border: 0,
    backgroundColor: 'transparent',
    padding: theme.spacing(3),
  },
  iconWrapper: {
    fontSize: 70,
  },
}));

const PROJECT_DECIDER_MODE = {
  TEMPLATE: 'TEMPLATE',
  IMPORT_FROM_QBO: 'IMPORT_FROM_QBO',
};

const NewProjectDecider = ({ passBack, managingCompanyInfo, handleClose }) => {
  const classes = useStyles();
  const [whatToShow, setWhatToShow] = useState(null);

  const handleTemplateSelection = templateData => {
    passBack({
      type: 'createFromTemplate',
      whichTemplate: templateData,
    });
  };

  const renderButtons = () => {
    const buttons = [
      {
        label: 'From Template',
        icon: <ExtensionIcon fontSize="inherit" />,
        onClick: () => {
          // if template was chosen, then show them a list of templates to choose from
          setWhatToShow(PROJECT_DECIDER_MODE.TEMPLATE);
        },
      },
      {
        label: 'Blank Project',
        icon: <AddCircleOutlineIcon fontSize="inherit" />,
        onClick: () => {
          passBack({ type: 'createFromScratch' });
        },
      },
    ];
    return (
      <>
        <Grid container item xs={12} direction="column">
          <Grid item>
            <Typography variant="h5">Create new project from:</Typography>
          </Grid>
        </Grid>
        <Grid item container justifyContent="center">
          {buttons.map(buttonInfo => {
            return (
              <Grid key={buttonInfo.label} item>
                <Button
                  className={classes.selectContentTypeLink}
                  onClick={buttonInfo.onClick}
                >
                  <Paper className={classes.paper}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      className={classes.selectContentWrapper}
                    >
                      <Grid item className={classes.iconWrapper}>
                        {buttonInfo.icon}
                      </Grid>
                      <Grid item>{buttonInfo.label}</Grid>
                    </Grid>
                  </Paper>
                </Button>
              </Grid>
            );
          })}
        </Grid>
        {managingCompanyInfo && managingCompanyInfo.isCustomerSyncEnabled && (
          <Grid container item justifyContent="flex-end">
            <Button
              color="primary"
              variant="text"
              onClick={() =>
                setWhatToShow(PROJECT_DECIDER_MODE.IMPORT_FROM_QBO)
              }
            >
              Import from QuickBooks Online
            </Button>
          </Grid>
        )}
      </>
    );
  };

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item xs={12} container justifyContent="center">
        {!whatToShow && renderButtons()}
        {whatToShow === PROJECT_DECIDER_MODE.TEMPLATE && (
          <>
            <Grid item>
              <Typography variant="h3" color="primary">
                Which template would you like to use?
              </Typography>
            </Grid>
            <TemplateSelector handleSelection={handleTemplateSelection} />
          </>
        )}
        {whatToShow === PROJECT_DECIDER_MODE.IMPORT_FROM_QBO && (
          <ImportProjectFromQbo handleClose={handleClose} />
        )}
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
  };
}

export default connect(mapStateToProps)(NewProjectDecider);
