import React, { Component } from 'react';
import pell, { exec } from 'pell';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import 'pell/dist/pell.min.css';
import './level-pell-customizations.scss';
import { validateUrl, saveSelection, restoreSelection } from '../../../helpers';

// pass pell object to custom actions callback
const mapActions = actions => {
  if (actions) {
    return actions.map(e => {
      if (typeof e === 'object' && e.result) {
        return { ...e, result: () => e.result(pell) };
      }
      return e;
    });
  }
  return actions;
};

// const defaults = {
//   defaultContent: '',
//   styleWithCSS: false,
//   actions: [
//     'bold',
//     'italic',
//     'underline',
//     'strikethrough',
//     // 'heading1',
//     // 'heading2',
//     'olist',
//     'ulist',
//     'line',
//     // 'link',
//     {
//       name: 'link',
//       result: () => {
//         const url = window.prompt('Enter the link URL');
//         if (url) exec('createLink', url);
//       },
//     },

//     // {
//     //   name: 'backColor',
//     //   icon: `<div style="background-color:yellow;height: 100%;width: 100%;display: flex;align-items: center;justify-content: center;">
//     //             A
//     //         </div>`,
//     //   title: 'Highlight Color',
//     //   result: () => exec('backColor', 'yellow'),
//     // },
//     // {
//     //   name: 'undo',
//     //   icon: '<div>Undo</div>',
//     //   title: 'Undo',
//     //   result: () => exec('undo'),
//     // },
//     // {
//     //   name: 'redo',
//     //   icon: '<div>redo</div>',
//     //   title: 'Redo',
//     //   result: () => exec('redo'),
//     // },
//   ],
//   containerClass: 'pell-container',
//   actionBarClass: 'pell-actionbar',
//   buttonClass: 'pell-button',
//   contentClass: 'pell-content',
// };

const getSelectedParagraphText = () => {
  let selection;
  if (window.getSelection) {
    selection = window.getSelection();
  } else if (document.selection) {
    selection = document.selection.createRange();
  }
  let parent = selection.anchorNode;
  while (parent != null && parent.localName !== 'a') {
    parent = parent.parentNode;
  }
  if (parent == null) {
    return '';
  }
  return parent.getAttribute('href');
};

class LevelPellEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlAttempt: '',
      openUrlModal: false,
      validUrl: null,
    };
    this.defaults = {
      defaultContent: '',
      styleWithCSS: false,
      actions: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        // 'heading1',
        // 'heading2',
        'olist',
        'ulist',
        'line',
        // 'link',
        {
          name: 'link',
          // result: this.urlEntryFlow,
          // result: () => {
          //   const url = window.prompt('Enter the link URL');
          //   if (url) exec('createLink', url);
          // },
          result: () => {
            const link = getSelectedParagraphText() || '';
            const whatToDo = (wording, passedLink) => {
              // eslint-disable-next-line no-alert
              const url = window.prompt(wording, passedLink);
              if (url !== null) {
                const isValid = validateUrl(url);
                if (isValid) {
                  const text = window.getSelection() || url;
                  exec(
                    'insertHTML',
                    `<a href="${url}" target="_blank">${text}</a>`
                  );
                } else {
                  whatToDo('Invalide link. Enter a valid URL:', url);
                }
              }
            };
            whatToDo('Enter a URL (including the "http" part):', link);
          },
        },
        // {
        //   name: 'removeFormat',
        //   icon: '&#128247;',
        //   result: () => {
        //     console.log('remove formatting');
        //     exec('removeFormat');
        //   },
        // },

        // {
        //   name: 'backColor',
        //   icon: `<div style="background-color:yellow;height: 100%;width: 100%;display: flex;align-items: center;justify-content: center;">
        //             A
        //         </div>`,
        //   title: 'Highlight Color',
        //   result: () => exec('backColor', 'yellow'),
        // },
        // {
        //   name: 'undo',
        //   icon: '<div>Undo</div>',
        //   title: 'Undo',
        //   result: () => exec('undo'),
        // },
        // {
        //   name: 'redo',
        //   icon: '<div>redo</div>',
        //   title: 'Redo',
        //   result: () => exec('redo'),
        // },
      ],
      containerClass: 'pell-container',
      actionBarClass: 'pell-actionbar',
      buttonClass: 'pell-button',
      contentClass: 'pell-content',
      disabled: false,
    };
  }

  componentDidMount() {
    const {
      onChange,
      actions = this.defaults.actions,
      styleWithCSS = this.defaults.styleWithCSS,
      actionBarClass = this.defaults.actionBarClass,
      buttonClass = this.defaults.buttonClass,
      contentClass = this.defaults.contentClass,
      defaultContent = this.defaults.defaultContent,
      disabled = this.defaults.disabled,
    } = this.props;

    if (!disabled) {
      // initialize pell editor
      pell.init({
        element: this.container,
        // onChange: html => onChange(html),
        onChange,
        actions: mapActions(actions),
        styleWithCSS,
        classes: {
          actionbar: actionBarClass,
          button: buttonClass,
          content: contentClass,
        },
      });

      // set default content
      this.container.content.innerHTML = defaultContent;
    }
  }

  componentDidUpdate() {
    const { defaultContent, disabled = this.defaults.disabled } = this.props;
    if (!disabled) {
      if (
        this.container.content &&
        this.container.content.innerHTML !== defaultContent
      ) {
        this.container.content.innerHTML = defaultContent;
      }
    }
  }

  handleClickOpen = () => {
    this.setState({ openUrlModal: true });
  };

  handleClose = () => {
    this.setState({ openUrlModal: false });
  };

  validateAnd = () => {
    const { urlAttempt } = this.state;
    const isValidUrl = validateUrl(urlAttempt);
    if (isValidUrl) {
      const { currentSelection } = this.state;
      restoreSelection(currentSelection);
      exec('createLink', urlAttempt);
      this.setState({ validUrl: true });
      this.handleClose();
    } else {
      this.setState({ validUrl: false });
    }
  };

  urlEntryFlow = () => {
    const currentSelection = saveSelection();
    this.setState({ currentSelection });
    this.handleClickOpen();
  };

  handleChange = e => {
    const str = e.target.value;
    const isValidUrl = validateUrl(str);
    this.setState({ validUrl: isValidUrl, urlAttempt: str });
  };

  // return the editor content
  getContent = () => this.container.content.innerHTML;

  render() {
    const {
      containerClass = this.defaults.containerClass,
      defaultContent = this.defaults.defaultContent,
      disabled = this.defaults.disabled,
      height,
    } = this.props;
    const { openUrlModal, handleClose, validUrl, urlAttempt } = this.state;

    if (disabled) {
      return (
        <div
          style={{
            padding: 10,
            height: height || 300,
            opacity: 0.38,
            overflow: 'auto',
          }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: defaultContent }}
        />
      );
    }

    return (
      <>
        <div
          ref={e => {
            this.container = e;
          }}
          className={containerClass}
          style={{ height: height || undefined }}
        />
        <Dialog
          open={openUrlModal}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Create A Link</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter a web address below. Please ensure you have the entire URL
              including the &quot;http&quot; part.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="url"
              label="Web Address"
              type="text"
              fullWidth
              value={urlAttempt}
              onChange={this.handleChange}
              error={typeof validUrl === 'boolean' ? validUrl : true}
              helperText={validUrl ? null : 'Invalid link'}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.validateAnd} color="primary">
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default LevelPellEditor;
