import React from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';
import { getQboSyncState, QBO_SYNC_STATE } from '../../helpers/qbo-sync';
import palette from '../../theme/palette';
import { useQboStatusUpdateChecker } from '../../hooks';
import QboIconImage from '../../assets/images/quickbooks/qb-logo-icon-button.svg';

/**
 * @param {Object} props
 * @param {Object} props.recordInfo Customer, lead or project object
 * @param {String} props.recordType
 * @param {'badge' | 'inline'} props.mode Icon type to use
 * @param {Object} props.managingCompanyInfo From redux
 * @returns {React.ReactElement | null}
 */
function QboSyncStatusIcon({
  recordInfo,
  recordType,
  mode = 'inline',
  managingCompanyInfo,
}) {
  useQboStatusUpdateChecker({ recordInfo, recordType });

  if (
    !(
      managingCompanyInfo &&
      managingCompanyInfo.isCompanyAdmin &&
      managingCompanyInfo.isCustomerSyncEnabled
    )
  ) {
    return null;
  }

  const qboSyncState = getQboSyncState(recordInfo);

  if (qboSyncState === QBO_SYNC_STATE.ACTIVE) {
    if (mode === 'badge') {
      return (
        <img
          alt="QuickBooks Online Logo"
          src={QboIconImage}
          width={22}
          style={{
            position: 'absolute',
            marginLeft: 6,
            bottom: 12,
            right: 12,
            borderStyle: 'solid',
            borderColor: 'white',
            borderRadius: 11,
            borderWidth: 2,
          }}
        />
      );
    }

    return (
      <img
        alt="QuickBooks Online Logo"
        src={QboIconImage}
        width={18}
        style={{ marginLeft: 6 }}
      />
    );
  }

  if (qboSyncState === QBO_SYNC_STATE.SYNCING) {
    if (mode === 'badge') {
      return (
        <CircularProgress
          size={22}
          style={{
            position: 'absolute',
            marginLeft: 6,
            bottom: 12,
            right: 12,
          }}
        />
      );
    }

    return <CircularProgress size={18} style={{ marginLeft: 6 }} />;
  }

  if (qboSyncState === QBO_SYNC_STATE.ERROR) {
    if (mode === 'badge') {
      return (
        <ErrorIcon
          style={{
            color: palette.brandColorOrange,
            fontSize: 22,
            position: 'absolute',
            marginLeft: 6,
            bottom: 12,
            right: 12,
            background: 'white',
            borderRadius: 11,
          }}
        />
      );
    }

    return (
      <ErrorIcon
        style={{
          color: palette.brandColorOrange,
          fontSize: 18,
          marginLeft: 6,
        }}
      />
    );
  }

  return null;
}

function mapStateToProps(state) {
  return {
    managingCompanyInfo: state.appState.managingCompanyInfo || null,
  };
}

export default connect(mapStateToProps)(QboSyncStatusIcon);
