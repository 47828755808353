import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import moment from 'moment';
import FormikTimePicker from '../../../components/formik-custom-components/formik-time-picker';
import FormikDatePicker from '../../../components/formik-custom-components/formik-date-picker';
import ConditionalTooltip from '../../../components/ConditionalTooltip/conditionalTooltip';
import {
  PAYMENT_DUE_IN_DAYS,
  PAYMENT_TERMS,
} from './add-content-form.constants';
import { CONTENT_TYPE } from '../../../config/appDefaults';

const DateInputField = ({
  dateLabel,
  timeLabel,
  name,
  timeFieldName,
  values,
  noFuture,
  disabled,
  showTimeInput,
  templateMode,
  setFieldValue,
  isBeforeName,
  isAfterName,
  tooltipText,
}) => {
  const onDateAccept = value => {
    if (isAfterName && moment(value).isBefore(values[isAfterName])) {
      setFieldValue(name, moment(values[isAfterName]));
    }

    if (isBeforeName && templateMode) {
      setFieldValue(
        isBeforeName,
        moment(value).add(values.projectLength.days - 1, 'days')
      );
    } else if (isBeforeName && moment(value).isAfter(values[isBeforeName])) {
      setFieldValue(isBeforeName, moment(value));
    }

    if (
      name === 'startDate' &&
      value &&
      values.type === CONTENT_TYPE.GLOBAL_BILL
    ) {
      setFieldValue('date', moment(value), false);

      if (values.paymentTerms && values.paymentTerms !== PAYMENT_TERMS.OTHER) {
        const daysToAdd = PAYMENT_DUE_IN_DAYS[values.paymentTerms];
        const newEndDate = moment(value).add(daysToAdd, 'days');
        setFieldValue('endDate', newEndDate, false);
      }
    }

    if (
      name === 'date' &&
      value &&
      (values.type === CONTENT_TYPE.GLOBAL_RECEIPT ||
        values.type === CONTENT_TYPE.GLOBAL_PAYMENT)
    ) {
      setFieldValue('startDate', moment(value), false);
      setFieldValue('endDate', moment(value), false);
    }
  };

  useEffect(() => {
    if (name === 'startDate' && values.startDate) {
      onDateAccept(values.startDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.startDate]);

  useEffect(() => {
    if (name === 'date' && values.date) {
      onDateAccept(values.date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.date]);

  const onTimeAccept = value => {
    if (isAfterName && moment(value).isBefore(values[isAfterName])) {
      setFieldValue(name, moment(values[isAfterName]));
    }
  };

  let minDate = null;
  if (isAfterName) {
    minDate = values[isAfterName];
  }

  return (
    <>
      <Grid item xs={12} sm={6}>
        <ConditionalTooltip title={tooltipText} showIf={!!tooltipText}>
          <span>
            <Field
              label={dateLabel}
              name={name}
              value={values[name]}
              component={FormikDatePicker}
              noFuture={noFuture}
              variant="inline"
              fullWidth
              autoOk
              disabled={!!disabled}
              onAccept={onDateAccept}
              {...(minDate ? { minDate } : {})}
            />
          </span>
        </ConditionalTooltip>
      </Grid>
      {showTimeInput && (
        <Grid item xs={12} sm={6}>
          <Field
            label={timeLabel}
            name={name}
            value={values[name]}
            component={FormikTimePicker}
            variant="inline"
            fullWidth
            disabled={!!disabled}
            timeFieldName={timeFieldName}
            onAccept={onTimeAccept}
          />
        </Grid>
      )}
    </>
  );
};

export default DateInputField;
