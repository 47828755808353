import React, { useState, useMemo, useEffect } from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  Tooltip,
  ButtonBase,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Block as BlockIcon,
  Cached as CachedIcon,
  Create as CreateIcon,
  DeleteOutline as DeleteOutlineIcon,
  Equalizer as EqualizerIcon,
  MoreHoriz as MoreHorizIcon,
  Publish as PublishIcon,
  Save as SaveIcon,
} from '@material-ui/icons';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Papa from 'papaparse';

// utilities
import _, { uniqueId } from 'lodash';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

// UI components
import AddLead from '../../../components/add-lead/add-lead';
import OkCancelDialog from '../../../components/OkCancelDialog/okCancelDialog';
import LeadStatsDialog from './lead-stats-dialog';
import ChangeLeadStatusDialog from '../../../components/change-lead-status/change-lead-status-dialog';
import LeadChip from '../../../components/lead-chip/lead-chip';
import AdminToolsIconButton from '../../../components/admin-tools-icon-button/admin-tools-icon-button';
import AdminToolsTitle from '../../../components/admin-tools-title/admin-tools-title';
import ContentDetailsModal from '../../add-to-project/content-details-modal';
import LoadingCover from '../../../components/LoadingCover/loadingCover';
import { useRepetitiveQuery } from '../../../hooks';

// GraphQL
import {
  DeleteJrnAction,
  GetCompanyCustomersAction,
  UpdateJrnMutationAction,
} from '../../../graphql/graphql';
import GetCompanyLeads from '../../../graphql/queries/get-company-leads';
import { createJrnInputMask } from '../../../graphql/masks/create-jrn-input';

// Helpers
import {
  runAnalytics,
  monetaryRender,
  fixedValueRender,
  applyPreferences,
} from '../../../helpers/index';

import {
  amountFilter,
  simpleSortForAmount,
} from '../../../helpers/react-datagrid-helpers';

import {
  BUDGET_TYPE_PAYMENTS,
  BUDGET_TYPE_RECEIPTS_INVOICES,
  BUDGET_TYPE_UNASSIGNED_LABOR_HOURS,
  BUDGET_TYPE_USER_LABOR_HOURS,
  leadStatuses,
  defaultLeadStatus,
  LEAD_STATUS_IN_PROGRESS_ACCEPTED,
  LEAD_STATUS_INITIAL,
  LEAD_STATUS_IN_PROGRESS_PENDING,
  LEAD_STATUS_REJECTED,
  TOP_PROJECT_ID,
  CONTENT_DETAILS_MODAL_MODE,
  CONTENT_DEFINITION,
  CONTENT_TYPE,
  DEFAULT_DATE_FORMAT,
} from '../../../config/appDefaults';
import themePalette from '../../../theme/palette';
import store from '../../../store';

const filterTypes = {
  ...ReactDataGrid.defaultProps.filterTypes,
  ...amountFilter,
};

// Styling
const useStyles = makeStyles(theme => ({
  scrollableColumn: {
    overflowY: 'scroll',
    height: 'calc(100vh - 64px)',
  },
  editButton: {
    padding: theme.spacing(0.5),
    margin: 0,
    minWidth: 0,
    color: '#aaa',
    '&:hover': {
      background: 'transparent',
      color: '#333',
    },
  },
  projectAction: {
    textTransform: 'uppercase',
    fontWeight: 'normal',
    color: '#333',
    '&:visited': {
      color: 'inherit',
    },
  },
  headerWrapper: {
    padding: theme.spacing(3),
  },
  actionButtonsContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    minWidth: 460,
    '& button': {
      marginLeft: theme.spacing(2),
    },
    '& div': {
      marginLeft: theme.spacing(2),
    },
  },
  headerIcon: {
    color: '#999',
    fontSize: 30,
  },
}));

// ReactDataGrid configuration
window.moment = moment;

const basicSortInfo = {
  name: 'startDate',
  dir: -1,
  type: 'date',
};

const levelFilterValues = [
  {
    name: 'projectCreationStatus',
    operator: 'inlist',
    type: 'select',
    value: null,
  },
  {
    name: 'leadName',
    operator: 'inlist',
    type: 'select',
    value: null,
  },
  {
    name: 'customerName',
    operator: 'inlist',
    type: 'select',
    value: null,
  },
  {
    name: 'contentStatus',
    operator: 'inlist',
    type: 'select',
    value: null,
  },
  {
    name: 'startDate',
    operator: 'afterOrOn',
    type: 'date',
    value: '',
  },
  {
    name: 'endDate',
    operator: 'beforeOrOn',
    type: 'date',
    value: '',
  },
  {
    name: 'labels',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'receiptsBillsBudget',
    type: 'amount',
    operator: 'Starts With',
    value: '',
  },
  {
    name: 'paymentsBudget',
    type: 'amount',
    operator: 'Starts With',
    value: '',
  },
  {
    name: 'laborBudget',
    type: 'amount',
    operator: 'Starts With',
    value: '',
  },
];

const scrollProps = {
  ...ReactDataGrid.defaultProps.scrollProps,
  autoHide: false,
  scrollThumbWidth: 12,
  scrollThumbStyle: {
    background: themePalette.brandColorPrimary,
  },
};

const columnWidthButtons = 82;
const columnMinWidthMedium = 180;
const dateTimeFormat = 'MMM D, YYYY';

// Helper functions
const capitalizeSentence = sentence => {
  const words = sentence.split(' ');
  words
    .map(word => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
  return words;
};

const buildColumnObj = options => {
  if (!options.name) return null;
  const basicColumn = {
    ...options,
    header:
      options.header !== undefined
        ? options.header
        : capitalizeSentence(options.name),
  };
  return basicColumn;
};

const downloadBlob = (blob, fileName = 'grid-data.csv') => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.position = 'absolute';
  link.style.visibility = 'hidden';

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

const ManageLeads = ({
  onDeleteJrn,
  userInfo,
  managingCompanyInfo,
  onUpdateJrn,
  // customers
  companyCustomers,
  // navigation
  history,
  columnSettings,
}) => {
  // Style hooks
  const classes = useStyles();

  const getCompanyLeadsAccessor = 'getCompanyLeads';
  const companyLeadsQuery = useRepetitiveQuery(GetCompanyLeads, {
    fetchPolicy: 'cache-and-network',
    skip: !managingCompanyInfo?.managingCompanyId,
    variables: {
      companyId: managingCompanyInfo?.managingCompanyId,
    },
  });

  const getCompanyLeadsLoading = _.get(companyLeadsQuery, 'loading');
  const getCompanyLeadsRefetch = _.get(companyLeadsQuery, 'refetch');
  const { items: companyLeads } = _.get(
    companyLeadsQuery,
    `data.${getCompanyLeadsAccessor}`,
    {}
  );

  // Ref hooks
  const [gridRef, setGridRef] = useState(null);

  // State hooks
  const [entriesStats, setEntriesStats] = useState(null);
  const [statsDialogInfo, setStatsDialogInfo] = useState({ open: false });
  const [showChangeLeadStatusDialog, setShowChangeLeadStatusDialog] = useState({
    open: false,
  });
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    title: '',
    message: '',
    onClose: () => {}, // Set based on context
    hideCancel: false,
    onConfirm: () => {}, // Set based on context
  });
  const [showManualAddDialog, setShowManualAddDialog] = useState({
    open: false,
    mode: 'edit',
    parentInfo: null,
    existingInfo: null,
    fromWhichAdminTool: 'lead',
  });

  const [
    showCreateProjectFromLeadDialog,
    setShowCreateProjectFromLeadDialog,
  ] = useState({
    open: false,
    mode: CONTENT_DETAILS_MODAL_MODE.CREATE_PROJECT_FROM_MANAGE_LEADS,
    parentInfo: null,
    existingInfo: null,
    fromWhichAdminTool: 'lead',
  });

  const [tableReady, setTableReady] = useState(false);
  const [defaultSortInfo, setDefaultSortInfo] = useState(basicSortInfo);
  const [filterValue, setFilterValue] = useState(null);
  const [columnOrder, setColumnOrder] = useState(null);
  const [tableKey, setTableKey] = useState(uniqueId());
  const resetTableKey = () => setTableKey(uniqueId());
  const [columns, setColumns] = useState(null);

  const editThisContent = leadInfo => {
    const customerInfo = _.find(companyCustomers, {
      customerId: leadInfo.customerId,
    });

    setShowManualAddDialog({
      open: true,
      existingInfo: { ...leadInfo, customerInfo },
      customerInfo,
      fromWhichAdminTool: 'lead',
    });
  };

  const showAskToGoToProjectDialog = goToProjectId => {
    setDialogInfo({
      open: true,
      title: 'Project Created!',
      message: 'Would you like to go to the project now?',
      onClose: () => {
        setDialogInfo({ ...dialogInfo, open: false });
      },
      hideCancel: false,
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      onConfirm: () => {
        history.push(`/projects/${goToProjectId}`);
      },
    });
  };

  const handleAfterProjectCreatedFromLead = creationResponse => {
    setShowCreateProjectFromLeadDialog({
      ...showCreateProjectFromLeadDialog,
      open: false,
    });
    if (creationResponse && !creationResponse.cancelled) {
      if (creationResponse.contentId) {
        showAskToGoToProjectDialog(creationResponse.contentId);
      } else {
        const runErrorDialog = passedError => {
          let errorStringed;
          try {
            errorStringed = JSON.stringify(passedError);
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log('JSON.stringify err: ', err);
            errorStringed = '';
          }

          const message = errorStringed.includes('Existing Parent ID')
            ? 'This lead already has a project associated with it. If you believe this was an error, please refresh the page and try again.'
            : 'There was an error creating the project. Please try again.';
          setDialogInfo({
            open: true,
            title: 'Error Creating Project',
            message,
            onClose: () => {
              setDialogInfo({ ...dialogInfo, open: false });
            },
            hideCancel: true,
          });
        };
        runErrorDialog(creationResponse);
      }
    }
  };

  const handleProjectCreation = async leadDetails => {
    const newProjectDetails = createJrnInputMask(leadDetails);
    newProjectDetails.type = 'project';
    newProjectDetails.contentId = uuid();
    if (newProjectDetails.budgets) {
      newProjectDetails.budgets.forEach((answer, budgetIndex) => {
        delete newProjectDetails.budgets[budgetIndex].__typename;
      });
    }
    delete newProjectDetails.contentStatus;
    setShowCreateProjectFromLeadDialog({
      open: true,
      mode: CONTENT_DETAILS_MODAL_MODE.CREATE_PROJECT_FROM_MANAGE_LEADS,
      existingInfo: newProjectDetails,
      leadDetails,
      baseContentInfo: leadDetails,
      hideCustomerOption: true,
    });
  };

  const openAddModal = () => {
    setShowManualAddDialog({ open: true });
  };

  const deleteThisContent = async leadInfo => {
    setDialogInfo({
      title: 'Just making sure...',
      message: `Are you sure you want to delete this lead?`,
      open: true,
      onClose: () => setDialogInfo({ ...dialogInfo, open: false }),
      hideCancel: false,
      okButtonText: 'Yes',
      onConfirm: async () => {
        await onDeleteJrn(leadInfo.contentId, 'lead');
      },
    });
  };

  const handleChipClick = data => {
    setShowChangeLeadStatusDialog({
      open: true,
      contentToChange: data.contentItem,
    });
  };

  const handleContentStatusChange = async newStatus => {
    const toPass = {
      ...showChangeLeadStatusDialog.contentToChange,
      contentStatus: newStatus,
    };
    if (toPass.budgets) {
      toPass.budgets.forEach((answer, budgetIndex) => {
        delete toPass.budgets[budgetIndex].__typename;
      });
    }

    await onUpdateJrn(toPass, { fromWhichAdminTool: 'lead' });
    const doesntHaveProjectYet =
      !toPass.jrnId || toPass.jrnId === TOP_PROJECT_ID;
    if (
      newStatus === LEAD_STATUS_IN_PROGRESS_ACCEPTED &&
      doesntHaveProjectYet
    ) {
      setDialogInfo({
        title: 'Successfully Updated Status',
        message: 'Would you like to create a project from this lead?',
        open: true,
        onClose: () => setDialogInfo({ ...dialogInfo, open: false }),
        hideCancel: false,
        okButtonText: 'Yes',
        cancelButtonText: 'No',
        onConfirm: async () => {
          handleProjectCreation(toPass);
        },
      });
    }
  };

  const compiledLeadNameMap = useMemo(() => {
    if (!companyLeads) {
      return null;
    }
    const leadNameMap = {};
    companyLeads.forEach(lead => {
      const leadName = lead.title;
      leadNameMap[lead.contentId] = leadName;
    });

    return leadNameMap;
  }, [companyLeads]);

  const compiledCustomerNameMap = useMemo(() => {
    if (!companyCustomers) {
      return null;
    }
    const customerNameMap = {};
    companyCustomers.forEach(customer => {
      const nameComponents = [];

      if (customer.firstName || customer.lastName) {
        if (customer.firstName) {
          nameComponents.push(customer.firstName);
        }
        if (customer.lastName) {
          nameComponents.push(customer.lastName);
        }
      } else if (customer.companyName) {
        nameComponents.push(customer.companyName);
      }

      const customerName = nameComponents.join(' ');
      customerNameMap[customer.customerId] = customerName;
    });

    return customerNameMap;
  }, [companyCustomers]);

  const dataSource = useMemo(() => {
    let allLeads = null;

    if (companyLeads && companyCustomers && managingCompanyInfo) {
      allLeads = companyLeads.map(lead => {
        // Format dates
        const startDateMoment = moment(lead.startDate);
        const startDateToUse = startDateMoment.format('YYYY-MM-DDTHH:mm:ssZ');
        const endDateMoment = moment(lead.endDate);
        const endDateToUse = endDateMoment.format('YYYY-MM-DDTHH:mm:ssZ');

        const customerName =
          compiledCustomerNameMap[lead.customerId] || 'Not Available';

        // Format labels
        const labels = lead.labels ? lead.labels.join(', ') : null;

        let receiptsBillsBudget = 0;
        let laborBudget = 0;
        let paymentsBudget = 0;

        if (lead.budgets) {
          lead.budgets.forEach(({ label, value }) => {
            switch (label) {
              case BUDGET_TYPE_UNASSIGNED_LABOR_HOURS: {
                laborBudget += value || 0;
                break;
              }
              case BUDGET_TYPE_USER_LABOR_HOURS: {
                laborBudget += value || 0;
                break;
              }
              case BUDGET_TYPE_RECEIPTS_INVOICES: {
                receiptsBillsBudget = value || 0;
                break;
              }
              case BUDGET_TYPE_PAYMENTS: {
                paymentsBudget = value || 0;
                break;
              }
              default:
                break;
            }
          });
        }

        const projectCreationStatus =
          lead && lead.jrnId && lead.jrnId !== TOP_PROJECT_ID
            ? 'Go To Project'
            : 'Create Project';

        return {
          leadId: lead.contentId,
          customerId: lead.customerId,
          customerName,
          leadName: compiledLeadNameMap[lead.contentId],
          contentStatus: lead.contentStatus || defaultLeadStatus,
          projectCreationStatus,
          startDate: startDateToUse,
          endDate: endDateToUse,
          labels,
          receiptsBillsBudget,
          paymentsBudget,
          laborBudget,
          parentId: lead.jrnId,
          contentItem: lead,
        };
      });
    }
    return allLeads;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companyLeads,
    companyCustomers,
    compiledLeadNameMap,
    compiledCustomerNameMap,
  ]);

  const columnsInfo = useMemo(() => {
    if (!compiledLeadNameMap) {
      return null;
    }

    // build lead titles
    const uniqueLeadNames = [];
    _.forEach(compiledLeadNameMap, leadName => {
      if (!uniqueLeadNames.includes(leadName)) {
        uniqueLeadNames.push(leadName);
      }
    });

    // Sort lead names
    uniqueLeadNames.sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });

    // build customer names
    const uniqueCustomerNames = [];
    _.forEach(compiledCustomerNameMap, customerName => {
      if (!uniqueCustomerNames.includes(customerName)) {
        uniqueCustomerNames.push(customerName);
      }
    });

    // Sort lead names
    uniqueCustomerNames.sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });

    // prep data by allowlisting wanted attributes
    const tableColumns = [
      {
        name: 'edit',
        header: null,
        minWidth: columnWidthButtons,
        maxWidth: columnWidthButtons,
        render: ({ data }) => {
          return (
            <>
              <Tooltip title="Delete Lead">
                <Button
                  onClick={() => {
                    deleteThisContent(data.contentItem);
                  }}
                  className={classes.editButton}
                >
                  <DeleteOutlineIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Edit Lead">
                <Button
                  onClick={() => {
                    editThisContent(data.contentItem);
                  }}
                  className={classes.editButton}
                >
                  <CreateIcon />
                </Button>
              </Tooltip>
            </>
          );
        },
      },
      {
        name: 'projectCreationStatus',
        header: null,
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: 'All',
          dataSource: ['Create Project', 'Go To Project'].map(value => ({
            id: value,
            label: value,
          })),
        },

        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
        render: ({ data }) => {
          return (
            <Grid container justifyContent="center" alignItems="center">
              {data.projectCreationStatus === 'Go To Project' ? (
                <Link
                  to={`/projects/${data.contentItem.jrnId}`}
                  className={classes.projectAction}
                >
                  {data.projectCreationStatus}
                </Link>
              ) : (
                <Button
                  onClick={() => {
                    handleProjectCreation(data.contentItem);
                  }}
                  className={classes.projectAction}
                >
                  {data.projectCreationStatus}
                </Button>
              )}
            </Grid>
          );
        },
      },

      { name: 'leadId', header: 'Lead Id', defaultVisible: false },
      {
        name: 'leadName',
        header: 'Title',
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: 'All',
          multiple: true,
          wrapMultiple: true,
          dataSource: uniqueLeadNames.map(value => ({
            id: value,
            label: value,
          })),
        },
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
        render: ({ value, data }) => {
          return (
            <Link
              to={`/customers/${data.customerId}/lead/${data.leadId}`}
              className="basicStyledLink"
            >
              {value}
            </Link>
          );
        },
      },
      {
        name: 'customerName',
        header: 'Customer',
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: 'All',
          multiple: true,
          wrapMultiple: true,
          dataSource: uniqueCustomerNames.map(value => ({
            id: value,
            label: value,
          })),
        },
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
        render: ({ value, data }) => {
          return (
            <Link
              to={`/customers/${data.customerId}`}
              className="basicStyledLink"
            >
              {value}
            </Link>
          );
        },
      },
      {
        name: 'contentStatus',
        header: 'Status',
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: 'All',
          multiple: true,
          wrapMultiple: true,
          dataSource: leadStatuses.map(pair => ({
            id: pair.value,
            label: pair.label,
          })),
        },
        defaultFlex: 30,
        minWidth: columnMinWidthMedium,
        render: ({ data, value }) => {
          return (
            <Grid container justifyContent="center" alignItems="center">
              <ButtonBase
                onClick={() => {
                  handleChipClick(data);
                }}
              >
                <LeadChip contentStatus={value} style={{ minWidth: 135 }} />
              </ButtonBase>
            </Grid>
          );
        },
      },
      {
        name: 'startDate',
        header: 'Start Date',
        dateFormat: 'YYYY-MM-DD',
        filterEditor: DateFilter,
        filterEditorProps: () => {
          // for range and notinrange operators, the index is 1 for the after field
          return {
            cancelButton: false,
            highlightWeekends: false,
          };
        },
        defaultFlex: 20,
        minWidth: columnMinWidthMedium,
        render: ({ value }) => {
          if (!value) {
            return 'n/a';
          }
          return moment(value).format(dateTimeFormat);
        },
      },
      {
        name: 'endDate',
        header: 'End Date',
        dateFormat: 'YYYY-MM-DD',
        filterEditor: DateFilter,
        filterEditorProps: () => {
          // for range and notinrange operators, the index is 1 for the after field
          return {
            cancelButton: false,
            highlightWeekends: false,
          };
        },
        defaultFlex: 20,
        minWidth: columnMinWidthMedium,
        render: ({ value }) => {
          if (!value) {
            return 'n/a';
          }
          return moment(value).format(dateTimeFormat);
        },
      },
      {
        name: 'labels',
        header: 'Labels',
        defaultFlex: 10,
        minWidth: columnMinWidthMedium + 5,
      },
      {
        name: 'paymentsBudget',
        header: 'Quoted',
        type: 'amount',
        defaultFlex: 10,
        textAlign: 'center',
        minWidth: columnMinWidthMedium + 5,
        render: monetaryRender,
        sort: simpleSortForAmount,
      },
      {
        name: 'receiptsBillsBudget',
        header: 'Budget - Receipt/Bills',
        type: 'amount',
        defaultFlex: 10,
        textAlign: 'center',
        minWidth: columnMinWidthMedium + 5,
        render: monetaryRender,
        sort: simpleSortForAmount,
      },
      {
        name: 'laborBudget',
        header: 'Budget - Labor Hours',
        type: 'amount',
        defaultFlex: 10,
        textAlign: 'center',
        minWidth: columnMinWidthMedium,
        render: fixedValueRender,
        sort: simpleSortForAmount,
      },
    ];

    if (!managingCompanyInfo) {
      _.remove(tableColumns, ({ name }) =>
        ['laborActual', 'receiptsBillsActual'].includes(name)
      );
    }

    return tableColumns.map(columnDefinition =>
      buildColumnObj(columnDefinition)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compiledLeadNameMap]);

  const buildDataGrid = ({ withoutPreferences = false } = {}) => {
    const {
      newColumnsInfo: newColumns,
      newSortInfo,
      newFilterInfo,
      newColumnOrder,
    } = applyPreferences({
      preferences: withoutPreferences ? null : columnSettings,
      defaultColumnsInfo: columnsInfo,
      defaultFilterInfo: levelFilterValues,
      defaultSortInfo: basicSortInfo,
      defaultColumnOrder: null,
    });

    if (!tableReady || withoutPreferences) {
      setColumns(newColumns);
      setDefaultSortInfo(newSortInfo);
      setFilterValue(newFilterInfo);
      setColumnOrder(newColumnOrder);

      resetTableKey(); // this is to force the table to re-render
    }

    if (!tableReady) {
      setTableReady(true);
    }
  };

  useEffect(() => {
    if (columnsInfo) {
      buildDataGrid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsInfo]);

  const exportCSV = (allOrVisibleColumns = 'allColumns') => {
    const monetaryRenders = ['receiptsBillsBudget', 'paymentsBudget'];
    const fixedValueRenders = ['laborBudget'];
    const currentColumns = gridRef.current[allOrVisibleColumns];
    const columnsToUpdate = {
      leadLatLon: { header: 'Lead Address Link' },
    };
    currentColumns.forEach((columnInfo, index) => {
      if (columnsToUpdate[columnInfo.name]) {
        currentColumns[index].header = columnsToUpdate[columnInfo.name].header;
      }
    });

    // remove the delete/edit column
    const columnsToRemove = ['edit', 'projectCreationStatus'];
    _.remove(currentColumns, column => columnsToRemove.includes(column.name));

    const rows = gridRef.current.data.map(data => {
      return currentColumns.map(column => {
        if (column.id === 'startDate' || column.id === 'endDate') {
          return moment(data[column.id]).format(DEFAULT_DATE_FORMAT);
        }
        if (column.id === 'contentStatus') {
          let chipWording = 'OPEN';
          switch (data[column.id]) {
            case LEAD_STATUS_INITIAL:
              // just leave defaults above
              chipWording = 'OPEN';
              break;
            case LEAD_STATUS_IN_PROGRESS_PENDING:
              chipWording = 'SENT';
              break;
            case LEAD_STATUS_REJECTED:
              chipWording = 'REJECTED';
              break;
            case LEAD_STATUS_IN_PROGRESS_ACCEPTED:
              chipWording = 'ACCEPTED';
              break;
            default:
              break;
          }
          return chipWording;
        }
        if (monetaryRenders.includes(column.id)) {
          return monetaryRender({ value: data[column.id] });
        }
        if (fixedValueRenders.includes(column.id)) {
          return fixedValueRender({ value: data[column.id] });
        }

        // default case
        return data[column.id];
      });
    });

    const csvOutput = Papa.unparse({
      fields: currentColumns.map(column => column.header),
      data: rows,
    });

    const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8;' });

    const filename = `lead-export-${moment().format(
      'YYYY-MM-DD-HH-mm-ss'
    )}.csv`;

    downloadBlob(blob, filename);
    runAnalytics('Contents', {
      contentAction: 'Export Content',
      userId: userInfo.userId,
      username: userInfo.username,
      type: 'Admin Leads',
    });
  };

  const calcInfoStats = () => {
    const statsInfo = {
      budget: {
        paymentsTotal: 0,
        paymentsTotalAvg: 0,
        receiptsAndBillsTotal: 0,
        receiptsAndBillsTotalAvg: 0,
        laborTotal: 0,
        laborTotalAvg: 0,
      },
      count: {
        paymentsBudgets: 0,
        receiptsAndBillsBudgets: 0,
        laborBudgets: 0,
      },
    };

    gridRef.current.data.forEach(lead => {
      // get budget for payments, receipts, labor
      if (lead.paymentsBudget) {
        statsInfo.budget.paymentsTotal += lead.paymentsBudget || 0;
        statsInfo.count.paymentsBudgets += 1;
      }
      if (lead.receiptsBillsBudget) {
        statsInfo.budget.receiptsAndBillsTotal += lead.receiptsBillsBudget || 0;
        statsInfo.count.receiptsAndBillsBudgets += 1;
      }
      if (lead.laborBudget) {
        statsInfo.budget.laborTotal += lead.laborBudget || 0;
        statsInfo.count.laborBudgets += 1;
      }
    });
    statsInfo.budget.paymentsTotalAvg = statsInfo.count.paymentsBudgets
      ? statsInfo.budget.paymentsTotal / statsInfo.count.paymentsBudgets
      : 0;

    statsInfo.budget.receiptsAndBillsTotalAvg = statsInfo.count
      .receiptsAndBillsBudgets
      ? statsInfo.budget.receiptsAndBillsTotal /
        statsInfo.count.receiptsAndBillsBudgets
      : 0;

    statsInfo.budget.laborTotalAvg = statsInfo.count.laborBudgets
      ? statsInfo.budget.laborTotal / statsInfo.count.laborBudgets
      : 0;

    // ACTUAL VARIANCES

    setEntriesStats(statsInfo);
    setStatsDialogInfo({ open: true });
  };

  const refreshData = () => {
    getCompanyLeadsRefetch();
  };

  const handleStatDialogClose = () => {
    setStatsDialogInfo({
      ...statsDialogInfo,
      open: false,
    });
  };

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const showPreferencesMenu = event => {
    setMenuAnchorEl(event.currentTarget);
  };
  const closePreferencesMenu = () => {
    setMenuAnchorEl(null);
  };

  const saveColumnPrefs = () => {
    const allColumnInfo = gridRef.current.columnsMap;
    store.dispatch({
      type: 'SET_ADMIN_TOOLS_SETTINGS',
      payload: { leads: allColumnInfo },
    });
    closePreferencesMenu();
  };

  const clearColumnPrefs = () => {
    store.dispatch({
      type: 'SET_ADMIN_TOOLS_SETTINGS',
      payload: { leads: null },
    });
    // rebuild the table now
    buildDataGrid({ withoutPreferences: true });
    closePreferencesMenu();
  };

  return (
    <>
      <Grid
        container
        direction="row"
        className={classes.scrollableColumn}
        style={{ position: 'relative' }}
      >
        {tableReady && columnsInfo && dataSource ? (
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="space-between"
              className={classes.headerWrapper}
              spacing={1}
            >
              <Grid container item xs={12} justifyContent="space-between">
                <Grid item>
                  <AdminToolsTitle
                    Icon={CONTENT_DEFINITION[CONTENT_TYPE.LEAD].Icon}
                    titleText="Manage Leads"
                  />
                </Grid>
                <Grid item className={classes.actionButtonsContainer}>
                  <AdminToolsIconButton
                    tooltipText="Reload Data"
                    transparentBackground
                    onClick={refreshData}
                    disabled={getCompanyLeadsLoading}
                    isLoading={getCompanyLeadsLoading}
                  >
                    <CachedIcon />
                  </AdminToolsIconButton>
                  <AdminToolsIconButton
                    tooltipText="Export to Excel/CSV"
                    onClick={() => {
                      exportCSV();
                    }}
                  >
                    <PublishIcon />
                  </AdminToolsIconButton>

                  <AdminToolsIconButton
                    tooltipText="View stats for current data"
                    onClick={calcInfoStats}
                  >
                    <EqualizerIcon />
                  </AdminToolsIconButton>

                  <AdminToolsIconButton
                    tooltipText="Add a lead"
                    onClick={openAddModal}
                  >
                    <AddIcon />
                  </AdminToolsIconButton>

                  <AdminToolsIconButton
                    tooltipText="View other options"
                    onClick={showPreferencesMenu}
                  >
                    <MoreHorizIcon />
                  </AdminToolsIconButton>
                  <Menu
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={Boolean(menuAnchorEl)}
                    onClose={closePreferencesMenu}
                  >
                    <MenuItem onClick={saveColumnPrefs}>
                      <SaveIcon />
                      &nbsp;&nbsp;Save Column Preferences
                    </MenuItem>
                    <MenuItem onClick={clearColumnPrefs}>
                      <BlockIcon />
                      &nbsp;&nbsp;Clear Column Preferences
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ height: '88%' }}>
              <ReactDataGrid
                key={tableKey}
                // grab the ref
                onReady={setGridRef}
                // set which property is used as the ID
                idProperty="leadId"
                // set which columns show
                columns={columns}
                // column order
                defaultColumnOrder={columnOrder}
                // set the data to build from
                dataSource={dataSource}
                // set basic styling for the overall table
                style={{ minHeight: '100%' }}
                // filtering
                enableFiltering
                defaultFilterValue={filterValue}
                filterTypes={filterTypes}
                // sorting
                defaultSortInfo={defaultSortInfo}
                allowUnsort={false}
                // scrollbar
                scrollProps={scrollProps}
                headerHeight={0}
              />
            </Grid>
          </Grid>
        ) : (
          <LoadingCover loader="linear">
            <Typography variant="h3" align="center">
              Loading all company leads...
            </Typography>
          </LoadingCover>
        )}
      </Grid>
      {dialogInfo.open && (
        <OkCancelDialog {...dialogInfo}>
          <Typography>{dialogInfo.message}</Typography>
        </OkCancelDialog>
      )}
      {showManualAddDialog.open && (
        <AddLead
          addEditLeadInfo={showManualAddDialog}
          toClose={() => {
            refreshData();
            setShowManualAddDialog({
              ...showManualAddDialog,
              open: false,
            });
          }}
          hideCustomerOption={showManualAddDialog.existingInfo}
        />
      )}
      {statsDialogInfo.open && (
        <LeadStatsDialog data={entriesStats} onClose={handleStatDialogClose} />
      )}
      {showChangeLeadStatusDialog.open && (
        <ChangeLeadStatusDialog
          toClose={() => setShowChangeLeadStatusDialog(false)}
          initialStatus={
            showChangeLeadStatusDialog.contentToChange
              ? showChangeLeadStatusDialog.contentToChange.contentStatus
              : null
          }
          onConfirm={handleContentStatusChange}
        />
      )}
      {showCreateProjectFromLeadDialog.open && (
        <ContentDetailsModal
          mode={showCreateProjectFromLeadDialog.mode}
          existingInfo={showCreateProjectFromLeadDialog.existingInfo}
          leadDetails={showCreateProjectFromLeadDialog.leadDetails}
          baseContentInfo={showCreateProjectFromLeadDialog.baseContentInfo}
          onClose={handleAfterProjectCreatedFromLead}
          hideCustomerOption={
            showCreateProjectFromLeadDialog.hideCustomerOption
          }
        />
      )}
    </>
  );
};
const mapStateToProps = state => {
  const columnSettings = _.get(
    state,
    'appState.adminToolsSettings.leads',
    null
  );

  return {
    userInfo: state.userInfo,
    managingCompanyInfo: state.appState.managingCompanyInfo,
    columnSettings,
  };
};

export default compose(
  DeleteJrnAction,
  GetCompanyCustomersAction,
  UpdateJrnMutationAction
)(connect(mapStateToProps)(withRouter(ManageLeads)));
