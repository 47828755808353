import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import BasicDialog from '../basic-dialog/basic-dialog';

const upgradeRequiredComponent = props => {
  const { handleClose } = props;

  return (
    <BasicDialog
      open
      handleClose={handleClose}
      title="Upgrade Required"
      customChildren
    >
      <Typography variant="h5" gutterBottom align="center">
        This action requires an admin account. You currently have a basic user
        account which is limited in its capabilities.
      </Typography>
      <Typography variant="h6" align="center" style={{ marginTop: 20 }}>
        To upgrade your account, please request an admin seat from an existing
        admin in your company.
      </Typography>
    </BasicDialog>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(upgradeRequiredComponent);
