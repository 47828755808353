import React, { useState } from 'react';
import { Grid, InputLabel, IconButton, Typography } from '@material-ui/core';
import { Help as HelpIcon } from '@material-ui/icons';

import LevelButtonSegment from '../../../components/level-button-segment/level-button-segment';
import FinancialTypeExplanationDialog from './financial-type-explanation-dialog';

const FinancialTypeButtonsSegment = ({
  classes,
  defaultType,
  error,
  financialSegmentKey,
  setFieldValue,
  values,
}) => {
  const [showTypeExplanationDialog, setShowTypeExplanationDialog] = useState(
    false
  );

  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid #ccc',
          paddingTop: 8,
          paddingBottom: 8,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'row',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <InputLabel className={classes.inlineLabel}>
              What type is this?
              <IconButton
                color="primary"
                onClick={() => setShowTypeExplanationDialog(true)}
              >
                <HelpIcon style={{ fontSize: 18 }} />
              </IconButton>
            </InputLabel>
          </div>
          {!!error && (
            <Typography
              variant="caption"
              color="error"
              style={{ marginTop: 4 }}
            >
              Please choose the type
            </Typography>
          )}
        </div>

        <LevelButtonSegment
          key={financialSegmentKey}
          size="medium"
          initiallySelected={values.type || null}
          options={
            defaultType === 'receipt' || defaultType === 'bill'
              ? [
                  {
                    label: 'RECEIPT',
                    value: 'receipt',
                  },
                  {
                    label: 'BILL',
                    value: 'bill',
                  },
                ]
              : [
                  {
                    label: 'INVOICE',
                    value: 'invoice',
                  },
                  {
                    label: 'PAYMENT',
                    value: 'payment',
                  },
                ]
          }
          passbackChosenValue={type => {
            setFieldValue('type', type);
          }}
        />
      </Grid>
      <FinancialTypeExplanationDialog
        contentType={defaultType}
        open={showTypeExplanationDialog}
        onClose={() => {
          setShowTypeExplanationDialog(false);
        }}
      />
    </>
  );
};

export default FinancialTypeButtonsSegment;
