import gql from 'graphql-tag';
import * as Fragment from '../fragments';

export default gql`
  ${Fragment.Customer}
  mutation DisconnectSyncedCustomer($companyId: String!, $customerId: String!) {
    disconnectSyncedCustomer(
      input: { companyId: $companyId, customerId: $customerId }
    ) {
      ...CustomerFields
    }
  }
`;
