import React, { useContext, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import {
  Grid,
  CircularProgress,
  Button,
  Typography,
  Tooltip,
  IconButton,
  FormControl,
  MenuItem,
  ListSubheader,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import {
  HelpOutline as HelpOutlineIcon,
  Person as PersonIcon,
  Business as BusinessIcon,
  SupervisorAccount as SupervisorAccountIcon,
} from '@material-ui/icons';

import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';

import ResponseTooBigWarning from '../../components/response-too-big-warning/response-too-big-warning';

import { CalendarDataContext } from './calendar-data-provider';
import { CALENDAR_MODE, DATA_TYPE } from './calendar.constants';
import CalendarEvent from './calendar-event';
import palette from '../../theme/palette';
import CalendarEventDetailsModal from './calendar-event-details-modal';
import useStyles from './calendar.styles';

const CalendarDisplay = ({
  selectValue,
  handleSelectChange,
  managingCompanyInfo,
}) => {
  const classes = useStyles();

  const calendarDataContext = useContext(CalendarDataContext);
  // {
  //   whosData,
  //   calendarDataType,
  //   calendarData,
  //   calendarDataLoading,
  //   calendarDataWarning,
  //   projectMap,
  //   projectColorMap,
  //   userMap,
  // }

  const eventsToShow = useMemo(() => {
    const { calendarData, projectColorMap } = calendarDataContext;

    if (!calendarData) {
      return [];
    }

    const eventItems = calendarData.map(contentItem => {
      const textColor = '#fff';

      return {
        // info needed to show event in calendar
        title: contentItem.title,
        start: contentItem.startDate,
        end: contentItem.endDate,
        // per event styling
        backgroundColor:
          projectColorMap[contentItem.contentId] || palette.brandColorMidGrey,
        borderColor:
          projectColorMap[contentItem.contentId] || palette.brandColorMidGrey,
        textColor,
        // info needed for modal
        extendedProps: {
          details: contentItem,
        },
      };
    });

    return eventItems;
  }, [calendarDataContext]);

  const isCompanyAdmin = !!managingCompanyInfo?.isCompanyAdmin;

  const calendarDataTypeOptions = [
    { isSubheader: true, label: 'MY PROJECTS', icon: <PersonIcon /> },
    {
      isSubheader: false,
      value: CALENDAR_MODE.MY_PROJECTS,
      label: 'My Projects',
    },
    {
      isSubheader: false,
      value: CALENDAR_MODE.MY_SHIFTS,
      label: 'My Shifts',
    },
    { isSubheader: false, value: CALENDAR_MODE.MY_TASKS, label: 'My Tasks' },
  ];

  if (isCompanyAdmin) {
    calendarDataTypeOptions.push(
      {
        isSubheader: true,
        label: 'MANAGED BY YOU',
        icon: <SupervisorAccountIcon />,
      },
      {
        isSubheader: false,
        value: CALENDAR_MODE.ADMIN_TASKS,
        label: 'Managed Tasks',
      },
      {
        isSubheader: false,
        value: CALENDAR_MODE.ADMIN_SHIFTS,
        label: 'Managed Shifts',
      },
      {
        isSubheader: true,
        label: 'COMPANY-WIDE',
        icon: <BusinessIcon />,
      },
      {
        isSubheader: false,
        value: CALENDAR_MODE.COMPANY_PROJECTS,
        label: 'Company Projects',
      },
      {
        isSubheader: false,
        value: CALENDAR_MODE.COMPANY_TASKS,
        label: 'Company Tasks',
      },
      {
        isSubheader: false,
        value: CALENDAR_MODE.COMPANY_SHIFTS,
        label: 'Company Shifts',
      }
    );
  }

  const HELPER_CALENDAR_MODE_TEXT = {
    MY_PROJECTS:
      'View all tasks/shifts assigned to you or all projects you are on.',
    MANAGED_BY_YOU:
      'View all the tasks and shifts in the projects you are an admin on.',
    COMPANY_WIDE: 'View company wide tasks, shifts and projects.',
  };

  const [modalContentItem, setModalContentItem] = useState(null);

  const [showInfoDialog, setShowInfoDialog] = useState(false);

  const eventClick = e => {
    const { details: contentInfo } = e.event.extendedProps;

    setModalContentItem(contentInfo);
  };

  const notShowingProjects =
    calendarDataContext.calendarDataType !== DATA_TYPE.PROJECTS;

  return (
    <>
      {!!calendarDataContext.calendarDataWarning && (
        <ResponseTooBigWarning
          managingCompanyId={managingCompanyInfo?.managingCompanyId}
        />
      )}
      <Grid container className={classes.root} direction="column">
        <Grid item xs={12} style={{ position: 'relative', flexGrow: 1 }}>
          <Grid item style={{ position: 'absolute', top: 0, marginBottom: 6 }}>
            <Grid item>
              {calendarDataContext.calendarDataLoading ? (
                <Grid container direction="row" alignItems="center">
                  <CircularProgress size={24} />
                  <Typography variant="body2">
                    &nbsp;&nbsp;Updating...
                  </Typography>
                </Grid>
              ) : (
                <Grid
                  item
                  container
                  style={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <FormControl
                    variant="outlined"
                    size="small"
                    style={{
                      minWidth: 160,
                      background: '#f7f7f7',
                    }}
                  >
                    <Select onChange={handleSelectChange} value={selectValue}>
                      {calendarDataTypeOptions.map(option => {
                        if (option.isSubheader) {
                          return (
                            <ListSubheader
                              key={option.label}
                              className={classes.listSubheader}
                            >
                              {option.icon}&nbsp;&nbsp;{option.label}
                            </ListSubheader>
                          );
                        }
                        return (
                          <MenuItem key={option.label} value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Tooltip title="Click for more info">
                    <IconButton onClick={() => setShowInfoDialog(true)}>
                      <HelpOutlineIcon fontSize="small" color="primary" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
          <FullCalendar
            initialView="dayGridMonth"
            headerToolbar={{
              center: 'title',
              left: '',
              right: 'prev,next today',
            }}
            buttonText={{
              today: 'Today',
            }}
            height="100%"
            expandRows
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            eventClick={eventClick}
            events={eventsToShow}
            displayEventEnd={notShowingProjects}
            displayEventTime={notShowingProjects}
            eventDisplay="block" // how to show events with time as well as date <> https://fullcalendar.io/docs/eventDisplay
            eventContent={({ event, timeText }) => (
              <CalendarEvent event={event} timeText={timeText} />
            )} // custom render function
            dayMaxEvents={notShowingProjects ? 4 : 6} // for all non-TimeGrid views
          />
        </Grid>
      </Grid>
      <CalendarEventDetailsModal
        contentItem={modalContentItem}
        handleClose={() => setModalContentItem(null)}
      />
      <Dialog
        open={showInfoDialog}
        onClose={() => setShowInfoDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography
            variant="h4"
            className={classes.modalTitle}
            style={{ textAlign: 'center' }}
          >
            Calender Options
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" className={classes.modalTitle}>
            My Projects
          </Typography>
          <DialogContentText>
            {HELPER_CALENDAR_MODE_TEXT.MY_PROJECTS}
          </DialogContentText>
          <Typography variant="h6" className={classes.modalTitle}>
            Managed By You
          </Typography>
          <DialogContentText>
            {HELPER_CALENDAR_MODE_TEXT.MANAGED_BY_YOU}
          </DialogContentText>
          <Typography variant="h6" className={classes.modalTitle}>
            Company Wide
          </Typography>
          <DialogContentText>
            {HELPER_CALENDAR_MODE_TEXT.COMPANY_WIDE}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowInfoDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default connect(mapStateToProps)(CalendarDisplay);
