import React from 'react';
import { Grid } from '@material-ui/core';
import ListOfVendors from './list-of-vendors/list-of-vendors.data';

const styles = {
  scrollableColumn: {
    overflowY: 'scroll',
    height: 'calc(100vh - 64px)',
    overflowX: 'hidden',
  },
};

const Vendors = () => {
  return (
    <Grid container>
      <Grid item xs={7} style={styles.scrollableColumn}>
        <ListOfVendors />
      </Grid>
    </Grid>
  );
};

export default Vendors;
