import React, { useState, useContext, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Image } from 'cloudinary-react';
import clsx from 'clsx';
import Linkify from 'react-linkify';
import { animateScroll as scroller } from 'react-scroll';
import { useInView } from 'react-intersection-observer';

import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  Menu,
  MenuItem,
  Tooltip,
  Divider,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Autorenew as AutorenewIcon,
  Wallpaper as WallpaperIcon,
  BarChart as BarChartIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Receipt as ReceiptIcon,
  Delete as DeleteIcon,
  MoreHoriz as MoreHorizIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  ArrowUpward as ArrowUpwardIcon,
  Archive as ArchiveIcon,
  Unarchive as UnarchiveIcon,
  Extension as TemplateIcon,
  OpenInNew as OpenInNewIcon,
  ContactMail as ContactMailIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  LibraryAdd as ApplyTemplateIcon,
  AssignmentReturned as AssignmentReturnedIcon,
  SupervisorAccount as SupervisorAccountIcon,
  MonetizationOn as MonetizationOnIcon,
} from '@material-ui/icons';

import _ from 'lodash';
import { ReceiptTools } from 'level-foundation';

import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import StatTiles from './stat-tiles/stat-tiles';
import ContentGallery from '../../../../components/ProjectContentListing/ContentTypes/contentGallery';
import QboSyncActionButton from '../../../../components/qbo-sync-action-button';

import { renderDateString } from '../../../../helpers/renderDateString';
import { publicIdFromUrl } from '../../../../helpers/cloudinary';
import {
  cloudindaryCloudName,
  CONTENT_TYPE,
  QBO_SYNCABLE_TYPE,
  TOP_PROJECT_ID,
} from '../../../../config/appDefaults';
import { googlifyAddress, daysAfterEpoch } from '../../../../helpers/index';

// eslint-disable-next-line import/no-cycle
import { TopParentContext } from './projectDetails.data';
import { getQboSyncState, QBO_SYNC_STATE } from '../../../../helpers/qbo-sync';
import palette from '../../../../theme/palette';

const windowWidth = window.innerWidth;
const imageTranformWidth =
  windowWidth / 3 < 612 ? 612 : Math.round(windowWidth / 3);

const shortDescriptionHeight = 200;

const useStyles = makeStyles(theme => ({
  root: {
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  wrapperBasics: {
    minHeight: 200,
  },
  projectDetailsWrapper: {
    position: 'relative',
    marginBottom: theme.spacing(4),
  },
  headerIconButton: {
    color: '#666',
  },
  activeHeaderIconButton: {
    color: theme.palette.brandColorPrimary,
  },
  coverWrapper: {
    textAlign: 'center',
    backgroundColor: '#f4f4f4',
    minHeight: 200,
    height: '100%',
    color: theme.palette.brandColorDarkGrey,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  extendedWrapper: {
    topMargin: 8,
    textAlign: 'center',
    backgroundColor: '#f4f4f4',
    minHeight: 200,
    height: '100%',
    color: theme.palette.brandColorDarkGrey,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  coverImage: {
    maxWidth: '100%',
    height: 'auto',
    maxHeight: 300,
    alignSelf: 'center',
  },
  projectInfoWrapper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    color: theme.palette.brandColorDarkGrey,
  },
  projectButtonsWrapper: {
    width: '100%',
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionButtons: {
    color: theme.palette.brandColorDarkGrey,
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  descriptionOverFlow: {
    maxHeight: shortDescriptionHeight,
    overflow: 'hidden',
  },
  tileInfoBig: {
    fontSize: 24,
  },
  statTileAmountTotal: {
    color: theme.palette.brandColorPrimary,
  },
  linkNoStyles: {
    color: '#aaa',
    textDecoration: 'none',
    textTransform: 'none',
    padding: '1px 5px',
    margin: 0,
  },
  biggerTooltipText: {
    fontSize: 16,
  },
  modalSectionWrapper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    display: 'block',
    background: palette.background.alt,
    width: '100%',
  },
}));

const ProjectDetailsHeader = props => {
  const {
    userId,
    children,
    projectInfo,
    showActionButtons,
    className,
    handleClick,
    anchorEl,
    handleClose,
    projectCanAdd,
    projectCanEdit,
    assignedToUsername,
    startDeleteProcess,
    startApplyTemplateProcess,
    startArchivalProcess,
    closeProject,
    refetchAll,
    handleAddOrEdit,
    handleSaveAsTemplate,
    isThisExtTask,
    isArchive,
    contentToShow,
    hideStatTiles,
    timeManager,
    projectUsers,
    managingCompanyInfo,
    showProjectScoreboard,
    setShowProjectScoreboard,
    history,
    handleApplyPermsToSubs,
    handleApplyLaborRatesToSubs,
  } = props;
  const topParentInfo = useContext(TopParentContext);
  const { ref: coverImageAreaRef, inView: headerInView } = useInView({
    /* Optional options */
    threshold: 0,
    root: document.getElementById('ContainerElementID'),
    initialInView: true,
  });

  const classes = useStyles({ showStickyHeader: headerInView });
  const [contentListsForStats, setContentListsForStats] = useState({});
  const [longDescription, setLongDescription] = useState(false);
  const [projectHasSubs, setProjectHasSubs] = useState(false);

  let figureStatView = 'user';
  if (projectCanEdit) {
    figureStatView = 'admin';
    if (
      projectInfo.jrnId === TOP_PROJECT_ID &&
      projectInfo.includeSubsInStats
    ) {
      figureStatView = 'overview';
    }
  }
  const [statView, setStatView] = useState(figureStatView);

  const descriptionRef = useRef(null);

  const isTemplate = projectInfo.type === CONTENT_TYPE.TEMPLATE;
  const parentIsTemplate =
    topParentInfo && topParentInfo.type === CONTENT_TYPE.TEMPLATE;
  const useTemplateLayout = isTemplate || parentIsTemplate;

  const [customerInfoModal, setCustomerInfoModal] = useState({ open: false });
  const customerInfoModalClose = () => setCustomerInfoModal({ open: false });

  const handleReceiptExport = () => {
    handleClose();
    if (!contentToShow || !contentToShow.length) return;
    const receiptLists = _.filter(
      contentToShow,
      contentItem =>
        contentItem.type === CONTENT_TYPE.RECEIPT ||
        contentItem.type === CONTENT_TYPE.BILL
    );
    const csv = ReceiptTools.generateReceiptExport(
      projectInfo,
      receiptLists,
      projectUsers
    );
    const a = document.createElement('a');
    const file = new Blob([csv], { type: 'text/csv' });
    a.href = URL.createObjectURL(file);
    a.download = `receipt-export-${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
    a.click();
  };

  const renderCoverImageArea = (options = {}) => {
    const { sticky } = options;

    if (!isThisExtTask) {
      if (projectInfo.contentUrl) {
        return (
          <Image
            publicId={publicIdFromUrl(projectInfo.contentUrl)}
            cloudName={cloudindaryCloudName}
            className={classes.coverImage}
            style={sticky ? { maxHeight: '100%' } : {}}
            alt="project cover"
            transformation={{
              quality: 'auto',
              width: imageTranformWidth,
              crop: 'scale',
              fetchFormat: 'jpg',
            }}
          />
        );
      }
      if (sticky) {
        return (
          <div
            style={{ background: '#f4f4f4', height: '100%', width: '100%' }}
          />
        );
      }
      return (
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item style={{ fontSize: 60 }}>
            <WallpaperIcon fontSize="inherit" color="inherit" />
          </Grid>
          <Typography variant="body2">No cover photo</Typography>
        </Grid>
      );
    }

    // if it's extTask and sticky is active
    if (sticky) {
      return (
        <span style={{ fontSize: 46, color: '#aaa' }}>
          <PlaylistAddCheckIcon fontSize="inherit" color="inherit" />
        </span>
      );
    }

    if (!projectInfo.contentUrl || projectInfo.contentUrl === '[]') {
      return (
        <>
          <Grid item style={{ fontSize: 60 }}>
            <WallpaperIcon fontSize="inherit" color="inherit" />
          </Grid>
          <Typography variant="body2">No task photos</Typography>
        </>
      );
    }

    if (projectInfo.contentUrl.length > 0) {
      return (
        <ContentGallery
          className={classes.coverImage}
          style={sticky ? { maxHeight: '100%' } : {}}
          fromTask
          contentPiece={projectInfo}
        />
      );
    }

    return null;
  };

  let canEdit = false;
  if (projectCanEdit) {
    canEdit = true;
  } else if (
    isThisExtTask &&
    projectCanAdd &&
    projectInfo.creatorId === userId
  ) {
    canEdit = true;
  }

  const canDelete =
    canEdit &&
    (projectInfo.type !== CONTENT_TYPE.PROJECT ||
      (projectInfo.type === CONTENT_TYPE.PROJECT &&
        contentToShow?.length === 0));

  // Can apply template if the content is a project and has no content on it
  const canApplyTemplate =
    projectCanEdit &&
    !isArchive &&
    !isThisExtTask &&
    !useTemplateLayout &&
    contentToShow &&
    contentToShow.length === 0;

  // Can archive if is company admin or is personal account
  let canArchive = false;
  const isTopLevelProject =
    projectInfo &&
    projectInfo.type === 'project' &&
    projectInfo.jrnId === TOP_PROJECT_ID;

  if (managingCompanyInfo?.isCompanyAdmin && isTopLevelProject) {
    canArchive = true;
  }

  // Is company bookkeeper and company has bookkeeping or advisory products
  const isOwnerOrBookkeeper =
    managingCompanyInfo &&
    (managingCompanyInfo.isCompanyOwner ||
      managingCompanyInfo.isCompanyBookkeeper);

  const isAdvisoryOrBookkeepingClient =
    managingCompanyInfo &&
    (managingCompanyInfo.isBookkeepingCustomer ||
      managingCompanyInfo.isAdvisoryCustomer);

  const hasAccessToProjectScoreboard =
    isOwnerOrBookkeeper && isAdvisoryOrBookkeepingClient;

  const renderActionButtons = (options = {}) => {
    const { sticky } = options;
    let wordingType = 'Project';
    if (
      projectInfo.jrnId &&
      projectInfo.jrnId !== TOP_PROJECT_ID &&
      !isThisExtTask
    ) {
      wordingType = 'Sub-Project';
    } else if (isTemplate) {
      wordingType = 'Template';
    } else if (isThisExtTask) {
      wordingType = 'Task';
    }

    if (showActionButtons) {
      return (
        <Grid
          item
          className={classes.projectButtonsWrapper}
          style={{ marginBottom: sticky ? 0 : null }}
        >
          <Tooltip title={`${wordingType} options`}>
            <IconButton
              aria-controls="projectDetailsHeaderMenu"
              aria-haspopup="true"
              onClick={handleClick}
              className={classes.headerIconButton}
            >
              <MoreHorizIcon />
            </IconButton>
          </Tooltip>

          <Menu
            id="projectDetailsHeaderMenu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                refetchAll();
              }}
              className={classes.actionButtons}
            >
              <AutorenewIcon />
              &nbsp;&nbsp;Refresh Content
            </MenuItem>
            {canEdit && (
              <MenuItem
                className={classes.actionButtons}
                onClick={() => {
                  handleClose();
                  handleAddOrEdit('edit');
                }}
              >
                <EditIcon />
                <>&nbsp;&nbsp;Edit {wordingType}</>
              </MenuItem>
            )}
            {canApplyTemplate && (
              <MenuItem
                onClick={() => {
                  startApplyTemplateProcess();
                  handleClose();
                }}
                className={classes.actionButtons}
              >
                <ApplyTemplateIcon />
                <>&nbsp;&nbsp;Add Template Content</>
              </MenuItem>
            )}
            {canEdit && projectUsers && !useTemplateLayout && (
              <MenuItem
                className={classes.actionButtons}
                onClick={handleReceiptExport}
              >
                <ReceiptIcon />
                <>&nbsp;&nbsp;Export Receipts/Bills</>
              </MenuItem>
            )}
            {canEdit && !isThisExtTask && !useTemplateLayout && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleSaveAsTemplate();
                }}
                className={classes.actionButtons}
              >
                <TemplateIcon />
                &nbsp;&nbsp;Save as Template
              </MenuItem>
            )}
            {canArchive && !isThisExtTask && !useTemplateLayout && (
              <MenuItem
                onClick={() => {
                  startArchivalProcess();
                  handleClose();
                }}
                className={classes.actionButtons}
              >
                {isArchive ? <UnarchiveIcon /> : <ArchiveIcon />}
                <>&nbsp;&nbsp;{isArchive ? 'Unarchive' : 'Archive'} Project</>
              </MenuItem>
            )}
            {canDelete && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  startDeleteProcess();
                }}
                className={classes.actionButtons}
              >
                <DeleteIcon />
                <>&nbsp;&nbsp;Delete {wordingType}</>
              </MenuItem>
            )}
            {managingCompanyInfo?.isCompanyAdmin &&
              isTopLevelProject &&
              projectHasSubs && [
                <Divider
                  key="divider"
                  style={{ margin: '12px 0 12px 18px' }}
                />,
                <Typography
                  key="wording1"
                  style={{ margin: '12px 0 8px 18px' }}
                  variant="body1"
                >
                  SUB-PROJECT TOOLS
                </Typography>,
                <MenuItem
                  key="menuItem1"
                  className={classes.actionButtons}
                  onClick={() => {
                    handleClose();
                    handleApplyPermsToSubs();
                  }}
                >
                  <AssignmentReturnedIcon />
                  <>&nbsp;&nbsp;Push Permissions To Sub-Projects</>
                </MenuItem>,
                <MenuItem
                  key="menuItem2"
                  className={classes.actionButtons}
                  onClick={() => {
                    handleClose();
                    handleApplyLaborRatesToSubs();
                  }}
                >
                  <Grid container style={{ position: 'relative' }}>
                    <SupervisorAccountIcon />
                    <MonetizationOnIcon
                      style={{
                        position: 'absolute',
                        top: -4,
                        right: -3,
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <>&nbsp;&nbsp;Push Labor Rates To Sub-Projects</>
                </MenuItem>,
              ]}
          </Menu>
          {isTopLevelProject &&
            canEdit &&
            managingCompanyInfo &&
            managingCompanyInfo.isCustomerSyncEnabled && (
              <QboSyncActionButton
                recordInfo={projectInfo}
                recordType={QBO_SYNCABLE_TYPE.PROJECT}
                parentCustomerInfo={projectInfo.customerInfo || null}
              />
            )}
          {hasAccessToProjectScoreboard &&
            setShowProjectScoreboard &&
            getQboSyncState(projectInfo) === QBO_SYNC_STATE.ACTIVE && (
              <Tooltip title="View scoreboard">
                <IconButton
                  onClick={() =>
                    setShowProjectScoreboard(!showProjectScoreboard)
                  }
                  color="secondary"
                  variant="contained"
                  className={classes.headerIconButton}
                  aria-label="view scoreboard"
                >
                  <BarChartIcon />
                </IconButton>
              </Tooltip>
            )}
          {(canEdit || projectCanAdd) && (
            <Tooltip title="Add content">
              <IconButton
                onClick={() => {
                  handleClose();
                  handleAddOrEdit('addTo');
                }}
                color="secondary"
                variant="contained"
                className={classes.headerIconButton}
                aria-label="add content"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Close this panel">
            <IconButton
              onClick={closeProject}
              color="secondary"
              className={classes.headerIconButton}
              aria-label="close project"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>

          {sticky && (
            <Tooltip title="Top">
              <IconButton
                onClick={() => {
                  scroller.scrollTo('topOfProjects', {
                    duration: 1500,
                    delay: 0,
                    smooth: true,
                    containerId: 'ContainerElementID',
                  });
                }}
                color="secondary"
                className={classes.headerIconButton}
                aria-label="scroll to top"
              >
                <ArrowUpwardIcon />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      );
    }
    return null;
  };

  let dateString;
  if (isTemplate) {
    const dayLength =
      moment(projectInfo.endDate).diff(moment(projectInfo.startDate), 'days') +
      1;
    dateString = `Length: ${dayLength} day${dayLength === 1 ? '' : 's'}`;
  } else if (parentIsTemplate) {
    // e.g. "Day 3 - 5"
    const diffStartFromStart = daysAfterEpoch(projectInfo.startDate) + 1;
    const diffEndFromStart = daysAfterEpoch(projectInfo.endDate) + 1;
    if (diffStartFromStart === diffEndFromStart) {
      dateString = `Day ${diffStartFromStart}`;
    } else {
      dateString = `Day ${diffStartFromStart} - ${diffEndFromStart}`;
    }
  } else {
    dateString = renderDateString(
      projectInfo.startDate,
      projectInfo.endDate,
      'short'
    );
  }
  useEffect(() => {
    if (contentToShow) {
      const contentForStats = {};
      let hasSubs = false;
      contentToShow.forEach(contentPiece => {
        let typeToCheck = contentPiece.type;
        if (typeToCheck === CONTENT_TYPE.BILL) {
          typeToCheck = CONTENT_TYPE.RECEIPT;
        } else if (typeToCheck === CONTENT_TYPE.PAYMENT) {
          typeToCheck = CONTENT_TYPE.INVOICE;
        }

        const dontPushFor =
          typeToCheck === CONTENT_TYPE.PROJECT && contentPiece.excludeFromStats;

        if (!dontPushFor) {
          if (!contentForStats[typeToCheck]) {
            contentForStats[typeToCheck] = [];
          }
          contentForStats[typeToCheck].push(contentPiece);
        }
        if (typeToCheck === CONTENT_TYPE.PROJECT) {
          hasSubs = true;
        }
      });
      setContentListsForStats(contentForStats);
      setProjectHasSubs(hasSubs);
    }
  }, [contentToShow]);

  const renderCustomerInfoInline = () => {
    if (!projectInfo.customerInfo) return null;
    const { firstName, lastName, companyName } = projectInfo.customerInfo;
    const hasFirstOrLast = !!(firstName || lastName);
    const hasFirstAndLast = firstName && lastName;
    const onClick = () => {
      setCustomerInfoModal({ open: true });
    };
    return (
      <Button
        size="small"
        onClick={onClick}
        startIcon={<ContactMailIcon style={{ color: '#aaa' }} />}
        style={{ textTransform: 'none' }}
      >
        {hasFirstOrLast ? (
          <>
            {firstName}
            {hasFirstAndLast && ' '}
            {lastName}
          </>
        ) : (
          companyName || ''
        )}
      </Button>
    );
  };

  const goToCustomer = () => {
    const { customerId } = projectInfo.customerInfo;
    history.push(`/customers/${customerId}`);
  };

  const renderCustomerInfoModalContents = () => {
    if (!projectInfo.customerInfo) return null;
    const {
      firstName,
      lastName,
      companyName,
      address,
      email,
      phoneNumber,
      notes,
    } = projectInfo.customerInfo;

    const hasFirstOrLast = !!(firstName || lastName);
    const hasFirstAndLast = firstName && lastName;

    return (
      <Grid container style={{ padding: 16 }}>
        <Grid item container>
          <Grid item xs={12}>
            {hasFirstOrLast && (
              <Typography variant="h3" color="primary">
                {firstName}
                {hasFirstAndLast && ' '}
                {lastName}
              </Typography>
            )}
          </Grid>
          {!!companyName && (
            <Grid item xs={12} container style={{ marginBottom: 8 }}>
              <Typography
                variant={hasFirstOrLast ? 'caption' : 'h3'}
                align="center"
              >
                {companyName}
              </Typography>
            </Grid>
          )}
          {!!address && (
            <Grid item xs={12} container>
              <Typography variant="body1" align="center">
                <a
                  href={googlifyAddress(address, 'search')}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="basicStyledLink"
                >
                  {address}
                </a>
                <OpenInNewIcon
                  style={{ marginLeft: 4, fontSize: 16, color: '#ccc' }}
                />
              </Typography>
            </Grid>
          )}

          {!!email && (
            <Grid item xs={12}>
              <Typography variant="body1">
                <a
                  href={`mailto:${email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="basicStyledLink"
                >
                  {email}
                </a>
              </Typography>
            </Grid>
          )}

          {!!phoneNumber && (
            <Grid item xs={12}>
              <Typography variant="body1">{phoneNumber}</Typography>
            </Grid>
          )}
        </Grid>
        {!!notes && (
          <Grid
            item
            xs={12}
            container
            className={classes.modalSectionWrapper}
            style={{ marginTop: 16 }}
          >
            <Grid item style={{ marginBottom: 16 }}>
              NOTES:
            </Grid>
            <div className="levelParsedHtml">{ReactHtmlParser(notes)}</div>
          </Grid>
        )}
      </Grid>
    );
  };
  let descriptionToShow = null;
  if (projectInfo.description && !isThisExtTask) {
    descriptionToShow = (
      <div
        className={
          longDescription === false ? classes.descriptionOverFlow : null
        }
      >
        <div ref={descriptionRef}>
          {projectInfo.description.split('\n').map((block, i) => (
            <Typography
              variant="h5"
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            >
              <Linkify>{block || <span>&nbsp;</span>}</Linkify>
            </Typography>
          ))}
        </div>
      </div>
    );
  } else if (projectInfo.description && isThisExtTask) {
    descriptionToShow = (
      <div
        className={
          longDescription === false ? classes.descriptionOverFlow : null
        }
      >
        <Typography variant="h5" ref={descriptionRef}>
          <Linkify>{ReactHtmlParser(projectInfo.description)}</Linkify>
        </Typography>
      </div>
    );
  }

  const descriptionHeight =
    descriptionRef.current && descriptionRef.current.clientHeight;

  return (
    <>
      {!headerInView && (
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{
            position: 'sticky',
            top: 5,
            zIndex: 100,
            background: 'rgba(255, 255, 255, 0.95)',
            padding: 8,
            boxShadow: '0px 5px 9px 2px #dedede',
            border: '1px solid #CCC',
          }}
        >
          <Grid item style={{ flex: 0 }}>
            <Grid
              container
              item
              style={{
                width: 50,
                height: 50,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {renderCoverImageArea({ sticky: true })}
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              flex: 1,
              alignItems: 'center',
              paddingLeft: 8,
              paddingRight: 8,
              minWidth: 0,
            }}
          >
            <Typography variant="h4" noWrap>
              {projectInfo.title}
            </Typography>
          </Grid>
          <Grid item container style={{ flex: 0, flexDirection: 'row' }}>
            <Grid
              container
              item
              style={{ justifyContent: 'flex-end', alignItems: 'center' }}
            >
              {renderActionButtons({ sticky: true })}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        justifyContent="center"
        className={classes.wrapperBasics}
        // since the stickyHeader stays in the document flow, move the header up the height of it so the UI is less janky
        style={{ marginTop: !headerInView ? '-68px' : 0, paddingBottom: 16 }}
        ref={coverImageAreaRef}
      >
        {!isThisExtTask && (
          <Grid item xs={4} className={classes.coverWrapper}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              style={{ height: '100%' }}
            >
              <Grid item container justifyContent="center" alignItems="center">
                {renderCoverImageArea()}
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={isThisExtTask ? 12 : 8}>
          <Grid container justifyContent="center">
            {renderActionButtons()}
          </Grid>
          <Grid
            container
            justifyContent="center"
            className={clsx(classes.projectInfoWrapper, className)}
          >
            <Grid
              item
              xs={12}
              style={{ paddingBottom: projectCanEdit ? 20 : 50 }}
            >
              {projectInfo.customerInfo && renderCustomerInfoInline()}
              <Typography variant="h3">{projectInfo.title}</Typography>
              {!!projectInfo.address && !useTemplateLayout && (
                <Typography variant="h5">
                  <a
                    href={googlifyAddress(projectInfo.address, 'search')}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="basicStyledLink"
                  >
                    {projectInfo.address}
                  </a>
                </Typography>
              )}
              <Typography variant="h5">{dateString}</Typography>
              {isThisExtTask && (
                <>
                  <Typography variant="h5">
                    Assigned To: {assignedToUsername || ''}
                  </Typography>
                  <Typography variant="h5">
                    Status: {projectInfo.contentStatus}
                  </Typography>
                </>
              )}
              {descriptionToShow}
              {!!descriptionToShow &&
                descriptionHeight &&
                descriptionHeight > shortDescriptionHeight && (
                  <Button
                    onClick={() => {
                      setLongDescription(!longDescription);
                      if (!longDescription) {
                        window.scrollTo(0, 0);
                      }
                    }}
                  >
                    View {!longDescription ? 'More' : 'Less'}
                  </Button>
                )}
              {children}
            </Grid>
            {isThisExtTask &&
              projectInfo.contentUrl &&
              projectInfo.contentUrl !== '[]' && (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  className={classes.extendedWrapper}
                >
                  {renderCoverImageArea()}
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
      {!isThisExtTask && !useTemplateLayout && !hideStatTiles && (
        <StatTiles
          projectInfo={projectInfo}
          statView={statView}
          projectCanEdit={projectCanEdit}
          setStatView={setStatView}
          contentLists={contentListsForStats}
          userId={userId}
          timeManager={timeManager}
          projectUsers={projectUsers}
        />
      )}
      {customerInfoModal.open && (
        <Dialog
          open={customerInfoModal.open}
          maxWidth="xs"
          fullWidth
          onClose={customerInfoModalClose}
        >
          {renderCustomerInfoModalContents()}
          {projectCanEdit && (
            <Button
              color="default"
              onClick={goToCustomer}
              style={{ marginBottom: 8, alignSelf: 'center', color: '#777' }}
              endIcon={
                <ArrowForwardIosIcon
                  fontSize="small"
                  style={{ color: '#999' }}
                />
              }
            >
              Go To Customer Profile
            </Button>
          )}
          <DialogActions>
            <Button onClick={customerInfoModalClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
});

export default withRouter(connect(mapStateToProps)(ProjectDetailsHeader));
