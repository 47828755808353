import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';
import _ from 'lodash';
import { Typography, Grid, Button } from '@material-ui/core';
import LevelModal from '../Modal/level-modal';
import {
  GetCompanyInfoAction,
  AddOrUpdateCompanyAction,
} from '../../graphql/graphql';
import GetRequestKeyQuery from '../../graphql/queries/get-request-key';

const ConnectCompanyModal = ({
  companies,
  managingCompanyInfo,
  handleClose,
  dispatch,
  onAddOrUpdateCompany,
  companyRegistrationKey,
}) => {
  const requestKeyResponse = useQuery(GetRequestKeyQuery, {
    variables: { requestKey: companyRegistrationKey },
    skip: !companyRegistrationKey,
  });

  const companyToConnect = useMemo(() => {
    let stripeCompanyData = null;

    if (requestKeyResponse) {
      const stripeCompanyDataString = _.get(
        requestKeyResponse,
        'data.getRequestKey.data'
      );

      if (stripeCompanyDataString) {
        try {
          stripeCompanyData = JSON.parse(stripeCompanyDataString);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log('err: ', err);
        }
      }
    }

    return stripeCompanyData;
  }, [requestKeyResponse]);

  const currentCompany = useMemo(() => {
    let companyInfo = null;

    if (companies && managingCompanyInfo) {
      companyInfo = _.find(companies, {
        companyId: managingCompanyInfo.managingCompanyId,
      });
    }

    return companyInfo;
  }, [managingCompanyInfo, companies]);

  if (!currentCompany || !companyToConnect) {
    return null;
  }

  const clearCompanyRegistrationKey = () => {
    dispatch({
      type: 'COMPANY_REGISTRATION_KEY',
      payload: null,
    });
  };

  const applyCompanySubscription = async () => {
    const { companyId, companyName, companyLogo } = currentCompany;

    await onAddOrUpdateCompany({
      companyId,
      companyName,
      companyLogo,
      invitationId: companyRegistrationKey,
    });
  };

  const handleSubmit = async () => {
    applyCompanySubscription();
    clearCompanyRegistrationKey();
    handleClose();
  };

  const handleCancel = () => {
    clearCompanyRegistrationKey();
    handleClose();
  };

  return (
    <LevelModal open handleClose={handleClose} fullWidth={false} maxWidth="sm">
      <Grid container style={{ minHeight: 200 }} spacing={2} direction="column">
        <Grid item>
          <Typography variant="h4">Subscription Confirmation</Typography>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Typography variant="body1">
            You are currently signed in with <b>{currentCompany.companyName}</b>
            . To apply your subscription to this company select <b>CONFIRM</b>,
            otherwise logout and click the registration link again.
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            onClick={handleCancel}
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Confirm
          </Button>
        </Grid>
      </Grid>
    </LevelModal>
  );
};

const mapStateToProps = state => ({
  managingCompanyInfo: state.appState.managingCompanyInfo || null,
  companyRegistrationKey: state.appState.companyRegistrationKey || null,
});

export default compose(
  GetCompanyInfoAction,
  AddOrUpdateCompanyAction
)(connect(mapStateToProps)(ConnectCompanyModal));
