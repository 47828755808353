import gql from 'graphql-tag';

export default gql`
  mutation AddSlideshowToJrn(
    $id: ID!
    $requestorId: String!
    $jrnId: String!
    $link: String!
    $active: Boolean!
  ) {
    addSlideshowToJrn(
      input: {
        id: $id
        requestorId: $requestorId
        jrnId: $jrnId
        link: $link
        active: $active
      }
    ) {
      __typename
      id
      jrnId
      link
      active
      status
      msg
    }
  }
`;
